
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL'); 
const GpsDataDynamic = (props) => {
    const handleEventClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onShowNoGPSKpi(props);
    };    
    var colr = 'text-secondary'
    
        if (props.data.no_gps_records == 0) {
            var colr = 'text-danger'
        } else if (props.data.no_gps_records == 1) {
            var colr = 'text-dark'
        } else if (props.data.no_gps_records == 2) {
            var colr = 'text-primary'
        } else if (props.data.no_gps_records == 3) {
            var colr = 'text-warning'
        }  
    //  if(props.data.status==4 || props.data.status==7)   { // For Delivered 
        // gps_flags = {'Red': 0, 'Violet': 1, 'Brown': 2, 'Grey': 3, 'Orange': 4, 'Dark Pink': 5, 'Black': 6}
        // missing_gps_for_delivered_trips
        if (props.data.missing_gps_for_delivered_trips == 0) {
            var colr = 'text-danger'
        } else if (props.data.missing_gps_for_delivered_trips == 1) {
            var colr = 'text-violet'
        } else if (props.data.missing_gps_for_delivered_trips == 2) {
            var colr = 'text-brown'
        } else if (props.data.missing_gps_for_delivered_trips == 3) {
            var colr = 'text-grey'
        }   else if (props.data.missing_gps_for_delivered_trips == 4) {
            var colr = 'text-orange'
        } else if (props.data.missing_gps_for_delivered_trips == 5) {
            var colr = 'text-dark-pink'
        } else if (props.data.missing_gps_for_delivered_trips == 6) {
            var colr = 'text-dark'
        }  
    //  }            
    return (
        <div>
            <button onClick={handleEventClick} className="custom-btn f22 label label-success" 
            title="GPS Data Not Adequate" ><i className={"icofont icofont-social-google-map  "+(colr)} style={{fontSize:"1.4rem"}}></i> &nbsp;</button>            
        </div>
    );
};
export default GpsDataDynamic;
