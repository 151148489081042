/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";

import Modal from 'react-responsive-modal';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
// //import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import CustomLoadingCellRenderer from "./customLoadingCellRenderer";
import { getDDMMYYYY, getYYYYMMDD, getDDMMYYYYHHMMSS, getHyphenDDMMMYYYYHHMM, getHyphenDDMMMYYYY } from '../common/utils';
import 'react-vertical-timeline-component/style.min.css';
import DrawMap from '../common/drawmap';
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
var infoBox = require('../common/google-infowindow');
var googleAnalytics = require("../common/googleanalytics");

var redirectURL = require('../redirectURL');
var moment = require('moment');

var drawingManager;
var selectedShape;
var colors = ['#1D9567', '#1692A5', '#F4D749', '#F73E3E', '#B7B3B3'];
var selectedColor;
var colorButtons = {};
var fencingLength = 0;
var mapZoom = 12;
var coordinates = [];
var geoFencingArea;

const decipher = salt => {
	const textToChars = text => text.split('').map(c => c.charCodeAt(0));
	const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
	return encoded => encoded.match(/.{1,2}/g)
		.map(hex => parseInt(hex, 16))
		.map(applySaltToChar)
		.map(charCode => String.fromCharCode(charCode))
		.join('');
}
var decode = decipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

export default class ConsignmentsGeofence extends Component {

	constructor(props) {
		super(props);
		this.state = {
			pageTitle: "",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			uploadDivWidth: '0%',
			sliderTranslate: '',
			sliderRouteTranslate: '',
			tabsliderTranslate: '',
			loadshow: 'show-n',
			showDiv: 'show-n',
			alertshow: 'fade',
			alertmg: 'show-n',
			alerterrmg: 'show-n',
			overly: 'show-n',
			alerterrshow: 'fade',
			alert: null,
			show: false,
			basicTitle: '',
			basicType: "default",
			modules: AllModules,
			sidebarSubHeader: '',
			defTransitCoords: '',
			defaultColDef: {
				sortable: true,
				filter: true,
				resizable: true

			},
			rowData: [],

			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			overlayNoRowsTemplate: "",
			frameworkComponents: {
				consignmentActions: ConsignmentActions,
				customLoadingCellRenderer: CustomLoadingCellRenderer
			},

			animateRows: true,
			debug: true,
			showToolPanel: false,
			uppressAggFuncInHeader: true,

			childconfs: [],
			childrow: [],
			rowModelType: "serverSide",
			paginationPageSize: 50,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
						statusPanel: "agTotalAndFilteredRowCountComponent",
						align: "left"
					},
					{
						statusPanel: "agTotalRowCountComponent",
						align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
						id: "columns",
						labelDefault: "Columns",
						labelKey: "columns",
						iconKey: "columns",
						toolPanel: "agColumnsToolPanel"
					},
					{
						id: "filters",
						labelDefault: "Filters",
						labelKey: "filters",
						iconKey: "filter",
						toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams: {},
			rowClassRules: {
				"highlitRow": "data.transit_delay == 1",
				"green": "data.status >= 3"

			},
			fencelist: [],
			startDate: '',
			endDate: '',
			mapinfo: '',
			defaultsdate: '',
			defaultedate: '',
			usermanualmodal: false,
			loginplant: "",
			trucksDropList: [],
			truck_no: ''
		}
		this.selectOptionsItems = this.selectOptionsItems.bind(this);
		this.onShowRouteDiv = this.onShowRouteDiv.bind(this);
		this.cipher = this.cipher.bind(this);
	}
	cipher = (salt) => {
		const textToChars = text => text.split('').map(c => c.charCodeAt(0));
		const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
		const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
		try {
			return text => text.split('')
				.map(textToChars)
				.map(applySaltToChar)
				.map(byteHex)
				.join('');
		}
		// eslint-disable-next-line no-unreachable
		catch (e) { }

	}


	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
			} else {
				console.log("GA disabled...");
			}
		} catch (error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}

	componentDidMount() {
		loadDatepicker()
		//console.log("Props ", this.props.match)
		this.logPageView(); // Log page view to GA
		var loguser = decode(localStorage.getItem("m"));
		// console.log("LOGUSER ", JSON.parse(loguser));
		var loginplant = "";
		try {
			var luser = JSON.parse(loguser);
			loginplant = luser.is_plant_code;
			// console.log("logplant", loginplant)
			this.setState({
				loginplant: loginplant,
				// consigner:[{"value":luser.is_plant_code,"label":luser.is_plant_name}]

			})
		}
		catch (e) {

		}

		var urlpath;
		var dpt;
		var hText = 'All';
		var edatetime = moment.parseZone().format('YYYY-MM-DD');
		var sdate = moment.parseZone().subtract(1, 'days').format('YYYY-MM-DD');

		this.setState({
			loadshow: 'show-m',
			startDate : sdate,
			endDate : edatetime
		});
		var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
		if (this.props.match.path == "/sndgeofence") {
			urlpath = '/consignees/sndgeofence';
			dpt = 'SNDG';
			hText = '';
		}
		else if (this.props.match.path == "/prtgeofence") {
			urlpath = '/consignees/prtgeofence';
			dpt = 'LOG-PRT';
			hText = '( Spare Parts )';
		}
		else if (this.props.match.path == "/tnpgeofence") {
			urlpath = '/consignees/tnpgeofence';
			dpt = 'LOG-TNP';
			hText = '( Train and Production )';
		}
		else if (this.props.match.path == "/mllsndgeofence") {
			urlpath = '/consignees/sndgeofence';
			dpt = 'SNDG';
			hText = '';
		}
		else if (this.props.match.path == "/scgeofence") {
			urlpath = '/consignees/scgeofence';
			dpt = 'LOG-SC';
			hText = '( Supply Chain )';
		}
		else {
			urlpath = '/consignees';
			dpt = 'all';
		}
		let pageTitle = "Geofence In/Out " + hText + ""


		//console.log(dpt);
		this.setState({
			deptcode: dpt,
			pageTitle: pageTitle
		});

		var reqparams = {
			dept_code: encode(dpt)
		}

		redirectURL.post(urlpath, reqparams).then((response) => {
				var records = response.data;
				//console.log("records", records);
				var listarr = [];
				records.map((item) => {
					if (loginplant != "" && loginplant != undefined) {
						if (item.plant_code == loginplant) {
							listarr.push(item.geofence_name);
						}

					}
					else {
						listarr.push(item.geofence_name);
					}
				})
				//console.log("listarr ",listarr);
				var uniquefencelist = listarr.filter(function (value, index, self) {
					return self.indexOf(value) === index;
				});

				this.setState({
					//rowData:rowarray,
					//loadshow:'show-n',
					fencelist: uniquefencelist
				});
			})
			.catch(function (error) {
				console.log(error);
			});

		var sdateObj = new Date();
		sdateObj.setDate(sdateObj.getDate() - 1);
		var edateObj = new Date();
		edateObj.setDate(edateObj.getDate());
		var ed = moment(edateObj).format("YYYY-MM-DD")
		//console.log("edateObj ", ed)
		var sd = moment(sdateObj).format("YYYY-MM-DD")
		//console.log("sdateObj ", sd)
		var sdate = sdateObj
		var edate = ed
		var formdata = {
			startDate: sd,
			endDate: ed,
			fence_name: '0',
			dept_code: encode(dpt)
		}
		redirectURL.post("/consignees/filters", formdata, {
			headers: {
				//'Content-Type': 'multipart/form-data',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': "*",
				'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DELETE, TOKEN',
				'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
				// 'authorization': 'Zoho-oauthtoken 1000.ecb6dbd17a44125ce5434f5f45148278.a54f59a27d9e9db5383f85ba3002ccf2'
			}
		})
			//.then(res => res.json())
			.then(
				(result) => {
					//console.log(result.data)
					var records = result.data;
					var rowarray = []
					records.map((item) => {
						// console.log("item",item)
						if (item.first_inside_fence != undefined) {
							var g1 = getDDMMYYYY(item.first_inside_fence);
							var g2 = getDDMMYYYY(item.first_outside_fence);
							var s1 = g1.split("-");
							var s2 = g2.split("-");
							var gdate1 = new Date(item.first_inside_fence);
							var gdate2 = new Date(item.first_outside_fence);
							var gatein = gdate1.getTime();
							var gateout = gdate2.getTime();
							var total = gateout - gatein;
							// console.log("gatein ", gatein, typeof(gatein))
							// console.log("gateout ", gateout, typeof(gateout))
							//var total =0 ;
						}
						else {
							var total = 0;
						}
						//console.log("total ", total)
						var diffMs = total;
						var diffDays = Math.floor(diffMs / 86400000); // days
						var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
						var diffMins = parseInt(((diffMs % 86400000) % 3600000) / 60000);
						// console.log("diffHrs ", diffHrs);
						// console.log("diffMins ", diffMins);
						// console.log("diffDays ", diffDays);
						if (item.transporter.length > 0) {
							var transprt = item.transporter[0].transporter_name
						}
						else {
							var transprt = "";
						}
						var tSpent = item.first_outside_fence === undefined ? '' : diffHrs + " Hr(s) " + diffMins + " Min(s)"

						// console.log("firstOutside", item.first_outside_fence)
						if (loginplant != "" && loginplant != undefined) {
							if (item.plant_code == loginplant) {
								rowarray.push({
									"truck_no": item.truck_no,
									"fence_name": item.fence_name,
									// "lsp_user": item.truck[0].actual_lspuser,
									"first_inside_fence": item.first_inside_fence,
									"first_outside_fence": item.first_outside_fence,
									"transporter_name": transprt,
									"time_spent": tSpent
								})
							}

						}
						else {
							if(item.truck.length > 0)
							{
								// if(item.truck[0].actual_lspuser != undefined)
								// {
								// 	var lsp = item.truck[0].actual_lspuser;
								// }
								// else{
								// 	var lsp = "";
								// }
							}
							else{
								var lsp = "";
							}
							rowarray.push({
								"truck_no": item.truck_no,
								"fence_name": item.fence_name,
								// "lsp_user": lsp,
								"first_inside_fence": item.first_inside_fence,
								"first_outside_fence": item.first_outside_fence,
								"transporter_name": transprt,
								"time_spent": tSpent
							})
						}
					})
					// console.log("rowarray ", rowarray)
					// let trucksDropList = []
					// rowarray.map(e => {
					// 	if(trucksDropList.includes(e.truck_no)){

					// 	} else{
					// 		trucksDropList.push(e.truck_no)
					// 	}

					// })

					this.setState({
						// trucksDropList,
						rowData: rowarray,
						loadshow: 'show-n',
						startDate: sd,
						endDate: ed
					});


					// this.setState({
					//      rowData:result.data,
					//      loadshow:'show-n'
					//  });
				})
			.catch(function (error) {
				console.log(error);
			});

			redirectURL.post("/consignments/usergridstates", { userId: localStorage.getItem("userid"), screenurl: window.location.pathname })
			.then(async (resp) => {
				//// console.log("resp ", resp)
				await this.setState({
					usergridstate: resp.data
				});
				await this.restoreGridStates();
			})
			
	};


	onGridReady = params => {
		this.gridApi = params.api;

		//console.log("Grid is: ", params);
		this.gridColumnApi = params.columnApi;

		//    ChartMenu.prototype.saveChart = function (e) {
		// 	   var event = {
		// 	      data: this.gridOptionsWrapper.getApi(),
		// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
		// 	    };
		// 	   //console.log("Testing", event);
		// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
		// 	   this.dispatchEvent(event);
		// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;

		//    };
		//console.log("Deptcode " ,this.state.deptcode)
		if (this.state.deptcode == 'LOG-PRT') {
			this.gridColumnApi.setColumnsVisible(["nightdriving_exception"], false);
			this.gridColumnApi.setColumnsVisible(["loading_delay_exception"], false);
		}
		if (this.state.deptcode == 'SNDG') {
			this.gridColumnApi.setColumnsVisible(["nightdriving_exception"], true);
			this.gridColumnApi.setColumnsVisible(["loading_delay_exception"], true);
		}


	};


	onGridState = () => {
		//console.log(this.gridApi);

		/*Get  Current Columns State and Store in this.colState */
		this.colState = this.gridColumnApi.getColumnState();

		/*Get Current RowGroup Columns State and Store in this.rowGroupState */
		this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		/*Get Current Pivot Columns State and Store in this.pivotColumns,
		 * User should enable the Pivot mode.*/
		this.pivotColumns = this.gridColumnApi.getPivotColumns();

		/*Get Current Filter State and Store in window.filterState */
		window.filterState = this.gridApi.getFilterModel();
		this.gridApi.setFilterModel(window.filterState);


	}

	onClickHideAll() {
		this.setState({
			loadshow: 'show-n',
			overly: 'show-n',
			sliderRouteTranslate: '',
			tabsliderTranslate: ''
		});

	}
	closeAlert = () => {
		this.setState({
			show: false
		});
	}
	handlerStartDateTime = (event, currentDate, selectedDate) => {
		//console.log("event ", event)
		//var d = new Date(event._d);
		var d = new Date(moment(event).format("YYYY-MM-DD"));
		//console.log("d ", d)
		var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());
		// console.log("startdate ", startdate)
		// alert(startdate)
		this.setState({
			startDate: startdate
		});
		//let value = event.target.value;
		//this.setState({'startDate':value});
	}

	handlerEndDateTime = (event, currentDate, selectedDate) => {
		//var d = new Date(event._d);
		var d = new Date(moment(event).format("YYYY-MM-DD"));
		// var edate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate())
		var enddate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());

		this.setState({
			endDate: enddate
		});


		//let value = event.target.value;
		//this.setState({'startDate':value});
	}
	formHandler = (event) => {
		event.preventDefault();
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmittedGetData,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.setState({
			loadshow: 'show-m'
		});
		//var formdata = new FormData(event.target);
		//var s = document.getElementById("startDate").value;
		// var s = this.state.startDate;
		// var st = s.split(" ");
		// console.log("S ", st)
		// var startdate = st[0];
		// //var startdate = strdate[2]+"-"+strdate[1]+"-"+strdate[0];
		// //var e = document.getElementById("endDate").value;
		// var e = this.state.endDate;
		// var et = e.split(" ");
		// console.log("E ", et)
		// var enddate = et[0];
		//var enddate = erdate[2]+"-"+erdate[1]+"-"+erdate[0];

		var startdate = document.getElementById("startDate").value;
		// console.log('startdate563',startdate)
		var s = startdate.split("-")
		var sdate = s[2] + "-" + s[1] + "-" + s[0]
		// console.log("sdate ", sdate)
		var enddate = document.getElementById("endDate").value;
		// console.log('enddate569',enddate)
		var e = enddate.split("-")
		var edate = e[2] + "-" + e[1] + "-" + e[0]

		if (sdate == "undefined-undefined-") {
			var sdt = this.state.startDate
		}
		else {
			var sdt = startdate;
		}

		if (edate == "undefined-undefined-") {
			var edt = this.state.endDate
		}
		else {

			var edt = enddate;
		}
		var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
		var formdata = {
			startDate: sdt,
			endDate: edt,
			truck_no: this.state.truck_no,
			fence_name: this.state.fence_name,
			dept_code: encode(this.state.deptcode)
		}
		//console.log("formdata ", formdata)
		redirectURL.post("/consignees/filters", formdata, {
			headers: {
				//'Content-Type': 'multipart/form-data',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': "*",
				'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DELETE, TOKEN',
				'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
				// 'authorization': 'Zoho-oauthtoken 1000.ecb6dbd17a44125ce5434f5f45148278.a54f59a27d9e9db5383f85ba3002ccf2'
			}
		})
			//.then(res => res.json())
			.then(
				(result) => {
					//console.log(result.data)
					var records = result.data;
					var rowarray = []
					records.map((item) => {
						//console.log("Items ", item)
						if (item.first_inside_fence != undefined) {
							var g1 = getDDMMYYYY(item.first_inside_fence);
							var g2 = getDDMMYYYY(item.first_outside_fence);
							var s1 = g1.split("-");
							var s2 = g2.split("-");
							var gdate1 = new Date(item.first_inside_fence);
							var gdate2 = new Date(item.first_outside_fence);
							var gatein = gdate1.getTime();
							var gateout = gdate2.getTime();
							var total = gateout - gatein;
							// console.log("gatein ", gatein)
							// console.log("gateout ", gateout)
							//var total =0 ;
						}
						else {
							var total = 0;
						}
						//console.log("total ", total)
						var diffMs = total;
						var diffDays = Math.floor(diffMs / 86400000); // days
						var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
						var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);
						//console.log("diffHrs ", diffHrs);
						//console.log("diffMins ", diffMins);
						//console.log("diffDays ", diffDays);

						if (item.transporter.length > 0) {
							var transprt = item.transporter[0].transporter_name
						}
						else {
							var transprt = "";
						}

						// let lspUser = ''
						// if(item.truck[0].actual_lspuser !== undefined) lspUser = item.truck[0].actual_lspuser

						rowarray.push({
							"truck_no": item.truck_no,
							"fence_name": item.fence_name,
							// "lsp_user": lspUser,
							"first_inside_fence": item.first_inside_fence,
							"first_outside_fence": item.first_outside_fence,
							"transporter_name": transprt,
							"time_spent": diffHrs + " Hr(s) " + diffMins + " Min(s)"
						})
					})
					//console.log("rowarray ", rowarray)
					this.setState({
						rowData: rowarray,
						startDate: sdt,
						endDate: edt,
						loadshow: 'show-n',
						overly:'show-n'
					});
					// this.setState({
					//      rowData:result.data,
					//      loadshow:'show-n'
					//  });
				})
			.catch(function (error) {
				console.log(error);
			});
	}
	selectOptionsItems() {
		let items = [];
		//console.log(this.state.fencelist);
		this.state.fencelist.forEach(item => {
			if (item != '' && item != null) {
				items.push(<option key={item} value={item}>{item}</option>);
			}

		});
		return items;
	}

	// selectTruckItems = () =>{
	// 	let items = [];
	// 	//console.log(this.state.fencelist);
	// 	this.state.trucksDropList.forEach(item => {
	// 		if (item != '' && item != null) {
	// 			items.push(<option key={item} value={item}>{item}</option>);
	// 		}
	// 	});
	// 	return items;
	// }

	handlerForm = (event) => {
		console.log("Select", event.target.value);
		let name = event.target.name;
		let value = event.target.value;
		this.setState({ [name]: value });
	}
	onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth: '0%',
			sliderRouteTranslate: "",
			tabsliderTranslate: '',
			showDiv: 'show-n',
			overly: 'show-n'
		});
	}
	onCloseRouteDiv = () => {
		this.setState({
			uploadDivWidth: '0%',
			sliderRouteTranslate: "",
			showDiv: 'show-n',
			overly: 'show-n'
		});
	}

	onShowRouteDiv = (params) => {
		console.log("Params ", params);
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		var fdate = moment.parseZone(params.first_inside_fence).format("YYYY-MM-DD HH:mm:ss");
		var edate = moment.parseZone(params.first_outside_fence).format("YYYY-MM-DD HH:mm:ss");
		var reqData = {
			truck_no: params.truck_no,
			gate_out_time: fdate,
			seconddate: edate,
			screen: "geofence"
		}
		console.log("reqData: ", reqData);
		redirectURL.post('/consignments/maproutes', reqData, {
			headers: {
				'content-type': 'application/json'
			}
		})
			.then((response) => {
				var records = response.data;
				this.setState({
					sliderRouteTranslate: "slider-translate-65p",
					overly: 'show-m',
					maptruckno: params.truck_no,
					mapinfo: records,
					sidebarSubHeader: "Truck Information",
					routeTruck: { "truck_no": params.truck_no, "startTime": response.data.route_details.start_time, "endTime": response.data.route_details.end_time },

				});
				// console.log("end Time", typeof(response.data.route_details.end_time), response.data.route_details.end_time)
			})
			.catch(function (e) {
				console.log("Error ", e)
			})
		// var minfo={
		// 	"breaks":[],
		// 	"coords":[],
		// 	"route_details":{
		// 		"start_time": "2020-02-28 12:53:21",
		// 		"end_time": "2020-02-29 00:22:53",
		// 		"distance": "57820"
		// 	}
		// };
		// this.setState({
		// 	sliderRouteTranslate:"slider-translate-65p",
		// 	overly:'show-m',
		// 	maptruckno:params.truck_no,
		// 	mapinfo:minfo
		// });
		//console.log("mapinfo ",this.state.mapinfo);
		//console.log("minfo ",minfo);
	}
	onCloseUserManualModal = () => {
		this.setState({ usermanualmodal: false });
	};

	onClickShowUserManaul = () => {
		this.setState({ usermanualmodal: true });
	};
	
	onClickSaveGridState() {
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();
		// // console.log("Columns ", window.colState)
		// // console.log("Group ", window.groupState)
		// // console.log("Sort ", window.sortState)
		// // console.log("Filter ", window.filterState)
		let screenpage = 'Geofence In/Out';

		let reqparams = {
			gridcolumns: window.colState,
			gridgroup: window.groupState,
			gridcolsort: window.sortState,
			gridcolfilter: window.filterState,
			userId: localStorage.getItem("userid"),
			screenurl: window.location.pathname,
			screentitle: screenpage
		}
		// // console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
			.then((response) => {
				//// console.log("State response ",response.data)
				this.setState({
					show: true,
					basicTitle: "Successfully saved grid layout",
					basicType: "success",
					screenurl: window.location.pathname,
					screentitle: screenpage
				})
			})
			.catch(function (e) {
				// console.log("Error ", e)
			})
	}

	restoreGridStates() {

		if (this.state.usergridstate.length > 0) {
			var windowstates = this.state.usergridstate;
			this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
			this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
			this.gridApi.setSortModel(windowstates[0].gridcolsort);
			this.gridApi.setFilterModel(windowstates[0].gridcolfilter);

		}
	}

	resetState = () => {
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//// console.log('column state reset');
		var reqparams = {
			userId: localStorage.getItem("userid"),
			screenurl: window.location.pathname,
			screentitle: this.state.screenpage
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
			.then((response) => {
				this.setState({
					show: true,
					basicTitle: "Successfully reset default grid layout",
					basicType: "success"
				})
			})

	};

	render() {
		const modalStyles = {
			width: '1300px !important',
		}

		const { usermanualmodal } = this.state;
		//console.log("Rendere ",this.state.deptcode)
		const columnwithDefs = [

			{
				headerName: "Actions",
				field: "_id",
				width: 40,
				cellRenderer: 'consignmentActions',
				filter: false, resizable: true

			},
			{
				headerName: "Vin no",
				field: "truck_no",
				width: 150,
				filter: true,
				resizable: true,
				valueGetter:function(params){
					try {
						if(params.data.vin_number != "" && params.data.vin_number != undefined)
						{
							return params.data.vin_number;
						}
						else{
							return params.data.truck_no;
							
						}
					} catch (error) {
						
					}
				}
			},
			{
				headerName: "Fence Name",
				field: "fence_name",
				width: 180,
				filter: true,
				Routeresizable: true
			},
			{
				headerName: "Transporter Name",
				field: "transporter_name",
				width: 250,
				filter: true,
				resizable: true,
				// valueGetter:function(params){
				// 	console.log("params ", params.data)
				// 	if(typeof params.data.transporter != 'undefined')
				// 	{
				// 		return params.data.transporter[0].transporter_name
				// 	}
				// 	else
				// 	{
				// 		return '';
				// 	}

				// }
			},
			{
				headerName: "Geofence In",
				field: "first_inside_fence",
				width: 200,
				filter: true,
				resizable: true,
				valueGetter: function (params) {
					return getHyphenDDMMMYYYYHHMM(params.data.first_inside_fence)
				},
				//filter: "agDateColumnFilter",
				comparator: dateComparator,
				// filterParams: {
				// 	browserDatePicker: true,
				// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
				// 	// provide comparator function
				// 	comparator: function(filteredDate,cellValue,secondDate) {
				// 		cellValue = cellValue.replace(/\//g,"-")
				// 		if(cellValue != "" && cellValue != " "){
				// 			cellValue = cellValue.split(" ")[0].split("-");
				// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
				// 			var cellDate = new Date(cellValue);
				// 			if(filteredDate.getMonth() < 10){
				// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
				// 			}
				// 			else{
				// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
				// 			}
				// 			if(filterValue.split("-")[2].length < 2)
				// 			{
				// 				var temp = filterValue;
				// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
				// 			}
				// 			var filteredDate = new Date(filterValue);
				// 			cellDate = cellDate.getTime();
				// 			filteredDate = filteredDate.getTime();
				// 			// console.log(cellDate,filteredDate);
				// 			if(cellDate === filteredDate)
				// 			{
				// 				return 0;
				// 			}
				// 			if(cellDate < filteredDate){
				// 				return -1;
				// 			}
				// 			if(cellDate > filteredDate)
				// 			{
				// 				return 1;
				// 			}
				// 		}
				// 	}
				// },

			},

			{
				headerName: "Geofence Out",
				field: "first_outside_fence",
				width: 200,
				filter: true,
				resizable: true,
				valueGetter: function (params) {
					return getHyphenDDMMMYYYYHHMM(params.data.first_outside_fence)
				},
				//filter: "agDateColumnFilter",
				comparator: dateComparator,
				// filterParams: {
				// 	browserDatePicker: true,
				// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
				// 	// provide comparator function
				// 	comparator: function(filteredDate,cellValue,secondDate) {
				// 		cellValue = cellValue.replace(/\//g,"-")
				// 		if(cellValue != "" && cellValue != " "){
				// 			cellValue = cellValue.split(" ")[0].split("-");
				// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
				// 			var cellDate = new Date(cellValue);
				// 			if(filteredDate.getMonth() < 10){
				// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
				// 			}
				// 			else{
				// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
				// 			}
				// 			if(filterValue.split("-")[2].length < 2)
				// 			{
				// 				var temp = filterValue;
				// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
				// 			}
				// 			var filteredDate = new Date(filterValue);
				// 			cellDate = cellDate.getTime();
				// 			filteredDate = filteredDate.getTime();
				// 			// console.log(cellDate,filteredDate);
				// 			if(cellDate === filteredDate)
				// 			{
				// 				return 0;
				// 			}
				// 			if(cellDate < filteredDate){
				// 				return -1;
				// 			}
				// 			if(cellDate > filteredDate)
				// 			{
				// 				return 1;
				// 			}
				// 		}
				// 	}
				// },
			},

			{
				headerName: "Time Spent",
				field: "time_spent",
				width: 200,
				filter: true,
				resizable: true,
				cellRenderer: param => {
					if (param.data.time_spent == "NaN Hr(s) NaN Min(s)") {
						return ""
					}
					return param.data.time_spent
				}
				// //filter: "agDateColumnFilter",
				// comparator: dateComparator,
				// filterParams: {
				// 	browserDatePicker: true,
				// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
				// 	// provide comparator function
				// 	comparator: function(filteredDate,cellValue,secondDate) {
				//		cellValue = cellValue.replace(/\//g,"-")
				// 		if(cellValue != "" && cellValue != " "){
				// 			cellValue = cellValue.split(" ")[0].split("-");
				// 			cellValue = cellValue[2]+"-"+cellValue[1]+"-"+cellValue[0];
				// 			var cellDate = new Date(cellValue);
				// 			if(filteredDate.getMonth() < 10){
				// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
				// 			}
				// 			else{
				// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
				// 			}
				// 			var filteredDate = new Date(filterValue);
				// 			cellDate = cellDate.getTime();
				// 			filteredDate = filteredDate.getTime();
				// 			// console.log(cellDate,filteredDate);
				// 			if(cellDate === filteredDate)
				// 			{
				// 				return 0;
				// 			}
				// 			if(cellDate < filteredDate){
				// 				return -1;
				// 			}
				// 			if(cellDate > filteredDate)
				// 			{
				// 				return 1;
				// 			}
				// 		}
				// 	}
				// },
			},

		];
		const dStyles = {
			width: this.props.width,
			height: '500px',
			marginLeft: this.props.marginLeft,

		}

		return (
			<div className="container-fluid">
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					onConfirm={this.closeAlert}
				>
				</SweetAlert>
				{this.state.alert}
				            <div className="mb-15p fbold f20">
				               <h5>
									<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.pageTitle} </span>
								</h5>
							</div>	
				<div className="row">
					<div className="col-xl-12 col-lg-12">
						<div className="card">
							{/* <div className="card-header">
								<h5>
									<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.pageTitle} </span>
								</h5> */}
							{/* </div> */}
							<div className="card-body">
								<div className="row col-xl-12 col-lg-12">
									<form className="row theme-form col-xl-12 col-lg-12" onSubmit={this.formHandler}>								
										<div className="col-xl-2 col-lg-2 form-group">
											<select name="fence_name" className="form-control" placeholder='Plants' onChange={this.handlerForm.bind(this)} required>
												<option value="0">All</option>
												{this.selectOptionsItems()}
											</select>
										</div>
										<div className="col-xl-2 col-lg-2 form-group">
											{/* <select name="truck_no" className="form-control" placeholder='Truck Number' onChange={this.handlerForm.bind(this)} required>
												<option value="0">Trucks</option>
												{this.selectTruckItems()}
											</select> */}
											<input type="text" name="truck_no" className="form-control" placeholder='Vin Number' onChange={this.handlerForm.bind(this)} />
										</div>
										<div className="col-xl-2 col-lg-2 form-group">
											{/* <Datetime value={this.state.startDate} inputProps={{ placeholder: 'Start Date', name: 'startDate', autoComplete:'off', required:true }} dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss"  name="startDate" onChange={this.handlerStartDateTime.bind(this)} /> */}
											<input type="text" name="startDate" autoComplete="Off" id="startDate" placeholder="Start Date" className="datepicker form-control" value={this.state.startDate} onChange={this.handlerStartDateTime.bind(this)} />
										</div>

										<div className="col-xl-2 col-lg-2 form-group">
											{/* <Datetime value={this.state.endDate} inputProps={{ placeholder: 'End Date', name: 'endDate`', autoComplete:'off', required:true }} dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss" onChange={this.handlerEndDateTime.bind(this)} /> */}
											<input type="text" name="endDate" autoComplete="Off" id="endDate" placeholder="End Date" className="datepicker form-control" value={this.state.endDate} onChange={this.handlerEndDateTime.bind(this)} />
										</div>

										<div className="col-xl-2 col-lg-2 form-group">
											<button type="submit" className="btn btn-success">Get Data</button>
										</div>
									</form>
								</div>
								<div className="row" style={{textAlign:'right'}}>
                                    <div className="col-sm-12">
                                        <button className="float-right custom-btn white btn-danger" onClick={this.onClickSaveGridState.bind(this)}>
                                            <i className="icofont icofont-save"></i> Save Grid Layout
                                        </button>
                                        <button className="float-right custom-btn white btn-info" style={{marginRight:"10px"}} onClick={this.resetState.bind(this)}>
                                            <i className="icofont icofont-refresh"></i> Reset Default Layout
                                        </button>
                                    </div>
                                </div>
								<div id="myGrid" style={{ height: "580px", width: "100%" }} className="ag-theme-balham">
									<AgGridReact
										modules={this.state.modules}
										columnDefs={columnwithDefs}
										defaultColDef={this.state.defaultColDef}
										rowData={this.state.rowData}
										enableCharts={true}
										enableRangeSelection={true}
										autoGroupColumnDef={this.state.autoGroupColumnDef}
										onGridReady={this.onGridReady}
										onGridState={this.onGridState}
										statusBar={this.state.statusBar}
										sideBar={this.state.sideBar}
										paginationPageSize={this.state.paginationPageSize}
										pagination={true}
										frameworkComponents={this.state.frameworkComponents}
										detailCellRendererParams={this.state.detailCellRendererParams}
										//editType={this.state.editType}
										stopEditingWhenGridLosesFocus={true}
										//floatingFilter={true}
										//enableCellChangeFlash={true}
										suppressCellFlash={true}
										rowClassRules={this.state.rowClassRules}
										//onCellClicked={this.onRowClicked.bind(this)}
										gridOptions={{
											context: { componentParent: this }
										}}
										masterDetail={true}
										overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
									/>

									<div className={"dataLoadpage " + (this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " + (this.state.loadshow)}>
										<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
									</div>

								</div>
							</div>
						</div>
					</div>

				</div>

				{this.state.sliderRouteTranslate != '' ?
					<div className={"slide-r " + (this.state.sliderRouteTranslate)} style={{ overflow: "auto" }}>
						<div className="slide-r-title">
							<h4>
								{(this.state.consignment_code)}
								<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onCloseRouteDiv.bind(this)} >X</span>

							</h4>
						</div>
						<div className="slide-r-body" style={{ position: "relative" }}>

							<div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
								<DrawMap mapFor={"geofence"} truckno={this.state.maptruckno} mapinfo={this.state.mapinfo} defTransitCoords={this.state.defTransitCoords} />
								<div className="col-xl-12 col-lg-12 n-p-0">
									<div className="crm-numbers pb-0">
										<h3 className="subH">{this.state.sidebarSubHeader}</h3>
										<div className="col-xl-12 col-lg-12 row">
											<div className="col route-block">
												<label className="sidebar-label">Vin No</label>
												<div>{this.state.routeTruck.truck_no}</div>
											</div>
											{/* <div className="col route-block">
												<label className="sidebar-label">Transporter</label>
												<div>{(!isEmpty(maptransporter))?maptransporter:""}</div>
											</div> */}
											<div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(this.state.routeTruck.startTime != '') ? getDDMMYYYYHHMMSS(this.state.routeTruck.startTime) : "NA"}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">End Time</label>
												<div>{(this.state.routeTruck.endTime != '') ? getDDMMYYYYHHMMSS(this.state.routeTruck.endTime) : "NA"}</div>
											</div>

											<div className="col route-block">
												<label className="sidebar-label">Distance</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details)) ? Math.round(this.state.mapinfo.route_details.distance / 1000) : "0"} Kms</div>
											</div>
											{/*<div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.start_time):"-"}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">End Time</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.end_time):"-"}</div>
											</div>
										*/}

										</div>
									</div>
								</div>
							</div>
						</div>

					</div>
					: ""
				}

				<div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>


				<span className="usermanual f22" onClick={this.onClickShowUserManaul.bind(this)}><i className="icofont icofont-read-book-alt"></i></span>
				<Modal open={usermanualmodal} onClose={this.onCloseUserManualModal.bind(this)} styles={modalStyles}>
					<div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
						<h5 className="model-title">Geofence Incursions</h5>
						<div className="col-xl-12 col-lg-12 mt-20p">
							<div className="col-xl-12 col-lg-12">
								This utility is available for SND and PRT department which provides data of list of vehicles which enters and exits for the first time within the selected geofence. The list of geofences are the fixed MSIL locations and not the dealer locations.
							</div>
							<div className="col-xl-12 col-lg-12">
								The user needs to select the location and time frame for which incursions are needed. For selected date and geofence, list of all vehicles will be displayed which have entered that particular location first time and exited that location.
							</div>
							<div className="col-xl-12 col-lg-12">
								Image here
							</div>
						</div>
					</div>
				</Modal>

			</div>

		);
	}
}

function isEmpty(obj) {
	for (var key in obj) {
		if (obj.hasOwnProperty(key))
			return false;
	}
	return true;
}
function timeConvert(n) {
	var num = n;
	var hours = (num / (3600));
	var rhours = parseInt(hours);
	var minutes = (num - (rhours * 3600)) / (60);
	var rminutes = Math.round(minutes);
	return rhours + " hour(s) and " + rminutes + " minute(s).";
}
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
		// console.log(date1,date2);
		var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
		date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
		var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
		date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
		// console.log(date1,date2);
		var date1Number = monthToComparableNumber(date1);
		var date2Number = monthToComparableNumber(date2);
		// console.log(date1Number,date2Number);
		if (date1Number === null && date2Number === null) {
			return 0;
		}
		if (date1Number === null) {
			return -1;
		}
		if (date2Number === null) {
			return 1;
		}
		return date1Number - date2Number;
	}
}
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	// console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName) {
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
	];
	var monthNum = months.indexOf(monthName) + 1;
	if (monthNum < 10) {
		monthNum = "0" + monthNum.toString();
	}
	return monthNum;
}
function loadDatepicker() {
	$(".datepicker").datepicker({
		dateFormat: "yy-mm-dd",
		changeMonth: true,
		changeYear: true
	});

};
