import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Redirect } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import CSVFileValidator from 'csv-file-validator'
import Select from "react-select";
import {getHyphenDDMMMYYYYHHMM} from '../common/utils';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';

import ViewDoc from "./viewdocbtn";
import ViewDoc2Btn  from "./viewdoc2";
// import UploadPOD from "./uploadInvoice";
$.datetimepicker.setLocale('en');
var infoBox = require('../common/google-infowindow');

var Constant = require('../common/Constant');
var redirectURL = require('../redirectURL');
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");
const decipher = salt => {
	const textToChars = text => text.split('').map(c => c.charCodeAt(0));
	const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
	return encoded => encoded.match(/.{1,2}/g)
		.map(hex => parseInt(hex, 16))
		.map(applySaltToChar)
		.map(charCode => String.fromCharCode(charCode))
		.join('');
}
var decode = decipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

export default class EPODItems extends Component {

	constructor(props) {
		super(props);
		this.state = {
			deptcode: "",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
            modules: AllModules,
			pivotmodeis: false,
			pivotcols: [],
			defaultColDef: {
				sortable: true,
				filter: true,
				resizable: true,
				pivot: true,
				enableValue: true,
				enableRowGroup: true,
			},
			rowData: [],
			maprowData: null,
			rowSelection: 'multiple',
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			overlayNoRowsTemplate: "",
			frameworkComponents: {
                // UploadPOD:UploadPOD
                ViewDoc:ViewDoc,
                ViewDoc2Btn:ViewDoc2Btn
            },

			animateRows: true,
			debug: true,
			showToolPanel: false,
			uppressAggFuncInHeader: true,

			childconfs: [],
			childrow: [],
			rowModelType: "serverSide",
			paginationPageSize: 100,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
						statusPanel: "agTotalAndFilteredRowCountComponent",
						align: "left"
					},
					{
						statusPanel: "agTotalRowCountComponent",
						align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
						id: "columns",
						labelDefault: "Columns",
						labelKey: "columns",
						iconKey: "columns",
						toolPanel: "agColumnsToolPanel"
					},
					{
						id: "filters",
						labelDefault: "Filters",
						labelKey: "filters",
						iconKey: "filter",
						toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams: {},
			rowClassRules: {
				//"highlitRow": "data.transit_delay == 1",
				//"green":"data.status >= 3"

			},
            show:false,
            basicTitle:"",
            basicType:"default",
            loadshow:"show-n",
            overly:"show-n",            
			sliderAddTranslate:'',
            customer_pod_date:"",
            customer_documents:"",
            customer_pod_remarks:"",
            item_code:{"value":"","label":"Select Item"},
            item_description:"",
            defect_description:"",
            defect_code:{"value":"","label":"Select Defect"},
            category:{"value":"","label":"Select Category"},
            quantity:"",
            itemslist:[],
            itemcode:"",
            defectlist:[],
            defectcode:"",
            categorylist:[],
            categorycode:"",
            customer_code:"",
            invoice_no:"",
            rownode:[],
            customer_document_1:"",
            customer_document_2:"",
            material_no:"",
            material_description:"",
            supply_plant_desc:""
        }
        this.changeHandler = this.changeHandler.bind(this);
        this.changeQtyHandler = this.changeQtyHandler.bind(this);
        this.changeFileHandler = this.changeFileHandler.bind(this);
        this.changeFileHandlerDoc = this.changeFileHandlerDoc.bind(this);
        this.saveItemsFormData = this.saveItemsFormData.bind(this);
    }
    componentDidMount(){
        loadDateTimeScript();
        var invoice_no = this.props.match.params.invoice_no;
        redirectURL.post("/dashboard/podcodes")
        .then((response) => {
            var itemslist = response.data.items;
            var defectlist = response.data.defects;
            var categories = response.data.categories;
            this.setState({
                itemslist:itemslist,
                defectlist:defectlist,
                categorylist:categories
            })
        })
        if(localStorage.getItem("customer_code") != "" && localStorage.getItem("customer_code") != undefined && localStorage.getItem("customer_code") != "undefined")
        {
            var customer_code = localStorage.getItem("customer_code");
        }
        else{
            var customer_code = "";
        }
        this.setState({
            customer_code:customer_code
        })
        var parameters = {
            dealer_code:customer_code,
            invoice_no:invoice_no
        }
        this.onLoadGetShipments(parameters);

    }
    onLoadGetShipments(parameters){
        this.setState({
            loadshow:"show-m"
        })
      
        redirectURL.post("/dashboard/customerconsignments",parameters)
        .then((response) => {
            if(response.data.status == "success")
            {
                var records = response.data.records;
                // console.log("records ", records)
                var rowData=[];
                if(records.length > 0)
                {
                    try{
                        var pods = records[0].pods;
                        if(pods.length > 0)
                        {
                            pods.map((itm) => {
                                itm.customer_pod_date = moment.parseZone(itm.customer_pod_date).format("DD-MM-YYYY HH:mm");
                                itm.truck_no = records[0].truck_no;
                                rowData.push(itm);
                            })
                        }
                    }
                    catch(e){

                    }
                    
                }
                console.log("rowData ", rowData)
                this.setState({
                    rownode:records,
                    rowData:rowData,
                    loadshow:"show-n",
                    overly:"show-n"
                })
            }
            
        });

    }
    closeAlert = async () => {
        await this.setState({
            show: false
        });
        // await window.location.reload();
    }
    onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
        
        this.gridApi.addEventListener('paginationChanged', (e) =>
        {
            //Reset rows selection based on current page
           // this.resetPaginationSelection(this);
        });

        this.gridApi.forEachLeafNode( (node) => {
            // console.log("node here ",node)
            node.setSelected(true);
         
        });
        
	};
	

	onGridState = () =>{
		 console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
           this.gridApi.setFilterModel(window.filterState);
           

           
        
        //
    }
   
    onCloseRouteDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderRouteTranslate:"",
			sliderCommentTranslate : "",
			sliderSegmentTranslate : "",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
			overly:'show-n'
		});
	}
    onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			slideuploadeway:'',
			sliderAddTranslate:'',
		});
		
	}
    
    onClicCloseCounterBlock(){
        this.setState({
            showmys:"show-n",
            showdsc:"show-n",
            showcrm:"show-n",
            showplatemill:"show-n",
            showpipemill:"show-n",
        })
    }
    onClickUpdateItem(){
        this.setState({
			sliderAddTranslate:"slider-translate",
			overly:'show-m'
            
        })
    }
    
    changeHandler(event){
        var name= event.target.name;
        var value = event.target.value;
        this.setState({
            [name]:value
        })
    }
    changeQtyHandler(event){
        var name= event.target.name;
        var value = event.target.value;
        if(isNaN(value) == false)
        {
            if(value[0] != "0" && value[0] != 0 && value[0] != " " && value[0] != null)
            {
                this.setState({
                    [name]:value
                })
            }
            else{
                this.setState({
                    [name]:""
                })
            }
        }
        else{
            
            this.setState({
                [name]:""
            })
        }
    }
    itemslist(){
        var items = [];
        var itemcodes = this.state.itemslist;
        if(itemcodes.length > 0)
        {
            itemcodes.map((item) => {
                items.push({
                    value:item.item_code,
                    label:item.item_description
                })
            })
        }
        return items;
    }

    changeItemCode(itemcode){
       this.setState({itemcode})
    }
    
    defectlist(){
        var items = [];
        var defectcodes = this.state.defectlist;
        if(defectcodes.length > 0)
        {
            defectcodes.map((item) => {
                items.push({
                    value:item.defect_code,
                    label:item.defect_description
                })
            })
        }
        return items;
    }
    changeDefectCode(defectcode){
       this.setState({defectcode})
    }
    categorylist(){
        var items = [];
        var categorylist = this.state.categorylist;
        if(categorylist.length > 0)
        {
            categorylist.map((item) => {
                items.push({
                    value:item.category_code,
                    label:item.category_description
                })
            })
        }
        return items;
    }
    changeCategoryCode(category){
       this.setState({category},()=>{
        console.log('category ', this.state.category)
       })
    }
    
    changeFileHandler(event) {
        console.log("event.target.files[0] ", event.target.files)
        try{
            // if(event.target.files[0].size <= 102400){
                this.setState({
                    customer_document_1: event.target.files[0]
                })
            // }else {
            //     this.setState({
            //         show: true,
            //         basicTitle: "File Size exceeds 100kb",
            //         basicType: "info",
            //         customer_lr_doc: ""
            //     })
            //     $("#customer_lr_doc").val("")
            // }
            
            
        }
        catch(e){
            this.setState({
                customer_document_1: ""
            })
            $("#customer_document_1").val("")
        }
        
    }
    
    changeFileHandlerDoc(event) {
        // console.log("event.target.files[0] ", event.target.files)
        try{
            // if(event.target.files[0].size <= 102400){
                this.setState({
                    customer_document_2: event.target.files[0]
                })
            // }else {
            //     this.setState({
            //         show: true,
            //         basicTitle: "File Size exceeds 100kb",
            //         basicType: "info",
            //         customer_lr_doc: ""
            //     })
            //     $("#customer_lr_doc").val("")
            // }
            
            
        }
        catch(e){
            this.setState({
                customer_document_2: ""
            })
            $("#customer_document_2").val("")
        }
        
    }
    saveItemsFormDataOld(event){
        event.preventDefault();
        var rownodes=[];
        this.gridApi.forEachNode(function(node) { 
            rownodes.push(node.data);
        });
        console.log("rownodes ", rownodes);
        // console.log("this.state.customer_lr_doc ", this.state.customer_lr_doc)
        var customer_document_1 = this.state.customer_document_1;
        var customer_document_2 = this.state.customer_document_2;
        if(this.state.rownode.length > 0)
        {
            var truck_no = this.state.rownode[0].truck_no;
            var invoice_no = this.state.rownode[0].invoice_no; 
        }
        else{
            var truck_no = '';
            var invoice_no = '';
        }
        const formData = new FormData();
        formData.append("customer_document_1", customer_document_1);
        formData.append("customer_document_2", customer_document_2);
        formData.append("customer_code", this.state.customer_code);
        formData.append("invoice_no", invoice_no);
        formData.append("truck_no", truck_no);
        formData.append("rownodes", JSON.stringify(rownodes));
        
        console.log("formData ",formData)

        redirectURL.post("/dashboard/saveInvoiceDocumentForm", formData, {
            headers: {
                'content-type': 'multipart/form-data',
                'Access-Control-Allow-Origin': "*",
                'Access-Control-Allow-Methods': 'POST, OPTIONS',
                'Access-Control-Allow-Headers': 'Origin, Content-Type'
            }
        })
        .then((response) => {
            // console.log("response ", response.data)
            if (response.data.status == "success") {
                this.setState({
                    show: true,
                    basicTitle: "Successfully update data",
                    basicType: "success",
                    customer_pod_date:"",
                    customer_lr_doc:"",
                    customer_pod_remarks:"",
                    itemcode:{"value":"","label":"Select Item"},
                    item_description:"",
                    defect_description:"",
                    defectcode:{"value":"","label":"Select Defect"},
                    category:{"value":"","label":"Select Category"},
                    quantity:"",
                    customer_document_1:"",
                    customer_document_2:""
                })
                $("#customer_pod_date").val("");
            }
            else {
                this.setState({
                    show: true,
                    basicTitle: response.data.message,
                    basicType: "danger"
                })
            }
        })
        .catch(error => {
            this.setState({
                show: true,
                basicTitle: "File size is too large.",
                basicType: "warning"
            })
        })
    
    }

    saveItemsFormData(event){
        event.preventDefault();
        // var rownodes=[];
        // this.gridApi.forEachNode(function(node) { 
        //     rownodes.push(node.data);
        // });
        // console.log("rownodes ", rownodes);

        var customer_pod_date = $("#customer_pod_date").val();
        var customer_pod_remarks = this.state.customer_pod_remarks;
        var material_no = this.state.material_no;
        var material_description = this.state.material_description;
        var supply_plant_desc = this.state.supply_plant_desc;
        if(this.state.itemcode.value != "")
        {
            var itemcode = this.state.itemcode.value;
            var itemdesc = this.state.itemcode.label;
        }
        else{
            var itemcode = "";
            var itemdesc = "";
        }
        if(this.state.defectcode.value != "")
        {
            var defectcode = this.state.defectcode.value;
            var defectdesc = this.state.defectcode.label;
        }
        else{
            var defectcode = "";
            var defectdesc = "";
        }
        if(this.state.category.value != "")
        {
            var category = this.state.category.value;
        }
        else{
            var category = "";
        }
        var quantity = this.state.quantity;
        if(itemcode != "" && defectcode != "" && customer_pod_date != ""  && customer_pod_date != "" && category != "" && quantity != "") 
        {
            var newrow = {
                material_no:material_no,
                material_description:material_description,
                supply_plant_desc:supply_plant_desc,
                customer_pod_date:customer_pod_date,
                item_code:itemcode,
                item_description:itemdesc,
                defect_code:defectcode,
                defect_description:defectdesc,
                category:category,
                quantity:quantity,
                customer_pod_remarks:customer_pod_remarks,
                }
            // console.log("this.state.customer_lr_doc ", this.state.customer_lr_doc)
            var customer_document_1 = this.state.customer_document_1;
            var customer_document_2 = this.state.customer_document_2;
            if(this.state.rownode.length > 0)
            {
                var truck_no = this.state.rownode[0].truck_no;
                var invoice_no = this.state.rownode[0].invoice_no; 
            }
            else{
                var truck_no = '';
                var invoice_no = '';
            }
            const formData = new FormData();
            formData.append("customer_document_1", customer_document_1);
            formData.append("customer_document_2", customer_document_2);
            formData.append("customer_code", this.state.customer_code);
            formData.append("invoice_no", invoice_no);
            formData.append("truck_no", truck_no);
            formData.append("rownodes", JSON.stringify([newrow]));
            
            // console.log("formData ",formData)

            redirectURL.post("/dashboard/saveInvoiceDocumentForm", formData, {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Access-Control-Allow-Origin': "*",
                    'Access-Control-Allow-Methods': 'POST, OPTIONS',
                    'Access-Control-Allow-Headers': 'Origin, Content-Type'
                }
            })
            .then((response) => {
                // console.log("response ", response.data)
                if (response.data.status == "success") {
                    this.setState({
                        show: true,
                        basicTitle: "Successfully update data",
                        basicType: "success",
                        customer_pod_date:"",
                        customer_lr_doc:"",
                        customer_pod_remarks:"",
                        itemcode:{"value":"","label":"Select Item"},
                        item_description:"",
                        defect_description:"",
                        defectcode:{"value":"","label":"Select Defect"},
                        category:{"value":"","label":"Select Category"},
                        quantity:"",
                        customer_document_1:"",
                        customer_document_2:"",
                        sliderAddTranslate:"",
			            overly:'show-n',
                        material_no:"",
                        material_description:"",
                        supply_plant_desc:""
                    })
                    $("#customer_pod_date").val("");
                    window.location.reload();
                }
                else {
                    this.setState({
                        show: true,
                        basicTitle: response.data.message,
                        basicType: "danger"
                    })
                }
            })
            .catch(error => {
                this.setState({
                    show: true,
                    basicTitle: "File size is too large.",
                    basicType: "warning"
                })
            })
        }
    
    }
    addItemData(event)
    {
        event.preventDefault();
        var customer_pod_date = $("#customer_pod_date").val();
        var customer_pod_remarks = this.state.customer_pod_remarks;

        if(this.state.itemcode.value != "")
        {
            var itemcode = this.state.itemcode.value;
            var itemdesc = this.state.itemcode.label;
        }
        else{
            var itemcode = "";
            var itemdesc = "";
        }
        if(this.state.defectcode.value != "")
        {
            var defectcode = this.state.defectcode.value;
            var defectdesc = this.state.defectcode.label;
        }
        else{
            var defectcode = "";
            var defectdesc = "";
        }
        if(this.state.category.value != "")
        {
            var category = this.state.category.value;
        }
        else{
            var category = "";
        }
        var quantity = this.state.quantity;
        if(itemcode != "" && defectcode != "" && customer_pod_date != ""  && customer_pod_date != "" && category != "" && quantity != "") 
        {
            var newrow = {
                customer_pod_date:customer_pod_date,
                item_code:itemcode,
                item_description:itemdesc,
                defect_code:defectcode,
                defect_description:defectdesc,
                category:category,
                quantity:quantity,
                customer_pod_remarks:customer_pod_remarks,
            }
            console.log("updateRowData ",newrow);

            // this.gridApi.updateRowData({add: [newrow]})
            // this.setState({
            //     customer_pod_date:"",
            //     customer_lr_doc:"",
            //     customer_pod_remarks:"",
            //     itemcode:{"value":"","label":"Select Item"},
            //     item_description:"",
            //     defect_description:"",
            //     defectcode:{"value":"","label":"Select Defect"},
            //     category:{"value":"","label":"Select Category"},
            //     quantity:"",            
            //     // sliderAddTranslate:"",
            //     // overly:'show-n'
            // })
            // $("#customer_pod_date").val("");
        }   
        else{
            this.setState({
                show:true,
                basicTitle:"All * fields are mandatory",
                basicType:"danger"
            })
        }
    }
    
    render(){
        if(this.props.match.params.invoice_no == "" || this.props.match.params.invoice_no == undefined || this.props.match.params.invoice_no == "0")
        {
            return <Redirect to={"/epod"} push={true} />
        }
        var maptransporter='';
		
        var columnwithDefs = [
            // {
            //     headerName: "",
            //     field: "_id",
            //     width: 100,
            //     pinned: 'left',
            //     cellRenderer:'ViewShipmentData',
            //     filter: false,resizable: true
                
            // },
            {
                headerName:"Invoice No",
                field:"invoice_no",
                width:120,
            },
            {
                headerName:"Material No",
                field:"material_no",
                width:120,
            },
            {
                headerName:"Material Description",
                field:"material_desc",
                width:180,
            },
            
            {
                headerName:"Supplying Plant Description",
                field:"supply_plant_desc",
                width:200,
            },
            {
                headerName:"Invoice Date",
                field:"invoice_date",
                width:120,
                valueGetter:function(params){
                    try{
                        if(params.data.invoice_date != "" && params.data.invoice_date != undefined)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.invoice_date);
                        }
                        else{
                            return "";
                        }
                    }
                    catch(e){

                    }
                }
            },
            {
                headerName:"POD Date",
                field:"customer_pod_date",
                width:120
            },
            {
                headerName:"Vin No",
                field:"truck_no",
                width:120,
            },
            {
                headerName:"Item Code",
                field:"item_code",
                width:120,
            },
           
            {
                headerName:"Item Description",
                field:"item_description",
                width:120,
            },
           
            {
                headerName:"Defect Code",
                field:"defect_code",
                width:120,
            },
           
            {
                headerName:"Defect Description",
                field:"defect_description",
                width:120,
            },
           
            {
                headerName:"Category",
                field:"category",
                width:120,
            },
           
            {
                headerName:"Quantity",
                field:"quantity",
                width:120,
            },
            {
                headerName:"Remarks",
                field:"customer_pod_remarks",
                width:120,
            },
            {
                headerName:"Document 1",
                field:"invoice_document_1",
                width:200,
                cellRenderer:'ViewDoc'
            },
            {
                headerName:"Document 2",
                field:"invoice_document_2",
                width:200,
                cellRenderer:'ViewDoc2Btn'
            }
           
            
            
        ]
        return (
            <div class="container-fluid">          
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
              
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>
                                    <span>View/Add POD Items</span>
                                    <a href={"/epod"} className="btn btn-primary float-right">Back</a>
                                    <button type="button" className="btn btn-warning float-right" onClick={this.onClickUpdateItem.bind(this)}>Add Item</button>
                                </h5>
                            </div>
                            <div className="card-body pt-15px">
                                <form  id="upform" className="theme-form" onSubmit={this.saveItemsFormData}>
                                {this.state.rownode.length > 0?
                                <div  className="row">
                                    <div className="col-sm-6 fbold">
                                        Invoice No: {this.state.rownode[0].invoice_no}
                                    </div>
                                    <div className="col-sm-6 fbold">
                                        Invoice Date: {(this.state.rownode[0].invoice_date != "" && this.state.rownode[0].invoice_date != undefined)?getHyphenDDMMMYYYYHHMM(this.state.rownode[0].invoice_date):""}
                                    </div>
                                    
                                    <div className="col-sm-6 mt-20p fbold">
                                        Vin No: {this.state.rownode[0].truck_no}
                                    </div>

                                    <div className="col-sm-6 mt-20p fbold">
                                        Reached Destination At: {getHyphenDDMMMYYYYHHMM(this.state.rownode[0].recent_dealer_reported)}
                                    </div>
                                                                        
                                    <div id="myGrid" style={{marginTop:"20px", height: "480px",width: "100%"}}  className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        enableCharts={true}
                                        enableRangeSelection={true}
                                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        frameworkComponents={this.state.frameworkComponents}
                                        detailCellRendererParams={this.state.detailCellRendererParams}
                                        stopEditingWhenGridLosesFocus={true}
                                        enableCellChangeFlash={false}
                                        rowClassRules={this.state.rowClassRules}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        masterDetail={true}
                                        rowSelection={this.state.rowSelection}
                                        suppressRowClickSelection={true}
                                        onPaginationChanged={this.resetPaginationSelection}
                                        
                                    />
                                        
                                    
                                    </div> 
{/*                                     
                                    <div className="form-group col-sm-3">
                                        <label className="">Upload Document - 1</label> 
                                        <input type="file" name="customer_doc_1" id="customer_doc_1" onChange={this.changeFileHandler} className="form-control" required /><br /><br />
                                        {(this.state.rownode[0].invoice_document_1 != "" && this.state.rownode[0].invoice_document_1 != undefined)?
                                        <a target="_blank" href={this.state.rownode[0].invoice_document_1}>View Invoice Document</a>:""}
                                    </div>
                                    <div className="form-group col-sm-3">
                                        <label className="">Upload Document - 2</label> 
                                        <input type="file" name="customer_doc_2" id="customer_doc_2" onChange={this.changeFileHandlerDoc} className="form-control" required /><br /><br />
                                        {(this.state.rownode[0].invoice_document_2 != "" && this.state.rownode[0].invoice_document_2 != undefined)?
                                        <a target="_blank" href={this.state.rownode[0].invoice_document_2}>View Invoice Document</a>:""}
                                    </div> */}
                                    
                                    {/* <div className="form-group col-sm-4">
                                        <button type="submit" className="mt-30p btn btn-success">Submit</button>
                                    </div> */}
                                </div>
                                :""}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"dataLoadpage " +(this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div className="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            

                <div className={"slide-r "+(this.state.sliderAddTranslate)} >
                    
                    <div className="slide-r-title">
                        <h4>
                            Add Items
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className={"dataLoad " +(this.state.loadshow)}>
                            <div style={{position:"absolute", left:"38%"}}>
                                <div class="loader-box">
                                    <div class="loader-box">
                                        <div class="rotate dashed colored"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-lg-12">
                            <form id="upform" className="theme-form" onSubmit={this.saveItemsFormData}>

                                <div className="form-group mt-20p">
                                    <label className="">Material No <span style={{color:"#ff0000"}}>*</span></label> 
                                    <input type="text" name="material_no" id="material_no" value={this.state.material_no} onChange={this.changeHandler} className="form-control" required  />
                                </div>
                                <div className="form-group mt-20p">
                                    <label className="">Material Description <span style={{color:"#ff0000"}}>*</span></label> 
                                    <textarea name="material_description" id="material_description" rows="3"  value={this.state.material_description} onChange={this.changeHandler} className="form-control" ></textarea>
                                </div>
                                <div className="form-group mt-20p">
                                    <label className="">Supplying Plant Description <span style={{color:"#ff0000"}}>*</span></label> 
                                    <textarea name="supply_plant_desc" id="supply_plant_desc" rows="3"  value={this.state.supply_plant_desc} onChange={this.changeHandler} className="form-control" ></textarea>
                                </div>
                                
                                <div className="form-group mt-20p">
                                    <label className="">POD Date <span style={{color:"#ff0000"}}>*</span></label> 
                                    <input type="text" name="customer_pod_date" id="customer_pod_date" className="datetimepicker_mask form-control" required  />
                                </div>

                                <div className="form-group mt-20p">
                                    <label className="">Item <span style={{color:"#ff0000"}}>*</span></label> 
                                    <Select 
                                        placeholder={"Select Item"}
                                        closeMenuOnSelect={true}
                                        isMulti={false}
                                        value={this.state.itemcode}
                                        onChange={this.changeItemCode.bind(this)} 
                                        className={"border-radius-0"}
                                        style={{borderRadius:"0px"}}
                                        options={this.itemslist()}
                                    />
                                </div>
{/*                                 
                                <div className="form-group mt-20p">
                                    <label className="">Item Description</label> 
                                    <input type="text" name="item_description" id="item_description" value={this.state.item_description} className="form-control" readOnly  />
                                </div> */}
                                
                                <div className="form-group mt-20p">
                                    <label className="">Defect <span style={{color:"#ff0000"}}>*</span></label> 
                                    <Select 
                                        placeholder={"Select Defect"}
                                        closeMenuOnSelect={true}
                                        isMulti={false}
                                        value={this.state.defectcode}
                                        onChange={this.changeDefectCode.bind(this)} 
                                        className={"border-radius-0"}
                                        style={{borderRadius:"0px"}}
                                        options={this.defectlist()}
                                    />
                                </div>
                                
                                {/* <div className="form-group mt-20p">
                                    <label className="">Defect Description</label> 
                                    <input type="text" name="defect_description" id="defect_description" value={this.state.defect_description} className="form-control" readOnly  />
                                </div>                         */}
                                
                                <div className="form-group mt-20p">
                                    <label className="">Category <span style={{color:"#ff0000"}}>*</span></label> 
                                    <Select 
                                        placeholder={"Select Category"}
                                        closeMenuOnSelect={true}
                                        value={this.state.category}
                                        isMulti={false}
                                        onChange={this.changeCategoryCode.bind(this)} 
                                        className={"border-radius-0"}
                                        style={{borderRadius:"0px"}}
                                        options={this.categorylist()}
                                    />
                                </div>
                                
                                <div className="form-group mt-20p">
                                    <label className="">Quantity <span style={{color:"#ff0000"}}>*</span></label> 
                                    <input type="text" name="quantity" id="quantity" value={this.state.quantity} onChange={this.changeQtyHandler} className="form-control" required  />
                                </div>        
                                <div className="form-group mt-20p">
                                    <label className="">Remarks</label> 
                                    <textarea name="customer_pod_remarks" id="customer_pod_remarks" rows="3"  value={this.state.customer_pod_remarks} onChange={this.changeHandler} className="form-control" ></textarea>
                                </div>
                                <div className="form-group mt-20p">
                                    <label className="">Document 1 <span style={{color:"#ff0000"}}>*</span></label> 
                                    <input type="file" name="customer_doc_1" id="customer_doc_1" onChange={this.changeFileHandler} className="form-control" required />
                                </div>    
                                <div className="form-group mt-20p">
                                    <label className="">Document 2 <span style={{color:"#ff0000"}}>*</span></label> 
                                    <input type="file" name="customer_doc_2" id="customer_doc_2" onChange={this.changeFileHandlerDoc} className="form-control" />
                                </div>    
                                
                                <div className="form-group">
                                    <button type="submit" className="btn btn-success">Add Item</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


window.onload = function (){
	// $(".datepicker").datepicker({
    //     dateFormat: "dd-mm-yy",
    //     changeMonth: true,
    //     changeYear: true
	// });
	$('.datetimepicker_mask').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i',
        maxDate:0
	});

	$(".styles_modal__gNwvD").css({width:"450px"})
};
document.addEventListener('turbolinks:before-render', () => {
	loadDateTimeScript() 
  });

function loadDateTimeScript(){

	$('.datetimepicker_mask').datetimepicker({
		//mask:'39-19-9999 29:59',
		format:'d-m-Y H:i',
        maxDate:0
	});
	$('.datetimepicker_date').datetimepicker({
		//mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false,
        maxDate:1
	});
	$('.datetimepicker_time').datetimepicker({
		//mask:'39-19-9999',
		format:'H:i',
        step:1,
		datepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}



function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}       
function GetSortDescOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
}       
function GetSortASCOrder(prop) {    
	return function(a, b) {    
		if (a[prop] > b[prop]) {    
			return 1;    
		} else if (a[prop] < b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 

function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600*24));
    var h = Math.floor(seconds % (3600*24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);
    
    var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay;
}
