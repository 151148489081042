import React, { useEffect, useState } from 'react';
import { GoogleMap, StandaloneSearchBox, Marker, useJsApiLoader } from '@react-google-maps/api';

const MyMapComponent = ({selectedData,onDataChanged}) => {
  const [location, setLocation] = useState(null);
  const [latLng, setLatLng] = useState(null);
  const [searchBox, setSearchBox] = useState(null);
  const [zoom, setZoom] = useState(12)
  useEffect(() => {
    try {
      let data = selectedData
      if(data.location){
        setLocation(data.location)
      }
      if(data.lat && data.lng){
        setLatLng({lat:data.lat,lng:data.lng})
        setZoom(18)
      }
    } catch (e) {
      console.log("Error :", e)
    }
  },[selectedData])

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyD1AFWPoJqPaemwGO4A7P7Q6CsUJ1TLGJk',  // Make sure to replace with your actual API key
    libraries: ['places'],
  });
  const onPlacesChanged = () => {
    try {
      const places = searchBox.getPlaces();
      if (places.length > 0) {
        const place = places[0];
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        setLocation(place.formatted_address);
        setLatLng({ lat, lng });
        setZoom(18)
        onDataChanged({
          location: place.formatted_address,
          lat: lat,
          lng: lng
        })
      }
    } catch (e) {
      console.log(e)
    }
  };
  const handleMapClick = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setLatLng({ lat, lng });  
    // Reverse geocoding to get the address from lat/lng
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: event.latLng }, (results, status) => {
      if (status === 'OK' && results[0]) {
        const formattedAddress = results[0].formatted_address;
        console.log('Geocode successful:', formattedAddress);  // Add log to verify the address
        setLocation(formattedAddress);  
        // Optionally call parent function if needed
        onDataChanged({
          location: formattedAddress,
          lat: lat,
          lng: lng,
        });
      } else {
        onDataChanged({
          location: "",
          lat: lat,
          lng: lng,
        });
        console.error('Geocode failed:', status);  // Log geocoding errors
        setLocation("Location not found");
      }
    });
  };  
  if (!isLoaded) {
    return <div>Loading...</div>;
  }
  return (
    <div style={{ width: "100%", height: "80%", paddingLeft: '20px' }}>
      <div style={{ position: 'absolute', top: '20px', left: '50%', transform: 'translateX(-50%)', zIndex: '10' }}>
        <StandaloneSearchBox
          onLoad={(ref) => setSearchBox(ref)}
          onPlacesChanged={onPlacesChanged}
        >
          <input
            type="text"
            placeholder="Search for a location"
            style={{
              boxSizing: 'border-box',
              border: '1px solid transparent',
              width: '540px',
              height: '38px',
              padding: '0 12px',
              borderRadius: '3px',
              fontSize: '14px',
              outline: 'none',
              textOverflow: 'ellipsis',
            }}
          />
        </StandaloneSearchBox>
      </div>
      <GoogleMap
        id="search-map"
        mapContainerStyle={{
          height: '500px',
          width: '100%',
        }}
        zoom={18}
        center={latLng || { lat: 37.7749, lng: -122.4194 }} // Default to San Francisco
        onClick={handleMapClick} // Handle map click event
      >
        {latLng && (
          <Marker position={latLng} />
        )}
      </GoogleMap>

      {latLng && (
        <div style={{ padding: '14px 0px 14px 0px' }}>
          {/* <h5> <span style={{ fontWeight: 700 }}>Location : </span>{location}</h5> */}
          <p><span style={{ fontWeight: 700 }}>Latitude: </span>{latLng.lat} , <span style={{ fontWeight: 700 }}> Longitude: </span>{latLng.lng} </p>
        </div>
      )}
    </div>
  );
};

export default MyMapComponent;
