/* eslint-disable array-callback-return */
/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import _ from "lodash";
import MarkerClusterer from "@google/markerclusterer";
import Modal from 'react-responsive-modal';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
// //import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import CustomLoadingCellRenderer from "./customLoadingCellRenderer";
import { getDDMMYYYY, getYYYYMMDD, getDDMMYYYYHHMMSS, getHyphenDDMMMYYYYHHMM, getHyphenDDMMMYYYY } from '../common/utils';
import 'react-vertical-timeline-component/style.min.css';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { CsvExportModule } from '@ag-grid-community/csv-export';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { MenuModule } from '@ag-grid-enterprise/menu';
import DrawMap from '../common/drawmap';
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import UpdateAction from '../manage/updateForParkingLot';
import ReportCarrier from "./reportcarrierbtn";
import CalledForLoading from './calledForLoading';
import WithdrawCarrier from "./withdrawalcarrierbtn";
import ParkingoutCarrier from "./parkingcarrierbtn";
import ParkingAddCarrier from "./parkingaddfromgrid";
import CustomTooltip from './parkinglotToolTipComponent'
import ParkingOutReport from "./parkingOutReport";
import ParkingSummaryChart from "./parkingSummaryChart";
import ParkingSummaryChartStack from "./parkingSummaryChartStack";
import ParkingSummaryChartStackTotal from "./parkingSummaryChartStackTotal";
import CountUp from 'react-countup';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
var infoBox = require('../common/google-infowindow');
var googleAnalytics = require("../common/googleanalytics");
var redirectURL = require('../redirectURL');
var moment = require('moment');

var oms = require(`npm-overlapping-marker-spiderfier/lib/oms.min`);

var marker;
var markers = [];
var circle;
var selectlat;
var selectlng
var allcords = [];
var map;
var rad = function (x) {
	return x * Math.PI / 180;
};
var rlatitude;
var rlongitude;

var fencingLength = 0;
var geoFencingArea;


const decipher = salt => {
	const textToChars = text => text.split('').map(c => c.charCodeAt(0));
	const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
	return encoded => encoded.match(/.{1,2}/g)
		.map(hex => parseInt(hex, 16))
		.map(applySaltToChar)
		.map(charCode => String.fromCharCode(charCode))
		.join('');
}
var decode = decipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

// Register the required feature modules with the Grid
ModuleRegistry.registerModules([
	ClientSideRowModelModule,
	CsvExportModule,
	ExcelExportModule,
	MenuModule,
  ]);
export default class ParkingLots extends Component {
	state = {
		pageTitle: "",
		eventGridAction: "gridAction",
		eventCounterAction: "counterAction",
		eventFormAction: "formAction",
		uploadDivWidth: '0%',
		sliderTranslate: '',
		sliderRouteTranslate: '',
		tabsliderTranslate: '',
		loadshow: 'show-n',
		showDiv: 'show-n',
		alertshow: 'fade',
		alertmg: 'show-n',
		alerterrmg: 'show-n',
		overly: 'show-n',
		alerterrshow: 'fade',
		alert: null,
		show: false,
		basicTitle: '',
		basicType: "default",
		show1: false,
		basicTitle1: '',
		basicType1: "default",
		modules: AllModules,
		sidebarSubHeader: '',
		defTransitCoords: '',
		defaultColDef: {
			sortable: true,
			filter: true,
			resizable: true,
			// tooltipComponent: CustomTooltip,
		},
		tooltipShowDelay: 0,
		tooltipHideDelay: 2000,
		rowData: [],
		allrowData: [],
		rowModelhighchartoptionsType: 'enterprise',
		rowGroupPanelShow: 'always',
		overlayNoRowsTemplate: "",
		frameworkComponents: {
			consignmentActions: ConsignmentActions,
			customLoadingCellRenderer: CustomLoadingCellRenderer,
			ReportCarrier: ReportCarrier,
			WithdrawCarrier: WithdrawCarrier,
			ParkingoutCarrier: ParkingoutCarrier,
			ParkingAddCarrier: ParkingAddCarrier,
			updateAction: UpdateAction,
			CalledForLoading: CalledForLoading
		},

		animateRows: true,
		debug: true,
		showToolPanel: false,
		uppressAggFuncInHeader: true,

		childconfs: [],
		childrow: [],
		rowModelType: "serverSide",
		paginationPageSize: 50,
		cacheBlockSize: 10,
		maxBlocksInCache: 1,
		statusBar: {
			statusPanels: [
				{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
				},
				{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
				},
				{ statusPanel: "agFilteredRowCountComponent" },
				{ statusPanel: "agSelectedRowCountComponent" },
				{ statusPanel: "agAggregationComponent" }
			]
		},
		sideBar: {
			toolPanels: [
				{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
				},
				{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
				}
			]
		},
		detailCellRendererParams: {},
		rowClassRules: {
			// "highlitRow": "data.transit_delay == 1",
			// "green":"data.status >= 3"

		},
		fencelist: [],
		startDate: '',
		endDate: '',
		mapinfo: '',
		defaultsdate: '',
		defaultedate: '',
		withdrawmodal: false,
		withdrawmodal1: false,
		reportCarrierOC: false,
		parkinglist: [],
		geofence_name: { "value": "", "label": "Select Parking" },
		parkingyard: "show-n",
		truckinfo: "show-n",
		summary: "show-m",
		parkingyardout: "show-n",
		pact: "btn-defaultc",
		tact: "btn-defaultc",
		sact: "btn-warning",
		poutact: "btn-defaultc",
		truckslist: [],
		truck_no: { "value": "", "label": "Select Truck" },
		dept_code: "",
		transporters: [],
		transporter_code: { "value": "", "label": "Select Transporter" },
		struckslist: [],
		struck_no: { "value": "", "label": "Select Truck" },
		radius: '5000',
		rlatitude: '',
		rlongitude: '',
		latitude: '',
		longitude: '',
		defaultradius: 0,
		coordinates: [],
		search_truck_no: "",
		search_transporter_name: "",
		search_gps_provider: "",
		search_speed: "",
		search_last_packet_time: "",
		search_gps_status: "",
		search_address: "",
		search_city: "",
		search_state: "",
		search_travel_status: "",
		search_carrier_reported: "",
		reporttruck: 0,
		fence_name: { "value": "", "label": "Select Parking" },
		plants: [],
		plant_code: { "value": "", "label": "Select Parking Yard" },
		withdrawdata: "",
		withdraw_reason: "",
		gpsplant: "",
		gpsradius: 15,
		mwidth: "col-xl-5 col-lg-5",
		rowData1: [],
		nsearch: "show-n",
		selectedTabLat: "",
		alltrucks: [],
		loginplant: "",
		filteredData: [],
		manual_truck: "",
		manual_transporter: { value: "", label: "Select Transporter" },
		manual_trucktype: { value: "", label: "Select Type" },
		manual_parkingyard: { value: "", label: "Select Parking Yard" },
		manual_reported_on: "",
		showTrucksList: "show-n",
		manual_parking_out: "",
		showtrans: "show-m",
		parkingdata: "",
		slideerParkingOut: "",
		totalparks: [],
		parkingYardList: [],
		parkingOutPlantIn: [],
		loading_capacity: "",
		departmentarray: [],
		transporteriwsearray: [],
		prkoutrowData: [],
		showtransinp: "show-n",
		dealers: [],
		overalldepartmentarray: [],
		overtransporteriwsearray: [],
		startDateC: "",
		endDateC: "",
		summaryRowData: [],
		summarycolumnDefs: [],
		mcOptions: [],
		scOptions: [],
		modalRowData: [],
		mcLoadingCapacityVal: { label: "", value: "" },
		scLoadingCapacityVal: { label: "", value: "" },
		notOkType: { label: "", value: "" },
		notOkCategoryVal: { label: "", value: "" },
		isNotSelected: false,
		paramForReorting: [],
		notOkSubOptions: [],
		okInParking: [],
		notOkInParking: [],
		// pOutPIn: [],
		calledForLoadList: [],
		fittingsCategoryVal: [],
		documentsCategoryVal: [],
		othersCategoryVal: [],
		withdrawList: [],
		totalOverDose: true,
		defaultExcelExportParams: {
			allColumns: true,
		},
		withDrawVal : null,
		trucksArr: [],
		onTripCount : [],
		isOnTrip : 0

	}

	cipher = (salt) => {
		const textToChars = text => text.split('').map(c => c.charCodeAt(0));
		const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
		const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
		try {
			return text => text.split('')
				.map(textToChars)
				.map(applySaltToChar)
				.map(byteHex)
				.join('');
		}
		// eslint-disable-next-line no-unreachable
		catch (e) { }

	}


	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
			} else {
				console.log("GA disabled...");
			}
		} catch (error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}

	componentDidMount() {
		// loadDateTimeScript()
		loadDatepicker();
		var currentdate = moment.parseZone().format("DD-MM-YYYY HH:mm");
		this.setState({
			manual_reported_on: currentdate
		})
		$("#manual_reported_on").val(currentdate);
		$("#manual_parking_out").val(currentdate);
		var loguser = decode(localStorage.getItem("m"));
		// console.log("LOGUSER ", JSON.parse(loguser));
		var loginplant = "";
		try {
			var luser = JSON.parse(loguser);
			loginplant = luser.is_plant_code;
			// console.log("logplant", loginplant)
			this.setState({
				loginplant: loginplant,
				// consigner:[{"value":luser.is_plant_code,"label":luser.is_plant_name}]

			})
		}
		catch (e) {

		}

		var sdate = moment.parseZone().format("YYYY-MM-DD");
		var edate = moment.parseZone().format("YYYY-MM-DD");
		//console.log("Props ", this.props.match)
		this.logPageView(); // Log page view to GA
		var urlpath;
		var dpt;
		var hText = '(All)';

		var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
		if (this.props.match.path == "/sndparkings") {
			urlpath = '/dashboard/sndparkings';
			var urlpath1 = '/dashboard/sndparkingslist';
			var urlpath2 = '/dashboard/snddeptplants';
			dpt = 'SNDG';
			hText = '( Sales and Dispatch )';
		}
		else if (this.props.match.path == "/prtparkings") {
			urlpath = '/dashboard/prtparkings';
			var urlpath1 = '/dashboard/prtparkingslist';
			var urlpath2 = '/dashboard/prtdeptplants';
			dpt = 'LOG-PRT';
			hText = '( Spare Parts )';
		}
		else {
			urlpath = '/dashboard/parkings';
			var urlpath1 = '/dashboard/parkingslist';
			dpt = 'all';
		}
		let pageTitle = "Carriers in Parking " + hText + ""


		//console.log(dpt);
		this.setState({
			dept_code: dpt,
			pageTitle: pageTitle,
			startDate: sdate,
			endDate: edate,
			startDateC: sdate,
			endDateC: edate
		});

		var reqparams = {
			dept_code: encode(dpt),
			startDate: sdate,
			endDate: edate
		}
		var reqparams1 = {
			dept_code: encode(dpt),
		}
		this.setState({
			loadshow: 'show-m',
		});
		redirectURL.post(urlpath1).then((response) => {
			var records = response.data.records;
			// console.log("TT records ", records);
			var transporters = response.data.transporters;
			var dealers = response.data.dealers;
			let parkingListDropOptions = response.data.parkingListDropOptions

			var stransporters = []
			if (transporters.length > 0) {
				transporters.map((item) => {
					stransporters.push({
						value: item.transporter_code,
						label: item.transporter_name
					})
				})

			}
			var parkings = [];
			parkings.push({
				value: "",
				label: "All"
			})
			if(parkingListDropOptions !== undefined){
				if (parkingListDropOptions.length > 0) {
					parkingListDropOptions.map((item) => {
						if (loginplant != "" && loginplant != undefined) {
							if (item.plant_code == loginplant) {
								parkings.push({
									value: `${item.plant_name} - Parking`,
									label: `${item.plant_name} - Parking`,
								});
							}
	
						}
						else {
							parkings.push({
								value: `${item.plant_name} - Parking`,
								label: `${item.plant_name} - Parking`,
							})
						}
	
					})
				}
			}
			this.setState({
				parkinglist: parkings,
				transporters: stransporters,
				dealers: dealers
			})
			// console.log("parksings ", records)
		})

		redirectURL.post(urlpath2).then((response) => {
			var records = response.data.records;
			var plants = []

			var cl = [];
			var avs = [];
			var rp = [];
			var uts = [];
			var bl = [];
			var blu = [];

			if (records.length > 0) {
				records.map((item) => {

					if (loginplant != "" && loginplant != undefined) {
						if (item.plant_code == loginplant) {
							plants.push({
								value: item.plant_code,
								label: item.plant_name
							});
							avs.push({
								headerName: item.plant_name,
								headerClass: ["fbold"],
								field: "available-" + item.plant_name,
								width: 100
							})
							bl.push({
								headerName: item.plant_name,
								headerClass: ["fbold"],
								field: "bal-" + item.plant_name,
								width: 100
							})
							blu.push({
								headerName: item.plant_name,
								headerClass: ["fbold"],
								field: "bal_util-" + item.plant_name,
								width: 100
							})
							rp.push({
								headerName: item.plant_name,
								headerClass: ["fbold"],
								field: "reported-" + item.plant_name,
								width: 100
							})
							uts.push({
								headerName: item.plant_name,
								headerClass: ["fbold"],
								field: "util-" + item.plant_name,
								width: 100
							})
						}

					}
					else {
						plants.push({
							value: item.plant_code,
							label: item.plant_name
						})
						avs.push({
							headerName: item.plant_name,
							field: "available-" + item.plant_name,
							headerClass: ["fbold"],
							width: 100
						})
						bl.push({
							headerName: item.plant_name,
							field: "bal-" + item.plant_name,
							headerClass: ["fbold"],
							width: 100
						})
						blu.push({
							headerName: item.plant_name,
							field: "bal_util-" + item.plant_name,
							headerClass: ["fbold"],
							width: 100
						})
						rp.push({
							headerName: item.plant_name,
							field: "reported-" + item.plant_name,
							headerClass: ["fbold"],
							width: 100
						})
						uts.push({
							headerName: item.plant_name,
							field: "util-" + item.plant_name,
							headerClass: ["fbold"],
							width: 100
						})
					}
				})

				var cols = [{
					headerName: "Transporter Name",
					headerClass: ["fbold"],
					children: [{
						headerName: "",
						field: "transporter_name",
						width: 200,
					}]				
				}];
				avs.push({
					headerName: "Total",
					field: "available-Total",
					width: 100,
					headerClass: ["fbold"],
					cellClass: ["fbold", "bgColorSuccessMedium"]
				})
				bl.push({
					headerName: "Total",
					field: "bal-Total",
					width: 100,
					headerClass: ["fbold"],
					cellClass: ["fbold", "bgColorSuccessMedium"]
				})
				blu.push({
					headerName: "Total",
					field: "bal_util-Total",
					width: 100,
					headerClass: ["fbold"],
					cellClass: ["fbold", "bgColorSuccessMedium"]
				})
				rp.push({
					headerName: "Total",
					field: "reported-Total",
					width: 100,
					headerClass: ["fbold"],
					cellClass: ["fbold", "bgColorSuccessMedium"]
				})
				uts.push({
					headerName: "Total",
					field: "util-Total",
					width: 100,
					headerClass: ["fbold"],
					cellClass: ["fbold", "bgColorSuccessMedium"]
				})
				cols.push({
					headerName: "Total Trucks",
					headerClass: ["fbold"],
					children: avs
				});
				cols.push({
					headerName: "Reported(OK + Called for loading)",
					headerClass: ["fbold"],
					children: rp
				});
				cols.push({
					headerName: "Utilize(Parking Out-Plant IN)",
					headerClass: ["fbold"],
					children: uts
				});

				cols.push({
					headerName: "Balance(|(OK + Called for Loading) - Parking Out Plant In|)",
					headerClass: ["fbold"],
					children: blu
				});
				cols.push({
					headerName: "Parking Yard",
					headerClass: ["fbold"],
					children: bl
				});
				// console.log("cols595 ",cols)
				this.setState({
					plants: plants,
					summarycolumnDefs: cols
				});
			}
		})
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		this.setState({ eventAction: eventAction });
		//fetching usergridstate data on initial page load
		redirectURL.post("/consignments/usergridstates", { userId: localStorage.getItem("userid"), screenurl: window.location.pathname })
			.then(async (resp) => {
				console.log("userGrid", resp.data)
				await this.setState({
					usergridstate: resp.data
				});
			})

		this.onLoadData(reqparams1)
		this.onLoadTrucks();
		// this.onLoadParkOutData(reqparams);
		var reqparams2 = {
			dept_code: encode(dpt),
			startDate: sdate,
			endDate: edate,
			// consigner_codes:[loginplant]
		}
		this.onLoadSummaryData(reqparams2);
		this.dataSummary(reqparams2);
	};

	dataSummary = (parameters) => {
		var loguser = decode(localStorage.getItem("m"));
		// console.log("LOGUSER ", JSON.parse(loguser));
		var loginplant = "";
		try {
			var luser = JSON.parse(loguser);
			loginplant = luser.is_plant_code;
			parameters.plant = loginplant;
			// console.log("logplant", loginplant)
			this.setState({
				loginplant: loginplant,
				// consigner:[{"value":luser.is_plant_code,"label":luser.is_plant_name}]

			})
		}
		catch (e) {

		}
		// console.log("parameters ", parameters)
		redirectURL.post("/dashboard/plantWiseParkingSummaryReport", parameters)
			.then((response) => {
				var data = []
				data = response.data;
				// console.log("data here ", data)
				var rows = [];
				var cols = [{
					headerName: "Transporter Name",
					field: "transporter_name",
					width: 140
				}];

				var cl = [];
				var avs = [];
				var rp = [];
				var uts = [];
				var bl = [];
				var blu = [];
				if (data.length > 0) {
					data.map((item) => {
						var rw = {}
						// cl.headerName = "Transporter Name";
						rw.transporter_code = item.transporter_code;
						rw.transporter_name = item.transporter_name;
						if (item.available.length > 0) {
							item.available.map((im) => {
								if (loginplant != "" && loginplant != undefined) {
									if (loginplant == im.plant_code) {
										rw["available-" + im.plant_name] = im.value;
										avs.push({
											headerName: im.plant_name,
											field: "available-" + im.plant_name,
											width: 100
										})
									}

								}
								else {
									rw["available-" + im.plant_name] = im.value;
									avs.push({
										headerName: im.plant_name,
										field: "available-" + im.plant_name,
										width: 100
									})
								}

							})
						}
						if (item.balance_to_report.length > 0) {
							item.balance_to_report.map((im) => {
								// rw["bal-"+im.plant_name] = im.value; 
								if (loginplant != "" && loginplant != undefined) {
									if (loginplant == im.plant_code) {
										rw["bal-" + im.plant_name] = im.value;
										bl.push({
											headerName: im.plant_name,
											field: "bal-" + im.plant_name,
											width: 100
										})
									}

								}
								else {
									rw["bal-" + im.plant_name] = im.value;
									bl.push({
										headerName: im.plant_name,
										field: "bal-" + im.plant_name,
										width: 100
									})
								}
							})
						}

						if (item.balance_to_utilise.length > 0) {
							item.balance_to_utilise.map((im) => {
								// rw["bal_util-"+im.plant_name] = im.value; 
								if (loginplant != "" && loginplant != undefined) {
									if (loginplant == im.plant_code) {
										rw["bal_util-" + im.plant_name] = im.value;
										blu.push({
											headerName: im.plant_name,
											field: "bal_util-" + im.plant_name,
											width: 100
										})
									}

								}
								else {
									rw["bal_util-" + im.plant_name] = im.value;
									blu.push({
										headerName: im.plant_name,
										field: "bal_util-" + im.plant_name,
										width: 100
									})
								}
							})
						}

						if (item.reported.length > 0) {
							item.reported.map((im) => {
								// rw["reported-"+im.plant_name] = im.value; 
								if (loginplant != "" && loginplant != undefined) {
									if (loginplant == im.plant_code) {
										rw["reported-" + im.plant_name] = im.value;
										rp.push({
											headerName: im.plant_name,
											field: "reported-" + im.plant_name,
											width: 100
										})
									}

								}
								else {
									rw["reported-" + im.plant_name] = im.value;
									rp.push({
										headerName: im.plant_name,
										field: "reported-" + im.plant_name,
										width: 100
									})
								}
							})
						}

						if (item.utilised.length > 0) {
							item.utilised.map((im) => {
								// rw["util-"+im.plant_name] = im.value; 
								if (loginplant != "" && loginplant != undefined) {
									if (loginplant == im.plant_code) {
										rw["util-" + im.plant_name] = im.value;
										uts.push({
											headerName: im.plant_name,
											field: "util-" + im.plant_name,
											width: 100
										})
									}

								}
								else {
									rw["util-" + im.plant_name] = im.value;
									uts.push({
										headerName: im.plant_name,
										field: "util-" + im.plant_name,
										width: 100
									})
								}
							})
						}
						rows.push(rw);

					})
				}

				// console.log("Rows here ", rows)
				// console.log("Columns ", cols)
				this.setState({
					summaryRowData: rows,
					// summarycolumnDefs:cols
				})
			})
	}

	onLoadSummaryData = (parameters) => {
		// console.log("this.state.loginplant ", this.state.loginplant)
		var loguser = decode(localStorage.getItem("m"));
		// console.log("LOGUSER ", JSON.parse(loguser));
		var loginplant = "";
		try {
			var luser = JSON.parse(loguser);
			loginplant = luser.is_plant_code;
			// console.log("logplant", loginplant)
			// this.setState({
			// 	loginplant:loginplant,
			// 	// consigner:[{"value":luser.is_plant_code,"label":luser.is_plant_name}]

			// })
		}
		catch (e) {

		}
		if (loginplant != "" && loginplant != undefined) {
			parameters.consigner_codes = [loginplant];
		}
		redirectURL.post("/dashboard/parkingdashboard", parameters)
			.then((response) => {
				try {

					// console.log(response.data)
					var records = JSON.parse(response.data.plant_source_wise);
					// console.log('records834', records)

					var transporter_wise = JSON.parse(response.data.transporter_wise);
					// console.log('transporter_wise', transporter_wise)

					var transporter_plant_wise = JSON.parse(response.data.transporter_plant_wise);
					// console.log('transporter_plant_wise', transporter_plant_wise)

					var overall_plant_source_wise = JSON.parse(response.data.overall_plant_source_wise);
					// console.log('overall_plant_source_wise', overall_plant_source_wise)

					var overall_plant_wise = JSON.parse(response.data.overall_plant_wise);
					// console.log('overall_plant_wise', overall_plant_wise)

					var overall_trans_plant_wise = JSON.parse(response.data.overall_trans_plant_wise);
					// console.log('overall_trans_plant_wise', overall_trans_plant_wise)

					var overtransporter_wise = JSON.parse(response.data.overall_transporter_wise);
					// console.log('overtransporter_wise', overtransporter_wise)

					var departmentarray = [];
					var transporteriwsearray = [];
					var loadingarr = [];
					var botharr = [];
					var botharrm = [];
					var delayedarr = [];
					var reportarr = [];
					var transdrildownarr = [];
					var outtrans = [];
					var intrans = [];
					var reporttrans = [];
					var categories = [];
					var tcategories = [];
					var tovercategories = [];
					var transdrildownarr = [];
					var overalldepartmentarray = [];

					var overtransdrildownarr = [];
					var overouttrans = [];
					var overintrans = [];
					var overreporttrans = [];
					var overtransporteriwsearray = []
					// var web = {"plant_code_order":0,"plant_code":"Dharuhera","source":"Web","inside_count":10,"outside_count":0,"reported_count":0};
					// records.push(web);
					// console.log("Reccc ", records);
					records.forEach((item, key) => {
						//var categories = [];
						categories.push(item.plant_code)
						// if(item.Ontime_per > 0)
						// {
						//     loadingarr.push(Math.round(item.Ontime_per));
						// }

						delayedarr.push({
							name: item.plant_code,
							y: Math.round(item.outside_count),
							drilldown: "ParkingOut-" + item.plant_code,
							// text:"Outside Parking",
							color: "#ff0000"
						});
						if (item.source == "Auto") {
							botharr.push({
								name: item.plant_code,
								y: Math.round(item.inside_count),
								drilldown: "ReportedInParking-" + item.plant_code,
								text: "Automatic",
								color: "#3188DD"
							});
						}
						if (item.source == "Web" || item.source == "Manual") {
							botharrm.push({
								name: item.plant_code,
								y: Math.round(item.inside_count),
								drilldown: "ReportedInParking-" + item.plant_code,
								text: "Manual",
								color: "#E1AC14"
							});
						}

						reportarr.push({
							name: item.plant_code,
							y: Math.round(item.reported_count),
							drilldown: "MarkedArrival-" + item.plant_code,
							// text:"On Time",
							color: "#00F0A6"
						});
					})
					// console.log("transporter_wise", transporter_plant_wise)
					// console.log("reportarr", reportarr)
					var drilldowntransporter_wise = groupBy(transporter_plant_wise, rdata => rdata.plant_code);
					// drilldowntransporter_wise = drilldowntransporter_wise.sort(GetSortDescOrder("outside_count"));
					// console.log("drilldowntransporter_wise ", drilldowntransporter_wise)
					var drilldeptarray = []

					drilldowntransporter_wise.forEach((item, key) => {
						var plouttrans = [];
						var plintrans = [];
						var plreporttrans = [];
						if (item.length > 0) {

							item.map((itm) => {
								if (itm.outside_count > 0) {
									plouttrans.push({
										name: itm.transporter_name,
										y: Math.round(itm.outside_count),
										text: "Parking Out",
										// color:"#ff0000"
									});
								}
								if (itm.inside_count > 0) {
									plintrans.push({
										name: itm.transporter_name,
										y: Math.round(itm.inside_count),
										text: "Available In Parking",
										// color:"#3188DD"
									});
								}

								if (itm.reported_count > 0) {
									plreporttrans.push({
										name: itm.transporter_name,
										y: Math.round(itm.reported_count),
										text: "Reported At Parking",
										// color:"#00F0A6"
									});
								}

							});
						}

						plintrans = plintrans.sort(GetSortDescOrder("y"));
						plreporttrans = plreporttrans.sort(GetSortDescOrder("y"));
						plouttrans = plouttrans.sort(GetSortDescOrder("y"));
						// console.log("plintrans ",plintrans)
						drilldeptarray.push({
							name: "ReportedInParking-" + key,
							id: "ReportedInParking-" + key,
							data: plintrans
						}, {
							name: "MarkedArrival-" + key,
							id: "MarkedArrival-" + key,
							data: plreporttrans
						}, {
							name: "ParkingOut-" + key,
							id: "ParkingOut-" + key,
							data: plouttrans
						})

					});
					// console.log("drilldeptarray",drilldeptarray)
					departmentarray.push({

						reportseries: reportarr,
						delayedseries: delayedarr,
						bothseries: botharr,
						bothseriesm: botharrm,
						categories: categories,
						yaxistitle: "Avg.",
						isfilter: "no",
						chartdrildown: drilldeptarray,
					})
					transporter_wise = transporter_wise.sort(GetSortDescOrder("inside_count"));
					transporter_wise.forEach((item, key) => {
						//var categories = [];
						// console.log("item ", item)
						if (item.transporter_name != "NA") {

							tcategories.push(item.transporter_name)
							// if(item.Ontime_per > 0)
							// {
							//     loadingarr.push(Math.round(item.Ontime_per));
							// }

							outtrans.push({
								name: item.transporter_name,
								y: Math.round(item.outside_count),
								drilldown: "ParkingOut-" + item.transporter_name,
								text: "Parking Out",
								color: "#ff0000"
							});
							intrans.push({
								name: item.transporter_name,
								y: Math.round(item.inside_count),
								drilldown: "ReportedInParking-" + item.transporter_name,
								text: "Available In Parking",
								color: "#3188DD"
							});
							reporttrans.push({
								name: item.transporter_name,
								y: Math.round(item.reported_count),
								drilldown: "MarkedArrival-" + item.transporter_name,
								text: "Reported At Parking",
								color: "#00F0A6"
							});
						}


					})


					transporter_plant_wise = groupBy(transporter_plant_wise, rdata => rdata.transporter_name);
					transporter_plant_wise.forEach((item, key) => {
						// console.log("key ", key)
						// console.log("item",item)
						var trandrilseries = [];
						var dtrandrilseries = [];
						var reportdrillseries = [];
						if (item.length > 0) {
							item.map((itm) => {
								trandrilseries.push({
									name: "Available In Parking - " + itm.plant_code,
									y: Math.round(itm.inside_count),
									// drilldown:key
								})
							})

							item.map((itm) => {
								dtrandrilseries.push({
									name: "Parking Out - " + itm.plant_code,
									y: Math.round(itm.outside_count)
								})
							})
							item.map((itm) => {
								reportdrillseries.push({
									name: "Reported At Parking - " + itm.plant_code,
									y: Math.round(itm.reported_count)
								})
							})
						}

						trandrilseries = trandrilseries.sort(GetSortDescOrder("y"));
						reportdrillseries = reportdrillseries.sort(GetSortDescOrder("y"));
						dtrandrilseries = dtrandrilseries.sort(GetSortDescOrder("y"));

						transdrildownarr.push({
							name: key,
							id: "ReportedInParking-" + key,
							data: trandrilseries,
							dataLabels: {
								enabled: true
							}
						},
							{
								name: key,
								id: "MarkedArrival-" + key,
								data: reportdrillseries,
								dataLabels: {
									enabled: true
								}
							}, {
							name: key,
							id: "ParkingOut-" + key,
							data: dtrandrilseries,
							dataLabels: {
								enabled: true
							}
						})

					})

					transporteriwsearray.push({

						reportseries: reporttrans,
						delayedseries: outtrans,
						bothseries: intrans,
						categories: tcategories,
						yaxistitle: "Avg.",
						isfilter: "no",
						chartdrildown: transdrildownarr,
					})

					var overallcategories = [];
					//Overall Chart Data
					var overalldelayedarr = [];
					var overallbotharr = [];

					var overallbotharrm = [];
					var overallreportarr = [];
					overall_plant_source_wise.forEach((item, key) => {
						//var categories = [];
						overallcategories.push(item.plant_code)
						// if(item.Ontime_per > 0)
						// {
						//     loadingarr.push(Math.round(item.Ontime_per));
						// }

						overalldelayedarr.push({
							name: item.plant_code,
							y: Math.round(item.outside_count),
							drilldown: "ParkingOut-" + item.plant_code,
							// text:"Outside Parking",
							color: "#ff0000"
						});
						if (item.source == "Auto") {
							overallbotharr.push({
								name: item.plant_code,
								y: Math.round(item.inside_count),
								drilldown: "ReportedInParking-" + item.plant_code,
								// text:"On Time",
								color: "#3188DD"
							});
						}
						if (item.source == "Web" || item.source == "Manual") {
							overallbotharrm.push({
								name: item.plant_code,
								y: Math.round(item.inside_count),
								drilldown: "ReportedInParking-" + item.plant_code,
								// text:"On Time",
								color: "#E1AC14"
							});
						}

						overallreportarr.push({
							name: item.plant_code,
							y: Math.round(item.reported_count),
							drilldown: "MarkedArrival-" + item.plant_code,
							// text:"On Time",
							color: "#00F0A6"
						});


					})
					// console.log("transporter_wise", transporter_plant_wise)
					var overalldrilldowntransporter_wise = groupBy(overall_trans_plant_wise, rdata => rdata.plant_code);
					// drilldowntransporter_wise = drilldowntransporter_wise.sort(GetSortDescOrder("inside_count"));
					// console.log("drilldowntransporter_wise ", drilldowntransporter_wise)
					var overalldrilldeptarray = []

					overalldrilldowntransporter_wise.forEach((item, key) => {
						var plouttrans = [];
						var plintrans = [];
						var plreporttrans = [];
						if (item.length > 0) {

							item.map((itm) => {

								if (itm.outside_count > 0) {
									plouttrans.push({
										name: itm.transporter_name,
										y: Math.round(itm.outside_count),
										text: "Parking Out",
										// color:"#ff0000"
									});
								}
								if (itm.inside_count > 0) {
									plintrans.push({
										name: itm.transporter_name,
										y: Math.round(itm.inside_count),
										text: "Available In Parking",
										// color:"#3188DD"
									});
								}

								if (itm.reported_count > 0) {
									plreporttrans.push({
										name: itm.transporter_name,
										y: Math.round(itm.reported_count),
										text: "Reported At Parking",
										// color:"#00F0A6"
									});
								}

							});
						}

						plintrans = plintrans.sort(GetSortDescOrder("y"));
						plreporttrans = plreporttrans.sort(GetSortDescOrder("y"));
						plouttrans = plouttrans.sort(GetSortDescOrder("y"));

						overalldrilldeptarray.push({
							name: "ReportedInParking-" + key,
							id: "ReportedInParking-" + key,
							data: plintrans
						}, {
							name: "MarkedArrival-" + key,
							id: "MarkedArrival-" + key,
							data: plreporttrans
						}, {
							name: "ParkingOut-" + key,
							id: "ParkingOut-" + key,
							data: plouttrans
						})

					});
					overalldepartmentarray.push({

						reportseries: overallreportarr,
						delayedseries: overalldelayedarr,
						bothseries: overallbotharr,
						bothseriesm: overallbotharrm,
						categories: overallcategories,
						yaxistitle: "Avg.",
						isfilter: "no",
						chartdrildown: overalldrilldeptarray,
					})

					overtransporter_wise = overtransporter_wise.sort(GetSortDescOrder("inside_count"));
					overtransporter_wise.forEach((item, key) => {
						//var categories = [];
						// console.log("item ", item)
						tovercategories.push(item.transporter_name)
						// if(item.Ontime_per > 0)
						// {
						//     loadingarr.push(Math.round(item.Ontime_per));
						// }

						overouttrans.push({
							name: item.transporter_name,
							y: Math.round(item.outside_count),
							drilldown: "ParkingOut-" + item.transporter_name,
							text: "Parking Out",
							color: "#ff0000"
						});
						overintrans.push({
							name: item.transporter_name,
							y: Math.round(item.inside_count),
							drilldown: "ReportedInParking-" + item.transporter_name,
							text: "Available In Parking",
							color: "#3188DD"
						});
						overreporttrans.push({
							name: item.transporter_name,
							y: Math.round(item.reported_count),
							drilldown: "MarkedArrival-" + item.transporter_name,
							text: "Reported At Parking",
							color: "#00F0A6"
						});


					})


					var overtransporter_plant_wise = groupBy(overall_trans_plant_wise, rdata => rdata.transporter_name);
					overtransporter_plant_wise.forEach((item, key) => {
						// console.log("key ", key)
						// console.log("item",item)
						var overtrandrilseries = [];
						var overdtrandrilseries = [];
						var overreportdrillseries = [];
						if (item.length > 0) {
							item.map((itm) => {
								overtrandrilseries.push({
									name: "Available In Parking - " + itm.plant_code,
									y: Math.round(itm.inside_count),
									// drilldown:key
								})
							})

							item.map((itm) => {
								overdtrandrilseries.push({
									name: "Parking Out - " + itm.plant_code,
									y: Math.round(itm.outside_count)
								})
							})
							item.map((itm) => {
								overreportdrillseries.push({
									name: "Reported At Parking - " + itm.plant_code,
									y: Math.round(itm.reported_count)
								})
							})
						}


						overtrandrilseries = overtrandrilseries.sort(GetSortDescOrder("y"));
						overreportdrillseries = overreportdrillseries.sort(GetSortDescOrder("y"));
						overdtrandrilseries = overdtrandrilseries.sort(GetSortDescOrder("y"));

						overtransdrildownarr.push({
							name: key,
							id: "ReportedInParking-" + key,
							data: overtrandrilseries,
							dataLabels: {
								enabled: true
							}
						},
							{
								name: key,
								id: "MarkedArrival-" + key,
								data: overreportdrillseries,
								dataLabels: {
									enabled: true
								}
							}, {
							name: key,
							id: "ParkingOut-" + key,
							data: overdtrandrilseries,
							dataLabels: {
								enabled: true
							}
						})

					})

					overtransporteriwsearray.push({
						reportseries: overreporttrans,
						delayedseries: overouttrans,
						bothseries: overintrans,
						categories: tovercategories,
						yaxistitle: "Avg.",
						isfilter: "no",
						chartdrildown: overtransdrildownarr,
					})

					// console.log("departmentarray ", departmentarray)
					this.setState({
						departmentarray: departmentarray,
						transporteriwsearray: transporteriwsearray,
						overalldepartmentarray: overalldepartmentarray,
						overtransporteriwsearray: overtransporteriwsearray
					})
				}
				catch (e) {
				}
			})
	}

	onLoadData = (reqparams) => {
		// console.log("reqparams ", reqparams)
		if (this.props.match.path == "/sndparkings") {
			var urlpath = '/dashboard/sndparkings';
		}
		else if (this.props.match.path == "/prtparkings") {
			var urlpath = '/dashboard/prtparkings';
		}
		else {
			var urlpath = '/dashboard/parkings';
		}
		redirectURL.post(urlpath, reqparams)
			.then((response) => {
				var records = response.data.records;
				// console.log("rcords ", records)
				var trucks = [];
				var recordsarr = [];
				var transporters = []
				if (records.length > 0) {
					trucks.push({ value: "", label: "Select Truck" });
					records.map((item) => {
						if (this.state.loginplant != "" && this.state.loginplant != undefined) {
							if (item.plant_code == this.state.loginplant) {
								trucks.push({ value: item.truck_no, label: item.truck_no });
								if (item.trucks.length > 0 && item.trucks[0].transporter_code != undefined
									&& item.trucks[0].transporter_code != "") {
									transporters.push({
										transporter_code: item.trucks[0].transporter_code,
										transporter_name: item.trucks[0].transporter_name,
									})
								}

							}

						}
						else {
							trucks.push({ value: item.truck_no, label: item.truck_no });
							if (item.trucks.length > 0 && item.trucks[0].transporter_code != undefined
								&& item.trucks[0].transporter_code != "") {
								transporters.push({
									transporter_code: item.trucks[0].transporter_code,
									transporter_name: item.trucks[0].transporter_name,
								})
							}

						}
						try {
							var d1 = moment.parseZone(new Date()).format("YYYY-MM-DD HH:mm:ss")
							var d2 = moment.parseZone(item.truckloc[0].timestamp).format("YYYY-MM-DD HH:mm:ss")
							var ms = moment(d1, "YYYY-MM-DD HH:mm:ss").diff(moment(d2, "YYYY-MM-DD HH:mm:ss"));
							var d = moment.duration(ms);
							var s = Math.floor(d.asHours());
							// console.log("d1 ", d1)
							// console.log("d2 ", d2)
							// console.log("Hrs1403 ", s)
							item.travelstatus = s;
							if (s >= 24) {
								item.gps_status = "Inactive";
							}
							else {
								item.gps_status = "Active";
							}
						}
						catch (e) {

						}
						if (this.state.loginplant != "" && this.state.loginplant != undefined) {
							if (item.plant_code == this.state.loginplant) {
								recordsarr.push(item);
							}

						}
						else {
							recordsarr.push(item);
						}
					})
				}

				let newArray = [];
				// Declare an empty object
				let totuniqueObject = {};
				var totobjTitle;
				// Loop for the array elements
				for (let i in transporters) {

					// Extract the title
					totobjTitle = transporters[i]['transporter_code'];

					// Use the title as the index
					totuniqueObject[totobjTitle] = transporters[i];
				}

				// Loop to push unique object into array
				for (var a in totuniqueObject) {
					newArray.push(totuniqueObject[a]);
				}
				var transporterlist = [];
				// console.log("Newrar ", newArray);
				if (newArray.length > 0) {
					newArray.map((item) => {
						transporterlist.push({
							value: item.transporter_code,
							label: item.transporter_name
						})
					})
				}
				var totalparks = [];
				var parkingYardList = [];
				var okInParking = [];
				var notOkInParking = [];
				var parkingOutPlantIn = [];
				const calledForLoading = []
				const withdrawList = []
				if (recordsarr.length > 0) {
					recordsarr.map((item) => {
						if (item.transporter_name != "NA") {
							var trns = item.transporter_name;
							item.transportername = trns;
						}
						else {
							if (item.trucks[0].transporter_name != undefined) {
								if (item.trucks[0].transporter_name != "NA") {
									var trns = item.trucks[0].transporter_name;
									item.transportername = trns;
								}
							}
						}
						totalparks.push(item);
						if ((item.carrier_reporting_status !== 'ok' || item.withdraw_carrier == 1) && item.carrier_called_for_loading !== 1 && item.is_parking_out !== 1) {
							parkingYardList.push(item);
						}
						if (item.is_parking_out == 1) {
							parkingOutPlantIn.push(item);
						}
						if (item.carrier_reporting_status === 'ok' && item.is_parking_out !== 1) okInParking.push(item)
						if (item.carrier_reporting_status === 'not ok') notOkInParking.push(item)
						if (item.carrier_called_for_loading === 1 && item.is_parking_out !== 1) calledForLoading.push(item)
						if (item.withdraw_carrier == 1) withdrawList.push(item)


					})
				}
				this.setState({
					'rowData': recordsarr,
					'allrowData': recordsarr,
					'truckslist': trucks,
					'parkingOutPlantIn': parkingOutPlantIn,
					'loadshow': "show-n",
					'overly': "show-n",
					'totalparks': totalparks,
					'parkingYardList': parkingYardList,
					okInParking,
					notOkInParking,
					'calledForLoadList': calledForLoading,
					withdrawList,
					totalOverDose: true
					// pOutPIn : [...okInParking,...notOkInParking]
					// transporters:transporterlist
				})
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	onLoadTrucks = () => {
		redirectURL.post("/dashboard/trucklist").
			then((response) => {
				var records = response.data.records;
				// console.log("records trucks", records);
				var trucks = []
				if (records.length > 0) {
					records.map((item) => {
						trucks.push({
							value: item.truck_no,
							label: item.truck_no
						})
					})
				}
				this.setState({
					struckslist: trucks
				})
			})
	}

	onLoadFilterData = (reqparams) => {
		if (this.props.match.path == "/sndparkings") {
			var urlpath = '/dashboard/sndparkings';
		}
		else if (this.props.match.path == "/prtparkings") {
			var urlpath = '/dashboard/prtparkings';
		}
		else {
			var urlpath = '/dashboard/parkings';
		}
		redirectURL.post(urlpath, reqparams)
			.then((response) => {
				var records = response.data.records;
				// console.log("rcords ", records)
				var trucks = [];
				var recordsarr = [];
				var transporters = []
				if (records.length > 0) {
					records.map((item) => {
						if (this.state.loginplant != "" && this.state.loginplant != undefined) {
							if (item.plant_code == this.state.loginplant) {
								if (item.trucks.length > 0 && item.trucks[0].transporter_code != undefined
									&& item.trucks[0].transporter_code != "") {
									transporters.push({
										transporter_code: item.trucks[0].transporter_code,
										transporter_name: item.trucks[0].transporter_name,
									})
								}
							}
						}
						else {
							if (item.trucks.length > 0 && item.trucks[0].transporter_code != undefined
								&& item.trucks[0].transporter_code != "") {
								transporters.push({
									transporter_code: item.trucks[0].transporter_code,
									transporter_name: item.trucks[0].transporter_name,
								})
							}
						}

						if (item.truckloc.length > 0) {
							var d1 = moment.parseZone(new Date()).format("YYYY-MM-DD HH:mm:ss")
							var d2 = moment.parseZone(item.truckloc[0].timestamp).format("YYYY-MM-DD HH:mm:ss")
							var ms = moment(d1, "YYYY-MM-DD HH:mm:ss").diff(moment(d2, "YYYY-MM-DD HH:mm:ss"));
							var d = moment.duration(ms);
							var s = Math.floor(d.asHours());
							// console.log("d1 ", d1)
							// console.log("d2 ", d2)
							// console.log("Hrs1581 ", s)
							item.travelstatus = s;
							if (s >= 24) {
								item.gps_status = "Inactive";
							}
							else {
								item.gps_status = "Active";
							}
						}
						else {
							item.gps_status = "Inactive";
						}
						if (this.state.loginplant != "" && this.state.loginplant != undefined) {
							if (item.plant_code == this.state.loginplant) {
								recordsarr.push(item);
							}

						}
						else {
							recordsarr.push(item);
						}
					})
				}

				let newArray = [];
				// Declare an empty object
				let totuniqueObject = {};
				var totobjTitle;
				// Loop for the array elements
				for (let i in transporters) {

					// Extract the title
					totobjTitle = transporters[i]['transporter_code'];

					// Use the title as the index
					totuniqueObject[totobjTitle] = transporters[i];
				}

				// Loop to push unique object into array
				for (var a in totuniqueObject) {
					newArray.push(totuniqueObject[a]);
				}
				var transporterlist = [];
				// console.log("Newrar ", newArray);
				if (newArray.length > 0) {
					newArray.map((item) => {
						transporterlist.push({
							value: item.transporter_code,
							label: item.transporter_name
						})
					})
				}
				var totalparks = [];
				var parkingYardList = [];
				var parkingOutPlantIn = [];
				var okInParking = [];
				var notOkInParking = [];
				const calledForLoading = [];
				const withdrawList = []
				if (recordsarr.length > 0) {
					recordsarr.map((item) => {
						if (item.transporter_name != "NA") {
							var trns = item.transporter_name;
							item.transportername = trns;
						}
						else {
							if (item.trucks[0].transporter_name != undefined) {
								if (item.trucks[0].transporter_name != "NA") {
									var trns = item.trucks[0].transporter_name;
									item.transportername = trns;
								}
							}
						}
						totalparks.push(item);
						if ((item.carrier_reporting_status !== 'ok' || item.withdraw_carrier == 1) && item.carrier_called_for_loading !== 1 && item.is_parking_out !== 1) {
							parkingYardList.push(item);
						}
						if (item.is_parking_out == 1) {
							parkingOutPlantIn.push(item);
						}
						if (item.carrier_reporting_status === 'ok' && item.is_parking_out !== 1) okInParking.push(item)
						if (item.carrier_reporting_status === 'not ok') notOkInParking.push(item)
						if (item.carrier_called_for_loading === 1 && item.is_parking_out !== 1) calledForLoading.push(item)
						if (item.withdraw_carrier == 1) withdrawList.push(item)

					})
				}
				// console.log("recordsarr ", recordsarr)
				this.setState({
					rowData: recordsarr,
					allrowData: recordsarr,
					totalparks: totalparks,
					parkingYardList: parkingYardList,
					parkingOutPlantIn: parkingOutPlantIn,
					loadshow: "show-n",
					overly: "show-n",
					okInParking,
					notOkInParking,
					withdrawList,
					'calledForLoadList': calledForLoading,
					totalOverDose: true
					// transporters:transporterlist
				})
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	onGridReady = params => {
		this.gridApi = params.api;
		//console.log("Grid is: ", params);
		this.gridColumnApi = params.columnApi;
	};


	onGridState = () => {
		//console.log(this.gridApi);

		/*Get  Current Columns State and Store in this.colState */
		this.colState = this.gridColumnApi.getColumnState();

		/*Get Current RowGroup Columns State and Store in this.rowGroupState */
		this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		/*Get Current Pivot Columns State and Store in this.pivotColumns,
		 * User should enable the Pivot mode.*/
		this.pivotColumns = this.gridColumnApi.getPivotColumns();

		/*Get Current Filter State and Store in window.filterState */
		window.filterState = this.gridApi.getFilterModel();
		this.gridApi.setFilterModel(window.filterState);
	}

	onClickHideAll() {
		this.setState({
			loadshow: 'show-n',
			overly: 'show-n',
			sliderRouteTranslate: '',
			tabsliderTranslate: '',
			slideAddManualForm: "",
			slideerParkingOut: ""
		});

	}

	closeAlert = async () => {
		await this.setState({
			show: false
		});
		//await window.location.reload();
	}

	closeAlert1 = () => {
		this.setState({
			show1: false
		});
	}

	handlerStartDateTime = (event, currentDate, selectedDate) => {
		//console.log("event ", event)
		//var d = new Date(event._d);
		var d = new Date(moment(event).format("YYYY-MM-DD"));
		//console.log("d ", d)
		var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());
		// console.log("startdate ", startdate)
		this.setState({
			startDate: startdate
		});
		//let value = event.target.value;
		//this.setState({'startDate':value});
	}

	handlerEndDateTime = (event, currentDate, selectedDate) => {
		//var d = new Date(event._d);
		var d = new Date(moment(event).format("YYYY-MM-DD"));
		// var edate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate())
		var enddate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());

		this.setState({
			endDate: enddate
		});


		//let value = event.target.value;
		//this.setState({'startDate':value});
	}

	formHandler = (event) => {
		event.preventDefault();
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmittedGetData,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.setState({
			loadshow: 'show-m'
		});
		//console.log("code ", this.state.dept_code)
		if (this.state.startDate != "" && this.state.endDate != "") {
			var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
			var sdate = $("#startDate").val();
			var edate = $("#endDate").val();
			if (this.state.truck_no.value != "") {
				var truck_no = this.state.truck_no.value;
			}
			else {
				var truck_no = "";
			}
			if (this.state.geofence_name.value != "") {
				var geofence_name = this.state.geofence_name.value;
			}
			else {
				var geofence_name = "";
			}
			var parameters = {
				dept_code: encode(this.state.dept_code),
				startDate: sdate,
				endDate: edate,
				truck_no: truck_no,
				geofence_name: geofence_name
			}
			// console.log("Params ", parameters)
			this.onLoadFilterData(parameters);

		}
		else {
			this.setState({
				show1: true,
				basicTitle1: "Start date and end Date should  not be empty",
				basicType1: "danger"
			})
		}
	}

	selectOptionsItems = () => {
		let items = [];
		//console.log(this.state.fencelist);
		this.state.fencelist.forEach(item => {
			if (item != '' && item != null) {
				items.push(<option key={item} value={item}>{item}</option>);
			}

		});
		return items;
	}

	handlerForm = (event) => {
		// console.log("Select", event.target.value);
		let name = event.target.name;
		let value = event.target.value;
		this.setState({ [name]: value });
	}

	onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth: '0%',
			sliderRouteTranslate: "",
			tabsliderTranslate: '',
			slideerParkingOut: "",
			showDiv: 'show-n',
			overly: 'show-n'
		});
	}

	onCloseRouteDiv = () => {
		this.setState({
			uploadDivWidth: '0%',
			sliderRouteTranslate: "",
			slideAddManualForm: "",
			slideerParkingOut: "",
			showDiv: 'show-n',
			overly: 'show-n'
		});
	}

	onShowRouteDiv = (params) => {
		// console.log("Params ", params);
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.setState({
			loadshow: "show-m"
		})
		var fdate = moment.parseZone(params.reporting_in_date).format("YYYY-MM-DD HH:mm:ss");
		if (params.reporting_out_date == undefined || params.reporting_out_date == "") {
			var edate = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
		}
		else {
			var edate = moment.parseZone(params.reporting_out_date).format("YYYY-MM-DD HH:mm:ss");
		}
		var reqData = {
			truck_no: params.truck_no,
			gate_out_time: fdate,
			seconddate: edate,
			screen: "geofence"
		}
		//console.log("reqData: ", reqData);
		redirectURL.post('/consignments/maproutes', reqData, {
			headers: {
				'content-type': 'application/json'
			}
		})
			.then((response) => {
				var records = response.data;
				this.setState({
					sliderRouteTranslate: "slider-translate-65p",
					overly: 'show-m',
					loadshow: "show-n",
					maptruckno: params.truck_no,
					mapinfo: records,
					sidebarSubHeader: "Truck Information",
					routeTruck: { "truck_no": params.truck_no, "startTime": response.data.route_details.start_time, "endTime": response.data.route_details.end_time },

				});
				// console.log("end Time", typeof(response.data.route_details.end_time), response.data.route_details.end_time)
			})
			.catch(function (e) {
				console.log("Error ", e)
			})
	}
	onCloseModal = () => {
		this.setState({ withdrawmodal: false, withdrawmodal1: false, reportCarrierOC: false, withdraw_reason: '', fittingsCategoryVal: null, documentsCategoryVal: null, othersCategoryVal: null });
	};

	onChangeParkingItem(geofence_name) {
		this.setState(
			{ geofence_name },
			() => console.log(`Name  Option selected:`, this.state.geofence_name)
		);

		this.setState({
			errorMessage: '',
			successMessage: ''
		})
	}

	onChangeTruckItem = (truck_no) => {
		this.setState(
			{ truck_no },
			// () => console.log(`Name  Option selected:`, this.state.truck_no)
		);

		this.setState({
			errorMessage: '',
			successMessage: ''
		})
	}
	onChangeFenceItem = (fence_name) => {
		this.setState(
			{ fence_name },
			// () => console.log(`Name  Option selected:`, this.state.fence_name)
		);

		this.setState({
			errorMessage: '',
			successMessage: ''
		})
	}

	onChangePlantItem = (plant_code) => {
		this.setState(
			{ plant_code },
			// () => console.log(`Name  Option selected:`, this.state.plant_code)
		);

		this.setState({
			errorMessage: '',
			successMessage: ''
		})
	}

	onChangeTransporterItem(transporter_code) {
		this.setState(
			{ transporter_code },
			() => {
				// console.log(`Name  Option selected:`, this.state.transporter_code)
				var triucks = this.state.truckslist;
				var truckarr = []
				if (triucks.length > 0) {
					triucks.map((item) => {
						if (item.value != "") {
							truckarr.push(item.value)
						}

					})
				}
				var parm = {
					transporter_code: this.state.transporter_code.value,
					trucks: truckarr
				}
				redirectURL.post("/dashboard/transportertrucklist", parm)
					.then((response) => {
						var records = response.data.records;
						var strucks = []
						if (records.length > 0) {
							records.map((item) => {
								//	console.log("item ", item)
								if (item.is_active == 1 && item.carrier_reported == 1) {

								}
								else {
									strucks.push({
										value: item.truck_no,
										label: item.truck_no
									})
								}

							})
						}
						this.setState({
							struckslist: strucks
						})
					})
				// var records = this.state.rowData;
				// var trans=[]
				// var tranp = [this.state.transporter_code];
				// if(records.length > 0)
				// {
				//     // records.map((item) => {
				//     //   console.log("item.trucks[0].transporter_code ", item.trucks[0].transporter_code)
				//     //   if(item.trucks.length > 0 && item.trucks[0].transporter_code != undefined
				//     //      && item.trucks[0].transporter_code != "" && item.trucks[0].transporter_code != this.state.transporter_code.value)
				//     //      {
				//     //        trans.push({
				//     //          value:item.truck_no,
				//     //          label:item.truck_no,
				//     //        })
				//     //      }
				//     // })
				//     trans = records.filter(f =>
				// 			tranp.some(d => d.value == f.trucks[0].transporter_code)
				// 		);
				// }
				// console.log("Trans ", trans);
				// var strucks = []
				// if(trans.length > 0)
				// {
				//   trans.map((item) => {
				//     strucks.push({
				//       value:item.truck_no,
				//       label:item.truck_no
				//     })
				//   })
				// }
				// this.setState({
				//   struckslist:strucks
				// })
			}
		);

		this.setState({
			errorMessage: '',
			successMessage: ''
		})
	}


	onChangeManualTransporterItem(manual_transporter) {
		this.onBlurData();
		this.setState(
			{ manual_transporter },
			() => {
				// console.log(`Name  Option selected:`, this.state.manual_transporter)
				var parm = {
					transporter_code: this.state.manual_transporter.value
				}
				// redirectURL.post("/dashboard/transportertruckslist",parm)
				// .then((response) => {
				//   var records = response.data.records;
				//   var strucks = []
				//   if(records.length > 0)
				//   {
				//     records.map((item) => {
				//       strucks.push({
				//         value:item.truck_no,
				//         label:item.truck_no
				//       })
				//     })
				//   }
				//   this.setState({
				//     struckslist:strucks
				//   })
				// })

			}
		);

		this.setState({
			errorMessage: '',
			successMessage: ''
		})
	}

	onChangeSearchTruckItem(struck_no) {
		this.setState(
			{ struck_no },
			// () => console.log(`Name  Option selected:`, this.state.struck_no)
		);

		this.setState({
			errorMessage: '',
			successMessage: ''
		})
	}

	onChangeManualTruckTypeItem(manual_trucktype) {
		this.setState(
			{ manual_trucktype },
			// () => console.log(`Name  Option selected:`, this.state.manual_trucktype)
		);

		this.setState({
			errorMessage: '',
			successMessage: ''
		})
	}

	onClickTab = (params) => {
		var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

		if (params == "parking") {
			this.setState({
				pact: "btn-warning",
				tact: "btn-defaultc",
				sact: "btn-defaultc",
				poutact: "btn-defaultc",
				parkingyard: "show-m",
				truckinfo: "show-n",
				parkingyardout: "show-n",
				summary: "show-n"
			})
			var reqparams = {
				dept_code: encode(this.state.dept_code),
				startDate: this.state.startDate,
				endDate: this.state.endDate
			}
			var sdate = $("#startDate").val();
			var edate = $("#endDate").val();
			reqparams.startDate = sdate;
			reqparams.endDate = edate;
			this.onLoadData(reqparams)
			this.onLoadTrucks();
			this.restoreGridStates();
			this.getOnTripDetails()
		}
		if (params == "truck") {
			var reqparams = {
				dept_code: encode(this.state.dept_code),
				startDate: this.state.startDate,
				endDate: this.state.endDate
			}
			this.setState({
				pact: "btn-defaultc",
				tact: "btn-warning",
				sact: "btn-defaultc",
				poutact: "btn-defaultc",
				parkingyard: "show-n",
				truckinfo: "show-m",
				parkingyardout: "show-n",
				summary: "show-n"
			})
		}
		if (params == "summary") {
			var reqparams = {
				dept_code: encode(this.state.dept_code),
				startDate: this.state.startDate,
				endDate: this.state.endDate
			}
			this.setState({
				pact: "btn-defaultc",
				tact: "btn-defaultc",
				sact: "btn-warning",
				poutact: "btn-defaultc",
				parkingyard: "show-n",
				truckinfo: "show-n",
				parkingyardout: "show-n",
				summary: "show-m"
			})

			this.onLoadSummaryData(reqparams);
		}

		if (params == "parkingout") {
			var reqparams = {
				dept_code: encode(this.state.dept_code),
				startDate: this.state.startDate,
				endDate: this.state.endDate
			}
			this.setState({
				pact: "btn-defaultc",
				tact: "btn-defaultc",
				sact: "btn-defaultc",
				poutact: "btn-warning",
				parkingyard: "show-n",
				truckinfo: "show-n",
				parkingyardout: "show-m",
				summary: "show-n"
			})

			// this.onLoadParkOutData(reqparams)
		}
		if(params == 'onTrip'){

		}
	}

	// onTrip Trucks 
	getOnTripDetails = () =>{
		var sdatetime = moment.parseZone().format('YYYY-MM-DD');
		// let startT = moment.parseZone().startOf('day').format('DD-MM-YYYY HH:mm')
		// let endT = moment.parseZone().endOf('day').format('DD-MM-YYYY HH:mm')
		// console.log("sdatetime",sdatetime,)
		let from_date = sdatetime
		let to_date = sdatetime
		let reqparams = {
			from_date,
			to_date,
			consigner_code: ["ALL"],
			dept_code : 'SNDG',
		}
		try {
			var userrols = localStorage.getItem("roles");
			var splitrole = userrols.split(",")
			if (splitrole.length > 0) {
				if (splitrole.includes("tpt_sndg") == true || splitrole.includes("tpt_prt") == true) {
					reqparams.transporter_codes = localStorage.getItem("transportercode")
				}
			}
		}
		catch (e) {

		}
		redirectURL.post('/trucks/sndtrucks',reqparams ).then(resp =>{
			let trucksArr = resp.data.active_trucks
			let onTripCount = resp.data.ontripCurrentDay.length
			// if (typeof resp.data == 'object') {
			// 	var recordsarr = resp.data;
			// }
			// else {
			// 	var dd = resp.data;
			// 	//console.log(JSON.parse(dd))
			// 	recordsarr = JSON.parse(resp.data);
			// }
			this.setState({
				trucksArr,
				onTripCount
			})
			// console.log("recordsarr",trucksArr)
		})

	}
	// report carrier button onclicked function
	onClickReportCarrierAction = (params) => {
		//  console.log("Report carrier",params);
		this.setState({
			paramForReorting: params,
			reportCarrierOC: true,
			isNotSelected: false,

		})
	}

	submitReportingDetails = params => {
		var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
		var encryptjson = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

		const { paramForReorting, fittingsCategoryVal, documentsCategoryVal, othersCategoryVal } = this.state
		// var cnf = window.confirm("Are you sure you want to perform this action?");
		// if (cnf == true) {
		// console.log('params',paramForReorting)
		var jsondata = encryptjson(JSON.stringify(paramForReorting));
		var parameters = {
			rownode: jsondata
		}
		if (params === 'ok') {
			parameters['carrier_reporting_status'] = 'ok';
		} else {
			// const { notOkType, notOkCategoryVal } = this.state
			// const fitList = ['Fittings', 'Gunny Bags', 'Side body foam', 'Fitting SC/MC', 'Groove(Kappa)', 'Back Horn', 'Nut Bolt']
			// const docList = ['Documents', 'RC', 'Fitness certificate', 'National Permit', 'Insurance', 'Pollution', 'Driving license']
			// const others = ['Others', 'Roof NG', 'Driver drunk']
			// if (fitList.includes(notOkCategoryVal.value) && fitList.includes(notOkType.value)) {
			// 	parameters['carrier_rejection_reason'] = notOkType.value
			// 	parameters['carrier_rejection_sub_reason'] = notOkCategoryVal.value
			// }
			// else if (docList.includes(notOkCategoryVal.value) && docList.includes(notOkType.value)) {
			// 	parameters['carrier_rejection_reason'] = notOkType.value
			// 	parameters['carrier_rejection_sub_reason'] = notOkCategoryVal.value
			// }
			// else if (others.includes(notOkCategoryVal.value) && others.includes(notOkType.value)) {
			// 	parameters['carrier_rejection_reason'] = notOkType.value
			// 	parameters['carrier_rejection_sub_reason'] = notOkCategoryVal.value
			// } else {
			// 	this.setState({
			// 		show: true,
			// 		basicType: "danger",
			// 		basicTitle: "Please Select Appropriate Value Only"
			// 	})
			// 	return null
			// }
		
			if ((fittingsCategoryVal !== null && fittingsCategoryVal !== undefined) || (documentsCategoryVal !== null && documentsCategoryVal !== undefined) || (othersCategoryVal !== null && othersCategoryVal !== undefined)) {
				// let fcListVal = ''
				// let dcListval = ''
				// let otherListVal = ''
				// let fittings = ''
				// let dcList = ''
				// let otherList = ''
				// try{
				// 	if(fittingsCategoryVal !== null){
				// 		console.log("fittingsCategoryVal",fittingsCategoryVal)
				// 		let a = ''
				// 		fcListVal = fittingsCategoryVal.map(e => e.value )
				// 		fittings = `fittings: ${fcListVal.join('-')}`
				// 	}
				// 	if(documentsCategoryVal !== null){
				// 		dcListval = documentsCategoryVal.map(e => e.value )
				// 		dcList = `documents: ${dcListval.join('-')}`
				// 	}
				// 	if(othersCategoryVal !== null){
				// 		otherListVal = othersCategoryVal.map(e => e.value )
				// 		otherList = `others: ${otherListVal.join('-')}`
				// 	}
				// }
				// catch(e){}
				// console.log('otherListVal',otherListVal)
				// let notOkayList = []
				// if(fittings !== '')notOkayList.push(fittings)
				// if(dcList !== '')notOkayList.push(dcList)
				// if(otherList !== '')notOkayList.push(otherList)

				let fcListVal = []
				let dcListval = []
				let otherListVal = []
				try {
					if (fittingsCategoryVal !== null) {
						let fitString = 'Fittings'
						fittingsCategoryVal.map(e => {
							fcListVal.push(`Fittings-${e.value}`)
						})
					}
					if (documentsCategoryVal !== null) {
						documentsCategoryVal.map(e => {
							dcListval.push(`Documents-${e.value}`)
						})
					}
					if (othersCategoryVal !== null) {
						othersCategoryVal.map(e => {
							otherListVal.push(`Others-${e.value}`)
						})
					}

				} catch (e) { }
				let notOkayList = [...fcListVal, ...dcListval, ...otherListVal]
				if(notOkayList.length == 0 ){
					this.setState({
						show: true,
						basicType: "warning",
						basicTitle: "Please Select Respective Values",
					})
					return null
				}
				// console.log(notOkayList,'notOkayList')
				parameters['carrier_rejection_reason'] = notOkayList
				parameters['carrier_reporting_status'] = 'not ok';
			} else {
				this.setState({
					show: true,
					basicType: "warning",
					basicTitle: "Please Select Respective Values",
				})
				return null
			}				
		}

		// console.log('setreportcarrier_parameters2267', parameters)
		redirectURL.post("/dashboard/setreportcarrier", parameters).then(response => {
			// console.log("response", response.data)
			if (response.data.status == "success") {
				var reqparams = {
					dept_code: encode(this.state.dept_code),
					// startDate: this.state.startDate,
					// endDate: this.state.endDate
				}
				this.onLoadData(reqparams);

				this.setState({
					show: true,
					basicType: "success",
					basicTitle: "Successfully reported carrier",
					reportCarrierOC: false,
					isNotSelected: false,
					overly: 'show-n',
					loadshow: 'show-n'
				})

			}
			else {

				this.setState({
					isNotSelected: false,
					show: true,
					basicType: "danger",
					basicTitle: "Failed to report carrier",
					overly: 'show-n',
					loadshow: 'show-n'
				})
			}
		})

	}

	onSubmitReportCarrier = param => {
		const { paramForReorting } = this.state
		if (param == 'ok') {
			this.submitReportingDetails('ok')
		} else {
			this.setState({
				isNotSelected: true
			})
		}

	}

	onClickWithdrawCarrierAction = (params) => {
		//  console.log("Withdraw carrier",params);
		// var cnf = window.confirm("Are you sure you want to perform this action?");
		// if (cnf == true) {
			this.setState({
				withdrawdata: params,
				withdrawmodal: true
			});
			// loadDateTimeScript()
		// }
		// else {
		// }
	}
	onClickParkingoutCarrierAction = async (params) => {
		var cnf = window.confirm("Are you sure you want to perform this action?");
		if (cnf == true) {
			// console.log(params)
			var rownode = params;
			var encryptjson = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
			var jsondata = encryptjson(JSON.stringify(rownode));
			var currentdate = moment.parseZone().format("DD-MM-YYYY HH:mm");

			var parameters = {
				rownode: jsondata,
				parking_out: currentdate
			}
			redirectURL.post("/dashboard/setparkingoutcarrier", parameters)
				.then((response) => {
					//console.log("response", response.data)
					if (response.data.status == "success") {
						var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
						var reqparams = {
							dept_code: encode(this.state.dept_code),
							// startDate: this.state.startDate,
							// endDate: this.state.endDate    // made new changes as per jira ticket ha 446 issue
						}
						this.onLoadData(reqparams);
						this.setState({
							show: true,
							basicType: "success",
							basicTitle: "Successfully update item",
							slideerParkingOut: "",
							// overly: "show-n"
						})
					}
					else {

						this.setState({
							show: true,
							basicType: "danger",
							basicTitle: "Failed to update item"
						})
					}
				})
		}
		else {

		}
		// this.setState({
		// 	parkingdata: params,
		// 	slideerParkingOut: "slider-translate",
		// 	overly: "show-m"
		// });

		// loadDateTimeScript()
		//  var currentdate = moment.parseZone().format("DD-MM-YYYY HH:mm");
		//  await	$("#manual_reported_on").val(currentdate);

	}

	formParkingoutCarrier = (event) => {
		event.preventDefault();
		//  console.log("Withdraw carrier",params);
		var manual_parking_out = $("#manual_parking_out").val();
		// console.log("manual_parking_out2392",manual_parking_out)
		var cnf = window.confirm("Are you sure you want to perform this action?");
		if (cnf == true) {
			// this.setState({
			// 	overly: "show-m",
			// 	loadshow: 'show-m'
			// })
			// console.log(params)
			var rownode = this.state.parkingdata;
			var encryptjson = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
			var jsondata = encryptjson(JSON.stringify(rownode));
			// var currentdate = moment.parseZone().format("DD-MM-YYYY HH:mm");

			var parameters = {
				rownode: jsondata,
				parking_out: manual_parking_out,
				// parking_out: manual_parking_out
			}
			redirectURL.post("/dashboard/setparkingoutcarrier", parameters)
				.then((response) => {
					//console.log("response", response.data)
					if (response.data.status == "success") {
						var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
						var reqparams = {
							dept_code: encode(this.state.dept_code),
							// startDate: this.state.startDate,
							// endDate: this.state.endDate    // made new changes as per jira ticket ha 446 issue
						}
						this.onLoadData(reqparams);
						this.setState({
							show: true,
							basicType: "success",
							basicTitle: "Successfully update item",
							slideerParkingOut: "",
							// overly: "show-n"
						})
					}
					else {

						this.setState({
							show: true,
							basicType: "danger",
							basicTitle: "Failed to update item"
						})
					}
				})
		}
		else {

		}
	}

	//withdraw carrier 
	onSubmitWithdrawAction = (event) => {
		event.preventDefault();
		var rownode = this.state.withdrawdata;
		var encryptjson = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
		var jsondata = encryptjson(JSON.stringify(rownode));
		// var manual_parking_out = $("#manual_parking_out").val();
		//var manual_parking_out = this.state.manual_parking_out;
		var parameters = {
			rownode: jsondata,
			withdraw_reason: this.state.withDrawVal.value,
			//	parking_out:manual_parking_out
		}
		// this.setState({
		// 	loadshow : 'show-m',
		// 	overly : 'show-m'
		// })
		if (parameters.withdraw_reason !== "" && parameters.withdraw_reason !== undefined) {
			redirectURL.post("/dashboard/setwithdrawcarrier", parameters)
				.then((response) => {
					//console.log("response", response.data)
					if (response.data.status == "success") {
						var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
						var reqparams = {
							dept_code: encode(this.state.dept_code),
							// startDate: this.state.startDate,
							// endDate: this.state.endDate
						}
						this.onLoadData(reqparams);
						this.setState({
							show: true,
							basicType: "success",
							basicTitle: "Successfully withdraw carrier",
							withdrawdata: "",
							withdrawmodal: false,
							withdraw_reason: ""
						})

					}
					else {

						this.setState({
							show: true,
							basicType: "danger",
							basicTitle: "Failed to withdraw carrier"
						})
					}
				})
		} else {
			this.setState({
				show: true,
				basicType: "warning",
				basicTitle: "Please Enter Value",
				loadshow: 'show-n',
				overly: 'show-n'
			})
		}
	}

	formSearchHandler = (event) => {
		event.preventDefault();
		this.onSubmitFormData();
	}

	onSubmitFormData = () => {
		if (this.state.struck_no.value != "" && this.state.struck_no.value != undefined) {
			this.setState({
				rowData1: [],
				coordinates: [],
				defaultradius: "",
				mwidth: "col-xl-5 col-lg-5",
				gpsradius: 15,
				loadshow: 'show-m',
				showform: 'show-m',
				showrefresh: 'show-n',
				rlatitude: "",
				rlongitude: "",
				tabplants: [],
				nsearch: "show-m",
				gpsplant: ""
			})
			var sdate = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
			var edate = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");

			var truck_no = [this.state.struck_no];
			var transportercode = [this.state.transporter_code.value];
			var parameters = {
				truck_no: this.state.struck_no.value,
			}
			redirectURL.post("/dashboard/truckreportdata", parameters)
				.then((response) => {
					var records = response.data.records;
					// console.log("records ", records);
					if (records.length == 0) {
						this.setState({
							reporttruck: 1
						});
					}
					else {
						this.setState({
							reporttruck: 0
						});
					}
				})
			// redirectURL.post("/gmap",parameters)
			// .then((response) => {
			console.log("transportercode ", transportercode)
			// })
			var formdata = {
				radius: 3000,
				latitude: "20.73568784876339",
				longitude: "79.38999444931353",
				dept_code: this.state.dept_code,
				transporter_code: JSON.stringify(transportercode),
				// status:this.state.truckstatus,
				truck_no: this.state.struck_no.value,
				trip_type: 1
			}
			var rlatitude = "20.73568784876339";
			var rlongitude = "79.38999444931353";
			//http://autometrics.in:5000/getNearByTrucks
			redirectURL.post('/vicinity/getRadiusCoordinates', formdata, {
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
					'Access-Control-Allow-Methods': 'PUT, GET, POST, DELETE, OPTIONS',
					'Access-Control-Allow-Headers': "Origin, X-Requested-With, Content-Type, Accept, Authorization"

				}
			})
				.then(
					async (response) => {
						// console.log("response ",response.data.apiData.body);
						var mrkers = JSON.parse(JSON.parse(response.data.apiData.body).trucks_data);
						// console.log("mrkers ", mrkers)
						if (mrkers.length > 0) {
							// console.log("truck_no ",truck_no)
							//console.log(transporterTrucks,"transporterTrucks")
							var markers = [];

							markers = mrkers.filter(f =>
								truck_no.filter((d) => {
									// console.log(d.value)
									// console.log("ffff",f.truck_no)
									if (d.value == f.truck_no) {

										return f;
									}
								})
							);
						}
						// console.log("markers ", markers)
						var markersarr = []
						if (markers.length > 0) {
							markers.map((item) => {
								if (item.truck_no == truck_no[0].value) {
									markersarr.push(item)
								}
							})
						}
						// console.log(markersarr, "markersfilter")
						var mapdata = []
						var rowrecords = this.state.rowData;
						if (markersarr.length > 0) {
							// console.log('markersarr ', markersarr.length)
							mapdata = await markersarr.filter(f =>
								rowrecords.filter((d) => {
									// console.log("s1")
									if (d.truck_no == f.truck_no) {
										// console.log("s2")
										// console.log("filter ", f)
										f.fence_name = d.fence_name;
										f.plant_code = d.plant_code;
										f.geofence_center = d.geofence_center;
										f.reportid = d._id;
										f.reporting_in_date = d.reporting_in_date;
										f.location_type = d.location_type;
										f.is_active = d.is_active;
										f.arrival_marked_on = d.arrival_marked_on;
										f.carrier_reported = d.carrier_reported;
										f.reported_marked_on = d.reported_marked_on;
										f.withdraw_carrier = d.withdraw_carrier;
										f.withdraw_on = d.withdraw_on;
										f.withdraw_reason = d.withdraw_reason;
										f.address = d.truckloc[0].address;
										f.area = d.truckloc[0].area;
										f.city = d.truckloc[0].city;
										f.state = d.truckloc[0].state;
										f.gps_provider = d.truckloc[0].actual_lspuser;
										return f;
									}
								})
							)
						}
						try {
							if (mapdata[0].truck_travelling_status != undefined) {
								if (mapdata[0].truck_travelling_status == 0) {
									var travelstat = "Active";
								}
								else {
									var travelstat = "Active";
								}
							}
							else {
								var travelstat = "Inctive";
							}
						}
						catch (e) {

						}
						try {
							// console.log("mapdata ", mapdata)
							if (mapdata[0].truck_travelling_status == 0) {
								var travelstatus = "Running";
							}
							else {
								var travelstatus = "Idle (" + secondsToDhms(mapdata[0].elaped_time_from_last_moved_seconds) + ")";
							}
						}
						catch (e) {

						}
						// console.log("mapdata ", mapdata
						if (mapdata.length > 0) {
							if (mapdata[0].truck_no != undefined) {
								var mtruck = mapdata[0].truck_no;
							}
							else {
								var mtruck = this.state.struck_no.value;
							}
							if (mapdata[0].transporter_name != undefined) {
								var mtrans = mapdata[0].transporter_name;
							}
							else {
								var mtrans = this.state.transporter_code.value;
							}
							if (mapdata[0].actual_lspuser != undefined) {
								var mgpsprovider = mapdata[0].actual_lspuser;
							}
							else {
								var mgpsprovider = "";
							}
							if (mapdata[0].speed != undefined) {
								var mspeed = mapdata[0].speed;
							}
							else {
								var mspeed = 0;
							}
							if (mapdata[0].timestamp != undefined) {
								var mtime = getHyphenDDMMMYYYYHHMM(mapdata[0].timestamp);
							}
							else {
								var mtime = "";
							}

							var setparams = {
								nsearch: "show-m",
								defaultradius: formdata.radius,
								coordinates: mapdata,
								totalTrucksCount: mapdata.length,
								mwidth: "col-xl-5 col-lg-5",
								// rowData:markersarr,
								radius: 5000,
								loadshow: 'show-n',
								showform: 'show-n',
								showrefresh: 'show-m',
								rlatitude: "21.144644112601775",
								rlongitude: "79.08860126768066",
								alltrucks: mapdata,

								search_truck_no: mtruck,
								search_transporter_name: mtrans,
								search_gps_provider: mgpsprovider,
								search_speed: mspeed,
								search_last_packet_time: mtime,
								search_gps_status: travelstat,
								search_address: mapdata[0].area,
								search_city: mapdata[0].city,
								search_state: mapdata[0].state,
								search_carrier_reported: mapdata[0].carrier_reported,
								search_travel_status: travelstatus
							}
						}
						else {
							var mtruck = this.state.struck_no.value;
							var mtrans = this.state.transporter_code.value;
							var mgpsprovider = "";
							var mspeed = 0;
							var mtime = "";
							var setparams = {
								nsearch: "show-m",
								defaultradius: formdata.radius,
								coordinates: mapdata,
								totalTrucksCount: mapdata.length,
								mwidth: "col-xl-5 col-lg-5",
								// rowData:markersarr,
								radius: 5000,
								loadshow: 'show-n',
								showform: 'show-n',
								showrefresh: 'show-m',
								rlatitude: "21.144644112601775",
								rlongitude: "79.08860126768066",
								alltrucks: mapdata,

								search_truck_no: mtruck,
								search_transporter_name: mtrans,
								search_gps_provider: mgpsprovider,
								search_speed: mspeed,
								search_last_packet_time: mtime,
								search_gps_status: travelstat,
								search_address: "",
								search_city: "",
								search_state: "",
								search_carrier_reported: "",
								search_travel_status: ""
							}

						}
						await this.setState(setparams);
						await this.renderMap();
					})
		}
		else {
			this.setState({
				show1: true,
				basicType1: "danger",
				basicTitle1: "Please select truck no"
			})
		}

	}


	formCarrierHandler = (event) => {
		event.preventDefault();
		var encryptjson = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

		if (this.state.plant_code.value != "") {
			var plant = this.state.plant_code.value;
			var truck_no = this.state.search_truck_no;
			var parameters = {
				dept_code: encryptjson(this.state.dept_code),
				plant_code: plant,
				truck_no: truck_no
			}
			redirectURL.post("/dashboard/createcarrier", parameters)
				.then((response) => {
					if (response.data.status == "success") {
						this.setState({
							show: true,
							basicType: "success",
							basicTitle: "Successfully added carrier item"
						})
						this.onSubmitFormData();
					}
					else {
						this.setState({
							show1: true,
							basicType1: "danger",
							basicTitle1: "Failed to add carrier"
						})
					}
				})
		}
		else {
			this.setState({
				show1: true,
				basicType1: "danger",
				basicTitle1: "Please select plant"
			})
		}

	}

	formGridCarrierHandler = (params) => {
		// event.preventDefault();
		// console.log("params ", params)
		var encryptjson = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

		var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
		var plant_code = params.plant_code;
		if (plant_code != "" && plant_code != undefined) {
			var plant = plant_code;
			var truck_no = params.truck_no;
			var parameters = {
				dept_code: encryptjson(this.state.dept_code),
				plant_code: plant,
				truck_no: truck_no
			}
			// console.log("parameters", parameters);
			redirectURL.post("/dashboard/createcarriergrid", parameters)
				.then((response) => {
					if (response.data.status == "success") {
						this.setState({
							show: true,
							basicType: "success",
							basicTitle: "Successfully added carrier item"
						})
						// this.onSubmitFormData();
						var reqparams = {
							dept_code: encode(this.state.dept_code),
							startDate: this.state.startDate,
							endDate: this.state.endDate
						}
						this.onLoadData(reqparams)
						this.onClickGeofenceHandler();
					}
					else {
						this.setState({
							show1: true,
							basicType1: "danger",
							basicTitle1: "Failed to add carrier"
						})
					}
				})
		}
		else {
			this.setState({
				show1: true,
				basicType1: "danger",
				basicTitle1: "Invalid plant code Or plant code empty"
			})
		}

	}

	onClickGeofenceHandler = () => {
		this.setState({
			loadshow: 'show-m',
		});
		var plantcodes = [];
		if (this.state.gpsplant.length > 0) {
			try {
				this.state.gpsplant.forEach(item => {
					plantcodes.push(item.value)
				})
			} catch (e) { }

		}

		// if(this.state.gpsplant != "")
		// {
		//   plantcodes.push(this.state.gpsplant.value);
		// }
		var formdata = {
			plant_codes: plantcodes,
			radius: this.state.gpsradius,
			request_type: "1",
			dept_code: this.state.dept_code.toUpperCase(),
			gps_records_flag: 0
		}

		if (plantcodes.length > 0) {
			this.submitFormHandler(formdata);

		}
		else {
			this.setState({
				show: true,
				basicType: "danger",
				basicTitle: "Please select Plant",
				loadshow: "show-n",
				overly: "show-n"
			})
		}
	}


	onCLickReportBtn = () => {
		//  console.log("Report carrier",params);
		redirectURL.post("/dashboard/truckreportdata", { truck_no: this.state.struck_no.value })
			.then((response) => {
				var record = response.data.records;
				var encryptjson = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
				var jsondata = encryptjson(JSON.stringify(record[0]));
				var parameters = {
					rownode: jsondata
				}
				redirectURL.post("/dashboard/setreportcarrier", parameters)
					.then((response) => {
						// console.log("response", response.data)
						if (response.data.status == "success") {
							this.setState({
								show: true,
								basicType: "success",
								basicTitle: "Successfully reported carrier"
							})
						}
						else {

							this.setState({
								show: true,
								basicType: "danger",
								basicTitle: "Failed to report carrier"
							})
						}
					})
			})

	}

	onCLickWithBtn = () => {
		//  console.log("Withdraw carrier",params);
		redirectURL.post("/dashboard/truckreportdata", { truck_no: this.state.struck_no.value })
			.then((response) => {
				var record = response.data.records;
				var encryptjson = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
				var jsondata = encryptjson(JSON.stringify(record[0]));
				var parameters = {
					rownode: jsondata
				}
				redirectURL.post("/dashboard/setwithdrawcarrier", parameters)
					.then((response) => {
						//console.log("response", response.data)
						if (response.data.status == "success") {
							this.setState({
								show: true,
								basicType: "success",
								basicTitle: "Successfully withdraw carrier"
							})
						}
						else {

							this.setState({
								show: true,
								basicType: "danger",
								basicTitle: "Failed to withdraw carrier"
							})
						}
					})
			});
	}

	renderMap = () => {

		loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyD1AFWPoJqPaemwGO4A7P7Q6CsUJ1TLGJk&libraries=places,drawing&callback=initMap");
		window.initMap = this.initMap
	}

	initMap = () => {
		allcords = [];
		this.state.coordinates.map((marker) => {
			allcords.push(marker);
		});
		//	console.log("allcords ", this.state.coordinates)
		var contentdata = this.state.contentString;

		var lt = 21.144644112601775;
		var ln = 79.08860126768066;

		try {
			map = new window.google.maps.Map(document.getElementById('search_map'), {
				zoom: 11,
				center: new window.google.maps.LatLng(lt, ln),
				mapTypeId: window.google.maps.MapTypeId.ROADMAP,
				/*mapTypeControl: true,
				gestureHandling: 'greedy',
				mapTypeControlOptions: {
					style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
					position: window.google.maps.ControlPosition.TOP_RIGHT
				  },
				disableDefaultUI: true,
				zoomControl: true*/
			});
		}
		catch (e) {
			// map = new window.google.maps.Map(document.getElementById('search_map'), {

		}

		circle = new window.google.maps.Circle({
			map: map
		});

		marker = new window.google.maps.Marker({
			map: map,
			draggable: true,
			animation: window.google.maps.Animation.DROP,
			//position: {lat: 28.48, lng: 77.06}
		});

		marker.addListener('click', function () {
			marker.setAnimation(window.google.maps.Animation.BOUNCE)
		});

		window.google.maps.event.addListener(map, 'click', function (event) {

			//document.getElementById("latitude").value = event.latLng.lat();
			//document.getElementById("longitude").value = event.latLng.lng();
			marker.setPosition(event.latLng);

		});
		circle.bindTo('center', marker, 'position');
		var markerLatlng1;
		//console.log("Radius ", this.state.radius)
		// console.log(allcords);
		var bounds = new window.google.maps.LatLngBounds();
		var markers = allcords.map((marker) => {

			var image = require('../../assets/icons/truck_na_24.png');
			var contentarr = []
			var headerTitle = marker.truck_no;
			contentarr.push({ "key": "Truck No", "value": marker.truck_no });
			contentarr.push({ "key": "Transporter Name", "value": marker.transporter_name });
			//contentarr.push({"key":"Distance from geofence", "value":marker.distance});
			//contentarr.push({"key":"GPS Provider", "value":marker.actual_lspuser});
			contentarr.push({ "key": "GPS Provider", "value": marker.actual_lspuser });
			contentarr.push({ "key": "Speed (km/h)", "value": Math.ceil(marker.speed) });
			contentarr.push({ "key": "Last Packet Time", "value": getHyphenDDMMMYYYYHHMM(marker.timestamp) });
			// truck_travelling_status
			if (marker.truck_travelling_status == 0) {
				var travelstat = "Active";
			}
			else {
				var travelstat = "Active";
			}
			if (marker.truck_travelling_status == 0) {
				var travellingstatus = "Running";
			}
			else {
				var travellingstatus = "Idle (" + secondsToDhms(marker.elaped_time_from_last_moved_seconds) + ")";
			}
			contentarr.push({ "key": "GPS Status", "value": travelstat });
			contentarr.push({ "key": "Travelling Status", "value": travellingstatus });
			contentarr.push({ "key": "Address", "value": marker.area });
			// contentarr.push({"key":"City", "value":marker.device_city});
			contentarr.push({ "key": "State", "value": marker.state });
			var contentString = infoBox(image, headerTitle, contentarr, '')

			var infowindow = new window.google.maps.InfoWindow({
				content: contentString
			});
			//console.log("parseFloat(marker.latitude) ", parseFloat(marker.latitude))
			var markerLatlng = new window.google.maps.LatLng(parseFloat(marker.latitude), parseFloat(marker.longitude));

			var image = require('../../assets/icons/truck_na_24.png');


			var mark = new window.google.maps.Marker({
				position: markerLatlng,
				map: map,
				title: "Truck No: " + marker.truck_no,
				icon: image
			});
			mark.addListener('click', function () {
				infowindow.open(map, mark);
			});
			if (this.state.rowData1.length == 0) {
				infowindow.open(map, mark);
			}
			//
			//return mark
			// mark.setMap(map);

			bounds.extend(new window.google.maps.LatLng(parseFloat(marker.latitude), parseFloat(marker.longitude)));
		});

		if (this.state.selectedTabLat != '' && this.state.selectedTabLng != '') {
			//console.log(" this.state.selectedTabLat ",parseFloat(this.state.selectedTabLat.toFixed(6)))
			//console.log(" this.state.selectedTabLng ",parseFloat(this.state.selectedTabLng.toFixed(6)))
			markerLatlng1 = new window.google.maps.LatLng(this.state.selectedTabLat, this.state.selectedTabLng);
			//console.log("markerLatlng", markerLatlng1)
			circle = new window.google.maps.Circle({
				strokeColor: '#71caab',
				strokeOpacity: 0.7,
				strokeWeight: 1,
				fillColor: '#71caab',
				fillOpacity: 0.25,
				map: map,
				center: markerLatlng1,
				radius: Math.sqrt(this.state.radius) * 8000
			});
			bounds.extend(new window.google.maps.LatLng(parseFloat(this.state.selectedTabLat), parseFloat(this.state.selectedTabLng)));
			var mainmark = new window.google.maps.Marker({
				position: new window.google.maps.LatLng(parseFloat(this.state.selectedTabLat), parseFloat(this.state.selectedTabLng)),
				map: map,
				//title:item.plant_code
			});

			mainmark.setMap(map);
			map.setZoom(8);
			// console.log("mainmark.getPosition() ", mainmark.getPosition())
			//	map.setCenter(mainmark.getPosition());
			//map.setZoom(10)
			//map.fitBounds(bounds)
		}
		else {
			// console.log("Here")
			if (this.state.tabplants.length > 0) {
				this.state.tabplants.forEach(item => {
					// console.log("Item Marker ", item)
					markerLatlng1 = new window.google.maps.LatLng(item.lat, item.lng);
					//console.log("markerLatlng", markerLatlng1)
					circle = new window.google.maps.Circle({
						strokeColor: '#71caab',
						strokeOpacity: 0.7,
						strokeWeight: 1,
						fillColor: '#71caab',
						fillOpacity: 0.25,
						map: map,
						center: markerLatlng1,
						radius: Math.sqrt(this.state.gpsradius) * 8000
					});
					bounds.extend(new window.google.maps.LatLng(parseFloat(item.lat), parseFloat(item.lng)));
					var mainmark = new window.google.maps.Marker({
						position: new window.google.maps.LatLng(parseFloat(item.lat), parseFloat(item.lng)),
						map: map,
						title: item.plant_code
					});
					mainmark.addListener('click', (function () {
						var contentarr = []
						//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
						// var header = "Plant"
						// contentarr.push({"key":"Code", "value":item.plant_code})
						//
						// var contentString = infoBox("", header, contentarr,'')
						//
						// infowindow.setContent(contentString);
						// currentwindow = infowindow;
						// infowindow.open(map, marker);

					})(marker));
					mainmark.setMap(map);
				})
			}
		}

		map.fitBounds(bounds)
		var eventtrigger = document.getElementsByClassName('truck');
		var markerCluster = new MarkerClusterer(map, markers,
			{ imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m' });


	}

	toggleBounce() {
		if (marker.getAnimation() !== null) {
			marker.setAnimation(null);
		} else {
			marker.setAnimation(window.google.maps.Animation.BOUNCE);
		}

	}

	getDistance = (p1, p2) => {
		//console.log("p1",p1);
		//console.log("p2",p2);
		var R = 6378137; // Earth’s mean radius in meter
		var dLat = rad(p2.lat - p1.lat);
		var dLong = rad(p2.lng - p1.lng);
		var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(rad(p1.lat)) * Math.cos(rad(p2.lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
		var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		var d = R * c;
		return d / 1000; // returns the distance in meter
	};

	onShowInfo = (e) => {
		//	console.log(JSON.parse(e.target.id));
		this.setState({
			contentString: "Here Success"
		});
		var ourMarker = e.target.id;
		window.google.maps.event.trigger(ourMarker, 'click');
		var infowindow = new window.google.maps.InfoWindow({
			content: "Here Success"
		});
		//	infowindow.open(map, marker);

	}

	popmarker = (e) => {
		var truckNo = e.data.truck_no;
		var truck = [];
		var trucks = this.state.alltrucks;
		// console.log(trucks, "input truck");
		var filteredValue = trucks.filter(function (e) {
			return e.truck_no == truckNo
		});
		if (filteredValue.length > 0) {
			// console.log("Matched")
			var lat = parseFloat(filteredValue[0].latitude);
			var lng = parseFloat(filteredValue[0].longitude);
			var data = filteredValue[0];
			map.setCenter(new window.google.maps.LatLng(lat, lng));
			map.setZoom(22);
			var markerLatlng = new window.google.maps.LatLng(lat, lng);

			if (filteredValue[0].status == 1) {
				var truckText = "Inside Plant";
				var color = "#333333";
			}
			else if (filteredValue[0].status == 2) {
				var truckText = "Going to Dealer";
				var color = "#333333";
			}
			else if (filteredValue[0].status == 3 || filteredValue[0].status == 4) {
				var truckText = "At Dealer Location";
				var color = "#333333";
			}
			else if (filteredValue[0].status == 5) {
				var truckText = "Left Destination Location";
				var color = "#333333";
			}
			else if (filteredValue[0].status == 6) {
				var truckText = "Return to Plant";
				var color = "#333333";
			}
			else if (filteredValue[0].status == 7) {
				var truckText = "Empty Truck Outside Plant";
				var color = "#333333";
			}
			else {
				var truckText = "N/A";
				var color = "#333333";
			}

			if (filteredValue[0].status == 1) {
				var image = require('../../assets/icons/truck_inside_24.png');
			}
			else if (filteredValue[0].status == 2) {
				var image = require('../../assets/icons/truck_going_24.png');
			}
			else if (filteredValue[0].status == 3 || filteredValue[0].status == 4) {
				var image = require('../../assets/icons/truck_atdealer_24.png');
			}
			else if (filteredValue[0].status == 5) {
				var image = require('../../assets/icons/truck_leftdealer_24.png');
			}
			else if (filteredValue[0].status == 6) {
				var image = require('../../assets/icons/truck_return_24.png');
			}
			else if (filteredValue[0].status == 7) {
				var image = require('../../assets/icons/truck_empty_24.png');
			}
			else {
				var image = require('../../assets/icons/truck_na_24.png');
			}
			var image = require('../../assets/icons/truck_na_24.png');
			var marker = new window.google.maps.Marker({
				position: markerLatlng,
				map: map,
				title: data.truck_no,
				icon: image
			});


			var contentarr = []
			var headerTitle = filteredValue[0].truck_no;
			contentarr.push({ "key": "Truck No", "value": filteredValue[0].truck_no });
			contentarr.push({ "key": "Transporter Name", "value": filteredValue[0].transporter_name });
			//contentarr.push({"key":"Distance from geofence", "value":trucks[i].distance});
			//contentarr.push({"key":"GPS Provider", "value":filteredValue[0].actual_lspuser});
			contentarr.push({ "key": "GPS Provider", "value": filteredValue[0].actual_lspuser });
			contentarr.push({ "key": "Speed (KMPH)", "value": filteredValue[0].speed });
			contentarr.push({ "key": "Last Packet Time", "value": getHyphenDDMMMYYYYHHMM(filteredValue[0].timestamp) });
			// contentarr.push({"key":"Address", "value":filteredValue[0].area+", "+filteredValue[0].state});
			if (filteredValue[0].truck_travelling_status == 1) {
				var travelstat = "Active";
			}
			else {
				var travelstat = "Active";
			} if (marker.truck_travelling_status == 0) {
				var travellingstatus = "Running";
			}
			else {
				var travellingstatus = "Idle (" + secondsToDhms(filteredValue[0].elaped_time_from_last_moved_seconds) + ")";
			}
			contentarr.push({ "key": "GPS Status", "value": travelstat });
			contentarr.push({ "key": "Travelling Status", "value": travellingstatus });
			contentarr.push({ "key": "Address", "value": filteredValue[0].area });
			// contentarr.push({"key":"City", "value":filteredValue[0].device_city});
			contentarr.push({ "key": "State", "value": filteredValue[0].state });

			var contentString = infoBox(image, headerTitle, contentarr, '')
			//console.log(contentarr,"contentarr")
			var infowindow = new window.google.maps.InfoWindow({
				content: contentString
			});

			marker.setAnimation(window.google.maps.Animation.DROP)
			marker.addListener('click', function () {
				infowindow.open(map, marker);
			});

			marker.setMap(map);
		}
		else {
			this.setState({
				show: true, basicType: 'warning',
				basicTitle: "No Data found.",
				loadshow: 'show-n',
				showform: 'show-m',
			})
		}
		//console.log(filteredValue);

	}

	resetCountersData = () => {
		$("#endDate").val("");
		$("#startDate").val("");
		this.setState({
			truck_no: { "value": "", "label": "Select Truck" },
			geofence_name: { "value": "", "label": "Select Parking" }
		})
		// window.location.reload();
	}

	truckFormHandler = () => {
		//e.preventDefault();

		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmittedSearchByTruckNo
			}
			googleAnalytics.logEvent(eventOptions);
		}
		var truck = []
		var trucks = this.state.alltrucks;
		var struck = this.state.truckno
		var filteredValue = trucks.filter(function (e) {
			return e.truck_no == struck
		});
		// console.log(filteredValue,"filteredValue")
		if (filteredValue.length > 0) {
			// console.log("Matched")
			var lat = parseFloat(filteredValue[0].latitude);
			var lng = parseFloat(filteredValue[0].longitude);
			var data = filteredValue[0];
			// map.setCenter(new window.google.maps.LatLng(lat,lng));
			// map.setZoom(22);
			var markerLatlng = new window.google.maps.LatLng(lat, lng);

			var image = require('../../assets/icons/truck_na_24.png');
			var marker = new window.google.maps.Marker({
				position: markerLatlng,
				map: map,
				title: data.truck_no,
				icon: image
			});



			var contentarr = []
			var headerTitle = filteredValue[0].truck_no;
			// contentarr.push({"key":"Truck No", "value":filteredValue[0].truck_no});
			contentarr.push({ "key": "Transporter Name", "value": filteredValue[0].transporter_name });
			//contentarr.push({"key":"Distance from geofence", "value":trucks[i].distance});
			//contentarr.push({"key":"GPS Provider", "value":filteredValue[0].actual_lspuser});
			contentarr.push({ "key": "GPS Provider", "value": "Enmovil" });
			contentarr.push({ "key": "Speed (KMPH)", "value": filteredValue[0].speed });
			contentarr.push({ "key": "Last Packet Time", "value": getHyphenDDMMMYYYYHHMM(filteredValue[0].timestamp) });
			// contentarr.push({"key":"Address", "value":filteredValue[0].area+", "+filteredValue[0].state});
			contentarr.push({ "key": "Address", "value": filteredValue[0].area });
			// contentarr.push({"key":"City", "value":filteredValue[0].device_city});
			contentarr.push({ "key": "State", "value": filteredValue[0].state });

			var contentString = infoBox(image, headerTitle, contentarr, '')

			var infowindow = new window.google.maps.InfoWindow({
				content: contentString
			});

			marker.setAnimation(window.google.maps.Animation.DROP)
			marker.addListener('click', function () {
				infowindow.open(map, marker);
			});

			marker.setMap(map);
		}
		else {
			this.setState({
				show: true, basicType: 'warning',
				basicTitle: "No Data found.",
				loadshow: 'show-n',
				showform: 'show-m',
			})
		}



	}

	changeHandler = (event) => {
		// console.log("Select", event.target.value);
		// console.log("SelectName", event.target.name);
		let name = event.target.name;
		let value = event.target.value;
		this.setState({ [name]: value });
	}

	changeLoadingHandler = (e) => {
		const re = /^[0-9\b]+$/;
		if (e.target.value === '' || re.test(e.target.value)) {
			let name = e.target.name;
			let value = e.target.value;
			this.setState({ [name]: value });
		}
	}
	onChangePlantCode = async gpsplant => {
		//console.log("Select", event.target.value);
		await this.setState(
			{ gpsplant },
			// () => console.log(`Option selected:`, this.state.gpsplant)
		);

	}

	formGeofenceHandler = (event) => {
		event.preventDefault()
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmitted
			}
			googleAnalytics.logEvent(eventOptions);
		}

		this.setState({
			loadshow: 'show-m',
		});
		var plantcodes = [];
		if (this.state.gpsplant.length > 0) {
			try {
				this.state.gpsplant.forEach(item => {
					plantcodes.push(item.value)
				})
			} catch (e) { }

		}

		// if(this.state.gpsplant != "")
		// {
		//   plantcodes.push(this.state.gpsplant.value);
		// }
		var formdata = {
			plant_codes: plantcodes,
			radius: this.state.gpsradius,
			request_type: "1",
			dept_code: this.state.dept_code.toUpperCase(),
			gps_records_flag: 0
		}

		if (plantcodes.length > 0) {
			this.submitFormHandler(formdata);

		}
		else {
			this.setState({
				show: true,
				basicType: "danger",
				basicTitle: "Please select Plant",
				loadshow: "show-n",
				overly: "show-n"
			})
		}
	}

	submitFormHandler = (formdata) => {
		this.setState({
			loadshow: "show-m"
		})
		var urlpath = '/dashboard/plantRadiusCoordinates';
		redirectURL.post(urlpath, formdata)
			.then(
				async (response) => {
					// console.log("response", response);

					var tabplants = [];
					// console.log("response.data.cluster_centriods ", response.data.cluster_centriods);
					if (response.data.cluster_centriods.length > 0) {
						// console.log("consigners ", this.state.gpsplant)
						response.data.cluster_centriods.forEach(item => {

							item.label = this.state.gpsplant[0].value;
							tabplants.push(item);
						})
					}
					var atrucks = JSON.parse(response.data.trucks_data);
					var trucks_data = [];
					if (atrucks.length > 0) {
						atrucks.map((item) => {
							var d1 = moment.parseZone(new Date()).format("YYYY-MM-DD HH:mm:ss")
							var d2 = moment.parseZone(item.timestamp).format("YYYY-MM-DD HH:mm:ss")
							var ms = moment(d1, "YYYY-MM-DD HH:mm:ss").diff(moment(d2, "YYYY-MM-DD HH:mm:ss"));
							var d = moment.duration(ms);
							var s = Math.floor(d.asHours());
							// console.log("d1 ", d1)
							// console.log("d2 ", d2)
							// console.log("Hrs ", s)
							item.travelstatus = s;
							// active inactive status based on hours greater than 24 hours
							if (s >= 24) {
								item.gps_status = "Inactive";
							}
							else {
								item.gps_status = "Active";
							}
							trucks_data.push(item);
						})
					}
					//allcords=[];
					var allmarkers = response.data.trucks_data;
					// console.log("allmarkers ", trucks_data);
					var dCode = this.state.dept_code;
					if (trucks_data.length > 0) {
						//console.log("UPpcase Dept ", dCode);
						var markers = [];
						var mainlat = '';
						var mainlng = '';
						var maincoords = [];
						var mlat = '';
						var mlng = '';
						// console.log("tabplants ", tabplants)
						this.setState({
							defaultradius: formdata.radius,
							coordinates: trucks_data,
							alltrucks: trucks_data,
							mwidth: "col-xl-7 col-lg-7",
							rowData1: trucks_data,
							originalrowData: trucks_data,
							gpsradius: formdata.radius,
							loadshow: 'show-n',
							showform: 'show-n',
							showrefresh: 'show-m',
							rlatitude: mlat,
							rlongitude: mlng,
							tabplants: tabplants,
							nsearch: "show-n"
						});


						var coords = markers;
						var p1 = { lat: parseFloat(formdata.latitude), lng: parseFloat(formdata.longitude) };
						// console.log("allcords.length ", allcords)

						window.initMap = await this.initMap
						await this.renderMap();
					}
					else {
						this.setState({
							show: true, basicType: 'warning', basicTitle: "No Data found.",
							loadshow: 'show-n',
							showform: 'show-m',
						});

					}


				})
			.catch(function (error) {
				console.log(error);
			});
	}
	onClickShowManualParkingForm = async () => {
		loadDateTimeScript()

		var currentdate = moment.parseZone().format("DD-MM-YYYY HH:mm");
		$("#manual_reported_on").val(currentdate);
		this.setState({
			slideAddManualForm: "slider-translate-30p",
			overly: "show-m",
			manual_reported_on: currentdate
		})
	}

	onSubmitManualParking = (event) => {
		event.preventDefault();
		var trans = $("#manual_transporter_hidden").val();
		if (trans == "") {
			if (this.state.manual_transporter.value != "") {
				var transporter_code = this.state.manual_transporter.value;
				var transporter_name = this.state.manual_transporter.label;
			}
			else {
				var transporter = "";
			}
		}
		else {
			var transporter_code = $("#manual_transporter_code").val();
			var transporter_name = $("#manual_transporter_hidden").val();

		}


		if (this.state.manual_trucktype.value != "") {
			var trucktype = this.state.manual_trucktype.value;
		}
		else {
			var trucktype = "";
		}

		if (this.state.manual_parkingyard.value != "") {
			var parkingyard = this.state.manual_parkingyard.value;
		}
		else {
			var parkingyard = "";
		}
		var truck_no = $("#manual_truck").val();
		// var reported_on = $("#manual_reported_on").val();
		var reported_on = this.state.manual_reported_on;
		var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

		var parameters = {
			truck_no: truck_no,
			transporter_code: transporter_code,
			transporter_name: transporter_name,
			truck_type: trucktype,
			reported_on: reported_on,
			fence_name: parkingyard,
			dept_code: encode(this.state.dept_code),
			loading_capacity: this.state.loading_capacity
		}
		// console.log("paraets ", parameters);
		if (truck_no != "" && transporter_code != "" && trucktype != "" && reported_on != "" && parkingyard != "") {
			redirectURL.post("/dashboard/savereportcarrier", parameters)
				.then((response) => {
					if (response.data.status == "success") {

						this.setState({
							show: true,
							basicType: "success",
							basicTitle: "Successfully added item",
							overly: "show-n",
							slideAddManualForm: "",
							manual_parkingyard: { "value": "", "label": "Select Parking Yard" },
							manual_transporter: { "value": "", "label": "Select Transporter" },
							manual_trucktype: { "value": "", "label": "Select Type" }
						});
						var reqparams = {
							dept_code: encode(this.state.dept_code),
							startDate: this.state.startDate,
							endDate: this.state.endDate
						}
						this.onLoadData(reqparams)

						var truck_no = $("#manual_truck").val("");
						var cdt = moment.parseZone().format("DD-MM-YYYY HH:mm")
						this.setState({
							manual_reported_on: cdt
						})
						// var reported_on = $("#manual_reported_on").val(cdt);
					}
					else {
						this.setState({
							show: true,
							basicType: "danger",
							basicTitle: "Failed to add item"
						})
					}
				})
		}
		else {
			this.setState({
				show: true,
				basicType: "danger",
				basicTitle: "All fields are mandatory"
			})
		}
	}

	initalList = (event) => {
		$(".trucks-dropdown").removeClass("show-n");
		var datasetarr = this.state.struckslist;
		// console.log("dataset",dataset);
		var dataset = []
		if (datasetarr.length > 0) {
			datasetarr.map((item) => {
				dataset.push(item.value)
			})

		}
		dataset = dataset.slice(0, 100);

		this.setState({
			filteredData: dataset,
			showTrucksList: "show-m",
		})
	}

	handlerManualTruckForm = async (event) => {
		try {
			$(".trucks-dropdown").removeClass("show-n");
			var datasetarr = this.state.struckslist;
			// console.log("dataset",dataset);
			var dataset = []
			if (datasetarr.length > 0) {
				datasetarr.map((item) => {
					dataset.push(item.value)
				})

			}
			var tptunqrecords = []

			tptunqrecords = dataset

			var filteredResult = tptunqrecords.filter(function (e) {
				if (e != "" && e != null) {

					return e.toString().toLowerCase().includes($("#manual_truck").val().toLocaleLowerCase());
				}

			});
			// var tno = $("#manual_truck").val();
			// if(tno != "" && tno != undefined)
			// {
			// 	var q = {
			// 		truck_no:tno
			// 	}
			// 	redirectURL.post("/dashboard/trucktransporter",q)
			// 	.then((response) => {
			// 		console.log("resposne ", response.data)
			// 	})
			// }
			filteredResult = filteredResult.slice(0, 100);
			await this.setState({
				filteredData: filteredResult,
				showTrucksList: "show-m",
			});
		} catch (e) { }
	}

	onBlurData = () => {
		var tno = $("#manual_truck").val();;
		if (tno != "" && tno != undefined && tno.length >= 10) {
			var q = {
				truck_no: tno
			}
			redirectURL.post("/dashboard/trucktransporter", q)
				.then((response) => {
					$("#manual_transporter_hidden").addClass("show-n")
					$("#manual_transporter_hidden").removeClass("show-m")
					$("#manual_transporter_hidden").val("");
					$("#manual_transporter_code").val("");
					$("#manual_transporter").removeClass("show-n")
					$("#manual_transporter").addClass("show-m")
				})
		}
	}

	onChangeManualPlantItem = (manual_parkingyard) => {
		this.setState(
			{ manual_parkingyard },
			// () => console.log(`Name  Option selected:`, this.state.manual_parkingyard)
		);

		this.setState({
			errorMessage: '',
			successMessage: ''
		})
	}

	handlerManualDateTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);

		var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate()) + " " + d.getHours() + ":" + (d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes());

		this.setState({
			manual_reported_on: startdate
		});
		//let value = event.target.value;
		//this.setState({'startDate':value});
	}

	//countersFunction
	onClickCounterShowData = (params) => {
		if (googleAnalytics.page.enableGA) {
			let eventLabel = null;
			if (params) {
				eventLabel = googleAnalytics.page.kpi[params];
				//console.log("eventLabel = ", eventLabel);
			}

			let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventCounterAction,
				"label": eventLabel,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		const { allrowData, parkingYardList, parkingOutPlantIn, okInParking, notOkInParking, calledForLoadList, withdrawList,trucksArr } = this.state
		// console.log("trucksArr",trucksArr)
		switch (params) {
			case 'total':
				this.setState({
					totalOverDose: true,
					// rowData:this.state.allrowData
					rowData: allrowData,
					isOnTrip : 0
				})
				break;
			case 'parkingYardList':
				this.setState({
					totalOverDose: false,
					rowData: parkingYardList,
					isOnTrip : 0
				})
				break;
			case 'okInParking':
				this.setState({
					totalOverDose: false,
					rowData: okInParking,
					isOnTrip : 0
				})
				break;
			case 'notOkInParking':
				// console.log("notOkInParking")
				let pOutPIn = notOkInParking
				this.setState({
					totalOverDose: false,
					rowData: pOutPIn,
					isOnTrip : 0
				})
				break;
			case 'calledForLoading':
				this.setState({
					totalOverDose: false,
					rowData: calledForLoadList,
					isOnTrip : 0
				})
				break;
			case 'parkingOutPlantIn':
				this.setState({
					totalOverDose: true,
					rowData: parkingOutPlantIn,
					isOnTrip : 0
				})
				break;
			case 'Withdraw':
				// console.log("withdrawList11")
				this.setState({
					totalOverDose: false,
					rowData: withdrawList,
					isOnTrip : 0
				})
				break;
			case 'onTripTrucks':
				// console.log("clciked")
				this.setState({
					isOnTrip : 1,
					rowData : trucksArr
				})
				break;
			default:
				break
		}

		// if (params == "total") {
		// 	this.setState({
		// 		// rowData:this.state.allrowData
		// 		rowData: allrowData
		// 	})
		// }
		// if (params == "parkingYardList") {
		// 	this.setState({
		// 		rowData: arrivalslist // if u r seeing this the name of arrivalslist is changed to parkingYardList
		// 	})
		// }
		// if (params == "parkingOutPlantIn") {
		// 	this.setState({
		// 		rowData: parkingOutPlantIn
		// 	})
		// }
	}

	onLoadParkOutData = (reqparams) => {
		// if (this.props.match.path == "/sndparkingreport") {
		var urlpath = "/dashboard/sndparkingreport";
		// } else if (this.props.match.path == "/prtparkingreport") {
		//     urlpath = "/dashboard/prtparkingreport";
		// } else {
		//     urlpath = "/dashboard/parkingreports";
		// }
		redirectURL.post(urlpath, reqparams).then((response) => {
			var records = response.data.records;
			// console.log("rcords ", records)
			var trucks = [];
			var recordsarr = [];
			var transporters = [];
			if (records.length > 0) {
				trucks.push({ value: "", label: "Select Truck" });
				records.map((item) => {
					if (
						this.state.loginplant != "" &&
						this.state.loginplant != undefined
					) {
						if (item.plant_code == this.state.loginplant) {
							trucks.push({ value: item.truck_no, label: item.truck_no });
							if (
								item.trucks.length > 0 &&
								item.trucks[0].transporter_code != undefined &&
								item.trucks[0].transporter_code != ""
							) {
								transporters.push({
									transporter_code: item.trucks[0].transporter_code,
									transporter_name: item.trucks[0].transporter_name,
								});
							}
						}
					} else {
						trucks.push({ value: item.truck_no, label: item.truck_no });
						if (
							item.trucks.length > 0 &&
							item.trucks[0].transporter_code != undefined &&
							item.trucks[0].transporter_code != ""
						) {
							transporters.push({
								transporter_code: item.trucks[0].transporter_code,
								transporter_name: item.trucks[0].transporter_name,
							});
						}
					}
					try {
						var d1 = moment.parseZone(new Date()).format("YYYY-MM-DD HH:mm:ss");
						var d2 = moment
							.parseZone(item.truckloc[0].timestamp)
							.format("YYYY-MM-DD HH:mm:ss");
						var ms = moment(d1, "YYYY-MM-DD HH:mm:ss").diff(
							moment(d2, "YYYY-MM-DD HH:mm:ss")
						);
						var d = moment.duration(ms);
						var s = Math.floor(d.asHours());
						// console.log("d1 ", d1)
						// console.log("d2 ", d2)
						// console.log("Hrs ", s)
						item.travelstatus = s;
						if (s >= 24) {
							item.gps_status = "Inactive";
						} else {
							item.gps_status = "Active";
						}
					}
					catch (e) { }
					if (
						this.state.loginplant != "" &&
						this.state.loginplant != undefined
					) {
						if (item.plant_code == this.state.loginplant) {
							recordsarr.push(item);
						}
					} else {
						recordsarr.push(item);
					}
				});
			}

			let newArray = [];
			// Declare an empty object
			let totuniqueObject = {};
			var totobjTitle;
			// Loop for the array elements
			for (let i in transporters) {
				// Extract the title
				totobjTitle = transporters[i]["transporter_code"];

				// Use the title as the index
				totuniqueObject[totobjTitle] = transporters[i];
			}

			// Loop to push unique object into array
			for (var a in totuniqueObject) {
				newArray.push(totuniqueObject[a]);
			}
			var transporterlist = [];
			// console.log("Newrar ", newArray);
			if (newArray.length > 0) {
				newArray.map((item) => {
					transporterlist.push({
						value: item.transporter_code,
						label: item.transporter_name,
					});
				});
			}
			// recordsarr.sort(GetSortOrder("_id"));

			// console.log("prkoutrowData", recordsarr)
			this.setState({
				prkoutrowData: recordsarr,
				truckslist: trucks,
				loadshow: "show-n",
				overly: "show-n",
				// transporters:transporterlist
			});
		})
			.catch(function (error) {
				console.log(error);
			});
	}

	formParkHandler = (event) => {
		event.preventDefault();
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				category: pageTitle,
				action: this.state.eventFormAction,
				label: googleAnalytics.page.action.formSubmittedGetData,
			};
			googleAnalytics.logEvent(eventOptions);
		}
		this.setState({
			loadshow: "show-m",
		});
		//console.log("code ", this.state.dept_code)
		if (this.state.startDate != "" && this.state.endDate != "") {
			var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
			var sdate = $("#startParkDate").val();
			var edate = $("#endparkDate").val();
			if (this.state.truck_no.value != "") {
				var truck_no = this.state.truck_no.value;
			} else {
				truck_no = "";
			}
			if (this.state.geofence_name.value != "") {
				var geofence_name = this.state.geofence_name.value;
			} else {
				geofence_name = "";
			}
			this.setState({
				startDate: sdate,
				endDate: edate
			})
			var parameters = {
				dept_code: encode(this.state.dept_code),
				startDate: sdate,
				endDate: edate,
				truck_no: truck_no,
				geofence_name: geofence_name,
			};
			// console.log("Params ", parameters);
			this.onLoadParkFilterData(parameters);
		} else {
			this.setState({
				show1: true,
				basicTitle1: "Start date and end Date should  not be empty",
				basicType1: "danger",
			});
		}
	}

	onLoadParkFilterData = (reqparams) => {
		// if (this.props.match.path == "/sndparkingreport") {
		var urlpath = "/dashboard/sndparkingreport";
		// } else if (this.props.match.path == "/prtparkingreport") {
		//     urlpath = "/dashboard/prtparkingreport";
		// } else {
		//     urlpath = "/dashboard/parkingreports";
		// }
		redirectURL
			.post(urlpath, reqparams)
			.then((response) => {
				var records = response.data.records;
				// console.log("rcords ", records)
				var trucks = [];
				var recordsarr = [];
				var transporters = [];
				if (records.length > 0) {
					records.map((item) => {
						if (
							this.state.loginplant != "" &&
							this.state.loginplant != undefined
						) {
							if (item.plant_code == this.state.loginplant) {
								if (
									item.trucks.length > 0 &&
									item.trucks[0].transporter_code != undefined &&
									item.trucks[0].transporter_code != ""
								) {
									transporters.push({
										transporter_code: item.trucks[0].transporter_code,
										transporter_name: item.trucks[0].transporter_name,
									});
								}
							}
						} else {
							if (
								item.trucks.length > 0 &&
								item.trucks[0].transporter_code != undefined &&
								item.trucks[0].transporter_code != ""
							) {
								transporters.push({
									transporter_code: item.trucks[0].transporter_code,
									transporter_name: item.trucks[0].transporter_name,
								});
							}
						}

						var d1 = moment.parseZone(new Date()).format("YYYY-MM-DD HH:mm:ss");
						var d2 = moment
							.parseZone(item.truckloc[0].timestamp)
							.format("YYYY-MM-DD HH:mm:ss");
						var ms = moment(d1, "YYYY-MM-DD HH:mm:ss").diff(
							moment(d2, "YYYY-MM-DD HH:mm:ss")
						);
						var d = moment.duration(ms);
						var s = Math.floor(d.asHours());
						// console.log("d1 ", d1)
						// console.log("d2 ", d2)
						// console.log("Hrs ", s)
						item.travelstatus = s;
						if (s >= 24) {
							item.gps_status = "Inactive";
						} else {
							item.gps_status = "Active";
						}
						if (
							this.state.loginplant != "" &&
							this.state.loginplant != undefined
						) {
							if (item.plant_code == this.state.loginplant) {
								recordsarr.push(item);
							}
						} else {
							recordsarr.push(item);
						}
					});
				}

				let newArray = [];
				// Declare an empty object
				let totuniqueObject = {};
				var totobjTitle;
				// Loop for the array elements
				for (let i in transporters) {
					// Extract the title
					totobjTitle = transporters[i]["transporter_code"];

					// Use the title as the index
					totuniqueObject[totobjTitle] = transporters[i];
				}

				// Loop to push unique object into array
				for (var a in totuniqueObject) {
					newArray.push(totuniqueObject[a]);
				}
				var transporterlist = [];
				// console.log("Newrar ", newArray);
				if (newArray.length > 0) {
					newArray.map((item) => {
						transporterlist.push({
							value: item.transporter_code,
							label: item.transporter_name,
						});
					});
				}
				// recordsarr.sort(GetSortOrder("_id"));
				this.setState({
					prkoutrowData: recordsarr,
					loadshow: "show-n",
					overly: "show-n",
					// transporters:transporterlist
				});
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	onGridReadyPark = (params) => {
		this.parkGridApi = params.api;

		//console.log("Grid is: ", params);
		this.parkGridColumnApi = params.columnApi;
	};

	onGridStatePark = () => {
		//console.log(this.gridApi);

		/*Get  Current Columns State and Store in this.colState */
		this.colState = this.parkGridColumnApi.getColumnState();

		/*Get Current RowGroup Columns State and Store in this.rowGroupState */
		this.rowGroupState = this.parkGridColumnApi.getRowGroupColumns();

		/*Get Current Pivot Columns State and Store in this.pivotColumns,
		 * User should enable the Pivot mode.*/
		this.pivotColumns = this.parkGridColumnApi.getPivotColumns();

		/*Get Current Filter State and Store in window.filterState */
		window.filterState = this.parkGridApi.getFilterModel();
		this.parkGridApi.setFilterModel(window.filterState);
	};

	formChartHandler(event) {
		event.preventDefault();
		var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
		// if(this.state.startDate == "" && this.state.endDate == "")
		// {
		var sdate = $("#startChartDate").val();
		var edate = $("#endChartDate").val();
		if (sdate == "" && edate == "") {
			this.setState({
				show: true,
				basicTitle: "Please select start and end dates",
				basicType: "danger"
			})
		}
		else {
			this.setState({
				startDateC: sdate,
				endDateC: edate
			})
			var reqparams = {
				dept_code: encode(this.state.dept_code),
				startDate: sdate,
				endDate: edate
			}
			this.onLoadSummaryData(reqparams);
			this.dataSummary(reqparams);
		}

		// }
		// else {
		// 	var reqparams = {
		// 		dept_code:encode(this.state.dept_code),
		// 		startDate:this.state.startDate,
		// 		endDate:this.state.endDate
		// 	}
		// }

	}
	isNumber() {
		var evt = $("#loading_capacity").val();
		evt = (evt) ? evt : window.event;
		var charCode = (evt.which) ? evt.which : evt.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;
	}
	// onCellClicked = e =>{
	// 	// console.log("oncellclicked",e)
	// 	if (e.colDef.field == "update"){

	// 	}
	// }

	// onCellUpdateData = param =>{
	// 	console.log("params",param)
	// }

	//update button in grid last column for updating loading capacity
	onClickUpdateGridData = param => {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}

		if (param.truck_type === 'TK') {
			var mcOptions = [{ 'value': 40, 'label': 40 }, { 'value': 50, 'label': 50 }, { 'value': 60, 'label': 60 }, { 'value': 70, 'label': 70 }, { 'value': 75, 'label': 75 }, { 'value': 'others', 'label': 'others' },]
			var scOptions = [{ 'value': 0, 'label': 0 }, { 'value': 20, 'label': 20 }, { 'value': 40, 'label': 40 }, { 'value': 'others', 'label': 'others' }]
		}
		else if (param.truck_type === 'TRE') {
			var mcOptions = [{ 'value': 84, 'label': 84 },{ 'value': 86, 'label': 86 }, { 'value': 88, 'label': 88 }, { 'value': 92, 'label': 92 }, { 'value': 96, 'label': 96 }, { 'value': 100, 'label': 100 }, { 'value': 109, 'label': 109 }, { 'value': 114, 'label': 114 }]
			var scOptions = [{ 'value': 84, 'label': 84 },{ 'value': 86, 'label': 86 }, { 'value': 88, 'label': 88 }, { 'value': 92, 'label': 92 }, { 'value': 96, 'label': 96 }, { 'value': 100, 'label': 100 }]
		}
		// console.log("param",param)
		this.setState({
			withdrawmodal1: true,
			modalRowData: param,
			mcOptions,
			scOptions,
			mcLoadingCapacityVal: { 'value': param.mc_loading_capacity, 'label': param.mc_loading_capacity },
			scLoadingCapacityVal: { 'value': param.sc_loading_capacity, 'label': param.sc_loading_capacity }
		})

	}

	changeLoadingCapHandler = mcLoadingCapacityVal => {
		// console.log("mcLoadingCapacityVal",mcLoadingCapacityVal)
		this.setState({ mcLoadingCapacityVal })
	}

	changeScLoadingCapHandler = scLoadingCapacityVal => {
		// console.log("scLoadingCapacityVal",scLoadingCapacityVal)
		this.setState({ scLoadingCapacityVal })
	}
	//for modal not ok options
	changeNotOkHandler = notOkType => {
		console.log("notOkType", notOkType)
		let notOkSubOptions = [];
		//values given by hero
		const fittings = [{ value: 'Gunny Bags', label: 'Gunny Bags' }, { value: 'Side body foam', label: 'Side body foam' }, { value: 'Fitting SC/MC', label: 'Fitting SC/MC' }, { value: 'Groove(Kappa)', label: 'Groove(Kappa)' }, { value: 'Back Horn', label: 'Back Horn' },
		{ value: 'Nut Bolt', label: 'Nut Bolt' }]

		const documents = [{ value: 'RC', label: 'RC' }, { value: 'Fitness certificate', label: 'Fitness certificate' }, { value: 'National Permit', label: 'National Permit' }, { value: 'Insurance', label: 'Insurance' }, { value: 'Pollution', label: 'Pollution' },
		{ value: 'Driving license', label: 'Driving license' }]

		const others = [{ value: 'Roof NG', label: 'Roof NG' }, { value: 'Driver drunk', label: 'Driver drunk' }]
		//alloting the dropdown values for not ok for reporting carrier
		if (notOkType.value === 'Fittings') {
			notOkSubOptions = fittings;
		} else if (notOkType.value === 'Documents') {
			notOkSubOptions = documents;
		}
		else if (notOkType.value === 'Others') {
			notOkSubOptions = others;
		}
		this.setState({ notOkType, notOkSubOptions })
	}
	// for modal not ok category changes
	documentCategoryHandler = documentsCategoryVal => {
		// console.log("documentsCategoryVal", documentsCategoryVal)
		this.setState({ documentsCategoryVal })
	}

	fittingsCategoryHandler = fittingsCategoryVal => {
		this.setState({ fittingsCategoryVal })
	}

	otherCategoryHandler = othersCategoryVal => {
		// console.log('othersCategoryVal', othersCategoryVal)
		this.setState({ othersCategoryVal })
	}

	withDrawCategoryHandler = withDrawVal =>{
		this.setState({withDrawVal})
	}

	onSubmitUpdateLoadingCap = e => {
		e.preventDefault()
		const { modalRowData, mcLoadingCapacityVal, scLoadingCapacityVal, dept_code } = this.state
		const loadingReason = $("#loadingReason").val()
		const fittingReason = $('#fittingReason').val()

		let params = {
			'modalRowData': modalRowData,
			'mc_loading_capacity': mcLoadingCapacityVal,
			'sc_loading_capacity': scLoadingCapacityVal,
			'loading_reason': loadingReason,
			'email': localStorage.getItem('email'),
			'username': localStorage.getItem('username'),
			fittingReason
		}

		// console.log('params4091', params)
		if (mcLoadingCapacityVal.value !== "" && mcLoadingCapacityVal.value !== undefined && scLoadingCapacityVal.value !== "" && scLoadingCapacityVal.value !== undefined) {
			redirectURL.post("/consignments/updateLoadingCapacityInParkingLot", params).then(response => {
				// console.log("response", response)
				let reqparams1 = {
					dept_code: dept_code
				}
				if (response.data == 'success') {
					this.onLoadData(reqparams1)
					$("#loadingReason").val('')
					this.setState({
						show: true,
						basicType: "success",
						basicTitle: "Successfully Updated",
						// loadshow: 'show-n',
						withdrawmodal1: false,
						mcLoadingCapacityVal: { label: "", value: "" },
						scLoadingCapacityVal: { label: "", value: "" },
					})
				} else {
					this.setState({
						show: true,
						basicType: "warning",
						basicTitle: "Failed To Update",
						loadshow: 'show-n',
						mcLoadingCapacityVal: { label: "", value: "" },
						scLoadingCapacityVal: { label: "", value: "" },
					})
				}
			})

		} else {
			this.setState({
				show: true,
				basicType: 'warning',
				basicTitle: "All Fields Are Mandatory .",
				loadshow: 'show-n',
			})
		}

	}

	//calledForLoading Function 
	onClickCalledForLoading = arg => {
		// console.log("args", arg)
		let param = {
			id: arg._id,
			truck_no: arg.truck_no
		}
		// attach truck is a renamed version of called for loading previously.
		// this.setState({
		// 	loadshow : 'show-m',
		// 	overly: 'show-m'
		// })
		redirectURL.post('/dashboard/markCalledForLoading', param).then(response => {
			// console.log('response', response.data)
			if (response.data == 'success') {
				this.onLoadData()
				this.setState({
					show: true,
					basicType: "success",
					basicTitle: "Successfully Updated",
					// loadshow: 'show-n',
				})
			}
			else {
				this.setState({
					show: true,
					basicType: "warning",
					basicTitle: response.data,
					loadshow: 'show-n',
				})
			}
		})
	}

	// onChangeLoadingType = e =>{
	// 	console.log("e",e)
	// }

	onClickSaveGridState = () => {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Save Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		try {
			window.colState = this.gridColumnApi.getColumnState();
			window.groupState = this.gridColumnApi.getColumnGroupState();
			window.sortState = this.gridApi.getSortModel();
			window.filterState = this.gridApi.getFilterModel();
			let screenpage = '';

			if (this.props.match.path == "/sndparkings") {
				screenpage = 'snd carriers in Parking';
			}
			// else if (this.props.match.path == "/prttrucks") {
			// 	screenpage = 'prt trucks';
			// }
			// else if (this.props.match.path == "/tnptrucks") {
			// 	screenpage = 'tnp trucks';
			// }
			// else {
			// 	screenpage = 'all trucks';
			// }

			let reqparams = {
				gridcolumns: window.colState,
				gridgroup: window.groupState,
				gridcolsort: window.sortState,
				gridcolfilter: window.filterState,
				userId: localStorage.getItem("userid"),
				screenurl: window.location.pathname,
				screentitle: screenpage
			}
			//console.log("reqparams ", reqparams)
			redirectURL.post("/consignments/saveGridStates", reqparams)
				.then((response) => {
					//console.log("State response ",response.data)
					this.setState({
						show: true,
						basicTitle: "Successfully saved grid layout",
						basicType: "success",
						screenurl: window.location.pathname,
						screentitle: screenpage
					})
				})
				.catch(function (e) {
					console.log("Error ", e)
				})
		}
		catch (e) { }
	}

	restoreGridStates = () => {
		// console.log(this.state.usergridstate, "this.state.usergridstate.length")
		if (this.state.usergridstate.length != 0) {
			try {
				var windowstates = this.state.usergridstate;
				// console.log(this.gridColumnApi, "gridColumnAPi")
				this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
				this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
				this.gridApi.setSortModel(windowstates[0].gridcolsort);
				this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
			}
			catch (e) {
				console.log(e, "save grid data")
			}
		}
	}

	resetState = () => {

		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Reset Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		try {

			this.gridColumnApi.resetColumnState();
			this.gridColumnApi.resetColumnGroupState();
			this.gridApi.setSortModel(null);
			this.gridApi.setFilterModel(null);
			//console.log('column state reset');
			var reqparams = {
				userId: localStorage.getItem("userid"),
				screenurl: window.location.pathname,
				screentitle: this.state.screenpage
			}

			redirectURL.post("/consignments/removeUserGridState", reqparams)
				.then((response) => {
					this.setState({
						show: true,
						basicTitle: "Successfully reset default grid layout",
						basicType: "success"
					})
				})
		}
		catch (e) { }

	};

	onBtExport = () => {
		// this.gridApi.exportDataAsExcel({skipColumnHeaders : false, skipColumnGroupHeaders : false})
		let params = getParams();
		this.gridApi.exportDataAsExcel(params);	
	};

	onGridReady2  = params =>{
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
	}

	onGridState2= () => {
		//console.log(this.gridApi);

		/*Get  Current Columns State and Store in this.colState */
		this.colState = this.gridColumnApi.getColumnState();

		/*Get Current RowGroup Columns State and Store in this.rowGroupState */
		this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		/*Get Current Pivot Columns State and Store in this.pivotColumns,
		 * User should enable the Pivot mode.*/
		this.pivotColumns = this.gridColumnApi.getPivotColumns();

		/*Get Current Filter State and Store in window.filterState */
		window.filterState = this.gridApi.getFilterModel();
		this.gridApi.setFilterModel(window.filterState);
	}

	render() {
		var listData = []
		try {
			this.state.filteredData.map(function (e) {
				listData.push(<li className="dropdown-truck-no">{e}</li>)
			})
		}
		catch (e) { console.log(e) }

		const modalStyles = {
			width: '700px !important',
		}
		const modalStyles1 = {
			width: '400px',
		}

		//console.log("Rendere ",this.state.deptcode)

		const { withdrawmodal, withdrawmodal1, modalRowData, mcOptions, scOptions, mcLoadingCapacityVal, scLoadingCapacityVal, reportCarrierOC, isNotSelected, notOkType, notOkCategoryVal, notOkSubOptions, fittingsCategoryVal, documentsCategoryVal, othersCategoryVal, parkingyard, totalparks, parkingYardList, show, basicType, basicTitle, show1, basicType1, basicTitle1, rowData, parkingOutPlantIn, okInParking, calledForLoadList, paramForReorting, notOkInParking, withdrawList, totalOverDose,withDrawVal,trucksArr,onTripCount,isOnTrip } = this.state;
		const notOkOptions = [{ value: "Fittings", label: "Fittings" }, { value: "Documents", label: "Documents" }, { value: "Others", label: "Others" }]
		let mc_loading_capacity = 'NA';
		let sc_loading_capacity = 'NA';
		if (paramForReorting.mc_loading_capacity !== undefined && paramForReorting.sc_loading_capacity !== undefined && paramForReorting.mc_loading_capacity !== '' && paramForReorting.sc_loading_capacity !== '') {
			mc_loading_capacity = paramForReorting.mc_loading_capacity
			sc_loading_capacity = paramForReorting.sc_loading_capacity
		}
		const columnwithDefs = [

			// {
			// 	headerName: "Actions",
			// 	field: "_id",
			// 	width: 40,
			// 	// cellRenderer:'consignmentActions',
			// 	filter: false,
			//   resizable: true,
			//   headerCheckboxSelection: true,
			//   headerCheckboxSelectionFilteredOnly: true,
			// 	checkboxSelection: true
			// 
			// },
			{
				headerName: "GPS Status",
				field: "gps_status",
				width: 75,
				filter: true,
				resizable: true,
				pinned: 'left',
				// //filter: "agDateColumnFilter",
				// comparator: dateComparator,
				valueGetter: function (params) {
					try {
						if (params.data.gps_status != "" && params.data.gps_status != undefined) {
							if (params.data.gps_status == "Inactive") {
								return "Inactive";
							}
							else {
								return "Active";
							}

						}
						else {
							if (params.data.truckloc.length > 0) {
								if (params.data.truckloc[0].elaped_time_from_last_moved_seconds == "" || params.data.truckloc[0].elaped_time_from_last_moved_seconds == undefined) {
									return "Inactive";
								}
								else {
									return "Active";
									// if(params.data.truckloc[0].elaped_time_from_last_moved_seconds == 0)
									// {
									//     return "Travelling";
									// }
									// else{
									//     var mre = secondsToDhms(params.data.truckloc[0].elaped_time_from_last_moved_seconds);
									//     //console.log("mre ", mre)
									//     return "Idle ("+mre+")";
									// }
								}
							}
							else {
								return "";
							}
						}

					}
					catch (e) {
						return "";
					}
				},
				cellClass: function (params) {
					try {
						if (params.data.gps_status != "" && params.data.gps_status != undefined) {
							if (params.data.gps_status == "Inactive") {
								return ["maroonbg", "fbold"];
							}
							else {
								return ["greenbg", "white", "fbold"];
							}

						}
						else {
							if (params.data.truckloc.length > 0) {
								if (params.data.truckloc[0].elaped_time_from_last_moved_seconds == "" || params.data.truckloc[0].elaped_time_from_last_moved_seconds == undefined) {
									return ["maroonbg", "fbold"];
								}
								else {

									// if(params.data.truckloc[0].elaped_time_from_last_moved_seconds == 0)
									// {
									//   return ["greenfont","fbold"];
									// }
									// else{
									//   return ["redfont","fbold"];
									// }
									return ["greenbg", "white", "fbold"];
								}
							}
							else {
								return "";
							}
						}

					}
					catch (e) {
						return "";
					}
				}
			},
			{
				headerName: "Update Capacity",
				field: "update",
				width: 90,
				resizable: true,
				editable: false,
				cellRenderer: 'updateAction'
			},

			{
				headerName: "",
				field: "",
				width: 50,
				cellRenderer: 'consignmentActions'

			},
			{
				headerName: "Truck Type",
				field: "truck_type",
				width: 78,
				filter: true,
				resizable: true,
				valueGetter: function (params) {
					if (params.data.truck_type != "" && params.data.truck_type != undefined) {
						if (params.data.truck_type == "TK") {
							return "Truck";
						}
						else {
							return params.data.truck_type;
						}

					}
					else {
						return "";
					}
				}
			},
			{
				headerName: "Truck no",
				field: "truck_no",
				width: 96,
				filter: true,
				resizable: true
			},
			{
				headerName: "Transporter Name",
				field: "transportername",
				width: 200,
				filter: true,
				resizable: true,
				valueGetter: function (params) {
					try {
						if (params.data.transportername != "" && params.data.transportername != undefined) {
							return params.data.transportername;
						}
						else {
							return "";
						}

					}
					catch (e) {

					}
				}
			},
			// {
			// 	headerName: "Loading Capacity",
			// 	field: "loading_capacity",
			// 	width: 100,
			// 	filter: true,
			// 	resizable: true
			// },
			{
				headerName: "SC Loading Capacity",
				field: "sc_loading_capacity",
				width: 105,
				filter: true,
				resizable: true,
			},
			{
				headerName: "MC Loading Capacity",
				field: "mc_loading_capacity",
				width: 108,
				filter: true,
				resizable: true,
			},
			{
				headerName: "GPS Received On",
				field: "truckloc",
				width: 140,
				filter: true,
				resizable: true,
				valueGetter: function (params) {
					try {
						if (params.data.truckloc.length > 0) {
							return getHyphenDDMMMYYYYHHMM(params.data.truckloc[0].timestamp);
						}
						else {
							return "";

						}
					}
					catch (e) {

					}
				},

			},
			{
				headerName: "Reported On",
				field: "reporting_in_date",
				width: 140,
				filter: true,
				resizable: true,
				valueGetter: function (params) {
					return getHyphenDDMMMYYYYHHMM(params.data.reporting_in_date)
				},
				//filter: "agDateColumnFilter",
				comparator: dateComparator,
			},

			{
				headerName: "Duration in Parking",
				field: "parking_duration",
				width: 140,
				filter: true,
				resizable: true,
				valueGetter: function (params) {
					if (
						params.data.reporting_in_date != "" &&
						params.data.reporting_in_date != undefined
					) {
						var s1 = moment.parseZone().format("x");
						var s2 = moment
							.parseZone(params.data.reporting_in_date)
							.format("x");
						var diff = (s1 - s2) / 1000;
						// console.log("secondsToDhms(diff) ", secondsToDhms(diff));
						return secondsToDhms(diff + (330 * 60));
					} else {
						return "";
					}
				},
				//filter: "agDateColumnFilter",
				comparator: dateComparator,
			},

			{
				headerName: "GPS Provider",
				field: "truckloc",
				width: 120,
				filter: true,
				resizable: true,
				// //filter: "agDateColumnFilter",
				// comparator: dateComparator
				valueGetter: function (params) {
					try {
						if (params.data.truckloc.length > 0) {
							return params.data.truckloc[0].actual_lspuser;
						}
						else {
							return "";

						}
					}
					catch (e) {

					}
				}
			},
			{
				headerName: "Arrival Marked On",
				field: "arrival_marked_on",
				width: 140,
				filter: true,
				resizable: true,
				valueGetter: function (params) {
					try {
						if (params.data.arrival_marked_on != "" && params.data.arrival_marked_on != undefined) {
							return getHyphenDDMMMYYYYHHMM(params.data.arrival_marked_on);
						}
						else {
							return "";

						}
					}
					catch (e) {

					}
				}
			},
			{
				headerName: "Last Dispatch City",
				field: "consignee_city",
				width: 140,
				filter: true,
				resizable: true,
				valueGetter: function (params) {
					try {
						if (params.data.consignee_city != "" && params.data.consignee_city != undefined) {
							return params.data.consignee_city;
						}
						else {
							return "";
						}
					}
					catch (e) {
					}
				}

			},
			{
				headerName: "Last Dispatch Date",
				field: "last_reported_time",
				width: 140,
				filter: true,
				resizable: true,
				valueGetter: function (params) {
					try {
						if (params.data.last_reported_time != "" && params.data.last_reported_time != undefined) {
							return getHyphenDDMMMYYYYHHMM(params.data.last_reported_time);
						}
						else {
							return "";
						}
					}
					catch (e) {
					}
				}

			},
			{
				headerName: "Plant Parking",
				field: "fence_name",
				width: 130,
				filter: true,
				Routeresizable: true,
				// tooltipField : "fence_name",
			},
			{
				headerName: "Withdraw On",
				field: "withdraw_on",
				width: 140,
				filter: true,
				resizable: true,
				valueGetter: function (params) {
					try {
						if (params.data.withdraw_on != "" && params.data.withdraw_on != undefined) {
							return getHyphenDDMMMYYYYHHMM(params.data.withdraw_on);
						}
						else {
							return "";

						}
					}
					catch (e) {

					}
				}

			},
			{
				headerName: "Withdraw Reason",
				field: "withdraw_reason",
				width: 140,
				filter: true,
				resizable: true,
				// //filter: "agDateColumnFilter",
				// comparator: dateComparator,

			},
			{
				headerName: "Fitting Type",
				field: "fittingReason",
				width: 140,
				filter: true,
				resizable: true,
				// //filter: "agDateColumnFilter",
				// comparator: dateComparator,
			},
			{
				headerName: "Carrier Rejection Reason",
				field: "carrier_rejection_reason",
				width: 140,
				filter: true,
				resizable: true,
				tooltipField: "carrier_rejection_reason",
				// tooltipComponent: CustomTooltip,
				// tooltipValueGetter: tooltipValueGetter,
				// valueGetter: args => {
				// 	try {
				// 		const crs = args.data.carrier_rejection_reason
				// 		if (crs !== "" && crs !== undefined) return crs
				// 		return ""
				// 	} catch (e) { }
				// },
				// tooltipField : args => {
				// 	try {
				// 		const crs = args.data.carrier_rejection_reason
				// 		if (crs !== "" && crs !== undefined) return crs
				// 		return ""
				// 	} catch (e) { }
				// }
				// //filter: "agDateColumnFilter",

			},

			// {
			// 	headerName: "Gate In Time",
			// 	field: "gate_in_time",
			// 	width: 140,
			// 	filter: true,
			//   resizable: true,
			//   valueGetter:function(params)
			//   {
			// 		if(params.data.gate_in_time != "" && params.data.gate_in_time != undefined)
			// 		{
			// 			return getHyphenDDMMMYYYYHHMM(params.data.gate_in_time);
			// 		}
			// 		else {
			// 			return "";
			// 		}
			//
			// 	},
			// 	//filter: "agDateColumnFilter",
			// 	comparator: dateComparator,
			//
			// },
			//
			// {
			// 	headerName: "Gate Out Time",
			// 	field: "gate_out_time",
			// 	width: 140,
			// 	filter: true,
			//   resizable: true,
			//   valueGetter:function(params)
			//   {
			// 		if(params.data.gate_out_time != "" && params.data.gate_out_time != undefined)
			// 		{
			// 			return getHyphenDDMMMYYYYHHMM(params.data.gate_out_time);
			// 		}
			// 		else {
			// 			return "";
			// 		}
			//
			// 	},
			// 	//filter: "agDateColumnFilter",
			// 	comparator: dateComparator,
			//
			// },

			// {
			// 	headerName: "Parking Out Time",
			// 	field: "parking_out_time",
			// 	width: 140,
			// 	filter: true,
			// 	resizable: true,
			// 	valueGetter:function(params)
			// 	{
			// 		if(params.data.parking_out_time != "" && params.data.parking_out_time != undefined)
			// 		{
			// 			return getHyphenDDMMMYYYYHHMM(params.data.parking_out_time);
			// 		}
			// 		else {
			// 			return "";
			// 		}
			//
			// 	},
			// 	//filter: "agDateColumnFilter",
			// 	comparator: dateComparator,
			//
			// },
			//
			// {
			// 	headerName: "Duration in Parking",
			// 	field: "parking_duration",
			// 	width: 140,
			// 	filter: true,
			// 	resizable: true,
			// 	valueGetter:function(params)
			// 	{
			// 		if(params.data.parking_out_time != "" && params.data.parking_out_time != undefined)
			// 		{
			// 			var s1 = moment.parseZone(params.data.parking_out_time).format("x");
			// 			var s2 = moment.parseZone(params.data.arrival_marked_on).format("x");
			// 			var diff = (s1-s2)/1000;
			// 			console.log("secondsToDhms(diff) ", secondsToDhms(diff))
			// 			return secondsToDhms(diff);
			// 		}
			// 		else {
			// 			return "";
			// 		}
			//
			// 	},
			// 	//filter: "agDateColumnFilter",
			// 	comparator: dateComparator,
			//
			// },

		];
		// if (totalOverDose == false) {
		//adding columns dynamically for counters other than total trucks
		columnwithDefs.splice(2, 0, {
			headerName: "",
			field: "",
			width: 140,
			cellRendererSelector: function (params) {
				try {

					// if (params.data.carrier_reported == 1 && params.data.carrier_called_for_loading !== 1 && params.data.is_parking_out !== 1) {
						if (params.data.carrier_reported == 1) {
						var rendComponent = {
							component: 'WithdrawCarrier'
						};
					}
					else {
						if (params.data.carrier_called_for_loading !== 1) {
							var rendComponent = {
								component: 'ReportCarrier'
							};
						}
					}
					return rendComponent;
				}
				catch (e) {

				}

			}
		},
			{
				headerName: "",
				field: "",
				width: 140,
				cellRendererSelector: function (params) {
					try {
						// if (params.data.carrier_called_for_loading !== 1) {
						if (params.data.carrier_reporting_status == 'ok' && params.data.is_parking_out !== 1) {
							var rendComponent = {
								component: 'CalledForLoading'
							};
						}
						else {
							rendComponent = ''
						}
						return rendComponent;
						// }
					}
					catch (e) {

					}

				}
			},
			{
				headerName: "",
				field: "",
				width: 140,
				cellRendererSelector: function (params) {
					try {
						if (params.data.carrier_called_for_loading == 1 && params.data.is_parking_out !== 1) {
							var rendComponent = {
								component: 'ParkingoutCarrier'
							};
						} else {
							rendComponent = ''
						}
						return rendComponent;

						// console.log("params.data.arrival_marked_on ", params.data.arrival_marked_on) this is the new change made on 20-4-2022
						// if (params.data.arrival_marked_on != "" && params.data.arrival_marked_on != undefined && (params.data.carrier_reporting_status !== 'not ok')) {
						// 	// console.log("params.data.withdraw_carrier ", params.data.withdraw_carrier)
						// 	if (params.data.withdraw_carrier == 1 || params.data.withdraw_carrier == "1") {

						// 	}
						// 	else {
						// 		var rendComponent = {
						// 			component: 'ParkingoutCarrier'
						// 		};
						// 	}

						// }
						// else {
						// 	var rendComponent = "";
						// }
					}
					catch (e) {

					}

				}
			})
		// }
		let hideonlyPRT = (this.state.deptcode === "LOG-PRT") ? true : false;

		const columnwithDefsOnTrip = [
			{
				headerName: "Truck No",
				field: "truck_no",
				width: 100,
				pinned: 'left',
				filter: "agSetColumnFilter",
				cellRenderer: '', resizable: true,
				//rowGroup: true
			},

			// {
			// 	headerName: "Original ETA",
			// 	field: "expected_trip_end",
			// 	width: 120,
			// 	resizable: true,
			// 	valueGetter: function (params) {
			// 		return getHyphenDDMMMYYYYHHMM(params.data.expected_trip_end);
			// 	},
			// 	comparator: dateComparator,			
			// },
			// {
			// 	headerName: "Revised ETA",
			// 	field: "revised_trip_end",
			// 	width: 120,
			// 	resizable: true,
			// 	valueGetter: function (params) {
			// 		//console.log(" ETA ", params.data)
			// 		if (params.data.revised_trip_end == '' || params.data.revised_trip_end == undefined) {
			// 			return getHyphenDDMMMYYYYHHMM(params.data.expected_trip_end);
			// 		}
			// 		else {
			// 			return getHyphenDDMMMYYYYHHMM(params.data.revised_trip_end);
			// 		}
			// 	},
			// 	// filter: "agDateColumnFilter",
			// 	comparator: dateComparator,	
			// 	cellClass: function (params) {
			// 		let hourDifference = 0;
			// 		if (params.data.revised_trip_end && params.data.expected_trip_end) {
			// 			let revisedDateOnlyMoment = moment(params.data.revised_trip_end);
			// 			let expectedDateOnlyMoment = moment(params.data.expected_trip_end);
			// 			hourDifference = revisedDateOnlyMoment.diff(expectedDateOnlyMoment, 'hours');
			// 			//console.log("diff= ", params.data.consignment_code, hourDifference);
			// 		} else {
			// 			//console.log("ELSE = ", params.data.revised_trip, params.data.expected_trip_end);
			// 		}

			// 		if (hourDifference >= 1) {
			// 			return 'bgColorDangerMedium'
			// 		}

			// 		if (hourDifference <= -1) {
			// 			return 'bgColorSuccessMedium'
			// 		}
			// 		else {
			// 			return ''
			// 		}
			// 	}

			// },

			{
				headerName: "Status",
				field: "status",
				width: 120,
				pinned: 'left',
				filter: "agSetColumnFilter",
				resizable: true,
				valueGetter: function (params) {
					//console.log("GPS ", params.data.gps_data_available);
					if (params.data.status == 1) {
						return "Inside Plant"
					}
					else if (params.data.status == 2) {
						return "In Transit"
					}
					else if (params.data.status == 3) {
						return "Near Destination"
					}
					else if (params.data.status == 4) {
						return "Reached Destination"
					}

					else if (params.data.status == 5) {
						return "Left Destination"
					}
					else {
						return ""
					}

				}
			},			
			{
				headerName: "Invoice Nos",
				field: "invoice_nos",
				width: 150,
				filter: "agSetColumnFilter",
				cellRenderer: '', resizable: true
			},

			{
				headerName: "GR Nos",
				field: "consignment_codes",
				width: 150,
				filter: "agSetColumnFilter",
				cellRenderer: '', resizable: true
			},

			{
				headerName: "Invoice Date",
				field: "invoice_time",
				width: 150,
				cellRenderer: '', resizable: true,
				valueGetter: function (params) {
					if (params.data.invoice_time != '' || params.data.invoice_time != undefined) {
						return getHyphenDDMMMYYYYHHMM(params.data.invoice_time);
					}
					else {
						return "NA";
					}
				},
				// filter: "agDateColumnFilter",
				comparator: dateComparator,

			},
			{
				headerName: "Transporter Name",
				field: "transporter_name",
				width: 150,
				filter: "agSetColumnFilter",
				cellRenderer: '', resizable: true
			},
			{
				headerName: "Zone",
				field: "zone",
				width: 150,
				filter: "agSetColumnFilter",
				cellRenderer: '', resizable: true
			},
			{
				headerName: "Consigner Code",
				field: "consigner_code",
				width: 150,
				filter: "agSetColumnFilter",
				cellRenderer: '', resizable: true
			},

			{
				headerName: "Dealer Code",
				field: "consignee_code",
				width: 120,
				filter: "agSetColumnFilter",
				cellRenderer: '', resizable: true
			},
			{
				headerName: "Dealer City",
				field: "consignee_city",
				width: 120,
				filter: "agSetColumnFilter",
				cellRenderer: '', resizable: true
			},

			{
				headerName: "Travel Status",
				field: "travel_status",
				width: 150,
				filter: "agSetColumnFilter"
			},

			{
				headerName: "Speed (kmph)",
				field: "speed",
				width: 150,
				filter: "agSetColumnFilter"
			},
			{
				headerName: "Last Location",
				field: "address",
				width: 150,
				resizable: true
			},
			{
				headerName: "Travelled Distance (KM)",
				field: "aprox_dist_travelled_from_start",
				width: 150,
				filter: "agSetColumnFilter",
				valueGetter: param =>{
					try{
						const data = parseFloat(param.data.aprox_dist_travelled_from_start)
						let otpt = Math.ceil(data)
						if( isNaN(otpt) == false ){
							return otpt
						}else{
							return ''
						}
						
					}catch(e){	}
				}
			},

			{
				headerName: "Remaining Distance from Dealers (KM)",
				field: "distance_from_dealer_location",
				width: 160,
				filter: "agSetColumnFilter",
				cellRenderer: '', resizable: true
			},

			// {
			// 	headerName: "Total Distance (KM)",
			// 	field: "total_distance_km",
			// 	width: 200,
			// 	filter: "agSetColumnFilter",
			// 	cellRenderer: '', resizable: true
			// },

			// {
			// 	headerName: "DAIMLER Distance (KM)",
			// 	field: "distance_in_km",
			// 	width: 200,
			// 	filter: "agSetColumnFilter",
			// 	cellRenderer: '', resizable: true
			// },

			// {
			// 	headerName: "Transit Time (Days)",
			// 	field: "transit_time",
			// 	width: 200,
			// 	filter: "agSetColumnFilter",
			// 	cellRenderer: '', resizable: true
			// },
			
			// {
			// 	headerName: "GPS Gate in Time",
			// 	field: "inside_fence",
			// 	width: 180,
			// 	filter: "agSetColumnFilter",
			// 	//cellRenderer:'dateFormater',
			// 	valueGetter: function (params) {
			// 		return getHyphenDDMMMYYYYHHMM(params.data.inside_fence);
			// 	},
			// 	resizable: true,
			// 	// filter: "agDateColumnFilter",
			// 	comparator: dateComparator,

			// },
			
			// {
			// 	headerName: "GPS Gate out Time",
			// 	field: "outside_fence",
			// 	width: 180,
			// 	filter: "agSetColumnFilter",
			// 	//cellRenderer:'dateFormater',
			// 	valueGetter: function (params) {
			// 		return getHyphenDDMMMYYYYHHMM(params.data.outside_fence);
			// 	},
			// 	resizable: true,
			// 	// filter: "agDateColumnFilter",
			// 	comparator: dateComparator,

			// },			
			{
				headerName: "Last Packet Time",
				field: "timestamp",
				width: 150,
				valueGetter: function (params) {
					return getHyphenDDMMMYYYYHHMM(params.data.timestamp);
				},
				resizable: true,
				// filter: "agDateColumnFilter",
				comparator: dateComparator,

			},
			{
				headerName: "GPS Provider",
				field: "actual_lspuser",
				width: 150,
				filter: "agSetColumnFilter",
				cellRenderer: '', resizable: true
			},
			
		];
		const dStyles = {
			width: this.props.width,
			height: '500px',
			marginLeft: this.props.marginLeft,

		}
		var showonlysnd = true;
		var showonlyprt = true;
		var hidecol = true;
		var columnDefs = [
			{
				headerName: "",
				field: "",
				width: 120,
				cellRendererSelector: function (params) {
					try {
						var rendComponent = {
							component: 'ParkingAddCarrier'
						};
						return rendComponent;
					}
					catch (e) {

					}

				}
			},
			{
				headerName: "Truck No",
				field: "truck_no",
				width: 140,


			},
			// 	{
			// 	  headerName: "LSP Code",
			// 	  field: "transporter_code",
			// 	  width: 200,

			//   },
			{
				headerName: "LSP Name",
				field: "transporter_name",
				width: 200,

			},

			{
				headerName: "Actual GPS Provider",
				field: "actual_lspuser",
				width: 200,

			},
			{
				headerName: "Plant Code",
				field: "plant_code",
				width: 120,

			},
			{
				headerName: "Invoice Time",
				field: "invoice_time",
				width: 150,
				hide: showonlysnd,
				valueGetter: function (params) {
					return getHyphenDDMMMYYYYHHMM(params.data.invoice_time)
				},


			},
			{
				headerName: "Distance from Location (KM)",
				field: "distance_from_coordinates",
				width: 120
			},
			{
				headerName: "Last Known City/Town",
				field: "area",
				width: 150,
			},
			{
				headerName: "Last Known State",
				field: "state",
				width: 150,
			},
			{
				headerName: "Last Packet Time",
				field: "timestamp",
				width: 150,
				valueGetter: function (params) {
					return getHyphenDDMMMYYYYHHMM(params.data.timestamp)
				}

			},
			{
				headerName: "Gate Out Time",
				field: "gate_out_time",
				width: 150,
				valueGetter: function (params) {
					return getHyphenDDMMMYYYYHHMM(params.data.gate_out_time)
				},
				hide: showonlyprt

			},

			{
				headerName: "Consignee City",
				field: "consignee_city",
				width: 140,
				hide: hidecol
			},

			{
				headerName: "Consignee State",
				field: "consignee_state",
				width: 140,
				hide: hidecol
			},
			// {
			//   headerName: "Cluster",
			//   field: "cluster",
			//   width: 120,
			//   hide:showonlysnd
			// },
			{
				headerName: "Route",
				field: "route_id",
				width: 120,
				hide: showonlysnd
			},
			{
				headerName: "GPS Status",
				field: "gps_status",
				width: 100,
				valueGetter: function (params) {
					try {
						if (params.data.gps_status != "" && params.data.gps_status != undefined) {
							return params.data.gps_status;
						}
						else {
							return "Inactive";
						}

					}
					catch (e) {
						return "";
					}
				},
				cellClass: function (params) {
					try {
						if (params.data.gps_status != "" && params.data.gps_status != undefined) {
							if (params.data.gps_status == "Inactive") {
								return ["maroonbg", "fbold"];
							}
							else {
								return ["greenbg", "white", "fbold"];
							}

						}
						else {
							return ["maroonbg", "white", "fbold"];
						}

					}
					catch (e) {
						return "";
					}
				}
			},

		];

		// Parking Out ColumnsDefs
		const pocolumnwithDefs = [

			{
				headerName: "",
				field: "",
				width: 50,
				cellRenderer: "consignmentActions",
			},
			{
				headerName: "Truck no",
				field: "truck_no",
				width: 100,
				filter: true,
				resizable: true,
			},
			{
				headerName: "Plant Parking",
				field: "fence_name",
				width: 140,
				filter: true,
				Routeresizable: true,
			},
			{
				headerName: "Transporter Name",
				field: "trucks",
				width: 200,
				filter: true,
				resizable: true,
				valueGetter: function (params) {
					try {
						if (params.data.trucks.length > 0) {
							if (
								params.data.trucks[0].transporter_name != "" &&
								params.data.trucks[0].transporter_name != undefined
							) {
								return params.data.trucks[0].transporter_name;
							} else {
								return "";
							}
						} else {
							return "NA";
						}
					} catch (e) { }
				},
			},

			{
				headerName: "Truck Type",
				field: "truck_type",
				width: 120,
				filter: true,
				resizable: true,
				valueGetter: function (params) {
					if (params.data.truck_type != "" && params.data.truck_type != undefined) {
						if (params.data.truck_type == "TK") {
							return "Truck";
						}
						else {
							return params.data.truck_type;
						}

					}
					else {
						return "here";
					}
				}
			},



			{
				headerName: "Available In Parking On",
				field: "reporting_in_date",
				width: 140,
				filter: true,
				resizable: true,
				valueGetter: function (params) {

					// console.log("params.data.reporting_in_date ", params.data.reporting_in_date)
					return getHyphenDDMMMYYYYHHMM(params.data.reporting_in_date);
				},
				//filter: "agDateColumnFilter",
				comparator: dateComparator,
			},
			{
				headerName: "Reported At Parking On",
				field: "arrival_marked_on",
				width: 140,
				filter: true,
				resizable: true,
				valueGetter: function (params) {
					try {
						if (
							params.data.arrival_marked_on != "" &&
							params.data.arrival_marked_on != undefined
						) {
							return getHyphenDDMMMYYYYHHMM(params.data.arrival_marked_on);
						} else {
							return "";
						}
					} catch (e) { }
				},
			},
			{
				headerName: "Last Dispatch City",
				field: "consignee_city",
				width: 140,
				filter: true,
				resizable: true,
				valueGetter: function (params) {
					try {
						if (params.data.consignee_city != "" && params.data.consignee_city != undefined) {
							return params.data.consignee_city;
						}
						else {
							return "";
						}
					}
					catch (e) {
					}
				}

			},
			{
				headerName: "Gate In Time",
				field: "gate_in_time",
				width: 140,
				filter: true,
				resizable: true,
				valueGetter: function (params) {
					if (
						params.data.gate_in_time != "" &&
						params.data.gate_in_time != undefined
					) {
						return getHyphenDDMMMYYYYHHMM(params.data.gate_in_time);
					} else {
						return "";
					}
				},
				//filter: "agDateColumnFilter",
				comparator: dateComparator,
			},

			{
				headerName: "Gate Out Time",
				field: "gate_out_time",
				width: 140,
				filter: true,
				resizable: true,
				valueGetter: function (params) {
					if (
						params.data.gate_out_time != "" &&
						params.data.gate_out_time != undefined
					) {
						return getHyphenDDMMMYYYYHHMM(params.data.gate_out_time);
					} else {
						return "";
					}
				},
				//filter: "agDateColumnFilter",
				comparator: dateComparator,
			},

			{
				headerName: "Parking Out Time",
				field: "parking_out_time",
				width: 140,
				filter: true,
				resizable: true,
				valueGetter: function (params) {
					if (
						params.data.parking_out_time != "" &&
						params.data.parking_out_time != undefined
					) {
						return getHyphenDDMMMYYYYHHMM(params.data.parking_out_time);
					} else {
						return "";
					}
				},
				//filter: "agDateColumnFilter",
				comparator: dateComparator,
			},

			{
				headerName: "Duration in Parking",
				field: "parking_duration",
				width: 140,
				filter: true,
				resizable: true,
				valueGetter: function (params) {
					if (
						params.data.parking_out_time != "" &&
						params.data.parking_out_time != undefined
					) {
						var s1 = moment.parseZone(params.data.parking_out_time).format("x");
						var s2 = moment
							.parseZone(params.data.arrival_marked_on)
							.format("x");
						var diff = (s1 - s2) / 1000;
						// console.log("secondsToDhms(diff) ", secondsToDhms(diff));
						return secondsToDhms(diff);
					} else {
						return "";
					}
				},
				//filter: "agDateColumnFilter",
				comparator: dateComparator,
			},
			{
				headerName: "GPS Status",
				field: "gps_status",
				width: 140,
				filter: true,
				resizable: true,
				pinned: 'left',
				// //filter: "agDateColumnFilter",
				// comparator: dateComparator,
				valueGetter: function (params) {
					try {
						if (
							params.data.gps_status != "" &&
							params.data.gps_status != undefined
						) {
							if (params.data.gps_status == "Inactive") {
								return "Inactive";
							} else {
								return "Active";
							}
						} else {
							if (params.data.truckloc.length > 0) {
								if (
									params.data.truckloc[0].elaped_time_from_last_moved_seconds ==
									"" ||
									params.data.truckloc[0].elaped_time_from_last_moved_seconds ==
									undefined
								) {
									return "Inactive";
								} else {
									return "Active";
									// if(params.data.truckloc[0].elaped_time_from_last_moved_seconds == 0)
									// {
									//     return "Travelling";
									// }
									// else{
									//     var mre = secondsToDhms(params.data.truckloc[0].elaped_time_from_last_moved_seconds);
									//     //console.log("mre ", mre)
									//     return "Idle ("+mre+")";
									// }
								}
							} else {
								return "";
							}
						}
					} catch (e) {
						return "";
					}
				},
				cellClass: function (params) {
					try {
						if (
							params.data.gps_status != "" &&
							params.data.gps_status != undefined
						) {
							if (params.data.gps_status == "Inactive") {
								return ["maroonbg", "fbold"];
							} else {
								return ["greenbg", "white", "fbold"];
							}
						} else {
							if (params.data.truckloc.length > 0) {
								if (
									params.data.truckloc[0].elaped_time_from_last_moved_seconds ==
									"" ||
									params.data.truckloc[0].elaped_time_from_last_moved_seconds ==
									undefined
								) {
									return ["maroonbg", "fbold"];
								} else {
									// if(params.data.truckloc[0].elaped_time_from_last_moved_seconds == 0)
									// {
									//   return ["greenfont","fbold"];
									// }
									// else{
									//   return ["redfont","fbold"];
									// }
									return ["greenbg", "white", "fbold"];
								}
							} else {
								return "";
							}
						}
					} catch (e) {
						return "";
					}
				},
			},
			{
				headerName: "GPS Received On",
				field: "truckloc",
				width: 140,
				filter: true,
				resizable: true,
				valueGetter: function (params) {
					try {
						if (params.data.truckloc.length > 0) {
							return getHyphenDDMMMYYYYHHMM(params.data.truckloc[0].timestamp);
						} else {
							return "";
						}
					} catch (e) { }
				},
			},

			{
				headerName: "GPS Provider",
				field: "truckloc",
				width: 120,
				filter: true,
				resizable: true,
				// //filter: "agDateColumnFilter",
				// comparator: dateComparator
				valueGetter: function (params) {
					try {
						if (params.data.truckloc.length > 0) {
							return params.data.truckloc[0].actual_lspuser;
						} else {
							return "";
						}
					} catch (e) { }
				},
			},

			{
				headerName: "Loading Bay In",
				field: "loading_bay_in",
				width: 140,
				filter: true,
				resizable: true,
				valueGetter: function (params) {
					try {
						if (params.data.loading_bay_in != "" && params.data.loading_bay_in != undefined) {
							return getHyphenDDMMMYYYYHHMM(params.data.loading_bay_in);
						} else {
							return "";
						}
					} catch (e) { }
				},
			},


			{
				headerName: "Loading Bay Out",
				field: "loading_bay_out",
				width: 140,
				filter: true,
				resizable: true,
				valueGetter: function (params) {
					try {
						if (params.data.loading_bay_out != "" && params.data.loading_bay_out != undefined) {
							return getHyphenDDMMMYYYYHHMM(params.data.loading_bay_out);
						} else {
							return "";
						}
					} catch (e) { }
				},
			},

			{
				headerName: "Withdraw On",
				field: "withdraw_on",
				width: 140,
				filter: true,
				resizable: true,
				valueGetter: function (params) {
					try {
						if (
							params.data.withdraw_on != "" &&
							params.data.withdraw_on != undefined
						) {
							return getHyphenDDMMMYYYYHHMM(params.data.withdraw_on);
						} else {
							return "";
						}
					} catch (e) { }
				},
			},
			{
				headerName: "Withdraw Reason",
				field: "withdraw_reason",
				width: 140,
				filter: true,
				resizable: true,
				// //filter: "agDateColumnFilter",
				comparator: dateComparator,
			},


		];

		const fittings = [{ value: 'Gunny Bags', label: 'Gunny Bags' }, { value: 'Side body foam', label: 'Side body foam' }, { value: 'Fitting SC/MC', label: 'Fitting SC/MC' }, { value: 'Groove(Kappa)', label: 'Groove(Kappa)' }, { value: 'Back Horn', label: 'Back Horn' },
		{ value: 'Nut Bolt', label: 'Nut Bolt' }]

		const documents = [{ value: 'RC', label: 'RC' }, { value: 'Fitness certificate', label: 'Fitness certificate' }, { value: 'National Permit', label: 'National Permit' }, { value: 'Insurance', label: 'Insurance' }, { value: 'Pollution', label: 'Pollution' },
		{ value: 'Driving license', label: 'Driving license' }]

		const others = [{ value: 'Roof NG', label: 'Roof NG' }, { value: 'Driver drunk', label: 'Driver drunk' }]

		const withDrawList = [{ value: 'No Driver', label: 'No Driver' },{ value: 'GPS device not working', label: 'GPS device not working'},{ value: 'Transporter denied to load', label: 'Transporter denied to load'},{ value: 'Repair & Maintenance', label: 'Repair & Maintenance'},{ value: 'Go to other Plant for loading', label: 'Go to other Plant for loading'},{ value: 'Others', label: 'Others'},]

		return (
			<div className="container-fluid">
				<SweetAlert
					show={show}
					type={basicType}
					title={basicTitle}
					onConfirm={this.closeAlert}
				>
				</SweetAlert>
				<SweetAlert
					show={show1}
					type={basicType1}
					title={basicTitle1}
					onConfirm={this.closeAlert1}
				>
				</SweetAlert>
				<div className="row">
					<div className="col-xl-12 col-lg-12">
						<div className="card">
							<div className="card-header">
								<h5>
									<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.pageTitle} </span>
									{/* <a href="javascript:;" onClick={this.onClickShowManualParkingForm} className={"btn btn-warning float-right"}>Report Carrier</a> */}

								</h5>
							</div>
							<div className="card-body">
								<ul className="ul-tabs mb-20p">
									<li>
										<a href="javascript:;" onClick={this.onClickTab.bind(this, 'summary')} className={"btn " + (this.state.sact)}>Summary</a>
									</li>
									<li>
										<a href="javascript:;" onClick={this.onClickTab.bind(this, 'parking')} className={"btn " + (this.state.pact)}>In Parking Yard</a>
									</li>
									{/* <li>
										<a href="javascript:;" onClick={this.onClickTab.bind(this, 'parkingout')} className={"btn " + (this.state.poutact)}>Parking Out</a>
									</li> */}
									<li>
										<a href="javascript:;" onClick={this.onClickTab.bind(this, 'truck')} className={"btn " + (this.state.tact)}>Search</a>
									</li>
									{/* <li>
										<a href="javascript:;" onClick={this.onClickTab.bind(this, 'onTrip')} className={"btn " + (this.state.on)}>On Trip</a>
									</li> */}
								</ul>
								<div className={"row col-xl-12 col-lg-12 " + (parkingyard)}>

									<form className="row theme-form col-xl-12 col-lg-12" onSubmit={this.formHandler}>
										<div className="col-xl-2 col-lg-2 form-group">
											<label>Select Parking</label>
											<Select
												placeholder={"Select Parking"}
												closeMenuOnSelect={true}
												onChange={this.onChangeParkingItem.bind(this)}
												className={"col-xl-12 col-lg-12 border-radius-0"}
												style={{ borderRadius: "0px" }}
												name="geofence_name"
												value={this.state.geofence_name}
												options={this.state.parkinglist} />
										</div>
										<div className="col-xl-2 col-lg-2 form-group">
											<label>Select Truck</label>
											<Select
												placeholder={"Select Truck"}
												closeMenuOnSelect={true}
												onChange={this.onChangeTruckItem.bind(this)}
												className={"col-xl-12 col-lg-12 border-radius-0"}
												style={{ borderRadius: "0px" }}
												name="truck_no"
												value={this.state.truck_no}
												options={this.state.truckslist} />
										</div>
										<div className="col-xl-2 col-lg-2 form-group">
											{/* <Datetime value={this.state.startDate} inputProps={{ placeholder: 'Start Date', name: 'startDate', autoComplete:'off', required:true }} dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss"  name="startDate" onChange={this.handlerStartDateTime.bind(this)} /> */}
											<label>Start Date</label>
											<input
												type="text"
												name="startDate"
												autoComplete="Off"
												id="startDate"
												placeholder="Start Date"
												className="datepicker form-control"
												onChange={this.handlerStartDateTime.bind(this)}
											/>
										</div>

										<div className="col-xl-2 col-lg-2 form-group">
											{/* <Datetime value={this.state.endDate} inputProps={{ placeholder: 'End Date', name: 'endDate', autoComplete:'off', required:true }} dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss" onChange={this.handlerEndDateTime.bind(this)} /> */}
											<label>End Date</label>
											<input
												type="text"
												name="endDate"
												autoComplete="Off"
												id="endDate"
												placeholder="End Date"
												className="datepicker form-control"
												onChange={this.handlerEndDateTime.bind(this)}
											/>
										</div>
										<div className="col-xl-1 col-lg-1 form-group">
											<button type="submit" className="btn btn-success mt-30p">Get Data</button>
										</div>
										<div className="col-xl-1 col-lg-1 form-group">
											<button onClick={this.resetCountersData} className="btn btn-info mt-30p ml-15p" >Reset Filters</button>
										</div>
									</form>
								</div>
								<div className={"col-sm-12 " + (parkingyard)}>
									<div className="col-xl-12 col-lg-12 col-sm-12 beffect">
										<div className="card">
											<div className="card-body" id="counterCardBody">
												<div className="crm-numbers pb-0">
													<div className="row">
														<div className="col cirlce-d cursorPointer" onClick={() => this.onClickCounterShowData("total")}>
															<span className="f13"><i className="icofont icofont-ui-browser f24 txt-info"></i><br /> Total Trucks</span>
															<h4 className="txt-info f40"><span className="counter"><CountUp end={(totalparks.length > 0) ? totalparks.length : 0} /></span></h4>
														</div>
														<div className="col cirlce-d cursorPointer">
															<div onClick={() => this.onClickCounterShowData("parkingYardList")}>
																<span className="f13"><i className="icofont icofont-ui-browser f24 txt-success"></i><br /> Parking Yard</span>
																<h4 className="txt-info f40"><span className="counter"><CountUp end={(parkingYardList.length > 0) ? parkingYardList.length : 0} /></span></h4>
															</div>
															<div className=" f16">
																<div className="counter txt-success-medium" onClick={() => this.onClickCounterShowData("Withdraw")}>
																	Withdraw : &nbsp;
																	<CountUp end={(withdrawList.length > 0) ? withdrawList.length : 0} />
																</div>

																<div className="counter txt-danger-medium" onClick={() => this.onClickCounterShowData("notOkInParking")}>
																	Not Ok : &nbsp;
																	<CountUp end={(notOkInParking.length > 0) ? notOkInParking.length : 0} />
																</div>
															</div>
														</div>
														<div className="col cirlce-d cursorPointer" onClick={() => this.onClickCounterShowData("okInParking")}>
															<span className="f13"><i className="icofont icofont-ui-browser f24 txt-warning"></i><br /> Ok in Parking</span>
															<h4 className="txt-info f40"><span className="counter"><CountUp end={(okInParking.length > 0) ? okInParking.length : 0} /></span></h4>
														</div>
														<div className="col cirlce-d cursorPointer" onClick={() => this.onClickCounterShowData("calledForLoading")}>
															<span className="f13"><i className="icofont icofont-ui-browser f24 txt-danger"></i><br /> Called For Loading</span>
															<h4 className="txt-info f40"><span className="counter"><CountUp end={(calledForLoadList.length > 0) ? calledForLoadList.length : 0} /></span></h4>
														</div>
														<div className="col cirlce-d cursorPointer" onClick={() => this.onClickCounterShowData("parkingOutPlantIn")}>
															<span className="f13"><i className="icofont icofont-ui-browser f24 txt-dark"></i><br /> Parking Out - Plant In</span>
															<h4 className="txt-info f40"><span className="counter"><CountUp end={(parkingOutPlantIn.length > 0) ? parkingOutPlantIn.length : 0} /></span></h4>
														</div>
														<div className="col cirlce-d cursorPointer" onClick={() => this.onClickCounterShowData("onTripTrucks")}>
															<span className="f13"><i className="icofont icofont-ui-browser f24 txt-info"></i><br /> OnTrips</span>
															<h4 className="txt-info f40"><span className="counter"><CountUp end={(onTripCount > 0) ? onTripCount : 0} /></span></h4>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className={parkingyard} style={{ marginBottom: '28px' }}>
									<div className="col-xl-12 mb-10p">
										<span className="layoutbtns">
											<button className="float-right custom-btn white btn-danger" onClick={() => this.onClickSaveGridState()}>
												<i className="icofont icofont-save"></i> Save Grid Layout
											</button>
											<button className="float-right custom-btn white btn-info" style={{ marginRight: "10px" }} onClick={() => this.resetState()}>
												<i className="icofont icofont-refresh"></i> Reset Default Layout
											</button>
										</span>
									</div>
								</div>
								<div id="myGrid" style={{ height: "580px", width: "100%" }} className={"ag-theme-balham " + (parkingyard)}>
									{/*in parking yard button grid */}
									<AgGridReact
										modules={this.state.modules}
										columnDefs={isOnTrip == 1 ? columnwithDefsOnTrip:columnwithDefs}
										defaultColDef={this.state.defaultColDef}
										rowData={rowData}
										enableCharts={true}
										enableRangeSelection={true}
										autoGroupColumnDef={this.state.autoGroupColumnDef}
										onGridReady={this.onGridReady}
										onGridState={this.onGridState}
										statusBar={this.state.statusBar}
										sideBar={this.state.sideBar}
										paginationPageSize={this.state.paginationPageSize}
										pagination={true}
										frameworkComponents={this.state.frameworkComponents}
										detailCellRendererParams={this.state.detailCellRendererParams}
										stopEditingWhenGridLosesFocus={true}
										gridOptions={{
											context: { componentParent: this }
										}}
										tooltipShowDelay={this.state.tooltipShowDelay}
										tooltipHideDelay={this.state.tooltipHideDelay}
										masterDetail={true}
										overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
										suppressCellFlash={true}
										rowClassRules={this.state.rowClassRules}
										onCellClicked={this.onCellClicked}
									// onCellEditingStopped={this.onCellUpdateData}
									//floatingFilter={true}
									//enableCellChangeFlash={true}
									//onCellClicked={this.onRowClicked.bind(this)}
									//editType={this.state.editType}
									/>
								</div>

								<div className={"row col-xl-12 col-lg-12 " + (this.state.truckinfo)}>
									<div className="row">
										<form className={" theme-form col-xl-6 col-lg-6 "} onSubmit={this.formGeofenceHandler}>
											<div className="row">

												<div className={"form-group col-xl-6 col-lg-6 "}>
													<label className="c-lbl">Select Plant *:</label>
													<Select
														closeMenuOnSelect={true}
														name="gpsplant"
														isMulti={true}
														placeholder="Select Plant"
														className="rselect"
														value={this.state.gpsplant}
														onChange={this.onChangePlantCode.bind(this)}
														options={this.state.plants} />

												</div>
												<div className={"form-group  col-xl-6 col-lg-6 " + this.state.showRadius}>
													<label className="c-lbl">Radius *:</label>
													<input
														type="text"
														name="gpsradius"
														id="gpsradius" value={this.state.gpsradius}
														onChange={this.changeHandler}
														className="form-control" placeholder="Radius" required />
												</div>

												<div className="form-group col-xl-4 col-lg-4">
													<button type="submit" className={"btn btn-success "}>Submit</button>
												</div>
											</div>
										</form>
										<form className="row theme-form col-xl-6 col-lg-6" onSubmit={this.formSearchHandler}>
											<div className="col-xl-6 col-lg-6 form-group">
												<label className="c-lbl">Select Transporter *:</label>
												<Select
													placeholder={"Select Transporter"}
													closeMenuOnSelect={true}
													onChange={this.onChangeTransporterItem.bind(this)}
													className={"border-radius-0"}
													style={{ borderRadius: "0px" }}
													name="transporter_code"
													value={this.state.transporter_code}
													options={this.state.transporters} />
											</div>
											<div className="col-xl-6 col-lg-6 form-group">
												<label className="c-lbl">Select Truck *:</label>

												<Select
													placeholder={"Select Truck"}
													closeMenuOnSelect={true}
													onChange={this.onChangeSearchTruckItem.bind(this)}
													className={"border-radius-0"}
													style={{ borderRadius: "0px" }}
													name="struck_no"
													value={this.state.struck_no}
													options={this.state.struckslist} />
											</div>

											<div className="col-xl-3 col-lg-3 form-group">
												<button type="submit" className="btn btn-success">Submit</button>
											</div>
										</form>
									</div>
									<div className="row">
										<div className="row col-lg-12 col-xl-12">
											<div id="search_map" className={this.state.mwidth} style={dStyles}></div>
											{
												(this.state.rowData1.length > 0) ?
													<div className="col-xl-5 col-lg-5 sidediv">

														<div id="myGrid1" style={{ height: "480px", width: "100%" }} className="ag-theme-balham">
															<AgGridReact
																modules={this.state.modules}
																columnDefs={columnDefs}
																defaultColDef={this.state.defaultColDef}
																rowData={this.state.rowData1}
																enableCharts={false}
																enableRangeSelection={false}
																autoGroupColumnDef={this.state.autoGroupColumnDef}
																onGridReady={this.onGridReady1}
																onGridState={this.onGridState1}
																statusBar={this.state.statusBar}
																sideBar={this.state.sideBar}
																paginationPageSize={this.state.paginationPageSize}
																pagination={true}
																//   enableRangeSelection={false}
																floatingFilter={false}
																frameworkComponents={this.state.frameworkComponents}
																//onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
																onCellClicked={this.popmarker}
																gridOptions={{
																	context: { componentParent: this }
																}}
															/>
														</div>
													</div>
													: ""}
											<div className={"col-xl-7 col-lg-7 sidediv " + (this.state.nsearch)}>
												{(this.state.reporttruck == 1 && this.state.dept_code != "all") ?

													<form className="row theme-form col-xl-12 col-lg-12" onSubmit={this.formCarrierHandler}>
														<table className="table table-bordered">
															<tr>
																<th>Truck No</th>

																<td>
																	{this.state.search_truck_no}
																</td>
															</tr>
															<tr>
																<th>Transporter Name</th>

																<td>
																	{this.state.search_transporter_name}
																</td>
															</tr>
															<tr>
																<th>GPS Provider</th>

																<td>
																	{this.state.search_gps_provider}
																</td>
															</tr>
															<tr>
																<th>Last Active On</th>

																<td>
																	{this.state.search_last_packet_time}
																</td>
															</tr>

															<tr>
																<th>GPS Status</th>

																<td>
																	{this.state.search_gps_status}
																</td>
															</tr>
															<tr>
																<th>Travel Status</th>

																<td>
																	{this.state.search_travel_status}
																</td>
															</tr>

															<tr>
																<th>Speed (km/h)</th>

																<td>
																	{this.state.search_speed}
																</td>
															</tr>

															<tr>
																<th>Address</th>

																<td>
																	{this.state.search_address}
																</td>
															</tr>
														</table>
														{/*  <div className="col-xl-12 col-lg-12 form-group">
															<Select
															placeholder={"Select Parking Yard"}
															closeMenuOnSelect={true}
															onChange={this.onChangePlantItem.bind(this)}
															className={"col-xl-12 col-lg-12 border-radius-0"}
															style={{borderRadius:"0px"}}
															name="plant_code"
															value={this.state.plant_code}
															options={this.state.plants}
															/>
														</div>
															<div className="col-xl-12 col-lg-12 form-group">
																<button type="submit" className="btn btn-success">Create</button>
															</div>
															*/}
													</form>
													:
													((this.state.coordinates.length > 0) ?
														<table className="table table-bordered">
															<tr>
																<th>Truck No</th>

																<td>
																	{this.state.search_truck_no}
																</td>
															</tr>
															<tr>
																<th>Transporter Name</th>

																<td>
																	{this.state.search_transporter_name}
																</td>
															</tr>
															<tr>
																<th>GPS Provider</th>

																<td>
																	{this.state.search_gps_provider}
																</td>
															</tr>
															<tr>
																<th>Last Active On</th>

																<td>
																	{this.state.search_last_packet_time}
																</td>
															</tr>

															<tr>
																<th>GPS Status</th>

																<td>
																	{this.state.search_gps_status}
																</td>
															</tr>
															<tr>
																<th>Travel Status</th>

																<td>
																	{this.state.search_travel_status}
																</td>
															</tr>

															<tr>
																<th>Speed (Km/h)</th>

																<td>
																	{this.state.search_speed}
																</td>
															</tr>

															<tr>
																<th>Address</th>

																<td>
																	{this.state.search_address}
																</td>
															</tr>
															<tr>
																<td>
																	{/*(this.state.search_carrier_reported == 1)?
																		<button onClick={this.onCLickWithBtn.bind(this)} type="button" className="btn btn-danger"><Withdraw Carrier</button>
																			:

																		<button onClick={this.onCLickReportBtn.bind(this)} type="button" className="btn btn-success">Report Carrier</button>
																	*/}
																</td>
															</tr>
														</table>
														: "")
												}
											</div>
										</div>
									</div>
								</div>
								{/* charts bars summary */}
								<div className={"row col-xl-12 col-lg-12 " + (this.state.summary)}>
									<div className="row">
										<form
											className="row theme-form col-xl-12 col-lg-12"
											onSubmit={this.formChartHandler.bind(this)}
										>
											<div className="col-xl-2 col-lg-2 form-group">
												{/* <Datetime value={this.state.startDate} inputProps={{ placeholder: 'Start Date', name: 'startDate', autoComplete:'off', required:true }} dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss"  name="startDate" onChange={this.handlerStartDateTime.bind(this)} /> */}
												<label>Start Date</label>
												<input
													type="text"
													name="startDate"
													autoComplete="Off"
													id="startChartDate"
													placeholder="Start Date"
													className="datepicker form-control"
													onChange={this.handlerStartDateTime.bind(this)}
													value={this.state.startDateC}
												/>
											</div>

											<div className="col-xl-2 col-lg-2 form-group">
												{/* <Datetime value={this.state.endDate} inputProps={{ placeholder: 'End Date', name: 'endDate', autoComplete:'off', required:true }} dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss" onChange={this.handlerEndDateTime.bind(this)} /> */}
												<label>End Date</label>
												<input
													type="text"
													name="endDate"
													autoComplete="Off"
													id="endChartDate"
													placeholder="End Date"
													className="datepicker form-control"
													onChange={this.handlerEndDateTime.bind(this)}
													value={this.state.endDateC}
												/>
											</div>

											<div className="col-xl-2 col-lg-2 form-group">
												<button type="submit" className="btn btn-success mt-30p">
													Get Data
												</button>
											</div>
										</form>
										{this.state.departmentarray.length > 0 ?

											this.state.departmentarray.map(item =>
												<div className="col-xl-12 col-lg-12">
													<ParkingSummaryChartStackTotal
														title={"Current Plant wise Parking"}
														subtitle={"List of carriers currently available for utilization"}
														context={this}
														reportseries={item.reportseries}
														delayedseries={item.delayedseries}
														bothseries={item.bothseries}
														bothseriesm={item.bothseriesm}
														drildown={item.chartdrildown}
														categories={item.categories}
														yaxistitle={item.yaxistitle}
														isfilter={item.isfilter}
														dept_code={this.state.dept_code}
													/>
												</div>

											)
											: ""}
										<div className="col-sm-12">
											<h4>Parking Summary
											<span className="layoutbtns pull-right">
												<button
													className="float-right export-btn white btn-danger"
													onClick={() => this.onBtExport()}
												>
													Export to Excel
												</button>
											</span>
											</h4>
											<div id="myGrid2" style={{ height: "480px", width: "100%" }} className="ag-theme-balham">
												<AgGridReact
													modules={this.state.modules}
													columnDefs={this.state.summarycolumnDefs}
													defaultColDef={this.state.defaultColDef}
													rowData={this.state.summaryRowData}
													enableCharts={false}
													enableRangeSelection={false}
													onGridReady={this.onGridReady2}
													onGridState={this.onGridState2}
													statusBar={this.state.statusBar}
													defaultExcelExportParams={this.state.defaultExcelExportParams}
													sideBar={this.state.sideBar}
													paginationPageSize={this.state.paginationPageSize}
													pagination={true}
													floatingFilter={false}
													gridOptions={{
														context: { componentParent: this }
													}}
													// frameworkComponents={this.state.frameworkComponents}
													// autoGroupColumnDef={this.state.autoGroupColumnDef}
												/>
											</div>
										</div>
										{this.state.overalldepartmentarray.length > 0 ?

											this.state.overalldepartmentarray.map(item =>
												<div className="col-xl-12 col-lg-12">
													<ParkingSummaryChartStackTotal
														title={"Overall Plant wise Parking"}
														subtitle={"Total carriers that were available between the selected duration"}
														context={this}
														reportseries={item.reportseries}
														delayedseries={item.delayedseries}
														bothseries={item.bothseries}
														bothseriesm={item.bothseriesm}
														drildown={item.chartdrildown}
														categories={item.categories}
														yaxistitle={item.yaxistitle}
														isfilter={item.isfilter}
														dept_code={this.state.dept_code}
													/>
												</div>

											)
											: ""}

									</div>

									<div className="row">
										{this.state.transporteriwsearray.length > 0 ?

											this.state.transporteriwsearray.map(item =>
												<div className="col-xl-12 col-lg-12">
													<ParkingSummaryChartStack
														title={"Transporter wise Parking"}
														subtitle={"List of carriers currently available for utilization"}
														context={this}
														reportseries={item.reportseries}
														delayedseries={item.delayedseries}
														bothseries={item.bothseries}
														drildown={item.chartdrildown}
														categories={item.categories}
														yaxistitle={item.yaxistitle}
														isfilter={item.isfilter}
														dept_code={this.state.dept_code}
													/>
												</div>

											)
											: ""}
										{this.state.overtransporteriwsearray.length > 0 ?

											this.state.overtransporteriwsearray.map(item =>
												<div className="col-xl-12 col-lg-12">
													<ParkingSummaryChartStack
														title={"Overall Transporter wise Parking"}
														subtitle={"Total carriers that were available between the selected duration"}
														context={this}
														reportseries={item.reportseries}
														delayedseries={item.delayedseries}
														bothseries={item.bothseries}
														drildown={item.chartdrildown}
														categories={item.categories}
														yaxistitle={item.yaxistitle}
														isfilter={item.isfilter}
														dept_code={this.state.dept_code}
													/>
												</div>

											)
											: ""}

									</div>
								</div>
								<div className={"col-xl-12 col-lg-12 " + (this.state.parkingyardout)}>
									{/*<ParkingOutReport
												/>*/}
									<form
										className="row theme-form col-xl-12 col-lg-12"
										onSubmit={this.formParkHandler}
									>
										<div className="col-xl-2 col-lg-2 form-group">
											<label>Select Parking</label>
											<Select
												placeholder={"Select Parking"}
												closeMenuOnSelect={true}
												onChange={this.onChangeParkingItem.bind(this)}
												className={"col-xl-12 col-lg-12 border-radius-0"}
												style={{ borderRadius: "0px" }}
												name="geofence_name"
												value={this.state.geofence_name}
												options={this.state.parkinglist}
											/>
										</div>
										<div className="col-xl-2 col-lg-2 form-group">
											<label>Select Truck</label>
											<Select
												placeholder={"Select Truck"}
												closeMenuOnSelect={true}
												onChange={this.onChangeTruckItem.bind(this)}
												className={"col-xl-12 col-lg-12 border-radius-0"}
												style={{ borderRadius: "0px" }}
												name="truck_no"
												value={this.state.truck_no}
												options={this.state.truckslist}
											/>
										</div>
										<div className="col-xl-2 col-lg-2 form-group">
											{/* <Datetime value={this.state.startDate} inputProps={{ placeholder: 'Start Date', name: 'startDate', autoComplete:'off', required:true }} dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss"  name="startDate" onChange={this.handlerStartDateTime.bind(this)} /> */}
											<label>Start Date</label>
											<input
												type="text"
												name="startDate"
												autoComplete="Off"
												id="startParkDate"
												placeholder="Start Date"
												className="datepicker form-control"
												onChange={this.handlerStartDateTime.bind(this)}
												value={this.state.startDate}
											/>
										</div>

										<div className="col-xl-2 col-lg-2 form-group">
											{/* <Datetime value={this.state.endDate} inputProps={{ placeholder: 'End Date', name: 'endDate', autoComplete:'off', required:true }} dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss" onChange={this.handlerEndDateTime.bind(this)} /> */}
											<label>End Date</label>
											<input
												type="text"
												name="endDate"
												autoComplete="Off"
												id="endparkDate"
												placeholder="End Date"
												className="datepicker form-control"
												onChange={this.handlerEndDateTime.bind(this)}
												value={this.state.endDate}
											/>
										</div>

										<div className="col-xl-1 col-lg-1 form-group">
											<button type="submit" className="btn btn-success mt-30p">
												Get Data
											</button>
										</div>
									</form>
									<div
										id="myGridParkout"
										style={{ height: "580px", width: "100%" }}
										className={"ag-theme-balham " + this.state.parkingyardout}
									>
										<AgGridReact
											modules={this.state.modules}
											columnDefs={pocolumnwithDefs}
											defaultColDef={this.state.defaultColDef}
											rowData={this.state.prkoutrowData}
											enableCharts={true}
											enableRangeSelection={true}
											autoGroupColumnDef={this.state.autoGroupColumnDef}
											onGridReady={this.onGridReadyPark}
											onGridState={this.onGridStatePark}
											statusBar={this.state.statusBar}
											sideBar={this.state.sideBar}
											paginationPageSize={this.state.paginationPageSize}
											pagination={true}
											frameworkComponents={this.state.frameworkComponents}
											detailCellRendererParams={
												this.state.detailCellRendererParams
											}
											//editType={this.state.editType}
											stopEditingWhenGridLosesFocus={true}
											//floatingFilter={true}
											//enableCellChangeFlash={true}
											suppressCellFlash={true}
											rowClassRules={this.state.rowClassRules}
											//onCellClicked={this.onRowClicked.bind(this)}
											gridOptions={{
												context: { componentParent: this },
											}}
											masterDetail={true}
											overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
										/>
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>

				{this.state.sliderRouteTranslate != '' ?
					<div className={"slide-r " + (this.state.sliderRouteTranslate)} style={{ overflow: "auto" }}>
						<div className="slide-r-title">
							<h4>
								{(this.state.consignment_code)}
								<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onCloseRouteDiv.bind(this)} >X</span>

							</h4>
						</div>
						<div className="slide-r-body" style={{ position: "relative" }}>
							<div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
								<DrawMap mapFor={"geofence"} parkdealers={this.state.dealers} truckno={this.state.maptruckno} mapinfo={this.state.mapinfo} defTransitCoords={this.state.defTransitCoords} />
								<div className="col-xl-12 col-lg-12 n-p-0">
									<div className="crm-numbers pb-0">
										<h3 className="subH">{this.state.sidebarSubHeader}</h3>
										<div className="col-xl-12 col-lg-12 row">
											<div className="col route-block">
												<label className="sidebar-label">Truck No</label>
												<div>{this.state.routeTruck.truck_no}</div>
											</div>
											{/* <div className="col route-block">
												<label className="sidebar-label">Transporter</label>
												<div>{(!isEmpty(maptransporter))?maptransporter:""}</div>
											</div> */}
											<div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(this.state.routeTruck.startTime != '') ? getDDMMYYYYHHMMSS(this.state.routeTruck.startTime) : "NA"}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">End Time</label>
												<div>{(this.state.routeTruck.endTime != '') ? getDDMMYYYYHHMMSS(this.state.routeTruck.endTime) : "NA"}</div>
											</div>

											<div className="col route-block">
												<label className="sidebar-label">Distance</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details)) ? Math.round(this.state.mapinfo.route_details.distance / 1000) : "0"} Kms</div>
											</div>
											{/*<div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.start_time):"-"}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">End Time</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.end_time):"-"}</div>
											</div>
										*/}

										</div>
									</div>
								</div>
							</div>
						</div>

					</div>
					: ""
				}

				<div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>

				{this.state.slideAddManualForm != '' ?
					<div className={"slide-r " + (this.state.slideAddManualForm)} style={{ overflow: "auto" }}>
						<div className="slide-r-title">
							<h4>
								Report Carrier
								<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onCloseRouteDiv.bind(this)} >X</span>

							</h4>
						</div>
						<div className="slide-r-body" style={{ position: "relative" }}>

							<div className="row col-xl-12 col-lg-12 ml-0p n-p-0">


								<form className="row theme-form col-xl-12 col-lg-12" onSubmit={this.onSubmitManualParking}>

									<div className="col-xl-12 col-lg-12 col-sm-12" style={{ paddingRight: '0px' }}>
										<div className={"trucks-dropdown left2em " + (this.state.showTrucksList)} style={{ top: "72px", width: "20.3em" }}>
											<ul>
												{listData}
											</ul>
										</div>
										<label>Truck No *:</label>
										<input
											type="text"
											name="truck_no"
											id="manual_truck"
											placeholder="Truck No"
											autoComplete="off"
											className="form-control"
											onFocus={this.initalList.bind(this)}
											onChange={this.handlerManualTruckForm.bind(this)}
											onBlur={this.onBlurData.bind(this)}
										/>
									</div>
									<div className="col-xl-12 col-lg-12 form-group">
										<label className="c-lbl">Select Transporter *:</label>
										<Select
											placeholder={"Select Transporter"}
											closeMenuOnSelect={true}
											onChange={this.onChangeManualTransporterItem.bind(this)}
											className={"border-radius-0 " + (this.state.showtrans)}
											style={{ borderRadius: "0px" }}
											name="manual_transporter"
											id="manual_transporter"
											value={this.state.manual_transporter}
											options={this.state.transporters} />

										<input type="text" id="manual_transporter_hidden" className={"form-control " + (this.state.showtransinp)}
											readonly="true"
										/>
										<input type="hidden" id="manual_transporter_code" />
									</div>
									<div className="col-xl-12 col-lg-12 form-group">
										<label className="c-lbl">Select Truck Type *:</label>
										<Select
											placeholder={"Select Truck Type"}
											closeMenuOnSelect={true}
											onChange={this.onChangeManualTruckTypeItem.bind(this)}
											className={"border-radius-0"}
											style={{ borderRadius: "0px" }}
											name="manual_trucktype"
											value={this.state.manual_trucktype}
											options={[
												{ "value": "Truck", "label": "Truck" },
												{ "value": "Trailer", "label": "Trailer" }
											]} />
									</div>
									<div className={"form-group  col-xl-12 col-lg-12 "}>
										<label className="c-lbl">Loading Capacity *:</label>
										<input
											type="text"
											name="loading_capacity"
											onkeypress={this.isNumber.bind(this)}
											id="loading_capacity" value={this.state.loading_capacity}
											onChange={this.changeLoadingHandler.bind(this)}
											className="form-control" placeholder="Loading Capacity" required />
									</div>

									<div className="col-xl-12 col-lg-12 form-group">
										<label className="c-lbl">Select Parking Yard *:</label>
										<Select
											placeholder={"Select Parking Yard"}
											closeMenuOnSelect={true}
											onChange={this.onChangeManualPlantItem.bind(this)}
											className={"col-xl-12 col-lg-12 border-radius-0"}
											style={{ borderRadius: "0px" }}
											name="manual_parkingyard"
											value={this.state.manual_parkingyard}
											options={this.state.parkinglist}
										/>
									</div>
									<div className="col-xl-12 col-lg-12 col-sm-12 mb-20p" style={{ paddingRight: '0px' }}>
										<label>Reported On *:</label>
										{/*	<input
										type="text"
										name="manual_reported_on"
										id="manual_reported_on"
										placeholder="Reported On"
										autoComplete="off"
										className="form-control datetimepicker_mask1"
										/>*/}
										<Datetime
											value={this.state.manual_reported_on}
											disableCloseOnClickOutside={false}
											closeOnSelect={true}
											inputProps={{ placeholder: 'Parking Out Time', name: 'manual_parking_out', autoComplete: 'off' }}
											dateFormat="YYYY-MM-DD"
											timeFormat="HH:mm"
											name="manual_reported_on"
											onChange={this.handlerManualDateTime.bind(this)} />

									</div>

									<div className="col-xl-12 col-lg-12 form-group">
										<button type="submit" className="btn btn-success">Submit</button>
									</div>
								</form>

							</div>
						</div>

					</div>
					: ""
				}

				<div className={"slide-r " + (this.state.slideerParkingOut)} style={{ overflow: "auto" }}>
					<div className="slide-r-title">
						<h4>
							Parking Out
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onCloseRouteDiv.bind(this)} >X</span>

						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>
						<div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
							<form className="row theme-form col-xl-12 col-lg-12" onSubmit={this.formParkingoutCarrier}>
								<div className="col-xl-12 col-lg-12 col-sm-12 mb-20p" style={{ paddingRight: '0px' }}>
									<label>Parking Out *:</label>
									<input type="datetime-local" id="manual_parking_out" name="manual_parking_out" />
									{/* <input
										type="text"
										name="manual_parking_out"
										id="manual_parking_out"
										placeholder="Parking Out Time"
										autoComplete="off"
										className="form-control datetimepicker_mask"
									/> */}
								</div>
								<div className="col-xl-12 col-lg-12 form-group">
									<button type="submit" className="btn btn-success">Submit</button>
								</div>
							</form>
						</div>
					</div>
				</div>

				{modalRowData.length !== 0 ?
					(<Modal open={withdrawmodal1} onClose={this.onCloseModal} style={modalStyles1} id="LoadingCapacity">
						<div className={"row"} style={{ padding: '5px 0px', width: '300px', zIndex: '9999', }} >
							<h5 className="model-title">{`${modalRowData.truck_no}`}</h5>
							<div className="col-xl-12 col-lg-12 mt-20p">
								<form className="row theme-form col-xl-12 col-lg-12" onSubmit={this.onSubmitUpdateLoadingCap}>
									<div className="col-xl-12 col-lg-12 form-group">
										<label>MC Loading Capacity</label>
										<Select
											placeholder={"Select MC Loading Capacity"}
											closeMenuOnSelect={true}
											onChange={this.changeLoadingCapHandler.bind(this)}
											className={"border-radius-0 "}
											style={{ borderRadius: "0px" }}
											name="mcLoadingCapacityVal"
											id="mcLoadingCapacityVal"
											value={mcLoadingCapacityVal}
											options={mcOptions} />
									</div>
									<div className="col-xl-12 col-lg-12 form-group">
										<label>SC Loading Capacity</label>
										<Select
											placeholder={"Select SC Loading Capacity"}
											closeMenuOnSelect={true}
											onChange={this.changeScLoadingCapHandler.bind(this)}
											className={"border-radius-0 "}
											style={{ borderRadius: "0px" }}
											name="scLoadingCapacityVal"
											id="scLoadingCapacityVal"
											value={scLoadingCapacityVal}
											options={scOptions} />
									</div>
									{/* <div className="col-xl-12 col-lg-12 form-group">
										<label htmlFor='availableFittings'>Available Fittings *</label>
										<input type="text" name="availableFittings" id="availableFittings" className="form-control"
											required />
									</div> */}
									<div className="col-xl-12 col-lg-12 form-group">
										<label htmlFor='loadingReason'>Reason *</label>
										<input type="text" name="loadingReason" id="loadingReason" className="form-control"
											required />
									</div>
									<div className="col-xl-12 col-lg-12 form-group">
										<label htmlFor='fittingReason'>Fitting Type</label>
										<input type="text" name="fittingReason" id="fittingReason" className="form-control"
											/>
									</div>
									<div className="col-xl-12 col-lg-12 form-group">
										<button type="submit" className="btn btn-success">Submit</button>
									</div>
								</form>
							</div>
						</div>
					</Modal>)
					: ""
				}

				<Modal open={reportCarrierOC} onClose={this.onCloseModal} style={modalStyles1} id="LoadingCapacity">
					<div classname='row' style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }}  >
						<h5 className="model-title">{`Report Carrier`}</h5>
						<div className="col-xl-12 col-lg-12 mt-20p">
							{isNotSelected ? (
								<div>
									<div className="col-xl-12 col-lg-12 form-group" >
										<div className='row'>
											<div className='col-lg-4'>
												<label htmlFor='Fittings'>Category</label>
												<input value='Fittings' id='Fittings' name='Fittings' className='form-control' style={{ width: '90px' }} readOnly />
											</div>
											<div className="col-lg-8">
												<label htmlFor='fittingsCategoryVal'>Select Sub Category</label>
												<Select
													isMulti
													placeholder={"Sub Category"}
													closeMenuOnSelect={true}
													onChange={this.fittingsCategoryHandler.bind(this)}
													className={"border-radius-0"}
													style={{ borderRadius: "0px" }}
													name="fittingsCategoryVal"
													id="fittingsCategoryVal"
													value={fittingsCategoryVal}
													options={fittings} />
											</div>
										</div>
									</div>
									<div className="col-xl-12 col-lg-12 form-group" >
										<div className='row'>
											<div className='col-lg-4'>
												{/* <label htmlFor='Documents'>Category</label> */}
												<input value='Documents' id='Documents' name='Documents' className='form-control' style={{ width: '90px' }} readOnly />
											</div>
											<div className="col-lg-8">
												{/* <label htmlFor='documentCategoryVal'>Select Sub Category</label> */}
												<Select
													isMulti
													placeholder={"Sub Category"}
													closeMenuOnSelect={true}
													onChange={this.documentCategoryHandler.bind(this)}
													className={"border-radius-0"}
													style={{ borderRadius: "0px" }}
													name="documentCategoryVal"
													id="documentCategoryVal"
													value={documentsCategoryVal}
													options={documents} />
											</div>
										</div>
									</div>
									<div className="col-xl-12 col-lg-12 form-group" >
										<div className='row'>
											<div className='col-lg-4'>
												{/* <label htmlFor='Others'>Category</label> */}
												<input value='Others' id='Others' name='Others' className='form-control' style={{ width: '90px' }} readOnly />
											</div>
											<div className="col-lg-8">
												{/* <label htmlFor='otherCategoryVal'>Select Sub Category</label> */}
												<Select
													isMulti
													placeholder={"Sub Category"}
													closeMenuOnSelect={true}
													onChange={this.otherCategoryHandler.bind(this)}
													className={"border-radius-0"}
													style={{ borderRadius: "0px" }}
													name="otherCategoryVal"
													id="otherCategoryVal"
													value={othersCategoryVal}
													options={others} />
											</div>
										</div>
									</div>
									{/* <div className="col-xl-12 col-lg-12 form-group">
										<label>Select Category</label> 
										<Select
											placeholder={"Select Category"}
											closeMenuOnSelect={true}
											onChange={this.changeNotOkHandler.bind(this)}
											className={"border-radius-0 "}
											style={{ borderRadius: "0px" }}
											name="notOkType"
											id="notOkType"
											value={notOkType}
											options={notOkOptions} />
									</div>
									<div className="col-xl-12 col-lg-12 form-group">
										<label>Select Sub Category</label>
										<Select
											placeholder={"Sub Category"}
											closeMenuOnSelect={true}
											onChange={this.changeNotOkCategoryHandler.bind(this)}
											className={"border-radius-0 "}
											style={{ borderRadius: "0px" }}
											name="notOkCategoryVal"
											id="notOkCategoryVal"
											value={notOkCategoryVal}
											options={notOkSubOptions} />
									</div> */}
									<button className="btn btn-success" style={{ marginLeft: '78px' }} onClick={() => this.submitReportingDetails('not')}>Submit</button>

								</div>
							) : (
								<div classname="row" style={{ justifyContent: 'center', alignItems: 'center', marginLeft: '46px' }}>
									<p style={{ fontFamily: 'Roboto', fontWeight: "bold", fontSize: "14px" }}>{`MC Loading Capacity : ${mc_loading_capacity}`}</p>
									<p style={{ fontFamily: 'Roboto', fontWeight: "bold", fontSize: "14px" }}>{`SC Loading Capacity : ${sc_loading_capacity}`}</p>

									{(mc_loading_capacity == 'NA' && sc_loading_capacity == 'NA') ? (
										<p style={{ fontFamily: 'Roboto', fontWeight: "bold", fontSize: "14px" }}>Please update SC/MC loading capacity</p>
									) :

										(<>
											<button type="button" style={{ width: '100px' }} className="btn btn-info" onClick={() => this.onSubmitReportCarrier('ok')}>Ok</button>
											<button type="button" style={{ width: '100px' }} className="btn btn-danger" onClick={() => this.onSubmitReportCarrier('not')}>Not Ok</button>
										</>)
									}

								</div>
							)
							}
						</div>
					</div>

				</Modal>
				<Modal open={withdrawmodal} onClose={this.onCloseModal} style={modalStyles}>
					<div className={"row"} style={{ 
						// padding: '5px 0px', width: '100%', 
					zIndex: '9999', }} >
						<h5 className="model-title">Withdraw Reason</h5>
						<div className="col-xl-12 col-lg-12 mt-20p">
							<form className="row theme-form col-xl-12 col-lg-12" onSubmit={this.onSubmitWithdrawAction}>
								<div className="col-xl-12 col-lg-12 form-group">									
													{/* <label htmlFor='otherCategoryVal'>Select Sub Category</label> */}
										<Select														
											placeholder={"Select Withdraw Reason"}
											closeMenuOnSelect={true}
											onChange={this.withDrawCategoryHandler.bind(this)}
											className={"border-radius-0"}
											style={{ borderRadius: "0px", marginTop : '18px', marginLeft : '10px'}}
											name="withDrawVal"
											id="withDrawVal"
											value={withDrawVal}
											options={withDrawList} />
									
									{/* <textarea className="col-xl-12" rows="4" name="withdraw_reason" onChange={this.changeHandler} placeholder="Write reason" value={this.state.withdraw_reason} /> */}
								</div>
								{/*	<div className="col-xl-4 col-lg-4 col-sm-4 mb-20p" style={{paddingRight:'0px'}}>
									<label>Parking Out Time *:</label>

									<Datetime

									disableCloseOnClickOutside={false}
									closeOnSelect={true}
									inputProps={{ placeholder: 'Parking Out Time', name: 'manual_parking_out', autoComplete:'off' }}
									dateFormat="YYYY-MM-DD"
									timeFormat="HH:mm"
									name="manual_parking_out"
									onChange={this.handlerManualDateTime.bind(this)} />
								</div>*/
								}
								<div className="col-xl-12 col-lg-12 form-group">
									<button type="submit" className="btn btn-success float-right">Submit</button>
								</div>
							</form>
						</div>
					</div>
				</Modal>

				<div className={"dataLoadpage " + (this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " + (this.state.loadshow)}>
					<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
				</div>

			</div>

		);
	}
}

function loadScript(url) {
	var index = window.document.getElementsByTagName("script")[0]
	var script = window.document.createElement("script")
	script.src = url
	script.async = true
	script.defer = true
	index.parentNode.insertBefore(script, index)
}

function getPolygonCoords() {

	var len = geoFencingArea.getPath().getLength();
	// console.log(len);
	var htmlStr = "";
	var coordinates = [];
	for (var i = 0; i < len; i++) {
		// var latlngd = {"lat":parseFloat(each_lat_lng[0]),"lng":parseFloat(each_lat_lng[1])};
		// coordinates.push()
		var strlatlng = geoFencingArea.getPath().getAt(i).toUrlValue(5);
		var spliting = strlatlng.split(",");
		var latlngd = { "lat": parseFloat(spliting[0]), "lng": parseFloat(spliting[1]) };
		coordinates.push(latlngd);
		// htmlStr += "new google.maps.LatLng(" + geoFencingArea.getPath().getAt(i).toUrlValue(5) + "), ";
		//Use this one instead if you want to get rid of the wrap > new google.maps.LatLng(),
		//htmlStr += "" + geoFencingArea.getPath().getAt(i).toUrlValue(5);
	}
	// console.log("htmlStr", coordinates);

}
function ConvertSeconds(n) {
	var days = Math.round(parseInt(n / (24 * 3600)));

	n = n % (24 * 3600);
	var hours = Math.round(parseInt(n / 3600));

	n %= 3600;
	var minutes = Math.round(n / 60);

	n %= 60;
	var seconds = Math.round(n);

	return days + " Day(s) " + hours + " Hrs " + minutes + " mins " + seconds + " secs"
}

function isEmpty(obj) {
	for (var key in obj) {
		if (obj.hasOwnProperty(key))
			return false;
	}
	return true;
}
function timeConvert(n) {
	var num = n;
	var hours = (num / (3600));
	var rhours = parseInt(hours);
	var minutes = (num - (rhours * 3600)) / (60);
	var rminutes = Math.round(minutes);
	return rhours + " hour(s) and " + rminutes + " minute(s).";
}
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
		// console.log(date1,date2);
		var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
		date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
		var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
		date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
		// console.log(date1,date2);
		var date1Number = monthToComparableNumber(date1);
		var date2Number = monthToComparableNumber(date2);
		// console.log(date1Number,date2Number);
		if (date1Number === null && date2Number === null) {
			return 0;
		}
		if (date1Number === null) {
			return -1;
		}
		if (date2Number === null) {
			return 1;
		}
		return date1Number - date2Number;
	}
}
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	// console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName) {
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
	];
	var monthNum = months.indexOf(monthName) + 1;
	if (monthNum < 10) {
		monthNum = "0" + monthNum.toString();
	}
	return monthNum;
}
function loadDatepicker() {
	$(".datepicker").datepicker({
		dateFormat: "yy-mm-dd",
		changeMonth: true,
		changeYear: true
	});

};


// window.onload = function (){
// 	var today = new Date();
// 	var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
// 	$('#manual_reported_on').datetimepicker({
// 		mask:'39-19-9999 29:59',
// 		format:'d-m-Y H:i',
// 		onShow:false,
// 		step:5,
// 		minTime:time
// 	});
// 	$('.datetimepicker_mask1').datetimepicker({
// 		mask:'39-19-9999 29:59',
// 		format:'d-m-Y H:i',
// 		onShow:false,
// 		step:5,
// 		minTime:time
// 	});
// 	$('#manual_parking_out').datetimepicker({
// 		mask:'39-19-9999 29:59',
// 		format:'d-m-Y H:i',
// 		onShow:false,
// 		step:5,
// 		minTime:time
// 	});
//
//
// 	$(".styles_modal__gNwvD").css({width:"450px"})
// };
// document.addEventListener('turbolinks:before-render', () => {
// 	loadDateTimeScript()
//   });

function loadDateTimeScript() {
	var today = new Date();
	var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
	$('#manual_reported_on').datetimepicker({
		mask: '39-19-9999 29:59',
		format: 'd-m-Y H:i',
		onShow: false,
		step: 5,
		minTime: time
	});
	$('#manual_parking_out').datetimepicker({
		mask: '39-19-9999 29:59',
		format: 'd-m-Y H:i',
		onShow: false,
		step: 5,
		minTime: time
	});

	$('.datetimepicker_mask1').datetimepicker({
		mask: '39-19-9999 29:59',
		format: 'd-m-Y H:i',
		onShow: false,
		step: 5,
		minTime: time
	});

	var index = window.document.getElementsByTagName("script")[1]
	var script = window.document.createElement("script")
	script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async = true
	script.defer = true
	index.parentNode.insertBefore(script, index)
}

function secondsToDhms(seconds) {
	seconds = Number(seconds);
	var d = Math.floor(seconds / (3600 * 24));
	var h = Math.floor(seconds % (3600 * 24) / 3600);
	var m = Math.floor(seconds % 3600 / 60);
	var s = Math.floor(seconds % 60);

	var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
	var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
	var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
	var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
	return dDisplay + hDisplay + mDisplay;
}

$(document).ready(function () {
	$(document).on("click", ".dropdown-truck-no", function () {
		var vals = $(this).text();
		$("#manual_truck").val(vals);
		// alert(vals)
		$(".trucks-dropdown").removeClass("show-m");
		$(".trucks-dropdown").addClass("show-n");
		var tno = vals;
		if (tno != "" && tno != undefined && tno.length >= 9) {
			var q = {
				truck_no: tno
			}
			redirectURL.post("/dashboard/trucktransporter", q)
				.then((response) => {
					// console.log("resposne ", response.data)
					var records = response.data.records;
					if (records.length > 0) {
						$("#manual_transporter_hidden").addClass("show-m")
						$("#manual_transporter_hidden").removeClass("show-n")
						$("#manual_transporter").removeClass("show-m")
						$("#manual_transporter").addClass("show-n")
						$("#manual_transporter").val({ value: records[0].transporter_code, label: records[0].transporter_name });
						$("#manual_transporter_hidden").val(records[0].transporter_name);
						$("#manual_transporter_code").val(records[0].transporter_code);
					}
					else {

						$("#manual_transporter_hidden").addClass("show-n")
						$("#manual_transporter_hidden").removeClass("show-m")
						$("#manual_transporter").removeClass("show-n")
						$("#manual_transporter").addClass("show-m")
						$("#manual_transporter_hidden").val("");
						$("#manual_transporter_code").val("");
					}
				})
		}
	});

	$("body").on("click", function (e) {
		var container = $("#manual_truck");
		// console.log("!container.is(e.target) ", !container.is(e.target))
		// console.log("etarget ", e.target)
		if (!container.is(e.target)) {
			try {
				// if($(".trucks-dropdown").attr("class").includes("show-m") || $(".trucks-dropdown").attr("class") == "trucks-dropdown")
				// {
				$(".trucks-dropdown").removeClass("show-m");
				$(".trucks-dropdown").addClass("show-n");
				// }
			} catch (e) { }

		}
		else {
			// console.log("here")
			try {
				if ($(".trucks-dropdown").attr("class").includes("show-m") || $(".trucks-dropdown").attr("class") == "trucks-dropdown") {
					$(".trucks-dropdown").removeClass("show-m");
					$(".trucks-dropdown").addClass("show-n");
				}
			} catch (e) { }
		}

	})
})


function groupBy(list, keyGetter) {
	const map = new Map();
	list.forEach((item) => {
		const key = keyGetter(item);
		const collection = map.get(key);
		if (!collection) {
			map.set(key, [item]);
		} else {
			collection.push(item);
		}
	});
	return map;
}

function GetSortOrder(prop) {
	return function (a, b) {
		if (a[prop] > b[prop]) {
			return 1;
		} else if (a[prop] < b[prop]) {
			return -1;
		}
		return 0;
	}
}

function GetSortDescOrder(prop) {
	return function (a, b) {
		if (a[prop] < b[prop]) {
			return 1;
		} else if (a[prop] > b[prop]) {
			return -1;
		}
		return 0;
	}
}
// function isNumber(evt) {
// 	evt = (evt) ? evt : window.event;
// 	var charCode = (evt.which) ? evt.which : evt.keyCode;
// 	if (charCode > 31 && (charCode < 48 || charCode > 57)) {
// 		return false;
// 	}
// 	return true;
// }
const tooltipValueGetter = (params) => {
	// console.log("params",params)
	return { value: params.value }
}

function getParams() {
    return {
        allColumns:false,
        columnGroups: true,
        columnKeys: true,
        // customHeader: true && makeCustomContent(),
        // customFooter: true && makeCustomContent(),
        skipFooters: false,
        skipGroups: true,
        skipHeader: false,
        skipPinnedTop: true,
        skipPinnedBottom: true,
    
    };
  }
