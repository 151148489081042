import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import '../../../node_modules/react-grid-layout/css/styles.css';
import '../../../node_modules/react-resizable/css/styles.css';

import { WidthProvider, Responsive } from "react-grid-layout";
import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import Autosuggest from 'react-autosuggest';

import Breadcrumb from '../common/breadcrumb.component';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils'; 
var redirectURL = require('../redirectURL');

const ResponsiveReactGridLayout = WidthProvider(Responsive);

export default class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
        	routemap:'',
        	allmarkers:'',
        	trucks:[],
        	truck_no:'',
        	startDate:'',
        	endDate:''
        		
    	      
        };
        this.showMap = this.showMap.bind(this);
    }
    componentDidMount(){
    	//console.log("Box ",localStorage.getItem('token'));
    	
    	redirectURL.get("/gmap/trucks")
        //.then(res => res.json())
        .then(
          (result) => {
        	  //console.log(result);
             this.setState({
            	 trucks:result.data
             });
          }
        )
        .catch(function (error) {
  		    console.log(error);
  		  });
    	
       
    	
    }
    selectOptionsItems() {
        let items = [];     
        //console.log(this.state.trucks);
        this.state.trucks.forEach(item => {
        	if(item != '' && item != null)
    		{
        		items.push(<option key={item.truck_no} value={item.truck_no}>{item.truck_no}</option>); 
    		}
        	
        });
        
        
        return items;
    }
    showMap =() =>{
    	this.renderMap();
    }
    renderMap = () => {
    	this.setState({
    		routemap:'a'
    	});
		loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyD1AFWPoJqPaemwGO4A7P7Q6CsUJ1TLGJk&libraries=places,drawing&callback=initMap");
		window.initMap = this.initMap
   }
   
    initMap = () => {
    	//console.log(this.state.allmarkers);
		var currentwindow;
		try{
			var jsondata = this.state.allmarkers;
			var locations = jsondata.breaks;
			var arr = jsondata.coords;
			//console.log("Here",arr);
			var routeinfo = jsondata.route_details;
			var mapOptions = {
					zoom: 13,
					zoomControl: true,
					mapTypeControl: true,
					scaleControl: false,
					streetViewControl: false,
					rotateControl: false,
					fullscreenControl: false,
					labels:true,
					mapTypeControlOptions: {
						mapTypeIds: ['hybrid', 'roadmap'],
					},
					center: new window.google.maps.LatLng(arr[0].lat,arr[0].lng),
					mapTypeId: window.google.maps.MapTypeId.ROADMAP
					};
					
					var map = new window.google.maps.Map(document.getElementById('map'),
						mapOptions);

				
					// Create our info window content
					var currentinfowindow = null;
				var line=new window.google.maps.Polyline(
						{
							map:map,
											strokeColor: '#157254',
											strokeOpacity: 1.0,
											strokeWeight: 2.5,
									icons: [{
									icon: {
											path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
											strokeColor:'#ff8c52',
											fillColor:'#ff8c52',
											fillOpacity:1,
											strokeWeight: 2
											},
									repeat:'100px',
									path:[]
								}]
							});

					
				for (let i = 0; i < arr.length; i++) {
						var path=line.getPath().getArray();
							
								let latLng=new window.google.maps.LatLng(arr[i].lat,arr[i].lng);
								path.push(latLng);
								line.setPath(path);
							

						
				
					//Change line color based on map type
					window.google.maps.event.addListener( map, 'maptypeid_changed', function() {
						if (map.getMapTypeId() =='hybrid' || map.getMapTypeId() =='satellite')
						{
							var line=new window.google.maps.Polyline(
							{
								map:map,
								strokeColor: '#FFFFFF',
								strokeOpacity: 1.0,
								strokeWeight: 2.5,
								icons: [{
										icon: {
												path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
												strokeColor:'#ff8c52',
												fillColor:'#ff8c52',
												fillOpacity:1,
												strokeWeight: 2
												},
										repeat:'100px',
										path:[]
									}]
								});                
						}
						else
						{
							var line=new window.google.maps.Polyline(
							{
								map:map,
								strokeColor: '#157254',
								strokeOpacity: 1.0,
								strokeWeight: 2.5,
								icons: [{
										icon: {
												path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
												strokeColor:'#ff8c52',
												fillColor:'#ff8c52',
												fillOpacity:1,
												strokeWeight: 2
												},
										repeat:'100px',
										path:[]
									}]
								});

						}

						for (i = 0; i < arr.length; i++) {
								var path=line.getPath().getArray();
								let latLng=new window.google.maps.LatLng(arr[i].lat,arr[i].lng);
								path.push(latLng);
								line.setPath(path);
										//map.setCenter(latLng);
							}
										
						var x = map.getZoom();
						var c = map.getCenter();
						window.google.maps.event.trigger(map, 'resize');
						map.setZoom(x);
						map.setCenter(c);
					} );
				}

			var infowindow = new window.google.maps.InfoWindow();
				var marker, l;

				for (l = 0; l < locations.length; l++) {
				marker = new window.google.maps.Marker({
				position: new window.google.maps.LatLng(locations[l].lat, locations[l].lng),
				icon:require('../../assets/icons/cf.png'),
				map: map,
				});

				window.google.maps.event.addListener(marker, 'click', (function(marker, l) {
					return function() {
						var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Break - "+(l+1)+"</B></p><p>Break time "+locations[l].break_start+" - "+locations[l].break_end+" ("+timeConvert(locations[l].break_time)+")</p>";
								
					infowindow.setContent(contentString);
					currentwindow = infowindow;
					infowindow.open(map, marker);
					//console.log(marker.position.toJSON());
					console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
					}
				})(marker, l));
				}
				marker = new window.google.maps.Marker({
					position: new window.google.maps.LatLng(arr[0].lat, arr[0].lng),
					icon:require('../../assets/icons/track_start.png'),
					map: map,	
				});
			window.google.maps.event.addListener(marker, 'click', (function(marker) {
					return function() {
						//	console.log(clusters[0])
						
						var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
						
					infowindow.setContent(contentString);
					currentwindow = infowindow;
					infowindow.open(map, marker);
					console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
					}
				})(marker));
			marker = new window.google.maps.Marker({
				position: new window.google.maps.LatLng(arr[(arr.length-1)].lat, arr[(arr.length-1)].lng),
				icon:require('../../assets/icons/truck-end.png'),
				map: map,	
			});
			window.google.maps.event.addListener(marker, 'click', (function(marker) {
					return function() {
						//	console.log(clusters[0])
						
					var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> End Point</B></p><p>End at "+routeinfo.end_time+"</p>";
						
					infowindow.setContent(contentString);
					currentwindow = infowindow;
					infowindow.open(map, marker);
					console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
					}
				})(marker));
		}
		catch(e){
			console.log("Error occurred ", e)
		}
	    	
    }
   
    handlerStartDateTime = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
    	var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+d.getDate()+" "+d.getHours()+":"+((d.getMinutes() < 10) ? "0"+d.getMinutes():d.getMinutes())+":"+((d.getSeconds() < 10) ? "0"+d.getSeconds():d.getSeconds());
    	
    	this.setState({
    		startDate:getYYYYMMDDHHMMSS(d)
    	});
    	//let value = event.target.value;
        //this.setState({'startDate':value});
    }

    handlerEndDateTime  = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
    	var edate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+d.getDate()+" "+d.getHours()+":"+((d.getMinutes() < 10) ? "0"+d.getMinutes():d.getMinutes())+":"+((d.getSeconds() < 10) ? "0"+d.getSeconds():d.getSeconds());
    	this.setState({
    		endDate:getYYYYMMDDHHMMSS(d)
    	});
    	
    	
    	//let value = event.target.value;
        //this.setState({'startDate':value});
    }
    handlerForm = (event) => {
    	console.log("Select", event.target.value);
    	let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
    }
    formHandler = (event) => {
    	console.log("Form", event.target);
    	event.preventDefault();
        //var formdata = new FormData(event.target);
    	var formdata= {
    			truck_no:this.state.truck_no,
    			startDate:this.state.startDate,
    			endDate:this.state.endDate,
    	}
    	
        console.log("Form", event);
    	redirectURL.post("/gmap",formdata,{
            headers:{
                //'Content-Type': 'multipart/form-data',
            	'Content-Type': 'application/json',
                'Access-Control-Allow-Origin':"*",
                'Access-Control-Allow-Methods':'GET, POST, OPTIONS, PUT, DELETE, TOKEN',
                'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
               // 'authorization': 'Zoho-oauthtoken 1000.ecb6dbd17a44125ce5434f5f45148278.a54f59a27d9e9db5383f85ba3002ccf2' 
            }
        })
        //.then(res => res.json())
        .then(
          (result) => {
             //console.log(result.data);
        	  this.showMap();
             this.setState({
            	 allmarkers:result.data
             });
          }
        )
        .catch(function (error) {
  		    console.log(error);
  		  });
    }
    render(){
    	let order_cnt=[];

    	const dStyles={
				width:'100%',
				height:'100%'
		}
		
        
        return (
            <div >
                {/*Container-fluid starts*/} 
                <div className="container-fluid">
                	 <div className="row col-xl-12 col-lg-12">
	                	 <form className="row theme-form col-xl-12 col-lg-12" onSubmit={this.formHandler}>
	                    	<div className="col-xl-3 col-lg-3 form-group">
	                    		{/*<select name="truck_no" className="form-control" onChange={this.handlerForm.bind(this)}>
	                    			<option value="">Truck No</option>
	                    			{this.selectOptionsItems()}
	                    		</select>
								*/}
								<input type="text" name="truck_no" placeholder="Truck No" className="form-control" value={this.state.truck_no} onChange={this.handlerForm.bind(this)} />
	                    	</div>
	                    	<div className="col-xl-3 col-lg-3 form-group">
	                    		<Datetime inputProps={{ placeholder: 'Start Date', name: 'startDate', autoComplete:'off' }} dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss"  name="startDate" onChange={this.handlerStartDateTime.bind(this)} />
	                    	</div>

	                    	<div className="col-xl-3 col-lg-3 form-group">
                    			<Datetime  inputProps={{ placeholder: 'End Date', name: 'endDate', autoComplete:'off' }} dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss" onChange={this.handlerEndDateTime.bind(this)} />
	                    	</div>

	                    	<div className="col-xl-3 col-lg-3 form-group">
	                    		<button type="submit" className="btn btn-success">Get Route</button>
	                    	</div>
	                     </form>
                    </div>
                    
                    {this.state.routemap == '' ? '':
                        <div className="row col-xl-12 col-lg-12">
                    		<div id="map" style={{width:'100%',height:'500px'}}>Map Here</div>
    	               </div>
                    }
                    
	               
                </div>
                {/*Container-fluid Ends*/}
                
               
            </div>
        );
    }
}
function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}
                   
function timeConvert(n) {
	var num = n;
	var hours = (num / (3600));
	var rhours = Math.floor(hours);
	var minutes = (n) / (60);
	var rminutes = Math.round(minutes);
	return rhours + " hour(s) and " + rminutes + " minute(s).";
}                              
var currentinfowindow = null;
function getTimeInfo(marker,timedet,coords)
{
	var timeinfo = new window.google.maps.InfoWindow({
          content: "Time at this location: " + timedet
        });

	marker.addListener('click', function() {
		
				if (currentinfowindow != null) {
					currentinfowindow.close();
					currentinfowindow = timeinfo;
					timeinfo.open(marker.get('map'), marker);
				}
				else
				{
					currentinfowindow = timeinfo;
					timeinfo.open(marker.get('map'), marker);
				}
				
        });
}
                              
