
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const TransporterDriverAction = (props) => {
    const handleEventClick = (e) => {
        e.stopPropagation();
        //console.log("Overspeed ",props);
        props.context.componentParent.onCickShowDriverSlide(props);
    };
    
    return (
        <div>
            {/* {(props.data.transit_delay === 1)? */}
            <button 
                onClick={handleEventClick} 
                className="custom-btn f12 btn btn-warning p-5p fbold" 
                title="Add Remarks" >
                    <i className={"icofont icofont-plus "}></i> Add Driver
            </button>
            {/* :""} */}
        </div>
    );
};

export default TransporterDriverAction;
