import React, {Component} from 'react';

import { Button, Form, FormGroup, Label, Input, FormText,Alert, UncontrolledAlert } from 'reactstrap';
import _ from "lodash";
import SweetAlert from 'react-bootstrap-sweetalert';
import $ from 'jquery';
import Select from 'react-select';
// import MultiSelectCheckbox from "./multiselectwithcheckbox";
import CheckboxOption from "./multicheckselect";
var redirectURL = require('../redirectURL');
var images = require.context('../../assets/images/', true);
var googleAnalytics = require("../common/googleanalytics");

class CreateUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pageTitle: "New User",
            eventGridAction: "gridAction",
            eventCounterAction: "counterAction",
            eventFormAction: "formAction",
            style: {},
            firstname:'',
            lastname:'',
            email:'',
            dealercode: '',
            dealerstate:"",
            dealercity:"",
            transportercode:'',
            dealername:"",
            password:'',
            dept_code:'',
            role:'',
            selectedZone:null,
            selectedRegion:null,
            alldepartments:[],
            alertFade:true,
            formMessage:'',
            formStatus:'',
            show : false,
            basicTitle : '',
            basicType : '',
            region:{value:"",label:""},
            zone:{value:"",label:""},
            location:{value:"",label:""},
            show1 : false,
            basicTitle1 : '',
            basicType1 : '',
            rolename:"",
            username:"",
            ZoneList:[],
            regionList:[],
            areaList:[],
            showPassword : "password",
            allDealers:[],
            dealerChecks:[],
            selectedDealers: [],
            selectDealer:"",
            drpdealers:[],
            dealersPlaceholder:"Select",
            rDealer:{value:"",label:"Select Dealer"}
        };
        this.changeHandler=this.changeHandler.bind(this);
        this.formHandler = this.formHandler.bind(this);
        this.dealersList = this.dealersList.bind(this);
        // this.onChangeRole = this.onChangeRole.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this); 
        this.getSelectedDealers=this.getSelectedDealers.bind(this);
        this.closeAlert1=this.closeAlert1.bind(this);
        this.closeAlert=this.closeAlert.bind(this);
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount() {
        this.logPageView();
        this.dealersList({});
        redirectURL.post("superadmin/zonecollection").then((response) => {
            // console.log("response",response.data)
            if (response.data.length > 0) {
                var zones = []
                response.data.map(  (e) => {
                    if (e.zone != "") {
                        zones.push(e.zone) 
                    }
                })
                //  console.log("zones",zones)
                this.setState({
                    ZoneList: zones
                    
                })
               
            }

        }) 
        var params = {
            selectedzone : this.state.zone.value
        }
        // console.log("params",params)
        redirectURL.post("superadmin/regioncollection").then((response) => {
            // console.log("response",response.data)
            if (response.data.length > 0) {
                var regions = []
                response.data.map(  (e) => {
                    if (e.region != "") {
                        regions.push(e.region) 
                    }
                })
                //  console.log("regions",regions)
                this.setState({
                    regionList: regions
                    
                })
               
            }

        })
        redirectURL.post("superadmin/areacollection").then((response) => {
            // console.log("response",response.data)
            if (response.data.length > 0) {
                var locations = []
                response.data.map(  (e) => {
                    if (e.location != "") {
                        locations.push(e.location) 
                    }
                })
                //  console.log("locations",locations)
                this.setState({
                    areaList: locations
                    
                })
               
            }

        })// Log page view to GA
        
        setTimeout(function () {
            this.setState({style: {display:'none'}});
        }.bind(this),1000)
    }
   
    changeHandler(event){
        // console.log(event.target,"event");
        let name = event.target.name;
        var flag = true;
        let value = event.target.value;
        if(value !=undefined && value !="")
        {
            if(['firstname', 'lastname'].includes(name))
            {
                var regex = /^[A-Za-z]+$/
                flag = regex.test(value);
            }
        }
        if(flag){
            this.setState({
                [name]:value
            });
        }
        // else
        // {
        //     this.setState({
        //         basicTitle: "Only characters allowed",
        //         basicType: "warning",
        //         show: true
        //     })
        // }
    }
    // onChangeRole(rolename) {
    //     console.log("rolename",rolename)
    //     this.setState({rolename  :rolename });
    // }
    onChangeRole = (event) => {
        // const { name, value } = event.target;
        // console.log(event,'option')
        this.setState({
            rolename: event.value,
            selectedDealers:[]
        });
        console.log(this.state.rolename,'rolename')
    }
    onChangeZone = (zone) => {
        // console.log("110")
        // console.log("zone",zone)
        this.setState({ zone },
              () => {}
            //   console.log(zone,`Name  Option selected:`, this.state.zone)
        );
                   var params = {
                        zone:zone.value,
                        // region: this.state.region.value
                    };
                    // console.log("params00000",params)
                    redirectURL.post("superadmin/regioncollectionforfilter", params).then((response) => {
                        // console.log(response.data,'data---')
                        if (response.data.length > 0) {
                            var regions = []
                            response.data.map(  (e) => {
                                if (e != "") {
                                    regions.push(e) 
                                }
                            })
                            //  console.log("regions",regions)
                            this.setState({
                                regionList: regions
                                
                            })
                           
                        }
        
                        
                    });
            
            
      
        this.setState({
            errorMessage: "",
            successMessage: "",
        });
    }
    
    submitChangeZone = () => {
	
        let items = [];     
        var ZoneList = this.state.ZoneList;
        //  console.log("ZoneList",ZoneList)
        if(ZoneList.length > 0)
        {
            ZoneList.map((item) => {
                items.push({
                    value:item,
                    label:item
                })
        })
        }
        return items;
    }
  
    onChangeRegion = (region) => {
        // console.log("Selected region:", region);
    
        this.setState({ region }, () => {
           
        });
        var params = {
            region:region.value,
            // region: this.state.region.value
        };
        // console.log("params00000",params)
        redirectURL.post("superadmin/areacollectionforfilter", params).then((response) => {
            // console.log(response.data,'data---')
            if (response.data.length > 0) {
                var areas = []
                response.data.map(  (e) => {
                    if (e != "") {
                        areas.push(e) 
                    }
                })
                //  console.log("areas",areas)
                this.setState({
                    areaList: areas
                    
                })
               
            }

            
        });
    };
    
    submitChangeRegion = () => {
        let items = [];
        var regionList = this.state.regionList;
      
        // Use a set to store unique values
        const uniqueValues = new Set();
      
        if (regionList.length > 0) {
          regionList.forEach((item) => {
            // Check if the value is not already in the set before adding
            if (!uniqueValues.has(item)) {
              uniqueValues.add(item);
              items.push({
                value: item,
                label: item
              });
            }
          });
        }
      
        return items;
      };
 
    onChangeArea = (location) => {
        // console.log("110")
        console.log("location",location)
        this.setState({ location },
              () => {
                // console.log(`Name  Option selected:`, this.state.location)
                if(this.state.rolename === "Business Manager")
                {
                    if(this.state.location.value != "" && this.state.location.value != undefined)
                    {
                        var zone = this.state.zone.value;
                        var region = this.state.region.value;
                        var location = this.state.location.value;
                        var params = {
                            zone:zone,
                            region:region,
                            area:location
                        }
                        this.dealersList(params);
                    }
                }
            }
        );

        this.setState({
            errorMessage: "",
            successMessage: "",
        });
    }
    submitChangeArea = () => {
	
        let items = [];     
        var areaList = this.state.areaList;
        //  console.log("areaList",areaList)
        if(areaList.length > 0)
        {
            areaList.map((item) => {
                items.push({
                    value:item,
                    label:item
                })
        })
        }
        return items;
    }
    closeAlert = async () => {
        await this.setState({
            show: false,
        });
        await window.location.reload();
    }
    closeAlert1(){
        this.setState({
            show1: false,
        });
    }
    onClickDropDown = () => {
        
    }
    formHandler(event){
        event.preventDefault();
        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pageTitle;
            let eventOptions = {
                "category": pageTitle,
                "action": this.state.eventFormAction,
                "label": googleAnalytics.page.action.formSubmittedUserRegistration,
            }
            googleAnalytics.logEvent(eventOptions);
        }
        //console.log(event.target);
        var dealersCls = $(".dealersCls");
        // console.log("dealersCls ", dealersCls)
        var chkDealers = []
        if(dealersCls.length > 0)
        {
            dealersCls.each((itm,val) => {
                // console.log("itm ", val)
                if(dealersCls[itm].checked === true)
                {
                    // console.log(dealersCls[itm].name)
                    chkDealers.push(dealersCls[itm].name);
                }
            })
        }
        
        // console.log("chkDealers data ",chkDealers);
        var email = this.state.email.replace(/\s/g,"").toLowerCase();
        // console.log("email")
        var rolename = this.state.rolename;
        var username  = this.state.username;
        // console.log("rolename",rolename)
        var dealername = this.state.dealername;
        // console.log("dealername",dealername)
        var dealercode = this.state.dealercode;
        // console.log("dealercode",dealercode)
        var dealercity = this.state.dealercity;
        var dealerstate = this.state.dealerstate;
        var transportercode = this.state.transportercode;
        //  console.log("transportercode",transportercode)
        var check = 0;
        var regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        var modifyemail =  regex.test(email);
        // console.log("modifyemail",modifyemail)
        var user_created_by = localStorage.getItem('email');
        if(email !=undefined && email !="")
        {
            if(email.includes("@") == true)
            {
                // console.log("146")
                var emailCheck = email.split("@")[1];
                // console.log("emailCheck",emailCheck)

                
                // if(emailCheck == "enmovil.in" || emailCheck == "herocorp.in"){
                    check = 1;
                    if(this.state.password !=undefined && this.state.password !="")
                    {
                        var verifypwd = CheckPassword(this.state.password);
                        // console.log(verifypwd,"verifypwd");
                        if(verifypwd == true)
                        {
                            check = 1;
                            var verifyconspwd = CheckConsecutivePassword(this.state.password);
                            // console.log(verifyconspwd,"verifyconspwd");
                            if(verifyconspwd == false)
                            {
                                check = 3;
                            }
                        }
                        else
                        {
                            check = 2;
                        }
                    }
                // }
                // else{
                //     check = 0;
                // }
            }
        //    console.log(emailCheck,"emailCheck"); 
        }
        
        
        if(this.state.rolename === "Area Manager" || this.state.rolename === "Regional Manager" || this.state.rolename === "Business Manager"){
            if(this.state.selectedDealers.length > 0)
            {

            }
            else{
                check = 5;
            }
        }
        // console.log(check,"check")
        if(check == 1)
        {
            if(this.state.rolename === "Area Manager"){
                var formdata = {
                    selectedDealers:this.state.selectedDealers,
                    username:username,
                    rolename:this.state.rolename,
                    location:this.state.location,
                    zone:this.state.zone.value,
                    region:this.state.region.value,
                    // dealercode:this.state.dealercode,
                    // dealername:this.state.dealername,
                    // transportercode:this.state.transportercode,
                    firstname:this.state.firstname,
                    lastname:this.state.lastname,
                    email: email,
                    password:this.state.password,
                    // roles:["LOGPRTUSER"],
                    // dept_code:["LOG-PRT"],
                    // uiuser : 1,
                    // verified : 1,
                    // user_type : "ORGUSER",
                    // login_attempts : 0,
                    user_created_by:user_created_by,
                }
                // console.log("formdata", formdata);

            }
            if(this.state.rolename === "Regional Manager"){
                var formdata = {
                    rolename:this.state.rolename,
                    username:username,
                    selectedDealers:this.state.selectedDealers,
                    zone:this.state.zone.value,
                    region:this.state.region.value,
                    // dealercode:this.state.dealercode,
                    // dealername:this.state.dealername,
                    // transportercode:this.state.transportercode,
                    firstname:this.state.firstname,
                    lastname:this.state.lastname,
                    email: email,
                    password:this.state.password,
                    // roles:["LOGPRTUSER"],
                    // dept_code:["LOG-PRT"],
                    // uiuser : 1,
                    // verified : 1,
                    // user_type : "ORGUSER",
                    // login_attempts : 0,
                    user_created_by:user_created_by,
                }
                // console.log("formdata", formdata);

            }
            if(this.state.rolename === "Zonal Manager"){
                var formdata = {
                    rolename:this.state.rolename,
                    username:username,
                    zone:this.state.zone.value,
                    // dealercode:this.state.dealercode,
                    // dealername:this.state.dealername,
                    // transportercode:this.state.transportercode,
                    firstname:this.state.firstname,
                    lastname:this.state.lastname,
                    email: email,
                    password:this.state.password,
                    // roles:["LOGPRTUSER"],
                    // dept_code:["LOG-PRT"],
                    // uiuser : 1,
                    // verified : 1,
                    // user_type : "ORGUSER",
                    // login_attempts : 0,
                    user_created_by:user_created_by,
                }
                // console.log("formdata", formdata);

            }
            
            if(this.state.rolename === "Business Manager"){
                var formdata = {
                    rolename:this.state.rolename,
                    username:username,
                    selectedDealers:this.state.selectedDealers,
                    location:this.state.location,
                    zone:this.state.zone.value,
                    region:this.state.region.value,
                    // dealercode:this.state.dealercode,
                    // dealername:this.state.dealername,
                    // transportercode:this.state.transportercode,
                    firstname:this.state.firstname,
                    lastname:this.state.lastname,
                    email: email,
                    password:this.state.password,
                    // roles:["LOGPRTUSER"],
                    // dept_code:["LOG-PRT"],
                    // uiuser : 1,
                    // verified : 1,
                    // user_type : "ORGUSER",
                    // login_attempts : 0,
                    dealers:chkDealers,
                    user_created_by:user_created_by,
                }
                // console.log("formdata", formdata);

            }
            
            if(this.state.rolename === "APP_ADMIN" || this.state.rolename === "OBL_ADMIN"){
                var formdata = {
                    rolename:this.state.rolename,
                    username:this.state.firstname,
                    firstname:this.state.firstname,
                    lastname:this.state.lastname,
                    email: email,
                    password:this.state.password,
                    user_created_by:user_created_by,
                }
                // console.log("formdata", formdata);

            }
            
            // var formdata = {
            //     rolename:this.state.rolename,
            //     // dealercode:this.state.dealercode,
            //     // dealername:this.state.dealername,
            //     // transportercode:this.state.transportercode,
            //     firstname:this.state.firstname,
            //     lastname:this.state.lastname,
            //     email: email,
            //     password:this.state.password,
            //     // roles:["LOGPRTUSER"],
            //     // dept_code:["LOG-PRT"],
            //     // uiuser : 1,
            //     // verified : 1,
            //     // user_type : "ORGUSER",
            //     // login_attempts : 0,
            //     user_created_by:user_created_by,
            // }
            // console.log("formdata", formdata);
            if(this.state.rolename === "TRANSPORTER"){
                var formdata = {
                    rolename:this.state.rolename,
                    username:this.state.transportercode,
                    // dealercode:this.state.dealercode,
                    // dealername:this.state.dealername,
                    transportercode:this.state.transportercode,
                    firstname:this.state.firstname,
                    lastname:this.state.lastname,
                    email: email,
                    password:this.state.password,
                    // roles:["LOGPRTUSER"],
                    // dept_code:["LOG-PRT"],
                    // uiuser : 1,
                    // verified : 1,
                    // user_type : "ORGUSER",
                    // login_attempts : 0,
                    user_created_by:user_created_by,
                }
                // console.log("formdata", formdata);

            }
            if(this.state.rolename === "DEALER"){
                var formdata = {
                    rolename:this.state.rolename,
                    username:this.state.dealercode,
                    dealercode:this.state.dealercode,
                    dealername:this.state.dealername,
                    dealercity:this.state.dealercity,
                    dealerstate:this.state.dealerstate,
                    // transportercode:this.state.transportercode,
                    firstname:this.state.firstname,
                    lastname:this.state.lastname,
                    email: email,
                    password:this.state.password,
                    // roles:["LOGPRTUSER"],
                    // dept_code:["LOG-PRT"],
                    // uiuser : 1,
                    // verified : 1,
                    // user_type : "ORGUSER",
                    // login_attempts : 0,
                    user_created_by:user_created_by,
                }
                // console.log("formdata", formdata);

            }
           console.log("last formdata ",formdata)
            redirectURL.post('/superadmin/createuserData',formdata)
            .then((response) => {
                // console.log('res[pse',response)
                let resp = response.data.status
                if(resp === 'Fail'){
                    this.setState({
                        show1: true,
                        basicTitle1: 'Email ID / Username already registered',
                        basicType1: 'warning'
                    })
                }else{
                    this.setState({
                        formMessage:response.data.message,
                        formStatus:response.data.status,
                        alertFade:false,
                        firstname:'',
                        lastname:'',
                        username:'',
                        email:'',
                        password:'',
                        rolename:'',
                        transportercode:'',
                        dealercode:'',
                        dealercity:"",
                        dealerstate:"",
                        dealername:'',
                        selectedDealers:[],
                        // dept_code:'',
                        // roles:'',
                        show: true,
                        basicTitle : 'User Created Successfully',
                        basicType: 'success'
                    })
                   // window.location.reload();
                }
                
            
            
            })

            .catch((error) => {
                console.log(error)
            })
        }
        else if(check == 0)
        {
            this.setState({
                show: true,
                basicTitle1 : 'Please enter valid email id',
                basicType1: 'warning'
            })
        }
        else if(check == 3)
        {
            this.setState({
                show1: true,
                basicTitle1 : 'Please enter valid password',
                basicType1: 'warning'
            })            
        }
        else if(check == 5)
        {
            this.setState({
                show1: true,
                basicTitle1 : 'Please select atleast 1 dealer',
                basicType1: 'warning'
            })            
        }
        else
        {
            this.setState({
                show1: true,
                basicTitle1 : 'Please enter valid password',
                basicType1: 'warning'
            })
        }
    }
   
    handleClickShowPassword = () => {
        var oldPassword = this.state.showPassword;
        if(oldPassword == "text")
        {
            var showPassword = "password";
        }
        else
        {
            var showPassword = "text";
        }
        this.setState({
            showPassword : showPassword
        });
    };

    dealersList(params){
        redirectURL.post("/superadmin/getdealerslist",params)
        .then((response) => {
            if(response.data.status == "success")
            {
                var drpdealers = []
                try {
                    var delars = response.data.dealers;
                    if(delars.length > 0)
                    {
                        delars.map((itm)=>{
                            drpdealers.push({value:itm.consignee_code,label:itm.consignee_name+" ("+itm.consignee_code+")"})
                        })
                    }    
                        
                    this.setState({
                        allDealers:response.data.dealers,
                        drpdealers:drpdealers
                    })
                } catch (error) {
                    
                }
                // console.log("Dealers ",response.data.dealers)
                
            }
        })
    }
    handleCheckboxChange = (checkboxName) => {
        console.log("checkboxName",checkboxName.target)
        var name= checkboxName.target.name;
        this.setState({
            [name]: !name,
        });
    };
    getSelectedDealers(value){
        // console.log("dealers ", value)
        // this.setState((prevState)=>({

        //     selectedDealers:dealers.data
        // })

       
          this.setState((prevState) => {
            const isSelected = prevState.selectedDealers.includes(value);
            return {
                selectedDealers: isSelected
                ? prevState.selectedDealers.filter((option) => option !== value)
                : [...prevState.selectedDealers, value],
            };
          },()=>{
            if(this.state.selectedDealers.length > 0)
            {
                this.setState({
                    dealersPlaceholder:this.state.selectedDealers.length+" Dealer(s)"
                })
            }
            else{
                
                this.setState({
                    dealersPlaceholder:"Select"
                })
            }
          });

    }
    handleSelect(selectDealer){
        this.setState({selectDealer})
    }
    onChangeDealer(rDealer){
        this.setState({rDealer},()=>{
            if(this.state.rDealer.value != "")
            {
                var dnm = this.state.rDealer.label;
                var dnme = "";
                if(dnm != "")
                {
                    var dn = dnm.split(" (");
                    dnme=dn[0];
                }

                this.setState({
                    dealercode:this.state.rDealer.value,
                    dealername:dnme
                })
            }
        })
    }
    render() {
        let style = this.state.style;
        const background = require('../../assets/images/auth-layer.png')
        const {show, basicType, basicTitle} = this.state
        const {show1, basicType1, basicTitle1} = this.state
        
        return (
            <div>
                 <SweetAlert
                    show={show}
                    type={basicType}
                    title={basicTitle}
                    onConfirm={this.closeAlert}>
                </SweetAlert>

                <SweetAlert
                    show={show1}
                    type={basicType1}
                    title={basicTitle1}
                    onConfirm={this.closeAlert1}>
                </SweetAlert>
                {/* Loader starts */}
                <div className="loader-wrapper" style={style}>
                    <div className="loader bg-white">
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        
                    </div>
                   
                </div>
                {/* Loader ends */}

                {/*page-wrapper Start*/}
                <div className="page-wrapper">

                    <div className="container-fluid">

                        {/*sign up page start*/}
                        <div className="authentication-main">
                            <div className="row">
                                
                                <div className="row col-md-12 p-0">
                                    <div className="col-sm-3"></div>
                                    <div className="col-sm-6 p-20">
                                        <div className="authentication-box">
                                            <h3 className="text-center">ADD NEW USER</h3>
                                            <div className="card mt-4 p-4">
                                            
                                                {this.state.formStatus != ""?
                                                        <div>
                                                                {(this.state.formStatus == 'Success')?
                                                                    <Alert color="secondary" fade={this.state.alertFade}>
                                                                        
                                                                    <i className="icon-thumb-up"></i>   {this.state.formMessage}
                                                                    {this.state.alertFade=true}
                                                                    {/* &nbsp; An email has been sent to you. Please Verify before logging in. */}
                                                                </Alert>
                                                                :
                                                                <Alert color="danger" fade={this.state.alertFade}>
                                                                    <i className="icon-alert"></i>  {this.state.formMessage}
                                                                    {this.state.alertFade=true}
                                                                </Alert>
                                                                }
                                                        </div>
                                                    :""
                                                }
                                                <form className="theme-form" onSubmit={this.formHandler}>
                                                    <div className="form-row">
                                                    {/* <div className="col-md-8">
                                                            <div className="form-group">
                                                                 <label className="col-form-label">Role Name</label> 
                                                                <select  type="text" className="form-control" name="rolename" value={this.state.rolename} onChange={this.onChangeRole.bind(this)} autocomplete="off"   
                                                                options={[
                                                                    {value:"Zonal Manager",label:"Zonal Manager"},
                                                                    {value:"Regional Manager",label:"Regional Manager"},
                                                                    {value:"Area Manager",label:"Area Manager"},
                                                                    {value:"Transporter",label:"Transporter"},
                                                                    {value:"Dealer",label:"Dealer"}
                                                                ]}/>
                                                            </div>
                                                        </div> */}
                                                        {/* <div className="col-md-8"> */}
                                                            
                                                                {/* <div className="form-group">
                                                                <label className="col-form-label">Role Name</label>
                                                                    <Select
                                                                    className = "form-control"
                                                                    // value={this.state.rolename}
                                                                    id= "rolename"
                                                                    name="rolename"
                                                                    options={[
                                                                        
                                                                        {value:"Area Manager",label:"Area Manager"},
                                                                        {value:"Transporter",label:"Transporter"},
                                                                        {value:"Dealer",label:" Dealer"},
                                                                        {value:"Regional Manager",label:"Regional Manager"},
                                                                        {value:"Zonal Manager",label:"Zonal Manager"},
                                                                    ]}
                                                                     onChange={this.onChangeRole}
                                                                   
                                                                     />
                                                                </div> */}
                                                <div className="col-md-6" style={{ marginLeft: "2px"}}>
                                                <div className="form-group">
                                                    <label className='col-form-label'>Role Name</label>
                                                    <Select
                                                        placeholder={"Role Name"}
                                                        closeMenuOnSelect={true}
                                                        onChange={this.onChangeRole.bind(this)}
                                                        className={"border-radius-0"}
                                                        style={{ borderRadius: "0px" }}
                                                        id= "rolename"
                                                        name="rolename"
                                                        // value={this.state.rolename}
                                                        options={[      
                                                            {value:"Area Manager",label:"Area Manager"},
                                                            {value:"TRANSPORTER",label:"TRANSPORTER"},
                                                            {value:"DEALER",label:" DEALER"},
                                                            {value:"Regional Manager",label:"Regional Manager"},
                                                            {value:"Zonal Manager",label:"Zonal Manager"},
                                                            {value:"Business Manager",label:"Business Manager"},
                                                            {value:"OBL_ADMIN",label:"OBL Admin"},
                                                        ]}
                                                        />
                                                </div>
                                               </div> 
                                           {/* </div> */}
                                           { (this.state.rolename == "Regional Manager" ||  this.state.rolename == "Area Manager" || this.state.rolename == "Business Manager")?
                                           <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Select Dealers</label>
                                                    {/* <MultiSelectCheckbox
                                                        context={this}
                                                        options={[{value:"0",label:"All"},{value:"1",label:"Dealer 1"},{value:"2",label:"Dealer2"}]} 
                                                        selectedDealers={this.state.selectedDealers}
                                                    /> */}


                                                    <Select
                                                        placeholder={this.state.dealersPlaceholder}
                                                        closeMenuOnSelect={false}
                                                        isMulti
                                                        options={this.state.drpdealers}
                                                        value={this.state.selectDealer}
                                                        context={this}
                                                        onChange={this.handleSelect.bind(this)}
                                                        components={{ Option: (props) => (
                                                            <CheckboxOption
                                                                {...props}
                                                                selectedDealers={this.state.selectedDealers}
                                                                customFunction={this.getSelectedDealers}
                                                            />
                                                            ) }}
                                                        />
                                                </div>
                                            </div>
                                            :""}
                                            { this.state.rolename == "TRANSPORTER"  ?<div className="col-md-8">
                                                <div className="form-group">
                                                    <label className="col-form-label">Transporter Code</label>
                                                    <input required type="text" className="form-control" name="transportercode" value={this.state.transportercode} onChange={this.changeHandler} autocomplete="off" placeholder="Enter Transporter Code"/>
                                                </div>
                                            </div> :""}
                                            { this.state.rolename == "Zonal Manager"  || this.state.rolename == "Regional Manager" ||  this.state.rolename == "Area Manager" || this.state.rolename == "Business Manager" ?<div className={(this.state.rolename == "Area Manager"?"col-md-5 ":"col-md-12")}>&nbsp;
                                                </div>:""}

                                            { this.state.rolename == "Zonal Manager" ?<div className={(this.state.rolename == "Area Manager" || this.state.rolename == "Business Manager"?"col-md-6 ":"col-md-6")}>
                                                <div className="form-group">
                                                    <label className="col-form-label">Zone</label>
                                                   
                                                    <Select
                                                        placeholder={"Select Zone"}
                                                        closeMenuOnSelect={true}
                                                        onChange={this.onChangeZone.bind(this)}
                                                        className={"col-xl-12 col-lg-12 border-radius-0"}
                                                        styles={{ borderRadius: "0px" }} // Use "styles" instead of "style"
                                                        name="zone"
                                                        value={this.state.zone}
                                                        options={this.submitChangeZone()} // Call the function to get options
                                                    />
                                                </div>
                                            </div> :""}
                                          {/*  { this.state.rolename == "Regional Manager" || this.state.rolename == "Area Manager"  || this.state.rolename == "Business Manager" ?<div className={(this.state.rolename == "Area Manager" || this.state.rolename == "Business Manager"?"col-md-4 ":"col-md-6")}>
                                                <div className="form-group">
                                                    <label className="col-form-label">Region</label>
                                                    <Select
                                                        placeholder={"Select region"}
                                                        closeMenuOnSelect={true}
                                                        onChange={this.onChangeRegion.bind(this)}
                                                        className={"col-xl-12 col-lg-12 border-radius-0"}
                                                        styles={{ borderRadius: "0px" }} // Use "styles" instead of "style"
                                                        name="region"
                                                        value={this.state.region}
                                                        options={this.submitChangeRegion()} // Call the function to get options
                                                    />
                                                </div>
                                            </div> :""}
                                            { this.state.rolename == "Area Manager" || this.state.rolename == "Business Manager"  ?<div className={(this.state.rolename == "Area Manager" || this.state.rolename == "Business Manager"?"col-md-4 ":"col-md-6")}>
                                                <div className="form-group">
                                                    <label className="col-form-label">Area Location</label>
                                                    <Select
                                                        placeholder={"Select area"}
                                                        closeMenuOnSelect={true}
                                                        onChange={this.onChangeArea.bind(this)}
                                                        className={"col-xl-12 col-lg-12 border-radius-0"}
                                                        styles={{ borderRadius: "0px" }} // Use "styles" instead of "style"
                                                        name="location"
                                                        value={this.state.location}
                                                        options={this.submitChangeArea()} // Call the function to get options
                                                    />
                                                </div>
                                            </div> :""}
                                            { this.state.rolename == "Business Manager"  ?<div className={"col-md-12"}>
                                                <div className="form-group">
                                                    <label className="col-form-label">Dealers</label>
                                                    <ul>
                                                   {(this.state.allDealers.length > 0)?
                                                    this.state.allDealers.map((itm) => 
                                                       <li key={itm.consignee_code}>
                                                            <input 
                                                                type="checkbox" 
                                                                value={itm.consignee_code} 
                                                                name={itm.consignee_code}
                                                                className="dealersCls"
                                                                // checked={(this.state.dealerChecks.length > 0?(this.state.dealerChecks[itm.consignee_code] || false):"")} 
                                                                onChange={this.handleCheckboxChange} 
                                                                    
                                                            />&nbsp;
                                                            {itm.consignee_name} - {itm.consignee_code}</li>
                                                    )
                                                    :""}
                                                    </ul>
                                                </div>
                                            </div> :""}
                                            */}

                                            {/* { this.state.rolename == "DEALER"  ?<div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="col-form-label">Dealer Code</label>
                                                    <input required type="text" className="form-control" name="dealercode" value={this.state.dealercode} onChange={this.changeHandler} autocomplete="off" placeholder="Enter Delaer Code"/>
                                                </div>
                                            </div> :""}
                                            
                                            { this.state.rolename == "DEALER"  ?<div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="col-form-label">Dealer Name</label>
                                                    <input required type="text" className="form-control" name="dealername" value={this.state.dealername} onChange={this.changeHandler} autocomplete="off" placeholder="Enter Dealer Name"/>
                                                </div>
                                            </div> :""} */}
                                            {this.state.rolename == "DEALER"?
                                                <div className="col-md-5">
                                                    <div className="form-group">
                                                        <label className="col-form-label">Seelct Dealer</label>
                                                    
                                                        <Select
                                                            placeholder={"Select Dealer"}
                                                            closeMenuOnSelect={true}
                                                            onChange={this.onChangeDealer.bind(this)}
                                                            className={"col-xl-12 col-lg-12 border-radius-0"}
                                                            style={{ borderRadius: "0px",width:"200px" }} // Use "styles" instead of "style"
                                                            name="zone"
                                                            value={this.state.rDealer}
                                                            options={this.state.drpdealers} // Call the function to get options
                                                        />
                                                    </div>
                                                </div>
                                            :""}
                                            { this.state.rolename == "DEALER"  ?<div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="col-form-label">Dealer State</label>
                                                    <input required type="text" className="form-control" name="dealerstate" value={this.state.dealerstate} onChange={this.changeHandler} autocomplete="off" placeholder="Enter Delaer State"/>
                                                </div>
                                            </div> :""}
                                            { this.state.rolename == "DEALER"  ?<div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="col-form-label">Dealer City</label>
                                                    <input required type="text" className="form-control" name="dealercity" value={this.state.dealercity} onChange={this.changeHandler} autocomplete="off" placeholder="Enter Delaer City"/>
                                                </div>
                                            </div> :""}
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                        <label className="col-form-label">First Name</label>
                                                        <input required type="text" className="form-control" name="firstname" value={this.state.firstname} onChange={this.changeHandler} autocomplete="off" placeholder="Enter First Name"/>
                                                    </div>
                                                </div>    
                                                <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="col-form-label">Last Name</label>
                                                            <input required type="text" className="form-control" name="lastname" value={this.state.lastname} onChange={this.changeHandler} autocomplete="off" placeholder="Enter Last Name"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                { (this.state.rolename == "Regional Manager" ||  this.state.rolename == "Area Manager" || this.state.rolename == "Business Manager" || this.state.rolename == "Zonal Manager")?
                                                
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="col-form-label">Username</label>
                                                        <input type="text" required className="form-control" name="username" value={this.state.username} onChange={this.changeHandler} autocomplete="off" placeholder="Username"/>
                                                    </div>
                                                </div>
                                                :""}
                                                    
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="col-form-label">Email Address</label>
                                                            <input type="email" required className="form-control" name="email" value={this.state.email} onChange={this.changeHandler} autocomplete="off" placeholder="Email Address"/>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="col-form-label">Password</label>
                                                            <div className="input-group">
                                                                <input type={this.state.showPassword} required className="form-control" name="password" value={this.state.password} onChange={this.changeHandler} autocomplete="off" placeholder="**********"/>
                                                                <div className='input-group-append'>
                                                                    <span className={(this.state.showPassword == "password") ? 'input-group-text fa fa-eye' : 'input-group-text fa fa-eye-slash'} onClick={this.handleClickShowPassword}></span>
                                                                </div>
                                                                <span style={{"color":"red"}}>Password should contain atleast 8 characters, atleast 1 uppercase character, atleast 1 lowercase character, atleast 1 digit and atleast 1 special Character</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    

                                                    {/* <div className="form-group">
                                                        <label className="col-form-label">Role</label>
                                                        <div className="form-row">
                                                            <div className="col-sm-12">
                                                                <select required className="form-control mb-1" name="roles" value={this.state.roles} onChange={this.changeHandler}>
                                                                    <option value=''>Select</option>
                                                                    <option value="Plant">Plant</option>
                                                                    <option value="RSO">RSO</option>
                                                                    <option value="Loading Officer">Loading Officer</option>
                                                                    <option value="Unloading Officer">Unloading Officer</option>
                                                                    <option value="BA">BA</option>
                                                                    <option value="MahindraAdmin">Admin</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                    <div className="form-row">
                                                        <div className="col-sm-3">
                                                            <button type="submit" className="btn btn-secondary">Register
                                                            </button>
                                                        </div>
                                                        

                                                    </div>
                                                    
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        {/*sign up page Ends*/}

                        </div>
                    </div>
                </div>
            </div>

        )
    }
}
function CheckPassword(inputtxt) 
{ 
	var decimal=  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
	if(inputtxt.match(decimal)) 
	{ 
		return true;
	}
	else
	{ 
		return false;
	}
}
function CheckConsecutivePassword(input)
{
    if (/(.)\1\1/.test(input)) {
        return false;
    }
    else
    {
        return true;
    }
}
function CheckField(inputtxt) 
{ 
    console.log("inputtxt ", inputtxt)
    // var decimal=  /^(?=.*?[A-Za-z])(?=.*?[0-9])[A-Za-z0-9]+$/;
    // console.log("inputtxt.match(decimal ",inputtxt.match(decimal))
	// if(inputtxt.match(decimal)) 
	// { 
	// 	return true;
	// }
	// else
	// { 
	// 	return false;
    // }
    var regex = /^[A-Za-z]+$/
 
    //Validate TextBox value against the Regex.
    var isValid = regex.test(inputtxt);
    console.log("isValid ", isValid)
    if (!isValid) {
        alert("Contains Special Characters.");
    } else {
        alert("Does not contain Special Characters.");
    }

    return isValid;
} 


export default CreateUser;