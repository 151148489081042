import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import Select from 'react-select';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import SweetAlert from 'react-bootstrap-sweetalert';
import CustomLoadingCellRenderer from "../layouts/customLoadingCellRenderer";
import UserActive from "./useractiveaction";
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");

export default class UsersList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageTitle: "Users",
            eventGridAction: "gridAction",
            eventCounterAction: "counterAction",
            eventFormAction: "formAction",
            loggedInDepartmentAdminRoles:null,
            customColumnFields:null,
            allUsers:[],
            customColumnDefinition:[],
            errorDuplicateRole:false,
            errorSelectionDepartment:false,
            selectedDepartment:null,
            departmentList:[],
            errorRoleUpdatedFailed: false,
            errorRoleNotEditable: false,
            errorEmptyValue: false,
            messageRoleUpdateSuccessful: false,
            userType:'',
            menu_title:'',
            loadshow:'show-n',
            overly:'show-n',
            updateForm:'show-n',
            addForm:'show-m',
            role_name:"",
            alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
            sliderTranslate:'',
            allmenus:[],
            defaultColDef: {
                sortable: true,
                filter: true,
                resizable: true,
            },
            modules: AllModules,
            rowData: [],
            headerHeight: 40,
            groupHeaderHeight:60,
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
               
            animateRows: true,
            debug: true,
            showToolPanel: false,
            uppressAggFuncInHeader: true,
                
            childconfs: [],
            childrow:[],
            rowModelType: "serverSide",
            paginationPageSize:500,
            cacheBlockSize: 10,
            maxBlocksInCache: 1,
            frameworkComponents: {
                customLoadingCellRenderer: CustomLoadingCellRenderer,
                UserActive:UserActive
            },
            detailCellRendererParams:{},
            statusBar: {
                statusPanels: [
                    {
                    statusPanel: "agTotalAndFilteredRowCountComponent",
                    align: "left"
                    },
                    {
                    statusPanel: "agTotalRowCountComponent",
                    align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            sideBar: {
                toolPanels: [
                    {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel"
                    },
                    {
                    id: "filters",
                    labelDefault: "Filters",
                    labelKey: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            otherusers:[],
            customotherusers:[],
            allRoles:[]


        };
    }
    getUserType = () => {
        let isAdminUser = localStorage.getItem('is_admin');
        let isDeptAdminUser = localStorage.getItem('is_dept_admin');
        let isGlobalUser = localStorage.getItem('is_global');
        //console.log(" isAdminUser and isGlobalUser = ", isAdminUser, isGlobalUser);
        if (isAdminUser == "true") {
            //console.log(" IN getUserType, ADMIN");
            this.setState({
                "userType": "ADMIN"
            });

        }
        if (isDeptAdminUser == "true") {
            //console.log(" IN getUserType, is_dept_admin");
            this.setState({
                "userType": "departmentadmin"
            });
        }
        if (isAdminUser == "true" && isGlobalUser == "true") {
            //console.log(" IN getUserType, SUPERADMIN");
            this.setState({
                "userType": "superadmin"
            });
        }

    }
   
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    
    componentDidMount(){
        this.getUserType();
        //console.log("this.state.userType",this.state.userType);
        this.logPageView(); // Log page view to GA
        //this.getUserType();
       this.getUsersList();

    }

    getUsersList(){
        redirectURL.post("/superadmin/siteusers")
        .then( async (response) => {
            try {
                let userList = [];
                userList = response.data;
                // console.log("userList ", userList)
                this.setState({
                    allUsers:userList,
                    rowData:response.data,
                    otherusers:response.data
                    //loadshow:'show-n'
                });
     
            } catch (error) {
                
            }
           
        })
        .catch(function(e){
            console.log(e)
        })
    }
    hideAlert = () => {
        this.setState({
            alert: null
        });
    }
    closeAlert = async () => {
        await this.setState({
            show: false
        });
        // await window.location.reload();
    }
	
	onClickHideAll(){
		this.setState({
			overly:'show-n',
			sliderTranslate:''
		});
		
	}
    
	onGridReady = params => {
	    this.gridApi = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
	    this.gridColumnApi = params.columnApi;
	};

	onGridState = () =>{
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
           this.gridApi.setFilterModel(window.filterState);
    }
    onClickDisableUser(rownode){
        // console.log("Disable Rownode ", rownode)
        var params = {
            rowid:rownode.data._id,
            username:rownode.data.username,
            email:rownode.data.email,
            user_type:rownode.data.user_type,
            verified:0
        }
        redirectURL.post("/superadmin/updateUserActive",params)
        .then((response) => {
            if(response.data.status == "success")
            {
                this.setState({
                    show:true,
                    basicTitle:"Successfully update status",
                    basicType:"success"
                })
                this.getUsersList();
            }
            else{
                this.setState({
                    show:true,
                    basicTitle:"Failed to update status",
                    basicType:"info"
                })
                this.getUsersList();
            }
        })
    }
    onClickActiveUser(rownode){
        // console.log("Active Rownode ", rownode)
        var params = {
            rowid:rownode.data._id,
            username:rownode.data.username,
            email:rownode.data.email,
            user_type:rownode.data.user_type,
            verified:1
        }
        redirectURL.post("/superadmin/updateUserActive",params)
        .then((response) => {
            if(response.data.status == "success")
            {
                this.setState({
                    show:true,
                    basicTitle:"Successfully update status",
                    basicType:"success"
                })
                this.getUsersList();
            }
            else{
                this.setState({
                    show:true,
                    basicTitle:"Failed to update status",
                    basicType:"info"
                })
                this.getUsersList();
            }
        })
    }
	
    render(){
        var columnDefs = [
            {
                headerName:"User Email",
                field:"email",
                width:250
            },
            {
                headerName:"Username",
                field:"username",
                width:180
            },
            {
                headerName:"Firstname",
                field:"firstname",
                width:180
            },
            {
                headerName:"Lastname",
                field:"lastname",
                width:180
            },
            {
                headerName:"User Type",
                field:"user_type",
                width:180
            },
            {
                headerName:"Status",
                field:"verified",
                width:180,
                valueGetter:function(params){
                    try {
                        if(params.data.verified == 1)
                        {
                            return "Active";
                        }
                        else{
                            return "Inactive";
                        }
                    } catch (error) {
                        
                    }
                }
            },
            {
                headerName:"Action",
                field:"_id",
                width:180,
                cellRendererSelector:function(params)
				{
					var rendComponent = {
						component: 'UserActive'
					};
					return rendComponent
				}
            }
            
        ]
        return (
    		<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
                <div className="mb-15p fbold f20">
                    <h5>
                        <i className="icofont icofont-navigation-menu cus-i"></i> <span>{this.state.pageTitle}</span>
                        {/* <a href="/assignmenus" className="btn btn-success ml-5p float-right">Assign Menus to Role</a> */}
                    </h5>
                </div>    
                {this.state.alert}
            	<div className="row" >
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
		           			<div className="card-body row">
                                <div className=" col-xl-12 col-lg-12" style={{color: "#0462a1"}}>
                                    <div id="myGrid" style={{ height: "580px",width: "100%"}}  className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        enableCharts={false}
                                        enableRangeSelection={false}
                                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        frameworkComponents={this.state.frameworkComponents}
									    detailCellRendererParams={this.state.detailCellRendererParams}
									    
                                        
									    
                                        //floatingFilter={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        suppressCellFlash={true}
									    masterDetail={true}
                                        
                                    />

									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
									</div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                 <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            
            </div>
        )
    }
}
function numberParser(params) {
    return Number(params.newValue);
  }