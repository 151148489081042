/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import $ from "jquery";
import _ from "lodash";
import MarkerClusterer from "@google/markerclusterer";
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import CountUp from 'react-countup';
import Modal from 'react-responsive-modal';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import {
	getDDMMYYYY,
	getDDMMYYYYHHMMSS,
	getYYYYMMDD,
	getYYYYMMDDHHMMSS,
	getHyphenYYYYMMDDHHMMSS,
	getHyphenDDMMMYYYYHHMM,
	getHyphenYYYYMMDD,
	getHyphenDDMMYYYYHHMMSS,
	getHyphenDDMMYYYY,
	getDDMMMYYYYHHMMDefault
} from '../common/utils';
var redirectURL = require('../redirectURL');

var infoBox = require('../common/google-infowindow');
var oms = require(`npm-overlapping-marker-spiderfier/lib/oms.min`);
var googleAnalytics = require("../common/googleanalytics");
var moment = require('moment');

var marker;
var markers = [];
var circle;
var selectlat;
var selectlng
var allcords = [];
var map;
var rad = function (x) {
	return x * Math.PI / 180;
};
var rlatitude;
var rlongitude;

var fencingLength = 0;
var geoFencingArea;
const decipher = salt => {
	const textToChars = text => text.split('').map(c => c.charCodeAt(0));
	const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
	return encoded => encoded.match(/.{1,2}/g)
		.map(hex => parseInt(hex, 16))
		.map(applySaltToChar)
		.map(charCode => String.fromCharCode(charCode))
		.join('');
}
var decode = decipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

export default class ConsignmentNearByTrucks extends Component {

	constructor(props) {
		super(props);
		// console.log(this.props.match.url)
		this.displayData = [];
		this.state = {
			pageTitle: "",
			gpsdata: false,
			mapped: true,
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			showform: 'show-m',
			loadshow: 'show-n',
			showrefresh: 'show-n',
			sliderTranslate: '',
			showdata: this.displayData,
			truck_no: [],
			radius: '5000',
			rlatitude: '',
			rlongitude: '',
			latitude: '',
			longitude: '',
			defaultradius: 0,
			coordinates: [],
			alert: null,
			show: false,
			basicTitle: '',
			basicType: "default",
			contentString: "testing",
			mwidth: "col-xl-12 col-lg-12",
			alldepts: [],
			transportervalue: [{ "value": "ALL", "label": "ALL Transporters" }],
			alltransporters: [],
			truckstatus: '0',
			kpicode: '0',
			truckNoList: [],
			deptcode: '',
			alltrucks: [],
			truckno: '',
			showTrucksList: "show-n",
			filteredData: [],
			triptype: { "value": 1, "label": "ALL" },
			plantLocations: [],
			totalTrucksCount: 0,
			onTripCounter: 0,
			usermanualmodal: false,
			weatherData: [],
			displayWeatherForecast: false,
			weatherMarkers: [],
			loginplant: '',
			modules: AllModules,
			defaultColDef: {
				sortable: true,
				filter: true,
				resizable: true

			},

			rowGroupPanelShow: 'always',
			overlayNoRowsTemplate: "",
			frameworkComponents: {
			},
			animateRows: true,
			debug: true,
			showToolPanel: false,
			uppressAggFuncInHeader: true,
			childconfs: [],
			childrow: [],
			statusBar: {
				statusPanels: [
					{
						statusPanel: "agTotalAndFilteredRowCountComponent",
						align: "left"
					},
					{
						statusPanel: "agTotalRowCountComponent",
						align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
						id: "columns",
						labelDefault: "Columns",
						labelKey: "columns",
						iconKey: "columns",
						toolPanel: "agColumnsToolPanel"
					},
					{
						id: "filters",
						labelDefault: "Filters",
						labelKey: "filters",
						iconKey: "filter",
						toolPanel: "agFiltersToolPanel"
					}
				]
			},
			rowClassRules: {
				"highlitRow": "data.transit_delay == 1",
				"green": "data.status >= 3"

			},
			'gridData': [],
			'open': false
		};
		this.popmarker = this.popmarker.bind(this);
		this.goBack = this.goBack.bind(this);

		this.cipher = this.cipher.bind(this);
	}
	cipher = (salt) => {
		const textToChars = text => text.split('').map(c => c.charCodeAt(0));
		const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
		const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
		try {
			return text => text.split('')
				.map(textToChars)
				.map(applySaltToChar)
				.map(byteHex)
				.join('');
		}
		// eslint-disable-next-line no-unreachable
		catch (e) { }
	}

	/*Alert Popups*/
	closeAlert = () => {
		this.setState({
			show: false
		});
	}

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	}
	hideAlert = () => {
		this.setState({
			alert: null
		});
	}

	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
			} else {
				console.log("GA disabled...");
			}
		} catch (error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}

	componentDidMount() {
		//console.log(this.props.match.)

		var loguser = decode(localStorage.getItem("m"));
		// console.log("LOGUSER ", JSON.parse(loguser));
		var loginplant = "";
		try {
			var luser = JSON.parse(loguser);
			loginplant = luser.is_plant_code;
			// console.log("logplant", loginplant)
			this.setState({
				loginplant: loginplant,
				// consigner:[{"value":luser.is_plant_code,"label":luser.is_plant_name}]

			})
		}
		catch (e) {

		}
		this.renderMapDefault();


		this.logPageView(); // Log page view to GA
		var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
		this.setState({
			loadshow: 'show-m',
			coordinates: [],
			rowData: []
		});
		var dept = "SNDG";
		var hText = 'All';
		if (this.props.match.url == '/nearbyprttrucks') {
			dept = 'LOG-PRT';
			hText = 'Spare Parts';
		}
		if (this.props.match.url == "/mllnearbysndtrucks") {
			dept = 'SNDG';
			hText = '';
		}
		if (this.props.match.url == "/nearbysndtrucks") {
			dept = 'SNDG';
			hText = '';
		}
		if (this.props.match.url == "/nearbysctrucks") {
			dept = 'LOG-SC';
			hText = '( Supply Chain )';
		}
		if (this.props.match.url == "/nearbytnptrucks") {
			dept = 'LOG-TNP';
			hText = '( Train and Production )';
		}

		let pageTitle = "Device Locations";
		this.setState({
			deptcode: dept,
			pageTitle: pageTitle
		});

		const layoutdata = {
			//token:localStorage.getItem('token'),
			deptcode: encode(dept)
		}

		redirectURL.post('/masters/transportersbydept', layoutdata, {
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
			}
		})
			.then((response) => {
				//console.log(response);
				var records = JSON.parse(JSON.stringify(response)).data;
				var optss = {};
				records.map((item, key) => {
					var optValue = item.dept_code;
					optss[optValue] = item.dept_name
				});
				this.setState({
					alltransporters: records
				});
			})
			.catch(function (error) {
				console.log(error);
			});


		// var formdata = {
		// 	radius:3000,
		// 	latitude:"20.73568784876339",
		// 	longitude:"79.38999444931353",
		// 	dept_code:dept,
		// 	transporter_code:["ALL"],
		// 	status:this.state.truckstatus,
		// 	trip_type : 1
		// }
		var formdata = {
			radius: 3000,
			latitude: "20.73568784876339",
			longitude: "79.38999444931353",
			dept_code: "SNDG",
			transporter_code: ["ALL"],
			status: "0",
			trip_type: 1,
			mapped: this.state.mapped,
			gpsdata: this.state.gpsdata
		}
		rlatitude = "20.73568784876339";
		rlongitude = "79.38999444931353";
		//http://autometrics.in:5000/getNearByTrucks
		redirectURL.post('/vicinity/getDevicRadiusCoordinates', formdata, {
			headers: {
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods': 'PUT, GET, POST, DELETE, OPTIONS',
				'Access-Control-Allow-Headers': "Origin, X-Requested-With, Content-Type, Accept, Authorization"

			}
		})
			.then(
				(response) => {
					try {
						console.log("response", JSON.parse(response.data.apiData.body));
						if (JSON.parse(response.data.apiData.body).devices_data == undefined) {
							this.setState({
								show: true, basicType: 'warning', basicTitle: "No Data found.",
								loadshow: 'show-n',
								showform: 'show-m',
							});
							this.renderMapDefault();
						}
						else {
							var markers = JSON.parse(JSON.parse(response.data.apiData.body).devices_data);
							// console.log(JSON.parse(response.data.apiData.body))

							if (markers.length > 0) {
								this.setState({
									defaultradius: formdata.radius,
									coordinates: markers,
									onTripCounter: JSON.parse(response.data.apiData.body).gps_devices,
									mwidth: "col-xl-12 col-lg-12",
									rowData: markers,
									radius: formdata.radius,
									loadshow: 'show-n',
									showform: 'show-n',
									showrefresh: 'show-m',
									rlatitude: formdata.latitude,
									rlongitude: formdata.longitude,
									'gridData': markers
								});
								redirectURL.post("/weather/getPlants").then((rsp) => {
									// console.log("getPlants", rsp.data)
									let plantList = rsp.data.filter(e => e.dept_code === dept)
									this.setState({
										plantLocations: plantList,
									});
									this.renderMap();
								})


							}
							else {
								this.setState({
									show: true, basicType: 'warning', basicTitle: "No Data found.",
									loadshow: 'show-n',
									showform: 'show-m',
								});
								this.renderMapDefault();
							}
						}
					}
					catch (e) { }
				})
			.catch(function (error) {
				console.log(error);
			});
		// redirectURL.post("consignments/getAllTrucksData").then((response) => {
		//     console.log("response",response.data)
		//     if (response.data.length > 0) {
		//         var trucks = []
		//         response.data.map((e) => {
		//             if (e != "") {
		//                 trucks.push(e) 
		//             }
		//         })
		//          console.log("trucks",trucks)
		//         this.setState({
		//             truckNoList: trucks

		//         })

		//     }

		// })

		redirectURL.post("masters/devices").then((response) => {
			// var trucks = response.data.map(function(e)
			// {
			//     if(e.truck_no != "" && e.dept_code == dept && e.truck_no != undefined)
			//     {
			//         return e.truck_no;
			//     }
			// })
			// this.setState({
			//     truckNoList :trucks
			// });
			try {
				var records = response.data.records;
				// console.log("response",response.data)
				if (records.length > 0) {
					var trucks = []
					records.map((e) => {
						if (e != "") {
							trucks.push(e)
						}
					})
					//  console.log("trucks",trucks)
					this.setState({
						truckNoList: trucks

					})

				}
				// console.log("response",response.data)
				// var trucks = response.data.filter(function(e){return e.dept_code == dept;})
				// console.log("trucks ", trucks)
				// trucks = trucks.map(function(e){
				// 	if(e.truck_no != "")
				// 	{
				// 		return e.truck_no;
				// 	}
				// })
				// this.setState({
				// 		truckNoList :trucks
				// });
				// console.log("truckNoList",truckNoList)
			} catch (e) { }

			//console.log(trucks.length);
		});


		// this.renderMapDefault();

	}
	createSelectTransportersItems() {
		let items = [];
		items.push({ value: 'ALL', label: "All Transporters" });
		this.state.alltransporters.map(item => {
			items.push({ value: item.transporter_code, label: item.transporter_name });
			//items.push(<option key={item.dept_code} value={item.dept_code}>{item.dept_name}</option>);
		});
		return items;
	}
	/*
	createSelectItems() {
		let items = [];
		//console.log(this.state.alldepts);
		items.push(<option key='all' value='all'>All Departments </option>);
		this.state.alldepts.map((item, key) => {
			//console.log("Here "+item,key);
			items.push(<option key={item.dept_code} value={item.dept_code}>{item.dept_name}</option>);
		});
		return items;
	}

	*/


	changeHandler = truckstatus => {

		this.setState(
			{ truckstatus },
			//   () => console.log(`Option selected:`, this.state.truckstatus)
		);
	}

	changeKpiHandler = kpicode => {

		this.setState(
			{ kpicode },
			//   () => console.log(`Option selected:`, this.state.kpicode)
		);
	}

	setTripType = trip => {
		this.setState({
			triptype: trip
		})
	}
	handleChange = transportervalue => {
		this.setState(
			{ transportervalue },
			() => console.log(`Option selected:`, this.state.transportervalue)
		);
	}
	/*handleTruckStatusChange(e){
		this.setState({ truckstatus: e.target.value });
	}*/
	initalList = (event) => {
		$(".trucks-dropdown").removeClass("show-n");
		var dataset = this.state.truckNoList;
		// console.log("dataset",dataset)
		dataset = dataset.slice(0, 100);
		this.setState({
			filteredData: dataset,
			showTrucksList: "show-m",
		})
	}
	// handlerForm = (event) => {
	// 	try
	// 	{
	// 		$(".trucks-dropdown").removeClass("show-n");
	// 		var dataset = this.state.truckNoList;
	// 		var filteredResult = dataset.filter(function(e){
	// 			if(e != "" && e != null)
	// 			{
	// 				return e.toString().toLowerCase().includes($("#inputTruck").val().toLocaleLowerCase());
	// 			}

	// 		});
	// 		filteredResult = filteredResult.slice(0,100);
	// 		this.setState({
	// 			filteredData : filteredResult,
	// 			showTrucksList : "show-m",
	// 		});
	// 	}catch(e){}
	// }
	onChangeTruckItem = (truck_no) => {
		// console.log("729")
		// console.log("truck_no",truck_no)
		this.setState({ truck_no },
			() => console.log(`Name  Option selected:`, this.state.truck_no)
		);

		this.setState({
			errorMessage: "",
			successMessage: "",
		});
	}
	ooChangeTruck = () => {
		let items = [];
		var truckNoList = this.state.truckNoList;
		//  console.log("truckNoList",truckNoList)
		if (truckNoList.length > 0) {
			truckNoList.map((item) => {

				if (![undefined, "undefined", null, ""].includes(item.device_id)) {
					items.push({
						value: item.device_id,
						label: item.device_id
					})

				} else {
					if (![undefined, "undefined", null, ""].includes(item.device_id)) {
						items.push({
							value: item.device_id,
							label: item.device_id
						})
					}
				}

			})
		}
		return items;
	}
	onClickTruck(event) {
		$("#inputTruck").val($(this).text());
	}
	handleFilter(e) {
		// console.log("filter clicked");
		e.preventDefault();
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmittedSearchByTransporter,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.setState({
			loadshow: 'show-m',
		});
		//console.log("Here ",this.props.match.url);
		var dept = 'SNDG';
		if (this.props.match.url == '/nearbyprttrucks') {
			dept = 'LOG-PRT'
		}

		if (this.props.match.url == "/nearbysndtrucks") {
			dept = 'SNDG'
		}
		if (this.props.match.url == "/nearbysctrucks") {
			dept = 'LOG-SC'
		}
		// console.log("dept ",dept);
		//console.log("Checking ",this.state.transportervalue);
		var transcode = [];
		// if(this.state.transportervalue.length > 0){
		//     // consigner_code.push({ label: 'ALL', value: 'All' })
		//     this.state.transportervalue.map(e => {
		//         if(e.value != "All"){
		//             transcode.push( e.value)
		//         }
		//     })
		// }
		// console.log("transcode",transcode)
		var tstatus;
		// console.log(this.state.transportervalue);

		var selectedTransporters = this.state.transportervalue.value;
		// console.log("633",selectedTransporters)
		try {
			if (selectedTransporters.length == 0) {
				selectedTransporters = "ALL";
			}
			if (selectedTransporters != "ALL") {

				transcode = [selectedTransporters];

			}
			else {
				transcode = [selectedTransporters];
			}
		} catch (e) {
			transcode = ["ALL"];
		}


		if (this.state.truckstatus == '0') {
			tstatus = this.state.truckstatus;
		}
		else {
			tstatus = this.state.truckstatus.value;
		}
		var trip_type = this.state.triptype.value;

		var formdata = {
			radius: 3000,
			latitude: "20.73568784876339",
			longitude: "79.38999444931353",
			dept_code: dept,
			transporter_code: transcode,
			status: tstatus,
			trip_type: trip_type
		}
		//  console.log(formdata);
		rlatitude = "20.73568784876339";
		rlongitude = "79.38999444931353";
		redirectURL.post('/vicinity/getDevicRadiusCoordinates', formdata, {
			headers: {
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods': 'PUT, GET, POST, DELETE, OPTIONS',
				'Access-Control-Allow-Headers': "Origin, X-Requested-With, Content-Type, Accept, Authorization"

			}
		})
			.then(
				(response) => {
					try {
						console.log("response", JSON.parse(response.data.apiData.body));
						if (JSON.parse(response.data.apiData.body).devices_data == undefined) {
							this.setState({
								show: true, basicType: 'warning', basicTitle: "No Data found.",
								loadshow: 'show-n',
								showform: 'show-m',
							});
							this.renderMapDefault();
						}
						else {
							var markers = JSON.parse(JSON.parse(response.data.apiData.body).devices_data);
							// console.log(JSON.parse(response.data.apiData.body))

							if (markers.length > 0) {
								this.setState({
									defaultradius: formdata.radius,
									coordinates: markers,
									onTripCounter: JSON.parse(response.data.apiData.body).gps_devices,
									mwidth: "col-xl-12 col-lg-12",
									rowData: markers,
									radius: formdata.radius,
									loadshow: 'show-n',
									showform: 'show-n',
									showrefresh: 'show-m',
									rlatitude: formdata.latitude,
									rlongitude: formdata.longitude,
									'gridData': markers
								});
								redirectURL.post("/weather/getPlants").then((rsp) => {
									// console.log("getPlants", rsp.data)
									let plantList = rsp.data.filter(e => e.dept_code === dept)
									this.setState({
										plantLocations: plantList,
									});
									this.renderMap();
								})


							}
							else {
								this.setState({
									show: true, basicType: 'warning', basicTitle: "No Data found.",
									loadshow: 'show-n',
									showform: 'show-m',
								});
								this.renderMapDefault();
							}
						}
					}
					catch (e) { }
				})
			.catch(function (error) {
				console.log(error);
			});

		//this.renderMap();



	}
	renderMapDefault = () => {

		loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyD1AFWPoJqPaemwGO4A7P7Q6CsUJ1TLGJk&libraries=places,drawing&callback=initMapDefault");
		window.initMapDefault = this.initMapDefault
	}
	renderMap = () => {

		loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyD1AFWPoJqPaemwGO4A7P7Q6CsUJ1TLGJk&libraries=places,drawing&callback=initMap");
		window.initMap = this.initMap
	}
	initMapDefault = () => {

		var lt = 12.974276546287868;
		var ln = 80.2426101;

		map = new window.google.maps.Map(document.getElementById('map_canvas'), {
			zoom: 13,
			center: new window.google.maps.LatLng(lt, ln),
			mapTypeId: window.google.maps.MapTypeId.ROADMAP,
			/*mapTypeControl: true,
			gestureHandling: 'greedy',
			mapTypeControlOptions: {
				style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
				position: window.google.maps.ControlPosition.TOP_RIGHT
			  },
			disableDefaultUI: true,
			zoomControl: true*/
		});

		var input = document.getElementById('search');
		var searchBox = new window.google.maps.places.SearchBox(input);
		map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);

		// Bias the SearchBox results towards current map's viewport.
		map.addListener('bounds_changed', function () {

			searchBox.setBounds(map.getBounds());
		});

		var markers = [];
		//console.log("searchBox",searchBox);
		searchBox.addListener('places_changed', function () {
			var places = searchBox.getPlaces();

			if (places.length == 0) {
				return;
			}

			// Clear out the old markers.
			markers.forEach(function (marker) {
				console.log("Search Marker ", marker)
				marker.setMap(null);
			});
			markers = [];
			// For each place, get the icon, name and location.
			var bounds = new window.google.maps.LatLngBounds();
			places.forEach(function (place) {
				if (!place.geometry) {
					console.log("Returned place contains no geometry");
					return;
				}
				var icon = {
					url: place.icon,
					size: new window.google.maps.Size(71, 71),
					origin: new window.google.maps.Point(0, 0),
					anchor: new window.google.maps.Point(17, 34),
					scaledSize: new window.google.maps.Size(25, 25)
				};

				// Create a marker for each place.
				//console.log("Plae Lat", place.geometry.location.lat());
				document.getElementById("latitude").value = place.geometry.location.lat();

				document.getElementById("longitude").value = place.geometry.location.lng();

				markers.push(new window.google.maps.Marker({
					map: map,
					icon: icon,
					title: place.name,
					position: place.geometry.location
				}));

				if (place.geometry.viewport) {
					// Only geocodes have viewport.
					bounds.union(place.geometry.viewport);
				} else {
					bounds.extend(place.geometry.location);
				}
			});

			map.fitBounds(bounds);
		});

		circle = new window.google.maps.Circle({
			map: map
		});

		marker = new window.google.maps.Marker({
			map: map,
			draggable: true,
			animation: window.google.maps.Animation.DROP,
			//position: {lat: 28.48, lng: 77.06}
		});

		marker.addListener('click', function () {
			marker.setAnimation(window.google.maps.Animation.BOUNCE)
		});

		window.google.maps.event.addListener(map, 'click', function (event) {

			document.getElementById("latitude").value = event.latLng.lat();
			document.getElementById("longitude").value = event.latLng.lng();
			marker.setPosition(event.latLng);

		});
		circle.bindTo('center', marker, 'position');



		var eventtrigger = document.getElementsByClassName('truck');

		// var searchBox = new window.google.maps.places.SearchBox(eventtrigger);
		//map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(eventtrigger);

	}

	initMap = () => {
		allcords = [];
		this.state.coordinates.map((marker) => {
			allcords.push(marker);
		});

		var contentdata = this.state.contentString;
		if (this.state.rlatitude == '' && this.state.rlongitude == '') {
			var lt = 12.974276546287868;
			var ln = 80.2426101;
		}
		else {
			var lt = this.state.rlatitude;
			var ln = this.state.rlongitude;
		}
		try {
			map = new window.google.maps.Map(document.getElementById('map_canvas'), {
				zoom: 5,
				center: new window.google.maps.LatLng(lt, ln),
				mapTypeId: window.google.maps.MapTypeId.ROADMAP,
				/*mapTypeControl: true,
				gestureHandling: 'greedy',
				mapTypeControlOptions: {
					style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
					position: window.google.maps.ControlPosition.TOP_RIGHT
				  },
				disableDefaultUI: true,
				zoomControl: true*/
			});
		}
		catch (e) {
			map = new window.google.maps.Map(document.getElementById('map_canvas'), {
				zoom: 5,
				center: new window.google.maps.LatLng(parseFloat(21.144644112601775), parseFloat(79.08860126768066)),
				mapTypeId: window.google.maps.MapTypeId.ROADMAP,
				/*mapTypeControl: true,
				gestureHandling: 'greedy',
				mapTypeControlOptions: {
					style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
					position: window.google.maps.ControlPosition.TOP_RIGHT
				  },
				disableDefaultUI: true,
				zoomControl: true*/
			});
		}

		circle = new window.google.maps.Circle({
			map: map
		});

		marker = new window.google.maps.Marker({
			map: map,
			draggable: true,
			animation: window.google.maps.Animation.DROP,
			//position: {lat: 28.48, lng: 77.06}
		});

		marker.addListener('click', function () {
			marker.setAnimation(window.google.maps.Animation.BOUNCE)
		});

		window.google.maps.event.addListener(map, 'click', function (event) {

			//document.getElementById("latitude").value = event.latLng.lat();
			//document.getElementById("longitude").value = event.latLng.lng();
			marker.setPosition(event.latLng);

		});
		circle.bindTo('center', marker, 'position');
		var markerLatlng1;
		//console.log("Radius ", this.state.radius)
		// console.log(allcords);
		var markers = allcords.map((marker) => {
			// console.log("marker ",marker);
			if (marker.status == 1) {
				var truckText = "Inside Plant";
				var color = "#333333";
				var image = require('../../assets/icons/truck_inside_24.png');
			}
			else if (marker.status == 2) {
				var truckText = "Going to Dealer";
				var color = "#333333";
				var image = require('../../assets/icons/truck_going_24.png');
			}
			else if (marker.status == 3 || marker.status == 4) {
				var truckText = "At Dealer Location";
				var color = "#333333";
				var image = require('../../assets/icons/truck_atdealer_24.png');
			}
			else if (marker.status == 5) {
				var truckText = "Left Destination Location";
				var color = "#333333";
				var image = require('../../assets/icons/truck_leftdealer_24.png');
			}
			else if (marker.status == 6) {
				var truckText = "Return to Plant";
				var color = "#333333";
				var image = require('../../assets/icons/truck_return_24.png');
			}
			else if (marker.status == 7) {
				var truckText = "Proximity Trip Closure";
				var color = "#333333";
				var image = require('../../assets/icons/truck_empty_24.png');
			}
			else {
				var truckText = marker.device_id;
				var color = "#333333";
				var image = require('../../assets/icons/truck_na_24.png');
			}
			var image = require('../../assets/icons/truck_na_24.png');
			var contentarr = []
			var headerTitle = truckText
			//contentarr.push({"key":"Truck No", "value":marker.truck_no});
			contentarr.push({ "key": "Device No", "value": marker.device_id });
			contentarr.push({ "key": "Transporter Name", "value": marker.transporter_name });
			//contentarr.push({"key":"Distance from geofence", "value":marker.distance});
			contentarr.push({ "key": "GPS Provider", "value": marker.actual_lspuser });
			contentarr.push({ "key": "Last Packet Time", "value": getHyphenDDMMMYYYYHHMM(marker.timestamp) });
			contentarr.push({ "key": "Speed (km/h)", "value": Math.ceil(marker.speed) });
			contentarr.push({ "key": "Address", "value": marker.area + ", " + marker.state });

			var contentString = infoBox(image, headerTitle, contentarr, '')


			// var contentString = "<table><tr><th>Status: </th><td style='color:"+color+";font-weight:600;text-align: right'>"+truckText+"</td></tr><tr><th>Distance from geofence: </th><td style='text-align: right'>";
			// contentString = contentString + marker.distance+"</td></tr><th>Truck No: </th><td style='text-align: right'>"+marker.truck_no+"</td></tr><tr><th>Name: </th><td style='text-align: right'>";
			// contentString = contentString + marker.transporter_name+"</td></tr><tr><th> LSP Name:</th><td style='text-align: right'>"+marker.lsp_name+"</td></tr><tr><th>Address:</th><td style='text-align: right'>";
			// contentString = contentString  +marker.address+"</td></tr></table>";

			//var contentString = "Here "+marker.truck_no;

			//var contentString = infoBox(require('../../assets/icons/truck_inside_24.png'), header, contentarr)

			var infowindow = new window.google.maps.InfoWindow({
				content: contentString
			});

			var markerLatlng = new window.google.maps.LatLng(parseFloat(marker.latitude), parseFloat(marker.longitude));

			if (marker.status == 1) {
				var image = require('../../assets/icons/truck_inside_24.png');
			}
			else if (marker.status == 2) {
				var image = require('../../assets/icons/truck_going_24.png');
			}
			else if (marker.status == 3 || marker.status == 4) {
				var image = require('../../assets/icons/truck_atdealer_24.png');
			}
			else if (marker.status == 5) {
				var image = require('../../assets/icons/truck_leftdealer_24.png');
			}
			else if (marker.status == 6) {
				var image = require('../../assets/icons/truck_return_24.png');
			}
			else if (marker.status == 7) {
				var image = require('../../assets/icons/truck_empty_24.png');
			}
			else {
				var image = require('../../assets/icons/truck_na_24.png');
			}
			var image = require('../../assets/icons/truck_na_24.png');
			/*
			if(marker.overspeeding_exception == 1)
			{
				var image =  require('../../assets/icons/truck_overspeed.png');
			}
			if(marker.nightdriving_exception == 1)
			{
				var image =  require('../../assets/icons/truck-end.png');
			}
			*/


			var mark = new window.google.maps.Marker({
				position: markerLatlng,
				map: map,
				title: "Status: " + truckText + " #" + marker.truck_no,
				icon: image
			});
			mark.addListener('click', function () {
				infowindow.open(map, mark);
			});
			return mark
			mark.setMap(map);
		});
		var eventtrigger = document.getElementsByClassName('truck');
		var markerCluster = new MarkerClusterer(map, markers,
			{ imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m' });
		// var searchBox = new window.google.maps.places.SearchBox(eventtrigger);
		//map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(eventtrigger);
		if (this.state.plantLocations.length > 0) {
			this.state.plantLocations.map(function (params) {
				var plant = {
					url: require("../../assets/icons/" + params.icon), // url
					scaledSize: new window.google.maps.Size(32, 32), // scaled size
					origin: new window.google.maps.Point(0, 0), // origin
					anchor: new window.google.maps.Point(0, 0) // anchor
				};
				var coords = JSON.parse(params.coordinates);
				var plantmarker = new window.google.maps.Marker({
					position: new window.google.maps.LatLng(coords.lat, coords.lng),
					map: map,
					icon: plant,
					title: params.plant_name
				});
				// var cityCircle = new window.google.maps.Circle({
				// strokeColor: '#9fc9b9',
				// strokeOpacity: 0.3,
				// strokeWeight: 2,
				// fillColor: '#9fc9b9',
				// fillOpacity: 0.6,
				// map: map,
				// center:coords,
				// radius: 50 * 1000
				// });
			})
		}
	}
	toggleBounce() {
		if (marker.getAnimation() !== null) {
			marker.setAnimation(null);
		} else {
			marker.setAnimation(window.google.maps.Animation.BOUNCE);
		}

	}

	changeInputHandler = (event) => {
		let name = event.target.name;
		let value = event.target.value;
		this.setState({ [name]: value });
	}

	changeRadiusHandler = (event) => {
		let name = event.target.name;
		let value = event.target.value;
		this.setState({ [name]: value });
		circle.setRadius(value);
	}


	goBack() {
		window.location.reload();
	}
	getDistance = (p1, p2) => {
		//console.log("p1",p1);
		//console.log("p2",p2);
		var R = 6378137; // Earth’s mean radius in meter
		var dLat = rad(p2.lat - p1.lat);
		var dLong = rad(p2.lng - p1.lng);
		var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(rad(p1.lat)) * Math.cos(rad(p2.lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
		var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		var d = R * c;
		return d / 1000; // returns the distance in meter
	};
	onShowInfo = (e) => {
		// console.log(JSON.parse(e.target.id));
		this.setState({
			contentString: "Here Success"
		});
		var ourMarker = e.target.id;
		window.google.maps.event.trigger(ourMarker, 'click');
		var infowindow = new window.google.maps.InfoWindow({
			content: "Here Success"
		});
		infowindow.open(map, marker);

	}
	popmarker(e) {
		//console.log("OnClick Table ",e.data)
		var lat = parseFloat(e.data.latitude);
		var lng = parseFloat(e.data.longitude);
		var data = e.data;
		map.setCenter(new window.google.maps.LatLng(lat, lng));
		map.setZoom(22);
		var markerLatlng = new window.google.maps.LatLng(lat, lng);
		var image = require('../../assets/icons/truckblue.png');
		var marker = new window.google.maps.Marker({
			position: markerLatlng,
			map: map,
			title: data.truck_no,
			icon: image
		});
		// var contentString = "Truck No: "+data.truck_no+"<br />Name: "+data.transporter_name+" LSP Name:"+data.lsp_name+"<br />Address:"+data.address;
		// var contentString = "<table><tr><th>Status: </th><td style='text-align: right'>"+data.status;
		// contentString = contentString +"</td></tr><th>Truck No: </th><td style='text-align: right'>"+data.truck_no;
		// contentString = contentString + "</td></tr><tr><th>Name: </th><td style='text-align: right'>"+data.transporter_name+"</td></tr><tr><th> LSP Name:</th><td style='text-align: right'>"+data.lsp_name;
		// contentString = contentString + "</td></tr><tr><th>Address:</th><td style='text-align: right'>"+data.address+"</td></tr></table>";

		var contentarr = []
		var headerTitle = data.device_id
		//contentarr.push({"key":"Truck No", "value":marker.truck_no});
		contentarr.push({ "key": "Device No", "value": data.device_id });
		contentarr.push({ "key": "Transporter Name", "value": data.transporter_name });
		//contentarr.push({"key":"Distance from geofence", "value":marker.distance});
		contentarr.push({ "key": "GPS Provider", "value": data.actual_lspuser });
		contentarr.push({ "key": "Last Packet Time", "value": getHyphenDDMMMYYYYHHMM(data.timestamp) });
		contentarr.push({ "key": "Speed (km/h)", "value": Math.ceil(data.speed) });
		contentarr.push({ "key": "Address", "value": data.area + ", " + data.state });

		var contentString = infoBox(image, headerTitle, contentarr, '')
		var infowindow = new window.google.maps.InfoWindow({
			content: contentString
		});

		marker.setAnimation(window.google.maps.Animation.DROP)
		marker.addListener('click', function () {
			infowindow.open(map, marker);
		});

		marker.setMap(map);


	}
	onShowUploadDiv = () => {
		//console.log("Click Upload Button")
		this.setState({
			uploadDivWidth: '30%',
			sliderTranslate: "slider-translate",
			showDiv: 'show-m',
			csvmessage: '',
			csverrmessage: '',
		});
	}
	onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth: '0%',
			sliderTranslate: "",
			showDiv: 'show-n',
			uploadFile: '',
			file: '',
			csvmessage: '',
			csverrmessage: '',
		});
	}

	onSelectTrucks() {
		var items = []
		this.state.alltrucks.map((item) => {
			items.push({ value: item, label: item.truck_no });
		})
		return items;
	}
	changeTruckHandler(event) {
		let name = event.target.name;
		let value = event.target.value;
		this.setState({ [name]: value });
	}
	truckFormHandler = (e) => {
		e.preventDefault();
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmittedSearchByTruckNo
			}
			googleAnalytics.logEvent(eventOptions);
		}
		var truck = []
		// var trucks = this.state.alltrucks;

		var trucks = this.state.rowData;
		//  console.log("trucks",trucks);
		//   console.log($("#inputTruck").val(),"input truck");
		//    console.log("truck_no",this.state.truck_no)
		var filteredValue = trucks.filter((e) => {
			// console.log("this.state.truck_no",this.state.truck_no)
			// console.log("e.truck_no == this.state.truck_no.value",e.truck_no == this.state.truck_no.value)
			if (this.state.truck_no.value != "" && this.state.truck_no.value != undefined) {
				// console.log(e.truck_no +"=="+ this.state.truck_no.value)
				if (e.device_id == this.state.truck_no.value) {
					return e;
				}
			}
			// return e.truck_no == this.state.truck_no.value
		});
		// console.log("filteredValue",filteredValue)
		if (filteredValue.length > 0) {
			// console.log("Matched")
			var lat = parseFloat(filteredValue[0].latitude);
			var lng = parseFloat(filteredValue[0].longitude);
			var data = filteredValue[0];
			// console.log("data",data)
			map.setCenter(new window.google.maps.LatLng(lat, lng));
			map.setZoom(22);
			var markerLatlng = new window.google.maps.LatLng(lat, lng);

			if (filteredValue[0].status == 1) {
				var truckText = "Inside Plant";
				var color = "#333333";
			}
			else if (filteredValue[0].status == 2) {
				var truckText = "Going to Dealer";
				var color = "#333333";
			}
			else if (filteredValue[0].status == 3 || filteredValue[0].status == 4) {
				var truckText = "At Dealer Location";
				var color = "#333333";
			}
			else if (filteredValue[0].status == 5) {
				var truckText = "Left Destination Location";
				var color = "#333333";
			}
			else if (filteredValue[0].status == 6) {
				var truckText = "Return to Plant";
				var color = "#333333";
			}
			else if (filteredValue[0].status == 7) {
				var truckText = "Proximity Trip Closure";
				var color = "#333333";
			}
			else {
				var truckText = filteredValue[0].device_id;
				var color = "#333333";
			}

			if (filteredValue[0].status == 1) {
				var image = require('../../assets/icons/truck_inside_24.png');
			}
			else if (filteredValue[0].status == 2) {
				var image = require('../../assets/icons/truck_going_24.png');
			}
			else if (filteredValue[0].status == 3 || filteredValue[0].status == 4) {
				var image = require('../../assets/icons/truck_atdealer_24.png');
			}
			else if (filteredValue[0].status == 5) {
				var image = require('../../assets/icons/truck_leftdealer_24.png');
			}
			else if (filteredValue[0].status == 6) {
				var image = require('../../assets/icons/truck_return_24.png');
			}
			else if (filteredValue[0].status == 7) {
				var image = require('../../assets/icons/truck_empty_24.png');
			}
			else {
				var image = require('../../assets/icons/truck_na_24.png');
			}
			var image = require('../../assets/icons/truck_na_24.png');
			var marker = new window.google.maps.Marker({
				position: markerLatlng,
				map: map,
				title: data.device_id,
				icon: image
			});



			// var contentarr = []
			// var headerTitle = truckText
			// contentarr.push({"key":"Truck No", "value":filteredValue[0].truck_no});
			// contentarr.push({"key":"Transporter Name", "value":filteredValue[0].transporter_name});
			// //contentarr.push({"key":"Distance from geofence", "value":trucks[i].distance});
			// contentarr.push({"key":"LSP Name", "value":filteredValue[0].lsp_name});
			// contentarr.push({"key":"Address", "value":filteredValue[0].address});

			var contentarr = []
			var headerTitle = truckText
			contentarr.push({ "key": "Device No", "value": filteredValue[0].device_id });
			contentarr.push({ "key": "Transporter Name", "value": filteredValue[0].transporter_name });
			//contentarr.push({"key":"Distance from geofence", "value":marker.distance});
			contentarr.push({ "key": "GPS Provider", "value": filteredValue[0].actual_lspuser });
			contentarr.push({ "key": "Last Packet Time", "value": getHyphenDDMMMYYYYHHMM(filteredValue[0].timestamp) });
			contentarr.push({ "key": "Speed (km/h)", "value": Math.ceil(filteredValue[0].speed) });
			contentarr.push({ "key": "Address", "value": filteredValue[0].area + ", " + filteredValue[0].state });

			var contentString = infoBox(image, headerTitle, contentarr, '')

			// var contentString = infoBox(image, headerTitle, contentarr, '')

			// var contentString = "<table><tr><th>Status: </th><td style='color:"+color+";font-weight:600;text-align: right'>"+truckText+"</td></tr><tr><th>Distance from geofence: </th><td style='text-align: right'>";
			// contentString = contentString + trucks[i].distance+"</td></tr><th>Truck No: </th><td style='text-align: right'>"+trucks[i].truck_no+"</td></tr><tr><th>Name: </th><td style='text-align: right'>";
			// contentString = contentString + trucks[i].transporter_name+"</td></tr><tr><th> LSP Name:</th><td style='text-align: right'>"+trucks[i].lsp_name+"</td></tr><tr><th>Address:</th><td style='text-align: right'>";
			// contentString = contentString  +trucks[i].address+"</td></tr></table>";


			var infowindow = new window.google.maps.InfoWindow({
				content: contentString
			});

			marker.setAnimation(window.google.maps.Animation.DROP)
			marker.addListener('click', function () {
				infowindow.open(map, marker);
			});

			marker.setMap(map);
		}
		else {
			this.setState({
				show: true, basicType: 'warning',
				basicTitle: "No Data found.",
				loadshow: 'show-n',
				showform: 'show-m',
			})
		}
		// console.log(filteredValue);
		// for (var i = 0; i < trucks.length; i++){
		// 	// look for the entry with a matching `code` value
		// 	if (trucks[i].truck_no == $("#inputTruck").val()){
		// 	   // we found it
		// 	  // trucks[i].name is the matched result

		// 	}
		//   }
		/*
				var lat = parseFloat(e.value.latitude);
				var lng =parseFloat(e.value.longitude);
				var data =e.value;
				map.setCenter(new window.google.maps.LatLng(lat,lng));
				map.setZoom(22);
				var markerLatlng = new window.google.maps.LatLng(lat, lng);

				if(e.value.status == 1)
				{
					var image =  require('../../assets/icons/truck_inside_24.png');
				}
				else if(e.value.status == 2)
				{
					var image =  require('../../assets/icons/truck_going_24.png');
				}
				else if(e.value.status == 3 || e.value.status == 4)
				{
					var image =  require('../../assets/icons/truck_atdealer_24.png');
				}
				else if(e.value.status == 5)
				{
					var image =  require('../../assets/icons/truck_leftdealer_24.png');
				}
				else if(e.value.status == 6)
				{
					var image =  require('../../assets/icons/truck_return_24.png');
				}
				else if(e.value.status == 7)
				{
					var image =  require('../../assets/icons/truck_empty_24.png');
				}
				else{
					var image =  require('../../assets/icons/truck_na_24.png');
				}

				var marker = new window.google.maps.Marker({
					position: markerLatlng,
					map: map,
					title:data.truck_no,
					icon:image
				});
				var contentString = "Truck No: "+data.truck_no+"<br />Name: "+data.transporter_name+" LSP Name:"+data.lsp_name+"<br />Address:"+data.address;
				var contentString = "<table><tr><th>Status: </th><td style='text-align: right'>"+data.status;
				contentString = contentString +"</td></tr><tr><th>Distance from geofence: </th><td style='text-align: right'>"+data.distance+" (KM)</td></tr><th>Truck No: </th><td style='text-align: right'>"+data.truck_no;
				contentString = contentString + "</td></tr><tr><th>Name: </th><td style='text-align: right'>"+data.transporter_name+"</td></tr><tr><th> LSP Name:</th><td style='text-align: right'>"+data.lsp_name;
				contentString = contentString + "</td></tr><tr><th>Address:</th><td style='text-align: right'>"+data.address+"</td></tr></table>";


				var infowindow = new window.google.maps.InfoWindow({
					content: contentString
				});

				marker.setAnimation(window.google.maps.Animation.DROP)
				marker.addListener('click', function() {
					infowindow.open(map, marker);
				});

				marker.setMap(map);
		*/
	}
	onCloseUserManualModal = () => {
		this.setState({ usermanualmodal: false });
	};

	onClickShowUserManaul = () => {
		this.setState({ usermanualmodal: true });
	};

	onClickWeatherForecast(e) {
		this.setState({ displayWeatherForecast: !this.state.displayWeatherForecast });
		// weather forecast
		if (!this.state.displayWeatherForecast) {
			var weatherMarkers = [];
			this.state.weatherData.map(function (params) {
				if (params.address.includes("NA") == false && params.address != "/") {
					// bounds.extend(new window.google.maps.LatLng(params.latitude,params.longitude));
					var url = "";
					var iconName = "";

					if (params.description.includes("Thunderstorm") == true) {
						url = require("../../assets/icons/thunderstorm.png")
					}
					else if (params.description.includes("Heavy Rain and Strong Winds") == true) {
						url = require("../../assets/icons/wind.png")
					}
					else if (params.description.includes("Heavy Rain") == true) {
						url = require("../../assets/icons/Heavyrain.png")
					}
					else if (params.description.includes("Cyclone") == true) {
						url = require("../../assets/icons/cyclone.png")
					}
					else if (params.description.includes("Hailstroms") == true) {
						url = require("../../assets/icons/hailstorm.png")
					}
					else if (params.description.includes("Sandstorm") == true || params.description.includes("Duststorm") == true) {
						url = require("../../assets/icons/sandstorm.png")
					}
					else {

					}
					var iconName = {
						url: url, // url
						scaledSize: new window.google.maps.Size(32, 32), // scaled size
						origin: new window.google.maps.Point(0, 0), // origin
						anchor: new window.google.maps.Point(0, 0) // anchor
					};

					var marker = new window.google.maps.Marker({
						position: new window.google.maps.LatLng(params.latitude, params.longitude),
						map: map,
						icon: iconName,
						// title : ""+params.address+","+params.forecast_date+","+params.description
					});
					var infowindow = new window.google.maps.InfoWindow({
						content: "<div class='weather-marker-tooltip'><div class='forecast-date'><label class='forecast-labels'> Forecast Date : </label> " + moment.parseZone(new Date(params.forecast_date)).format("DD-MMM-YYYY") + "</div><div class='address'> <label class='forecast-labels'>Address :</label> " + params.address + "</div><div class='weather-desc'><label class='forecast-labels'> Weather : </label> " + params.description + "</div><div class='last-updated'><label class='forecast-labels'> Last Updated On : </label> " + getHyphenDDMMMYYYYHHMM(params.last_updated_on) + "</div>"
					});
					window.google.maps.event.addListener(marker, 'mouseover', function () {
						infowindow.open(map, marker);
					});
					window.google.maps.event.addListener(marker, 'mouseout', function () {
						infowindow.close();
					});
					marker.setVisible(true)
					weatherMarkers.push({
						"marker": marker,
						"desc": params.description,
						"url": url
					})
				}
			});
			this.setState({
				weatherMarkers: weatherMarkers
			})
		}
		else {
			if (this.state.weatherMarkers.length) {
				this.state.weatherMarkers.map((e) => {
					e.marker.setMap(null);
				})
				this.setState({
					weatherMarkers: []
				})
			}
		}
	}

	onClickFilterWeather(clickedType) {
		var weatherMarkers = this.state.weatherMarkers;
		if (clickedType == 1) {
			weatherMarkers.map((e) => {
				e.marker.setVisible(true);
			})
		}
		else if (clickedType == 2) {
			weatherMarkers.map((e) => {
				if (e.desc == "Heavy Rain") {
					e.marker.setVisible(true);
				}
				else {
					e.marker.setVisible(false);
				}
			})
		}
		else if (clickedType == 3) {
			weatherMarkers.map((e) => {
				if (e.desc.includes("Winds")) {
					e.marker.setVisible(true);
				}
				else {
					e.marker.setVisible(false);
				}
			})
		}
		else if (clickedType == 4) {
			weatherMarkers.map((e) => {
				if (e.desc.includes("Thunderstorm")) {
					e.marker.setVisible(true);
				}
				else {
					e.marker.setVisible(false);
				}
			})
		}
		else if (clickedType == 5) {
			weatherMarkers.map((e) => {
				if (e.desc.includes("Hailstrom")) {
					e.marker.setVisible(true);
				}
				else {
					e.marker.setVisible(false);
				}
			})
		}
		else if (clickedType == 6) {
			weatherMarkers.map((e) => {
				if (e.desc.includes("Cyclone")) {
					e.marker.setVisible(true);
				}
				else {
					e.marker.setVisible(false);
				}
			})
		}
		else if (clickedType == 7) {
			weatherMarkers.map((e) => {
				if (e.desc.includes("Sandstorm")) {
					e.marker.setVisible(true);
				}
				else {
					e.marker.setVisible(false);
				}
			})
		}
	}

	onShowGridPop(e) {
		this.setState({
			loadshow: 'show-m'
		})
		var jsondata = this.state.gridData;
		var coordinates = jsondata;
		//console.log("truckno ", this.props.truckno)
		// console.log("coordinates ", coordinates)
		// var breaks = []
		// if(locations.length > 0)
		// {
		// 	locations.map((i) => {
		// 		console.log("substr",(i.lat).toString().substr(0,8))
		// 		breaks.push((i.lat).toString().substr(0,8))
		// 	})
		// }
		var dataarr = []
		//console.log("coordinates ", coordinates)
		coordinates.map((item) => {
			// console.log("breaksincludes",breaks.includes(item.lat))
			// if(breaks.indexOf(item.lat) >= 0)
			// {
			// 	console.log("stopages registered")
			// 	item['stopages'] = 1
			// }else{
			// 	item['stopages'] = 0
			// }
			dataarr.push({ "device_id": item.device_id, "lat": item.lat, "lng": item.lng, "speed": item.speed, "stime": item.stime, "dist_from_prev_point": item.dist_from_prev_point, "received_on": item.received_on, "time_from_prev_point": item.time_from_prev_point, 'address': item.address })
		});
		this.setState({
			rowData: jsondata,
			open: true,
			loadshow: 'show-n'
		})
	}

	onGridReady = params => {
		this.gridApi = params.api;
		//console.log("Grid is: ", params);
		this.gridColumnApi = params.columnApi;
		//    ChartMenu.prototype.saveChart = function (e) {
		// 	   var event = {
		// 	      data: this.gridOptionsWrapper.getApi(),
		// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
		// 	    };
		// 	   //console.log("Testing", event);
		// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
		// 	   this.dispatchEvent(event);
		// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		//    };
		//console.log("Deptcode " ,this.state.deptcode)
		if (this.state.deptcode == 'LOG-PRT') {
			this.gridColumnApi.setColumnsVisible(["nightdriving_exception"], false);
			this.gridColumnApi.setColumnsVisible(["loading_delay_exception"], false);
		}
		if (this.state.deptcode == 'SNDG') {
			this.gridColumnApi.setColumnsVisible(["nightdriving_exception"], true);
			this.gridColumnApi.setColumnsVisible(["loading_delay_exception"], true);
		}
	};


	onGridState = () => {
		//console.log(this.gridApi);
		/*Get  Current Columns State and Store in this.colState */
		this.colState = this.gridColumnApi.getColumnState();
		/*Get Current RowGroup Columns State and Store in this.rowGroupState */
		this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
		/*Get Current Pivot Columns State and Store in this.pivotColumns,
		 * User should enable the Pivot mode.*/
		this.pivotColumns = this.gridColumnApi.getPivotColumns();
		/*Get Current Filter State and Store in window.filterState */
		window.filterState = this.gridApi.getFilterModel();
		this.gridApi.setFilterModel(window.filterState);
	}

	onCloseModal = () => {
		this.setState({ open: false });
	};


	handleCheckboxChange = (checkboxName) => {

		this.setState({
			loadshow: 'show-m'
		});
		this.setState(prevState => ({
			[checkboxName]: !prevState[checkboxName]
		}), () => {

			let dept = "SNDG";
			let hText = 'All';
			if (this.props.match.url == '/nearbyprttrucks') {
				dept = 'LOG-PRT';
				hText = 'Spare Parts';
			}
			if (this.props.match.url == "/mllnearbysndtrucks") {
				dept = 'SNDG';
				hText = '';
			}
			if (this.props.match.url == "/nearbysndtrucks") {
				dept = 'SNDG';
				hText = '';
			}
			if (this.props.match.url == "/nearbysctrucks") {
				dept = 'LOG-SC';
				hText = '( Supply Chain )';
			}
			if (this.props.match.url == "/nearbytnptrucks") {
				dept = 'LOG-TNP';
				hText = '( Train and Production )';
			}

			let pageTitle = "Device Locations";
			this.setState({
				deptcode: dept,
				pageTitle: pageTitle
			});

			var formdata = {
				radius: 3000,
				latitude: "20.73568784876339",
				longitude: "79.38999444931353",
				dept_code: dept,
				transporter_code: ["ALL"],
				status: "0",
				trip_type: 1,
				mapped: this.state.mapped,
				gpsdata: this.state.gpsdata
			}
			rlatitude = "20.73568784876339";
			rlongitude = "79.38999444931353";
			//http://autometrics.in:5000/getNearByTrucks
			redirectURL.post('/vicinity/getDevicRadiusCoordinates', formdata, {
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
					'Access-Control-Allow-Methods': 'PUT, GET, POST, DELETE, OPTIONS',
					'Access-Control-Allow-Headers': "Origin, X-Requested-With, Content-Type, Accept, Authorization"

				}
			})
				.then(
					(response) => {
						try {
							console.log("response", JSON.parse(response.data.apiData.body));
							if (JSON.parse(response.data.apiData.body).devices_data == undefined) {
								this.setState({
									show: true, basicType: 'warning', basicTitle: "No Data found.",
									loadshow: 'show-n',
									showform: 'show-m',
								});
								this.renderMapDefault();
							}
							else {
								var markers = JSON.parse(JSON.parse(response.data.apiData.body).devices_data);
								// console.log(JSON.parse(response.data.apiData.body))

								if (markers.length > 0) {
									this.setState({
										defaultradius: formdata.radius,
										coordinates: markers,
										onTripCounter: JSON.parse(response.data.apiData.body).gps_devices,
										mwidth: "col-xl-12 col-lg-12",
										rowData: markers,
										radius: formdata.radius,
										loadshow: 'show-n',
										showform: 'show-n',
										showrefresh: 'show-m',
										rlatitude: formdata.latitude,
										rlongitude: formdata.longitude,
										'gridData': markers
									});
									redirectURL.post("/weather/getPlants").then((rsp) => {
										// console.log("getPlants", rsp.data)
										let plantList = rsp.data.filter(e => e.dept_code === dept)
										this.setState({
											plantLocations: plantList,
										});
										this.renderMap();
									})


								}
								else {
									this.setState({
										show: true, basicType: 'warning', basicTitle: "No Data found.",
										loadshow: 'show-n',
										showform: 'show-m',
									});
									this.renderMapDefault();
								}
							}
						}
						catch (e) { }
					})
				.catch(function (error) {
					console.log(error);
				});

		});
	}

	render() {
		const modalStyles = {
			width: '1300px !important',
		}

		const { usermanualmodal } = this.state;
		const { truckstatus, open } = this.state;
		const truckstatusoptions = [
			{ value: '0', label: 'All Status' },
			{ value: '1', label: 'Inside Plant' },
			{ value: '2', label: 'Going to Destination' },
			{ value: '3', label: 'At Destination Location' },
			{ value: '5', label: 'Left Destination Location' },
			/*{ value: '6', label: 'Return to Plant' },*/
			{ value: '7', label: 'Empty Truck Outside Plant' },
		];
		const kpioptions = [
			{ value: '0', label: 'All Status' },
			{ value: '1', label: 'Overspeed' },
			{ value: '2', label: 'Transit Time Delay' },
			{ value: '3', label: 'Night Driving' },
			{ value: '4', label: 'Enroute Stoppages' }
		];
		const dStyles = {
			width: '90%',
			marginLeft: '0px',
			paddingLeft: '0px',
			height: '500px'
		}
		const btstye = {
			marginBottom: "10px"
		}
		var listData = []
		try {
			this.state.filteredData.map((e) => {
				listData.push(<li className="dropdown-truck-no" key={e}>{e}</li>);
			})
		} catch (e) { console.log(e) }

		const columnwithDefs = [
			{
				headerName: "Device No",
				field: "device_id",
				width: 150,
				// filter: false,
				resizable: true,
				// cellClass : params =>{
				// 	if(params.data.stopages == 1) return 'red'
				// 		return 'green'
				// }
			},
			{
				headerName: "Latitude",
				field: "latitude",
				width: 120,
				// filter: false,
				resizable: true,
				valueGetter: function (params) {
					return params.data.latitude;
				},
				// cellClass : params =>{
				// 	if(params.data.stopages == 1) return 'red'
				// 		return 'green'
				// }
			},
			{
				headerName: "Longitude",
				field: "longitude",
				width: 120,
				// filter: false,
				resizable: true,
				valueGetter: function (params) {
					return params.data.longitude;
				},
				// cellClass : params =>{
				// 	if(params.data.stopages == 1) return 'red'
				// 		return 'green'
				// }
			},
			{
				headerName: "Speed (Kms)",
				field: "speed",
				width: 120,
				// filter: false,
				resizable: true,
				// cellClass : params =>{
				// 	if(params.data.stopages == 1) return 'red'
				// 		return 'green'
				// }
			},
			{
				headerName: "Packet Time",
				field: "timestamp",
				width: 140,
				// filter: false,
				resizable: true,
				valueGetter: function (params) {
					//console.log(params);
					return getDDMMYYYYHHMMSS(params.data.timestamp);
				},
				// cellClass : params =>{
				// 	if(params.data.stopages == 1) return 'red'
				// 		return 'green'
				// }
				// valueGetter:function(params){
				// 	console.log(params);
				// 	return params.data.timestamp;
				// }

			},
			// {
			// 	headerName: "Received On",
			// 	field: "received_on",
			// 	width: 140,
			// 	// filter: false,
			// 	resizable: true,
			// 	valueGetter: function (params) {
			// 		//console.log(params);
			// 		return getDDMMYYYYHHMMSS(params.data.received_on);
			// 	},			
			// 	// cellClass : params =>{
			// 	// 	if(params.data.stopages == 1) return 'red'
			// 	// 		return 'green'
			// 	// }
			// },
			// {
			// 	headerName: "Distance From previous Point (Meters)",
			// 	field: "dist_from_prev_point",
			// 	width: 250,
			// 	// filter: false,
			// 	resizable: true,
			// 	// cellClass : params =>{
			// 	// 	if(params.data.stopages == 1) return 'red'
			// 	// 		return 'green'
			// 	// }
			// },

		]

		return (

			<div className="container-fluid">
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					onConfirm={this.closeAlert}
				>
				</SweetAlert>
				{this.state.alert}
				<div className="mb-15p fbold f20">
					<h5>
						{/* <i className="icofont icofont-truck cus-i"></i> <span> Trucks Locations ( {hText} ) </span> */}
						<i className="icofont icofont-truck cus-i"></i> <span> {this.state.pageTitle} </span>

					</h5>
				</div>
				<div className="row">
					<div className="col-xl-12 col-lg-12">
						<div className="card">
							{/* <div className="card-header">
				       			
				   			</div> */}
							<div className="card-body row">
								{/*  <span className="col-xl-12 col-lg-12 float-right" style={{fontSize:"12px",marginBottom:"20px"}}>
									<img className="mapico1" src={require('../../assets/icons/truck_na_32.png')} /> - N/A
									&nbsp;&nbsp;<img className="mapico1" src={require('../../assets/icons/truck_inside_32.png')} /> - Inside Plant
									&nbsp;&nbsp;<img className="mapico1" src={require('../../assets/icons/truck_going_32.png')} /> - Going to Dealer
									&nbsp;&nbsp;<img className="mapico1" src={require('../../assets/icons/truck_atdealer_32.png')} /> - At Dealer Location
									&nbsp;&nbsp;<img className="mapico1" src={require('../../assets/icons/truck_leftdealer_32.png')} /> - Left Dealer Location
									&nbsp;&nbsp;<img className="mapico" src={require('../../assets/icons/truck_empty_32.png')} /> - Empty Truck Outside Plant

								</span>
								*/}

								<div className="col-xl-3 col-lg-3 col-sm-3 row ">
									<form className=" theme-form col-xl-12 col-lg-12" onSubmit={this.handleFilter.bind(this)}>
										<div className="col-xl-12 col-lg-12 col-sm-12" style={{ paddingRight: '0px', marginBottom: "1.2em" }}>
											<label className="c-lbl">Select Type</label>
											<Select placeholder="Select Trip Type"
												isMulti={false}
												value={this.state.triptype}
												onChange={this.setTripType.bind(this)}
												options={(this.state.deptcode == "LOG-SC") ? [{ "value": 1, "label": "ALL" }] :
													[
														{ "value": 1, "label": "ALL" },
														{ "value": 2, "label": "On Trip" },
														{ "value": 5, "label": "Waiting at Dealer" }
														// {"value":3,"label":"Empty Vin"},
														//{"value":3,"label":"Empty / Non-MSIL trip"}
													]
												}
												required />

										</div>
										<div className="col-xl-12 col-lg-12 col-sm-12" style={{ paddingRight: '0px' }}>
											<label className="c-lbl">Select Transporter</label>
											<Select placeholder="Select Transporters"
												className="rselect"
												isMulti={false}
												value={this.state.transportervalue}
												onChange={this.handleChange.bind(this)}
												options={this.createSelectTransportersItems()} required />

										</div>
										{/*
										<div className="col-xl-12 col-lg-12 col-sm-12" style={{paddingRight:'0px'}}>
											<Select value={truckstatus} placeholder="Select Status" className="rselect" value={this.state.truckstatus} onChange={this.changeHandler.bind(this)} options={truckstatusoptions} />

										</div>

										<div className="col-xl-12 col-lg-12 col-sm-12" style={{paddingRight:'0px'}}>
											<Select placeholder="Select KPI" className="rselect" value={this.state.kpicode} onChange={this.changeKpiHandler.bind(this)} options={kpioptions} />

										</div>
										*/}
										<div className="col-xl-12 col-lg-12 col-sm-12" style={{ padding: "0" }}>
											<button name="go" id="go" style={{ height: "38px", margin: "10px 15px" }} className="btn btn-success" type="submit">Submit</button>
										</div>
										<div className="row" style={{ marginTop: "4em" }}>
											<div className="col-xl-8 col-lg-8">
												<label className=""><i className="icofont icofont-vehicle-delivery-van cus-i"></i> Total Device(s) </label>
											</div>
											<div className="col-xl-4 col-lg-4">
												<h6 className="txt-info f20" style={{ paddingTop: "0.2em", marginLeft: "-2em" }}>
													<span className="counter">
														<CountUp end={(this.state.onTripCounter != '') ? this.state.onTripCounter : 0} />
													</span>
												</h6>
											</div>
										</div>

										<div className="col-xl-12 col-lg-12 col-sm-12" style={{display:"flex"}}>

											<div className='' style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
												<div className=''>
													<input
														type="checkbox"
														id="checkbox1"
														checked={this.state.mapped}
														onChange={() => this.handleCheckboxChange('mapped')}
													/>
												</div>
												<div className='col'>
													<label htmlFor="checkbox1">Mapped</label>
												</div>
											</div>
											<div className='' style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
												<div className=''>
													<input
														type="checkbox"
														id="checkbox2"
														checked={this.state.gpsdata}
														onChange={() => this.handleCheckboxChange('gpsdata')}
													/></div>
												<div className='col'>
													<label htmlFor="checkbox2">GPS data in 24 hours</label>
												</div>
											</div>
										</div>
									</form>
									<div className={"trucks-dropdown " + this.state.showTrucksList} style={{ top: "386px", left: "41px", width: "20.3em" }}>
										<ul>
											{listData}
										</ul>
									</div>
									<form className=" theme-form col-xl-12 col-lg-12" onSubmit={this.truckFormHandler}>
										{/* <div className="col-xl-12 col-lg-12 col-sm-12" style={{paddingRight:'0px'}}>
											<label>Search by Vin No:</label>
											<input type="text" name="truck_no" id="inputTruck"   value={this.state.truck_no} placeholder="Vin No" autoComplete="off" className="form-control"  onChange={this.onChangeTruckItem} options={this.ooChangeTruck()} />
										</div> */}
										<div className="theme-form col-xl-12 col-lg-12 col-sm-12" style={{ marginLeft: "-1.75em" }}>
											<Select
												placeholder={"Select Vin"}
												closeMenuOnSelect={true}
												onChange={this.onChangeTruckItem}
												className={"col-xl-12 col-lg-12 border-radius-0"}
												styles={{ borderRadius: "0px" }} // Use "styles" instead of "style"
												name="truck_no"
												value={this.state.truck_no}
												options={this.ooChangeTruck()} // Call the function to get options
											/>
										</div>
										<div className="col-xl-12 col-lg-12 col-sm-12" style={{ marginLeft: "-1.75em" }}>
											<button name="go" id="go" style={{ height: "38px", margin: "10px 15px" }} className="btn btn-success" type="submit">Submit</button>
										</div>
									</form>
									{/* <div className="col-xl-12 col-lg-12 col-sm-12">
										<div className="col-xl-12 col-lg-12 col-sm-12" style={{padding:"0"}}>
											<input type="checkbox" onChange={this.onClickWeatherForecast.bind(this)}/> Show Today Weather Forecast
										</div>
									</div> */}

								</div>

								<div className="row col-xl-9 col-lg-9">

									<div className={"dataLoadpage " + (this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " + (this.state.loadshow)}>
										<div className="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
									</div>
									<ul>
										<li className="float-right">
											<button type="button" className="btn btn-success btn-xs float-right" onClick={this.onShowGridPop.bind(this)}> <span style={{ fontSize: "14px" }}> <i className="icofont icofont-download-alt"></i> Download Data</span></button>
										</li>
									</ul>

									<div id="map_canvas" className={this.state.mwidth} style={dStyles}></div>
									{(this.state.displayWeatherForecast) ?
										<div className="col-sm-12 col-xs-12 col-md-12 map-legends row" style={{ marginTop: "10px", marginLeft: "10px" }}>
											{/* <div className="col-xl-12 col-lg-12"> */}
											<div className="col-sm-2 col-xs-2 col-md-2">
												<img src={require('../../assets/icons/snowy.png')} style={{ cursor: "pointer" }} onClick={this.onClickFilterWeather.bind(this, 1)}></img> <figcaption>ALL</figcaption>
											</div>
											<div className="col-sm-2 col-xs-2 col-md-2">
												<img src={require('../../assets/icons/Heavyrain.png')} style={{ cursor: "pointer" }} onClick={this.onClickFilterWeather.bind(this, 2)}></img> <figcaption>Heavy Rain</figcaption>
											</div>
											<div className="col-sm-2 col-xs-2 col-md-2">
												<img src={require('../../assets/icons/wind.png')} style={{ cursor: "pointer" }} onClick={this.onClickFilterWeather.bind(this, 3)}></img>&nbsp;&nbsp; <figcaption>Heavy Rain & Winds</figcaption>
											</div>
											<div className="col-sm-2 col-xs-2 col-md-2">
												<img src={require('../../assets/icons/thunderstorm.png')} style={{ cursor: "pointer" }} onClick={this.onClickFilterWeather.bind(this, 4)}></img><figcaption> Thunderstorm </figcaption>
											</div>
											<div className="col-sm-2 col-xs-2 col-md-2">
												<img src={require('../../assets/icons/hailstorm.png')} style={{ cursor: "pointer" }} onClick={this.onClickFilterWeather.bind(this, 5)}></img>&nbsp;<figcaption> Hailstorm </figcaption>
											</div>
											<div className="col-sm-2 col-xs-2 col-md-2">
												<img src={require("../../assets/icons/cyclone.png")} style={{ cursor: "pointer" }} onClick={this.onClickFilterWeather.bind(this, 6)}></img>&nbsp;&nbsp; <figcaption>Cyclone</figcaption>
											</div>
											<div className="col-sm-2 col-xs-2 col-md-2">
												<img src={require('../../assets/icons/sandstorm.png')} style={{ cursor: "pointer" }} onClick={this.onClickFilterWeather.bind(this, 7)}></img><figcaption> Sandstorm </figcaption>
											</div>
											{/* </div> */}
										</div>
										: ""}



								</div>

							</div>
							<div className="card-header">
								<h6>
									<i className="fa fa-info-circle" style={{ color: "cornflowerblue" }}></i> <span> Note : Only Vins with GPS data in the last 24 hours are considered </span>

								</h6>
							</div>

						</div>
					</div>

				</div>


				<div className={"slide-r " + (this.state.sliderTranslate)} >


					<div className="slide-r-body" style={{ position: "relative" }}>
						<div className={"dataLoad " + (this.state.loadshow)}>
							<img src={require("../../assets/icons/loader.gif")} />
						</div>
						<div className="col-xl-12 col-lg-12">
							{this.displayData}
						</div>
					</div>
				</div>

				<span className="usermanual f22" onClick={this.onClickShowUserManaul.bind(this)}><i className="icofont icofont-read-book-alt"></i></span>
				<Modal open={usermanualmodal} onClose={this.onCloseUserManualModal.bind(this)} styles={modalStyles}>
					<div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
						<h5 className="model-title">Truck Location (Map)</h5>
						<div className="col-xl-12 col-lg-12 mt-20p">
							<div className="col-xl-12 col-lg-12">
								This utility provides pictorial representation of entire fleet (departmental) present at various locations throughout India.
							</div>
							<div className="col-xl-12 col-lg-12">
								Through this utility option user can find vehicle details either for individual truck or for selected transporter fleet for in transit and Empty/non MSIL trips
							</div>
							<div className="col-xl-12 col-lg-12">
								Image here
							</div>
						</div>
					</div>
				</Modal>

				<Modal open={open} onClose={this.onCloseModal} styles={modalStyles} >
					<div className={"row"} style={{ height: '100vh', padding: '5px 0px', width: '1185px', overflow: 'hidden', zIndex: '9999', }} >
						<div className={"col-sm-8 col-lg-8 col-md-8"} style={{ padding: '20px', background: '#fff' }}>
							<div id="myGrid" style={{ height: "580px", width: "100%", marginTop: '10px' }} className="ag-theme-balham">
								<AgGridReact
									modules={this.state.modules}
									columnDefs={columnwithDefs}
									defaultColDef={this.state.defaultColDef}
									rowData={this.state.rowData}
									enableCharts={true}
									enableRangeSelection={true}
									autoGroupColumnDef={this.state.autoGroupColumnDef}
									onGridReady={this.onGridReady}
									onGridState={this.onGridState}
									statusBar={this.state.statusBar}
									sideBar={this.state.sideBar}
									paginationPageSize={this.state.paginationPageSize}
									pagination={true}
									frameworkComponents={this.state.frameworkComponents}
									// detailCellRendererParams={this.state.detailCellRendererParams}
									//editType={this.state.editType}
									stopEditingWhenGridLosesFocus={true}
									floatingFilter={false}
									enableCellChangeFlash={true}
									suppressCellFlash={true}
									rowClassRules={this.state.rowClassRules}
									//onCellClicked={this.onRowClicked.bind(this)}
									gridOptions={{
										context: { componentParent: this }
									}}
									masterDetail={true}
									overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
								/>

								{/* <div className={"dataLoadpage " + (this.state.loadshow)}>
								</div>
								<div className={"dataLoadpageimg " + (this.state.loadshow)}>
									<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
								</div> */}

							</div>
						</div>
					</div>
				</Modal>

			</div>

		);
	}
}


function loadScript(url) {
	var index = window.document.getElementsByTagName("script")[0]
	var script = window.document.createElement("script")
	script.src = url
	script.async = true
	script.defer = true
	index.parentNode.insertBefore(script, index)
}

function getPolygonCoords() {

	var len = geoFencingArea.getPath().getLength();
	console.log(len);
	var htmlStr = "";
	var coordinates = [];
	for (var i = 0; i < len; i++) {
		// var latlngd = {"lat":parseFloat(each_lat_lng[0]),"lng":parseFloat(each_lat_lng[1])};
		// coordinates.push()
		var strlatlng = geoFencingArea.getPath().getAt(i).toUrlValue(5);
		var spliting = strlatlng.split(",");
		var latlngd = { "lat": parseFloat(spliting[0]), "lng": parseFloat(spliting[1]) };
		coordinates.push(latlngd);
		// htmlStr += "new google.maps.LatLng(" + geoFencingArea.getPath().getAt(i).toUrlValue(5) + "), ";
		//Use this one instead if you want to get rid of the wrap > new google.maps.LatLng(),
		//htmlStr += "" + geoFencingArea.getPath().getAt(i).toUrlValue(5);
	}
	// console.log("htmlStr", coordinates);

}


$(document).ready(function () {
	$(document).on("click", ".dropdown-truck-no", function () {
		var vals = $(this).text();
		$("#inputTruck").val(vals);
		$(".trucks-dropdown").removeClass("show-m");
		$(".trucks-dropdown").addClass("show-n");
	});

	$("body").on("click", function (e) {
		var container = $("#inputTruck");
		if (!container.is(e.target)) {
			try {
				if ($(".trucks-dropdown").attr("class").includes("show-m") || $(".trucks-dropdown").attr("class") == "trucks-dropdown") {
					$(".trucks-dropdown").removeClass("show-m");
					$(".trucks-dropdown").addClass("show-n");
				}
			} catch (e) { }

		}


	})
})
