/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable no-eval */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Select from 'react-select';

import SweetAlert from 'react-bootstrap-sweetalert';
import DrawMap from '../common/drawmap';
import MyHeaderComponent from "./deviationheaderaction";
import Showtolls from "./showtolls";
import ShowChokePoints from "./showchokepoints";
import ShowTollsList from "./showtollslist";
import TollGrid from "./deviationtollgrid";
import ChokePointsGrid from "./deviationchokepoints";
import ApprovalDeviationRoute from "./approvaldeviationroute";
import DateDeviationRoute from "./datedeviationroute";
import ViewDeviationRouteData from "./viewdeviationroutedata";
import RouteGrid from "./deviationroutedata";
import $ from 'jquery';
import { getHyphenYYYYMMDD, getHyphenYYYYMMDDHHMMSS, getDDMMYYYYHHMMSS } from '../common/utils';
var moment = require('moment');
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
var aggriddata = [];
var originaldata;

var colDefs1 = [
    {
        headerName: "Source",
        field: "consigner_code",
        valueGetter: (params)=>{
            return params.data.consigner_code
        }
    },
    {
        headerName: "Destination",
        field: "consignee_city",
    },
    {
        headerName: "Base Routes",
        field: "no_of_base_routes",
    },
    {
        headerName: "Total Trips",
        field: "total_trips",
    },
    {
        headerName: "No. of Trips Considered",
        field: "no_of_trips_travelled",
    },
    {
        headerName: "DAIMLER SAP Distance (KM)",
        field: "expected_distance",
    },
    {
        headerName: "Valid Trips",
        field: "valid_trips",
    },
    // {
    //     headerName: "Route Ignored",
    //     field: "route_ignored",
    // },
    // {
    //     headerName: "1 Straight Line",
    //     field: "st_lines_1",
    // },
    // {
    //     headerName: "2 Straight Lines",
    //     field: "st_lines_2",
    // },
    // {
    //     headerName: "3 Straight Lines",
    //     field: "st_lines_3",
    // },
    // {
    //     headerName: "4 Straight Lines",
    //     field: "st_lines_4",
    // },
    // {
    //     headerName: "5 Straight Lines",
    //     field: "st_lines_5",
    // },
    // {
    //     headerName: "> 5 Straight Lines",
    //     field: "st_lines_gt_5",
    // }
]

var colDefs2 = [
    {
        headerName: "",
        field: "checkbox_selection",
        checkboxSelection:true,
        width:60
    },
    {
        headerName: "",
        field: "map_routes",
        cellRenderer: (params)=>{
            return "<i class='icofont icofont-map-pins f20'></i>"
        },
        width:60
    },
    {
        headerName: "Truck No",
        field: "truck_no",
    },
    {
        headerName: "Invoice No",
        field: "invoice_nos",
    },
    {
        headerName: "Consignment Code",
        field: "consignment_codes",
    },
    {
        "headerName": "Base Route",
        field: "base_route_no",
    },
    {
        headerName: "Dealer Code",
        field: "consignee_code",
    },
    {
        headerName: "Dealer Name",
        field: "consignee_name",
    },
    {
        headerName: "Source",
        field: "consigner_code",
    },
    {
        headerName: "Destination",
        field: "consignee_city",
    },
    {
        headerName: "Zone",
        field: "zone"
    },
    {
        headerName: "Invoice Time",
        field: "invoice_time",
        valueGetter: (params)=>{
            return getHyphenYYYYMMDDHHMMSS(params.data.invoice_time)
        }
    },
    {
        headerName: "Trip End Time",
        field: "trip_end_time",
        valueGetter: (params)=>{
            return getHyphenYYYYMMDDHHMMSS(params.data.trip_end_time)
        }
    },
    {
        headerName: "Transporter Code",
        field: "transporter_code"
    },
    {
        headerName: "Transporter Name",
        field: "transporter_name",
    },
    {
        headerName: "GPS Provider",
        field: "gps_provider",
        valueGetter: (params)=>{
            var label = params.data.gps_provider
            if(params.data.gps_provider == "AXESTRACK_SND"){label = "AXESTRACK"}
            return label
        }
    },
    {
        headerName: "Truck Type",
        field: "vehicle_mode"
    },
    // {
    //     headerName: "Total GPS Distance (Km)",
    //     field: "total_travelled_trip_dist_km",
    // },
    {
        headerName: "Distance Travelled (KM)",
        field: "total_trip_dist_with_google_km",
        valueGetter: (params)=>{
            var total = Math.round(parseFloat(params.data.total_trip_dist_with_google_km));
            // if(params.data.missing_distance_km != undefined)
            // {
            //     total += Math.round(parseFloat(params.data.missing_distance_km))
            // }
            return total;
        }
    },
    {
        headerName: "Route Ignored",
        field: "route_ignored",
        valueGetter: (params)=>{
            if(params.data.route_ignored != undefined)
            {
                return "Yes";
            }
            else
            {
                return "No";
            }
        }
    },
    {
        headerName: "Ignored Reason",
        field: "",
        valueGetter: (params)=>{
            if(params.data.club_load == 1 && params.data.route_ignored == 1)
            {
                return  "Club Load"
            }
            if(params.data.straight_lines == 1 && params.data.route_ignored == 1)
            {
                return "Due to straight lines"
            }
            else if(params.data.route_ignored != undefined)
            {
                if(params.data.away_from_plant == 1)
                {
                    return "Away from Plant";
                }
                else if(params.data.expected_distance*0.8 > params.data.total_travelled_trip_dist_km)
                {
                    return "Trip Distance < 80% of DAIMLER SAP Distance";
                }
                else{
                    return "Trip Distance > 120% of DAIMLER SAP Distance";
                }
            }
            else
            {
                return "";
            }
        }
    },
    {
        headerName: "Straight lines",
        field: "no_of_straight_lines",
    },
]

var colDefs3 = [
    {
        headerName: "",
        field: "checkbox_selection",
        checkboxSelection:true,
        width:60,
        // cellRenderer: (params)=> {
        //     if(params.data.is_marked_base_route == 1)
        //     {
        //         return params.node.setSelected(true);
        //     }
        //     else{
        //         return params.node.setSelected(false);
        //     }
        // }
    },
    {
        headerName: "",
        field: "base_routes_map",
        cellRenderer: (params)=>{
            return "<i class='icofont icofont-map-pins f20'></i>"
        },
        width:60
    },
    {
        headerName: "DAIMLER SAP Route",
        field: "is_marked_base_route",
        valueGetter: (params)=>{
            if(params.data.is_marked_base_route == 1)
            {
                return "Yes"
            }
            else
            {
                return 'No'
            }
        }
    },
    {
        headerName: "Base Route",
        field: "base_route_no",
        valueGetter: (params)=>{
            return "Base Route "+params.data.base_route_no
        }
    },
    {
        headerName: "Source",
        field: "plant",
        valueGetter: (params)=>{
            return params.data.plant
        }
    },
    {
        headerName: "Destination",
        field: "consignee_city",
    },
    {
        headerName: "DAIMLER SAP Distance (KM)",
        field: "expected_distance",
    },
    {
        headerName: "Min. GPS Distance (KM)",
        field: "min_google_distance",
    },
    {
        headerName: "Max. GPS Distance (KM)",
        field: "max_google_distance",
    },
    {
        headerName: "Avg. GPS Distance (KM)",
        field: "avg_google_distance",
    },
    {
        headerName: "Median GPS Distance (KM)",
        field: "median_google_distance",
    },
    {
        headerName: "Google Distance (KM)",
        field: "google_distance_via_waypoints"
    },
    {
        headerName: "No. of followers",
        field: "no_of_followers",
    },
    {
        headerName: "No. of Followers %",
        field: "no_of_followers_percentage",
    }
]

var colDefs5 = [
    // {
    //     headerName: "",
    //     field: "checkbox_selection",
    //     checkboxSelection:true,
    //     width:60
    // },
    // {
    //     headerName: "",
    //     field: "map_routes",
    //     cellRenderer: (params)=>{
    //         return "<i class='icofont icofont-map-pins f20'></i>"
    //     },
    //     width:60
    // },
    {
        headerName: "Truck No",
        field: "truck_no",
    },
    {
        headerName: "Invoice No",
        field: "invoice_no",
    },
    {
        headerName: "Consignment Code",
        field: "consignment_code",
    },
    // {
    //     "headerName": "Base Route",
    //     field: "base_route_no",
    // },
    {
        headerName: "Dealer Code",
        field: "consignee_code",
    },
    // {
    //     headerName: "Dealer Name",
    //     field: "consignee_name",
    // },
    {
        headerName: "Source",
        field: "consigner_code",
    },
    {
        headerName: "Destination",
        field: "consignee_city",
    },
    {
        headerName: "Zone",
        field: "zone"
    },
    {
        headerName: "Invoice Time",
        field: "invoice_date",
        valueGetter: (params)=>{
            return getHyphenYYYYMMDDHHMMSS(params.data.invoice_date)
        }
    },
    {
        headerName: "Trip End Time",
        field: "trip_end_time",
        valueGetter: (params)=>{
            return getHyphenYYYYMMDDHHMMSS(params.data.trip_end_time)
        }
    },
    {
        headerName: "Transporter Code",
        field: "transporter_code"
    },
    {
        headerName: "Transporter Name",
        field: "transporter_name",
    },
    {
        headerName: "GPS Provider",
        field: "gps_provider",
        valueGetter: (params)=>{
            var label = params.data.gps_provider
            if(params.data.gps_provider == "AXESTRACK_SND"){label = "AXESTRACK"}
            return label
        }
    },
    {
        headerName: "Truck Type",
        field: "vehicle_mode"
    },
    // {
    //     headerName: "Total GPS Distance (Km)",
    //     field: "total_travelled_trip_dist_km",
    // },
    // {
    //     headerName: "Distance Travelled (KM)",
    //     field: "total_trip_dist_with_google_km",
    //     valueGetter: (params)=>{
    //         var total = Math.round(parseFloat(params.data.total_trip_dist_with_google_km));
    //         // if(params.data.missing_distance_km != undefined)
    //         // {
    //         //     total += Math.round(parseFloat(params.data.missing_distance_km))
    //         // }
    //         return total;
    //     }
    // },
    // {
    //     headerName: "Route Ignored",
    //     field: "route_ignored",
    //     valueGetter: (params)=>{
    //         if(params.data.route_ignored != undefined)
    //         {
    //             return "Yes";
    //         }
    //         else
    //         {
    //             return "No";
    //         }
    //     }
    // },
    {
        headerName: "Reason",
        field: "reason",
        // valueGetter: (params)=>{
        //     if(params.data.club_load == 1 && params.data.route_ignored == 1)
        //     {
        //         return  "Club Load"
        //     }
        //     if(params.data.straight_lines == 1 && params.data.route_ignored == 1)
        //     {
        //         return "Due to straight lines"
        //     }
        //     else if(params.data.route_ignored != undefined)
        //     {
        //         if(params.data.away_from_plant == 1)
        //         {
        //             return "Away from Plant";
        //         }
        //         else if(params.data.expected_distance*0.8 > params.data.total_travelled_trip_dist_km)
        //         {
        //             return "Trip Distance < 80% of DAIMLER SAP Distance";
        //         }
        //         else{
        //             return "Trip Distance > 120% of DAIMLER SAP Distance";
        //         }
        //     }
        //     else
        //     {
        //         return "";
        //     }
        // }
    },  
]

export default class DeviationRoutes extends Component {

    constructor(props) {
        super(props);
		this.displayData = [];
        this.state = {
            loadshow:'show-n',
            overly:'show-n',
			modules: AllModules,
        	alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
			dept_code:'',
			defaultColDef: {
				sortable: true,
				filter: true,
                //editable: true,
                autoHeight:true,
				resizable: true,
                width: 130
			},
			rowData: [],
            headerHeight: 60,
            rowHeight:50,
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
            showToolPanel: false,
            griddata:[],
            routeRowData:{},
			rowSelection: "single" ,
			context: { componentParent: this },
            frameworkComponents: {
                MyHeaderComponent: MyHeaderComponent,
                Showtolls:Showtolls,
                ShowTollsList:ShowTollsList,
                ApprovalDeviationRoute:ApprovalDeviationRoute,
                DateDeviationRoute:DateDeviationRoute,
                ViewDeviationRouteData:ViewDeviationRouteData,
                ShowChokePoints:ShowChokePoints
            },
			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 50,
			maxBlocksInCache: 2,
            pageTitle:"Summary",
            originaldata:"",
            orignalresponse:"",
            mapinfo:"",
            originoption:{"value":"","label":"Select Origin"},
            destinationoption:{"value":"","label":"Select Destination"},
            destinationarr:[],
            selectedorigin:'',
            selecteddestination:'',
            columnDefs: colDefs1,
            checkedinputs:[],
            uncheckedinputs:[],
            noofroutes:3,
            tolls:[],
            tollshow:false,
            plantcoords:"",
            plantname:"",
            sliderTranslate:"",
            viewtolls:[],
            tollrowid:"",
            routeid:"",
            routeapprovestatus:"",
            open:false,
            route_reason:"",
            gridapi: [],
            viewroutedata:[],
            sliderRouteTranslate:"",
            sliderRouteTranslate1:"",
            sliderChokeTranslate:"",
            chokepoints:[],
            chokepointdata:[],
            originoptions:[],
            btn1: "btn-default",
            btn2: "btn-default",
            btn3: "btn-default",
            btn4: "btn-default",
            complete_analysis: [],
            base_routes_anlsysis: [],
            transporters_analysis: [],
            complete_trips: [],
            breaks_list: [],
            route_coords: [],
            gridHeight: "160px",
            "mapfor": "",
            destinationOptions: [],
            defaultTrips: [],
            selectedRoutes: [],
            dealersList : [],
            route_coords1: [],
            breaks_list1: [],
            tripsGridApi: {},
            defaultCompleteTrips: [],
            hideSummaryTab: false,
            lspusers_options: [],
            truck_types: [],
            period_types: [],
            selected_provider: {"label": "ALL", "value": "ALL"},
            selected_truck_type: {"label": "ALL", "value": "ALL"},
            selected_period_type: {"label": "ALL", "value": "ALL"},
            ignoredRouteSubmit : 0,
            ignoredRoutes: []
        };
        this.origins = this.origins.bind(this);
        this.destinations = this.destinations.bind(this);
        this.onLoadFormRequest = this.onLoadFormRequest.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.onClickViewRouteData = this.onClickViewRouteData.bind(this);
	}
	/*Alert Popups*/
	closeAlert = () => {
        this.setState({
            show: false
        });
    }

	hideAlert = () => {
        this.setState({
            alert: null
        });
	}
	
    componentDidMount(){
        this.setState({
            overly: "show-m",
            loadshow: "show-m"
        })
       // console.log("this.macth ", this.props.location.search)
        var s = this.props.location.search;
        var consigner_code = "";
        if(s != "")
        {
            var spl = s.split("?")
            var s1 = spl[1].split("&")
            var sorce = s1[0].split("=")
            var source = sorce[1];
            consigner_code = source;
            var srcname = s1[1].split("=")
            var sourcename = srcname[1].replace(/%20/g," ")

            var dest = s1[2].split("=")
            var destination = dest[1].replace(/%20/g," ")
            
            var period_type = s1[3].split("=")
            period_type = period_type[1].replace(/%20/g," ")

            var gps_provider = s1[4].split("=")
            gps_provider = gps_provider[1].replace(/%20/g," ")
            if(gps_provider == "AXESTRACK_SND"){
                gps_provider = {"value":gps_provider,"label": "AXESTRACK"}
            }
            else
            {
                gps_provider = {"value":gps_provider,"label": gps_provider}
            }

            var truck_type = s1[5].split("=")
            truck_type = truck_type[1].replace(/%20/g," ")
            //var fromparent = this.props.match.params
            var sourceopt = source
            var sourcename = sourcename
            var destinationopt = destination
            
            this.setState({
                originoption: {"value":sourceopt,"label":sourcename},
                destinationoption: {"value":destination,"label":destination},
                selected_period_type: {"value":period_type,"label":period_type},
                selected_provider: gps_provider,
                selected_truck_type: {"value":truck_type,"label":truck_type},
            })
            var p = {
                plant:sourceopt
            }
            redirectURL.post("/consignments/plantcoordinates", p)
            .then((response) => {
                //console.log("Ress ", response.data)
                var plantdata = response.data
                //console.log("plantcoords ", JSON.parse(plantdata[0].plant_coordinates))
                var plantcoords = JSON.parse(plantdata[0].plant_coordinates)
                this.setState({
                    plantcoords:{coord:plantcoords,name:this.state.originoption.label},
                    plantname:this.state.originoption.label,
                })
            })
            var formdata = {
                consigner_code: sourceopt,
                consignee_city: destinationopt,
                gps_provider: gps_provider.value,
                period_type: period_type,
                truck_type: truck_type,
            }
            this.setState({
                loadshow: "show-m",
                overly: "show-m"
            })
            this.onLoadFormRequest(formdata)
        }
        
        //this.onClickShowRoute()
		// console.log("path ", this.props.match.path)
		this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
        this.setState({eventAction: eventAction});
        redirectURL.post("/consignments/deviationconsignees")
        .then((response) => {
            //console.log("Consignees ", response.data)
           var records = response.data;
            var destinationarr = []
            if(records.length > 0)
            {
                records.map((item) => {
                    if(item != "")
                    {
                        destinationarr.push(item)
                    }
                })
            }
            this.setState({
                destinationarr:destinationarr
            })
        })

        var map_plants = {"DPC": "Chennai"}
        redirectURL.post("/dashboard/getSourceFromBaseRoutes")
        .then((response) => {
            //console.log("Ress ", response.data)
            var plantdata = response.data;
            var originoptions = [];
            if(plantdata.length > 0)
            {
                plantdata.map((item) => {
                    originoptions.push({
                        value:item,
                        label: map_plants[item]
                    })
                })
            }
            redirectURL.post("dashboard/getDestinationFromBaseRoutes", {"consigner_code": consigner_code}).then((response1)=>{
                var items=[];
                var destinationarr = response1.data;
                var check = destinationarr.map((item) =>{
                    items.push({
                        value:item,label:item 
                    })
                })
                redirectURL.post("dashboard/getInitialDataForBaseRouteSummary", {}).then((response2)=>{
                    // console.log(response2.data)
                    var lspusers_options = [];
                    var truck_types = [];
                    var period_types_options = [];
                    // var period_types = sortPeriodTypes(response2.data.period_types);
                    var period_types = response2.data.period_types;
                    // console.log(period_types, "period_types")
                    response2.data.lsp_users.map((e)=>{
                        if(e != "NA")
                        {
                            var label = e
                            if(e == "AXESTRACK_SND"){label = "AXESTRACK"}
                            lspusers_options.push({"label": label, "value": e})
                        }
                    })
                    response2.data.truck_types.map((e)=>{
                        truck_types.push({"label": e, "value": e})
                    })
                    period_types.map((e)=>{
                        period_types_options.push({"label": e, "value": e})
                    })
                    // console.log(period_types, "period_types options")
                    this.setState({
                        originoptions: originoptions,
                        destinationOptions: items,
                        lspusers_options: lspusers_options,
                        truck_types: truck_types,
                        period_types: period_types_options,
                        overly: "show-n",
                        loadshow: "show-n"
                    })
                })
                
                
            })
            
        })
        
        var columnDefs = [];
        
	}
	
	logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
	}
	onGridReady = params => {
		this.gridApi = params.api;
        this.setState({
            gridapi : params.api
        })
        this.gridColumnApi = params.columnApi;
        var sort = [
			{
			  colId: 'no_of_followers_percentage',
			  sort: 'desc',
			},
		  ];
		this.gridApi.setSortModel(sort);
        this.gridApi.addEventListener('paginationChanged', (e) =>
        {
            //Reset rows selection based on current page
           // this.resetPaginationSelection(this);
        });

        this.gridApi.forEachLeafNode( (node) => {
            // console.log("node here ",node)
            // node.setSelected(true);
         
        });
        
	};

    onGridReady1 = params => {
		this.gridApi1 = params.api;
        this.setState({
            tripsGridApi: params.api
        })
        this.gridColumnApi1 = params.columnApi;
        var sort = [
			{
			  colId: 'no_of_followers_percentage',
			  sort: 'desc',
			},
		  ];
		this.gridApi.setSortModel(sort);
        
	};
	

	onGridState = () =>{
		//  console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
           this.gridApi.setFilterModel(window.filterState);
           

           
        
        //
    }

    onGridState1 = () =>{
        // console.log(this.gridApi);
       
        /*Get  Current Columns State and Store in this.colState */
          this.colState = this.gridColumnApi1.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
          this.rowGroupState = this.gridColumnApi1.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
          this.pivotColumns = this.gridColumnApi1.getPivotColumns();
                   
        /*Get Current Filter State and Store in window.filterState */
          window.filterState = this.gridApi1.getFilterModel();
          this.gridColumnApi1.setFilterModel(window.filterState);
   }

	changeOriginHandler = originoption => {
        this.setState(
            { originoption }
        );
        // redirectURL.post("dashboard/getDestinationFromBaseRoutes", {"consigner_code": originoption.value}).then((response)=>{
        //     var items=[];
        //     var destinationarr = response.data;
        //     var check = destinationarr.map((item) =>{
        //         items.push({
        //             value:item,label:item 
        //         })
        //     })
        //     Promise.all([check]).then(()=>{
        //         this.setState({
        //             destinationOptions: items
        //         })
        //     })
            
        // })
    }

    changeDestinationHandler = destinationoption => {
        // var formdata = {}
        // if([null, undefined, "ALL", ""].includes(this.state.originoption.value) == false)
        // {
        //     formdata['consigner_code'] = this.state.originoption.value
        // }
        // if([null, undefined, "ALL", ""].includes(this.state.originoption.value) == false)
        // {
        //     formdata['consignee_city'] = destinationoption.value
        // }
        // redirectURL.post("dashboard/getInitialDataForBaseRouteSummary", formdata).then((response2)=>{
        //     var lspusers_options = [];
        //     var truck_types = [];
        //     var period_types_options = [];
        //     response2.data.lsp_users.map((e)=>{
        //         if(e != "NA")
        //         {
        //             var label = e
        //             if(e == "AXESTRACK_SND"){label = "AXESTRACK"}
        //             lspusers_options.push({"label": label, "value": e})
        //         }
        //     })
        //     response2.data.truck_types.map((e)=>{
        //         truck_types.push({"label": e, "value": e})
        //     })
        //     var period_types = sortPeriodTypes(response2.data.period_types);
        //     period_types.map((e)=>{
        //         period_types_options.push({"label": e, "value": e})
        //     })
        //     this.setState({
        //         destinationoption,
        //         // originoptions: originoptions,
        //         // destinationOptions: items,
        //         lspusers_options: lspusers_options,
        //         truck_types: truck_types,
        //         period_types: period_types_options,
        //         // overly: "show-n",
        //         // loadshow: "show-n"
        //     })
        // })
        this.setState(
            { destinationoption }
        );
    
    }
    
    changeGpsProviderHandler = selected_provider => {
        // var formdata = {}
        // if([null, undefined, "ALL", ""].includes(this.state.originoption.value) == false)
        // {
        //     formdata['consigner_code'] = this.state.originoption.value
        // }
        // if([null, undefined, "ALL", ""].includes(this.state.originoption.value) == false)
        // {
        //     formdata['consignee_city'] = this.state.destinationoption.value
        // }
        // if([null, undefined, "ALL", ""].includes(this.state.originoption.value) == false)
        // {
        //     formdata['gps_provider'] = selected_provider.value
        // }
        // redirectURL.post("dashboard/getInitialDataForBaseRouteSummary", formdata).then((response2)=>{
        //     var lspusers_options = [];
        //     var truck_types = [];
        //     var period_types_options = [];
        //     // response2.data.lsp_users.map((e)=>{
        //     //     if(e != "NA")
        //     //     {
        //     //         var label = e
        //     //         if(e == "AXESTRACK_SND"){label = "AXESTRACK"}
        //     //         lspusers_options.push({"label": label, "value": e})
        //     //     }
        //     // })
        //     response2.data.truck_types.map((e)=>{
        //         truck_types.push({"label": e, "value": e})
        //     })
        //     var period_types = sortPeriodTypes(response2.data.period_types);
        //     period_types.map((e)=>{
        //         period_types_options.push({"label": e, "value": e})
        //     })
            this.setState({
                selected_provider: selected_provider,
                // originoptions: originoptions,
                // destinationOptions: items,
                // lspusers_options: lspusers_options,
                // truck_types: truck_types,
                // period_types: period_types_options,
                // overly: "show-n",
                // loadshow: "show-n"
            })
        // })
    }

    changePeriodTypeHandler = selected_period_type => {
        // var formdata = {}
        // if([null, undefined, "ALL", ""].includes(this.state.originoption.value) == false)
        // {
        //     formdata['consigner_code'] = this.state.originoption.value
        // }
        // if([null, undefined, "ALL", ""].includes(this.state.originoption.value) == false)
        // {
        //     formdata['consignee_city'] = this.state.destinationoption.value
        // }
        // if([null, undefined, "ALL", ""].includes(this.state.originoption.value) == false)
        // {
        //     formdata['gps_provider'] = this.state.selected_provider.value
        // }
        // if([null, undefined, "ALL", ""].includes(this.state.originoption.value) == false)
        // {
        //     formdata['period_type'] = selected_period_type.value
        // }
        // redirectURL.post("dashboard/getInitialDataForBaseRouteSummary", formdata).then((response2)=>{
        //     var truck_types = [];
        //     response2.data.truck_types.map((e)=>{
        //         truck_types.push({"label": e, "value": e})
        //     })
            this.setState({
                selected_period_type: selected_period_type,
                // truck_types: truck_types,
            })
        // })
    }

    changeTruckTypeHandler = selected_truck_type => {
        this.setState({
            selected_truck_type: selected_truck_type,
        })
    }

    getIgnoredRoutes = (event) =>{
        event.preventDefault();
        if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.formAction,
			}
			googleAnalytics.logEvent(eventOptions);
		}
        try{
            var tcheck = document.getElementById("tollcheck")
            if(tcheck.checked == true)
            {
                tcheck.checked = false;
            }
        }
        catch(e){

        }
		this.setState({
            loadshow:'show-m',            
            tolllist:[]
        });

        if(this.state.originoption.value != "" && this.state.destinationoption.value != "")
        {

            var formdata = {
                consigner_code:this.state.originoption.value,
                consignee_city:this.state.destinationoption.value,
                gps_provider: this.state.selected_provider.value,
                period_type: this.state.selected_period_type.value,
                truck_type: this.state.selected_truck_type.value,
            }
            // console.log('formdata',formdata)
            // this.onLoadFormRequest(formdata)
            redirectURL.post("/dashboard/getIgnoredRoutes",formdata).then((response) => {
                // console.log("response",response.data);
                // let responseData = response.data;
                // let truckList = []
                // responseData.trucksList.map(e => truckList.push({ value: e, label: e }))
                this.setState({
                    ignoredRoutes: response.data,
                    rowData: response.data,
                    overly: "show-n",
                    loadshow : "show-n"
                })
            }).catch((e) => {
                console.log("error", e);
            })
        }
        else
        {
            this.setState({
                basicTitle: "Please select Consigner and Consignee City",
                basicType: "warning",
                show: true,
                overly: "show-n",
                loadshow: "show-n"
            })
        }

    }

	formHandler = (event) =>{
        event.preventDefault();
       // loadchecked()
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.formAction,
			}
			googleAnalytics.logEvent(eventOptions);
		}
        try{
            var tcheck = document.getElementById("tollcheck")
            if(tcheck.checked == true)
            {
                tcheck.checked = false;
            }
        }
        catch(e){

        }
		this.setState({
            loadshow:'show-m',            
            tolllist:[]
        });
        if(this.state.originoption.value != "" && this.state.destinationoption.value != "")
        {

            var formdata = {
                consigner_code:this.state.originoption.value,
                consignee_city:this.state.destinationoption.value,
                gps_provider: this.state.selected_provider.value,
                period_type: this.state.selected_period_type.value,
                truck_type: this.state.selected_truck_type.value,
            }
            // console.log(formdata)
            this.onLoadFormRequest(formdata)
        }
        else
        {
            this.setState({
                basicTitle: "Please select Consigner and Consignee City",
                basicType: "warning",
                show: true,
                overly: "show-n",
                loadshow: "show-n"
            })
        }
		
    }
    
    onLoadFormRequest(formdata)
    { 
        this.setState({
            loadshow: "show-m",
            overly: "show-m"
        })
        redirectURL.post("dashboard/getBaseRouteAnalysisReport", formdata).then((response)=>{
            // console.log(response.data)
            if(response.data.status == "Failure")
            {
                this.setState({
                    complete_analysis: [],
                    base_routes_anlsysis: [],
                    transporters_analysis: [],
                    complete_trips: [],
                    rowData: [],
                    btn1: "btn-danger",
                    btn2: "btn-danger",
                    btn3: "btn-default",
                    btn4: "btn-default",
                    columnDefs: colDefs1,
                    loadshow: "show-n",
                    overly: "show-n"
                })
            }
            else
            {
                var complete_analysis = JSON.parse(response.data.complete_analysis);
                var base_routes_anlsysis = JSON.parse(response.data.base_routes_anlsysis);
                var transporters_analysis = JSON.parse(response.data.transporters_analysis);
                redirectURL.post("dashboard/getCompleteTripsDataForBaseRoutes", formdata).then((response)=>{
                    var data = response.data;
                    // console.log(data, "complete trips")
                    data.map((e)=>{
                        var filtereddata =  base_routes_anlsysis.filter(k =>{
                            if(k.invoice_nos.filter(value => e.invoice_nos.includes(value)).length)
                            {
                                return true;
                            }
                        })
                        if(filtereddata.length)
                        {
                            // console.log(e.invoice_nos, filtereddata, "checking ")
                            e.base_route_no = "Base Route "+filtereddata[0]['base_route_no']
                        }
                    })
                    // console.log("complete_trips_data",data)
                    this.setState({
                        base_routes_anlsysis: base_routes_anlsysis,
                        transporters_analysis: transporters_analysis,
                        complete_trips: data,
                        defaultCompleteTrips: data,
                        defaultTrips: data,
                        rowData: complete_analysis,
                        columnDefs: colDefs1,
                        btn1: "btn-danger",
                        btn2: "btn-danger",
                        btn3: "btn-default",
                        btn4: "btn-default",
                        loadshow: "show-n",
                        overly: "show-n",
                        hideSummaryTab: false,
                        gridHeight: "160px",
                        pageTitle: "Summary",
                        complete_analysis: complete_analysis,
                    })
                })
            }
            
        })
    }


	origins(){
        var items=[]
        items.push(
            // {value:"F", label:"Gurgoan"},
            // {value:"G", label:"Gujarat"},
            // {value:"M", label:"Manesar"},
            // {value:"N", label:"Nagpur"},
            // {value:"I", label:"Silguri"},
            // {value:"B", label:"Banglore"}
            {value:"F", label:"Haryana"},
            {value:"G", label:"Gujarat"},
            {value:"N", label:"Nagpur"},
            {value:"I", label:"Silguri"},
            {value:"B", label:"Banglore"}
            
        );
        return items;
    }

    destinations(){
        var items=[]
        if(this.state.destinationarr.length > 0)
        {
            var destinationarr = this.state.destinationarr;
            destinationarr.map((item) =>{
                items.push({
                   value:item,label:item 
                })
            })
        }
        return items;
    }


    onCloseUploadDiv = () => {
		this.setState({
			sliderTranslate:"",
            sliderRouteTranslate:'',
            sliderRouteTranslate1:'',
            sliderChokeTranslate:"",
			showDiv:'show-n',
			overly:'show-n'
		});
    }
    

	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
            sliderTranslate:'',
            sliderRouteTranslate:'',
            sliderRouteTranslate1:'',
            sliderChokeTranslate:""
		});
		
    }

    changeHandler(event){
        let name = event.target.name;
        let value = event.target.value;
        this.setState({
            [name]:value
        });
    }

    onOpenModal = () => {
		this.setState({ open: true });
	 };
	
	 onCloseModal = () => {
	   this.setState({ open: false });
     };
     
    onClickViewRouteData(params){
        // console.log("Parmas ", params.colDef.colId)
        this.setState({
            loadshow:'show-m'
        })
        var parameters = {
            reference_route : params.value 
        }
        redirectURL.post("/consignments/deviationroutedata", parameters)
        .then((response) => {
            var records = response.data.records;
           // console.log("records ",records)
            if(records.length > 0)
            {
                this.setState({
                    sliderRouteTranslate:"slider-translate-50p",
                    viewroutedata:records,
                    overly:"show-m",
                    loadshow:'show-n'
                })
            }
            else
            {
                this.setState({
                    sliderRouteTranslate:"",
                    viewroutedata:records,
                    overly:"show-n",
                    loadshow:'show-n',
                    show:true,
                    basicTitle:"No data found.",
                    basicType:"danger"
                })
            }
            
        })
    }

    onClickTab(tab)
    {
        var btns_list = ["bnt1", "btn2", "btn3", "btn4", 'btn5']
        var to_update = {hideSummaryTab: false}
        btns_list.map((e)=>{
            to_update[e] = (e != tab)?"btn-default":"btn-danger";
        })
        if(tab == "btn1"){
            to_update["columnDefs"] = colDefs1;
            to_update['pageTitle'] = "Summary";
            to_update['rowData'] = this.state.complete_analysis;
            to_update['complete_trips'] = this.state.defaultCompleteTrips;
            to_update['gridHeight'] = "160px";
            to_update['btn1'] = "btn-danger"
            to_update['btn2'] = "btn-default"
            to_update['btn3'] = "btn-default"
            to_update['btn4'] = "btn-default"
            to_update['btn5'] = 'btn-default'
            to_update['hideIgnoredTab'] = false;
            to_update['ignoredRouteSubmit'] = 0

            try{
                this.gridApi1.deselectAll();
            }
            catch(e){}
            
            this.setState(to_update)

        }
        else if(tab == "btn2"){
            to_update["columnDefs"] = colDefs2
            to_update['pageTitle'] = "All Trips"
            to_update['complete_trips'] = this.state.defaultCompleteTrips;
            to_update['btn1'] = "btn-default"
            to_update['btn2'] = "btn-danger"
            to_update['btn3'] = "btn-default"
            to_update['btn4'] = "btn-default"
            to_update['btn5'] = 'btn-default'
            to_update['gridHeight'] = "460px";
            to_update['hideIgnoredTab'] = false;
            to_update['ignoredRouteSubmit'] = 0

            this.setState(to_update)
        }
        else if(tab == "btn3"){
            to_update["columnDefs"] = colDefs3
            to_update['pageTitle'] = "Base Routes"
            to_update['complete_trips'] = this.state.base_routes_anlsysis;
            to_update['btn1'] = "btn-default"
            to_update['btn2'] = "btn-default"
            to_update['btn3'] = "btn-danger"
            to_update['btn4'] = "btn-default"
            to_update['btn5'] = 'btn-default'
            to_update['gridHeight'] = "460px";
            to_update['hideSummaryTab'] = true;
            to_update['hideIgnoredTab'] = false;
            to_update['ignoredRouteSubmit'] = 0

            try{
                this.gridApi1.deselectAll();
            }
            catch(e){}
            this.setState(to_update)

        }
        else if(tab == "btn4"){
            // console.log("btn4 clicked",this.state.transporters_analysis[0])
            var colDefs4 = [
                {
                    headerName: "Transporter Name",
                    field: "transporter_name",
                },
            ]
            try{
                Object.keys(this.state.transporters_analysis[0]).map((e)=>{
                    if(colDefs4.filter(k => k.headerName == e).length == 0 && e != "transporter_name"){
                        colDefs4.push({
                            "headerName": e,
                            "field": e
                        })
                    }
                })
                to_update["columnDefs"] = colDefs4
                to_update['pageTitle'] = "Transporters Info"
                to_update['rowData'] = this.state.transporters_analysis;
                to_update['btn1'] = "btn-default"
                to_update['btn2'] = "btn-default"
                to_update['btn3'] = "btn-default"
                to_update['btn4'] = "btn-danger"
                to_update['btn5'] = 'btn-default'
                to_update['gridHeight'] = "460px";
                to_update['hideIgnoredTab'] = false;
                to_update['ignoredRouteSubmit'] = 0
                // console.log("to_update",to_update)
                this.setState(to_update)
            }
            catch(e){
                console.log("error",e)
            }
        }
        else if(tab == 'btn5'){
            to_update['ignoredRouteSubmit'] = 1;
            to_update['pageTitle'] = "Ignored Routes";
            to_update["columnDefs"] = colDefs5;
            to_update['gridHeight'] = "460px";
            to_update['hideSummaryTab'] = false;
            to_update['btn1'] = "btn-default"
            to_update['btn2'] = "btn-default"
            to_update['btn3'] = "btn-default"
            to_update['btn4'] = "btn-default"
            to_update['btn5'] = "btn-danger"
            to_update['rowData'] = this.state.ignoredRoutes;
            
            this.setState(to_update)
        }
    }
    
    onCellClicked(e){
        if(e.colDef.field == "map_routes")
        {
            var formdata = {
                "consigner_code": e.data.consigner_code,
                "consignee_city": e.data.consignee_city,
                "period_type": this.state.selected_period_type.value,
                "gps_provider": this.state.selected_provider.value,
                "truck_type": this.state.selected_truck_type.value,
                "row_id": e.data._id
            }
            redirectURL.post("dashboard/getTripCoords", formdata).then((resp)=>{
                // console.log(resp.data)
                if(resp.data.saved_base_route.length == 0)
                {
                    this.setState({
                        sliderRouteTranslate:"slider-translate-50p",
                        mapfor: "map_routes",
                        routeRowData: e.data,
                        route_coords:eval(resp.data.trip_route),
                        breaks_list: [],
                        overly:"show-m",
                        loadshow:'show-n'
                    })
                }
                else
                {
                    var route_coords1 = [
                        {
                            "consignment_code": e.data.consignment_codes,
                            "coords": eval(resp.data.trip_route),
                            "breaks": []
                        },
                        {
                            "consignment_code": ["DAIMLER SAP Route"],
                            "coords": eval(resp.data.saved_base_route[0]),
                            "breaks": []
                        }
                    ]
                    this.setState({
                        sliderRouteTranslate1: "slider-translate-50p",
                        mapfor: "map_routes",
                        // routeRowData: e.data,
                        dealersList: [],
                        route_coords1: route_coords1,
                        overly:"show-m",
                        loadshow:'show-n'
                    })
                }
                
            })
            
        }
        else if(e.colDef.field == "base_routes_map")
        {
            this.setState({
                overly: "show-m",
                loadshow: "show-m"
            })
            var plant_code = this.state.originoptions.filter(k => k.label == e.data.plant)
            plant_code = plant_code[0]['value']
            var formdata = {
                "row_id": [e.data.base_route_id],
                "consigner_code": plant_code,
                "consignee_city": e.data.consignee_city,
                "period_type": this.state.selected_period_type.value,
                "gps_provider": this.state.selected_provider.value,
                "truck_type": this.state.selected_truck_type.value,
            }
            redirectURL.post("dashboard/getBaseRouteMapDetails", formdata).then((response)=>{
                // console.log(response.data, 'breaks')
                if(response.data.saved_base_route.length == 0)
                {
                    this.setState({
                        sliderRouteTranslate:"slider-translate-50p",
                        mapfor: "base_routes_map",
                        routeRowData: response.data[0],
                        route_coords:eval(response.data.base_route_row[0].route_coords),
                        breaks_list: [],
                        overly:"show-m",
                        loadshow:'show-n'
                    })
                }
                else
                {
                    var route_coords1 = [
                        {
                            "consignment_code": ["Base Route - "+e.data.base_route_no],
                            "coords": eval(response.data.base_route_row[0].route_coords),
                            "breaks": []
                        },
                        {
                            "consignment_code": ["DAIMLER SAP Route"],
                            "coords": eval(response.data.saved_base_route[0]),
                            "breaks": []
                        }
                    ]
                    this.setState({
                        sliderRouteTranslate1: "slider-translate-50p",
                        mapfor: "map_routes",
                        // routeRowData: e.data,
                        dealersList: [],
                        route_coords1: route_coords1,
                        overly:"show-m",
                        loadshow:'show-n'
                    })
                }
                
            })
        }
        else if(e.colDef.field == "no_of_trips_travelled")
        {
            this.setState({
                complete_trips: this.state.defaultTrips
            })
        }
        else if(e.colDef.field == "valid_trips")
        {
            var filteredTrips = this.state.defaultTrips.filter((e)=>{
                return e.route_ignored == undefined
            })
            this.setState({
                complete_trips: filteredTrips
            })
        }
        else if(e.colDef.field == "route_ignored")
        {
            var filteredTrips = this.state.defaultTrips.filter((e)=>{
                return e.route_ignored == 1
            })
            this.setState({
                complete_trips: filteredTrips
            })
        }
        else if(e.colDef.field == "st_lines_1")
        {
            var filteredTrips = this.state.defaultTrips.filter((e)=>{
                return e.no_of_straight_lines == 1
            })
            this.setState({
                complete_trips: filteredTrips
            })
        }
        else if(e.colDef.field == "st_lines_2")
        {
            var filteredTrips = this.state.defaultTrips.filter((e)=>{
                return e.no_of_straight_lines == 2
            })
            this.setState({
                complete_trips: filteredTrips
            })
        }
        else if(e.colDef.field == "st_lines_3")
        {
            var filteredTrips = this.state.defaultTrips.filter((e)=>{
                return e.no_of_straight_lines == 3
            })
            this.setState({
                complete_trips: filteredTrips
            })
        }
        else if(e.colDef.field == "st_lines_4")
        {
            var filteredTrips = this.state.defaultTrips.filter((e)=>{
                return e.no_of_straight_lines == 4
            })
            this.setState({
                complete_trips: filteredTrips
            })
        }
        else if(e.colDef.field == "st_lines_5")
        {
            var filteredTrips = this.state.defaultTrips.filter((e)=>{
                return e.no_of_straight_lines == 5
            })
            this.setState({
                complete_trips: filteredTrips
            })
        }
        else if(e.colDef.field == "st_lines_gt_5")
        {
            var filteredTrips = this.state.defaultTrips.filter((e)=>{
                return e.no_of_straight_lines > 5
            })
            this.setState({
                complete_trips: filteredTrips
            })
        }
    }

    async onClickShowRouteMap(){
        this.setState({
            loadshow: "show-m",
            overly: "show-m"
        })
        var selectedRows = [];
        this.state.tripsGridApi.getSelectedNodes().map(e => {
            // console.log(e, "check")
            selectedRows.push(e.data)
        })
        if(selectedRows.length == 0){
            this.setState({
                loadshow: "show-n",
                overly: "show-n",
                basicTitle: "Please Select Atlease One Route",
                basicType: "warning",
                show: true
            })

        }else{
            if(selectedRows.length > 3)
            {
                this.setState({
                    loadshow: "show-n",
                    overly: "show-n",
                    basicTitle: "Only 3 trips are allowed",
                    basicType: "warning",
                    show: true
                })
            }
            else
            {
                var route_coords1 = [];
                var dealersList = [];
                // console.log(selectedRows,"selected");
                var ids = [];
                var waitForLoop = await selectedRows.map(async (e)=>{
                    await new Promise(async (resolve,reject)=>{
                        var consignment_codes = e.consignment_codes;
                        if(e.consignment_codes == undefined)
                        {
                            consignment_codes = ["Base Route "+e.base_route_no]
                        }
                        var breaks = e.breaks;
                        if(e.breaks == undefined)
                        {
                            breaks = []
                        }
                        // console.log(this.state.btn3, "btn3")
                        if(this.state.btn3 == "btn-danger")
                        {
                            ids.push(e.base_route_id)
                            route_coords1.push({
                                "consignment_code": consignment_codes,
                                "coords": "",
                                "breaks": breaks,
                                "base_route_id": e.base_route_id
                            })
                        }
                        else{
                            route_coords1.push({
                                "consignment_code": consignment_codes,
                                "coords": eval(e.route_coords),
                                "breaks": breaks
                            })
                        }
                        
                        if(e.consignee_coordinates != undefined)
                        {
                            dealersList.push(e.consignee_coordinates)
                        }
                        resolve(1)
                    })                
                })
                Promise.all([waitForLoop]).then(async ()=>{
                    var formdata = {
                        "consigner_code":  this.state.originoption.value,
                        "consignee_city": this.state.destinationoption.value,
                        // "period_type": this.state.selected_period_type.value,
                        // "gps_provider": this.state.selected_provider.value,
                        // "truck_type": this.state.selected_truck_type.value,
                    }
                    redirectURL.post("dashboard/getSavedBaseRoute", formdata).then(async (respdata)=>{
                        if(respdata.data.length)
                        {
                            route_coords1.push({
                                "consignment_code": ['DAIMLER SAP Route'],
                                "coords": eval(respdata.data[0]),
                                "breaks": []
                            })
                        }
                        if(ids.length > 0)
                        {
                            var formdata = {
                                "row_id": ids
                            }
                            // console.log(formdata, "formdata")
                            var waitForMap = await redirectURL.post("dashboard/getBaseRouteMapDetails", formdata).then(async (response)=>{
                                response.data.base_route_row.map((k,index)=>{
                                    var idx = ""
                                    route_coords1.filter((j, index)=>{
                                        // console.log(j.base_route_id, k._id, "base_cons[0]")
                                        if(j.base_route_id == k._id)
                                        {
                                            idx = index
                                        }
                                    })
                                    if(route_coords1[idx].consignment_code[0] != "DAIMLER SAP Route")
                                    {
                                        route_coords1[idx].coords = eval(k.route_coords)
                                    }
                                })
                                this.setState({
                                    sliderRouteTranslate1: "slider-translate-50p",
                                    mapfor: "map_routes",
                                    // routeRowData: e.data,
                                    dealersList: dealersList,
                                    route_coords1: route_coords1,
                                    overly:"show-m",
                                    loadshow:'show-n'
                                })
                            })
                            
                        }
                        else{
                            // console.log(route_coords1, "route_coords1 else")
                            this.setState({
                                sliderRouteTranslate1: "slider-translate-50p",
                                mapfor: "map_routes",
                                // routeRowData: e.data,
                                dealersList: dealersList,
                                route_coords1: route_coords1,
                                overly:"show-m",
                                loadshow:'show-n'
                            })
                        }
                    })
                })
                
            }

        }
    }

    async onClickMarkAsBaseRoute(){
        this.setState({
            loadshow: "show-m",
            overly: "show-m"
        })
        var selectedRows = [];
        this.state.tripsGridApi.getSelectedNodes().map(e => {
            // console.log(e, "check")
            selectedRows.push(e.data)
        })
        
        if(selectedRows.length > 1)
        {
            this.setState({
                loadshow: "show-n",
                overly: "show-n",
                basicTitle: "Only 1 Route is allowed to mark as base route",
                basicType: "warning",
                show: true
            })
        }
        else if(selectedRows.length == 0)
        {
            this.setState({
                loadshow: "show-n",
                overly: "show-n",
                basicTitle: "Please select a route",
                basicType: "warning",
                show: true
            })
        } 
        else
        {
            if(window.confirm("Do you really wish to mark this as DAIMLER SAP Route?"))
            {
                var plant_name = selectedRows[0]['plant'];
                var plant_code = this.state.originoptions.filter(e => e.label == plant_name);
                plant_code = plant_code[0]['value']
                var formdata = {
                    "base_route_id": selectedRows[0]['base_route_id'],
                    "consigner_code": plant_code,
                    "consignee_city": selectedRows[0]['consignee_city'],
                    "gps_provider": this.state.selected_provider.value,
                    "period_type": this.state.selected_period_type.value,
                    "truck_type": this.state.selected_truck_type.value,
                    "created_time": moment.parseZone(new Date()).utcOffset("+05:30")._d,
                    "created_by": localStorage.getItem("email"),
                    "google_distance_via_waypoints": selectedRows[0]['google_distance_via_waypoints'],
                    "expected_distance": selectedRows[0]['expected_distance']
                }
                redirectURL.post("dashboard/markAsBaseRouteAnalysis", formdata).then((resp)=>{
                    if(resp.data.message == "success")
                    {
                        this.setState({
                            loadshow: "show-n",
                            overly: "show-n",
                            basicTitle: "successfuly updated",
                            basicType: "success",
                            show: true
                        })
                        var formdata = {
                            consigner_code:this.state.originoption.value,
                            consignee_city:this.state.destinationoption.value,
                            gps_provider: this.state.selected_provider.value,
                            period_type: this.state.selected_period_type.value,
                            truck_type: this.state.selected_truck_type.value,
                        }
                        this.onLoadFormRequest(formdata)
                    }
                    else
                    {
                        this.setState({
                            loadshow: "show-n",
                            overly: "show-n",
                            basicTitle: "Failed to mark the base route",
                            basicType: "warning",
                            show: true
                        })
                    }
                    
                })
            }
            else{
                this.setState({
                    loadshow: "show-n",
                    overly: "show-n",
                })
            }
        }
    }

    render(){
        const {ignoredRouteSubmit} = this.state
        return (
            
    		<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
	            {this.state.alert}
            	<div className="row">
            		<div className="col-xl-12 col-lg-12">
                        <div className="row col-xl-12 col-lg-12">
                            <div className="form-group col-xl-2 col-lg-2">
                                <label>Origin: </label>
                                <Select 
                                    name="originoption"
                                    closeMenuOnSelect={true} 
                                    value={this.state.originoption} 
                                    className="border-radius-0" 
                                    onChange={this.changeOriginHandler.bind(this)} 
                                    style={{borderRadius:"0px"}} 
                                    options={this.state.originoptions} required
                                />
                            </div>
                            <div className="form-group col-xl-2 col-lg-2">
                                <label>Destination: </label>
                                <Select 
                                    name="destinationoption"
                                    closeMenuOnSelect={true} 
                                    value={this.state.destinationoption} 
                                    className="border-radius-0" 
                                    onChange={this.changeDestinationHandler.bind(this)} 
                                    style={{borderRadius:"0px"}} 
                                    options={this.state.destinationOptions} required
                                />
                            </div>
                            <div className="form-group col-xl-2 col-lg-2">
                                <label>GPS Provider: </label>
                                <Select 
                                    name="GPS Provider"
                                    closeMenuOnSelect={true} 
                                    value={this.state.selected_provider} 
                                    className="border-radius-0" 
                                    onChange={this.changeGpsProviderHandler.bind(this)} 
                                    style={{borderRadius:"0px"}} 
                                    options={this.state.lspusers_options} required
                                />
                            </div>
                            <div className="form-group col-xl-2 col-lg-2">
                                <label>Period: </label>
                                <Select 
                                    name="Period"
                                    closeMenuOnSelect={true} 
                                    value={this.state.selected_period_type} 
                                    className="border-radius-0" 
                                    onChange={this.changePeriodTypeHandler.bind(this)} 
                                    style={{borderRadius:"0px"}} 
                                    options={this.state.period_types} required
                                />
                            </div>
                            <div className="form-group col-xl-2 col-lg-2">
                                <label>Truck Type: </label>
                                <Select 
                                    name="Truck Type"
                                    closeMenuOnSelect={true} 
                                    value={this.state.selected_truck_type} 
                                    className="border-radius-0" 
                                    onChange={this.changeTruckTypeHandler.bind(this)} 
                                    style={{borderRadius:"0px"}} 
                                    options={this.state.truck_types} required
                                />
                            </div>

                            <div className="form-group col-xl-2 col-lg-2">
                                <button style={{marginTop:"30px"}} 
                                onClick={ignoredRouteSubmit == 1 ? this.getIgnoredRoutes : this.formHandler.bind(this)}
                                 type="button" className="btn btn-success">Submit</button>
                            </div>
                            {/* <div className="form-group col-xl-3 col-lg-3" style={{textAlign: "right"}}>
                            </div> */}
                            
                        </div>
                        <div className="button-group">
                            <button className={"btn "+this.state.btn1} onClick={this.onClickTab.bind(this, "btn1")}>Summary</button>
                            {/* <button className={"btn "+this.state.btn2} onClick={this.onClickTab.bind(this, "btn2")}>Trips</button> */}
                            <button className={"btn "+this.state.btn3} onClick={this.onClickTab.bind(this, "btn3")}>Base Routes</button>
                            <button className={"btn "+this.state.btn4} onClick={this.onClickTab.bind(this, "btn4")}>Transporters Info</button>
                            <button className={"btn "+this.state.btn5} onClick={this.onClickTab.bind(this, "btn5")}>Ignored Routes</button>
                            {/* <a style={{float: "right"}} href={'/ignoredroutes'} class="btn btn-warning" rel="noopener" >Ignored Routes</a> */}
                            {/* <a style={{float: "right"}} href={"/deviationroutes?source="+this.state.originoption.value+"&sourcename="+this.state.originoption.value+"&destination="+this.state.destinationoption.value+'&period_type='+ this.state.selected_period_type.value +'&gps_provider='+this.state.selected_provider.value+'&truck_type='+this.state.selected_truck_type.value} target="_blank" class="btn btn-warning" rel="noopener">View Base Routes</a> */}
                        </div>
		            	<div className="card">
			                <div className="card-header">
				       			<h5 style={{float: "left", width: "50%"}}>
				       				<i className="icon-map cus-i"></i> <span>{this.state.pageTitle}</span>
				       			</h5>
                                {/* {(this.state.btn1 == "btn-danger")?
                                    <p style={{color: "red"}}><b>Note :</b> If there is a GPS gap of >20 Kms, that instance is considered as straight line</p>
                                :""} */}
				   			</div>
				   			<div className="card-body row">

								<div className="row col-xl-12 col-lg-12">
                                    {(this.state.hideSummaryTab == false)?                                    
                                        <div id="myGrid" style={{ height: this.state.gridHeight,width: "100%"}}  className="ag-theme-balham">
                                            <AgGridReact
                                                modules={this.state.modules}
                                                columnDefs={this.state.columnDefs}
                                                defaultColDef={this.state.defaultColDef}
                                                rowData={this.state.rowData}
                                                enableCharts={false}
                                                autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                onGridReady={this.onGridReady}
                                                onGridState={this.onGridState}
                                                statusBar={this.state.statusBar}
                                                sideBar={this.state.sideBar}
                                                paginationPageSize={this.state.paginationPageSize}
                                                pagination={true}
                                                enableRangeSelection={true}
                                                gridOptions={{
                                                    context: { componentParent: this }
                                                }}
                                                frameworkComponents={this.state.frameworkComponents}
                                                //onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                                onCellClicked={this.onCellClicked.bind(this)}
                                            />
                                        </div>
                                    :""}                     
                                    {(this.state.btn1 == "btn-danger" || this.state.btn3 == "btn-danger")?
                                        <div id="myGrid" style={{ height: "360px",width: "100%"}}  className="ag-theme-balham">
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                <h5 style={{float: "left", width:"70%"}}>
                                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{"Trips"}</span>
                                                </h5>
                                                {(this.state.btn3 == "btn-danger")?
                                                    <button className="btn btn-info" onClick={this.onClickMarkAsBaseRoute.bind(this)}>Mark as DAIMLER SAP Route</button>
                                                :""}
                                                <button className="btn btn-warning" onClick={this.onClickShowRouteMap.bind(this)}>Show Map</button>
                                            </div>
                                            <AgGridReact
                                                modules={this.state.modules}
                                                columnDefs={(this.state.btn3 == "btn-danger")?colDefs3:colDefs2}
                                                // columnDefs = {this.state.columnDefs}
                                                defaultColDef={this.state.defaultColDef}
                                                rowData={this.state.complete_trips}
                                                enableCharts={false}
                                                autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                onGridReady={this.onGridReady1}
                                                onGridState={this.onGridState1}
                                                statusBar={this.state.statusBar}
                                                sideBar={this.state.sideBar}
                                                paginationPageSize={this.state.paginationPageSize}
                                                pagination={true}
                                                enableRangeSelection={true}
                                                gridOptions={{
                                                    context: { componentParent: this }
                                                }}
                                                onCellClicked={this.onCellClicked.bind(this)}
                                                // onRowSelected={this.onRowSelected.bind(this)}
                                                rowSelection={"multiple"}

                                            />
                                        </div>
                                    :""}
                                    
								</div>
							   		
                            </div>
				   		</div>	
				   	</div>
				 </div>
                <div className={"dataLoadpage " +(this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>

                {
                    (this.state.sliderRouteTranslate != "")?
                    <div className={"slide-r "+(this.state.sliderRouteTranslate)} style={{overflow:"auto"}}>
					 	<div className="slide-r-title">
							<h4>
								View Route Data
								<span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseUploadDiv.bind(this)} >X</span>
										
							</h4>
						</div>
						<div className="slide-r-body" style={{position:"relative"}}>
							
							<div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
								
                                <DrawMap 
                                    mapFor={"geofence"}
                                    mapinfo={{"coords": this.state.route_coords,
                                            "breaks": this.state.breaks_list,
                                            "consigner_coordinates": this.state.complete_trips[0]['consigner_coordinates'],
                                            "consignee_coordinates": this.state.complete_trips[0]['consignee_coordinates'],
                                            "route_details": {"start_time": "",
                                                            "end_time": ""}}} 
                                    coords={this.state.route_coords} 
                                    consignments_list={[]}
                                    breaks={this.state.breaks_list} 
                                />
                                {(this.state.mapfor != "base_routes_map")?
                                    <div className="col-xl-12 col-lg-12 n-p-0">
                                        <div className="crm-numbers pb-0">
                                            <h3 className="subH">{"Details"}</h3>
                                            <div className="col-xl-12 col-lg-12 row">
                                                <div className="col route-block">
                                                    <label className="sidebar-label">Truck No</label>
                                                    <div>{this.state.routeRowData.truck_no}</div>
                                                </div>
                                                <div className="col route-block">
                                                    <label className="sidebar-label">Start Time</label>
                                                    <div>{(this.state.routeRowData.invoice_time != '')?getDDMMYYYYHHMMSS(this.state.routeRowData.invoice_time):"NA"}</div>
                                                </div>
                                                <div className="col route-block">
                                                    <label className="sidebar-label">End Time</label>
                                                    <div>{(this.state.routeRowData.trip_end_time != '')?getDDMMYYYYHHMMSS(this.state.routeRowData.trip_end_time):"NA"}</div>
                                                </div>

                                                <div className="col route-block">
                                                    <label className="sidebar-label">Distance</label>
                                                    <div>{Math.round(this.state.routeRowData.total_trip_dist_with_google_km) +" Kms"}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                :""}
                                
                                
							</div>
						</div>
						
					</div>
				:""}

                {
                    (this.state.sliderRouteTranslate1 != "")?
                    <div className={"slide-r "+(this.state.sliderRouteTranslate1)} style={{overflow:"auto"}}>
					 	<div className="slide-r-title">
							<h4>
								View Route Data
								<span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseUploadDiv.bind(this)} >X</span>
										
							</h4>
						</div>
						<div className="slide-r-body" style={{position:"relative"}}>
							
							<div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
								
                                <DrawMap 
                                    mapFor={"multiroutedeviation"}
                                    mapinfo={{"coords": this.state.route_coords1,
                                            "consigner_coordinates": this.state.defaultCompleteTrips[0]['consigner_coordinates'],
                                            "consignee_coordinates": this.state.dealersList,
                                            "route_details": {"start_time": "",
                                                            "end_time": ""}}} 
                                    coords={this.state.route_coords} 
                                    consignments_list={[]}
                                    breaks={this.state.breaks_list} 
                                />
                                
                                
							</div>
						</div>
						
					</div>
				:""}

                


            </div>
              
        );
    }
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};
function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}
function loadchecked()
{
   // var defaultin = document.getElementById("Route 1").checked = true;
//    var checkBox = document.getElementById("Route 1").checked = true
//    var checkBox = document.getElementById("Route 2").checked = true
//    var checkBox = document.getElementById("Route 3").checked = true
    var check = document.getElementsByClassName("checklists");
    // console.log("check here ",check)
    if(check.length > 0)
    {
        for(var c=0;c<check.length;c++)
        {
            //check[c].setAttribute("checked", true)
            document.getElementById(check[c]).checked = true;
        }
    }
}
window.onload = function() {
    // var checkBox = document.getElementById("Route 1").checked = true
    // var checkBox = document.getElementById("Route 2").checked = true
    // var checkBox = document.getElementById("Route 3").checked = true
    
};

function selectAllRenderer(params) {
    var cb = document.createElement('input');
    cb.setAttribute('type', 'checkbox');

    var eHeader = document.createElement('label');
    var eTitle = document.createTextNode(params.colDef.headerName);
    eHeader.appendChild(cb);
    eHeader.appendChild(eTitle);
    params.api.selectAll();
    cb.addEventListener('change', function (e) {
       
            params.api.selectAll();
       
    });
    return eHeader; 
}

function CheckForHtmlTag(){
	var rs = document.getElementById("route_reason");
	var reg =/<(.|\n)*?>/g; 
	if (reg.test(rs.value)) {
		var ErrorText = "Oops! HTML or Script is not allowed.";		
		rs.value=''
		//alert();
		return 1;
	}
}
function SplitTime(numberOfHours){
    var Days=Math.floor(numberOfHours/24);
    var Remainder=numberOfHours % 24;
    var Hours=Math.floor(Remainder);
    var Minutes=Math.floor(60*(Remainder-Hours));
    return({"Days":Days,"Hours":Hours,"Minutes":Minutes})
}

function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}   

function sortPeriodTypes(periods_list)
{
    var quarters_dict = {"4": ["Jan", "Feb", "Mar"], "1": ["Apr", "May", "Jun"], "2": ["Jul", "Aug", "Sep"], "3": ["Oct", "Nov", "Dec"]};
    var final_list = ["ALL"];
    var distinctYears = [];
    periods_list = periods_list.filter((e) => {
        var matches = e.match(/(\d+)/);
        // console.log(matches)
        if(matches != null)
        {
            if(matches.length)
            {
                var year = parseInt(matches[0])
                if(distinctYears.indexOf(year) < 0)
                {
                    distinctYears.push(year)
                }
            }
        }
        
        return e != "ALL"
    })
    distinctYears = distinctYears.sort()
    distinctYears.map((e)=>{
        var filteredData = periods_list.filter(k => k.includes(e.toString()))
        if(filteredData.length)
        {
            var quarters = filteredData.filter(j => j.includes("Q"));
            var resultMonths = filteredData.filter(j => j.includes("Q") == false);
            quarters = quarters.sort()
            if(quarters.length)
            {
                quarters.map(q => {
                    final_list.push(q)
                    var quarter_no = q.split("Q")[1];
                    var quarter_months = quarters_dict[quarter_no];
                    quarter_months.map(m =>{
                        var finalMonth = resultMonths.filter(r => r.includes(m));
                        if(finalMonth.length)
                        {
                            final_list.push(finalMonth[0])
                        }
                    })
                })
            }
        }
    });
    final_list = final_list.filter(e => e != "");
    return final_list
}