import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import CountUp from 'react-countup';

import _ from "lodash";

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
//import Grid from '../layouts/gridnosidebarComponent';

import CustomLoadingCellRenderer from "../layouts/customLoadingCellRenderer";
import KpiTransitDelayActions from './kpiTransitDelayActions';
import Notransit from './notransitComponent.js';
import ConsignmentActions from './routemapComponent';
import ConsignmentNoActions from './routemapNoComponent';
import ExportsHighSeasAction from './exportshighseasaction';
import ConsignmentImportsNoEventsAction from './consignmentimportsnoEventAction';

import ExportsPortAction from './exportsinportaction';
import ExportsIcdToPortAction from './exportsicdtoportaction';
import ExportsIcdToMsilAction from './exportsicdtomsilaction';
import ExportsMsilToIcdAction from './exportsmsiltoicdaction';
import ExportsInIcdAction from './exportsinicdaction';
import ImportsEmptyContainerAction from './importsemptycontaineraction';

// Actions for Imports Coils
import ImportsCoilsInfoReceivedAction from './importscoilinforeceivedaction';
import ImportsNykCoilInfoReceivedAction from './importsnykcoilinforeceivedaction';
import ImportsCoilsArrivedAtIndianPortAction from './importscoilsarrivedatindianportaction';
import ImportsCoilsInTransitToIcdAction from './importscoilsintransittoicdction';
import ImportsCoilsInsideIcdAction from './importscoilsinsideicdaction';
import ImportsCoilsInTransitToMsilAction from './importscoilsintransittomsilaction';
import ImportsCoilsInsideMsilAction from './importscoilsinsidemsilraction';


//import Counters from '../layouts/consignmentimportscountersComponent';
import ImportsCoilsCountersComponent from '../layouts/importscoilscounters.component';
import 'react-vertical-timeline-component/style.min.css';
import { getDDMMYYYY,getYYYYMMDDHHMMSS, getDDMMYYYYHHMMSS,getHyphenYYYYMMDDHHMMSS, getYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY } from '../common/utils'; 
import DrawMap from '../common/drawmap';
import Breadcrumb from '../common/breadcrumb.component';

import Modal from 'react-responsive-modal';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';

import Select from 'react-select';


/*Critical and Force Close buttons */
import Forceclose from './importsforcecloseaction';
import Criticalaction from './importcontainercriticalaction';

import $ from 'jquery';
var moment = require('moment');
 
var redirectURL = require('../redirectURL');

var infoBox = require('../common/google-infowindow');

const fs = require('fs');
var googleAnalytics = require("../common/googleanalytics");

 
var drawingManager;
var selectedShape;
var colors = ['#1D9567', '#1692A5', '#F4D749','#F73E3E', '#B7B3B3' ];
var selectedColor;
var colorButtons = {};
var fencingLength=0;
var mapZoom=12;
var coordinates=[];
var geoFencingArea;

export default class ManageImportsCoils extends Component {

	constructor(props){
		super(props);
		this.state={
			pagetitle: "Coils Imports",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			
			totalCoilsCount: 0,

			coilInfoReceivedData: null,
			coilInfoReceivedCount: 0,

			arrivedAtIndianPortData: null,
			arrivedAtIndianPortCount:0,

			inTransitToIcdData: null,
			inTransitToIcdCount: 0,

			insideIcdData: null,
			insideIcdCount: 0,

			inTransitToMsilData: null,
			inTransitToMsilCount: 0,

			insideMsilData: null,
			insideMsilCount: 0,

			msiltovendor:0,
			vendortomsp:0,
			consignmentStatus: null,
			statusTexts: [],

			uploadDivWidth:'0%',
			sliderTranslate:'',
			sliderRouteTranslate:'',
			tabsliderTranslate:'',
			timelineinfo:'',
			consignment_code:'',
			mapinfo:'',
			loadshow:'show-n',
			showDiv:'show-n',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			alerterrshow:'fade',
			overly:'show-n',
			alert:null,
            show: false,
            basicTitle:'',
			basicType:"default",
			modules: AllModules,
			filterstatess:{},
      	    columnsdatas:{},
      	    rowgroupdcols:[],
      	    valuecolumns:[],
      	    pivotmodeis:false,
			pivotcols:[],
			defTransitCoords:'',
			allRowData:0,
			highseascount:0,
			inportcount:0,
			
			inicdcount:0,
			transitmsilcount:0,
      	    defaultColDef: {
      	    	sortable: true,
				filter: true,
				resizable: true,
				

      	    },
      	    rowData: null,
			headerHeight: 40,
			groupHeaderHeight:60,
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
			  frameworkComponents: {
                customLoadingCellRenderer: CustomLoadingCellRenderer,
				consignmentActions:ConsignmentActions,
				consignmentNoActions:ConsignmentNoActions,
				exportsHighSeasAction: ExportsHighSeasAction,
				ConsignmentImportsNoEventsAction:ConsignmentImportsNoEventsAction,
				exportsPortAction:ExportsPortAction,
				exportsIcdToPortAction:ExportsIcdToPortAction,
				exportsIcdToMsilAction:ExportsIcdToMsilAction,
				exportsMsilToIcdAction:ExportsMsilToIcdAction,
				exportsInIcdAction:ExportsInIcdAction,
				importsEmptyContainerAction:ImportsEmptyContainerAction,

				importsNykCoilInfoReceivedAction:ImportsNykCoilInfoReceivedAction,
				importsCoilInfoReceivedAction: ImportsCoilsInfoReceivedAction,
				importsArrivedAtIndianPortAction: ImportsCoilsArrivedAtIndianPortAction,
				importsInTransitToIcdAction: ImportsCoilsInTransitToIcdAction,
				importsInsideIcdAction: ImportsCoilsInsideIcdAction,
				importsInTransitToMsilAction: ImportsCoilsInTransitToMsilAction,
				importsInsideMsilAction: ImportsCoilsInsideMsilAction,
				forceclose:Forceclose,
				criticalaction:Criticalaction
			},

      	    animateRows: true,
      	    debug: true,
      	    showToolPanel: false,
			uppressAggFuncInHeader: true,
				
			childconfs: [],
			childrow:[],
			rowModelType: "serverSide",
			paginationPageSize:100,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			rowClassRules: {
				"highlitRow": "data.transit_delay == 1",
				"cellStyleGrid":"columnApi.columnController.columnDefs[0].headerName == 'High Sea Details'",
				//"d-none": "data.last_status == 'Empty Returned'"
			},
			podlist:[],
			pod:'',
			startDate:'',
			endDate:'',
			criteria:'',
			errStyCriteria:'',
			errStyPod:'',
			errStyStartDate:'',
			errStyEndDate:'',
			commentsRowData:"",
			open: false,
			opencritical:false,
			criticalslide:"",
			usergridstate:[],
			screenurl:"",
			screentitle:"",
            tolls : []
			
		}
	
		this.onShowRouteMap = this.onShowRouteMap.bind(this);
		this.compare_dates = this.compare_dates.bind(this);
		this.changeHandler=this.changeHandler.bind(this);
		
	}

	getStatusText(status) {
		let consignmentStatus = null;
		switch(status) {
			case 1:
				//console.log("CASE 1 = ", status);
				consignmentStatus = this.state.statusTexts[status];
				break;
			case 2:
				consignmentStatus = this.state.statusTexts[status];
				break;
			case 3:
				consignmentStatus = this.state.statusTexts[status];
				break;
			case 4:
				consignmentStatus = this.state.statusTexts[status];
				break;
			case 5:
				consignmentStatus = this.state.statusTexts[status];
				break;
			case 6:
				consignmentStatus = this.state.statusTexts[status];
				break;
			case 7:
				consignmentStatus = this.state.statusTexts[status];
				break;
			
			case 9:
				consignmentStatus = this.state.statusTexts[status];
				break;
			default:
				//console.log("CASE Default = ", status);
				consignmentStatus = "All"
				break;
		}

		//console.log("CASE consignmentStatus = ", consignmentStatus);
		
		return consignmentStatus;
	}
	componentDidMount(){
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	
		let statusTexts = {
			"1": "High Seas",
			"2": "Arrived at Indian Port",
			"3": "In transit to ICD",
			"4": "Inside ICD",
			"5": "In transit to MSIL",
			"6": "Inside MSIL",
			"7": "MSIL to Vendor",
			"9": "Vendor to MSP"
		}
		this.setState({
			statusTexts:statusTexts,
		});
		 /*Consignments List*/	

		redirectURL.post("importscoils/pods")
		.then((response) =>{
			this.setState({
				podlist:response.data
			});
		})
		.catch(function(e){
			console.log("Error ", e)
		})
		 var urlpath;
		
		 redirectURL.post("/consignments/usergridstates",{userId:localStorage.getItem("userid"),screenurl:window.location.pathname})
		 .then( async (resp) => {
			 await this.setState({
				 usergridstate:resp.data
			 });
			 await this.restoreGridStates();
		 })
		if(this.props.match.path == "/imports/coils")
		{
			//urlpath = '/exports/consignments';
			urlpath = '/importscoils/coils';
		}
		// if(this.props.match.path == "/exports/adaniconsignments")
		// {
		// 	urlpath = '/exports/adaniconsignments';
		// }
		var sdate = '2020-03-01';
		var edate = moment.parseZone().format('YYYY-MM-DD');
		var rparams = {
			startDate:"",
			endDate:"",
			pod:'',
			page:"default"
		}
		redirectURL.post(urlpath,rparams)
		    .then((response) => {
				var recordarr = response.data;
				console.log(recordarr)
				//process.exit(0);
				var records=[];
				let uniqueObject = {}; 
				var objTitle;
				for (let i in recordarr) { 
					
					if(recordarr[i]["nyk_bl_no"])
					{
						objTitle = recordarr[i]['nyk_bl_no']; 
						uniqueObject[objTitle]= recordarr[i];
					}
					else
					{
						objTitle = recordarr[i]['bill_of_lading']; 
						uniqueObject[objTitle]= recordarr[i];
					}
				} 
				//console.log("uniqueObject asdas ", uniqueObject);
				for (var a in uniqueObject) { 
					records.push(uniqueObject[a]); 
				}

				 console.log("Records = ", records);
				recordarr.map(rec => {

					// if (rec.icd_source) {
					// 	rec.icd_source = rec.icd_source.toUpperCase();
					// }  
					if (rec.port_of_discharge) {
						rec.port_of_discharge = rec.port_of_discharge.toUpperCase();
					}  
					if (rec.status) {
						rec.statusText = this.getStatusText(rec.status);
						//console.log("IN  rec.statusText = ",rec.statusText);
					}  
				})
				//console.log("IN EXPORTS , records = ", records, records.length);
				this.setState({
					rowData:recordarr,
					allRowData:recordarr,
				});

				// calculate counters
				let totalCoilsCount  = 0;

				let coilInfoReceivedData  = null;
				let coilInfoReceivedCount  = 0;

				let arrivedAtIndianPortData  = null;
				let arrivedAtIndianPortCount  = 0;

				let inTransitToIcdData  = null;
				let inTransitToIcdCount  = 0;

				let insideIcdData  = null;
				let insideIcdCount  = 0;

				let inTransitToMsilData  = null;
				let inTransitToMsilCount  = 0;

				let insideMsilData  = null;
				let insideMsilCount  = 0;

				let msiltovendor = 0;

				totalCoilsCount = (recordarr) ? recordarr.length : 0;

				let coilInfoReceivedDatam = recordarr.filter(rec => (rec.status == 1));
				//Appending Data from "nykvoyages" collection rows to importscoils collection
				let coilInfoReceivedData1 = recordarr.filter(rec => (rec.nyk_bl_no &&  rec.last_status != "Arrived at Indian Port"));
				
				coilInfoReceivedData = coilInfoReceivedDatam.concat(coilInfoReceivedData1);
				coilInfoReceivedCount = (coilInfoReceivedData) ? coilInfoReceivedData.length : 0;
				
				arrivedAtIndianPortData = recordarr.filter(rec => (rec.status == 2 || rec.last_status == "Arrived at Indian Port"));
				arrivedAtIndianPortCount = (arrivedAtIndianPortData) ? arrivedAtIndianPortData.length : 0;
				
				inTransitToIcdData = recordarr.filter(rec => rec.status == 3);
				inTransitToIcdCount = (inTransitToIcdData) ? inTransitToIcdData.length : 0;
				
				insideIcdData = recordarr.filter(rec => rec.status == 4);
				insideIcdCount = (insideIcdData) ? insideIcdData.length : 0;
				
				inTransitToMsilData = recordarr.filter(rec => rec.status == 5);
				inTransitToMsilCount = (inTransitToMsilData) ? inTransitToMsilData.length : 0;

				insideMsilData = recordarr.filter(rec => rec.status == 6);
				insideMsilCount = (insideMsilData) ? insideMsilData.length : 0;
				
				msiltovendor = recordarr.filter(rec => rec.status ==7 );
				// msiltovendorCount = msiltovendor.length;


				//console.log("inTransitToMsilData = ", inTransitToMsilData, inTransitToMsilCount);

				this.setState({
					totalCoilsCount: totalCoilsCount,

					coilInfoReceivedData: coilInfoReceivedData,
					coilInfoReceivedCount: coilInfoReceivedCount,

					arrivedAtIndianPortData: arrivedAtIndianPortData,
					arrivedAtIndianPortCount: arrivedAtIndianPortCount,
		
					inTransitToIcdData: inTransitToIcdData,
					inTransitToIcdCount: inTransitToIcdCount,
		
					insideIcdData: insideIcdData,
					insideIcdCount: insideIcdCount,
		
					inTransitToMsilData: inTransitToMsilData,
					inTransitToMsilCount: inTransitToMsilCount,

					insideMsilData: insideMsilData,
					insideMsilCount: insideMsilCount,

					msiltovendor : msiltovendor
				});

				//console.log("This state = ", this.state);


			})
		    .catch(function(error) {
		    	console.log(error);
			});
			
	   
	};
	
	


	
	onGridReady = params => {
	    this.gridApi = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
	    this.gridColumnApi = params.columnApi;
	
	   //    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
	//    };
	   
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
	}

	renderMap = () => {    	
		//loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyD1AFWPoJqPaemwGO4A7P7Q6CsUJ1TLGJk&libraries=places,drawing&callback=initMap");
		//window.initMap = this.initMap
	}
	
	initMap = () => {
    	//console.log(this.state.allmarkers);
    	var currentwindow;
    	var jsondata = this.state.mapinfo;
    	var locations = jsondata.points;
        var arr = jsondata.route;
		//console.log("Here",arr);
		if(arr.length > 0)
		{
			var lt=arr[0].lat;
			var ln=arr[0].lng;
		}
		else{
			//console.log("defTransitCoords ", JSON.parse(this.state.defTransitCoords));
			var lt=JSON.parse(this.state.defTransitCoords).lat;
			var ln=JSON.parse(this.state.defTransitCoords).lng;
		}
    	 var mapOptions = {
    			zoom: 7,
				zoomControl: true,
				mapTypeControl: true,
				scaleControl: true,
				streetViewControl: true,
				rotateControl: true,
				fullscreenControl: true,
    	          labels:true,
    	          mapTypeControlOptions: {
    	            mapTypeIds: ['hybrid', 'roadmap'],
    	          },
    	          center: new window.google.maps.LatLng(lt,ln),
    	          mapTypeId: window.google.maps.MapTypeId.ROADMAP
    	        };
    	        
    	        var map = new window.google.maps.Map(document.getElementById('map'),
    	            mapOptions);

				var bounds = new window.google.maps.LatLngBounds();
				bounds.extend(new window.google.maps.LatLng(lt,ln));
    			 // Create our info window content
    			var currentinfowindow = null;
    	       var line=new window.google.maps.Polyline(
    	              {
    	                map:map,
    									strokeColor: '#157254',
    									strokeOpacity: 1.0,
    									strokeWeight: 2.5,
    	                		icons: [{
    	                          icon: {
    	                                  path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
    	                                  strokeColor:'#ff8c52',
    	                                  fillColor:'#ff8c52',
    	                                  fillOpacity:1,
    									  strokeWeight: 2
    	                                },
    	                          repeat:'100px',
    	                          path:[]
    	                       }]
    	                 });

			if(arr.length > 0)
			{	
    		  for (let i = 0; i < arr.length; i++) {
    				var path=line.getPath().getArray();
    					
    						let latLng=new window.google.maps.LatLng(arr[i].lat,arr[i].lng);
    						path.push(latLng);
    	          			line.setPath(path);
    					

    				  
    		  
    	          //Change line color based on map type
    	        window.google.maps.event.addListener( map, 'maptypeid_changed', function() {
    	            if (map.getMapTypeId() =='hybrid' || map.getMapTypeId() =='satellite')
    	            {
    	                var line=new window.google.maps.Polyline(
    	                  {
    	                    map:map,
    	                    strokeColor: '#FFFFFF',
    	                    strokeOpacity: 1.0,
    	                    strokeWeight: 2.5,
    	                    icons: [{
    	                              icon: {
    	                                      path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
    	                                      strokeColor:'#ff8c52',
    	                                      fillColor:'#ff8c52',
    	                                      fillOpacity:1,
    	                                      strokeWeight: 2
    	                                    },
    	                              repeat:'100px',
    	                              path:[]
    	                           }]
    	                     });                
    	            }
    	            else
    	            {
    	                var line=new window.google.maps.Polyline(
    	                  {
    	                    map:map,
    	                    strokeColor: '#157254',
    	                    strokeOpacity: 1.0,
    	                    strokeWeight: 2.5,
    	                    icons: [{
    	                              icon: {
    	                                      path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
    	                                      strokeColor:'#ff8c52',
    	                                      fillColor:'#ff8c52',
    	                                      fillOpacity:1,
    	                                      strokeWeight: 2
    	                                    },
    	                              repeat:'100px',
    	                              path:[]
    	                           }]
    	                     });

    	            }

    	              /*for (i = 0; i < arr.length; i++) {
    	                    var path=line.getPath().getArray();
    	                    let latLng=new window.google.maps.LatLng(arr[i].lat,arr[i].lng);
    						path.push(latLng);
    	          			line.setPath(path);
    	                            //map.setCenter(latLng);
						  }
						*/
    	                            
    	             var x = map.getZoom();
    	             var c = map.getCenter();
    	             window.google.maps.event.trigger(map, 'resize');
    	             map.setZoom(x);
					 map.setCenter(c);
					 
					 var path=line.getPath().getArray();
					let latLng=new window.google.maps.LatLng(arr[i].lat,arr[i].lng);
					path.push(latLng);
					line.setPath(path);
				} );
				
				bounds.extend(new window.google.maps.LatLng(arr[i].lat,arr[i].lng));
    		  }

		  var infowindow = new window.google.maps.InfoWindow();
			var marker, l;
			  //console.log("locations.length ", locations.length)
			  var lastorigin='';
			if(locations.length > 0)
			{
				for (l = 0; l < locations.length; l++) {
					if(arr[0].lat != locations[l].coordinates.lat && arr[0].lng != locations[l].coordinates.lmg)
					{
						marker = new window.google.maps.Marker({
						position: new window.google.maps.LatLng(locations[l].coordinates.lat, locations[l].coordinates.lng),
						icon:require('../../assets/icons/boat.png'),
						map: map,
						});

						window.google.maps.event.addListener(marker, 'click', (function(marker, l) {
							return function() {

								var contentarr = []
								var headerTitle = locations[l].name+", "+locations[l].country;
								//var contentString = "<p class='maptitle'>"+"<B> "+locations[l].name+", "+locations[l].country+"</B></p>";
								var contentStringData='';
								if(locations[l].events.length > 0)
								{
									contentStringData = contentStringData+"<table class='mt-8p table table-border' style='background:#f5f5f5;border:1px solid #dee2e6'>";
									contentStringData = contentStringData + "<thead><tr><th>Date</th><th>Event</th><th>Transport Mode</th></tr></thead><tbody>";
									for(var e=0;e<locations[l].events.length;e++)
									{
										var typemode;
										if(locations[l].events[e].type == 1){
											typemode='Rail/Truck';
										}
										if(locations[l].events[e].type == 2){
											typemode='Air';
										}
										if(locations[l].events[e].type == 3){
											typemode='Sea';
										}
										if(locations[l].events[e].type == 4){
											typemode='Rail/Truck';
										}
										var d = locations[l].events[e].event_datetime_ist;
										var dat = d.split(" ");				
										
										contentStringData = contentStringData + "<tr><td class='custb'><span class='tbmap'><i class='icofont icofont-disc'></i> <span class='tdmapsp'>"+ dat[0];
										contentStringData = contentStringData + "</span></td><td>" + locations[l].events[e].description + "</span></td><td style='text-align: right'>" + typemode + "</span></td></tr>";
										
				
									}
									contentStringData = contentStringData+"</tbody></table>";
									
								}
								contentarr.push(contentStringData);
							//lastorigin=contentString;
							//console.log("1", headerTitle)
							var contentString = infoBox(marker.icon, headerTitle, contentarr, 'table')
				

							infowindow.setContent(contentString);
							currentwindow = infowindow;
							infowindow.open(map, marker);
							//console.log(marker.position.toJSON());
							//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
							}
						})(marker, l));
					}
					else{
						marker = new window.google.maps.Marker({
							position: new window.google.maps.LatLng(locations[l].coordinates.lat, locations[l].coordinates.lng),
							icon:require('../../assets/icons/start_port.png'),
							map: map,
							});
	
							window.google.maps.event.addListener(marker, 'click', (function(marker, l) {
								return function() {
									//var contentString = "<p class='maptitle'>"+"<B> "+locations[l].name+", "+locations[l].country+"</B></p>";
									var contentarr = []
									var headerTitle = locations[l].name+", "+locations[l].country;
									var contentStringData='';
									if(locations[l].events.length > 0)
									{
										contentStringData = contentStringData+"<table class='mt-8p table table-border' style='background:#f5f5f5;border:1px solid #dee2e6'>";
										contentStringData = contentStringData + "<thead><tr><th>Date</th><th>Event</th><th>Transport Mode</th></tr></thead><tbody>";
										for(var e=0;e<locations[l].events.length;e++)
										{
											var typemode;
											if(locations[l].events[e].type == 1){
												typemode='Rail/Truck';
											}
											if(locations[l].events[e].type == 2){
												typemode='Air';
											}
											if(locations[l].events[e].type == 3){
												typemode='Sea';
											}
											if(locations[l].events[e].type == 4){
												typemode='Rail/Truck';
											}
											var d = locations[l].events[e].event_datetime_ist;
											var dat = d.split(" ");											
											
											contentStringData = contentStringData + "<tr><td class='custb'><span class='tbmap'><i class='icofont icofont-disc'></i> <span class='tdmapsp'>"+ dat[0];
											contentStringData = contentStringData + "</span></td><td>" + locations[l].events[e].description + "</span></td><td style='text-align: right'>" + typemode + "</span></td></tr>";
	
										}
										contentStringData = contentStringData+"</tbody></table>";
										
									}
									contentarr.push(contentStringData);	
									//console.log("2", headerTitle)
									var contentString = infoBox(marker.icon, headerTitle, contentarr, 'table')
					
								infowindow.setContent(contentString);
								currentwindow = infowindow;
								infowindow.open(map, marker);
								//console.log(marker.position.toJSON());
								//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
								}
							})(marker, l));
					}
				}
			}
			if (jsondata.hasOwnProperty('last_known_location') == true && jsondata.last_known_location != null)
			{
		   	    marker = new window.google.maps.Marker({
					position: new window.google.maps.LatLng(jsondata.last_known_location.lat, jsondata.last_known_location.lng),
					icon:require('../../assets/icons/crane.png'),
					map: map,	
				 
			  });
			

		   window.google.maps.event.addListener(marker, 'click', (function(marker) {
		        return function() {
		        	//console.log("jsondata", jsondata);
					var contentarr = []
					var headerTitle = "Last known location";
					var contentStringData='';
					var typemode;
					if(jsondata.last_event.type == 1){
						typemode='Rail/Truck';
					}
					if(jsondata.last_event.type == 2){
						typemode='Air';
					}
					if(jsondata.last_event.type == 3){
						typemode='Sea';
					}
					if(jsondata.last_event.type == 4){
						typemode='Rail/Truck';
					}
					var d = jsondata.last_event.event_datetime_ist;
					var dat = d.split(" ");
					
					var contentStringData = contentStringData+"<table class='mt-8p table table-border' style='background:#f5f5f5;border:1px solid #dee2e6'>";
					contentStringData = contentStringData + "<thead><tr><th>Last Updated Date</th><th>Transport Mode</th></tr></thead><tbody>";
					
					contentStringData = contentStringData + "<tr><td class='custb'><span class='tbmap'><i class='icofont icofont-disc'></i> <span class='tdmapsp'>"+ jsondata.last_known_time;
					contentStringData = contentStringData + "</span></td><td style='text-align: right'>" + typemode + "</td></tr>";
					contentStringData = contentStringData+"</tbody></table>";	
					
					contentarr.push(contentStringData);	
					var contentString = infoBox(marker.icon, headerTitle, contentarr, 'table')
					

				  infowindow.setContent(contentString);
				  currentwindow = infowindow;
				  infowindow.open(map, marker);
				 // console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
				}
			})(marker));
			}
		}
		map.fitBounds(bounds);

    }



	
	onShowRouteMap(rownode){
		console.log("rownode", rownode);
		if (googleAnalytics.page.enableGA) {
			let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		
		if(rownode != 0)
		{
			// if(typeof rownode.msil_gate_out == 'undefined')
			// {
			// 	if(typeof rownode.msil_gate_in == 'undefined')
			// 	{
			// 		var edate = moment.parseZone(rownode.msil_gate_in).add(10,'days').format("YYYY-MM-DD HH:mm:ss");
			// 	}
			// 	else{
			// 		var edate = getHyphenYYYYMMDDHHMMSS(rownode.msil_gate_in)
			// 	}
			// }
			// else{
			// 	var edate = getHyphenYYYYMMDDHHMMSS(rownode.msil_gate_out)
			// }
			var cdatae =new Date();
			var ed = moment.parseZone(cdatae).utcOffset("+05:30").format("YYYY-MM-DD HH:mm:ss")
			
			var edate = ed
			var sdate = getHyphenYYYYMMDDHHMMSS(rownode.dispatch_date)
			var params = { 
				//container_no:"FSCU9842333"
				truck_no:rownode.trailer_no,
				start_date:sdate,
				end_date:edate,
			}
			this.setState({
				loadshow:'show-m'
			})
			 redirectURL.post('/imports/exportssearates',params)
			  .then((response) => {
			  
			   var records = JSON.parse(response.data.details);
			   var stautus = response.data.status;
				console.log("Here ",records);
				if(stautus == 'Failure' || records.status == 'failure')
				{
					this.setState({
						show: true, 
						basicType:'danger', 
						basicTitle:"No data found",
							
					});
				}
				else{
					console.log(records);
					this.setState({
						mapinfo:records,
						sliderRouteTranslate:"slider-translate-75p",
						consignment_code:rownode.trailer_no,
						maptruckno:rownode.trailer_no,
						routeTruck:	{"truck_no":rownode.trailer_no,"startTime":sdate,"endTime": edate},
						show: false,
						overly:'show-m',
						tolls : records.tolls
					});
					//this.renderMap();
					
				}
				this.setState({
					loadshow:'show-n',
				})
			  /* this.setState({
					mapinfo:response.data,
					sliderRouteTranslate:"slider-translate-50p",
					consignment_code:rownode.truck_no
			   });
				   this.renderMap();
			
				*/
			  })
			  .catch(function (error) {
				console.log(error);
			  });
			  
		}
		
	}
	onCloseRouteDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderRouteTranslate:"",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
			overly:'show-n'
		});
	}
	closeAlert = () => {
        this.setState({
			show: false,
			overly:'show-n'
        });
	}
	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderRouteTranslate:'',
			tabsliderTranslate:''
		});
		
	}
	compare_dates = (date1,date2) => {
		if (date1>date2) return 1; 
	 }

	 logCounterClickGA(params) {
		if (googleAnalytics.page.enableGA) {
			let statusText = this.getStatusText(params);
			let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventCounterAction,
				"label": statusText,
			}
			googleAnalytics.logEvent(eventOptions);
		}
	}

	onClickCounterShowData(params){
		console.log("Counter Params Here ", params);
		this.logCounterClickGA(params); // Log Counter Actions for Google Analytics
		let selectedFilterData = null;
		console.log(params);
		switch(params) {
			case 1:
				selectedFilterData = this.state.coilInfoReceivedData;
				
				//console.log("Case 1 params,", params, selectedFilterData);
				break;
			case 2:
				selectedFilterData = this.state.arrivedAtIndianPortData;
				break;
			case 3:
				selectedFilterData = this.state.inTransitToIcdData;
				break;
			case 4:
				selectedFilterData = this.state.insideIcdData;
				break;
			case 5:
				selectedFilterData = this.state.inTransitToMsilData;
				break;
			case 6:
				selectedFilterData = this.state.insideMsilData;
				break;
			case 7:
				selectedFilterData = this.state.msiltovendor;
				break;
			default:
				selectedFilterData = this.state.allRowData;
				break;
		}
		this.setState({
			rowData: selectedFilterData
		});

	}

	getPodListItems = pod =>{
        this.setState(
	      { pod },
	      () => console.log(this.state.pod)
        );
        
        this.setState({
			errorMessage:'',
			successMessage:''
        })
	}


	selectPodOptionsItems(){
        let items = [];     
		//items.push({value:'all',label:'All'}); 
		this.state.podlist.forEach(item =>{
			//console.log("pod ",item)
			items.push({value:item,label:item}); 
		});
        
		items.push({value:"DEENDAYAL",label:"DEENDAYAL"}); 
        return items;
	}

	getCriteriaListItems = criteria =>{
        this.setState(
	      { criteria },
	      () => console.log(this.state.criteria)
        );
        
        this.setState({
			errorMessage:'',
			successMessage:''
        })
	}


	selectCriteriaOptionsItems(){
        let items = [
			//{value:'all',label:'All'},
			{value:'bill_of_lading_date',label:'Bill Of Lading Date'},
			{value:'invoice_date',label:'Invoice Date'},
			{value:'ata_date',label:'ATA Date'},
			{value:'atb_date',label:'ATB Date'},
			{value:'coc_date',label:'COC Date'},
			{value:'eta_date',label:'ETA Date'}
		];      
		
        
        return items;
	}
	
	
	handlerStartDateTime = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
    	var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    	
    	this.setState({
    		startDate:startdate
    	});
    	//let value = event.target.value;
        //this.setState({'startDate':value});
    }

    handlerEndDateTime  = (event) => {
    	var d = new Date(event._d);
    	
        var edate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate())
        this.setState({
    		endDate:edate
    	});
	}

	onClickGetData = (event) =>{
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Filters",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		var eror=0;
		if(this.state.criteria != null && this.state.criteria != "")
		{
			this.setState({
				errStyCriteria:''
			});
			var criteria = this.state.criteria
			if(this.state.startDate == '')
			{
				this.setState({
					errStyStartDate:'errorStyles',
					show: true, 
					basicType:'danger', 
					basicTitle:"From Date should not be null",
				});
				
				eror=1;
			}
			else{
				this.setState({
					errStyStartDate:''
				});
			}

			if(this.state.endDate == '')
			{
				this.setState({
					errStyEndDate:'errorStyles',
					show: true, 
					basicType:'danger', 
					basicTitle:"To Date should not be null",
				});
				eror=1;
				
			}
			else{
				this.setState({
					errStyEndDate:''
				});
			}
			
			

		}
		// else{
		// 	this.setState({
		// 		errStyCriteria:'errorStyles',
		// 		show: true, 
		// 		basicType:'danger', 
		// 		basicTitle:"Criteria should not be null",
		// 	});
		// 	eror=1;
		// 	//var criteria = {value:"all", label:"All"}
		// }

		if(this.state.pod == null || this.state.pod == "")
		{
			// this.setState({
			// 	errStyPod:'errorStyles',
			// 	show: true, 
			// 	basicType:'danger', 
			// 	basicTitle:"POD should not be null",
			// });
			// eror=1;
			var pod = [{value:"all", label:"All"}]
		}
		else{
			this.setState({
				errStyPod:''
			});
			var pod = this.state.pod
		}
		
		// if(this.state.startDate == '')
		// {
		// 	this.setState({
		// 		errStyStartDate:'errorStyles',
		// 		show: true, 
		// 		basicType:'danger', 
		// 		basicTitle:"From Date should not be null",
		// 	});
			
		// 	eror=1;
		// }
		// else{
		// 	this.setState({
		// 		errStyStartDate:''
		// 	});
		// }

		// if(this.state.endDate == '')
		// {
		// 	this.setState({
		// 		errStyEndDate:'errorStyles',
		// 		show: true, 
		// 		basicType:'danger', 
		// 		basicTitle:"To Date should not be null",
		// 	});
		// 	eror=1;
			
		// }
		// else{
		// 	this.setState({
		// 		errStyEndDate:''
		// 	});
		// }

		if(this.state.startDate != '' || this.state.endDate != '')
		{
			if(this.state.criteria == null || this.state.criteria == "")
			{
				this.setState({
					errStyCriteria:'errorStyles',
					show: true, 
					basicType:'danger', 
					basicTitle:"Criteria should not be null",
				});
				eror=1;
			}
		}

		if(eror == 0)
		{
			var fdata ={
				pod:pod,
				criteria:criteria,
				startDate:this.state.startDate,
				endDate:this.state.endDate,
				page:"filter"
	
			}
			console.log(fdata);
			redirectURL.post("/importscoils/coilsfilter",fdata)
			.then((response) =>{
				//console.log("Response ", response.data)
				var recordarr = response.data;
				var records=[];
				let uniqueObject = {}; 
				var objTitle;
				for (let i in recordarr) { 
					
					if(recordarr[i]["nyk_bl_no"])
					{
						objTitle = recordarr[i]['nyk_bl_no']; 
						uniqueObject[objTitle]= recordarr[i];
					}
					else
					{
						objTitle = recordarr[i]['bill_of_lading']; 
						uniqueObject[objTitle]= recordarr[i];
					}
				} 
				console.log("uniqueObject ", uniqueObject);
				for (var a in uniqueObject) { 
					records.push(uniqueObject[a]); 
				}

				// console.log("Records = ", records);
				recordarr.map(rec => {

					// if (rec.icd_source) {
					// 	rec.icd_source = rec.icd_source.toUpperCase();
					// }  
					if (rec.port_of_discharge) {
						rec.port_of_discharge = rec.port_of_discharge.toUpperCase();
					}  
					if (rec.status) {
						rec.statusText = this.getStatusText(rec.status);
						//console.log("IN  rec.statusText = ",rec.statusText);
					}  
				})
				//console.log("IN EXPORTS , records = ", records, records.length);
				this.setState({
					rowData:recordarr,
					allRowData:recordarr,
				});

				// calculate counters
				let totalCoilsCount  = 0;

				let coilInfoReceivedData  = null;
				let coilInfoReceivedCount  = 0;

				let arrivedAtIndianPortData  = null;
				let arrivedAtIndianPortCount  = 0;

				let inTransitToIcdData  = null;
				let inTransitToIcdCount  = 0;

				let insideIcdData  = null;
				let insideIcdCount  = 0;

				let inTransitToMsilData  = null;
				let inTransitToMsilCount  = 0;

				let insideMsilData  = null;
				let insideMsilCount  = 0;

				totalCoilsCount = (records) ? records.length : 0;

				let coilInfoReceivedDatam = records.filter(rec => rec.status == 1);
				//Appending Data from "nykvoyages" collection rows to importscoils collection
				let coilInfoReceivedData1 = records.filter(rec => rec.nyk_bl_no);
				
				coilInfoReceivedData = coilInfoReceivedDatam.concat(coilInfoReceivedData1);
				coilInfoReceivedCount = (coilInfoReceivedData) ? coilInfoReceivedData.length : 0;
				
				arrivedAtIndianPortData = records.filter(rec => rec.status == 2);
				arrivedAtIndianPortCount = (arrivedAtIndianPortData) ? arrivedAtIndianPortData.length : 0;
				
				inTransitToIcdData = records.filter(rec => rec.status == 3);
				inTransitToIcdCount = (inTransitToIcdData) ? inTransitToIcdData.length : 0;
				
				insideIcdData = records.filter(rec => rec.status == 4);
				insideIcdCount = (insideIcdData) ? insideIcdData.length : 0;
				
				inTransitToMsilData = records.filter(rec => rec.status == 5);
				inTransitToMsilCount = (inTransitToMsilData) ? inTransitToMsilData.length : 0;

				insideMsilData = records.filter(rec => rec.status == 7);
				insideMsilCount = (insideMsilData) ? insideMsilData.length : 0;




				//console.log("inTransitToMsilData = ", inTransitToMsilData, inTransitToMsilCount);

				this.setState({
					totalCoilsCount: totalCoilsCount,

					coilInfoReceivedData: coilInfoReceivedData,
					coilInfoReceivedCount: coilInfoReceivedCount,

					arrivedAtIndianPortData: arrivedAtIndianPortData,
					arrivedAtIndianPortCount: arrivedAtIndianPortCount,
		
					inTransitToIcdData: inTransitToIcdData,
					inTransitToIcdCount: inTransitToIcdCount,
		
					insideIcdData: insideIcdData,
					insideIcdCount: insideIcdCount,
		
					inTransitToMsilData: inTransitToMsilData,
					inTransitToMsilCount: inTransitToMsilCount,

					insideMsilData: insideMsilData,
					insideMsilCount: insideMsilCount,
				});


			})
			.catch(function(e){
				console.log("Error ",e)
			})
		}
		else{
			this.setState({
				show: true, 
				basicType:'danger', 
				basicTitle:"Fields are mandatory",
			})
		}
		
	}

	/*Force close and Critical buttons*/

	onClickForceCloseConsignment(params){
		//console.log("Force Closed ",params.data.inland_destuffing_date)
		if(params.data.inland_destuffing_date == '' || params.data.inland_destuffing_date == undefined)
		{
			var destuffingDate = '';
			var disable = false;
		}
		else{
			var destuffingDate = getYYYYMMDD(params.data.inland_destuffing_date);
			var disable = true;
		}

		this.setState({
			open:true,
			empty_return_date:destuffingDate,
			forcedata:params.data,
			emptydisable:disable

		});
	}

	onOpenModal = () => {
		this.setState({ open: true });
	 };
	
	 onCloseModal = () => {
	   this.setState({ open: false });
	 };

	 onCloseCriticalModal = () => {
		this.setState({ opencritical: false });
	  };
 
	 changeHandler(event){
        let name = event.target.name;
        let value = event.target.value;
        this.setState({
            [name]:value
        });
	}
	
    handlerCriticalMSILDateTime = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
    	//var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    	var startdate = moment(d).format("YYYY-MM-DD HH:mm:ss");
		var startdateseconds = moment(d).format("x");
		//console.log("startdate ", startdate);
		//console.log("startdateseconds ", startdateseconds);
    	this.setState({
    		critical_msil_eta:startdate
		});
		
	}
	handlerGateinDateTime = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
    	//var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    	var startdate = moment(d).format("YYYY-MM-DD HH:mm:ss");
		var startdateseconds = moment(d).format("x");
		//console.log("startdate ", startdate);
		//console.log("startdateseconds ", startdateseconds);
    	this.setState({
    		msil_gatein_date:startdate
		});
		if(this.state.msil_gateout_date != '')
		{
			var getout = moment(this.state.msil_gateout_date).format("x");
			if(getout > startdateseconds)
			{

			}
			else{
				this.setState({
					show: true,
					basicTitle:"MSIL Gateout Date should be greater than MSIL Gatein Date",
					basicType:"danger",
					msil_gateout_date:'',
					msil_gatein_date:''
				});
			}
		}
    	//let value = event.target.value;
        //this.setState({'startDate':value});
    }

    handlerGateoutDateTime = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
    	//var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
		var startdate = moment(d).format("YYYY-MM-DD HH:mm:ss");
		var startdateseconds = moment(d).format("x");
		//console.log("startdate ", startdate);
		//console.log("startdateseconds ", startdateseconds);
    	this.setState({
    		msil_gateout_date:startdate
		});
		var forcedata = this.state.forcedata;
		if(forcedata.inland_destuffing_date != '')
		{
			if(this.state.empty_return_date != '')
			{
				var getin = moment(this.state.empty_return_date).format("x");
				if(getin >= startdateseconds)
				{

				}
				else{
					this.setState({
						show: true,
						basicTitle:"MSIL Gateout Date should be less than or equals to Emapty Return Date",
						basicType:"danger",
						msil_gateout_date:'',
						msil_gatein_date:''
					});
				}
			}
		}
		if(this.state.msil_gatein_date != '')
		{
			var getin = moment(this.state.msil_gatein_date).format("x");
			if(startdateseconds > getin)
			{

			}
			else{
				this.setState({
					show: true,
					basicTitle:"MSIL Gateout Date should be greater than MSIL Gatein Date",
					basicType:"danger",
					msil_gateout_date:'',
					msil_gatein_date:''
				});
			}
		}
    	//let value = event.target.value;
        //this.setState({'startDate':value});
    }
    handlerEmptyReturnon = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
    	//var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    	
		var startdate = moment(d).format("YYYY-MM-DD HH:mm:ss");
		var startdateseconds = moment(d).format("x");
		//console.log("startdate ", startdate);
		//console.log("startdateseconds ", startdateseconds);
    	this.setState({
    		empty_return_date:startdate
		});
		var forcedata = this.state.forcedata;
		
		if(this.state.msil_gateout_date != '')
		{
			var getout = moment(this.state.msil_gateout_date).format("x");
			if(startdateseconds >= getout)
			{

			}
			else{
				this.setState({
					show: true,
					basicTitle:"Empty Return Date should be greater than MSIL Gateout Date",
					basicType:"danger",
					msil_gateout_date:''
				});
			}
		}


    	//let value = event.target.value;
        //this.setState({'startDate':value});
	}
	formHandler(event){
		event.preventDefault();
		var forcedata = this.state.forcedata;
		var fdata = {
			coil_no:forcedata.coil_no,
			msil_gatein_date:this.state.msil_gatein_date,
			msil_gateout_date:this.state.msil_gateout_date,
			empty_return_date:this.state.empty_return_date,
			forceclose_remarks:this.state.forceclose_remarks,
			userId : localStorage.getItem('userid'),
			forcecloseflag:1

		}
		//console.log("fdata ",fdata)
		if(fdata.forceclose_remarks.replace(/\s/g,"").length)
		{
			redirectURL.post("importscoils/storeForceCloseData", fdata)
			.then((response) => {
				this.setState({
					show: true,
					basicTitle:"Success",
					basicType:"success",
					msil_gatein_date:'',
					msil_gateout_date:'',
					empty_return_date:'',
					forceclose_remarks:'',
					open:false,
					// overly:"show-n",
				});
			})
			.catch(function(e){
				console.log("Error ",e)
			});
		}
		else{
			this.setState({
				show: true,
				basicTitle:"Remarks Cant be Empty",
				basicType:"warning",
				
				// overly:"show-n",
			});
		}
		

		//console.log("Form data is ", fdata)
	}

	formCriticalHandler(event){
		event.preventDefault();
		var forcedata = this.state.criticalrowdata;
		var fdata = {
			coil_no:forcedata.coil_no,
			critical_msil_eta:this.state.critical_msil_eta,
			userId : localStorage.getItem('userid'),
			
		}
		//console.log("fdata ",fdata)
		redirectURL.post("importscoils/storeCriticalCloseData", fdata)
		.then((response) => {
			this.setState({
				show: true,
				basicTitle:"Success",
				basicType:"success",
				msil_gatein_date:'',
				msil_gateout_date:'',
				empty_return_date:'',
				forceclose_remarks:'',
				opencritical:false
			});
		})
		.catch(function(e){
			console.log("Error ",e)
		});

		//console.log("Form data is ", fdata)
	}

	onClickCriticalConsignment(params){
		console.log("Critical Closed ",params.data)
		this.setState({
			opencritical:true,
			criticalrowdata:params.data
		});
	}

	onClickSaveGridState(){
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Save Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();
		
		let screenpage = 'imports coils';

		let reqparams = {
			gridcolumns:window.colState,
			gridgroup:window.groupState,
			gridcolsort:window.sortState,
			gridcolfilter:window.filterState,
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:screenpage
		}
		//console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
		.then((response) => {
			//console.log("State response ",response.data)
			this.setState({
				show:true,
				basicTitle:"Successfully saved grid layout",
				basicType:"success",
				screenurl:window.location.pathname,
				screentitle:screenpage
			})
		})
		.catch(function(e){
			console.log("Error ", e)
		})
	}

	restoreGridStates()
	{
		try{


			if(this.state.usergridstate.length > 0)
			{
				var windowstates = this.state.usergridstate;
				this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
				this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
				this.gridApi.setSortModel(windowstates[0].gridcolsort);
				this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
			
			}
		}
		catch(e){}
	}

	resetState = () => {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Reset Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:this.state.screenpage
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
		.then((response) => {
			this.setState({
				show:true,
				basicTitle:"Successfully reset default grid layout",
				basicType:"success"
			})
		})

	};


	render(){
		const modalStyles  = {
			width:'500px !important',
			}
		const { open } = this.state;
		const { opencritical } = this.state;
		const columnwithDefs = [
			
			{
				headerName: "",
				headerClass:["cellstylegridW","cellTopHeader"],			
				children: [
					{
						headerName: "",
						//headerClass:"cellstylegrid",
						field: "status",
						width: 40,
						pinned: 'left',
						
						headerClass:"cellstylegridW",
						cellClass:'cellstylegridW',
						cellRendererSelector:function(params){
							if(params.data.hasOwnProperty('status'))
							{
								if (params.data.status >= 5)
								{
									return { component: 'consignmentActions'}
								}
								else
								{
									return { component: 'consignmentNoActions'}	
								}
							}
							else{
								return { component: 'consignmentNoActions'}
							}
						},
						filter: true,resizable: true,
						// cellStyle:function(params){
						// 	return {background:"rgb(241, 251, 255)",color:"#333"}
						// }
						
						//cellClass: function(params) { return 'cellStyleGrid'; }
					},
					{
						headerName: "",
						//headerClass:"cellstylegrid",
						field: "high_seas",
						width: 40,
						pinned: 'left',
						cellRendererSelector:function(params){
							//console.log("params Highsees ", params.data)
							if(params.data.nyk_ship_name != undefined || params.data.nyk_ship_name != null)
							{
								return { component: 'importsNykCoilInfoReceivedAction'};
							}
							else{
								return { component: 'importsCoilInfoReceivedAction'};
							}
							
						},
						filter: true,resizable: true,
						headerClass:"cellstylegridW",
						cellClass:'cellstylegridW',
						// cellStyle:function(params){
						// 	return {background:"rgb(241, 251, 255)",color:"#333"}
						// }
					},
					{
						headerName: "",
						//headerClass:"cellstylegrid",
						field: "arrived_at_indian_port",
						width: 40,
						pinned: 'left',
						headerClass:"cellstylegridW",
						cellClass:'cellstylegridW',
						cellRendererSelector:function(params){
							return { component: 'importsArrivedAtIndianPortAction'};
						},
						filter: true,resizable: true,
						// cellStyle:function(params){
						// 	return {background:"rgb(241, 251, 255)",color:"#333"}
						// }
					},
					{
						headerName: "",
						//headerClass:"cellstylegrid",
						field: "in_transit_to_icd",
						width: 40,
						pinned: 'left',
						headerClass:"cellstylegridW",
						cellClass:'cellstylegridW',
						cellRendererSelector:function(params){
							return { component: 'importsInTransitToIcdAction'};
						},
						filter: true,resizable: true,
						// cellStyle:function(params){
						// 	return {background:"rgb(241, 251, 255)",color:"#333"}
						// }
					},
					{
						headerName: "",
						//headerClass:"cellstylegrid",
						field: "inside_icd",
						width: 40,
						pinned: 'left',
						headerClass:"cellstylegridW",
						cellClass:'cellstylegridW',
						cellRendererSelector:function(params){
							return { component: 'importsInsideIcdAction'};
						},
						filter: true,resizable: true,
						// cellStyle:function(params){
						// 	return {background:"rgb(241, 251, 255)",color:"#333"}
						// }
					},
					{
						headerName: "",
						//headerClass:"cellstylegrid",
						field: "in_transit_to_msil",
						width: 40,
						pinned: 'left',
						headerClass:"cellstylegridW",
						cellClass:'cellstylegridW',
						cellRendererSelector:function(params){
							return { component: 'importsInTransitToMsilAction'};
						},
						filter: true,resizable: true,
						// cellStyle:function(params){
						// 	return {background:"rgb(241, 251, 255)",color:"#333"}
						// }
					},
					{
						headerName: "",
						//headerClass:"cellstylegrid",
						field: "inside_msil",
						width: 40,
						pinned: 'left',
						headerClass:"cellstylegridW",
						cellClass:'cellstylegridW',
						cellRendererSelector:function(params){
						
							return { component: 'importsInsideMsilAction'};
							
						},
						
						filter: true,resizable: true,
						// cellStyle:function(params){
						// 	return {background:"rgb(241, 251, 255)",color:"#333"}
						// }
					},

					{ 
						headerName: "Bill of lading",
						//headerClass:"cellstylegrid",
						field: "bill_of_lading",
						width: 120,
						editable:false,
						pinned: 'left',
						filter: "agSetColumnFilter",resizable: true,
						headerClass:"cellstylegridW",
						cellClass:'cellstylegridW',
						valueGetter:function(params){
							if(params.data.nyk_bl_no)
							{
								return params.data.nyk_bl_no;
							}
							else
							{
								return params.data.bill_of_lading;
							}
						}
					},
					{
						headerName: "Coil No",
						//headerClass:"cellstylegrid",
						field: "coil_no",
						width: 120,
						pinned: 'left',
						editable:false,
						headerClass:"cellstylegridW",
						cellClass:'cellstylegridW',
						filter: "agSetColumnFilter",resizable: true,
						//cellClass:'cellstylegrid'
					},
					{
						headerName: "ETA/ATA POD",
						field: "eta_date",
						width: 180,
						pinned: 'left',
						editable:false,
						headerClass:"cellstylegridW",
						cellClass:'cellstylegridW',
						filter: "agSetColumnFilter",resizable: true,
						valueGetter:function(params){
							if(params.data.nyk_bl_no)
							{
								if(params.data.ata_pod)
								{
									return getHyphenDDMMMYYYYHHMM(params.data.ata_pod);
								}
								else{
									return getHyphenDDMMMYYYYHHMM(params.data.eta_pod);
								}
								
							}
							else
							{
								if(params.data.ata_date)
								{
									return getHyphenDDMMMYYYYHHMM(params.data.ata_date);
								}
								else
								{
									return getHyphenDDMMMYYYYHHMM(params.data.eta_date);
								}
							}
						},
						// filterParams: {
						// 	browserDatePicker: true,
						// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
						// 	// provide comparator function
						// 	comparator: function(filteredDate,cellValue,secondDate) {
						// 	  cellValue = cellValue.replace(/\//g,"-")
						// 		if(cellValue != "" && cellValue != " "){
						// 			cellValue = cellValue.split(" ")[0].split("-");
						// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
						// 			var cellDate = new Date(cellValue);
						// 			if(filteredDate.getMonth() < 10){
						// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
						// 			}
						// 			else{
						// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
						// 			}
						// 			if(filterValue.split("-")[2].length < 2)
						// 			{
						// 				var temp = filterValue
						// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
						// 			}
						// 			var filteredDate = new Date(filterValue);
						// 			cellDate = cellDate.getTime();
						// 			filteredDate = filteredDate.getTime();
						// 			// console.log(cellDate,filteredDate);
						// 			if(cellDate === filteredDate)
						// 			{
						// 				return 0;
						// 			}
						// 			if(cellDate < filteredDate){
						// 				return -1;
						// 			}
						// 			if(cellDate > filteredDate)
						// 			{
						// 				return 1;
						// 			}
						// 		}
						// 	}
						// },
					},
					{
						headerName: "Status",
						//headerClass:"cellstylegrid",
						field: "statusText",
						width: 120,
						pinned: 'left',
						editable:false,
						headerClass:"cellstylegridW",
						cellClass:'cellstylegridW',
						filter: "agSetColumnFilter",resizable: true,
						valueGetter:function(params){
							if(params.data.nyk_bl_no)
							{
								return params.data.last_status;
							}
							else
							{
								return params.data.statusText;
							}
						}
						//cellClass:'cellstylegrid'
					},

					{
						headerName: "Port of loading",
						//headerClass:"cellstylegrid",
						field: "nyk_pol",
						width: 150,
						editable:false,
						headerClass:"cellstylegridW",
						cellClass:'cellstylegridW',
						filter: "agSetColumnFilter",resizable: true,
						//cellClass:'cellstylegrid'
					},
					{
						headerName: "Bill of lading date",
						field: "bill_of_lading_date",
						width: 150,
						editable:false,
						// 
						filter : true,
						resizable: true,
						headerClass:"cellstylegridW",
						cellClass:'cellstylegridW',
						valueGetter:function(params){
							//console.log("params ", params);
							if(params.data.nyk_bl_date != undefined || params.data.nyk_bl_date != null)
							{
								var splitdt = params.data.nyk_bl_date.split("/");
								//console.log("splitdt ",splitdt);
								if(splitdt[2] == "20")
								{
									var date = "2020-"+splitdt[1]+"-"+splitdt[0];
								}
								else
								{
									var date = params.data.nyk_bl_date
								}
								//console.log("params.data.nyk_bl_date ", date)
								return getHyphenDDMMMYYYY(new Date(date));
							}
							else
							{
								if (params.data.bill_of_lading_date != null)
								{
									return getHyphenDDMMMYYYY(params.data.bill_of_lading_date);
									//return params.data.msil_initial_transit_eta;
								}
								else
								{
									return "NA"
								}
							}

						},
						comparator: dateComparator1,
						// filterParams: {
						// 	browserDatePicker: true,
						// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
						// 	// provide comparator function
						// 	comparator: function(filteredDate,cellValue,secondDate) {
						// 		cellValue = cellValue.replace(/\//g,"-")
						// 		if(cellValue != "" && cellValue != " "){
						// 			cellValue = cellValue.split(" ")[0].split("-");
						// 			cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
						// 			var cellDate = new Date(cellValue);
						// 			if(filteredDate.getMonth() < 10){
						// 			var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
						// 			}
						// 			else{
						// 				var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
						// 			}
						// 			var filteredDate = new Date(filterValue);
						// 			cellDate = cellDate.getTime();
						// 			filteredDate = filteredDate.getTime();
						// 			// console.log(cellDate,filteredDate);
						// 			// console.log(cellValue,filterValue);
						// 			if(cellDate === filteredDate)
						// 			{
						// 				return 0;
						// 			}
						// 			if(cellDate < filteredDate){
						// 				return -1;
						// 			}
						// 			if(cellDate > filteredDate)
						// 			{
						// 				return 1;
						// 			}
						// 		}
						// 	}
						// },
							
					},
					{
						headerName: "Ship Name",
						field: "ship_name",
						width: 120,
						editable:false,
						headerClass:"cellstylegridW",
						cellClass:'cellstylegridW',
						filter: "agSetColumnFilter",resizable: true,
						valueGetter:function(params){
							if(params.data.nyk_ship_name)
							{
								return params.data.nyk_ship_name;
							}
							else
							{
								return params.data.ship_name;
							}
						}
					},
					{
						headerName: "Ship Number",
						field: "ship_no",
						width: 150,
						editable:false,
						headerClass:"cellstylegridW",
						cellClass:'cellstylegridW',
						filter: "agSetColumnFilter",resizable: true,
					},
					{
						headerName: "Voyage Number",
						field: "voyage_no",
						width: 150,
						editable:false,
						headerClass:"cellstylegridW",
						cellClass:'cellstylegridW',
						filter: "agSetColumnFilter",resizable: true,
						valueGetter:function(params){
							if(params.data.nyk_voyage_no)
							{
								return params.data.nyk_voyage_no;
							}
							else
							{
								return params.data.voyage_no;
							}
						}
					}
				]
			},
			{
				headerName: "Coil Info",
				headerClass:["cellstylegridB","cellTopHeader"],			
				children: [
					

					{
						headerName: "Cargo Description",
						field: "cargo_description",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						headerClass:"cellstylegridB",
						cellClass:'cellstylegridB'
					},
					{
						headerName: "Case Number",
						field: "case_no",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						headerClass:"cellstylegridB",
						cellClass:'cellstylegridB'
					},
					{
						headerName: "Coil Description",
						//headerClass:"cellstylegrid",
						field: "coil_desc",
						width: 120,
						editable:false,
						filter: "agSetColumnFilter",
						resizable: true,
						headerClass:"cellstylegridB",
						cellClass:'cellstylegridB'
					},
					{
						headerName: "Coil Gross Weight",
						//headerClass:"cellstylegrid",
						field: "coil_gross_weight",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						headerClass:"cellstylegridB",
						cellClass:'cellstylegridB'
					},
					{
						headerName: "Coil Net Weight",
						//headerClass:"cellstylegrid",
						field: "coil_net_weight",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						headerClass:"cellstylegridB",
						cellClass:'cellstylegridB'
					},
					{
						headerName: "Coil Size",
						field: "coil_size",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						headerClass:"cellstylegridB",
						cellClass:'cellstylegridB'
					},
					{
						headerName: "Coil Size 1",
						field: "coil_size1",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						headerClass:"cellstylegridB",
						cellClass:'cellstylegridB'
					},
					{
						headerName: "IMID Item Code",
						field: "imid_item_code",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						headerClass:"cellstylegridB",
						cellClass:'cellstylegridB'
					},
					{
						headerName: "IMPI Number",
						field: "impi_no",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						headerClass:"cellstylegridB",
						cellClass:'cellstylegridB'
					},
					{
						headerName: "Invoice Date",
						field: "invoice_date",
						width: 150,
						editable:false,
						resizable: true,
						headerClass:"cellstylegridB",
						cellClass:'cellstylegridB',
						valueGetter:function(params){
							//console.log("params ", params);
							if (params.data.invoice_date != null)
							{
								return getHyphenDDMMMYYYY(params.data.invoice_date);
								//return params.data.msil_initial_transit_eta;
							}
							else
							{
								return "NA"
							}

						},
						
						comparator: dateComparator1,
						// filterParams: {
						// 	browserDatePicker: true,
						// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
						// 	// provide comparator function
						// 	comparator: function(filteredDate,cellValue,secondDate) {
						// 		cellValue = cellValue.replace(/\//g,"-")
						// 		if(cellValue != "" && cellValue != " "){
						// 			cellValue = cellValue.split(" ")[0].split("-");
						// 			cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
						// 			var cellDate = new Date(cellValue);
						// 			if(filteredDate.getMonth() < 10){
						// 			var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
						// 			}
						// 			else{
						// 				var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
						// 			}
						// 			var filteredDate = new Date(filterValue);
						// 			cellDate = cellDate.getTime();
						// 			filteredDate = filteredDate.getTime();
						// 			// console.log(cellDate,filteredDate);
						// 			// console.log(cellValue,filterValue);
						// 			if(cellDate === filteredDate)
						// 			{
						// 				return 0;
						// 			}
						// 			if(cellDate < filteredDate){
						// 				return -1;
						// 			}
						// 			if(cellDate > filteredDate)
						// 			{
						// 				return 1;
						// 			}
						// 		}
						// 	}
						// },
						
					},
					// {
					// 	headerName: "MSIL Gate In",
					// 	field: "msil_gate_in",
					// 	width: 150,
					// 	editable:false,
					// 	filter: "agSetColumnFilter",resizable: true,
					// 	valueGetter:function(params){
					// 		return getDDMMYYYY(params.data.msil_gate_in);
					// 	}
					// },
					// {
					// 	headerName: "MSIL Gate Out",
					// 	field: "msil_gate_out",
					// 	width: 150,
					// 	editable:false,
					// 	filter: "agSetColumnFilter",resizable: true,
					// 	valueGetter:function(params){
					// 		return getDDMMYYYY(params.data.msil_gate_out);
					// 	}
					// },
				]
			},
			{
				headerName: "Indian Port",
				headerClass:["cellstylegridG","cellTopHeader"],			
				children: [
					{
						headerName: "Port of Discharge",
						field: "port_of_discharge",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						headerClass:"cellstylegridG",
						cellClass:'cellstylegridG',
						valueGetter:function(params){
							if(params.data.nyk_pod)
							{
								return params.data.nyk_pod;
							}
							else
							{
								return params.data.port_of_discharge;
							}
						}
					},
					{
						headerName: "Priotity",
						field: "priotity",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						headerClass:"cellstylegridG",
						cellClass:'cellstylegridG'
					},
					
					
					
					{
						headerName: "ATA Date",
						field: "ata_date",
						width: 150,
						editable:false,
						resizable: true,
						headerClass:"cellstylegridG",
						cellClass:'cellstylegridG',
						
						valueGetter:function(params){
							//console.log("params ", params);
							if (params.data.ata_date != null)
							{
								return getHyphenDDMMMYYYYHHMM(params.data.ata_date);
								//return params.data.msil_initial_transit_eta;
							}
							else
							{
								return "NA"
							}

						},
						comparator: dateComparator,
						// filterParams: {
						// 	browserDatePicker: true,
						// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
						// 	// provide comparator function
						// 	comparator: function(filteredDate,cellValue,secondDate) {
						// 		cellValue = cellValue.replace(/\//g,"-")
						// 		if(cellValue != "" && cellValue != " "){
						// 			cellValue = cellValue.split(" ")[0].split("-");
						// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
						// 			var cellDate = new Date(cellValue);
						// 			if(filteredDate.getMonth() < 10){
						// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
						// 			}
						// 			else{
						// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
						// 			}
						// 			var filteredDate = new Date(filterValue);
						// 			cellDate = cellDate.getTime();
						// 			filteredDate = filteredDate.getTime();
						// 			// console.log(cellDate,filteredDate);
						// 			if(cellDate === filteredDate)
						// 			{
						// 				return 0;
						// 			}
						// 			if(cellDate < filteredDate){
						// 				return -1;
						// 			}
						// 			if(cellDate > filteredDate)
						// 			{
						// 				return 1;
						// 			}
						// 		}
						// 	}
						// },
						
					},
					{
						headerName: "ATB Date",
						field: "atb_date",
						width: 150,
						editable:false,
						resizable: true,
						headerClass:"cellstylegridG",
						cellClass:'cellstylegridG',
						
						comparator: dateComparator,
						valueGetter:function(params){
							//console.log("params ", params);
							if (params.data.atb_date != null)
							{
								return getHyphenDDMMMYYYYHHMM(params.data.atb_date);
								//return params.data.msil_initial_transit_eta;
							}
							else
							{
								return "NA"
							}

						},
						// filterParams: {
						// 	browserDatePicker: true,
						// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
						// 	// provide comparator function
						// 	comparator: function(filteredDate,cellValue,secondDate) {
						// 		cellValue = cellValue.replace(/\//g,"-")
						// 		if(cellValue != "" && cellValue != " "){
						// 			cellValue = cellValue.split(" ")[0].split("-");
						// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
						// 			var cellDate = new Date(cellValue);
						// 			if(filteredDate.getMonth() < 10){
						// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
						// 			}
						// 			else{
						// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
						// 			}
						// 			var filteredDate = new Date(filterValue);
						// 			cellDate = cellDate.getTime();
						// 			filteredDate = filteredDate.getTime();
						// 			// console.log(cellDate,filteredDate);
						// 			if(cellDate === filteredDate)
						// 			{
						// 				return 0;
						// 			}
						// 			if(cellDate < filteredDate){
						// 				return -1;
						// 			}
						// 			if(cellDate > filteredDate)
						// 			{
						// 				return 1;
						// 			}
						// 		}
						// 	}
						// },
					},
					{
						headerName: "COC Date",
						field: "coc_date",
						width: 150,
						editable:false,
						resizable: true,
						headerClass:"cellstylegridG",
						cellClass:'cellstylegridG',
						
						valueGetter:function(params){
							//console.log("params ", params);
							if (params.data.coc_date != null)
							{
								return getHyphenDDMMMYYYYHHMM(params.data.coc_date);
								//return params.data.msil_initial_transit_eta;
							}
							else
							{
								return "NA"
							}

						},
						comparator: dateComparator,
						// filterParams: {
						// 	browserDatePicker: true,
						// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
						// 	// provide comparator function
						// 	comparator: function(filteredDate,cellValue,secondDate) {
						// 		cellValue = cellValue.replace(/\//g,"-")
						// 		if(cellValue != "" && cellValue != " "){
						// 			cellValue = cellValue.split(" ")[0].split("-");
						// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
						// 			var cellDate = new Date(cellValue);
						// 			if(filteredDate.getMonth() < 10){
						// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
						// 			}
						// 			else{
						// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
						// 			}
						// 			var filteredDate = new Date(filterValue);
						// 			cellDate = cellDate.getTime();
						// 			filteredDate = filteredDate.getTime();
						// 			// console.log(cellDate,filteredDate);
						// 			if(cellDate === filteredDate)
						// 			{
						// 				return 0;
						// 			}
						// 			if(cellDate < filteredDate){
						// 				return -1;
						// 			}
						// 			if(cellDate > filteredDate)
						// 			{
						// 				return 1;
						// 			}
						// 		}
						// 	}
						// },
					},
					{
						headerName: "ETA Date",
						field: "eta_date",
						width: 150,
						editable:false,
						resizable: true,
						headerClass:"cellstylegridG",
						cellClass:'cellstylegridG',
						
						comparator: dateComparator,
						valueGetter:function(params){
							//console.log("params ", params);
							if (params.data.eta_date != null)
							{
								return getHyphenDDMMMYYYYHHMM(params.data.eta_date);
								//return params.data.msil_initial_transit_eta;
							}
							else
							{
								return "NA"
							}

						},
						// filterParams: {
						// 	browserDatePicker: true,
						// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
						// 	// provide comparator function
						// 	comparator: function(filteredDate,cellValue,secondDate) {
						// 		cellValue = cellValue.replace(/\//g,"-")
						// 		if(cellValue != "" && cellValue != " "){
						// 			cellValue = cellValue.split(" ")[0].split("-");
						// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
						// 			var cellDate = new Date(cellValue);
						// 			if(filteredDate.getMonth() < 10){
						// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
						// 			}
						// 			else{
						// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
						// 			}
						// 			var filteredDate = new Date(filterValue);
						// 			cellDate = cellDate.getTime();
						// 			filteredDate = filteredDate.getTime();
						// 			// console.log(cellDate,filteredDate);
						// 			if(cellDate === filteredDate)
						// 			{
						// 				return 0;
						// 			}
						// 			if(cellDate < filteredDate){
						// 				return -1;
						// 			}
						// 			if(cellDate > filteredDate)
						// 			{
						// 				return 1;
						// 			}
						// 		}
						// 	}
						// },
					},
				]
			},

			{
				headerName: "Indian Port to ICD",
				headerClass:["cellstylegridM","cellTopHeader"],			
				children: [
					{
						headerName: "Departure to ICD",
						field: "departure_to_adani_date",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						headerClass:"cellstylegridM",
						cellClass:'cellstylegridM',
						valueGetter:function(params){
							return getHyphenDDMMMYYYYHHMM(params.data.departure_to_adani_date);
						},
						// filterParams: {
						// 	browserDatePicker: true,
						// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
						// 	// provide comparator function
						// 	comparator: function(filteredDate,cellValue,secondDate) {
						// 	  cellValue = cellValue.replace(/\//g,"-")
						// 		if(cellValue != "" && cellValue != " "){
						// 			cellValue = cellValue.split(" ")[0].split("-");
						// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
						// 			var cellDate = new Date(cellValue);
						// 			if(filteredDate.getMonth() < 10){
						// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
						// 			}
						// 			else{
						// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
						// 			}
						// 			if(filterValue.split("-")[2].length < 2)
						// 			{
						// 				var temp = filterValue
						// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
						// 			}
						// 			var filteredDate = new Date(filterValue);
						// 			cellDate = cellDate.getTime();
						// 			filteredDate = filteredDate.getTime();
						// 			// console.log(cellDate,filteredDate);
						// 			if(cellDate === filteredDate)
						// 			{
						// 				return 0;
						// 			}
						// 			if(cellDate < filteredDate){
						// 				return -1;
						// 			}
						// 			if(cellDate > filteredDate)
						// 			{
						// 				return 1;
						// 			}
						// 		}
						// 	}
						// },
					},
					{
						headerName: "Destination Location",
						field: "destination_loc",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						headerClass:"cellstylegridM",
						cellClass:'cellstylegridM'
					},
					{
						headerName: "Transit Mode",
						field: "transit_mode",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						headerClass:"cellstylegridM",
						cellClass:'cellstylegridM'
					},
					{
						headerName: "Train/Truck No",
						field: "live_voyage_no",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						headerClass:"cellstylegridM",
						cellClass:'cellstylegridM'
					},
					{
						headerName: "ICD Arrival Date",
						field: "adani_arrival_date",
						width: 150,
						editable:false,
						resizable: true,
						valueGetter:function(params){
							return getHyphenDDMMMYYYYHHMM(params.data.adani_arrival_date);
						},
						headerClass:"cellstylegridM",
						cellClass:'cellstylegridM',
						// filterParams: {
						// 	browserDatePicker: true,
						// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
						// 	// provide comparator function
						// 	comparator: function(filteredDate,cellValue,secondDate) {
						// 	  cellValue = cellValue.replace(/\//g,"-")
						// 		if(cellValue != "" && cellValue != " "){
						// 			cellValue = cellValue.split(" ")[0].split("-");
						// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
						// 			var cellDate = new Date(cellValue);
						// 			if(filteredDate.getMonth() < 10){
						// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
						// 			}
						// 			else{
						// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
						// 			}
						// 			if(filterValue.split("-")[2].length < 2)
						// 			{
						// 				var temp = filterValue
						// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
						// 			}
						// 			var filteredDate = new Date(filterValue);
						// 			cellDate = cellDate.getTime();
						// 			filteredDate = filteredDate.getTime();
						// 			// console.log(cellDate,filteredDate);
						// 			if(cellDate === filteredDate)
						// 			{
						// 				return 0;
						// 			}
						// 			if(cellDate < filteredDate){
						// 				return -1;
						// 			}
						// 			if(cellDate > filteredDate)
						// 			{
						// 				return 1;
						// 			}
						// 		}
						// 	}
						// },
					},
					
				]
			},

			{
				headerName: "ICD to MSIL",
				headerClass:["cellstylegridY","cellTopHeader"],			
				children: [
					{
						headerName: "Dispatch to MSIL",
						field: "dispatch_date",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						valueGetter:function(params){
							return getHyphenDDMMMYYYYHHMM(params.data.dispatch_date);
						},
						headerClass:"cellstylegridY",
						cellClass:'cellstylegridY',
						// filterParams: {
						// 	browserDatePicker: true,
						// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
						// 	// provide comparator function
						// 	comparator: function(filteredDate,cellValue,secondDate) {
						// 	  cellValue = cellValue.replace(/\//g,"-")
						// 		if(cellValue != "" && cellValue != " "){
						// 			cellValue = cellValue.split(" ")[0].split("-");
						// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
						// 			var cellDate = new Date(cellValue);
						// 			if(filteredDate.getMonth() < 10){
						// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
						// 			}
						// 			else{
						// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
						// 			}
						// 			if(filterValue.split("-")[2].length < 2)
						// 			{
						// 				var temp = filterValue
						// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
						// 			}
						// 			var filteredDate = new Date(filterValue);
						// 			cellDate = cellDate.getTime();
						// 			filteredDate = filteredDate.getTime();
						// 			// console.log(cellDate,filteredDate);
						// 			if(cellDate === filteredDate)
						// 			{
						// 				return 0;
						// 			}
						// 			if(cellDate < filteredDate){
						// 				return -1;
						// 			}
						// 			if(cellDate > filteredDate)
						// 			{
						// 				return 1;
						// 			}
						// 		}
						// 	}
						// },
					},
					{
						headerName: "Transporter Name",
						field: "transporter_name",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						headerClass:"cellstylegridY",
						cellClass:'cellstylegridY'
					},
					{
						headerName: "Truck No",
						field: "trailer_no",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						headerClass:"cellstylegridY",
						cellClass:'cellstylegridY'
					},
					{
						headerName: "Last GPS Time",
						field: "",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						headerClass:"cellstylegridY",
						cellClass:'cellstylegridY',
						valueGetter : function(params){
							try{
									if(params.data.truck.length > 0){
									return getHyphenDDMMMYYYYHHMM(params.data.truck[0].timestamp);
								}
								else{
									return "";
								}
							}
							catch(e){
								return '';
							}
							
						}
					},
					{
						headerName: "MSIL Plant Name",
						field: "destination_plant",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						headerClass:"cellstylegridY",
						cellClass:'cellstylegridY'
					},
					{
						headerName: "MSIL Gate In",
						field: "msil_gate_in",
						width: 150,
						editable:false,
						resizable: true,
						valueGetter:function(params){
							return getHyphenDDMMMYYYYHHMM(params.data.msil_gate_in);
						},
						headerClass:"cellstylegridY",
						cellClass:'cellstylegridY',
						// filterParams: {
						// 	browserDatePicker: true,
						// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
						// 	// provide comparator function
						// 	comparator: function(filteredDate,cellValue,secondDate) {
						// 	  cellValue = cellValue.replace(/\//g,"-")
						// 		if(cellValue != "" && cellValue != " "){
						// 			cellValue = cellValue.split(" ")[0].split("-");
						// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
						// 			var cellDate = new Date(cellValue);
						// 			if(filteredDate.getMonth() < 10){
						// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
						// 			}
						// 			else{
						// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
						// 			}
						// 			if(filterValue.split("-")[2].length < 2)
						// 			{
						// 				var temp = filterValue
						// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
						// 			}
						// 			var filteredDate = new Date(filterValue);
						// 			cellDate = cellDate.getTime();
						// 			filteredDate = filteredDate.getTime();
						// 			// console.log(cellDate,filteredDate);
						// 			if(cellDate === filteredDate)
						// 			{
						// 				return 0;
						// 			}
						// 			if(cellDate < filteredDate){
						// 				return -1;
						// 			}
						// 			if(cellDate > filteredDate)
						// 			{
						// 				return 1;
						// 			}
						// 		}
						// 	}
						// },
					},
					{
						headerName: "MSIL Gate Out",
						field: "msil_gate_out",
						width: 150,
						editable:false,
						resizable: true,
						headerClass:"cellstylegridY",
						cellClass:'cellstylegridY',
						valueGetter : function(params){
							if(params.data.msil_gate_out != ""){
								return getHyphenDDMMMYYYYHHMM(params.data.msil_gate_out);
							}
							else{
								return "";
							}
						}
					},
					{
						headerName: "SPOC Name",
						field: "spoc_contact_name",
						width: 150,
						editable:false,
						resizable: true,
						headerClass:"cellstylegridY",
						cellClass:'cellstylegridY'
					},
					{
						headerName: "SPOC Contact",
						field: "spoc_contact_no",
						width: 150,
						editable:false,
						resizable: true,
						headerClass:"cellstylegridY",
						cellClass:'cellstylegridY'
					}
				]
			},

			{
				headerName: "MSIL to Vendor",
				headerClass:["cellstylegrid","cellTopHeader"],			
				children: [
					// {
					// 	headerName: "Vendor Name",
					// 	field: "_id",
					// 	width: 150,
					// 	editable:false,
					// 	filter: "agSetColumnFilter",resizable: true,
					// 	valueGetter:function(params){
					// 		return "";
					// 	},
					// 	headerClass:"cellstylegrid",
					// 	cellClass:'cellstylegrid'
					// },
					{
						headerName: "Vendor Code",
						field: "vendor_code",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						headerClass:"cellstylegridG",
						cellClass:'cellstylegridG'
					},
					{
						headerName: "Vendor Gate In",
						field: "_id",
						width: 150,
						editable:false,
						resizable: true,
						valueGetter:function(params){
							return "";
						},
						headerClass:"cellstylegrid",
						cellClass:'cellstylegrid'
					},
					{
						headerName: "Vendor Gate Out",
						field: "_id",
						width: 150,
						editable:false,
						resizable: true,
						valueGetter:function(params){
							return "";
						},
						headerClass:"cellstylegrid",
						cellClass:'cellstylegrid'
					},
					{
						headerName: "MSP Name",
						field: "_id",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						valueGetter:function(params){
							return "";
						},
						headerClass:"cellstylegrid",
						cellClass:'cellstylegrid'
					},
					{
						headerName: "MSP Gate In",
						field: "_id",
						width: 150,
						editable:false,
						resizable: true,
						valueGetter:function(params){
							return "";
						},
						headerClass:"cellstylegrid",
						cellClass:'cellstylegrid'
					},
					{
						headerName: "MSP Gate Out",
						field: "_id",
						width: 150,
						editable:false,
						resizable: true,
						valueGetter:function(params){
							return "";
						},
						headerClass:"cellstylegrid",
						cellClass:'cellstylegrid'
					},

					{
						headerName: "Is Critical",
						field: "is_critical",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						headerClass:"cellstylegridG",
						cellClass:'cellstylegridG',
						valueGetter:function(params){
							if(typeof params.data.is_critical != 'undefined')
							{
								if(params.data.is_critical == 1)
								{
									return "Yes";
								}
								else{
									return "No";
								}
							}
							else
							{
								return "No";
							}
						},
						
					},
					{
						headerName: "Critical MSIL ETA",
						field: "critical_msil_eta",
						width: 150,
						editable:false,
						resizable: true,
						headerClass:"cellstylegridG",
						cellClass:'cellstylegridG',
						valueGetter:function(params){
							if(typeof params.data.critical_msil_eta != 'undefined')
							{
								return getHyphenDDMMMYYYYHHMM(params.data.critical_msil_eta)
							}
							else
							{
								return "";
							}
						},
						// filterParams: {
						// 	browserDatePicker: true,
						// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
						// 	// provide comparator function
						// 	comparator: function(filteredDate,cellValue,secondDate) {
						// 		cellValue = cellValue.replace(/\//g,"-")
						// 		if(cellValue != "" && cellValue != " "){
						// 			cellValue = cellValue.split(" ")[0].split("-");
						// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
						// 			var cellDate = new Date(cellValue);
						// 			if(filteredDate.getMonth() < 10){
						// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
						// 			}
						// 			else{
						// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
						// 			}
						// 			var filteredDate = new Date(filterValue);
						// 			cellDate = cellDate.getTime();
						// 			filteredDate = filteredDate.getTime();
						// 			// console.log(cellDate,filteredDate);
						// 			if(cellDate === filteredDate)
						// 			{
						// 				return 0;
						// 			}
						// 			if(cellDate < filteredDate){
						// 				return -1;
						// 			}
						// 			if(cellDate > filteredDate)
						// 			{
						// 				return 1;
						// 			}
						// 		}
						// 	}
						// },
					},

					{
						headerName: "Force Close",
						field: "_id",
						width: 150,
						editable:false,
						resizable: true,
						headerClass:"cellstylegridG",
						cellClass:'cellstylegridG',
						cellRendererSelector:function(params){
							//console.log("params ", params)
							return { component: 'forceclose'};
							
						},
						
					},
					{
						headerName: "Critical",
						field: "_id",
						width: 150,
						editable:false,
						filter: false,resizable: true,
						headerClass:"cellstylegridG",
						cellClass:'cellstylegridG',
						cellRendererSelector:function(params){
							//console.log("params ", params)
							return { component: 'criticalaction'};
							
						},
						
					}
				]
			}
		];
		const dStyles={
			width:this.props.width,
			height:'500px',
			marginLeft:this.props.marginLeft,

	    }

		return(
			<div className="container-fluid">
				<Breadcrumb parent="Imports" title="Coils" />
			<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
	            {this.state.alert}
				<div className="row col-xl-12 col-lg-12">
					<form method="POST" className="form-theme col-xl-12 col-lg-12 row">
					<div className="form-group col-xl-3 col-lg-3">
						<label>Select POD: </label>
						<Select placeholder={"Select POD"} closeMenuOnSelect={false} value={this.state.pod} isMulti="true" className={"border-radius-0 "+(this.state.errStyPod)} onChange={this.getPodListItems.bind(this)} style={{borderRadius:"0px"}} options={this.selectPodOptionsItems()} />
                                       
					</div>
					<div className="form-group col-xl-2 col-lg-2" style={{borderRight:"1px solid #aaa"}}>
						<label>Select Criteria: </label>
						<Select placeholder={"Select Criteria"} closeMenuOnSelect={false} value={this.state.criteria} className={"border-radius-0 "+(this.state.errStyCriteria)} onChange={this.getCriteriaListItems.bind(this)} style={{borderRadius:"0px"}} options={this.selectCriteriaOptionsItems()} />
                                       
					</div>
					<div className="col-xl-2 col-lg-2 form-group">
						<label>From Date</label>
						<Datetime className={this.state.errStyStartDate} value={this.state.startDate} disableCloseOnClickOutside={false} closeOnSelect={true} inputProps={{ placeholder: 'From', name: 'startDate', autoComplete:'off', required:true }} dateFormat="YYYY-MM-DD"  name="startDate" onChange={this.handlerStartDateTime.bind(this)} />
					</div>

					<div className="col-xl-2 col-lg-2 form-group">
						<label>To Date</label>
						<Datetime className={this.state.errStyEndDate} value={this.state.endDate} disableCloseOnClickOutside={false} closeOnSelect={true} inputProps={{ placeholder: 'To', name: 'endDate', autoComplete:'off', required:true}} dateFormat="YYYY-MM-DD" onChange={this.handlerEndDateTime.bind(this)} />
					</div>
					
					<div className="form-group col-xl-3 col-lg-3">
						<label>&nbsp;</label><br />
						<button type="button" className="btn btn-success" onClick={this.onClickGetData.bind(this)}>Submit</button>
						<a href="/getMonthNumber(getMonthNumber(cellValue[1]))" className="btn btn-primary">Reset</a>
					
					</div>
					</form>
				</div>
				<ImportsCoilsCountersComponent 
					context={this}
					allRowDatacount={this.state.allRowData.length}
					totalCoilsCount={this.state.totalCoilsCount}
					coilInfoReceivedCount={this.state.coilInfoReceivedCount}
					arrivedAtIndianPortCount={this.state.arrivedAtIndianPortCount}
					inTransitToIcdCount={this.state.inTransitToIcdCount}
					insideIcdCount={this.state.insideIcdCount}
					inTransitToMsilCount={this.state.inTransitToMsilCount}
					insideMsilCount={this.state.insideMsilCount}
					msiltovendor={this.state.msiltovendor.length}
					vendortomsp={this.state.vendortomsp}
					
				 />
            	<div className="row">					
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.pagetitle} </span>
									{/* <span className="float-right f13">
										<i className="icofont icofont-ship  f24"></i> - High Seas &nbsp; &nbsp;
										<i className="icofont icofont-ship-alt  f25"></i> - Arrived at Indian Port &nbsp; &nbsp;
										<i className="icofont icofont-truck-alt  f20"></i> - In transit to ICD &nbsp; &nbsp;
										<i className="icofont icofont-ui-home f20"></i> - Inside ICD &nbsp; &nbsp;
										<i className="icofont icofont-van-alt f20"></i> - In transit to MSIL &nbsp; &nbsp;
										<i className="icofont icofont-ui-home f20"></i> - Inside MSIL &nbsp; &nbsp;
										<i className="icofont icofont-truck-alt f20"></i> - MSIL to Vendor &nbsp; &nbsp;
										<i className="icofont icofont-truck-alt f20"></i> - Vendor to MSP &nbsp; &nbsp;
									
									</span> */}
									<span className="layoutbtns float-right">
										<button className="float-right custom-btn white btn-danger" onClick={this.onClickSaveGridState.bind(this)}>
											<i className="icofont icofont-save"></i> Save Grid Layout
										</button>
										<button className="float-right custom-btn white btn-info" style={{marginRight:"10px"}} onClick={this.resetState.bind(this)}>
											<i className="icofont icofont-refresh"></i> Reset Default Layout
										</button>
									</span>




								</h5>
				   			</div>				   			
		            		<div className="card-body">
								
								 <div id="myGrid" style={{ height: "580px",width: "100%"}}  className="ag-theme-balham">
		          		          <AgGridReact
		          		            modules={this.state.modules}
		          		            columnDefs={columnwithDefs}
		          		            defaultColDef={this.state.defaultColDef}
		          		            rowData={this.state.rowData}
		          		            enableCharts={true}
		          		            enableRangeSelection={true}
		          		            autoGroupColumnDef={this.state.autoGroupColumnDef}
		          		            onGridReady={this.onGridReady}
		          		            onGridState={this.onGridState}
		          		            statusBar={this.state.statusBar}
		          	                sideBar={this.state.sideBar}
									paginationPageSize={this.state.paginationPageSize}
									pagination={true}
									frameworkComponents={this.state.frameworkComponents}
									detailCellRendererParams={this.state.detailCellRendererParams}
									//editType={this.state.editType}
									stopEditingWhenGridLosesFocus={true}
									floatingFilter={false}
									enableCellChangeFlash={true}
									suppressCellFlash={true}
									rowClassRules={this.state.rowClassRules}
									//onCellClicked={this.onRowClicked.bind(this)}
									gridOptions={{
										context: { componentParent: this }
									}}
									masterDetail={true}
									headerHeight={this.state.headerHeight}
									//groupHeaderHeight={this.state.groupHeaderHeight}
		          		          />

									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
									</div>

		          		        </div>
				   			</div>
				   		</div>	
				   	</div>
				 </div>
				
				
				 {this.state.sliderRouteTranslate != ''?
					 <div className={"slide-r "+(this.state.sliderRouteTranslate)} style={{overflow:"auto"}}>
					 	<div className="slide-r-title">
							<h4>
								Route #{this.state.consignment_code}
								<span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseRouteDiv.bind(this)} >X</span>
										
							</h4>
						</div>
						<div className="slide-r-body" style={{position:"relative"}}>
							
							<div className="row col-xl-12 col-lg-12">
								{/* <img src={require('../../assets/icons/start_port.png')} className="mapico2" /> - Starting Point &nbsp;
								<img src={require('../../assets/icons/boat.png')} className="mapico1" /> - Port/Change Over location &nbsp; 
								<img src={require('../../assets/icons/crane.png')} className="mapico" /> - Last Known location &nbsp; */}
								
								<DrawMap mapFor={"geofence"} tolls={this.state.tolls} mapinfo={this.state.mapinfo} defTransitCoords={this.state.defTransitCoords} />
									{/*
								<div id="map" className="" style={{width:'100%',height:"90vh",marginTop:"15px"}}></div>
									*/}

								<div className="col-xl-12 col-lg-12 n-p-0">
									<div className="crm-numbers pb-0">
										<h3 className="subH">{this.state.sidebarSubHeader}</h3>
										<div className="col-xl-12 col-lg-12 row">
											<div className="col route-block">
												<label className="sidebar-label">Truck No</label>
												<div>{this.state.routeTruck.truck_no}</div>
											</div>
											
											<div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(this.state.routeTruck.startTime != '')?(this.state.routeTruck.startTime):"NA"}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">End Time</label>
												<div>{(this.state.routeTruck.endTime != '')?this.state.routeTruck.endTime:"NA"}</div>
											</div>
											
											<div className="col route-block">
												<label className="sidebar-label">GPS Distance</label>
												<div>{(this.state.mapinfo.route_details != '')?Math.round(this.state.mapinfo.route_details.distance/1000):"0"} Kms</div>
											</div>
											{(this.state.leg_no == 0 || this.state.leg_no == null)?	""
											:
											<div className="col route-block">
												<label className="sidebar-label">Google Distance</label>
												<div>{(this.state.rownode.actual_google_leg_distance)?parseInt(this.state.rownode.actual_google_leg_distance):"0"} Kms</div>
											</div>
											}
											
											{/*<div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.start_time):"-"}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">End Time</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.end_time):"-"}</div>
											</div>
										*/}
										
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				:""	
				}
				
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            
				
				<Modal open={open} onClose={this.onCloseModal} styles={modalStyles} >
	                <div className={""} style={{padding:'5px 0px', width: '790px', overflow: 'hidden',zIndex:'9999',}} >
						<form className="row col-xl-12 col-lg-12 theme-form" method="post" onSubmit={this.formHandler.bind(this)}>
						
						<div className="col-md-4 col-sm-4 col-xl-4 col-lg-4 ">
							<div className="form-group">
								<label className="col-form-label">MSIL Gatein Date:</label>
								<Datetime 
								//value={this.state.msil_gatein_date} 
								disableCloseOnClickOutside={false} 
								//closeOnSelect={true} 
								inputProps={{ placeholder: 'MSIL Gatein Date', name: 'msil_gatein_date', autoComplete:'off' }}
								dateFormat="YYYY-MM-DD"  
								timeFormat="HH:mm:ss"  
								name="msil_gatein_date" 
								onChange={this.handlerGateinDateTime.bind(this)}
								/>
							</div>
						</div>
						<div className="col-md-4 col-sm-4 col-xl-4 col-lg-4 ">
							<div className="form-group">
								<label className="col-form-label">MSIL Gateout Date:</label>
								<Datetime 
								//value={this.state.msil_gateout_date} 
								disableCloseOnClickOutside={false} 
								//closeOnSelect={true} 
								inputProps={{ placeholder: 'MSIL Gateout Date', name: 'msil_gateout_date', autoComplete:'off' }}
								dateFormat="YYYY-MM-DD"  
								timeFormat="HH:mm:ss"
								name="msil_gateout_date" 
								onChange={this.handlerGateoutDateTime.bind(this)}
								/>
							</div>
						</div>
						<div className="col-md-4 col-sm-4 col-xl-4 col-lg-4">
							<div className="form-group">
								<label className="col-form-label">Empty Return Date:</label>
								{(this.state.emptydisable == true)?
								<div >
									<div className="form-control">
									{this.state.empty_return_date}
									</div>
									<input type="hidden" placeholder="Empty Return Date"  className="form-control" readonly name="empty_return_date" autoComplete='off' onChange={this.changeHandler} value={this.state.empty_return_date} />
								 </div>
								 :
								<Datetime 
								//value={this.state.empty_return_date} 
								disableCloseOnClickOutside={false} 
								//closeOnSelect={true} 
								inputProps={{ placeholder: 'Empty Return Date', name: 'empty_return_date', autoComplete:'off' }}
								dateFormat="YYYY-MM-DD"  
								timeFormat="HH:mm:"  
								name="empty_return_date" 
								onChange={this.handlerEmptyReturnon.bind(this)}
								 
								/>
								}
							</div>
						</div>
						<div className="col-md-12 col-sm-12 col-xl-12 col-lg-12 ">
							<div className="form-group">
								<label className="col-form-label">Remarks:</label>
								<textarea required id="forceclose_remarks" className="form-control" rows="6" name="forceclose_remarks" value={this.state.forceclose_remarks} onChange={this.changeHandler} placeholder="Enter Remarks"/>
							</div>
						</div>
						<div className="col-md-12 col-sm-12 col-xl-12 col-lg-12 ">
							<div className="form-group">
								<button type="submit" className="btn btn-success" name="submit">Submit</button>						
							</div>
							
						</div>
						</form>
					</div>
				</Modal>

				<Modal open={opencritical} onClose={this.onCloseCriticalModal} styles={modalStyles} >
	                <div className={""} style={{padding:'5px 0px', width: '790px', zIndex:'9999',}} >
						<form className="row col-xl-12 col-lg-12 theme-form" method="post" onSubmit={this.formCriticalHandler.bind(this)}>
						
						<div className="col-md-4 col-sm-4 col-xl-4 col-lg-4 ">
							<div className="form-group">
								<label className="col-form-label">Expected ETA at MSIL:</label>
								<Datetime 
								//value={this.state.msil_gatein_date} 
								disableCloseOnClickOutside={false} 
								//closeOnSelect={true} 
								inputProps={{ placeholder: 'Expected ETA at MSIL', name: 'critical_msil_eta', autoComplete:'off' }}
								dateFormat="YYYY-MM-DD"  
								timeFormat="HH:mm:ss"  
								name="critical_msil_eta" 
								onChange={this.handlerCriticalMSILDateTime.bind(this)}
								/>
							</div>
						</div>
					
						<div className="col-md-12 col-sm-12 col-xl-12 col-lg-12 ">
							<div className="form-group">
								<button type="submit" className="btn btn-success" name="submit">Submit</button>						
							</div>
							
						</div>
						</form>
					</div>
				</Modal>
            </div>
              	
		);
    }


    
    onShowConsignmentExportsEventsClick = async (params) => {
		if (googleAnalytics.page.enableGA) {
			let eventLabel = null;
			if (params) {
				let columnFieldName = (params.colDef) ? params.colDef.field : null;
				console.log("columnFieldName = ", columnFieldName);
				eventLabel = googleAnalytics.page.colDef.importsCoils[columnFieldName];
				console.log("eventLabel = ", eventLabel);
			}
			
			let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventGridAction,
				"label": eventLabel,
			}
			googleAnalytics.logEvent(eventOptions);
		}

		if(params != 0)
		{
			var eventsData=[];
			// await redirectURL.post('trackBlDeepSea/getcontainertrackingdetails', {
            //     container: params.data.container_no,
            //     sealine: params.data.shipping_line_code,
            //     apiKey: "O1TX-DPHE-WYN6-53MI"
            // })
			// .then(async (response) =>{
			// 	console.log("Step 11 ",response.data)
				
				
			// 	eventsData = await parseEventsData(response.data);
			// 	//params.successCallback(eventsData);
					
			// 	console.log("Step 12 ",response.data)
				
			// });

			//console.log("Step 3 GOING TO SET STATE");
			
			await this.setState({
				//detailCellRenderer: "<div>Test</div>",
				detailCellRendererParams:{
					detailGridOptions: {
						columnDefs: [							
							{ headerName: "Event",field: "description", filter:true,
								cellClass:function(params){
									//console.log("In ", params)
									if(params.data.event_completed == 1 && params.data.leg_delayed == 0)
									{
										return 'green';
									}
									else if(params.data.event_completed == 1 && params.data.leg_delayed == 1)
									{
										return 'green';
									}
									else if(params.data.event_completed == 0 && params.data.leg_delayed == 1)
									{
										return '';
									}
									else{
										return '';
									}
								},resizable: true
							},
							
							{ headerName: "Place",field: "pod", filter:true,
								cellClass:function(params){
									if(params.data.event_completed == 1 && params.data.leg_delayed == 0)
									{
										return 'green';
									}
									else if(params.data.event_completed == 1 && params.data.leg_delayed == 1)
									{
										return 'green';
									}
									else if(params.data.event_completed == 0 && params.data.leg_delayed == 1)
									{
										return '';
									}
									else{
										return '';
									}
								},
								valueGetter:function(params){
									//	return getDDMMYYYY(params.data.initial_event_date);
									//(params.data.initial_event_date) 
									return (params.data.pod) ? params.data.pod.toUpperCase() : "";
								},resizable: true
							 },	
							{ 
								headerName: "Initial Estimated Date", field: "initial_event_date", filter:true ,
								cellClass:function(params){
									if(params.data.event_completed == 1 && params.data.leg_delayed == 0)
									{
										return 'green';
									}
									else if(params.data.event_completed == 1 && params.data.leg_delayed == 1)
									{
										return 'green';
									}
									else if(params.data.event_completed == 0 && params.data.leg_delayed == 1)
									{
										return '';
									}
									else{
										return '';
									}
								},
								valueGetter:function(params){
									return getDDMMYYYY(params.data.initial_event_date);

									//return (params.data.initial_event_date) ?
									//moment.parseZone(params.data.initial_event_date.substring(0,10)).format('DD-MM-YYYY') : "";
								},resizable: true,
								
							},
							{ 
								headerName: "Previous Revised Date", field: "previous_event_date", filter:true ,
								cellClass:function(params){
									if(params.data.event_completed == 1 && params.data.leg_delayed == 0)
									{
										return 'green';
									}
									else if(params.data.event_completed == 1 && params.data.leg_delayed == 1)
									{
										return 'green';
									}
									else if(params.data.event_completed == 0 && params.data.leg_delayed == 1)
									{
										return '';
									}
									else{
										return '';
									}
								},
								valueGetter:function(params){
									return getDDMMYYYY(params.data.previous_event_date);
								},resizable: true
							},						
							{ 
								headerName: "Actual Event Date", field: "actual_event_date", filter:true ,
								cellClass:function(params){
									if(params.data.event_completed == 1 && params.data.leg_delayed == 0)
									{
										return 'green';
									}
									else if(params.data.event_completed == 1 && params.data.leg_delayed == 1)
									{
										return 'green';
									}
									else if(params.data.event_completed == 0 && params.data.leg_delayed == 1)
									{
										return '';
									}
									else{
										return '';
									}
								},
								valueGetter:function(params){
									return getDDMMYYYY(params.data.actual_event_date);
								},resizable: true,
								//comparator: dateComparator,
								//sort: "asc"
							}
							// { 
							// 	headerName: "Trip Stage", field: "trip_stage", filter:true ,
							// 	cellClass:function(params){
							// 		if(params.data.event_completed == 1 && params.data.leg_delayed == 0)
							// 		{
							// 			return 'green';
							// 		}
							// 		else if(params.data.event_completed == 1 && params.data.leg_delayed == 1)
							// 		{
							// 			return 'green';
							// 		}
							// 		else if(params.data.event_completed == 0 && params.data.leg_delayed == 1)
							// 		{
							// 			return '';
							// 		}
							// 		else{
							// 			return '';
							// 		}
							// 	},resizable: true,
							// 	valueGetter:function(params){
							// 		if(params.data.trip_stage == 1)
							// 		{
							// 			return 'High Seas';
							// 		}

							// 		if(params.data.trip_stage == 2)
							// 		{
							// 			return 'Port In';
							// 		}

							// 		if(params.data.trip_stage == 3)
							// 		{
							// 			return 'Port Out';
							// 		}

							// 		if(params.data.trip_stage == 4)
							// 		{
							// 			return 'ICD In';
							// 		}

							// 		if(params.data.trip_stage == 5)
							// 		{
							// 			return 'ICD Out';
							// 		}
							// 	}
							// }
							
						],
										
					},
					getDetailRowData: async function(params) {
						console.log("Step 4 eventsData =",eventsData);
						//console.log("Step 5 params = ",params);
						redirectURL.post('/trackBlDeepSea/exports', {
							container: params.data.container_no,
							sealine: params.data.shipping_line_code,
							apiKey: "O1TX-DPHE-WYN6-53MI"
						})
						.then(async (response) =>{
							console.log("Event Step 1 ",response.data)
							//eventsData = await parseEventsData(response.data);
							var nonempty = [];
							var emptyrows = [];
							response.data.map((item) => {
								if(item.hasOwnProperty("actual_event_date"))
								{
									nonempty.push(item)
								}
								else{
									emptyrows.push(item);
								}
							})
							nonempty.sort(function(a, b) {
								var c = new Date(a.actual_event_date);
								var d = new Date(b.actual_event_date);
								return c-d;
							});
							var records = nonempty.concat(emptyrows);
							//console.log("Records ", records);
							params.successCallback(records);
						});
					},

					
					masterDetail: true,
				}
			});
			//console.log("H ", params.column.colDef.field)
			//console.log(params.node.expanded);
			if(params.column.colDef.field == 'sea_transit_delay')
			// || params.column.colId == 'port_delay' || params.column.colId == 'rail_transit_delay' || params.column.colId == 'icd_delay' || params.column.colId == '_id' || params.column.colId == 'msil_transit_delay')
			{
				//console.log("detailCellRendererParams 1111 = ", this.state.detailCellRendererParams, params.column.colId);
				params.node.setExpanded(!params.node.expanded);
				//console.log("params.node.expanded =", params.node.expanded);
			}
			else{
				//console.log("detailCellRendererParams 2222 = ", this.state.detailCellRendererParams, params.column.colId);
				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);
			
		} 
		
	}

	onShowImportsCoilLegs = async (params) => {
		if (googleAnalytics.page.enableGA) {
			let eventLabel = null;
			if (params) {
				let columnFieldName = (params.colDef) ? params.colDef.field : null;
				//console.log("columnFieldName = ", columnFieldName);
				eventLabel = googleAnalytics.page.colDef.importsCoils[columnFieldName];
				//console.log("eventLabel = ", eventLabel);
			}
			
			let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventGridAction,
				"label": eventLabel,
			}
			googleAnalytics.logEvent(eventOptions);
		}

		if(params != 0)
		{
			var eventsData=[];
			
			await this.setState({
				//detailCellRenderer: "<div>Test</div>",
				detailCellRendererParams:{
					detailGridOptions: {
						columnDefs: [							
							{ 
								headerName: "POL",
								field: "pol", 
								filter:true,
								cellClass:function(params){
									return '';
								},
								resizable: true
							},
							
							// { 
							// 	headerName: "Expected Date Range",
							// 	field: "expected_date", 
							// 	filter:true,
							// 	cellClass:function(params){
							// 		return '';
							// 	},
							// 	// valueGetter:function(params){
							// 	// 	console.log("params.data.expected_date ", params.data.expected_date)
							// 	// 	return convertDateFormat(params.data.expected_date);
							// 	// },
							// 	resizable: true
							//  },	
							// { 
							// 	headerName: "Actual Date Range", 
							// 	field: "actual_date", 
							// 	filter:true,
							// 	cellClass:function(params){
							// 		return '';
							// 	},
							// 	resizable: true
								
							// },


							{ 
								headerName: "Expected Port In", 
								field: "expected_port_in", 
								filter:true,
								cellClass:function(params){
									return '';
								},
								resizable: true,
								valueGetter:function(params){
									return getDDMMYYYY(params.data.expected_port_in);
								}
							},

							{ 
								headerName: "Actual Port In", 
								field: "actual_port_in", 
								filter:true,
								cellClass:function(params){
									return '';
								},
								resizable: true,
								valueGetter:function(params){
									return getDDMMYYYY(params.data.actual_port_in);
								},
								cellClass:function(params){
									var expect = moment.parseZone(params.data.expected_port_in).format("x");
									var actual = moment.parseZone(params.data.actual_port_in).format("x");
									// var tt = edate +">"+ sdate;
									// console.log("check ", tt);
									//console.log("1 day ", (60*60*24*1000))
									//var oneday = (60*60*24*1000);
									if(actual > expect)
									{
										return 'bgColorDangerMedium';
									}
									else if(actual < expect)
									{
										return 'bgColorSuccessLight';
									}
									else
									{
										return '';
									}
								}
							},

							{ 
								headerName: "Expected Port Out", 
								field: "expected_port_out", 
								filter:true,
								cellClass:function(params){
									return '';
								},
								resizable: true,
								valueGetter:function(params){
									return getDDMMYYYY(params.data.expected_port_out);
								}
							},

							{ 
								headerName: "Actual Port Out", 
								field: "actual_port_out", 
								filter:true,
								cellClass:function(params){
									return '';
								},
								resizable: true,
								valueGetter:function(params){
									return getDDMMYYYY(params.data.actual_port_out);
								},
								cellClass:function(params){
									var expect = moment.parseZone(params.data.expected_port_out).format("x");
									var actual = moment.parseZone(params.data.actual_port_out).format("x");
									
									if(actual > expect)
									{
										return 'bgColorDangerMedium';
									}
									else if(actual < expect)
									{
										return 'bgColorSuccessLight';
									}
									else
									{
										return '';
									}
								}
							},
							{ 
								headerName: "Revision", 
								field: "schedule", 
								filter:true,
								cellClass:function(params){
									return '';
								},
								resizable: true
							},						
							{ 
								headerName: "Status", field: "status", filter:true ,
								cellClass:function(params){
									return '';
								},
								resizable: true
							}
							
						],
										
					},
					getDetailRowData: async function(params) {
						//console.log("Step 4 eventsData =",eventsData);
						//console.log("Step 5 params = ",params);
						redirectURL.post('/getMonthNumber(getMonthNumber(cellValue[1]))Legs', {
							ship_name: params.data.nyk_ship_name,
							voyage_no: params.data.nyk_voyage_no
						})
						.then(async (response) =>{
							//console.log("Event Step 1 ",response.data)
							var records = response.data;
							params.successCallback(records);
						});
					},

					
					masterDetail: true,
				}
			});
			//console.log("H ", params.column.colDef.field)
			//console.log(params.node.expanded);
			if(params.column.colDef.field == 'high_seas')
			// || params.column.colId == 'port_delay' || params.column.colId == 'rail_transit_delay' || params.column.colId == 'icd_delay' || params.column.colId == '_id' || params.column.colId == 'msil_transit_delay')
			{
				//console.log("detailCellRendererParams 1111 = ", this.state.detailCellRendererParams, params.column.colId);
				params.node.setExpanded(!params.node.expanded);
				//console.log("params.node.expanded =", params.node.expanded);
			}
			else{
				//console.log("detailCellRendererParams 2222 = ", this.state.detailCellRendererParams, params.column.colId);
				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);
			
		} 
		
	}

}
function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}
                   
function timeConvert(n) {
	var num = n;
	var hours = (num / (3600));
	var rhours = Math.floor(hours);
	var minutes = (n) / (60);
	var rminutes = Math.round(minutes);
	return rhours + " hour(s) and " + rminutes + " minute(s).";
}                              
var currentinfowindow = null;
function getTimeInfo(marker,timedet,coords)
{
	var timeinfo = new window.google.maps.InfoWindow({
          content: "Time at this location: " + timedet
        });

	marker.addListener('click', function() {
		
				if (currentinfowindow != null) {
					currentinfowindow.close();
					currentinfowindow = timeinfo;
					timeinfo.open(marker.get('map'), marker);
				}
				else
				{
					currentinfowindow = timeinfo;
					timeinfo.open(marker.get('map'), marker);
				}
				
        });
}

function parseEventsData2 (jsonData) {
    console.info('parsing data IN parseEventsData');
    console.info('parsing data IN parseConsignemntDetailsSafexpress', jsonData);
   
    let points = jsonData.points;
   // console.log('points = ', points);
    let eventList = [];
    let eventInfo = {};
    points.forEach(function(point) {
        eventInfo.name = point.name;
        eventInfo.country = point.country;
      // traversing events
      if (point.events) {
        console.log('coming in events');
        point.events.forEach(function(eventObj) { 
            eventInfo.event_datetime_ist = eventObj.event_datetime_ist;
            eventInfo.description = eventObj.description;
        });
        eventList.push(eventInfo);
      } else {
        console.log('coming in ELSE point.events ');

      }
      
    });
    console.info('parsing points');

    return eventList;
  }
  
async function parseEventsData (jsonData) {
//console.info('parsing data IN parseEventsData');
//console.info('parsing data IN parseConsignemntDetailsSafexpress', jsonData);

let points = jsonData.points;
// console.log('points = ', points);
let eventList = [];
let eventInfo = [];

points.forEach(function(point) {
	let pointName = point.name;
	let pointCountry = point.country;
	let pointTransportTypeName = point.transport_type_name;
	let pointTransportType = point.type;
	if (pointTransportType === 1 || pointTransportType === 4) {
		pointTransportTypeName = "Rail/Truck";
	}
	if (!pointTransportTypeName) {
		pointTransportTypeName = "Data unavailable"
	}
	// traversing events
	if (point.events) {
		//console.log('coming in events');
		point.events.forEach(function(eventObj) { 
			let eventDatetime = eventObj.event_datetime_ist;
			let eventDescription = eventObj.description;
			
			let initial_event_datetime_ist = eventObj.initial_event_datetime_ist;
            let previous_event_datetime_ist = eventObj.previous_event_datetime_ist;
			let actual_event_datetime_ist	= eventObj.actual_event_datetime_ist;
			

			eventInfo = {
				"name": pointName,
				"country": pointCountry,
				"event_datetime_ist": eventDatetime,
				"description": eventDescription,
				"transport_type_name": pointTransportTypeName,
				"initial_event_datetime_ist": initial_event_datetime_ist,
				"previous_event_datetime_ist": previous_event_datetime_ist,
				"actual_event_datetime_ist": actual_event_datetime_ist,
			}
			eventList.push(eventInfo);
		});
	} else {
		console.log('coming in ELSE point.events '); // do nothing
	}
});
//console.log('eventList', eventList);
return eventList;
}



function convertDateFormat(dDate){
    if(dDate != '')
    {
         var document_receiveddate = dDate;
         var s1 = document_receiveddate.split("-");
         var doc_rec_date = s1[2]+"-"+s1[1]+"-"+s1[0];
         var document_received_date = moment(new Date(doc_rec_date)).format("YYYY-MM-DD");
        return document_received_date
    }
    else{
        return "";
    }
    
}
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }

function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}
function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}

function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}