
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');
var moment = require('moment');

 
const PrintItems = (props) => {
//    const handleClick = (e) => {
//     e.stopPropagation();
//     props.context.componentParent.onClickViewPrintItems(props);
//    }
    // console.log("props.data.customer_lr_doc ", props.data)
    return (
        <span>
        {
            (props.data.is_pod_submit != "" && props.data.is_pod_submit != undefined)?
            <span>
                <a href={"/printepoditems/"+props.data.invoice_no} target="_blank" className="label label-success f12"  style={{padding:"5px 10px",color:"#333","border":"0px"}}>Print</a>
            </span>
          :""
        }
        </span>
    );
};

export default PrintItems;
