import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils'; 

import Highcharts from 'highcharts';
import drilldown from 'highcharts-drilldown';
import HighchartsReact from 'highcharts-react-official';
import $ from 'jquery';
drilldown(Highcharts);
window.jQuery = $;
window.$ = $;

var redirectURL = require('../redirectURL');

var criticaldata=[]
export default class PieChart extends Component {

    constructor(props) {
		super(props);
		this.state = {
			showgrid:'display:none',
			showtransgrid:'display:none',
			rowdata:[],
			test:"TEstng"
		}

	}
	componentDidMount = async () =>{
		
	}
	
    render(){
    
        const dStyles={
				width:'100%',
				height:'100%'
		}
		const highchartoptions = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie'
            },
            title: {
                text: this.props.xTitle,
                align: 'left'
            },
            tooltip: {
                pointFormat: '<b>{series.name}:{point.percentage:.1f} % <br /> No of Truck: {point.y}</b>'
            },
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        format: '<b>{point.name}</b>:{point.percentage:.1f} % <br /> No of Truck: {point.y}'
                    }
                }
            },
            // defs: {
            //     gradient0: {
            //         tagName: 'linearGradient',
            //         id: 'gradient0',
            //         x1: 0,
            //         y1: 0,
            //         x2: 0,
            //         y2: 1,
            //         children: [{
            //             tagName: 'stop',
            //             offset: 0,
            //             'stop-color': '#000'
            //         }, {
            //             tagName: 'stop',
            //             offset: 1,
            //             'stop-color': '#000'
            //         }]
            //     }
            // },
            series: this.props.series,
            // colors:[ '#FF5370', '#00f0b5','#f35e82','#7f84e9','#f3d800',  '#fff493', '#f88fa2'],
            
            // colors: Highcharts.map(Highcharts.getOptions().colors, function (color) {
            //         return {
            //             radialGradient: {
            //                 cx: 0.5,
            //                 cy: 0.3,
            //                 r: 0.7
            //             },
            //             stops: [
            //                 [0, color],
            //                 [1, Highcharts.color(color).brighten(-0.3).get('rgb')] // darken
            //             ]
            //         };
            //     }),
            credits:false
        };
        
        
        return ( 
			<div>
               <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={highchartoptions} />
			</div>
			);
		}
}