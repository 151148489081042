
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const BreakDown = (props) => {
    const handleEventClick = (e) => {
        e.stopPropagation();
        //console.log("Overspeed ",props);
        props.context.componentParent.onShowBreakDownForm(props);
    };
    
   
    return (
        <div>
            <button onClick={handleEventClick} className="custom-btn f16 label label-success" 
            title="Break Down" >
                {
                (props.data.tpt_break_down_status !== "" && props.data.tpt_break_down_status !== undefined)?
                props.data.tpt_break_down_status === "Yes"?
                    <i className="fa fa-truck"></i>
                :
                <i className="fa fa-truck" style={{color:"#ccc"}}></i>
                :
                <i className="fa fa-truck" style={{color:"#ccc"}}></i>
            }
            </button>
            
        </div>
    );
};

export default BreakDown;