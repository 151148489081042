
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import _ from "lodash";
import Modal from 'react-responsive-modal';
import SweetAlert from 'react-bootstrap-sweetalert';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import Select from 'react-select';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import axios from 'axios';
import Autosuggest from 'react-autosuggest';
import Grid from '../layouts/gridActionComponent';
import Breadcrumb from '../common/breadcrumb.component';
import EditItem from '../layouts/editActionComponent';
import { event } from 'react-ga';
import ViewAttachments from "../layouts/viewattachments";
import {
    getDDMMYYYY,
    getDDMMYYYYHHMMSS,
    getYYYYMMDD,
    getYYYYMMDDHHMMSS,
    getHyphenYYYYMMDDHHMMSS,
    getHyphenDDMMMYYYYHHMM,
    getHyphenYYYYMMDD,
    getHyphenDDMMYYYYHHMMSS,
    getHyphenDDMMYYYY,
    getDDMMMYYYYHHMMDefault
} from '../common/utils';
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
var moment = require('moment');
export default class DeviationIdData extends Component {
    constructor(props) {
        super(props);
        const today = moment().utcOffset("+05:30").subtract(7, 'days').format("DD-MM-YYYY"); // Get today's date with +05:30 offset
        const endDate = moment().utcOffset("+05:30").format("DD-MM-YYYY"); // Get tomorrow's date with +05:30 offset
        this.state = {
            lists: [],
            departmentCode: null,
            currentDepartmentTitle: null,
            currentAddGeoFenceLink: null,
            usermanualmodal: false,
            departmentCode: null,
            currentDepartmentTitle: null,
            currentAddGeoFenceLink: null,
            pagetitle: "Deviation Request",
            rowData: [],
            show1: false,
            basicTitle1: '',
            basicType1: "default",
            show: false,
            basicTitle: '',
            basicType: "default",
            loadshow: "show-n",
            from_date: "",
            to_date: "",
            noOfVehicles: 0,
            reasonForHold: '',
            approvalEmailId: "",
            requesterEmail: '',
            salesPlannerEmail: '',
            oblEmail: '',
            emailIds: {
                approvalEmailId: "",
                requesterEmail: '',
                salesPlannerEmail: '',
                oblEmail: '',
            },
            errors: {},
            startDate: today, // Set default to today
            endDate: endDate, // Set default to tomorrow
            rowData: [],
            sliderBulkUpdate: "",
            loadshow: 'show-n',
            overly: 'show-n',
            defaultColDef: {
                sortable: true,
                filter: true,
                resizable: true,
                pivot: true,
                enableValue: true,
                // enableRowGroup: true,
            },
            modules: AllModules,
            animateRows: true,
            debug: true,
            showToolPanel: false,
            uppressAggFuncInHeader: true,
            childconfs: [],
            childrow: [],
            rowModelType: "serverSide",
            paginationPageSize: 100,
            cacheBlockSize: 10,
            maxBlocksInCache: 1,
            rowData: [],
            maprowData: null,
            rowSelection: 'single',
            rowHeight: 32,
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            overlayNoRowsTemplate: "",
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            detailCellRendererParams: {},
            rowClassRules: {},
            frameworkComponents: {
                ViewAttachments: ViewAttachments,
            },
            errrorRowData: "",
            offroute: "btn-info",
            yard: "btn-default",
            matrixFile: null,
            showFilters: false,
            inputValue: '',
            deviation_id: '',
            usergridstate: [],
            pagetitle: "",
            row_files: {},
        };
    }
    async componentDidMount() {
        try {
            this.setState({
                loadshow: "show-m",
                overly: "show-m"
            })
            let { deviation_id } = this.props.match.params
            let params = {
                deviation_id: deviation_id
            }
            if (!deviation_id) {
                return ''
            }
            let response = await redirectURL.post("/consignments/getuniquedeviationdata", params);
            let data = response.data || []
            let isOffroute = response.data[0].deviation_type == 2 ? true : false
            this.setState({
                rowData: data,
                deviation_id: deviation_id,
                loadshow: "show-n",
                overly: "show-n",
                offroute: isOffroute ? 'btn-default' : 'btn-info',
            })
            redirectURL.post("/consignments/usergridstates", { userId: localStorage.getItem("userid"), screenurl: window.location.pathname })
                .then(async (resp) => {
                    await this.setState({
                        usergridstate: resp.data
                    });
                    await this.restoreGridStates();
                })
        } catch (e) {
            console.log(e, 'e')
            this.setState({
                loadshow: "show-n",
                overly: "show-n"
            })
        }
    }
    closeAlert = () => {
        this.setState({
            show: false
        });
    }
    onClickHideAll() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            sliderViewFiles: "",
            row_files: {},
        });
    }
    onBackToDeviationReport = () => {
        window.location.href = "/deviationreport"
    }
    onClickSaveGridState() {
        window.colState = this.gridColumnApi.getColumnState();
        window.groupState = this.gridColumnApi.getColumnGroupState();
        window.sortState = this.gridApi.getSortModel();
        window.filterState = this.gridApi.getFilterModel();
        let screenpage = 'Deviation Report ';
        let reqparams = {
            gridcolumns: window.colState,
            gridgroup: window.groupState,
            gridcolsort: window.sortState,
            gridcolfilter: window.filterState,
            userId: localStorage.getItem("userid"),
            screenurl: window.location.pathname,
            screentitle: screenpage
        }
        redirectURL.post("/consignments/saveGridStates", reqparams)
            .then((response) => {
                this.setState({
                    show: true,
                    basicTitle: "Successfully saved grid layout",
                    basicType: "success",
                    screenurl: window.location.pathname,
                    screentitle: screenpage
                })
            })
            .catch(function (e) {
            })
    }
    restoreGridStates() {
        if (this.state.usergridstate.length > 0) {
            var windowstates = this.state.usergridstate;
            this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
            this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
            this.gridApi.setSortModel(windowstates[0].gridcolsort);
            this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
        }
    }
    resetState = () => {
        this.gridColumnApi.resetColumnState();
        this.gridColumnApi.resetColumnGroupState();
        this.gridApi.setSortModel(null);
        this.gridApi.setFilterModel(null);
        var reqparams = {
            userId: localStorage.getItem("userid"),
            screenurl: window.location.pathname,
            screentitle: this.state.screenpage
        }
        redirectURL.post("/consignments/removeUserGridState", reqparams)
            .then((response) => {
                this.setState({
                    show: true,
                    basicTitle: "Successfully reset default grid layout",
                    basicType: "success"
                })
            })
    };
    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    };
    onClickViewAttachment = (params) => {
        try {
            // console.log(params, 'params')
            this.setState({
                sliderViewFiles: 'slider-translate-30p',
                overly: 'show-m',
                row_files: params.data.uploaded_files
            })
        } catch (e) {
            console.log("Error ", e)
        }
    }
    downloadB2 = (fileName = "") => {
        try {
            if (fileName === "") {
                return;
            }
            redirectURL
                .post("/consignments/downloadFile", {
                    fileName: fileName
                }
                )
                .then((res) => {
                    // console.log(res);
                    if (res.status === 200) {
                        const downloadUrl = res.data.url;
                        const link = document.createElement("a");
                        link.href = downloadUrl;
                        link.setAttribute("download", fileName); // Optional: specify download filename
                        link.setAttribute("target", "_blank");
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        // console.log("clicked");
                    }
                })
                .catch((err) => {
                    console.log("err - ", err);
                });
        } catch (e) {
            console.log("Error ", e)
        }
    };
    render() {
        const {
            rowData,
            offroute
        } = this.state;
        let hideForEnroute = offroute == 'btn-info' ? true : false
        let attachMentHeader = {
            "dealer_declaration": "Dealer Declaration",
            "delivery_challan": "Delivery Challan",
            "retail_invoice": "Retail Invoice",
            "trade_certificate": "Trade Certificate",
            "tc_insurance": "TC Insurance or Transit Insurance",
            "eway_bill": "Eway Bill for Delivery Address",
            "body_builder_letter": "Body Builder Letter"
        }
        let columnDefs = [
            {
                headerName: 'Deviation Id',
                validation: (value) => value && value.trim() !== '',
                field: 'deviation_id',
                editable: false,
                width: 140,
            },
            {
                headerName: 'Deviation Status',
                validation: (value) => value && value.trim() !== '',
                field: 'deviation_status',
                editable: false,
                width: 120,
                cellClass: function (params) {
                    if (params.data && params.data.deviation_status == 0) {
                        return "boxbgblue5"
                    }
                    if (params.data && params.data.deviation_status == 1) {
                        return "bgcolorsuccesgreen";
                    } else if (params.data && params.data.deviation_status == 2) {
                        return "boxBgLighRed";
                    } else if (params.data && params.data.deviation_status == 3) {
                        return "bgcolorsuccesgreenRelease";
                    }
                },
                valueGetter: params => {
                    try {
                        let { deviation_status } = params.data
                        let status = ""
                        if (deviation_status == 0) {
                            status = "Pending"
                        }
                        if (deviation_status == 1) {
                            status = "Approved"
                        } else if (deviation_status == 2) {
                            status = "Rejected"
                        }
                        // else if (deviation_status == 2) {
                        //     status = "Released"
                        // }
                        return status
                    } catch (e) {
                        console.log("Error ", e)
                    }
                }
            },
            {
                headerName: 'No of Vehicles',
                validation: (value) => value && value.trim() !== '',
                field: 'no_of_vehicles',
                editable: false,
                width: 120,
            },
            {
                headerName: 'Reason',
                validation: (value) => value && value.trim() !== '',
                field: 'deviation_reason',
                editable: false,
                width: 160,
            },
            {
                headerName: 'OBL Status',
                validation: (value) => value && value.trim() !== '',
                field: 'obl_status',
                editable: false,
                width: 180,
                hide: hideForEnroute,
                suppressColumnsToolPanel: hideForEnroute,
                cellClass: function (params) {
                    if (params.data && params.data.obl_status == 0) {
                        return "boxbgblue5"
                    } else if (params.data && params.data.obl_status == 1) {
                        return "bgcolorsuccesgreen";
                    } else if (params.data && params.data.obl_status == 2) {
                        return "boxBgLighRed";
                    }
                },
                valueGetter: params => {
                    try {
                        let { obl_status } = params.data
                        let status = ''
                        if (obl_status == 0) {
                            status = 'Pending'
                        } else if (obl_status == 1) {
                            status = "Approved"
                        } else if (obl_status == 2) {
                            status = "Rejected"
                        }
                        return status
                    } catch (e) {
                        console.log("Error :", e)
                    }
                }
            },
            {
                headerName: 'Requester L4 Status',
                validation: (value) => value && value.trim() !== '',
                field: 'req_l4_status',
                editable: false,
                width: 180,
                cellClass: function (params) {
                    if (params.data && params.data.req_l4_status == 0) {
                        return "boxbgblue5"
                    } else if (params.data && params.data.req_l4_status == 1) {
                        return "bgcolorsuccesgreen";
                    } else if (params.data && params.data.req_l4_status == 2) {
                        return "boxBgLighRed";
                    }
                },
                valueGetter: params => {
                    try {
                        let { req_l4_status } = params.data
                        let status = ''
                        if (req_l4_status == 0) {
                            status = 'Pending'
                        } else if (req_l4_status == 1) {
                            status = "Approved"
                        } else if (req_l4_status == 2) {
                            status = "Rejected"
                        }
                        return status
                    } catch (e) {
                        console.log("Error :", e)
                    }
                }
            },
            {
                headerName: 'Requester Business Head Status',
                validation: (value) => value && value.trim() !== '',
                field: 'req_bs_head_status',
                editable: false,
                width: 180,
                cellClass: function (params) {
                    if (params.data && params.data.req_bs_head_status == 0) {
                        return "boxbgblue5"
                    } else if (params.data && params.data.req_bs_head_status == 1) {
                        return "bgcolorsuccesgreen";
                    } else if (params.data && params.data.req_bs_head_status == 2) {
                        return "boxBgLighRed";
                    }
                },
                valueGetter: params => {
                    try {
                        let { req_bs_head_status } = params.data
                        let status = ''
                        if (req_bs_head_status == 0) {
                            status = 'Pending'
                        } else if (req_bs_head_status == 1) {
                            status = "Approved"
                        } else if (req_bs_head_status == 2) {
                            status = "Rejected"
                        }
                        return status
                    } catch (e) {
                        console.log("Error :", e)
                    }
                }
            },
            {
                headerName: 'F&C Approver Status',
                validation: (value) => value && value.trim() !== '',
                field: 'fc_status',
                editable: false,
                width: 180,
                hide: hideForEnroute,
                suppressColumnsToolPanel: hideForEnroute,
                cellClass: function (params) {
                    if (params.data && params.data.fc_status == 0) {
                        return "boxbgblue5"
                    } else if (params.data && params.data.fc_status == 1) {
                        return "bgcolorsuccesgreen";
                    } else if (params.data && params.data.fc_status == 2) {
                        return "boxBgLighRed";
                    }
                },
                valueGetter: params => {
                    try {
                        let { fc_status } = params.data
                        let status = ''
                        if (fc_status == 0) {
                            status = 'Pending'
                        } else if (fc_status == 1) {
                            status = "Approved"
                        } else if (fc_status == 2) {
                            status = "Rejected"
                        }
                        return status
                    } catch (e) {
                        console.log("Error :", e)
                    }
                }
            },
            {
                headerName: 'Bill To Customer Number',
                validation: (value) => value && value.trim() !== '',
                field: 'bill_to_customer_number',
                editable: true,
                width: 160, cellStyle: (params) => !params.value ? { backgroundColor: '#f29f8d', color: 'black' } : null
            },
            {
                headerName: 'Dealer Name',
                validation: (value) => value && value.trim() !== '',
                field: 'dealer_name',
                editable: true,
                width: 140,
                cellStyle: (params) => !params.value ? { backgroundColor: '#f29f8d', color: 'black' } : null
            },
            {
                headerName: 'Ship To Party Code',
                validation: (value) => value && value.trim() !== '',
                field: 'ship_to_party_code',
                editable: true,
                width: 160, cellStyle: (params) => !params.value ? { backgroundColor: '#f29f8d', color: 'black' } : null
            },
            {
                headerName: 'Ship To Party Name',
                validation: (value) => value && value.trim() !== '',
                field: 'ship_to_party_name',
                editable: true,
                width: 160,
                cellStyle: (params) => !params.value ? { backgroundColor: '#f29f8d', color: 'black' } : null
            },
            {
                headerName: 'Ship To Party Location',
                validation: (value) => value && value.trim() !== '',
                field: 'ship_to_party_location',
                editable: true,
                width: 180, cellStyle: (params) => !params.value ? { backgroundColor: '#f29f8d', color: 'black' } : null
            },
            {
                headerName: 'Ship To Party Sate',
                validation: (value) => value && value.trim() !== '',
                field: 'ship_to_party_state',
                editable: true,
                width: 160, cellStyle: (params) => !params.value ? { backgroundColor: '#f29f8d', color: 'black' } : null
            },
            {
                headerName: 'Invoice Date',
                field: 'invoice_date',
                editable: true,
                width: 140,
                cellStyle: (params) => !params.value ? { backgroundColor: '#f29f8d', color: 'black' } : null,
                valueGetter: params => {
                    try {
                        let { invoice_date } = params.data
                        if (invoice_date) {
                            return getHyphenDDMMMYYYYHHMM(invoice_date)
                        }
                    } catch (e) {
                        console.log("Error ", e)
                    }
                }
            },
            {
                headerName: 'Requester Email',
                validation: (value) => value && value.trim() !== '',
                field: 'requester_mail',
                editable: false,
                width: 180,
            },
            {
                headerName: 'Requester L4 Email',
                validation: (value) => value && value.trim() !== '',
                field: 'req_l4_mail',
                editable: false,
                width: 180,
            },
            {
                headerName: 'Requester Business Head Email',
                validation: (value) => value && value.trim() !== '',
                field: 'req_bs_head_mail',
                editable: false,
                width: 180,
            },
            {
                headerName: 'M&S Email',
                validation: (value) => value && value.trim() !== '',
                field: 'ms_mail',
                editable: false,
                width: 180,
            },
            {
                headerName: 'F&C Email',
                validation: (value) => value && value.trim() !== '',
                field: 'fc_mail',
                editable: false,
                width: 180,
                hide: hideForEnroute,
                suppressColumnsToolPanel: hideForEnroute,
            },
            {
                headerName: 'Obl Email',
                validation: (value) => value && value.trim() !== '',
                field: 'obl_mail',
                editable: false,
                width: 180,
            },

            {
                headerName: "Attachments",
                field: "",
                width: 100,
                resizable: true,
                params: {
                    onClickViewAttachment: this.onClickViewAttachment
                },
                cellRendererSelector: function (params) {
                    var rendComponent = {
                        component: "ViewAttachments"
                    };
                    return rendComponent
                },
            },
        ]
        return (
            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                />
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="d-flex justify-content-between"
                            style={{ width: "100%" }}
                        >
                            <h5>
                                <i className="icofont icofont-vehicle-delivery-van cus-i"> </i>
                                <span> Deviation Report</span>
                                <span style={{ lineHeight: "0px", letterSpacing: "0px", fontSize: "14px", fontWeight: "900" }}>
                                    <span> (Deviation Id : {this.state.deviation_id}) </span>
                                </span>
                            </h5>
                            <p
                                onClick={this.onBackToDeviationReport}
                                style={{
                                    fontSize: "18px", marginRight: "4px", color: "#000",
                                    textDecoration: "underline",
                                    border: "0px",
                                    background: "transparent",
                                    cursor: "pointer",
                                    fontStyle: 'oblique'
                                }}
                                title="Back To Deviation Report"
                            >
                                &#8592; Back
                            </p>
                        </div>
                        <div className="card">
                            <div className="card-body" style={{ padding: "20px", minHeight: "60vh" }}>
                                <div className="card-body" style={{ padding: '10px', paddingRight: '0px', paddingTop: '0px' }}>
                                    <div className="row" style={{ textAlign: 'right' }}>
                                        <div className="col-sm-12">
                                            <button className="float-right custom-btn white btn-danger" onClick={this.onClickSaveGridState.bind(this)}>
                                                <i className="icofont icofont-save"></i> Save Grid
                                            </button>
                                            <button className="float-right custom-btn white btn-info" style={{ marginRight: "10px" }} onClick={this.resetState.bind(this)}>
                                                <i className="icofont icofont-refresh"></i> Reset Grid
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div id="myGrid" style={{ height: "480px", width: "100%" }} className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnDefs} // Assuming this is a state variable for columns
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={rowData}
                                        sideBar={this.state.sideBar}
                                        enableCharts={true}
                                        enableRangeSelection={true}
                                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady}
                                        pagination={true}
                                        frameworkComponents={this.state.frameworkComponents}
                                        stopEditingWhenGridLosesFocus={true}
                                        enableCellChangeFlash={false}
                                        rowClassRules={this.state.rowClassRules}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        masterDetail={true}
                                        rowHeight={this.state.rowHeight}
                                        rowSelection={this.state.rowSelection}
                                        suppressRowClickSelection={true}
                                        overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                                        excelStyles={this.state.excelStyles}
                                        editable={false}  // Enable editing for all cells
                                        onSelectionChanged={this.onSelectionChanged}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"slide-r " + (this.state.sliderViewFiles)} style={{ overflow: "auto", }}>
                    <div className="slide-r-title">
                        <h4>
                            Attachments
                            <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 text-center">
                            <div className="col-xl-12 col-lg-12 form-group">
                                <div>
                                    {
                                        Object.keys(this.state.row_files).map((key, index) => {
                                            const file = this.state.row_files[key];
                                            return (
                                                <div className="mb-15p mt-15p" key={index}>
                                                    <p className='p-0 m-0'>{attachMentHeader[key]}</p>
                                                    <button onClick={() => this.downloadB2(file)} className="btn btn-warning1">
                                                        {(file !== undefined && file !== "undefined")
                                                            ? (file.includes('_')
                                                                ? file.split('/').pop().split('_').slice(0, -1).join('_')
                                                                : file)
                                                            : `Download File ${index + 2}`
                                                        }
                                                        <i
                                                            style={{ color: 'rgb(255 255 255)', cursor: 'pointer', marginLeft: '10px', marginTop: '5px', marginBottom: '5px' }}
                                                            className="fa fa-download f20"
                                                        ></i>
                                                    </button>
                                                    <br />
                                                </div>
                                            );
                                        }) || ""
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                </div>
            </div>
        );
    }
}
