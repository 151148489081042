import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils'; 

import Highcharts from 'highcharts';
import drilldown from 'highcharts-drilldown';
import HighchartsReact from 'highcharts-react-official';
import $ from 'jquery';
drilldown(Highcharts);
window.jQuery = $;
window.$ = $;

var redirectURL = require('../redirectURL');

var criticaldata=[]
export default class StackGroupChart extends Component {

    constructor(props) {
		super(props);
		this.state = {
			showgrid:'display:none',
			showtransgrid:'display:none',
			rowdata:[],
			test:"TEstng"
		}

	}
	componentDidMount = async () =>{
		
	}
	
    render(){
    
        const dStyles={
				width:'100%',
				height:'100%'
		}
		const highchartoptions = {
            chart: {
                zoomType: 'xy',
                // type: 'column',
                renderTo: 'container',
                // height:"500"
            },
            title: {
                text: this.props.xTitle,
                align: 'left'
            },
            tooltip: {
                // pointFormat: '<b>{series.name}:Total Cost: INR {point.y}</b>'
                shared:false
            },
            xAxis: {
                // categories: ['East', 'West', 'North','South', 'Central']
                type:"category"
            },
            // yAxis: {
            //     title: "Total Cost (INR)",
            //     // type:"logarithmic"
            // },
            yAxis: [
                {
                    title: {
                        text: "Total Cost (INR)"
                    },
                    stackLabels: {
                        enabled: true,
                        dataLabels:{
                            format:'{point.lblTxt}'
                        },
                        formatter:function(){
                            // console.log(this.axis.series)
                            // console.log(this.x)
                            var x = this.x;
                            var value= 0;
                            var series = this.axis.series;
                            Highcharts.each(series, function(s) {
                                // console.log("S ", s)
                              value += s.userOptions.data[x].y;
                            });
                            return "INR "+value;
                        },
                        style: {
                          fontWeight: 'bold',
                          color: (Highcharts.theme && Highcharts.theme.textColor) || 'black'
                        }
                    }
                },
                {
                    title: {
                        text: 'No Of Trucks'
                    },
                    labels: {
                        dataLabels:{enabled: true}
                        
                    },
                    opposite: true // Place the secondary Y-axis on the opposite side
                },
                
            ],
            accessibility: {
                // point: {
                //     valueSuffix: ''
                // }
            },
            plotOptions: {
                column: {
                    stacking: 'normal'
                },
                dataLabels:{
                    format:'{point.lblTxt}',
                    enabled:true
                },
                series: {
                    dataGrouping:true
                }
            },
            series:this.props.series,
            // drilldown:{
            //     series:this.props.drilldowm
            // },
            // colors:[ '#7f84e9','#f3d800', '#f88fa2', '#0BDD97','#fff493' ],
            credits:false
        };
        
        return ( 
			<div>
               <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={highchartoptions} />
			</div>
			);
		}
}