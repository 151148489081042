/* eslint-disable array-callback-return */
/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import _, { join } from "lodash";
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
//import Grid from '../layouts/gridnosidebarComponent';
import Grid from '../layouts/gridComponent';
import CSVFileValidator from 'csv-file-validator'
import ConsignmentActions from './ConsignmentActionsComponent';
import CommentActions from '../layouts/commentsComponent';
import SegmentAction from "../layouts/segmentAction";
import KpiActions from './kpiActionsComponent';
import KpiDeviationActions from './kpiDeviationActionsComponent';
import SegmentSideBar from "../layouts/segmentComponent";
import KpiTransitDelayActions from './kpiTransitDelayActions';
import Nooverspeed from './nooverspeedComponent.js';
import Notransit from './notransitComponent.js';
import RoutemapAction from './routemapActionComponent';
import RoutemapTransitAction from './routemaptransit';
import RoutemapNoGPSAction from './routemapnogps';
import RouteMapForDayWiseDistanceTrip from './routeMapForDayWiseDistanceTrip';
import RouteNightDrivemap from './routenightdrivemap';
import RoutemapenrouteAction from './routemapenroute';
import KpiNightDriveActions from './kpiNightDriveActionsComponent';
import Nonightdrive from './nonightdriveComponent.js';
import KpiEnrouteStoppagesActions from './kpiEnrouteStoppagesActionsComponent';
import kpiDayWiseDistanceActions from './kpiDayWiseDistanceActionsComponent'
import KpiLoadingDelayActions from './kpiLoadingDelayActions';
import TransporterRemarkAction from "./transporterRemarkAction";
import TransporterDriverAction from "./transporterDriverAction";
import KpiUnLoadingDelayActions from './kpiUnLoadingDelayActions';
import AccidentIcon from "./accidenticon";
import DeviationIcon from "./deviationicon";
import BreakDownIcon from './breakdownicon';
import Noenroutes from './noenroutesComponent.js';
import Gps from './gpsActionComponent.js';
import Gpsdata from './gpsdataaction.js';
import NoGps from './nogpsActionComponent.js';
import NoGpsLifetime from './nogpslifetimeActionComponent.js';
import Consignmentforceclose from './consignmentforceclose.js';
import Select from 'react-select';
import ForceCloseSideBar from '../layouts/forceclosuresidebarcomponent.js';
import Modal from 'react-responsive-modal';
import CustomLoadingCellRenderer from "../layouts/customLoadingCellRenderer";
import AppendComponentIndex from '../common/appendComponents';
import { Timeline, TimelineItem } from 'vertical-timeline-component-for-react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
//import Counters from '../layouts/consignmentcountersComponent';
import Counters from '../layouts/consignmentkpicountersComponent';
import DateFormater from '../layouts/dateComponent';
import tollImg from '../../assets/icons/tolls-image.png';
import {
	getDDMMYYYY,
	getDDMMYYYYHHMMSS,
	getYYYYMMDD,
	getYYYYMMDDHHMMSS,
	getHyphenYYYYMMDDHHMMSS,
	getHyphenDDMMMYYYYHHMM,
	getHyphenYYYYMMDD,
	getHyphenDDMMYYYYHHMMSS,
	getHyphenDDMMYYYY,
	getDDMMMYYYYHHMMDefault
} from '../common/utils';
import 'react-vertical-timeline-component/style.min.css';
import DrawMap from '../common/drawmap';
import ConsignmentDrawMap from '../common/consignmentdrawmap';
import ComponentIndex from '../common/appendComponents';
import CustomDateComponent from '../common/dateFilterComponent';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';
import ProbableAction from "../layouts/probableDataComponent";
import Consignmenttickets from "./consignmenttickets";
import AccidentDocs from "./accidentdocs";
import LSPDeliver from  "./lspdeliveraction";
import GpsDataDynamic from './gpsdatadynamic.js';
$.datetimepicker.setLocale('en');
var infoBox = require('../common/google-infowindow');

var redirectURL = require('../redirectURL');
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");

var drawingManager;
var selectedShape;
var colors = ['#1D9567', '#1692A5', '#F4D749', '#F73E3E', '#B7B3B3'];
var selectedColor;
var colorButtons = {};
var fencingLength = 0;
var mapZoom = 12;
var coordinates = [];
var geoFencingArea;
var containerslist = [];

const decipher = salt => {
	const textToChars = text => text.split('').map(c => c.charCodeAt(0));
	const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
	return encoded => encoded.match(/.{1,2}/g)
		.map(hex => parseInt(hex, 16))
		.map(applySaltToChar)
		.map(charCode => String.fromCharCode(charCode))
		.join('');
}
var decode = decipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

export default class ManageConsignments extends Component {

	constructor(props) {
		super(props);
		this.displayData = [];
		this.state = {
			counterTitle:"",
			deptcode: "",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			eventAction: null,
			forceCloseRowNode: "",
			allRowData: [],
			activeFilter: null,
			uploadDivWidth: '0%',
			sliderTranslate: '',
			sliderRouteTranslate: '',
			sliderCommentTranslate: "",
			sliderTranslateAdd:"",
			tabsliderTranslate: '',
			timelineinfo: '',
			consignment_code: '',
			sliderSegmentTranslate: "",
			mapinfo: '',
			commentsRowData: [],
			dealer: '',
			maptruckno: '',
			loadshow: 'show-n',
			showDiv: 'show-n',
			alertshow: 'fade',
			alertmg: 'show-n',
			alerterrmg: 'show-n',
			overly: 'show-n',
			alerterrshow: 'fade',
			alert: null,
			show: false,
			basicTitle: '',
			rownode: '',
			leg_no: '',
			basicType: "default",
			show1:false,
			basicTitle1:"",
			basicType1:"default",
			modules: AllModules,
			filterstatess: {},
			columnsdatas: {},
			rowgroupdcols: [],
			valuecolumns: [],
			activeconsignemnt: [],
			activetrucks: [],
			transitdelays: [],
			gpsdatana: [],
			overspeedtrucks: [],
			nightdrive: [],
			open: false,
			openforceclose: false,
			pivotmodeis: false,
			pivotcols: [],
			defTransitCoords: '',
			routeTruck: {},
			sidebarSubHeader: '',
			googelRoutes: '',
			plants: [],
			bulkPrioritySlide: "",
			hideTransporterBtns: "show-m",
			defaultColDef: {
				sortable: true,
				filter: true,
				resizable: true,
				pivot: true,
				enableValue: true,
				enableRowGroup: true,
			},
			rowData: [],
			maprowData: null,
			rowSelection: 'multiple',
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			overlayNoRowsTemplate: "",
			frameworkComponents: {
				consignmentActions: ConsignmentActions,
				commentActions: CommentActions,
				SegmentAction: SegmentAction,
				kpiActions: KpiActions,
				kpiDeviationActions: KpiDeviationActions,
				kpiTransitDelayActions: KpiTransitDelayActions,
				nooverspeed: Nooverspeed,
				notransit: Notransit,
				kpiNightDriveActions: KpiNightDriveActions,
				nonightdrive: Nonightdrive,
				kpiEnrouteStoppagesActions: KpiEnrouteStoppagesActions,
				kpiDayWiseDistanceActions: kpiDayWiseDistanceActions,
				noenroutes: Noenroutes,
				kpiLoadingDelayActions: KpiLoadingDelayActions,
				kpiUnLoadingDelayActions: KpiUnLoadingDelayActions,
				dateFormater: DateFormater,
				gps: Gpsdata,
				nogps: NoGps,
				noGpsLifetime: NoGpsLifetime,
				RoutemapenrouteAction: RoutemapenrouteAction,
				customLoadingCellRenderer: CustomLoadingCellRenderer,
				consignmentforceclose: Consignmentforceclose,
				customDateComponent: CustomDateComponent,
				ProbableAction: ProbableAction,
				Consignmenttickets: Consignmenttickets,
				TransporterRemarkAction:TransporterRemarkAction,
				TransporterDriverAction:TransporterDriverAction,
				AccidentDocs:AccidentDocs,
				AccidentIcon:AccidentIcon,
				DeviationIcon:DeviationIcon,
				BreakDownIcon:BreakDownIcon,
				LSPDeliver: LSPDeliver,
				GpsDataDynamic: GpsDataDynamic,
			},

			animateRows: true,
			debug: true,
			showToolPanel: false,
			uppressAggFuncInHeader: true,

			childconfs: [],
			childrow: [],
			rowModelType: "serverSide",
			paginationPageSize: 100,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
						statusPanel: "agTotalAndFilteredRowCountComponent",
						align: "left"
					},
					{
						statusPanel: "agTotalRowCountComponent",
						align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
						id: "columns",
						labelDefault: "Columns",
						labelKey: "columns",
						iconKey: "columns",
						toolPanel: "agColumnsToolPanel"
					},
					{
						id: "filters",
						labelDefault: "Filters",
						labelKey: "filters",
						iconKey: "filter",
						toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams: {},
			rowClassRules: {
				//"highlitRow": "data.transit_delay == 1",
				//"green":"data.status >= 3"

			},
			triptype: { "value": 'all', "label": 'All' },
			tripvalue: '',
			consigner: [{ "value": 'all', "label": 'All' }],
			originalData: [],
			isCounterDisplay: 1,
			screenpage: '',
			pagetitle: 'Active Consignments',
			countersjson: '',
			originalcountersjson: '',
			consigneecoords: '',
			consigner_coordinates: '',
			containerslist: [],
			forceclosedata: '',
			reached_dealer_time: moment.parseZone().format('YYYY-MM-DD'),
			reachedhh: '',
			reachedmm: '',
			reachedss: '00',
			left_dealer_time: moment.parseZone().format('YYYY-MM-DD'),
			lefthh: '',
			leftmm: '',
			leftss: '00',
			invoice_time: '',
			invoicehh: '',
			invoicemm: '',
			segementConsignmentCode: "",
			counter_screen: "",
			priority_counter_display: 0,
			invoicess: '00',
			reasonforceclose: '',
			sliderTranslatesidebar: '',
			consignment_details: '',
			startDatetime: '',
			endDatetime: '',
			tolls: [],
			excelStyles: [
				{
					id: "dateFormat",
					dataType: 'dateTime',
					numberFormat: { format: "yyyy-mm-dd" }
				}
			],
			getRowHeight: function (params) {
				if (typeof params.data.dct_comment != 'undefined') {
					//console.log("pars ", params.data.dct_comment.length)
					return (
						//params.api.getSizesForCurrentTheme().rowHeight *
						(100 * Math.floor(params.data.dct_comment.length / 50))
					);
				}
				if (typeof params.data.vin_list != 'undefined') {
					//console.log("pars ", params.data.vin_list.toString().length)
					return (
						//params.api.getSizesForCurrentTheme().rowHeight *
						(100 * Math.floor(params.data.vin_list.toString().length / 50))
					);
				}
				else if (typeof params.data.force_closure_reason != 'undefined') {
					//console.log("pars ", params.data.dct_comment.length)
					return (
						//params.api.getSizesForCurrentTheme().rowHeight *
						(100 * Math.floor(params.data.force_closure_reason.length / 50))
					);
				}
				else {
					return 30;
				}
			},
			defaultsdate: '',
			defaultedate: '',
			movementtype: { "value": "all", "label": "All" },
			usergridstate: [],
			screenurl: "",
			screentitle: "",
			showumncr: 0,
			showummove: 0,
			usermanualncrmodal: false,
			usermanualmovemodal: false,
			slideuploadeway: "",
			cancelled_data: [],
			pod_received_data: [],
			trip_closed_by_pod: [],
			returnConsignments: [],
			tnpButtonTitle: "",
			tnpReturnConsignmentsVisibilty: false,
			consBtn: "btn-danger",
			returnConsBtn: "btn-default",
			truckReportedDate: [],
			filterCities: [],
			isValidMobile : true,
			filterStates: [],
			filterDealers: [],
			filterClusters: [],
			filterZones: [],
			filterTransporters: [],
			beforeNewFilterRowData: [],
			beforeNewFiltercountersjson: [],
			flCity: "",
			flState: "",
			flCluster: "",
			flDealer:"",
			flZone: "",
			flTransporter: {value:"",label:"Choose Transporter"},
			sliderCreateConsignmentTranslate: "",
			devation_flag: false,
			truck_no: { "value": "Select Truck", "label": "Select Vin No" },
			plant_code: { "value": "Select Plant", "label": "Select Plant" },
			grno_consignment_code: "",
			invoice_no: "",
			invoice_date: "",
			gate_out_time: "",
			consignee_code: "",
			trucklist: [],
			plantlist: [],
			dept_code: "",
			consignees: [],
			loginplant: "",
			selectedFilter: [],
			baseRouteCoords: "",
			withoutTruckGateOutTimeData :[],
			forceClosed : [],
			trucks:[],
            dealers:[],
			truckitem:{"value":"","label":"Select Vin"},
			dealeritem:{"value":"","label":"Select Dealer"},
			plantitem:{"value":"","label":"Select Plant"},
			transporter:{"value":"","label":"Select"},
			transporterslist:[],
			shower:"show-n",
			dealer_city:"",
			dealer_location:"",
			parentDealers:[],
			parentdealer:{"value":"","label" : "Select Parent Dealer"},
			date_type:{value:"all",label:"All"},
			customer_code:"",
			csvcontent:[],
			csvcontentbulk:[],
			uploadFileBulk:"",
			allBtn:"btn-default",
			intransitBtn:"btn-info",
			deliveredBtn:"btn-default",
			tpt_remarks:"",
			transitrowdata:[],
			sliderTPTRemarkTranslate:"",
            sliderTPTDeviationTranslate:"",
			sliderTPTDriverTranslate:"",
			tpt_driver_name:"",
			tpt_driver_mobile:"",
			tpt_convoy_leader_name:"",
			tpt_convoy_leader_mobile:"",
			tpt_deviation_location:"",
			tpt_deviation_city:"",
			tpt_deviation_address:"",
			tpt_deviation_zone:"",
			tpt_deviation_postal_code:"",
			tpt_approved_deviation_no:"",
			ship_to_party_code:"",
			ship_to_party_name:"",
			tpt_deviation_status:"No",
			accident_exception:"No",
			overlyFixed:"show-n",
			showfirfield:"show-n",
			tpt_accident_fir_status:[{value:"No",label:"No"},{value:"Yes",label:"Yes"}],
			tpt_accident_a3_report_submission:{value:"No",label:"No"},
			tpt_accident_damage_vehicle:"",
			tpt_accident_scenario:"",
			tpt_accident_third_party_damage:"",
			tpt_accident_vehicle_current_status:"",
			tpt_accident_fir_date:"",
			uploadDocs:'',
			allDocs:[],
			sliderTPTAccidentDocuments:"",
			sliderTPTConfirmDeliver:"",
			accidentDocuments:[],
			sliderTPTBreakdownTranslate:"",
			tpt_break_down_location:"",
			tpt_break_down_consignee_code:{value:"",label:"Select"},
			tpt_break_down_consignee_name:"",
			total_accidents:[],
			total_deviations:[],
			drivercontent:[],
			driverfile:"",
			sliderBulkDriverUpdate:"",
			deviations:[],
			deviation:{value:"",label:"Select"},
			alldeviations:[],
			tpt_approved_deviation_no:"",
			body_shop:{value:"",label:"Select"},
			ship_to_party:{value:"",label:"Select"},
			bodyshops:[],
			tpt_confirm_deliver_remarks:"",
			tpt_confirm_rowid:"",
			tpt_confirm_delivered_location:{value:"",label:"Select"},
			tpt_confirm_delivered_other_location:"",
			otherinp:"show-n",
			tpt_deviation_delivered_at:{value:"",label:"Select"},
			showDealerDeviate:"show-n",
			showDealerDeviateother:"show-n",
			TPTdeviationZones:[{value:"Central",label:"Central"},{value:"East",label:"East"},{value:"West",label:"West"},{value:"North",label:"North"},{value:"South",label:"South"},],
			filteredOptions: [], // Filtered options based on input
			inputValue: '', // Store the input value for dynamic filtering
			
		}
	
		this.onShowRouteDiv = this.onShowRouteDiv.bind(this);
		this.onShowCommentsDiv = this.onShowCommentsDiv.bind(this);
		this.changeSegmentHandler = this.changeSegmentHandler.bind(this);
		this.onShowTimelineDiv = this.onShowTimelineDiv.bind(this);
		this.onShowRouteMap = this.onShowRouteMap.bind(this);
		this.onShowTransitRouteMap = this.onShowTransitRouteMap.bind(this);
		this.onShowNoGPSRouteMap = this.onShowNoGPSRouteMap.bind(this);
		this.onShowGoogleRoute = this.onShowGoogleRoute.bind(this);
		this.selectConsignerOptionsItems = this.selectConsignerOptionsItems.bind(this);
		this.onShowEnrouteRouteMap = this.onShowEnrouteRouteMap.bind(this);
		this.onClickShowMapView = this.onClickShowMapView.bind(this);
		this.onClickForceClose = this.onClickForceClose.bind(this);
		this.changeHandler = this.changeHandler.bind(this);
		this.changeConsignmentHandler = this.changeConsignmentHandler.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.closeSegmentSideBar = this.closeSegmentSideBar.bind(this);
		this.onLoadGetConsignmentsList = this.onLoadGetConsignmentsList.bind(this);
		this.resetState = this.resetState.bind(this);
		this.onShowConsignmentTickets = this.onShowConsignmentTickets.bind(this);
		this.onShowUploadEWay = this.onShowUploadEWay.bind(this);
		this.onBulkPriorityBtn = this.onBulkPriorityBtn.bind(this);
		this.uploadEway = this.uploadEway.bind(this);
		this.changeFileHandler = this.changeFileHandler.bind(this);
		this.changeFileHandlerBulk = this.changeFileHandlerBulk.bind(this);
		this.changeDocumentHandler = this.changeDocumentHandler.bind(this);
		this.onClickCounterShowData = this.onClickCounterShowData.bind(this);
		this.saveBulkConsignmentData = this.saveBulkConsignmentData.bind(this);
		this.formTPTAccident = this.formTPTAccident.bind(this);
		this.onShowAccidentForm = this.onShowAccidentForm.bind(this);

		this.cipher = this.cipher.bind(this);
		this.onChangeTPTDvZone = this.onChangeTPTDvZone.bind(this);
	}
	cipher = (salt) => {
		const textToChars = text => text.split('').map(c => c.charCodeAt(0));
		const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
		const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
		try {
			return text => text.split('')
				.map(textToChars)
				.map(applySaltToChar)
				.map(byteHex)
				.join('');
		}
		catch (e) {

		}

	}


	componentDidCatch(error, info) {
		console.log("Error here ", error)
		console.log("Error Info here ", info)
	}
	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
			} else {
				console.log("GA disabled...");
			}
		} catch (error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}
	componentDidMount() {
		loadDateTimeScript();
		var customer_code = "";
		try{
			if(localStorage.getItem("customer_code") != "" && localStorage.getItem("customer_code") != undefined && localStorage.getItem("customer_code") != "undefined")
			{
				customer_code = localStorage.getItem("customer_code");
			}
			else{
				customer_code = "";
			}
			this.setState({
				customer_code:customer_code
			})
		}
		catch(e){

		}
		redirectURL.post("/masters/getAllTransporters")
		.then((response) => {
			var records = response.data;
			var transporterslist = []
			if(records.length > 0)
			{
				records.map((tm) => {
					transporterslist.push({
						value:tm.transporter_code,
						label:tm.transporter_name+"-"+tm.transporter_code
					})
				})
			}
			this.setState({
				transporterslist:transporterslist
			})
			
		})
		redirectURL.post("/consignments/parentDealers")
		.then((response) => {
			var records = response.data.records;
			this.setState({
				parentDealers:records
			})
			
		})
		redirectURL.post("/masters/snddealerslist")
		.then((response) => {
			var records = response.data.records;
			var dealers = []
			if(records.length > 0)
			{
				records.map((tm) => {
                    dealers.push({
                        value:tm.consignee_code,
                        label:tm.consignee_name+"-"+tm.consignee_code
                    })
                })
			}
			this.setState({
				dealers:dealers
			})
			
		})
		redirectURL.post("/masters/bodyshops")
		.then((response) => {
			var records = response.data.records;
			var bodyshops = []
			if(records.length > 0)
			{
				records.map((tm) => {
                    bodyshops.push({
						value:tm.geofence_name,
						label:tm.geofence_name
					})
                })
			}
			this.setState({
				bodyshops:bodyshops
			})
			
		})
		
		var loguser = decode(localStorage.getItem("m"));
		// console.log("LOGUSER ", JSON.parse(loguser));
		var loginplant = "";
		try {
			var luser = JSON.parse(loguser);
			loginplant = luser.is_plant_code;
			// console.log("logplant", loginplant)
			if (loginplant !== "" && loginplant !== undefined) {
				this.setState({
					loginplant: loginplant,
					consigner: [{ "value": luser.is_plant_code, "label": luser.is_plant_name }]

				})
			}
		}
		catch (e) {

		}
		var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
		var edate = moment.parseZone().format('YYYY-MM-DD');
		// if (this.props.match.path.includes("deliveredconsignments") || this.props.match.path.includes("allconsignments")) {
		// 	var sdate = moment.parseZone().subtract(7, 'days').format('YYYY-MM-DD');
		// }
		// else {
			var sdate = moment.parseZone().subtract(1, 'month').format('YYYY-MM-DD');
			// var sdate = '2022-09-01';
		// }


		// var sdate = '2020-03-01';

		var edatetime = moment.parseZone().format('DD-MM-YYYY HH:mm');
		var sdatetime = moment.parseZone().format('DD-MM-YYYY HH:mm');
		this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		this.setState({ eventAction: eventAction });
		//console.log("Props ", this.props.match)
		this.setState({
			loadshow: 'show-m',
			startDate: sdate,
			endDate: edate,
			defaultsdate: sdate,
			defaultedate: edate,
			startDatetime: sdatetime,
			endDatetime: edatetime
		});
		var urlpath = '/consignments/activetransporterconsignments';
		var dpt = "SNDG";
		var isCounterDisplay = 1;
		var screenpage = '';
		var title = '';

		var consignData = {}
		if (loginplant != "" && loginplant != undefined) {
			var consigners = [{ "value": loginplant, "label": loginplant }]
		}
		else {
			var consigners = this.state.consigner;
		}
		// if (this.props.match.path == "/sndtransporterconsignments") {

			urlpath = '/consignments/activetransporterconsignments';
			dpt = 'SNDG';
			title = 'Consignments Dashboard';

			var consignData = {
				dept_code: encode(dpt),
				startDate: sdate,
				endDate: edate,
				screen:'all',
				consigner: consigners,
				transporter_code:localStorage.getItem("transportercode")
			}
			this.setState({
				counter_screen: "active",
				startDate: sdate,
				endDate: edate,
				flTransporter:{value:localStorage.getItem("transportercode")}
			});
		// }
		
		this.setState({
			deptcode: dpt,
			isCounterDisplay: isCounterDisplay,
			screenpage: screenpage,
			pagetitle: title
		});


		redirectURL.post("/consignments/getConsignmentsNewFilterKeys", { dept_code: encode(dpt) })
			.then((resp) => {
				var dealers = resp.data.consigneeName;
				var filterDealers = [{value:"",label:"All"}];
				if (dealers.length > 0) {
					dealers.map(function (c) {
						filterDealers.push({
							label: c.consignee_name+"-"+c.consignee_code,
							value: c.consignee_code,
						});
					});
				}

				var cities = resp.data.cities;
				var filterCities = [{value:"",label:"All"}];
				if (cities.length > 0) {
					cities.map(function (c) {
						filterCities.push({
							label: c,
							value: c,
						});
					});
				}
				var states = resp.data.states;
				var filterStates = [{value:"",label:"All"}];
				if (states.length > 0) {
					states.map(function (s) {
						filterStates.push({
							label: s,
							value: s,
						});
					});
				}
				var clusters = resp.data.clusters;
				var filterClusters = [{value:"",label:"All"}];
				if (clusters.length > 0) {
					clusters.map(function (cl) {
						filterClusters.push({
							label: cl,
							value: cl,
						});
					});
				}
				var zones = resp.data.zones;
				var filterZones = [{value:"",label:"All"}];
				if (zones.length > 0) {
					zones.map(function (cl) {
						filterZones.push({
							label: cl,
							value: cl,
						});
					});
				}
				var transporters = resp.data.transporters;
				var filterTransporters = [{value:"",label:"All"}];
				if (transporters.length > 0) {
					transporters.map(function (t) {
						filterTransporters.push({
							label: t,
							value: t,
						});
					});
				}
				this.setState({
					filterCities: filterCities,
					filterStates: filterStates,
					filterClusters: filterClusters,
					filterZones: filterZones,
					filterTransporters: filterTransporters,
					filterDealers:filterDealers
				});
			})

		redirectURL.post("/consignments/plants", { dept_code: encode(dpt) })
			.then((resp) => {
				this.setState({
					plants: resp.data
				});
			})


		redirectURL.post("/consignments/usergridstates", { userId: localStorage.getItem("userid"), screenurl: window.location.pathname })
			.then(async (resp) => {
				//console.log("resp ", resp)
				await this.setState({
					usergridstate: resp.data
				});
				await this.restoreGridStates();
			})
		// console.log("urlPath:", urlpath, "consignData:", consignData)

		setTimeout(() => {
			this.onLoadGetConsignmentsList(urlpath, consignData);
		},500)
		
		redirectURL.post("/dashboard/sndconsignees")
			.then((response) => {
				var records = response.data.records;
				var consignees = []
				if (records.length > 0) {
					records.map((item) => {

						consignees.push({
							value: item.consignee_code,
							label: item.consignee_name+"-"+item.consignee_code+"-"+item.consignee_city
						})
					})
				}
				this.setState({
					consignees: consignees
				})
			})
		var upath = "/masters/deviations";
	
		var parameters = {};
		redirectURL.post(upath,parameters)
		.then(
			(response)=>{
				let records = (response.data.records) ? response.data.records : [];
				var recordsarr =[{value:"",label:"Select"}]
				if(records.length > 0)
				{
					records.map((item) => {
						recordsarr.push({value:item.deviation_code,label:item.deviation_name})
					})
				}
				this.setState({
					deviations:recordsarr,
					alldeviations:records
				});
			}
		)
		.catch(function(error){
			console.log(error);
		});

	};

	getWithoutGateoutTime = (consignData) =>{
		redirectURL.post('/consignments/prtconsignmentsNoGateOut', consignData).then( response =>{
			// console.log("responseNoGateOut", response.data)
			var withoutTruckGateOutTimeData = response.data.consignments;
			this.setState({
				withoutTruckGateOutTimeData
			})
			// var pagecounters = response.data.counters;
		})
	}


	onLoadGetConsignmentsList(urlpath, parameters) {
		this.setState({
			loadshow: 'show-m',
			overly: 'show-m',
			total_accidents:[]
		})
		// console.log("this.state.loginplant ", this.state.loginplant)

		// console.log(parameters);
		parameters.date_type = this.state.date_type.value;
		redirectURL.post(urlpath, parameters)
			.then((response) => {
				// console.log("response", response.data)
				var recordsc = response.data.consignments;
				var records = [];
				if(recordsc.length > 0)
				{
					recordsc.map((item) => {
						
						if(item.accident_exception === 1)
						{
							item.accident_exception_text = "Yes"
						}
						else{
							item.accident_exception_text = "No"
						}
						records.push(item);

					})
				}
				var gridData = records;
				var pagecounters = response.data.counters;
				var returnConsignments = (response.data.returnConsignments != undefined) ? response.data.returnConsignments : []
				pagecounters.cancelled_count = "Not Applicable"
				pagecounters['deviated_routes'] = records.filter(e => e.deviation_flag == 1)
				// console.log("recordsLength", records.length);
				var cancelled_data = []
				var pod_received_data = [];
				var trip_closed_by_pod = [];
				//console.log("counters", pagecounters);
				// records = records.sort(GetSortDescOrder("_id"));
				records = records.sort(GetSortDescOrder("invoice_time_seconds"));
				// if (this.state.loginplant !== "" && this.state.loginplant !== undefined) {
				// 	records = records.filter(e => e.consigner_code == this.state.loginplant);
				// 	console.log("loginplant",this.state.loginplant)
				// 	console.log("0")
				// }
				if (records.length > 0) {

					if (this.props.match.path == "/sndallconsignments"
						|| this.props.match.path == "/prtallconsignments"
						|| this.props.match.path == "/tnpallconsignments") {

						if (this.props.match.path == "/prtallconsignments") {
							cancelled_data = records.filter(function (e) {
								return e.cancelled == 1
							})
							pagecounters.cancelled_count = cancelled_data.length
							pod_received_data = records.filter(function (e) {
								return e.pod_received == 1
							})
							pagecounters.pod_received_data = pod_received_data.length
							trip_closed_by_pod = records.filter(function (e) {
								return e.trip_closed_by_pod == 1
							})
							// console.log(trip_closed_by_pod, "trip_closed_by_pod")
							pagecounters.trip_closed_by_pod = trip_closed_by_pod.length
						}

					}
					else if (this.props.match.path == "/ncrconsignments"
						|| this.props.match.path == "/moveconsignments") {

						records = records.filter(rec => rec.status <= 3)
					}
					else if (this.props.match.path == "/snddeliveredconsignments"
						|| this.props.match.path == "/prtdeliveredconsignments"
						|| this.props.match.path == "/tnpdeliveredconsignments") {
						if (this.props.match.path == "/prtdeliveredconsignments") {
							pod_received_data = records.filter(function (e) {
								return e.pod_received == 1
							})
							pagecounters.pod_received_data = pod_received_data.length
							trip_closed_by_pod = records.filter(function (e) {
								return e.trip_closed_by_pod == 1
							})
							pagecounters.trip_closed_by_pod = trip_closed_by_pod.length
							cancelled_data = records.filter(function (e) {
								return e.cancelled == 1
							})
							pagecounters.cancelled_count = cancelled_data.length
							var forceClosed = []
							records = records.filter(rec => {
								if(rec.status == 6){
									// console.log('status', rec)
									forceClosed.push(rec)
								}else{
									return rec.status > 3
								}
							})

						}else{
							records = records.filter(rec => rec.status > 3)
						}
					}
					else {

						records = records.filter(rec => rec.status >= 1)
						if (this.props.match.path == "/prtconsignments") {
							pod_received_data = records.filter(function (e) {
								return e.pod_received == 1
							})
							pagecounters.pod_received_data = pod_received_data.length
							trip_closed_by_pod = records.filter(function (e) {
								return e.trip_closed_by_pod == 1
							})
							pagecounters.trip_closed_by_pod = trip_closed_by_pod.length
						}
					}
				}
				var trucksReported = [];
				if (this.props.match.path == "/prtconsignments" || this.props.match.path == "/prtdeliveredconsignments" || this.props.match.path == "/prtallconsignments") {
					trucksReported = records.filter(function (e) {
						return (e.truck_reporting_date !== undefined && e.truck_reporting_date !== "")
					})
				}
				//console.log("records ", records.filter(e=> e.tpt_confirm_delivery == 1))
				
				// console.log("gridlenBefore",gridData.length)
				var selectedCity = $("#selectedCity").text();
				var selectedState = $("#selectedState").text();
				var selectedCluster = $("#selectedCluster").text();
				var selectedTransporter = $("#selectedTransporter").text();
				// console.log(selectedCity,selectedState,selectedCluster,selectedTransporter,"filters")
				//console.log(filterRowData,"0")
				if (selectedCity !== undefined && selectedCity !== '' && selectedCity !== "Select...") {
					gridData = gridData.filter(e => e.consignee_city == selectedCity);
					// console.log("1")
				}
				if (selectedState !== undefined && selectedState !== '' && selectedState !== "Select...") {
					gridData = gridData.filter(e => e.consignee_state == selectedState);
					// console.log("2")

				}
				if (selectedCluster !== undefined && selectedCluster !== '' && selectedCluster !== "Select...") {
					// console.log("selectedCluster",selectedCluster)
					// console.log("3")
					// gridData = gridData.filter(e => e.cluster == selectedCluster);
					gridData = gridData.filter(e => {
						if (e.zone == selectedCluster) {
							// console.log("ecluster",e.cluster)
							return e
						}
					});
				}
				if (selectedTransporter !== undefined && selectedTransporter !== '' && selectedTransporter !== "Select...") {
					// console.log("selectedCluster",selectedTransporter)
					// console.log("4")
					// gridData = gridData.filter(e => e.transporter_name == selectedTransporter);
					gridData = gridData.filter(e => {
						if (e.transporter_name == selectedTransporter) {
							// console.log("transporter_name",e.transporter_name)
							return e
						}

					});
				}
				if (this.props.match.path == "/prtconsignments") {
						// console.log('checkinging')
						records = records.filter((item) =>item.no_gps_data !== 1)
				}
				// console.log("gridData",gridData)
				// console.log("gridData",gridData.length)
				var recordsarr = [];
				if(records.length > 0)
				{
					try{
						if(this.state.customer_code != "")
						{
							records.map((item) => {
								if(item.consignee_code == this.state.customer_code)
								{
									recordsarr.push(item);		
								}
							})
						}
						else{
							recordsarr = records;
						}
					}
					catch(e){}
					
				}
				var accidents = [];
				var deviations = [];
				if(recordsarr.length > 0)
				{
					recordsarr.map((item) => {
									
						if(item.accident_exception !== '' && item.accident_exception !== undefined)
						{
							if(item.accident_exception === 1)
							{
								accidents.push(item);
							}
						}
						if(item.tpt_deviation_status !== '' && item.tpt_deviation_status !== undefined)
						{
							if(item.tpt_deviation_status === "Yes")
							{
								deviations.push(item);
							}
						}
						
					})
				}
				this.setState({
					rowData: recordsarr,
					allRowData: gridData,
					originalData: gridData,
					beforeNewFilterRowData: gridData,
					loadshow: 'show-n',
					overly: 'show-n',
					countersjson: pagecounters,
					originalcountersjson: pagecounters,
					beforeNewFiltercountersjson: pagecounters,
					cancelled_data: cancelled_data,
					trip_closed_by_pod: trip_closed_by_pod,
					pod_received_data: pod_received_data,
					returnConsignments: returnConsignments,
					consBtn: "btn-danger",
					returnConsBtn: "btn-default",
					truckReportedDate: trucksReported,
					forceClosed,
					total_accidents:accidents,
					total_deviations:deviations,
				});
				// if(this.state.urlpath == '/consignments/filterTNPActiveConsignments')
				// {

				// }
				// else
				// {
				// 	this.setState({

				// 	})
				// }


			})
			.catch(function (error) {
				console.log(error);
			});
	}

	onShowTimelineDiv = (e) => {
		//console.log("Props data ", e)
		this.setState({
			loadshow: 'show-m'
		});
		var reqData = {
			consignment_code: e.consignment_code
		}
		//console.log("reqData: ", reqData);
		redirectURL.post('/consignments/timeline', reqData, {
			headers: {
				'content-type': 'application/json'
			}
		})
			.then((response) => {
				var records = response.data;
				//console.log("records", records);

				this.setState({
					uploadDivWidth: '30%',
					sliderTranslate: "slider-translate-50p",
					showDiv: 'show-m',
					timelinedata: records,
					loadshow: 'show-n',
					overly: 'show-m'
				});
			})
			.catch(function (error) {
				console.log(error);
			});

	}

	onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth: '0%',
			sliderTranslate: "",
			tabsliderTranslate: '',
			showDiv: 'show-n',
			sliderTranslatesidebar: "",
			sliderCommentTranslate: "",
			uploadFile: '',
			file: '',
			csvmessage: '',
			csverrmessage: '',
			overly: 'show-n',
			loadshow: 'show-n',
			slideuploadeway: ""
		});
	}
	onShowGoogleRoute(consignmentcode, consigneecode, legno, truck_no) {
		// 	console.log("GOogle params ", consignmentcode+", "+ consigneecode+", "+legno)
		// if (googleAnalytics.page.enableGA) {
		// 	let pageTitle = this.state.pagetitle;
		// 	let eventOptions = {
		// 		"category": pageTitle,
		// 		"action": this.state.eventGridAction,
		// 		"label": googleAnalytics.page.action.viewRoute,
		// 	}
		// 	googleAnalytics.logEvent(eventOptions);
		// }

		// this functionality is missing in the python flask so we are commmenting the code here

		var reqData = {
			consignment_code: consignmentcode,
			consignee_code: consigneecode,
			leg_no: legno,
			truck_no: truck_no
		}
		// redirectURL.post('/consignments/googlemaproutes', reqData, {
		// 	headers: {
		// 		'content-type': 'application/json'
		// 	}
		// })
		// 	.then((response) => {
		// 		// console.log("Google ", response.data)
		// 		//var gdt = '[{	"lat": 28.367420000000003,	"lng": 76.90442},{	"lat": 28.379795,	"lng": 76.903625},{	"lat": 28.380361999999998,	"lng": 76.899445},{	"lat": 28.383162,	"lng": 76.90275},{	"lat": 28.384619,	"lng": 76.900024},{	"lat": 28.385069,	"lng": 76.900024},{	"lat": 28.384990000000002,	"lng": 76.8998}]'
		// 		this.setState({
		// 			googelRoutes: response.data.googleroute
		// 		})
		// 	})
		// 	.catch(function (e) {
		// 		console.log(e)
		// 	})
	}
	onShowCommentsDiv = async (params) => {
		// console.log(params);
		var reqparams = {
			consignment_code: params.data.consignment_code,
			truck_no: params.data.truck_no,
			dept_code: this.state.deptcode,
			screen: "consignments",
			type: "Force Close"
		}
		console.log(reqparams);

		let eventLabel = googleAnalytics.page.action.comments;
		let eventOptions = {
			"category": this.state.pagetitle,
			"action": this.state.eventAction,
			"label": eventLabel,
		}
		googleAnalytics.logEvent(eventOptions);

		var commentHeaders = [
			{
				headerName: "Vin No",
				field: "truck_no",
				resizable: true,
				width: 100,
				filter: true
			},
			{
				headerName: "GR No",
				field: "consignment_code",
				resizable: true,
				width: 120,
				filter: true
			},
			{
				headerName: "User Type",
				field: "user_type",
				width: 100,
				resizable: true,
				filter: true,
				valueGetter: function (params) {
					if (params.data.user_type == "ORGUSER") {
						return "MSIL"
					}
					if (params.data.user_type == "DCTUSER") {
						return "DCT"
					}
					if (params.data.user_type == "TRANSPORTER") {
						return "TRANSPORTER"
					}
				}
			},
			{
				headerName: "Comment",
				field: "comment",
				width: 250,
				cellClass: ['wraptext'],
				resizable: true,
				filter: true
			},
			{
				headerName: "Commented By",
				field: "username",
				width: 150,
				resizable: true,
				filter: true
			},

			{
				headerName: "Commented Date",
				field: "comment_date",
				resizable: true,
				filter: true,
				width: 150,
				valueGetter: function (params) {
					if (params.data.comment_date != "") {
						return getDDMMYYYYHHMMSS(params.data.comment_date);
					}
				}
			}
		]
		if (params != 0) {
			var overspeedData = [];

			//console.log("Step 3 ",overspeedData)
			var propsdata = params;
			var deptcode = this.state.deptcode;
			await this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: commentHeaders,
						overlayNoRowsTemplate: 'No rows to show',

					},
					getDetailRowData: async function (param) {
						param.successCallback([]);
						// console.log("Step 4 ", param)
						// console.log("reqparams", reqparams);
						await redirectURL.post("/consignments/getCommentLogs", {
							consignment_code: param.data.consignment_code,
							truck_no: param.data.truck_no,
							dept_code: deptcode,
							screen: "consignments",
							type: "Force Close"
						}).then(async (response) => {
							//console.log("Step 1 ",response.data)
							// console.log(response.data.data);
							var comments = response.data.data;
							param.successCallback(comments);


							//console.log("Step 2 ",response.data)

						}).catch(function (error) {
							console.log(error);
						})

					},
					masterDetail: true
				}
			});
			// console.log(propsdata);
			if (params.column.colDef.field == 'comment_exists') {

				params.node.setExpanded(!params.node.expanded);
			}
			else {

				params.node.setExpanded(false);
			}

		}
		else {
			this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: commentHeaders

					},
					getDetailRowData: function (params) {
						//console.log("Child ", params);
						params.successCallback([]);
					}
				}
			});
		}
	}
	onShowRouteDiv = (e) => {
		if (googleAnalytics.page.enableGA) {
			let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}

		//console.log("IN onShowRouteDiv, Rute params ", e)
		this.setState({
			loadshow: 'show-m',
			maptruckno: e.truck_no
		});

		// If gateouttime is current time - It shows "No Route data available"
		if (e.invoice_time) {
			// console.log("came into invoice time", e.invoice_time)
			//console.log("Step1", e.invoice_time)
			var gateouttime = moment.parseZone(e.invoice_time).format('YYYY-MM-DD HH:mm:ss');
		}
		else {
			//console.log("Step2")
			if (e.gate_in_time) {
				// console.log("came into gate in time", e.gate_in_time)
				//console.log("Step3")
				var gateouttime = moment.parseZone(e.gate_in_time).format('YYYY-MM-DD HH:mm:ss');
			}
			else {
				//console.log("Step4")
				if (e.gate_out_time) {
					//console.log("Step5")
					// console.log("came into gate out time", e.gate_out_time)
					var gateouttime = moment.parseZone(e.gate_out_time).format('YYYY-MM-DD HH:mm:ss');
				}
			}
		}

		// if (e.gate_out_time) {
		// 	var gateouttime = e.gate_out_time.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		// }

		var edate = '';
		var end_date;
		if (e.status <= 2) {
			//var ms = new Date(e.gate_out_time).getTime() + ((e.transit_time+1) * 86400000);
			//var edatesecond = new Date(ms);
			// console.log("status less than 2");
			end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss')
			edate = getHyphenDDMMYYYYHHMMSS(end_date);
		}
		else if (e.status == 3 || e.status == 4) {
			if (e.recent_dealer_reported) {
				end_date = e.recent_dealer_reported.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
				edate = getHyphenDDMMYYYYHHMMSS(e.recent_dealer_reported);
			}
			// console.log("status 3 and 4");
		}
		else if (e.status == 5) {
			if (e.left_dealer_marked) {
				end_date = e.left_dealer_marked.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
				edate = getHyphenDDMMYYYYHHMMSS(e.left_dealer_marked);
			}
			// console.log("status 5");
			console.log()
		}
		else if (e.status == 6) {
			if (e.force_closure_time) {
				let splitT = e.force_closure_time.split('T')
				let yMD = splitT[0]
				let hMS = splitT[1]
				let hms1 = hMS.split('.')
				// console.log('hms1',hms1)
				let finalHms = hms1[0]
				let final_end_date = yMD + ' ' + finalHms
				// end_date = e.force_closure_time.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
				end_date = final_end_date;
				// edate = getHyphenDDMMYYYYHHMMSS(e.force_closure_time);
				edate = final_end_date
			}
			// console.log("status 6");
		}
		else {
			end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss')
			edate = getHyphenDDMMYYYYHHMMSS(end_date);
			// console.log("in else condition");
		}

		//console.log("end date",end_date);
		//console.log("checking e",e)

		if ((e.transit_time) > 0) {
			var seconddate = new Date(new Date(e.gate_out_time).getTime() + ((e.transit_time + 1) * 24 * 60 * 60 * 1000));
		}
		else {
			var seconddate = new Date(new Date(e.gate_out_time).getTime() + 2 * 24 * 60 * 60 * 1000);
		}

		var sdate = seconddate.getFullYear() + "-" + (seconddate.getMonth() + 1) + "-" + seconddate.getDate() + " " + seconddate.getHours() + ":" + seconddate.getMinutes() + ":" + seconddate.getSeconds();

		if (!end_date) {
			// When end date is unavailable then current date is considered as end date.
			// end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
			//console.log("Defining end_date ", end_date);
		}
		var reqData = {
			truck_no: e.truck_no,
			invoice_no: e.invoice_no,
			consignee_code: e.consignee_code,
			gate_out_time: gateouttime,
			seconddate: end_date,
			screen: "consignment"
		}
		if (["2", "3", "4"].includes(e.status)) reqData.mapMode = 2

		// console.log("reqData: ", reqData);
		redirectURL.post('/consignments/maproutes', reqData, {
			headers: {
				'content-type': 'application/json'
			}
		})
			.then((response) => {
				var records = response.data;
				// console.log("Props data ", records)

				this.setState({
					loadshow: 'show-m'
				});
				try {
					if (records.coords.length == 0 || records.status == "failure") {
						//console.log("records", records.coords);
						this.setState({
							show: true,
							basicTitle: 'No Route data available',
							basicType: "danger",
							loadshow: 'show-n'
						});
					}
					else {
						if (records != '') {
							var sdate = e.gate_out_time;
							if (e.invoice_time) {
								// console.log("came into invoice time", e.invoice_time)
								//console.log("Step1", e.invoice_time)
								var sdate = e.invoice_time;
							}
							else {
								//console.log("Step2")
								if (e.gate_in_time) {
									// console.log("came into gate in time", e.gate_in_time)
									//console.log("Step3")
									var sdate = e.gate_in_time;
								}
								else {
									//console.log("Step4")
									if (e.gate_out_time) {
										//console.log("Step5")
										// console.log("came into gate out time", e.gate_out_time)
										var sdate = e.gate_out_time;
									}
								}
							}


							var edate = '';
							if (e.status <= 4) {
								//var ms = new Date(e.gate_out_time).getTime() + ((e.transit_time+1)*86400000);
								//var edatesecond = new Date(ms);
								end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss')
								edate = getHyphenDDMMYYYYHHMMSS(end_date);
								// console.log("End Date status4 ", edate)

							}
							else if (e.status == 4) {
								// console.log("end_time", e)
								edate = getHyphenDDMMYYYYHHMMSS(e.end_time);
							}
							else if (e.status == 5) {
								if (e.left_dealer_marked) {
									edate = getHyphenDDMMYYYYHHMMSS(e.left_dealer_marked);
									// console.log("End Date if one ", edate)

								}
								else {
									end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss')
									edate = getHyphenDDMMYYYYHHMMSS(end_date);
									// console.log("End Date else one", edate)
								}
							}
							// console.log("End Date ", edate)
							if (e.deviation_flag == 1 && this.state.deptcode == "LOG-PRT") {
								var params = {
									"base_route_id": e.base_route_id
								}
								redirectURL.post("/consignments/getLiveRouteDeviationBaseRoute",
									params).then((resp) => {
										this.setState({
											sliderRouteTranslate: "slider-translate-60p",
											showDiv: 'show-m',
											mapinfo: records,
											dealer: e.consignee_code,
											consignment_code: "Serial No : " + e.invoice_no,
											maptruckno: e.truck_no,
											routeTruck: { "truck_no": e.truck_no, "startTime": sdate, "endTime": end_date },
											loadshow: 'show-n',
											sidebarSubHeader: "Consignment Information",
											overly: 'show-m',
											rownode: e,
											leg_no: 0,
											tolls: records.tolls,
											baseRouteCoords: resp.data.alteredHeroPRTBaseRoute,
											devation_flag: true
										});
										this.onShowGoogleRoute(e.invoice_no, e.consignee_code, 0, e.truck_no)
										this.renderMap();
									})
							}
							else {
								this.setState({
									sliderRouteTranslate: "slider-translate-60p",
									showDiv: 'show-m',
									mapinfo: records,
									dealer: e.consignee_code,
									consignment_code: "Serial No : " + e.invoice_no,
									maptruckno: e.truck_no,
									routeTruck: { "truck_no": e.truck_no, "startTime": sdate, "endTime": end_date },
									loadshow: 'show-n',
									sidebarSubHeader: "Consignment Information",
									overly: 'show-m',
									rownode: e,
									leg_no: 0,
									tolls: records.tolls
								});
								this.onShowGoogleRoute(e.invoice_no, e.consignee_code, 0, e.truck_no)
								this.renderMap();
							}
						}
					}
				}
				catch (e) {
					this.setState({
						show: true,
						basicTitle: 'No Route data available',
						basicType: "danger",
						loadshow: 'show-n'
					});
				}

			})
			.catch(function (error) {
				console.log(error);
			});
	}
	onCloseRouteDiv = () => {
		this.setState({
			uploadDivWidth: '0%',
			sliderRouteTranslate: "",
			sliderCommentTranslate: "",
			sliderSegmentTranslate: "",
			showDiv: 'show-n',
			uploadFile: '',
			file: '',
			csvmessage: '',
			csverrmessage: '',
			overly: 'show-n',
			devation_flag: false
		});
	}



	onGridReady = params => {
		this.gridApi = params.api;

		//console.log("Grid is: ", params);
		this.gridColumnApi = params.columnApi;

		//    ChartMenu.prototype.saveChart = function (e) {
		// 	   var event = {
		// 	      data: this.gridOptionsWrapper.getApi(),
		// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
		// 	    };
		// 	   //console.log("Testing", event);
		// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
		// 	   this.dispatchEvent(event);
		// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;

		//    };
		if (this.state.deptcode == 'LOG-PRT') {
			// console.log("Step 1 ")
			this.gridColumnApi.setColumnsVisible(["nightdriving_exception", "loading_delay_exception", "unloading_delay_exception", "trip_type"], false);

			this.gridColumnApi.setColumnsVisible(["trip_type"], false);
		}
		else if (this.state.deptcode == 'SNDG') {
			this.gridColumnApi.setColumnsVisible(["nightdriving_exception"], true);
			this.gridColumnApi.setColumnsVisible(["loading_delay_exception"], true);
			//this.gridColumnApi.setColumnsVisible(["unloading_delay_exception"], true);
			this.gridColumnApi.setColumnsVisible(["consignee_city"], true);
			this.gridColumnApi.setColumnsVisible(["trip_type"], false);
		}
		else if (this.state.deptcode == 'LOG-TNP') {
			this.gridColumnApi.setColumnsVisible(["nightdriving_exception"], true);
			this.gridColumnApi.setColumnsVisible(["loading_delay_exception"], true);
			//this.gridColumnApi.setColumnsVisible(["unloading_delay_exception"], true);
			this.gridColumnApi.setColumnsVisible(["consignee_city"], true);
			this.gridColumnApi.setColumnsVisible(["trip_type"], true);
		}
		else if ((this.props.match.path == "/sndconsignments")
			|| (this.props.match.path == "/prtconsignments")
			|| (this.props.match.path == "/tnpconsignments")) {
			this.gridColumnApi.setColumnsVisible(["unloading_delay_exception"], false);

		}
		else { }



	};


	onGridState = () => {
		//console.log(this.gridApi);

		/*Get  Current Columns State and Store in this.colState */
		this.colState = this.gridColumnApi.getColumnState();

		/*Get Current RowGroup Columns State and Store in this.rowGroupState */
		this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		/*Get Current Pivot Columns State and Store in this.pivotColumns,
		 * User should enable the Pivot mode.*/
		this.pivotColumns = this.gridColumnApi.getPivotColumns();

		/*Get Current Filter State and Store in window.filterState */
		window.filterState = this.gridApi.getFilterModel();
		this.gridApi.setFilterModel(window.filterState);



	}

	renderMap = () => {
		//loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyD1AFWPoJqPaemwGO4A7P7Q6CsUJ1TLGJk&libraries=places,drawing&callback=initMap");
		//window.initMap = this.initMap
	}

	initMap = () => {
		//console.log("mapinfo ",this.state.mapinfo);
		var currentwindow;

		var jsondata = this.state.mapinfo;
		var locations = jsondata.breaks;
		var arr = jsondata.coords;


		//console.log("Here",arr);
		if (arr.length > 0) {
			var lt = arr[0].lat;
			var ln = arr[0].lng;
		}
		else {
			if (this.state.defTransitCoords == '') {
				var lt = 28.4519751;
				var ln = 77.0310713;
			}
			else {
				var lt = JSON.parse(this.state.defTransitCoords).lat;
				var ln = JSON.parse(this.state.defTransitCoords).lng;
			}

		}
		var routeinfo = jsondata.route_details;
		var mapOptions = {
			zoom: 7,
			zoomControl: true,
			mapTypeControl: true,
			scaleControl: true,
			streetViewControl: true,
			rotateControl: true,
			fullscreenControl: true,
			labels: true,
			mapTypeControlOptions: {
				mapTypeIds: ['hybrid', 'roadmap'],
			},
			center: new window.google.maps.LatLng(lt, ln),
			mapTypeId: window.google.maps.MapTypeId.ROADMAP
		};

		var map = new window.google.maps.Map(document.getElementById('map'),
			mapOptions);

		var bounds = new window.google.maps.LatLngBounds();
		bounds.extend(new window.google.maps.LatLng(lt, ln));
		// Create our info window content
		var currentinfowindow = null;
		var line = new window.google.maps.Polyline(
			{
				map: map,
				strokeColor: '#157254',
				strokeOpacity: 1.0,
				strokeWeight: 2.5,
				icons: [{
					icon: {
						path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
						strokeColor: '#ff8c52',
						fillColor: '#ff8c52',
						fillOpacity: 1,
						strokeWeight: 2
					},
					repeat: '100px',
					path: []
				}]
			});

		if (arr.length > 0) {
			for (let i = 0; i < arr.length; i++) {
				var path = line.getPath().getArray();

				let latLng = new window.google.maps.LatLng(arr[i].lat, arr[i].lng);
				path.push(latLng);
				line.setPath(path);




				//Change line color based on map type
				window.google.maps.event.addListener(map, 'maptypeid_changed', function () {
					if (map.getMapTypeId() == 'hybrid' || map.getMapTypeId() == 'satellite') {
						var line = new window.google.maps.Polyline(
							{
								map: map,
								strokeColor: '#FFFFFF',
								strokeOpacity: 1.0,
								strokeWeight: 2.5,
								icons: [{
									icon: {
										path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
										strokeColor: '#ff8c52',
										fillColor: '#ff8c52',
										fillOpacity: 1,
										strokeWeight: 2
									},
									repeat: '100px',
									path: []
								}]
							});
					}
					else {
						var line = new window.google.maps.Polyline(
							{
								map: map,
								strokeColor: '#157254',
								strokeOpacity: 1.0,
								strokeWeight: 2.5,
								icons: [{
									icon: {
										path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
										strokeColor: '#ff8c52',
										fillColor: '#ff8c52',
										fillOpacity: 1,
										strokeWeight: 2
									},
									repeat: '100px',
									path: []
								}]
							});

					}

					for (i = 0; i < arr.length; i++) {
						var path = line.getPath().getArray();
						let latLng = new window.google.maps.LatLng(arr[i].lat, arr[i].lng);
						path.push(latLng);
						line.setPath(path);
						//map.setCenter(latLng);
					}

					var x = map.getZoom();
					var c = map.getCenter();
					window.google.maps.event.trigger(map, 'resize');
					map.setZoom(x);
					map.setCenter(c);
				});

				bounds.extend(new window.google.maps.LatLng(arr[i].lat, arr[i].lng));
			}

			var infowindow = new window.google.maps.InfoWindow();
			var marker, l;

			for (l = 0; l < locations.length; l++) {
				marker = new window.google.maps.Marker({
					position: new window.google.maps.LatLng(locations[l].lat, locations[l].lng),
					icon: require('../../assets/icons/cf.png'),
					map: map,
				});

				window.google.maps.event.addListener(marker, 'click', (function (marker, l) {
					return function () {
						//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Break - "+(l+1)+"</B></p><p>Break time "+locations[l].break_start+" - "+locations[l].break_end+" ("+timeConvert(locations[l].break_time_seconds)+")</p>";
						var contentarr = []
						var header = "Break - " + (l + 1)
						contentarr.push({ "key": "Start time", "value": getDDMMYYYYHHMMSS(locations[l].break_start) })
						contentarr.push({ "key": "End time", "value": getDDMMYYYYHHMMSS(locations[l].break_end) + " (" + secondsToString(locations[l].break_time_seconds) + ")" })
						var contentString = infoBox(marker.icon, header, contentarr)
						infowindow.setContent(contentString);
						currentwindow = infowindow;
						infowindow.open(map, marker);
						//console.log(marker.position.toJSON());
						//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
					}
				})(marker, l));
			}
			marker = new window.google.maps.Marker({
				position: new window.google.maps.LatLng(arr[0].lat, arr[0].lng),
				icon: require('../../assets/icons/track_start.png'),
				map: map,
			});
			window.google.maps.event.addListener(marker, 'click', (function (marker) {
				return function () {
					//	console.log(clusters[0])
					var contentarr = []
					//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
					var header = "Starting Point"
					contentarr.push({ "key": "Started at ", "value": getDDMMYYYYHHMMSS(routeinfo.start_time) })
					var contentString = infoBox(marker.icon, header, contentarr, '')


					infowindow.setContent(contentString);
					currentwindow = infowindow;
					infowindow.open(map, marker);
					//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
				}
			})(marker));
			marker = new window.google.maps.Marker({
				position: new window.google.maps.LatLng(arr[(arr.length - 1)].lat, arr[(arr.length - 1)].lng),
				icon: require('../../assets/icons/truck-end.png'),
				map: map,
			});
			window.google.maps.event.addListener(marker, 'click', (function (marker) {
				return function () {
					//	console.log(clusters[0])
					var contentarr = []
					//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> End Point</B></p><p>End at "+routeinfo.end_time+"</p>";
					var header = "End Point"
					contentarr.push({ "key": "End at ", "value": getDDMMYYYYHHMMSS(routeinfo.end_time) })
					var contentString = infoBox(marker.icon, header, contentarr)

					infowindow.setContent(contentString);
					currentwindow = infowindow;
					infowindow.open(map, marker);
					// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
				}
			})(marker));
			if (this.state.tolls.length > 0) {
				var infowindow = new window.google.maps.InfoWindow();
				var l;
				this.state.tolls.map(function (e, index) {
					var tollMarker = new window.google.maps.Marker({
						position: new window.google.maps.LatLng(e.lat, e.lon),
						icon: require('../../assets/icons/barrier.png'),
						map: map,
					});
					window.google.maps.event.addListener(tollMarker, 'mouseover', (function (tollMarker, index) {
						return function () {
							var contentarr = []
							var header = "Toll - " + e.name
							contentarr.push({ "key": "Address", "value": e.location })
							var contentString = infoBox(tollMarker.icon, header, contentarr)
							infowindow.setContent(contentString);
							var currentwindow = infowindow;
							infowindow.open(map, tollMarker);
						}
					})(tollMarker, index));
					window.google.maps.event.addListener(tollMarker, 'mouseout', function () {
						infowindow.close();
					});
				})

			}
		}
		map.fitBounds(bounds)
	}



	onShowOverspeedKpi = async (params) => {
		let eventLabel = googleAnalytics.page.action.overspeed;
		let eventOptions = {
			"category": this.state.pagetitle,
			"action": this.state.eventAction,
			"label": eventLabel,
		}
		googleAnalytics.logEvent(eventOptions);
		//console.log("IN onShowOverspeedKpi, params = ",params);
		//console.log(params.column.colId);
		if (params != 0) {
			var overspeedData = [];

			//console.log("Step 3 ",overspeedData)
			var propsdata = params;
			await this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							{
								headerName: "", field: "_id",
								width: 50,
								cellRendererFramework: RoutemapAction,
								resizable: true
							},
							{ headerName: "Vin No", field: "truck_no", filter: true, resizable: true },
							{ headerName: "Speed (km/h)", field: "speed", filter: true, resizable: true },
							// {
							// 	headerName: "Reported At", field: "first_instance", filter: true, resizable: true,
							// 	valueGetter: function (params) {
							// 		return getDDMMYYYYHHMMSS(params.data.first_instance);
							// 	}
							// },

							{
								headerName: "Reported From Date", field: "first_instance", filter: true, resizable: true,
								valueGetter: function (params) {
									// return getDDMMYYYYHHMMSS(params.data.first_instance);
									try {
										if (params.data.first_instance != "" && params.data.first_instance != undefined) {
											return getDDMMYYYYHHMMSS(params.data.first_instance);
										}
										else {
											return "";
										}
									}
									catch (e) { }
								}
							},

							{
								headerName: "Reported End Date", field: "recent_instance", filter: true, resizable: true,
								valueGetter: function (params) {
									try {
										if (params.data.recent_instance != "" && params.data.recent_instance != undefined) {
											return getDDMMYYYYHHMMSS(params.data.recent_instance);
										}
										else {
											return "";
										}
									}
									catch (e) { }

								}
							},
						],
						overlayNoRowsTemplate: 'No rows to show',

					},
					getDetailRowData: function (param) {
						// console.log("Step 4 ", param)
						redirectURL.post('/consignments/overspeed', {
							consignment_code: param.data.consignment_code,
							truck_no: param.data.truck_no
						})
							.then(async (response) => {
								//console.log("Step 1 ",response.data)
								overspeedData = response.data;
								param.successCallback(overspeedData);
								//console.log("Step 2 ",response.data)

							});

					},
					masterDetail: true
				}
			});

			if (propsdata.column.colDef.field == 'overspeeding_exception') {

				propsdata.node.setExpanded(!propsdata.node.expanded);
			}
			else {

				propsdata.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else {
			this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							{ headerName: "Vin No", field: "truck_no" },
							{ headerName: "Speed", field: "speed" },
							{ headerName: "Reported At", field: "first_instance" }
						]

					},
					getDetailRowData: function (params) {
						//console.log("Child ", params);
						params.successCallback([]);
					}
				}
			});
		}

	}

	onShowTransitKpi = async (params) => {
		console.log("params", params)
		if (params != 0) {
			let eventLabel = googleAnalytics.page.action.transitDelay;
			let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventAction,
				"label": eventLabel,
			}
			googleAnalytics.logEvent(eventOptions);
			//console.log("IN onShowTransitKpi, params = ",params);
			//console.log(params);
			//console.log("Consign onShowTransitKpi params = ",params);
			//console.log(params.column.colId);
			if (params != 0) {
				var transitedelaydata = [];

				await this.setState({

					detailCellRendererParams: {
						suppressRefresh: true,
						detailGridOptions: {
							headerHeight: 50,
							columnDefs: [
								{
									headerName: "", field: "_id",

									cellRendererFramework: RoutemapTransitAction,
									width: 50,
									suppressSizeToFit: true,
									cellClass: function (params) {
										if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
											return 'green';
										}
										else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
											return 'lightred';
										}
										else {
											return '';
										}

									}
								},
								{
									headerName: "Vin No", field: "truck_no", width: 100, resizable: true,
									cellClass: function (params) {
										if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
											return 'green';
										}
										else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
											return 'lightred';
										}
										else {
											return '';
										}

									}
								},
								{
									headerName: "Leg Start Time", field: "leg_start", width: 120, resizable: true,
									valueGetter: function (params) {
										return getDDMMYYYYHHMMSS(params.data.leg_start);
									},
									cellClass: function (params) {
										if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
											return 'green';
										}
										else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
											return 'lightred';
										}
										else {
											return '';
										}

									}
								},
								{
									headerName: "Leg End Time", field: "leg_end_eta",
									width: 120, resizable: true,
									valueGetter: function (params) {
										return getDDMMYYYYHHMMSS(params.data.leg_end_eta);
									},
									cellClass: function (params) {
										if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
											return 'green';
										}
										else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
											return 'lightred';
										}
										else {
											return '';
										}

									}
								},
								{
									headerName: "Exp. Leg Distance (KM)",
									field: "leg_expected_distance", width: 140, resizable: true,
									valueGetter: function (params) {
										return Math.round(params.data.leg_expected_distance);
									},
									cellClass: function (params) {
										if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
											return 'green';
										}
										else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
											return 'lightred';
										}
										else {
											return '';
										}

									}
								},
								{
									headerName: "Actual Leg Distance Covered (KM)",
									field: "actual_leg_distance", width: 140, resizable: true,
									valueGetter: function (params) {
										//console.log("Actual Leg Dist ", params.data.actual_leg_distance);
										if (params.data.actual_leg_distance != '' && params.data.actual_leg_distance != undefined) {
											return Math.round(params.data.actual_leg_distance);
										}
										else {
											return 0;
										}

									},
									cellClass: function (params) {
										if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
											return 'green';
										}
										else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
											return 'lightred';
										}
										else {
											return '';
										}

									}
								},
								{
									headerName: "Exp. Distance from Trip Start(KM)",
									field: "expected_distance_start_to_leg", width: 140, resizable: true,
									valueGetter: function (params) {
										//console.log("Actual Leg Dist ", params.data.actual_leg_distance);
										if (params.data.expected_distance_start_to_leg != '' && params.data.expected_distance_start_to_leg != undefined) {
											return Math.round(params.data.expected_distance_start_to_leg);
										}
										else {
											return 0;
										}

									},
									cellClass: function (params) {
										if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
											return 'green';
										}
										else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
											return 'lightred';
										}
										else {
											return '';
										}

									}

								},
								{
									headerName: "Actual Distance from Trip Start (KM)",
									field: "actual_distance_traveled_start_to_leg", width: 140, resizable: true,
									valueGetter: function (params) {
										//console.log("Actual Leg Dist ", params.data.actual_leg_distance);
										if (params.data.actual_distance_traveled_start_to_leg != '' && params.data.actual_distance_traveled_start_to_leg != undefined) {
											return Math.round(params.data.actual_distance_traveled_start_to_leg);
										}
										else {
											return 0;
										}

									},
									cellClass: function (params) {
										if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
											return 'green';
										}
										else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
											return 'lightred';
										}
										else {
											return '';
										}

									}


								},

								{
									headerName: "Google Distance from Start (KM)",
									field: "actual_start_to_leg_google_distance",
									width: 140,
									resizable: true,
									cellClass: function (params) {
										if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
											return 'green';
										}
										else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
											return 'lightred';
										}
										else {
											return '';
										}

									}


								},
								{
									headerName: "GPS Data Available",
									field: "no_gps_data",
									width: 140,
									resizable: true,
									valueGetter: function (params) {
										//console.log("Actual Leg Dist ", params.data.actual_leg_distance);
										if (params.data.no_gps_data == 1) {
											return "Not Available";
										}
										if (params.data.no_gps_data == 0) {
											return "Available";
										}

									},
									cellClass: function (params) {
										if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
											return 'green';
										}
										else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
											return 'lightred';
										}
										else {
											return '';
										}

									}


								},


								{
									headerName: "Trip Completed",
									field: "trip_completed",
									width: 140,
									resizable: true,
									valueGetter: function (params) {
										//console.log("Actual Leg Dist ", params.data.actual_leg_distance);
										if (params.data.trip_completed == 1) {
											return "Yes";
										}
										if (params.data.trip_completed == 2) {
											return "";
										}
										if (params.data.trip_completed == 0) {
											return "";
										}

									},
									cellClass: function (params) {
										if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
											return 'green';
										}
										else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
											return 'lightred';
										}
										else {
											return '';
										}

									}


								}
							],
							overlayNoRowsTemplate: 'No rows to show',
						},
						getDetailRowData: function (params) {
							//console.log("Step 4 ",transitedelaydata)
							//console.log("Step 4 ",params)
							redirectURL.post('/consignments/transitdelay', {
								consignment_code: params.data.consignment_code,
								consignee_code: params.data.consignee_code,
								truck_no: params.data.truck_no
							})
								.then(async (response) => {
									//console.log("Step 1 ",response.data)
									transitedelaydata = response.data;
									var legsarr = []
									transitedelaydata.map((item) => {
										//if(item.trip_completed < 2)
										//{
										legsarr.push(item)
										//}
									})
									// console.log('transitedelaydata ', transitedelaydata);

									params.successCallback(legsarr);
									//console.log("Step 2 ",response.data)

								});

						}
					}
				});

				//if( params.column.colId == 'transit_delay' || params.column.colId == 'transit_delay_1' ||   params.column.colId == 'no_gps_data' || params.column.colId == 'no_gps_data_1')
				if (params.column.colDef.field == 'transit_delay' || params.column.colDef.field == 'no_gps_data') {
					params.node.setExpanded(!params.node.expanded);
					//console.log("expanded ",params.node.expanded)
					if (params.node.expanded == false) {
						transitedelaydata = []
					}
				}
				else {

					params.node.setExpanded(false);
				}
				//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

			}
			else {
				this.setState({

					detailCellRendererParams: {
						detailGridOptions: {
							columnDefs: [
								{ headerName: "Vin No", field: "truck_no" },
								{ headerName: "Speed (km/h)", field: "speed" },
								{ headerName: "Reported At", field: "first_instance" }
							]

						},
						getDetailRowData: function (params) {
							//console.log("Child ", params);
							params.successCallback([]);
						}
					}
				});
			}
		}
		else {
			this.setState({
				show: true,
				basicTitle: "Transit for this consignment has not yet started",
				basicType: "info"
			});
		}

	}

	/*NO GPS DATA*/


	onShowNoGPSKpi = async (params) => {
		let eventLabel = googleAnalytics.page.action.gpsAvailable;
		let eventOptions = {
			"category": this.state.pagetitle,
			"action": this.state.eventAction,
			"label": eventLabel,
		}
		googleAnalytics.logEvent(eventOptions);
		//console.log("IN onShowNoGPSKpi, params = ",params);
		//console.log(params);
		//console.log("Consign onShowNoGPSKpi params = ",params);
		//console.log(params.column.colId);
		if (params != 0) {
			var transitedelaydata = [];

			await this.setState({

				detailCellRendererParams: {
					suppressRefresh: true,
					detailGridOptions: {
						columnDefs: [
							{
								headerName: "",
								field: "_id",
								cellRendererFramework: RoutemapNoGPSAction,
								width: 50,
								suppressSizeToFit: true,
								cellClass: function (params) {
									if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
										return 'green';
									}
									else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
										return 'lightred';
									}
									else {
										return '';
									}

								}
							},

							{
								headerName: "Vin No", field: "truck_no", width: 100, resizable: true,
								cellClass: function (params) {
									if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
										return 'green';
									}
									else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
										return 'lightred';
									}
									else {
										return '';
									}

								}
							},
							{
								headerName: "Last Data Receieved",
								field: "last_packet_datetime",
								width: 180,
								resizable: true,
								valueGetter: function (params) {
									return getDDMMYYYYHHMMSS(params.data.last_packet_datetime);
								},
								cellClass: function (params) {
									if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
										return 'green';
									}
									else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
										return 'lightred';
									}
									else {
										return '';
									}

								}
							},
							{
								headerName: "Data Received on",
								field: "data_received_on",
								width: 180,
								resizable: true,
								valueGetter: function (params) {
									return getDDMMYYYYHHMMSS(params.data.packet_received_on);
								},
								cellClass: function (params) {
									if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
										return 'green';
									}
									else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
										return 'lightred';
									}
									else {
										return '';
									}

								}
							}
						],
						overlayNoRowsTemplate: 'No rows to show',
					},
					getDetailRowData: function (params) {
						//console.log("Step 4 ",transitedelaydata)
						//console.log("Step 4 ",params)
						redirectURL.post('/consignments/nogpsdata', {
							consignment_code: params.data.consignment_code,
							truck_no: params.data.truck_no
						})
							.then(async (response) => {
								//console.log("Step 1 ",response.data)
								transitedelaydata = response.data;
								var legsarr = []
								transitedelaydata.map((item) => {
									//if(item.trip_completed < 2)
									//{
									legsarr.push(item)
									//}
								})
								// console.log('transitedelaydata ', transitedelaydata);
								params.successCallback(legsarr);
								//console.log("Step 2 ",response.data)

							});

					}
				}
			});

			//if( params.column.colId == 'transit_delay' || params.column.colId == 'transit_delay_1' ||   params.column.colId == 'no_gps_data' || params.column.colId == 'no_gps_data_1')
			if (params.column.colDef.field == 'transit_delay' || params.column.colDef.field == 'no_gps_data') {
				params.node.setExpanded(!params.node.expanded);
				//console.log("expanded ",params.node.expanded)
				if (params.node.expanded == false) {
					transitedelaydata = []
				}
			}
			else {

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else {
			this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							{ headerName: "Vin No", field: "truck_no" },
							{ headerName: "Speed (km/h)", field: "speed" },
							{ headerName: "Reported At", field: "first_instance" }
						]

					},
					getDetailRowData: function (params) {
						//console.log("Child ", params);
						params.successCallback([]);
					}
				}
			});
		}

	}
	onClickShowProbableData = async (params) => {
		let eventLabel = googleAnalytics.page.action.nightDrive;
		let eventOptions = {
			"category": this.state.pagetitle,
			"action": this.state.eventAction,
			"label": eventLabel,
		}
		googleAnalytics.logEvent(eventOptions);
		// console.log("IN probable data, params = ", params);
		var probableData = [];
		await this.setState({

			detailCellRendererParams: {
				detailGridOptions: {
					columnDefs: [
						{ headerName: "Vin No", field: "truck_no", width: 150, resizable: true },
						{ headerName: "GPS Provider", field: "actual_lspuser", width: 180, resizable: true, },
						{
							headerName: "Event Time", field: "timestamp", resizable: true,
							valueGetter: function (params) {
								return getHyphenDDMMMYYYYHHMM(params.data.timestamp);
							}
						},
						{
							headerName: "Event", field: "", width: 180, resizable: true,
							width: 220,
							valueGetter: function (params) {
								return "Harsh Braking / Probable Accident"
							}
						},
					],
					overlayNoRowsTemplate: 'No rows to show',
				},
				getDetailRowData: function (params) {
					//console.log("Step 4 ",overspeedData)
					redirectURL.post('/consignments/probableAccidentsData', {
						consignment_code: params.data.consignment_code,
					})
						.then(async (response) => {
							// console.log("Step 1 ", response.data)
							probableData = response.data;
							params.successCallback(probableData);
							//console.log("Step 2 ",response.data)

						});

				}

			}
		});
		//console.log("Parmas ", params)
		if (params.column.colDef.field == 'probable_accident') {

			params.node.setExpanded(!params.node.expanded);
		}
		else {

			params.node.setExpanded(false);
		}

	}

	onShowNightDriveKpi = async (params) => {
		let eventLabel = googleAnalytics.page.action.nightDrive;
		let eventOptions = {
			"category": this.state.pagetitle,
			"action": this.state.eventAction,
			"label": eventLabel,
		}
		googleAnalytics.logEvent(eventOptions);
		//console.log("IN onShowNightDriveKpi, params = ",params);
		//console.log(e);
		//console.log("Consign Overspeed ",params);

		if (params != 0) {
			var overspeedData = [];


			//console.log("Step 3 ",overspeedData)

			await this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							{
								headerName: "", field: "_id",

								cellRendererFramework: RouteNightDrivemap,
								width: 50,
								suppressSizeToFit: true
							},
							{ headerName: "Vin No", field: "truck_no", width: 150, resizable: true },
							{
								headerName: "Traveled From", field: "first_instance", width: 180, resizable: true,
								valueGetter: function (params) {
									return getDDMMYYYYHHMMSS(params.data.first_instance);
								}
							},
							{
								headerName: "Traveled Until", field: "recent_instance", resizable: true,
								valueGetter: function (params) {
									return getDDMMYYYYHHMMSS(params.data.recent_instance);
								}
							},
							{
								headerName: "Distance Traveled (KM)", field: "distance_from_first_instance", width: 180,
								resizable: true,
								valueGetter: function (params) {
									return Math.round(params.data.distance_from_first_instance)
								}
							}
						],
						overlayNoRowsTemplate: 'No rows to show',
					},
					getDetailRowData: function (params) {
						//console.log("Step 4 ",overspeedData)
						redirectURL.post('/consignments/nightdriving', {
							consignment_code: params.data.consignment_code,
							gate_out_time: params.data.gate_out_time,
							truck_no: params.data.truck_no
						})
							.then(async (response) => {
								//console.log("Step 1 ",response.data)
								overspeedData = response.data;
								params.successCallback(overspeedData);
								//console.log("Step 2 ",response.data)

							});

					}

				}
			});

			//if(params.column.colId == 'nightdriving_exception')
			if (params.column.colDef.field == 'nightdriving_exception') {

				params.node.setExpanded(!params.node.expanded);
			}
			else {

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else {
			this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							{ headerName: "Vin No", field: "truck_no" },
							{ headerName: "Speed (km/h)", field: "speed" },
							{ headerName: "Reported At", field: "first_instance" }
						]

					},
					getDetailRowData: function (params) {
						//console.log("Child ", params);
						params.successCallback([]);
					}
				}
			});
		}

	}

	onShowDayWiseDistanceTrip = async (params) => {
		// console.log("params", params)
		// let eventLabel = googleAnalytics.page.action.nightDrive;
		// let eventOptions = {
		// 	"category": this.state.pagetitle,
		// 	"action": this.state.eventAction,
		// 	"label": eventLabel,
		// }
		// googleAnalytics.logEvent(eventOptions);
		if (params != 0) {
			var transitedelaydata = [];

			await this.setState({

				detailCellRendererParams: {
					suppressRefresh: true,
					detailGridOptions: {
						columnDefs: [
							{
								headerName: "",
								field: "_id",
								cellRendererFramework: RouteMapForDayWiseDistanceTrip,
								width: 50,
								suppressSizeToFit: true,
								// cellClass:function(params){
								// 	if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
								// 	{
								// 		return 'green';
								// 	}
								// 	else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
								// 	{
								// 		return 'lightred';
								// 	}
								// 	else{
								// 		return '';
								// 	}
								// }
							},

							{
								headerName: "Vin No",
								field: "truck_no",
								width: 100,
								resizable: true,
							},
							{
								headerName: "Distance Travelled (KM)",
								field: "distance_travelled_km",
								width: 180,
								resizable: true,
							},
							{
								headerName: "Day",
								field: "day",
								width: 180,
								resizable: true,
							},
							{
								headerName: "Start time",
								field: "start_time",
								width: 180,
								resizable: true,
								valueGetter: function (params) {
									return getDDMMYYYYHHMMSS(params.data.start_time);
								}
							},
							{
								headerName: "End time",
								field: "end_time",
								width: 180,
								resizable: true,
								valueGetter: function (params) {
									return getDDMMYYYYHHMMSS(params.data.end_time);
								},
							}
						],
						overlayNoRowsTemplate: 'No rows to show',
					},
					getDetailRowData: function (params) {
						//console.log("Step 4 ",transitedelaydata)
						//console.log("Step 4 ",params)
						redirectURL.post('/consignments/getDayWiseDistanceTravelled', {
							invoice_nos: [params.data.invoice_no],
							invoice_time: getHyphenYYYYMMDDHHMMSS(params.data.invoice_time)
						})
							.then(async (response) => {
								// console.log("Step 1 ",response.data)
								transitedelaydata = response.data;
								var legsarr = []
								transitedelaydata.map((item) => {
									//if(item.trip_completed < 2)
									//{
									legsarr.push(item)
									//}
								})
								// console.log('transitedelaydata ', transitedelaydata);

								params.successCallback(legsarr);
								//console.log("Step 2 ",response.data)

							});

					}
				}
			});

			//if( params.column.colId == 'transit_delay' || params.column.colId == 'transit_delay_1' ||   params.column.colId == 'no_gps_data' || params.column.colId == 'no_gps_data_1')
			if (params.column.colDef.field == 'day_wise_distance_trip' || params.column.colDef.field == 'day_wise_distance_trip') {
				params.node.setExpanded(!params.node.expanded);
				//console.log("expanded ",params.node.expanded)
				if (params.node.expanded == false) {
					transitedelaydata = []
				}
			}
			else {
				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else {
			// console.log("elseData")
			this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							{ headerName: "Vin No", field: "truck_no" },
							{ headerName: "Distance Travelled(Km)", field: "distance_travelled_km" },
							{ headerName: "Day", field: "day" },
							{ headerName: "Start Time", field: "start_time" },
							{ headerName: "End Time", field: "end_time" }
						]

					},
					getDetailRowData: function (params) {
						//console.log("Child ", params);
						params.successCallback([]);
					}
				}
			});
		}

	}


	onShowEnrouteStoppagesKpi = async (params) => {
		let eventLabel = googleAnalytics.page.action.enrouteStoppages;
		let eventOptions = {
			"category": this.state.pagetitle,
			"action": this.state.eventAction,
			"label": eventLabel,
		}
		googleAnalytics.logEvent(eventOptions);
		// console.log("IN onShowEnrouteStoppagesKpi, params = ",params);
		//console.log(e);
		//console.log("Consign Overspeed ",params);

		if (params != 0) {
			var overspeedData = [];


			//console.log("Step 3 ",overspeedData)

			await this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [

							{
								headerName: "", field: "_id",

								cellRendererFramework: RoutemapenrouteAction,
								width: 50,
								suppressSizeToFit: true,

							},
							{
								headerName: "Break Start",
								field: "stoppage_start_time",
								width: 150,
								resizable: true,
								valueGetter: function (params) {
									if (params.data.stoppage_start_time != undefined) {
										return getDDMMYYYYHHMMSS(params.data.stoppage_start_time)
									}
									else {
										return '';
									}

								}
							},
							// {
							// 	headerName: "Break End",
							// 	field: "reset_start_time",
							// 	width:180,
							// 	resizable: true,
							// 	valueGetter:function(params){
							// 		if(params.data.reset_start_time != undefined)
							// 		{
							// 			return getDDMMYYYYHHMMSS(params.data.reset_start_time)
							// 		}
							// 		else{
							// 			return '';
							// 		}

							// 	}
							// },

							{
								headerName: "Exception Closed On",
								field: "modified_date",
								width: 220,
								resizable: true,
								valueGetter: function (params) {
									if (params.data.exception_closed == 1) {

										if (params.data.modified_date != undefined) {
											return getDDMMYYYYHHMMSS(params.data.modified_date)
										}
										else {
											return '';
										}
									}
									else {
										return '';
									}

								}
							},
							{
								headerName: "Break Time",
								field: "elaped_time_from_last_moved_seconds",
								width: 180,
								resizable: true,
								valueGetter: function (params) {
									if (params.data.exception_closed == 1) {
										// if(params.data.elaped_time_from_last_moved_seconds != undefined)
										// {
										// 	var seconds = params.data.elaped_time_from_last_moved_seconds;
										// 	var days = parseInt(seconds / (24 * 3600));
										// 	var hours = parseInt(seconds/3600);
										// 	var minutes = Math.round((seconds-(hours*3600))/60)
										// 	//return hours+" Hr(s) "+minutes+" Min(s)";
										// 	return secondsToString(seconds)
										// }
										// else{
										// 	return '';
										// }
										var sdate = moment.parseZone(params.data.stoppage_start_time).format("x");
										if (typeof params.data.reset_datetime == 'undefined') {
											var edate = moment.parseZone().format("x");
										}
										else {
											var edate = moment.parseZone(params.data.reset_datetime).format("x");
										}
										var diff = (edate - sdate) / 1000;
										return secondsToString(diff);
									}
									else {
										return '';
									}
								}
							}
						],
						overlayNoRowsTemplate: 'No rows to show',
					},
					getDetailRowData: async function (params) {
						//console.log("Step 4 ",overspeedData)
						if (params.data.is_transshipment == 1) {
							var oldtruck = params.data.old_truck_no
						}
						else {
							var oldtruck = ''
						}
						redirectURL.post('/consignments/enroutes', {
							consignment_code: params.data.consignment_code,
							truck_no: params.data.truck_no,
							old_truck_no: oldtruck
						})
							.then(async (response) => {
								//console.log("Step 1 ",response.data)
								overspeedData = response.data;
								params.successCallback(overspeedData);
								//console.log("Step 2 ",response.data)

							});

					}
				}
			});

			if (params.column.colDef.field == 'enroute_exception') {

				params.node.setExpanded(!params.node.expanded);
			}
			else {

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else {
			this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							{ headerName: "Vin No", field: "truck_no" },
							{ headerName: "Speed (km/h)", field: "speed" },
							{ headerName: "Reported At", field: "first_instance" }
						]

					},
					getDetailRowData: function (params) {
						//console.log("Child ", params);
						params.successCallback([]);
					}
				}
			});
		}

	}

	onShowEnrouteRouteMap(rownode) {
		//console.log("IN onShowEnrouteRouteMap, params = ",params);
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		// console.log("rownode", rownode);
		this.setState({
			loadshow: 'show-m'
		})
		var fintance = rownode.stoppage_start_time.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		if (rownode.reset_datetime) {
			var eintance = rownode.reset_datetime.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();

		}
		else {
			var eintance = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');

		}
		//var eintance = rownode.packet_received_on.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		var params = {
			truck_no: rownode.truck_no,
			//consignment_code:rownode.consignment_code,
			//consignee_code:rownode.consignee_code,
			first_instance: fintance,
			recent_instance: eintance,
			screen: "consignment"
		}
		redirectURL.post('/reports/nightdriveroutes', params)
			.then((response) => {

				//var records = JSON.parse(JSON.stringify(response)).data;
				// console.log("responseMapData",response.data);
				if (response.data.coords.length == 0) {
					this.setState({
						show: true,
						basicTitle: 'No Route data available',
						basicType: "danger",
						loadshow: 'show-n'
					});
				}
				else {
					var sdate = '';
					var edate = '';

					if (rownode.stoppage_start_time != '' && rownode.stoppage_start_time != undefined) {
						sdate = rownode.stoppage_start_time;
					}
					if (rownode.modified_date != '' && rownode.modified_date != undefined) {
						edate = rownode.modified_date
					}
					this.setState({
						mapinfo: response.data,
						dealer: rownode.consignee_code,
						sliderRouteTranslate: "slider-translate-60p",
						defTransitCoords: rownode.consignee_coordinates,
						consignment_code: "Vin No : " + rownode.truck_no,
						maptruckno: rownode.truck_no,
						routeTruck: { "truck_no": rownode.truck_no, "startTime": sdate, "endTime": edate },
						loadshow: 'show-n',
						sidebarSubHeader: "Consignment Leg Information",
						overly: 'show-m',
						rownode: rownode,
						leg_no: rownode.leg_no,
						tolls: response.data.tolls

					});

					this.onShowGoogleRoute(rownode.consignment_code, rownode.consignee_code, rownode.leg_no, rownode.truck_no)
					this.renderMap();

				}
			})
			.catch(function (error) {
				console.log(error);
			});

	}

	onShowLoadingDelayKpi = async (params) => {

		let eventLabel = googleAnalytics.page.action.loadingDelay;
		let eventOptions = {
			"category": this.state.pagetitle,
			"action": this.state.eventAction,
			"label": eventLabel,
		}
		googleAnalytics.logEvent(eventOptions);
		//console.log("IN onShowLoadingDelayKpi, params = ",params);
		//console.log(e);
		//console.log("Consign onShowLoadingDelayKpi ",params);
		//console.log(params.column.colId);
		if (params != 0) {
			var overspeedData = [];

			var hideonTNP = (this.state.deptcode == 'LOG-TNP') ? true : false
			var showonTNP = (this.state.deptcode == 'LOG-TNP') ? false : true
			await this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [

							{
								headerName: "Gate In Time",
								field: "gate_in_time",
								width: 180,
								resizable: true,
								hide: hideonTNP,
								valueGetter: function (params) {

									if (params.data.gate_in_time) {
										//return getDDMMYYYYHHMMSS(params.data.gate_in_time);
										return params.data.gate_in_time;
									}
									else {
										return "NA"
									}

								}
							},

							{
								headerName: "Gate Out Time",
								field: "gate_out_time",
								width: 180,
								resizable: true,
								hide: hideonTNP,
								valueGetter: function (params) {
									//	console.log("IN gate_out_time valueGetter, params = ", params);

									if (params.data.gate_out_time) {
										//console.log("IN gate_out_time valueGetter, IF params.data.gate_out_time = ", params.data.gate_out_time);
										//return getDDMMYYYYHHMMSS(params.data.gate_out_time);
										return params.data.gate_out_time;
									}
									else {
										//console.log("IN gate_out_time valueGetter, ELSE params.data.gate_out_time = ", params.data.gate_out_time);
										return "NA"
									}
								}
							},


							{
								headerName: "Gate In Time",
								field: "gate_in_time",
								width: 180,
								resizable: true,
								hide: showonTNP,
								valueGetter: function (params) {
									// console.log("IN gate_out_time valueGetter, params = ", params);

									if (params.data.gate_in_time) {
										// console.log("IN gate_out_time valueGetter, IF params.data.gate_out_time = ", params.data.gate_in_time);
										//return getDDMMYYYYHHMMSS(params.data.gate_out_time);
										return params.data.gate_in_time;
									}
									else {
										// console.log("IN gate_out_time valueGetter, ELSE params.data.gate_out_time = ", params.data.gate_in_time);
										return "NA"
									}
								}
							},


							{
								headerName: "Gate Out Time",
								field: "gate_out_time",
								width: 180,
								resizable: true,
								hide: showonTNP,
								valueGetter: function (params) {
									// console.log("IN gate_out_time valueGetter, params = ", params);

									if (params.data.gate_out_time) {
										// console.log("IN gate_out_time valueGetter, IF params.data.gate_out_time = ", params.data.gate_out_time);
										//return getDDMMYYYYHHMMSS(params.data.gate_out_time);
										return params.data.gate_out_time;
									}
									else {
										// console.log("IN gate_out_time valueGetter, ELSE params.data.gate_out_time = ", params.data.gate_out_time);
										return "NA"
									}
								}
							},
							{
								headerName: "Loading Time",
								field: "loading_time",
								width: 180,
								valueGetter: param => {
									const data = param.data.loading_time
									if (data.includes('NaN')) {
										return 'NA'
									} else {
										return data
									}
								}
							}

						],
						overlayNoRowsTemplate: 'No rows to show',


					},
					getDetailRowData: function (params) {
						// console.log("Step 4 ", params)
						if (hideonTNP != true) {
							// console.log("Inner here")
							if (params.data.gate_in_time != undefined) {
								var g1 = getDDMMYYYYHHMMSS(params.data.gate_in_time);
								//var g1 = (params.data.gate_in_time) ? moment.parseZone(params.data.gate_in_time).format('DD-MM-YYYY') : "";
								var gdate1 = new Date(params.data.gate_in_time);
								var fromDate = g1;
								var secondsgate1 = moment.parseZone(new Date(params.data.gate_in_time)).format("x")
							}
							else {

								var g1 = getDDMMYYYYHHMMSS(params.data.inside_fence);
								var gdate1 = new Date(params.data.inside_fence);
								var fromDate = g1;
								var secondsgate1 = moment.parseZone(new Date(params.data.inside_fence)).format("x")

							}
							//console.log("g1 ", g1)//console.log("Step 4 ",params)
							if (params.data.gate_out_time != undefined) {
								var g2 = getDDMMYYYYHHMMSS(params.data.gate_out_time);
								var gdate2 = new Date(params.data.gate_out_time);
								var toField = getDDMMYYYYHHMMSS(params.data.gate_out_time);
								//console.log("gatein ", gatein)
								//console.log("gateout ", gateout)
								//var total =0 ;
								var secondsgate2 = moment.parseZone(new Date(params.data.gate_out_time)).format("x")

							}
							else {
								var c = new Date();
								var todaydate = moment(new Date(c)).format("x");
								var g2 = getDDMMYYYYHHMMSS(todaydate);
								var gdate2 = new Date(todaydate);
								var toField = getDDMMYYYYHHMMSS(new Date());
								var secondsgate2 = todaydate

							}
						}
						else {
							var fromDate = getDDMMYYYYHHMMSS(params.data.inside_fence);
							var secondsgate1 = moment.parseZone(new Date(params.data.inside_fence)).format("x")
							var secondsgate2 = moment.parseZone(new Date(params.data.outside_fence)).format("x")
							var toField = getDDMMYYYYHHMMSS(params.data.outside_fence);
						}

						//console.log("secondsg1 ", secondsg1)
						//var g2 = getDDMMYYYY(params.data.gate_out_time);

						//console.log("g1 ", g2)
						// var s1 = g1.split("-");
						// var s2 = g2.split("-");


						// var gatein = gdate1.getTime();
						// var gateout = gdate2.getTime();
						var total = secondsgate2 - secondsgate1;
						//console.log("total ", total)
						var diffMs = total;
						var diffDays = Math.ceil(diffMs / 86400000); // days
						var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
						var diffMins = parseInt(((diffMs % 86400000) % 3600000) / 60000);
						// console.log(diffDays + " " + diffHrs + " " + diffMins);
						let loadingTimeDifference = null;

						var dataarr = []

						dataarr.push({
							"gate_in_time": fromDate,
							"gate_out_time": toField,
							//"loading_time": diffHrs+" Hr(s) "+" "+diffMins+" Min(s)"
							"loading_time": secondsToString(diffMs / 1000)
						})
						// console.log("dataarr ", dataarr)
						params.successCallback(dataarr);
					}

				}
			});

			//if(params.column.colId == 'loading_delay_exception')

			if (params.column.colDef.field == 'loading_delay_exception') {

				params.node.setExpanded(!params.node.expanded);
			}
			else {

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else {

		}

	}

	onShowUnLoadingDelayKpi = async (params) => {
		let eventLabel = googleAnalytics.page.action.unloadingDelay;
		let eventOptions = {
			"category": this.state.pagetitle,
			"action": this.state.eventAction,
			"label": eventLabel,
		}
		googleAnalytics.logEvent(eventOptions);
		//console.log("IN onShowUnLoadingDelayKpi, params = ",params);
		//console.log(e);
		//console.log("Consign Overspeed ",params);
		//console.log(params.column.colId);
		if (params != 0) {
			var overspeedData = [];


			//console.log("Step 3 ",overspeedData)

			await this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [

							{
								headerName: "From Date",
								field: "from_date",
								width: 180,
								resizable: true,
								valueGetter: function (params) {
									// console.log("child params ", params)
									if (params.data.from_date != undefined) {
										return getDDMMYYYYHHMMSS(params.data.from_date);
									}
									else {
										return "NA"
									}

								}
							},
							{
								headerName: "To Date",
								field: "to_date",
								width: 180,
								resizable: true,
								valueGetter: function (params) {
									if (params.data.to_date != undefined) {
										return getDDMMYYYYHHMMSS(params.data.to_date);
									}
									else {
										return "NA"
									}
								}
							},
							{
								headerName: "Unloading Time",
								field: "unloading_time",
								width: 180,

							}

						],
						overlayNoRowsTemplate: 'No rows to show',

					},
					getDetailRowData: function (params) {
						//console.log("Step 4 ",params)
						if (params.data.locked_dealer_reported == undefined) {
							var g1 = getYYYYMMDDHHMMSS(params.data.first_dealer_reported);
							var fromField = g1;
						}
						else {

							var g1 = getYYYYMMDDHHMMSS(params.data.locked_dealer_reported);
							var fromField = g1;
						}
						//console.log("params.data.left_dealer_marked ", params.data.left_dealer_marked)
						if (params.data.left_dealer_marked == undefined) {
							var c = new Date();
							var todaydate = c;
							var g2 = getYYYYMMDDHHMMSS(todaydate);
							var toField = g2;
						}
						else {
							var g2 = getYYYYMMDDHHMMSS(params.data.left_dealer_marked);
							var toField = g2;
							//console.log("toField stee " ,toField);
						}
						var fstr = fromField.split("-");
						//console.log(fstr);
						var gdate1 = new Date(fromField);
						var gdate2 = new Date(toField);
						var frmd = gdate1.getTime();
						var tod = gdate2.getTime();
						var total = tod - frmd;
						//console.log("From Date ", fromField)
						//console.log("To Date ", toField)
						//var total =0 ;
						//console.log("gdate1 ", frmd)
						//console.log("gdate2 ", tod)
						//console.log("Total ", total)
						var diffMs = total;
						var diffDays = Math.floor(diffMs / 86400000); // days
						var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
						var diffMins = parseInt(((diffMs % 86400000) % 3600000) / 60000);

						// var sr = params.data.unloading_time_in_hrs;
						// var hrs=sr.toString().split(".");
						// var hours = parseInt(hrs[0]);
						// var mins = Math.round((hrs[1]/100)*60)
						var dataarr = []
						// dataarr.push({
						// 	"from_date":fromField,
						// 	"to_date": toField,
						// 	"unloading_time":diffHrs+" Hr(s) "+" "+diffMins+" Min(s)"
						// 	//"unloading_time":hours+"Hr(s) "+mins+" min(s)"
						// })
						let loadingTimeDifference = null;
						// if (diffDays) {
						// 	loadingTimeDifference = diffDays+" Day(s) " + diffHrs + " Hr(s) "+" "+diffMins+" Min(s)";
						// } else {
						// 	loadingTimeDifference = diffHrs+" Hr(s) "+" "+diffMins+" Min(s)";
						// }
						loadingTimeDifference = secondsToString(diffMs / 1000)
						dataarr.push({
							"from_date": fromField,
							"to_date": toField,
							"unloading_time": loadingTimeDifference
							//"unloading_time":hours+"Hr(s) "+mins+" min(s)"
						})
						params.successCallback(dataarr);

					}

				}
			});

			//if(params.column.colId == 'unloading_delay_exception')
			if (params.column.colDef.field == 'unloading_delay_exception') {

				params.node.setExpanded(!params.node.expanded);
			}
			else {

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else {

		}

	}


	onShowDeviate = async (params) => {
		let eventLabel = googleAnalytics.page.action.deviation;
		let eventOptions = {
			"category": this.state.pagetitle,
			"action": this.state.eventAction,
			"label": eventLabel,
		}
		googleAnalytics.logEvent(eventOptions);
		// console.log("IN onShowDeviate, params = ", params);

		//console.log(e);
		//console.log("Consign Overspeed ",params);

		if (params != 0) {
			var overspeedData = [];

			await this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							{ headerName: "Vin No", field: "truck_no" },
							{ headerName: "Speed (km/h)", field: "speed" },
							{ headerName: "Reported At", field: "first_instance" }
						]
					},
					getDetailRowData: async function (params) {
						// console.log("Step 4 ", overspeedData)
						await params.successCallback(overspeedData);
					}
				}
			});

			if (params.column.colId == '_id_1' || params.column.colId == '_id_2') {

				params.node.setExpanded(!params.node.expanded);
			}
			else {

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else {
			this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							{ headerName: "Vin No", field: "truck_no" },
							{ headerName: "Speed (km/h)", field: "speed" },
							{ headerName: "Reported At", field: "first_instance" }
						]

					},
					getDetailRowData: function (params) {
						//console.log("Child ", params);
						params.successCallback([]);
					}
				}
			});
		}

	}
	onRowClicked(params) {
		// console.log("IN onRowClicked, params = ", params);
		// console.log("row click ", params)
		if (params.column.colId == 'consignment_code') {
			this.setState({
				sliderSegmentTranslate: "slider-translate-60p",
				segementConsignmentCode: params.data.consignment_code,
				overly: "show-m"
			})
		}
	}
	closeSegmentSideBar() {
		this.setState({
			sliderSegmentTranslate: "",
			segementConsignmentCode: "",
			overly: "show-n",
			basicTitle: "No Segment Record Found",
			basicType: "danger",
			show: true
		});
	}
	onShowRouteMap(rownode) {
		// console.log("IN onShowRouteMap, params = ",rownode);
		// console.log("rownode", rownode);
		// console.log("rownode.consignment_code ", rownode.consignment_code)
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.setState({
			loadshow: 'show-m'
		})
		var d = new Date(rownode.first_instance);
		var r = new Date(rownode.recent_instance);
		var finstance = rownode.first_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		var rinstance = rownode.recent_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		var params = {
			truck_no: rownode.truck_no,
			consignment_code: rownode.consignment_code,
			consignee_code: rownode.consignee_code,
			first_instance: finstance,
			recent_instance: rinstance,
			screen: "consignment"
		}

		redirectURL.post('/reports/overspeedgpsroutes', params)
			.then((response) => {

				//var records = JSON.parse(JSON.stringify(response)).data;
				// console.log(response.data);
				this.setState({
					mapinfo: response.data,
					dealer: rownode.consignee_code,
					sliderRouteTranslate: "slider-translate-50p",
					consignment_code: rownode.truck_no,
					maptruckno: rownode.truck_no,
					routeTruck: {
						"truck_no": rownode.truck_no, "startTime": response.data['route_details']['start_time'],
						"endTime": response.data['route_details']['end_time']
					},
					overly: 'show-m',
					loadshow: 'show-n',
					rownode: rownode,
					tolls: response.data.tolls
				});
				this.renderMap();


			})
			.catch(function (error) {
				console.log(error);
			});

	}

	onShowTransitRouteMap(rownode) {
		//console.log("IN onShowTransitRouteMap, params = ",rownode);
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		//console.log("rownode", rownode);
		this.setState({
			loadshow: 'show-m'
		})
		var d = new Date(rownode.leg_start);
		var r = new Date(rownode.leg_end_eta);
		var fintance = rownode.leg_start.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		var eintance = rownode.leg_end_eta.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		var params = {
			truck_no: rownode.truck_no,
			consignment_code: rownode.consignment_code,
			consignee_code: rownode.consignee_code,
			first_instance: fintance,
			recent_instance: eintance,
			screen: "consignment"
		}
		redirectURL.post('/reports/nightdriveroutes', params)
			.then((response) => {

				//var records = JSON.parse(JSON.stringify(response)).data;
				//console.log("Inner Grid ",response.data);
				if (response.data.coords.length == 0) {
					this.setState({
						show: true,
						basicTitle: 'No Route data available',
						basicType: "danger",
						loadshow: 'show-n'
					});
				}
				else {
					var sdate = '';
					var edate = '';

					if (rownode.leg_start != '' && rownode.leg_start != undefined) {
						sdate = rownode.leg_start;
					}
					if (rownode.leg_end_eta != '' && rownode.leg_end_eta != undefined) {
						edate = rownode.leg_end_eta;
					}
					this.setState({
						mapinfo: response.data,
						dealer: rownode.consignee_code,
						sliderRouteTranslate: "slider-translate-60p",
						defTransitCoords: rownode.consignee_coordinates,
						consignment_code: "Vin No : " + rownode.truck_no,
						maptruckno: rownode.truck_no,
						routeTruck: { "truck_no": rownode.truck_no, "startTime": sdate, "endTime": edate },
						loadshow: 'show-n',
						sidebarSubHeader: "Consignment Leg Information",
						overly: 'show-m',
						rownode: rownode,
						leg_no: rownode.leg_no,
						tolls: response.data.tolls

					});

					this.onShowGoogleRoute(rownode.consignment_code, rownode.consignee_code, rownode.leg_no, rownode.truck_no)
					this.renderMap();

				}
			})
			.catch(function (error) {
				console.log(error);
			});

	}

	onShowNoGPSRouteMap(rownode) {
		//console.log("IN onShowNoGPSRouteMap, params = ",rownode);
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		// console.log("rownode", rownode);
		this.setState({
			loadshow: 'show-m'
		})
		var fintance = rownode.last_packet_datetime.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		//var eintance = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
		var eintance = rownode.packet_received_on.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		var params = {
			truck_no: rownode.truck_no,
			consignment_code: rownode.consignment_code,
			//consignee_code:rownode.consignee_code,
			first_instance: fintance,
			recent_instance: eintance,
			screen: "consignment"
		}
		redirectURL.post('/reports/nightdriveroutes', params)
			.then((response) => {

				//var records = JSON.parse(JSON.stringify(response)).data;
				// console.log("responseDataForLeg",response.data);
				if (response.data.coords.length == 0) {
					this.setState({
						show: true,
						basicTitle: 'No Route data available',
						basicType: "danger",
						loadshow: 'show-n'
					});
				}
				else {
					var sdate = '';
					var edate = '';

					if (rownode.last_packet_datetime != '' && rownode.last_packet_datetime != undefined) {
						sdate = rownode.last_packet_datetime;
					}

					// if (rownode.data_received_on != '' && rownode.data_received_on != undefined) {
					// 	edate = rownode.data_received_on;
					// }
					if (rownode.packet_received_on != '' && rownode.packet_received_on != undefined) {
						edate = rownode.packet_received_on;
						// console.log("edate",edate)
					}
					this.setState({
						mapinfo: response.data,
						dealer: rownode.consignee_code,
						sliderRouteTranslate: "slider-translate-60p",
						defTransitCoords: rownode.consignee_coordinates,
						consignment_code: "Vin No : " + rownode.truck_no,
						maptruckno: rownode.truck_no,
						routeTruck: { "truck_no": rownode.truck_no, "startTime": sdate, "endTime": edate },
						loadshow: 'show-n',
						sidebarSubHeader: "Consignment Leg Information",
						overly: 'show-m',
						rownode: rownode,
						leg_no: rownode.leg_no,
						tolls: response.data.tolls

					});

					this.onShowGoogleRoute(rownode.consignment_code, rownode.consignee_code, rownode.leg_no, rownode.truck_no)
					this.renderMap();

				}
			})
			.catch(function (error) {
				console.log(error);
			});

	}

	onShowNightDriveRouteMap(rownode) {
		// console.log("IN onShowNightDriveRouteMap, params = ",rownode);
		// console.log("nightdrivenode", rownode);
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.setState({
			loadshow: 'show-m'
		});
		var d = new Date(rownode.first_instance);
		var r = new Date(rownode.recent_instance);
		var fintance = rownode.first_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		var eintance = rownode.recent_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		var params = {
			truck_no: rownode.truck_no,
			first_instance: fintance,
			recent_instance: eintance,
		}

		redirectURL.post('/reports/nightdriveroutes', params)
			.then((response) => {

				//var records = JSON.parse(JSON.stringify(response)).data;
				// console.log("nightroutedata", response.data);

				this.setState({
					mapinfo: response.data,
					sliderRouteTranslate: "slider-translate-50p",
					consignment_code: rownode.truck_no,
					defTransitCoords: { "lat": rownode.first_lat, "lng": rownode.first_lng },
					maptruckno: rownode.truck_no,
					routeTruck: {
						"truck_no": rownode.truck_no, "startTime": response.data['route_details']['start_time'],
						"endTime": response.data['route_details']['end_time']
					},
					loadshow: 'show-n',
					overly: 'show-m',
					tolls: response.data.tolls
				});
				this.renderMap();


			})
			.catch(function (error) {
				console.log(error);
			});
	}

	onShowDayWiseRouteMap = (rownode) => {
		//console.log("IN onShowNoGPSRouteMap, params = ",rownode);
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		//console.log("rownode", rownode);
		this.setState({
			loadshow: 'show-m'
		})
		var fintance = rownode.start_time.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		//var eintance = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
		var eintance = rownode.end_time.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		var params = {
			truck_no: rownode.truck_no,
			// consignment_code:rownode.consignment_code,
			//consignee_code:rownode.consignee_code,
			startDate: fintance,
			endDate: eintance,
			screen: "consignment"
		}
		if (["/prtconsignments", "/sndconsignments"].includes(this.props.match.path)) params.inTransit = 1

		redirectURL.post('/gmap', params)
			.then((response) => {
				var records = JSON.parse(response.data.data);
				// console.log("records ", records)
				if (records.length == 0) {
					this.setState({
						show: true,
						basicTitle: 'No Route data available',
						basicType: "danger",
						loadshow: 'show-n'
					});
				}
				else {
					var sdate = '';
					var edate = '';

					// if (rownode.leg_start != '' && rownode.leg_start != undefined) {
					// 	sdate = rownode.leg_start;
					// }
					// if (rownode.leg_end_eta != '' && rownode.leg_end_eta != undefined) {
					// 	edate = getDDMMYYYYHHMMSS(rownode.leg_end_eta)
					// }
					this.setState({
						mapinfo: records,
						dealer: rownode.consignee_code,
						sliderRouteTranslate: "slider-translate-60p",
						defTransitCoords: rownode.consignee_coordinates,
						consignment_code: "Vin No : " + rownode.truck_no,
						maptruckno: rownode.truck_no,
						routeTruck: { "truck_no": rownode.truck_no, "startTime": fintance, "endTime": eintance },
						loadshow: 'show-n',
						sidebarSubHeader: "View Information",
						overly: 'show-m',
						rownode: rownode,
						leg_no: rownode.leg_no,
						tolls: response.data.tolls

					});

					this.onShowGoogleRoute(rownode.consignment_code, rownode.consignee_code, rownode.leg_no, rownode.truck_no)
					this.renderMap();

				}
			})
			.catch(function (error) {
				console.log(error);
			});

	}
	onClickHideAll() {
		this.setState({
			loadshow: 'show-n',
			overly: 'show-n',
			overlyFixed:"show-n",
			slideuploadeway: '',
			sliderRouteTranslate: '',
			sliderBulkUpdate:"",
			sliderSegmentTranslate: "",
			tabsliderTranslate: '',
			sliderTranslatesidebar: '',
			sliderForceCloseTranslate: '',
			sliderTPTRemarkTranslate:"",
			sliderTPTDeviationTranslate:"",
			sliderTPTBreakdownTranslate:"",
			sliderTPTAccidentDocuments:"",
			sliderTPTConfirmDeliver:"",
			sliderTPTDriverTranslate:"",
			sliderCommentTranslate: "",
			bulkPrioritySlide: "",
			tpt_driver_name:'',
			tpt_driver_mobile:"",
			tpt_convoy_leader_name:"",
			tpt_convoy_leader_mobile:"",
			sliderCreateConsignmentTranslate: "",
			sliderTPTAccidentTranslate:"",
			tpt_deviation_location:"",
			tpt_deviation_city:"",
			tpt_deviation_address:"",
			tpt_deviation_zone:"",
			tpt_deviation_postal_code:"",
			tpt_approved_deviation_no:"",
			body_shop:{value:"",label:"Select"},
			ship_to_party:{value:"",label:"Select"},
			tpt_deviation_status:"No",
			accident_exception:"No",
			overlyFixed:"show-n",
			showfirfield:"show-n",
			tpt_accident_fir_status:[{value:"No",label:"No"},{value:"Yes",label:"Yes"}],
			tpt_accident_a3_report_submission:{value:"No",label:"No"},
			tpt_accident_damage_vehicle:"",
			tpt_accident_scenario:"",
			tpt_accident_third_party_damage:"",
			tpt_accident_vehicle_current_status:"",
			tpt_accident_fir_date:"",
			transitrowdata:[],
			sliderBulkDriverUpdate:"",
			drivercontent:[],
			driverfile:"",
			tpt_break_down_location:""
			
		});

	}

	showGoogleRouteAlert = () => {
		this.setState({
			show: true,
			basicTitle: "No google routes found",
			basicType: "danger"
		});
	}

	closeAlert = () => {
		this.setState({
			show: false
		});
	}
	
	closeAlertReload = async () => {
		await this.setState({
			show1: false
		});
		await window.location.reload();
	}
	onClickCounterShowDataTEST(params) {
		// console.log("IN onClickCounterShowData Do NOTHING- params = ", params);
	}

	onClickCounterShowData(params) {
		// console.log("IN onClickCounterShowData, params = ", params);
		if (googleAnalytics.page.enableGA) {
			let eventLabel = null;
			if (params) {
				eventLabel = googleAnalytics.page.kpi[params];
				//console.log("eventLabel = ", eventLabel);
			}

			let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventCounterAction,
				"label": eventLabel,
			}
			googleAnalytics.logEvent(eventOptions);
		}


		if (params == "transit_delay") {
			// var filterComponent = this.gridApi.getFilterInstance("transit_delay");
			// filterComponent.selectNothing();
			// filterComponent.selectValue("1");
			// if(this.state.tripvalue != undefined && this.state.tripvalue != '')
			// {
			// 	filterComponent.selectValue(this.state.tripvalue);
			// }
			// filterComponent.applyModel();
			this.setState({
				counterTitle:"Consingments - Transit Delay"
			})
			var gridData = this.state.allRowData
			if (this.state.tnpReturnConsignmentsVisibilty) { gridData = this.state.returnConsignments }
			let isFilterDataAvailable = gridData.filter(rec => {
				if (rec["transit_delay"]) {
					return rec["transit_delay"] == 1;
				}
			});
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}
		else if(params == 'no_gate_out_time'){
			this.gridApi.setRowData(this.state.withoutTruckGateOutTimeData);
			this.gridApi.onFilterChanged();
		}
		else if (params == "truck_reporting_date") {
			// console.log(this.state.truckReportedDate, "trkfilter");
			this.gridApi.setRowData(this.state.truckReportedDate);
			this.gridApi.onFilterChanged();
		}
		else if (params == "probable_accident") {
			let isFilterDataAvailable = this.state.allRowData.filter(rec => {
				if (rec["probable_accident"]) {
					return rec["probable_accident"] == 1;
				}
			});
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}
		else if (params == "probable_accident_confirm") {
			let isFilterDataAvailable = this.state.allRowData.filter(rec => {
				if (rec["probable_accident_confirm"]) {
					return rec["probable_accident_confirm"] == 1;
				}
			});
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}
		else if (params == "is_critical") {
			//var filterComponent = this.gridApi.getFilterInstance("is_critical");
			// filterComponent.selectNothing();
			// filterComponent.selectValue("1");
			if (this.state.tripvalue != undefined && this.state.tripvalue != '') {
				//filterComponent.selectValue(this.state.tripvalue);
			}
			// filterComponent.applyModel();
			var gridData = this.state.allRowData
			if (this.state.tnpReturnConsignmentsVisibilty) { gridData = this.state.returnConsignments }
			let isFilterDataAvailable = gridData.filter(rec => {
				if (rec["is_critical"]) {
					return rec["is_critical"] == true;
				}
			});
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}
		else if (params == "is_priority") {
			//var filterComponent = this.gridApi.getFilterInstance("is_critical");
			// filterComponent.selectNothing();
			// filterComponent.selectValue("1");
			if (this.state.tripvalue != undefined && this.state.tripvalue != '') {
				//filterComponent.selectValue(this.state.tripvalue);
			}
			// filterComponent.applyModel();
			var gridData = this.state.allRowData
			if (this.state.tnpReturnConsignmentsVisibilty) { gridData = this.state.returnConsignments }
			let isFilterDataAvailable = gridData.filter(rec => {
				if (rec["is_priority"]) {
					return rec["is_priority"] == true;
				}
			});
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}
		else if (params == "no_gps_data") {
			// var filterComponent = this.gridApi.getFilterInstance("no_gps_data");
			// filterComponent.selectNothing();
			// filterComponent.selectValue("1");
			// if(this.state.tripvalue != undefined && this.state.tripvalue != '')
			// {
			// 	filterComponent.selectValue(this.state.tripvalue);
			// }
			// filterComponent.applyModel();
			let rowData = this.state.allRowData			
			// const uniqueTruck = rowData.reduce((item, truck) =>{
			// 	item[truck.truck_no] = truck
			// 	return item
			// }, {})

			// const output = Object.values(uniqueTruck)
			// console.log("output", output)

			let isFilterDataAvailable = rowData.filter(rec => {
				if (rec["no_gps_data"]) {
					return rec["no_gps_data"] == 1;
				}
			});
			// console.log("isFilterDataAvailable ", isFilterDataAvailable)
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}
		else if (params == "no_gps_data_lifetime") {
			// var filterComponent = this.gridApi.getFilterInstance("no_gps_data_lifetime");
			// filterComponent.selectNothing();
			// filterComponent.selectValue("1");
			if (this.state.tripvalue != undefined && this.state.tripvalue != '') {
				this.gridApi.getFilterInstance("no_gps_data_lifetime").selectValue(this.state.tripvalue);
			}
			//this.gridApi.getFilterInstance("no_gps_data_lifetime").applyModel();
			let isFilterDataAvailable = this.state.allRowData.filter(rec => {
				if (rec["no_gps_data_lifetime"]) {
					return (rec["no_gps_data_lifetime"] == 1);
				}
			});
			// console.log("isFilterDataAvailable ", isFilterDataAvailable)
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}
		else if (params == "overspeeding_exception") {
			// console.log(this.gridApi, "this.gridApi")
			// console.log("in oveerspeeding");
			// console.log(this.state.rowData);
			this.setState({
				counterTitle:"Consingments - Overspeeding"
			})
			let isFilterDataAvailable = this.state.allRowData.filter(rec => {
				if (rec["overspeeding_exception"]) {
					return rec["overspeeding_exception"] == 1;
				}
			});
			// console.log(isFilterDataAvailable);
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}
		else if (params == "nightdriving_exception") {
			// var filterComponent = this.gridApi.getFilterInstance("nightdriving_exception");
			// filterComponent.selectNothing();
			// filterComponent.selectValue("1");
			// if(this.state.tripvalue != undefined && this.state.tripvalue != '')
			// {
			// 	filterComponent.selectValue(this.state.tripvalue);
			// }
			// filterComponent.applyModel();
			this.setState({
				counterTitle:"Consingments - Night Driving"
			})
			let isFilterDataAvailable = this.state.allRowData.filter(rec => {
				if (rec["nightdriving_exception"]) {
					return rec["nightdriving_exception"] == 1;
				}
			});
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}
		else if (params == "unloading_delay_exception") {
			// var filterComponent = this.gridApi.getFilterInstance("unloading_delay_exception");
			// filterComponent.selectNothing();
			// filterComponent.selectValue("1");
			// if(this.state.tripvalue != undefined && this.state.tripvalue != '')
			// {
			// 	filterComponent.selectValue(this.state.tripvalue);
			// }
			// filterComponent.applyModel();
			let isFilterDataAvailable = this.state.allRowData.filter(rec => {
				if (rec["unloading_delay_exception"]) {
					return rec["unloading_delay_exception"] == 1;
				}
			});
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}
		else if(params == 'force_closed'){
			let isFilterDataAvailable = this.state.allRowData.filter(rec => {
				if (rec["status"]) {
					return rec["status"] == 6;
				}
			});
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}
		else if (params == "loading_delay_exception") {
			// var filterComponent = this.gridApi.getFilterInstance("loading_delay_exception");
			// filterComponent.selectNothing();
			// filterComponent.selectValue("1");
			// if(this.state.tripvalue != undefined && this.state.tripvalue != '')
			// {
			// 	filterComponent.selectValue(this.state.tripvalue);
			// }
			// filterComponent.applyModel();
			let isFilterDataAvailable = this.state.allRowData.filter(rec => {
				if (rec["loading_delay_exception"]) {
					return rec["loading_delay_exception"] == 1;
				}
			});
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}
		else if (params == "enroute_exception") {
			var gridData = this.state.allRowData
			this.setState({
				counterTitle:"Consingments - Stoppages"
			})
			if (this.state.tnpReturnConsignmentsVisibilty) { gridData = this.state.returnConsignments }
			let isFilterDataAvailable = gridData.filter(rec => {
				if (rec["enroute_exception"] != undefined || rec["enroute_exception"] != '') {
					return rec["enroute_exception"] == 1;
				}
			});
			//console.log("isFilterDataAvailable Enrotue ", isFilterDataAvailable)
			if (isFilterDataAvailable.length > 0) {
				// var filterComponent = this.gridApi.getFilterInstance("enroute_exception");
				// try{
				// 	if (filterComponent) {
				// 		filterComponent.selectNothing();
				// 		filterComponent.selectValue(1);
				// 		if(this.state.tripvalue != undefined && this.state.tripvalue != '')
				// 		{
				// 			filterComponent.selectValue(this.state.tripvalue);
				// 		}
				// 		filterComponent.applyModel();
				// 	}

				// }
				// catch(e){
				// 	console.log(e)
				// }

				this.gridApi.setRowData(isFilterDataAvailable);
				this.gridApi.onFilterChanged();
			}
			else {
				this.gridApi.setRowData([]);
				this.gridApi.onFilterChanged();
			}

		}
		else if (params == "cancelled") {
			this.gridApi.setRowData(this.state.cancelled_data)
		}
		else if (params == "pod") {
			this.gridApi.setRowData(this.state.pod_received_data)
		}
		else if (params == "trip_closed") {
			this.gridApi.setRowData(this.state.trip_closed_by_pod)
		}
		else if (params == "deviation") {
			// this.gridApi.setRowData(this.state.originalcountersjson.deviated_routes)
			this.setState({
				counterTitle:"Consingments - Deviation"
			})
			let isFilterDataAvailable = this.state.rowData.filter(rec => {
				if (rec["deviation_flag"]) {
					return rec["deviation_flag"] == 1;
				}
			});
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}
		else if (params == "accident") {
			// this.gridApi.setRowData(this.state.originalcountersjson.deviated_routes)
			this.setState({
				counterTitle:"Consingments - Accident"
			})
			let isFilterDataAvailable = this.state.rowData.filter(rec => {
				if (rec["accident_exception"]) {
					return rec["accident_exception"] == 1;
				}
			});
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}
		else if (params == "tripdeviations") {
			this.setState({
				counterTitle:"Consingments - Trips Deviation"
			})
			// this.gridApi.setRowData(this.state.originalcountersjson.deviated_routes)
			let isFilterDataAvailable = this.state.rowData.filter(rec => {
				if (rec["deviation_lifetime"]) {
					return rec["deviation_lifetime"] == 1;
				}
			});
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}
		else if (params == "deviations") {
			// this.gridApi.setRowData(this.state.originalcountersjson.deviated_routes)
			this.setState({
				counterTitle:"Consingments - Deviations"
			})
			let isFilterDataAvailable = this.state.rowData.filter(rec => {
				if (rec["tpt_deviation_status"]) {
					return rec["tpt_deviation_status"] == "Yes";
				}
			});
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}
		else {
			this.setState({
				counterTitle:""
			})
			if (this.state.tnpReturnConsignmentsVisibilty == true) {
				this.gridApi.setRowData(this.state.returnConsignments)
			}
			else if (this.state.tripvalue != undefined && this.state.tripvalue != '') {

				this.gridApi.setRowData(this.state.rowData);
			}
			else {
				if (this.props.match.path == "/prtconsignments") {
					this.gridApi.setRowData(this.state.rowData);
				}else{
					this.gridApi.setRowData(this.state.allRowData);
				}				
			}
			this.gridApi.onFilterChanged();
		}
	}
	// onSelectTrip(value){
	// 	//console.log(value.target.value)
	// 	this.setState({
	// 		triptype:value.target.value
	// 	});
	// }


	onSelectTrip = triptype => {
		this.setState(
			{ triptype },
			// () => console.log(`Trip Option selected:`, this.state.triptype)
		);

		this.setState({
			errorMessage: '',
			successMessage: ''
		})
	}

	changeConsignerArrayHandler = consigner => {
		this.setState(
			{ consigner },
			// () => console.log(`Consigner Option selected:`, this.state.consigner)
		);

		this.setState({
			errorMessage: '',
			successMessage: ''
		})
	}
	changeMovementArrayHandler = movementtype => {
		this.setState(
			{ movementtype },
			// () => console.log(`Movement Option selected:`, this.state.movementtype)
		);

	}
	selectConsignerOptionsItems() {
		let items = [];

		var plants = this.state.plants;

		items.push({ value: 'all', label: 'All' });
		if (plants.length > 0) {
			plants.forEach(item => {
				if (this.state.loginplant != "" && this.state.loginplant != undefined) {
					if (this.state.loginplant == item.plant_code) {
						items.push({ value: item.plant_code, label: item.plant_name });
					}

				}
				else {
					items.push({ value: item.plant_code, label: item.plant_name });
				}

			});
		}


		return items;
	}

	selectMovementTypeOptionsItems() {
		let items = [];
		items.push({ value: "all", label: "All" });
		items.push({ value: true, label: "Local Movement" });
		items.push({ value: false, label: "Non Local Movement" });
		return items;
	}
	/*Venkatesh Jalla : New code for filters*/
	onGetConsigners = async (event) => {

		var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

		if (this.props.match.path == "/sndconsignments" || this.props.match.path == "/prtconsignments"
			|| this.props.match.path == "/tnpconsignments") {

			if (this.state.startDate == '' || this.state.startDate == "NaN-NaN-NaN") {
				//var sDate = this.state.defaultsdate;
				var sDate = "";
			}
			else {
				var sDate = this.state.startDate;
			}
			if (this.state.endDate == '' || this.state.startDate == "NaN-NaN-NaN") {
				//var eDate = this.state.defaultedate;
				var eDate = "";
			}
			else {
				var eDate = this.state.endDate;
			}

		}
		else {
			if (this.state.startDate == '' || this.state.startDate == "NaN-NaN-NaN") {
				var sDate = this.state.defaultsdate;
			}
			else {
				var sDate = this.state.startDate;
			}
			if (this.state.endDate == '' || this.state.startDate == "NaN-NaN-NaN") {
				var eDate = this.state.defaultedate;
			}
			else {
				var eDate = this.state.endDate;
			}
		}

		// console.log("flCity", this.state.flCity);
		// console.log("flState", this.state.flState);
		// console.log("flZone", this.state.flZone);
		// console.log("flTransporter", this.state.flTransporter);
		// var sDate=this.state.startDate;
		// var eDate = this.state.endDate;
		if (this.state.flTransporter.value != "" && this.state.flTransporter.value != undefined && this.state.flTransporter.value != null) {
			var transporter_code = this.state.flTransporter.value;
		}
		else {
			var transporter_code = "";
		}

		if (this.state.flZone != "" && this.state.flZone != undefined && this.state.flZone != null) {
			var zone = this.state.flZone.value;
		}
		else {
			var zone = "";
		}


		if (this.state.flState != "" && this.state.flState != undefined && this.state.flState != null) {
			var state = this.state.flState.value;
		}
		else {
			var state = "";
		}


		if (this.state.flCity != "" && this.state.flCity != undefined && this.state.flCity != null) {
			var city = this.state.flCity.value;
		}
		else {
			var city = "";
		}
		if(this.state.flDealer != "" && this.state.flDealer != undefined && this.state.flDealer != null) {
			var consignee_code = this.state.flDealer.value;
		}
		else {
			var consignee_code = "";
		}
		var selectconsigners = []
		// console.log('selectconsigners',this.state.consigner)
		if (this.state.consigner == null) {
			selectconsigners = [{ "value": 'all', "label": 'All' }]
		}
		else {
			selectconsigners = this.state.consigner
		}

		var mvmtype = []
		if (this.state.movementtype == null) {
			mvmtype = [{ "value": 'all', "label": 'All' }]
		}
		else {
			mvmtype = [this.state.movementtype]
		}

		//console.log("this.state.movementtype ", this.state.movementtype)
		//console.log("mvmtype ", mvmtype)
		var selecttrip = []
		// if(this.state.triptype == null)
		// {
		// 	selecttrip = [{"value":'all', "label":'All'}]
		// }
		// else{
		selecttrip = [this.state.triptype]
		// }
		if (this.state.deptcode == 'SNDG') {
			//console.log("S1 ")
			var requestparams = {
				consigner: selectconsigners,
				dept_code: encode(this.state.deptcode),
				startDate: sDate,
				endDate: eDate,
				transporter_code: transporter_code,
				zone: zone,
				state: state,
				city: city,
				consignee_code:consignee_code
			}

			if (this.state.flTransporter.value != "" && this.state.flTransporter.value != undefined && this.state.flTransporter.value != null) {
				requestparams.transporter_code = this.state.flTransporter.value;
			}
		}
		else if (this.state.deptcode == 'LOG-PRT') {
			//console.log("S2 ")
			var requestparams = {
				consigner: selectconsigners,
				dept_code: encode(this.state.deptcode),
				startDate: sDate,
				endDate: eDate,
				movement: mvmtype,
				transporter_code: transporter_code,
				state: state,
				city: city,
				consignee_code:consignee_code
			}
		}
		else {
			//console.log("S3 ")
			var requestparams = {
				consigner: selectconsigners,
				dept_code: encode(this.state.deptcode),
				startDate: sDate,
				endDate: eDate,
				triptype: selecttrip,
				consignee_code:consignee_code
			}
			if (this.state.flTransporter.value != "" && this.state.flTransporter.value != undefined && this.state.flTransporter.value != null) {
				requestparams.transporter_code = this.state.flTransporter.value;
			}
		}

		//console.log("requestparams ", requestparams)

		var urlpath = '';
		// if (this.props.match.path == "/sndconsignments") {

		// 	urlpath = '/consignments/filterSNDActiveConsignments';

		// }
		// else if (this.props.match.path == "/prtconsignments") {
		// 	urlpath = '/consignments/filterPRTActiveConsignments';

		// }
		// else if (this.props.match.path == "/tnpconsignments") {
		// 	urlpath = '/consignments/tnpconsignments';
		// }
		// else if (this.props.match.path == "/sndallconsignments") {
		// 	urlpath = '/consignments/allsndconsignments';
		// 	requestparams['screen'] = "all";
		// }
		// else if (this.props.match.path == "/insidemsilvicinty") {
		// 	urlpath = '/consignments/allsndconsignments';
		// }
		// else if (this.props.match.path == "/prtallconsignments") {
		// 	urlpath = '/consignments/allsndconsignments';
		// 	requestparams['screen'] = "all";
		// }
		// else if (this.props.match.path == "/tnpallconsignments") {
		// 	urlpath = '/consignments/alltnpconsignments';
		// 	requestparams['screen'] = "all";
		// }
		// else if (this.props.match.path == "/snddeliveredconsignments") {
		// 	urlpath = '/consignments/deliveredsndconsignments';
		// }
		// else if (this.props.match.path == "/prtdeliveredconsignments") {
		// 	urlpath = '/consignments/deliveredprtconsignments';
		// }
		// else if (this.props.match.path == "/tnpdeliveredconsignments") {
		// 	urlpath = '/consignments/deliveredtnpconsignments';
		// }
		// else if (this.props.match.path == "/railconsignments") {
		// 	urlpath = '/consignments/railsndconsignments';
		// }
		// else if (this.props.match.path == "/ncrconsignments") {
		// 	urlpath = '/consignments/ncrsndconsignments';
		// }
		// else if (this.props.match.path == "/moveconsignments") {
		// 	urlpath = '/consignments/movesndconsignments';
		// }

		if(this.state.allBtn === "btn-info")
		{
			urlpath = '/consignments/transportconsignments';
			requestparams['screen'] = "all";
		}
		
		if(this.state.intransitBtn === "btn-info")
		{
			urlpath = '/consignments/activetransporterconsignments';
		}
		
		if(this.state.deliveredBtn === "btn-info")
		{
			urlpath = '/consignments/deliveredtransporterconsignments';
		}
		// console.log("urlpath",urlpath,"requestparams",requestparams)
		this.onLoadGetConsignmentsList(urlpath, requestparams)
		this.setState({counterTitle : ""});
	}

	getTruckConsignments = (event) => {
		this.setState({
			loadshow: "show-m",
			overly: "show-m",
			total_accidents:"",
			total_deviations:""
		})
		event.preventDefault();
		// var selectedFilter = $("#selectedFilter").text();
		let selectedFilter = this.state.selectedFilter.value
		// console.log("selectedFilter",selectedFilter)
		var inputTruckConsignment = $("#inputTruckConsignment").val();
		if (selectedFilter == "Select..." || inputTruckConsignment == "") {
			this.setState({
				basicTitle: "Fields Should not be Empty",
				basicType: "danger",
				show: true,
				loadshow: "show-n",
				overly: "show-n"
			})
		}
		else {
			//console.log(selectedFilter,inputTruckConsignment);
			var urlpath = ""
			if (this.state.intransitBtn === "btn-info") {
				urlpath = "/consignments/filterByConsignmentTransporter";
			}
			if (this.state.deliveredBtn === "btn-info") {
				urlpath = "/consignments/filterTransporterDeliveredByConsignment";
			}
			if (this.state.allBtn === "btn-info") {
				urlpath = "/consignments/filterAllByConsignmentTransporter";
			}
			var transporter_code = "";
			var transportercode = localStorage.getItem('transportercode');
			if (transportercode != undefined && transportercode != "undefined" && transportercode != "") {
				// var tptCode = JSON.parse(transportercode);
				var tptCode = transportercode;
			}
			var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
			redirectURL.post(urlpath,
				{
					"selectedFilter": selectedFilter,
					"inputTruckConsignment": inputTruckConsignment,
					"dept_code": encode(this.state.deptcode),
					 "transporter_code": localStorage.getItem('transportercode'),
					"returnConsignments": this.state.tnpReturnConsignmentsVisibilty
				})
				.then((response) => {
					var counters = response.data.counters
					if (this.state.tnpReturnConsignmentsVisibilty) {
						counters.transit_delay = [{ "transit_delay_count": this.state.returnConsignments.filter(e => e.transit_delay == 1).length }];
						counters.enroute_stoppage = [{ "enroute_exception_count": this.state.returnConsignments.filter(e => e.enroute_exception == 1).length }];
						counters.criticalCount = [{ "criticalCount": this.state.returnConsignments.filter(e => e.is_critical == true).length }];
					}
					counters.deviated_routes = [{ "deviated_routes": response.data.consignments.filter(e => e.deviation_flag == true).length }];
					this.setState({
						rowData: response.data.consignments,
						allRowData: response.data.consignments,
						countersjson: counters,
						loadshow: "show-n",
						overly: "show-n",
						counterTitle : ""
					})
				}).catch = ((e) => {
					console.log(e);
				})
		}

	}
	onClickTripButton = async () => {

		if (this.state.startDate == '' || this.state.startDate == "NaN-NaN-NaN") {
			var sDate = this.state.defaultsdate;
		}
		else {
			var sDate = this.state.startDate;
		}
		if (this.state.endDate == '' || this.state.startDate == "NaN-NaN-NaN") {
			var eDate = this.state.defaultedate;
		}
		else {
			var eDate = this.state.endDate;
		}
		// var sDate=this.state.startDate;
		// var eDate = this.state.endDate;
		var selectconsigners = []
		if (this.state.consigner == null) {
			selectconsigners = [{ "value": 'all', "label": 'All' }]
		}
		else {
			selectconsigners = this.state.consigner
		}

		var selecttrip = []
		if (this.state.triptype == null) {
			selecttrip = { "value": 'all', "label": 'All' }
		}
		else {
			selecttrip = this.state.triptype
		}
		var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
		var requestparams = {
			consigner: selectconsigners,
			dept_code: encode(this.state.deptcode),
			startDate: sDate,
			endDate: eDate,
			triptype: selecttrip,
			returnConsignments: this.state.tnpReturnConsignmentsVisibilty
		}

		var urlpath = '';
		if (this.props.match.path == "/tnpconsignments") {
			urlpath = '/consignments/tnpconsignments';
			var transportercode = localStorage.getItem('transportercode');
			if (transportercode != undefined && transportercode != "undefined" && transportercode != "") {
				var tptCode = JSON.parse(transportercode);
				requestparams.transporter_code = tptCode[0];
			}
		}

		else if (this.props.match.path == "/tnpallconsignments") {
			urlpath = '/consignments/alltnpconsignments';
		}

		else if (this.props.match.path == "/tnpdeliveredconsignments") {
			urlpath = '/consignments/deliveredtnpconsignments';
		}

		this.onLoadGetConsignmentsList(urlpath, requestparams)
		this.setState({counterTitle : ""});
	}


	handlerStartDateTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);

		var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());
		if (this.state.endDate != undefined && this.state.endDate != "") {
			// console.log(startdate,"startdate")
			// console.log(this.state.endDate,"endDate")
			var startDate = new Date(startdate);
			var endDate = new Date(this.state.endDate);
			var startTime = startDate.getTime();
			var endTime = endDate.getTime();
			// console.log(startTime,endTime);
			if (startTime > endTime) {
				this.setState({
					startDate: ""
				});
				alert("To Date should be greater than From Date");
			}
			else {
				this.setState({
					startDate: startdate
				});
			}
		}
		else {
			this.setState({
				startDate: startdate
			});
		}
		//let value = event.target.value;
		//this.setState({'startDate':value});
	}

	handlerEndDateTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);
		var edate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate())
		if (this.state.endDate != undefined && this.state.endDate != "") {
			// console.log(this.state.startDate,"startdate")
			// console.log(edate,"endDate")
			var startDate = new Date(this.state.startDate);
			var endDate = new Date(edate);
			var startTime = startDate.getTime();
			var endTime = endDate.getTime();
			// console.log(startTime,endTime);
			if (startTime > endTime) {
				this.setState({
					endDate: ""
				});
				alert("To Date should be greater than From Date");
			}
			else {
				this.setState({
					endDate: edate
				});
			}
		}
		else {
			this.setState({
				endDate: edate
			});
		}
	}
	onRowSelection(event) {
		var containerslist = [];
		//console.log("rowselect", event)
		var rwCount = event.api.getSelectedNodes();
		//console.log("rwCount ", rwCount)
		//if(event.node.selected == true)
		//{
		//var containerlist=[];
		//console.log("rowselect", event.data)
		var rowCount = event.data
		//console.log("rowCount ", rowCount)

		rwCount.map((item) => {
			containerslist.push(item.data.truck_no)
		});

		this.setState({
			containerslist: containerslist
		})
		//console.log("checkedlist ", containerslist)
		// }
		// else{
		// 	if(containerslist.length > 0)
		// 	{
		// 		containerslist=[]
		// 		this.setState({
		// 			containerslist:containerslist
		// 		})
		// 	}
		// }

	}
	onClickShowMapView = async () => {
		//this.gridApi.deselectAll();
		if (this.state.containerslist.length > 0) {
			var reqparms = {
				truck_no: this.state.containerslist,
				dept_code: this.state.deptcode
			}
		}
		else {
			var reqparms = {
				truck_no: [],
				dept_code: this.state.deptcode
			}
		}

		await redirectURL.post("/consignments/mapview", reqparms)
			.then((response) => {
				//console.log("Res ",response.data.message)
				var records = response.data.message;

				var truck = JSON.parse(records.truck_info);
				if (truck.length > 0) {
					if (truck.length > 1) {
						var consinees = this.state.plants;
					}
					else {
						var consinees = records.consignee_coordinates;
					}
				}
				else {
					var consinees = [];
				}
				// console.log("truckinfo,", truck)
				//console.log("truck.consignee_coordinates,",JSON.parse(records.consignee_coordinates))
				this.setState({
					open: true,
					maprowData: truck,
					rownode: truck,
					maptruckno: records.truck_no,
					consigneecoords: consinees,
					consigner_coordinates: records.consigner_coordinates
				});


				//containerslist=[];
			})
			.catch(function (e) {
				console.log("Error ", e)
			})
	}
	onOpenModal = () => {
		this.setState({ open: true });
	};

	onCloseModal = () => {
		this.gridApi.deselectAll();
		this.setState({ open: false });
	};
	changeSegmentHandler = (e) => {
		//  alert(e.consignment_code);
		this.setState({
			sliderSegmentTranslate: "slider-translate-60p",
			segementConsignmentCode: e.consignment_code,
			overly: "show-m"
		})
	}

	onOpenForceCloseModal = () => {
		this.setState({ openforceclose: true });
	};

	onCloseForceCloseModal = () => {
		this.gridApi.deselectAll();
		this.setState({ openforceclose: false });
	};

	onCellUpdateData = (params) => {
		// console.log("Parms ", params);
		/*if(params.colDef.field === "tpt_deviation_status")
		{
			if(params.value !== "" && params.value !== undefined)
			{
				
				if(params.value === "Yes")
				{

					this.setState({
						transitrowdata: [params.data],
						sliderTPTDeviationTranslate: "slider-translate-40p",
						overlyFixed: 'show-m'
					})
				}
				else{
					var oPamrs = {
						tpt_deviation_status:"No",
						rowid: params.data._id
					}
					redirectURL.post("/dashboard/updateTPTNoDeviationStatus",oPamrs)
					.then((response) => {
						this.setState({
							transitrowdata: [],
							sliderTPTDeviationTranslate: "",
							overlyFixed: 'show-n'
						})
						this.reloadConsignments();	
					})
					this.setState({
						transitrowdata: [],
						sliderTPTDeviationTranslate: "",
						overlyFixed: 'show-n'
					})
				}
			}
			else{
				this.setState({
					transitrowdata: [],
					sliderTPTDeviationTranslate: "",
					overlyFixed: 'show-n'
				})
			}
		}*/
		if(params.colDef.field === "tpt_double_driver")
		{
			if(params.value !== "" && params.value !== undefined)
			{
				var oPamrs = {
					tpt_double_driver:params.value,
					rowid: params.data._id,
					truck_no: params.data.truck_no,
				}
				redirectURL.post("/dashboard/updateTPTDoubleDriverStatus",oPamrs)
				.then((response) => {
				
					this.reloadConsignments();	
				})
				
			}
		}
		/*if(params.colDef.field === "accident_exception_text")
		{
			// console.log("params ", params)
			if(params.value !== "" && params.value !== undefined)
			{
				
				if(params.value === "Yes")
				{

					this.setState({
						transitrowdata: [params.data],
						sliderTPTAccidentTranslate: "slider-translate-40p",
						overlyFixed: 'show-m'
					})
				}
				else{
					var oPamrs = {
						accident_exception:"No",
						rowid: params.data._id
					}
					redirectURL.post("/dashboard/updateTPTAccidentStatus",oPamrs)
					.then((response) => {
						this.setState({
							transitrowdata: [],
							sliderTPTAccidentTranslate: "",
							overlyFixed: 'show-n'
						})
						this.reloadConsignments();	
					})
					this.setState({
						transitrowdata: [],
						sliderTPTAccidentTranslate: "",
						overlyFixed: 'show-n'
					})
				}
			}
			else{
				this.setState({
					transitrowdata: [],
					sliderTPTAccidentTranslate: "",
					overlyFixed: 'show-n'
				})
			}
		}*/
			
	}
	async onShowSidebarAquireComponent(e) {

		// console.log(e);
		if (e.colDef.field == 'truck_no') {
			this.setState({
				sliderTranslatesidebar: "",
				loadshow: 'show-m'
			});
			let ComponentInfo = ComponentIndex['truck'];
			//console.log("ComponentInfo", ComponentInfo);
			var layoutdata1 = {
				truck_no: e.data['truck_no']
			};
			//console.log("layoutdata",layoutdata);
			await redirectURL.post('/consignments/truckDetails', layoutdata1)
				.then((response) => {
					// console.log("trucks response", response);
					var records = JSON.parse(JSON.stringify(response)).data;
					var truckrecords = records.truck;
					var consignrecords = records.consignments;
					var currenttrucklocation = records.maplocation;
					// console.log("Current Location",currenttrucklocation);
					this.setState({
						truckInfo: truckrecords,
						consignments: consignrecords,
						currenttrucklocation: currenttrucklocation
					});
				})
				.catch(function (error) {
					console.log(error);
				});
			this.displayData = await (<ComponentInfo context={this} truckNo={e.data[e.colDef.field]} truckInfo={this.state.truckInfo} consignments={this.state.consignments} currenttrucklocation={this.state.currenttrucklocation}></ComponentInfo>);
			this.setState({
				showdata: this.displayData,
				sliderTranslatesidebar: "slider-translate",
				loadshow: 'show-n',
				overly: 'show-m'
			});
		}
		else if (e.colDef.field == 'consignment_code') {
			this.setState({
				sliderTranslatesidebar: "",
				loadshow: 'show-m'
			});
			let ComponentInfo = ComponentIndex['consignment'];
			//console.log("ComponentInfo", ComponentInfo);
			var layoutdata1 = {
				consignment_code: e.data['consignment_code'],
				consignee_code: e.data['consignee_code']
			};
			// console.log(layoutdata1);
			await redirectURL.post('/consignments/consignmentDetails', layoutdata1)
				.then((response) => {
					// console.log("trucks response", response.data[0]);
					var consignment_details = response.data;
					// If gateouttime is current time - It shows "No Route data available"

					this.setState({
						consignment_details: consignment_details,
						//consignments:consignrecords,
						//currenttrucklocation:currenttrucklocation
					});
					// console.log(this.state.consignment_details);
				})
				.catch(function (error) {
					console.log(error);
				});
			try {
				this.displayData = await (<ComponentInfo context={this} consignment={this.state.consignment_details}></ComponentInfo>);
				this.setState({
					showdata: this.displayData,
					sliderTranslatesidebar: "slider-translate",
					loadshow: 'show-n',
					overly: 'show-m'
				});
			}
			catch (e) {
				this.setState({
					showdata: "",
					sliderTranslatesidebar: "",
					loadshow: 'show-n',
					overly: 'show-n'
				});
			}

		}
	}


	handlerReachedDealerTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);

		var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());

		this.setState({
			reached_dealer_time: startdate
		});
		//let value = event.target.value;
		//this.setState({'startDate':value});
	}

	handlerLeftDealerTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);

		var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());

		this.setState({
			left_dealer_time: startdate
		});
		//let value = event.target.value;
		//this.setState({'startDate':value});
	}

	handlerInvoiceTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);

		var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());

		this.setState({
			invoice_time: startdate
		});
		//let value = event.target.value;
		//this.setState({'startDate':value});
	}
	onClickForceClose = (params) => {
		//console.log("Pareas ", params)
		this.setState({
			forceclosedata: params.data,
			forceCloseRowNode: params,
			sliderForceCloseTranslate: "slider-translate-40p",
			overly: 'show-m',
			reasonforceclose: ""
		})
	}
	handleInput(val) {
		this.setState({ reasonforceclose: val[1], selectedReason: val[0] });
		// console.log(val)
	}
	changeHandler = (event) => {
		// console.log("Select", event.target);
		let name = event.target.name;
		let value = event.target.value;
		this.setState({ [name]: value });
	}

	handleChange = (event) => {
		const newValue = event.target.value;
		let name = event.target.name;
		console.log(/^\d*$/.test(newValue))
		// This will accept only input which is numeric
		if (/^\d*$/.test(newValue)) {
			if(newValue.length <= 10)
			{
				this.setState({ [name]: newValue });
			}
		}
		else{
			this.setState({ [name]: "" });
		}
	};
	changeDeliverLocation = (tpt_confirm_delivered_location) => {
		this.setState({tpt_confirm_delivered_location},()=>{
			if(this.state.tpt_confirm_delivered_location.value == "other")
			{
				this.setState({
					otherinp:"show-m"
				})
			}
			else{
				this.setState({
					otherinp:"show-n"
				})
			}
		})
	}
	changeDeviationDeliverLocation = (tpt_deviation_delivered_at) => {
		this.setState({tpt_deviation_delivered_at},()=>{
			if(this.state.tpt_deviation_delivered_at.value == "At Dealer")
			{
				this.setState({
					showDealerDeviate:"show-m",
					showDealerDeviateother:"show-n",
					tpt_deviation_city:"",
					tpt_deviation_address:"",
					tpt_deviation_zone: ""
				})
			}
			else{
				if(this.state.tpt_deviation_delivered_at.value == "others")
				{
					this.setState({
						showDealerDeviate:"show-n",
						showDealerDeviateother:"show-m",
						tpt_deviation_city:"",
						tpt_deviation_address:"",
						tpt_deviation_zone: ""
					})
				}
				else
				{
					this.setState({
						showDealerDeviate:"show-n",
						showDealerDeviateother:"show-n",
						tpt_deviation_city:"",
						tpt_deviation_address:"",
						tpt_deviation_zone: ""
					})
				}
			}
		})
	}
	changeMobileHandler = (event) => {
		//console.log("Select", event.target.value);
		let name = event.target.name;
		let value = event.target.value;
		// this.setState({ [name]: value });
		
		var changedValue = value.replace(/[^0-9]/g, '');
	    var  isValidMobile = changedValue.length === 10;
		this.setState({
		  tpt_driver_mobile: changedValue,
		  isValidMobile: isValidMobile,
		});

	};
	changeLeaderMobileHandler = (event) => {
		let name = event.target.name;
		let value = event.target.value;
		// this.setState({ [name]: value });
		
		var changedValue = value.replace(/[^0-9]/g, '');
	    var  isValidMobile = changedValue.length === 10;
		this.setState({
			tpt_convoy_leader_mobile: changedValue,
		  isValidMobile: isValidMobile,
		});
	}	
	validateMobile = (mobile) => {
		// Define a regular expression pattern for a valid mobile number
		const mobilePattern = /^[0-9]{10}$/; // Adjust the pattern as needed
	
		return mobilePattern.test(mobile);
	  };
	
	changeNumberHandler = (event) => {
		//console.log("Select", event.target.value);
		let name = event.target.name;
		let value = event.target.value;
		console.log("Number ", isNaN(value))
		if(isNaN(value) === true)
		{
			// if(this.state.tpt_deviation_postal_code !== "")
			// {
			// 	var pno = this.state.tpt_deviation_postal_code;
			// 	this.setState({ tpt_deviation_postal_code: pno });
			// }
			// else{
				this.setState({ tpt_deviation_postal_code: "" });
			// }
		}
		else{
			this.setState({ tpt_deviation_postal_code: value });
		}
	}
	changeConsignmentHandler = (event) => {
		//console.log("Select", event.target.value);
		let name = event.target.name;
		let value = event.target.value;
		this.setState({ [name]: value });
	}
	onBlurConsignment(){
		var value = this.state.grno_consignment_code;
		if(value != "")
		{
			var params = {
				consignment_code:value
			}
			redirectURL.post("/dashboard/consignment",params)
			.then((response) => {
				var records = response.data.records;
				if(records.length > 0)
				{
					this.setState({
						grno_consignment_code:"",
						shower:"show-m"
					})
				}
				else{
					this.setState({
						shower:"show-n"
					})
				}
			})
		}
	}
	formForceCloseHandler(event) {
		event.preventDefault()
		var cnf = window.confirm("Are you sure you want to force close item?");
		if (cnf == true) {
			var consignemnt = this.state.forceCloseRowNode.data;
			var consignment_code = consignemnt.consignment_code;
			var invoice_no = consignemnt.invoice_no;
			var consignee_code = consignemnt.consignee_code;

			var truck_no = consignemnt.truck_no;
			var dept_code = this.state.deptcode;
			var force_closure_time = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
			var userid = localStorage.getItem('userid');
			var reasonforceclose = this.state.reasonforceclose;
			var others_Reason = this.state.selectedReason;
			//  var reached_dealer_time =  moment.parseZone(new Date(this.state.reached_dealer_time)).format("YYYY-MM-DD")+" "+this.state.reachedhh+":"+this.state.reachedmm+":"+this.state.reachedss;
			//  var left_dealer = moment.parseZone(new Date(this.state.left_dealer_time)).format("YYYY-MM-DD")+" "+this.state.lefthh+":"+this.state.leftmm+":"+this.state.leftss;

			// 	var reached_dealer_time =  document.getElementById("reached_dealer_time").value;
			// 	if(reached_dealer_time != '')
			// 	{
			// 		var rtime = reached_dealer_time.split(" ");
			// 		var splitrtime = rtime[0].split("-");
			// 		var reachdealertime = splitrtime[2]+"-"+splitrtime[1]+"-"+splitrtime[0]+" "+rtime[1]+":00";
			// 		//console.log("reached_dealer_time ", reachdealertime)
			// 	}
			//    else{
			// 	var reachdealertime = "";
			//    }
			// 	var left_dealer = document.getElementById("left_dealer_time").value;
			// 	if(left_dealer != '')
			// 	{
			// 		var ltime = left_dealer.split(" ");
			// 		var splitltime = ltime[0].split("-");
			// 		var leftdealertime = splitltime[2]+"-"+splitltime[1]+"-"+splitltime[0]+" "+ltime[1]+":00";
			// 		//console.log("leftdealertime ", leftdealertime)
			// 	}
			// 	else
			// 	{
			// 		var leftdealertime=""
			// 	}

			if (this.state.deptcode == "SNDG") {
				//console.log(consignemnt.invoice_time);
				var invoice_time = getHyphenYYYYMMDDHHMMSS(consignemnt.invoice_time);
				var reqdata = {
					dept_code: dept_code,
					consignment_code: [consignment_code],
					invoice_no: [invoice_no],
					//consignee_code:consignee_code,
					truck_no: truck_no,
					//force_closure_time:force_closure_time,
					userid: userid,
					reasonforceclose: reasonforceclose,
					others_Reason: others_Reason,
					screen: ""
					// reached_dealer_time:reachdealertime,
					//invoice_time:invoice_time,
					// left_dealer:leftdealertime
				}
			}
			else {
				var reqdata = {
					dept_code: dept_code,
					consignment_code: [consignment_code],
					invoice_no: [invoice_no],
					//consignee_code:"",
					truck_no: truck_no,
					//force_closure_time:force_closure_time,
					userid: userid,
					screen: "",
					reasonforceclose: reasonforceclose,
					others_Reason: others_Reason,
					// reached_dealer_time:reachdealertime,
					//invoice_time:"",
					// left_dealer:leftdealertime
				}
			}
			var flag = 0;
			if (others_Reason == "" && reasonforceclose == "Others") {
				flag = 1;
			}
			if (flag == 0 && reasonforceclose == "Others") {
				//    console.log(others_Reason.replace(/\s/g,"").length,"lengt");
				if (others_Reason.replace(/\s/g, "").length == 0) {
					flag = 1;
				}
			}
			// console.log("reqdata ",reqdata)
			if (flag == 0) {
				reqdata["returnConsignment"] = this.state.tnpReturnConsignmentsVisibilty
				redirectURL.post("/consignments/updateConsignmentForceClose", reqdata)
					.then((response) => {
						//console.log("Resposne updateConsignmentForceClose ", response)
						// console.log(response);
						if (response.data.status == 'Success') {
							// document.getElementById("reached_dealer_time").value='';
							// document.getElementById("left_dealer_time").value='';
							this.setState({
								show: true,
								basicTitle: "Success",
								basicType: "success",
								loadshow: 'show-n',
								overly: 'show-n',
								left_dealer_time: '',
								reached_dealer_time: '',
								reachedhh: '',
								reachedmm: '',
								reachedss: '',
								lefthh: '',
								leftmm: '',
								leftss: '',
								reasonforceclose: "",
								openforceclose: false,
								sliderForceCloseTranslate: '',
								sliderTranslatesidebar: "",
							});
						}
						else {
							this.setState({
								show: true,
								basicTitle: "Error",
								basicType: "danger",
								loadshow: 'show-n',
								overly: 'show-n',
								left_dealer_time: '',
								sliderTranslatesidebar: "",
								reached_dealer_time: '',
								reachedhh: '',
								reachedmm: '',
								reachedss: '',
								lefthh: '',
								leftmm: '',
								leftss: '',
								reasonforceclose: '',
								openforceclose: false,
								sliderForceCloseTranslate: ''
							});
						}

					})
					.catch(function (e) {
						console.log("Error ", e)
					})
			}
			else {
				this.setState({
					show: true,
					basicTitle: 'Reason for closue should not be empty',
					basicType: "danger",
				});
			}
			event.target.reset();
		}
		else {

		}
		//  if(this.state.deptcode == 'SNDG')
		//  {
		// 	 var invoice_time = moment.parseZone(this.state.invoice_time).format("YYYY-MM-DD")+" "+this.state.invoicehh+":"+this.state.invoicemm+":"+this.state.invoicess;
		//  }
		//  else{
		// 	var invoice_time=""
		//  }

	}

	onClickSaveGridState() {
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();
		// console.log("Columns ", window.colState)
		// console.log("Group ", window.groupState)
		// console.log("Sort ", window.sortState)
		// console.log("Filter ", window.filterState)
		let screenpage = '';

		if (this.props.match.path == "/sndconsignments") {
			screenpage = 'snd active consignments';
		}
		else if (this.props.match.path == "/prtconsignments") {
			screenpage = 'prt active consignments';
		}
		else if (this.props.match.path == "/tnpconsignments") {
			screenpage = 'tnp active consignments';
		}
		else if (this.props.match.path == "/sndallconsignments") {
			screenpage = 'snd Consignments Dashboard';

		}
		else if (this.props.match.path == "/prtallconsignments") {
			screenpage = 'prt Consignments Dashboard';
		}
		else if (this.props.match.path == "/tnpallconsignments") {
			screenpage = 'tnp Consignments Dashboard';
		}
		else if (this.props.match.path == "/snddeliveredconsignments") {
			screenpage = 'snd delivered consignments';
		}
		else if (this.props.match.path == "/prtdeliveredconsignments") {
			screenpage = 'prt delivered consignments';
		}
		else if (this.props.match.path == "/tnpdeliveredconsignments") {
			screenpage = 'tnp delivered consignments';
		}
		else if (this.props.match.path == "/railconsignments") {
			screenpage = 'railconsignments';
		}
		else if (this.props.match.path == "/ncrconsignments") {
			screenpage = 'ncr consignments';
		}
		else if (this.props.match.path == "/moveconsignments") {
			screenpage = 'move consignments';
		}

		let reqparams = {
			gridcolumns: window.colState,
			gridgroup: window.groupState,
			gridcolsort: window.sortState,
			gridcolfilter: window.filterState,
			userId: localStorage.getItem("userid"),
			screenurl: window.location.pathname,
			screentitle: screenpage
		}
		// console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
			.then((response) => {
				//console.log("State response ",response.data)
				this.setState({
					show: true,
					basicTitle: "Successfully saved grid layout",
					basicType: "success",
					screenurl: window.location.pathname,
					screentitle: screenpage
				})
			})
			.catch(function (e) {
				console.log("Error ", e)
			})
	}

	restoreGridStates() {

		if (this.state.usergridstate.length > 0) {
			var windowstates = this.state.usergridstate;
			this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
			this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
			this.gridApi.setSortModel(windowstates[0].gridcolsort);
			this.gridApi.setFilterModel(windowstates[0].gridcolfilter);

		}
	}

	resetState = () => {
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId: localStorage.getItem("userid"),
			screenurl: window.location.pathname,
			screentitle: this.state.screenpage
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
			.then((response) => {
				this.setState({
					show: true,
					basicTitle: "Successfully reset default grid layout",
					basicType: "success"
				})
			})

	};

	onShowConsignmentTickets = async (item) => {
		//console.log(item)
		var deptcode = this.state.deptcode

		await this.setState({

			detailCellRendererParams: {
				detailGridOptions: {
					columnDefs: [
						{
							headerName: "Ticket",
							field: "ticket_type",
							valueGetter: function (params) {
								var tickettype = params.data.ticket_type;
								var str = tickettype.replace(/_/g, " ")
								return str;
							},
							cellClass: "capitlize"
						},
						{
							headerName: "Ticket No",
							field: "ticket_number",
							width: 120,
						},
						{
							headerName: "Vin No",
							field: "truck_no",
							width: 120,
						},

						{
							headerName: "Transporter",
							field: "transporter_name",
							width: 120,
						},
						{
							headerName: "Department",
							field: "dept_code",
							width: 150,
						},
						{
							headerName: "Actual LSP User",
							field: "actual_lspuser",
							width: 150,
						},
						{
							headerName: "Status",
							field: "ticket_status",
							width: 130,
							valueGetter: function (params) {
								if (params.data.ticket_status == 1) {
									return "Open";
								}
								if (params.data.ticket_status == 2) {
									return "Work in progress";
								}
								if (params.data.ticket_status == 3) {
									return "Closed";
								}
								if (params.data.ticket_status == 4) {
									return "On hold";
								}
								if (params.data.ticket_status == 5) {
									return "Auto closed";
								}
								if (params.data.ticket_status == 6) {
									return "Invalid";
								}
								if (params.data.ticket_status == 7) {
									return "Closed - No response";
								}
								if (params.data.ticket_status == 8) {
									return "Force Closed";
								}
							}
						},
						{
							headerName: "Deaer City",
							field: "consignee_city",
							width: 180,
							valueGetter: function (params) {
								if (typeof params.data.consignee_city == undefined) {
									return '';
								}
								else {
									return params.data.consignee_city;
								}
							}
						},
						{
							headerName: "Transit Delay Hours",
							field: "transit_delay_hours",
							width: 180,
							valueGetter: function (params) {
								if (typeof params.data.transit_delay_hours == undefined) {
									return '';
								}
								else {
									return params.data.transit_delay_hours;
								}
							}
						},
						{
							headerName: "Exception Location",
							field: "exception_location",
							width: 170,
							resizable: true
						},
						// {
						// 	headerName : "Exception Longitude",
						// 	field : "exception_lng",
						// 	width : 140,
						// 	resizable : true
						// },
						// {
						// 	headerName: "Last Known City/Area",
						// 	field: "area",
						// 	width: 180,
						// 	valueGetter: function (params) {
						// 		return params.data.truck[0].area
						// 	}
						// },
						// {
						// 	headerName: "Last Known State",
						// 	field: "area",
						// 	width: 180,
						// 	valueGetter: function (params) {
						// 		return params.data.truck[0].state
						// 	}
						// },
						{
							headerName: "Last Packet Time",
							field: "timestamp",
							width: 180,
							valueGetter: function (params) {
								return getHyphenDDMMMYYYYHHMM(params.data.truck[0].timestamp)
							},
							comparator: dateComparator,
						},
						{
							headerName: "Gate Out / Serial Time",
							field: "gate_out_invoice_time",
							width: 180,
							valueGetter: function (params) {
								return getHyphenDDMMMYYYYHHMM(params.data.gate_out_invoice_time)
							},
							//filter: "agDateColumnFilter",
							comparator: dateComparator,
						},
						{
							headerName: "Created Date",
							field: "ticket_creation_date",
							width: 180,
							valueGetter: function (params) {
								return getHyphenDDMMMYYYYHHMM(params.data.ticket_creation_date)
							},
							//filter: "agDateColumnFilter",
							comparator: dateComparator,
						},
						{
							headerName: "Modified Date",
							field: "ticket_modification_date",
							width: 180,
							valueGetter: function (params) {
								return getHyphenDDMMMYYYYHHMM(params.data.ticket_modification_date)
							},
							//filter: "agDateColumnFilter",
							comparator: dateComparator,
						},
					]
				},
				getDetailRowData: async function (params) {
					var parameter = {
						consignment_code: params.data.consignment_code,
						dept_code: deptcode
					}
					redirectURL.post("/consignments/ticketsbyconsignment", parameter)
						.then((response) => {
							params.successCallback(response.data)
						})
						.catch(function (e) {

						})

				}
			}
		});

		if (item.column.colDef.field == 'tickets') {
			item.node.setExpanded(!item.node.expanded);
		}
		else {

			item.node.setExpanded(false);
		}


	}


	onCloseUserManualModal = () => {
		this.setState({ usermanualncrmodal: false });
	};

	onClickShowUserManaul = () => {
		this.setState({ usermanualncrmodal: true });
	};

	onCloseUserManualMoveModal = () => {
		this.setState({ usermanualmovemodal: false });
	};

	onClickShowUserMoveManaul = () => {
		this.setState({ usermanualmovemodal: true });
	};

	onShowUploadEWay() {
		this.setState({
			slideuploadeway: "slider-translate",
			overly: 'show-m'
		})
	}

	uploadBulkPriorityFormHandler(event) {
		event.preventDefault();
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.formAction,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		var bulkMdData = this.state.pCsvContent;
		//console.log(bulkMdData);
		var flag = 0;
		// console.log("bulkMdData: =>", bulkMdData)
		bulkMdData.map(function (e, index) {
			var keys = Object.keys(e);
			var consignmentKey = ""
			keys.map(function (key) {
				if (key == "Consignment_Code" ||
					key == "GR No" ||
					key == "consignment_code" ||
					key == "gr no" ||
					key == "ConsignmentCode" ||
					key == "consignmentcode"
				) {
					consignmentKey = key;
				}
			})
			if (index != (bulkMdData.length - 1)) {
				if (e[consignmentKey] == "") {
					flag = 1
				}
			}

		})
		if (flag == 0) {
			var reqParams = {
				email: localStorage.getItem("email"),
				csvData: bulkMdData
			}
			redirectURL.post("/consignments/bulkUploadConsignmentPriority", reqParams).then((response) => {
				this.setState({
					basicTitle: "Success",
					basicType: "success",
					show: true,
					file: "",
					bulkPrioritySlide: "",
					overly: "show-n",
					loadshow: "show-n"
				})
				// window.location.reload();
			}).catch((e) => {
				console.log(e);
			})

		}
		else {
			$("#bulkUploadPID").val();
			this.setState({
				basicTitle: "Consignment_code fields must not be empty",
				basicType: "danger",
				show: true,
				file: "",
				pCsvContent: "",
			})
		}

		var urlpath = '/consignments/prtconsignments';
		var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
		var dpt = 'LOG-PRT';
		var consignData = {
			dept_code: encode(dpt),
			startDate: this.state.startDate,
			endDate: this.state.endDate,
			consigner: this.state.consigner,
			movement: [this.state.movementtype]
		}


		this.onLoadGetConsignmentsList(urlpath, consignData)
	}

	changePriorityFileHandler = async (e) => {
		var dt = '';

		const config = {
			headers: [
				{
					name: 'consignment_code',
					inputName: 'consignment_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
			.then((csvData) => {
				this.setState({
					pCsvContent: csvData.data
				});
			})
			.catch(err => { })
		// console.log("e.target.files[0]",e.target.result);
		var contentList = []

		var out = new Promise(function (reject, resolve) {
			var reader = new FileReader();
			reader.onload = async function (e) {
				var contents = await e.target.result;
				resolve(contents);
			};
			var tt = reader.readAsText(e.target.files[0]);
		});
		this.setState({
			file: e.target.files[0]
		});
		// console.log("e.target.files[0].type ", e.target.files[0]);

		if (e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel') {
		}
		else {
			e.target.value = null;
			this.setState({
				uploadFile: '',
				file: "",
				show: true,
				basicType: 'danger',
				basicTitle: 'Please upload file having extensions .csv only.',
			});
		}

	}


	onBulkPriorityBtn() {
		this.setState({
			overly: "show-m",
			bulkPrioritySlide: "slider-translate"
		})
	}

	changeFileHandler = async (e) => {
		const config = {
			headers: [
				{
					name: 'consignment_code',
					inputName: 'consignment_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'truck_no',
					inputName: 'truck_no',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'ewaybill_no',
					inputName: 'ewaybill_no',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'expiry_date',
					inputName: 'expiry_date',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				}
			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
			.then((csvData) => {
				this.setState({
					csvcontent: csvData.data
				});
			})
			.catch(err => { })

		var out = new Promise(function (reject, resolve) {
			var reader = new FileReader();
			reader.onload = async function (e) {
				var contents = await e.target.result;
				// console.log("contents ", contents)
				resolve(contents);
			};
			var tt = reader.readAsText(e.target.files[0]);
			// console.log("tt ", tt)
		});
		//console.log("Out ", out);

		this.setState({
			file: e.target.files[0]
		});
		//console.log("e.target.files[0].type ", e.target.files[0].type);
		// if(e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
		// {
		// }
		// else{
		// 	e.target.value = null;
		// 	this.setState({
		// 		uploadFile:'',
		// 		show: true,
		// 		basicType:'danger',
		// 		basicTitle:'Please upload file having extensions .csv only.',
		// 	});
		// }

	}

	resetUpload = () => {
		this.setState({
			slideuploadeway: '',
			overly: 'show-n',
			file: '',
			showDiv: 'show-n',
			sliderBulkUpdate:"",
			uploadDivWidth: '0%',
			bulkPrioritySlide: '',
			bulkForceCloseSlide: '',
		});
	}

	uploadEway(event) {
		event.preventDefault();

		if (this.state.file != '') {
			// console.log("this.state.file ", this.state.file)
			var csvdd = this.state.file;

			// console.log("event ", event.target.uploadFile.value);
			var formData = new FormData();
			formData.append('uploadFile', this.state.file);
			formData.append("userId", localStorage.getItem('userid'));
			formData.append("csvcontent", this.state.csvcontent);
			var rparams = {
				uploadFile: this.state.file,
				userId: localStorage.getItem('userid'),
				csvcontent: this.state.csvcontent
			}
			//console.log('file',formData);
			//console.log('rparams',rparams);
			this.setState({
				loadshow: 'show-m'
			});
			redirectURL.post("/consignments/uploadewayexpires", rparams)
				.then(
					(response) => {
						//console.log(response);
						// console.log(response.data);
						//document.getElementById("upform").reset();
						if (response.data.status == 'failure') {
							if (response.data.message.empty != "")
								this.setState({
									//csverrmessage:response.data.status,
									show: true,
									basicType: 'danger',
									basicTitle: "Failed",
									slideuploadeway: '',
									overly: 'show-n',
									showDiv: 'show-n',
									loadshow: 'show-n',
									uploadFile: '',
									file: ''
								});

						}
						else {
							//console.log("here")
							this.setState({
								show: true,
								basicType: 'success',
								basicTitle: "Successfully Uploaded.",
								slideuploadeway: '',
								overly: 'show-n',
								showDiv: 'show-n',
								uploadFile: '',
								loadshow: 'show-n',
								file: ''
							});

						}


					}
				)
				.catch(function (error) {
					console.log(error);
				});
		}

	}
	async onCellClicked(cell) {
		if (cell.colDef.field == 'breaks_at_tolls' || cell.colDef.field == 'toll_details') {
			this.setState({
				detailCellRendererParams : []
			})
			console.log('cell.data.toll_details',cell.data.toll_details)
			try{
				var tollDetails = []
				if(JSON.parse(cell.data.toll_details) !== undefined) tollDetails = JSON.parse(cell.data.toll_details)				
			}
			catch(e){
				tollDetails = []
			}
			// try{
			// 	var breaks_at_tolls = []
			// 	if(JSON.parse(cell.data.breaks_at_tolls) !== undefined) breaks_at_tolls = JSON.parse(cell.data.breaks_at_tolls)
			// }
			// catch(e){
			// 	breaks_at_tolls = []
			// }
			// let toll_details1 = []
			// let tollNames = []
			// tollDetails.map(e =>{
			// 	breaks_at_tolls.map(i =>{
			// 		if(e.name === i.toll_name){ 
			// 			toll_details1.push(i)
			// 			tollNames.push(i.toll_name)
			// 		}
			// 	})
			// 	if(!tollNames.includes(e.name)){	
			// 		tollNames.push(e.name)
			// 		toll_details1.push(e)	
			// 	}
			// })
			// console.log("t",tollDetails )
			// console.log("b", breaks_at_tolls)
			Promise.all([tollDetails]).then(async ()=>{
				await this.setState({
					detailCellRendererParams: {
						detailGridOptions: {
							columnDefs: [
								{
									"headerName": "Toll Name",
									field: "toll_name",
									width: 140, resizable: true,
									valueGetter: param => {
										if (param.data.toll_name !== '' && param.data.toll_name !== undefined) {
											return param.data.toll_name
										}else{
											return param.data.name
										}
									}
								},
								{
									"headerName": "State",
									field: "state",
									width: 120, resizable: true
								},
								{
									"headerName": "Area",
									field: "area",
									width: 120, resizable: true
								},
								{
									"headerName": "Rate To Be considered",
									field: 'rate_to_be_considered',
									width: 120, resizable: true,
									valueGetter: param => {
										if (param.data.rate_to_be_considered !== '') {
											return `${param.data.rate_to_be_considered} Rs`
										}
									}
								},
								{
									"headerName": "Break Start Time",
									field: "break_start_time",
									width: 140, resizable: true,
									valueGetter: (params) => {
										if (params.data.break_start_time !== '' && params.data.break_start_time !== undefined) {
											// console.log(" break_start_time ", getHyphenDDMMMYYYYHHMM(params.data.break_start_time),'\n time', params.data.break_start_time)
											return getHyphenDDMMMYYYYHHMM(params.data.break_start_time);
										} else {
											return 'NA'
										}
									},
								},
								{
									"headerName": "Break End Time",
									field: "break_end_time",
									width: 140, resizable: true,
									valueGetter: (params) => {
										// console.log(" ETA ", params.data)
										if (params.data.break_end_time !== '' && params.data.break_end_time !== undefined) {
											return getHyphenDDMMMYYYYHHMM(params.data.break_end_time);
										} else {
											return 'NA'
										}
									},
								},
								{
									"headerName": "Break duration",
									field: "break_time_seconds",
									width: 200, resizable: true,
									valueGetter: function (params) {
										// console.log(" ETA ", params.data.break_time_seconds)
										if (params.data.break_time_seconds !== '' && params.data.break_time_seconds !== undefined) {
											let sec = params.data.break_time_seconds
											return secondsToMinutesSeconds(sec)
										} else {
											return 'No Break'
										}
									},
								},
							],
							overlayNoRowsTemplate: 'No rows to show',
						},
						getDetailRowData: (params) => {
							params.successCallback(tollDetails);
						},
						masterDetail: true
					}
				})
				cell.node.setExpanded(!cell.node.expanded);
			})
		}

		if (cell.colDef.field == "srv_logs") {
			await this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							{
								"headerName": "gr no",
								field: "consignment_code",
								width: 120, resizable: true
							},
							{
								"headerName": "Serial No",
								field: "invoice_no",
								width: 120, resizable: true
							},
							{
								"headerName": "SRV No",
								field: "srv_no",
								width: 110, resizable: true,
								valueGetter: function (resp) {
									if (resp.data.srv_no != "nan" && resp.data.srv_no != undefined) {
										return parseInt(resp.data.srv_no).toString()
									}
									else {
										return ""
									}
								}
							},
							{
								"headerName": "SRV Status",
								field: "srv_status",
								width: 120,
								resizable: true,
								valueGetter: function (resp) {
									if (resp.data.srv_status != "nan" && resp.data.srv_status != undefined && resp.data.srv_status != "NA") {
										return resp.data.srv_status
									}
									else {
										return ""
									}
								}
							},
							{
								"headerName": "DLoc Code",
								field: "dloc_code",
								width: 120, resizable: true
							},
							{
								"headerName": "Part Name",
								field: "part_name",
								width: 120, resizable: true
							},
							{
								"headerName": "Part No",
								field: "part_no",
								width: 120, resizable: true
							},
							{
								"headerName": "Quantity",
								field: "quantity",
								width: 120, resizable: true
							},
							{
								"headerName": "Transaction Type",
								field: "transaction_type",
								width: 120, resizable: true
							},
							{
								"headerName": "Unload No",
								field: "unload_no",
								width: 120, resizable: true,
								valueGetter: function (resp) {
									if (resp.data.unload_no != "nan" && resp.data.unload_no != undefined) {
										return parseInt(resp.data.unload_no).toString()
									}
									else {
										return ""
									}
								}
							},
						],
						overlayNoRowsTemplate: 'No rows to show',

					},
					getDetailRowData: async function (param) {
						param.successCallback([]);
						await redirectURL.post("/dashboard/getSRVLogs", {
							"consignment_code": param.data.consignment_code,
							"invoice_no": param.data.invoice_no
						}).then((response) => {
							param.successCallback(response.data);
						}).catch(function (error) {
							console.log(error);
						})

					},
					masterDetail: true
				}
			});


			cell.node.setExpanded(!cell.node.expanded);
		}
		if (cell.colDef.field == "delete") {
			var rowId = cell.data._id;
			var consigner_code = cell.data.consigner_code;
			var qry = {
				rowId: rowId,
				rowData: cell.data,
				user_id: localStorage.getItem('userid'),
				user_name: localStorage.getItem('username'),
				user_email: localStorage.getItem('email'),
			}
			if (consigner_code == "SMG") {
				if (window.confirm("Are You Sure, Consignment and related details will be deleted")) {
					// console.log(rowId, "rowId")
					redirectURL.post("/consignments/deleteReturnPallets", qry).then((response) => {
						// console.log("responseData:", response.data);
						if (response.data.message == "Success") {
							this.setState({
								basicTitle: "Deleted Successfully.",
								basicType: "success",
								show: true
							})
							window.location.reload();
						}
						else {
							this.setState({
								basicTitle: "Failed to Delete.",
								basicType: "warning",
								show: true
							})
						}
					}).catch((e) => {
						console.log(e);
					})
				}
			}
		}

		// if (cell.colDef.field == "priority") {
		// 	if (cell.data.is_priority != 1) {
		// 		this.setState({
		// 			loadshow: "show-m",
		// 			overly: "show-m",
		// 		});
		// 		var consignmentId = cell.data.consignment_code;
		// 		var email = localStorage.getItem("email");
		// 		var reqParams = {
		// 			consignmentId: consignmentId,
		// 			email: email
		// 		}
		// 		redirectURL.post("/consignments/updateConsignmentPriority", reqParams).then((response) => {
		// 			this.setState({
		// 				loadshow: "show-n",
		// 				overly: "show-n",
		// 				basicTitle: "Consignment Marked As Priority",
		// 				basicType: "success",
		// 				show: true,
		// 			});
		// 			// window.location.reload();
		// 			var urlpath = '/consignments/prtconsignments';
		// 			var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
		// 			var dpt = 'LOG-PRT';
		// 			var consignData = {
		// 				dept_code: encode(dpt),
		// 				startDate: this.state.startDate,
		// 				endDate: this.state.endDate,
		// 				consigner: this.state.consigner,
		// 				movement: [this.state.movementtype]
		// 			}


		// 			this.onLoadGetConsignmentsList(urlpath, consignData)

		// 		}).catch((cell) => {
		// 			// console.log("cell:", cell)
		// 		})
		// 	}
		// }

		//commented the priority because it was msil requirement

	}
	onClickTab(clickedTab) {
		var colsToHide = ["overspeeding_exception", "nightdriving_exception", "loading_delay_exception"]
		if (clickedTab == 1) {
			var title = this.state.pagetitle;
			// console.log("initialTitle:", title)
			title = title.split(" ")

			if (title.includes("Return", false)) { title.splice(title.indexOf("Return"), 1) }
			title = join(title, " ")
			this.setState({
				rowData: this.state.originalData,
				tnpReturnConsignmentsVisibilty: false,
				consBtn: "btn-danger",
				returnConsBtn: "btn-default",
				countersjson: this.state.originalcountersjson,
				allRowData: this.state.originalData,
				pagetitle: title
			})
			this.gridColumnApi.setColumnsVisible(colsToHide, true)
		}
		else {
			this.state.countersjson.transit_delay = [{ "transit_delay_count": this.state.returnConsignments.filter(e => e.transit_delay == 1).length }];
			this.state.countersjson.enroute_stoppage = [{ "enroute_exception_count": this.state.returnConsignments.filter(e => e.enroute_exception == 1).length }];
			this.state.countersjson.criticalCount = [{ "criticalCount": this.state.returnConsignments.filter(e => e.is_critical == true).length }];
			this.state.countersjson.no_gps = [{ "no_gps_data_count": this.state.returnConsignments.filter(e => e.no_gps_data == 1).length }]
			this.state.countersjson.unloading_delay = [{
				"unloading_delay_exception_count":
					this.state.returnConsignments.filter(e => e.unloading_delay_exception == 1).length
			}]
			this.state.countersjson.nogpslifetime = [{
				"no_gps_data_lifetime_count":
					this.state.returnConsignments.filter(e => e.no_gps_data_lifetime == 1).length
			}]
			var title = this.state.pagetitle;

			// console.log("initialTitle", title)
			title = title.split(" ")
			// console.log(typeof (title))
			// console.log(title, "title")
			var tarray = [];
			var ttl;
			title.map(function (t) {
				ttl = t.replace(/Return/g, "");
				tarray.push(ttl);
			})
			tarray.splice(1, 0, "Return")
			var title = join(tarray, " ")

			this.setState({
				rowData: this.state.returnConsignments,
				tnpReturnConsignmentsVisibilty: true,
				consBtn: "btn-default",
				returnConsBtn: "btn-danger",
				countersjson: this.state.countersjson,
				allRowData: this.state.returnConsignments,
				pagetitle: title
			})

			// colsToHide.map((e)=>{
			this.gridColumnApi.setColumnsVisible(colsToHide, false)
			// })
		}

	}
	onSetCritical(params, status) {
		//console.log("onSetCritical ", params);
		//console.log("onSetCritical value ", status);
		redirectURL.post('/consignments/setCriticalStatus', {
			params: params,
			is_critical: status,
			token: localStorage.getItem("token"),
			userId: localStorage.getItem("userid"),
			returnConsignment: this.state.tnpReturnConsignmentsVisibilty,

		})
			.then((response) => {

				//var records = JSON.parse(JSON.stringify(response)).data;
				// console.log(response.data);
			});

	}

	getNewFilterConsignmnets = (event) => {
		this.setState({
			loadshow: "show-m",
			overly: "show-m"
		})
		event.preventDefault();
		var selectedCity = $("#selectedCity").text();
		var selectedState = $("#selectedState").text();
		var selectedCluster = $("#selectedCluster").text();
		var selectedTransporter = $("#selectedTransporter").text();
		var beforeNewFilterRowData = this.state.rowData;
		var filterRowData = this.state.rowData;
		// console.log(selectedCity, selectedState, selectedCluster, selectedTransporter, "Bhanu Teja")
		//console.log(filterRowData,"0")
		if (selectedCity != undefined && selectedCity != '' && selectedCity != "Select...") {
			filterRowData = filterRowData.filter(e => e.consignee_city == selectedCity);
			// console.log(filterRowData, "1")
		}
		if (selectedState != undefined && selectedState != '' && selectedState != "Select...") {
			filterRowData = filterRowData.filter(e => e.consignee_state == selectedState);
		}
		if (selectedCluster != undefined && selectedCluster != '' && selectedCluster != "Select...") {
			filterRowData = filterRowData.filter(e => e.cluster == selectedCluster);
		}
		if (selectedTransporter != undefined && selectedTransporter != '' && selectedTransporter != "Select...") {
			filterRowData = filterRowData.filter(e => e.transporter_name == selectedTransporter);
		}
		var conCodes = [];
		filterRowData.map(function (f) {
			conCodes.push(f.consignment_code);
		});
		redirectURL.post('/consignments/getConsignmentsNewFilterCounters', {
			consignmentCodes: conCodes,
			filterType: 1,
		}).then((response) => {
			//var records = JSON.parse(JSON.stringify(response)).data;
			// console.log(response.data.counters, "filter counters");
			var pagecounters = response.data.counters
			// console.log(filterRowData, "final")
			var beforeNewFiltercountersjson = this.state.countersjson;
			this.setState({
				rowData: filterRowData,
				allRowData: filterRowData,
				beforeNewFilterRowData: beforeNewFilterRowData,
				beforeNewFiltercountersjson: beforeNewFiltercountersjson,
				countersjson: pagecounters,
				loadshow: "show-n",
				overly: "show-n"
			})
			this.gridApi.refreshCells();
		});
	}

	ClearNewFilter = () => {
		this.setState({
			loadshow: "show-m",
			overly: "show-m"
		})
		this.setState({
			flCity: "",
			flState: "",
			flCluster: "",
			flZone: "",
			flTransporter: "",
			flDealer:""
		});
		this.setState({
			rowData: this.state.beforeNewFilterRowData,
			allRowData: this.state.beforeNewFilterRowData,
			countersjson: this.state.beforeNewFiltercountersjson,
			loadshow: "show-n",
			overly: "show-n"
		});
		this.gridApi.refreshCells();
	}

	newFilter = (filterType, selOption) => {
		// console.log(filterType, selOption)
		this.setState({ [filterType]: selOption });
	}
	reloadPageData = () => {
		// window.location.reload();
		this.componentDidMount();
		var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
		this.setState({
			startDate: this.state.defaultsdate,
			endDate: this.state.defaultedate,
			flDealer:{value:"",label:"Select..."},
			consigner: [{value: "all", label: "All"}],
			date_type:{value:"all", label:"All"},
			flZone:{value:"",label:"Select..."},
			flState:{value:"",label:"Select..."},
			flCity:{value:"",label:"Select..."},
			counterTitle : ""
		},()=>{
			if(this.state.allBtn === "btn-info")
			{
				var urlpath = '/consignments/allsndconsignments';
				var dpt = 'SNDG';
				var consignData = {
					dept_code: encode(dpt),
					startDate: this.state.startDate,
					endDate: this.state.endDate,
					consigner: this.state.consigner,
					screen: "all"
				}
				if (this.state.flTransporter.value != "" && this.state.flTransporter.value != undefined && this.state.flTransporter.value != null) {
					consignData.transporter_code = this.state.flTransporter.value;
				}
			}
			
			if(this.state.intransitBtn === "btn-info")
			{
				var urlpath = '/consignments/sndconsignments';
				var dpt = 'SNDG';
				
				var consignData = {
					dept_code: encode(dpt),
					startDate: this.state.startDate,
					endDate: this.state.endDate,
					consigner: this.state.consigner
				}
				
				if (this.state.flTransporter.value != "" && this.state.flTransporter.value != undefined && this.state.flTransporter.value != null) {
					consignData.transporter_code = this.state.flTransporter.value;
				}
			}
			
			if(this.state.deliveredBtn === "btn-info")
			{
				var urlpath = '/consignments/deliveredsndconsignments';
				var dpt = 'SNDG';
				var consignData = {
					dept_code: encode(dpt),
					startDate: this.state.startDate,
					endDate: this.state.endDate,
					consigner: this.state.consigner
				}
				
				if (this.state.flTransporter.value != "" && this.state.flTransporter.value != undefined && this.state.flTransporter.value != null) {
					consignData.transporter_code = this.state.flTransporter.value;
				}
	
			}
			this.onLoadGetConsignmentsList(urlpath, consignData);
		})
	}

	
	reloadPageDataGloabl = () => {
		// window.location.reload();
		document.getElementById("inputTruckConsignment").value = "";
		// document.getElementById("selectedFilter").value = "";

		var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
		this.setState({
			selectedFilter:[{label:"Select...",value:""}],
			counterTitle: "",
			inputValue: "",
		},()=>{
			if(this.state.allBtn === "btn-info")
			{
				var urlpath = '/consignments/transportconsignments';
				var dpt = 'SNDG';
				var consignData = {
					dept_code: encode(dpt),
					startDate: this.state.startDate,
					endDate: this.state.endDate,
					consigner: this.state.consigner,
					screen: "all",
					
				}
				if (this.state.flTransporter.value != "" && this.state.flTransporter.value != undefined && this.state.flTransporter.value != null) {
					consignData.transporter_code = this.state.flTransporter.value;
				}
			}
			
			if(this.state.intransitBtn === "btn-info")
			{
				var urlpath = '/consignments/activetransporterconsignments';
				var dpt = 'SNDG';
				
				var consignData = {
					dept_code: encode(dpt),
					startDate: this.state.startDate,
					endDate: this.state.endDate,
					consigner: this.state.consigner
				}
				
				if (this.state.flTransporter.value != "" && this.state.flTransporter.value != undefined && this.state.flTransporter.value != null) {
					consignData.transporter_code = this.state.flTransporter.value;
				}
			}
			
			if(this.state.deliveredBtn === "btn-info")
			{
				var urlpath = '/consignments/deliveredtransporterconsignments';
				var dpt = 'SNDG';
				var consignData = {
					dept_code: encode(dpt),
					startDate: this.state.startDate,
					endDate: this.state.endDate,
					consigner: this.state.consigner
				}
				
				if (this.state.flTransporter.value != "" && this.state.flTransporter.value != undefined && this.state.flTransporter.value != null) {
					consignData.transporter_code = this.state.flTransporter.value;
				}
	
			}
			this.onLoadGetConsignmentsList(urlpath, consignData);
		})
	}

	onClickShowForm() {
		this.setState({
			overly: "show-m",
			sliderCreateConsignmentTranslate: "slider-translate"
		});
	}
	saveConsignmentData = async (event) => {
		event.preventDefault();
		var truck_no = this.state.truck_no.value;
		var plant_code = this.state.plant_code.value;
		var plant_name = this.state.plant_code.label;
		var parentdealercode = this.state.parentdealer.value;
		var parentdealername = this.state.parentdealer.label;
		var grno_consignment_code = this.state.grno_consignment_code;
		var transporter = this.state.transporter.value;
		var transporterlbl = this.state.transporter.label;
		// var invoice_no = this.state.invoice_no;
		var invoice_date = $("#invoice_date").val();

		var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

		var gate_out_time = $("#gate_out_time").val();
		if (this.state.consignee_code != "") {
			var consignee_code = this.state.consignee_code.value;
			var consigneename = this.state.consignee_code.label;
			var consignee_name = "";
			try{
				if(consigneename != "" && consigneename != "Select Dealer")
				{
					var c = consigneename.split("-");
					consignee_name = c[0];
				}
			}
			catch(e){}
			
			
		}
		else {
			var consignee_code = "";
			var consignee_name = "";
		}
		if (truck_no != "" && plant_code != "" && grno_consignment_code != ""
			&& invoice_date != "" && invoice_date != "__-__-____ __:__"
			&& gate_out_time != "" && consignee_code != "" && parentdealercode != "" && transporter !== "") {
				var transname = "";
				var transportername = "";
				try {
					if(transporterlbl !== "")
					{
						transname = transporterlbl.split("-")
						transportername = transname[0]; 
					}
				} catch (error) {
					
				}
			var parameters = {
				truck_no: truck_no,
				plant_code: plant_code,
				plant_name: plant_name,
				consignment_code: grno_consignment_code,
				transporter_code:transporter,
				transporter_name:transportername,
				// invoice_no: invoice_no,
				invoice_date: invoice_date,
				gate_out_time: gate_out_time,
				consignee_code: consignee_code,
				consignee_name: consignee_name,
				parent_dealer_code:parentdealercode,
				parent_dealer_name:parentdealername,
				dept_code: encode(this.state.deptcode)
			}
			// console.log("paraneters ", parameters);

			redirectURL.post("/dashboard/saveConsignmentData", { params: parameters })
				.then((response) => {
					// console.log("save response ", response.data)
					if (response.data.status == "success") {

						this.setState({
							show: true,
							basicType: "success",
							basicTitle: "Successfully created consignment",
							sliderCreateConsignmentTranslate:"",
							overlay:"show-n",
							truck_no:{"value":"","label":"Select Truck"},
							plant_code:{"value":"","label":"Select Plant"},
							grno_consignment_code:"",
							consignee_code:{"value":"","label":"Select Dealer"}

						})
						$("#invoice_date").val("");
						$("#gate_out_time").val("");
						var consignData = {
							dept_code: encode("SNDG"),
							startDate: this.state.startDatetime,
							endDate: this.state.endDatetime,
							consigner: this.state.consigner
						}
						this.onLoadGetConsignmentsList("/consignments/sndconsignments", consignData);
					}
					else {
						if (response.data.status == "Special characters are not allowed") {
							this.setState({
								show: true,
								basicType: "danger",
								basicTitle: "Invalid Data"
							})
						}
						else if (response.data.status == "already-exists") {
							this.setState({
								show: true,
								basicType: "info",
								basicTitle: "Already Consignment Data Exists"
							})
						}
						else {
							this.setState({
								show: true,
								basicType: "danger",
								basicTitle: "Failed to create consignment"
							})
						}

					}
				})
		}
		else {
			this.setState({
				show: true,
				basicType: "danger",
				basicTitle: "All fields are mandatory"
			})

		}
	}

	onChangeTruckItem(truck_no) {
		this.setState(
			{ truck_no },
			// () => console.log(`Truck  Option selected:`, this.state.truck_no)
		);

		this.setState({
			errorMessage: '',
			successMessage: ''
		})
	}

	onChangeConsigneeItem(consignee_code) {
		this.setState(
			{ consignee_code },
			() => {
				var cc = this.state.consignee_code;
				if(cc.value != "" && cc.value != "Select Dealer")
				{
					var p = {
						dealer_code:cc.value
					}
					redirectURL.post("/dashboard/dealerinfo",p)
					.then((response) => {
						var records = response.data.records;
						if(records.length > 0)
						{
							this.setState({
								dealer_location: records[0].consignee_address,
								dealer_city: records[0].consignee_city,
							})
						}
					})
				} 
			}
		);

		this.setState({
			errorMessage: '',
			successMessage: ''
		})
	}
	onChangePlantItem(plant_code) {
		this.setState(
			{ plant_code },
			// () => console.log(`Plant Option selected:`, this.state.plant_code)
		);

		this.setState({
			errorMessage: '',
			successMessage: ''
		})
	}
	onClickShwAdd(){
		
		this.setState({
			sliderTranslateAdd:"slider-translate",
			loadshow:'show-n',
			overly:'show-m'
		});
	}
	truckslist(){
		var items=[];
		var trucks = this.state.trucks;
		if(trucks.length > 0)
		{
			trucks.map((item) => {
                items.push({
                    value:item.truck_no,
                    label:item.truck_no
                });
				
			});
		}
		return items;
	}
   
	onChangePlant(plantitem){
		this.setState(
			{ plantitem },
			() => console.log(`Plant Option selected:`, this.state.plantitem)
		  );

	}
	onChangeTruck(truckitem){
		this.setState(
			{ truckitem },
			() => console.log(`Truck Option selected:`, this.state.truckitem)
		  );

	}
    onChangeDealer(dealeritem){
        this.setState(
			{ dealeritem },
			() => console.log(`Dealer Option selected:`, this.state.dealeritem)
		  );

    }
	
    parentdealerlist(){
		var items=[];
		var dealers = this.state.parentDealers;
		if(dealers.length > 0)
		{
			dealers.map((item) => {
                items.push({
                    value:item.parent_dealer_code,
                    label:item.parent_dealer_name
                });
				
			});
		}
		return items;
	}
	
	changeParentDealer = parentdealer => {
		this.setState(
			{ parentdealer },
			// () => console.log(`Consigner Option selected:`, this.state.consigner)
		);

		this.setState({
			errorMessage: '',
			successMessage: ''
		})
	}
	onChangeTransporter(transporter)
	{
		this.setState({transporter},() => {console.log("transporter ", this.state.transporter)})
	}
	onClickBulkForm(){
		this.setState({
			sliderBulkUpdate:"slider-translate",
			overly:"show-m"
		})
	}
	changeFileHandlerBulk = async (e) => {
		console.log(e.target.files[0])
		var dt = '';
		// const config = {
		// 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
		// }

		var config = {
			headers: [
				{
					name: 'truck_no',
					inputName: 'truck_no',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'grno',
					inputName: 'grno',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'Invoice_no',
					inputName: 'invoice_no',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'invoice_date',
					inputName: 'invoice_date',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'trip_started_on',
					inputName: 'trip_started_on',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'plant_code',
					inputName: 'plant_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'plant_name',
					inputName: 'plant_name',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'pd_location',
					inputName: 'pd_location',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'transporter_code',
					inputName: 'transporter_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'transporter_name',
					inputName: 'transporter_name',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				}
			]
		}
		// console.log("S2")
		var data = CSVFileValidator(e.target.files[0], config)
		.then((csvData) => {
			
		// console.log("S3",csvData)
			console.log(csvData.data)
			this.setState({
				csvcontentbulk:csvData.data
			});
		})
		.catch(err => {})

		// console.log("S4")
		var out = new Promise(function(reject, resolve){
			var reader =  new FileReader();
			reader.onload = async function(e) {
				var contents = await e.target.result;
				console.log("contents ", contents )
				resolve(contents);
			};
			// var tt =  reader.readAsText(e.target.files[0]);
			// console.log("tt ",tt)
		});
		//console.log("Out ", out);

		this.setState({
			file:e.target.files[0]
		});
		//console.log("e.target.files[0].type ", e.target.files[0].type);

		if(e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
		{
		}
		else{
			e.target.value = null;
			this.setState({
				uploadFile:'',
				show: true,
				basicType:'danger',
				basicTitle:'Please upload file having extensions .csv only.',
			});
		}

		console.log('csvcontentbulk ', this.state.csvcontentbulk)

	}
	saveBulkConsignmentData = async (event) => {
		event.preventDefault();
		var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
		console.log(" csvcontentbulk", this.state.csvcontentbulk)
			var parameters = {
				csvcontent:this.state.csvcontent,
				dept_code:encode("SNDG")
			}

				redirectURL.post("/dashboard/savebulkconsignment",parameters)
				.then((response) => {
					console.log("save response ", response.data)
					if(response.data.status == "success")
					{
						this.setState({
							show:true,
							basicType:"success",
							basicTitle:"Successfully created consignment"
						})
					}
					else {
						if(response.data.message == "Special characters are not allowed")
						{
							this.setState({
								show:true,
								basicType:"danger",
								basicTitle:"Invalid Data"
							})
						}
						else{
							this.setState({
								show:true,
								basicType:"danger",
								basicTitle:"Failed to create consignment"
							})
						}

					}
				})

	}
	onClickTabConsignments(oVal){
		var loguser = decode(localStorage.getItem("m"));
		this.setState({
			total_accidents:'',
			total_deviations:""
		})
		// console.log("LOGUSER ", JSON.parse(loguser));
		var loginplant = "";
		try {
			var luser = JSON.parse(loguser);
			loginplant = luser.is_plant_code;
			// console.log("logplant", loginplant)
			if (loginplant !== "" && loginplant !== undefined) {
				this.setState({
					loginplant: loginplant,
					consigner: [{ "value": luser.is_plant_code, "label": luser.is_plant_name }]

				})
			}
		}
		catch (e) {

		}

		var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
		if (loginplant != "" && loginplant != undefined) {
			var consigners = [{ "value": loginplant, "label": loginplant }]
		}
		else {
			var consigners = this.state.consigner;
		}
		if(oVal === "all")
		{
			var urlpath = '/consignments/transportconsignments';
			var dpt = 'SNDG';
			var consignData = {
				dept_code: encode(dpt),
				startDate: this.state.startDate,
				endDate: this.state.endDate,
				consigner: consigners,
				screen: "all"
			}

			if (this.state.flTransporter.value != "" && this.state.flTransporter.value != undefined && this.state.flTransporter.value != null) {
				consignData.transporter_code = this.state.flTransporter.value;
			}
			this.setState({
				allBtn:"btn-info",
				intransitBtn:"btn-default",
				deliveredBtn:"btn-default",
				pagetitle:"Consignments Dashboard",
				flDealer:{value:"",label:"Select..."}
			})
		}
		
		if(oVal === "active")
		{
			var urlpath = '/consignments/activetransporterconsignments';
			var dpt = 'SNDG';
			
			var consignData = {
				dept_code: encode(dpt),
				startDate: this.state.startDate,
				endDate: this.state.endDate,
				consigner: consigners
			}
			
			if (this.state.flTransporter.value != "" && this.state.flTransporter.value != undefined && this.state.flTransporter.value != null) {
				consignData.transporter_code = this.state.flTransporter.value;
			}
			this.setState({
				allBtn:"btn-default",
				intransitBtn:"btn-info",
				deliveredBtn:"btn-default",
				pagetitle:"Consignments Dashboard"
			})
		}
		
		if(oVal === "delivered")
		{
			var urlpath = '/consignments/deliveredtransporterconsignments';
			var dpt = 'SNDG';
			var consignData = {
				dept_code: encode(dpt),
				startDate: this.state.startDate,
				endDate: this.state.endDate,
				consigner: consigners
			}
			
			if (this.state.flTransporter.value != "" && this.state.flTransporter.value != undefined && this.state.flTransporter.value != null) {
				consignData.transporter_code = this.state.flTransporter.value;
			}

			this.setState({
				allBtn:"btn-default",
				intransitBtn:"btn-default",
				deliveredBtn:"btn-info",
				pagetitle:"Consignments Dashboard"
			})
		}
		this.onLoadGetConsignmentsList(urlpath, consignData);
	}
	onChangeTransporter(flTransporter)
	{
		this.setState({flTransporter}, () => {
			this.onGetConsigners();
		})
	}
	onChangeDateType(date_type){
		this.setState({date_type})
	}
	onChangeZone(flZone)
	{
		this.setState({flZone},()=>{
			// if(this.state.flZone.value !== "" && this.state.flZone.value !== undefined)
			// {
				this.filterZoneParams();
			// }
		})
	}
	onChangeDealerState(flState)
	{
		this.setState({flState},()=>{
			// if(this.state.flState.value !== "" && this.state.flState.value !== undefined)
			// {
				this.filterZoneParams();
			// }
		})
	}
	onChangeDealerCity(flCity)
	{
		this.setState({flCity},()=>{
			// if(this.state.flCity.value !== "" && this.state.flCity.value !== undefined)
			// {
				this.filterZoneParams();
			// }
		})
	}
	filterZoneParams(){
		var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
		var params = {
			dept_code: encode("SNDG")
		}
		if(this.state.flZone.value !== "" && this.state.flZone.value !== undefined)
		{
			params.zone = this.state.flZone.value;
		}
		if(this.state.flCity.value !== "" && this.state.flCity.value !== undefined)
		{
			params.dealer_city = this.state.flCity.value;
		}
		
		if(this.state.flState.value !== "" && this.state.flState.value !== undefined)
		{
			params.dealer_state = this.state.flState.value;
		}
		
		redirectURL.post("/consignments/getConsignmentsNewFilterKeys", params)
		.then((resp) => {
			var dealers = resp.data.consigneeName;
			var filterDealers = [{value:"",label:"All"}];
			if (dealers.length > 0) {
				dealers.map(function (c) {
					filterDealers.push({
						label: c.consignee_name+"-"+c.consignee_code,
						value: c.consignee_code,
					});
				});
			}

			var cities = resp.data.cities;
			var filterCities = [{value:"",label:"All"}];
			if (cities.length > 0) {
				cities.map(function (c) {
					filterCities.push({
						label: c,
						value: c,
					});
				});
			}
			var states = resp.data.states;
			var filterStates = [{value:"",label:"All"}];
			if (states.length > 0) {
				states.map(function (s) {
					filterStates.push({
						label: s,
						value: s,
					});
				});
			}
			var clusters = resp.data.clusters;
			var filterClusters = [{value:"",label:"All"}];
			if (clusters.length > 0) {
				clusters.map(function (cl) {
					filterClusters.push({
						label: cl,
						value: cl,
					});
				});
			}
			
			var transporters = resp.data.transporters;
			var filterTransporters = [{value:"",label:"All"}];
			if (transporters.length > 0) {
				transporters.map(function (t) {
					filterTransporters.push({
						label: t,
						value: t,
					});
				});
			}
			this.setState({
				filterCities: filterCities,
				filterStates: filterStates,
				filterClusters: filterClusters,
				filterDealers:filterDealers
			});
		})
	}
	onCickShowRemarkSlide = (params) => {
		//console.log("Pareas ", params)
		this.setState({
			transitrowdata: [params.data],
			sliderTPTRemarkTranslate: "slider-translate-40p",
			overly: 'show-m'
		})
	}
	
	formTPTRemarks(event) {
		event.preventDefault()
		var cnf = window.confirm("Are you sure you want to submit remarks?");
		if (cnf == true) {
			var params ={
				transporter_remarks:this.state.tpt_remarks,
				rowdata:this.state.transitrowdata
			}
			redirectURL.post("/dashboard/saveTransporterRemarks",params)
			.then((response) =>{
				if(response.data.status === "success")
				{
					this.setState({
						show:true,
						basicTitle:"Successfully submitted remarks",
						basicType:"success",
						tpt_remarks:"",
						transitrowdata:[],
						sliderTPTRemarkTranslate:"",
						overly: 'show-n'
					})
					
					this.reloadConsignments();
				}
			})
		}
	}
	onClickCancelDeviationProcess() {
		this.setState({
			loadshow: 'show-n',
			overlyFixed: 'show-n',
			sliderTPTDeviationTranslate:""
		});
		var transitrowdata = this.state.transitrowdata;
		if(transitrowdata[0].tpt_deviation_status === "Yes")
		{
		}
		else{
			// var oPamrs = {
			// 	tpt_deviation_status:"No",
			// 	rowid: transitrowdata[0]._id
			// }
			// redirectURL.post("/dashboard/updateTPTNoDeviationStatus",oPamrs)
			// .then((response) => {
			// 	this.setState({
			// 		transitrowdata: [],
			// 		sliderTPTDeviationTranslate: "",
			// 		overlyFixed: 'show-n'
			// 	})
			// })
		}
		
		this.reloadConsignments();
	}
	onCickShowDeviationSlide = (params) => {
		// console.log("Pareas ", params)
		this.setState({
			transitrowdata: [params.data],
			sliderTPTDeviationTranslate: "slider-translate-40p",
			overlyFixed: 'show-m'
		})
	}
	formTPTBreakDown(event) {
		event.preventDefault()
		var cnf = window.confirm("Are you sure you want to submit breakdown information?");
		if (cnf == true) {
			var params ={
				tpt_break_down_location:this.state.tpt_break_down_location,
				tpt_break_down_consignee_code:this.state.tpt_break_down_consignee_code.value,
				tpt_break_down_consignee_name:this.state.tpt_break_down_consignee_code.label,
				rowdata:this.state.transitrowdata
			}
			// console.log("params",params)
			redirectURL.post("/dashboard/saveTransporterBreakdown",params)
			.then((response) =>{
				// console.log("response.data",response.data)
				if(response.data.status === "success")
				{
					this.setState({
						show:true,
						basicTitle:"Successfully submitted breakdown information",
						basicType:"success",
						tpt_break_down_location:"",
						tpt_break_down_consignee_code:{value:"",label:"Select"},
						transitrowdata:[],
						sliderTPTBreakdownTranslate:"",
						overlyFixed: 'show-n'
					})
					
					this.reloadConsignments();
				
				}
			})
		}
	}

	changeDeviationHandler(deviation){
		this.setState({deviation},()=>{
			if(this.state.deviation.value != "")
			{
				var deviate = "";
				if(this.state.alldeviations.length > 0)
				{
					deviate = this.state.alldeviations.filter(d => d.deviation_code == this.state.deviation.value);
				}
				if(deviate != "")
				{
					// console.log('deviate ',deviate)
					this.setState({
						tpt_deviation_location:this.state.deviation.label,
						tpt_deviation_city:deviate[0].deviation_city,
						tpt_deviation_address:deviate[0].deviation_address,
						tpt_deviation_zone: deviate[0].zone,
						tpt_deviation_postal_code: deviate[0].deviation_postal_code,
						tpt_deviation_contact_number:deviate[0].tpt_deviation_contact_number,						
						body_shop:{value:deviate[0].tpt_deviation_body_shop,label:deviate[0].tpt_deviation_body_shop},
						ship_to_party:{value:deviate[0].tpt_deviation_ship_to_party,label:deviate[0].tpt_deviation_ship_to_party_name}
					})
				}
			}
			else{
				this.setState({
					tpt_deviation_location:"",
					tpt_deviation_city:"",
					tpt_deviation_address:"",
					tpt_deviation_zone: "",
					tpt_deviation_postal_code: "",
					tpt_deviation_contact_number:"",
					body_shop:{value:"",label:"Select"},
					ship_to_party:{value:"",label:"Select"}
				})
			}
		})
	}
	changeShipToPartyHandler(ship_to_party){
		this.setState({ship_to_party},()=>{
			if(this.state.ship_to_party.value != "")
			{
				var parms = {
					consignee_code:this.state.ship_to_party.value
				}
				redirectURL.post("/masters/dealerbycode",parms)
				.then((response) => {
					var dealer = response.data.records;
					if(dealer.length > 0)
					{
						this.setState({
							tpt_deviation_city:dealer[0].consignee_city,
							tpt_deviation_address:dealer[0].consignee_address,
							tpt_deviation_zone: dealer[0].zone
						})

					}
				})
			}
		})
	}
	changeBodyShopHandler(body_shop){
		this.setState({body_shop})
	}
	
	formTPTDeviation(event) {
		event.preventDefault()
		// console.log("7414",event)
		// this.setState({
		// 	loadshow:"show-m"
		// })
		if(this.state.tpt_deviation_location != "" && this.state.tpt_deviation_city != "" && this.state.tpt_deviation_address != "" && this.state.tpt_deviation_zone != "" && this.state.tpt_deviation_postal_code != "" && this.state.tpt_approved_deviation_no != "" && this.state.tpt_deviation_delivered_at.value != "")
		{
			var cnf = window.confirm("Are you sure you want to submit deviation information?");
			if (cnf == true) {
				if(this.state.tpt_deviation_delivered_at.value == "At Dealer")
				{
					var tpt_deviation_ship_to_party  = this.state.ship_to_party.value;
					var tpt_deviation_ship_to_party_name  = this.state.ship_to_party.label;
				}
				else{
					var tpt_deviation_ship_to_party  = "";
					var tpt_deviation_ship_to_party_name  =  "";
				}
				var params ={
					tpt_deviation_status:"Yes",
					tpt_deviation_location:this.state.tpt_deviation_location,
					tpt_deviation_city:this.state.tpt_deviation_city,
					tpt_deviation_address:this.state.tpt_deviation_address,
					tpt_deviation_zone: this.state.tpt_deviation_zone,
					tpt_deviation_postal_code: this.state.tpt_deviation_postal_code,
					tpt_approved_deviation_no:this.state.tpt_approved_deviation_no,
					rowdata:this.state.transitrowdata,
					truck_no:this.state.transitrowdata[0].truck_no,
					tpt_deviation_contact_number:this.state.tpt_deviation_contact_number,
					tpt_deviation_body_shop:this.state.body_shop.value,
					tpt_deviation_ship_to_party:tpt_deviation_ship_to_party,
					tpt_deviation_ship_to_party_name:tpt_deviation_ship_to_party_name,
					tpt_deviation_delivered_at:this.state.tpt_deviation_delivered_at.value,
					tpt_deviation_other_ship_to_party_code:this.state.ship_to_party_code,
					tpt_deviation_other_ship_to_party_name:this.state.ship_to_party_name
				}
					// console.log("params",params)
				// if(this.state.tpt_deviation_location != "" && this.state.tpt_deviation_location != undefined)
				// {
	
					redirectURL.post("/dashboard/saveTransporterDeviation",params)
					.then((response) =>{
						// console.log("response",response)
						if(response.data.status === "success")
						{
								this.setState({
									show1:true,
									basicTitle1:"Successfully submitted deviation information",
									basicType1:"success",
									sliderTPTDeviationTranslate:"",
									tpt_deviation_location:"",
									tpt_deviation_city:"",
									tpt_deviation_address:"",
									tpt_deviation_zone:"",
									tpt_deviation_postal_code:"",
									tpt_approved_deviation_no:"",
									body_shop:{value:"",label:"Select"},
									ship_to_party:{value:"",label:"Select"},
									tpt_deviation_contact_number:"",
									transitrowdata:[],
									// sliderTPTDeviationTranslate:"",
									overlyFixed: 'show-n',
									loadshow:"show-n"
								})
								
								
							// })
	
						}
						else{
							this.setState({
								show:true,
								basicTitle:response.data.message,
								basicType:"danger",
								sliderTPTDeviationTranslate:"",
								// sliderTPTDeviationTranslate:"",
								// tpt_deviation_location:"",
								// tpt_deviation_city:"",
								// tpt_deviation_address:"",
								// tpt_deviation_zone:"",
								// tpt_deviation_postal_code:"",
								// transitrowdata:[],
								// sliderTPTDeviationTranslate:"",
								overlyFixed: 'show-n'
								// loadshow:"show-n"
							})
						}
						
					})
					this.reloadConsignments();
				// }
				// else{
				// 	this.setState({
				// 		show:true,
				// 		basicTitle:"Please select Deviation Location from dropdown",
				// 		basicType:"danger"
				// 	})
				// }
			}
		}
		else{
			if(this.state.tpt_deviation_location == "")
			{
				this.setState({
					show:true,
					basicTitle:"Please enter deviation location",
					basicType:"danger"
				})
			}
			
			else if(this.state.tpt_deviation_city == "")
			{
				this.setState({
					show:true,
					basicTitle:"Please enter deviation city",
					basicType:"danger"
				})
			}
			
			else if(this.state.tpt_deviation_address == "")
			{
				this.setState({
					show:true,
					basicTitle:"Please enter deviation address",
					basicType:"danger"
				})
			}
			
			else if(this.state.tpt_deviation_zone == "")
			{
				this.setState({
					show:true,
					basicTitle:"Please enter deviation zone",
					basicType:"danger"
				})
			}
			
			else if(this.state.tpt_deviation_postal_code == "")
			{
				this.setState({
					show:true,
					basicTitle:"Please enter deviation postal code",
					basicType:"danger"
				})
			}
			
			else if(this.state.tpt_approved_deviation_no == "")
			{
				this.setState({
					show:true,
					basicTitle:"Please enter deviation approved no",
					basicType:"danger"
				})
			}
		}
	}
	onCickShowAccidentSlide = (params) => {
		console.log("Pareas ", params.data)
		this.setState({
			transitrowdata: [params.data],
			sliderTPTAccidentTranslate: "slider-translate-40p",
			overlyFixed: 'show-m',
			loadshow:'show-m'
		})
	}
	
	formTPTAccident(event) {
		event.preventDefault()
		this.setState({
			overly:"show-m",
			loadshow:"show-m",
		})
		var cnf = window.confirm("Are you sure you want to submit accident information?");
		if (cnf == true) {
			//  console.log("uploadDocs ", this.state.uploadDocs)
			var uploadDocs = this.state.uploadDocs;
			// console.log("uploadDocs",uploadDocs)
			

			if(uploadDocs.length <= 10)

			{
				for (let i = 0; i < uploadDocs.length; i++) {
				var itm= uploadDocs[i];
					// console.log("utn ", itm)

				

					const formData = new FormData();
					formData.append("customer_document_1", itm);
					formData.append("rownodes", JSON.stringify(this.state.transitrowdata));
					
					// console.log("formData ",formData)
		
				redirectURL.post("/dashboard/saveDocumentUploadFile", formData, {
						headers: {
							'content-type': 'multipart/form-data',
							'Access-Control-Allow-Origin': "*",
							'Access-Control-Allow-Methods': 'POST, OPTIONS',
							'Access-Control-Allow-Headers': 'Origin, Content-Type'
						}
					})
					.then((response) => {
						try {
							var allDocs = this.state.allDocs;
							if(response.data.status == "success")
							{
								allDocs.push(response.data.document);
							}
							this.setState({
								sliderTPTAccidentTranslate:"",
								allDocs:allDocs,
								
								loadshow:"show-m",
								overlay:"show-n",
								overlyFixed:"show-n"
								
							})	
						} catch (error) {
							
						}
					});
				
				}  
				

				setTimeout(()=>{
					
					this.saveData()
				},15000)
			}
			else{
				this.saveData()
			}
			
			
		}
	}
	// formTPTAccident(event) {
	// 	event.preventDefault();
	// 	this.setState({
	// 	  overly: "show-m",
	// 	  loadshow: "show-m",
	// 	});
	  
	// 	var cnf = window.confirm("Are you sure you want to submit accident information?");
	// 	if (cnf === true) {
	// 	  var uploadDocs = this.state.uploadDocs;
	  
	// 	  // Define the allowed file extensions
	// 	//   const allowedExtensions = ["jpeg","png","pdf"];
	// 	//   let hasInvalidFile = false;
	  
	// 	  if (uploadDocs.length <= 10) {
	// 		for (let i = 0; i < uploadDocs.length; i++) {
	// 		  var itm = uploadDocs[i];
	// 		  console.log("utn", itm);
	  
	// 		  // Get the file extension by splitting the filename
	// 		//   const fileExtension = itm.name.split('.').pop();
	  
	// 		  // Check if the file extension is allowed
	// 		//   if (allowedExtensions.includes(fileExtension.toLowerCase())) {
	// 			const formData = new FormData();
	// 			formData.append("customer_document_1", itm);
	// 			formData.append("rownodes", JSON.stringify(this.state.transitrowdata));
	  
	// 			console.log("formData", formData);
	  
	// 			redirectURL
	// 			  .post("/dashboard/saveDocumentUploadFile", formData, {
	// 				headers: {
	// 				  'content-type': 'multipart/form-data',
	// 				  'Access-Control-Allow-Origin': "*",
	// 				  'Access-Control-Allow-Methods': 'POST, OPTIONS',
	// 				  'Access-Control-Allow-Headers': 'Origin, Content-Type',
	// 				},
	// 			  })
	// 			  .then((response) => {
	// 				try {
	// 				  var allDocs = this.state.allDocs;
	// 				  if (response.data.status === "success") {
	// 					allDocs.push(response.data.document);
	// 				  }
	// 				  this.setState({
	// 					allDocs: allDocs,
	// 				  });
	// 				} catch (error) {}
	// 			  });
	// 		//   } 
	// 		//   else {
	// 		// 	// Handle the case when the file extension is not allowed (e.g., display an alert)
	// 		// 	hasInvalidFile = true;
	// 		// 	alert("Invalid file: " + itm.name);
	// 		//   }
	// 		}
	  
	// 		// if (!hasInvalidFile) {
	// 		  setTimeout(() => {
	// 			this.saveData();
	// 		  }, 15000);
	// 		// }
	// 	  } else {
	// 		this.saveData();
	// 	  }
	// 	}
	//   }
	  
	  

	saveData(){
		
		// console.log("tpt_accident_fir_date",tpt_accident_fir_date)
		var params ={
			accident_exception:1,
			tpt_accident_damage_vehicle:this.state.tpt_accident_damage_vehicle,
			tpt_accident_scenario:this.state.tpt_accident_scenario,
			tpt_accident_third_party_damage:this.state.tpt_accident_third_party_damage,
			tpt_accident_vehicle_current_status:this.state.tpt_accident_vehicle_current_status,
			tpt_accident_fir_date:this.state.tpt_accident_fir_date,
			tpt_accident_a3_report_submission:this.state.tpt_accident_a3_report_submission.value,
			tpt_accident_fir_status:this.state.tpt_accident_fir_status.value,
			rowdata:this.state.transitrowdata,
			uploadDocs:this.state.allDocs
		}
		// console.log("params",params)
		redirectURL.post("/dashboard/saveTransporterAccident",params)
		.then((response) =>{
			if(response.data.status === "success")
			{
				// var rdata = this.state.transitrowdata;
				// var oPamrs = {
				// 	accident_exception:"Yes",
				// 	rowid: rdata[0]._id
				// }
				// redirectURL.post("/dashboard/updateTPTAccidentStatus",oPamrs)
				// .then((response) => {
	
					this.setState({
						
						show:true,
						basicTitle:"Successfully submitted accident information",
						basicType:"success",
						accident_exception:"",
						tpt_accident_damage_vehicle:"",
						tpt_accident_scenario:"",
						tpt_accident_third_party_damage:"",
						tpt_accident_vehicle_current_status:"",
						tpt_accident_fir_date:"",
						tpt_accident_a3_report_submission:{value:"No",label:"No"},
						tpt_accident_fir_status:{value:"No",label:"No"},
						transitrowdata:[],
						sliderTPTAccidentTranslate:"",
						overlyFixed: 'show-n',
						overly:"show-n",
						loadshow:"show-n",
						allDocs:[]
					})
					$("#tpt_accident_fir_date").val("");
					document.getElementById("formAcciednt").reset();
					this.reloadConsignments();
				// })

			}
		})
	}
	onClickCancelAccidentProcess() {
		this.setState({
			loadshow: 'show-n',
			overlyFixed: 'show-n',
			sliderTPTAccidentTranslate:"",
			tpt_deviation_location:"",
			tpt_deviation_city:"",
			tpt_deviation_address:"",
			tpt_deviation_zone:"",
			tpt_deviation_postal_code:"",
			tpt_deviation_contact_number:"",
			body_shop:{value:"",label:"Select"},
			ship_to_party:{value:"",label:"Select"},
			tpt_approved_deviation_no:"",
			tpt_deviation_status:"No",
			accident_exception:"No",
			overlyFixed:"show-n",
			showfirfield:"show-n",
			tpt_accident_fir_status:{value:"No",label:"No"},
			tpt_accident_a3_report_submission:{value:"No",label:"No"},
			tpt_accident_damage_vehicle:"",
			tpt_accident_scenario:"",
			tpt_accident_third_party_damage:"",
			tpt_accident_vehicle_current_status:"",
			tpt_accident_fir_date:"",
			accidentDocuments:[],
			allDocs:[],
		});
		var transitrowdata = this.state.transitrowdata;
		// if(transitrowdata[0].accident_exception === "Yes")
		// {
		// }
		// else{
		// 	var oPamrs = {
		// 		accident_exception:"No",
		// 		rowid: transitrowdata[0]._id
		// 	}
		// 	redirectURL.post("/dashboard/updateTPTAccidentStatus",oPamrs)
		// 	.then((response) => {
		// 		this.setState({
		// 			transitrowdata: [],
		// 			sliderTPTAccidentTranslate: "",
		// 			overlyFixed: 'show-n'
		// 		})
		// 	})
		// }
		
		this.reloadConsignments();
	}
	onClickCancelAccidentDocuments(){
		this.setState({
			loadshow: 'show-n',
			overlyFixed: 'show-n',
			sliderTPTAccidentDocuments:"",
			accidentDocuments:[]
		});
	}
	onCickShowDriverSlide = (params) => {
		//console.log("Pareas ", params)
		this.setState({
			transitrowdata: [params.data],
			sliderTPTDriverTranslate: "slider-translate-40p",
			overly: 'show-m'
		})
	}
	
	formTPTDriver(event) {
		event.preventDefault()
		var cnf = window.confirm("Are you sure you want to submit driver information?");
		if (cnf == true) {
			var params ={
				tpt_driver_name:this.state.tpt_driver_name,
				tpt_driver_mobile:this.state.tpt_driver_mobile,
				tpt_convoy_leader_name:this.state.tpt_convoy_leader_name,
				tpt_convoy_leader_mobile:this.state.tpt_convoy_leader_mobile,
				rowdata:this.state.transitrowdata
			}
			redirectURL.post("/dashboard/saveTransporterDriver",params)
			.then((response) =>{
				if(response.data.status === "success")
				{
					this.setState({
						show:true,
						basicTitle:"Successfully submitted driver information",
						basicType:"success",
						tpt_driver_name:"",
						tpt_driver_mobile:"",
						tpt_convoy_leader_name:"",
						tpt_convoy_leader_mobile:"",
						transitrowdata:[],
						sliderTPTDriverTranslate:"",
						overly: 'show-n'
					})
					this.reloadConsignments();
				}
			})
		}
	}
	reloadConsignments(){
		var selectconsigners = []
		// console.log('selectconsigners',this.state.consigner)
		if (this.state.consigner == null) {
			selectconsigners = [{ "value": 'all', "label": 'All' }]
		}
		else {
			selectconsigners = this.state.consigner
		}

		if (this.state.startDate == '' || this.state.startDate == "NaN-NaN-NaN") {
			var sDate = this.state.defaultsdate;
		}
		else {
			var sDate = this.state.startDate;
		}
		if (this.state.endDate == '' || this.state.startDate == "NaN-NaN-NaN") {
			var eDate = this.state.defaultedate;
		}
		else {
			var eDate = this.state.endDate;
		}
		if (this.state.flZone != "" && this.state.flZone != undefined && this.state.flZone != null) {
			var zone = this.state.flZone.value;
		}
		else {
			var zone = "";
		}


		if (this.state.flState != "" && this.state.flState != undefined && this.state.flState != null) {
			var state = this.state.flState.value;
		}
		else {
			var state = "";
		}


		if (this.state.flCity != "" && this.state.flCity != undefined && this.state.flCity != null) {
			var city = this.state.flCity.value;
		}
		else {
			var city = "";
		}
		
		if(this.state.flDealer != "" && this.state.flDealer != undefined && this.state.flDealer != null) {
			var consignee_code = this.state.flDealer.value;
		}
		else {
			var consignee_code = "";
		}
	
		var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
		var requestparams = {
			consigner: selectconsigners,
			dept_code: encode(this.state.deptcode),
			startDate: sDate,
			endDate: eDate,
			transporter_code: localStorage.getItem("transportercode"),
			zone: zone,
			state: state,
			city: city,
			consignee_code:consignee_code
		}
		if(this.state.allBtn === "btn-info")
		{
			var urlpath = '/consignments/transportconsignments';
			requestparams['screen'] = "all";
		}
		
		if(this.state.intransitBtn === "btn-info")
		{
			var urlpath = '/consignments/activetransporterconsignments';
		}
		
		if(this.state.deliveredBtn === "btn-info")
		{
			var urlpath = '/consignments/deliveredtransporterconsignments';
		}
		if(localStorage.getItem("user_type") === "TRANSPORTER")
		{
			this.onLoadGetConsignmentsList(urlpath, requestparams)
		}
	}
	handleFirDateChange = (selectedDate) => {
		// console.log("selectedDate",selectedDate)
        this.setState({
            tpt_accident_fir_date: selectedDate.format("DD-MM-YYYY"),
        });
		// console.log("tpt_accident_fir_date",tpt_accident_fir_date)
    };
	changeFIR(tpt_accident_fir_status)
	{
		this.setState({tpt_accident_fir_status},()=>{
			if(this.state.tpt_accident_fir_status.value === "Yes")
			{
				this.setState({
					showfirfield:"show-m"
				})
			}
			else{
				this.setState({
					showfirfield:"show-n"
				})
			}
		})
	}
	// changeFIR(tpt_accident_fir_status) {
	// 	console.log("tpt_accident_fir_status",tpt_accident_fir_status)
	// 	const showfirfield = tpt_accident_fir_status.value === "Yes" ? "show-m" : "show-n";
	// 	console.log("showfirfield",showfirfield)
	// 	this.setState({ tpt_accident_fir_status, showfirfield });
	//   }
	  
	changeA3Report(tpt_accident_a3_report_submission)
	{
		this.setState({tpt_accident_a3_report_submission})
	}
	// changeDocumentHandler(event){
	// 	var allfiles = []
	// 	const allowedExtensions = ["jpeg","png","pdf"];
	// 	console.log("allowedExtensions",allowedExtensions)
	// 	let hasInvalidFile = false;
	// 	var fileExtension = allfiles.name.split('.').pop();
	// 	if (allowedExtensions.includes(fileExtension.toLowerCase())) {
	// 		if(event.target.files.length > 0)
	// 		{
	// 			if(event.target.files.length <= 10)
	// 			{
	// 				var ffile = event.target.files;
	// 				for(var f=0;f<ffile.length;f++)
	// 				{
	// 					allfiles.push(ffile[f])
	// 				}
					
	// 				this.setState({
	// 					uploadDocs:allfiles
	// 				})
	// 			}
	// 			else{
	// 				this.setState({
	// 					show:true,
	// 					basicTitle:"Only a maximum of 10 files are accepted.",
	// 					basicType:"danger"
	// 				})
	// 			}
	// 		}
	// 	}else {
	// 		// Handle the case when the file extension is not allowed (e.g., display an alert)
	// 		hasInvalidFile = true;
	// 		alert("Invalid file: " + allfiles.name);
	// 	  }
	// }
	
	changeDocumentHandler(event) {
		var allfiles = [];
		const allowedFiles = ["jpeg", "png", "pdf", "jpg"];
		const disallowedFiles = ["csv", "xls", "xlsx"];
		let InvalidFile = false;
	
		for (let i = 0; i < event.target.files.length; i++) {
			const file = event.target.files[i];
			const fileExtension = file.name.split('.').pop().toLowerCase();
	
			if (disallowedFiles.includes(fileExtension)) {
				InvalidFile = true;
				this.setState({
					show: true,
					basicTitle: "CSV and MS Excel files are not allowed",
					basicType: "danger"
				});
				event.target.value = "";
			} else if (allowedFiles.includes(fileExtension)) {
				allfiles.push(file);
	
				// Check file size (1MB = 1024 * 1024 bytes)
				const fileSizeInBytes = file.size;
				const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
	
				if (fileSizeInMB > 1) {
					InvalidFile = true;
					this.setState({
						show: true,
						basicTitle: "File size should be less than 1MB",
						basicType: "danger"
					});
					event.target.value = "";
				}
			}
		}
	
		if (!InvalidFile) {
			if (event.target.files.length > 0) {
				if (event.target.files.length <= 10) {
					this.setState({
						uploadDocs: allfiles
					});
				} else {
					this.setState({
						show: true,
						basicTitle: "Only a maximum of 10 files are accepted.",
						basicType: "danger"
					});
				}
			}
		}
	}
	
	  
	onShowAccidentDocs(rownode)
	{
		// console.log("rownode ", rownode)
		this.setState({
			sliderTPTAccidentDocuments:"slider-translate-30p",
			overly:"show-m",
			accidentDocuments: rownode.data.tpt_accident_documents
		})
	}

		  
	onShowConfirmDevlier(rownode)
	{
		// console.log("rownode ", rownode)
		this.setState({
			sliderTPTConfirmDeliver:"slider-translate-30p",
			overly:"show-m",
			tpt_confirm_rowid:[rownode.data]
		})
	}

	
	onShowBreakDownForm(rownode)
	{
		// console.log("rownode ", rownode.data)
		this.setState({
			sliderTPTBreakdownTranslate:"slider-translate-30p",
			overly:"show-m",
			transitrowdata: [rownode.data],
			tpt_break_down_location:rownode.data.tpt_break_down_location,
			tpt_break_down_consignee_code:{value:rownode.data.tpt_break_down_consignee_code,label:rownode.data.tpt_break_down_consignee_name}
		})
	}
	
	
	
	onShowAccidentForm(rownode){
		// console.log("rownodw", rownode)
		if(rownode.data.tpt_accident_fir_date !== "" && rownode.data.tpt_accident_fir_date !== undefined)
		{
			var tpt_accident_fir_date = getHyphenDDMMYYYY(rownode.data.tpt_accident_fir_date); 
		}
		else{
			var tpt_accident_fir_date = "";
		}
		$("#tpt_accident_fir_date").val(tpt_accident_fir_date);
		// console.log("rownode.data.tpt_accident_documents ", rownode.data.tpt_accident_documents)
		if(rownode.data.tpt_accident_documents !== "" && rownode.data.tpt_accident_documents !== null && rownode.data.tpt_accident_documents !== undefined)
		{
			var docms = rownode.data.tpt_accident_documents;
		}
		else{
			var docms = [];
		}
		this.setState({
			transitrowdata: [rownode.data],
			sliderTPTAccidentTranslate: "slider-translate-40p",
			overlyFixed: 'show-m',
			tpt_accident_a3_report_submission:{value:rownode.data.tpt_accident_a3_report_submission,label:rownode.data.tpt_accident_a3_report_submission},
			tpt_accident_damage_vehicle : rownode.data.tpt_accident_damage_vehicle,
			tpt_accident_documents:rownode.data.tpt_accident_documents,
			tpt_accident_fir_date : tpt_accident_fir_date,
			showfirfield:(rownode.data.tpt_accident_fir_status === "Yes")?"show-m":"show-n",
			tpt_accident_fir_status : {value:rownode.data.tpt_accident_fir_status,label:rownode.data.tpt_accident_fir_status},
			tpt_accident_scenario : rownode.data.tpt_accident_scenario,
			tpt_accident_third_party_damage : rownode.data.tpt_accident_third_party_damage,
			tpt_accident_vehicle_current_status : rownode.data.tpt_accident_vehicle_current_status,
			allDocs:docms,
			accidentDocuments: docms
		})
	}
	onShowDeviationForm(rownode){
		// console.log("Formd rownode ,", rownode.data)
		this.setState({
			loadshow:"show-m",
			transitrowdata: [],
			tpt_deviation_location:"",
			tpt_deviation_city:"",
			tpt_deviation_address:"",
			tpt_deviation_zone:"",
			tpt_deviation_postal_code:"",
			tpt_approved_deviation_no:"",
			tpt_deviation_contact_number:"",
			body_shop:{value:"",label:"Select"},
			ship_to_party:{value:"",label:"Select"},
			tpt_deviation_delivered_at:{value:"",label:"Select"}
		},()=>{
			setTimeout(() => {

				if(rownode.data.tpt_deviation_delivered_at == "At Dealer")
				{
					var showDealerDeviate = "show-m";
				}
				else{
					var showDealerDeviate = "show-n";
				}
				if(rownode.data.tpt_deviation_ship_to_party == "others")
				{
					var showDealerDeviateother = "show-m";
				}
				else{
					var showDealerDeviateother = "show-n";
				}
				this.setState({
					loadshow:"show-n",
					transitrowdata: [rownode.data],
					sliderTPTDeviationTranslate: "slider-translate-40p",
					overlyFixed: 'show-m',
					tpt_deviation_location:rownode.data.tpt_deviation_location,
					tpt_deviation_city:rownode.data.tpt_deviation_city,
					tpt_deviation_address:rownode.data.tpt_deviation_address,
					tpt_deviation_zone:rownode.data.tpt_deviation_zone,
					tpt_deviation_postal_code:rownode.data.tpt_deviation_postal_code,
					tpt_approved_deviation_no:rownode.data.tpt_approved_deviation_no,
					tpt_deviation_contact_number:rownode.data.tpt_deviation_contact_number,
					body_shop:{value:rownode.data.tpt_deviation_body_shop,label:rownode.data.tpt_deviation_body_shop},
					ship_to_party:{value:rownode.data.tpt_deviation_ship_to_party,label:rownode.data.tpt_deviation_ship_to_party_name},
					tpt_deviation_delivered_at:{value:rownode.data.tpt_deviation_delivered_at,label:rownode.data.tpt_deviation_delivered_at},
					showDealerDeviate:showDealerDeviate,
					showDealerDeviateother:showDealerDeviateother
				})

			},2000)
		})
		
	}
	changeDriverFileHandler = async (e) => {
		var dt = '';

		const config = {
			headers: [
				{
					name: 'Invoice No',
					inputName: 'invoice_no',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'VIN No',
					inputName: 'vin_number',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'Driver Name',
					inputName: 'tpt_driver_name',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'Driver Mobile Number',
					inputName: 'tpt_driver_mobile',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'Convoy Leader Name',
					inputName: 'tpt_convoy_leader_name',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'Convoy Leader Mobile',
					inputName: 'tpt_convoy_leader_mobile',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
			.then((csvData) => {
				this.setState({
					drivercontent: csvData.data
				});
			})
			.catch(err => { })
		// console.log("e.target.files[0]",e.target.result);
		var contentList = []

		var out = new Promise(function (reject, resolve) {
			var reader = new FileReader();
			reader.onload = async function (e) {
				var contents = await e.target.result;
				resolve(contents);
			};
			var tt = reader.readAsText(e.target.files[0]);
		});
		this.setState({
			driverfile: e.target.files[0]
		});
		// console.log("e.target.files[0].type ", e.target.files[0]);

		if (e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel') {
		}
		else {
			e.target.value = null;
			this.setState({
				uploadFile: '',
				driverfile: "",
				show: true,
				basicType: 'danger',
				basicTitle: 'Please upload file having extensions .csv only.',
			});
		}

	}
	onClickShowBulkUploadDriversForm(){
		this.setState({
			sliderBulkDriverUpdate:"slider-translate",
			overly:"show-m"
		})
	}
	uploadDriverBulk(event)
	{
		event.preventDefault();
		var params = {
			driversdata:this.state.drivercontent
		}
		// console.log("Params ", params)
		redirectURL.post("/dashboard/uplaodTransporterDriver",params)
		.then((response) => {
			// sconsole.log("response.data",response.data)
			if(response.data.status === "success")
			{
				this.setState({
					show:true,
					basicTitle:"Successfully update drivers information",
					basicType:"success",
					sliderBulkDriverUpdate:"",
					overly:"show-n",
					drivercontent:[],
					driverfile:""

				})
				
				var consigners = this.state.consigner;
				// console.log("consigners",consigners)
				
				var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
				var urlpath = '/consignments/activetransporterconsignments';
				var dpt = 'SNDG';
				
				var consignData = {
					dept_code: encode(dpt),
					startDate: this.state.startDate,
					endDate: this.state.endDate,
					consigner: consigners
				}
				
				if (this.state.flTransporter.value != "" && this.state.flTransporter.value != undefined && this.state.flTransporter.value != null) {
					consignData.transporter_code = this.state.flTransporter.value;
				}
				this.onLoadGetConsignmentsList(urlpath, consignData);
			}
			else{
				this.setState({
					show:true,
					basicTitle:"Failed to update drivers information",
					basicType:"danger",
					sliderBulkDriverUpdate:"",
					overly:"show-n",
					drivercontent:[],
					driverfile:""

				})
			}
			
		})
	}
	formConfirmDeliver(event){
		event.preventDefault();

		var tpt_confirm_rowid = this.state.tpt_confirm_rowid[0]._id;
		var tpt_confirm_deliver_remarks = this.state.tpt_confirm_deliver_remarks;
		var tpt_confirm_delivered_location = this.state.tpt_confirm_delivered_location.value;
		var tpt_confirm_delivered_other_location = this.state.tpt_confirm_delivered_other_location;
		var params = {
			tpt_confirm_rowid:tpt_confirm_rowid,
			tpt_delivere_confirm:1,
			tpt_confirm_deliver_remarks:tpt_confirm_deliver_remarks,
			tpt_confirm_delivered_location:tpt_confirm_delivered_location,
			tpt_confirm_delivered_other_location:tpt_confirm_delivered_other_location
		}
		// console.log("parms ", params)
		redirectURL.post("/dashboard/tptconfirmdeliver",params)
		.then((response) => {
			if(response.data.status  == "success")
			{
				this.setState({
					show:true,
                    basicTitle:"Confirmation delivery status update successfully",
                    basicType:"success",
                    overly:"show-n",
					tpt_confirm_rowid:[],
                    tpt_confirm_deliver_remarks:"",
					sliderTPTConfirmDeliver:''
				})
					
				var consigners = this.state.consigner;
				// console.log("consigners",consigners)
				
				var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
				var urlpath = '/consignments/activetransporterconsignments';
				var dpt = 'SNDG';
				
				var consignData = {
					dept_code: encode(dpt),
					startDate: this.state.startDate,
					endDate: this.state.endDate,
					consigner: consigners
				}
				
				if (this.state.flTransporter.value != "" && this.state.flTransporter.value != undefined && this.state.flTransporter.value != null) {
					consignData.transporter_code = this.state.flTransporter.value;
				}
				this.onLoadGetConsignmentsList(urlpath, consignData);
			}
			else{
				this.setState({
					show:true,
                    basicTitle:"Failed process",
                    basicType:"danger",
                    overly:"show-n"
				})
			}
		})
	}
	onChangeTPTDvZone = (event) => {
		this.setState({ tpt_deviation_zone: event.value });
	};
	
	// Handle input change and filter options based on user input
	handleInputChange = (e) => {
		try {
			const { value } = e.target;
			this.setState({ inputValue: value }, this.filterOptions); // Update input value and filter options
		} catch (e) {
			console.log("Error ", e)
		}
	};
	// Filter the options based on the selected filter type and input value
	filterOptions = () => {
		try {
			const { selectedFilter, originalData, inputValue } = this.state;
			let options = [];
			// Select appropriate options based on the selected filter type
			if (selectedFilter.value === 'Truck') {
				options = originalData.map((item) => item.vin_number);
			} else if (selectedFilter.value === 'GSTINV') {
				options = originalData.map((item) => item.invoice_no);
			} else if (selectedFilter.value === 'deliveryno') {
				options = originalData.map((item) => item.delivery_no);
			}
			options = [...new Set(options)]
			// Filter options based on the input value
			const filtered = options.filter((option) =>
				option.toString().toLowerCase().includes(inputValue.toLowerCase())
			);
			// Limit the filtered options to the first 5 matches
			this.setState({ filteredOptions: filtered.slice(0, 5) });
		} catch (e) {
			console.log("Error ", e)
		}
	};
	// Handle Select change event
	handleSelectChange = (selectedOption) => {
		try {
			this.setState(
				{ selectedFilter: selectedOption, inputValue: '' },
				this.filterOptions // Re-filter options when the selection changes
			);
		} catch (e) {
			console.log("Error ", e)
		}
	};
	render() {
		const { selectedFilter, filteredOptions, inputValue , allBtn , intransitBtn , deliveredBtn} = this.state;
		console.log( deliveredBtn , intransitBtn , allBtn)
		const modalStyles = {
			width: '1300px !important',
		}
		const { open } = this.state;
		const { openforceclose } = this.state;
		const { usermanualncrmodal } = this.state;
		const { usermanualmovemodal } = this.state;
		let plantWithHold = 13
		let plantWithoutHold = 16
		let yardWithHold = 15
		let yardWithoutHold = 37
		const plantTotal = plantWithHold + plantWithoutHold;
		const yardTotal = yardWithHold + yardWithoutHold;
		var maptransporter = '';
		try {
			if (this.state.rownode.transporter) {
				///console.log("map rownode ",this.state.rownode.transporter[0])
				maptransporter = this.state.rownode.transporter[0].transporter_name;
			}
		}
		catch (e) {
			console.log(e)
		}
		var screen_type = this.state.deptcode
		//console.log("Rendere ",this.state.deptcode)
		let transitTimeHeading = (this.state.deptcode === "LOG-TNP") ? "Transit Time (Hours)" : "Transit Time (Days)";
		var department_code = this.state.deptcode;
		let isHiddenLocalMovement = (this.state.deptcode === "LOG-PRT") ? false : true;
		//console.log("isHiddenLocalMovement = ", isHiddenLocalMovement, this.state.deptcode);
		let hideonlyPRT = (this.state.deptcode === "LOG-PRT") ? true : false;
		let hideonlyTNP = (this.state.deptcode === "LOG-TNP") ? true : false;
		let hideTNPconsignment = (this.state.deptcode === "LOG-TNP" && this.state.tnpReturnConsignmentsVisibilty == false) ? false : true;
		let invoiceTimeName = (this.state.deptcode === "LOG-PRT") ? 'Truck Gateout Time' : 'Dispatch Date';
		//let hideOnlyActive =
		let hideonlyCol = ((this.props.match.path == "/sndconsignments")
			|| (this.props.match.path == "/prtconsignments")) ? true : false;


		let showOnlyCol = false;
		let showtimestamp = false;
		if ((this.props.match.path == "/sndconsignments")
			|| (this.props.match.path == "/prtconsignments")
			|| (this.props.match.path == "/tnpconsignments")
			|| (this.props.match.path == "/railconsignments")
			|| (this.props.match.path == "/ncrconsignments")) {
			showOnlyCol = false;
			showtimestamp = false;
		}
		else if (localStorage.getItem("roles") == "PACC") {
			showOnlyCol = true;
		}
		else {
			showOnlyCol = true;
		}
		if (this.props.match.path == "/insidemsilvicinity") {
			showtimestamp = false;
		}
		else {
			if ((this.props.match.path == "/sndconsignments")
				|| (this.props.match.path == "/prtconsignments")
				|| (this.props.match.path == "/tnpconsignments")
				|| (this.props.match.path == "/railconsignments")
				|| (this.props.match.path == "/ncrconsignments")) {
				showtimestamp = false;
			}
			else {
				showtimestamp = true;
			}

		}

		let showOnlyColDeliver = ((this.props.match.path == "/snddeliveredconsignments")
			|| (this.props.match.path == "/prtdeliveredconsignments")
			|| (this.props.match.path == "/tnpdeliveredconsignments")) ? false : true;

		let showonlyactivencr = ((this.props.match.path == "/sndconsignments")
			|| (this.props.match.path == "/prtconsignments")
			|| (this.props.match.path == "/tnpconsignments")
			|| (this.props.match.path == "/ncrconsignments")) ? false : true;

		let showdistance = ((this.props.match.path == "/sndconsignments")
			|| (this.props.match.path == "/prtconsignments")
			|| (this.props.match.path == "/tnpconsignments")) ? false : true;

		let invoicedisable = (this.state.deptcode == 'SNDG') ? false : true;
		let showonlysnd = (this.props.match.path == "/sndconsignments" || this.props.match.path == "/snddeliveredconsignments" || this.props.match.path == "sndallconsignments") ? false : true;
		//console.log("hideonlyCol ", hideonlyCol)
		var pagepath = this.props.match.path;
		if ((this.state.counter_screen == "active" && this.state.deptcode != "LOG-TNP") || this.props.match.path == "/insidemsilvicinty") {
			var checkboxSelectionval = true;
			var hideStatus = true;
			var truckNoHeader = {
				headerName: "Vin No",
				field: "truck_no",
				width: 100,
				filter: "agSetColumnFilter",
				pinned: "left",
				cellRenderer: '', resizable: true
			}
		}
		else {
			var checkboxSelectionval = false;
			var hideStatus = false;
			var truckNoHeader = {
				headerName: "Vin No",
				field: "truck_no",
				width: 100,
				filter: "agSetColumnFilter",
				cellRenderer: '', resizable: true
			}
		}
		var hideNCR;
		if (this.props.match.path == "/ncrconsignments" || this.state.tnpReturnConsignmentsVisibilty) {
			hideNCR = true;
		} else {
			hideNCR = false;
		}
		if (this.state.screenpage == "deliver") {
			var hideOnTime = false
		} else { var hideOnTime = true }
		var hideonlypacc = (localStorage.getItem("roles") == "PACC") ? true : false;
		var hideTransporterCode = (this.state.deptcode === 'LOG-PRT' || this.state.deptcode === 'SNDG') ? false : true;
		let hideGPSFields = ((this.props.match.path == "/snddeliveredconsignments")
			|| (this.props.match.path == "/prtdeliveredconsignments")
			|| (this.props.match.path == "/tnpdeliveredconsignments")
			|| (this.props.match.path == "/sndallconsignments")
			|| (this.props.match.path == "/prtallconsignments")
			|| (this.props.match.path == "/tnpallconsignments")) ? false : true;

		let showOnlyDeliver = ((this.props.match.path == "/snddeliveredconsignments")
			|| (this.props.match.path == "/sndallconsignments")) ? false : true;
		var hideAccidentColumn = true;
		if (this.state.deptcode == "SNDG") {
			hideAccidentColumn = false;
		}
		var hidePalletColumns = (this.props.match.path == "/tnpconsignments") ? false : true;
		var hideCritical = (this.props.match.path == "/insidemsilvicinty") ? true : false;
		var hideDuration = (this.props.match.path == "/insidemsilvicinty") ? false : true;
		var last_consignment_zone = ((this.props.match.path == "/prtallconsignments") ||
			(this.props.match.path == "/prtdeliveredconsignments") ||
			(this.props.match.path == "/prtconsignments")) ? false : true;
		var screen_url = this.props.match.path;
		var hideDealerGateIn = true;
		if (screen_type == "LOG-PRT") {
			if (screen_url != "/prtconsignments") {
				hideDealerGateIn = false;
			} else { hideDealerGateIn = true };
		} else { hideDealerGateIn = true; };
		const mgpa_fields = (this.state.deptcode == "LOG-PRT") ? false : true;
		var hideDeleteOpt = true;
		if (this.props.match.path == "/tnpconsignments" && this.state.tnpReturnConsignmentsVisibilty == false) {
			var userType = localStorage.getItem('user_type');
			// console.log(userType, "userType")
			if (userType == 'TRANSPORTER') {
				// console.log(hideDeleteOpt, "hideDeleteOpt1")
				hideDeleteOpt = true;
			}
			else {
				// console.log(hideDeleteOpt, "hideDeleteOpt2")
				hideDeleteOpt = false;
			}
		}
		let showForDeliveryPrt = (this.props.match.path == "/prtdeliveredconsignments") ? false : true
		var hideVinsbvh = (this.state.deptcode == "SNDG") ? false : true;
		var hidePriority = (["/prtconsignments", "/prtdeliveredconsignments", "/prtallconsignments"].includes(this.props.match.path)) ? true : false;
		const columnwithDefs =
			[
				// {
				// 	headerName: "Actions",
				// 	field: "_id",
				// 	colId: "_id",
				// 	width: 40,
				// 	pinned: 'left',
				// 	filter: false, resizable: true,
				// 	// headerCheckboxSelection: checkboxSelectionval,
				// 	// headerCheckboxSelectionFilteredOnly: true,
				// 	checkboxSelection: true

				// },
				{
					headerName: "View Route",
					field: "_id",
					colId: "_id",
					width: 40,
					pinned: 'left',
					cellRenderer: 'consignmentActions',
					filter: true, resizable: true,
					valueGetter: params => {
						try {
							const { no_consignee_coords, no_dealer_coords } = params.data;
							return (no_consignee_coords && no_dealer_coords && (no_consignee_coords === 1 || no_dealer_coords === 1)) ? "No Dealer Coords" : "Dealer Coords";
						} catch (e) {
							console.error("Error:", e);
							return 0; // Return default value in case of error
						}
					}	

				}, 
				{
					headerName: "Transit Delay",
					field: "transit_delay",
					colId: "transit_delay",
					width: 40,
					pinned: 'left',
					pivot: false,
					cellRendererSelector: function (params) {
						if (params.data.transit_delay == 1) {
							var rendComponent = {
								component: 'kpiTransitDelayActions'
							};
							return rendComponent
						}
						else {
							rendComponent = {
								component: 'notransit'
							};
							return rendComponent
						}
					},
					filter: true, resizable: true,
					valueGetter: params => {
						try {
							let { transit_delay } = params.data
							let msg = "No Transit Delay"
							if (transit_delay == 1) {
								msg = "Transit Delay"
							}
							return msg
						} catch (e) {
							console.log("Error ,", e)
						}
					}
				},
				{
					headerName: "Gps Data",
					field: "no_gps_data",
					colId: "no_gps_data",
					width: 40,
					pinned: 'left',
					pivot: false,
					cellRendererSelector: function (params) {
						var comp = 'gps';
						// if (params.data.no_gps_data == 0 && params.data.no_gps_data_lifetime == 1) {
						// 	comp = 'noGpsLifetime'

						// }
						// else if (params.data.no_gps_data == 1) {
						// 	comp = 'nogps'
						// }

						// else if (params.data.no_gps_data == 0) {
						// 	comp = 'gps'

						// }
						var comp = 'GpsDataDynamic'
						var rendComponent = {
							component: comp
						};
						return rendComponent
					},
					filter: true, resizable: true,
					valueGetter: params => {
						try {
							let { no_gps_records, missing_gps_for_delivered_trips } = params.data
							var msg = 'GPS Data Available'
							if (no_gps_records == 0) {
								var msg = "GPS Data Not Available"
							} else if (no_gps_records == 1) {
								var msg = '> 1 Hr'
							} else if (no_gps_records == 2) {
								var msg = '> 4 Hrs'
							} else if (no_gps_records == 3) {
								var msg = '> 10 Hrs'
							}
							//  if(props.data.status==4 || props.data.status==7)   { // For Delivered 
							// gps_flags = {'Red': 0, 'Violet': 1, 'Brown': 2, 'Grey': 3, 'Orange': 4, 'Dark Pink': 5, 'Black': 6}
							// missing_gps_for_delivered_trips
							if (missing_gps_for_delivered_trips == 0) {
								var msg = 'GPS Data Not Available'
							} else if (missing_gps_for_delivered_trips == 1) {
								var msg = '< 2 Hrs'
							} else if (missing_gps_for_delivered_trips == 2) {
								var msg = '> 2 Hrs'
							} else if (missing_gps_for_delivered_trips == 3) {
								var msg = '> 4 Hrs'
							} else if (missing_gps_for_delivered_trips == 4) {
								var msg = '> 8 Hrs'
							} else if (missing_gps_for_delivered_trips == 5) {
								var msg = '> 16 Hrs'
							} else if (missing_gps_for_delivered_trips == 6) {
								var msg = '> 24 Hrs'
							}
							return msg
						} catch (e) {
							console.log("Error ,", e)
						}
					}
				},
				{
					headerName: "Overspeed",
					field: "overspeeding_exception",
					colId: "overspeeding_exception",
					width: 40,
					pinned: 'left',
					pivot: false,
					cellRendererSelector: function (params) {
						if (params.data.overspeeding_exception == 1) {
							var rendComponent = {
								component: 'kpiActions'
							};
							return rendComponent
						}
						else {
							var rendComponent = {
								component: 'nooverspeed'
							};
							return rendComponent
						}
					},
					filter: true, resizable: true,
					valueGetter: params => {
						try {
							let { overspeeding_exception } = params.data
							let msg = "No Overspeed"
							if (overspeeding_exception == 1) {
								msg = "Overspeed"
							}
							return msg
						} catch (e) {
							console.log("Error ", e)
						}
					}
				},
				{
					headerName: "Night Drive",
					field: "nightdriving_exception",
					colId: "nightdriving_exception",
					width: 40,
					pinned: 'left',
					pivot: true,
					hide: hideonlyPRT,
					cellRendererSelector: function (params) {
						//console.log("Except ",params.data.nightdriving_exception)
						//console.log("Consignemnt code ", params.data.consignment_code)
						if (params.data.nightdriving_exception != undefined && params.data.nightdriving_exception == 1) {
							var rendComponent = {
								component: 'kpiNightDriveActions'
							};
							return rendComponent
						}
						else {
							var rendComponent = {
								component: 'nonightdrive'
								//component: 'kpiNightDriveActions'
							};
							return rendComponent
						}
					},
					filter: true, resizable: true,
					valueGetter: params => {
						try {
							let { nightdriving_exception } = params.data
							let msg = "No Nightdrive"
							if (nightdriving_exception == 1) {
								msg = "Nightdrive"
							}
							return msg
						} catch (e) {
							console.log("Error ", e)
						}
					}
				},
				// {
				// 	headerName: "Loading Delay",
				// 	field: "loading_delay_exception",
				// 	colId: "loading_delay_exception",
				// 	width: 40,
				// 	pinned: 'left',
				// 	pivot: false,
				// 	hide: hideonlyPRT,
				// 	cellRendererSelector: function (params) {
				// 		var rendComponent = {
				// 			component: 'kpiLoadingDelayActions'
				// 		};
				// 		return rendComponent;
				// 	},
				// 	filter: true, resizable: true
				// },
				// {
				// 	headerName: "Unloading Delay",
				// 	field: "unloading_delay_exception",
				// 	colId: "unloading_delay_exception",
				// 	width: 40,
				// 	pinned: 'left',
				// 	pivot: false,
				// 	hide: hideonlyCol,
				// 	cellRendererSelector: function (params) {
				// 		if (params.data.unloading_delay_exception != undefined && params.data.unloading_delay_exception == 1) {
				// 			var rendComponent = {
				// 				component: 'kpiUnLoadingDelayActions'
				// 			};
				// 			return rendComponent;
				// 		}
				// 		else {
				// 			var rendComponent = {
				// 				component: 'kpiUnLoadingDelayActions'
				// 			};
				// 			return rendComponent;
				// 		}

				// 	},
				// 	filter: true, resizable: true
				// },
				{
					headerName: "Enroute Stoppages",
					field: "enroute_exception",
					width: 40,
					pinned: 'left',
					pivot: false,
					cellRendererSelector: function (params) {

						var rendComponent = {
							component: 'kpiEnrouteStoppagesActions'
						};
						return rendComponent

					},
					filter: true, resizable: true,
					valueGetter: params => {
						try {
							let { enroute_exception } = params.data
							let msg = "No Stoppages"
							if (enroute_exception == 1) {
								msg = "Stoppages"
							}
							return msg
						} catch (e) {
							console.log("Error ", e)
						}
					}
				},
				{
					headerName: "Day Wise Distance Trip",
					field: "day_wise_distance_trip",
					colId: "day_wise_distance_trip",
					width: 40,
					pinned: 'left',
					pivot: false,
					hide: hideonlyPRT,
					cellRenderer: 'kpiDayWiseDistanceActions',
					filter: true, resizable: true
				},
				{
					headerName: "Tolls",
					field: "toll_details",
					// colId: "breaks_at_tolls",
					width: 40,
					pinned: 'left',
					filter: true,
					resizable: true,
					hide: showForDeliveryPrt,
					cellRenderer: (params) => `<img style="height: 25px; width: 24px" src=${tollImg} />`,			

				},
				{
					headerName: "",
					field: "accident_exception",
					// colId: "breaks_at_tolls",
					width: 60,
					pinned: 'left',
					filter: true,
					resizable: true,
					cellRendererSelector:function(params){
						try {
							
							return {
								component:'AccidentIcon'
							}
						
						
						} catch (error) {
							
						}
					},
					valueGetter: params => {
						try {
							let { accident_exception } = params.data
							let msg = "No Accidents"
							if (accident_exception) {
								msg = "Accidents"
							}
							return msg
						} catch (e) {
							console.log("Error ", e)
						}
					}
				},
				{
					headerName: "",
					field: "tpt_deviation_status",
					// colId: "breaks_at_tolls",
					width: 60,
					pinned: 'left',
					filter: true,
					resizable: true,
					cellRendererSelector:function(params){
						try {
							
							return {
								component:'DeviationIcon'
							}
						
						
						} catch (error) {
							
						}
					},
					valueGetter: params => {
						try {
							let { tpt_deviation_status } = params.data
							let msg = "No Deviation"
							if (tpt_deviation_status == "Yes" || tpt_deviation_status == 1) {
								msg = "Deviation"
							}
							return msg
						} catch (e) {
							console.log("Error ", e)
						}
					}
				},
				{
					headerName: "",
					field: "tpt_break_down_status",
					// colId: "breaks_at_tolls",
					width: 60,
					pinned: 'left',
					filter: true,
					resizable: true,
					cellRendererSelector:function(params){
						try {
							
							return {
								component:"BreakDownIcon"
							}
						
						
						} catch (error) {
							
						}
					},
					valueGetter: params => {
						try {
							let { tpt_break_down_status } = params.data
							let msg = "No Breakdown"
							if (tpt_break_down_status == "Yes" || tpt_break_down_status == 1) {
								msg = "Breakdown"
							}
							return msg
						} catch (e) {
							console.log("Error ", e)
						}
					}
				},
				{
					headerName: "Status",
					field: "status",
					width: 120,
					pinned: 'left',
					// hide : hideStatus,
					filter: "agSetColumnFilter",
					resizable: true,
					cellClass:function(params){
						try {
							if(params.data.duplicate_invoice !== ""  && params.data.duplicate_invoice !== undefined)
							{
								if(params.data.duplicate_invoice === 1)
								{
									return ["cellstylegridO"]
								}
							}
							else{
								return "";
							}
						} catch (error) {
							
						}
					},
					valueGetter: (params) => {
						//console.log("GPS ", params.data.gps_data_available);
						if (params.data.status == 1) {
							return "Inside Plant"
						}
						else if (params.data.status == 2) {
							return "In Transit"
						}
						else if (params.data.dest_geofence_id != undefined && screen_type == "LOG-PRT") {
							if (screen_url != "/prtconsignments") {
								return "Reached Plant Gate";
							}
						}
						else if (params.data.status == 3) {
							return "Near Destination"
						}
						else if (params.data.status == 4) {
							return "Reached Destination"
						}

						else if (params.data.status == 5) {
							return "Left Destination"
						}
						else if (params.data.status == 6) {
							return "Force Closed"
						}
						else if (params.data.status == 7) {
							return "Proximity Trip Closure"
						}
						else if (params.data.status == 8 && this.state.deptcode == "LOG-PRT") {
							return "POD Received From MSIL"
						}
						else {
							return ""
						}

					}
				},
				{
					headerName: "Original ETA",
					field: "expected_trip_end",
					id: "expected_trip_end",
					width: 120,
					// pinned: 'left',
					resizable: true,
					cellClass:function(params){
						try {
							if(params.data.duplicate_invoice !== ""  && params.data.duplicate_invoice !== undefined)
							{
								if(params.data.duplicate_invoice === 1)
								{
									return ["cellstylegridO"]
								}
							}
							else{
								return "";
							}
						} catch (error) {
							
						}
					},
					valueGetter: function (params) {
						if (params.data.expected_trip_end != "" && params.data.expected_trip_end != undefined && params.data.expected_trip_end != null) {
							return getHyphenDDMMMYYYYHHMM(params.data.expected_trip_end);
						}
						else {
							return "";
						}


					},
					//filter: "agDateColumnFilter",
					comparator: dateComparator,

				},
				{
					headerName: "Revised ETA",
					field: "revised_trip_end",
					width: 120,
					// pinned: 'left',
					resizable: true,
					// cellClass : "dateFormat",
				
					valueGetter: function (params) {
						//console.log(" ETA ", params.data)
						if (params.data.revised_trip_end == '' || params.data.revised_trip_end == undefined) {
							return getHyphenDDMMMYYYYHHMM(params.data.expected_trip_end);
						}
						else {
							return getHyphenDDMMMYYYYHHMM(params.data.revised_trip_end);
						}

					},
					//filter: "agDateColumnFilter",
					comparator: dateComparator,

					cellClass: function (params) {
						let hourDifference = 0;
						if (params.data.revised_trip_end && params.data.expected_trip_end) {
							let revisedDateOnlyMoment = moment(params.data.revised_trip_end);
							let expectedDateOnlyMoment = moment(params.data.expected_trip_end);
							hourDifference = revisedDateOnlyMoment.diff(expectedDateOnlyMoment, 'hours');
							//console.log("diff= ", params.data.consignment_code, hourDifference);
						} else {
							//console.log("ELSE = ", params.data.revised_trip, params.data.expected_trip_end);
						}

						if (hourDifference >= 1) {
							return 'bgColorDangerMedium'
						}

						if (hourDifference <= -1) {
							return 'bgColorSuccessMedium'
						}
						else {
							return ''
						}
					}
				},
				{
					headerName: "Truck Type",
					field: "vehicle_mode",
					// pinned: 'left',
					width: 120,
					resizable: true,
					cellClass:function(params){
						try {
							if(params.data.duplicate_invoice !== ""  && params.data.duplicate_invoice !== undefined)
							{
								if(params.data.duplicate_invoice === 1)
								{
									return ["cellstylegridO"]
								}
							}
							else{
								return "";
							}
						} catch (error) {
							
						}
					},
				},
				{
					headerName: "Model",
					field: "model",
					// pinned: 'left',
					width: 120,
					resizable: true,
					cellClass:function(params){
						try {
							if(params.data.duplicate_invoice !== ""  && params.data.duplicate_invoice !== undefined)
							{
								if(params.data.duplicate_invoice === 1)
								{
									return ["cellstylegridO"]
								}
							}
							else{
								return "";
							}
						} catch (error) {
							
						}
					},
				},
				{
					headerName: "Variant Number",
					field: "variant_number",
					width: 180,
					// pinned: "left",
					cellClass:function(params){
						try {
							if(params.data.duplicate_invoice !== ""  && params.data.duplicate_invoice !== undefined)
							{
								if(params.data.duplicate_invoice === 1)
								{
									return ["cellstylegridO"]
								}
							}
							else{
								return "";
							}
						} catch (error) {
							
						}
					},
					filter: "agSetColumnFilter",
					resizable: true
				},
				{
					headerName: "Description",
					field: "description",
					width: 200,
					// pinned: "left",
					cellClass:function(params){
						try {
							if(params.data.duplicate_invoice !== ""  && params.data.duplicate_invoice !== undefined)
							{
								if(params.data.duplicate_invoice === 1)
								{
									return ["cellstylegridO"]
								}
							}
							else{
								return "";
							}
						} catch (error) {
							
						}
					},
					filter: "agSetColumnFilter",
					resizable: true
				},
				
				{
					headerName: "Truck No",
					field: "truck_no",
					width: 120,
					hide:true,
					// pinned: "left",
					cellClass:function(params){
						try {
							if(params.data.duplicate_invoice !== ""  && params.data.duplicate_invoice !== undefined)
							{
								if(params.data.duplicate_invoice === 1)
								{
									return ["cellstylegridO"]
								}
							}
							else{
								return "";
							}
						} catch (error) {
							
						}
					},
					filter: "agSetColumnFilter",
					resizable: true
				},
				{
					headerName: "Vin Number",
					field: "vin_number",
					width: 180,
					// pinned: "left",
					cellClass:function(params){
						try {
							if(params.data.duplicate_invoice !== ""  && params.data.duplicate_invoice !== undefined)
							{
								if(params.data.duplicate_invoice === 1)
								{
									return ["cellstylegridO"]
								}
							}
							else{
								return "";
							}
						} catch (error) {
							
						}
					},
					filter: "agSetColumnFilter",
					resizable: true
				},
				{
					headerName: "Engine Number",
					field: "consignment_code",
					// pinned: 'left',
					width: 150,
					resizable: true,
					cellClass:function(params){
						try {
							if(params.data.duplicate_invoice !== ""  && params.data.duplicate_invoice !== undefined)
							{
								if(params.data.duplicate_invoice === 1)
								{
									return ["cellstylegridO"]
								}
							}
							else{
								return "";
							}
						} catch (error) {
							
						}
					},
					// hide:hideTNPconsignment,
					// comparator: dateComparator,
					valueGetter: function (params) {
						if (params.data.consignment_code != '' && params.data.consignment_code != undefined) {
							return params.data.consignment_code;
						}
						else {
							return "";
						}
					},
				},
				
				{
					headerName: "Dealer Name",
					field: "parent_dealer_name",
					// pinned: 'left',
					width: 150,
					resizable: true,
					cellClass:function(params){
						try {
							if(params.data.duplicate_invoice !== ""  && params.data.duplicate_invoice !== undefined)
							{
								if(params.data.duplicate_invoice === 1)
								{
									return ["cellstylegridO"]
								}
							}
							else{
								return "";
							}
						} catch (error) {
							
						}
					},
				},
				{
					headerName: "Ship To Party Code",
					field: "consignee_code",
					width: 150,
					filter: "agSetColumnFilter",
					cellClass:function(params){
						try {
							if(params.data.duplicate_invoice !== ""  && params.data.duplicate_invoice !== undefined)
							{
								if(params.data.duplicate_invoice === 1)
								{
									return ["cellstylegridO"]
								}
							}
							else{
								return "";
							}
						} catch (error) {
							
						}
					},
					cellRenderer: '', resizable: true
				},

				{
					headerName: "Ship To Party Name",
					field: "consignee_name",
					width: 150,
					hide: hideonlyTNP,
					filter: "agSetColumnFilter",
					cellClass:function(params){
						try {
							if(params.data.duplicate_invoice !== ""  && params.data.duplicate_invoice !== undefined)
							{
								if(params.data.duplicate_invoice === 1)
								{
									return ["cellstylegridO"]
								}
							}
							else{
								return "";
							}
						} catch (error) {
							
						}
					},
					cellRenderer: '', resizable: true
				},
				{
					headerName: "Ship To Dealer City",
					field: "consignee_city",
					width: 150,
					hide: hideonlyTNP,
					filter: "agSetColumnFilter",
					cellClass:function(params){
						try {
							if(params.data.duplicate_invoice !== ""  && params.data.duplicate_invoice !== undefined)
							{
								if(params.data.duplicate_invoice === 1)
								{
									return ["cellstylegridO"]
								}
							}
							else{
								return "";
							}
						} catch (error) {
							
						}
					},
					cellRenderer: '', resizable: true
				},

				{
					headerName: "Dealer State",
					field: "consignee_state",
					width: 150,
					hide: hideonlyTNP,
					filter: "agSetColumnFilter",
					cellClass:function(params){
						try {
							if(params.data.duplicate_invoice !== ""  && params.data.duplicate_invoice !== undefined)
							{
								if(params.data.duplicate_invoice === 1)
								{
									return ["cellstylegridO"]
								}
							}
							else{
								return "";
							}
						} catch (error) {
							
						}
					},
					cellRenderer: '', resizable: true
				},

				{
					headerName: "Invoice No",
					field: "invoice_no",
					width: 150,
					hide: hideonlyTNP,
					filter: "agSetColumnFilter",
					cellClass:function(params){
						try {
							if(params.data.duplicate_invoice !== ""  && params.data.duplicate_invoice !== undefined)
							{
								if(params.data.duplicate_invoice === 1)
								{
									return ["cellstylegridO"]
								}
							}
							else{
								return "";
							}
						} catch (error) {
							
						}
					},
					cellRenderer: '', resizable: true
				},
				{
					headerName: "Invoice Date",
					field: "invoice_time",
					width: 150,
					// pinned: 'left',
					//filter: "agDateColumnFilter",
					cellRenderer: '', resizable: true,
					comparator: dateComparator,
					cellClass:function(params){
						try {
							if(params.data.duplicate_invoice !== ""  && params.data.duplicate_invoice !== undefined)
							{
								if(params.data.duplicate_invoice === 1)
								{
									return ["cellstylegridO"]
								}
							}
							else{
								return "";
							}
						} catch (error) {
							
						}
					},

					valueGetter: function (params) {
						if (params.data.invoice_time != '' && params.data.invoice_time != undefined && params.data.invoice_time !== ' ') {
							return getHyphenDDMMMYYYYHHMM(params.data.invoice_time);
						}
						else {
							return "NA";
						}
					},
					// hide: (this.state.tnpReturnConsignmentsVisibilty) ? true : hideonlyPRT, // this logic was imported from msil so for hero we are commenting this as of 27-07-2022
				},
				
				{
					headerName: "Delivery No",
					field: "delivery_no",
					width: 150,
					hide: hideonlyTNP,
					filter: "agSetColumnFilter",
					cellClass:function(params){
						try {
							if(params.data.duplicate_invoice !== ""  && params.data.duplicate_invoice !== undefined)
							{
								if(params.data.duplicate_invoice === 1)
								{
									return ["cellstylegridO"]
								}
							}
							else{
								return "";
							}
						} catch (error) {
							
						}
					},
					cellRenderer: '', resizable: true
				},
				
				{
					headerName: "Dispatch Date",
					field: "gate_out_time",
					width: 150,
					// pinned: 'left',
					//filter: "agDateColumnFilter",
					cellRenderer: '', resizable: true,
					comparator: dateComparator,
					cellClass:function(params){
						try {
							if(params.data.duplicate_invoice !== ""  && params.data.duplicate_invoice !== undefined)
							{
								if(params.data.duplicate_invoice === 1)
								{
									return ["cellstylegridO"]
								}
							}
							else{
								return "";
							}
						} catch (error) {
							
						}
					},

					valueGetter: function (params) {
						if (params.data.gate_out_time != '' && params.data.gate_out_time != undefined && params.data.gate_out_time !== ' ') {
							return getHyphenDDMMMYYYYHHMM(params.data.gate_out_time);
						}
						else {
							return "NA";
						}
					},
					// hide: (this.state.tnpReturnConsignmentsVisibilty) ? true : hideonlyPRT, // this logic was imported from msil so for hero we are commenting this as of 27-07-2022
				},
				{
					headerName: "Vin Starting Point",
					field: "truck_starting_point",
					width: 200,
					filter: "agSetColumnFilter",
					hide: last_consignment_zone,
					cellRenderer: '', resizable: true
				},
				
				{
					headerName: "Transit Delay (Days)",
					field: "consignment_code",
					width: 120,
					filter: "agSetColumnFilter",
					cellRenderer: '',
					resizable: true,
					valueGetter: function (params) {
						var expected = params.data.expected_trip_end;
						if (params.data.revised_trip_end == '' || params.data.revised_trip_end == undefined) {
							var revised = params.data.expected_trip_end;
						}
						else {
							var revised = params.data.revised_trip_end;
						}
						//console.log("Exp ", expected)
						//console.log("revised ", revised)
						var s = moment.parseZone(expected).format("x");
						var ed = moment.parseZone(revised).format("x");
						//console.log("Exp ", s)
						//console.log("revised ", ed)
						var diff = (ed - s)
						// console.log("Diff",diff/1000)
						var days = secondsToDays(diff)
						//console.log("Days ", Math.round(days/1000))
						var totaldays = Math.round(days / 1000);
						if (totaldays > 0) {
							return totaldays;
						}
						else {
							return 0;
						}
						//return Math.round(days/1000);

					}
				},
				
				{
					headerName: "Transit Days Difference",
					field: "difference_transit_time",
					width: 120,
					filter: "agSetColumnFilter",
					cellRenderer: '',
					resizable: true,
					valueGetter: function (params) {
						try {
							if(params.data.difference_transit_time !== "" && params.data.difference_transit_time !== undefined)
							{
								return params.data.difference_transit_time;
							}
							else{
								return "";
							}
						} catch (error) {
							
						}
					}
				},
				
				{
					headerName: "Duration from Gate Out",
					field: "duration_from_gate_out",
					width: 170,
					pinned: "left",
					filter: true,
					resizable: true,
					hide: hideDuration,
					valueGetter: function (params) {
						if (params.data.gate_out_time != undefined &&
							params.data.gate_out_time != "" &&
							params.data.gate_out_time != null
						) {
							var gateout = new Date(params.data.gate_out_time);
							var lastpacket = new Date();
							var diff = lastpacket.getTime() - gateout.getTime();
							var diffdate = new Date(diff);
							var days = Math.floor(diff / (1000 * 60 * 60 * 24))
							days = (days < 0) ? 0 : days;
							return days + " Day(s) : " + diffdate.getHours() + " hours : " + diffdate.getMinutes() + " Minutes"
						}
						else {
							return "";
						}

					}
				},

				{
					headerName: "Transporter Name",
					field: "transporter_name",
					width: 150,
					filter: "agSetColumnFilter",
					cellRenderer: '',
					resizable: true,
					valueGetter: function (params) {
						if (params.data.truck_no != "" && params.data.truck_no != "") {
							return params.data.transporter_name
						}
					}
				},
				{
					headerName: "Transporter Code",
					field: "transporter_code",
					width: 110,
					filter: "agSetColumnFilter",
					hide: hideTransporterCode,
					cellRenderer: '', resizable: true,
					valueGetter: function (params) {
						if (params.data.truck_no != "" && params.data.truck_no != "") {
							return params.data.transporter_code
						}
					}
				},

				{
					headerName: "Last Location",
					field: "address",
					width: 170,
					// hide:showOnlyCol,
					valueGetter: function (params) {
						//console.log(params.data.transporter);
						try {
							// console.log("params.data.truck.length ", params.data.truck.length)
							if (params.data.truck.length > 0) {
								// console.log("params.data.truck ", params.data.truck)
								if (params.data.truck[0].last_known_address != undefined) {
									if (params.data.truck_no != "" && params.data.truck_no != "") {
										return params.data.truck[0].last_known_address;
									}
									else {
										return "";
									}
								}
								else {
									return "";
								}
							}
							else {
								return "";
							}
						}
						catch (e) {

						}
					},
					filter: "agSetColumnFilter",
					cellRenderer: '', resizable: true
				},

				{
					headerName: "Travel Status",
					field: "elaped_time_from_last_moved_seconds",
					width: 150,
					filter: "agSetColumnFilter",
					valueGetter: function (params) {
						try {
							if (params.data.truck_no != "" && params.data.truck_no != "") {
								if (params.data.elaped_time_from_last_moved_seconds != "" && params.data.elaped_time_from_last_moved_seconds != undefined) {
									return "Idle (" + secondsToDhms(params.data.elaped_time_from_last_moved_seconds) + ")";
								}

								else {
									if (params.data.elaped_time_from_last_moved_seconds == "" && params.data.elaped_time_from_last_moved_seconds == undefined) {

										return "";
									}
									else {
										return "Running";
									}

								}
							}
						}
						catch (e) {

						}
					}
				},

				{
					headerName: "Speed",
					field: "speed",
					width: 150,
					filter: "agSetColumnFilter"
				},

				{
					headerName: "Travelled Distance (KM)",
					field: "aprox_dist_travelled_from_start",
					width: 170,
					filter: "agSetColumnFilter",
					valueGetter: params => {
						let data = params.data.aprox_dist_travelled_from_start
						if (data == 0) {
							return 0
						}
						return data
						// console.log("params",params.data.aprox_dist_travelled_from_start)
					}
				},
				{
					headerName: "Last Packet Time",
					field: "truck",
					// hide:showtimestamp,
					valueGetter: function (params) {
						//console.log(params.data.transporter);
						if (params.data.hasOwnProperty('truck')) {
							if (params.data.truck_no != "" && params.data.truck_no != "") {
								if (params.data.truck.length > 0) {
									if (params.data.truck[0].timestamp != undefined) {
										return getHyphenDDMMMYYYYHHMM(params.data.truck[0].timestamp);
									}
									else {
										// var s1 = moment.parseZone(params.data.truck[0].timestamp).format("x");
										// var s2 = moment.parseZone(params.data.truck[0].manual_timestamp).format("x");
										// if (s1 > s2) {
										// 	return getHyphenDDMMMYYYYHHMM(params.data.truck[0].timestamp);
										// }
										// else {
										// 	var tstamp = getHyphenDDMMMYYYYHHMM(params.data.truck[0].manual_timestamp);
										// 	return tstamp + " (M)";
										// }
										return "";
									}
								}
							}
						}

					},
					comparator: dateComparator,

					width: 150,
					//filter: "agDateColumnFilter",
					cellRenderer: '', resizable: true
				},
				{
					headerName: "GPS Provider",
					field: "truck",
					width: 150,
					filter: "agSetColumnFilter",
					cellRenderer: '', resizable: true,
					hide: showOnlyCol,
					valueGetter: function (params) {
						//console.log(params.data.transporter);
						if (params.data.hasOwnProperty('truck')) {
							if (params.data.truck.length > 0) {
								return params.data.truck[0].actual_lspuser;
							}
						}

					},
				},				
				{
					headerName: "Plant Code",
					field: "consigner_code",
					width: 120,
					filter: "agSetColumnFilter",
					cellRenderer: '', resizable: true
				},


				{
					headerName: "Dist. From Destination (km)",
					field: "distance_from_dealer_location",
					width: 150,
					filter: "agSetColumnFilter",
					cellRenderer: '', resizable: true,
					valueGetter: function (params) {
						//console.log("params ", params.data);
						if (typeof params.data.distance_from_dealer_location == 'undefined') {

						}
						else {
							return Math.round(params.data.distance_from_dealer_location);
						}

					},
					hide: showonlyactivencr
				},
				// {
				// 	headerName: "Dealer Code",
				// 	field: "consignee_code",
				// 	width: 150,
				// 	filter: "agSetColumnFilter",
				// 	cellRenderer: '', resizable: true
				// },

				// {
				// 	headerName: "Dealer Name",
				// 	field: "consignee_name",
				// 	width: 150,
				// 	hide: hideonlyTNP,
				// 	filter: "agSetColumnFilter",
				// 	cellRenderer: '', resizable: true
				// },
				// {
				// 	headerName: "Dealer City",
				// 	field: "consignee_city",
				// 	width: 150,
				// 	hide: hideonlyTNP,
				// 	filter: "agSetColumnFilter",
				// 	cellRenderer: '', resizable: true
				// },

				// {
				// 	headerName: "Dealer State",
				// 	field: "consignee_state",
				// 	width: 150,
				// 	hide: hideonlyTNP,
				// 	filter: "agSetColumnFilter",
				// 	cellRenderer: '', resizable: true
				// },
				{
					headerName: "Dist. Traveled last 24Hr (KM)",
					field: "distance_travelled_in_24_hrs_kms",
					//colId : "consignment_code",
					width: 120,
					editable: false,
					filter: "agSetColumnFilter", resizable: true,
					hide: showdistance,
					valueGetter: function (params) {
						//console.log(params.data.distance_travelled_in_24_hrs_kms);
						if (params.data.distance_travelled_in_24_hrs_kms != "" && params.data.distance_travelled_in_24_hrs_kms != undefined) {
							return params.data.distance_travelled_in_24_hrs_kms;
						}
						else {
							return 0;
						}
					}
					//cellRenderer : "agGroupCellRenderer"
				},
				

				{
					headerName: "Reached Destination At",
					field: "first_dealer_reported",
					width: 180,

					hide: hideonlyCol,
					//cellRenderer:'dateFormater',
					valueGetter: function (params) {
						if (params.data.status == 4 || params.data.status == 5) {
							return getHyphenDDMMMYYYYHHMM(params.data.recent_dealer_reported);
						}
						else {
							return "";
						}

					},
					resizable: true,

				},
				{
					headerName: "Reached Plant Gate In",
					field: "locked_dealer_reported",
					width: 150,
					//filter: "agDateColumnFilter",
					cellRenderer: '', resizable: true,
					hide: hideDealerGateIn,
					comparator: dateComparator,
					valueGetter: function (params) {

						if (params.data.locked_dealer_reported != '' && params.data.locked_dealer_reported != undefined
							&& params.data.dest_geofence_id != undefined) {
							return getHyphenDDMMMYYYYHHMM(params.data.locked_dealer_reported);
						}
						else {
							return "";
						}
					},
				},
				
				{
					headerName: "OnTime Delivery",
					field: "ontime_status",
					width: 110,
					hide: hideOnTime,
					valueGetter: function (params) {
						if (params.data.ontime_status == 1) {
							return "On Time";
						}
						else if (params.data.ontime_status == 0) {
							return "Delayed";
						} else {
							return "";
						}
					},
					cellClass: function (params) {
						if (params.data.ontime_status == 1) {
							return "bgColorSuccessMedium";
						}
						else if (params.data.ontime_status == 0) {
							return "bgColorDangerMedium";
						} else {
							return "";
						}
					}


				},


				{
					headerName: transitTimeHeading,
					field: "transit_time",
					width: 200,
					filter: "agSetColumnFilter",
					cellRenderer: '', resizable: true,
					valueGetter: function (params) {
						if (department_code == "LOG-PRT") {
							if (params.data.transit_time == 0) {
								return "-";
							}
							else {
								return params.data.transit_time;
							}
						}
						else {
							return params.data.transit_time;
						}

					}
				},
				{

					headerName: "No Of Tolls",
					field: "number_of_tolls",
					width: 100,
					filter: "agSetColumnFilter",
					hide: showForDeliveryPrt,
					cellRenderer: '', resizable: true,
					valueGetter: function (params) {
						if (department_code == "LOG-PRT") {
							if (params.data.number_of_tolls !== 0 && params.data.number_of_tolls !== undefined) {
								return params.data.number_of_tolls;
							}
							else if(params.data.status === 6){
								return 'NA'
							}
							else {
								return 0;
							}
						}						
					}
				},
				{
					headerName: "Expected Distance (KM)",
					field: "distance_in_km",
					width: 200,
					filter: "agSetColumnFilter",
					cellRenderer: '', resizable: true
				},
				
				{
					headerName: "Approved Deviation Number",
					field: "tpt_approved_deviation_no",
					width: 180,
					filter: "agSetColumnFilter",
					cellRenderer: '', resizable: true
				},
				
				{
					headerName: "Force Close",
					field: "",
					width: 80,
					hide: showOnlyCol,
					cellRendererSelector: function (params) {
						var rendComponent = {
							component: 'consignmentforceclose'
						};
						return rendComponent;
					},
					filter: false, resizable: true,


				},


				{
					headerName: "Force Closed Reason",
					field: "force_closure_reason",
					width: 200,
					hide: showOnlyColDeliver,
					cellClass: ['wraptext'],
					filter: false, resizable: true,


				},
				
				{
					headerName: "Driver Details",
					field: "is_driver_update",
					width: 180,
					// hide: hideTPTOnly,
					cellRendererSelector: function (params) {
						try {
							
							var rendComponent = {
								component: "TransporterDriverAction"
							};
							return rendComponent;
							
						} catch (error) {
							
						}
					},
					filter: false, resizable: true,

				},
				
				{
					headerName: "Driver Name",
					field: "tpt_driver_name",
					width: 180,
					filter: false, resizable: true,

				},
				{
					headerName: "Driver Mobile No",
					field: "tpt_driver_mobile",
					width: 180,
					filter: false, resizable: true,

				},
				{
					headerName: "Convoy Leader Name",
					field: "tpt_convoy_leader_name",
					width: 180,
					filter: false, resizable: true,

				},
				{
					headerName: "Convoy Leader Mobile No",
					field: "tpt_convoy_leader_mobile",
					width: 180,
					filter: false, resizable: true,

				},
				
				{
					headerName: "Double Driver",
					field: "tpt_double_driver",
					width: 180,
					editable:true,
					filter: false, resizable: true,
					cellEditor: 'agSelectCellEditor',
					cellEditorParams: {
						values: ['','No', 'Yes'],
					},
					valueGetter:function(params){
						try {
							if(params.data.tpt_double_driver !== "" && params.data.tpt_double_driver !== undefined)
							{
								return params.data.tpt_double_driver;
							}
							else{
								return "NA";
							}
						} catch (error) {
							
						}
					},

				},
				{
					headerName: "Transporter Ramarks",
					field: "transporter_remarks",
					width: 180,
					// hide: hideTPTOnly,
					cellRendererSelector: function (params) {
						var rendComponent = {
							component: 'TransporterRemarkAction'
						};
						return rendComponent;
					},
					filter: false, resizable: true,

				},
				
				{
					headerName: "LSP Deliver Confirmation",
					field: "tpt_delivere_confirm",
					width: 300,
					cellRendererSelector: function (params) {
						// if (params.data.status != 4) {
						var rendComponent = {
							component: 'LSPDeliver'
						};
						return rendComponent
						// }
						// else{
						// 	return "";
						// }
					},
					filter: true, resizable: true
				},
				{
					headerName: "LSP Delivered Location At",
					field: "tpt_confirm_delivered_location",
					width: 160,
					filter: true, resizable: true,
					valueGetter:function (params) {
						try {
							if(params.data.tpt_confirm_delivered_location == "other")
							{
								return params.data.tpt_confirm_delivered_other_location;
							}
							else{
								return params.data.tpt_confirm_delivered_location;
							}
						} catch (error) {
							
						}
					}
				},
				// {
				// 	headerName: "LSP Delivered Location",
				// 	field: "tpt_confirm_delivered_location",
				// 	width: 160,
				// 	filter: true, resizable: true
				// },
				{
					headerName: "LSP Deliver Confirmation On",
					field: "tpt_confirm_delivered_on",
					width: 160,
					valueGetter:function(params){
						try {
							if(params.data.tpt_confirm_delivered_on != "" && params.data.tpt_confirm_delivered_on != undefined)
							{
								var formattedTime = moment(params.data.tpt_confirm_delivered_on, "YYYYMMDDHHmmss").format("DD-MMM-YYYY HH:mm");
								return formattedTime;
							}
						} catch (error) {
							
						}
					},
					filter: true, resizable: true
				},
				// {
				// 	headerName: "Force Closed On",
				// 	field: "force_closure_time",
				// 	width: 180,
				// 	hide:showOnlyColDeliver,

				// 	filter: false,resizable: true,
				// 	valueGetter:function(params){
				// 		return getDDMMYYYYHHMMSS(params.data.force_closure_time)
				// 	}

				// }
				{
					headerName: "Bill To Dealer City",
					field: "dealer_city",
					width: 180

				},
				{
					headerName: "Bill To Dealer Code",
					field: "dealer_code",
					width: 180

				}


			];
			// if ((localStorage.getItem("roles").includes("APP_ADMIN") === true || localStorage.getItem("roles").includes("TRANSPORTER")) && this.state.intransitBtn == "btn-info") {
				
			// 	columnwithDefs.push({
			// 		headerName: "Mark As Delivered",
			// 		field: "mark_delivered",
			// 		colId: "mark_delivered",
			// 		width: 50,
			// 		cellRenderer: (params) => {
			// 			try {
			// 				return '<button title="Mark Delivered" class="btn btn-warning unhold-btn" style="border-radius: 6px; width:70px; padding:5px;" >Mark Delivered</button>';
			// 			} catch (e) {
			// 				console.error("Error in cellRenderer:", e);
			// 				return ''; // Return empty string in case of error
			// 			}
			// 		},				
			// 		filter: false,
			// 		resizable: true
			// 	}
			// 	)
			// }
		const dStyles = {
			width: this.props.width,
			height: '500px',
			marginLeft: this.props.marginLeft,

		}

		const commentColumns = [
			{
				headerName: "Vin No",
				field: "truck_no",
				resizable: true,
				width: 100,
				filter: true
			},
			{
				headerName: "GR No",
				field: "consignment_code",
				resizable: true,
				width: 120,
				filter: true
			},
			{
				headerName: "User Type",
				field: "user_type",
				width: 100,
				resizable: true,
				filter: true,
				valueGetter: function (params) {
					if (params.data.user_type == "ORGUSER") {
						return "MSIL"
					}
					if (params.data.user_type == "DCTUSER") {
						return "DCT"
					}
					if (params.data.user_type == "TRANSPORTER") {
						return "TRANSPORTER"
					}
				}
			},
			{
				headerName: "Comment",
				field: "comment",
				width: 250,
				cellClass: ['wraptext'],
				resizable: true,
				filter: true
			},
			{
				headerName: "Commented By",
				field: "username",
				width: 150,
				resizable: true,
				filter: true
			},

			{
				headerName: "Commented Date",
				field: "comment_date",
				resizable: true,
				filter: true,
				width: 150,
				valueGetter: function (params) {
					if (params.data.comment_date != "") {
						return getDDMMYYYYHHMMSS(params.data.comment_date);
					}
				}
			}

		]
		var hText = 'All';
		// var filterClassName = "form-group col-xl-6 col-lg-6";
		// var filterCardStyle1 = {};
		// var filterCardStyle2 = {padding:'10px 30px'};
		var filterClassName = "form-group col-xl-12 col-lg-12";
		var filterCardStyle1 = { minHeight: "234px" };
		var filterCardStyle2 = { padding: '10px' };
		var mnFilterClass = { marginLeft: "0px", padding: "0px" };
		if (this.state.deptcode == 'SNDG') {
			hText = 'Sales and Dispatch';
			filterClassName = "form-group col-xl-12 col-lg-12";
			filterCardStyle1 = { minHeight: "234px" };
			filterCardStyle2 = { padding: '10px' };
		}
		if (this.state.deptcode == 'LOG-TNP') {
			hText = 'Train and Production';
			filterCardStyle1 = { minHeight: "240px" };
		}

		if (this.state.deptcode == 'LOG-PRT') {
			hText = 'Spare Parts';
			mnFilterClass = { marginLeft: "0px" };
		}
		const tripoptoins = [
			{ "value": "all", label: "All" },
			{ "value": "S", label: "Short Haul" },
			{ "value": "L", label: "Long Haul" }
		]
		
		return (
			<div className="container-fluid">
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					onConfirm={this.closeAlert}>
				</SweetAlert>

				<SweetAlert
					show={this.state.show1}
					type={this.state.basicType1}
					title={this.state.basicTitle1}
					onConfirm={this.closeAlertReload}>
				</SweetAlert>

				
				{(this.state.deptcode == "LOG-TNP") ?
					<div className="button-group">
						<button className={"btn " + this.state.consBtn} onClick={this.onClickTab.bind(this, 1)}>{this.state.tnpButtonTitle} Consignments</button>
						<button className={"btn " + this.state.returnConsBtn} onClick={this.onClickTab.bind(this, 2)}>{this.state.tnpButtonTitle} Return Consignments</button>
					</div>
					: ""}
				<h5 className="mb-15p">
					<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.pagetitle} </span>
				{(this.state.intransitBtn === "btn-info")?
				
				<button className={"btn btn-warning float-right"} onClick={this.onClickShowBulkUploadDriversForm.bind(this)}>Upload Drivers</button>
				:""}
				</h5>
				<div className="row" style={{ marginBottom: "1%" }}>
					<div className="col-xl-12 col-lg-12">
						<button className={"btn "+this.state.intransitBtn} onClick={this.onClickTabConsignments.bind(this,'active')} style={{borderRight:"1px solid #ccc",borderLeft:"1px solid #ccc"}}>Intranit</button>
						<button className={"btn "+this.state.deliveredBtn} onClick={this.onClickTabConsignments.bind(this,'delivered')}>Delivered</button>
						
						<button className={"btn "+(this.state.allBtn)} onClick={this.onClickTabConsignments.bind(this,'all')}>All</button>
					</div>
				</div>
				<div className="row">
					<div className="col-xl-4 col-lg-4">
						<div className="card" style={filterCardStyle1}>
							<div className="row card-body" style={{ padding: "10px 30px" }}>
								<form className="row" onSubmit={this.getTruckConsignments}>
									<div className={filterClassName}>
										<label>Select Type</label>
										<Select
											className="border-radius-0"
											isMulti={false}
											id="selectedFilter"
											value={this.state.selectedFilter}
											style={{ borderRadius: "0px" }}
											options={[
												{ label: "Vin number", value: "Truck" },
												// { label: "GR No", value: "Consingments" }, 
												// { label: "Serial No", value: "Consingments" }, 
												{ label: "Invoice No", value: "GSTINV" }, 
												{ label: "Delivery No", value: "deliveryno" }
											]}
											onChange={e => { this.setState({ selectedFilter: e }) }} required />
									</div>
									<div className={filterClassName}>
										<label className="reason">Vin / Serial No</label>
										<input
											type="text"
											name="reason"
											autoComplete="off"
											className="form-control"
											id="inputTruckConsignment"
											value={inputValue}
											onChange={this.handleInputChange} // Handle change in input value
											list="optionsList" // Connect input to datalist
										/>
										{/* Dynamically populate the datalist based on filtered options */}
										<datalist id="optionsList">
											{filteredOptions.map((option, index) => (
												<option key={index} value={option} />
											))}
										</datalist>
									</div>
									<div className="form-group col-xl-12 col-lg-12" style={{ textAlign: "left" }}>
										<button type="submit" className="btn btn-success">Submit</button>
										
										<button type="button" onClick={this.reloadPageDataGloabl.bind(this)} className="f12" style={{ color:"#000", textDecoration:"underline", border:"0px",background:"transparent",cursor:"pointer"}}>Reset Filters</button>
									</div>
								</form>
							</div>
						</div>
					</div>
					{/* <div className="" id="or" style={{padding:"33px 0px 0px 10px"}}>OR</div> */}
					<div className="col-xl-8 col-lg-8">
						<div className="card" style={filterCardStyle1}>
							<div className="card-body" style={filterCardStyle2}>
								<form id="filterForm" method="POST" className="row">
								{(this.state.deptcode == 'LOG-TNP') ?
									<div className="row col-xl-12 col-lg-12">
										<div className="row col-xl-12 col-lg-12">
											<div className="form-group col-xl-3 col-lg-3">
												<label>Trip Type</label>
												<Select placeholder={"All"} value={this.state.triptype} className="border-radius-0" onChange={this.onSelectTrip.bind(this)} style={{ borderRadius: "0px" }} options={tripoptoins} required />
											</div>

											<div className="form-group col-xl-3 col-lg-3">
												<label>Select Consigner</label>
												<Select placeholder={"All"} closeMenuOnSelect={false} value={this.state.consigner} isMulti="true" className="border-radius-0" onChange={this.changeConsignerArrayHandler.bind(this)} style={{ borderRadius: "0px" }} options={this.selectConsignerOptionsItems()} required />

											</div>
											<div className="col-xl-2 col-lg-2 form-group">
												<label>From Date</label>
												<Datetime value={this.state.startDate}
													disableCloseOnClickOutside={false}
													closeOnSelect={true}
													inputProps={{ placeholder: 'From', name: 'startDate', autoComplete: 'off', readOnly: true }}
													dateFormat="YYYY-MM-DD"
													timeFormat={false}
													name="startDate"
													onChange={this.handlerStartDateTime.bind(this)} />
												{/* <input type="text" placeholder="From Date" id="from_date" value={this.state.startDate} autoComplete="off"  className="datetimepicker_date form-control"  required /> */}

												{/* <input type="text" placeholder="From Date" id="from_date" value={this.state.startDate} autoComplete="off"  className="datetimepicker_date form-control"  required /> */}

											</div>

											<div className="col-xl-2 col-lg-2 form-group">
												<label>To Date</label>
												{/* <input type="text" placeholder="To Date" autoComplete="off" id="to_date" value={this.state.endDate}  className="datetimepicker_date form-control"  required /> */}
												<Datetime value={this.state.endDate}
													disableCloseOnClickOutside={false}
													closeOnSelect={true}
													inputProps={{ placeholder: 'To', name: 'endDate', autoComplete: 'off', readOnly: true }}
													dateFormat="YYYY-MM-DD"
													timeFormat={false}
													onChange={this.handlerEndDateTime.bind(this)} />

											</div>
											{/* <div className="form-group col-xl-12 col-lg-12">
										<button type="button" style={{margin:"0 auto"}} className="btn btn-success" onClick={this.onClickTripButton.bind(this)}>Submit</button>
									</div> */}

										</div>
									</div>
									: ""}

								{(this.state.deptcode == 'LOG-PRT' || this.state.deptcode == 'SNDG') ?
									<div className="row col-xl-12 col-lg-12" style={mnFilterClass}>
										{/* {(this.state.deptcode == 'LOG-PRT') ?

											<div className="form-group col-xl-2 col-lg-2 n-p-0">
												<label>Select Type: </label>
												<Select placeholder={"All"} closeMenuOnSelect={true} value={this.state.movementtype} className="border-radius-0" onChange={this.changeMovementArrayHandler.bind(this)} style={{ borderRadius: "0px" }} options={this.selectMovementTypeOptionsItems()} required />

											</div>
											: ""} */}
										<div className="form-group col-xl-4 col-lg-4">
											<label>Select Consigner</label>
											<Select placeholder={"All"} closeMenuOnSelect={false} value={this.state.consigner} isMulti="true" className="border-radius-0" onChange={this.changeConsignerArrayHandler.bind(this)} style={{ borderRadius: "09px" }} options={this.selectConsignerOptionsItems()} required />

										</div>
										<div className="col-xl-3 col-lg-3 form-group">
											<label>From Date</label>
											{/* <input type="text" placeholder="From Date" id="from_date" value={this.state.startDate} autoComplete="off"  className="datetimepicker_date form-control"  required /> */}
											<Datetime
												value={this.state.startDate}
												disableCloseOnClickOutside={false}
												closeOnSelect={true}
												inputProps={{ placeholder: 'From', name: 'startDate', autoComplete: 'off', readOnly: true }}
												dateFormat="YYYY-MM-DD"
												timeFormat={false}
												name="startDate"
												onChange={this.handlerStartDateTime.bind(this)} />
										</div>

										<div className="col-xl-3 col-lg-3 form-group">
											<label>To Date</label>
											{/* <input type="text" placeholder="To Date" id="to_date" value={this.state.endDate} autoComplete="off"  className="datetimepicker_date form-control"  required /> */}
											<Datetime
												value={this.state.endDate}
												disableCloseOnClickOutside={false}
												closeOnSelect={true}
												inputProps={{ placeholder: 'To', name: 'endDate', autoComplete: 'off', readOnly: true }}
												dateFormat="YYYY-MM-DD"
												timeFormat={false}
												onChange={this.handlerEndDateTime.bind(this)} />
										</div>
										{(this.state.intransitBtn !== "btn-info")?
										<div className="form-group col-xl-2 col-lg-3">
											<label>Date Type</label>
											<Select
												className="border-radius-0"
												isMulti={false}
												style={{ borderRadius: "0px" }}
												value={this.state.date_type}
												onChange={this.onChangeDateType.bind(this)}
												options={[{value:"all", label:"All"},{value:"Delivered", label:"Delivered Date"},{value:"Dispatch", label:"Dispatch Date"}]}
											/>
										</div>
										:""}
										{/* {(this.state.deptcode == 'LOG-PRT')?
								<div className="form-group col-xl-12 col-lg-12 n-p-0">
									<button type="button" className="btn btn-success" onClick={this.onGetConsigners.bind(this)}>Submit</button>
								</div>:""} */}

									</div>
									: ""}

								<div className="row col-xl-12 col-lg-12" style={{ marginLeft: "0px", padding: "0px" }}>
									{(this.state.deptcode == 'SNDG') ?
									<div className="form-group col-xl-3 col-lg-3">
										<label>Dealer Zone </label>
										<Select
											className="border-radius-0"
											isMulti={false}
											id="selectedCluster"
											style={{ borderRadius: "0px" }}
											value={this.state.flZone}
											onChange={this.onChangeZone.bind(this)}
											options={this.state.filterZones}
										/>
									</div>
									: ""}
									{(this.state.deptcode == 'LOG-PRT' || this.state.deptcode == 'SNDG') ?
										<div className="form-group col-xl-3 col-lg-3">
											<label>Dealer State</label>
											<Select
												className="border-radius-0"
												isMulti={false}
												id="selectedState"
												style={{ borderRadius: "0px" }}
												value={this.state.flState}
												onChange={this.onChangeDealerState.bind(this)}
												options={this.state.filterStates}
											/>
										</div>
										: ""}
									{(this.state.deptcode == 'LOG-PRT' || this.state.deptcode == 'SNDG') ?
										<div className="form-group col-xl-3 col-lg-3">
											<label>Dealer City</label>
											<Select
												className="border-radius-0"
												isMulti={false}
												id="selectedCity"
												style={{ borderRadius: "0px" }}
												value={this.state.flCity}
												onChange={this.onChangeDealerCity.bind(this)}
												options={this.state.filterCities}
											/>
										</div>
										: ""}
									{(this.state.deptcode == 'LOG-PRT' || this.state.deptcode == 'SNDG') ?
										<div className="form-group col-xl-3 col-lg-3">
											<label>Dealer</label>
											<Select
												className="border-radius-0"
												isMulti={false}
												id="selectedDealer"
												style={{ borderRadius: "0px" }}
												value={this.state.flDealer}
												onChange={this.newFilter.bind(this, "flDealer")}
												options={this.state.filterDealers}
											/>
										</div>
										: ""}
									
									
									{/* <div className="form-group col-xl-3 col-lg-3">
										<label>Transporter :  </label>
										<Select
											className="border-radius-0"
											isMulti={false}
											id="selectedTransporter"
											style={{ borderRadius: "0px" }}
											value={this.state.flTransporter}
											onChange={this.newFilter.bind(this, "flTransporter")}
											options={this.state.filterTransporters}
										/>
									</div> */}
									{/* <div className="form-group col-xl-12 col-lg-12" style={{textAlign:"left"}}>
										<button type="submit"  className="btn btn-success">Submit</button> &nbsp;&nbsp;
										<button type="button" onClick={this.ClearNewFilter.bind(this)} className="btn btn-danger">Clear</button>
									</div> */}
								</div>
								{(this.state.deptcode == "LOG-TNP") ?
									<div className="form-group col-xl-12 col-lg-12">
										<button type="button" style={{ margin: "0 auto" }} className="btn btn-success" onClick={this.onClickTripButton.bind(this)}>Submit</button>
										<button type="button" onClick={this.reloadPageData.bind(this)} className="f12" style={{ color:"#000", textDecoration:"underline", border:"0px",background:"transparent" ,cursor:"pointer"}}>Reset Filters</button>
								
									</div>
									:
									<div className="row col-xl-12 col-lg-12" style={{ marginLeft: "0px" }}>
										<button type="button" className="btn btn-success" onClick={this.onGetConsigners.bind(this)}>Submit</button>
										<button type="button" onClick={this.reloadPageData.bind(this)} className="f12" style={{ color:"#000", textDecoration:"underline", border:"0px",background:"transparent",cursor:"pointer"}}>Reset Filters</button>
								
									</div>
								}
								</form>
							</div>
						</div>
					</div>
				</div>
				{
					(this.state.isCounterDisplay == 1 && this.state.customer_code == "") ?
						// <div className='row'>
						// 	<div style={{ width: '75%', margin: '0px', paddingRight: '7px', paddingLeft: '14px' }}>
								<Counters
									hide={hideonlyCol}
									consignmentsfor={'tpt'}
									context={this}
									countersjson={this.state.countersjson}
									unloadshow={hideonlyCol}
									gridData={this.state.rowData}
									deptcode={this.state.deptcode}
									triptype={this.state.tripvalue}
									counterScreen={this.state.counter_screen}
									path={this.props.match.path}
									returnConsignments={this.state.tnpReturnConsignmentsVisibilty}
									priority_counter_display={this.state.priority_counter_display}
									truckReportedDate={this.state.truckReportedDate.length}
									withoutTruckGateOutTimeData={this.state.withoutTruckGateOutTimeData}
									total_accidents={this.state.total_accidents.length}
									total_deviations={this.state.total_deviations.length}

								/>
						// 	</div>
						// 	<div style={{ width: '25%', margin: '0px', paddingRight: '14px', paddingLeft: '7px' }}>
						// 		<div style={{
						// 			backgroundColor: '#f0f8ff',
						// 			padding: '20px',
						// 			borderRadius: '5px',
						// 			width: '100%',
						// 			fontFamily: 'sans-serif',
						// 			padding: '15px',
						// 			border: '1px solid #95baf5',
						// 			height:'100%',
						// 		}}>
						// 			<table style={{ width: '100%', borderCollapse: 'collapse' }}>
						// 				<thead>
						// 					<tr>
						// 						<th colSpan={2} style={{ textAlign: 'center', fontWeight: '100', fontSize: '16px', padding: '8px', borderBottom: '1px solid #95baf5' }}>Yard ({yardTotal})</th>
						// 					</tr>
						// 					<tr>
						// 						<th style={{ textAlign: 'center', fontWeight: '100', padding: '8px' }}>With Hold</th>
						// 						<th style={{ textAlign: 'center', fontWeight: '100', padding: '8px' }}>Without Hold</th>
						// 					</tr>
						// 				</thead>
						// 				<tbody>
						// 					<tr>
						// 						<td style={{ fontWeight: '800', fontSize: '18px', textAlign: 'center' }}>{yardWithHold}</td>
						// 						<td style={{ fontWeight: '800', fontSize: '18px', textAlign: 'center' }}>{yardWithoutHold}</td>
						// 					</tr>
						// 				</tbody>
						// 			</table>
						// 		</div>
						// 	</div>
						// </div>
						: ""
				}
				<div className="row mt-20p">
					<div className="col-xl-12 col-lg-12">
						<div className="card">
							<div className="card-body pt-10px" >
								<div className="row">
								<div className="col-xl-2 col-lg-2 fbold">{this.state.counterTitle}</div>
									{
										(this.state.customer_code == "") ?
									<div className="col-xl-12 col-lg-12 mb-10p">

									<span className="float-right" style={{ fontSize: "13px", fontWeight: "100" }}>
										<i className="icofont icofont-map-pins f20" style={{ fontSize: "22px" }}></i>&nbsp;View Route
										<span className="ml-10p mr-10p f14">|</span>
										<i className="icofont icofont-map-pins f20 icolor-red" style={{ fontSize: "22px" }}></i>&nbsp;No Dealer Coords
										<span className="ml-10p mr-10p f14">|</span>
										<i className="icofont icofont-clock-time" style={{ color: "#ff0000", fontSize: "22px	" }}></i>&nbsp;Transit Delay
										<span className="ml-10p mr-10p f14">|</span><i className="icofont icofont-social-google-map txt-danger" style={{ fontSize: "22px" }}></i>&nbsp;GPS Data Not Available
										<span className="ml-10p mr-10p f14">|</span>
											{this.state.deliveredBtn != 'btn-info' &&
												<>{this.state.allBtn == 'btn-info' && <span style={{ fontWeight: 700 }}>Active Consignment </span>}
													<span> ( </span>	GPS Data Not Availble From -
													{/* <i className="icofont icofont-social-google-map txt-secondary" style={{ fontSize: "22px" }}></i>&nbsp;GPS Data Not Adequate */}
													<i className={"icofont icofont-social-google-map  text-dark"} style={{ fontSize: "1.4rem" }}></i> &nbsp; &gt; 1 Hr
													<span className="ml-10p mr-10p f14">|</span>
													<i className={"icofont icofont-social-google-map  text-primary"} style={{ fontSize: "1.4rem" }}></i> &nbsp; &gt; 4 Hrs
													<span className="ml-10p mr-10p f14">|</span>
													<i className={"icofont icofont-social-google-map  text-warning"} style={{ fontSize: "1.4rem" }}></i> &nbsp; &gt; 10 Hrs
													<span> ) </span>
												</>
											}
											{this.state.intransitBtn != 'btn-info' &&
												<>
													{this.state.allBtn == 'btn-info' && <span style={{ fontWeight: 700 }}> Delivered Consignment </span>}
													<span> ( </span>	GPS Data Not Availble From -
													{/* <i className="icofont icofont-social-google-map txt-secondary" style={{ fontSize: "22px" }}></i>&nbsp;GPS Data Not Adequate */}
													<i className={"icofont icofont-social-google-map  text-violet"} style={{ fontSize: "1.4rem" }}></i> &nbsp; &lt; 2 Hrs
													<span className="ml-10p mr-10p f14">|</span>
													<i className={"icofont icofont-social-google-map  text-brown"} style={{ fontSize: "1.4rem" }}></i> &nbsp; &gt; 2 Hrs &lt; 4 Hrs
													<span className="ml-10p mr-10p f14">|</span>
													<i className={"icofont icofont-social-google-map  text-grey"} style={{ fontSize: "1.4rem" }}></i> &nbsp; &gt; 4 Hrs &lt; 8 Hrs
													<span className="ml-10p mr-10p f14">|</span>
													<i className={"icofont icofont-social-google-map  text-orange"} style={{ fontSize: "1.4rem" }}></i>  &nbsp; &gt; 8 Hrs &lt; 16 Hrs
													<span className="ml-10p mr-10p f14">|</span>
													<i className={"icofont icofont-social-google-map  text-dark-pink"} style={{ fontSize: "1.4rem" }}></i> &nbsp; &gt; 16 Hrs &lt; 24 Hrs
													<span className="ml-10p mr-10p f14">|</span>
													<i className={"icofont icofont-social-google-map  text-dark"} style={{ fontSize: "1.4rem" }}></i> &nbsp; &gt;24 Hrs
													<span> ) </span>
												</>
											}			
										<span className="ml-10p mr-10p f14">|</span>
										<span>
												<i className="fa fa-tachometer txt-success" style={{ fontSize: "22px" }}></i>&nbsp;Overspeed
												
											</span>
											<span className="ml-10p mr-10p f14">|</span>
											<span><i className="icofont icofont-full-night txt-primary" style={{ fontSize: "22px" }}></i>&nbsp;Night Driving
											</span>
											<span className="ml-10p mr-10p f14">|</span>
										<i className="icofont icofont-pause maroon" style={{ fontSize: "22px	" }}></i>&nbsp;Enroute Stoppages
										<span className="ml-10p mr-10p f14">|</span>
										{/* {
											(this.props.match.path == "/sndallconsignments") ||
												(this.props.match.path == "/snddeliveredconsignments") ?
												<span><i className="icofont icofont-vehicle-delivery-van" style={{ fontSize: "22px" }}></i> - Unloading Delay</span> : ''}
										 */}
										<span><i className="icofont icofont-list" style={{ fontSize: "22px" }}></i>&nbsp;Day Wise Distance Trip
										</span>
										{/* <span className="ml-10p mr-10p f14">|</span> */}
																								
										{/* <span><img src={tollImg} alt="toll_img" style={{ width: "24px",height: '25px' }}></img> Tolls Details</span> */}
									</span>

								</div>
									:""}
									<div className="col-xl-12 mb-10p">
											{/* <button className="float-left custom-btn btn-warning" onClick={this.onClickShowMapView.bind(this)}>
												<i className="icofont icofont-social-google-map"></i> Map View
											</button> */}
										<span className="layoutbtns">


											{/* <button className="float-right custom-btn white btn-danger" onClick={this.onClickSaveGridState.bind(this)}>
													<i className="icofont icofont-save"></i> Save Grid Layout
												</button>
												<button className="float-right custom-btn white btn-info" style={{marginRight:"10px"}} onClick={this.resetState.bind(this)}>
													<i className="icofont icofont-refresh"></i> Reset Default Layout
												</button> */}
											{/*(this.props.match.path == "/prtconsignments")?
													<button type="button" className="float-right custom-btn btn-success" style={{marginRight:"10px"}} onClick={this.onShowUploadEWay}>
														Upload Eway Enquiry
													</button>
												:""}
												{(this.props.match.path == "/prtconsignments")?
													<div className="float-right">
													<button
														type="button"
														title="Bulk Upload"
														onClick={this.onBulkPriorityBtn}
														className={"float-right custom-btn white btn-danger mr-2 "+(this.state.hideTransporterBtns)}
														type="button">
														Bulk Mark Priority
													</button>
												</div>
											:""*/}
										</span>
									</div>
								</div>
								{/*
				   				   <Grid
								   ashow={this.state.show}
								   atype={this.state.basicType}
								   atitle={this.state.basicTitle}
								   actionCall={"manage"}
									actionMethod={"updateTruckRow"}
									table={"trucklocation"}
									columnyaxis={this.state.rowData}
									columnaxis={columnwithDefs}
									frameworkComponents={this.state.frameworkComponents}
									detailCellRendererParams={this.state.detailCellRendererParams}
									childconfs={this.state.childconfs}
									childrow={this.state.childrow}
									context={this }

									/>
									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
									</div>
								*/}

								<div id="myGrid" style={{ height: "580px", width: "100%" }} className="ag-theme-balham">
									<AgGridReact
										modules={this.state.modules}
										columnDefs={columnwithDefs}
										defaultColDef={this.state.defaultColDef}
										rowData={this.state.rowData}
										// onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
										enableCharts={true}
										enableRangeSelection={true}
										autoGroupColumnDef={this.state.autoGroupColumnDef}
										onGridReady={this.onGridReady}
										onGridState={this.onGridState}
										statusBar={this.state.statusBar}
										sideBar={this.state.sideBar}
										paginationPageSize={this.state.paginationPageSize}
										pagination={true}
										frameworkComponents={this.state.frameworkComponents}
										detailCellRendererParams={this.state.detailCellRendererParams}
										//editType={this.state.editType}
										stopEditingWhenGridLosesFocus={true}
										//floatingFilter={true}

										enableCellChangeFlash={false}
										//suppressCellFlash={true}
										rowClassRules={this.state.rowClassRules}
										onCellDoubleClicked={this.onRowClicked.bind(this)}
										gridOptions={{
											context: { componentParent: this }
										}}
										masterDetail={true}
										// getRowHeight={this.state.getRowHeight}
										onCellEditingStopped={this.onCellUpdateData.bind(this)}

										rowSelection={this.state.rowSelection}
										onRowSelected={this.onRowSelection.bind(this)}
										suppressRowClickSelection={true}
										// suppressCellSelection= {true}
										overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
										excelStyles={this.state.excelStyles}
										onCellClicked={this.onCellClicked.bind(this)}
									/>

									{/*
									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
									</div> */}

								</div>
							</div>
						</div>
					</div>

				</div>
				

				{this.state.sliderRouteTranslate != '' ?
					<div className={"slide-r " + (this.state.sliderRouteTranslate)} style={{ overflow: "auto" }}>
						<div className="slide-r-title">
							<h4>
								{(this.state.consignment_code)}
								<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onCloseRouteDiv.bind(this)} >X</span>

							</h4>
						</div>
						<div className="slide-r-body" style={{ position: "relative" }}>

							<div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
								<DrawMap
									context={this}
									rownode={this.state.rownode}
									mapFor={"consignment"}
									tolls={this.state.tolls}
									googleroutes={this.state.googelRoutes}
									truckno={this.state.maptruckno}
									dealer={this.state.dealer}
									mapinfo={this.state.mapinfo}
									defTransitCoords={this.state.defTransitCoords}
									dept_code={this.state.deptcode}
									devation_flag={this.state.devation_flag}
									baseRouteCoords={this.state.baseRouteCoords} />
								{/*
								<div id="map" className="" style={{width:'100%',height:"70vh"}}></div>
								*/}
								<div className="col-xl-12 col-lg-12 n-p-0">
									<div className="crm-numbers pb-0">
										<h3 className="subH">{this.state.sidebarSubHeader}</h3>
										<div className="col-xl-12 col-lg-12 row">
											<div className="col route-block">
												<label className="sidebar-label">Vin No</label>
												<div>{this.state.routeTruck.truck_no}</div>
											</div>
											{(maptransporter != '') ?
												<div className="col route-block">
													<label className="sidebar-label">Transporter</label>
													<div>{(!isEmpty(maptransporter)) ? maptransporter : ""}</div>
												</div>

												: ""}
											<div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(this.state.routeTruck.startTime != '') ? getDDMMYYYYHHMMSS(this.state.routeTruck.startTime) : "NA"}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">End Time</label>
												<div>{(this.state.routeTruck.endTime != '') ? getDDMMYYYYHHMMSS(this.state.routeTruck.endTime) : "NA"}</div>
											</div>

											<div className="col route-block">
												<label className="sidebar-label">GPS Distance</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details)) ? Math.round(this.state.mapinfo.route_details.distance_without_google / 1000) : "0"} Kms</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">Google Distance</label>
												<div>{Math.round(this.state.mapinfo.route_details.distance / 1000)} Kms</div>
											</div>

											{/* {(this.state.leg_no == 0 || this.state.leg_no == null) ? ""
												:
												<div className="col route-block">
													<label className="sidebar-label">Google Distance</label>
													<div>{(this.state.rownode.actual_google_leg_distance) ? parseInt(this.state.rownode.actual_google_leg_distance) : "0"} Kms</div>
												</div>
											} */}

											{/*<div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.start_time):"-"}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">End Time</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.end_time):"-"}</div>
											</div>
										*/}

										</div>
									</div>
								</div>
							</div>
						</div>

					</div>
					: ""
				}

				{this.state.sliderCommentTranslate != '' ?
					<div className={"slide-r " + (this.state.sliderCommentTranslate)} style={{ overflow: "auto" }}>
						<div className="slide-r-title">
							<h4>
								Consignment Comments
								<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onCloseRouteDiv.bind(this)} >X</span>
							</h4>
						</div>
						<div className="slide-r-body" style={{ position: "relative" }}>
							<div id="myGrid1" style={{ height: "580px", width: "100%" }} className="ag-theme-balham">
								<AgGridReact
									modules={this.state.modules}
									columnDefs={commentColumns}
									defaultColDef={this.state.defaultColDef}
									rowData={this.state.commentsRowData}
									onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
									enableCharts={true}
									enableRangeSelection={true}
									autoGroupColumnDef={this.state.autoGroupColumnDef}
									onGridReady={this.onGridReady}
									onGridState={this.onGridState}
									statusBar={this.state.statusBar}
									// sideBar={this.state.sideBar}
									paginationPageSize={this.state.paginationPageSize}
									pagination={true}

								/>



							</div>
						</div>

					</div>
					: ""
				}


				{this.state.sliderSegmentTranslate != '' ?
					<SegmentSideBar context={this} closeSegmentSideBar={this.closeSegmentSideBar} sliderValue={this.state.sliderSegmentTranslate} segmentConsignmentCode={this.state.segementConsignmentCode} onCloseRouteDiv={this.onCloseRouteDiv.bind(this)} />
					: ""
				}
				<div className={"dataLoadpage " + (this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " + (this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
				<div className={"overlay-part " + (this.state.overlyFixed)}></div>

				<Modal open={open} onClose={this.onCloseModal} classNames={{
					overlay: 'customOverlay',
					modal: 'customModal',
				}}>
					<div className={"row"} style={{ padding: '5px 0px', width: '100%', overflow: 'hidden', zIndex: '9999', }} >
						{(this.state.maprowData != '') ?
							<ConsignmentDrawMap
								context={this}
								griddata={this.state.maprowData}
								consigner_coordinates={this.state.consigner_coordinates}
								consigneecoords={this.state.consigneecoords}
								rownode={this.state.rownode} mapFor={"consignment"} />
							: ""}

					</div>
				</Modal>


				<Modal open={openforceclose} onClose={this.onCloseForceCloseModal} styles={modalStyles}>
					<div className={"row"} style={{ padding: '5px 0px', width: '100%', overflow: 'hidden', zIndex: '9999', }} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formForceCloseHandler.bind(this)}>
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Reached Dealer Time</label>

								<input type="text" placeholder="Reached Dealer " id="reached_dealer_time" className="datetimepicker_mask form-control" required />

							</div>

							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Left Dealer Time</label>

								<input type="text" placeholder="Left Dealer " id="left_dealer_time" className="datetimepicker_mask form-control" required />

							</div>

							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Reason for closure</label>
								<textarea name="reasonforceclose" id="reasonforceclose" onChange={this.changeHandler.bind(this)} rows="5" value={this.state.reasonforceclose} className="form-control col-xl-12 col-lg-12"></textarea>
							</div>
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>
					</div>
				</Modal>

				<div className={"slide-r " + (this.state.sliderForceCloseTranslate)} style={{ overflow: "auto" }}>
					<div className="slide-r-title">
						<h4>
							Force Closure
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>

						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>

						<div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', overflow: 'hidden', zIndex: '9999', height: "100em" }} >
							<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formForceCloseHandler.bind(this)}>
								{/* <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Reached Dealer Time</label>

                                <input readonly type="text" placeholder="Reached Dealer " value={this.state.startDatetime} id="reached_dealer_time" className="datetimepicker_mask form-control" />

							</div>

							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Left Dealer Time</label>

                                <input readonly type="text" placeholder="Left Dealer " value={this.state.endDatetime} id="left_dealer_time" className="datetimepicker_mask form-control" />

							</div> */}
								<ForceCloseSideBar context={this} handleInput={this.handleInput} deptcode={this.state.deptcode} />
								{/* <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Reason for closure</label>
								<textarea name="reasonforceclose" onChange={this.changeHandler.bind(this)} rows="5" value={this.state.reasonforceclose} className="form-control col-xl-12 col-lg-12" required></textarea>
							</div>
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div> */}
							</form>
						</div>
					</div>
				</div>

				<div className={"slide-r " + (this.state.sliderTranslatesidebar)} >
					{this.displayData}
				</div>

				{(this.state.showumncr == 1) ?
					<div>
						<span className="usermanual f22" onClick={this.onClickShowUserManaul.bind(this)}><i className="icofont icofont-read-book-alt"></i></span>
						<Modal open={usermanualncrmodal} onClose={this.onCloseUserManualModal.bind(this)} styles={modalStyles}>
							<div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
								<h5 className="model-title">NCR Consignments</h5>
								<div className="col-xl-12 col-lg-12 mt-20p">
									<div className="col-xl-12 col-lg-12">
										This screen is for SND department only. This screen shows those consignments which have cluster as NCR and originate from F,M plants. These are generally local movements happening. For these consignments there are no violation tickets generated except for No GPS data. The grid columns are similar to that of active consignment screen.
									</div>
									<div className="col-xl-12 col-lg-12">
										Image Here
									</div>
									<div className="col-xl-12 col-lg-12">
										The trips that are seen in this screen are active in system.
									</div>
									<div className="col-xl-12 col-lg-12">
										Save Grid Layout :  this filter is used to save the  interchanged columns.
									</div>
									<div className="col-xl-12 col-lg-12">
										Reset default layout :  this filter is used to reset the grid layout  to the default layout.
									</div>
								</div>
							</div>
						</Modal>
					</div>
					: ""}
				
				<div className={"slide-r " + (this.state.slideuploadeway)} style={{ overflow: "auto" }}>
					<div className="slide-r-title">
						<h4>
							Upload Eway Expiry
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>

						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>
						<div className="container-fluid">
							<form method="POST" id="upform" className="theme-form" onSubmit={this.uploadEway}>

								<div className="form-group mt-20p">
									<label className="">Upload File</label>
									<input type="file" name="uploadFile" onChange={this.changeFileHandler} className="form-control" required />
								</div>
								<div className="form-group">
									<button type="submit" className="btn btn-success">Submit</button>
									<button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
								</div>
							</form>

							<div className="form-group">
								<a className="btn btn-primary" href={require('../../assets/json/sample_upload_eway_expiry.csv')} target="_blank">Sample Template</a>

							</div>
						</div>
					</div>
				</div>

				<div className={"slide-r " + (this.state.bulkPrioritySlide)} style={{ overflow: "hidden" }}>
					<h3 className="subH">Bulk Priority</h3>

					<div className="slide-r-body" style={{ position: "relative" }}>

						<div className="container-fluid">
							<form method="POST" id="upform" className="theme-form" onSubmit={this.uploadBulkPriorityFormHandler.bind(this)}>

								<div className="form-group mt-20p">
									<label className="">Upload File</label>
									<input type="file" name="uploadFile" id="bulkUploadPID" onChange={this.changePriorityFileHandler} className="form-control" required />
								</div>
								<div className="form-group">
									<button type="submit" className="btn btn-success">Submit</button>
									<button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
								</div>
							</form>
							<div className="form-group">
								<a className="btn btn-primary" href={require('../../assets/json/bulk_couriers_mark_deliver_sample.csv')} target="_blank">Sample Template</a>
							</div>
							<div className="form-group">
								{/* <a className="btn btn-primary" href={require('../../assets/json/Bulk_tickets_update_file.csv')} target="_blank">Sample Template</a> */}

							</div>
						</div>
					</div>
				</div>


				<div className={"slide-r " + (this.state.sliderCreateConsignmentTranslate)} style={{ overflow: "auto" }}>
					<div className="slide-r-title">
						<h4>
							Add Consignment
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>

						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>

						<div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div>
						<div className={"row"} style={{ padding: '5px 0px', width: '100%', overflow: 'hidden', zIndex: '9999', height: "100em" }} >
							<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.saveConsignmentData.bind(this)}>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Consignment Code / Serial No :</label>
									<input
										type="text"
										className="form-control col-sm-12 ml-15p"
										onChange={this.changeConsignmentHandler}
										onBlur={this.onBlurConsignment.bind(this)}
										value={this.state.grno_consignment_code}
										name="grno_consignment_code"
									/>

									<div className={"row "+(this.state.shower)}>
										<div className="col-sm-12 f12 ml-15p" style={{color:"#ff0000"}}>Already Consignment code / Serial no exists</div>
									</div>
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Select Vin No :</label>

									<Select
										placeholder={"Select Vin No"}
										closeMenuOnSelect={true}
										onChange={this.onChangeTruckItem.bind(this)}
										className={"col-xl-12 col-lg-12 border-radius-0"}
										style={{ borderRadius: "0px" }}
										name="truck_no"
										value={this.state.truck_no}
										options={this.state.trucklist} />
								</div>
								
								{/* <div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Serial No :</label>
									<input
										type="text"
										className="form-control"
										onChange={this.changeHandler}
										value={this.state.invoice_no}
										name="invoice_no"
									/>
								</div> */}
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Select Plant :</label>

									<Select
										placeholder={"Select Plant"}
										closeMenuOnSelect={true}
										onChange={this.onChangePlantItem.bind(this)}
										className={"col-xl-12 col-lg-12 border-radius-0"}
										style={{ borderRadius: "0px" }}
										name="plant_code"
										value={this.state.plant_code}
										options={this.state.plantlist} />
								</div>
								<div className="form-group col-xl-12 col-lg-12">
										<label className="col-xl-12 col-lg-12">Transporter :  </label>
										<Select
											className="border-radius-0"
											isMulti={false}
											style={{ borderRadius: "0px" }}
											value={this.state.transporter}
											onChange={this.onChangeTransporter.bind(this)}
											options={this.state.transporterslist}
										/>
									</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Dealer :</label>

									<Select
										placeholder={"Select Dealer"}
										closeMenuOnSelect={true}
										onChange={this.onChangeConsigneeItem.bind(this)}
										className={"col-xl-12 col-lg-12 border-radius-0"}
										style={{ borderRadius: "0px" }}
										name="consignee_code"
										value={this.state.consignee_code}
										options={this.state.consignees} />
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Dealer Location :</label>
									<input
										type="text"
										className="form-control ml-15p"
										id="dealer_location"
										name="dealer_location"
										value={this.state.dealer_location}
										readOnly={true}
										style={{background:"#eee"}}
									/>
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Dealer City :</label>
									<input
										type="text"
										className="form-control ml-15p"
										id="dealer_city"
										name="dealer_city"
										value={this.state.dealer_city}
										readOnly={true}
										style={{background:"#eee"}}
									/>
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Parent Dealer :</label>

									<Select
										placeholder={"Select Parent Dealer"}
										closeMenuOnSelect={true}
										onChange={this.changeParentDealer.bind(this)}
										className={"col-xl-12 col-lg-12 border-radius-0"}
										style={{ borderRadius: "0px" }}
										name="parentdealer"
										value={this.state.parentdealer}
										options={this.parentdealerlist()} />
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Dispatch Time :</label>
									<input
										type="text"
										className="form-control datetimepicker_mask ml-15p"
										id="gate_out_time"
										name="gate_out_time"
									/>
								</div>
								
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Invoice Time :</label>
									<input
										type="text"
										className="form-control datetimepicker_mask ml-15p"
										id="invoice_date"
										name="invoice_date"
									/>
								</div>

								<div className="col-xl-12 col-lg-12 form-group">
									<button type="submit" className="btn btn-success">Submit</button>
								</div>
							</form>
						</div>
					</div>
				</div>
				

				<div className={"slide-r "+(this.state.sliderBulkUpdate)} style={{overflow:"auto"}}>
					<div className="slide-r-title">
						<h4>
							Bulk Upload - Consignments
							<span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
						</h4>
					</div>
					<div className="slide-r-body" style={{position:"relative"}}>

						<div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div>
						<div className={"row"} style={{padding:'5px 0px', width: '100%', overflow: 'hidden',zIndex:'9999',height:"100em"}} >
							<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.saveBulkConsignmentData}>
									<div className="col-xl-12 col-lg-12 row form-group">
										<label className="col-xl-12 col-lg-12">Upload File :</label>
										<input type="file"
											onChange={this.changeFileHandlerBulk}
											name="uploadFileBulk"
											className="form-control"
											 />
									</div>

									<div className="col-xl-12 col-lg-12 form-group">
										<button type="submit" className="btn btn-success">Submit</button>
									</div>
							</form>
						</div>
					</div>
				</div>

				<div className={"slide-r "+(this.state.sliderBulkDriverUpdate)} style={{overflow:"auto"}}>
					<div className="slide-r-title">
						<h4>
							Bulk Upload - Drivers List
							<span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
						</h4>
					</div>
					<div className="slide-r-body" style={{position:"relative"}}>

						<div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div>
						<div className={"row"} style={{padding:'5px 0px', width: '100%', overflow: 'hidden',zIndex:'9999',height:"100em"}} >
							<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.uploadDriverBulk.bind(this)}>
									<div className="col-xl-12 col-lg-12 row form-group">
										<label className="col-xl-12 col-lg-12">Upload File :</label>
										<input type="file"
											onChange={this.changeDriverFileHandler}
											name="uploadDriverBulk"
											className="form-control"
											 />
									</div>

									<div className="col-xl-12 col-lg-12 form-group">
										<button type="submit" className="btn btn-success">Submit</button>
									</div>
									
									<div className="col-xl-12 col-lg-12 form-group">
										<a href={require("../../assets/json/bulk_drivers_list.csv")} target="_blank" className="btn btn-danger">Download Sample Template</a>
									</div>
							</form>
						</div>
					</div>
				</div>





				<div className={"slide-r " + (this.state.sliderTPTRemarkTranslate)} style={{ overflow: "auto" }}>
					<div className="slide-r-title">
						<h4>
							Transporter Remarks
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>

						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>

						<div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', overflow: 'hidden', zIndex: '9999', height: "100em" }} >
							<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formTPTRemarks.bind(this)}>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Remarks</label>
									<textarea name="tpt_remarks" onChange={this.changeHandler.bind(this)} rows="5" value={this.state.tpt_remarks} className="form-control col-xl-12 col-lg-12" required></textarea>
								</div>
								<div className="col-xl-12 col-lg-12 form-group">
									<button type="submit" className="btn btn-success">Submit</button>
								</div>
							</form>
						</div>
					</div>
				</div>
				
				<div className={"slide-r " + (this.state.sliderTPTDeviationTranslate)} style={{ overflow: "auto" }}>
					<div className="slide-r-title">
						<h4>
							Update Deviation Information
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickCancelDeviationProcess.bind(this)} >X</span>

						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>

						<div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', overflow: 'hidden', zIndex: '9999', height: "100em" }} >
							<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formTPTDeviation.bind(this)}>
								
								{/* <div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Deviation Location 
									</label>
									<Select
										name="deviation" 
										onChange={this.changeDeviationHandler.bind(this)} 
										value={this.state.deviation} 
										className="col-xl-12 col-lg-12" 
										options={this.state.deviations}
										// required 
										/>
								</div> */}
								<div className="form-group row col-sm-12">
									<label className="col-sm-12">Delivered at</label>
									<div className="col-sm-12">
										<Select 
											name="tpt_deviation_delivered_at" 
											className="" 
											value={this.state.tpt_deviation_delivered_at} 
											onChange={this.changeDeviationDeliverLocation.bind(this)}
											options={[{value:"At Dealer",label:"At Dealer"}, {value:"At Dealer Yard",label:"At Dealer Yard"},{value:"At Body Shop",label:"At Body Shop"},{value:"others",label:"Others"}]}	
										/>
									</div>
								</div>
								<div className={"col-lg-12 row form-group " +(this.state.showDealerDeviate)}>
									<label className="col-xl-12 col-lg-12">Ship to Party 
									</label>
									<Select
										name="ship_to_party" 
										onChange={this.changeShipToPartyHandler.bind(this)} 
										value={this.state.ship_to_party} 
										className="col-xl-12 col-lg-12" 
										options={this.state.dealers}
										/>
								</div>
								
								<div className={this.state.showDealerDeviateother}>
									<div className={"col-lg-12 row form-group " }>
										<label className="col-xl-12 col-lg-12">Ship to Party Code 
										</label>
											<input type="text" name="ship_to_party_code" onChange={this.changeHandler.bind(this)} value={this.state.ship_to_party_code} className="form-control" autoComplete='off'  />
									</div>
									
									<div className={"col-lg-12 row form-group " }>
										<label className="col-xl-12 col-lg-12">Ship to Party Name
										</label>
											<input type="text" name="ship_to_party_name" onChange={this.changeHandler.bind(this)} value={this.state.ship_to_party_name} className="form-control" autoComplete='off'  />
									</div>
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Deviation Location <span style={{color:"#ff0000",fontSize:"20px"}}>*</span></label>
									<div className="col-xl-12">
										<input type="text" name="tpt_deviation_location" onChange={this.changeHandler.bind(this)} value={this.state.tpt_deviation_location} className="form-control " autoComplete='off' required />
									</div>
								</div>

								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">City <span style={{color:"#ff0000",fontSize:"20px"}}>*</span></label>
									<div className="col-xl-12">
										<input type="text" name="tpt_deviation_city" onChange={this.changeHandler.bind(this)} value={this.state.tpt_deviation_city} className="form-control" autoComplete='off' required />
									</div>
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Address <span style={{color:"#ff0000",fontSize:"20px"}}>*</span></label>
									<div className="col-xl-12">
										<input type="text" name="tpt_deviation_address" onChange={this.changeHandler.bind(this)} value={this.state.tpt_deviation_address} className="form-control " autoComplete='off' required />
									</div>
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Zone <span style={{color:"#ff0000",fontSize:"20px"}}>*</span></label>
									<div className="col-xl-12">
										{/* <input type="text" name="tpt_deviation_zone" onChange={this.changeHandler.bind(this)} value={this.state.tpt_deviation_zone} className="form-control" autoComplete='off' required /> */}
										<Select
											className="border-radius-0"
											isMulti={false}
											id="tpt_deviation_address"
											style={{ borderRadius: "0px" }}
											value={{value:this.state.tpt_deviation_zone,label:this.state.tpt_deviation_zone}}
											onChange={this.onChangeTPTDvZone.bind(this)}
											options={this.state.TPTdeviationZones}
										/>
									</div>
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Postal Code <span style={{color:"#ff0000",fontSize:"20px"}}>*</span></label>
									<div className="col-xl-12">
										<input type="text" name="tpt_deviation_postal_code" onChange={this.changeNumberHandler.bind(this)} value={this.state.tpt_deviation_postal_code} className="form-control" autoComplete='off' required />
									</div>
								</div>
								
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Contact Number <span style={{color:"#ff0000",fontSize:"20px"}}>*</span></label>
									<div className="col-xl-12">
										<input type="text" name="tpt_deviation_contact_number" onChange={this.handleChange.bind(this)} value={this.state.tpt_deviation_contact_number} className="form-control" autoComplete='off' required />
									</div>
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Approved Deviation Number <span style={{color:"#ff0000",fontSize:"20px"}}>*</span></label>
									<div className="col-xl-12">
										<input type="text" name="tpt_approved_deviation_no" onChange={this.changeHandler.bind(this)} value={this.state.tpt_approved_deviation_no} className="form-control " autoComplete='off' required />
									</div>
								</div>
								<div className="col-xl-12 col-lg-12 form-group">
									<button type="submit" className="btn btn-success">Submit</button>
								</div>
							</form>
						</div>
					</div>
				</div>
				<div className={"slide-r " + (this.state.sliderTPTAccidentTranslate)} style={{ overflow: "auto" }}>
					<div className="slide-r-title">
						<h4>
							Update Accident Information
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickCancelAccidentProcess.bind(this)} >X</span>

						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>

						<div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', overflowY: 'auto', zIndex: '9999', height: "100em" }} >
							<form method="POST" className="theme-form col-xl-12 col-lg-12" id="formAcciednt" onSubmit={this.formTPTAccident}>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Damage to our vehicle</label>
									<input type="text" name="tpt_accident_damage_vehicle" onChange={this.changeHandler.bind(this)} value={this.state.tpt_accident_damage_vehicle} className="form-control col-xl-12 col-lg-12" required />
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Accident Scenario</label>
									<input type="text" name="tpt_accident_scenario" onChange={this.changeHandler.bind(this)} value={this.state.tpt_accident_scenario} className="form-control col-xl-12 col-lg-12" required />
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Any third Party damage</label>
									<input type="text" name="tpt_accident_third_party_damage" onChange={this.changeHandler.bind(this)} value={this.state.tpt_accident_third_party_damage} className="form-control col-xl-12 col-lg-12" required />
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Vehicle Current Status</label>
									<input type="text" name="tpt_accident_vehicle_current_status" onChange={this.changeHandler.bind(this)} value={this.state.tpt_accident_vehicle_current_status} className="form-control col-xl-12 col-lg-12" required />
								</div>
								
								<div className="col-xl-14 col-lg-14 row form-group" style={{marginLeft:"-1em",width:"100%"}}>
									<label className="col-xl-12 col-lg-12" style={{marginLeft:"10px"}}>A3 Report submission</label>
									<Select
										name="tpt_accident_a3_report_submission" 
										onChange={this.changeA3Report.bind(this)} 
										value={this.state.tpt_accident_a3_report_submission || null} 
										options={[{value:"No",label:"No"},{value:"Yes",label:"Yes"}]}
										className="col-xl-12 col-lg-12" 
										required 
										styles={{
											control: provided => ({
											  ...provided,
											  margin: 0, // Reset margin
											  padding: 0, // Reset padding
											}),
											menu: provided => ({
											  ...provided,
											  marginTop: 0, // Reset margin-top for the dropdown menu
											}),
										  }}
									/>
								</div>

								<div className="col-xl-14 col-lg-14 row form-group" style={{marginLeft:"-1em",width:"100%"}}>
									<label className="col-xl-12 col-lg-12" style={{marginLeft:"10px"}}>FIR</label>
									<Select
										name="tpt_accident_fir_status" 
										onChange={this.changeFIR.bind(this)} 
										value={this.state.tpt_accident_fir_status} 
										options={[{value:"No",label:"No"},{value:"Yes",label:"Yes"}]}
										className="col-xl-12 col-lg-12" 
										required 
									/>
								</div>
								<div className={"col-xl-12 col-lg-12 row form-group " + (this.state.showfirfield)}>
									<label className="col-xl-12 col-lg-12">FIR Date</label>
									<Datetime
										type="text"
										value={this.state.tpt_accident_fir_date}
										disableCloseOnClickOutside={false}
										closeOnSelect={true}
										dateFormat="YYYY-MM-DD"
										timeFormat={false}
										autoComplete='Off'
										onChange={this.handleFirDateChange} // Add this line
									/>
								</div>
								 {/* <div className={"col-xl-12 col-lg-12 row form-group "+(this.state.showfirfield)}>
											<label>FIR Date</label>
										
											<Datetime
												value={this.state.endDate}
												disableCloseOnClickOutside={false}
												closeOnSelect={true}
												inputProps={{ placeholder: 'To', name: 'endDate', autoComplete: 'off', readOnly: true }}
												dateFormat="YYYY-MM-DD"
												timeFormat={false}
												onChange={this.handlerEndDateTime.bind(this)} />
										</div>  */}
								<div className={"col-xl-12 col-lg-12 row form-group "}>
									<ul className="col-sm-12">
									{this.state.accidentDocuments.length > 0?
										(this.state.accidentDocuments.map((itm,idx) =>
										<li style={{borderBottom:"1px solid #ccc"}}>
											<a href={itm} target="_blank" className="btn btn-default">View Document - {idx+1}</a>
										</li>
										))
									:""}
									</ul>
								</div>
								<div className={"col-xl-12 col-lg-12 row form-group "}>
									<label className="col-xl-12 col-lg-12">Upload Documents</label>
									<input type="file" name="uploadDocs" multiple="multiple" onChange={this.changeDocumentHandler.bind(this)} className="form-control col-xl-12 col-lg-12"  />
								</div>
								<div className="col-xl-12 col-lg-12 form-group">
									<button type="submit" className="btn btn-success">Submit</button>
								</div>
							</form>
						</div>
					</div>
				</div>

				
				<div className={"slide-r " + (this.state.sliderTPTAccidentDocuments)} style={{ overflow: "auto" }}>
					<div className="slide-r-title">
						<h4>
							View Documents
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickCancelAccidentDocuments.bind(this)} >X</span>

						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>

						<div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', overflow: 'hidden', zIndex: '9999', height: "100em" }} >
							<ul className="col-sm-12">
							{this.state.accidentDocuments.length > 0?
								(this.state.accidentDocuments.map((itm,idx) =>
								<li style={{borderBottom:"1px solid #ccc"}}>
									<a href={itm} target="_blank" className="btn btn-default">View Document - {idx+1}</a>
								</li>
								))
							:""}
							</ul>
						</div>
					</div>
				</div>
				
				
				<div className={"slide-r " + (this.state.sliderTPTConfirmDeliver)} style={{ overflow: "auto" }}>
					<div className="slide-r-title">
						<h4>
							Confirm Delivered
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>

						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>

						<div className="row"></div><div className={"row"}>
							
							<form onSubmit={this.formConfirmDeliver.bind(this)} className="theme-form col-xl-12 col-lg-12">
								<div className="form-group col-sm-12">
									<label className="col-sm-12">Delivered at</label>
									<Select 
										name="tpt_confirm_delivered_location" 
										className="" 
										value={this.state.tpt_confirm_delivered_location} 
										onChange={this.changeDeliverLocation.bind(this)}
										options={[{value:"At Dealer",label:"At Dealer"}, {value:"At Dealer Yard",label:"At Dealer Yard"},{value:"At Body Shop",label:"At Body Shop"},{value:"Deviated location",label:"Deviated location"},,{value:"Accident",label:"Accident"},,{value:"Break down",label:"Break down"},{value:"other",label:"Others"}]}	
									/>
								</div>
								<div className={"form-group col-sm-12 "+(this.state.otherinp)}>
									<label className="col-sm-12">Other Location</label>
									<input type="text" name="tpt_confirm_delivered_other_location" className="form-control col-sm-12" value={this.state.tpt_confirm_delivered_other_location} onChange={this.changeHandler.bind(this)} />
								</div>
								<div className="form-group col-sm-12">
									<label className="col-sm-12">Remarks</label>
									<textarea rows="5" name="tpt_confirm_deliver_remarks" className="form-control col-sm-12" value={this.state.tpt_confirm_deliver_remarks} onChange={this.changeHandler.bind(this)}></textarea>
								</div>
								<div className="form-group">
									<button type="submit" className="btn btn-success">Submit</button>
								</div>

							</form>
						</div>
					</div>
				</div>
				<div className={"slide-r " + (this.state.sliderTPTDriverTranslate)} style={{ overflow: "auto" }}>
					<div className="slide-r-title">
						<h4>
							Driver Information
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>

						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>

						<div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', overflow: 'hidden', zIndex: '9999', height: "100em" }} >
							<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formTPTDriver.bind(this)}>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Driver Name</label>
									<input type="text" name="tpt_driver_name" onChange={this.changeHandler.bind(this)} value={this.state.tpt_driver_name} className="form-control col-xl-12 col-lg-12" required />
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Driver Mobile Number</label>
									<input type="text" name="tpt_driver_mobile"  onChange={this.changeMobileHandler.bind(this)} value={this.state.tpt_driver_mobile}  className="form-control col-xl-12 col-lg-12" required />  {this.state.tpt_driver_mobile && !this.state.isValidMobile &&  (
                                   <div style={{ color: 'red' }}>Invalid mobile number. Please enter a valid 10-digit number.</div>
                                    )}
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Convoy Leader Name</label>
									<input type="text" name="tpt_convoy_leader_name" onChange={this.changeHandler.bind(this)} value={this.state.tpt_convoy_leader_name} className="form-control col-xl-12 col-lg-12" required />
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Convoy Leader Mobile Number</label>
									<input type="text" name="tpt_convoy_leader_mobile" onChange={this.changeLeaderMobileHandler.bind(this)} value={this.state.tpt_convoy_leader_mobile} className="form-control col-xl-12 col-lg-12" required /> {this.state.tpt_convoy_leader_mobile && !this.state.isValidMobile && (
                                    <div style={{ color: 'red' }}>Invalid mobile number. Please enter a valid 10-digit number without special characters.</div>
                                     )}
								</div>
								<div className="col-xl-12 col-lg-12 form-group">
									<button type="submit" className="btn btn-success">Submit</button>
								</div>
							</form>
						</div>
					</div>
				</div>

				
				<div className={"slide-r " + (this.state.sliderTPTBreakdownTranslate)} style={{ overflow: "auto" }}>
					<div className="slide-r-title">
						<h4>
							Update Breakdown Information
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>

						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>

						<div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', overflow: 'hidden', zIndex: '9999', height: "100em" }} >
							<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formTPTBreakDown.bind(this)}>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Location</label>
									<input type="text" name="tpt_break_down_location" onChange={this.changeHandler.bind(this)} value={this.state.tpt_break_down_location} className="form-control col-xl-12 col-lg-12" required />
								</div>
								<div className="col-xl-14 col-lg-14 form-group" style={{marginLeft:"-1em" ,width:"100%"}}>
									<label className="col-xl-12 col-lg-12" style={{marginLeft:"15px"}}>Dealer</label>
									<Select
										className="col-sm-12 border-radius-0"
										isMulti={false}
										id="selectedDealer"
										style={{ borderRadius: "0px" }}
										value={this.state.tpt_break_down_consignee_code}
										onChange={this.newFilter.bind(this, "tpt_break_down_consignee_code")}
										options={this.state.filterDealers}
									/>
								</div>
								<div className="col-xl-12 col-lg-12 form-group">
									<button type="submit" className="btn btn-success">Submit</button>
								</div>
							</form>
						</div>
					</div>
				</div>


			</div>

		);
	}
}



// function loadScript(url){
//     var index  =window.document.getElementsByTagName("script")[0]
//     var script = window.document.createElement("script")
//     script.src=url
//     script.async=true
//     script.defer = true
//     index.parentNode.insertBefore(script,index)
// }
function isEmpty(obj) {
	for (var key in obj) {
		if (obj.hasOwnProperty(key))
			return false;
	}
	return true;
}
function timeConvert(n) {
	// var num = n/1000;
	// var hours = (num / (3600*24));
	// var rhours = Math.floor(hours);
	// var minutes = (n) / (60*60);
	// var rminutes = Math.round(minutes);

	var diffMs = n;
	var diffDays = Math.floor(diffMs / 86400000); // days
	var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
	var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes


	return diffHrs + " hour(s) and " + (diffMins / 60) + " minute(s).";
}
var currentinfowindow = null;
function getTimeInfo(marker, timedet, coords) {
	var timeinfo = new window.google.maps.InfoWindow({
		content: "Time at this location: " + timedet
	});

	marker.addListener('click', function () {

		if (currentinfowindow != null) {
			currentinfowindow.close();
			currentinfowindow = timeinfo;
			timeinfo.open(marker.get('map'), marker);
		}
		else {
			currentinfowindow = timeinfo;
			timeinfo.open(marker.get('map'), marker);
		}

	});
}



function secondsToString(seconds) {
	var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}

function secondsToDays(seconds) {
	var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numdays;
}
function CheckForHtmlTag() {
	var rs = document.getElementById("invalid_reason");
	var reg = /<(.|\n)*?>/g;
	if (reg.test(rs.value)) {
		var ErrorText = "Oops! HTML or Script is not allowed.";
		rs.value = ''
		//alert();
		return 1;
	}
}
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
		// console.log(date1,date2);
		var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
		date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
		var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
		date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
		// console.log(date1,date2);
		var date1Number = monthToComparableNumber(date1);
		var date2Number = monthToComparableNumber(date2);
		// console.log(date1Number,date2Number);
		if (date1Number === null && date2Number === null) {
			return 0;
		}
		if (date1Number === null) {
			return -1;
		}
		if (date2Number === null) {
			return 1;
		}
		return date1Number - date2Number;
	}
}
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	//console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName) {
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
	];
	var monthNum = months.indexOf(monthName) + 1;
	if (monthNum < 10) {
		monthNum = "0" + monthNum.toString();
	}
	return monthNum;
}

function loadDateTimeScript() {
	$('.datepicker').datepicker({
		dateFormat:"dd-mm-yy"
	});
	$('#reached_dealer_time').datetimepicker({
		mask: '39-19-9999 29:59',
		format: 'd-m-Y H:i',
		onShow: false
	});
	$('#left_dealer_time').datetimepicker({
		mask: '39-19-9999 29:59',
		format: 'd-m-Y H:i',
		onShow: false
	});
	$('.datetimepicker_date').datetimepicker({
		mask: '39-19-9999',
		format: 'd-m-Y',
		timepicker: false,
		onShow: false
	});
	$('#invoice_date').datetimepicker({
		mask: '39-19-9999 29:59',
		format: 'd-m-Y H:i'
	});
	$('#gate_out_time').datetimepicker({
		mask: '39-19-9999 29:59',
		format: 'd-m-Y H:i'
	});

	var index = window.document.getElementsByTagName("script")[1]
	var script = window.document.createElement("script")
	script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async = true
	script.defer = true
	index.parentNode.insertBefore(script, index)
}


function GetSortDescOrder(prop) {
	return function (a, b) {
		if (a[prop] < b[prop]) {
			return 1;
		} else if (a[prop] > b[prop]) {
			return -1;
		}
		return 0;
	}
}


function GetSortAscOrder(prop) {
	return function (a, b) {
		if (a[prop] > b[prop]) {
			return 1;
		} else if (a[prop] < b[prop]) {
			return -1;
		}
		return 0;
	}
}

function secondsToDhms(seconds) {
	seconds = Number(seconds);
	var d = Math.floor(seconds / (3600 * 24));
	var h = Math.floor((seconds % (3600 * 24)) / 3600);
	var m = Math.floor((seconds % 3600) / 60);
	var s = Math.floor(seconds % 60);

	var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
	var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
	var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
	var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
	return dDisplay + hDisplay + mDisplay;
}

function secondsToMinutesSeconds(seconds) {
	// var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numhours + " hours " + numminutes + " minutes " + numseconds + ' seconds';
}