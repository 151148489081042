import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const CommentItem = (props) => {
    const handleEventClick = (e) => {
        e.stopPropagation();
        //console.log("Overspeed ",props);
        console.log(props.data.comment,"111")
        // props.context.componentParent.onClickEditForm(props);
    };
    
   
            var colr = 'grayfont'
       
    //}
    return (
        <div>
            {props.data.comment =="" ?
            <button onClick={handleEventClick} className="custom-btn f11 label label-success" 
            title="Edit" ><i className={"f11 icofont icofont-pencil "}></i> &nbsp;Comment</button>
           :props.data.comment}
            
        </div>
    );
};

export default CommentItem;