
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const MapUnmapAction = (props) => {
    const handleClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.unMapDevice(props.data);
    };
    return (
        <div className="map-icon-component">
            {(props.data.truck_no != "" && props.data.truck_no != undefined)?
                <button onClick={handleClick} className="btn btn-danger" style={{paddingLeft:"1em",paddingRight:"1em",paddingTop:"0em"}}> &nbsp;Unmap </button> 
            :""
            }
        </div>
    );
};

export default MapUnmapAction;
