
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const UserActiveAction = (props) => {
    const handleActiveEventClick = (e) => {
        e.stopPropagation();
        //console.log("Overspeed ",props);
        props.context.componentParent.onClickActiveUser(props);
    };
    const handleDisableEventClick = (e) => {
        e.stopPropagation();
        //console.log("Overspeed ",props);
        props.context.componentParent.onClickDisableUser(props);
    };
    
   
    return (
        <div>
            {(props.data.verified == 1)?
                <button onClick={handleDisableEventClick} className="custom-btn label label-success" style={{cursor:"pointer",background:"#AFE1AF"}} 
            title="Make InActive" ><i className={"icofont icofont-ui-press "}></i> Make Inactive</button>
            :
            <button onClick={handleActiveEventClick} className="custom-btn label label-success"  style={{cursor:"pointer",background:"#FAA0A0"}}
            title="Make Active" ><i className={"icofont icofont-not-allowed "}></i> Make Active</button>
            
            }
            
        </div>
    );
};

export default UserActiveAction;
