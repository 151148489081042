
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');
var moment = require('moment');

 
const WithDefectsItems = (props) => {
    const handleClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onCLickWithDefectsLoadInvoicItems(props);
    };
    // console.log("props.data.customer_lr_doc ", props.data)
    return (
        <span>
            <a href="javascript:;"  className="label label-warning f12" style={{color:"#333",padding:"2px 10px"}} onClick={handleClick}>With Defects</a>
        </span>
    );
};

export default WithDefectsItems;
