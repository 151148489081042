import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import 'react-datetime/css/react-datetime.css'
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getHyphenDDMMMYYYYHHMM, getHyphenDDMMMYYYY ,convertSecondsToHHMM} from '../common/utils';

var redirectURL = require('../redirectURL');
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");
export default class DeviceLocationHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventGridAction: "gridAction",
            eventCounterAction: "counterAction",
            eventFormAction: "formAction",
            loadshow: 'show-n',
            showDiv: 'show-n',
            overly: 'show-n',
            alert: null,
            show: false,
            basicTitle: '',
            basicType: "default",
            modules: AllModules,
            sidebarSubHeader: '',
            defaultColDef: {
                sortable: true,
                filter: true,
                resizable: true

            },
            rowData: [],
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            overlayNoRowsTemplate: "",
            frameworkComponents: {
            },
            animateRows: true,
            debug: true,
            showToolPanel: false,
            uppressAggFuncInHeader: true,

            childconfs: [],
            childrow: [],
            rowModelType: "serverSide",
            paginationPageSize: 50,
            cacheBlockSize: 10,
            maxBlocksInCache: 1,
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    }                    
                ]
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            detailCellRendererParams: {},
            rowClassRules: {

            },
            email: localStorage.getItem('email') || '',
            devicesList: [],
            selectedDevice: [{ value: "all", label: "All" }]

        }
        this.onLoadGetShipments = this.onLoadGetShipments.bind(this);
        this.onClickSubmit = this.onClickSubmit.bind(this);
    }
    componentDidMount = async () => {
        var params
        try {
            const response = await redirectURL.post("/masters/getDevicesList");
            const devicesInfo = response.data.deviceList || [];
           const filteredDeviceInfo= devicesInfo.filter(item=>![null,"",undefined,"undefined"].includes(item))
            // console.log(filteredDeviceInfo, "devices info");
            this.setState({ devicesList: filteredDeviceInfo ,
                selectedDevice:[{ value:filteredDeviceInfo[0] , label: filteredDeviceInfo[0]}]
            });
            var params = {devicesList:[filteredDeviceInfo[0]]}

        } catch (error) {
            console.error("Error fetching devices list:", error);
        }
        this.getUserGridState()
        this.onLoadGetShipments(params);
    }

    getUserGridState() {
        redirectURL.post("/consignments/usergridstates", { userId: localStorage.getItem("userid"), screenurl: window.location.pathname })
            .then(async (resp) => {
                // console.log("resp ", resp)
                this.setState({
                    usergridstate: resp.data
                });
                this.restoreGridStates();
            })
    }

    restoreGridStates() {
        ///console.log("this.state.usergridstate ", this.state.usergridstate)
        try {
            if (this.state.usergridstate.length > 0) {
                var windowstates = this.state.usergridstate;
                // console.log("windowstates[0].gridcolumns ", windowstates[0].gridcolumns)
                this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
                this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
                this.gridApi.setSortModel(windowstates[0].gridcolsort);
                this.gridApi.setFilterModel(windowstates[0].gridcolfilter);

            }
        }
        catch (e) { }
    }

    onLoadGetShipments = async (params) => {
        this.setState({ loadshow: "show-m"  });
        try {
            var param = {}
            if(params){
                var param = params
            }
            // console.log("params",param)
            const response = await redirectURL.post("/masters/getDevicesHistory",param) || [];
            const responseData = Array.isArray(response.data)? response.data : []
            this.setState({
                rowData : responseData,
                loadshow:"show-n"
            })
            // console.log(response,"response ")
        } catch (error) {
            console.error("Error fetching devices list:", error);
        }
    }

    onClickSubmit = async (event)=>{
        event.preventDefault()
        const selectedDeviceValues = this.state.selectedDevice ? this.state.selectedDevice.map(device => device.value) : [];
        var selectedValues = selectedDeviceValues.some(item=>["all","All","ALL"].includes(item)) ? [] : selectedDeviceValues
        var params = {devicesList : selectedValues}
        this.onLoadGetShipments(params);
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    };
    onClickSaveGridState() {
        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pagetitle;
            let eventOptions = {
                "category": "alltrips",
                "action": this.state.eventGridAction,
                "label": "Save Grid Layout",
            }
            googleAnalytics.logEvent(eventOptions);
        }
        try {
            window.colState = this.gridColumnApi.getColumnState();
            window.groupState = this.gridColumnApi.getColumnGroupState();
            window.sortState = this.gridApi.getSortModel();
            window.filterState = this.gridApi.getFilterModel();
            let screenpage = 'deviceLatLngHistory';
            // console.log(window.colState,"window.colState")
            let reqparams = {
                gridcolumns: window.colState,
                gridgroup: window.groupState,
                gridcolsort: window.sortState,
                gridcolfilter: window.filterState,
                userId: localStorage.getItem("userid"),
                screenurl: window.location.pathname,
                screentitle: "deviceLatLngHistory"
            }
            redirectURL.post("/consignments/saveGridStates", reqparams)
                .then((response) => {
                    this.setState({
                        show: true,
                        basicTitle: "Successfully saved grid layout",
                        basicType: "success",
                        screenurl: window.location.pathname,
                        screentitle: screenpage
                    })
                })
                .catch(function (e) {
                    console.log("Error ", e)
                })
        }
        catch (e) { }
    }
    resetState = () => {

        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pagetitle;
            let eventOptions = {
                "category": pageTitle,
                "action": this.state.eventGridAction,
                "label": "Reset Grid Layout",
            }
            googleAnalytics.logEvent(eventOptions);
        }
        try {

            this.gridColumnApi.resetColumnState();
            this.gridColumnApi.resetColumnGroupState();
            this.gridApi.setSortModel(null);
            this.gridApi.setFilterModel(null);
            //console.log('column state reset');
            var reqparams = {
                userId: localStorage.getItem("userid"),
                screenurl: window.location.pathname,
                screentitle: this.state.screenpage
            }

            redirectURL.post("/consignments/removeUserGridState", reqparams)
                .then((response) => {
                    this.setState({
                        show: true,
                        basicTitle: "Successfully reset default grid layout",
                        basicType: "success"
                    })
                })
        }
        catch (e) { }

    };
    onGridState = () => {
        this.colState = this.gridColumnApi.getColumnState();
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }
    onClickHideAll() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            sliderRouteTranslate: '',
            tabsliderTranslate: '',
            sliderAddTransporter: "",
            sliderEmailAllDeviceHistory: '',
            sliderTranslateBulk: "",
            uploaddeviceLogsBulk: [],
            invalid_devices: ''
        });
    }
    onClickHide() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            sliderAddTransporter: '',
            sliderEditTransporter: "",
            sliderEmailAllDeviceHistory: '',
            sliderTranslateBulk: "",
            uploaddeviceLogsBulk: [],
            invalid_devices: '',
        });

    }
    closeAlert = () => {
        this.setState({
            show: false
        });

    }

    selectConsignerOptionsItems() {
        const { devicesList } = this.state; // Destructure the state for cleaner code
        const items = [{ value: 'all', label: 'All' }]; // Initialize with 'All' option    
        if (devicesList && devicesList.length > 0) {
            devicesList.forEach(item => {
                if (item) { // Check for non-empty and non-null value
                    items.push({ value: item, label: item });
                }
            });
        }
        return items;
    }

    render() {
        var columnwithDefs = [
            {
                headerName: "Device ID",
                field: "device_id",
                width: 150,
                valueGetter: function (params) {
                    return params.data.device_id || '';
                }
            },
            {
                headerName: "Latitude",
                field: "latitude",
                width: 130,
                valueGetter: function (params) {
                    return params.data.latitude || '';
                }
            },
            {
                headerName: "Longitude",
                field: "longitude",
                width: 130,
                valueGetter: function (params) {
                    return params.data.longitude || '';
                }
            },
            {
                headerName: "Last Packet Time",
                field: "last_packet",
                width: 150,
                valueGetter: function (params) {
                    try {
                        
                        if (params.data.last_packet) {
                            const timestampInSeconds = params.data.last_packet; // Unix timestamp in seconds
                            if (isNaN(timestampInSeconds)) {
                                return null; // Handle cases where the timestamp is invalid
                            }
                            
                            const date = new Date(timestampInSeconds * 1000); // Convert seconds to milliseconds
                            
                            if (isNaN(date.getTime())) {
                                return null; // If the date conversion fails
                            }
                
                            // Return the Date object
                            return date;
                        }
                    } catch (e) {
                        return '';
                    }
                }
            },
            {
                headerName: "Dealer Code",
                field: "dealer_code",
                width: 150,
                valueGetter: function (params) {
                    return params.data.dealer_code || '';
                }
            },
            {
                headerName: "Dealer Name",
                field: "dealer_name",
                width: 180,
                valueGetter: function (params) {
                    return params.data.dealer_name || '';
                }
            },
            {
                headerName: "Area",
                field: "address.area",
                width: 150,
                valueGetter: function (params) {
                    return params.data.address && params.data.address.area ? params.data.address.area : '';
                }
            },
            {
                headerName: "City",
                field: "address.city",
                width: 150,
                valueGetter: function (params) {
                    return params.data.address && params.data.address.city ? params.data.address.city : '';
                }
            },
            {
                headerName: "District",
                field: "address.district",
                width: 150,
                valueGetter: function (params) {
                    return params.data.address && params.data.address.district ? params.data.address.district : '';
                }
            },
            {
                headerName: "Address",
                field: "address.formatted_address",
                width: 250,
                valueGetter: function (params) {
                    return params.data.address && params.data.address.formatted_address ? params.data.address.formatted_address : '';
                }
            },
            {
                headerName: "Pincode",
                field: "address.pincode",
                width: 130,
                valueGetter: function (params) {
                    return params.data.address && params.data.address.pincode ? params.data.address.pincode : '';
                }
            },
            {
                headerName: "State",
                field: "address.state",
                width: 130,
                valueGetter: function (params) {
                    return params.data.address && params.data.address.state ? params.data.address.state : '';
                }
            },
            {
                headerName: "SubDistrict",
                field: "address.subDistrict",
                width: 150,
                valueGetter: function (params) {
                    return params.data.address && params.data.address.subDistrict ? params.data.address.subDistrict : '';
                }
            },
            {
                headerName: "Village",
                field: "address.village",
                width: 150,
                valueGetter: function (params) {
                    return params.data.address && params.data.address.village ? params.data.address.village : '';
                }
            },
        ];        

        return (
            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="col-xl-12 col-lg-12 col-sm-12 beffect pt-2">
                    <div className="card">
                        <div className="card-body">
                            <form id="filterForm" method="POST" className="row" onSubmit={this.onClickSubmit.bind(this)}>
                                <div className="form-group col-xl-3 col-lg-3 mr-2">
                                    <label>Select Device Id</label>
                                    <Select
                                        placeholder="All"
                                        closeMenuOnSelect={false}
                                        value={this.state.selectedDevice}
                                        onChange={(selectedOption) => this.setState({ selectedDevice: selectedOption })}
                                        options={this.selectConsignerOptionsItems()}
                                        isMulti={true}
                                        classNamePrefix="react-select"
                                        menuPortalTarget={document.body}  // This attaches the dropdown to the body to escape any overflow issues
                                        styles={{
                                            menuPortal: base => ({ ...base, zIndex: 9999 }),  // High z-index to render on top
                                            control: base => ({ ...base, zIndex: 1 }),  // Control should have a lower z-index than the menu
                                        }}
                                        required
                                    />
                                </div>
                                <div className="form-group col-xl-2 col-lg-2 d-flex flex-column justify-content-end">
                                    <button
                                        type="submit"
                                        style={{ margin: "0 auto" }}
                                        className="btn btn-success"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>
                                        <span className="">
                                            <button className="float-right custom-btn white btn-danger" onClick={this.onClickSaveGridState.bind(this)}>
                                                <i className="icofont icofont-save"></i> Save Grid Layout
                                            </button>
                                            <button className="float-right custom-btn white btn-info" style={{ marginRight: "10px" }} onClick={this.resetState.bind(this)}>
                                                <i className="icofont icofont-refresh"></i> Reset Default Layout
                                            </button>
                                        </span>
                                    </h5>
                                </div>
                                <div className="card-body">
                                    <div className="row col-xl-12 col-lg-12">
                                    </div>
                                    <div className="row col-xl-12 col-lg-12">
                                        <div className="col-xl-12 col-lg-12">
                                            <div id="myGrid" style={{ height: "500px", width: "100%" }} className="ag-theme-balham">
                                                <AgGridReact
                                                    modules={this.state.modules}
                                                    columnDefs={columnwithDefs}
                                                    defaultColDef={this.state.defaultColDef}
                                                    rowData={this.state.rowData}
                                                    enableCharts={false}
                                                    enableRangeSelection={true}
                                                    autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                    onGridReady={this.onGridReady}
                                                    onGridState={this.onGridState}
                                                    statusBar={this.state.statusBar}
                                                    sideBar={this.state.sideBar}
                                                    paginationPageSize={this.state.paginationPageSize}
                                                    pagination={true}
                                                    components={this.state.components}
                                                    frameworkComponents={this.state.frameworkComponents}
                                                    gridOptions={{
                                                        context: { componentParent: this }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"dataLoadpage " + (this.state.loadshow)}></div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>
        );
    }
}
