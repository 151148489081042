
import React from 'react';

 
const UpdateSB = (props) => {
    const handleClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onCLickUpdateSB(props);
    };
    
    return (
        <span>
            <a href="javascript:;" className="label label-warning f12" style={{color:"#333",padding:"2px 10px"}} onClick={handleClick}><i className="fa fa-pencil"/></a>
        </span>
    );
};

export default UpdateSB;
