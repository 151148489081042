import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import Select from 'react-select';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import SweetAlert from 'react-bootstrap-sweetalert';
import CustomLoadingCellRenderer from "../layouts/customLoadingCellRenderer";
import Check from './checkboxComponent';
import  CheckBox  from './checkbox'
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");

export default class AdminUserRoles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageTitle: "Assign Roles to User",
            eventGridAction: "gridAction",
            eventCounterAction: "counterAction",
            eventFormAction: "formAction",
            userType:'',
            menu_title:'',
            loadshow:'show-n',
            overly:'show-n',
            updateForm:'show-n',
            addForm:'show-m',
            allusers:[],
            role_name:"",
            alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
            sliderTranslate:'',
            allmenus:[],
            allmenustree:[],
            submenuitems:[],
            defaultColDef: {
                sortable: true,
                filter: true,
                resizable: true,
            },
            modules: AllModules,
            rowData: [],
            headerHeight: 40,
            groupHeaderHeight:60,
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            rowSelection: "multiple",
            animateRows: true,
            debug: true,
            showToolPanel: false,
            uppressAggFuncInHeader: true,
            allroles:[], 
            childconfs: [],
            childrow:[],
            rowModelType: "serverSide",
            paginationPageSize:100,
            cacheBlockSize: 10,
            maxBlocksInCache: 1,
            // isRowSelectable: function(node) {
            //     this.onRowSelecteable(node);
            // },
            frameworkComponents: {
                customLoadingCellRenderer: CustomLoadingCellRenderer,
                check:Check
            },
            autoGroupColumnDef: {
                headerName: "Code",
                field: "code",
                width: 200,
                cellRenderer: "agGroupCellRenderer",
                cellRendererParams: { checkbox: true }
              },
            detailCellRendererParams:{},
            statusBar: {
                statusPanels: [
                    {
                    statusPanel: "agTotalAndFilteredRowCountComponent",
                    align: "left"
                    },
                    {
                    statusPanel: "agTotalRowCountComponent",
                    align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            sideBar: {
                toolPanels: [
                    {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel"
                    },
                    {
                    id: "filters",
                    labelDefault: "Filters",
                    labelKey: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            checked: [],
            expanded: [],
            email:'',
            responsemessage:''
        }
        this.onRowSelecteable= this.onRowSelecteable.bind(this)
    }
    getUserType = () => {
        let isAdminUser = localStorage.getItem('is_admin');
        let isDeptAdminUser = localStorage.getItem('is_dept_admin');
        let isGlobalUser = localStorage.getItem('is_global');
        console.log(" IN assignRoleMenus, isAdminUser and isGlobalUser = ", isAdminUser, isGlobalUser);
        if (isAdminUser == "true") {
            //console.log(" IN getUserType, ADMIN");
            this.setState({
                "userType": "admin"
            });

        }
        if (isDeptAdminUser == "true") {
            //console.log(" IN getUserType, is_dept_admin");
            this.setState({
                "userType": "departmentadmin"
            });
        }
        if (isAdminUser == "true" && isGlobalUser == "true") {
            //console.log(" IN getUserType, SUPERADMIN");
            this.setState({
                "userType": "superadmin"
            });
        }
    }

    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }

    componentDidMount(){
        this.logPageView(); // Log page view to GA
        this.getUserType();
        redirectURL.post("/superadmin/users")
        .then( async (response) => {
            //console.log("IN componentDidMount, superadmin/users = ",response.data);
            let currentUserType = this.state.userType;
            let userList = null;
            let userDeptCode = JSON.parse(localStorage.getItem("dept_code"));
            if (currentUserType == "superadmin") {
                userList = response.data;
            }
            if (currentUserType == "admin") {
                // userList = response.data.filter(user => user.email.includes("@maruti.co.in"));
                userList = response.data;
            }
            if (currentUserType == "departmentadmin") {
                let userListMaruti = response.data.filter(user => user.email.includes("@maruti.co.in"));
                userList = userListMaruti.filter(user => {
                    return (user.dept_code == userDeptCode)
                });
                console.log("Coming in departmentadmin, userList, userListMaruti, response.data = ", userList.length, userListMaruti.length, response.data.length);
            }
            this.setState({
                allusers:userList,
                //loadshow:'show-n'
            });
         
        })
        .catch(function(e){
            console.log(e)
        })

        this.setState({
            loadshow:'show-m'
        });
        redirectURL.post("/superadmin/roles")
        .then( async (response) => {
            let currentUserType = this.state.userType;
            var roles = null;
            let userDeptCode = JSON.parse(localStorage.getItem("dept_code"));
            console.log("currentUserType, roles = ", currentUserType, roles);
            if (currentUserType == "superadmin") {
                
                console.log("Coming in superadmin, roles = ", roles);
            }
            if (currentUserType == "admin") {
                // roles = response.data;
                
                var roles = [];
               var dummy_name = response.data.map((e) => {
                    if(e.role_name == "APP_ADMIN" || e.role_name == "MANAGER" || e.role_name == "SNDG" || e.role_name == "TRANSPORTER" || e.role_name == "epod" || e.role_name == "epod_admin"){
                        roles.push(e)
                    }
                    
                })
    
                // console.log("role_name",role_name)
                console.log("Coming in admin, roles = ", roles);
            }
            // if (currentUserType == "departmentadmin") {
            //     roles = response.data.filter(role => (role.maruti_roles == 1) && (role.dept_code == userDeptCode));
                
            //     console.log("Coming in departmentadmin, roles = ", roles);
            // }

            //console.log("Menus ",response.data);
            var menusarr = [];
            //var menus = response.data;
            if(roles.length > 0)
            {
                roles.map((item) => {
                    menusarr.push({"value":item.role_name, "label":item.role_alias})
                })
            }
            //console.log("menusarr ", menusarr)
            console.log("IN componentDidMount, superadmin/roles, menusarr = ",menusarr);
                this.setState({
                    allroles:menusarr,
                    loadshow:'show-n'
                });
         
        })
        .catch(function(e){
            console.log(e)
        })
        
    }
    hideAlert = () => {
        this.setState({
            alert: null
        });
    }
    closeAlert = () => {
        this.setState({
            show: false
        });
    }
	changeInputHandler = (event) => {
    	let name = event.target.name;
		let value = event.target.value;
    	this.setState({[name]:value});
    }

	changeFileHandler(e){
		this.setState({file:e.target.files[0]});
	}
    
	onClickHideAll(){
		this.setState({
			overly:'show-n',
			sliderTranslate:''
		});
		
	}
    onShowRoleForm(){

        this.setState({
            sliderTranslate:'slider-translate',
            overly:'show-m',
            addForm:'show-m',
            updateForm:'show-n'

        });
    }
    onShowUpdateRoleForm(){

        this.setState({
            sliderTranslate:'slider-translate',
            overly:'show-m',
            addForm:'show-n',
            updateForm:'show-m'

        });
    }
    formHandler = (event) =>{
        event.preventDefault();
        if (googleAnalytics.page.enableGA) {
            let label = googleAnalytics.page.action.formSubmittedAssignRolesToUser;
            let pageTitle = this.state.pageTitle;
            let eventOptions = {
                "category": pageTitle,
                "action": this.state.eventFormAction,
                "label": label,
            }
            googleAnalytics.logEvent(eventOptions);

        }
        var formdata = {
            email:this.state.email.value,
            roles:this.state.checked
        }
        console.log("formdata ", formdata)

       redirectURL.post("/superadmin/assignrolestouser", formdata)
        .then((response) => {
            //  console.log("response ", response)
            if(response.data.nModified == 1)
            {
                this.setState({
                    responsemessage:"Successfully assigned roles to "+this.state.email.value+"  "
                });
                //window.location.href='/superadmin/roles';
            }
            //
            this.setState({
                loadshow:'show-n',
            });
        })
        
		
    }

	onGridReady = params => {
	    this.gridApi = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
	    this.gridColumnApi = params.columnApi;
	
	   //    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
	//    };
	   
	};

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
    }
    
	handlerForm = email => {
		//console.log("IN handlerForm, Select", event.target.value);
		this.setState(
			{ email },
			() => console.log(`Option selected:`, this.state.email)
          );
        this.showPreSelectedRoles(email);

    }
    showPreSelectedRoles = (email) => {
        //console.log("IN showPreSelectedRoles, EMAIL =", email, email.value);
        let currentUser = this.state.allusers.filter(user => user.email === email.value);
        if (currentUser[0]['roles']) {
            this.setState({
                checked: currentUser[0]['roles']
            })
        }
    }

    onClickMenu(event){
        console.log("event ", event.target.value)
    }

	selectOptionsItems() {
        let items = [];     
        // console.log(this.state.trucks);
        console.log("this.state.allusers",this.state.allusers)
        this.state.allusers.forEach(item => {
            items.push({"value":item.email,"label":item.username});	
        });
        return items;
    }
    handleCheckChieldElement = (event) => {
        let allmenus = this.state.allmenus
        allmenus.forEach(menu => {
            if (menu.code === event.target.value)
            {
                menu.isChecked =  event.target.checked
                if(menu.isChecked == true)
                {
                    console.log(menu.code)
                }
            }
        })
        this.setState({allmenus: allmenus})
      }
    
    handleCheckSubMenuElement = (event) => {
        let submenuitems = this.state.submenuitems
        submenuitems.forEach(submenu => {
           if (submenu.code === event.target.value)
           submenu.isChecked =  event.target.checked
        })
        this.setState({submenuitems: submenuitems})
    }
    onRowSelecteable = (params) =>{
        console.log("params ",params)
        var formdata = {
            parentmenu:params.data.code
        }
        //console.log("formdata ", formdata)

            this.setState({
					
				detailCellRendererParams:{
					detailGridOptions: {
						columnDefs: [
							{
                                headerName: "Menu Title",
                                field: "name",
                                width: 250,
                                editable:false,
                                filter: "agSetColumnFilter",
                                cellRenderer: "agGroupCellRenderer",
                                cellRendererParams: { checkbox: true }
                                
                            },
													
						],
						detailCellRendererParams: {
                            detailGridOptions: {
                              columnDefs: [
                                {
                                    headerName: "Menu Title",
                                    field: "name",
                                    width: 250,
                                    editable:false,
                                    filter: "agSetColumnFilter",
                                    cellRenderer: "agGroupCellRenderer",
                                    cellRendererParams: { checkbox: true }
                                    
                                },
                                    
                              ],
                            },
                            getDetailRowData: function(params) {
                                var formdata = {
                                    parentmenu:params.data.code
                                }
                                console.log("formdata ", params)
                        
                                redirectURL.post("/superadmin/submenus", formdata)
                                .then((response) => {
                                    console.log("Chlde Submenu response ", response)
                                    params.successCallback(response.data);
                                });
                            }
                          },			
					},
					getDetailRowData: function(params) {
						var formdata = {
                            parentmenu:params.data.code
                        }
                        //console.log("formdata ", formdata)
                
                        redirectURL.post("/superadmin/submenus", formdata)
                        .then((response) => {
                            console.log("Submenu response ", response)
                            params.successCallback(response.data);
                        });
						
					}
					
				}
			});
			
			//if(params.column.colId == 'unloading_delay_exception')
			if(params.column.colDef.field == 'name')
			{

				params.node.setExpanded(!params.node.expanded);
			}
			else{

				params.node.setExpanded(false);
            }
            

            this.setState({
                loadshow:'show-n',
            });
       
    }
    onSelectedCheckedItem(){
        console.log(this.state.checked)
    }
    render(){
        const columnDefs = [
            
            {
                headerName: "Menu Title",
                field: "name",
                width: 250,
                editable:false,
                filter: "agSetColumnFilter",
                cellRenderer: "agGroupCellRenderer",
                cellRendererParams: {
                    checkbox: function(params) {
                        return params.node.group === true;
                    }
                }
                
            },
        ]
       var allparentmenus = [];
       this.state.allmenus.map((menu) => {
            if(menu.parent_code == null)
            {
                var pcode = menu.code;
                allparentmenus.push({"code":pcode, "name":menu.name}) 
            }
                
        });
        return (
            
    		<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert> 
                            <div className="mb-15p fbold f20">
                                <h5>
				       				<i className="icofont icofont-navigation-menu cus-i"></i> <span>{this.state.pageTitle}</span>
                                </h5>
                            </div>    
	            {this.state.alert}
            	<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
{/* 		            	
			                <div className="card-header">
				       			
				   			</div> */}
				   			<div className="card-body row">
                               
                                <div className=" col-xl-12 col-lg-12">
                                    {this.state.responsemessage != ''?
                                    <div className="alert alert-success">
                                        {this.state.responsemessage}
                                    </div>
                                    :""}
                                    <form className={" theme-form col-xl-4 col-lg-4"} onSubmit={this.formHandler}>
                                        <div className="row">
                                            <div className="form-group  col-xl-12 col-lg-12" style={{marginBottom:"0px"}}>
                                                <label className="c-lbl">Select User *:</label>
                                                <Select name="email" placeholder="Select User" className="rselect" 
                                                   value={this.state.email} onChange={this.handlerForm.bind(this)}  options={this.selectOptionsItems()} />
                                            </div>
                                        </div>
                                    <div className=" col-xl-8 col-lg-8" style={{marginLeft: "-2.5em"}}>
                                        <CheckboxTree
                                            nodes={this.state.allroles}
                                            checked={this.state.checked}
                                            expanded={this.state.expanded}
                                            onCheck={checked => this.setState({ checked })}
                                            onExpand={expanded => this.setState({ expanded })}
                                        // onClick={checked => this.setState({ checked })}
                                        />
                                        </div>
                                        <button type="submit" className="btn btn-success"  style={{marginTop:"10px"}}>Assign</button>
                                    </form>

									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
									</div>

                                
                                {/*
                                        <ul>
                                            {this.state.allmenus.map((r) => (
                                                (r.children.length > 0)?
                                                <li>
                                                    <div>
                                                        <input type="checkbox" />{r.name}
                                                    </div>
                                                    <uL style={{marginLeft:"10px"}}>
                                                     {r.children.map((m) => (
                                                       
                                                        <li>
                                                            <input type="checkbox" />{m.name}
                                                        </li>
                                                       
                                                    ))
                                                    }
                                                        </uL>
                                                    </li>
                                                :""    
                                                ))
                                            }
                                                
                                            {
                                                
                                                this.state.allmenus.map((menu) => {
                                                   
                                                        if(menu.children.length > 0)
                                                        {
                                                             menu.children.map((smenu) => {
                                                                return (
                                                                    <CheckBox handleCheckChieldElement={this.handleCheckChieldElement}  {...smenu} />
                                                                )
                                                            })
                                                        }
                                                        else{
                                                            return (
                                                                <CheckBox handleCheckChieldElement={this.handleCheckChieldElement}  {...menu} />
                                                            )
                                                        }
                                                        
                                                })
                                                
                                            }
                                        </ul>
                                    </div>
                                    <div className=" col-xl-8 col-lg-8">
                                        <ul>
                                            {
                                                (this.state.submenuitems.length > 0)?
                                                    this.state.submenuitems.map((submenu) => {
                                                        return (<CheckBox handleCheckChieldElement={this.handleCheckSubMenuElement}  {...submenu} />)
                                                    })
                                                :""
                                            }
                                        </ul>
                                    </div>
                                    */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
				 
                 <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            
            </div>
        )
    }
}