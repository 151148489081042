import React, {Component} from "react";
import ReactDom,{ render } from 'react-dom';
import axios from 'axios';
import { Redirect } from 'react-router';
import LoginForm from './Loginform';
import LmLoginForm from './LmLoginform';

//import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';

var images = require.context('../../assets/images/', true);

class Login extends Component {

    constructor(props) {
        super(props);
        var refur='';
        
        this.state = {
            style: {},
            email:'',
            password:'',
            loginMessage:'',
            is_enmovil_transporter:0,
            is_load_management:0,
        };
    }

    componentDidMount() {
        setTimeout(function () {
            this.setState({style: {display:'none'}});
        }.bind(this),1000)
        var urlPath = this.props.match.path;
        //console.log(urlPath,"urlPath")
       // console.log("location.hostname ", window.location.hostname)
        if(urlPath == "/tptlogin")
        {
            this.setState({
                is_enmovil_transporter:1
            });
        }
        else if(urlPath == "/optirun")
        {
            this.setState({
                is_load_management:1
            });
        }
        else if(urlPath == "/loadmanagement/login")
        {
            this.setState({
                is_load_management:1
            });
        }
    }
    
   
    render() {
        let style = this.state.style;
       
        var background = require('../../assets/images/login_bg.png');
               
       // console.log("window.location.hostname-",window.location.hostname)
        return (
            <div>
                {/* Loader starts */}
                {/*
                <div className="loader-wrapper" style={style}>
                    <div className="loader bg-white">
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <h4>Welcome to Autometrics <span>&#x263A;</span></h4>
                    </div>
                </div>
                */}
                {/* Loader ends */}

                {/*page-wrapper Start*/}

                {/* <div className="page-wrapper">
                    <div className="container-fluid">
                        <div className="authentication-main" >
                            <div className="row">
                                <div className="col-md-8 p-0" style ={ { backgroundImage: "url("+background+")" } }>
                                    <div className="auth-innerleft" >
                                        <div className="text-center">
                                            <img src={require('../../assets/images/autometrics_logo.png')} style={{width:"100%"}} className="logo-login" alt=""/> 
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 p-0">
                                    <div className="auth-innerright">
                                        <div className="authentication-box">
                                            <h4>LOGIN</h4>
                                            <div className="card mt-4 p-4 mb-0">
                                                <LoginForm is_enmovil_transporter={this.state.is_enmovil_transporter}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                 */}
                {/*page-wrapper Ends*/}


                <div className="page-wrapper ovrfl-h"  style ={ { backgroundImage: "url("+background+")" ,backgroundSize:"cover",backgroundPosition:"bottom",height:"100vh",backgroundRepeat:"no-repeat"} }>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-7 col-md-7" style={{}}>
                                {/* <div className="wavegn"></div>
                                <div className="smlg">
                                </div> */}
                                <div className="toplog">
                                    <img src={require('../../assets/images/diamlerbenz_logo.png')} style={{width:"auto"}} className="logo-login" alt="" />
                                </div>
                                
                            </div>
                            <div className="col-sm-5 col-md-5">
                                <div className="row">
                                    <div className="col-sm-10 lgna ml-60p">
                                         <img src={require('../../assets/images/Deliver-Ease.JPG')} style={{width:"285px",height:"150px"}} className="logo-login" alt=""/><br /><br /><br />
                                        <LoginForm is_enmovil_transporter={this.state.is_enmovil_transporter}/>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        )
    }
}


export default Login;
