
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const DeleteDuplicateConsignment = (props) => {
    const handleEventClick = (e) => {
        e.stopPropagation();
        //console.log("Overspeed ",props);
        props.context.componentParent.onClickDuplicateDeleteProcess(props);
    };
    
    //}
    return (
        <div>
            {(props.data.duplicate_invoice !== "" && props.data.duplicate_invoice  !== undefined)?
                (props.data.duplicate_invoice  === 1)?
                <button onClick={handleEventClick} className="f12 btn btn-warning" style={{padding:"0 5px"}} title="Remove Consignment" ><i className={"icofont icofont-file "}></i> Remove Consignment</button>
                :""
            :""}
            
        </div>
    );
};

export default DeleteDuplicateConsignment;
