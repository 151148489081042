import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import _, { parseInt } from "lodash";
import Modal from 'react-responsive-modal';
import SweetAlert from 'react-bootstrap-sweetalert';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import Select from 'react-select';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { getHyphenDDMMMYYYYHHMM } from '../common/utils';
var moment = require('moment');
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
const decipher = salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
    return encoded => encoded.match(/.{1,2}/g)
        .map(hex => parseInt(hex, 16))
        .map(applySaltToChar)
        .map(charCode => String.fromCharCode(charCode))
        .join('');
}
var decode = decipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
export default class HoldRequest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lists: [],
            departmentCode: null,
            currentDepartmentTitle: null,
            currentAddGeoFenceLink: null,
            pagetitle: "Hold Request",
            rowData: [],
            show1: false,
            basicTitle1: '',
            basicType1: "default",
            show: false,
            basicTitle: '',
            basicType: "default",
            loadshow: "show-n",
            from_date: "",
            to_date: "",
            noOfVehicles: 0,
            startDate: '',
            clearanceDate: '',
            reasonForHold: '',
            approverEmailId: "",
            requesterEmail: localStorage.getItem("email") || "",
            salesPlannerEmail: '',
            oblEmail: '',
            emailIds: {
                approverEmailId: "",
                requesterEmail: '',
                salesPlannerEmail: '',
                oblEmail: '',
                controllerMail: '',
                reqL4Email: '',
            },
            errors: {},
            startDate: moment().utcOffset("+05:30").format("DD-MM-YYYY HH:mm"), // Set default to today
            clearanceDate: moment().utcOffset("+05:30").add(1, 'days').format("DD-MM-YYYY HH:mm"), // Set default to tomorrow
            rowData: [],
            sliderBulkUpdate: "",
            loadshow: 'show-n',
            overly: 'show-n',
            defaultColDef: {
                sortable: true,
                filter: true,
                resizable: true,
                pivot: true,
                enableValue: true,
                // enableRowGroup: true,
            },
            modules: AllModules,
            animateRows: true,
            debug: true,
            showToolPanel: false,
            uppressAggFuncInHeader: true,
            childconfs: [],
            childrow: [],
            rowModelType: "serverSide",
            paginationPageSize: 100,
            cacheBlockSize: 10,
            maxBlocksInCache: 1,
            rowData: [],
            maprowData: null,
            rowSelection: 'multiple',
            rowHeight: 32,
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            overlayNoRowsTemplate: "",
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            detailCellRendererParams: {},
            rowClassRules: {},
            frameworkComponents: {},
            errrorRowData: "",
            inplant: "btn-info",
            yard: "btn-default",
            matrixFile: null,
            yardCost: 0,
            reqL4Email: '',
            controllerMail: '',
            columnDefs: [
                {
                    headerName: 'Vin Number',
                    validation: (value) => value && value.trim() !== '',
                    field: 'vin_number',
                    editable: true,
                    width: 120,
                    cellStyle: (params) => !params.value ? { backgroundColor: '#f29f8d', color: 'black' } : null
                },
                {
                    headerName: 'Invoice Number',
                    validation: (value) => value && value.trim() !== '',
                    field: 'invoice_number',
                    editable: true,
                    width: 140,
                    cellStyle: (params) => !params.value ? { backgroundColor: '#f29f8d', color: 'black' } : null
                },
                {
                    headerName: 'Bill To Customer Number',
                    validation: (value) => value && value.trim() !== '',
                    field: 'bill_to_customer_number',
                    editable: true,
                    width: 160, cellStyle: (params) => !params.value ? { backgroundColor: '#f29f8d', color: 'black' } : null
                },
                {
                    headerName: 'Dealer Name',
                    validation: (value) => value && value.trim() !== '',
                    field: 'dealer_name',
                    editable: true,
                    width: 140,
                    cellStyle: (params) => !params.value ? { backgroundColor: '#f29f8d', color: 'black' } : null
                },
                {
                    headerName: 'Ship To Party Code',
                    validation: (value) => value && value.trim() !== '',
                    field: 'ship_to_party_code',
                    editable: true,
                    width: 160, cellStyle: (params) => !params.value ? { backgroundColor: '#f29f8d', color: 'black' } : null
                },
                {
                    headerName: 'Ship To Party Name',
                    validation: (value) => value && value.trim() !== '',
                    field: 'ship_to_party_name',
                    editable: true,
                    width: 160,
                    cellStyle: (params) => !params.value ? { backgroundColor: '#f29f8d', color: 'black' } : null
                },
                {
                    headerName: 'Ship To Party Location',
                    validation: (value) => value && value.trim() !== '',
                    field: 'ship_to_party_location',
                    editable: true,
                    width: 180, cellStyle: (params) => !params.value ? { backgroundColor: '#f29f8d', color: 'black' } : null
                },
                {
                    headerName: 'Ship To Party Sate',
                    validation: (value) => value && value.trim() !== '',
                    field: 'ship_to_party_state',
                    editable: true,
                    width: 160, cellStyle: (params) => !params.value ? { backgroundColor: '#f29f8d', color: 'black' } : null
                },
                {
                    headerName: 'Invoice Date',
                    field: 'invoice_date',
                    editable: true,
                    width: 140,
                    cellStyle: (params) => !params.value ? { backgroundColor: '#f29f8d', color: 'black' } : null
                },
            ]
        };
        this.changeFileHandlerBulk = this.changeFileHandlerBulk.bind(this);
        this.saveBulkConsignmentData = this.saveBulkConsignmentData.bind(this);
        this.cipher = this.cipher.bind(this);
    }
    cipher = (salt) => {
        const textToChars = text => text.split('').map(c => c.charCodeAt(0));
        const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
        const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
        try {
            return text => text.split('')
                .map(textToChars)
                .map(applySaltToChar)
                .map(byteHex)
                .join('');
        }
        catch (e) { }
    }
    componentDidMount() {
        if (googleAnalytics.page.enableGA) {
            googleAnalytics.logPageView();
        }
    }    // Validate email format
    validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Stricter regex for email format
        return emailRegex.test(email);
    };
    validateForm = () => {
        const { noOfVehicles, startDate, clearanceDate, reasonForHold, emailIds } = this.state;
        const errors = {};
        // Validate number of vehicles (should be a number between 1 and 100)
        if (isNaN(noOfVehicles) || noOfVehicles < 1 || noOfVehicles > 100) {
            errors.noOfVehicles = 'Number of vehicles should be between 1 and 100';
        }
        // Validate Start Date
        if (!startDate) {
            errors.startDate = 'Start date is required';
        }
        // Validate Expected Clearance Date (should be greater than current date)
        if (!clearanceDate || new Date(clearanceDate) <= new Date(startDate)) {
            errors.clearanceDate = 'Expected clearance date should be greater than the current date';
        }
        // Validate Reason for Hold
        if (!reasonForHold) {
            errors.reasonForHold = 'Reason for hold is required';
        }
        // Validate Email Ids
        for (let key in emailIds) {
            let isPlant = this.state.inplant == 'btn-info'
            if (isPlant && !['controllerMail', 'reqL4Email'].includes(key)) {
                if (!this.validateEmail(this.state[key])) {
                    errors[key] = `${key} is not a valid email address`;
                }
            } else if (!isPlant && !['approverEmailId'].includes(key)) {
                if (!this.validateEmail(this.state[key])) {
                    errors[key] = `${key} is not a valid email address`;
                }
            }
        }
        this.setState({ errors });
        return Object.keys(errors).length === 0;
    };
    // Handle form submit
    handleSubmit = async (event) => {
        try {
            this.setState({
                loadshow: "show-n",
                overly: "show-n"
            })
            event.preventDefault();
            if (this.validateForm()) {
                // alert('Form Submitted Successfully');
                const { rowData, clearanceDate, startDate, approverEmailId, requesterEmail, salesPlannerEmail, oblEmail, noOfVehicles, reasonForHold, yardCost, reqL4Email, controllerMail } = this.state;
                // console.log(startDate, '\n ', clearanceDate)                
                // let validateDates = moment(startDate, "DD-MM-YYYY HH:mm").isAfter(moment(clearanceDate, "DD-MM-YYYY HH:mm"))
                // Parse the dates using Moment.js
                let startMoment = moment(startDate, "DD-MM-YYYY HH:mm");
                let clearanceMoment = moment(clearanceDate, "DD-MM-YYYY HH:mm");
                // Set the clearance date to be at least 1 day after the start date
                let minClearanceDate = startMoment.add(1, 'days').startOf('day'); // Tomorrow at 00:00:00
                // Check if the clearance date is before the required date (i.e., less than 1 day after start date)
                let validateDates = clearanceMoment.isBefore(minClearanceDate);
                if (validateDates) {
                    this.setState({
                        show: true,
                        basicTitle: "Clearance Date should be at least 1 day after the Start Date",
                        basicType: "danger",
                    });
                } else if (rowData.length == 0) {
                    this.setState({
                        show: true,
                        basicTitle: "Please Upload the File to Submit",
                        basicType: "danger",
                    })
                } else if (noOfVehicles != rowData.length) {
                    this.setState({
                        show: true,
                        basicTitle: "Vehicle count must match the number of records uploaded",
                        basicType: "danger",
                    })
                } else if (this.validateData(rowData)) {
                    let hold_type = this.state.inplant == "btn-info" ? 1 : 2
                    var params = {
                        rowData,
                        clearanceDate,
                        startDate,
                        requesterEmail,
                        salesPlannerEmail,
                        oblEmail,
                        noOfVehicles,
                        reasonForHold,
                        performed_by: localStorage.getItem("email") || "",
                        action: 'Uploaded Hold Request',
                        hold_type: hold_type,
                    }
                    if (hold_type == 2) {
                        params.yard_cost = yardCost
                        params.requester_l4_mail = reqL4Email
                        params.controller_mail = controllerMail
                    } else {
                        params.approverEmailId = approverEmailId
                    }
                    let response = await redirectURL.post("/consignments/uploadHoldRequest", params)
                    let duplicates = response.data.duplicate
                    if (response.data.status == "success") {
                        let basicTitle = "Data Uploaded Successfully "
                        this.setState({
                            show: true,
                            basicTitle: basicTitle,
                            basicType: "success",
                            loadshow: "show-n",
                            overly: "show-n",
                            rowData: [],
                            approverEmailId: "",
                            requesterEmail: '',
                            salesPlannerEmail: '',
                            oblEmail: '',
                            reqL4Email: '',
                            controllerMail: '',
                            emailIds: {
                                approverEmailId: "",
                                requesterEmail: '',
                                salesPlannerEmail: '',
                                oblEmail: '',
                                controllerMail: '',
                                reqL4Email: '',
                            },
                            noOfVehicles: 0,
                            reasonForHold: '',
                            errors: {},
                        })
                        document.getElementById("uploadFileBulk").value = ""
                    } else if (response.data.status == "failure") {
                        let basicTitle = duplicates.length > 0 ? "Data upload failed: Duplicate entry found:" + duplicates : "Failed to Upload Data"
                        this.setState({
                            show: true,
                            basicTitle: basicTitle,
                            basicType: "danger",
                            loadshow: "show-n",
                        })
                    } else {
                        this.setState({
                            show: true,
                            basicTitle: "Failed to Upload Data",
                            basicType: "danger",
                            loadshow: "show-n",
                            overly: "show-n"
                        })
                    }
                }
            }
        } catch (e) {
            console.log(e, "error ")
            this.setState({
                loadshow: "show-n",
                overly: "show-n"
            })
        }
    };
    // Handle input change
    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    };
    closeAlert = () => {
        this.setState({
            show: false
        });
    }
    // Handle email id changes
    handleEmailChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            emailIds: {
                ...this.state.emailIds,
                [name]: value,
            },
        });
    };
    handleChange = (event) => {
        const { name, value } = event.target;
        const { startDate } = this.state;
        // If the input is startDate, check if it's a valid future date
        if (name === 'startDate') {
            // Ensure the selected start date is not before today
            const currentDate = new Date().toISOString().split('T')[0];
            if (value < currentDate) {
                // If it's a previous date, reset to today
                this.setState({ startDate: currentDate, clearanceDate: '' });
            } else {
                this.setState({ startDate: value });
                // Automatically update clearance date to be the day after the start date
                const clearanceDate = new Date(value);
                clearanceDate.setDate(clearanceDate.getDate() + 1);
                this.setState({ clearanceDate: clearanceDate.toISOString().split('T')[0] });
            }
        }
        // For clearance date, ensure it is greater than the start date
        else if (name === 'clearanceDate') {
            if (value <= startDate) {
                this.setState({ errors: { clearanceDate: 'Clearance Date must be after Start Date' } });
            } else {
                this.setState({ clearanceDate: value, errors: {} });
            }
        }
        else {
            this.setState({
                [name]: value
            });
        }
    };
    handleReset = (event) => {
        try {
            event.preventDefault()
            this.setState({ rowData: [], errors: {} });
        } catch (e) {
            console.log(e, 'error')
        }
    };
    handleFileUpload = (data) => {
        this.setState({ rowData: data, errors: {} });
    };
    onClickHideAll() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            slideuploadeway: '',
            sliderBulkUpdate: "",
        });
        document.getElementById("uploadFileBulk").value = ""
    }
    validateData = (data) => {
        try {
            if (!Array.isArray(data) || data.length == 0) {
                this.setState({
                    show: true,
                    basicTitle: "Data Can't be Empty",
                    basicType: "warning"
                })
                return false
            }
            const errors = [];
            let emptyFields = []; // Collect empty fields
            let invalidDateFields = []; // Collect invalid date fields
            data.forEach((row, index) => {
                this.state.columnDefs.forEach((column) => {
                    const fieldValue = row[column.field];
                    // Check if there's a custom validation function
                    if (column.validation && !column.validation(fieldValue, row)) {
                        errors.push({ rowIndex: index, field: column.field });
                    }
                    // Check for empty fields
                    else if (!fieldValue && fieldValue !== 0) { // Handles empty string, null, undefined
                        emptyFields.push(column.headerName); // Collect the empty field's header name
                        errors.push({ rowIndex: index, field: column.field });
                    }
                    // Validate `invoice_date` field format
                    if (column.field === 'invoice_date' && (fieldValue === '' || !this.isValidDateFormat(fieldValue))) {
                        invalidDateFields.push('INVOICE DATE');
                        errors.push({ rowIndex: index, field: 'invoice_date' });
                    }
                });
            });
            // Set the alert message depending on the empty fields and invalid date fields
            let basicTitle = '';
            let basicType = '';
            if (emptyFields.length > 0 && invalidDateFields.length > 0) {
                basicTitle = `
                Empty Fields: ${emptyFields.join(', ')}
                Invalid Date Format: ${invalidDateFields.join(', ')}
            `;
                basicType = 'danger';
            } else if (emptyFields.length > 0) {
                basicTitle = `Empty Fields: ${emptyFields.join(', ')}`;
                basicType = 'warning';
            } else if (invalidDateFields.length > 0) {
                basicTitle = `Invalid Date Format: ${invalidDateFields.join(', ')}`;
                basicType = 'danger';
            }
            this.setState({
                show: true,
                basicTitle: basicTitle,
                basicType: basicType,
                errors: {
                    rowData: errors
                }
            });
            return errors.length === 0; // Return true if no errors, false otherwise
        } catch (e) {
            console.log("Error", e)
            this.setState({
                show: false,
                loadshow: "show-n",
                loadshow: "sho-n"
            })
        }
    };
    // Helper function to validate date format (DD-MM-YYYY HH:mm)
    isValidDateFormat = (dateStr) => {
        const dateFormat = 'DD-MM-YYYY HH:mm';
        return moment(dateStr, dateFormat, true).isValid();
    };
    onClickBulkForm() {
        this.setState({
            sliderBulkUpdate: "slider-translate",
            overly: "show-m",
            // loadshow:"show-m",
        })
    }
    changeFileHandlerBulk = async (e) => {
        //  console.log(e.target.files[0])
        const fileInput = document.getElementById('uploadFileBulk');
        const filePath = fileInput.value;
        const allowedExtensions = /(\.xls|\.xlsx)$/i; // Regular expression for .xls and .xlsx file extensions
        if (!allowedExtensions.exec(filePath)) {
            this.setState({
                show: true,
                basicTitle: 'Invalid file type. Please provide an .xls or .xlsx file.',
                basicType: "info"
            })
            fileInput.value = ''; // Clear the input
            return false;
        }
        this.setState({
            matrixFile: e.target.files[0]
        });
    }
    saveBulkConsignmentData = async (event) => {
        try {
            event.preventDefault();
            if (!this.state.matrixFile) {
                this.setState({
                    show: true,
                    basicTitle: "Please Upload File and Submit",
                    basicType: 'danger',
                })
                return ""
            }
            this.setState({ loadshow: "show-m" });
            const fdata = new FormData();
            fdata.append("uploadfile", this.state.matrixFile);
            // Send file to server
            const resp = await redirectURL.post("/readBulkUploadedData", fdata, {
                headers: {
                    'cache-control': 'no-cache',
                    'content-type': 'multipart/form-data'
                }
            });
            const excelData = resp.data.records || [];
            let duplicatesMsg = resp.data.duplicatesMsg || false;
            if (duplicatesMsg.length) {
                this.setState({
                    rowData: excelData,
                    show: true,
                    basicTitle: duplicatesMsg || 'Duplicate Records Found',
                    basicType: 'danger',
                })
            }
            this.setState({
                rowData: excelData,
                loadshow: "show-n",
                overly: "show-n",
                sliderBulkUpdate: "",
            })
            document.getElementById("uploadFileBulk").value = ""
        } catch (error) {
            console.error('Error during saveBulkConsignmentData:', error);
        }
    };
    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    };
    onGridState = () => {
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();
        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
        /*Get Current Pivot Columns State and Store in this.pivotColumns,
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }
    onCellValueChanged = (event) => {
        const { data, colDef, newValue } = event;
        const isValidDateFormat = (dateStr) => {
            // Validate the full date format including hours and minutes (DD-MM-YYYY HH:mm)
            const dateFormat = 'DD-MM-YYYY HH:mm';
            // Check if the date is strictly valid with the given format
            const momentDate = moment(dateStr, dateFormat, true);
            // Ensure the date has valid hours and minutes (e.g., minutes should not be empty)
            return momentDate.isValid() && dateStr.includes(":");
        };
        // If the new value is blank or invalid, prevent the update
        if (newValue === '' || newValue === null) {
            alert("Field cannot be empty.");
            return;
        }
        if (colDef.field == "invoice_date" && !isValidDateFormat(newValue)) {
            this.setState({
                show: true,
                basicTitle: 'Invoice Date Should be in the format of "DD-MM-YYYY HH:mm"',
                basicType: "warning"
            })
        }
        // Create a new copy of the data to avoid mutating the original state
        const updatedRowData = this.state.rowData.map(row => {
            // Find the row to update using a unique identifier (e.g., `hold_id`)
            if (row.unique_id === data.unique_id) {
                // Return a new row object with the updated value
                return { ...row, [colDef.field]: newValue };
            }
            return row; // Return the other rows unchanged
        });
        // Update the state with the new rowData
        this.setState({ rowData: updatedRowData });
    };
    convertToActualData(value) {
        // Implement any conversion logic here
        return value; // Return the converted value, if applicable
    }
    handleStartDateChange = (event) => {
        try {
            let { noOfVehicles } = this.state
            const selectedStartDate = moment(event._d).utcOffset("+05:30"); // Keep this as a moment object
            // Check if clearance date exists
            if (this.state.clearanceDate && this.state.clearanceDate !== "") {
                const startDate = selectedStartDate; // No need to format again, already a moment object
                const clearanceDate = moment(this.state.clearanceDate, "DD-MM-YYYY HH:mm").utcOffset("+05:30"); // Ensure both dates are in the same timezone 

                if (startDate.isBefore(moment().startOf('day').utcOffset("+05:30"))) {
                    this.setState({
                        startDate: "", // Clear the start date if it's before the current date
                        show: true,
                        basicTitle: "Start Date should be >= Current Date",
                        basicType: 'danger',
                        yardCost: 0,
                    });
                } else {
                    let diffDays = clearanceDate.diff(startDate, 'hours');  // Get the difference in hours
                    // Round up to the next whole day
                    let roundedDiffDays = Math.ceil(diffDays / 24);  // Divide by 24 and round up
                    this.setState({
                        startDate: selectedStartDate.format("DD-MM-YYYY HH:mm"), // Format the start date for display
                        yardCost: parseInt(noOfVehicles) * parseInt(roundedDiffDays) * 450 || 0
                    });
                }
            } else {
                this.setState({
                    startDate: selectedStartDate.format("DD-MM-YYYY HH:mm")  // Set the start date if no clearance date is set
                });
            }
        } catch (e) {
            console.log("Error ", e)
        }
    }
    handleClearanceDateChange = (event) => {
        try {
            const selectedClearanceDate = moment(event._d).utcOffset("+05:30").format("DD-MM-YYYY HH:mm"); // Convert to Moment and format the date
            // Check if start date exists
            if (this.state.startDate && this.state.startDate !== "") {
                const startDate = moment(this.state.startDate, "DD-MM-YYYY HH:mm").utcOffset("+05:30");
                const clearanceDate = moment(selectedClearanceDate, "DD-MM-YYYY HH:mm");
                // Compare the dates
                // console.log(startDate._d, 'stardatae')
                // console.log(clearanceDate._d, 'clearanceDate')
                if (clearanceDate.isSameOrBefore(startDate)) {
                    this.setState({
                        clearanceDate: "", // Clear the clearance date if it's before the start date
                        show: true,
                        basicTitle: "Clearance Date should be greater than Start Date",
                        basicType: 'danger',
                        yardCost: 0,
                    });
                } else if (clearanceDate.isBefore(moment().utcOffset("+05:30"))) {
                    this.setState({
                        clearanceDate: "", // Clear the clearance date if it's before the start date
                        show: true,
                        basicTitle: "Clearance Date should be greater than current Date Time",
                        basicType: 'danger',
                        yardCost: 0,
                    });
                } else {
                    let diffDays = clearanceDate.diff(startDate, 'hours');  // Get the difference in hours
                    // Round up to the next whole day
                    let roundedDiffDays = Math.ceil(diffDays / 24);  // Divide by 24 and round up
                    let noOfVehicles = this.state.noOfVehicles
                    this.setState({
                        clearanceDate: selectedClearanceDate,  // Set the clearance date if it's valid
                        yardCost: parseInt(noOfVehicles) * parseInt(roundedDiffDays) * 450 || 0
                    });
                }
            } else {
                this.setState({
                    clearanceDate: selectedClearanceDate  // Set the clearance date if no start date is set
                });
            }
        } catch (e) {
            console.log("Error ", e)
        }
    }
    onClickTab = (value) => {
        try {
            if (value == "inplant") {
                this.setState({
                    inplant: "btn-info",
                    yard: "btn-default",
                })
            } else if (value == "yard") {
                this.setState({
                    inplant: "btn-default",
                    yard: "btn-info",
                })
            }
            this.setState({
                loadshow: "show-n",
                overly: "show-n",
                startDate: moment().utcOffset("+05:30").format("DD-MM-YYYY HH:mm"), // Set default to today
                clearanceDate: moment().utcOffset("+05:30").add(1, 'days').format("DD-MM-YYYY HH:mm"), // Set default to tomorrow
                rowData: [],
                approverEmailId: "",
                requesterEmail: localStorage.getItem("email") || "",
                salesPlannerEmail: '',
                oblEmail: '',
                emailIds: {
                    approverEmailId: "",
                    requesterEmail: '',
                    salesPlannerEmail: '',
                    oblEmail: '',
                    controllerMail: '',
                    reqL4Email: '',
                },
                noOfVehicles: 0,
                reasonForHold: '',
                errors: {},
            })
            document.getElementById("uploadFileBulk").value = ""
        } catch (e) {
            console.log("Error : ", e)
        }
    }
    onNoOfVehicleChanged = (event) => {
        try {
            let vehicleCount = event.value;
            // Destructure startDate and clearanceDate from state
            let { startDate, clearanceDate } = this.state;
            // Check if start date and clearance date are available
            if (startDate && clearanceDate) {
                // Use moment.js to parse the dates with the correct format
                const parsedStartDate = moment(startDate, "DD-MM-YYYY HH:mm").utcOffset("+05:30");
                const parsedClearanceDate = moment(clearanceDate, "DD-MM-YYYY HH:mm").utcOffset("+05:30");
                // Calculate the difference in hours between the two dates
                let diffHours = parsedClearanceDate.diff(parsedStartDate, 'hours');
                // Round up to the next whole day (24 hours)
                let roundedDiffDays = Math.ceil(diffHours / 24);  // Divide by 24 and round up                
                // Update the yard cost based on the vehicle count and difference in days
                this.setState({
                    yardCost: roundedDiffDays * parseInt(vehicleCount) * 450 || 0
                });
            }
            // Update the number of vehicles
            this.setState({
                noOfVehicles: vehicleCount
            });
        } catch (e) {
            console.log("Error :", e);
        }
    }
    render() {
        const {
            requesterEmail,
            noOfVehicles,
            startDate,
            clearanceDate,
            reasonForHold,
            approverEmailId,
            salesPlannerEmail,
            oblEmail,
            errors,
            rowData,
            controllerMail,
            reqL4Email,
        } = this.state;
        let vehicleOptions = [];
        for (let i = 1; i <= 100; i++) {
            const option = {
                label: i,
                value: i,
            };
            vehicleOptions.push(option);
        }
        return (
            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}>
                </SweetAlert>
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <h5 className="mb-15p fbold f20">
                            <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.pagetitle} </span>
                        </h5>
                        <div style={{ width: "100%" }} >
                            <button className={"btn " + this.state.inplant} onClick={this.onClickTab.bind(this, 'inplant')} style={{ borderRight: "1px solid #ccc", borderLeft: "1px solid #ccc" }}>In Plant</button>
                            <button className={"btn " + this.state.yard} onClick={this.onClickTab.bind(this, 'yard')}>Yard</button>
                        </div>
                        <div className="card">
                            <div className=" card-body" style={{ minHeight: "70vh", padding: "0px" }}>
                                {/* { commented the code to show for yard tab
                                    this.state.inplant == "btn-info" && */}
                                <div className="row p-0">
                                    <div style={{ margin: '20px', padding: '0px 20px', width: '100%', marginBottom: "0px", paddingBottom: "0px" }}>
                                        <div className='row p-0 m-0' style={{ display: 'flex', justifyContent: "space-between" }} >
                                            <h6>{this.state.inplant == 'btn-info' ? 'Plant - Hold' : 'Yard - Hold'}</h6>
                                            <button
                                                type="button"
                                                className="btn btn-warning form-group "
                                                style={{ marginRight: '8px', fontSize: "14px", padding: "5px", width: "80px", height: "36px", borderRadius: "6px", marginBottom: "0px" }}
                                                onClick={this.onClickBulkForm.bind(this)}>
                                                File Upload
                                            </button>
                                        </div>
                                        {/* <h2 style={{ textAlign: 'center' }}>Request Form</h2> */}
                                        <form onSubmit={this.handleSubmit} style={{ width: "100%", marginTop: "0px" }}>
                                            {/* Requester Email */}
                                            <div className="form-group col-xl-12 col-lg-12 mt-2" style={{ display: "flex", flexWrap: 'wrap', gap: '10px', border: '1px solid #ccc', paddingTop: "20px", borderRadius: "16px ", backgroundColor: "#f7f9ff" }}>
                                                <div className="form-group col-xl-2 col-lg-2" style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px', flex: '1 0 25%' }}>
                                                    <label style={{ marginBottom: '5px' }}>Requester Mail<span style={{ color: 'red' }}>*</span></label>
                                                    <input
                                                        type="email"
                                                        name="requesterEmail"
                                                        value={requesterEmail}
                                                        onChange={this.handleChange}
                                                        style={{ padding: '8px', marginBottom: '5px', width: '100%', borderRadius: "6px", border: "1px solid #bdbbbb" }}
                                                    />
                                                    {errors.requesterEmail && <small style={{ color: 'red' }}>Requester Mail is not a valid email address</small>}
                                                </div>
                                                {/* No of Vehicles */}
                                                <div className="form-group col-xl-2 col-lg-2" style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px', flex: '1 0 25%' }}>
                                                    <label style={{ marginBottom: '5px' }}>No of Vehicles<span style={{ color: 'red' }}>*</span></label>
                                                    <Select
                                                        className="border-radius-0"
                                                        isMulti={false} // Set to `true` if you want multi-select
                                                        id="noOfVehicles"
                                                        value={{ label: noOfVehicles, value: noOfVehicles }} // find the selected option based on value
                                                        // onChange={(e) => this.setState({ noOfVehicles: e.value })} // set state to the selected value
                                                        onChange={this.onNoOfVehicleChanged}
                                                        options={vehicleOptions} // options should be an array of { label, value }
                                                        getOptionLabel={(e) => `${e.label}`} // Optional: customize how the options are displayed
                                                    />
                                                    {errors.noOfVehicles && <small style={{ color: 'red' }}>{errors.noOfVehicles}</small>}
                                                </div>
                                                {/* Start Date */}
                                                <div className="form-group col-xl-2 col-lg-2" style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px', flex: '1 0 25%' }}>
                                                    <label style={{ marginBottom: '5px' }}>Start Date<span style={{ color: 'red' }}>*</span></label>
                                                    <Datetime
                                                        disableCloseOnClickOutside={false}
                                                        closeOnSelect={true}
                                                        inputProps={{
                                                            placeholder: 'Select Start Date and Time',
                                                            name: 'startDate',
                                                            autoComplete: 'off',
                                                            readOnly: true,
                                                            value: startDate,
                                                        }}
                                                        dateFormat="DD-MM-YYYY"
                                                        timeFormat="HH:mm"
                                                        name="startDate"
                                                        onChange={this.handleStartDateChange}
                                                        required
                                                    />
                                                </div>
                                                {/* Expected Clearance Date */}
                                                <div className="form-group col-xl-2 col-lg-2" style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px', flex: '1 0 25%', paddingRight: '0px' }}>
                                                    <label style={{ marginBottom: '5px' }}>Expected Clearance Date<span style={{ color: 'red' }}>*</span></label>
                                                    <Datetime
                                                        disableCloseOnClickOutside={false}
                                                        closeOnSelect={true}
                                                        inputProps={{
                                                            placeholder: 'Select clearanceDate and Time',
                                                            name: 'clearanceDate',
                                                            autoComplete: 'off',
                                                            readOnly: true,
                                                            value: clearanceDate
                                                        }}
                                                        dateFormat="YYYY-MM-DD"
                                                        timeFormat="HH:mm"
                                                        name="clearanceDate"
                                                        onChange={this.handleClearanceDateChange}
                                                        required
                                                    />
                                                    {errors.clearanceDate && <small style={{ color: 'red' }}>{errors.clearanceDate}</small>}
                                                </div>
                                                {/* Reason for Hold */}
                                                {this.state.inplant != 'btn-info' &&
                                                    <div className="form-group col-xl-1 col-lg-1" style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px', flex: '1 0 25%', cursor: 'not-allowed', minWidth: "112px" }}>
                                                        <label style={{ marginBottom: '5px' }}>Expected Cost</label>
                                                        <p style={{ fontSize: '20px', color: '#DC143C', backgroundColor: '#ffffff', border: '1px solid #c1c1c1', padding: '0px 8px 0px 8px ', borderRadius: '6px' }}>
                                                            {this.state.yardCost}
                                                        </p>
                                                    </div>
                                                }
                                                <div className="form-group col-xl-2 col-lg-2" style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px', flex: '1 0 25%' }}>
                                                    <label style={{ marginBottom: '5px' }}>Reason for Hold<span style={{ color: 'red' }}>*</span></label>
                                                    <input
                                                        type="text"
                                                        name="reasonForHold"
                                                        value={reasonForHold}
                                                        onChange={this.handleChange}
                                                        style={{ padding: '8px', marginBottom: '5px', width: '100%', borderRadius: "6px", border: "1px solid #bdbbbb" }}
                                                    />
                                                    {errors.reasonForHold && <small style={{ color: 'red' }}>Reason for hold is required<span style={{ color: 'red' }}>*</span></small>}
                                                </div>
                                                {/* Request Approver Section */}
                                                <div className="form-group col-xl-12 col-lg-12" style={{ width: '100%', padding: "0px", marginBottom: "0px" }}>
                                                    {/* <h3 style={{ textAlign: 'center' }}>Request Approver</h3> */}
                                                    <div className="form-group" style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                                                        {/* Approver Email Id */}
                                                        {this.state.yard != 'btn-info' &&
                                                            <div className="form-group col-xl-2 col-lg-2" style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px', flex: '1 0 30%' }}>
                                                                <label style={{ marginBottom: '5px' }}>Approver Email<span style={{ color: 'red' }}>*</span></label>
                                                                <input
                                                                    type="email"
                                                                    name="approverEmailId"
                                                                    value={approverEmailId}
                                                                    onChange={this.handleChange}
                                                                    style={{ padding: '8px', marginBottom: '5px', width: '100%', borderRadius: "6px", border: "1px solid #bdbbbb" }}
                                                                />
                                                                {errors.approverEmailId && <small style={{ color: 'red' }}>Approver Email is not a valid email address</small>}
                                                            </div>
                                                        }
                                                        {this.state.yard == 'btn-info' &&
                                                            <>
                                                                <div className="form-group col-xl-2 col-lg-2" style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px', flex: '1 0 30%' }}>
                                                                    <label style={{ marginBottom: '5px' }}>Requester L4 Email<span style={{ color: 'red' }}>*</span></label>
                                                                    <input
                                                                        type="email"
                                                                        name="reqL4Email"
                                                                        value={reqL4Email}
                                                                        onChange={this.handleChange}
                                                                        style={{ padding: '8px', marginBottom: '5px', width: '100%', borderRadius: "6px", border: "1px solid #bdbbbb" }}
                                                                    />
                                                                    {errors.reqL4Email && <small style={{ color: 'red' }}>Requester L4 Email is not a valid email address</small>}
                                                                </div>
                                                                <div className="form-group col-xl-2 col-lg-2" style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px', flex: '1 0 30%' }}>
                                                                    <label style={{ marginBottom: '5px' }}>Logistics Controller Email<span style={{ color: 'red' }}>*</span></label>
                                                                    <input
                                                                        type="email"
                                                                        name="controllerMail"
                                                                        value={controllerMail}
                                                                        onChange={this.handleChange}
                                                                        style={{ padding: '8px', marginBottom: '5px', width: '100%', borderRadius: "6px", border: "1px solid #bdbbbb" }}
                                                                    />
                                                                    {errors.controllerMail && <small style={{ color: 'red' }}>Logistics Controller Email is not a valid email address</small>}
                                                                </div>
                                                            </>
                                                        }
                                                        {/* Sales Planner Email */}
                                                        <div className="form-group col-xl-2 col-lg-2" style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px', flex: '1 0 30%' }}>
                                                            <label style={{ marginBottom: '5px' }}>Sales Planner Email<span style={{ color: 'red' }}>*</span></label>
                                                            <input
                                                                type="email"
                                                                name="salesPlannerEmail"
                                                                value={salesPlannerEmail}
                                                                onChange={this.handleChange}
                                                                style={{ padding: '8px', marginBottom: '5px', width: '100%', borderRadius: "6px", border: "1px solid #bdbbbb" }}
                                                            />
                                                            {errors.salesPlannerEmail && <small style={{ color: 'red' }}>Sales Planner Email is not a valid email address</small>}
                                                        </div>
                                                        {/* OBL Email */}
                                                        <div className="form-group col-xl-2 col-lg-2" style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px', flex: '1 0 30%' }}>
                                                            <label style={{ marginBottom: '5px' }}>OBL Email<span style={{ color: 'red' }}>*</span></label>
                                                            <input
                                                                type="email"
                                                                name="oblEmail"
                                                                value={oblEmail}
                                                                onChange={this.handleChange}
                                                                style={{ padding: '8px', marginBottom: '5px', width: '100%', borderRadius: "6px", border: "1px solid #bdbbbb" }}
                                                            />
                                                            {errors.oblEmail && <small style={{ color: 'red' }}>OBL Email is not a valid email address</small>}
                                                        </div>
                                                        <div className={`form-group ${this.state.yard !== 'btn-info' ? 'col-xl-5 col-lg-5' : 'col-xl-3 col-lg-3'} mb-0 pb-0`} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', paddingRight: "0px", right: "-20px" }}>
                                                            <button className="btn btn-default form-group "
                                                                onClick={this.handleReset}
                                                                style={{ color: "red", border: "1px solid red", marginRight: "8px", padding: "5px", fontSize: "14px", width: "70px", height: "36px", borderRadius: "6px", backgroundColor: "white" }}>
                                                                Reset
                                                            </button>
                                                            <button
                                                                className=" btn btn-info form-group "
                                                                type="submit"
                                                                style={{ marginRight: '8px', padding: "5px", fontSize: "14px", width: "70px", height: "36px", borderRadius: "6px" }}
                                                            >
                                                                Submit
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    {this.state.yard == 'btn-info' &&
                                        <div style={{ width: '100%', padding: '0px 3rem 0px 3rem', textAlign: 'right' }}>
                                            <p style={{ color: 'red' }}>
                                                Note: The yard cost is calculated by multiplying the number of vehicles by 450 per day.
                                            </p>
                                        </div>
                                    }
                                    <div className='mt-0 pt-0' style={{ margin: '20px', padding: '20px', width: '100%' }}>
                                        <div id="myGrid" style={{ height: "580px", width: "100%" }} className="ag-theme-balham">
                                            <AgGridReact
                                                modules={this.state.modules}
                                                columnDefs={this.state.columnDefs} // Assuming this is a state variable for columns
                                                defaultColDef={this.state.defaultColDef}
                                                sideBar={this.state.sideBar}
                                                rowData={rowData}
                                                enableCharts={true}
                                                enableRangeSelection={true}
                                                autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                onGridReady={this.onGridReady}
                                                pagination={true}
                                                frameworkComponents={this.state.frameworkComponents}
                                                stopEditingWhenGridLosesFocus={true}
                                                enableCellChangeFlash={false}
                                                rowClassRules={this.state.rowClassRules}
                                                gridOptions={{
                                                    context: { componentParent: this }
                                                }}
                                                masterDetail={true}
                                                rowHeight={this.state.rowHeight}
                                                rowSelection={this.state.rowSelection}
                                                suppressRowClickSelection={true}
                                                overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                                                excelStyles={this.state.excelStyles}
                                                // onCellClicked={this.onCellClicked.bind(this)}
                                                onCellValueChanged={this.onCellValueChanged}
                                                // onCellEditingStopped={this.onCellEditingStopped} // Add this to handle updated data
                                                editable={true}  // Enable editing for all cells
                                                cellEditorSelector={this.cellEditorSelector}  // Optional: you can specify custom editors here if needed
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* } */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"slide-r " + (this.state.sliderBulkUpdate)} style={{ overflow: "auto" }}>
                    <div className="slide-r-title">
                        <h4>
                            Bulk Upload - Hold
                            <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{ position: "relative" }}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div>
                        <div className={"row"} style={{ padding: '5px 0px', width: '100%', overflow: 'hidden', zIndex: '9999', height: "100em" }} >
                            <form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.saveBulkConsignmentData}>
                                <div className="col-xl-12 col-lg-12 row form-group">
                                    <label className="col-xl-12 col-lg-12">Upload File :</label>
                                    <div className="col-xl-12 col-lg-12 form-group">
                                        <input type="file"
                                            onChange={this.changeFileHandlerBulk}
                                            name="uploadFileBulk"
                                            id="uploadFileBulk"
                                            className="form-control"
                                            accept=".xls,.xlsx"
                                        />
                                    </div>
                                </div>
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <button type="submit" className="btn btn-success">Submit</button>
                                </div>
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <a href={require("../../assets/json/samplebulkholdrequest.xlsx")} target="_blank" className="btn btn-warning">Download Sample Template</a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                </div>
            </div>
        );
    }
}