import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import redirectURL from '../redirectURL';

export default class DeviationStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showAlert: false,
            alertTitle: '',
            alertType: "default",
            actionText: "",
            actualCost: '',
            deviationCost: '',
            delta: null,
            isCostFieldsVisible: false,
            errorMessage: '',
        };
    }
    componentDidMount() {
        try {
            const { deviation_type, user_type, deviation_status } = this.props.match.params;
            this.handleDeviationStatus();
            if (!(deviation_type == '2' && user_type == 'obl_user' && deviation_status == 1)) {                
                setTimeout(() => {
                    window.close();
                }, 3000);
            }
        } catch (e) {
            console.log("Error ", e)
        }
    }
    handleDeviationStatus = async () => {
        try {
            const { deviation_id, deviation_status, deviation_type, user_type } = this.props.match.params;
            const params = {
                deviation_id,
                deviation_status,
                deviation_type,
                user_type,
                performed_by: localStorage.getItem("email" || "")
            };
            if (deviation_type == 2 && user_type == 'obl_user' && deviation_status == 1) {
                // If deviation_type is 2 and user_type is obl_user, show cost input fields
                this.setState({ isCostFieldsVisible: true });
            } else {
                // If not 'obl_user' or deviation_type is not 2, proceed normally
                const response = await redirectURL.post("/consignments/approvediviationrequests", params);
                this.handleResponse(response);
            }
        } catch (e) {
            console.error("Error while processing deviation request:", e);
            this.setState({
                showAlert: true,
                alertTitle: "An error occurred while processing the request.",
                alertType: "danger",
            });
        }
    };
    handleResponse = (response) => {
        const { user_type, deviation_status } = this.props.match.params;
        if (user_type !== 'obl_user' || (user_type == 'obl_user' && deviation_status == 2)) {
            if (response.data.status === "success") {
                let defaultMsg = "Request Approved";
                this.setState({
                    showAlert: true,
                    alertTitle: response.data.message,
                    alertType: "success",
                    actionText: response.data.message || defaultMsg,
                });
            } else {
                let defaultMsg = "Request Failed";
                this.setState({
                    showAlert: true,
                    alertTitle: response.data.message,
                    alertType: "danger",
                    actionText: response.data.message || defaultMsg,
                });
            }
            setTimeout(() => {
                window.close();
            }, 3000);
        }
    };
    handleCostCalculation = () => {
        const { actualCost, deviationCost } = this.state;
        // Validate if both costs are provided and are numbers
        if (isNaN(actualCost) || isNaN(deviationCost)) {
            this.setState({
                errorMessage: 'Both Actual Cost and Deviation Cost must be valid numbers.',
                delta: null,  // Reset delta if inputs are invalid
            });
            return;
        }
        // Reset error message
        this.setState({ errorMessage: '' });
        // Calculate the delta
        const delta = (parseFloat(actualCost) - parseFloat(deviationCost)) || 0;
        this.setState({ delta });
    };
    handleInputChange = (e) => {
        const { name, value } = e.target;
        // Only allow numeric values in the inputs
        if (value === '' || !isNaN(value)) {
            this.setState({ [name]: value }, this.handleCostCalculation);
        }
    };
    handleSubmit = async () => {
        const { actualCost, deviationCost, delta } = this.state;
        // Ensure both costs are entered and delta is calculated
        if (actualCost === '' || deviationCost === '') {
            this.setState({
                errorMessage: 'Both Actual Cost and Deviation Cost must be provided.',
            });
            return;
        }
        // Submit the data if valid
        try {
            const { deviation_id, deviation_status, user_type } = this.props.match.params;
            const params = {
                deviation_id,
                deviation_status,
                actual_cost: actualCost,
                deviation_cost: deviationCost,
                delta,
                user_type,
                performed_by: localStorage.getItem("email" || ""),
            };
            // You can send the data to the API here
            const response = await redirectURL.post("/consignments/submitdeviationcost", params);
            if (response.data.status === 'success') {
                this.setState({
                    showAlert: true,
                    alertTitle: 'Cost information submitted successfully!',
                    alertType: 'success',
                    actionText: 'Cost information submitted successfully!',
                });
            } else {
                this.setState({
                    showAlert: true,
                    alertTitle: response.data.message,
                    alertType: 'danger',
                    actionText: response.data.message,
                });
            }
        } catch (e) {
            this.setState({
                showAlert: true,
                alertTitle: 'Error submitting the data.',
                alertType: 'danger',
                actionText: 'Error submitting the data.',
            });
        }
    };
    closeAlert = () => {
        this.setState({ showAlert: false });
    };
    render() {
        const { actualCost, deviationCost, delta = 0, isCostFieldsVisible, showAlert, alertTitle, alertType, actionText, errorMessage } = this.state;
        return (
            <div className="container-fluid">
                <SweetAlert
                    show={showAlert}
                    type={alertType}
                    title={alertTitle}
                    onConfirm={this.closeAlert}
                />
                <div>
                    {/* Display actionText only if user is not 'obl_user' */}
                    {actionText && actionText !== "" && this.props.match.params.user_type !== 'obl_user' && (
                        <h1>{actionText}</h1>
                    )}
                </div>
                {isCostFieldsVisible && (
                    <div className="cost-inputs">
                        <div> Deviaiton Id : {this.props && this.props.match && this.props.match.params && this.props.match.params.deviation_id} </div>
                        <div className="form-group row">
                            <div className="col-lg-3 col-md-4 col-sm-12">
                                <label htmlFor="actualCost" className="col-form-label">Actual Cost</label>
                                <input
                                    type="number"
                                    id="actualCost"
                                    name="actualCost"
                                    value={actualCost}
                                    onChange={this.handleInputChange}
                                    className={`form-control ${errorMessage ? 'is-invalid' : ''}`}
                                    placeholder="Enter Actual Cost"
                                />
                                {errorMessage && <div className="invalid-feedback">{errorMessage}</div>}
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-12">
                                <label htmlFor="deviationCost" className="col-form-label">Deviation Cost</label>
                                <input
                                    type="number"
                                    id="deviationCost"
                                    name="deviationCost"
                                    value={deviationCost}
                                    onChange={this.handleInputChange}
                                    className={`form-control ${errorMessage ? 'is-invalid' : ''}`}
                                    placeholder="Enter Deviation Cost"
                                />
                                {errorMessage && <div className="invalid-feedback">{errorMessage}</div>}
                            </div>
                            {delta !== null && !errorMessage && (
                                <div className="col-lg-3 col-md-4 col-sm-12">
                                    <label className="col-form-label">Delta</label>
                                    <h3 style={{ border: '1px solid lightgrey', borderRadius: '4px', backgroundColor: '#fff', padding: '0px 14px 0px 14px' }}>{delta}</h3>
                                </div>
                            )}
                            <div className="col-lg-2 col-md-3 col-sm-6" style={{ alignContent: 'center', maxWidth: '124px', marginTop: "28px" }}>
                                <button
                                    type="button"
                                    className="btn btn-primary w-100"
                                    onClick={this.handleSubmit}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}