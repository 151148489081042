/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */


import React, { Component } from "react";
import { render } from "react-dom";
import { AgGridReact } from "@ag-grid-community/react";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { MenuModule } from "@ag-grid-enterprise/menu";
import Modal from "react-responsive-modal";

import { AllModules } from "@ag-grid-enterprise/all-modules";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import * as Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import CellGraphComponent from "./cellGraphComponent";
import $ from "jquery";
import Consignmentforceclose from "../manage/consignmentforceclose";
import ColumnGroup from "./columngroupComponent";
import Select from "react-select";
import LoadingDashboard from "./loadingDashboardComponent";
import UnLoadingDashboard from "./unloadingdashboardComponent";
import DailyDashboard from "./dailyrunningdashboardComponent";
import TransitDashboard from "./transitdashboardComponent";
import TransporterDailyRunning from "./dailrunningtransporterdashboardComponent";
import {
  getHyphenDDMMMYYYY,
  getHyphenYYYYMMDD,
  getHyphenDDMMMYYYYHHMM,
  getHyphenYYYYMMDDHHMMSS,
  getHyphenDDMMYYYYHHMMSS,
  getYYYYMMDD,
  getYYYYMMDDHHMMSS
} from "../common/utils";
// const $ = window.$;

import Columnchart from "./columnloadingdashboard";

import DashboardDelayResponse from "./dashboardDelayResponse";

window.jQuery = $;
window.$ = $;
var redirectURL = require("../redirectURL");
var googleAnalytics = require("../common/googleanalytics");
var moment = require("moment");
var CELL_DIMENSION_SIZE = 90;

export default class DepartmentDashboard extends Component {
  state = {
    // modules: [
    //   ClientSideRowModelModule,
    //   RowGroupingModule,
    //   MenuModule,
    //   ColumnsToolPanelModule,
    //   FiltersToolPanelModule,
    //   SetFilterModule,
    //   AllModules
    // ],
    sliderRouteTranslate: "",
    sliderCriticalTranslate: "",
    serires: [],
    categories: [],
    activeserires: [],
    activecategories: [],
    loadshow: "show-n",
    overly: "show-n",
    leftTitle: "Delay Meter for selected period",
    leftSubTitle: "",
    activeleftTitle: "Delay Meter as on Date(Cummulative)",
    activeleftSubTitle: "",
    sidebartitle: "",
    modules: AllModules,
    defaultColDef: {
      //flex: 1,
      minWidth: 50,
      filter: true,
      sortable: true,
      enablePivot: true,
      pivot: true
    },
    // autoGroupColumnDef: {
    //   headerName: 'Department',
    //   field: 'dept_name',
    //   minWidth: 50,
    //   width:240,
    //   cellRendererParams: {
    //     suppressCount: true,
    //     expanded: true,
    //   },
    //   comparator: function(valueA, valueB) {
    //     console.log("valueA ", valueA)
    //     if (valueA == null || valueB == null) return valueA - valueB;
    //     if (!valueA.substring || !valueB.substring) return valueA - valueB;
    //     if (valueA.length < 1 || valueB.length < 1) return valueA - valueB;
    //     return strcmp(
    //       valueA.substring(1, valueA.length),
    //       valueB.substring(1, valueB.length)
    //     );
    //   },
    // },

    autoGroupColumnDef: {
      // headerName: 'Department',
      field: "consignment_code",
      // minWidth: 50,
      // width:240,
      cellRendererParams: {
        suppressCount: true
        //checkbox: true,
      }
    },
    originalchartdata: [],
    detailCellRendererParams: {},
    rowData: null,
    popupParent: document.body,
    groupHeaderHeight: 50,
    headerHeight: 70,
    floatingFiltersHeight: 50,
    pivotGroupHeaderHeight: 30,
    pivotHeaderHeight: 50,
    rowHeight: 50,
    startDate: "",
    endDate: "",
    showlegs: [],
    legcolumns: [],
    legrows: null,
    frameworkComponents: {
      cellGraphComponent: CellGraphComponent
    },
    // defaultGroupSortComparator: function(nodeA, nodeB) {
    //   if (parseInt(nodeA.key) < parseInt(nodeB.key)) {
    //       return -1;
    //     } else if (parseInt(nodeA.key) > parseInt(nodeB.key)) {
    //       return 1;
    //     } else {
    //       return 0;
    //     }

    // },
    searchkey: "",
    pagetitle: "",
    dept_code: "",
    showreachdealer: false,
    showactcols: false,
showdashboard: "show-m",
    loaddashboard: "show-n",
    unloaddashboard: "show-n",
    bidrd: "show-n",
    bila: "show-m",
    bitransit: "show-n",
    bitransport: "show-n",
    summarydata: [],
    originaldata: [],
    usergridstate: [],
    screenurl: "",
    screentitle: "",
    eventGridAction: "gridAction",
    rowCriticalData: [],
    usermanualmodal: false,
    locations: [],
    transporters: [],
    transporter: { value: "all", label: "All" },
    areaVal: { value: "all", label: "All" },
    location: { value: "all", label: "All" },
    carriertype: { value: "all", label: "All" },
    zone: [{ value: "plant", label: "View By Plant" }],
    zones: [{ value: "plant", label: "View By Plant" }, { value: "zone", label: "View By Zone" }, { value: "area", label: "View By Area" }],
    type: "plant",
    allrecords: [],
    columnDefs: [],
    rowData1: null,
    rowData2: null,
    plantgrid: "show-m",
    zonegrid: "show-n",
    areagrid: "show-n"
  };

  cipher = salt => {
    const textToChars = text => text.split("").map(c => c.charCodeAt(0));
    const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = code =>
      textToChars(salt).reduce((a, b) => a ^ b, code);
    try {
      return text =>
        text
          .split("")
          .map(textToChars)
          .map(applySaltToChar)
          .map(byteHex)
          .join("");
    } catch (e) { }
  };

  componentDidMount = async () => {
    var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
    var deptcode = encode("SNDG");
    $(document).ready(function () {
      $(".loadclickm").on("mouseover", function () {
        var id = $(this).attr("id");
        //$(".loadclickm").removeClass("active");
        $(".dashboard-sub").hide();
        if (id == "load") {
          $("#" + id).addClass("active");
          $(".dashboard-sub").show();
        }
      });

      $(".loadclickm").on("mouseout", function () {
        var id = $(this).attr("id");
        $(".dashboard-sub").hide();
      });
    });

    var defaultdate = moment
      .parseZone()
      .subtract(1, "days")
      .format("YYYY-MM-DD");
    this.setState({
      startDate: defaultdate,
      endDate: defaultdate
      //loadshow:'show-m',
      //overly:'show-m',
    });
    var urlpath = "/consignments/dashboardtemplatedepartmentdata";
    var dept_code = "";
    var pagetitle;
    if (this.props.match.path == "/snddashboardtemplate") {
      dept_code = "SNDG";
      pagetitle = "Sales and Dispatch Dashboard";
    }

    if (this.props.match.path == "/tnpdashboardtemplate") {
      dept_code = "LOG-TNP";
      pagetitle = "Train and Production";
    }

    if (this.props.match.path == "/prtdashboardtemplate") {
      dept_code = "LOG-PRT";
      pagetitle = "Spare Parts";
    }

    if (this.props.match.path == "/scdashboardtemplate") {
      dept_code = "LOG-SC";
      pagetitle = "Supply Chain";
    }

    this.setState({
      dept_code: dept_code,
      pagetitle: pagetitle
    });

    this.logPageView(); // Log page view to GA
    let eventAction =
      googleAnalytics.page.actionType.gridAction +
      " - " +
      window.location.pathname;
    this.setState({ eventAction: eventAction });

    var reqparms = {
      startDate: defaultdate,
      endDate: defaultdate,
      dept_code: encode(dept_code)
    };
    await redirectURL
      .post(urlpath, reqparms)
      .then(response => {
        var recordsarr = response.data.message;
        // console.log("Rcords ", JSON.stringify(recordsarr));
        var records = [];
        recordsarr.map(item => {
          // console.log('LoopedItem', item)
          var zerodays;
          var onedays;
          var twodays;
          var activezerodays;
          var activeonedays;
          var activetwodays;
          var criticalset;
          //console.log("item.transit_delay_days ", typeof parseInt(item.transit_delay_days))
          // if(parseInt(item.transit_delay_days) == 0 ||
          // parseInt(item.transit_delay_days) == 1 ||
          // parseInt(item.transit_delay_days) == 2)
          // {
          var set;
          var status;
          var activeset;
          var consoletxt =
            item.dept_code +
            "::" +
            item.dept_name +
            "===" +
            "Days: " +
            parseInt(item.transit_delay_days) +
            ",  Dept : " +
            item.dept_name +
            ", Status: " +
            item.status +
            ",  Set:" +
            item.set;
          // console.log("response data", consoletxt)
          if (
            parseInt(item.transit_delay_days) == 0 &&
            parseInt(item.set) == 1 &&
            (parseInt(item.status) == 2 || item.status == "In ICD Ghari")
          ) {
            zerodays = 1;
          }

          if (
            parseInt(item.transit_delay_days) == 1 &&
            parseInt(item.set) == 1 &&
            (parseInt(item.status) == 2 || item.status == "In ICD Ghari")
          ) {
            onedays = 1;
          }

          if (
            parseInt(item.transit_delay_days) == 2 &&
            parseInt(item.set) == 1 &&
            (parseInt(item.status) == 2 || item.status == "In ICD Ghari")
          ) {
            twodays = 1;
          }

          if (parseInt(item.set) == 1 && parseInt(item.status) != -1) {
            set = 1;
          } else {
            set = 0;
          }
          if (parseInt(item.status) != -1) {
            if (parseInt(item.status) == 2 && parseInt(item.set) == 1) {
              status = 1;
            } else {
              status = 0;
            }
          }
          if (parseInt(item.set) == 2 && parseInt(item.status) != -1) {
            activeset = 1;
          } else {
            activeset = 0;
          }

          if (
            parseInt(item.transit_delay_days) == 0 &&
            parseInt(item.set) == 2 &&
            (parseInt(item.status) == 2 || item.status == "In ICD Ghari")
          ) {
            activezerodays = 1;
          }

          if (
            parseInt(item.transit_delay_days) == 1 &&
            parseInt(item.set) == 2 &&
            (parseInt(item.status) == 2 || item.status == "In ICD Ghari")
          ) {
            activeonedays = 1;
          }

          if (
            parseInt(item.transit_delay_days) == 2 &&
            parseInt(item.set) == 2 &&
            (parseInt(item.status) == 2 || item.status == "In ICD Ghari")
          ) {
            activetwodays = 1;
          }

          // console.log("activezerodays ", activezerodays)
          if (
            parseInt(item.set) == 2 &&
            parseInt(item.status) == 2 &&
            item.transit_delay_days == "Critical"
          ) {
            criticalset = 1;
          } else {
            criticalset = 0;
          }

          //console.log("item.delivery_order ", item.delivery_order)
          if (item.dept_name != "-1" && item.dept_name != "NA") {
            //console.log("item.consigner_code ", item.consigner_code)
            var itemdata = {
              consigner_code: item.consigner_code,
              consignment_code: item.consignment_code,
              zone: item.zone,
              consignee_area: item.consignee_area,
              dept_code: item.dept_code,
              dept_name: item.dept_name,
              invoice_date: item.invoice_date,
              transit_delay_days_0:
                typeof zerodays == "undefined" || zerodays == -1 ? 0 : zerodays,
              transit_delay_days_1:
                typeof onedays == "undefined" || onedays == -1 ? 0 : onedays,
              transit_delay_days_2:
                typeof twodays == "undefined" || twodays == -1 ? 0 : twodays,
              uom: item.uom,
              delivery_order: item.delivery_order,
              set: typeof set == "undefined" ? 0 : set,
              originalset: item.set,
              status: typeof status == "undefined" ? 0 : status,
              originalstatus: item.status,
              extra_details: item.extra_details,
              activeset: typeof activeset == "undefined" ? 0 : activeset,
              active_transit_delay_days_0:
                typeof activezerodays == "undefined" || activezerodays == -1
                  ? 0
                  : activezerodays,
              active_transit_delay_days_1:
                typeof activeonedays == "undefined" || activeonedays == -1
                  ? 0
                  : activeonedays,
              active_transit_delay_days_2:
                typeof activetwodays == "undefined" || activetwodays == -1
                  ? 0
                  : activetwodays,
              criticalset:
                typeof criticalset == "undefined" || criticalset == -1
                  ? 0
                  : criticalset
            };
            records.push(itemdata);
          }
        });
        // console.log("records", records)
        var columnDefs = [
          {
            headerClass: ["cellTopHeaderTemplate"],
            headerName: "Department",
            field: "dept_name",
            rowGroup: true,
            width: 300,
            cellClass: "cellTopHeaderTemplate16",
            //pivot:true,
            hide: true,
            chartDataType: "category"
          },
          {
            field: "consigner_code",
            rowGroup: true,
            cellClass: "cellTopHeaderTemplate14",
            hide: true
            //chartDataType: 'category',
          },
          {
            field: "invoice_date",
            //rowGroup: true,
            cellClass: "cellTopHeaderTemplate14",
            hide: true
            //chartDataType: 'category',
          },
          {
            field: "consignment_code",
            //rowGroup: true,
            cellClass: "cellTopHeaderTemplate14",
            hide: true
            //chartDataType: 'category',
          },
          {
            headerName: "UOM",
            field: "uom",
            width: 120,
            cellClass: ["cellTopHeaderTemplatenb"],
            chartDataType: "excluded",
            valueGetter: function (params) {
              // console.log(params.node.key);
              // if(typeof params.data == 'undefined')
              // {
              //   return "Trucks";
              // }
              // else{
              if (params.node.key == "Sales & Dispatch") {
                return "Trucks";
              } else if (params.node.key == "Spare Parts (Domestic)") {
                return "Consignments";
              } else if (params.node.key == "Spare Parts (Courier)") {
                return "Consignments";
              } else if (
                params.node.key == "Spare Parts (Container Exports)" ||
                params.node.key == "Spares (Container Exports)"
              ) {
                return "Containers";
              } else if (params.node.key == "Production Parts") {
                return "Trucks";
              } else if (params.node.key == "Container Imports") {
                return "Containers";
              } else if (params.node.key == "Container Exports (KDO)") {
                return "Containers";
              } else if (params.node.key == "Coil Imports") {
                return "Coils";
              }
              // }
            }
            //hide:true
          },

          {
            headerName: "For This Period",
            headerClass: ["cellTopHeaderTemplateH", "cellstylegrid"],
            children: [
              {
                headerName: "Total for selected period",
                headerClass: ["cellstylegrid"],
                field: "set",
                cellClass: ["cellTopHeaderTemplate16", "cellstylegrid", "cpointer"],
                // chartDataType: 'series',
                width: 90,
                chartDataType: "excluded"
                //hide:true
              },
              {
                headerName: "Active for selected period",
                headerClass: ["cellstylegrid"],
                field: "status",
                width: 100,
                chartDataType: "excluded",
                cellClass: ["cellTopHeaderTemplate16", "cellstylegrid", "cpointer"]
                //chartDataType: 'series',
                //hide:true
              }
            ]
          },
          {
            headerName: "Delay Meter",
            headerClass: ["cellTopHeaderTemplateH", "cellstylegrid"],
            children: [
              {
                headerClass: ["cellTopHeaderTemplate14", "cellstylegrid"],
                headerName: "On Time",
                field: "transit_delay_days_0",
                width: 70,
                cellClass: [
                  "cellTopHeaderTemplate16",
                  "bgColorSuccessLight",
                  "cellstylegrid",
                  "cpointer"
                ],
                chartDataType: "series"
              },
              {
                headerClass: ["cellTopHeaderTemplate14", "cellstylegrid"],
                headerName: "1-2 Days",
                field: "transit_delay_days_1",
                width: 70,
                cellClass: [
                  "cellTopHeaderTemplate16",
                  "cellstylegridDY",
                  "cellstylegrid",
                  "cpointer"
                ],
                chartDataType: "series"
              },
              {
                headerClass: [
                  "cellTopHeaderTemplate14",
                  "cellstylegrid",
                  "cpointer"
                ],
                headerName: ">2 Day",
                field: "transit_delay_days_2",
                width: 70,
                cellClass: [
                  "cellTopHeaderTemplate16",
                  "bgColorDangerMedium",
                  "cellstylegrid",
                  "cpointer"
                ],
                chartDataType: "series"
              }
            ]
          },

          {
            headerName: "Cummulative As On Date",
            headerClass: ["cellTopHeaderTemplateH", "cellstylegridB"],
            children: [
              {
                headerClass: ["cellstylegridB"],
                headerName: "Active as on date (Cummulative)",
                field: "activeset",
                width: 130,
                cellClass: [
                  "cellTopHeaderTemplate16",
                  "cellstylegridB",
                  "cpointer"
                ],
                chartDataType: "category"
                //hide:true
              },

              {
                headerClass: ["cellTopHeaderTemplate14", "cellstylegridB"],
                headerName: "On Time",
                field: "active_transit_delay_days_0",
                width: 70,
                cellClass: [
                  "cellTopHeaderTemplate16",
                  "bgColorSuccessLight",
                  "cellstylegridB",
                  "cpointer"
                ],
                chartDataType: "series"
              },
              {
                headerClass: ["cellTopHeaderTemplate14", "cellstylegridB"],
                headerName: "1-2 Days",
                field: "active_transit_delay_days_1",
                width: 70,
                cellClass: [
                  "cellTopHeaderTemplate16",
                  "cellstylegridDY",
                  "cellstylegridB",
                  "cpointer"
                ],
                chartDataType: "series"
              },
              {
                headerClass: ["cellTopHeaderTemplate14", "cellstylegridB"],
                headerName: ">2 Day",
                field: "active_transit_delay_days_2",
                width: 70,
                cellClass: [
                  "cellTopHeaderTemplate16",
                  "bgColorDangerMedium",
                  "cellstylegridB",
                  "cpointer"
                ],
                chartDataType: "series"
              },
              {
                headerClass: ["cellTopHeaderTemplate14", "cellstylegridB"],
                headerName: "Critical",
                field: "criticalset",
                width: 90,
                cellClass: [
                  "cellTopHeaderTemplate16",
                  "red",
                  "cellstylegridB",
                  "cpointer"
                ],
                chartDataType: "series"
              }
            ]
          }
        ]
        //console.log("Respons ", JSON.stringify(records))
        //updateData(records);
        this.setState({
          rowData: records,
          allrecords: recordsarr,
          columnDefs: columnDefs,
          loadshow: "show-n",
          overly: "show-n"
        });
      })
      .catch(function (e) {
        console.log("Error ", e);
      });
    await this.setState({
      loadshow: "show-m",
      overly: "show-m"
    });

    /*Chart Summary*/
    await redirectURL
      .post("/consignments/dashboardsummary")
      .then(response => {
        var recordsarr = response.data.message;
        //console.log("Respons ", recordsarr)
        var summaryarr = [];
        var ytitle = "";
        recordsarr.map(item => {
          //console.log("item.dept_code ", item.dept_code)
          if (item.dept_code == "SNDG") {
            ytitle = "Trucks";
          }
          if (item.dept_code == "LOG-PRT") {
            ytitle = "Consignments";
          }
          if (item.dept_code == "LOG-PRT (Courier)") {
            ytitle = "Consignments";
          }
          if (item.dept_code == "LOG-SC") {
            ytitle = "Containers";
          }
          if (item.dept_code == "LOG-TNP") {
            ytitle = "Trucks";
          }
          if (item.dept_code == "LOG-SC (Exports)") {
            ytitle = "Containers";
          }
          summaryarr.push({
            categories: ["Total", "On Time", "Delayed", "Critical"],
            series: [
              {
                name: "Total",
                data: [item.total_active_count],
                percent: 0,
                code: item.dept_name.replace(
                  /[&\/\\#,+()$~%.'" :*?<>{}]/g,
                  "_"
                ),
                dept_name: item.dept_name,
                critical_list: item.critical_tpt_list,
                critical_data: item.critical_data,
                dept_code: item.dept_code
              },
              {
                name: "On Time",
                data: [item.delay_counts.OnTime],
                percent: Math.round(
                  (item.delay_counts.OnTime / item.total_active_count) * 100
                ),
                code: item.dept_name.replace(
                  /[&\/\\#,+()$~%.'" :*?<>{}]/g,
                  "_"
                ),
                dept_name: item.dept_name,
                critical_list: item.critical_tpt_list,
                critical_data: item.critical_data,
                dept_code: item.dept_code
              },
              {
                name: "Delayed",
                data: [item.delay_counts.Delayed],
                percent: Math.round(
                  (item.delay_counts.Delayed / item.total_active_count) * 100
                ),
                code: item.dept_name.replace(
                  /[&\/\\#,+()$~%.'" :*?<>{}]/g,
                  "_"
                ),
                dept_name: item.dept_name,
                critical_list: item.critical_tpt_list,
                critical_data: item.critical_data,
                dept_code: item.dept_code
              },
              {
                name: "Critical",
                data: [item.delay_counts.Critical],
                percent: Math.round(
                  (item.delay_counts.Critical / item.total_active_count) * 100
                ),
                code: item.dept_name.replace(
                  /[&\/\\#,+()$~%.'" :*?<>{}]/g,
                  "_"
                ),
                dept_name: item.dept_name,
                critical_list: item.critical_tpt_list,
                critical_data: item.critical_data,
                dept_code: item.dept_code
              }
            ],
            total_active_count: item.total_active_count,
            critical: Math.round(
              (item.delay_counts.Critical / item.total_active_count) * 100
            ),
            onTime: Math.round(
              (item.delay_counts.OnTime / item.total_active_count) * 100
            ),
            delayed: Math.round(
              (item.delay_counts.Delayed / item.total_active_count) * 100
            ),
            title: item.dept_name,
            yaxistitle: ytitle,
            dept_code: item.dept_code,
            code: item.dept_name.replace(/[&\/\\#,+()$~%.'" :*?<>{}]/g, "_")
          });
        });

        this.setState({
          summarydata: summaryarr,
          originaldata: recordsarr,
          loadshow: "show-n",
          overly: "show-n"
        });
      })
      .catch(function (e) {
        console.log("Error ", e);
      });
  };

  logPageView = () => {
    try {
      if (googleAnalytics.page.enableGA) {
        googleAnalytics.logPageView();
        let eventGridAction =
          googleAnalytics.page.actionType.gridAction +
          " - " +
          window.location.pathname;
        let eventCounterAction =
          googleAnalytics.page.actionType.counterAction +
          " - " +
          window.location.pathname;
        let eventFormAction =
          googleAnalytics.page.actionType.formAction +
          " - " +
          window.location.pathname;
        this.setState({
          eventGridAction: eventGridAction,
          eventCounterAction: eventCounterAction,
          eventFormAction: eventFormAction
        });
      } else {
        console.log("GA disabled...");
      }
    } catch (error) {
      console.error("Error occured while logging to GA, error = ", error);
    }
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.params = params;
    this.gridColumnApi = params.columnApi;
  };

  onGridState = () => {
    console.log("params.api ", this.gridApi);
    var childrows = this.gridApi.rowModel.rowsToDisplay;
    var category = [];
    var serires1 = [];
    var serires2 = [];
    var serires3 = [];

    var activecategory = [];
    var activeserires1 = [];
    var activeserires2 = [];
    var activeserires3 = [];
    var criticalsetarr = [];
    if (childrows.length > 0) {
      childrows.map(item => {
        category.push(item.key);
        serires1.push(item.aggData.transit_delay_days_0);

        serires2.push(item.aggData.transit_delay_days_1);

        serires3.push(item.aggData.transit_delay_days_2);

        activecategory.push(item.key);
        activeserires1.push(item.aggData.active_transit_delay_days_0);

        activeserires2.push(item.aggData.active_transit_delay_days_1);

        activeserires3.push(item.aggData.active_transit_delay_days_2);
        criticalsetarr.push(item.aggData.criticalset);
      });
    }
    this.setState({
      serires: [
        {
          name: "On Time",
          data: serires1,
          dataLabels: {
            enabled: false,
            color: "#00f0b5"
          }
        },
        {
          name: "1 Day",
          data: serires2,
          dataLabels: {
            enabled: false,
            color: "#fff493"
          }
        },
        {
          name: ">1 Day",
          data: serires3,
          dataLabels: {
            enabled: false,
            color: "#f88fa2"
          }
        }
      ],
      categories: category,

      activeserires: [
        {
          name: "On Time",
          data: activeserires1
        },
        {
          name: "1 Day",
          data: activeserires2
        },
        {
          name: ">1 Day",
          data: activeserires3
        },
        {
          name: "Critical",
          data: criticalsetarr
        }
      ],
      activecategories: category
    });

    this.gridApi.groupRowRendererParams = {
      suppressCount: true
    };
  };
  onGridReady1 = params => {
    this.gridApi1 = params.api;
    this.params1 = params;
    this.gridColumnApi1 = params.columnApi;
  };

  onGridState1 = () => {
    console.log("params.api ", this.gridApi1);
    var childrows = this.gridApi1.rowModel.rowsToDisplay;
    var category = [];
    var serires1 = [];
    var serires2 = [];
    var serires3 = [];

    var activecategory = [];
    var activeserires1 = [];
    var activeserires2 = [];
    var activeserires3 = [];
    var criticalsetarr = [];
    if (childrows.length > 0) {
      childrows.map(item => {
        category.push(item.key);
        serires1.push(item.aggData.transit_delay_days_0);

        serires2.push(item.aggData.transit_delay_days_1);

        serires3.push(item.aggData.transit_delay_days_2);

        activecategory.push(item.key);
        activeserires1.push(item.aggData.active_transit_delay_days_0);

        activeserires2.push(item.aggData.active_transit_delay_days_1);

        activeserires3.push(item.aggData.active_transit_delay_days_2);
        criticalsetarr.push(item.aggData.criticalset);
      });
    }
    this.setState({
      serires: [
        {
          name: "On Time",
          data: serires1,
          dataLabels: {
            enabled: false,
            color: "#00f0b5"
          }
        },
        {
          name: "1 Day",
          data: serires2,
          dataLabels: {
            enabled: false,
            color: "#fff493"
          }
        },
        {
          name: ">1 Day",
          data: serires3,
          dataLabels: {
            enabled: false,
            color: "#f88fa2"
          }
        }
      ],
      categories: category,

      activeserires: [
        {
          name: "On Time",
          data: activeserires1
        },
        {
          name: "1 Day",
          data: activeserires2
        },
        {
          name: ">1 Day",
          data: activeserires3
        },
        {
          name: "Critical",
          data: criticalsetarr
        }
      ],
      activecategories: category
    });

    this.gridApi1.groupRowRendererParams = {
      suppressCount: true
    };
  };

  onGridReady2 = params => {
    this.gridApi2 = params.api;
    this.params2 = params;
    this.gridColumnApi2 = params.columnApi;
  };

  onGridState2 = () => {
    var childrows = this.gridApi2.rowModel.rowsToDisplay;
    var category = [];
    var serires1 = [];
    var serires2 = [];
    var serires3 = [];

    var activecategory = [];
    var activeserires1 = [];
    var activeserires2 = [];
    var activeserires3 = [];
    var criticalsetarr = [];
    if (childrows.length > 0) {
      childrows.map(item => {
        category.push(item.key);
        serires1.push(item.aggData.transit_delay_days_0);

        serires2.push(item.aggData.transit_delay_days_1);

        serires3.push(item.aggData.transit_delay_days_2);

        activecategory.push(item.key);
        activeserires1.push(item.aggData.active_transit_delay_days_0);

        activeserires2.push(item.aggData.active_transit_delay_days_1);

        activeserires3.push(item.aggData.active_transit_delay_days_2);
        criticalsetarr.push(item.aggData.criticalset);
      });
    }
    this.setState({
      serires: [
        {
          name: "On Time",
          data: serires1,
          dataLabels: {
            enabled: false,
            color: "#00f0b5"
          }
        },
        {
          name: "1 Day",
          data: serires2,
          dataLabels: {
            enabled: false,
            color: "#fff493"
          }
        },
        {
          name: ">1 Day",
          data: serires3,
          dataLabels: {
            enabled: false,
            color: "#f88fa2"
          }
        }
      ],
      categories: category,

      activeserires: [
        {
          name: "On Time",
          data: activeserires1
        },
        {
          name: "1 Day",
          data: activeserires2
        },
        {
          name: ">1 Day",
          data: activeserires3
        },
        {
          name: "Critical",
          data: criticalsetarr
        }
      ],
      activecategories: category
    });

    this.gridApi2.groupRowRendererParams = {
      suppressCount: true
    };
  };
  expandAll = () => {
    this.gridApi.expandAll();
    this.gridApi1.expandAll();
    this.gridApi2.expandAll();
  };

  collapseAll = () => {
    this.gridApi.collapseAll();
    this.gridApi1.collapseAll();
    this.gridApi2.collapseAll();
  };

  groupRowAggNodes = nodes => {
    var result = {
      transit_delay_days_0: 0,
      transit_delay_days_1: 0,
      transit_delay_days_2: 0,
      active_transit_delay_days_0: 0,
      active_transit_delay_days_1: 0,
      active_transit_delay_days_2: 0,
      criticalset: 0,
      set: 0,
      status: 0,
      activeset: 0
    };
    var st = [];
    //console.log("se ",nodes)
    nodes.forEach(function (node) {
      var data = node.group ? node.aggData : node.data;
      // console.log("node ",data)
      //console.log("nodedata ",typeof data.active_transit_delay_days_0)
      if (typeof data.transit_delay_days_0 === "number") {
        result.transit_delay_days_0 += data.transit_delay_days_0;
      }
      if (typeof data.transit_delay_days_1 === "number") {
        result.transit_delay_days_1 += data.transit_delay_days_1;
      }
      if (typeof data.transit_delay_days_2 === "number") {
        result.transit_delay_days_2 += data.transit_delay_days_2;
      }

      if (typeof data.set === "number") {
        if (data.set != -1) {
          result.set += data.set;
        }
      }
      if (typeof data.activeset === "number") {
        if (data.activeset != -1) {
          result.activeset += data.activeset;
        }
      }
      if (typeof data.status === "number") {
        if (data.status != -1 && data.status != 0) {
          result.status += data.status;
          st.push("a");
        }
      }

      if (typeof data.active_transit_delay_days_0 === "number") {
        result.active_transit_delay_days_0 += parseInt(
          data.active_transit_delay_days_0
        );
      }
      if (typeof data.active_transit_delay_days_1 === "number") {
        result.active_transit_delay_days_1 += parseInt(
          data.active_transit_delay_days_1
        );
      }
      if (typeof data.active_transit_delay_days_2 === "number") {
        result.active_transit_delay_days_2 += parseInt(
          data.active_transit_delay_days_2
        );
      }

      if (typeof data.criticalset === "number") {
        result.criticalset += data.criticalset;
      }
      //console.log("result  ", result)
    });
    //console.log(st.length)
    // console.log("asda ", result)
    return result;
  };
  onFirstDataRendered = params => {
    //console.log("onFIrst chart,", params)
    var childrows = params.api.rowModel.rowsToDisplay;
    var category = [];
    var serires1 = [];
    var serires2 = [];
    var serires3 = [];

    var activecategory = [];
    var activeserires1 = [];
    var activeserires2 = [];
    var activeserires3 = [];
    var criticalsetarr = [];
    if (childrows.length > 0) {
      childrows.map(item => {
        category.push(item.key);
        serires1.push(item.aggData.transit_delay_days_0);

        serires2.push(item.aggData.transit_delay_days_1);

        serires3.push(item.aggData.transit_delay_days_2);

        activecategory.push(item.key);
        activeserires1.push(parseInt(item.aggData.active_transit_delay_days_0));

        activeserires2.push(parseInt(item.aggData.active_transit_delay_days_1));

        activeserires3.push(parseInt(item.aggData.active_transit_delay_days_2));
        criticalsetarr.push(item.aggData.criticalset);
      });
    }
    var originalchartdata = [
      {
        series1: serires1,
        series2: serires2,
        series3: serires3,
        category: category,
        activeseries1: activeserires1,
        activeseries2: activeserires2,
        activeseries3: activeserires3,
        activecategory: activecategory,
        criticalset: criticalsetarr
      }
    ];
    var ltitle = "Delay Meter for selected period";
    var stitle = "";
    var altitle = "Delay Meter as on Date(Cummulative)";
    var astitle = "";
    this.setState({
      originalchartdata: originalchartdata,
      serires: [
        {
          name: "On Time",
          data: serires1,
          dataLabels: {
            enabled: false,
            color: "#00f0b5"
          }
        },
        {
          name: "1 Day",
          data: serires2,
          dataLabels: {
            enabled: false,
            color: "#fff493"
          }
        },
        {
          name: ">1 Day",
          data: serires3,
          dataLabels: {
            enabled: false,
            color: "#f88fa2"
          }
        }
      ],
      categories: category,

      activeserires: [
        {
          name: "On Time",
          data: activeserires1
        },
        {
          name: "1 Day",
          data: activeserires2
        },
        {
          name: ">1 Day",
          data: activeserires3
        },
        {
          name: "Critical",
          data: criticalsetarr
        }
      ],
      activecategories: category,

      leftSubTitle: stitle,
      activeleftSubTitle: astitle
    });
    if (this.state.dept_code == "SNDG") {
      setTimeout(function () {
        params.api.getDisplayedRowAtIndex(0).setExpanded(true);
      }, 0);
    }
  };

  handlerStartDateTime = (event, currentDate, selectedDate) => {
    var d = new Date(event._d);

    var startdate =
      d.getFullYear() +
      "-" +
      (d.getMonth() + 1) +
      "-" +
      (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());

    this.setState({
      startDate: startdate
    });
    //let value = event.target.value;
    //this.setState({'startDate':value});
  };

  handlerEndDateTime = (event, currentDate, selectedDate) => {
    var d = new Date(event._d);

    var edate =
      d.getFullYear() +
      "-" +
      (d.getMonth() + 1) +
      "-" +
      (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());
    this.setState({
      endDate: edate
    });
  };
  // locationoptions() {
  //   var items = [];
  //   // console.log("locations ",this.props.locations)
  //   var locations = this.state.locations;
  //   //console.log("locations ",locations)
  //   if (locations.length > 0) {
  //     items.push({ value: "all", label: "All" });
  //     locations.forEach(item => {
  //       items.push({ value: item.code, label: item.label });
  //     });
  //   }
  //   //console.log("Locas ", items)
  //   return items;
  // }
  // transporteroptions() {
  //   var items = [];
  //   var transporters = this.state.transporters;
  //   items.push({ value: "all", label: "All" });
  //   if (transporters.length > 0) {
  //     transporters.forEach(item => {
  //       items.push({ value: item.code, label: item.label });
  //     });
  //   }
  //   //console.log("Transps ", items)
  //   return items;
  // }

  onSelectType = zone => {
    this.setState({ zone }, () => {
      console.log(`Location Option selected:`, this.state.zone)

    }
    );

    this.setState({
      errorMessage: "",
      successMessage: ""
    });
  };
  onSelectTransporter = transporter => {
    this.setState({ transporter }, () =>
      console.log(`Transporter Option selected:`, this.state.transporter)
    );

    this.setState({
      errorMessage: "",
      successMessage: ""
    });
  };

  onSelectArea = areaVal => {
    this.setState(
      { areaVal }, () => console.log(this.state.areaVal)
    );

    this.setState({
      errorMessage: '',
      successMessage: ''
    })
  }

  onSelectCarrierType = carriertype => {
    this.setState({ carriertype }, () =>
      console.log("Option sekected ", this.state.carriertype)
    );
  };
  onClickSummaryData = event => {
    if (googleAnalytics.page.enableGA) {
      let pageTitle = this.state.pagetitle;
      let eventOptions = {
        category: pageTitle,
        action: this.state.eventGridAction,
        label: googleAnalytics.page.action.formAction
      };
      googleAnalytics.logEvent(eventOptions);
    }
    this.setState({
      //loadshow:'show-m',
      //overly:'show-m',
    });

    var urlpath = "";
    urlpath = "/consignments/dashboardtemplatedepartmentdata";
    // if(this.props.match.path == "/snddashboardtemplate")
    // {
    // 	urlpath = '/consignments/dashboardtemplatedepartmentdata';
    // }

    // if(this.props.match.path == "/tnpdashboardtemplate")
    // {
    // 	urlpath = '/consignments/dashboardtemplatedepartmentdata';
    // }
    // if(this.props.match.path == "/prtdashboardtemplate")
    // {
    // 	urlpath = '/consignments/dashboardtemplatedepartmentdata';
    // }
    var allzones = this.state.zone;
    var zones = []
    if (allzones != null) {
      if (allzones.length > 0) {
        allzones.map((item) => {
          zones.push(item.value)
        })
      }
    }
    else {
      zones.push('all');
    }
    // console.log("allzone ", zones);
    var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
    var reqparms = {
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      dept_code: encode(this.state.dept_code),
      zones: zones,
    };
    redirectURL.post(urlpath, reqparms).then(response => {
      var recordsarr = response.data.message;
      var records = [];
      recordsarr.map(item => {
        var zerodays;
        var onedays;
        var twodays;
        var activezerodays;
        var activeonedays;
        var activetwodays;
        var criticalset;
        //console.log("item.transit_delay_days ", typeof parseInt(item.transit_delay_days))
        // if(parseInt(item.transit_delay_days) == 0 ||
        // parseInt(item.transit_delay_days) == 1 ||
        // parseInt(item.transit_delay_days) == 2)
        // {
        var set;
        var status;
        var activeset;
        var consoletxt =
          parseInt(item.transit_delay_days) +
          " " +
          item.dept_name +
          " " +
          item.status +
          " " +
          item.set;
        //console.log("container Imports ", consoletxt)
        if (
          parseInt(item.transit_delay_days) == 0 &&
          parseInt(item.set) == 1 &&
          (parseInt(item.status) == 2 || item.status == "In ICD Ghari")
        ) {
          zerodays = 1;
        }

        if (
          parseInt(item.transit_delay_days) == 1 &&
          parseInt(item.set) == 1 &&
          (parseInt(item.status) == 2 || item.status == "In ICD Ghari")
        ) {
          onedays = 1;
        }

        if (
          parseInt(item.transit_delay_days) == 2 &&
          parseInt(item.set) == 1 &&
          (parseInt(item.status) == 2 || item.status == "In ICD Ghari")
        ) {
          twodays = 1;
        }

        if (parseInt(item.set) == 1 && parseInt(item.status) != -1) {
          set = 1;
        } else {
          set = 0;
        }
        if (parseInt(item.status) != -1) {
          if (parseInt(item.status) == 2 && parseInt(item.set) == 1) {
            status = 1;
          } else {
            status = 0;
          }
        }
        if (parseInt(item.set) == 2 && parseInt(item.status) != -1) {
          activeset = 1;
        } else {
          activeset = 0;
        }

        if (
          parseInt(item.set) == 2 &&
          parseInt(item.status) == 2 &&
          item.transit_delay_days == "Critical"
        ) {
          criticalset = 1;
        } else {
          criticalset = 0;
        }

        if (
          parseInt(item.transit_delay_days) == 0 &&
          parseInt(item.set) == 2 &&
          (parseInt(item.status) == 2 || item.status == "In ICD Ghari")
        ) {
          activezerodays = 1;
        }

        if (
          parseInt(item.transit_delay_days) == 1 &&
          parseInt(item.set) == 2 &&
          (parseInt(item.status) == 2 || item.status == "In ICD Ghari")
        ) {
          activeonedays = 1;
        }

        if (
          parseInt(item.transit_delay_days) == 2 &&
          parseInt(item.set) == 2 &&
          (parseInt(item.status) == 2 || item.status == "In ICD Ghari")
        ) {
          activetwodays = 1;
        }
        //console.log("item.delivery_order ", item.delivery_order)
        if (item.dept_name != "-1" && item.dept_name != "NA") {
          var itemdata = {
            consigner_code: item.consigner_code,
            consignment_code: item.consignment_code,
            zone: item.zone,
            consignee_area: item.consignee_area,
            dept_code: item.dept_code,
            dept_name: item.dept_name,
            invoice_date: item.invoice_date,
            transit_delay_days_0:
              typeof zerodays == "undefined" || zerodays == -1 ? 0 : zerodays,
            transit_delay_days_1:
              typeof onedays == "undefined" || onedays == -1 ? 0 : onedays,
            transit_delay_days_2:
              typeof twodays == "undefined" || twodays == -1 ? 0 : twodays,
            uom: item.uom,
            delivery_order: item.delivery_order,
            extra_details: item.extra_details,
            set: typeof set == "undefined" ? 0 : set,
            originalset: item.set,
            status: typeof status == "undefined" ? 0 : status,
            originalstatus: item.status,
            activeset: typeof activeset == "undefined" ? 0 : activeset,
            active_transit_delay_days_0:
              typeof activezerodays == "undefined" || activezerodays == -1
                ? 0
                : activezerodays,
            active_transit_delay_days_1:
              typeof activeonedays == "undefined" || activeonedays == -1
                ? 0
                : activeonedays,
            active_transit_delay_days_2:
              typeof activetwodays == "undefined" || activetwodays == -1
                ? 0
                : activetwodays,
            criticalset:
              typeof criticalset == "undefined" || criticalset == -1
                ? 0
                : criticalset
          };
          records.push(itemdata);
        }
      });
      //console.log("Respons ", records)
      //updateData(records);
      this.setState({
        rowData: records,
        allrecords: recordsarr,
        loadshow: "show-n",
        overly: "show-n"
      });

      this.onFirstDataRendered(this.params);
    })
      .catch(function (e) {
        console.log("Error ", e);
      });
  };

  getChartToolbarItems = params => {
    return [];
  };
  onRowGroupOpened = async event => {
    //console.log("rowModel ",this.gridApi.rowModel);
    var rowmodels = this.gridApi.rowModel.rowsToDisplay;
    var flagone = [];

    await rowmodels.map(item => {
      if (item.expanded == false) {
      } else {
        flagone.push(1);
      }
    });
    //console.log("flag ",flagone)

    //console.log("checkc ", event.node)
    // if (event.node.expanded) {
    //   var expandedNode = event.node;
    //   event.api.forEachNode(function(node) {
    //       if (node.group && node.expanded) {
    //           var contract = true;
    //           var pointer = expandedNode;
    //           while (pointer) {
    //               if (pointer===node) {
    //                   contract = false;
    //               }
    //               pointer = pointer.parent;
    //           }
    //           if (contract) {
    //               node.setExpanded(false);
    //           }
    //       }
    //   });
    // }
    //console.log("sss ", event.node.rowIndex)
    //console.log("le ",event.node.childrenAfterGroup.length)

    if (flagone.length == 0) {
      var originalchartdata = this.state.originalchartdata;
      //console.log("originalchartdata ",originalchartdata)
      this.setState({
        serires: [
          {
            name: "On Time",
            data: originalchartdata[0].series1
          },
          {
            name: "1 Day",
            data: originalchartdata[0].series2
          },
          {
            name: ">1 Day",
            data: originalchartdata[0].series3
          }
        ],
        categories: originalchartdata[0].category,

        activeserires: [
          {
            name: "On Time",
            data: originalchartdata[0].activeseries1
          },
          {
            name: "1 Day",
            data: originalchartdata[0].activeseries2
          },
          {
            name: ">1 Day",
            data: originalchartdata[0].activeseries3
          },
          {
            name: "Critical",
            data: originalchartdata[0].criticalset
          }
        ],
        activecategories: originalchartdata[0].activecategory,
        leftSubTitle: "",
        activeleftSubTitle: ""
      });
    } else {
      var childrows = event.node.childrenAfterGroup;
      //console.log("event.node ",event.node)
      var category = [];
      var serires1 = [];
      var serires2 = [];
      var serires3 = [];
      var activecategory = [];
      var activeserires1 = [];
      var activeserires2 = [];
      var activeserires3 = [];
      var criticalsetarr = [];
      var sflag = 0;
      if (childrows.length > 0) {
        var sn = 0;
        childrows.map(item => {
          if (typeof item.aggData != "undefined") {
            //console.log("adas ",item)
            category.push(item.key);
            serires1.push(item.aggData.transit_delay_days_0);

            serires2.push(item.aggData.transit_delay_days_1);

            serires3.push(item.aggData.transit_delay_days_2);

            activecategory.push(item.key);
            activeserires1.push(item.aggData.active_transit_delay_days_0);

            activeserires2.push(item.aggData.active_transit_delay_days_1);

            activeserires3.push(item.aggData.active_transit_delay_days_2);
            criticalsetarr.push(item.aggData.criticalset);
          } else {
            if (item.parent.expanded == true) {
              if (sn == 0) {
                // console.log("adas ",item)

                category.push(item.parent.key);
                serires1.push(item.parent.aggData.transit_delay_days_0);

                serires2.push(item.parent.aggData.transit_delay_days_1);

                serires3.push(item.parent.aggData.transit_delay_days_2);

                activecategory.push(item.parent.key);
                activeserires1.push(
                  item.parent.aggData.active_transit_delay_days_0
                );

                activeserires2.push(
                  item.parent.aggData.active_transit_delay_days_1
                );

                activeserires3.push(
                  item.parent.aggData.active_transit_delay_days_2
                );
                criticalsetarr.push(item.parent.aggData.criticalset);
              }
            } else {
              sflag = 1;
              // category.push(item.parent.parent.key);
              // serires1.push(item.parent.parent.aggData.transit_delay_days_0);

              // serires2.push(item.parent.parent.aggData.transit_delay_days_1);

              // serires3.push(item.parent.parent.aggData.transit_delay_days_2);

              // activecategory.push(item.parent.parent.key);
              // activeserires1.push(item.parent.parent.aggData.active_transit_delay_days_0);

              // activeserires2.push(item.parent.parent.aggData.active_transit_delay_days_1);

              // activeserires3.push(item.parent.parent.aggData.active_transit_delay_days_2);
            }
          }
          sn = sn + 1;
        });
      }
      //console.log("sflag=",sflag)
      if (sflag == 1) {
        var subchildrows = await event.node.parent.childrenAfterGroup;
        if (subchildrows.length > 0) {
          //console.log("subchildrows ",subchildrows)
          await subchildrows.map(item => {
            category.push(item.key);
            serires1.push(item.aggData.transit_delay_days_0);

            serires2.push(item.aggData.transit_delay_days_1);

            serires3.push(item.aggData.transit_delay_days_2);

            activecategory.push(item.key);
            activeserires1.push(item.aggData.active_transit_delay_days_0);

            activeserires2.push(item.aggData.active_transit_delay_days_1);

            activeserires3.push(item.aggData.active_transit_delay_days_2);
            criticalsetarr.push(item.aggData.criticalset);
          });
        }
      }
      await this.setState({
        serires: [
          {
            name: "On Time",
            data: serires1
          },
          {
            name: "1 Day",
            data: serires2
          },
          {
            name: ">1 Day",
            data: serires3
          }
        ],
        categories: category,

        activeserires: [
          {
            name: "On Time",
            data: activeserires1
          },
          {
            name: "1 Day",
            data: activeserires2
          },
          {
            name: ">1 Day",
            data: activeserires3
          },
          {
            name: "Critical",
            data: criticalsetarr
          }
        ],
        activecategories: category,
        leftSubTitle: event.node.key,
        activeleftSubTitle: event.node.key
      });
    }
  };

  onFilterTextBoxChanged(eValue) {
    console.log("eValue ", eValue.target.value);
    this.onSearchValue();
    //this.gridApi.setQuickFilter(eValue);
  }

  onSearchValue =() => {
    //console.log("eValue ",eValue)
    //this.gridApi.setQuickFilter(eValue);
  }

  updateDelayRemarks(params) {
    //console.log(params);

    if (googleAnalytics.page.enableGA) {
      let pageTitle = this.state.pagetitle;
      let eventOptions = {
        category: pageTitle,
        action: this.state.eventGridAction,
        label: "Update Delay Remarks"
      };
      googleAnalytics.logEvent(eventOptions);
    }

    if (params.colDef.field == "spoc_delay_reason") {
      var e = params.data;
      try {
        if (e.spoc_delay_reason.replace(/\s/g, "").length) {
          if (this.state.dept_code == "LOG-SC") {
            var reqParams = {
              dept_code: this.state.dept_code,
              container_no: e.container_no,
              spoc_delay_reason: e.spoc_delay_reason,
              status: 1,
              modified_by: localStorage.getItem("email")
            };
          } else {
            // if(this.state.dept_code == "LOG-PRT (Courier)")
            // {
            //   var cc = e.consignment_code
            // }else{var cc = e.consignment_no}

            var reqParams = {
              dept_code: this.state.dept_code,
              // editedDataArr : editedDataArr,
              truck_no: e.truck_no,
              spoc_delay_reason: e.spoc_delay_reason,
              consignment_no: e.consignment_code,
              status: 2,
              modified_by: localStorage.getItem("email")
            };
          }

          var userrols = localStorage.getItem("roles");

          //console.log("splitrole ",splitrole)
          try {
            var splitrole = userrols.split(",");
            if (splitrole.length > 0) {
              if (
                splitrole.includes("tpt_sndg") == true ||
                splitrole.includes("tpt_prt") == true
              ) {
                reqParams.transporter = "yes";
              }
            }
          } catch (e) {
            console.log("Error ", e);
          }

          console.log(reqParams);
          redirectURL
            .post("/consignments/updateDelayReason", reqParams)
            .then(response => {
              // console.log(response);
              //   this.setState({
              //     show:true,
              //     basicTitle:"Updated Successfully.",
              //     basicType:"success",
              // });
            })
            .catch(function (e) {
              console.log("Error ", e);
            });
        } else {
          // console.log("empty");
        }
      } catch (e) { }
    }
  }

  onShowLegs = async params => {
    console.log("params.nody ", params.node)
    console.log("params ", params)
    // console.log("params.colDef ", params.colDef)

    if (googleAnalytics.page.enableGA) {
      let pageTitle = this.state.pagetitle;
      let eventOptions = {
        category: pageTitle,
        action: this.state.eventGridAction,
        label: "Show Legs Data"
      };
      googleAnalytics.logEvent(eventOptions);
    }
    var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

    var setter = 1;
    // if (
    //   params.node.key == "Spare Parts (Courier)" ||
    //   params.node.parent.key == "Spare Parts (Courier)"
    // ) {
    //   var cdept = "LOG-PRT (Courier)";
    // } else if (
    //   params.node.key == "Spare Parts (Domestic)" ||
    //   params.node.parent.key == "Spare Parts (Domestic)"
    // ) {
    //   var cdept = "LOG-PRT";
    // } else if (
    //   params.node.key == "Production Parts" ||
    //   params.node.parent.key == "Production Parts"
    // ) {
    //   var cdept = "LOG-TNP";
    // } else if (
    //   params.node.key == "Container Exports (KDO)" ||
    //   params.node.parent.key == "Container Exports (KDO)"
    // ) {
    //   var cdept = "LOG-SC";
    // } else if (
    //   params.node.key == "Container Exports (KDO)" ||
    //   params.node.parent.key == "Container Exports (KDO)"
    // ) {
    //   var cdept = "LOG-SC";
    // } else if (
    //   params.node.key == "Sales & Dispatch" ||
    //   params.node.parent.key == "Sales & Dispatch"
    // ) {
    var cdept = "SNDG";
    // } else {
    //   var cdept = this.state.dept_code;
    // }

    if (params.colDef.field == "criticalset") {
      var reqParams = {
        dept_code: encode(cdept)
      };
      if (params.node.field == "consigner_code") {
        reqParams.plant_location = params.node.key;
      }

      if (params.node.field == "zone") {
        reqParams.zone = params.node.key;
      }

      if (params.node.field == "consignee_area") {
        reqParams.consignee_area = params.node.key;
      }
      redirectURL
        .post("/consignments/summarysecondleveldata", reqParams)
        .then(async res => {
          // console.log("res.data.message here", res.data);
          var criticaldata = res.data.message.critical_data;
          console.log("criticaldata", criticaldata);
          // console.log(dupDeptCode);
          var filteredData = "";

          filteredData = criticaldata;
          //console.log(filteredData.length);
          //console.log("filteredData ",filteredData);

          this.setState({
            rowCriticalData: filteredData,
            loadshow: "show-n",
            overly: "show-m"
          });
        })
        .catch(function (e) {
          console.log("Error ", e);
        });
    }

    if (
      params.colDef.field == "activeset" ||
      params.colDef.field == "active_transit_delay_days_0" ||
      params.colDef.field == "active_transit_delay_days_1" ||
      params.colDef.field == "active_transit_delay_days_2" ||
      params.colDef.field == "criticalset"
    ) {
      setter = 2;
    }
    if (params.colDef.field == "active_transit_delay_days_0") {
      var delay_type = 0;
    }
    if (params.colDef.field == "active_transit_delay_days_1") {
      var delay_type = 1;
    }
    if (params.colDef.field == "active_transit_delay_days_2") {
      var delay_type = 2;
    }

    if (params.colDef.field == "criticalset") {
      var delay_type = "Critical";
    }

    if (params.colDef.field == "transit_delay_days_0") {
      var delay_type = 0;
    }
    if (params.colDef.field == "transit_delay_days_1") {
      var delay_type = 1;
    }
    if (params.colDef.field == "transit_delay_days_2") {
      delay_type = 2;
    }

    // if (params.node.parent.id == "ROOT_NODE_ID") {
    //   var plant_location = "ALL";
    // } else {
    //   var plant_location = params.node.key;
    // }
    if (params.colDef.field == "status") {
      var data_type = "ACTIVE";
    } else {
      data_type = "";
    }    

    var reqparsm = {
      // plant_location: plant_location,
      dept_code: cdept,
      delay_type: delay_type,
      data_type: data_type,
      set: setter,
      from_date: this.state.startDate + " 00:00:00",
      to_date: this.state.endDate + " 23:59:59"
    };
    //changes as per 23-06-2022 for grid legs data
    if(params.node.field == "consigner_code"){
      reqparsm["plant_location"] = params.node.key
    }
    if(params.node.field == 'zone'){
      reqparsm["zone"] = params.node.key
    }
    if(params.node.field == "consignee_area"){
      reqparsm["consignee_area"] = params.node.key
    }

    if (
      params.colDef.field == "dept_name" ||
      params.colDef.field == "consignment_code" ||
      params.colDef.field == "dept_code" ||
      params.colDef.field == "uom" ||
      typeof params.node.aggData == "undefined"
    ) {
    } else {
      //console.log("params.colDef.field" , params.colDef.field)
      if (
        params.colDef.field != "dept_name" ||
        params.colDef.field != "dept_code" ||
        params.colDef.field != "uom" ||
        params.colDef.field != "consignment_code"
      ) {
        this.setState({
          loadshow: "show-m",
          overly: "show-m"
        });
        var txt = "";
        var htxt = "";
        if (
          params.colDef.field == "transit_delay_days_0" ||
          params.colDef.field == "transit_delay_days_1" ||
          params.colDef.field == "transit_delay_days_2"
        ) {
          htxt = "For selected period ";
        }

        if (
          params.colDef.field == "active_transit_delay_days_0" ||
          params.colDef.field == "active_transit_delay_days_1" ||
          params.colDef.field == "active_transit_delay_days_2" ||
          params.colDef.field == "criticalset"
        ) {
          htxt = "Cummulative as on date ";
        }

        if (params.node.parent.id != "ROOT_NODE_ID") {
          txt =
            htxt +
            " (" +
            params.node.parent.key +
            " - " +
            params.node.key +
            " - " +
            params.colDef.headerName +
            ")";
        } else {
          txt =
            htxt +
            " (" +
            params.node.key +
            " - " +
            params.colDef.headerName +
            ")";
        }
        await redirectURL
          .post("/consignments/dashboardlegs", reqparsm)
          .then(async response => {
            //console.log("Leg Response ", response.data);
            // if(this.state.dept_code == "LOG-SC")
            // {
            //   var records =  response.data;
            //   console.log("recs ", records)

            // }else{
            var records = response.data.message;

            //}
            //console.log("recs ", records)
            //console.log("leg srecords ", JSON.stringify(records))
            var extraarr = [];
            var showreachdealer = false;
            var showactcols = false;
            if (records.length > 0) {
              records.map(item => {
                //console.log(item.consigner_code +" == "+ params.node.key)
                if (params.node.parent.id == "ROOT_NODE_ID") {
                  //console.log("params.colDef.field 123123 ", params.colDef.field)
                  if (params.node.key == item.dept_name) {
                    if (params.colDef.field == "set") {
                      if (parseInt(item.set) == 1) {
                        extraarr.push(item.extra_details);
                      }
                      showreachdealer = false;
                      showactcols = true;
                    }
                    if (params.colDef.field == "status") {
                      if (
                        parseInt(item.set) == 1 &&
                        parseInt(item.status) == 2
                      ) {
                        extraarr.push(item.extra_details);
                      }

                      showreachdealer = false;
                      showactcols = true;
                    }

                    if (params.colDef.field == "activeset") {
                      if (
                        parseInt(item.set) == 2 &&
                        parseInt(item.status) != -1
                      ) {
                        extraarr.push(item.extra_details);
                      }

                      showreachdealer = true;
                      showactcols = false;
                    }

                    if (params.colDef.field == "transit_delay_days_0") {
                      if (
                        parseInt(item.transit_delay_days) == 0 &&
                        parseInt(item.set) == 1 &&
                        (parseInt(item.status) == 2 ||
                          item.status == "In ICD Ghari")
                      ) {
                        extraarr.push(item.extra_details);
                      }
                      showreachdealer = true;
                      showactcols = false;
                    }

                    if (params.colDef.field == "transit_delay_days_1") {
                      if (
                        parseInt(item.transit_delay_days) == 1 &&
                        parseInt(item.set) == 1 &&
                        (parseInt(item.status) == 2 ||
                          item.status == "In ICD Ghari")
                      ) {
                        extraarr.push(item.extra_details);
                      }
                      showreachdealer = true;
                      showactcols = false;
                    }

                    if (params.colDef.field == "transit_delay_days_2") {
                      if (
                        parseInt(item.transit_delay_days) == 2 &&
                        parseInt(item.set) == 1 &&
                        (parseInt(item.status) == 2 ||
                          item.status == "In ICD Ghari")
                      ) {
                        extraarr.push(item.extra_details);
                      }
                      showreachdealer = true;
                      showactcols = false;
                    }

                    if (params.colDef.field == "active_transit_delay_days_0") {
                      //console.log('test ', parseInt(item.transit_delay_days))
                      //console.log('set ', parseInt(item.set))
                      //console.log('status ', parseInt(item.status))
                      if (
                        parseInt(item.transit_delay_days) == 0 &&
                        parseInt(item.set) == 2 &&
                        (parseInt(item.status) == 2 ||
                          item.status == "In ICD Ghari")
                      ) {
                        extraarr.push(item.extra_details);
                      }

                      showreachdealer = true;
                      showactcols = false;
                    }
                    if (params.colDef.field == "active_transit_delay_days_1") {
                      if (
                        parseInt(item.transit_delay_days) == 1 &&
                        parseInt(item.set) == 2 &&
                        (parseInt(item.status) == 2 ||
                          item.status == "In ICD Ghari")
                      ) {
                        extraarr.push(item.extra_details);
                      }

                      showreachdealer = true;
                      showactcols = false;
                    }
                    if (params.colDef.field == "active_transit_delay_days_2") {
                      if (
                        parseInt(item.transit_delay_days) == 2 &&
                        parseInt(item.set) == 2 &&
                        (parseInt(item.status) == 2 ||
                          item.status == "In ICD Ghari")
                      ) {
                        extraarr.push(item.extra_details);
                      }

                      showreachdealer = true;
                      showactcols = false;
                    }
                    if (params.colDef.field == "criticalset") {
                      if (
                        item.transit_delay_days == "Critical" &&
                        parseInt(item.set) == 2 &&
                        parseInt(item.status) == 2
                      ) {
                        extraarr.push(item.extra_details);
                      }

                      showreachdealer = true;
                      showactcols = false;
                    }
                  }
                } else {
                  if (item.consigner_code === params.node.key) {
                    //console.log("s1chreere")
                    if (params.colDef.field == "set") {
                      if (item.set == 1) {
                        extraarr.push(item.extra_details);
                      }
                      showreachdealer = false;
                      showactcols = true;
                    }

                    if (params.colDef.field == "status") {
                      if (item.set == 1 && item.status == 2) {
                        extraarr.push(item.extra_details);
                      }
                      showreachdealer = false;
                      showactcols = true;
                    }
                    //console.log("params.colDef.field 123 ", params.colDef.field)
                    if (params.colDef.field == "activeset") {
                      if (item.set == 2 && item.status != -1) {
                        extraarr.push(item.extra_details);
                      }

                      showreachdealer = true;
                      showactcols = false;
                    }

                    if (params.colDef.field == "transit_delay_days_0") {
                      if (
                        parseInt(item.transit_delay_days) == 0 &&
                        parseInt(item.set) == 1 &&
                        (parseInt(item.status) == 2 ||
                          item.status == "In ICD Ghari")
                      ) {
                        extraarr.push(item.extra_details);
                      }
                      showreachdealer = true;
                      showactcols = false;
                    }

                    if (params.colDef.field == "transit_delay_days_1") {
                      if (
                        parseInt(item.transit_delay_days) == 1 &&
                        parseInt(item.set) == 1 &&
                        (parseInt(item.status) == 2 ||
                          item.status == "In ICD Ghari")
                      ) {
                        extraarr.push(item.extra_details);
                      }
                      showreachdealer = true;
                      showactcols = false;
                    }

                    if (params.colDef.field == "transit_delay_days_2") {
                      if (
                        parseInt(item.transit_delay_days) == 2 &&
                        parseInt(item.set) == 1 &&
                        (parseInt(item.status) == 2 ||
                          item.status == "In ICD Ghari")
                      ) {
                        extraarr.push(item.extra_details);
                      }
                      showreachdealer = true;
                      showactcols = false;
                    }

                    if (params.colDef.field == "active_transit_delay_days_0") {
                      if (
                        parseInt(item.transit_delay_days) == 0 &&
                        parseInt(item.set) == 2 &&
                        (parseInt(item.status) == 2 ||
                          item.status == "In ICD Ghari")
                      ) {
                        extraarr.push(item.extra_details);
                      }

                      showreachdealer = true;
                      showactcols = false;
                    }
                    if (params.colDef.field == "active_transit_delay_days_1") {
                      if (
                        parseInt(item.transit_delay_days) == 1 &&
                        parseInt(item.set) == 2 &&
                        (parseInt(item.status) == 2 ||
                          item.status == "In ICD Ghari")
                      ) {
                        extraarr.push(item.extra_details);
                      }

                      showreachdealer = true;
                      showactcols = false;
                    }
                    if (params.colDef.field == "active_transit_delay_days_2") {
                      if (
                        parseInt(item.transit_delay_days) == 2 &&
                        parseInt(item.set) == 2 &&
                        (parseInt(item.status) == 2 ||
                          item.status == "In ICD Ghari")
                      ) {
                        extraarr.push(item.extra_details);
                      }

                      showreachdealer = true;
                      showactcols = false;
                    }

                    if (params.colDef.field == "criticalset") {
                      if (
                        item.transit_delay_days == "Critical" &&
                        parseInt(item.set) == 2 &&
                        parseInt(item.status) == 2
                      ) {
                        extraarr.push(item.extra_details);
                      }

                      showreachdealer = true;
                      showactcols = false;
                    }
                  } else {
                    //console.log("s1chreere")
                    if (params.colDef.field == "set") {
                      if (item.set == 1) {
                        extraarr.push(item.extra_details);
                      }
                      showreachdealer = false;
                      showactcols = true;
                    }

                    if (params.colDef.field == "status") {
                      if (item.set == 1 && item.status == 2) {
                        extraarr.push(item.extra_details);
                      }
                      showreachdealer = false;
                      showactcols = true;
                    }
                    //console.log("params.colDef.field 123 ", params.colDef.field)
                    if (params.colDef.field == "activeset") {
                      if (item.set == 2 && item.status != -1) {
                        extraarr.push(item.extra_details);
                      }

                      showreachdealer = true;
                      showactcols = false;
                    }

                    if (params.colDef.field == "transit_delay_days_0") {
                      if (
                        parseInt(item.transit_delay_days) == 0 &&
                        parseInt(item.set) == 1 &&
                        (parseInt(item.status) == 2 ||
                          item.status == "In ICD Ghari")
                      ) {
                        extraarr.push(item.extra_details);
                      }
                      showreachdealer = true;
                      showactcols = false;
                    }

                    if (params.colDef.field == "transit_delay_days_1") {
                      if (
                        parseInt(item.transit_delay_days) == 1 &&
                        parseInt(item.set) == 1 &&
                        (parseInt(item.status) == 2 ||
                          item.status == "In ICD Ghari")
                      ) {
                        extraarr.push(item.extra_details);
                      }
                      showreachdealer = true;
                      showactcols = false;
                    }

                    if (params.colDef.field == "transit_delay_days_2") {
                      if (
                        parseInt(item.transit_delay_days) == 2 &&
                        parseInt(item.set) == 1 &&
                        (parseInt(item.status) == 2 ||
                          item.status == "In ICD Ghari")
                      ) {
                        extraarr.push(item.extra_details);
                      }
                      showreachdealer = true;
                      showactcols = false;
                    }

                    if (params.colDef.field == "active_transit_delay_days_0") {
                      if (
                        parseInt(item.transit_delay_days) == 0 &&
                        parseInt(item.set) == 2 &&
                        (parseInt(item.status) == 2 ||
                          item.status == "In ICD Ghari")
                      ) {
                        extraarr.push(item.extra_details);
                      }

                      showreachdealer = true;
                      showactcols = false;
                    }
                    if (params.colDef.field == "active_transit_delay_days_1") {
                      if (
                        parseInt(item.transit_delay_days) == 1 &&
                        parseInt(item.set) == 2 &&
                        (parseInt(item.status) == 2 ||
                          item.status == "In ICD Ghari")
                      ) {
                        extraarr.push(item.extra_details);
                      }

                      showreachdealer = true;
                      showactcols = false;
                    }
                    if (params.colDef.field == "active_transit_delay_days_2") {
                      if (
                        parseInt(item.transit_delay_days) == 2 &&
                        parseInt(item.set) == 2 &&
                        (parseInt(item.status) == 2 ||
                          item.status == "In ICD Ghari")
                      ) {
                        extraarr.push(item.extra_details);
                      }

                      showreachdealer = true;
                      showactcols = false;
                    }

                    if (params.colDef.field == "criticalset") {
                      if (
                        item.transit_delay_days == "Critical" &&
                        parseInt(item.set) == 2 &&
                        parseInt(item.status) == 2
                      ) {
                        extraarr.push(item.extra_details);
                      }

                      showreachdealer = true;
                      showactcols = false;
                    }
                  }
                }
              });
            }
            // console.log("extraarr ", extraarr.length)
            var legarr = [];
            await extraarr.map(item => {
              console.log("extraarr",item)
              try {
                var oData = JSON.parse(item);
                console.log("oData ", oData)
                oData.map(iData => {
                  if (this.state.dept_code == "LOG-SC") {
                    if (iData.searates_pol != "") {
                      if (params.node.parent.id == "ROOT_NODE_ID") {
                        legarr.push(iData);
                      } else {
                        if (typeof iData.searates_pol == "undefined") {
                          legarr.push(iData);
                        } else {
                          if (iData.searates_pol == params.node.key) {
                            legarr.push(iData);
                          }
                        }
                      }
                    }
                  } else {
                    if (params.node.parent.id == "ROOT_NODE_ID") {
                      legarr.push(iData);
                    } else {
                      if (typeof iData.consigner_code == "undefined") {
                        legarr.push(iData);
                      } else {
                        // console.log("idata",iData)
                        if (iData.consigner_code == params.node.key) {
                          legarr.push(iData);
                        }
                        else if(iData.zone == params.node.key){
                          legarr.push(iData);
                        }
                        else if(iData.consignee_area == params.node.key){
                          legarr.push(iData);
                        }
                      }
                    }
                  }
                });
              } catch (e) {
                console.log("Errr ", e);
              }
            });
            //console.log("legarr ", legarr)
            var showsetone = showreachdealer;
            var showactcols = showactcols;

            // console.log("params.node.key ",params.node.key)

            if (params.node.key == "Spare Parts (Courier)") {
              this.setState({
                dept_code: "LOG-PRT (Courier)"
              });
              var legcolumns = [
                {
                  headerName: "Consignment Code",
                  field: "consignment_code",
                  width: 150,
                  resizable: true,
                  filter: true
                },
                {
                  headerName: "Service Provider",
                  field: "service_provider",
                  width: 150,
                  resizable: true,
                  filter: true
                },
                {
                  headerName: "MGPA Date",
                  field: "mgpa_date_time",
                  width: 150,
                  resizable: true,
                  valueGetter: function (params) {
                    if (params.data.mgpa_date_time != "") {
                      return getHyphenDDMMMYYYYHHMM(params.data.mgpa_date_time);
                    } else {
                      return "";
                    }
                  },
                  //filter: "agDateColumnFilter",
                  comparator: dateComparator
                  // filterParams: {
                  //   browserDatePicker: true,
                  //   filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
                  //   // provide comparator function
                  //   comparator: function(filteredDate,cellValue,secondDate) {
                  //     cellValue = cellValue.replace(/\//g,"-")
                  //     if(cellValue != "" && cellValue != " "){
                  //       cellValue = cellValue.split(" ")[0].split("-");
                  //       cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
                  //       var cellDate = new Date(cellValue);
                  //       if(filteredDate.getMonth() < 10){
                  //       var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                  //       }
                  //       else{
                  //         var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                  //       }
                  //       var filteredDate = new Date(filterValue);
                  //       cellDate = cellDate.getTime();
                  //       filteredDate = filteredDate.getTime();
                  //       // console.log(cellDate,filteredDate);
                  //       if(cellDate === filteredDate)
                  //       {
                  //         return 0;
                  //       }
                  //       if(cellDate < filteredDate){
                  //         return -1;
                  //       }
                  //       if(cellDate > filteredDate)
                  //       {
                  //         return 1;
                  //       }
                  //     }
                  //   }
                  // },
                },
                {
                  headerName: "Consigner Code",
                  field: "consigner_code",
                  width: 150,
                  resizable: true,
                  filter: true
                },
                {
                  headerName: "Consignee City",
                  field: "consignee_city",
                  width: 150,
                  resizable: true,
                  filter: true
                },
                {
                  headerName: "Consignment Status",
                  field: "consignment_status",
                  width: 150,
                  resizable: true,
                  filter: true
                },

                {
                  headerName: "Delivery Date",
                  field: "delivery_datetime",
                  width: 150,
                  resizable: true,
                  valueGetter: function (params) {
                    if (
                      params.data.delivery_datetime != "" &&
                      typeof params.data.delivery_datetime != "undefined"
                    ) {
                      return getHyphenDDMMMYYYYHHMM(
                        params.data.delivery_datetime
                      );
                    } else {
                      return "";
                    }
                  },
                  //filter: "agDateColumnFilter",
                  comparator: dateComparator
                  // filterParams: {
                  //   browserDatePicker: true,
                  //   filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
                  //   // provide comparator function
                  //   comparator: function(filteredDate,cellValue,secondDate) {
                  //     cellValue = cellValue.replace(/\//g,"-")
                  //     if(cellValue != "" && cellValue != " "){
                  //       cellValue = cellValue.split(" ")[0].split("-");
                  //       cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
                  //       var cellDate = new Date(cellValue);
                  //       if(filteredDate.getMonth() < 10){
                  //       var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                  //       }
                  //       else{
                  //         var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                  //       }
                  //       var filteredDate = new Date(filterValue);
                  //       cellDate = cellDate.getTime();
                  //       filteredDate = filteredDate.getTime();
                  //       // console.log(cellDate,filteredDate);
                  //       if(cellDate === filteredDate)
                  //       {
                  //         return 0;
                  //       }
                  //       if(cellDate < filteredDate){
                  //         return -1;
                  //       }
                  //       if(cellDate > filteredDate)
                  //       {
                  //         return 1;
                  //       }
                  //     }
                  //   }
                  // },
                },

                {
                  headerName: "Delivery Station",
                  field: "delivery_station",
                  width: 150,
                  resizable: true,
                  filter: true
                }

                // {
                //   headerName: "SPOC Contact Name",
                //   field: "spoc_contact_name",
                //   width:150,
                //   resizable: true,
                //   filter : true,
                // },

                // {
                //   headerName: "SPOC Contact Number",
                //   field: "spoc_contact_number",
                //   width:150,
                //   resizable: true,
                //   filter : true,
                // },
                // {
                //   headerName: "Delay Remarks",
                //   field: "spoc_delay_reason",
                //   width:150,
                //   editable : true,
                //   resizable: true
                // }
              ];
            } else if (
              params.node.key == "Container Imports" ||
              params.node.parent.key == "Container Imports"
            ) {
              var legcolumns = [
                {
                  headerName: "Container No",
                  field: "container_no",
                  width: 150,
                  resizable: true,
                  filter: true
                },

                {
                  headerName: "HBL No",
                  field: "hbl_no",
                  width: 150,
                  resizable: true,
                  filter: true
                },

                {
                  headerName: "Initial ETA ICD/HERO",
                  field: "icd_initial_transit_eta",
                  width: 150,
                  resizable: true,
                  valueGetter: function (params) {
                    //console.log("params ", params);
                    //console.log("s1", params.data.container_no)
                    if (
                      params.data.discharge_port == "Nhava Sheva" ||
                      params.data.discharge_port == "NHAVA SHEVA"
                    ) {
                      return getHyphenDDMMMYYYY(
                        params.data.msil_initial_transit_eta
                      );
                    } else {
                      return getHyphenDDMMMYYYY(
                        params.data.icd_initial_transit_eta
                      );
                    }
                  },
                  //filter: "agDateColumnFilter",
                  comparator: dateComparator1
                  // filterParams: {
                  //   browserDatePicker: true,
                  //   filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
                  //   // provide comparator function
                  //   comparator: function(filteredDate,cellValue,secondDate) {
                  //     cellValue = cellValue.replace(/\//g,"-")
                  //     if(cellValue != "" && cellValue != " "){
                  //       cellValue = cellValue.split(" ")[0].split("-");
                  //       cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
                  //       var cellDate = new Date(cellValue);
                  //       if(filteredDate.getMonth() < 10){
                  //       var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
                  //       }
                  //       else{
                  //         var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
                  //       }
                  //       var filteredDate = new Date(filterValue);
                  //       cellDate = cellDate.getTime();
                  //       filteredDate = filteredDate.getTime();
                  //       // console.log(cellDate,filteredDate);
                  //       // console.log(cellValue,filterValue);
                  //       if(cellDate === filteredDate)
                  //       {
                  //         return 0;
                  //       }
                  //       if(cellDate < filteredDate){
                  //         return -1;
                  //       }
                  //       if(cellDate > filteredDate)
                  //       {
                  //         return 1;
                  //       }
                  //     }
                  //   }
                  // },
                },

                {
                  headerName: "Revised ETA ICD/HERO",
                  field: "icd_current_transit_eta",
                  width: 150,
                  resizable: true,
                  valueGetter: function (params) {
                    if (
                      params.data.grfl_arrival_date != "" &&
                      params.data.grfl_arrival_date != "NaT" &&
                      typeof params.data.grfl_arrival_date != "undefined"
                    ) {
                      return getHyphenDDMMMYYYY(
                        new Date(params.data.grfl_arrival_date)
                      );
                    } else {
                      return getHyphenDDMMMYYYY(
                        params.data.icd_current_transit_eta
                      );
                    }
                  },
                  //filter: "agDateColumnFilter",
                  comparator: dateComparator1
                  // filterParams: {
                  //   browserDatePicker: true,
                  //   filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
                  //   // provide comparator function
                  //   comparator: function(filteredDate,cellValue,secondDate) {
                  //     cellValue = cellValue.replace(/\//g,"-")
                  //     if(cellValue != "" && cellValue != " "){
                  //       cellValue = cellValue.split(" ")[0].split("-");
                  //       cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
                  //       var cellDate = new Date(cellValue);
                  //       if(filteredDate.getMonth() < 10){
                  //       var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
                  //       }
                  //       else{
                  //         var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
                  //       }
                  //       var filteredDate = new Date(filterValue);
                  //       cellDate = cellDate.getTime();
                  //       filteredDate = filteredDate.getTime();
                  //       // console.log(cellDate,filteredDate);
                  //       // console.log(cellValue,filterValue);
                  //       if(cellDate === filteredDate)
                  //       {
                  //         return 0;
                  //       }
                  //       if(cellDate < filteredDate){
                  //         return -1;
                  //       }
                  //       if(cellDate > filteredDate)
                  //       {
                  //         return 1;
                  //       }
                  //     }
                  //   }
                  // },
                },
                {
                  headerName: "Last Status",
                  field: "last_status",
                  width: 150,
                  resizable: true,
                  filter: true,
                  valueGetter: function (params) {
                    //console.log("params ", params.data);
                    if (params.data.last_status != null) {
                      if (params.data.last_status == "Port In") {
                        return "Arrived at Indian Port";
                      } else if (
                        params.data.last_status == "In Transit to MSIL"
                      ) {
                        return "HERO In Transit";
                      } else if (params.data.last_status == "In ICD") {
                        return "In ICD";
                      } else if (params.data.last_status == "Port Out") {
                        return "In Transit to ICD";
                      } else {
                        return params.data.last_status;
                      }
                    } else {
                      return "NA";
                    }
                  }
                },
                {
                  headerName: "Initial ETA-HERO",
                  field: "msil_initial_transit_eta",
                  width: 150,
                  resizable: true,

                  valueGetter: function (params) {
                    //console.log("params ", params);
                    if (params.data.msil_initial_transit_eta != null) {
                      return getHyphenDDMMMYYYY(
                        params.data.msil_initial_transit_eta
                      );
                      //return params.data.msil_initial_transit_eta;
                    } else {
                      return "NA";
                    }
                  },
                  //filter: "agDateColumnFilter",
                  comparator: dateComparator1
                  // filterParams: {
                  //   browserDatePicker: true,
                  //   filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
                  //   // provide comparator function
                  //   comparator: function(filteredDate,cellValue,secondDate) {
                  //     cellValue = cellValue.replace(/\//g,"-")
                  //     if(cellValue != "" && cellValue != " "){
                  //       cellValue = cellValue.split(" ")[0].split("-");
                  //       cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
                  //       var cellDate = new Date(cellValue);
                  //       if(filteredDate.getMonth() < 10){
                  //       var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
                  //       }
                  //       else{
                  //         var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
                  //       }
                  //       var filteredDate = new Date(filterValue);
                  //       cellDate = cellDate.getTime();
                  //       filteredDate = filteredDate.getTime();
                  //       // console.log(cellDate,filteredDate);
                  //       // console.log(cellValue,filterValue);
                  //       if(cellDate === filteredDate)
                  //       {
                  //         return 0;
                  //       }
                  //       if(cellDate < filteredDate){
                  //         return -1;
                  //       }
                  //       if(cellDate > filteredDate)
                  //       {
                  //         return 1;
                  //       }
                  //     }
                  //   }
                  // },
                },

                {
                  headerName: "Revised ETA-HERO",
                  field: "msil_current_transit_eta",
                  width: 150,
                  resizable: true,
                  valueGetter: function (params) {
                    //console.log("params ", params);
                    if (params.data.msil_current_transit_eta != null) {
                      return getHyphenDDMMMYYYY(
                        params.data.msil_current_transit_eta
                      );
                    } else {
                      return "NA";
                    }
                  },
                  //filter: "agDateColumnFilter",
                  comparator: dateComparator1
                  // filterParams: {
                  //   browserDatePicker: true,
                  //   filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
                  //   // provide comparator function
                  //   comparator: function(filteredDate,cellValue,secondDate) {
                  //     cellValue = cellValue.replace(/\//g,"-")
                  //     if(cellValue != "" && cellValue != " "){
                  //       cellValue = cellValue.split(" ")[0].split("-");
                  //       cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
                  //       var cellDate = new Date(cellValue);
                  //       if(filteredDate.getMonth() < 10){
                  //       var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
                  //       }
                  //       else{
                  //         var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
                  //       }
                  //       var filteredDate = new Date(filterValue);
                  //       cellDate = cellDate.getTime();
                  //       filteredDate = filteredDate.getTime();
                  //       // console.log(cellDate,filteredDate);
                  //       // console.log(cellValue,filterValue);
                  //       if(cellDate === filteredDate)
                  //       {
                  //         return 0;
                  //       }
                  //       if(cellDate < filteredDate){
                  //         return -1;
                  //       }
                  //       if(cellDate > filteredDate)
                  //       {
                  //         return 1;
                  //       }
                  //     }
                  //   }
                  // },
                },
                {
                  headerName: "Supplier Code",
                  field: "supplier_code",
                  width: 150,
                  resizable: true,
                  filter: true
                },

                {
                  headerName: "Country Code",
                  field: "country_code",
                  width: 150,
                  resizable: true,
                  filter: true
                },
                {
                  headerName: "POL",
                  field: "searates_pol",
                  width: 150,
                  resizable: true,
                  filter: true,
                  valueGetter: function (params) {
                    //console.log("params ", params);
                    if (params.data.searates_pol != null) {
                      return params.data.searates_pol;
                    } else {
                      return params.data.loading_port;
                    }
                  }
                },

                {
                  headerName: "POD",
                  field: "searates_pod",
                  width: 150,
                  resizable: true,
                  filter: true,
                  valueGetter: function (params) {
                    //console.log("params ", params);
                    if (params.data.searates_pod != null) {
                      return params.data.searates_pod;
                    } else {
                      return params.data.discharge_port;
                    }
                  }
                },

                {
                  headerName: "ATA POD",
                  field: "searates_ata_pod",
                  width: 150,
                  resizable: true,
                  filter: true,
                  valueGetter: function (params) {
                    //console.log("params ", params);
                    if (params.data.searates_ata_pod != null) {
                      return getHyphenDDMMMYYYYHHMM(
                        params.data.searates_ata_pod
                      );
                    } else {
                      return "NA";
                    }
                  },
                  //filter: "agDateColumnFilter",
                  comparator: dateComparator
                  // filterParams: {
                  //   browserDatePicker: true,
                  //   filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
                  //   // provide comparator function
                  //   comparator: function(filteredDate,cellValue,secondDate) {
                  //     cellValue = cellValue.replace(/\//g,"-")
                  //     if(cellValue != "" && cellValue != " "){
                  //       cellValue = cellValue.split(" ")[0].split("-");
                  //       cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
                  //       var cellDate = new Date(cellValue);
                  //       if(filteredDate.getMonth() < 10){
                  //       var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                  //       }
                  //       else{
                  //         var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                  //       }
                  //       var filteredDate = new Date(filterValue);
                  //       cellDate = cellDate.getTime();
                  //       filteredDate = filteredDate.getTime();
                  //       // console.log(cellDate,filteredDate);
                  //       if(cellDate === filteredDate)
                  //       {
                  //         return 0;
                  //       }
                  //       if(cellDate < filteredDate){
                  //         return -1;
                  //       }
                  //       if(cellDate > filteredDate)
                  //       {
                  //         return 1;
                  //       }
                  //     }
                  //   }
                  // },
                },

                {
                  headerName: "ETA POD",
                  field: "searates_eta_pod",
                  width: 150,
                  resizable: true,
                  filter: true,
                  valueGetter: function (params) {
                    //console.log("params ", params);
                    if (params.data.searates_eta_pod != null) {
                      return getHyphenDDMMMYYYYHHMM(
                        params.data.searates_eta_pod
                      );
                    } else {
                      return "NA";
                    }
                  },
                  //filter: "agDateColumnFilter",
                  comparator: dateComparator
                  // filterParams: {
                  //   browserDatePicker: true,
                  //   filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
                  //   // provide comparator function
                  //   comparator: function(filteredDate,cellValue,secondDate) {
                  //     cellValue = cellValue.replace(/\//g,"-")
                  //     if(cellValue != "" && cellValue != " "){
                  //       cellValue = cellValue.split(" ")[0].split("-");
                  //       cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
                  //       var cellDate = new Date(cellValue);
                  //       if(filteredDate.getMonth() < 10){
                  //       var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                  //       }
                  //       else{
                  //         var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                  //       }
                  //       var filteredDate = new Date(filterValue);
                  //       cellDate = cellDate.getTime();
                  //       filteredDate = filteredDate.getTime();
                  //       // console.log(cellDate,filteredDate);
                  //       if(cellDate === filteredDate)
                  //       {
                  //         return 0;
                  //       }
                  //       if(cellDate < filteredDate){
                  //         return -1;
                  //       }
                  //       if(cellDate > filteredDate)
                  //       {
                  //         return 1;
                  //       }
                  //     }
                  //   }
                  // },
                },

                {
                  headerName: "ETD POL",
                  field: "searates_etd_pol",
                  width: 150,
                  resizable: true,
                  filter: true,
                  valueGetter: function (params) {
                    //console.log("params ", params);
                    if (params.data.searates_etd_pol != null) {
                      return getHyphenDDMMMYYYYHHMM(
                        params.data.searates_etd_pol
                      );
                    } else {
                      return "NA";
                    }
                  },
                  //filter: "agDateColumnFilter",
                  comparator: dateComparator
                  // filterParams: {
                  //   browserDatePicker: true,
                  //   filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
                  //   // provide comparator function
                  //   comparator: function(filteredDate,cellValue,secondDate) {
                  //     cellValue = cellValue.replace(/\//g,"-")
                  //     if(cellValue != "" && cellValue != " "){
                  //       cellValue = cellValue.split(" ")[0].split("-");
                  //       cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
                  //       var cellDate = new Date(cellValue);
                  //       if(filteredDate.getMonth() < 10){
                  //       var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                  //       }
                  //       else{
                  //         var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                  //       }
                  //       var filteredDate = new Date(filterValue);
                  //       cellDate = cellDate.getTime();
                  //       filteredDate = filteredDate.getTime();
                  //       // console.log(cellDate,filteredDate);
                  //       if(cellDate === filteredDate)
                  //       {
                  //         return 0;
                  //       }
                  //       if(cellDate < filteredDate){
                  //         return -1;
                  //       }
                  //       if(cellDate > filteredDate)
                  //       {
                  //         return 1;
                  //       }
                  //     }
                  //   }
                  // },
                },

                {
                  headerName: "Vessel Arrived On",
                  field: "vessel_arrived_on",
                  width: 150,
                  resizable: true,
                  filter: true,
                  valueGetter: function (params) {
                    //console.log("params ", params);
                    if (params.data.vessel_arrived_on != null) {
                      return getHyphenDDMMMYYYYHHMM(
                        params.data.vessel_arrived_on
                      );
                    } else {
                      return "NA";
                    }
                  },
                  //filter: "agDateColumnFilter",
                  comparator: dateComparator
                  // filterParams: {
                  //   browserDatePicker: true,
                  //   filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
                  //   // provide comparator function
                  //   comparator: function(filteredDate,cellValue,secondDate) {
                  //     cellValue = cellValue.replace(/\//g,"-")
                  //     if(cellValue != "" && cellValue != " "){
                  //       cellValue = cellValue.split(" ")[0].split("-");
                  //       cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
                  //       var cellDate = new Date(cellValue);
                  //       if(filteredDate.getMonth() < 10){
                  //       var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                  //       }
                  //       else{
                  //         var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                  //       }
                  //       var filteredDate = new Date(filterValue);
                  //       cellDate = cellDate.getTime();
                  //       filteredDate = filteredDate.getTime();
                  //       // console.log(cellDate,filteredDate);
                  //       if(cellDate === filteredDate)
                  //       {
                  //         return 0;
                  //       }
                  //       if(cellDate < filteredDate){
                  //         return -1;
                  //       }
                  //       if(cellDate > filteredDate)
                  //       {
                  //         return 1;
                  //       }
                  //     }
                  //   }
                  // },
                },

                {
                  headerName: "SMTP Handover Date",
                  field: "smtp_handover_date",
                  width: 150,
                  resizable: true,
                  filter: true,
                  valueGetter: function (params) {
                    //console.log("params ", params);
                    if (params.data.smtp_handover_date != null) {
                      return getHyphenDDMMMYYYYHHMM(
                        params.data.smtp_handover_date
                      );
                    } else {
                      return "NA";
                    }
                  },
                  //filter: "agDateColumnFilter",
                  comparator: dateComparator
                  // filterParams: {
                  //   browserDatePicker: true,
                  //   filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
                  //   // provide comparator function
                  //   comparator: function(filteredDate,cellValue,secondDate) {
                  //     cellValue = cellValue.replace(/\//g,"-")
                  //     if(cellValue != "" && cellValue != " "){
                  //       cellValue = cellValue.split(" ")[0].split("-");
                  //       cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
                  //       var cellDate = new Date(cellValue);
                  //       if(filteredDate.getMonth() < 10){
                  //       var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                  //       }
                  //       else{
                  //         var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                  //       }
                  //       var filteredDate = new Date(filterValue);
                  //       cellDate = cellDate.getTime();
                  //       filteredDate = filteredDate.getTime();
                  //       // console.log(cellDate,filteredDate);
                  //       if(cellDate === filteredDate)
                  //       {
                  //         return 0;
                  //       }
                  //       if(cellDate < filteredDate){
                  //         return -1;
                  //       }
                  //       if(cellDate > filteredDate)
                  //       {
                  //         return 1;
                  //       }
                  //     }
                  //   }
                  // },
                },

                {
                  headerName: "Departure Date",
                  field: "departure_to_grfl_date",
                  width: 150,
                  resizable: true,

                  valueGetter: function (params) {
                    //console.log("params ", params);
                    if (params.data.departure_to_grfl_date != null) {
                      return getHyphenDDMMMYYYYHHMM(
                        params.data.departure_to_grfl_date
                      );
                    } else {
                      return "NA";
                    }
                  },
                  //filter: "agDateColumnFilter",
                  comparator: dateComparator
                  // filterParams: {
                  //   browserDatePicker: true,
                  //   filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
                  //   // provide comparator function
                  //   comparator: function(filteredDate,cellValue,secondDate) {
                  //     cellValue = cellValue.replace(/\//g,"-")
                  //     if(cellValue != "" && cellValue != " "){
                  //       cellValue = cellValue.split(" ")[0].split("-");
                  //       cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
                  //       var cellDate = new Date(cellValue);
                  //       if(filteredDate.getMonth() < 10){
                  //       var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                  //       }
                  //       else{
                  //         var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                  //       }
                  //       var filteredDate = new Date(filterValue);
                  //       cellDate = cellDate.getTime();
                  //       filteredDate = filteredDate.getTime();
                  //       // console.log(cellDate,filteredDate);
                  //       if(cellDate === filteredDate)
                  //       {
                  //         return 0;
                  //       }
                  //       if(cellDate < filteredDate){
                  //         return -1;
                  //       }
                  //       if(cellDate > filteredDate)
                  //       {
                  //         return 1;
                  //       }
                  //     }
                  //   }
                  // },
                },

                {
                  headerName: "BOE Date",
                  field: "boe_date",
                  width: 150,
                  resizable: true,
                  filter: true,
                  valueGetter: function (params) {
                    //console.log("params ", params);
                    if (params.data.boe_date != null) {
                      return getHyphenDDMMMYYYY(params.data.boe_date);
                    } else {
                      return "NA";
                    }
                  },
                  //filter: "agDateColumnFilter",
                  comparator: dateComparator1
                  // filterParams: {
                  //   browserDatePicker: true,
                  //   filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
                  //   // provide comparator function
                  //   comparator: function(filteredDate,cellValue,secondDate) {
                  //     cellValue = cellValue.replace(/\//g,"-")
                  //     if(cellValue != "" && cellValue != " "){
                  //       cellValue = cellValue.split(" ")[0].split("-");
                  //       cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
                  //       var cellDate = new Date(cellValue);
                  //       if(filteredDate.getMonth() < 10){
                  //       var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
                  //       }
                  //       else{
                  //         var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
                  //       }
                  //       var filteredDate = new Date(filterValue);
                  //       cellDate = cellDate.getTime();
                  //       filteredDate = filteredDate.getTime();
                  //       // console.log(cellDate,filteredDate);
                  //       // console.log(cellValue,filterValue);
                  //       if(cellDate === filteredDate)
                  //       {
                  //         return 0;
                  //       }
                  //       if(cellDate < filteredDate){
                  //         return -1;
                  //       }
                  //       if(cellDate > filteredDate)
                  //       {
                  //         return 1;
                  //       }
                  //     }
                  //   }
                  // },
                },

                {
                  headerName: "BOE No",
                  field: "boe_no",
                  width: 150,
                  resizable: true,
                  filter: true
                },

                // {
                //   headerName: "SPOC Contact Name",
                //   field: "spoc_contact_name",
                //   width:150,
                //   resizable: true,
                //   filter : true,
                // },

                // {
                //   headerName: "SPOC Contact Number",
                //   field: "spoc_contact_number",
                //   width:150,
                //   resizable: true,
                //   filter : true,
                // },
                {
                  headerName: "Delay Remarks",
                  field: "spoc_delay_reason",
                  width: 150,
                  editable: true,
                  resizable: true,
                  filter: true
                }
              ];
            } else if (
              params.node.key == "Container Exports (KDO)" ||
              params.node.key == "LOG-SCE"
            ) {
              var legcolumns = [
                {
                  headerName: "Container No",
                  field: "container_no",
                  width: 120,
                  resizable: true,
                  filter: true
                },
                {
                  headerName: "Container Size",
                  field: "container_size",
                  width: 120,
                  resizable: true,
                  filter: true
                },
                {
                  headerName: "Status",
                  field: "status",
                  width: 120,
                  resizable: true,
                  filter: true
                },
                {
                  headerName: "Handover Date",
                  field: "handover_date",
                  width: 120,

                  valueGetter: function (params) {
                    //console.log("params ", params);
                    if (params.data.handover_date != null) {
                      return getHyphenDDMMMYYYY(params.data.handover_date);
                    } else {
                      return "NA";
                    }
                  },
                  resizable: true,
                  //filter: "agDateColumnFilter",
                  comparator: dateComparator1
                  // filterParams: {
                  //   browserDatePicker: true,
                  //   filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
                  //   // provide comparator function
                  //   comparator: function(filteredDate,cellValue,secondDate) {
                  //     cellValue = cellValue.replace(/\//g,"-")
                  //     if(cellValue != "" && cellValue != " "){
                  //       cellValue = cellValue.split(" ")[0].split("-");
                  //       cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
                  //       var cellDate = new Date(cellValue);
                  //       if(filteredDate.getMonth() < 10){
                  //       var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
                  //       }
                  //       else{
                  //         var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
                  //       }
                  //       var filteredDate = new Date(filterValue);
                  //       cellDate = cellDate.getTime();
                  //       filteredDate = filteredDate.getTime();
                  //       // console.log(cellDate,filteredDate);
                  //       // console.log(cellValue,filterValue);
                  //       if(cellDate === filteredDate)
                  //       {
                  //         return 0;
                  //       }
                  //       if(cellDate < filteredDate){
                  //         return -1;
                  //       }
                  //       if(cellDate > filteredDate)
                  //       {
                  //         return 1;
                  //       }
                  //     }
                  //   }
                  // },
                },
                {
                  headerName: "Expected ETD",
                  field: "expected_etd",
                  width: 120,

                  valueGetter: function (params) {
                    //console.log("params ", params);
                    if (params.data.expected_etd != null) {
                      return getHyphenDDMMMYYYY(params.data.expected_etd);
                    } else {
                      return "NA";
                    }
                  },
                  //filter: "agDateColumnFilter",
                  comparator: dateComparator1,
                  // filterParams: {
                  //   browserDatePicker: true,
                  //   filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
                  //   // provide comparator function
                  //   comparator: function(filteredDate,cellValue,secondDate) {
                  //     cellValue = cellValue.replace(/\//g,"-")
                  //     if(cellValue != "" && cellValue != " "){
                  //       cellValue = cellValue.split(" ")[0].split("-");
                  //       cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
                  //       var cellDate = new Date(cellValue);
                  //       if(filteredDate.getMonth() < 10){
                  //       var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
                  //       }
                  //       else{
                  //         var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
                  //       }
                  //       var filteredDate = new Date(filterValue);
                  //       cellDate = cellDate.getTime();
                  //       filteredDate = filteredDate.getTime();
                  //       // console.log(cellDate,filteredDate);
                  //       // console.log(cellValue,filterValue);
                  //       if(cellDate === filteredDate)
                  //       {
                  //         return 0;
                  //       }
                  //       if(cellDate < filteredDate){
                  //         return -1;
                  //       }
                  //       if(cellDate > filteredDate)
                  //       {
                  //         return 1;
                  //       }
                  //     }
                  //   }
                  // },
                  resizable: true
                },
                {
                  headerName: "Revised ETD",
                  field: "revised_etd",
                  width: 120,
                  valueGetter: function (params) {
                    //console.log("params ", params);
                    if (params.data.revised_etd != null) {
                      return getHyphenDDMMMYYYY(params.data.revised_etd);
                    } else {
                      return "NA";
                    }
                  },
                  resizable: true,
                  //filter: "agDateColumnFilter",
                  comparator: dateComparator1
                  // filterParams: {
                  //   browserDatePicker: true,
                  //   filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
                  //   // provide comparator function
                  //   comparator: function(filteredDate,cellValue,secondDate) {
                  //     cellValue = cellValue.replace(/\//g,"-")
                  //     if(cellValue != "" && cellValue != " "){
                  //       cellValue = cellValue.split(" ")[0].split("-");
                  //       cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
                  //       var cellDate = new Date(cellValue);
                  //       if(filteredDate.getMonth() < 10){
                  //       var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
                  //       }
                  //       else{
                  //         var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
                  //       }
                  //       var filteredDate = new Date(filterValue);
                  //       cellDate = cellDate.getTime();
                  //       filteredDate = filteredDate.getTime();
                  //       // console.log(cellDate,filteredDate);
                  //       // console.log(cellValue,filterValue);
                  //       if(cellDate === filteredDate)
                  //       {
                  //         return 0;
                  //       }
                  //       if(cellDate < filteredDate){
                  //         return -1;
                  //       }
                  //       if(cellDate > filteredDate)
                  //       {
                  //         return 1;
                  //       }
                  //     }
                  //   }
                  // },
                },
                {
                  headerName: "ICD Arrival Date",
                  field: "icd_arrival_date",
                  width: 120,
                  valueGetter: function (params) {
                    //console.log("params ", params);
                    if (params.data.icd_arrival_date != null) {
                      return getHyphenDDMMMYYYY(params.data.icd_arrival_date);
                    } else {
                      return "NA";
                    }
                  },
                  resizable: true,
                  //filter: "agDateColumnFilter",
                  comparator: dateComparator1
                  // filterParams: {
                  //   browserDatePicker: true,
                  //   filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
                  //   // provide comparator function
                  //   comparator: function(filteredDate,cellValue,secondDate) {
                  //     cellValue = cellValue.replace(/\//g,"-")
                  //     if(cellValue != "" && cellValue != " "){
                  //       cellValue = cellValue.split(" ")[0].split("-");
                  //       cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
                  //       var cellDate = new Date(cellValue);
                  //       if(filteredDate.getMonth() < 10){
                  //       var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
                  //       }
                  //       else{
                  //         var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
                  //       }
                  //       var filteredDate = new Date(filterValue);
                  //       cellDate = cellDate.getTime();
                  //       filteredDate = filteredDate.getTime();
                  //       // console.log(cellDate,filteredDate);
                  //       // console.log(cellValue,filterValue);
                  //       if(cellDate === filteredDate)
                  //       {
                  //         return 0;
                  //       }
                  //       if(cellDate < filteredDate){
                  //         return -1;
                  //       }
                  //       if(cellDate > filteredDate)
                  //       {
                  //         return 1;
                  //       }
                  //     }
                  //   }
                  // },
                },
                {
                  headerName: "ICD Gate out",
                  field: "icd_gate_out",
                  width: 120,
                  valueGetter: function (params) {
                    //console.log("params ", params);
                    if (params.data.icd_gate_out != null) {
                      return getHyphenDDMMMYYYY(params.data.icd_gate_out);
                    } else {
                      return "NA";
                    }
                  },
                  //filter: "agDateColumnFilter",
                  comparator: dateComparator1,
                  // filterParams: {
                  //   browserDatePicker: true,
                  //   filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
                  //   // provide comparator function
                  //   comparator: function(filteredDate,cellValue,secondDate) {
                  //     cellValue = cellValue.replace(/\//g,"-")
                  //     if(cellValue != "" && cellValue != " "){
                  //       cellValue = cellValue.split(" ")[0].split("-");
                  //       cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
                  //       var cellDate = new Date(cellValue);
                  //       if(filteredDate.getMonth() < 10){
                  //       var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
                  //       }
                  //       else{
                  //         var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
                  //       }
                  //       var filteredDate = new Date(filterValue);
                  //       cellDate = cellDate.getTime();
                  //       filteredDate = filteredDate.getTime();
                  //       // console.log(cellDate,filteredDate);
                  //       // console.log(cellValue,filterValue);
                  //       if(cellDate === filteredDate)
                  //       {
                  //         return 0;
                  //       }
                  //       if(cellDate < filteredDate){
                  //         return -1;
                  //       }
                  //       if(cellDate > filteredDate)
                  //       {
                  //         return 1;
                  //       }
                  //     }
                  //   }
                  // },
                  resizable: true
                },
                {
                  headerName: "HERO Gate In",
                  field: "msil_gate_in",
                  width: 120,
                  valueGetter: function (params) {
                    //console.log("params ", params);
                    if (params.data.msil_gate_in != null) {
                      return getHyphenDDMMMYYYY(params.data.msil_gate_in);
                    } else {
                      return "NA";
                    }
                  },
                  //filter: "agDateColumnFilter",
                  comparator: dateComparator1,
                  // filterParams: {
                  //   browserDatePicker: true,
                  //   filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
                  //   // provide comparator function
                  //   comparator: function(filteredDate,cellValue,secondDate) {
                  //     cellValue = cellValue.replace(/\//g,"-")
                  //     if(cellValue != "" && cellValue != " "){
                  //       cellValue = cellValue.split(" ")[0].split("-");
                  //       cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
                  //       var cellDate = new Date(cellValue);
                  //       if(filteredDate.getMonth() < 10){
                  //       var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
                  //       }
                  //       else{
                  //         var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
                  //       }
                  //       var filteredDate = new Date(filterValue);
                  //       cellDate = cellDate.getTime();
                  //       filteredDate = filteredDate.getTime();
                  //       // console.log(cellDate,filteredDate);
                  //       // console.log(cellValue,filterValue);
                  //       if(cellDate === filteredDate)
                  //       {
                  //         return 0;
                  //       }
                  //       if(cellDate < filteredDate){
                  //         return -1;
                  //       }
                  //       if(cellDate > filteredDate)
                  //       {
                  //         return 1;
                  //       }
                  //     }
                  //   }
                  // },
                  resizable: true
                },
                {
                  headerName: "HERO Gate Out",
                  field: "msil_gate_out",
                  width: 120,
                  valueGetter: function (params) {
                    //console.log("params ", params);
                    if (params.data.msil_gate_out != null) {
                      return getHyphenDDMMMYYYY(params.data.msil_gate_out);
                    } else {
                      return "NA";
                    }
                  },
                  //filter: "agDateColumnFilter",
                  comparator: dateComparator1,
                  // filterParams: {
                  //   browserDatePicker: true,
                  //   filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
                  //   // provide comparator function
                  //   comparator: function(filteredDate,cellValue,secondDate) {
                  //     cellValue = cellValue.replace(/\//g,"-")
                  //     if(cellValue != "" && cellValue != " "){
                  //       cellValue = cellValue.split(" ")[0].split("-");
                  //       cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
                  //       var cellDate = new Date(cellValue);
                  //       if(filteredDate.getMonth() < 10){
                  //       var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
                  //       }
                  //       else{
                  //         var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
                  //       }
                  //       var filteredDate = new Date(filterValue);
                  //       cellDate = cellDate.getTime();
                  //       filteredDate = filteredDate.getTime();
                  //       // console.log(cellDate,filteredDate);
                  //       // console.log(cellValue,filterValue);
                  //       if(cellDate === filteredDate)
                  //       {
                  //         return 0;
                  //       }
                  //       if(cellDate < filteredDate){
                  //         return -1;
                  //       }
                  //       if(cellDate > filteredDate)
                  //       {
                  //         return 1;
                  //       }
                  //     }
                  //   }
                  // },
                  resizable: true
                },

                {
                  headerName: "Trailer No",
                  field: "trailer_no",
                  width: 120,
                  resizable: true,
                  filter: true
                },
                {
                  headerName: "Plant Name",
                  field: "plant_name",
                  width: 120,
                  resizable: true,
                  filter: true
                },
                {
                  headerName: "POD ETA",
                  field: "pod_eta",
                  width: 120,
                  valueGetter: function (params) {
                    //console.log("params ", params);
                    if (params.data.pod_eta != null) {
                      return getHyphenDDMMMYYYY(params.data.pod_eta);
                    } else {
                      return "NA";
                    }
                  },
                  resizable: true,
                  //filter: "agDateColumnFilter",
                  comparator: dateComparator1
                  // filterParams: {
                  //   browserDatePicker: true,
                  //   filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
                  //   // provide comparator function
                  //   comparator: function(filteredDate,cellValue,secondDate) {
                  //     cellValue = cellValue.replace(/\//g,"-")
                  //     if(cellValue != "" && cellValue != " "){
                  //       cellValue = cellValue.split(" ")[0].split("-");
                  //       cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
                  //       var cellDate = new Date(cellValue);
                  //       if(filteredDate.getMonth() < 10){
                  //       var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
                  //       }
                  //       else{
                  //         var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
                  //       }
                  //       var filteredDate = new Date(filterValue);
                  //       cellDate = cellDate.getTime();
                  //       filteredDate = filteredDate.getTime();
                  //       // console.log(cellDate,filteredDate);
                  //       // console.log(cellValue,filterValue);
                  //       if(cellDate === filteredDate)
                  //       {
                  //         return 0;
                  //       }
                  //       if(cellDate < filteredDate){
                  //         return -1;
                  //       }
                  //       if(cellDate > filteredDate)
                  //       {
                  //         return 1;
                  //       }
                  //     }
                  //   }
                  // },
                },
                {
                  headerName: "POD Name",
                  field: "pod_name",
                  width: 120,
                  resizable: true,
                  filter: true
                },
                {
                  headerName: "POL ETD",
                  field: "pol_etd",
                  width: 120,
                  valueGetter: function (params) {
                    //console.log("params ", params);
                    if (params.data.pol_etd != null) {
                      return getHyphenDDMMMYYYY(params.data.pol_etd);
                    } else {
                      return "NA";
                    }
                  },
                  resizable: true,
                  //filter: "agDateColumnFilter",
                  comparator: dateComparator1
                  // filterParams: {
                  //   browserDatePicker: true,
                  //   filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
                  //   // provide comparator function
                  //   comparator: function(filteredDate,cellValue,secondDate) {
                  //     cellValue = cellValue.replace(/\//g,"-")
                  //     if(cellValue != "" && cellValue != " "){
                  //       cellValue = cellValue.split(" ")[0].split("-");
                  //       cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
                  //       var cellDate = new Date(cellValue);
                  //       if(filteredDate.getMonth() < 10){
                  //       var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
                  //       }
                  //       else{
                  //         var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
                  //       }
                  //       var filteredDate = new Date(filterValue);
                  //       cellDate = cellDate.getTime();
                  //       filteredDate = filteredDate.getTime();
                  //       // console.log(cellDate,filteredDate);
                  //       // console.log(cellValue,filterValue);
                  //       if(cellDate === filteredDate)
                  //       {
                  //         return 0;
                  //       }
                  //       if(cellDate < filteredDate){
                  //         return -1;
                  //       }
                  //       if(cellDate > filteredDate)
                  //       {
                  //         return 1;
                  //       }
                  //     }
                  //   }
                  // },
                },
                {
                  headerName: "Port Entry Date",
                  field: "port_entry_date",
                  width: 120,
                  resizable: true,
                  filter: true
                },
                // {
                //   headerName: "SPOC Name",
                //   field: "spoc_contact_name",
                //   width:120,
                //   resizable: true,
                //   filter : true,
                // },
                // {
                //   headerName: "SPOC Number",
                //   field: "spoc_contact_number",
                //   width:120,
                //   resizable: true,
                //   filter : true,
                // },
                {
                  headerName: "Delay Remarks",
                  field: "spoc_delay_reason",
                  width: 150,
                  editable: true,
                  resizable: true,
                  filter: true
                }
              ];
            } else {
              var legcolumns = [
                {
                  headerName: "Consignment Code",
                  field: "consignment_code",
                  width: 150,
                  resizable: true,
                  filter: true
                },
                {
                  headerName: "Consigner Code",
                  field: "consigner_code",
                  width: 150,
                  resizable: true,
                  filter: true
                },
                {
                  headerName: "Consignee Name",
                  field: "consignee_name",
                  width: 150,
                  resizable: true,
                  filter: true
                },
                {
                  headerName: "Consignee City",
                  field: "consignee_city",
                  width: 150,
                  resizable: true,
                  filter: true
                },
                {
                  headerName: "Truck No",
                  field: "truck_no",
                  width: 150,
                  resizable: true,
                  filter: true
                },
                {
                  headerName: "Transporter Name",
                  field: "transporter_name",
                  width: 150,
                  resizable: true,
                  filter: true
                },
                {
                  headerName: "Invoice Time",
                  field: "invoice_time",
                  width: 150,
                  resizable: true,
                  valueGetter: function (params) {
                    if (params.data.invoice_time != "") {
                      return getHyphenDDMMMYYYYHHMM(params.data.invoice_time);
                    }
                  },
                  //filter: "agDateColumnFilter",
                  comparator: dateComparator
                  // filterParams: {
                  //   browserDatePicker: true,
                  //   filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
                  //   // provide comparator function
                  //   comparator: function(filteredDate,cellValue,secondDate) {
                  //     cellValue = cellValue.replace(/\//g,"-")
                  //     if(cellValue != "" && cellValue != " "){
                  //       cellValue = cellValue.split(" ")[0].split("-");
                  //       cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
                  //       var cellDate = new Date(cellValue);
                  //       if(filteredDate.getMonth() < 10){
                  //       var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                  //       }
                  //       else{
                  //         var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                  //       }
                  //       var filteredDate = new Date(filterValue);
                  //       cellDate = cellDate.getTime();
                  //       filteredDate = filteredDate.getTime();
                  //       // console.log(cellDate,filteredDate);
                  //       if(cellDate === filteredDate)
                  //       {
                  //         return 0;
                  //       }
                  //       if(cellDate < filteredDate){
                  //         return -1;
                  //       }
                  //       if(cellDate > filteredDate)
                  //       {
                  //         return 1;
                  //       }
                  //     }
                  //   }
                  // },
                },
                {
                  headerName: "Gate Out Time",
                  field: "gate_out_time",
                  width: 150,
                  resizable: true,
                  valueGetter: function (params) {
                    if (params.data.gate_out_time != "") {
                      return getHyphenDDMMMYYYYHHMM(params.data.gate_out_time);
                    }
                  },
                  //filter: "agDateColumnFilter",
                  comparator: dateComparator
                  // filterParams: {
                  //   browserDatePicker: true,
                  //   filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
                  //   // provide comparator function
                  //   comparator: function(filteredDate,cellValue,secondDate) {
                  //     cellValue = cellValue.replace(/\//g,"-")
                  //     if(cellValue != "" && cellValue != " "){
                  //       cellValue = cellValue.split(" ")[0].split("-");
                  //       cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
                  //       var cellDate = new Date(cellValue);
                  //       if(filteredDate.getMonth() < 10){
                  //       var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                  //       }
                  //       else{
                  //         var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                  //       }
                  //       var filteredDate = new Date(filterValue);
                  //       cellDate = cellDate.getTime();
                  //       filteredDate = filteredDate.getTime();
                  //       // console.log(cellDate,filteredDate);
                  //       if(cellDate === filteredDate)
                  //       {
                  //         return 0;
                  //       }
                  //       if(cellDate < filteredDate){
                  //         return -1;
                  //       }
                  //       if(cellDate > filteredDate)
                  //       {
                  //         return 1;
                  //       }
                  //     }
                  //   }
                  // },
                },
                {
                  headerName: "Original ETA",
                  field: "expected_trip_end",
                  width: 150,
                  resizable: true,
                  valueGetter: function (params) {
                    if (params.data.expected_trip_end != "") {
                      return getHyphenDDMMMYYYYHHMM(
                        params.data.expected_trip_end
                      );
                    }
                  },
                  //filter: "agDateColumnFilter",
                  comparator: dateComparator
                  // filterParams: {
                  //   browserDatePicker: true,
                  //   filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
                  //   // provide comparator function
                  //   comparator: function(filteredDate,cellValue,secondDate) {
                  //     cellValue = cellValue.replace(/\//g,"-")
                  //     if(cellValue != "" && cellValue != " "){
                  //       cellValue = cellValue.split(" ")[0].split("-");
                  //       cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
                  //       var cellDate = new Date(cellValue);
                  //       if(filteredDate.getMonth() < 10){
                  //       var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                  //       }
                  //       else{
                  //         var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                  //       }
                  //       var filteredDate = new Date(filterValue);
                  //       cellDate = cellDate.getTime();
                  //       filteredDate = filteredDate.getTime();
                  //       // console.log(cellDate,filteredDate);
                  //       if(cellDate === filteredDate)
                  //       {
                  //         return 0;
                  //       }
                  //       if(cellDate < filteredDate){
                  //         return -1;
                  //       }
                  //       if(cellDate > filteredDate)
                  //       {
                  //         return 1;
                  //       }
                  //     }
                  //   }
                  // },
                },
                {
                  headerName: "Revised ETA",
                  field: "revised_trip_end",
                  width: 150,
                  resizable: true,
                  valueGetter: function (params) {
                    if (params.data.revised_trip_end != "") {
                      return getHyphenDDMMMYYYYHHMM(
                        params.data.revised_trip_end
                      );
                    }
                  },
                  //filter: "agDateColumnFilter",
                  comparator: dateComparator
                  // filterParams: {
                  //   browserDatePicker: true,
                  //   filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
                  //   // provide comparator function
                  //   comparator: function(filteredDate,cellValue,secondDate) {
                  //     cellValue = cellValue.replace(/\//g,"-")
                  //     if(cellValue != "" && cellValue != " "){
                  //       cellValue = cellValue.split(" ")[0].split("-");
                  //       cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
                  //       var cellDate = new Date(cellValue);
                  //       if(filteredDate.getMonth() < 10){
                  //       var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                  //       }
                  //       else{
                  //         var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                  //       }
                  //       var filteredDate = new Date(filterValue);
                  //       cellDate = cellDate.getTime();
                  //       filteredDate = filteredDate.getTime();
                  //       // console.log(cellDate,filteredDate);
                  //       if(cellDate === filteredDate)
                  //       {
                  //         return 0;
                  //       }
                  //       if(cellDate < filteredDate){
                  //         return -1;
                  //       }
                  //       if(cellDate > filteredDate)
                  //       {
                  //         return 1;
                  //       }
                  //     }
                  //   }
                  // },
                },

                {
                  headerName: "Last City/Town",
                  field: "area",
                  width: 150,
                  resizable: true,
                  filter: true
                },

                {
                  headerName: "Last State",
                  field: "state",
                  width: 150,
                  resizable: true,
                  filter: true
                },
                {
                  headerName: "Last Packet Time",
                  field: "timestamp",
                  width: 150,
                  resizable: true,
                  valueGetter: function (params) {
                    if (params.colDef.field == "criticalset") {
                      if (params.data.timestamp != "") {
                        return getHyphenDDMMMYYYYHHMM(params.data.timestamp);
                      } else {
                        return "";
                      }
                    } else {
                      if (
                        params.data.manual_timestamp == undefined ||
                        params.data.manual_timestamp == ""
                      ) {
                        if (params.data.timestamp != "") {
                          return getHyphenDDMMMYYYYHHMM(params.data.timestamp);
                        } else {
                          return "";
                        }
                      } else {
                        var s1 = moment
                          .parseZone(params.data.timestamp)
                          .format("x");
                        var s2 = moment
                          .parseZone(params.data.manual_timestamp)
                          .format("x");
                        if (s1 > s2) {
                          if (params.data.timestamp != "") {
                            return getHyphenDDMMMYYYYHHMM(
                              params.data.timestamp
                            );
                          } else {
                            return "";
                          }
                        } else {
                          var tstamp = getHyphenDDMMMYYYYHHMM(
                            params.data.manual_timestamp
                          );
                          return tstamp + " (M)";
                        }
                      }
                    }
                  },
                  //filter: "agDateColumnFilter",
                  comparator: dateComparator
                  // filterParams: {
                  //   browserDatePicker: true,
                  //   filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
                  //   // provide comparator function
                  //   comparator: function(filteredDate,cellValue,secondDate) {
                  //     cellValue = cellValue.replace(/\//g,"-")
                  //     if(cellValue != "" && cellValue != " "){
                  //       cellValue = cellValue.split(" ")[0].split("-");
                  //       cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
                  //       var cellDate = new Date(cellValue);
                  //       if(filteredDate.getMonth() < 10){
                  //       var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                  //       }
                  //       else{
                  //         var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                  //       }
                  //       var filteredDate = new Date(filterValue);
                  //       cellDate = cellDate.getTime();
                  //       filteredDate = filteredDate.getTime();
                  //       // console.log(cellDate,filteredDate);
                  //       if(cellDate === filteredDate)
                  //       {
                  //         return 0;
                  //       }
                  //       if(cellDate < filteredDate){
                  //         return -1;
                  //       }
                  //       if(cellDate > filteredDate)
                  //       {
                  //         return 1;
                  //       }
                  //     }
                  //   }
                  // },
                },
                {
                  headerName: "Trip Distance (KM)",
                  field: "distance_in_km",
                  width: 150,
                  resizable: true,
                  hide: showactcols,
                  filter: true
                },

                {
                  headerName: "Distance from Dealer (KM)",
                  field: "distance_from_dealer_location",
                  width: 150,
                  resizable: true,
                  hide: showactcols,
                  filter: true
                },
                {
                  headerName: "Reached Dealer",
                  field: "reached_dealer",
                  width: 150,
                  hide: showsetone,
                  resizable: true,
                  filter: true
                },

                // {
                //   headerName: "SPOC Contact Name",
                //   field: "spoc_contact_name",
                //   width:150,
                //   resizable: true,
                //   filter : true,
                // },

                // {
                //   headerName: "SPOC Contact Number",
                //   field: "spoc_contact_number",
                //   width:150,
                //   resizable: true,
                //   filter : true,
                // },
                {
                  headerName: "Delay Remarks",
                  field: "spoc_delay_reason",
                  width: 150,
                  editable: true,
                  resizable: true,
                  filter: true
                }
              ];
            }
            if (legarr.length > 0) {
              var legsrows = legarr;
            } else {
              var legsrows = [];
            }
            //console.log("legsrows ", legsrows)
            if (params.colDef.field == "criticalset") {
              this.setState({
                sliderCriticalTranslate: "slider-translate-75p",
                loadshow: "show-n",
                overly: "show-m"
              });
            } else {
              this.setState({
                sliderRouteTranslate: "slider-translate-75p",
                loadshow: "show-n",
                overly: "show-m",
                legcolumns: legcolumns,
                legrows: legsrows,
                sidebartitle: txt
              });
            }
          })
          .catch(function (e) {
            console.log("Error ", e);
          });
      }
    }
  };

  onClickHideAll() {
    this.setState({
      loadshow: "show-n",
      overly: "show-n",
      sliderRouteTranslate: "",
      sliderCriticalTranslate: "",
      sliderTicketTranslate: "",
      tabsliderTranslate: "",
      inboxSlider: ""
    });
  }
  onCloseRouteDiv = () => {
    this.setState({
      uploadDivWidth: "0%",
      sliderRouteTranslate: "",
      sliderCriticalTranslate: "",
      showDiv: "show-n",
      uploadFile: "",
      file: "",
      csvmessage: "",
      csverrmessage: "",
      overly: "show-n"
    });
  };

  onClickLoadDept(params) {
    if (params == "dashboard") {
      this.setState({
        showdashboard: "show-m",
        loaddashboard: "show-n"
      });
    }
    if (params == "load") {
      this.setState({
        showdashboard: "show-n",
        loaddashboard: "show-m"
      });
    }
  }

  onClickBIItem(params) {
    if (params == "loaddashboard") {
      this.setState({
        bidrd: "show-n",
        biunla: "show-n",
        bila: "show-m",
        bitransit: "show-n",
        bitransport: "show-n"
      });
    }
    if (params == "unloaddashboard") {
      this.setState({
        bidrd: "show-n",
        biunla: "show-m",
        bila: "show-n",
        bitransit: "show-n",
        bitransport: "show-n"
      });
    }
    if (params == "dailydashboard") {
      this.setState({
        bila: "show-n",
        biunla: "show-n",
        bidrd: "show-m",
        bitransit: "show-n",
        bitransport: "show-n"
      });
    }
    if (params == "transitanalytics") {
      this.setState({
        bila: "show-n",
        biunla: "show-n",
        bidrd: "show-n",
        bitransit: "show-m",
        bitransport: "show-n"
      });
    }
    if (params == "transporterdaily") {
      this.setState({
        bila: "show-n",
        biunla: "show-n",
        bidrd: "show-n",
        bitransit: "show-n",
        bitransport: "show-m"
      });
    }
  }

  onCellUpdateData = params => {
    if (googleAnalytics.page.enableGA) {
      let pageTitle = this.state.pagetitle;
      let eventOptions = {
        category: pageTitle,
        action: this.state.eventGridAction,
        label: "Update Data"
      };
      googleAnalytics.logEvent(eventOptions);
    }
    if (params.colDef.field == "delay_reason") {
      var e = params.data;
      // console.log(e.delay_reason,"e.delayreason");
      try {
        if (e.delay_reason.replace(/\s/g, "").length) {
          if (this.state.dept_code == "LOG-SC") {
            var reqParams = {
              dept_code: this.state.dept_code,
              container_no: e.container_no,
              spoc_delay_reason: e.delay_reason,
              status: e.status,
              modified_by: localStorage.getItem("email")
            };
          } else {
            var reqParams = {
              dept_code: this.state.dept_code,
              // editedDataArr : editedDataArr,
              truck_no: e.consignment_code,
              spoc_delay_reason: e.delay_reason,
              consignment_no: e.consignment_no,
              status: e.status,
              modified_by: localStorage.getItem("email")
            };
          }

          var userrols = localStorage.getItem("roles");

          //console.log("splitrole ",splitrole)
          try {
            var splitrole = userrols.split(",");
            if (splitrole.length > 0) {
              if (
                splitrole.includes("tpt_sndg") == true ||
                splitrole.includes("tpt_prt") == true
              ) {
                reqParams.transporter = "yes";
              }
            }
          } catch (e) {
            console.log("Error ", e);
          }

          // console.log(reqParams);
          redirectURL
            .post("/consignments/updateDelayReason", reqParams)
            .then(response => {
              // console.log(response);
              //   this.setState({
              //     show:true,
              //     basicTitle:"Updated Successfully.",
              //     basicType:"success",
              // });
            })
            .catch(function (e) {
              console.log("Error ", e);
            });
        } else {
          console.log("empty");
        }
      } catch (e) {
        console.log("empty reason");
      }
    }
  };
  onCloseUserManualModal = () => {
    this.setState({ usermanualmodal: false });
  };

  onClickShowUserManaul = () => {
    this.setState({ usermanualmodal: true });
  };
  onClickGetData = () => {
    if (this.state.zone.value == "plant") {
      var records = [];
      this.state.allrecords.map(item => {
        //console.log(item)
        var zerodays;
        var onedays;
        var twodays;
        var activezerodays;
        var activeonedays;
        var activetwodays;
        var criticalset;
        //console.log("item.transit_delay_days ", typeof parseInt(item.transit_delay_days))
        // if(parseInt(item.transit_delay_days) == 0 ||
        // parseInt(item.transit_delay_days) == 1 ||
        // parseInt(item.transit_delay_days) == 2)
        // {
        var set;
        var status;
        var activeset;
        var consoletxt =
          item.dept_code +
          "::" +
          item.dept_name +
          "===" +
          "Days: " +
          parseInt(item.transit_delay_days) +
          ",  Dept : " +
          item.dept_name +
          ", Status: " +
          item.status +
          ",  Set:" +
          item.set;
        // console.log("response data", consoletxt)
        if (
          parseInt(item.transit_delay_days) == 0 &&
          parseInt(item.set) == 1 &&
          (parseInt(item.status) == 2 || item.status == "In ICD Ghari")
        ) {
          zerodays = 1;
        }

        if (
          parseInt(item.transit_delay_days) == 1 &&
          parseInt(item.set) == 1 &&
          (parseInt(item.status) == 2 || item.status == "In ICD Ghari")
        ) {
          onedays = 1;
        }

        if (
          parseInt(item.transit_delay_days) == 2 &&
          parseInt(item.set) == 1 &&
          (parseInt(item.status) == 2 || item.status == "In ICD Ghari")
        ) {
          twodays = 1;
        }

        if (parseInt(item.set) == 1 && parseInt(item.status) != -1) {
          set = 1;
        } else {
          set = 0;
        }
        if (parseInt(item.status) != -1) {
          if (parseInt(item.status) == 2 && parseInt(item.set) == 1) {
            status = 1;
          } else {
            status = 0;
          }
        }
        if (parseInt(item.set) == 2 && parseInt(item.status) != -1) {
          activeset = 1;
        } else {
          activeset = 0;
        }

        if (
          parseInt(item.transit_delay_days) == 0 &&
          parseInt(item.set) == 2 &&
          (parseInt(item.status) == 2 || item.status == "In ICD Ghari")
        ) {
          activezerodays = 1;
        }

        if (
          parseInt(item.transit_delay_days) == 1 &&
          parseInt(item.set) == 2 &&
          (parseInt(item.status) == 2 || item.status == "In ICD Ghari")
        ) {
          activeonedays = 1;
        }

        if (
          parseInt(item.transit_delay_days) == 2 &&
          parseInt(item.set) == 2 &&
          (parseInt(item.status) == 2 || item.status == "In ICD Ghari")
        ) {
          activetwodays = 1;
        }

        // console.log("activezerodays ", activezerodays)
        if (
          parseInt(item.set) == 2 &&
          parseInt(item.status) == 2 &&
          item.transit_delay_days == "Critical"
        ) {
          criticalset = 1;
        } else {
          criticalset = 0;
        }

        //console.log("item.delivery_order ", item.delivery_order)
        if (item.dept_name != "-1" && item.dept_name != "NA") {
          //console.log("item.consigner_code ", item.consigner_code)
          var itemdata = {
            consigner_code: item.consigner_code,
            consignment_code: item.consignment_code,
            zone: item.zone,
            consignee_area: item.consignee_area,
            dept_code: item.dept_code,
            dept_name: item.dept_name,
            invoice_date: item.invoice_date,
            transit_delay_days_0:
              typeof zerodays == "undefined" || zerodays == -1 ? 0 : zerodays,
            transit_delay_days_1:
              typeof onedays == "undefined" || onedays == -1 ? 0 : onedays,
            transit_delay_days_2:
              typeof twodays == "undefined" || twodays == -1 ? 0 : twodays,
            uom: item.uom,
            delivery_order: item.delivery_order,
            set: typeof set == "undefined" ? 0 : set,
            originalset: item.set,
            status: typeof status == "undefined" ? 0 : status,
            originalstatus: item.status,
            extra_details: item.extra_details,
            activeset: typeof activeset == "undefined" ? 0 : activeset,
            active_transit_delay_days_0:
              typeof activezerodays == "undefined" || activezerodays == -1
                ? 0
                : activezerodays,
            active_transit_delay_days_1:
              typeof activeonedays == "undefined" || activeonedays == -1
                ? 0
                : activeonedays,
            active_transit_delay_days_2:
              typeof activetwodays == "undefined" || activetwodays == -1
                ? 0
                : activetwodays,
            criticalset:
              typeof criticalset == "undefined" || criticalset == -1
                ? 0
                : criticalset
          };
          records.push(itemdata);
        }
      });
      // console.log("plants ", records)

      this.setState({
        type: this.state.zone.value,
        rowData: records,
        plantgrid: "show-m",
        zonegrid: "show-n",
        areagrid: "show-n"
      })
    }
    if (this.state.zone.value == "zone") {
      var records = [];
      this.state.allrecords.map(item => {
        //console.log(item)
        var zerodays;
        var onedays;
        var twodays;
        var activezerodays;
        var activeonedays;
        var activetwodays;
        var criticalset;
        //console.log("item.transit_delay_days ", typeof parseInt(item.transit_delay_days))
        // if(parseInt(item.transit_delay_days) == 0 ||
        // parseInt(item.transit_delay_days) == 1 ||
        // parseInt(item.transit_delay_days) == 2)
        // {
        var set;
        var status;
        var activeset;
        var consoletxt =
          item.dept_code +
          "::" +
          item.dept_name +
          "===" +
          "Days: " +
          parseInt(item.transit_delay_days) +
          ",  Dept : " +
          item.dept_name +
          ", Status: " +
          item.status +
          ",  Set:" +
          item.set;
        // console.log("response data", consoletxt)
        if (
          parseInt(item.transit_delay_days) == 0 &&
          parseInt(item.set) == 1 &&
          (parseInt(item.status) == 2 || item.status == "In ICD Ghari")
        ) {
          zerodays = 1;
        }

        if (
          parseInt(item.transit_delay_days) == 1 &&
          parseInt(item.set) == 1 &&
          (parseInt(item.status) == 2 || item.status == "In ICD Ghari")
        ) {
          onedays = 1;
        }

        if (
          parseInt(item.transit_delay_days) == 2 &&
          parseInt(item.set) == 1 &&
          (parseInt(item.status) == 2 || item.status == "In ICD Ghari")
        ) {
          twodays = 1;
        }

        if (parseInt(item.set) == 1 && parseInt(item.status) != -1) {
          set = 1;
        } else {
          set = 0;
        }
        if (parseInt(item.status) != -1) {
          if (parseInt(item.status) == 2 && parseInt(item.set) == 1) {
            status = 1;
          } else {
            status = 0;
          }
        }
        if (parseInt(item.set) == 2 && parseInt(item.status) != -1) {
          activeset = 1;
        } else {
          activeset = 0;
        }

        if (
          parseInt(item.transit_delay_days) == 0 &&
          parseInt(item.set) == 2 &&
          (parseInt(item.status) == 2 || item.status == "In ICD Ghari")
        ) {
          activezerodays = 1;
        }

        if (
          parseInt(item.transit_delay_days) == 1 &&
          parseInt(item.set) == 2 &&
          (parseInt(item.status) == 2 || item.status == "In ICD Ghari")
        ) {
          activeonedays = 1;
        }

        if (
          parseInt(item.transit_delay_days) == 2 &&
          parseInt(item.set) == 2 &&
          (parseInt(item.status) == 2 || item.status == "In ICD Ghari")
        ) {
          activetwodays = 1;
        }

        // console.log("activezerodays ", activezerodays)
        if (
          parseInt(item.set) == 2 &&
          parseInt(item.status) == 2 &&
          item.transit_delay_days == "Critical"
        ) {
          criticalset = 1;
        } else {
          criticalset = 0;
        }

        //console.log("item.delivery_order ", item.delivery_order)
        if (item.dept_name != "-1" && item.dept_name != "NA" && item.zone != "") {
          //console.log("item.consigner_code ", item.consigner_code)
          var itemdata = {
            zone: item.zone,
            consignment_code: item.consignment_code,
            consignee_area: item.consignee_area,
            dept_code: item.dept_code,
            dept_name: item.dept_name,
            invoice_date: item.invoice_date,
            transit_delay_days_0:
              typeof zerodays == "undefined" || zerodays == -1 ? 0 : zerodays,
            transit_delay_days_1:
              typeof onedays == "undefined" || onedays == -1 ? 0 : onedays,
            transit_delay_days_2:
              typeof twodays == "undefined" || twodays == -1 ? 0 : twodays,
            uom: item.uom,
            delivery_order: item.delivery_order,
            set: typeof set == "undefined" ? 0 : set,
            originalset: item.set,
            status: typeof status == "undefined" ? 0 : status,
            originalstatus: item.status,
            extra_details: item.extra_details,
            activeset: typeof activeset == "undefined" ? 0 : activeset,
            active_transit_delay_days_0:
              typeof activezerodays == "undefined" || activezerodays == -1
                ? 0
                : activezerodays,
            active_transit_delay_days_1:
              typeof activeonedays == "undefined" || activeonedays == -1
                ? 0
                : activeonedays,
            active_transit_delay_days_2:
              typeof activetwodays == "undefined" || activetwodays == -1
                ? 0
                : activetwodays,
            criticalset:
              typeof criticalset == "undefined" || criticalset == -1
                ? 0
                : criticalset
          };
          records.push(itemdata);
        }
      });
      // console.log("zone records ", records);
      this.setState({
        type: this.state.zone.value,
        rowData: []
      })

      this.setState({
        type: this.state.zone.value,
        rowData1: records,
        plantgrid: "show-n",
        zonegrid: "show-m",
        areagrid: "show-n"
      })

    }
    if (this.state.zone.value == "area") {
      var records = [];
      this.state.allrecords.map(item => {

        //console.log(item)
        var zerodays;
        var onedays;
        var twodays;
        var activezerodays;
        var activeonedays;
        var activetwodays;
        var criticalset;
        //console.log("item.transit_delay_days ", typeof parseInt(item.transit_delay_days))
        // if(parseInt(item.transit_delay_days) == 0 ||
        // parseInt(item.transit_delay_days) == 1 ||
        // parseInt(item.transit_delay_days) == 2)
        // {
        var set;
        var status;
        var activeset;
        var consoletxt =
          item.dept_code +
          "::" +
          item.dept_name +
          "===" +
          "Days: " +
          parseInt(item.transit_delay_days) +
          ",  Dept : " +
          item.dept_name +
          ", Status: " +
          item.status +
          ",  Set:" +
          item.set;
        // console.log("response data", consoletxt)
        if (
          parseInt(item.transit_delay_days) == 0 &&
          parseInt(item.set) == 1 &&
          (parseInt(item.status) == 2 || item.status == "In ICD Ghari")
        ) {
          zerodays = 1;
        }

        if (
          parseInt(item.transit_delay_days) == 1 &&
          parseInt(item.set) == 1 &&
          (parseInt(item.status) == 2 || item.status == "In ICD Ghari")
        ) {
          onedays = 1;
        }

        if (
          parseInt(item.transit_delay_days) == 2 &&
          parseInt(item.set) == 1 &&
          (parseInt(item.status) == 2 || item.status == "In ICD Ghari")
        ) {
          twodays = 1;
        }

        if (parseInt(item.set) == 1 && parseInt(item.status) != -1) {
          set = 1;
        } else {
          set = 0;
        }
        if (parseInt(item.status) != -1) {
          if (parseInt(item.status) == 2 && parseInt(item.set) == 1) {
            status = 1;
          } else {
            status = 0;
          }
        }
        if (parseInt(item.set) == 2 && parseInt(item.status) != -1) {
          activeset = 1;
        } else {
          activeset = 0;
        }

        if (
          parseInt(item.transit_delay_days) == 0 &&
          parseInt(item.set) == 2 &&
          (parseInt(item.status) == 2 || item.status == "In ICD Ghari")
        ) {
          activezerodays = 1;
        }

        if (
          parseInt(item.transit_delay_days) == 1 &&
          parseInt(item.set) == 2 &&
          (parseInt(item.status) == 2 || item.status == "In ICD Ghari")
        ) {
          activeonedays = 1;
        }

        if (
          parseInt(item.transit_delay_days) == 2 &&
          parseInt(item.set) == 2 &&
          (parseInt(item.status) == 2 || item.status == "In ICD Ghari")
        ) {
          activetwodays = 1;
        }

        // console.log("activezerodays ", activezerodays)
        if (
          parseInt(item.set) == 2 &&
          parseInt(item.status) == 2 &&
          item.transit_delay_days == "Critical"
        ) {
          criticalset = 1;
        } else {
          criticalset = 0;
        }

        //console.log("item.delivery_order ", item.delivery_order)
        if (item.dept_name != "-1" && item.dept_name != "NA" && item.consignee_area != "") {
          //console.log("item.consigner_code ", item.consigner_code)
          var itemdata = {
            consigner_code: item.consigner_code,
            consignment_code: item.consignment_code,
            zone: item.zone,
            consignee_area: item.consignee_area,
            dept_code: item.dept_code,
            dept_name: item.dept_name,
            invoice_date: item.invoice_date,
            transit_delay_days_0:
              typeof zerodays == "undefined" || zerodays == -1 ? 0 : zerodays,
            transit_delay_days_1:
              typeof onedays == "undefined" || onedays == -1 ? 0 : onedays,
            transit_delay_days_2:
              typeof twodays == "undefined" || twodays == -1 ? 0 : twodays,
            uom: item.uom,
            delivery_order: item.delivery_order,
            set: typeof set == "undefined" ? 0 : set,
            originalset: item.set,
            status: typeof status == "undefined" ? 0 : status,
            originalstatus: item.status,
            extra_details: item.extra_details,
            activeset: typeof activeset == "undefined" ? 0 : activeset,
            active_transit_delay_days_0:
              typeof activezerodays == "undefined" || activezerodays == -1
                ? 0
                : activezerodays,
            active_transit_delay_days_1:
              typeof activeonedays == "undefined" || activeonedays == -1
                ? 0
                : activeonedays,
            active_transit_delay_days_2:
              typeof activetwodays == "undefined" || activetwodays == -1
                ? 0
                : activetwodays,
            criticalset:
              typeof criticalset == "undefined" || criticalset == -1
                ? 0
                : criticalset
          };
          records.push(itemdata);
        }
      });
      // console.log("Area ", records);
      this.setState({
        type: this.state.zone.value,
        rowData2: records,
        plantgrid: "show-n",
        zonegrid: "show-n",
        areagrid: "show-m"
      })
    }
  }
  render() {
    //console.log("rowData ",this.state.rowData)
    var taburl = "/snddashboardtemplate";
    var loadingurl = "";
    var transiturl = "";
    var transporturl = "";
    if (this.state.dept_code == "SNDG") {
      taburl = "/snddashboardtemplate";
      loadingurl = "/sndloadingdashboard";
      transiturl = "/sndtransitanalytics";
      transporturl = "/sndtransportdashboard";
    }
    if (this.state.dept_code == "LOG-TNP") {
      taburl = "/tnpdashboardtemplate";
      loadingurl = "/tnploadingdashboard";
      transiturl = "/tnptransitanalytics";
      transporturl = "/tnptransportdashboard";
    }
    if (this.state.dept_code == "LOG-PRT") {
      taburl = "/prtdashboardtemplate";
      loadingurl = "/prtloadingdashboard";
      transiturl = "/prttransitanalytics";
      transporturl = "/prttransportdashboard";
    }
    if (this.state.dept_code == "LOG-SC") {
      taburl = "/scdashboardtemplate";
    }

    const { usermanualmodal } = this.state;
    const modalStyles = {
      width: "1300px !important"
    };

    var startdate = this.state.startDate;
    var enddate = this.state.endDate;
    let { startDate } = this.state;
    var consignmentgenerate = "Consignment Generated ( " + startDate + ")";

    var columnDefs = [
      {
        headerClass: ["cellTopHeaderTemplate"],
        headerName: "Department",
        field: "dept_name",
        rowGroup: true,
        width: 300,
        cellClass: "cellTopHeaderTemplate16",
        //pivot:true,
        hide: true,
        chartDataType: "category"
      },
      {
        field: "consigner_code",
        rowGroup: true,
        cellClass: "cellTopHeaderTemplate14",
        hide: true
        //chartDataType: 'category',
      },
      {
        field: "invoice_date",
        //rowGroup: true,
        cellClass: "cellTopHeaderTemplate14",
        hide: true
        //chartDataType: 'category',
      },
      {
        field: "consignment_code",
        //rowGroup: true,
        cellClass: "cellTopHeaderTemplate14",
        hide: true
        //chartDataType: 'category',
      },
      {
        headerName: "UOM",
        field: "uom",
        width: 120,
        cellClass: ["cellTopHeaderTemplatenb"],
        chartDataType: "excluded",
        valueGetter: function (params) {
          // console.log(params.node.key);
          // if(typeof params.data == 'undefined')
          // {
          //   return "Trucks";
          // }
          // else{
          if (params.node.key == "Sales & Dispatch") {
            return "Trucks";
          } else if (params.node.key == "Spare Parts (Domestic)") {
            return "Consignments";
          } else if (params.node.key == "Spare Parts (Courier)") {
            return "Consignments";
          } else if (
            params.node.key == "Spare Parts (Container Exports)" ||
            params.node.key == "Spares (Container Exports)"
          ) {
            return "Containers";
          } else if (params.node.key == "Production Parts") {
            return "Trucks";
          } else if (params.node.key == "Container Imports") {
            return "Containers";
          } else if (params.node.key == "Container Exports (KDO)") {
            return "Containers";
          } else if (params.node.key == "Coil Imports") {
            return "Coils";
          }
          // }
        }
        //hide:true
      },

      {
        headerName: "For This Period",
        headerClass: ["cellTopHeaderTemplateH", "cellstylegrid"],
        children: [
          {
            headerName: "Total for selected period",
            headerClass: ["cellstylegrid"],
            field: "set",
            cellClass: ["cellTopHeaderTemplate16", "cellstylegrid", "cpointer"],
            // chartDataType: 'series',
            width: 90,
            chartDataType: "excluded"
            //hide:true
          },
          {
            headerName: "Active for selected period",
            headerClass: ["cellstylegrid"],
            field: "status",
            width: 100,
            chartDataType: "excluded",
            cellClass: ["cellTopHeaderTemplate16", "cellstylegrid", "cpointer"]
            //chartDataType: 'series',
            //hide:true
          }
        ]
      },
      {
        headerName: "Delay Meter",
        headerClass: ["cellTopHeaderTemplateH", "cellstylegrid"],
        children: [
          {
            headerClass: ["cellTopHeaderTemplate14", "cellstylegrid"],
            headerName: "On Time",
            field: "transit_delay_days_0",
            width: 70,
            cellClass: [
              "cellTopHeaderTemplate16",
              "bgColorSuccessLight",
              "cellstylegrid",
              "cpointer"
            ],
            chartDataType: "series"
          },
          {
            headerClass: ["cellTopHeaderTemplate14", "cellstylegrid"],
            headerName: "1-2 Days",
            field: "transit_delay_days_1",
            width: 70,
            cellClass: [
              "cellTopHeaderTemplate16",
              "cellstylegridDY",
              "cellstylegrid",
              "cpointer"
            ],
            chartDataType: "series"
          },
          {
            headerClass: [
              "cellTopHeaderTemplate14",
              "cellstylegrid",
              "cpointer"
            ],
            headerName: ">2 Day",
            field: "transit_delay_days_2",
            width: 70,
            cellClass: [
              "cellTopHeaderTemplate16",
              "bgColorDangerMedium",
              "cellstylegrid",
              "cpointer"
            ],
            chartDataType: "series"
          }
        ]
      },

      {
        headerName: "Cummulative As On Date",
        headerClass: ["cellTopHeaderTemplateH", "cellstylegridB"],
        children: [
          {
            headerClass: ["cellstylegridB"],
            headerName: "Active as on date (Cummulative)",
            field: "activeset",
            width: 130,
            cellClass: [
              "cellTopHeaderTemplate16",
              "cellstylegridB",
              "cpointer"
            ],
            chartDataType: "category"
            //hide:true
          },

          {
            headerClass: ["cellTopHeaderTemplate14", "cellstylegridB"],
            headerName: "On Time",
            field: "active_transit_delay_days_0",
            width: 70,
            cellClass: [
              "cellTopHeaderTemplate16",
              "bgColorSuccessLight",
              "cellstylegridB",
              "cpointer"
            ],
            chartDataType: "series"
          },
          {
            headerClass: ["cellTopHeaderTemplate14", "cellstylegridB"],
            headerName: "1-2 Days",
            field: "active_transit_delay_days_1",
            width: 70,
            cellClass: [
              "cellTopHeaderTemplate16",
              "cellstylegridDY",
              "cellstylegridB",
              "cpointer"
            ],
            chartDataType: "series"
          },
          {
            headerClass: ["cellTopHeaderTemplate14", "cellstylegridB"],
            headerName: ">2 Day",
            field: "active_transit_delay_days_2",
            width: 70,
            cellClass: [
              "cellTopHeaderTemplate16",
              "bgColorDangerMedium",
              "cellstylegridB",
              "cpointer"
            ],
            chartDataType: "series"
          },
          {
            headerClass: ["cellTopHeaderTemplate14", "cellstylegridB"],
            headerName: "Critical",
            field: "criticalset",
            width: 90,
            cellClass: [
              "cellTopHeaderTemplate16",
              "red",
              "cellstylegridB",
              "cpointer"
            ],
            chartDataType: "series"
          }
        ]
      }
      // {
      //   headerClass:["cellTopHeaderTemplate"],
      //   headerName:"order",
      //   width:150,
      //   field: 'delivery_order',
      //   sort:"asc",
      //   sortable:true,
      //   //hide:true
      // },
    ];
    var columnDefs1 = [
      {
        headerClass: ["cellTopHeaderTemplate"],
        headerName: "Department",
        field: "dept_name",
        rowGroup: true,
        width: 300,
        cellClass: "cellTopHeaderTemplate16",
        //pivot:true,
        hide: true,
        chartDataType: "category"
      },
      {
        field: "zone",
        rowGroup: true,
        cellClass: "cellTopHeaderTemplate14",
        hide: true
        //chartDataType: 'category',
      },
      {
        field: "invoice_date",
        //rowGroup: true,
        cellClass: "cellTopHeaderTemplate14",
        hide: true
        //chartDataType: 'category',
      },
      {
        field: "consignment_code",
        //rowGroup: true,
        cellClass: "cellTopHeaderTemplate14",
        hide: true
        //chartDataType: 'category',
      },
      {
        headerName: "UOM",
        field: "uom",
        width: 120,
        cellClass: ["cellTopHeaderTemplatenb"],
        chartDataType: "excluded",
        valueGetter: function (params) {
          // console.log(params.node.key);
          // if(typeof params.data == 'undefined')
          // {
          //   return "Trucks";
          // }
          // else{
          if (params.node.key == "Sales & Dispatch") {
            return "Trucks";
          } else if (params.node.key == "Spare Parts (Domestic)") {
            return "Consignments";
          } else if (params.node.key == "Spare Parts (Courier)") {
            return "Consignments";
          } else if (
            params.node.key == "Spare Parts (Container Exports)" ||
            params.node.key == "Spares (Container Exports)"
          ) {
            return "Containers";
          } else if (params.node.key == "Production Parts") {
            return "Trucks";
          } else if (params.node.key == "Container Imports") {
            return "Containers";
          } else if (params.node.key == "Container Exports (KDO)") {
            return "Containers";
          } else if (params.node.key == "Coil Imports") {
            return "Coils";
          }
          // }
        }
        //hide:true
      },

      {
        headerName: "For This Period",
        headerClass: ["cellTopHeaderTemplateH", "cellstylegrid"],
        children: [
          {
            headerName: "Total for selected period",
            headerClass: ["cellstylegrid"],
            field: "set",
            cellClass: ["cellTopHeaderTemplate16", "cellstylegrid", "cpointer"],
            // chartDataType: 'series',
            width: 90,
            chartDataType: "excluded"
            //hide:true
          },
          {
            headerName: "Active for selected period",
            headerClass: ["cellstylegrid"],
            field: "status",
            width: 100,
            chartDataType: "excluded",
            cellClass: ["cellTopHeaderTemplate16", "cellstylegrid", "cpointer"]
            //chartDataType: 'series',
            //hide:true
          }
        ]
      },
      {
        headerName: "Delay Meter",
        headerClass: ["cellTopHeaderTemplateH", "cellstylegrid"],
        children: [
          {
            headerClass: ["cellTopHeaderTemplate14", "cellstylegrid"],
            headerName: "On Time",
            field: "transit_delay_days_0",
            width: 70,
            cellClass: [
              "cellTopHeaderTemplate16",
              "bgColorSuccessLight",
              "cellstylegrid",
              "cpointer"
            ],
            chartDataType: "series"
          },
          {
            headerClass: ["cellTopHeaderTemplate14", "cellstylegrid"],
            headerName: "1-2 Days",
            field: "transit_delay_days_1",
            width: 70,
            cellClass: [
              "cellTopHeaderTemplate16",
              "cellstylegridDY",
              "cellstylegrid",
              "cpointer"
            ],
            chartDataType: "series"
          },
          {
            headerClass: [
              "cellTopHeaderTemplate14",
              "cellstylegrid",
              "cpointer"
            ],
            headerName: ">2 Day",
            field: "transit_delay_days_2",
            width: 70,
            cellClass: [
              "cellTopHeaderTemplate16",
              "bgColorDangerMedium",
              "cellstylegrid",
              "cpointer"
            ],
            chartDataType: "series"
          }
        ]
      },

      {
        headerName: "Cummulative As On Date",
        headerClass: ["cellTopHeaderTemplateH", "cellstylegridB"],
        children: [
          {
            headerClass: ["cellstylegridB"],
            headerName: "Active as on date (Cummulative)",
            field: "activeset",
            width: 130,
            cellClass: [
              "cellTopHeaderTemplate16",
              "cellstylegridB",
              "cpointer"
            ],
            chartDataType: "category"
            //hide:true
          },

          {
            headerClass: ["cellTopHeaderTemplate14", "cellstylegridB"],
            headerName: "On Time",
            field: "active_transit_delay_days_0",
            width: 70,
            cellClass: [
              "cellTopHeaderTemplate16",
              "bgColorSuccessLight",
              "cellstylegridB",
              "cpointer"
            ],
            chartDataType: "series"
          },
          {
            headerClass: ["cellTopHeaderTemplate14", "cellstylegridB"],
            headerName: "1-2 Days",
            field: "active_transit_delay_days_1",
            width: 70,
            cellClass: [
              "cellTopHeaderTemplate16",
              "cellstylegridDY",
              "cellstylegridB",
              "cpointer"
            ],
            chartDataType: "series"
          },
          {
            headerClass: ["cellTopHeaderTemplate14", "cellstylegridB"],
            headerName: ">2 Day",
            field: "active_transit_delay_days_2",
            width: 70,
            cellClass: [
              "cellTopHeaderTemplate16",
              "bgColorDangerMedium",
              "cellstylegridB",
              "cpointer"
            ],
            chartDataType: "series"
          },
          {
            headerClass: ["cellTopHeaderTemplate14", "cellstylegridB"],
            headerName: "Critical",
            field: "criticalset",
            width: 90,
            cellClass: [
              "cellTopHeaderTemplate16",
              "red",
              "cellstylegridB",
              "cpointer"
            ],
            chartDataType: "series"
          }
        ]
      }
      // {
      //   headerClass:["cellTopHeaderTemplate"],
      //   headerName:"order",
      //   width:150,
      //   field: 'delivery_order',
      //   sort:"asc",
      //   sortable:true,
      //   //hide:true
      // },
    ];
    var columnDefs2 = [
      {
        headerClass: ["cellTopHeaderTemplate"],
        headerName: "Department",
        field: "dept_name",
        rowGroup: true,
        width: 300,
        cellClass: "cellTopHeaderTemplate16",
        //pivot:true,
        hide: true,
        chartDataType: "category"
      },
      {
        field: "consignee_area",
        rowGroup: true,
        cellClass: "cellTopHeaderTemplate14",
        hide: true
        //chartDataType: 'category',
      },
      {
        field: "invoice_date",
        //rowGroup: true,
        cellClass: "cellTopHeaderTemplate14",
        hide: true
        //chartDataType: 'category',
      },
      {
        field: "consignment_code",
        //rowGroup: true,
        cellClass: "cellTopHeaderTemplate14",
        hide: true
        //chartDataType: 'category',
      },
      {
        headerName: "UOM",
        field: "uom",
        width: 120,
        cellClass: ["cellTopHeaderTemplatenb"],
        chartDataType: "excluded",
        valueGetter: function (params) {
          // console.log(params.node.key);
          // if(typeof params.data == 'undefined')
          // {
          //   return "Trucks";
          // }
          // else{
          if (params.node.key == "Sales & Dispatch") {
            return "Trucks";
          } else if (params.node.key == "Spare Parts (Domestic)") {
            return "Consignments";
          } else if (params.node.key == "Spare Parts (Courier)") {
            return "Consignments";
          } else if (
            params.node.key == "Spare Parts (Container Exports)" ||
            params.node.key == "Spares (Container Exports)"
          ) {
            return "Containers";
          } else if (params.node.key == "Production Parts") {
            return "Trucks";
          } else if (params.node.key == "Container Imports") {
            return "Containers";
          } else if (params.node.key == "Container Exports (KDO)") {
            return "Containers";
          } else if (params.node.key == "Coil Imports") {
            return "Coils";
          }
          // }
        }
        //hide:true
      },

      {
        headerName: "For This Period",
        headerClass: ["cellTopHeaderTemplateH", "cellstylegrid"],
        children: [
          {
            headerName: "Total for selected period",
            headerClass: ["cellstylegrid"],
            field: "set",
            cellClass: ["cellTopHeaderTemplate16", "cellstylegrid", "cpointer"],
            // chartDataType: 'series',
            width: 90,
            chartDataType: "excluded"
            //hide:true
          },
          {
            headerName: "Active for selected period",
            headerClass: ["cellstylegrid"],
            field: "status",
            width: 100,
            chartDataType: "excluded",
            cellClass: ["cellTopHeaderTemplate16", "cellstylegrid", "cpointer"]
            //chartDataType: 'series',
            //hide:true
          }
        ]
      },
      {
        headerName: "Delay Meter",
        headerClass: ["cellTopHeaderTemplateH", "cellstylegrid"],
        children: [
          {
            headerClass: ["cellTopHeaderTemplate14", "cellstylegrid"],
            headerName: "On Time",
            field: "transit_delay_days_0",
            width: 70,
            cellClass: [
              "cellTopHeaderTemplate16",
              "bgColorSuccessLight",
              "cellstylegrid",
              "cpointer"
            ],
            chartDataType: "series"
          },
          {
            headerClass: ["cellTopHeaderTemplate14", "cellstylegrid"],
            headerName: "1-2 Days",
            field: "transit_delay_days_1",
            width: 70,
            cellClass: [
              "cellTopHeaderTemplate16",
              "cellstylegridDY",
              "cellstylegrid",
              "cpointer"
            ],
            chartDataType: "series"
          },
          {
            headerClass: [
              "cellTopHeaderTemplate14",
              "cellstylegrid",
              "cpointer"
            ],
            headerName: ">2 Day",
            field: "transit_delay_days_2",
            width: 70,
            cellClass: [
              "cellTopHeaderTemplate16",
              "bgColorDangerMedium",
              "cellstylegrid",
              "cpointer"
            ],
            chartDataType: "series"
          }
        ]
      },

      {
        headerName: "Cummulative As On Date",
        headerClass: ["cellTopHeaderTemplateH", "cellstylegridB"],
        children: [
          {
            headerClass: ["cellstylegridB"],
            headerName: "Active as on date (Cummulative)",
            field: "activeset",
            width: 130,
            cellClass: [
              "cellTopHeaderTemplate16",
              "cellstylegridB",
              "cpointer"
            ],
            chartDataType: "category"
            //hide:true
          },

          {
            headerClass: ["cellTopHeaderTemplate14", "cellstylegridB"],
            headerName: "On Time",
            field: "active_transit_delay_days_0",
            width: 70,
            cellClass: [
              "cellTopHeaderTemplate16",
              "bgColorSuccessLight",
              "cellstylegridB",
              "cpointer"
            ],
            chartDataType: "series"
          },
          {
            headerClass: ["cellTopHeaderTemplate14", "cellstylegridB"],
            headerName: "1-2 Days",
            field: "active_transit_delay_days_1",
            width: 70,
            cellClass: [
              "cellTopHeaderTemplate16",
              "cellstylegridDY",
              "cellstylegridB",
              "cpointer"
            ],
            chartDataType: "series"
          },
          {
            headerClass: ["cellTopHeaderTemplate14", "cellstylegridB"],
            headerName: ">2 Day",
            field: "active_transit_delay_days_2",
            width: 70,
            cellClass: [
              "cellTopHeaderTemplate16",
              "bgColorDangerMedium",
              "cellstylegridB",
              "cpointer"
            ],
            chartDataType: "series"
          },
          {
            headerClass: ["cellTopHeaderTemplate14", "cellstylegridB"],
            headerName: "Critical",
            field: "criticalset",
            width: 90,
            cellClass: [
              "cellTopHeaderTemplate16",
              "red",
              "cellstylegridB",
              "cpointer"
            ],
            chartDataType: "series"
          }
        ]
      }
      // {
      //   headerClass:["cellTopHeaderTemplate"],
      //   headerName:"order",
      //   width:150,
      //   field: 'delivery_order',
      //   sort:"asc",
      //   sortable:true,
      //   //hide:true
      // },
    ];

    var header = "";
    var field = "";
    var hidden = "";
    var courier_Headers;
    var hideTruckNo;
    var consginmentHeader = {};
    if (this.state.dept_code == "SNDG") {
      header = "Truck No";
      hidden = true;
      courier_Headers = true;
    } else if (
      this.state.dept_code == "LOG-PRT" ||
      this.state.dept_code == "LOG-PRT (Courier)"
    ) {
      header = "Truck No";
      hidden = false;
      if (this.state.dept_code == "LOG-PRT (Courier)") {
        courier_Headers = false;
        hideTruckNo = true;
      } else {
        courier_Headers = true;
        hideTruckNo = false;
      }
    } else if (this.state.dept_code == "LOG-SC") {
      header = "Container No";
      hidden = false;
      courier_Headers = true;
    } else if (this.state.dept_code == "LOG-TNP") {
      header = "Truck No";
      hidden = false;
      courier_Headers = true;
    } else {
      header = "Consignment Code";
      hidden = true;
      courier_Headers = true;
    }
    var hideForceClose;
    var transporterCodeHide;
    if (this.state.dept_code == "LOG-PRT (Courier)") {
      hideForceClose = false;
      transporterCodeHide = true;
    } else {
      hideForceClose = true;
      transporterCodeHide = false;
    }
    var hiddencols = false;
    if (this.state.dept_code == "LOG-SC") {
      hiddencols = true;
    }

    var criticalColumns = [
      {
        headerName: header,
        field: "consignment_code",
        width: 130,
        filter: true,
        resizable: true,
        hide: hideTruckNo,
        editable: false
      },

      {
        headerName: "Consignment code",
        field: "consignment_no",
        width: 120,
        filter: true,
        hide: hidden,
        resizable: true,
        editable: false
      },
      {
        headerName: "MGPA Date",
        field: "",
        width: 150,
        filter: true,
        resizable: true,
        editable: false,
        hide: courier_Headers,
        valueGetter: function (params) {
          if (
            params.data.mgpa_date_time != "" &&
            params.data.mgpa_date_time != null &&
            params.data.mgpa_date_time != undefined
          ) {
            return getHyphenDDMMMYYYYHHMM(params.data.mgpa_date_time);
          } else {
            return "";
          }
        },
        //filter: "agDateColumnFilter",
        comparator: dateComparator
        // filterParams: {
        //   browserDatePicker: true,
        //   filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
        //   // provide comparator function
        //   comparator: function(filteredDate,cellValue,secondDate) {
        //     cellValue = cellValue.replace(/\//g,"-")
        //     if(cellValue != "" && cellValue != " "){
        //       cellValue = cellValue.split(" ")[0].split("-");
        //       cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
        //       var cellDate = new Date(cellValue);
        //       if(filteredDate.getMonth() < 10){
        //       var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
        //       }
        //       else{
        //         var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
        //       }
        //       var filteredDate = new Date(filterValue);
        //       cellDate = cellDate.getTime();
        //       filteredDate = filteredDate.getTime();
        //       // console.log(cellDate,filteredDate);
        //       if(cellDate === filteredDate)
        //       {
        //         return 0;
        //       }
        //       if(cellDate < filteredDate){
        //         return -1;
        //       }
        //       if(cellDate > filteredDate)
        //       {
        //         return 1;
        //       }
        //     }
        //   }
        // },
      },
      {
        headerName: "Consigner Code",
        width: 150,
        // width:100,
        // pinned : "left",
        field: "consigner_code",
        filter: true,
        resizable: true,
        editable: false
      },
      {
        headerName: "Consignee Name",
        field: "consignee_name",
        width: 150,
        // pinned : "left",
        filter: true,
        resizable: true,
        editable: false
      },
      {
        headerName: "Courier Status",
        field: "last_status",
        width: 150,
        // pinned : "left",
        filter: true,
        resizable: true,
        hide: hideForceClose,
        editable: false
      },
      {
        headerName: "Transporter Code",
        field: "transporter_code",
        width: 105,
        filter: true,
        resizable: true,
        // pinned : "left",
        editable: false,
        hide: transporterCodeHide
      },
      {
        headerName: "Transporter Name",
        field: "transporter_name",
        width: 150,
        filter: true,
        resizable: true,
        editable: false,
        valueGetter: function (params) {
          if (params.data.transporter_name != undefined) {
            return params.data.transporter_name;
          } else {
            if (params.data.transporters_name != undefined) {
              return params.data.transporters_name;
            } else {
              return "";
            }
          }
        }
      },

      // {
      // 	headerName : "TPT Confirmed Delivery",
      // 	field : "tpt_confirm_delivery",
      // 	width:125,
      // 	resizable : true,
      // 	filter:true,
      // 	valueGetter:function(params){
      // 		if(params.data.tpt_confirm_delivery == 1)
      // 		{
      // 			return "Yes";
      // 		}
      // 		else
      // 		{
      // 			return "No";
      // 		}
      // 	}
      // },
      // {
      //   headerName : "TPT Delivered On",
      //   field : "tpt_delivery_date",
      //   resizable : true,
      //   filter : true,
      //   width:150,
      //   valueGetter : function(params){
      //     if(params.data.tpt_delivery_date != ""){
      //       return getHyphenDDMMMYYYYHHMM(params.data.tpt_delivery_date);
      //     }
      //   },
      //   //filter: "agDateColumnFilter",
      //   comparator: dateComparator,

      // },
      {
        headerName: "Destination City",
        field: "consignee_city",
        // width:100,
        width: 150,
        filter: true,
        resizable: true,
        editable: false
      },
      {
        headerName: "Original ETA",
        field: "expected_trip_end",
        width: 150,
        // filter: false,
        // pinned : "left",
        resizable: true,
        editable: false,
        valueGetter: function (params) {
          if (params.data.expected_trip_end != "") {
            return getHyphenDDMMMYYYYHHMM(params.data.expected_trip_end);
          } else {
            return "";
          }
        },
        //filter: "agDateColumnFilter",
        comparator: dateComparator
        // filterParams: {
        //   browserDatePicker: true,
        //   filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
        //   // provide comparator function
        //   comparator: function(filteredDate,cellValue,secondDate) {
        //     cellValue = cellValue.replace(/\//g,"-")
        //     if(cellValue != "" && cellValue != " "){
        //       cellValue = cellValue.split(" ")[0].split("-");
        //       cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
        //       var cellDate = new Date(cellValue);
        //       if(filteredDate.getMonth() < 10){
        //       var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
        //       }
        //       else{
        //         var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
        //       }
        //       var filteredDate = new Date(filterValue);
        //       cellDate = cellDate.getTime();
        //       filteredDate = filteredDate.getTime();
        //       // console.log(cellDate,filteredDate);
        //       if(cellDate === filteredDate)
        //       {
        //         return 0;
        //       }
        //       if(cellDate < filteredDate){
        //         return -1;
        //       }
        //       if(cellDate > filteredDate)
        //       {
        //         return 1;
        //       }
        //     }
        //   }
        // },
      },
      {
        headerName: "Transit Delay (Days)",
        field: "transit_delay_days",
        width: 120,
        filter: true,
        resizable: true,
        editable: false
      },
      //   {
      //       headerName: "SPOC Name",
      //       field: "spoc_contact_name",
      //       editable:true,
      //       width:150,
      //       filter: true,
      //       resizable: true,
      //       editable:false
      //   },
      //   {
      //       headerName: "SPOC Contact No.",
      //       field: "spoc_contact_number",
      //       editable:true,
      //       width:150,
      //       filter: true,
      //       resizable: true,
      //       editable:false
      //   },
      //   {
      //     headerName: "Last City / Area.",
      //     field: "area",
      //     editable:true,
      //     width:150,
      //     filter: true,
      //     resizable: true,
      //     editable:false
      //   },
      //   {
      //     headerName: "Last State",
      //     field: "state",
      //     editable:true,
      //     width:150,
      //     filter: true,
      //     resizable: true,
      //     editable:false
      // },
      {
        headerName: "Last Packet Time",
        field: "timestamp",
        width: 150,
        filter: true,
        resizable: true,
        editable: false,
        valueGetter: function (params) {
          // console.log("params", params.data);
          if (params.data.timestamp != "" && params.data.timestamp != "NaT") {
            return getHyphenDDMMMYYYYHHMM(params.data.timestamp);
          } else {
            return "";
          }
        },
        //filter: "agDateColumnFilter",
        comparator: dateComparator
        // filterParams: {
        //   browserDatePicker: true,
        //   filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
        //   // provide comparator function
        //   comparator: function(filteredDate,cellValue,secondDate) {
        //     cellValue = cellValue.replace(/\//g,"-")
        //     if(cellValue != "" && cellValue != " "){
        //       cellValue = cellValue.split(" ")[0].split("-");
        //       cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
        //       var cellDate = new Date(cellValue);
        //       if(filteredDate.getMonth() < 10){
        //       var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
        //       }
        //       else{
        //         var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
        //       }
        //       var filteredDate = new Date(filterValue);
        //       cellDate = cellDate.getTime();
        //       filteredDate = filteredDate.getTime();
        //       // console.log(cellDate,filteredDate);
        //       if(cellDate === filteredDate)
        //       {
        //         return 0;
        //       }
        //       if(cellDate < filteredDate){
        //         return -1;
        //       }
        //       if(cellDate > filteredDate)
        //       {
        //         return 1;
        //       }
        //     }
        //   }
        // },
      },
      {
        headerName: "Travel Status",
        field: "travel_status",
        width: 100,
        editable: true,
        filter: true,
        resizable: true
      },
      {
        headerName: "GPS Status",
        field: "timestamp",
        width: 100,
        editable: true,
        filter: true,
        resizable: true,
        valueGetter: params => {
          var d1 = moment.parseZone(new Date()).format("YYYY-MM-DD HH:mm:ss");
          var d2 = moment
            .parseZone(params.timestamp)
            .format("YYYY-MM-DD HH:mm:ss");
          var ms = moment(d1, "YYYY-MM-DD HH:mm:ss").diff(
            moment(d2, "YYYY-MM-DD HH:mm:ss")
          );
          var d = moment.duration(ms);
          var s = Math.floor(d.asHours());
          if (s >= 2) {
            return "Inactive";
          } else {
            return "Active";
          }
        }
      },
      {
        headerName: "Address",
        field: "address",
        width: 100,
        editable: true,
        filter: true,
        resizable: true
      },
      {
        headerName: "Distance To Dealer(Km)",
        field: "distance_from_dealer_location",
        width: 160,
        editable: true,
        filter: true,
        resizable: true
      },

      {
        headerName: "Delay Reason",
        field: "delay_reason",
        width: 245,
        editable: true,
        filter: true,
        resizable: true
      },
      {
        headerName: "Force Close",
        field: "consignment_code",
        width: 120,
        hide: hideForceClose,
        cellRendererSelector: function (params) {
          // console.log(params);
          var rendComponent = {
            component: "consignmentforceclose"
          };
          return rendComponent;
        },
        filter: false,
        resizable: true
      },
      {
        headerName: "Mark Deliver",
        field: "consignment_code",
        width: 120,
        hide: hideForceClose,
        cellRendererSelector: function (params) {
          // console.log(params);
          var rendComponent = {
            component: "MarkDeliver"
          };
          return rendComponent;
        },
        filter: true,
        resizable: true
      }
    ];

    if (this.state.dept_code == "LOG-PRT") {
      var dailyurl = "/prtdailyrunningdashboard";
    } else {
      var dailyurl = "/dailyrunningdashboard";
    }
    return (
      <div className="col-xl-12 col-lg-12 ">
        <div className={"row col-xl-12 col-lg-12 "}>
          <ul className="dashboard-page">
            {/*
               <li className="active">
                    {(this.state.dept_code == "SNDG")?
                        <a href={"/snddashboardtemplate"} title="Performance Metrics" className="loadclickm" id="dashboard" onClick={this.onClickLoadDept.bind(this,'dashboard')}>
                            <img src={require("../../assets/icons/performance.png")} className="dashboard-icon" />
                        </a>
                    :""}
                    {(this.state.dept_code == "LOG-PRT")?
                    <a href={"/prtdashboardtemplate"} title="Performance Metrics" className="loadclickm" id="dashboard" onClick={this.onClickLoadDept.bind(this,'dashboard')}>
                            <img src={require("../../assets/icons/performance.png")} className="dashboard-icon" />
                        </a>
                    :""}
                    {(this.state.dept_code == "LOG-TNP")?
                    <a href={"/tnpdashboardtemplate"} title="Performance Metrics" className="loadclickm" id="dashboard" onClick={this.onClickLoadDept.bind(this,'dashboard')}>
                            <img src={require("../../assets/icons/performance.png")} className="dashboard-icon" />
                        </a>
                    :""}
                    
                </li>
                {(this.state.dept_code != 'LOG-SC')?
                  <li className="btn-group">
                    <a href="javascript:;"  className="loadclickm" id="load">
                      <img src={require("../../assets/icons/kpi.png")} className="dashboard-icon" />
                       
                      <ul className="dashboard-sub" style={{"display":"none"}}>
                       
                          {(this.state.dept_code == 'SNDG' || this.state.dept_code == 'LOG-PRT') ?
                              <li>
                                  <a href={dailyurl}>
                                      Daily Running
                                  </a>
                              </li>
                              :""}
                              {(this.state.dept_code == "SNDG")?
                              <li>
                                  <a href="/sndloadingdashboard">
                                      Loading Dashboard
                                  </a>
                              </li>
      
                              :""}
                              {(this.state.dept_code == "LOG-PRT")?
                              <li>
                                  <a href="/prtloadingdashboard">
                                      Loading Dashboard
                                  </a>
                              </li>
      
                              :""}
                              {(this.state.dept_code == "LOG-TNP")?
                              <li>
                                  <a href="/tnploadingdashboard">
                                      Loading Dashboard
                                  </a>
                              </li>
                            :""}

                              <li>
                                  {(this.state.dept_code == "SNDG")?
                                  <a href="/sndunloadingdashboard">
                                      Unloading Dashboard
                                  </a>
                                  :""}
                                  {(this.state.dept_code == "LOG-PRT")?
                                  <a href="/prtunloadingdashboard">
                                      Unloading Dashboard
                                  </a>
                                  :""}
                                  {(this.state.dept_code == "LOG-TNP")?
                                  <a href="/tnpunloadingdashboard">
                                      Unloading Dashboard
                                  </a>
                                  :""}
                                  
                              </li>
                              
                              
                            
                          {(this.state.dept_code == 'SNDG')?
                              <li>
                              <a href="/sndtransportdashboard">
                                  Transporter Daily
                              </a>
                              </li>
                              :""}

                              {(this.state.dept_code == 'SNDG' && this.props.match.path != "/dailyrunningmanagement")?
                              <li >
                              <a href="/ontimedashboard">
                                On TIme Performance
                              </a>
                              </li>
                              :""}
                              {(this.state.dept_code == 'LOG-PRT')?
                                <li >
                                  <a href="/prtontimedashboard">
                                    On TIme Performance
                                  </a>
                                </li>
                              :""}
                          </ul>
                    </a>
                  </li>
                  :""*/}
          </ul>
        </div>
        <div
          className={
            "row col-xl-12 col-lg-12 tab- pt-20px whiteRow ml-18p " +
            this.state.showdashboard
          }
        >
          <div className="col-xl-12 col-lg-12">
            <h5>
              <i className="icofont icofont-dashboard-web cus-i"></i>{" "}
              <span>{this.state.pagetitle}</span>
              {/* <span className="float-right">
                <a href="/criticaldelayreasons" className="btn btn-warning">
                  Update Remarks (Critical Delays)
                </a>
              </span> */}
            </h5>
          </div>
          
          <div className="row">
            <div className=" col-xl-7 col-lg-7">
              <div className="row">
                <div className="col-xl-3 col-lg-3 form-group">
                  <label>From Date</label>
                  <Datetime
                    value={this.state.startDate}
                    disableCloseOnClickOutside={false}
                    closeOnSelect={true}
                    inputProps={{
                      placeholder: "From",
                      name: "startDate",
                      autoComplete: "off"
                    }}
                    dateFormat="YYYY-MM-DD"
                    name="startDate"
                    onChange={this.handlerStartDateTime}
                  />
                </div>

                <div className="col-xl-3 col-lg-3 form-group">
                  <label>To Date</label>
                  <Datetime
                    value={this.state.endDate}
                    disableCloseOnClickOutside={false}
                    closeOnSelect={true}
                    inputProps={{
                      placeholder: "To",
                      name: "endDate",
                      autoComplete: "off"
                    }}
                    dateFormat="YYYY-MM-DD"
                    onChange={this.handlerEndDateTime}
                  />
                </div>

                <div className="form-group col-xl-3 col-lg-3">
                  <label>&nbsp;</label>
                  <br />
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={this.onClickSummaryData}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4">
              <div className="row">
                {/* 
                <div className="form-group col-xl-2 col-lg-2">
                  <input type="text" name="searchkey" onChange={this.onFilterTextBoxChanged.bind(this)} value={this.state.searchkey} />
                </div> */}
                <div className="form-gplantwiseloadingroup col-xl-9 col-lg-9">
                  <label>View By Type: </label>
                  <Select
                    placeholder={"All"}
                    isMulti={false}
                    value={this.state.zone}
                    className="border-radius-0"
                    onChange={this.onSelectType}
                    style={{ borderRadius: "0px" }}
                    options={this.state.zones}
                    required
                  />
                </div>
                <div className="form-gplantwiseloadingroup col-xl-3 col-lg-3">
                  <label>&nbsp;</label>
                  <br />
                  <button tyepe="button" className="btn btn-success" onClick={this.onClickGetData}>Get Data</button>
                </div>
              </div>
            </div>
          </div>
          <div
            id="myGrid"
            style={{
              height: "550px",
              width: "100%"
            }}
            className={"ag-theme-balham " + (this.state.plantgrid)}
          >
            <AgGridReact
              modules={this.state.modules}
              columnDefs={columnDefs}
              defaultColDef={this.state.defaultColDef}
              autoGroupColumnDef={this.state.autoGroupColumnDef}
              sideBar={false}
              groupUseEntireRow={false}
              enableRangeSelection={true}
              groupRowAggNodes={this.groupRowAggNodes}
              onGridReady={this.onGridReady.bind(this)}
              onGridState={this.onGridState.bind(this)}
              rowData={this.state.rowData}
              groupHeaderHeight={this.state.groupHeaderHeight}
              headerHeight={this.state.headerHeight}
              floatingFiltersHeight={this.state.floatingFiltersHeight}
              pivotGroupHeaderHeight={this.state.pivotGroupHeaderHeight}
              pivotHeaderHeight={this.state.pivotHeaderHeight}
              rowHeight={this.state.rowHeight}
              frameworkComponents={this.state.frameworkComponents}
              popupParent={this.state.popupParent}
              enableCharts={true}
              processChartOptions={this.state.processChartOptions}
              onFirstDataRendered={this.onFirstDataRendered.bind(this)}
              getChartToolbarItems={this.getChartToolbarItems}
              suppressChartRanges={true}
              //defaultGroupSortComparator={this.state.defaultGroupSortComparator}
              onRowGroupOpened={this.onRowGroupOpened.bind(this)}
              //suppressCount={true}
              onCellClicked={this.onShowLegs.bind(this)}
            // detailCellRendererParams={this.state.detailCellRendererParams}
            //masterDetail={true}
            />
            {/*<div id="chart" style={{ float: 'left', overflow: 'hidden', width:"50%",height: '500px' }}></div>
            <div id="chartactive" style={{ float: 'left', overflow: 'hidden',width:"50%", height: '500px' }}></div>
            <div id="chart2" style={{ flex: '1 1 auto', overflow: 'hidden', width: '50%' }}></div>
            <div id="chart3" style={{ flex: '1 1 auto', overflow: 'hidden', width: '50%' }}></div> */}
          </div>
          <div
            id="myGrid1"
            style={{
              height: "550px",
              width: "100%"
            }}
            className={"ag-theme-balham " + (this.state.zonegrid)}
          >
            <AgGridReact
              modules={this.state.modules}
              columnDefs={columnDefs1}
              defaultColDef={this.state.defaultColDef}
              autoGroupColumnDef={this.state.autoGroupColumnDef}
              sideBar={false}
              groupUseEntireRow={false}
              enableRangeSelection={true}
              groupRowAggNodes={this.groupRowAggNodes}
              onGridReady={this.onGridReady1.bind(this)}
              onGridState={this.onGridState1.bind(this)}
              rowData={this.state.rowData1}
              groupHeaderHeight={this.state.groupHeaderHeight}
              headerHeight={this.state.headerHeight}
              floatingFiltersHeight={this.state.floatingFiltersHeight}
              pivotGroupHeaderHeight={this.state.pivotGroupHeaderHeight}
              pivotHeaderHeight={this.state.pivotHeaderHeight}
              rowHeight={this.state.rowHeight}
              frameworkComponents={this.state.frameworkComponents}
              popupParent={this.state.popupParent}
              enableCharts={true}
              processChartOptions={this.state.processChartOptions}
              onFirstDataRendered={this.onFirstDataRendered.bind(this)}
              getChartToolbarItems={this.getChartToolbarItems}
              suppressChartRanges={true}
              //defaultGroupSortComparator={this.state.defaultGroupSortComparator}
              onRowGroupOpened={this.onRowGroupOpened.bind(this)}
              //suppressCount={true}
              onCellClicked={this.onShowLegs.bind(this)}
            // detailCellRendererParams={this.state.detailCellRendererParams}
            //masterDetail={true}
            />
            {/*<div id="chart" style={{ float: 'left', overflow: 'hidden', width:"50%",height: '500px' }}></div>
            <div id="chartactive" style={{ float: 'left', overflow: 'hidden',width:"50%", height: '500px' }}></div>
            <div id="chart2" style={{ flex: '1 1 auto', overflow: 'hidden', width: '50%' }}></div>
            <div id="chart3" style={{ flex: '1 1 auto', overflow: 'hidden', width: '50%' }}></div> */}
          </div>
          <div
            id="myGrid2"
            style={{
              height: "550px",
              width: "100%"
            }}
            className={"ag-theme-balham " + (this.state.areagrid)}
          >
            <AgGridReact
              modules={this.state.modules}
              columnDefs={columnDefs2}
              defaultColDef={this.state.defaultColDef}
              autoGroupColumnDef={this.state.autoGroupColumnDef}
              sideBar={false}
              groupUseEntireRow={false}
              enableRangeSelection={true}
              groupRowAggNodes={this.groupRowAggNodes}
              onGridReady={this.onGridReady2.bind(this)}
              onGridState={this.onGridState2.bind(this)}
              rowData={this.state.rowData2}
              groupHeaderHeight={this.state.groupHeaderHeight}
              headerHeight={this.state.headerHeight}
              floatingFiltersHeight={this.state.floatingFiltersHeight}
              pivotGroupHeaderHeight={this.state.pivotGroupHeaderHeight}
              pivotHeaderHeight={this.state.pivotHeaderHeight}
              rowHeight={this.state.rowHeight}
              frameworkComponents={this.state.frameworkComponents}
              popupParent={this.state.popupParent}
              enableCharts={true}
              processChartOptions={this.state.processChartOptions}
              onFirstDataRendered={this.onFirstDataRendered.bind(this)}
              getChartToolbarItems={this.getChartToolbarItems}
              suppressChartRanges={true}
              //defaultGroupSortComparator={this.state.defaultGroupSortComparator}
              onRowGroupOpened={this.onRowGroupOpened.bind(this)}
              //suppressCount={true}
              onCellClicked={this.onShowLegs.bind(this)}
            // detailCellRendererParams={this.state.detailCellRendererParams}
            //masterDetail={true}
            />
            {/*<div id="chart" style={{ float: 'left', overflow: 'hidden', width:"50%",height: '500px' }}></div>
            <div id="chartactive" style={{ float: 'left', overflow: 'hidden',width:"50%", height: '500px' }}></div>
            <div id="chart2" style={{ flex: '1 1 auto', overflow: 'hidden', width: '50%' }}></div>
            <div id="chart3" style={{ flex: '1 1 auto', overflow: 'hidden', width: '50%' }}></div> */}
          </div>
          <div className="row mt-20p">
            {this.state.summarydata.map(item =>
              this.state.dept_code == "SNDG" ? (
                item.dept_code == this.state.dept_code ? (
                  <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                    <ColumnGroup
                      context={this}
                      series={item.series}
                      categories={item.categories}
                      title={item.title}
                      subtitle={"On time performance of the on going trips"}
                      yaxistitle={item.yaxistitle}
                      code={item.code}
                      critical_data={item.critical_data}
                      dept_code={item.dept_code}
                    />
                  </div>
                ) : (
                  ""
                )
              ) : (
                ""
              )
            )}

            {this.state.summarydata.map(item =>
              this.state.dept_code == "LOG-TNP" ? (
                item.dept_code == this.state.dept_code ? (
                  <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                    <ColumnGroup
                      context={this}
                      series={item.series}
                      categories={item.categories}
                      title={item.title}
                      subtitle={"On time performance of the on going trips"}
                      yaxistitle={item.yaxistitle}
                      code={item.code}
                      critical_data={item.critical_data}
                      dept_code={item.dept_code}
                    />
                  </div>
                ) : (
                  ""
                )
              ) : (
                ""
              )
            )}

            {this.state.summarydata.map(item =>
              this.state.dept_code == "LOG-PRT" ? (
                item.dept_code == "LOG-PRT" ||
                  item.dept_code == "LOG-PRT (Courier)" ? (
                  <div className="col-xl-6 col-lg-6 col-sm-6 beffect">
                    <ColumnGroup
                      context={this}
                      series={item.series}
                      categories={item.categories}
                      title={item.title}
                      subtitle={"On time performance of the on going trips"}
                      yaxistitle={item.yaxistitle}
                      code={item.code}
                      critical_data={item.critical_data}
                      dept_code={item.dept_code}
                    />
                  </div>
                ) : (
                  ""
                )
              ) : (
                ""
              )
            )}

            {this.state.summarydata.map(item =>
              this.state.dept_code == "LOG-SC" ? (
                item.dept_code == this.state.dept_code ||
                  item.dept_code == "LOG-SC (Exports)" ? (
                  <div className="col-xl-6 col-lg-6 col-sm-6 beffect">
                    <ColumnGroup
                      context={this}
                      series={item.series}
                      categories={item.categories}
                      title={item.title}
                      subtitle={"On time performance of the on going trips"}
                      yaxistitle={item.yaxistitle}
                      code={item.code}
                      critical_data={item.critical_data}
                      dept_code={item.dept_code}
                    />
                  </div>
                ) : (
                  ""
                )
              ) : (
                ""
              )
            )}
          </div>
          <div className={"dataLoadpage " + this.state.loadshow}></div>
          <div className={"dataLoadpageimg " + this.state.loadshow}>
            <div className="loader-box">
              <div className="loader-box">
                <div className="rotate dashed colored"></div>
              </div>
            </div>
          </div>
          <div
            className={"overlay-part " + this.state.overly}
            onClick={this.onClickHideAll.bind(this)}
          ></div>

          <div
            className={"slide-r " + this.state.sliderRouteTranslate}
            style={{ overflow: "auto" }}
          >
            <div className="slide-r-title">
              <h4>
                {this.state.sidebartitle}
                <span
                  className="float-right closebtn"
                  style={{ marginRight: "25px" }}
                  onClick={this.onCloseRouteDiv.bind(this)}
                >
                  X
                </span>
              </h4>
            </div>
            <div className="slide-r-body" style={{ position: "relative" }}>
              <div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
                <div
                  id="myGrid"
                  style={{
                    height: "600px",
                    width: "100%"
                  }}
                  className="ag-theme-balham"
                >
                  <AgGridReact
                    modules={this.state.modules}
                    columnDefs={this.state.legcolumns}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.state.legrows}
                    paginationPageSize={this.state.paginationPageSize}
                    pagination={true}
                    onCellEditingStopped={this.updateDelayRemarks.bind(this)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            className={"slide-r " + this.state.sliderCriticalTranslate}
            style={{ overflow: "auto" }}
          >
            <div className="slide-r-title">
              <h4>
                Critical Delay Reasons
                <span
                  className="float-right closebtn"
                  style={{ marginRight: "25px" }}
                  onClick={this.onCloseRouteDiv.bind(this)}
                >
                  X
                </span>
              </h4>
            </div>
            <div className="slide-r-body" style={{ position: "relative" }}>
              <div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
                <div
                  id="myGrid"
                  style={{
                    height: "600px",
                    width: "100%"
                  }}
                  className="ag-theme-balham"
                >
                  <AgGridReact
                    modules={this.state.modules}
                    columnDefs={criticalColumns}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.state.rowCriticalData}
                    paginationPageSize={this.state.paginationPageSize}
                    pagination={true}
                    onCellEditingStopped={this.onCellUpdateData.bind(this)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className={"col-xl-12 col-lg-12 whiteRow n-p-0 "+(this.state.loaddashboard)}>
          <ul className="tabc-sbm col-xl-12 col-lg-12 row n-p-0" style={{marginTop:"-22px"}}>
            <li>
              <a href="javascript:;" className="biclickm active" id="loaddashboard" onClick={this.onClickBIItem.bind(this,'loaddashboard')}>
                 <span>Loading Analytics </span>
              </a>
            </li>
            {(this.state.dept_code == 'SNDG')?
            
            <li>
              <a href="javascript:;" className="biclickm" id="unloaddashboard" onClick={this.onClickBIItem.bind(this,'unloaddashboard')}>
                <span>Unloading Analytics </span>
              </a>
            </li>
          :""}
            {(this.state.dept_code == 'SNDG')?
            <li>
              <a href="javascript:;" className="biclickm" id="dailydashboard" onClick={this.onClickBIItem.bind(this,'dailydashboard')}>
                  <span>Daily Running Summary </span>
              </a>
            </li>
             :""}
            
            
            <li>
              <a href="javascript:;" className="biclickm" id="transitanalytics" onClick={this.onClickBIItem.bind(this,'transitanalytics')}>
                  <span>Transit Analytics </span>
              </a>
            </li>
            {(this.state.dept_code == 'SNDG')?
            <li>
            <a href="javascript:;" className="biclickm" id="transporterdaily" onClick={this.onClickBIItem.bind(this,'transporterdaily')}>
                <span>Transporter Daily Running </span>
            </a>
          </li>
          :""}
            
          </ul>
              <div className={"col-xl-12 col-lg-12 n-p-0 mt-35p "+(this.state.bila)}>
                  <LoadingDashboard  dept_code={this.state.dept_code} />
              </div>
              <div className={"col-xl-12 col-lg-12 n-p-0 mt-35p "+(this.state.biunla)}>
                  <UnLoadingDashboard  dept_code={this.state.dept_code} />
              </div>
              <div className={"col-xl-12 col-lg-12 n-p-0 mt-35p "+(this.state.bidrd)}>
                <DailyDashboard showexclude={1} />
             </div>
            <div className={"col-xl-12 col-lg-12 n-p-0 mt-35p "+(this.state.bitransit)}>
                <TransitDashboard dept_code={this.state.dept_code}  />
            </div>
            <div className={"col-xl-12 col-lg-12 n-p-0 mt-35p "+(this.state.bitransport)}>
                <TransporterDailyRunning dept_code={this.state.dept_code}  />
            </div>
          </div>  */}

        <span
          className="usermanual f22"
          onClick={this.onClickShowUserManaul.bind(this)}
        >
          <i className="icofont icofont-read-book-alt"></i>
        </span>
        <Modal
          open={usermanualmodal}
          onClose={this.onCloseUserManualModal.bind(this)}
          styles={modalStyles}
        >
          <div
            className={"row"}
            style={{ padding: "5px 0px", width: "100%", zIndex: "9999" }}
          >
            <h5 className="model-title">Dashboard</h5>
            <div className="col-xl-12 col-lg-12 mt-20p">
              <div className="col-xl-12 col-lg-12">
                Dashboard screen represents the summary of all currently active
                consignments which are running in below mentioned brackets
              </div>
              <div className="col-xl-12 col-lg-12">
                <ul>
                  <li>
                    Total trucks: Number of trucks which are currently in
                    transit{" "}
                  </li>
                  <li>On time : Number of trucks which are running on time</li>
                  <li>
                    Delay by 1 day: Number of trucks which will be delayed by 1
                    day{" "}
                  </li>
                  <li>
                    Delay by 2 days: Number of trucks which will be delayed
                    between 1-2 days
                  </li>
                  <li>
                    Delay by more than 2 days: Number of trucks which will be
                    delayed for more than 2 days
                  </li>
                  <li>
                    Critical delay: Number of trucks which are delayed for more
                    than 50% of transit time allocated by HERO{" "}
                  </li>
                </ul>
              </div>
              <div className="col-xl-12 col-lg-12">Image here</div>
              <div className="col-xl-12 col-lg-12">
                The Same is represented in graphical form where the total trucks
                are represented in form of graphs depicting total ,On time,
                Delayed and Critical Delay trucks in form of percentage
              </div>
              <div className="col-xl-12 col-lg-12">Image here</div>
              <div className="col-xl-12 col-lg-12">
                For PRT Department Dashboard is present for number of
                consignments instead of Number of trucks, There are two
                departments in LOG PRT By road consignments and by Courier
                consignments
              </div>
              <div className="col-xl-12 col-lg-12">Image here</div>
              <div className="col-xl-12 col-lg-12">
                The same is also been depicted in form of graphical
                representation
              </div>
              <div className="col-xl-12 col-lg-12">Image here</div>
              <div className="col-xl-12 col-lg-12">
                Apart from Summary there are certain analytics which are also
                available in system along with its respective graphical
                representation are:
              </div>
              <div className="col-xl-12 col-lg-12">
                <ul>
                  <li>
                    <strong>a. For SND Dept</strong>
                    <ul>
                      <li>
                        Daily Running: Daily running analytics regional wise
                      </li>
                      <li>
                        Loading Dashboard: Average time taken to load a vehicke
                        plant wise
                      </li>
                      <li>
                        Unloading Dashboard: Average time taken to unload a
                        vehicle location wise
                      </li>
                      <li>
                        Transit Analytics: Trip closure summary (type of
                        closures) of trips
                      </li>
                      <li>
                        Transport Daily: Daily average running data regional
                        wise
                      </li>
                      <li>
                        GPS Dashboard: Quality of GPS data provided by various
                        service providers
                      </li>
                      <li>
                        On Time Performance: Route wise on time performance
                        analysis
                      </li>
                    </ul>
                  </li>
                  <li>
                    b. For PRT:
                    <ul>
                      <li>
                        Daily Running: Daily running analytics regional wise
                      </li>
                      <li>
                        Loading Dashboard: Average time taken to load a vehicke
                        plant wise
                      </li>
                      <li>
                        Unloading Dashboard: Average time taken to unload a
                        vehicle location wise
                      </li>
                      <li>
                        Transit Analytics: Trip closure summary (type of
                        closures) of trips
                      </li>
                      <li>
                        GPS Dashboard: Quality of GPS data provided by various
                        service providers
                      </li>
                    </ul>
                  </li>
                  <li>
                    b. For TNP:
                    <ul>
                      <li>
                        Loading Dashboard: Average time taken to load a vehicke
                        plant wise
                      </li>
                      <li>
                        Unloading Dashboard: Average time taken to unload a
                        vehicle location wise
                      </li>
                      <li>
                        Transit Analytics: Trip closure summary (type of
                        closures) of trips
                      </li>
                      <li>
                        GPS Dashboard: Quality of GPS data provided by various
                        service providers
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

function strcmp(a, b) {
  return a < b ? -1 : a > b ? 1 : 0;
}
function yAxisLabelFormatter(params) {
  var n = params.value;
  if (n < 1e3) return n;
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
}
// function BarChartLineRenderer() {
// }

// BarChartLineRenderer.prototype.init = function(params) {
//     var eGui = document.createElement('div');
//     this.eGui = eGui;

//     // sparklines requires the eGui to be in the dom - so we put into a timeout to allow
//     // the grid to complete it's job of placing the cell into the browser.
//     setTimeout(function() {
//       console.log(params.value);
//         var values = params.value
//             //.sort(function(a, b) { return a.transit_delay_days_0 - b.transit_delay_days_2; })
//             .map(function(datum) { return datum.AverageVolume.toFixed(); });
//         $(eGui).sparkline(values, {
//             type: 'bar',
//             barColor: 'green',
//             chartRangeMin: 1000000,
//             barWidth: 11,
//             height: CELL_DIMENSION_SIZE,
//             width: CELL_DIMENSION_SIZE
//         });
//     }, 0);
// };

// BarChartLineRenderer.prototype.getGui = function() {
//     return this.eGui;
// };

$(document).ready(function () {
  $(".loadclickm").on("mouseover", function () {
    var id = $(this).attr("id");
    //  $(".loadclickm").removeClass("active");
    $(".dashboard-sub").hide();
    if (id == "load") {
      $("#" + id).addClass("active");
      $(".dashboard-sub").show();
    }
  });

  $(".loadclickm").on("mouseout", function () {
    var id = $(this).attr("id");
    //$(".loadclickm").removeClass("active");
    $(".dashboard-sub").hide();
    // $("#"+id).addClass("active");
    // $("#"+id+">dashboard-sub").show()
  });
  // $(".loadclickm").click(function(){
  //     var id = $(this).attr("id");
  //     $(".loadclickm").removeClass("active");
  //     $("#"+id).addClass("active");
  // })
  // $(".biclickm").click(function(){
  //     var id = $(this).attr("id");
  //     $(".biclickm").removeClass("active");
  //     $("#"+id).addClass("active");
  // })
});

function dateComparator(date1, date2) {
  // console.log("dateComparator");
  // console.log(date1.length);
  if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
    // console.log(date1,date2);
    var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
    date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
    var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
    date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
    // console.log(date1,date2);
    var date1Number = monthToComparableNumber(date1);
    var date2Number = monthToComparableNumber(date2);
    // console.log(date1Number,date2Number);
    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }
    return date1Number - date2Number;
  }
}

function monthToComparableNumber(date) {
  // console.log(date,date.length);
  if (date === undefined || date === null || date.length !== 16) {
    return null;
  }
  var yearNumber = date.substring(6, 10);
  var monthNumber = date.substring(3, 5);
  var dayNumber = date.substring(0, 2);
  console.log(yearNumber, monthNumber, dayNumber);
  var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
  return result;
}

function getMonthNumber(monthName) {
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];
  var monthNum = months.indexOf(monthName) + 1;
  if (monthNum < 10) {
    monthNum = "0" + monthNum.toString();
  }
  return monthNum;
}
function dateComparator1(date1, date2) {
  //console.log(date1,date2);
  if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
    // console.log(date1,date2);
    var date_1 = getMonthNumber(date1.split("-")[1]);
    date1 = date1.replace(date1.split("-")[1], date_1);
    var date_2 = getMonthNumber(date2.split("-")[1]);
    date2 = date2.replace(date2.split("-")[1], date_2);
    var date1Number = monthToComparableNumber1(date1);
    var date2Number = monthToComparableNumber1(date2);
    console.log(date1Number, date2Number);
    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }
    return date1Number - date2Number;
  }
}

function monthToComparableNumber1(date) {
  console.log(date.length);
  console.log(date);
  if (date === undefined || date === null || date.length !== 10) {
    return null;
  }
  var yearNumber = date.substring(6, 10);
  var monthNumber = date.substring(3, 5);
  var dayNumber = date.substring(0, 2);
  var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
  return result;
}
