import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import _, { parseInt } from "lodash";
import Modal from 'react-responsive-modal';
import SweetAlert from 'react-bootstrap-sweetalert';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import Select from 'react-select';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { getHyphenDDMMMYYYYHHMM } from '../common/utils';
import MyMapComponent from "./googlelocationsearch"
import e from 'jquery-datetimepicker';
var moment = require('moment');
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
const decipher = salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
    return encoded => encoded.match(/.{1,2}/g)
        .map(hex => parseInt(hex, 16))
        .map(applySaltToChar)
        .map(charCode => String.fromCharCode(charCode))
        .join('');
}
var decode = decipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
export default class DeviationRequests extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lists: [],
            departmentCode: null,
            currentDepartmentTitle: null,
            currentAddGeoFenceLink: null,
            pagetitle: "Deviation Request",
            rowData: [],
            show1: false,
            basicTitle1: '',
            basicType1: "default",
            show: false,
            basicTitle: '',
            basicType: "default",
            loadshow: "show-n",
            from_date: "",
            to_date: "",
            noOfVehicles: 0,
            deviationType: { label: "Select...", value: "" },
            bdBuildrLocType: { label: "Select...", value: "" },
            startDate: '',
            clearanceDate: '',
            reasonForDeviation: '',
            plantHoldRequestNumber: '',
            requesterEmail: localStorage.getItem("email") || "",
            fAndCMail: '',
            oblEmail: '',
            mAndSApprMail: '',
            reqBusinessHeadMail: "",
            reqL4Email: '',
            emailIds: {
                requesterEmail: '',
                fAndCMail: '',
                oblEmail: '',
                mAndSApprMail: '',
                reqBusinessHeadMail: "",
                reqL4Email: '',
            },
            errors: {},
            startDate: moment().utcOffset("+05:30").format("DD-MM-YYYY HH:mm"), // Set default to today
            clearanceDate: moment().utcOffset("+05:30").add(1, 'days').format("DD-MM-YYYY HH:mm"), // Set default to tomorrow
            rowData: [],
            sliderBulkUpdate: "",
            sliderSelectOnMap: '',
            loadshow: 'show-n',
            overly: 'show-n',
            defaultColDef: {
                sortable: true,
                filter: true,
                resizable: true,
                pivot: true,
                enableValue: true,
                // enableRowGroup: true,
            },
            modules: AllModules,
            animateRows: true,
            debug: true,
            showToolPanel: false,
            uppressAggFuncInHeader: true,
            childconfs: [],
            childrow: [],
            rowModelType: "serverSide",
            paginationPageSize: 100,
            cacheBlockSize: 10,
            maxBlocksInCache: 1,
            rowData: [],
            maprowData: null,
            rowSelection: 'multiple',
            rowHeight: 32,
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            overlayNoRowsTemplate: "",
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            detailCellRendererParams: {},
            rowClassRules: {},
            frameworkComponents: {},
            errrorRowData: "",
            enroute: "btn-info",
            offroute: "btn-default",
            matrixFile: null,
            offroutecost: 0,
            bodyBuilderName: '',
            bodyBuilderAddress: '',
            bodyBuilderCity: '',
            bodyBuilderState: '',
            bodyBuilderPincode: '',
            selectedMapData: {
                lat: '',
                lng: '',
                location: ''
            },
            files: {
                dealerDeclaration: null,
                deliveryChallan: null,
                retailInvoice: null,
                tradeCertificate: null,
                tcInsurance: null,
                ewayBill: null,
                bodyBuilderLetter: null,
            },
            columnDefs: [
                {
                    headerName: 'Vin Number',
                    validation: (value) => value && value.trim() !== '',
                    field: 'vin_number',
                    editable: true,
                    width: 120,
                    cellStyle: (params) => !params.value ? { backgroundColor: '#f29f8d', color: 'black' } : null
                },
                {
                    headerName: 'Invoice Number',
                    validation: (value) => value && value.trim() !== '',
                    field: 'invoice_number',
                    editable: true,
                    width: 140,
                    cellStyle: (params) => !params.value ? { backgroundColor: '#f29f8d', color: 'black' } : null
                },
                {
                    headerName: 'Bill To Customer Number',
                    validation: (value) => value && value.trim() !== '',
                    field: 'bill_to_customer_number',
                    editable: true,
                    width: 160, cellStyle: (params) => !params.value ? { backgroundColor: '#f29f8d', color: 'black' } : null
                },
                {
                    headerName: 'Dealer Name',
                    validation: (value) => value && value.trim() !== '',
                    field: 'dealer_name',
                    editable: true,
                    width: 140,
                    cellStyle: (params) => !params.value ? { backgroundColor: '#f29f8d', color: 'black' } : null
                },
                {
                    headerName: 'Ship To Party Code',
                    validation: (value) => value && value.trim() !== '',
                    field: 'ship_to_party_code',
                    editable: true,
                    width: 160, cellStyle: (params) => !params.value ? { backgroundColor: '#f29f8d', color: 'black' } : null
                },
                {
                    headerName: 'Ship To Party Name',
                    validation: (value) => value && value.trim() !== '',
                    field: 'ship_to_party_name',
                    editable: true,
                    width: 160,
                    cellStyle: (params) => !params.value ? { backgroundColor: '#f29f8d', color: 'black' } : null
                },
                {
                    headerName: 'Ship To Party Location',
                    validation: (value) => value && value.trim() !== '',
                    field: 'ship_to_party_location',
                    editable: true,
                    width: 180, cellStyle: (params) => !params.value ? { backgroundColor: '#f29f8d', color: 'black' } : null
                },
                {
                    headerName: 'Ship To Party Sate',
                    validation: (value) => value && value.trim() !== '',
                    field: 'ship_to_party_state',
                    editable: true,
                    width: 160, cellStyle: (params) => !params.value ? { backgroundColor: '#f29f8d', color: 'black' } : null
                },
                {
                    headerName: 'Invoice Date',
                    field: 'invoice_date',
                    editable: true,
                    width: 140,
                    cellStyle: (params) => !params.value ? { backgroundColor: '#f29f8d', color: 'black' } : null
                },
            ]
        };
        this.changeFileHandlerBulk = this.changeFileHandlerBulk.bind(this);
        this.saveBulkConsignmentData = this.saveBulkConsignmentData.bind(this);
        this.cipher = this.cipher.bind(this);
    }
    cipher = (salt) => {
        const textToChars = text => text.split('').map(c => c.charCodeAt(0));
        const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
        const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
        try {
            return text => text.split('')
                .map(textToChars)
                .map(applySaltToChar)
                .map(byteHex)
                .join('');
        }
        catch (e) { }
    }
    componentDidMount() {
        if (googleAnalytics.page.enableGA) {
            googleAnalytics.logPageView();
        }
    }    // Validate email format
    validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Stricter regex for email format
        return emailRegex.test(email);
    };
    validateForm = () => {
        const { noOfVehicles, reasonForDeviation, emailIds, deviationType,
            bdBuildrLocType, bodyBuilderName, bodyBuilderAddress, bodyBuilderCity, bodyBuilderState, bodyBuilderPincode, selectedMapData, plantHoldRequestNumber,
            files, rowData } = this.state;
        const errors = {};
        // Validate number of vehicles (should be a number between 1 and 100)
        if (isNaN(noOfVehicles) || noOfVehicles < 1 || noOfVehicles > 100) {
            errors.noOfVehicles = 'Number of vehicles should be between 1 and 100';
        }
        // Validate Reason for Deviation
        if (!reasonForDeviation) {
            errors.reasonForDeviation = 'Reason for deviation is required';
        }
        // Check For Deviation Type and Selection
        if (deviationType && deviationType.value == 'enroute') {
            const requiredFiles = ['dealerDeclaration', 'deliveryChallan', 'ewayBill', 'bodyBuilderLetter'];
            requiredFiles.forEach(file => {
                if (!files[file] && (file != 'fAndCMail' && deviationType.value !== 'enroute')) {
                    errors[file] = `${file.replace(/([A-Z])/g, ' $1')} is Required`;
                }
            });
        } else if (deviationType && deviationType.value == 'offroute') {
            const requiredFiles = ['dealerDeclaration', 'deliveryChallan', 'retailInvoice', 'tradeCertificate', 'tcInsurance', 'ewayBill', 'bodyBuilderLetter'];
            requiredFiles.forEach(file => {
                if (!files[file]) {
                    errors[file] = `${file.replace(/([A-Z])/g, ' $1')} is Required`;
                }
            });
        } else {
            errors.deviationType = "Please Select The Deviation Type"
        }
        if (bdBuildrLocType.value == 'map') {
            if (!selectedMapData.lat || !selectedMapData.lng) {
                errors.selectedMapData = 'Please Select The Body Builder Location on Map'
            }
        } else if (bdBuildrLocType.value == 'manual') {
            if (!bodyBuilderName) {
                errors.bodyBuilderName = 'Please Select The Body Builder Name'
            }
            if (!bodyBuilderAddress) {
                errors.bodyBuilderAddress = 'Please Select The Body Builder Address'
            }
            if (!bodyBuilderCity) {
                errors.bodyBuilderCity = 'Please Select The Body Builder City'
            }
            if (!bodyBuilderState) {
                errors.bodyBuilderState = 'Please Select The Body Builder State'
            } if (!bodyBuilderPincode) {
                errors.bodyBuilderPincode = 'Please Select The Body Builder Pincode'
            }
        } else {
            errors.bdBuildrLocType = 'Please Select The Body Builder Location'
        }
        // Validate Email Ids
        for (let key in emailIds) {
            if (this.state[key] != 'fAndCMail' && deviationType.value !== 'enroute') {
                if (!this.validateEmail(this.state[key])) {
                    errors[key] = `${key} is not a valid email address`;
                }
            }
        }
        // Removing s_no and preparing the insert data
        let duplicate_data = [];
        let checkDuplicatesUploaded = new Set();
        // Loop through each item in the rowData array
        const checkTestDuplicates = rowData.map(async item => {
            // Create a combination of vin_number and invoice_number to ensure uniqueness
            const combination = `${item.vin_number}-${item.invoice_number}`;
            // Check if the combination has already been processed
            if (checkDuplicatesUploaded.has(combination)) {
                // If already processed, add to duplicate_data
                duplicate_data.push(`Vin Number: ${item.vin_number}, Invoice Number: ${item.invoice_number}`);
            } else {
                // Otherwise, add the combination to the Set and check the database for existing data
                checkDuplicatesUploaded.add(combination);
            }
        });
        if (Object.keys(errors).length === 0 && duplicate_data.length > 0) {
            let errorMessage = duplicate_data.join(" , ")
            this.setError(errorMessage)
        }
        this.setState({ errors });
        return Object.keys(errors).length === 0;
    };
    // Handle form submit
    handleSubmit = async (event) => {
        try {
            event.preventDefault();
            if (!this.validateForm()) return;
            const {
                rowData, requesterEmail, noOfVehicles, deviationType, reasonForDeviation, reqL4Email, reqBusinessHeadMail, mAndSApprMail, fAndCMail,
                oblEmail, bdBuildrLocType, bodyBuilderAddress, bodyBuilderName, bodyBuilderCity, bodyBuilderState, bodyBuilderPincode, selectedMapData, plantHoldRequestNumber,
                files
            } = this.state;
            const dataValidation = this.validateData(rowData) || false;
            if (rowData.length === 0) {
                return this.setError("Please Upload the File to Submit");
            }
            if (noOfVehicles !== rowData.length) {
                return this.setError("Vehicle count must match the number of records uploaded");
            }
            if (dataValidation) {
                this.setState({
                    loadshow: "show-m",
                    overly: "show-m"
                });
                const params = this.prepareParams({
                    rowData, requesterEmail, fAndCMail, oblEmail, mAndSApprMail, reqBusinessHeadMail, reqL4Email,
                    noOfVehicles, reasonForDeviation, deviationType, bdBuildrLocType, selectedMapData, plantHoldRequestNumber, bodyBuilderName, bodyBuilderCity, bodyBuilderState, bodyBuilderPincode, bodyBuilderAddress
                });
                const uploadedFiles = this.prepareUploadedFiles(deviationType, files);
                const formData = new FormData();
                // Log keys for debugging
                let filekeys = Object.keys(uploadedFiles);
                // console.log(filekeys, 'filekeys');
                // Append files to FormData
                filekeys.forEach(key => {
                    const file = uploadedFiles[key];
                    if (file instanceof File) {
                        formData.append(key, file);
                    }
                });
                // Append other parameters
                formData.append("params", JSON.stringify(params)); // Ensure params is serialized as a JSON string                
                // Send the form data using the POST request
                const response = await redirectURL.post("/uploadDeviationRequest", formData, {
                    headers: {
                        'cache-control': 'no-cache',
                        'content-type': 'multipart/form-data' // Do not set this manually
                    }
                });
                this.handleUploadResponse(response);
            }
        } catch (e) {
            console.log(e, "error");
            this.setState({
                loadshow: "show-n",
                overly: "show-n"
            });
        }
    };
    setError = (message) => {
        this.setState({
            show: true,
            basicTitle: message,
            basicType: "danger",
        });
    };
    prepareParams = ({
        rowData, requesterEmail, fAndCMail, oblEmail, mAndSApprMail, reqBusinessHeadMail, reqL4Email, noOfVehicles, reasonForDeviation, deviationType, bdBuildrLocType, selectedMapData, plantHoldRequestNumber, bodyBuilderName, bodyBuilderCity, bodyBuilderState, bodyBuilderPincode, bodyBuilderAddress
    }) => {
        const params = {
            rowData,
            requesterEmail,
            fAndCMail,
            oblEmail,
            mAndSApprMail,
            reqBusinessHeadMail,
            reqL4Email,
            noOfVehicles,
            reasonForDeviation,
            performed_by: localStorage.getItem("email") || "",
            action: 'Uploaded Deviation Request',
            deviationType: deviationType.value || "",
            bdBuildrLocType: bdBuildrLocType.value || "",
        };
        if (plantHoldRequestNumber) {
            params.plantHoldRequestNumber = plantHoldRequestNumber;
        }
        if (bdBuildrLocType.value === 'map') {
            params.body_builder_lat_lng = { lat: selectedMapData.lat, lng: selectedMapData.lng };
        } else if (bdBuildrLocType.value === 'manual') {
            params.bodyBuilderName = bodyBuilderName;
            params.bodyBuilderCity = bodyBuilderCity;
            params.bodyBuilderState = bodyBuilderState;
            params.bodyBuilderPincode = bodyBuilderPincode;
            params.bodyBuilderAddress = bodyBuilderAddress;
        }
        return params;
    };
    prepareUploadedFiles = (deviationType, files) => {
        let uploadedFiles = {};
        const fileMappings = {
            'enroute': ['dealerDeclaration', 'deliveryChallan', 'ewayBill', 'bodyBuilderLetter'],
            'offroute': ['dealerDeclaration', 'deliveryChallan', 'retailInvoice', 'tradeCertificate', 'tcInsurance', 'ewayBill', 'bodyBuilderLetter']
        };
        if (deviationType && fileMappings[deviationType.value]) {
            fileMappings[deviationType.value].forEach(file => {
                uploadedFiles[file] = files[file];
            });
        }
        return uploadedFiles;
    };
    handleUploadResponse = (response) => {
        const duplicates = response.data.duplicate || [];
        if (response.data.status === "success") {
            this.setState({
                show: true,
                basicTitle: "Data Uploaded Successfully",
                basicType: "success",
                loadshow: "show-n",
                overly: "show-n",
                rowData: [],
                approverEmailId: "",
                requesterEmail: '',
                fAndCMail: '',
                oblEmail: '',
                reqL4Email: '',
                mAndSApprMail: '',
                emailIds: {},
                noOfVehicles: 0,
                reasonForDeviation: '',
                plantHoldRequestNumber: '',
                errors: {}
            });
            window.location.reload()
            let files = Object.keys(this.state.files);
            // Iterate over each key in the files object
            files.forEach(item => {
                // Get the input element by ID and reset its value
                const inputElement = document.getElementById(item);
                if (inputElement) {
                    inputElement.value = "";  // Clear the file input
                }
            });
        } else if (response.data.status === "failure") {
            const message = duplicates.length > 0
                ? `Data upload failed: Duplicate entry found: ${duplicates}`
                : "Failed to Upload Data";
            this.setState({
                show: true,
                basicTitle: message,
                basicType: "danger",
                loadshow: "show-n",
                overly:'show-n',
            });
        } else {
            this.setState({
                show: true,
                basicTitle: "Failed to Upload Data",
                basicType: "danger",
                loadshow: "show-n",
                overly: "show-n"
            });
        }
    };

    // Handle input change
    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    };
    closeAlert = () => {
        this.setState({
            show: false
        });
    }
    // Handle email id changes
    handleEmailChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            emailIds: {
                ...this.state.emailIds,
                [name]: value,
            },
        });
    };
    handleChange = (event) => {
        const { name, value } = event.target;
        // If the input is startDate, check if it's a valid future date
        if (name === "bodyBuilderPincode") {
            const isValidNumber = !isNaN(value) && value.trim() !== ""; // Checks if it's a valid number
            if (isValidNumber) {
                this.setState({
                    [name]: value
                });
            } else {
                // Optionally, you could show an error message or handle invalid input
                console.log("Invalid input, please enter a valid number");
            }
        }
        else {
            this.setState({
                [name]: value
            });
        }
    };
    handleReset = (event) => {
        try {
            event.preventDefault()
            this.setState({ rowData: [], errors: {} });
        } catch (e) {
            console.log(e, 'error')
        }
    };
    handleFileUpload = (data) => {
        this.setState({ rowData: data, errors: {} });
    };
    onClickHideAll() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            slideuploadeway: '',
            sliderBulkUpdate: "",
            sliderSelectOnMap: '',

        });
        document.getElementById("uploadFileBulk").value = ""
    }
    validateData = (data) => {
        try {
            if (!Array.isArray(data) || data.length == 0) {
                this.setState({
                    show: true,
                    basicTitle: "Data Can't be Empty",
                    basicType: "warning"
                })
                return false
            }
            const errors = [];
            let emptyFields = []; // Collect empty fields
            let invalidDateFields = []; // Collect invalid date fields
            data.forEach((row, index) => {
                this.state.columnDefs.forEach((column) => {
                    const fieldValue = row[column.field];
                    // Check if there's a custom validation function
                    if (column.validation && !column.validation(fieldValue, row)) {
                        errors.push({ rowIndex: index, field: column.field });
                    }
                    // Check for empty fields
                    else if (!fieldValue && fieldValue !== 0) { // Handles empty string, null, undefined
                        emptyFields.push(column.headerName); // Collect the empty field's header name
                        errors.push({ rowIndex: index, field: column.field });
                    }
                    // Validate `invoice_date` field format
                    if (column.field === 'invoice_date' && (fieldValue === '' || !this.isValidDateFormat(fieldValue))) {
                        invalidDateFields.push('INVOICE DATE');
                        errors.push({ rowIndex: index, field: 'invoice_date' });
                    }
                });
            });
            // Set the alert message depending on the empty fields and invalid date fields
            let basicTitle = '';
            let basicType = '';
            if (emptyFields.length > 0 && invalidDateFields.length > 0) {
                basicTitle = `
                Empty Fields: ${emptyFields.join(', ')}
                Invalid Date Format: ${invalidDateFields.join(', ')}
            `;
                basicType = 'danger';
            } else if (emptyFields.length > 0) {
                basicTitle = `Empty Fields: ${emptyFields.join(', ')}`;
                basicType = 'warning';
            } else if (invalidDateFields.length > 0) {
                basicTitle = `Invalid Date Format: ${invalidDateFields.join(', ')}`;
                basicType = 'danger';
            }
            if (basicTitle.length && basicTitle.length > 0) {
                this.setState({
                    show: true,
                    basicTitle: basicTitle,
                    basicType: basicType,
                    errors: {
                        rowData: errors
                    }
                });
            }
            return errors.length === 0; // Return true if no errors, false otherwise
        } catch (e) {
            console.log("Error", e)
            this.setState({
                show: false,
                loadshow: "show-n",
                loadshow: "sho-n"
            })
        }
    };
    // Helper function to validate date format (DD-MM-YYYY HH:mm)
    isValidDateFormat = (dateStr) => {
        const dateFormat = 'DD-MM-YYYY HH:mm';
        return moment(dateStr, dateFormat, true).isValid();
    };
    onClickBulkForm() {
        this.setState({
            sliderBulkUpdate: "slider-translate",
            overly: "show-m",
            // loadshow:"show-m",
        })
    }
    changeFileHandlerBulk = async (e) => {
        //  console.log(e.target.files[0])
        const fileInput = document.getElementById('uploadFileBulk');
        const filePath = fileInput.value;
        const allowedExtensions = /(\.xls|\.xlsx)$/i; // Regular expression for .xls and .xlsx file extensions
        if (!allowedExtensions.exec(filePath)) {
            this.setState({
                show: true,
                basicTitle: 'Invalid file type. Please provide an .xls or .xlsx file.',
                basicType: "info"
            })
            fileInput.value = ''; // Clear the input
            return false;
        }
        this.setState({
            matrixFile: e.target.files[0]
        });
    }
    saveBulkConsignmentData = async (event) => {
        try {
            event.preventDefault();
            if (!this.state.matrixFile) {
                this.setState({
                    show: true,
                    basicTitle: "Please Upload File and Submit",
                    basicType: 'danger',
                })
                return ""
            }
            this.setState({ loadshow: "show-m" });
            const fdata = new FormData();
            fdata.append("uploadfile", this.state.matrixFile);
            // Send file to server
            const resp = await redirectURL.post("/readBulkUploadedData", fdata, {
                headers: {
                    'cache-control': 'no-cache',
                    'content-type': 'multipart/form-data'
                }
            });
            const excelData = resp.data.records || [];
            let duplicatesMsg = resp.data.duplicatesMsg || false;
            if (duplicatesMsg.length) {
                this.setState({
                    rowData: excelData,
                    show: true,
                    basicTitle: duplicatesMsg || 'Duplicate Records Found',
                    basicType: 'danger',
                })
            }
            this.setState({
                rowData: excelData,
                loadshow: "show-n",
                overly: "show-n",
                sliderBulkUpdate: "",
                sliderSelectOnMap: '',

            })
            document.getElementById("uploadFileBulk").value = ""
        } catch (error) {
            console.error('Error during saveBulkConsignmentData:', error);
        }
    };
    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    };
    onGridState = () => {
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();
        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
        /*Get Current Pivot Columns State and Store in this.pivotColumns,
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }
    onCellValueChanged = (event) => {
        const { data, colDef, newValue } = event;
        const isValidDateFormat = (dateStr) => {
            // Validate the full date format including hours and minutes (DD-MM-YYYY HH:mm)
            const dateFormat = 'DD-MM-YYYY HH:mm';
            // Check if the date is strictly valid with the given format
            const momentDate = moment(dateStr, dateFormat, true);
            // Ensure the date has valid hours and minutes (e.g., minutes should not be empty)
            return momentDate.isValid() && dateStr.includes(":");
        };
        // If the new value is blank or invalid, prevent the update
        if (newValue === '' || newValue === null) {
            alert("Field cannot be empty.");
            return;
        }
        if (colDef.field == "invoice_date" && !isValidDateFormat(newValue)) {
            this.setState({
                show: true,
                basicTitle: 'Invoice Date Should be in the format of "DD-MM-YYYY HH:mm"',
                basicType: "warning"
            })
        }
        // Create a new copy of the data to avoid mutating the original state
        const updatedRowData = this.state.rowData.map(row => {
            // Find the row to update using a unique identifier (e.g., `deviation_id`)
            if (row.unique_id === data.unique_id) {
                // Return a new row object with the updated value
                return { ...row, [colDef.field]: newValue };
            }
            return row; // Return the other rows unchanged
        });
        // Update the state with the new rowData
        this.setState({ rowData: updatedRowData });
    };
    convertToActualData(value) {
        // Implement any conversion logic here
        return value; // Return the converted value, if applicable
    }
    onClickTab = (value) => {
        try {
            if (value == "enroute") {
                this.setState({
                    enroute: "btn-info",
                    offroute: "btn-default",
                })
            } else if (value == "offroute") {
                this.setState({
                    enroute: "btn-default",
                    offroute: "btn-info",
                })
            }
            this.setState({
                loadshow: "show-n",
                overly: "show-n",
                startDate: moment().utcOffset("+05:30").format("DD-MM-YYYY HH:mm"), // Set default to today
                clearanceDate: moment().utcOffset("+05:30").add(1, 'days').format("DD-MM-YYYY HH:mm"), // Set default to tomorrow
                rowData: [],
                approverEmailId: "",
                requesterEmail: localStorage.getItem("email") || "",
                fAndCMail: '',
                oblEmail: '',
                emailIds: {
                    approverEmailId: "",
                    requesterEmail: '',
                    fAndCMail: '',
                    oblEmail: '',
                    mAndSApprMail: '',
                    reqL4Email: '',
                },
                noOfVehicles: 0,
                reasonForDeviation: '',
                plantHoldRequestNumber: '',
                errors: {},
            })
            document.getElementById("uploadFileBulk").value = ""
        } catch (e) {
            console.log("Error : ", e)
        }
    }
    onNoOfVehicleChanged = (event) => {
        try {
            let vehicleCount = event.value;
            // Destructure startDate and clearanceDate from state
            let { startDate, clearanceDate } = this.state;
            // Check if start date and clearance date are available
            if (startDate && clearanceDate) {
                // Use moment.js to parse the dates with the correct format
                const parsedStartDate = moment(startDate, "DD-MM-YYYY HH:mm").utcOffset("+05:30");
                const parsedClearanceDate = moment(clearanceDate, "DD-MM-YYYY HH:mm").utcOffset("+05:30");
                // Calculate the difference in hours between the two dates
                let diffHours = parsedClearanceDate.diff(parsedStartDate, 'hours');
                // Round up to the next whole day (24 hours)
                let roundedDiffDays = Math.ceil(diffHours / 24);  // Divide by 24 and round up                
                // Update the offroute cost based on the vehicle count and difference in days
                this.setState({
                    offroutecost: roundedDiffDays * parseInt(vehicleCount) * 450 || 0
                });
            }
            // Update the number of vehicles
            this.setState({
                noOfVehicles: vehicleCount
            });
        } catch (e) {
            console.log("Error :", e);
        }
    }

    renderMap = () => {
        loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyD1AFWPoJqPaemwGO4A7P7Q6CsUJ1TLGJk&libraries=places,drawing&callback=initMap");
        window.initMap = this.initMap
    }
    onChangeBodyBuilderType = (e) => {
        try {
            let event = e
            console.log(e)
            if (e.value == 'map') {
                this.setState({
                    sliderSelectOnMap: 'slider-translate-65p',
                    overly: 'show-m',
                })
            }
            this.setState({
                bdBuildrLocType: event
            })
        } catch (e) {
            console.log("Error :", e)
        }
    }
    onClickSelectMap = () => {
        try {
            this.setState({
                sliderSelectOnMap: 'slider-translate-65p',
                overly: 'show-m',
            })
        } catch (e) {
            console.log("Erro :", e)
        }
    }
    onBodyBuilderLocationChanged = (e) => {
        try {
            this.setState({
                selectedMapData: {
                    location: e.location,
                    lat: e.lat,
                    lng: e.lng
                }
            })
        } catch (e) {
            console.log('Error', e)
        }
    }
    // Handle file change for each document
    handleFileChange = (e, documentType) => {
        const file = e.target.files[0];  // Get the first selected file
        if (file) {
            this.setState((prevState) => ({
                files: {
                    ...prevState.files,
                    [documentType]: file,
                },
            }));
        }
    };
    render() {
        const {
            requesterEmail,
            noOfVehicles,
            reasonForDeviation,
            fAndCMail,
            oblEmail,
            errors,
            rowData,
            mAndSApprMail,
            reqL4Email,
            reqBusinessHeadMail,
            deviationType,
            bdBuildrLocType,
            bodyBuilderName,
            bodyBuilderAddress,
            bodyBuilderCity,
            bodyBuilderState,
            bodyBuilderPincode,
            plantHoldRequestNumber,
            files,

        } = this.state;
        let vehicleOptions = [];
        for (let i = 1; i <= 100; i++) {
            const option = {
                label: i,
                value: i,
            };
            vehicleOptions.push(option);
        }
        let deviationOptions = [{ value: 'enroute', label: "Enroute" }, { value: 'offroute', label: 'Offroute' }]
        let bodyBuilderSelectionOptions = [{ value: 'map', label: "Map" }, { value: 'manual', label: 'Manual Entry' }]
        const filesCSS = {
            padding: '8px',
            marginBottom: '5px',
            width: '100%',
            borderRadius: '6px',
            border: '1px solid #bdbbbb',
            backgroundColor: 'white',
        }
        const styleObjectLatLng = {
            backgroundColor: 'white',
            border: '1px solid lightgrey',
            padding: '0px 8px',
            borderRadius: '4px',
            fontSize: '12px',
            borderRadius: '0px 4px 4px 0px',
            lineHeight: '18px',
            alignContent: 'center',
        };
        return (
            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}>
                </SweetAlert>
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <h5 className="mb-15p fbold f20">
                            <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.pagetitle} </span>
                        </h5>
                        {/* <div style={{ width: "100%" }} >
                            <button className={"btn " + this.state.enroute} onClick={this.onClickTab.bind(this, 'enroute')} style={{ borderRight: "1px solid #ccc", borderLeft: "1px solid #ccc" }}>Enroute</button>
                            <button className={"btn " + this.state.offroute} onClick={this.onClickTab.bind(this, 'offroute')}>Offroute</button>
                        </div> */}
                        <div className="card">
                            <div className=" card-body" style={{ minHeight: "70vh", padding: "0px" }}>
                                <div className="row p-0">
                                    <div style={{ margin: '20px', padding: '0px 20px', width: '100%', marginBottom: "0px", paddingBottom: "0px" }}>
                                        <div className='row p-0 m-0' style={{ display: 'flex', justifyContent: "space-between" }} >
                                            <h6>{this.state.enroute == 'btn-info' ? '' : ''}</h6>
                                            <button
                                                type="button"
                                                className="btn btn-warning form-group "
                                                style={{ marginRight: '8px', fontSize: "14px", padding: "5px", width: "80px", height: "36px", borderRadius: "6px", marginBottom: "0px" }}
                                                onClick={this.onClickBulkForm.bind(this)}>
                                                File Upload
                                            </button>
                                        </div>
                                        <form method='POST' onSubmit={this.handleSubmit} style={{ width: "100%", marginTop: "0px" }}>
                                            {/* Requester Email */}
                                            <div className="form-group col-xl-12 col-lg-12 mt-2" style={{ display: "flex", flexWrap: 'wrap', gap: '0px', border: '1px solid #ccc', paddingTop: "20px", borderRadius: "16px ", backgroundColor: "#f7f9ff" }}>
                                                <div className="form-group col-xl-3 col-lg-3" >
                                                    <label style={{ marginBottom: '5px' }}>Requester Email<span style={{ color: 'red' }}>*</span></label>
                                                    <input
                                                        type="email"
                                                        name="requesterEmail"
                                                        value={requesterEmail}
                                                        onChange={this.handleChange}
                                                        style={{ padding: '8px', marginBottom: '5px', width: '100%', borderRadius: "6px", border: "1px solid #bdbbbb" }}
                                                        required
                                                    />
                                                    {errors.requesterEmail && <small style={{ color: 'red' }}>Requester Email is not a valid email address</small>}
                                                </div>
                                                {/* No of Vehicles */}
                                                <div className="form-group col-xl-3 col-lg-3" >
                                                    <label style={{ marginBottom: '5px' }}>No of Vehicles<span style={{ color: 'red' }}>*</span></label>
                                                    <Select
                                                        className="border-radius-0"
                                                        isMulti={false} // Set to `true` if you want multi-select
                                                        id="noOfVehicles"
                                                        value={{ label: noOfVehicles, value: noOfVehicles }} // find the selected option based on value
                                                        onChange={this.onNoOfVehicleChanged}
                                                        options={vehicleOptions} // options should be an array of { label, value }
                                                        getOptionLabel={(e) => `${e.label}`} // Optional: customize how the options are displayed

                                                    />
                                                    {errors.noOfVehicles && <small style={{ color: 'red' }}>{errors.noOfVehicles}</small>}
                                                </div>
                                                {/* <div className="form-group col-xl-3 col-lg-3" >
                                                    <label style={{ marginBottom: '5px' }}>Plant Hold Request Number<span style={{ color: 'red' }}></span></label>
                                                    <input
                                                        type="text"
                                                        name="plantHoldRequestNumber"
                                                        value={plantHoldRequestNumber}
                                                        onChange={this.handleChange}
                                                        style={{ padding: '8px', marginBottom: '5px', width: '100%', borderRadius: "6px", border: "1px solid #bdbbbb" }}
                                                    />
                                                    {errors.plantHoldRequestNumber && <small style={{ color: 'red' }}>Plant Hold Request Number is required<span style={{ color: 'red' }}>*</span></small>}
                                                </div> */}
                                                <div className="form-group col-xl-3 col-lg-3" >
                                                    <label style={{ marginBottom: '5px' }}>Deviation Type<span style={{ color: 'red' }}>*</span></label>
                                                    <Select
                                                        className="border-radius-0"
                                                        isMulti={false} // Set to `true` if you want multi-select
                                                        id="deviationType"
                                                        value={deviationType} // find the selected option based on value
                                                        // onChange={(e) => this.setState({ deviationType: e.value })} // set state to the selected value
                                                        onChange={e => (this.setState({ deviationType: e }))}
                                                        options={deviationOptions} // options should be an array of { label, value }
                                                        getOptionLabel={(e) => `${e.label}`} // Optional: customize how the options are displayed
                                                        required={true}
                                                    />
                                                    {errors.deviationType && <small style={{ color: 'red' }}>{errors.deviationType}</small>}
                                                </div>
                                                {(deviationType.value && deviationType.value == 'enroute' || deviationType.value && deviationType.value == 'offroute') &&
                                                    <>
                                                        <div className="form-group col-xl-3 col-lg-3" >
                                                            <label style={{ marginBottom: '5px' }}>
                                                                Dealer Declaration <span style={{ color: 'red' }}>*</span>
                                                            </label>
                                                            <input
                                                                type="file"
                                                                accept=".pdf, .doc, .docx, .jpg, .jpeg, .png"
                                                                onChange={(e) => this.handleFileChange(e, 'dealerDeclaration')}
                                                                style={filesCSS}
                                                                name="dealerDeclaration"
                                                                id='dealerDeclaration'
                                                                required
                                                            />
                                                        </div>
                                                        <div className="form-group col-xl-3 col-lg-3" >
                                                            <label style={{ marginBottom: '5px' }}>
                                                                Delivery Challan <span style={{ color: 'red' }}>*</span>
                                                            </label>
                                                            <input
                                                                type="file"
                                                                accept=".pdf, .doc, .docx, .jpg, .jpeg, .png"
                                                                onChange={(e) => this.handleFileChange(e, 'deliveryChallan')}
                                                                style={filesCSS}
                                                                name="deliveryChallan"
                                                                id='deliveryChallan'
                                                                required
                                                            />
                                                        </div>
                                                        {(deviationType.value && deviationType.value == 'offroute') &&
                                                            <>
                                                                <div className="form-group col-xl-3 col-lg-3" >
                                                                    <label style={{ marginBottom: '5px' }}>
                                                                        Retail Invoice <span style={{ color: 'red' }}>*</span>
                                                                    </label>
                                                                    <input
                                                                        type="file"
                                                                        accept=".pdf, .doc, .docx, .jpg, .jpeg, .png"
                                                                        onChange={(e) => this.handleFileChange(e, 'retailInvoice')}
                                                                        style={filesCSS}
                                                                        name="retailInvoice"
                                                                        id='retailInvoice'
                                                                        required
                                                                    />
                                                                </div>
                                                                <div className="form-group col-xl-3 col-lg-3" >
                                                                    <label style={{ marginBottom: '5px' }}>
                                                                        Trade Certificate <span style={{ color: 'red' }}>*</span>
                                                                    </label>
                                                                    <input
                                                                        type="file"
                                                                        accept=".pdf, .doc, .docx, .jpg, .jpeg, .png"
                                                                        onChange={(e) => this.handleFileChange(e, 'tradeCertificate')}
                                                                        style={filesCSS}
                                                                        name="tradeCertificate"
                                                                        id='tradeCertificate'
                                                                        required
                                                                    />
                                                                </div>
                                                                <div className="form-group col-xl-3 col-lg-3" >
                                                                    <label style={{ marginBottom: '5px' }}>
                                                                        TC Insurance or Transit Insurance <span style={{ color: 'red' }}>*</span>
                                                                    </label>
                                                                    <input
                                                                        type="file"
                                                                        accept=".pdf, .doc, .docx, .jpg, .jpeg, .png"
                                                                        onChange={(e) => this.handleFileChange(e, 'tcInsurance')}
                                                                        style={filesCSS}
                                                                        name="tcInsurance"
                                                                        id="tcInsurance"
                                                                        required
                                                                    />
                                                                </div>
                                                            </>
                                                        }
                                                        <div className="form-group col-xl-3 col-lg-3" >
                                                            <label style={{ marginBottom: '5px' }}>
                                                                Eway Bill for Delivery Address<span style={{ color: 'red' }}>*</span>
                                                            </label>
                                                            <input
                                                                type="file"
                                                                accept=".pdf, .doc, .docx, .jpg, .jpeg, .png"
                                                                onChange={(e) => this.handleFileChange(e, 'ewayBill')}
                                                                style={filesCSS}
                                                                name="ewayBill"
                                                                id="ewayBill"
                                                                required
                                                            />
                                                        </div>
                                                        <div className="form-group col-xl-3 col-lg-3" >
                                                            <label style={{ marginBottom: '5px' }}>
                                                                Body Builder Letter <span style={{ color: 'red' }}>*</span>
                                                            </label>
                                                            <input
                                                                type="file"
                                                                accept=".pdf, .doc, .docx, .jpg, .jpeg, .png"
                                                                onChange={(e) => this.handleFileChange(e, 'bodyBuilderLetter')}
                                                                style={filesCSS}
                                                                name="bodyBuilderLetter"
                                                                id="bodyBuilderLetter"
                                                                required
                                                            />
                                                        </div>
                                                    </>
                                                }
                                                <div className="form-group col-xl-3 col-lg-3" >
                                                    <label style={{ marginBottom: '5px' }}>Reason for Deviation<span style={{ color: 'red' }}>*</span></label>
                                                    <input
                                                        type="text"
                                                        name="reasonForDeviation"
                                                        value={reasonForDeviation}
                                                        onChange={this.handleChange}
                                                        style={{ padding: '8px', marginBottom: '5px', width: '100%', borderRadius: "6px", border: "1px solid #bdbbbb" }}
                                                        required
                                                    />
                                                    {errors.reasonForDeviation && <small style={{ color: 'red' }}>Reason for deviation is required<span style={{ color: 'red' }}>*</span></small>}
                                                </div>
                                                <div className="form-group col-xl-3 col-lg-3" style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px', flex: '1 0 30%' }}>
                                                    <label style={{ marginBottom: '5px' }}>Requester L4 Email<span style={{ color: 'red' }}>*</span></label>
                                                    <input
                                                        type="email"
                                                        name="reqL4Email"
                                                        value={reqL4Email}
                                                        onChange={this.handleChange}
                                                        style={{ padding: '8px', marginBottom: '5px', width: '100%', borderRadius: "6px", border: "1px solid #bdbbbb" }}
                                                        required
                                                    />
                                                    {errors.reqL4Email && <small style={{ color: 'red' }}>Requester L4 Email is not a valid email address</small>}
                                                </div>
                                                <div className="form-group col-xl-3 col-lg-3" style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px', flex: '1 0 30%' }}>
                                                    <label style={{ marginBottom: '5px' }}>Requester Business Head Email<span style={{ color: 'red' }}>*</span></label>
                                                    <input
                                                        type="email"
                                                        name="reqBusinessHeadMail"
                                                        value={reqBusinessHeadMail}
                                                        onChange={this.handleChange}
                                                        style={{ padding: '8px', marginBottom: '5px', width: '100%', borderRadius: "6px", border: "1px solid #bdbbbb" }}
                                                        required
                                                    />
                                                    {errors.reqBusinessHeadMail && <small style={{ color: 'red' }}>Requester Business Head Email is not a valid email address</small>}
                                                </div>
                                                <div className="form-group col-xl-3 col-lg-3" style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px', flex: '1 0 30%' }}>
                                                    <label style={{ marginBottom: '5px' }}>M&S Email<span style={{ color: 'red' }}>*</span></label>
                                                    <input
                                                        type="email"
                                                        name="mAndSApprMail"
                                                        value={mAndSApprMail}
                                                        onChange={this.handleChange}
                                                        required
                                                        style={{ padding: '8px', marginBottom: '5px', width: '100%', borderRadius: "6px", border: "1px solid #bdbbbb" }}
                                                    />
                                                    {errors.mAndSApprMail && <small style={{ color: 'red' }}>M&S Email is not a valid email address</small>}
                                                </div>
                                                {deviationType.value == 'offroute' &&
                                                    <div className="form-group col-xl-3 col-lg-3" style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px', flex: '1 0 30%' }}>
                                                        <label style={{ marginBottom: '5px' }}>F&C Email<span style={{ color: 'red' }}>*</span></label>
                                                        <input
                                                            type="email"
                                                            name="fAndCMail"
                                                            value={fAndCMail}
                                                            onChange={this.handleChange}
                                                            style={{ padding: '8px', marginBottom: '5px', width: '100%', borderRadius: "6px", border: "1px solid #bdbbbb" }}
                                                            required
                                                        />
                                                        {errors.fAndCMail && <small style={{ color: 'red' }}>F&C Email is not a valid email address</small>}
                                                    </div>
                                                }
                                                {/* OBL Email */}
                                                <div className="form-group col-xl-3 col-lg-3" style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px', flex: '1 0 30%' }}>
                                                    <label style={{ marginBottom: '5px' }}>OBL Email<span style={{ color: 'red' }}>*</span></label>
                                                    <input
                                                        type="email"
                                                        name="oblEmail"
                                                        value={oblEmail}
                                                        onChange={this.handleChange}
                                                        style={{ padding: '8px', marginBottom: '5px', width: '100%', borderRadius: "6px", border: "1px solid #bdbbbb" }}
                                                        required
                                                    />
                                                    {errors.oblEmail && <small style={{ color: 'red' }}>OBL Email is not a valid email address</small>}
                                                </div>
                                                <div className="form-group col-xl-3 col-lg-3" >
                                                    <label style={{ marginBottom: '5px' }}>Body Builder Selection<span style={{ color: 'red' }}>*</span></label>
                                                    <Select
                                                        className="border-radius-0"
                                                        isMulti={false} // Set to `true` if you want multi-select
                                                        id="bdBuildrLocType"
                                                        value={bdBuildrLocType} // find the selected option based on value
                                                        onChange={this.onChangeBodyBuilderType}
                                                        // onChange={e => (this.setState({ bdBuildrLocType: e }))}
                                                        options={bodyBuilderSelectionOptions} // options should be an array of { label, value }
                                                        getOptionLabel={(e) => `${e.label}`} // Optional: customize how the options are displayed
                                                        required={true}
                                                    />
                                                    {errors.bdBuildrLocType && <small style={{ color: 'red' }}>{errors.bdBuildrLocType}</small>}
                                                </div>
                                                {bdBuildrLocType && bdBuildrLocType.value == 'manual' &&
                                                    <>
                                                        <div className="form-group col-xl-3 col-lg-3" >
                                                            <label style={{ marginBottom: '5px' }}>Body Builder Name<span style={{ color: 'red' }}>*</span></label>
                                                            <input
                                                                type="text"
                                                                name="bodyBuilderName"
                                                                value={bodyBuilderName}
                                                                onChange={this.handleChange}
                                                                required
                                                                style={{ padding: '8px', marginBottom: '5px', width: '100%', borderRadius: "6px", border: "1px solid #bdbbbb" }}
                                                            />
                                                            {errors.bodyBuilderName && <small style={{ color: 'red' }}>Body Builder Name is required<span style={{ color: 'red' }}>*</span></small>}
                                                        </div>
                                                        <div className="form-group col-xl-3 col-lg-3" >
                                                            <label style={{ marginBottom: '5px' }}>Body Builder Address<span style={{ color: 'red' }}>*</span></label>
                                                            <input
                                                                type="text"
                                                                name="bodyBuilderAddress"
                                                                value={bodyBuilderAddress}
                                                                onChange={this.handleChange}
                                                                required
                                                                style={{ padding: '8px', marginBottom: '5px', width: '100%', borderRadius: "6px", border: "1px solid #bdbbbb" }}
                                                            />
                                                            {errors.bodyBuilderAddress && <small style={{ color: 'red' }}>Body Builder Address is required<span style={{ color: 'red' }}>*</span></small>}
                                                        </div>
                                                        <div className="form-group col-xl-3 col-lg-3" >
                                                            <label style={{ marginBottom: '5px' }}>Body Builder City<span style={{ color: 'red' }}>*</span></label>
                                                            <input
                                                                type="text"
                                                                name="bodyBuilderCity"
                                                                value={bodyBuilderCity}
                                                                onChange={this.handleChange}
                                                                required
                                                                style={{ padding: '8px', marginBottom: '5px', width: '100%', borderRadius: "6px", border: "1px solid #bdbbbb" }}
                                                            />
                                                            {errors.bodyBuilderCity && <small style={{ color: 'red' }}>Body Builder City is required<span style={{ color: 'red' }}>*</span></small>}
                                                        </div>
                                                        <div className="form-group col-xl-3 col-lg-3" >
                                                            <label style={{ marginBottom: '5px' }}>Body Builder State<span style={{ color: 'red' }}>*</span></label>
                                                            <input
                                                                type="text"
                                                                name="bodyBuilderState"
                                                                value={bodyBuilderState}
                                                                onChange={this.handleChange}
                                                                required
                                                                style={{ padding: '8px', marginBottom: '5px', width: '100%', borderRadius: "6px", border: "1px solid #bdbbbb" }}
                                                            />
                                                            {errors.bodyBuilderState && <small style={{ color: 'red' }}>Body Builder State is required<span style={{ color: 'red' }}>*</span></small>}
                                                        </div>
                                                        <div className="form-group col-xl-3 col-lg-3" >
                                                            <label style={{ marginBottom: '5px' }}>Body Builder Pincode<span style={{ color: 'red' }}>*</span></label>
                                                            <input
                                                                type="text"
                                                                name="bodyBuilderPincode"
                                                                value={bodyBuilderPincode}
                                                                onChange={this.handleChange}
                                                                required
                                                                style={{ padding: '8px', marginBottom: '5px', width: '100%', borderRadius: "6px", border: "1px solid #bdbbbb" }}
                                                            />
                                                            {errors.bodyBuilderPincode && <small style={{ color: 'red' }}>Body Builder Pincode is required<span style={{ color: 'red' }}>*</span></small>}
                                                        </div>
                                                    </>
                                                }
                                                {bdBuildrLocType && bdBuildrLocType.value == 'map' &&
                                                    <>
                                                        <div className="form-group col-xl-3 col-lg-3" >
                                                            <label style={{ marginBottom: '5px' }}>Body Builder Location<span style={{ color: 'red' }}>*</span></label>
                                                            <div className='d-flex m-0 p-0'>
                                                                <button style={{ padding: '8px', borderRadius: '4px 0px 0px 4px ' }} onClick={this.onClickSelectMap} className='col-2 btn btn-primary'>Select</button>
                                                                <p className='col-10' style={{ ...styleObjectLatLng, whiteSpace: 'pre-line' }}>
                                                                    {this.state.selectedMapData.lat && this.state.selectedMapData.lng
                                                                        ? `Lat: ${this.state.selectedMapData.lat}\nLang: ${this.state.selectedMapData.lng}`
                                                                        : "Select The Location on the Map"}
                                                                </p>
                                                            </div>
                                                            {errors.bodyBuilderAddress && <small style={{ color: 'red' }}>Body Builder Location is required<span style={{ color: 'red' }}>*</span></small>}
                                                        </div>
                                                    </>
                                                }
                                                <div className={`form-group ${this.state.offroute !== 'btn-info' ? 'col-xl-12 col-lg-12' : 'col-xl-12 col-lg-12'} `} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', paddingRight: "0px", paddingBottom: "16px", left: "-20px" }}>
                                                    <button className="btn btn-default form-group "
                                                        onClick={this.handleReset}
                                                        style={{ color: "red", border: "1px solid red", marginRight: "8px", padding: "5px", fontSize: "14px", width: "70px", height: "36px", borderRadius: "6px", backgroundColor: "white" }}>
                                                        Reset
                                                    </button>
                                                    <button
                                                        className=" btn btn-info form-group "
                                                        type="submit"
                                                        style={{ marginRight: '8px', padding: "5px", fontSize: "14px", width: "70px", height: "36px", borderRadius: "6px" }}
                                                    >
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className='mt-0 pt-0' style={{ margin: '20px', padding: '20px', width: '100%' }}>
                                        <div id="myGrid" style={{ height: "580px", width: "100%" }} className="ag-theme-balham">
                                            <AgGridReact
                                                modules={this.state.modules}
                                                columnDefs={this.state.columnDefs} // Assuming this is a state variable for columns
                                                defaultColDef={this.state.defaultColDef}
                                                sideBar={this.state.sideBar}
                                                rowData={rowData}
                                                enableCharts={true}
                                                enableRangeSelection={true}
                                                autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                onGridReady={this.onGridReady}
                                                pagination={true}
                                                frameworkComponents={this.state.frameworkComponents}
                                                stopEditingWhenGridLosesFocus={true}
                                                enableCellChangeFlash={false}
                                                rowClassRules={this.state.rowClassRules}
                                                gridOptions={{
                                                    context: { componentParent: this }
                                                }}
                                                masterDetail={true}
                                                rowHeight={this.state.rowHeight}
                                                rowSelection={this.state.rowSelection}
                                                suppressRowClickSelection={true}
                                                overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                                                excelStyles={this.state.excelStyles}
                                                // onCellClicked={this.onCellClicked.bind(this)}
                                                onCellValueChanged={this.onCellValueChanged}
                                                // onCellEditingStopped={this.onCellEditingStopped} // Add this to handle updated data
                                                editable={true}  // Enable editing for all cells
                                                cellEditorSelector={this.cellEditorSelector}  // Optional: you can specify custom editors here if needed
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"slide-r " + (this.state.sliderBulkUpdate)} style={{ overflow: "auto" }}>
                    <div className="slide-r-title">
                        <h4>
                            Bulk Upload - Deviation
                            <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{ position: "relative" }}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div>
                        <div className={"row"} style={{ padding: '5px 0px', width: '100%', overflow: 'hidden', zIndex: '9999', height: "100em" }} >
                            <form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.saveBulkConsignmentData}>
                                <div className="col-xl-12 col-lg-12 row form-group">
                                    <label className="col-xl-12 col-lg-12">Upload File :</label>
                                    <div className="col-xl-12 col-lg-12 form-group">
                                        <input type="file"
                                            onChange={this.changeFileHandlerBulk}
                                            name="uploadFileBulk"
                                            id="uploadFileBulk"
                                            className="form-control"
                                            accept=".xls,.xlsx"
                                        />
                                    </div>
                                </div>
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <button type="submit" className="btn btn-success">Submit</button>
                                </div>
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <a href={require("../../assets/json/samplebulkdevaitondocumet.xlsx")} target="_blank" className="btn btn-warning">Download Sample Template</a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className={"slide-r " + (this.state.sliderSelectOnMap)} style={{ overflow: "auto" }}>
                    <div className="slide-r-title">
                        <h4>
                            Select Body Builder Location
                            <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{ position: "relative" }}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div>
                        <div className={"row"} style={{ padding: '5px 0px', width: '100%', overflow: 'hidden', zIndex: '9999', height: "100%" }} >
                            <MyMapComponent selectedData={this.state.selectedMapData} onDataChanged={this.onBodyBuilderLocationChanged} />
                        </div>
                    </div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                </div>
            </div>
        );
    }
}
function loadScript(url) {
    var index = window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src = url
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}