
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');
var moment = require('moment');

 
const ApprovePODBtn = (props) => {
   const handleClick = (e) => {
    e.stopPropagation();
    props.context.componentParent.onClickShowApproveForm(props);
   }
    // console.log("props.data.customer_lr_doc ", props.data)
    return (
        <span>
        {
           (props.data.is_pod_submit == 1 && (props.data.is_approve_pod == "" || props.data.is_approve_pod == undefined))?
            <span>
                <button type="button" className="label label-warning f12" onClick={handleClick} style={{padding:"5px 10px",color:"#333","border":"0px"}}>Approve POD</button>
            </span>
            :""
        }
        </span>
    );
};

export default ApprovePODBtn;
