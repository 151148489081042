
import e from 'jquery-datetimepicker';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');
var moment = require('moment');

 
const ViewDocBtn = (props) => {
    var invDoc = [];
    if(props.data.invoice_document_1 !== "" && props.data.invoice_document_1 !== undefined)
    {
        // console.log("props.data.invoice_document_1 ", props.data.invoice_document_1)
        
        if(Array.isArray(props.data.invoice_document_1))
        {
            if(props.data.invoice_document_1.length > 0)
            {
                props.data.invoice_document_1.map((itm) =>{
                    invDoc.push(itm)
                })
            }
        }
        else{
            invDoc.push(props.data.invoice_document_1);
        }
    }
    // console.log("props.data.customer_lr_doc ", props.data)
    return (
        <span>
        {
           (invDoc.length > 0)?
            (invDoc.map((itm) =>
            <span>
                <a href={itm} target="_blank" className="f12"><i className="fa fa-eye"></i> View Document </a>
            </span>
            
            ))
            :""
        }
        </span>
    );
};

export default ViewDocBtn;
