
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const EditConsignment = (props) => {
    const handleEventClick = (e) => {
        e.stopPropagation();
        //console.log("Overspeed ",props);
        props.context.componentParent.onCickEditConsignment(props);
    };
    
    return (
        <div>
            <button 
                onClick={handleEventClick} 
                className="custom-btn f12 btn btn-default bordergray p-5p fbold" 
                title="Edit Consignment" >
                    <i className={"icofont icofont-pencil "}></i> Edit
            </button>
        </div>
    );
};

export default EditConsignment;
