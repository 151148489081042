
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');
var moment = require('moment');

 
const UpdateInvoice = (props) => {
    const handleOverspeedClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onClickUpdateItem(props);
    };
    // console.log("props.data.customer_lr_doc ", props.data)
    return (
        
        <a href={"/epoditems/"+props.data.invoice_no} className="label label-warning" style={{padding:"5px",fontSize:"12px",color:"#000" }}>View / Submit POD</a>
    );
};

export default UpdateInvoice;
