import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import CSVFileValidator from 'csv-file-validator'
import Select from "react-select";
import {getHyphenDDMMMYYYYHHMM} from '../common/utils';
import CountUp from 'react-countup';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import ViewItems from "./viewitems";
import ViewDoc from "./viewdocbtn";
import ApproveBtn from "./approvepod";
import ViewDoc2Btn  from "./viewdoc2";
import PrintItems  from "./printitems";
import ViewWithOutDefectsItems from './viewitemswithoutdefects';
import VendorPayments from "./vendorpaymentaction";
import Rating from "./ratingpod";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
$.datetimepicker.setLocale('en');
var infoBox = require('../common/google-infowindow');

var Constant = require('../common/Constant');
var redirectURL = require('../redirectURL');
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");
const decipher = salt => {
	const textToChars = text => text.split('').map(c => c.charCodeAt(0));
	const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
	return encoded => encoded.match(/.{1,2}/g)
		.map(hex => parseInt(hex, 16))
		.map(applySaltToChar)
		.map(charCode => String.fromCharCode(charCode))
		.join('');
}
var decode = decipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

export default class EPODDashboard extends Component {

	constructor(props) {
		super(props);
		this.state = {
			deptcode: "",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
            modules: AllModules,
			pivotmodeis: false,
			pivotcols: [],
			defaultColDef: {
				sortable: true,
				filter: true,
				resizable: true,
				pivot: true,
				enableValue: true,
				enableRowGroup: true,
			},
			rowData: [],
			maprowData: null,
			rowSelection: 'multiple',
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			overlayNoRowsTemplate: "",
			frameworkComponents: {
                ViewItems:ViewItems,
                ViewDoc:ViewDoc,
                ViewDoc2Btn:ViewDoc2Btn,
                ApproveBtn:ApproveBtn,
                PrintItems:PrintItems,
                VendorPayments:VendorPayments,
                Rating:Rating
            },

			animateRows: true,
			debug: true,
			showToolPanel: false,
			uppressAggFuncInHeader: true,

			childconfs: [],
			childrow: [],
			rowModelType: "serverSide",
			paginationPageSize: 100,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
						statusPanel: "agTotalAndFilteredRowCountComponent",
						align: "left"
					},
					{
						statusPanel: "agTotalRowCountComponent",
						align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
						id: "columns",
						labelDefault: "Columns",
						labelKey: "columns",
						iconKey: "columns",
						toolPanel: "agColumnsToolPanel"
					},
					{
						id: "filters",
						labelDefault: "Filters",
						labelKey: "filters",
						iconKey: "filter",
						toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams: {},
			rowClassRules: {
				//"highlitRow": "data.transit_delay == 1",
				//"green":"data.status >= 3"

			},
            show:false,
            basicTitle:"",
            basicType:"default",
            loadshow:"show-n",
            overly:"show-n",            
			sliderAddTranslate:'',
            customer_pod_date:"",
            customer_lr_doc:"",
            customer_pod_remarks:"",
            item_code:{"value":"","label":"Select Item"},
            item_description:"",
            defect_description:"",
            defect_code:{"value":"","label":"Select Defect"},
            category:{"value":"","label":"Select Category"},
            quantity:"",
            itemslist:[],
            itemcode:"",
            defectlist:[],
            defectcode:"",
            categorylist:[],
            categorycode:"",
            customer_code:"",
            podsubmitdata:[],
            nonpoddata:[],
            alldata:[],
            withdefects:[],
            withoutdefects:[],
            withdefectspod:[],
            withoutdefectspod:[],
            sidebarrowData:[],
            sliderWithDefectAddTranslate:"",
            sliderApprovePOD:"",
            startDate:"",
            endDate:"",
            dealercode:{"value":"","label":"All"},
            dealerslist : [],
            podapprvinv:"",
            approve_remarks:"",
            approvedpods:[],
            admincode:"",
            sliderFeedback:"",
            rating1:"",
            rating2:"",
            rating3:"",
            comment1:"",
            comment2:"",
            comment3:"",
            managerDealers:[],
            userZone:''
            
        }
        this.onClickShowApproveForm = this.onClickShowApproveForm.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
    }
    componentDidMount(){


        loadDateTimeScript();
        // if(localStorage.getItem("user_type") === "REGIONAL_MANAGER" || localStorage.getItem("user_type") === "AREA_MANAGER")
        // {
        //     var mp = {
        //         email:localStorage.getItem("email")
        //     }
        //     redirectURL.post("/dashboard/managerdealers",mp)
        //     .then((response) => {
        //         var records  = response.data.records;
        //         var managerDealers = []
        //         if(records.length > 0)
        //         {
        //             records.map((item) => {
        //                 managerDealers.push(item.consignee_code)
        //             })
        //         }
        //          console.log('managerDealers ', managerDealers)
        //         this.setState({
        //             managerDealers:managerDealers
        //         })
        //     })
        // }
        if(localStorage.getItem("assign_dealers") != "" && localStorage.getItem("assign_dealers") != undefined)
        {
            var assignDealers = localStorage.getItem("assign_dealers");
            var aDealers= []
            if(assignDealers != "")
            {
                var d = assignDealers.split(",");
                if(d.length > 0)
                {
                    d.map((itm) => {
                        aDealers.push(itm)
                    })
                }
            }
            // console.log("aDealers ", aDealers)
            this.setState({
                managerDealers:aDealers
            })
        }
        if(localStorage.getItem("user_type") === "ZONE_MANAGER")
        {
            if(localStorage.getItem("m") !== "" && localStorage.getItem("m") !== undefined && localStorage.getItem("m") !== null)
            {
                var m = decode(localStorage.getItem("m"));
                // console.log("AdaS ", JSON.parse(m))
                var loguser = JSON.parse(m);
                try {
                    var userZone = loguser.zone;
                    this.setState({
                        userZone:userZone
                    })   
                } catch (error) {
                    
                }
            }
           
        }
        // var stdate = moment.parseZone().subtract(30,"days").format("YYYY-MM-DD");
        var stdate = "2022-10-01";
        var etdate = moment.parseZone().format("YYYY-MM-DD");
        this.setState({
            startDate:stdate,
            endDate:etdate
        })
        redirectURL.post("/dashboard/sndconsignees")
        .then((response) => {
            var records = response.data.records;
            var consignees = []
            consignees.push({
                value: "",
                label: "All"
            })
            if (records.length > 0) {
				records = records.sort(GetSortASCOrder("consignee_name"));
                var userzone = ''; 
                if(localStorage.getItem("m") !== "" && localStorage.getItem("m") !== undefined && localStorage.getItem("m") !== null)
                {
                    var m = decode(localStorage.getItem("m"));
                    // console.log("AdaS ", JSON.parse(m))
                    var loguser = JSON.parse(m);
                    try {
                        userZone = loguser.zone;
                    }
                    catch(e){

                    }
                }
                
                records.map((item) => {
                    console.log("item ", item.zone)
                    if(localStorage.getItem("user_type") === "ZONE_MANAGER")
                    {
                        if(localStorage.getItem("assign_dealers") != "" && localStorage.getItem("assign_dealers") != undefined  && localStorage.getItem("assign_dealers") != "undefined")
                        {
                            var assignDealers = localStorage.getItem("assign_dealers");
                            var aDealers= []
                            if(assignDealers != "")
                            {
                                var d = assignDealers.split(",");
                                if(d.length > 0)
                                {
                                    d.map((itm) => {
                                        if(itm == item.consignee_code)
                                        {
                                            consignees.push({
                                                value: item.consignee_code,
                                                label: item.consignee_name+"-"+item.consignee_code+"-"+item.consignee_city
                                            })
                                        }
                                    })
                                }
                            }
                            
                        }
                        else{
                            
                            if(localStorage.getItem("user_type") === "ZONE_MANAGER")
                            {
                                // console.log("userzone == item.zone", userzone +"=="+ item.zone)
                                if(userZone == item.zone)
                                {
                                    consignees.push({
                                        value: item.consignee_code,
                                        label: item.consignee_name+"-"+item.consignee_code+"-"+item.consignee_city
                                    })
                                }
                            }
                            else{
                                consignees.push({
                                    value: item.consignee_code,
                                    label: item.consignee_name+"-"+item.consignee_code+"-"+item.consignee_city
                                })
                            }
                            
                        }
                    }
                    else{
                        if(localStorage.getItem("assign_dealers") != "" && localStorage.getItem("assign_dealers") != undefined  && localStorage.getItem("assign_dealers") != "undefined")
                        {
                            var assignDealers = localStorage.getItem("assign_dealers");
                            var aDealers= []
                            if(assignDealers != "")
                            {
                                var d = assignDealers.split(",");
                                if(d.length > 0)
                                {
                                    d.map((itm) => {
                                        if(itm == item.consignee_code)
                                        {
                                            consignees.push({
                                                value: item.consignee_code,
                                                label: item.consignee_name+"-"+item.consignee_code+"-"+item.consignee_city
                                            })
                                        }
                                    })
                                }
                            }
                            
                        }
                        else{
                            
                           
                            consignees.push({
                                value: item.consignee_code,
                                label: item.consignee_name+"-"+item.consignee_code+"-"+item.consignee_city
                            })
                        
                            
                        }
                    }
                    
                })
            }
            this.setState({
                dealerslist: consignees
            })
        })
        redirectURL.post("/dashboard/podcodes")
        .then((response) => {
            console.log("response",response.data)
            var itemslist = response.data.items;
            var defectlist = response.data.defects;
            var categories = response.data.categories;
            this.setState({
                itemslist:itemslist,
                defectlist:defectlist,
                categorylist:categories
            })
        })
        if(localStorage.getItem("customer_code") != "" && localStorage.getItem("customer_code") != undefined && localStorage.getItem("customer_code") != "undefined")
        {
            // var customer_code = localStorage.getItem("customer_code");
            if(localStorage.getItem("customer_code") != "adminpod")
            {
                var customer_code = localStorage.getItem("customer_code");
            }
            else{
                this.setState({
                    admincode:"adminpod"
                })
                var customer_code = "";
            }
        }
        else{
            var customer_code = "";
        }
        this.setState({
            customer_code:customer_code,
        })
        var parameters = {
            dealer_code:customer_code
        }
        setTimeout(() => {
            this.onLoadGetShipments(parameters);
        },500)
        redirectURL.post("/consignments/usergridstates", { userId: localStorage.getItem("userid"), screenurl: window.location.pathname })
        .then(async (resp) => {
            //// console.log("resp ", resp)
            await this.setState({
                usergridstate: resp.data
            });
            await this.restoreGridStates();
        })

    }
    onLoadGetShipments(parameters){
        this.setState({
            loadshow:"show-m"
        })
        if(this.state.startDate != "" && this.state.startDate != undefined && this.state.endDate != "" && this.state.endDate != "" )
        {
            parameters.startDate = this.state.startDate;
            parameters.endDate = this.state.endDate;
        }
        if(localStorage.getItem("user_type") === "TRANSPORTER")
        {
            parameters.transporter_code = localStorage.getItem("transportercode");
        }
        if(localStorage.getItem("user_type") === "REGIONAL_MANAGER" || localStorage.getItem("user_type") === "AREA_MANAGER" || localStorage.getItem("user_type") === "BUSINESS_MANAGER")
        {
            parameters.consignees = this.state.managerDealers;
            var upath = "/dashboard/managerpods";
        }
        // else if(localStorage.getItem("user_type") === "ZONE_MANAGER")
        // {
        //     parameters.consignees = this.state.managerDealers;
        //     var upath = "/dashboard/zonealpods";
        // }
        else{
            var upath = "/dashboard/customerconsignments";
        }
        // console.log("parameters ", parameters)
        redirectURL.post(upath,parameters)
        .then((response) => {
            if(response.data.status == "success")
            {
                var records = response.data.records;
                // console.log("records ", records)
                var recordsarr = [];
                var podsubmitdata = []
                var nonpoddata = [];
                var withoutdefects=[];
                var withdefects=[];
                var withoutdefectspod=[];
                var withdefectspod=[];
                var approvedpods = [];
                try{
                    if(records.length > 0)
                    {
                        records.map((item) => {
                            if(localStorage.getItem("user_type") === "ZONE_MANAGER")
                            {
                                if(item.zone == this.state.userZone)
                                {
                                    if(item.is_pod_submit != "" && item.is_pod_submit != undefined)
                                    {
                                        if(item.pods != "" && item.pods != undefined)
                                        {
                                            if(item.pods.length > 0)
                                            {
                                                var isdefec = 0;
                                                item.pods.map((jt) => {
                                                    if(jt.is_defect_item == 1)
                                                    {
                                                        isdefec=1;
                                                        withdefects.push(jt);
                                                    }
                                                    else{
                                                        withoutdefects.push(jt);
                                                    }
                                                })
                                                if(isdefec == 1)
                                                {
                                                    withdefectspod.push(item)
                                                }
                                                else{
                                                    withoutdefectspod.push(item)
                                                }
                                                
                                            }
                                        }
                                        
                                        podsubmitdata.push(item);
                                        if(item.is_approve_pod == 1)
                                        {
                                            approvedpods.push(item);
                                        }
                                    }
                                    else{
                                        item.invoice_document_1 = "";
                                        item.invoice_document_2 = "";
                                        item.is_pod_submit= "";
                                        nonpoddata.push(item)
                                    }
                                }
                            }
                            else{
                                if(item.is_pod_submit != "" && item.is_pod_submit != undefined)
                                {
                                    if(item.pods != "" && item.pods != undefined)
                                    {
                                        if(item.pods.length > 0)
                                        {
                                            var isdefec = 0;
                                            item.pods.map((jt) => {
                                                if(jt.is_defect_item == 1)
                                                {
                                                    isdefec=1;
                                                    withdefects.push(jt);
                                                }
                                                else{
                                                    withoutdefects.push(jt);
                                                }
                                            })
                                            if(isdefec == 1)
                                            {
                                                withdefectspod.push(item)
                                            }
                                            else{
                                                withoutdefectspod.push(item)
                                            }
                                            
                                        }
                                    }
                                    
                                    podsubmitdata.push(item);
                                    if(item.is_approve_pod == 1)
                                    {
                                        approvedpods.push(item);
                                    }
                                }
                                else{
                                    item.invoice_document_1 = "";
                                    item.invoice_document_2 = "";
                                    item.is_pod_submit= "";
                                    nonpoddata.push(item)
                                }
                            }
                            if(localStorage.getItem("user_type") === "ZONE_MANAGER")
                            {
                                if(item.zone === this.state.userZone)
                                {
                                    recordsarr.push(item);
                                }
                            }
                            else{
                                recordsarr.push(item);
                            }
                        })
                    }
                }
                catch(e){

                }
                
                this.setState({
                    rowData:recordsarr,
                    alldata:recordsarr,
                    podsubmitdata:podsubmitdata,
                    withoutdefects:withoutdefects,
                    withdefects:withdefects,
                    withoutdefectspod:withoutdefectspod,
                    withdefectspod:withdefectspod,
                    nonpoddata:nonpoddata,
                    approvedpods:approvedpods,
                    loadshow:"show-n",
                    overly:"show-n"
                })
            }
            
        });

    }
    closeAlert = async () => {
        await this.setState({
            show: false
        });
        await window.location.reload();
    }
    onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
        
        this.gridApi.addEventListener('paginationChanged', (e) =>
        {
            //Reset rows selection based on current page
           // this.resetPaginationSelection(this);
        });

        this.gridApi.forEachLeafNode( (node) => {
            // console.log("node here ",node)
            node.setSelected(true);
         
        });
        
	};
	

	onGridState = () =>{
		 console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
           this.gridApi.setFilterModel(window.filterState);
    }
    
    onGridReady1 = params => {
		this.gridApi1 = params.api;
		
	    //console.log("Grid is: ", params);
        this.gridColumnApi1 = params.columnApi;
        
        this.gridApi1.addEventListener('paginationChanged', (e) =>
        {
            //Reset rows selection based on current page
           // this.resetPaginationSelection(this);
        });

        this.gridApi1.forEachLeafNode( (node) => {
            // console.log("node here ",node)
            node.setSelected(true);
         
        });
        
	};
	

	onGridState1 = () =>{
		//  console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState1 = this.gridColumnApi1.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState1 = this.gridColumnApi1.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns1 = this.gridColumnApi1.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState1 = this.gridApi.getFilterModel();
           this.gridApi1.setFilterModel(window.filterState1);
    }
   
    onCloseRouteDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderWithDefectAddTranslate:"",
			sliderApprovePOD : "",
			sliderSegmentTranslate : "",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
			overly:'show-n'
		});
	}
    onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderApprovePOD:'',
			sliderWithDefectAddTranslate:'',
            sliderFeedback:"",
		});
		
	}
    
    onCLickLoadInvoicItems = async (rownode) =>{
        // console.log("rpownode ", rownode)
        if (rownode.colDef.field == "is_pod_submit") {
			await this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							 
                            {
                                headerName:"POD No",
                                field:"pod_no",
                                width:140,
                                resizable:true
                            },
                            {
                                headerName:"Invoice No",
                                field:"invoice_no",
                                width:120,
                            },
                            {
                                headerName:"Material No",
                                field:"material_no",
                                width:120,
                            },
                            {
                                headerName:"Material Description",
                                field:"material_desc",
                                width:180,
                            },
                            
                            {
                                headerName:"Supplying Plant Description",
                                field:"supply_plant_desc",
                                width:200,
                            },
                            {
                                headerName:"Invoice Date",
                                field:"invoice_time",
                                width:120,
                                valueGetter:function(params){
                                    try{
                                        if(params.data.invoice_time != "" && params.data.invoice_time != undefined)
                                        {
                                            return getHyphenDDMMMYYYYHHMM(params.data.invoice_time);
                                        }
                                        else{
                                            return "";
                                        }
                                    }
                                    catch(e){
                
                                    }
                                }
                            },
                            
                            {
                                headerName:"Vin No",
                                field:"vin_number",
                                width:120,
                            },
                            
                            {
                                headerName:"Transporter Code",
                                field:"transporter_code",
                                width:120,
                                resizable:true,
                                valueGetter:function(params){
                                    try{
                                        return rownode.data.transporter_code;
                                    }
                                    catch(e){}
                                }
                            },
                            {
                                headerName:"Transporter Name",
                                field:"transporter_name",
                                width:120,
                                resizable:true,
                                valueGetter:function(params){
                                    try{
                                        return rownode.data.transporter_name;
                                    }
                                    catch(e){}
                                }
                            },
                            {
                                headerName:"POD Date",
                                field:"customer_pod_date",
                                width:120,
                                valueGetter:function(params){
                                    try{
                                        if(params.data.customer_pod_date != "" && params.data.customer_pod_date != undefined)
                                        {
                                            return getHyphenDDMMMYYYYHHMM(params.data.customer_pod_date);
                                        }
                                        else{
                                            return "";
                                        }
                                    }
                                    catch(e){}
                                }
                            },
                           
                            {
                                headerName:"Item Code",
                                field:"item_code",
                                width:120,
                            },
                           
                            {
                                headerName:"Item Description",
                                field:"item_description",
                                width:120,
                            },
                           
                            {
                                headerName:"Defect Code",
                                field:"defect_code",
                                width:120,
                            },
                           
                            {
                                headerName:"Defect Description",
                                field:"defect_description",
                                width:120,
                            },
                           
                            {
                                headerName:"Category",
                                field:"category",
                                width:120,
                            },
                           
                            {
                                headerName:"Quantity",
                                field:"quantity",
                                width:120,
                            },
                            {
                                headerName:"Remarks",
                                field:"customer_pod_remarks",
                                width:120,
                            },
                            {
                                headerName:"Document 1",
                                field:"invoice_document_1",
                                width:200,
                                cellRendererFramework:ViewDoc
                            },
                            {
                                headerName:"Document 2",
                                field:"invoice_document_2",
                                width:200,
                                cellRendererFramework:ViewDoc2Btn
                            }
                           
                            
                           
						],
						overlayNoRowsTemplate: 'No rows to show',

					},
					getDetailRowData: async function (param) {
						param.successCallback([]);
                        try{
                            var rr = {
                                dealer_code:param.data.consignee_code,
                                invoice_no:param.data.invoice_no
                            }
                            redirectURL.post("/dashboard/customerconsignments",rr)
                            .then((resp) => {
                                var records = resp.data.records;
                                var recordsarr = []
                                if(records.length > 0)
                                { 
                                    try{
                                        var pods = records[0].pods;
                                        pods.map((item) => {
                                            item.truck_no = param.data.truck_no;
                                            recordsarr.push(item);
                                        })
                                    }
                                    catch(e){}
                                    
                                }
                                param.successCallback(recordsarr);
                            })
                            
                        }
                        catch(e){

                        }
						
					},
					masterDetail: true
				}
			});


			rownode.node.setExpanded(!rownode.node.expanded);
		}
    }
    
    onClickCounter(params){
        if(params == "1")
        {
            this.setState({
                rowData:this.state.alldata
            })
        }
        
        if(params == "2")
        {
            this.setState({
                rowData:this.state.podsubmitdata
            })
        }
        
        if(params == "3")
        {
            this.setState({
                rowData:this.state.nonpoddata
            })
        }
        if(params == "4")
        {
            this.setState({
                rowData:this.state.approvedpods
            })
        }
        
        if(params == "withdefect")
        {
            this.setState({
                rowData:this.state.withdefectspod
            })
        }
        if(params == "withoutdefect")
        {
            this.setState({
                rowData:this.state.withoutdefectspod
            })
        }
    }
    onClickCounterItems(clickable){
        console.log("'clickable ", clickable)
        if(clickable == 'withdefectitems')
        {
            this.setState({
                sliderWithDefectAddTranslate:"slider-translate-60p",
                overly:"show-m",
                sidebarrowData:this.state.withdefects
            })
        }
        if(clickable == 'withoutdefectitems')
        {
            this.setState({
                sliderWithDefectAddTranslate:"slider-translate-60p",
                overly:"show-m",
                sidebarrowData:this.state.withoutdefects
            })
        }
    }
    
    handlerStartDateTime = (event, currentDate, selectedDate) => {
        // var d = new Date(event._d);

        // var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate() + " " + d.getHours() + ":" + ((d.getMinutes() < 10) ? "0" + d.getMinutes() : d.getMinutes()) + ":" + ((d.getSeconds() < 10) ? "0" + d.getSeconds() : d.getSeconds());

        // this.setState({
        //     startDate: getYYYYMMDDHHMMSS(d)
        // });
        //let value = event.target.value;
        //this.setState({'startDate':value});
        var d = new Date(event._d);

		var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());
		if (this.state.endDate != undefined && this.state.endDate != "") {
			// console.log(startdate,"startdate")
			// console.log(this.state.endDate,"endDate")
			var startDate = new Date(startdate);
			var endDate = new Date(this.state.endDate);
			var startTime = startDate.getTime();
			var endTime = endDate.getTime();
			// console.log(startTime,endTime);
			if (startTime > endTime) {
				this.setState({
					startDate: ""
				});
				alert("To Date should be greater than From Date");
			}
			else {
				this.setState({
					startDate: startdate
				});
			}
		}
		else {
			this.setState({
				startDate: startdate
			});
		}
    }
    handlerEndDateTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);
		var edate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate())
		if (this.state.startDate != undefined && this.state.startDate != "") {
			// console.log(this.state.startDate,"startdate")
			// console.log(edate,"to_date")
			var startDate = new Date(this.state.startDate);
			var endDate = new Date(edate);
			var startTime = startDate.getTime();
			var endTime = endDate.getTime();
			// console.log(startTime,endTime);
			if (startTime > endTime) {
				this.setState({
					endDate: ""
				});
				alert("To Date should be greater than From Date");
			}
			else {
				this.setState({
					endDate: edate
				});
			}
		}
		else {
			this.setState({
				endDate: edate
			});
		}
	}
    onClickSubmitGetData(){
        var startDate = this.state.startDate;
        var endDate = this.state.endDate;
    
        var customer = this.state.dealercode.value;
        // console.log("dealercodedealercode ", this.state.dealercode);
        var parameters = {
            startDate:startDate,
            endDate:endDate
        }
        if(customer != "" && customer != undefined)
        {
            parameters.dealer_code = customer;            
        }
        // console.log("parameters ", parameters)
        this.onLoadGetShipments(parameters);
        
    }
    // dealerslistall(){
    //     var items = [];
    //     var dealerslist = this.state.dealerslist;
    //     if(dealerslist.length > 0)
    //     {
    //         dealerslist.map((item) => {
    //             items.push({
    //                 value:item.consignee_code,
    //                 label:item.consignee_name
    //             })
    //         })
    //     }
    //     return items;
    // }
    changeDealer(dealercode){
        this.setState({dealercode},()=>{console.log("dealer ", this.state.dealercode)})
    }
    onClickShowApproveForm(rownode){
        // console.log("'rownode", rownode)
        var invoice_no = rownode.data.invoice_no;
        this.setState({
            podapprvinv:invoice_no,
            sliderApprovePOD:"slider-translate",
            overly:"show-m",
            approve_remarks:""
                    
        })
    }

    onSubmitApporvePOD(event){
        event.preventDefault();
        var approve_remarks = this.state.approve_remarks;
        var invoice_no = this.state.podapprvinv;
        var approve_by = localStorage.getItem("email");
        var approveuserid = localStorage.getItem("userid");
        var approveusername = localStorage.getItem("username");
        if(approve_remarks != "")
        {
            var parameters = {
                invoice_no:invoice_no,
                approve_remarks:approve_remarks,
                approve_by:approve_by,
                approved_user_name:approveusername,
                approve_user_id:approveuserid,
            }
            console.log("parameters", parameters)
            var cnf = window.confirm("Are you sure you want to approve POD?");
            if(cnf === true)
            {
                    
                redirectURL.post("/dashboard/approvePOD",parameters)
                .then((response) => {
                    if(response.data.status == "success")
                    {
                        this.setState({
                            show:true,
                            basicTitle:"Successfully approved POD",
                            basicType:'success',
                            approve_remarks:"",
                            podapprvinv:"",
                            overly:"show-n",
                            sliderApprovePOD:""
                        })
                    }
                    else{
                        this.setState({
                            show:true,
                            basicTitle:"Failed to approved POD",
                            basicType:'danger'
                        })
                    }
                })
            }
        }
        else{
            this.setState({
                show:true,
                basicTitle:"Please enter remarks",
                basicType:'danger'
            })
        }
    }   
    
    changeHandler(event){
        var name= event.target.name;
        var value = event.target.value;
        this.setState({
            [name]:value
        })
    }
    onClickShowFeedbackForm(rownode){
        var params = {
            // customer_code:this.state.customer_code,
            feed_invoice_no:rownode.data.invoice_no
        }
        redirectURL.post("/dashboard/customerfeedbackdata",params)
        .then((response) => {
            var records = response.data.records;
            
            var rating1= "";
            var rating2= "";
            var rating3= "";
            var comment1= "";
            var comment2= "";
            var comment3= "";
            if(records.length > 0)
            {
                var data = records[0];
                if(data.based_on_delivery_time != undefined){
                    rating1= data.based_on_delivery_time
                }
                else {
                    rating1= 10;
                }
                
                if(data.based_on_delivery_time_comment != undefined){
                    comment1= data.based_on_delivery_time_comment;
                }
                else {
                    comment1= "";
                }
                
                if(data.based_on_vehicle_delivery_condition != undefined){
                    rating2= data.based_on_vehicle_delivery_condition;
                }
                else {
                    rating2= 10
                }
                
                if(data.based_on_vehicle_delivery_condition_comment != undefined){
                    comment2= data.based_on_vehicle_delivery_condition_comment;
                }
                else {
                    comment2= "";
                }
                
                if(data.overall_experience != undefined){
                    rating3= data.overall_experience;
                }
                else {
                    rating3= 10;
                }
                
                if(data.overall_experience_comment != undefined){
                    comment3= data.overall_experience_comment;
                }
                else {
                    comment3= "";
                }
            }
            this.setState({
                sliderFeedback:"slider-translate-30p",
                overly:'show-m',
                feedrowid:rownode.data._id,
                feed_invoice_no:rownode.data.invoice_no,
                feed_rownode:rownode,
                rating1:rating1,
                rating2:rating2,
                rating3:rating3,
                comment1:comment1,
                comment2:comment2,
                comment3:comment3,
            })
            
           
        })
        
    }
    onClickSaveGridState() {
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();
		// // console.log("Columns ", window.colState)
		// // console.log("Group ", window.groupState)
		// // console.log("Sort ", window.sortState)
		// // console.log("Filter ", window.filterState)
		let screenpage = 'POD Dashboard';

		
		let reqparams = {
			gridcolumns: window.colState,
			gridgroup: window.groupState,
			gridcolsort: window.sortState,
			gridcolfilter: window.filterState,
			userId: localStorage.getItem("userid"),
			screenurl: window.location.pathname,
			screentitle: screenpage
		}
		console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
			.then((response) => {
				//// console.log("State response ",response.data)
				this.setState({
					show: true,
					basicTitle: "Successfully saved grid layout",
					basicType: "success",
					screenurl: window.location.pathname,
					screentitle: screenpage
				})
			})
			.catch(function (e) {
				// console.log("Error ", e)
			})
	}

	restoreGridStates() {

		if (this.state.usergridstate.length > 0) {
			var windowstates = this.state.usergridstate;
			this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
			this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
			this.gridApi.setSortModel(windowstates[0].gridcolsort);
			this.gridApi.setFilterModel(windowstates[0].gridcolfilter);

		}
	}

	resetState = () => {
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//// console.log('column state reset');
		var reqparams = {
			userId: localStorage.getItem("userid"),
			screenurl: window.location.pathname,
			screentitle: this.state.screenpage
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
			.then((response) => {
				this.setState({
					show: true,
					basicTitle: "Successfully reset default grid layout",
					basicType: "success"
				})
			})

	};
    render(){
        var maptransporter='';
		
        var columnwithDefs = [
            // {
            //     headerName: "",
            //     field: "_id",
            //     width: 100,
            //     pinned: 'left',
            //     cellRenderer:'ViewShipmentData',
            //     filter: false,resizable: true
                
            // },
            
            {
                headerName:"",
                field:"invoice_no",
                width:120,
                cellRenderer:"PrintItems"
            },
            {
                headerName: "",
                field: "consigner_code",
                width: 100,
                cellRenderer:'Rating',
                filter: false,resizable: true,
                
            },
            // {
            //     headerName:"",
            //     field:"invoice_no",
            //     width:120,
            //     cellRenderer:"VendorPayments"
            // },
            {
                headerName:Constant.COL_INVOICE_NO,
                field:"invoice_no",
                width:140,
            },
            {
                headerName:Constant.COL_REACHED_DESTINATION_AT,
                field:"recent_dealer_reported",
                width:140,
                valueGetter:function(params){
                    try{
                        if(params.data.recent_dealer_reported != "" && params.data.recent_dealer_reported != undefined)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.recent_dealer_reported);
                        }
                        else{
                            return "";
                        }
                    }
                    catch(e){}
                }
            },
            {
                headerName:Constant.COL_VIN_NO,
                field:"vin_number",
                width:120,
            },
            {
                headerName:Constant.COL_DEALER_CODE,
                field:"consignee_code",
                width:120,
            },
            {
                headerName:Constant.COL_DEALER_NAME,
                field:"consignee_name",
                width:200,
            },
            // {
            //     headerName:Constant.COL_PLANT,
            //     field:"consigner_code",
            //     width:120,
            // },
            {
                headerName:"Vehicle Variant",
                field:"description",
                width:120,
            },
            {
                headerName: "",
                field: "is_pod_submit",
                width: 50,
                cellRenderer:'ViewItems',
                filter: false,resizable: true
                
            },
            {
                headerName:"POD Code",
                field:"pod_no",
                width:150
            },
            {
                headerName:Constant.COL_POD_CREATED_DATE,
                field:"pod_create_date",
                width:150,
                valueGetter:function(params){
                    try{
                        if(params.data.pod_create_date != "" && params.data.pod_create_date != undefined)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.pod_create_date);
                        }
                        else{
                            return "";
                        }
                    }
                    catch(e){}
                }
            },
            {
                headerName:"POD Unique Code",
                width:150,
                field:"pod_unique_code"
            },
            
            {
                headerName: "Approved On",
                field: "pod_approved_on",
                width: 140,
                filter: false,resizable: true,
                
                valueGetter:function(params){
                    try{
                        if(params.data.pod_approved_on != "" && params.data.pod_approved_on != undefined)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.pod_approved_on);
                        }
                        else{
                            return "";
                        }
                    }
                    catch(e){}
                }
                
            },
            {
                headerName: "Approved By",
                field: "pod_approved_user_name",
                width: 140,
                filter: false,resizable: true
                
            },
            {
                headerName: "Remarks",
                field: "pod_approved_remards",
                width: 200,
                filter: false,resizable: true
                
            },
            
           
            
            // {
            //     headerName:Constant.COL_POD_INVOICE_DOC_1,
            //     field:"invoice_document_1",
            //     width:200,
            //     cellRenderer:'ViewDoc'
            // },
            // {
            //     headerName:Constant.COL_POD_INVOICE_DOC_2,
            //     field:"invoice_document_2",
            //     width:200,
            //     cellRenderer:'ViewDoc2Btn'
            // }
           
            
        ]
        if(localStorage.getItem("user_type") === "APP_ADMIN")
        {
            columnwithDefs.push( {
                headerName:"",
                field:"invoice_no",
                width:120,
                cellRenderer:"ApproveBtn"
            });
        }
        var columnwithDefsItems  = [
            
            {
                headerName:"POD No",
                field:"pod_no",
                width:140,
                resizable:true
            },
            {
                headerName:"Unique Code",
                field:"item_uniquecode",
                width:120,
            },
            {
                headerName:"Invoice No",
                field:"invoice_no",
                width:120,
            },
            {
                headerName:"Material No",
                field:"material_no",
                width:120,
            },
            {
                headerName:"Material Description",
                field:"material_desc",
                width:180,
            },
            
            {
                headerName:"Supplying Plant Description",
                field:"supply_plant_desc",
                width:200,
            },
            {
                headerName:"Invoice Date",
                field:"invoice_date",
                width:120,
                valueGetter:function(params){
                    try{
                        if(params.data.invoice_date != "" && params.data.invoice_date != undefined)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.invoice_date);
                        }
                        else{
                            return "";
                        }
                    }
                    catch(e){

                    }
                }
            },
            
            {
                headerName:"Vin No",
                field:"vin_number",
                width:120,
            },
            
            {
                headerName:"POD Date",
                field:"customer_pod_date",
                width:120,
                valueGetter:function(params){
                    try{
                        if(params.data.customer_pod_date != "" && params.data.customer_pod_date != undefined)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.customer_pod_date);
                        }
                        else{
                            return "";
                        }
                    }
                    catch(e){}
                }
            },
           
            {
                headerName:"Item Code",
                field:"item_code",
                width:120,
            },
           
            {
                headerName:"Item Description",
                field:"item_description",
                width:120,
            },
           
            {
                headerName:"Defect Code",
                field:"defect_code",
                width:120,
            },
           
            {
                headerName:"Defect Description",
                field:"defect_description",
                width:120,
            },
           
            {
                headerName:"Category",
                field:"category",
                width:120,
            },
           
            {
                headerName:"Quantity",
                field:"quantity",
                width:120,
            },
            {
                headerName:"Remarks",
                field:"customer_pod_remarks",
                width:120,
            },
            {
                headerName:"Document 1",
                field:"invoice_document_1",
                width:200,
                cellRenderer:"ViewDoc"
            },
            {
                headerName:"Document 2",
                field:"invoice_document_2",
                width:200,
                cellRenderer:"ViewDoc2Btn"
            }
           
            
            
        ]
        return (
            <div class="container-fluid">          
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row">
                    <div className="col-sm-12 fbold f18">
                        <h5 className="fbold f18">
                            <i class="icofont icofont-law-document"></i> <span>{Constant.PAGE_EPOD_DASHBOARD}</span>
                        </h5>
                    </div>
                    <div className="col-xl-12 col-lg-12 col-sm-12">
                        <div className="row">
                            <div className="col-xl-3 col-lg-3 form-group">
                                <label>From Date</label>
                                <Datetime value={this.state.startDate}
                                    disableCloseOnClickOutside={false}
                                    closeOnSelect={true}
                                    inputProps={{ placeholder: 'From', name: 'startDate', autoComplete: 'off', readOnly: true }}
                                    dateFormat="YYYY-MM-DD"
                                    timeFormat={false}
                                    name="startDate"
                                    onChange={this.handlerStartDateTime.bind(this)} />
                                {/* <input type="text" placeholder="From Date" id="from_date" value={this.state.startDate} autoComplete="off"  className="datetimepicker_date form-control"  required /> */}

                                {/* <input type="text" placeholder="From Date" id="from_date" value={this.state.startDate} autoComplete="off"  className="datetimepicker_date form-control"  required /> */}

                            </div>

                            <div className="col-xl-3 col-lg-3 form-group">
                                <label>To Date</label>
                                {/* <input type="text" placeholder="To Date" autoComplete="off" id="to_date" value={this.state.endDate}  className="datetimepicker_date form-control"  required /> */}
                                <Datetime value={this.state.endDate}
                                    disableCloseOnClickOutside={false}
                                    closeOnSelect={true}
                                    inputProps={{ placeholder: 'To', name: 'endDate', autoComplete: 'off', readOnly: true }}
                                    dateFormat="YYYY-MM-DD"
                                    timeFormat={false}
                                    onChange={this.handlerEndDateTime.bind(this)} />

                            </div>
                            <div className="form-group col-xl-3 col-lg-3">
                                <label>Customer </label>
                                <Select
                                    className="border-radius-0"
                                    isMulti={false}
                                    id="dealercode"
                                    style={{ borderRadius: "0px" }}
                                    value={this.state.dealercode}
                                    onChange={this.changeDealer.bind(this)}
                                    options={this.state.dealerslist}
                                />
                            </div>
                            <div className="col-xl-3 col-lg-3">
                                <button type="button" className="btn btn-success mt-30p" onClick={this.onClickSubmitGetData.bind(this)}>Submit</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                        {/* <div className="card">
                            <div className="card-body" id="counterCardBody"> */}
                                <div className="crm-numbers pb-0">
                                    <div className="row">
                                        <div className="col cirlce-d cursorPointer"  onClick={this.onClickCounter.bind(this,"1")}>
                                            {/* <span className="f13"><i className="icofont icofont-ui-browser f24 greenfont"></i><br /> Total PODs</span> */}
                                        <div class="o-hidden card">
                                            <div class="bg-warning b-r-4 card-body mdi">
                                               <div class="media static-top-widget d-flex">
                                                        {/* <div class="align-self-center text-center"> */}
                                                            {/* <i class="icofont icofont-chart-histogram-alt"></i> */}
                                                            {/* <i className="fa fa-tachometer"></i>
                                                        </div> */}
                                                    <div class="media-body">
                                                    <span class="m-0">Total PODS</span> 
                                                        <h4 class="counter mb-0 f28"><span><CountUp end={(this.state.alldata.length > 0)?this.state.alldata.length:0}/></span></h4>
                                                        
                                                    </div>  
                                               </div> 
                                           </div> 
                                        </div> 
                                    </div> 
                                    <div className="col cirlce-d cursorPointer"  onClick={this.onClickCounter.bind(this,"3")}>
                                            {/* <span className="f13"><i className="icofont icofont-ui-browser f24 greenfont"></i><br /> Total PODs</span> */}
                                            <div class="o-hidden card">
                                                <div class="bg-danger b-r-4 card-body mdi">
                                                <div class="media static-top-widget d-flex">
                                                            {/* <div class="align-self-center text-center"> */}
                                                                {/* <i class="icofont icofont-chart-histogram-alt"></i> */}
                                                                {/* <i className="icofont icofont-social-google-map  txt-info"></i>
                                                            </div> */}
                                                        <div class="media-body"> 
                                                        <span class="m-0">POD Pending</span>
                                                            <h4 class="counter mb-0 f28"><span><CountUp end={(this.state.nonpoddata.length > 0)?this.state.nonpoddata.length:0}/></span></h4>
                                                            
                                                        </div>  
                                                </div> 
                                            </div> 
                                            </div> 
                                        </div>  
                                        {/* <div className="col cirlce-d cursorPointer" onClick={this.onClickCounter.bind(this,"2")}> */}
                                            {/* <span className="f13"><i className="icofont icofont-ui-browser f24 greenfont"></i><br /> Total PODs</span> */}
                                        <div class ="col cirlce-d cursorPointer">    
                                            <div class="o-hidden card">
                                                <div class="bg-secondary b-r-4 card-body mdi">
                                                   <div class="media static-top-widget d-flex">
                                                            {/* <div class="align-self-center text-center"> */}
                                                                {/* <i class="icofont icofont-chart-histogram-alt"></i> */}
                                                                {/* <i className="icofont icofont-clock-time txt-success"></i>
                                                            </div> */}
                                                        {/* <div class="col-xl-12">    */}
                                                    
                                                        {/* <div class="media-body">
                                                        <span class="m-0">POD Submitted</span> 
                                                            <h4 class="counter mb-0 f28"><span><CountUp end={(this.state.podsubmitdata.length > 0)?this.state.podsubmitdata.length:0}/></span></h4> */}
                                                        {/* <div className="col-xl-12 counter"  style={{cursor:"pointer",borderRight:"1px solid #ccc"}} onClick={this.onClickCounter.bind(this,"2")}> */}
                                                             
                                                          {/* <span class="col-xl-4">POD Submitited </span>
                                                          <span class="counter mb-0">
                                                            <CountUp end={(this.state.podsubmitdata.length > 0)?this.state.podsubmitdata.length:0}/></span> */}
                                                       
{/*                                                             
                                                                <div className="col-xl-4 counter txt-danger" style={{cursor:"pointer",borderRight:"1px solid #ccc"}} onClick={this.onClickCounter.bind(this,"withdefect")}>
                                                                <span class="col-sm-4"> With Defects: </span>    
                                                                <span class="counter mb-0">
                                                                    <CountUp end={(this.state.withdefectspod.length > 0) ? this.state.withdefectspod.length : 0} />
                                                                    </span>
                                                                </div>

                                                                <div className="col-xl-4 counter"  style={{cursor:"pointer",borderRight:"1px solid #ccc"}} onClick={this.onClickCounter.bind(this,"withoutdefect")}>
                                                                <span class="col-sm-4"> Without Defects: </span>
                                                                    <span class="counter mb-0">
                                                                    <CountUp end={(this.state.withoutdefectspod.length > 0) ? this.state.withoutdefectspod.length : 0} /> 
                                                                    </span>
                                                                </div> */}
                                                         {/* </div> */}
                                                         {/* </div>  */}
                                                         <div class="media-body">
    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        
        <div className="col cirlce-d cursorPointer"  onClick={this.onClickCounter.bind(this,"2")}>
            <span class="m-0">POD Submitted</span>
            <h4 class="counter mb-0 f28">
                <span>
                    <CountUp end={(this.state.podsubmitdata.length > 0) ? this.state.podsubmitdata.length : 0} />
                </span>
            </h4>
        </div>
        <div>
            {/* <span class="counter mb-0">
                <CountUp end={(this.state.podsubmitdata.length > 0) ? this.state.podsubmitdata.length : 0} />
            </span> */}
            <div className="counter txt-danger" style={{ cursor: "pointer",marginBottom:"5px" }} onClick={this.onClickCounter.bind(this, "withdefect")}>
                <span>With Defects:</span>
                <span class="counter mb-0">
                    <CountUp end={(this.state.withdefectspod.length > 0) ? this.state.withdefectspod.length : 0} />
                </span>
            </div>
            <div className="counter" style={{ cursor: "pointer" }} onClick={this.onClickCounter.bind(this, "withoutdefect")}>
                <span>Without Defects:</span>
                <span class="counter mb-0">
                    <CountUp end={(this.state.withoutdefectspod.length > 0) ? this.state.withoutdefectspod.length : 0} />
                </span>
            </div>
        </div>
    </div>
</div>

                                                     
                                                    
                                                  </div> 
                                                </div> 
                                            </div> 
                                        </div>
                                        {/* </div>   */}
                                    
                                        <div className="col cirlce-d cursorPointer"  onClick={this.onClickCounter.bind(this,"4")}>
                                            {/* <span className="f13"><i className="icofont icofont-ui-browser f24 greenfont"></i><br /> Total PODs</span> */}
                                            <div class="o-hidden card">
                                                <div class="bg-default2 b-r-4 card-body mdi">
                                                <div class="media static-top-widget d-flex">
                                                            {/* <div class="align-self-center text-center"> */}
                                                                {/* <i class="icofont icofont-chart-histogram-alt"></i> */}
                                                                {/* <i className="icofont icofont-social-google-map txt-info"></i>
                                                            </div> */}
                                                        <div class="media-body">
                                                        <span class="m-0">POD Approved</span> 
                                                            <h4 class="counter mb-0 f28"><span><CountUp end={(this.state.approvedpods.length > 0)?this.state.approvedpods.length:0}/></span></h4>
                                                          
                                                        </div>  
                                                </div> 
                                            </div> 
                                            </div> 
                                        </div>          
                                   
                                        {/* <div class="o-hidden card">
                                                <div class="bg-warning b-r-4 card-body">
                                                    <div class="media static-top-widget d-flex">
                                                        <div class="align-self-center text-center"> */}
                                                            {/* <i class="icofont icofont-chart-histogram-alt"></i> */}
                                                            {/* <i className="fa fa-tachometer"></i>
                                                        </div> */}
                                                        {/* <div class="media-body">
                                                            <h4 class="counter mb-0 f28"><span>
                                                                <CountUp end={(this.state.overspeeding != '') ? this.state.overspeeding : 0} />
                                                            </span></h4>
                                                            <span class="m-0">Overspeeding</span> */}
                                                            {/* <i class="icofont icofont-chart-histogram-alt icon-bg">

                                                            </i> */}
                                                        {/* </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                        {/* <div className="col cursorPointer" >
                                            <div className="row f20">
                                                <div className="col-sm-12">
                                                    <span style={{pointer:"cursor"}} onClick={this.onClickCounter.bind(this,"2")}>
                                                        <span className="f13"><i className="icofont icofont-clock-time f22 txt-success"></i><br /> POD Submitited</span>
                                                        <h4 className="txt-success f40"><span className="counter"><CountUp end={(this.state.podsubmitdata.length > 0)?this.state.podsubmitdata.length:0}/></span></h4>
                                                    </span>
                                                </div>
                                                <div className="col-sm-6 counter txt-danger" style={{cursor:"pointer",borderLeft:"1px solid #ccc"}} onClick={this.onClickCounter.bind(this,"withdefect")}>
                                                   <div className="f15 txt-danger">
                                                        With Defects : <CountUp end= {(this.state.withdefectspod.length > 0) ? this.state.withdefectspod.length : 0}/>
                                                   </div>
                                                </div>
                                                <div className="col-sm-6" onClick={this.onClickCounter.bind(this,"withoutdefect")}>
                                                    <div className="f15 txt-success">
                                                        Without Defects :  <CountUp end= {(this.state.withoutdefectspod.length > 0) ? this.state.withoutdefectspod.length : 0}/>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                        {/* <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"3")}>
                                            <span className="f13"><i className="icofont icofont-social-google-map f24 txt-info"></i><br /> POD Pending</span>
                                            <h4 className="txt-info f40"><span className="counter"><CountUp end={(this.state.nonpoddata.length > 0)?this.state.nonpoddata.length:0}/></span></h4>
                                            
                                        </div> */}
{/*                                         
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"4")}>
                                            <span className="f13"><i className="icofont icofont-social-google-map f24 txt-info"></i><br /> POD Approved</span>
                                            <h4 className="txt-warning f40"><span className="counter"><CountUp end={(this.state.approvedpods.length > 0)?this.state.approvedpods.length:0}/></span></h4>
                                            
                                        </div> */}
                                       
                                    </div>
                                </div>
                            {/* </div> */}
                        {/* </div> */}
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="card">
                           
                            <div className="card-body pt-15px">
                                <div className="row" style={{textAlign:'right'}}>
                                    <div className="col-sm-12">
                                        <button className="float-right custom-btn white btn-danger" onClick={this.onClickSaveGridState.bind(this)}>
                                            <i className="icofont icofont-save"></i> Save Grid Layout
                                        </button>
                                        <button className="float-right custom-btn white btn-info" style={{marginRight:"10px"}} onClick={this.resetState.bind(this)}>
                                            <i className="icofont icofont-refresh"></i> Reset Default Layout
                                        </button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div id="myGrid" style={{marginTop:"20px", height: "480px",width: "100%"}}  className="ag-theme-balham">
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={columnwithDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.rowData}
                                            enableCharts={true}
                                            enableRangeSelection={true}
                                            autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            onGridReady={this.onGridReady.bind(this)}
                                            onGridState={this.onGridState.bind(this)}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            frameworkComponents={this.state.frameworkComponents}
                                            detailCellRendererParams={this.state.detailCellRendererParams}
                                            stopEditingWhenGridLosesFocus={true}
                                            enableCellChangeFlash={false}
                                            rowClassRules={this.state.rowClassRules}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            masterDetail={true}
                                            rowSelection={this.state.rowSelection}
                                            suppressRowClickSelection={true}
                                            onPaginationChanged={this.resetPaginationSelection}
                                            
                                        />
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"dataLoadpage " +(this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div className="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
                <div className={"slide-r "+(this.state.sliderWithDefectAddTranslate)} >
                    
                    <div className="slide-r-title">
                        <h4>
                            View Items
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className={"dataLoad " +(this.state.loadshow)}>
                            <div style={{position:"absolute", left:"38%"}}>
                                <div class="loader-box">
                                    <div class="loader-box">
                                        <div class="rotate dashed colored"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-lg-12">
                            <div id="myGrid1" style={{marginTop:"20px", height: "90vh",width: "100%"}}  className="ag-theme-balham">
                                <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={columnwithDefsItems}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.sidebarrowData}
                                    enableCharts={true}
                                    enableRangeSelection={true}
                                    autoGroupColumnDef={this.state.autoGroupColumnDef}
                                    onGridReady={this.onGridReady1}
                                    onGridState={this.onGridState1}
                                    statusBar={this.state.statusBar}
                                    sideBar={this.state.sideBar}
                                    paginationPageSize={this.state.paginationPageSize}
                                    pagination={true}
                                    frameworkComponents={this.state.frameworkComponents}
                                    detailCellRendererParams={this.state.detailCellRendererParams}
                                    stopEditingWhenGridLosesFocus={true}
                                    enableCellChangeFlash={false}
                                    rowClassRules={this.state.rowClassRules}
                                    gridOptions={{
                                        context: { componentParent: this }
                                    }}
                                    masterDetail={true}
                                    rowSelection={this.state.rowSelection}
                                    suppressRowClickSelection={true}
                                    onPaginationChanged={this.resetPaginationSelection}
                                    
                                />
                            </div> 
                        </div>
                    </div>
                </div>

                <div className={"slide-r "+(this.state.sliderApprovePOD)} >
                    
                    <div className="slide-r-title">
                        <h4>
                            Approve POD
                            <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>

                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className={"dataLoad " +(this.state.loadshow)}>
                            <div style={{position:"absolute", left:"38%"}}>
                                <div class="loader-box">
                                    <div class="loader-box">
                                        <div class="rotate dashed colored"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-lg-12">
                            <form id="upform" className="theme-form" onSubmit={this.saveItemsFormData}>

                                <div className="form-group mt-20p">
                                    <label className="">Remarks <span style={{color:"#ff0000"}}>*</span></label> 
                                    <textarea name="approve_remarks" id="approve_remarks" value={this.state.approve_remarks} onChange={this.changeHandler} className="form-control" rows="3" required ></textarea>
                                </div>
                                <div className="form-group mt-20p">
                                    <button type="submit" className="btn btn-success" onClick={this.onSubmitApporvePOD.bind(this)}>Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                
                <div className={"slide-r "+(this.state.sliderFeedback)} >
                
                    
                    <div className="slide-r-title">
                        <h4>
                            FeedBack
                            <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className={"dataLoad " +(this.state.loadshow)}>
                            <div style={{position:"absolute", left:"38%"}}>
                                <div class="loader-box">
                                    <div class="loader-box">
                                        <div class="rotate dashed colored"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-lg-12">
                            <form id="upform" className="theme-form">

                                <div className="form-group mt-40p">
                                    <div className="row">
                                        <div className="col-sm-7">
                                            <label className="">Based on delivery time : </label>
                                        </div>                                        
                                        <div className="col-sm-4">
                                            {this.state.rating1}
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="form-group mt-40p">
                                  <div className="row">
                                        <div className="col-sm-7">
                                            <label className="">Comments:</label> 
                                        </div>       
                                        <div className="col-sm-4">
                                            {this.state.comment1}
                                        </div>
                                    </div>    
                                </div>
                                <div className="form-group mt-40p">
                                    <div className="row">
                                        <div className="col-sm-8">
                                            <label className="">Based on vehicle delivery condition: </label>
                                        </div>                                        
                                        <div className="col-sm-4">
                                            {this.state.rating2}
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="form-group">
                                   <div className="row">
                                       <div className="col-sm-7">
                                            <label className="">Comments:</label>
                                        </div>     
                                        <div className="col-sm-4">
                                                {this.state.comment2}
                                        </div>
                                   </div>         
                                </div>
                                
                                <div className="form-group mt-40p">
                                    <div className="row">
                                        <div className="col-sm-7">
                                            <label className="">Overall Experience: </label>
                                        </div>                                        
                                        <div className="col-sm-4">
                                                {this.state.rating3}
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="form-group">
                                   <div className="row">
                                       <div className="col-sm-7">
                                            <label className="">Comments:</label> 
                                        </div>    
                                        <div className="col-sm-5">
                                            {this.state.comment3}
                                        </div>
                                    </div>        
                                </div>
                                
                            </form>
                        </div>
                    </div>
                </div>


            </div>
        );
    }
}


window.onload = function (){
	// $(".datepicker").datepicker({
    //     dateFormat: "dd-mm-yy",
    //     changeMonth: true,
    //     changeYear: true
	// });
	$('.datetimepicker_mask').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i',
        maxDate:0
	});

	$(".styles_modal__gNwvD").css({width:"450px"})
};
document.addEventListener('turbolinks:before-render', () => {
	loadDateTimeScript() 
  });

function loadDateTimeScript(){

	$('.datetimepicker_mask').datetimepicker({
		//mask:'39-19-9999 29:59',
		format:'d-m-Y H:i',
        maxDate:0
	});
	$('.datetimepicker_date').datetimepicker({
		//mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false,
        maxDate:1
	});
	$('.datetimepicker_time').datetimepicker({
		//mask:'39-19-9999',
		format:'H:i',
        step:1,
		datepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}



function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}       
function GetSortDescOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
}       
function GetSortASCOrder(prop) {    
	return function(a, b) {    
		if (a[prop] > b[prop]) {    
			return 1;    
		} else if (a[prop] < b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 

function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600*24));
    var h = Math.floor(seconds % (3600*24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);
    
    var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay;
}
