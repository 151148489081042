
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');
var moment = require('moment');

 
const EditDieselCost = (props) => {
   const handleClick = (e) => {
    e.stopPropagation();
    props.context.componentParent.onClickShowEditForm(props);
   }
    // console.log("props.data.customer_lr_doc ", props.data)
    return (
        <span>
        {
            // (props.data.is_pod_submit != "" && props.data.is_pod_submit != undefined)?
            <span>
                <button type="button" onClick={handleClick} className="label label-warning f12"  style={{padding:"5px 10px",color:"#333","border":"0px"}}>Edit</button>
            </span>
        //   :""
        }
        </span>
    );
};

export default EditDieselCost;
