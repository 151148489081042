
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const Gpsdata = (props) => {
    const handleEventClick = (e) => {
        e.stopPropagation();
        //console.log("Overspeed ",props);
        props.context.componentParent.onShowNoGPSKpi(props);
    };
    
   
            var colr = 'grayfont'
       
    //}
    return (
        <div>
            <button onClick={handleEventClick} className="custom-btn f22 label label-success" 
            title="GPS Available" ><i className={"icofont icofont-social-google-map "+(colr)} style={{fontSize:"1.4rem"}}></i> &nbsp;</button>
            
        </div>
    );
};

export default Gpsdata;
