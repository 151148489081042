import React, { Component } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert';
import {getHyphenDDMMMYYYYHHMM} from '../common/utils';
var redirectURL = require('../redirectURL');
var moment = require('moment');


export default class LevelWiseLSPCostApprove extends Component {
    constructor(props) {
		super(props);
		this.state = {
            show:false,
            basicTitle:"",
            basicType:"default",
            rowData:[],
            loadshow:"show-n",
            overly:"show-n",
            email:""
        }
        this.onLoadGetEmail=this.onLoadGetEmail.bind(this);
    }
    componentDidMount(){
       
    }
    closeAlert = async () => {
        await this.setState({
            show: false
        });
        // await window.location.reload();
    }
    onLoadGetEmail(parameters){
        this.setState({
            loadshow:"show-m"
        })
        
    }


  render() {
    return (
        <div className="container-fluid" style={{marginBottom:"60px"}}>
            <SweetAlert
                show={this.state.show}
                type={this.state.basicType}
                title={this.state.basicTitle}
                onConfirm={this.closeAlert}
            >
            </SweetAlert>
            <div className="row">
                <div className="col-sm-12" style={{position:"fixed",height:"80px", bottom:"0px",left:"0px",textAlign:"center"}}>
                    
                </div>
            </div>          
        </div>    
    )
  }
}
