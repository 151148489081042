/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';

import CSVFileValidator from 'csv-file-validator'
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Select from 'react-select';
import {verifyColumns} from "../common/verifyCSVFileFields";
import Modal from 'react-responsive-modal';
import MapUnmapAction from "./MapUnmapAction"; 
import { getHyphenDDMMMYYYYHHMM } from '../common/utils';
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");

export const validPhone = new RegExp(/^[6789]\d{9}$/);
export default class Devices extends Component {

    constructor(props) {
        super(props);

        this.state = {
			devices:[],
			device:{"value":"","label":"Select Device"},
			truck_no:"",
			sliderTranslate:'',
			show:false,
            basicTitle:"",
            basicType:"default",
            loadshow:"show-n",
			overly:"show-n",
            modules: AllModules,
			defaultColDef: {
				sortable: true,
				filter: true,
				resizable: true,
				pivot: true,
				enableValue: true,
				enableRowGroup: true,
			},
			rowData: [],
			maprowData: null,
			rowSelection: 'multiple',
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			overlayNoRowsTemplate: "",
			frameworkComponents: {
				mapunmapaction:MapUnmapAction,
			},

			animateRows: true,
			debug: true,
			showToolPanel: false,
			uppressAggFuncInHeader: true,

			childconfs: [],
			childrow: [],
			rowModelType: "serverSide",
			paginationPageSize: 500,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
						statusPanel: "agTotalAndFilteredRowCountComponent",
						align: "left"
					},
					{
						statusPanel: "agTotalRowCountComponent",
						align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
						id: "columns",
						labelDefault: "Columns",
						labelKey: "columns",
						iconKey: "columns",
						toolPanel: "agColumnsToolPanel"
					},
					{
						id: "filters",
						labelDefault: "Filters",
						labelKey: "filters",
						iconKey: "filter",
						toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams: {},
			rowClassRules: {
			},
			deviceoptions:[],
			device_model:"",
			vendor:"",
			mobile_no:"",
			device_id:"",
			sliderTranslateAdd:"",
			sliderTranslateBulk:"",
			sliderTranslateBulkDevice:"",
			uploadFileBulk:"",
			uploadFileBulkContent:[],
			uploaddeviceBulk:""
        };
        this.loadDevices = this.loadDevices.bind(this);
		this.changeHandler = this.changeHandler.bind(this);
		this.changeDeviceHandler = this.changeDeviceHandler.bind(this);
		this.changeMobileHandler = this.changeMobileHandler.bind(this);
		this.changeDeviceCSVFileHandler = this.changeDeviceCSVFileHandler.bind(this);
	}
    componentDidMount(){
        // console.log("here check ")
        this.loadDevices();    
    }
    loadDevices(){
        this.setState({
            loadshow:'show-m',
        })
        redirectURL.post("/masters/devices")
        .then((response) => {
            var records = response.data.records;
			// var recordsarr = [];
			// if(records.length > 0)
			// {
			// 	records.map((item) => {
			// 		if(item.truck_no != "")
			// 		{

			// 		}
			// 		else{
			// 			recordsarr.push(item);
			// 		}
			// 	})
			// }
			// console.log("records ", records)
            this.setState({
                rowData:records,
				deviceoptions:records,
                loadshow:"show-n",
				overly:"show-n"
            })
        })
    }
	
	changeHandler = (event) => {
    	//console.log("Select", event.target.value);
    	let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
	}
	changeDeviceHandler(event){
		let name = event.target.name;
        let value = event.target.value;
		// console.log("value.isNaN ", isNaN(value))
		if(isNaN(value) == true)
		{

		}
		else{
			if(value.length <= 16)
			{
				this.setState({[name]:value});
			}
			
		}
        
	}
	changeMobileHandler(event){
		let name = event.target.name;
        let value = event.target.value;
		if(isNaN(value) == true && validPhone.test(value) == false)
		{

		}
		else{
			if(value.length <= 30)
			{
				this.setState({[name]:value});
			}
			
		}
        
	}
	deviceslist(){
		var items=[];
		var devices = this.state.rowData;
		if(devices.length > 0)
		{
			devices.map((item) => {
				if(item.truck_no != "" && item.truck_no != undefined)
				{

				}
				else{
					items.push({
						value:item.device_id,
						label:item.device_id
					});
				}
				
			});
		}
		return items;
	}
	onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderTranslate:"",
			sliderTranslateAdd:'',
			overly:'show-n',
			loadshow:'show-n'
		});
	}
	
	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderTranslate:'',
			sliderTranslateAdd:'',
			sliderTranslateBulk:"",
			sliderTranslateBulkDevice:""
		});

	}

    onGridReady = params => {
		this.gridApi = params.api;
		//console.log("Grid is: ", params);
		this.gridColumnApi = params.columnApi;
    };
	

	onGridState = () =>{
		//console.log(this.gridApi);
	   
		/*Get  Current Columns State and Store in this.colState */
		  this.colState = this.gridColumnApi.getColumnState();

		/*Get Current RowGroup Columns State and Store in this.rowGroupState */
		  this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		/*Get Current Pivot Columns State and Store in this.pivotColumns, 
		 * User should enable the Pivot mode.*/
		  this.pivotColumns = this.gridColumnApi.getPivotColumns();
				   
		/*Get Current Filter State and Store in window.filterState */
		  window.filterState = this.gridApi.getFilterModel(); 
		  this.gridApi.setFilterModel(window.filterState);
		   
		 
		  
	}
	onClickShowForm(){
		this.setState({
			sliderTranslate:"slider-translate",
			loadshow:'show-n',
			overly:'show-m'
		});
	}
	onClickShwAdd(){
		
		this.setState({
			sliderTranslateAdd:"slider-translate",
			loadshow:'show-n',
			overly:'show-m'
		});
	}
	formAssignDevice(event){
		event.preventDefault();

		var device = this.state.device;
		// console.log("device",device)
		try{
			if(this.state.truck_no != "" && this.state.truck_no != undefined)
			{
				var truck_no = this.state.truck_no.toString();
			}
			else{
				var truck_no = "";
			}
		}
		catch(e){
			
		}
		// var truck_no = this.state.truck_no;
		if(device.value != "" && truck_no != "")
		{
			this.setState({
				loadshow:'show-m'
			})
			var parameters = {
				device_id:device.value,
				truck_no:truck_no,
				email:localStorage.getItem("email"),
				username:localStorage.getItem("username")
			}
			// console.log("parameters",parameters)
			redirectURL.post("/masters/assignDevice",parameters)
			.then((response) => {
				
				if(response.data.status == "success")
				{
					this.setState({
						show:true,
						basicTitle:"Successfully assign device",
						basicType:"success",
						truck_no:"",
						device:{"value":"","label":"Select Device"},
						loadshow:'show-n',
						overly:"show-n",
						sliderTranslate:''
					});
					this.loadDevices();
				}
				else if(response.data.status == "existsdata"){
					this.setState({
						show:true,
						basicTitle:"Already device or truck has assigned",
						basicType:"info"
					});
					
				}
				else{
					this.setState({
						show:true,
						basicTitle:"Failed to assign device",
						basicType:"danger",
						truck_no:"",
						device:{"value":"","label":"Select Device"}
					});
					
				}
			})
		}
		else{
			
			this.setState({
				show:true,
				basicTitle:"Fields can not be empty",
				basicType:"danger"
			});
		}
	}
	
	formAddDevice(event){
		event.preventDefault();

		var device_id = this.state.device_id;
		var mobile_no = this.state.mobile_no;
		// var vendor = this.state.vendor;
		// var device_model = this.state.device_model;
		if(device_id != "" && mobile_no != "")
		{
			this.setState({
				loadshow:'show-m'
			})
			var parameters = {
				device_id:device_id,
				mobile_no:mobile_no,
				vendor:"",
				device_model:""
			}
			redirectURL.post("/masters/addDevice",parameters)
			.then((response) => {
				if(response.data.status == "success")
				{
					this.setState({
						show:true,
						basicTitle:"Successfully add device",
						basicType:"success",
						device_model:"",
						vendor:"",
						mobile_no:"",
						device_id:"",
						loadshow:'show-n',
						overly:"show-n",
						sliderTranslateAdd:''
					});
					this.loadDevices();
				}
				else if(response.data.status == "existsdata"){
					this.setState({
						show:true,
						basicTitle:"Already device added",
						basicType:"info"
					});
					
				}
				else{
					this.setState({
						show:true,
						basicTitle:"Failed to add device",
						basicType:"danger",
						device_model:"",
						vendor:"",
						mobile_no:"",
						device_id:""
					});
					
				}
			})
		}
		else{
			
			this.setState({
				show:true,
				basicTitle:"Fields can not be empty",
				basicType:"danger"
			});
		}
	}

	
	
	onChangeDevice(device){
		this.setState(
			{ device },
			() => console.log(`Device Option selected:`, this.state.device)
		  );

	}
	
	closeAlert = async() => {
        await this.setState({
            show: false
        });
	}
	unMapDevice(devicedata)
	{
	
		// console.log("devicedata ",devicedata)
		var cnf = window.confirm("Are you sure you want to unmap device?")
		if(cnf === true)
		{
			
			var device = devicedata.device_id;
			var truck_no = devicedata.truck_no;
			if(device != "" && truck_no != "")
			{
				this.setState({
					loadshow:'show-m'
				})
				var parameters = {
					device_id:device,
					truck_no:truck_no,
					email:localStorage.getItem("email"),
					username:localStorage.getItem("username"),
				}
				redirectURL.post("/masters/unassignDevice",parameters)
				.then((response) => {
					if(response.data.status == "success")
					{
						this.setState({
							show:true,
							basicTitle:"Successfully unassign device",
							basicType:"success",
							truck_no:"",
							device:{"value":"","label":"Select Device"},
							loadshow:'show-n',
							overly:"show-n",
							sliderTranslate:''
						});
						this.loadDevices();
						setTimeout(() => {
							window.location.reload();
						},3000)
					}
					else if(response.data.status == "existsdata"){
						this.setState({
							show:true,
							basicTitle:"Device or Truck are not assigned",
							basicType:"info"
						});
						
					}
					else{
						this.setState({
							show:true,
							basicTitle:"Failed to unassign device",
							basicType:"danger",
							truck_no:"",
							device:{"value":"","label":"Select Device"}
						});
						
					}
				})
			}
			else{
				this.setState({
					show:true,
					basicTitle:"Fields can not be empty",
					basicType:"danger"
				});
			}
		}
		else{

		}
		
	}
	onCellUpdateData(rownode){
		console.log("rownode ", rownode)
		var params = {
			device_id:rownode.data.device_id,
			gps_device_status:rownode.data.gps_device_status,
			gps_device_remarks:rownode.data.gps_device_remarks
		}
		redirectURL.post("/dashboard/updateDeviceGPSStatus",params)
		.then((response) => {
			if(response.data.status === "successs")
			{
				
			}
		})
	}
	changeCSVFileHandler(e){
		var uploadFileBulk = e.target.files[0];

		const config = {
			headers: [
				{
					name: 'Truck No',
					inputName: 'truck_no',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'Device No',
					inputName: 'device_id',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				

				// {
				// 	name: 'MOB.',
				// 	inputName: 'mobile_no',
				// 	required: true,
				// 	requiredError: function (headerName, rowNumber, columnNumber) {
				// 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
				// 	}
				// },
				// {
				// 	name: 'SIM NO',
				// 	inputName: 'sim_no',
				// 	required: true,
				// 	requiredError: function (headerName, rowNumber, columnNumber) {
				// 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
				// 	}
				// }
			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
			.then((csvData) => {
				console.log("csvData",csvData)
				this.setState({
					uploadFileBulkContent: csvData.data
				});
			})
			.catch(err => { })
		// console.log("e.target.files[0]",e.target.result);
		var contentList = []

		var out = new Promise(function (reject, resolve) {
			var reader = new FileReader();
			reader.onload = async function (e) {
				var contents = await e.target.result;
				resolve(contents);
			};
			var tt = reader.readAsText(e.target.files[0]);
		});
		// console.log("e.target.files[0].type ", e.target.files[0]);

		if (e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel') {
		}
		else {
			e.target.value = null;
			this.setState({
				uploadFileBulk: '',
				file: "",
				show: true,
				basicType: 'danger',
				basicTitle: 'Please upload file having extensions .csv only.',
			});
		}

	}
	changeDeviceCSVFileHandler(e){
		var uploadFileBulk = e.target.files[0];
		const config = {
			headers: [
				{
					name: 'Device No',
					inputName: 'device_id',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'Mobile No',
					inputName: 'mobile_no',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				
			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
			.then((csvData) => {
				console.log("csvData",csvData)
				this.setState({
					uploaddeviceBulk: csvData.data
				});
			})
			.catch(err => { })
		// console.log("e.target.files[0]",e.target.result);
		var contentList = []

		var out = new Promise(function (reject, resolve) {
			var reader = new FileReader();
			reader.onload = async function (e) {
				var contents = await e.target.result;
				resolve(contents);
			};
			var tt = reader.readAsText(e.target.files[0]);
		});
		// console.log("e.target.files[0].type ", e.target.files[0]);

		if (e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel') {
		}
		else {
			e.target.value = null;
			this.setState({
				uploaddeviceBulk: '',
				file: "",
				show: true,
				basicType: 'danger',
				basicTitle: 'Please upload file having extensions .csv only.',
			});
		}

	}
	
	onClickShowBulkDeviceForm(){
		
		this.setState({
			sliderTranslateBulkDevice:"slider-translate",
			loadshow:'show-n',
			overly:'show-m'
		});
	}
	onClickShowBulkForm(){
		
		this.setState({
			sliderTranslateBulk:"slider-translate",
			loadshow:'show-n',
			overly:'show-m'
		});
	}
	formBulkUpload_(event) {
		event.preventDefault();
	  
		var devices = this.state.uploadFileBulkContent;
		// console.log("devices ", devices);
	
		var reqcols = ["truck_no","device_id"]
		var nonEmptyParams=["truck_no","device_id"]
		var nonNumericParams = []
		var nonDateParams = []
		var verify = verifyColumns.checkValidations(devices,reqcols,nonEmptyParams,nonNumericParams,nonDateParams);
		// console.log("verify",verify);
		if(verify != "")
		{
			this.setState({
				show: true,
				basicTitle: verify,
				basicType: "danger"
			});
		}
		else{
			this.setState({
				loadshow:'show-m'
			})
		  redirectURL.post("/masters/uploadassignDevicesmapping", devices)
			.then((response) => {
			  console.log("response", response)
			  console.log("response.data[0].status ", response.data.status)
			try{
				if (response.data.status === "fail") {
					this.setState({
					  show: true,
					  basicTitle: "Failed to assign device",
					  basicType: "danger",
					  truck_no: "",
					  device: {"value": "", "label": "Select Device"},
					  loadshow: 'show-n',
					  overly: "show-n",
					  sliderTranslate: ''
					});
					// this.loadDevices();
				  } else if (response.data.status === "existsdata") {
					this.setState({
					  show: true,
					  basicTitle: "Already device or truck has assigned "+ response.data.devices,
					  basicType: "info",
					  loadshow: 'show-n',
					  overly: "show-n",
					  sliderTranslate: ''
					});
				  } else {
					this.setState({
					  show: true,
					  basicTitle: "Successfully assign device",
					  basicType: "success",
					  truck_no: "",
					  device: {"value": "", "label": "Select Device"}
					});
					
					this.loadDevices();
				  }
		  
			}
			catch(e){

			}
			  
			})
			.catch((error) => {
			  console.error(" error:", error);
			  
			});
		 } 
		
	  }


	  formBulkUpload(event) {
		// Prevent the form from submitting
		event.preventDefault();
	   
		// Get the list of devices from the state
		const devices = this.state.uploadFileBulkContent;
	   
		// Define the required and non-empty columns
		const requiredColumns = ["truck_no", "device_id"];
		const nonEmptyColumns = ["truck_no", "device_id"];
	   
		// Define the list of non-numeric and non-date columns
		const nonNumericColumns = [];
		const nonDateColumns = [];
	   
		// Check the validity of the columns
		const verificationResult = verifyColumns.checkValidations(
		  devices,
		  requiredColumns,
		  nonEmptyColumns,
		  nonNumericColumns,
		  nonDateColumns
		);
	   
		// Check if there are any errors
		if (verificationResult !== "") {
		  // Show an error message
		  this.setState({
			show: true,
			basicTitle: verificationResult,
			basicType: "danger",
		  });
		} else {
			this.setState({
				loadshow:'show-m'
			})
		  // Post the list of devices to the backend
		  redirectURL.post("/masters/uploadassignDevicesmapping", devices)
			.then((response) => {
			//   console.log("response", response);
			  if (response.data.status === "fail") {
				// Show an error message
				this.setState({
				  show: true,
				  basicTitle: "Failed to assign device",
				  basicType: "danger",
				  truck_no: "",
				  device: { value: "", label: "Select Device" },
				  loadshow: 'show-n',
				  overly: "show-n",
				  sliderTranslate: ''
				});
				// this.loadDevices();
			  } else if (response.data.status === "existsdata") {
				// Show an info message
				this.setState({
				  show: true,
				  basicTitle: "Already device has assigned. "+ response.data.devices,
				  basicType: "info",
				});
			  } else {
				// Show a success message
				this.setState({
				  show: true,
				  basicTitle: "Successfully assign device",
				  basicType: "success",
				  truck_no: "",
				  device: { value: "", label: "Select Device" },
				});
			  }
	   
			  // Load the devices again
			  this.loadDevices();
			})
			.catch((error) => {
			  console.error(" error:", error);
			});
		}
	  }

	  formBulkUploadDevices(event) {
		event.preventDefault();
	  
		var devices = this.state.uploaddeviceBulk;
		// console.log("Uploaded Devices ", devices)
		
		var devicelist = this.state.rowData;
		// console.log(JSON.stringify(devicelist),"devicelist")
		var existsFlag = 0;
		if(devicelist.length > 0)
		{
			devicelist.map((e)=>{
				
				if(devices.filter(k => k.device_id == e.device_id).length > 0)
				{
					existsFlag=1;
				}
			})
			
		}

		var reqcols = ["device_id","mobile_no"]
		var nonEmptyParams=["device_id","mobile_no"]
		var nonNumericParams = []
		var nonDateParams = []
		var verify = verifyColumns.checkValidations(devices,reqcols,nonEmptyParams,nonNumericParams,nonDateParams);
		// console.log("verify",verify);
		if(verify != "")
		{
			this.setState({
				show: true,
				basicTitle: verify,
				basicType: "danger"
			});
		}
		else{
			// console.log("existsFlag ", existsFlag);
			if(existsFlag==0)
			{
				this.setState({
					loadshow:'show-m'
				})
				redirectURL.post("/masters/saveBulkDeviceMobiles", devices)
				.then((response) => {
				//   console.log("response", response)
					document.getElementById("deviceBulkForm").reset();
					if (response.data.status === "fail") {
					this.setState({
						show: true,
						basicTitle: "Failed to upload device",
						basicType: "danger",
						truck_no: "",
						device: {"value": "", "label": "Select Device"},
						loadshow: 'show-n',
						overly: "show-n",
						sliderTranslateBulkDevice: '',
						uploaddeviceBulk:"",
					});
					// this.loadDevices();
					} else if (response.data.status === "existsdata") {
					this.setState({
						show: true,
						basicTitle: "Already device or truck has exists",
						basicType: "info"
					});
					} else {
					this.setState({
						show: true,
						basicTitle: "Successfully upload device",
						basicType: "success",
						truck_no: "",
						sliderTranslateBulkDevice:"",
						uploaddeviceBulk:"",
						device: {"value": "", "label": "Select Device"}
					});
					}
			
					this.loadDevices();
				})
				.catch((error) => {
					console.error(" error:", error);
					
				});
			}
			else{
				this.setState({
					show: true,
					basicTitle: "Device(s) already exists",
					basicType: "info"
				});
			}
		}
		
	  }
	  
	// formBulkUpload(event){
	// 	event.preventDefault();

	// 	var devices = this.state.uploadFileBulkContent;
	// 	console.log("devices ", devices)

	// 	// var params={
	// 	// 	device_id:devices.device_id,

	// 	// }
	// 	// console.log("params",params)
	// 	redirectURL.post("/masters/uploadassignDevicesmapping",devices)
	// 	.then((response) => {
	// 		console.log("response",response)
	// 		if(response.data.status == "fail")
	// 		{
	// 			this.setState({
	// 				show:true,
	// 				basicTitle:"Failed to assign device",
	// 				basicType:"danger",
	// 				truck_no:"",
	// 				device:"",
	// 				loadshow:'show-n',
	// 				overly:"show-n",
	// 				sliderTranslate:''
	// 			});
	// 			// this.loadDevices();
	// 		}
	// 		else if(response.data.status == "existsdata"){
	// 			this.setState({
	// 				show:true,
	// 				basicTitle:"Already device or truck has assigned",
	// 				basicType:"info"
	// 			});
				
	// 		}
	// 		else{
	// 			this.setState({
	// 				show:true,
	// 				basicTitle:"Successfully assign device",
	// 				basicType:"success",
	// 				truck_no:"",
	// 				device:{"value":"","label":"Select Device"}
	// 			});
				
	// 		}
			
	// 		this.loadDevices();
	// 	})
	// }
    render(){
		var columnWithDefs = [
			{
				headerName:"Device ID",
				field:"device_id",
			},
			{
				headerName:"Mobile Number",
				field:"mobile_no",
			},
			// {
			// 	headerName:"Vendor",
			// 	field:"vendor",
			// },
			// {
			// 	headerName:"Device Model",
			// 	field:"device_model",
			// },
			{
				headerName:"Vin No",
				field:"truck_no",
			},
			
			{
				headerName:"Device Status",
				width:200,
				field:"gps_device_status",
				editable:true,
				cellEditor: 'agSelectCellEditor',
				cellEditorParams:{
					values:["","GPS deposited at dealership","Pick up booked","Return in transit","GPS received at Plant","Waiting at dealership","Transshipment","Misplaced/Lost","GPS Returned to Dealer"]
				},
				// valueGetter:function(params){
				// 	try {
				// 		if(params.data.gps_device_status !== "" && params.data.gps_device_status !== undefined)
				// 		{
				// 			return params.data.gps_device_status;
				// 		}
				// 		else{
				// 			return "";
				// 		}
				// 	} catch (error) {
						
				// 	}
				// }
			},
			{
				headerName:"Device Remarks",
				width:200,
				field:"gps_device_remarks",
				editable:true
			},
			{
				headerName:"Consignment",
				width:150,
				field:"consignmentExist"
				
			},
			
			{
				headerName:"Device Registered On",
				width:150,
				field:"device_registered_on",
				valueGetter:function(params){
					try{
						// console.log("params.data ", params.data.registereddevices)
						if(params.data.registereddevices != "" && params.data.registereddevices != undefined)
						{
							if(params.data.registereddevices[0].device_registered_on != "" && params.data.registereddevices[0].device_registered_on != undefined)
							{
								return getHyphenDDMMMYYYYHHMM(params.data.registereddevices[0].device_registered_on);
							}
							else{
								return "";
							}

						} 
					}
					catch(e){}
				}
				
			},
			
			{
				headerName:"Action",
				field:"device_id",
				cellRendererSelector:function(params){

					var rendComponent = {
						component: 'mapunmapaction'
					};
					return rendComponent

				},
			}
		]
        return (

    		<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
			<div className="mb-15p fbold f20">  
				<h5>
					<i className="icon-mobile cus-i"></i> <span>Devices </span>
					
					<button className="btn btn-info pull-right cs-btn" type="button" onClick={this.onClickShowBulkForm.bind(this)}> Bulk Upload</button>
					<a href={this.state.currentAddGeoFenceLink} className="btn btn-success pull-right cs-btn" onClick={this.onClickShwAdd.bind(this)}> Add Device</a>
					<a href={this.state.currentAddGeoFenceLink} className="btn btn-info pull-right cs-btn" onClick={this.onClickShowBulkDeviceForm.bind(this)}> Bulk Upload Device Mobile</a>
					<a href={this.state.currentAddGeoFenceLink} className="btn btn-warning pull-right cs-btn" onClick={this.onClickShowForm.bind(this)}> Assign Device</a>
				</h5>
			</div>	
            	<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
				   			<div className="card-body">
							   <div id="myGrid" style={{width:"100%",height:"70vh"}} className={"ag-theme-balham"}>    
								<AgGridReact
									modules={this.state.modules}
									columnDefs={columnWithDefs}
									defaultColDef={this.state.defaultColDef}
									rowData={this.state.rowData}
									enableCharts={false}
									// autoGroupColumnDef={this.state.autoGroupColumnDef}
									onGridReady={this.onGridReady}
									onGridState={this.onGridState}
									frameworkComponents={this.state.frameworkComponents}
									statusBar={this.state.statusBar}
									sideBar={this.state.sideBar}
									stopEditingWhenGridLosesFocus= {true}
									paginationPageSize={this.state.paginationPageSize}
									pagination={true}
									gridOptions={{
										context: { componentParent: this },
									}}
									// components={this.state.components}
									enableRangeSelection= {true}
									// onCellClicked={this.onCellClickedInTransit.bind(this)}
									onCellEditingStopped={this.onCellUpdateData.bind(this)}
									/>
								</div>
                            </div>
				   		</div>
				   	</div>
				 </div>
                <div className={"dataLoadpage " +(this.state.loadshow)}></div>
                <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>

				<div className={"slide-r "+(this.state.sliderTranslate)} style={{overflow:"auto"}}>
					<div className="slide-r-title">
						<h4>
							Assign Device 
							<span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>

						</h4>
					</div>
					<div className="slide-r-body" style={{position:"relative"}}>
						<div className="row col-xl-12 col-lg-12">
							<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formAssignDevice.bind(this)}>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Select Device <span style={{color:"#FF0000"}}>*</span></label>
									<Select
									placeholder={"Select Device"}
									closeMenuOnSelect={true}
									onChange={this.onChangeDevice.bind(this)}
									className={"col-xl-12 col-lg-12 border-radius-0"}
									style={{borderRadius:"0px"}}
									value={this.state.device}
									options={this.deviceslist()} />
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Truck No <span style={{color:"#FF0000"}}>*</span></label>
									<input type="text" placeholder="Truck No" id="truck_no" size="10" value={this.state.truck_no} name="truck_no" className="form-control" onChange={this.changeHandler} required />
								</div>
								
								<div className="col-xl-12 col-lg-12 form-group">
									<button type="submit" className="btn btn-success">Submit</button>
								</div>
							</form>
						</div>
					</div>
				</div>

				<div className={"slide-r "+(this.state.sliderTranslateAdd)} style={{overflow:"auto"}}>
					<div className="slide-r-title">
						<h4>
							Add Device 
							<span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>

						</h4>
					</div>
					<div className="slide-r-body" style={{position:"relative"}}>
						<div className="row col-xl-12 col-lg-12">
							<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formAddDevice.bind(this)}>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Device ID <span style={{color:"#FF0000"}}>*</span></label>
									<input type="text" placeholder="Device ID" id="device_id" size="16" value={this.state.device_id} name="device_id" className="form-control" onChange={this.changeDeviceHandler} required />
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Mobile No <span style={{color:"#FF0000"}}>*</span></label>
									<input type="text" placeholder="Mobile No" id="mobile_no" size="30" value={this.state.mobile_no} name="mobile_no" className="form-control" onChange={this.changeMobileHandler} required />
								</div>
								{/* <div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Vendor <span style={{color:"#FF0000"}}>*</span></label>
									<input type="text" placeholder="Vendor" id="vendor" name="vendor" value={this.state.vendor} className="form-control" onChange={this.changeHandler} required />
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Device Model <span style={{color:"#FF0000"}}>*</span></label>
									<input type="text" placeholder="Device Model" id="device_model" value={this.state.device_model} name="device_model" className="form-control" onChange={this.changeHandler} required />
								</div> */}
								
								<div className="col-xl-12 col-lg-12 form-group">
									<button type="submit" className="btn btn-success">Submit</button>
								</div>
							</form>
						</div>
					</div>
				</div>

				
				<div className={"slide-r "+(this.state.sliderTranslateBulk)} style={{overflow:"auto"}}>
					<div className="slide-r-title">
						<h4>
							Bulk Upload
							<span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>

						</h4>
					</div>
					<div className="slide-r-body" style={{position:"relative"}}>
						<div className="row col-xl-12 col-lg-12">
							<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formBulkUpload.bind(this)}>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Upload CSV <span style={{color:"#FF0000"}}>*</span></label>
									<input type="file" name="uploadBulk" className="form-control" onChange={this.changeCSVFileHandler.bind(this)} required />
								</div>
								
								<div className="col-xl-12 col-lg-12 form-group">
									<button type="submit" className="btn btn-success">Submit</button>
								</div>
								
								<div className="col-xl-12 col-lg-12 form-group">
									<a href={require("../../assets/json/bulk_upload_device.csv")} target="_blank" className="btn btn-danger">Download Sample Template</a>
								</div>
							</form>
						</div>
					</div>
				</div>

				
				<div className={"slide-r "+(this.state.sliderTranslateBulkDevice)} style={{overflow:"auto"}}>
					<div className="slide-r-title">
						<h4>
							Bulk Upload Devices
							<span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>

						</h4>
					</div>
					<div className="slide-r-body" style={{position:"relative"}}>
						<div className="row col-xl-12 col-lg-12">
							<form method="POST" id="deviceBulkForm" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formBulkUploadDevices.bind(this)}>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Upload CSV <span style={{color:"#FF0000"}}>*</span></label>
									<input type="file" name="uploaddeviceBulk" className="form-control" onChange={this.changeDeviceCSVFileHandler.bind(this)} required />
								</div>
								
								<div className="col-xl-12 col-lg-12 form-group">
									<button type="submit" className="btn btn-success">Submit</button>
								</div>
								
								<div className="col-xl-12 col-lg-12 form-group">
									<a href={require("../../assets/json/bulk_upload_device_mobile.csv")} target="_blank" className="btn btn-danger">Download Sample Template</a>
								</div>
							</form>
						</div>
					</div>
				</div>

            </div>

        );
    }
}
