
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');
var moment = require('moment');

 
const CustomerViewItems = (props) => {
    const handleClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onCLickLoadInvoicItems(props);
    };
    // console.log("props.data.customer_lr_doc ", props.data)
    return (
        <span>
        {/* {(props.data.is_pod_submit == 1 || props.data.is_pod_submit == "1")? */}
            <a href="javascript:;" className="f20" onClick={handleClick}><i className="fa fa-sitemap"></i></a>
        {/*  :<span></span>} */}
        </span>
    );
};

export default CustomerViewItems;
