import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import redirectURL from '../redirectURL';
export default class AutomailStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showAlert: false,
            alertTitle: '',
            alertType: "default",
            actionText: "",
        };
    }
    componentDidMount() {
        this.approveHoldRequest();
        setTimeout(() => {
            window.close()
        }, 3000)
    }
    approveHoldRequest = async () => {
        try {
            const { hold_id, req_status, auto_ext_token } = this.props.match.params;
            console.log(this.props.match.params)
            if (hold_id) {
                const params = { hold_id: hold_id, req_status, auto_ext_token, performed_by: localStorage.getItem("email") || "" };
                const response = await redirectURL.post("/consignments/atuomailerresponse", params);
                if (response.data.status === "success") {
                    let defaultMsg = req_status ? "Extension Approved" : "Hold Released"
                    this.setState({
                        showAlert: true,
                        alertTitle: response.data.message,
                        alertType: "success",
                        actionText: response.data.message || defaultMsg
                    });
                } else {
                    let defaultMsg = req_status ? "Extension Approval Failed" : "Hold Release Failed"
                    this.setState({
                        showAlert: true,
                        alertTitle: response.data.message,
                        alertType: "danger",
                        actionText: response.data.message || defaultMsg
                    });
                }
            }
        } catch (e) {
            console.error("Error while approving hold request:", e);
            this.setState({
                showAlert: true,
                alertTitle: "An error occurred while processing the request.",
                alertType: "danger",
            });
        }
    };
    closeAlert = () => {
        this.setState({ showAlert: false });
    };
    render() {
        return (
            <div className="container-fluid">
                <SweetAlert
                    show={this.state.showAlert}
                    type={this.state.alertType}
                    title={this.state.alertTitle}
                    onConfirm={this.closeAlert}
                />
                <div>
                    <h1>{this.state.actionText}</h1>
                </div>
            </div>
        );
    }
}