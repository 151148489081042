import React, { Component } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import CommentActions from '../layouts/commentsComponent';
import SegmentAction from "../layouts/segmentAction";
import KpiActions from '../manage/kpiActionsComponent';
import KpiDeviationActions from '../manage/kpiDeviationActionsComponent';
import KpiTransitDelayActions from '../manage/kpiTransitDelayActions';
import Nooverspeed from '../manage/nooverspeedComponent.js';
import Notransit from '../manage/notransitComponent.js';
import RoutemapenrouteAction from '../manage/routemapenroute';
import KpiNightDriveActions from '../manage/kpiNightDriveActionsComponent';
import Nonightdrive from '../manage/nonightdriveComponent.js';
import KpiEnrouteStoppagesActions from '../manage/kpiEnrouteStoppagesActionsComponent';
import kpiDayWiseDistanceActions from '../manage/kpiDayWiseDistanceActionsComponent'
import KpiLoadingDelayActions from '../manage/kpiLoadingDelayActions';
import KpiUnLoadingDelayActions from '../manage/kpiUnLoadingDelayActions';
import Noenroutes from '../manage/noenroutesComponent.js';
import Gpsdata from '../manage/gpsdataaction.js';
import NoGps from '../manage/nogpsActionComponent.js';
import NoGpsLifetime from '../manage/nogpslifetimeActionComponent.js';
import Consignmentforceclose from '../manage/consignmentforceclose.js';
import Select from 'react-select';
import CustomLoadingCellRenderer from "../layouts/customLoadingCellRenderer";
import ChangeMatrixCosts from "./changematrixconsts";
import ViewMatrixCosts from "./viewMatrixCosts";
import tollImg from '../../assets/icons/tolls-image.png';
import CountUp from 'react-countup';
import {
	getDDMMYYYY,
	getDDMMYYYYHHMMSS,
	getYYYYMMDD,
	getYYYYMMDDHHMMSS,
	getHyphenYYYYMMDDHHMMSS,
	getHyphenDDMMMYYYYHHMM,
	getHyphenYYYYMMDD,
	getHyphenDDMMYYYYHHMMSS,
	getHyphenDDMMYYYY,
	getDDMMMYYYYHHMMDefault
} from '../common/utils';
import 'react-vertical-timeline-component/style.min.css';
import DrawMap from '../common/drawmap';
import ConsignmentDrawMap from '../common/consignmentdrawmap';
import ComponentIndex from '../common/appendComponents';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import ProbableAction from "../layouts/probableDataComponent";
import ConsignmentImportsNoEventsAction from '../manage/consignmentimportsnoEventAction';

$.datetimepicker.setLocale('en');
var infoBox = require('../common/google-infowindow');

var redirectURL = require('../redirectURL');
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");

const decipher = salt => {
	const textToChars = text => text.split('').map(c => c.charCodeAt(0));
	const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
	return encoded => encoded.match(/.{1,2}/g)
		.map(hex => parseInt(hex, 16))
		.map(applySaltToChar)
		.map(charCode => String.fromCharCode(charCode))
		.join('');
}
var decode = decipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
const cipher = (salt) => {
	const textToChars = text => text.split('').map(c => c.charCodeAt(0));
	const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
	const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
	try {
		return text => text.split('')
			.map(textToChars)
			.map(applySaltToChar)
			.map(byteHex)
			.join('');
	}
	catch (e) {

	}

}
var encode = cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

export default class VendorPaymentReconcilliation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
			basicTitle: "",
			basicType: "default",
			rowData: [],
			rownode: [],
			loadshow: "show-n",
			overly: "show-n",
			plants: [],
			flCity: { value: "", label: "All" },
			flState: { value: "", label: "All" },
			flCluster: { value: "", label: "All" },
			flZone: { value: "", label: "All" },
			flDealer: { value: "", label: "All" },
			consigner: [{ value: "all", label: "All" }],
			startDate: "",
			endDate: "",
			date_type: { value: "all", label: "All" },
			filterCities: [],
			filterStates: [],
			filterDealers: [],
			filterClusters: [],
			filterZones: [],
			filterTransporters: [],
			flTransporter: { value: "", label: "All" },
			matrixData: [],

			defaultColDef: {
				sortable: true,
				filter: true,
				resizable: true,
				pivot: true,
				enableValue: true,
				enableRowGroup: true,
			},
			maprowData: null,
			rowSelection: 'multiple',
			rowHeight: 32,
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			overlayNoRowsTemplate: "",
			frameworkComponents: {
				consignmentActions: ConsignmentActions,
				commentActions: CommentActions,
				SegmentAction: SegmentAction,
				kpiActions: KpiActions,
				kpiDeviationActions: KpiDeviationActions,
				kpiTransitDelayActions: KpiTransitDelayActions,
				nooverspeed: Nooverspeed,
				notransit: Notransit,
				kpiNightDriveActions: KpiNightDriveActions,
				nonightdrive: Nonightdrive,
				kpiEnrouteStoppagesActions: KpiEnrouteStoppagesActions,
				kpiDayWiseDistanceActions: kpiDayWiseDistanceActions,
				noenroutes: Noenroutes,
				kpiLoadingDelayActions: KpiLoadingDelayActions,
				kpiUnLoadingDelayActions: KpiUnLoadingDelayActions,
				gps: Gpsdata,
				nogps: NoGps,
				noGpsLifetime: NoGpsLifetime,
				RoutemapenrouteAction: RoutemapenrouteAction,
				customLoadingCellRenderer: CustomLoadingCellRenderer,
				consignmentforceclose: Consignmentforceclose,
				ChangeMatrixCosts: ChangeMatrixCosts,
				ViewMatrixCosts: ViewMatrixCosts
			},

			animateRows: true,
			debug: true,
			showToolPanel: false,
			uppressAggFuncInHeader: true,

			childconfs: [],
			childrow: [],
			rowModelType: "serverSide",
			paginationPageSize: 500,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
						statusPanel: "agTotalAndFilteredRowCountComponent",
						align: "left"
					},
					{
						statusPanel: "agTotalRowCountComponent",
						align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
						id: "columns",
						labelDefault: "Columns",
						labelKey: "columns",
						iconKey: "columns",
						toolPanel: "agColumnsToolPanel"
					},
					{
						id: "filters",
						labelDefault: "Filters",
						labelKey: "filters",
						iconKey: "filter",
						toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams: {},
			rowClassRules: {
				//"highlitRow": "data.transit_delay == 1",
				//"green":"data.status >= 3"

			},
			consigner: [{ "value": 'all', "label": 'All' }],
			originalData: [],
			isCounterDisplay: 1,
			screenpage: '',
			pagetitle: 'Vendor Payment Reconcilliation',
			sliderTranslatesidebar: '',
			tolls: [],
			excelStyles: [
				{
					id: "dateFormat",
					dataType: 'dateTime',
					numberFormat: { format: "yyyy-mm-dd" }
				}
			],
			getRowHeight: function (params) {

			},
			defaultsdate: '',
			defaultedate: '',
			flStateArray: [],
			flCityArray: [],
			deptcode: "SNDG",
			currentDateDiesel: 0,
			last_update_matrix: '',
			sliderTranslateMatrix: '',
			matrixFile: "",
			changeMatrixRow: [],
			lsp_diesel_cost: '',
			lsp_driver_cost: '',
			lsp_road_cost: '',
			lsp_toll_cost: '',
			sliderTranslateChangeMatrix: '',
			sliderTranslateViewMatrix: '',
			viewMartixDocs: '',
			viewMatrixRowData: [],
			approveLevels: [],
			total_diesel_cost: 0,
			total_driver_cost: 0,
			total_road_cost: 0,
			total_toll_cost: 0,
			total_transportation_cost: 0,
			formulaSB: '',
			allCities:'',
			allStates:'',
			allDealers:''
		}
		this.uploadMatrixFile = this.uploadMatrixFile.bind(this);
		this.changeXLSXFileHandler = this.changeXLSXFileHandler.bind(this);
	}
	componentDidMount() {
		this.filtersData();
		var edate = moment.parseZone().format('YYYY-MM-DD');
		var sdate = moment.parseZone().subtract(1, 'year').format('YYYY-MM-DD');
		var urlpath = '/consignments/vendorreconcilliationdata';

		var loguser = decode(localStorage.getItem("m"));
		// console.log("LOGUSER ", JSON.parse(loguser));
		var loginplant = "";
		try {
			var luser = JSON.parse(loguser);
			loginplant = luser.is_plant_code;
			// console.log("logplant", loginplant)
			if (loginplant !== "" && loginplant !== undefined) {
				this.setState({
					loginplant: loginplant,
					consigner: [{ "value": luser.is_plant_code, "label": luser.is_plant_name }]

				})
			}
		}
		catch (e) {

		}
		if (loginplant != "" && loginplant != undefined) {
			var consigners = [{ "value": loginplant, "label": loginplant }]
		}
		else {
			var consigners = this.state.consigner;
		}
		if (localStorage.getItem("user_type") === "TRANSPORTER") {
			var transportavalue = { value: localStorage.getItem("transportercode") };
		}
		else {
			var transportavalue = this.state.flTransporter;
		}
		this.setState({
			startDate: sdate,
			endDate: edate,
			defaultsdate: sdate,
			defaultedate: edate,
			flTransporter: transportavalue
		})
		var parameters = {
			dept_code: encode("SNDG"),
			startDate: sdate,
			endDate: edate,
			consigner: consigners,

		}
		if (localStorage.getItem("user_type") === "TRANSPORTER") {
			parameters.transporter_code = localStorage.getItem("transportercode");
		}
		this.onLoadGetConsignmentsList(urlpath, parameters)
		this.getCurrentDateDieselCost()
		this.recentUpdateMatrix();
		redirectURL.post("/dashboard/lspapprovals")
			.then((response) => {
				var records = []
				records = response.data.records;
				this.setState({
					approveLevels: records
				})

			})

		redirectURL.post("/consignments/usergridstates", { userId: localStorage.getItem("userid"), screenurl: window.location.pathname })
			.then(async (resp) => {
				//// console.log("resp ", resp)
				await this.setState({
					usergridstate: resp.data
				});
				await this.restoreGridStates();
			})


		if (localStorage.getItem("user_type") === "APP_ADMIN") {
			redirectURL.get("/consignments/getMatrixLogs")
				.then(async (resp) => {
					if (resp.data.status === "success") {
						this.setState({
							matrixData: resp.data.matrixData
						})
					}
					else {
						this.setState({
							matrixData: []
						})
					}
				})
		}


	}
	recentUpdateMatrix() {
		redirectURL.post("/dashboard/recentUpdateMatrix")
			.then((response) => {
				var records = []
				records = response.data.records;
				if (records.length > 0) {
					if (records[0].last_updated_on !== "" && records[0].last_updated_on !== undefined) {
						var last_update_on = getHyphenDDMMYYYYHHMMSS(records[0].last_updated_on);
					}
					else {
						var last_update_on = '';
					}
					this.setState({
						last_update_matrix: last_update_on
					})
				}
			})
	}
	getCurrentDateDieselCost() {
		var cdate = moment.parseZone().format("YYYY-MM-DD");
		var params = {
			current_date: cdate
		}
		redirectURL.post("/dashboard/currentDayDieselCost", params)
			.then((response) => {
				var records = [];
				records = response.data.records;
				if (records.length > 0) {
					this.setState({
						currentDateDiesel: records[0].diesel_cost
					})
				}
			})
	}
	async onLoadGetConsignmentsList(urlpath, parameters) {
		this.setState({
			loadshow: 'show-m',
			overly: 'show-m'
		})
		// console.log("this.state.loginplant ", this.state.loginplant)

		//console.log(parameters);
		parameters.date_type = this.state.date_type.value;
		await redirectURL.post(urlpath, parameters)
			.then((response) => {
				// console.log("response", response.data)
				var recordslist = response.data.consignments;
				var records = [];
				var total_driver_cost = 0;
				var total_diesel_cost = 0;
				var total_road_cost = 0;
				var total_toll_cost = 0;
				var total_transportation_cost = 0;
				if (localStorage.getItem("user_type") === "TRANSPORTER") {
					if (recordslist.length > 0) {
						records = recordslist.filter(e => {
							if (e.transporter_code == localStorage.getItem("transportercode")) {
								// console.log("transporter_name",e.transporter_name)
								return e
							}

						});
					}
				}
				else {
					records = recordslist;
				}
				if (records.length > 0) {
					records.map((itm) => {
						if (itm.driver_cost_matrix !== "" && itm.driver_cost_matrix !== undefined) {
							total_driver_cost = parseFloat(itm.driver_cost_matrix) + parseFloat(total_driver_cost);
						}
						else {
							total_driver_cost = 0 + parseFloat(total_driver_cost);
						}

						if (itm.diesel_cost_matrix !== "" && itm.diesel_cost_matrix !== undefined) {
							total_diesel_cost = parseFloat(itm.diesel_cost_matrix) + parseFloat(total_diesel_cost);
						}
						else {
							total_diesel_cost = 0 + parseFloat(total_diesel_cost);
						}

						if (itm.road_cost_matrix !== "" && itm.road_cost_matrix !== undefined) {
							total_road_cost = parseFloat(itm.road_cost_matrix) + parseFloat(total_road_cost);
						}
						else {
							total_road_cost = 0 + parseFloat(total_road_cost);
						}

						if (itm.toll_cost_matrix !== "" && itm.toll_cost_matrix !== undefined) {
							total_toll_cost = parseFloat(itm.toll_cost_matrix) + parseFloat(total_toll_cost);
						}
						else {
							total_toll_cost = 0 + parseFloat(total_toll_cost);
						}

						if (itm.total_transportation_cost_matrix !== "" && itm.total_transportation_cost_matrix !== undefined) {
							total_transportation_cost = parseFloat(itm.total_transportation_cost_matrix) + parseFloat(total_transportation_cost);
						}
						else {
							total_transportation_cost = 0 + parseFloat(total_transportation_cost);
						}

					})
				}
				// console.log("formatNumber(parseFloat(total_driver_cost)) ",formatNumber(parseFloat(total_driver_cost)))
				this.setState({
					rowData: records,
					allRowData: records,
					originalData: records,
					loadshow: 'show-n',
					overly: 'show-n',
					total_driver_cost: total_driver_cost,
					total_diesel_cost: total_diesel_cost,
					total_road_cost: total_road_cost,
					total_toll_cost: total_toll_cost,
					total_transportation_cost: total_transportation_cost
				});


			})
			.catch(function (error) {
				console.log(error);
			});
	}
	closeAlert = async () => {
		await this.setState({
			show: false
		});
		// await window.location.reload();
	}
	changeConsignerArrayHandler = consigner => {
		this.setState(
			{ consigner },
			// () => console.log(`Consigner Option selected:`, this.state.consigner)
		);

		this.setState({
			errorMessage: '',
			successMessage: ''
		})
	}


	ClearNewFilter = () => {
		this.setState({
			loadshow: "show-m",
			overly: "show-m"
		})
		this.setState({
			flCity: "",
			flState: "",
			flCluster: "",
			flZone: "",
			flTransporter: "",
			flDealer: ""
		});
	}

	newFilter = (filterType, selOption) => {
		// console.log(filterType, selOption)
		this.setState({ [filterType]: selOption });
	}
	selectConsignerOptionsItems() {
		let items = [];

		var plants = this.state.plants;

		items.push({ value: 'all', label: 'All' });
		if (plants.length > 0) {
			plants.forEach(item => {
				if (this.state.loginplant != "" && this.state.loginplant != undefined) {
					if (this.state.loginplant == item.plant_code) {
						items.push({ value: item.plant_code, label: item.plant_name });
					}

				}
				else {
					items.push({ value: item.plant_code, label: item.plant_name });
				}

			});
		}


		return items;
	}

	handlerStartDateTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);

		var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());
		if (this.state.endDate != undefined && this.state.endDate != "") {
			// console.log(startdate,"startdate")
			// console.log(this.state.endDate,"endDate")
			var startDate = new Date(startdate);
			var endDate = new Date(this.state.endDate);
			var startTime = startDate.getTime();
			var endTime = endDate.getTime();
			// console.log(startTime,endTime);
			if (startTime > endTime) {
				this.setState({
					startDate: ""
				});
				alert("To Date should be greater than From Date");
			}
			else {
				this.setState({
					startDate: startdate
				});
			}
		}
		else {
			this.setState({
				startDate: startdate
			});
		}
		//let value = event.target.value;
		//this.setState({'startDate':value});
	}

	handlerEndDateTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);
		var edate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate())
		if (this.state.endDate != undefined && this.state.endDate != "") {
			// console.log(this.state.startDate,"startdate")
			// console.log(edate,"endDate")
			var startDate = new Date(this.state.startDate);
			var endDate = new Date(edate);
			var startTime = startDate.getTime();
			var endTime = endDate.getTime();
			// console.log(startTime,endTime);
			if (startTime > endTime) {
				this.setState({
					endDate: ""
				});
				alert("To Date should be greater than From Date");
			}
			else {
				this.setState({
					endDate: edate
				});
			}
		}
		else {
			this.setState({
				endDate: edate
			});
		}
	}
	onChangeTransporter(flTransporter) {
		this.setState({ flTransporter }, () => {
			// this.onGetConsigners();
		})
	}
	onChangeDateType(date_type) {
		this.setState({ date_type })
	}
	onChangeZone(flZone) {
		this.setState({ flZone }, () => {
			// if(this.state.flZone.value !== "" && this.state.flZone.value !== undefined)
			// {
			this.filterZoneParams();
			// }
		})
	}
	onChangeDealerState(flState) {
		this.setState({ flState }, () => {
			// if(this.state.flState.value !== "" && this.state.flState.value !== undefined)
			// {
			this.filterZoneParams();
			// }
		})
	}
	onChangeDealerCity(flCity) {
		this.setState({ flCity }, () => {
			// if(this.state.flCity.value !== "" && this.state.flCity.value !== undefined)
			// {
			this.filterZoneParams();
			// }
		})
	}
	filterZoneParams() {
		var params = {
			dept_code: encode("SNDG")
		}
		if (this.state.flZone.value !== "" && this.state.flZone.value !== undefined) {
			params.zone = this.state.flZone.value;
		}
		if (this.state.flCity.value !== "" && this.state.flCity.value !== undefined) {
			params.dealer_city = this.state.flCity.value;
		}

		if (this.state.flState.value !== "" && this.state.flState.value !== undefined) {
			params.dealer_state = this.state.flState.value;
		}
		// console.log("flCityArray ", this.state.flCityArray)
		if (this.state.flStateArray.length > 0) {
			var stlist = []
			this.state.flStateArray.map((it) => {
				stlist.push(it.value)
			})
			if (stlist.length > 0) {
				params.region_states = stlist;
			}

		}

		if (this.state.flCityArray.length > 0) {
			var astlist = []
			this.state.flCityArray.map((it) => {
				astlist.push(it.value)
			})
			if (astlist.length > 0) {
				params.areas = astlist;
			}

		}
		redirectURL.post("/consignments/getConsignmentsNewFilterKeys", params)
			.then((resp) => {
				var dealers = resp.data.consigneeName;
				var filterDealers = [{ value: "", label: "All" }];
				if (dealers.length > 0) {
					dealers.map(function (c) {
						filterDealers.push({
							label: c.consignee_name + "-" + c.consignee_code,
							value: c.consignee_code,
						});
					});
				}

				var cities = resp.data.cities;
				var filterCities = [{ value: "", label: "All" }];
				if (cities.length > 0) {
					cities.map(function (c) {
						filterCities.push({
							label: c,
							value: c,
						});
					});
				}
				var states = resp.data.states;
				var filterStates = [{ value: "", label: "All" }];
				if (states.length > 0) {
					states.map(function (s) {
						filterStates.push({
							label: s,
							value: s,
						});
					});
				}
				var clusters = resp.data.clusters;
				var filterClusters = [{ value: "", label: "All" }];
				if (clusters.length > 0) {
					clusters.map(function (cl) {
						filterClusters.push({
							label: cl,
							value: cl,
						});
					});
				}

				var transporters = resp.data.transporters;
				var filterTransporters = [{ value: "", label: "All" }];
				if (transporters.length > 0) {
					transporters.map(function (t) {
						filterTransporters.push({
							label: t,
							value: t,
						});
					});
				}
				this.setState({
					filterCities: filterCities,
					filterStates: filterStates,
					filterClusters: filterClusters,
					filterDealers: filterDealers,
				});
			})
	}
	filtersData() {
		redirectURL.post("/consignments/getConsignmentsNewFilterKeys", { dept_code: encode("SNDG") })
			.then((resp) => {
				var dealers = resp.data.consigneeName;

				var filterDealers = [{ value: "", label: "All" }];

				if (dealers.length > 0) {
					dealers.map(function (c) {
						if (localStorage.getItem("user_type") === "DEALER") {
							if (c.consignee_code == localStorage.getItem("customer_code")) {
								filterDealers.push({
									label: c.consignee_name + "-" + c.consignee_code,
									value: c.consignee_code,
								});
							}

						}
						else if (localStorage.getItem("user_type") === "PARENT_DEALER") {
							if (c.parent_dealer_code == localStorage.getItem("customer_code")) {
								filterDealers.push({
									label: c.consignee_name + "-" + c.consignee_code,
									value: c.consignee_code,
								});
							}
						}
						else {
							filterDealers.push({
								label: c.consignee_name + "-" + c.consignee_code,
								value: c.consignee_code,
							});
						}
					});
				}

				var cities = resp.data.cities;
				var filterCities = [{ value: "", label: "All" }];
				if (cities.length > 0) {
					cities.map(function (c) {
						if (localStorage.getItem("user_type") !== "" && localStorage.getItem("user_type") !== undefined) {
							if (localStorage.getItem("user_type") === "AREA_MANAGER") {
								if (localStorage.getItem("areas") !== "" && localStorage.getItem("areas") !== undefined) {
									var rr = localStorage.getItem("areas").split(",");
									if (rr.length > 0) {
										rr.map((i) => {
											if (i == c) {
												filterCities.push({
													label: c,
													value: c,
												});
											}
										})
									}
								}
								else {

									filterCities.push({
										label: c,
										value: c,
									});
								}

							}
							else {
								filterCities.push({
									label: c,
									value: c,
								});
							}
						}
						else {
							filterCities.push({
								label: c,
								value: c,
							});
						}

					});
				}
				var states = resp.data.states;
				var filterStates = [{ value: "", label: "All" }];
				if (states.length > 0) {
					states.map(function (s) {
						if (localStorage.getItem("user_type") !== "" && localStorage.getItem("user_type") !== undefined) {
							if (localStorage.getItem("user_type") === "REGIONAL_MANAGER" || localStorage.getItem("user_type") === "AREA_MANAGER") {
								if (localStorage.getItem("region") !== "" && localStorage.getItem("region") !== undefined) {
									var rr = localStorage.getItem("region").split(",");
									if (rr.length > 0) {
										rr.map((i) => {
											if (i == s) {
												filterStates.push({
													label: s,
													value: s,
												});
											}
										})
									}
								}
								else {

									filterStates.push({
										label: s,
										value: s,
									});
								}

							}
							else {
								filterStates.push({
									label: s,
									value: s,
								});
							}
						}
						else {
							filterStates.push({
								label: s,
								value: s,
							});
						}
					});

				}
				var clusters = resp.data.clusters;
				var filterClusters = [{ value: "", label: "All" }];
				if (clusters.length > 0) {
					clusters.map(function (cl) {
						filterClusters.push({
							label: cl,
							value: cl,
						});
					});
				}
				var zones = resp.data.zones;
				var filterZones = [{ value: "", label: "All" }];
				if (zones.length > 0) {
					zones.map(function (cl) {
						if (localStorage.getItem("user_type") !== "" && localStorage.getItem("user_type") !== undefined) {
							if (localStorage.getItem("user_type") === "ZONE_MANAGER" || localStorage.getItem("user_type") === "REGIONAL_MANAGER" || localStorage.getItem("user_type") === "AREA_MANAGER") {
								// console.log("zone ", localStorage.getItem("zone"))
								if (localStorage.getItem("zone").includes(cl) === true) {
									filterZones.push({
										label: cl,
										value: cl,
									});
								}
							}
							else {
								filterZones.push({
									label: cl,
									value: cl,
								});
							}
						}
						else {
							filterZones.push({
								label: cl,
								value: cl,
							});
						}
					});
				}
				var transporters = resp.data.alltransporters;
				var filterTransporters = [{ value: "", label: "All" }];
				if (transporters.length > 0) {
					transporters.map(function (t) {
						filterTransporters.push({
							label: t.transporter_name,
							value: t.transporter_code,
						});
					});
				}

				// if(localStorage.getItem("user_type") === "DEALER")
				// {
				// 	this.setState({
				// 		flDealer:filterDealers[0]
				// 	})
				// }
				this.setState({
					filterCities: filterCities,
					allCities:filterCities,
					filterStates: filterStates,
					allStates:filterStates,
					filterClusters: filterClusters,
					filterZones: filterZones,
					filterTransporters: filterTransporters,
					filterDealers: filterDealers,
					allDealers:filterDealers
				});
			})
	}

	onGetConsigners = async (event) => {


		if (this.state.startDate == '' || this.state.startDate == "NaN-NaN-NaN") {
			var sDate = this.state.defaultsdate;
		}
		else {
			var sDate = this.state.startDate;
		}
		if (this.state.endDate == '' || this.state.startDate == "NaN-NaN-NaN") {
			var eDate = this.state.defaultedate;
		}
		else {
			var eDate = this.state.endDate;
		}


		if (this.state.flTransporter.value != "" && this.state.flTransporter.value != undefined && this.state.flTransporter.value != null) {
			var transporter_code = this.state.flTransporter.value;
		}
		else {
			var transporter_code = "";
		}

		if (this.state.flZone != "" && this.state.flZone != undefined && this.state.flZone != null) {
			var zone = this.state.flZone.value;
		}
		else {
			var zone = "";
		}


		if (this.state.flState != "" && this.state.flState != undefined && this.state.flState != null) {
			var state = this.state.flState.value;
		}
		else {
			var state = "";
		}


		if (this.state.flCity != "" && this.state.flCity != undefined && this.state.flCity != null) {
			var city = this.state.flCity.value;
		}
		else {
			var city = "";
		}

		if (this.state.flDealer != "" && this.state.flDealer != undefined && this.state.flDealer != null) {
			var consignee_code = this.state.flDealer.value;
		}
		else {
			var consignee_code = "";
		}

		var selectconsigners = []
		// console.log('selectconsigners',this.state.consigner)
		if (this.state.consigner == null) {
			selectconsigners = [{ "value": 'all', "label": 'All' }]
		}
		else {
			selectconsigners = this.state.consigner
		}

		var mvmtype = []
		if (this.state.movementtype == null) {
			mvmtype = [{ "value": 'all', "label": 'All' }]
		}
		else {
			mvmtype = [this.state.movementtype]
		}

		//console.log("this.state.movementtype ", this.state.movementtype)
		//console.log("mvmtype ", mvmtype)
		var selecttrip = []
		// if(this.state.triptype == null)
		// {
		// 	selecttrip = [{"value":'all', "label":'All'}]
		// }
		// else{
		selecttrip = [this.state.triptype]

		var requestparams = {
			consigner: selectconsigners,
			dept_code: encode(this.state.deptcode),
			startDate: sDate,
			endDate: eDate,
			transporter_code: transporter_code,
			zone: zone,
			state: state,
			city: city,
			consignee_code: consignee_code
		}

		if (this.state.flTransporter.value != "" && this.state.flTransporter.value != undefined && this.state.flTransporter.value != null) {
			requestparams.transporter_code = this.state.flTransporter.value;
		}
		if (localStorage.getItem("user_type") === "DEALER") {
			requestparams.consignee_code = localStorage.getItem("customer_code");
		}
		if (localStorage.getItem("user_type") === "PARENT_DEALER") {
			requestparams.parent_dealer_code = localStorage.getItem("customer_code");
		}




		//console.log("requestparams ", requestparams)

		var urlpath = '/consignments/vendorreconcilliationdata';

		if (localStorage.getItem("user_type") !== "" && localStorage.getItem("user_type") !== undefined) {
			if (localStorage.getItem("user_type") === "ZONE_MANAGER") {
				requestparams.zone = localStorage.getItem("zone");
			}
			if (localStorage.getItem("user_type") === "REGIONAL_MANAGER") {
				if (localStorage.getItem("region") !== "" && localStorage.getItem("region") !== undefined) {
					var rr = localStorage.getItem("region").split(",");
					if (rr.length > 0) {
						requestparams.region_states = rr;
					}
				}

			}

			if (localStorage.getItem("user_type") === "AREA_MANAGER") {
				if (localStorage.getItem("areas") !== "" && localStorage.getItem("areas") !== undefined) {
					var rr = localStorage.getItem("areas").split(",");
					if (rr.length > 0) {
						requestparams.areas = rr;
					}
				}

			}
		}
		// console.log("urlpath",urlpath,"requestparams",requestparams)
		await this.onLoadGetConsignmentsList(urlpath, requestparams)
	}
	reloadPageData = () => {

		this.setState(
			prevState => ({
				startDate: this.state.defaultsdate,
				endDate: this.state.defaultedate,
				flDealer: { value: "", label: "Select..." },
				consigner: [{ value: "all", label: "All" }],
				date_type: { value: "all", label: "All" },
				flZone: { value: "", label: "Select..." },
				flState: { value: "", label: "Select..." },
				flCity: { value: "", label: "Select..." },
				flTransporter: { value: "", label: "All" },
				filterCities:this.state.allCities,
				filterStates:this.state.allStates,
				filterDealers:this.state.allDealers,
			}),
			() => {

				var urlpath = '/consignments/vendorreconcilliationdata';

				var loguser = decode(localStorage.getItem("m"));
				// console.log("LOGUSER ", JSON.parse(loguser));
				var loginplant = "";
				try {
					var luser = JSON.parse(loguser);
					loginplant = luser.is_plant_code;
					// console.log("logplant", loginplant)
					if (loginplant !== "" && loginplant !== undefined) {
						this.setState({
							loginplant: loginplant,
							consigner: [{ "value": luser.is_plant_code, "label": luser.is_plant_name }]

						})
					}
				}
				catch (e) {

				}
				if (loginplant != "" && loginplant != undefined) {
					var consigners = [{ "value": loginplant, "label": loginplant }]
				}
				else {
					var consigners = [{ value: "all", label: "All" }];
				}
				if (localStorage.getItem("user_type") === "TRANSPORTER") {
					var transportavalue = { value: localStorage.getItem("transportercode") };
				}
				else {
					var transportavalue = this.state.flTransporter;
				}
				this.setState({
					startDate: this.state.defaultsdate,
					endDate: this.state.defaultedate,
					flDealer: { value: "", label: "Select..." },
					consigner: [{ value: "all", label: "All" }],
					date_type: { value: "all", label: "All" },
					flZone: { value: "", label: "Select..." },
					flState: { value: "", label: "Select..." },
					flCity: { value: "", label: "Select..." },
					flTransporter: { value: "", label: "All" }
				})
				var parameters = {
					dept_code: encode("SNDG"),
					startDate: this.state.defaultsdate,
					endDate: this.state.defaultedate,
					consigner: consigners,

				}
				if (localStorage.getItem("user_type") === "TRANSPORTER") {
					parameters.transporter_code = localStorage.getItem("transportercode");
				}
				this.onLoadGetConsignmentsList(urlpath, parameters)
				this.getCurrentDateDieselCost()
				this.recentUpdateMatrix();
				redirectURL.post("/dashboard/lspapprovals")
					.then((response) => {
						var records = []
						records = response.data.records;
						this.setState({
							approveLevels: records,
							startDate: this.state.defaultsdate,
							endDate: this.state.defaultedate,
							flDealer: { value: "", label: "Select..." },
							consigner: [{ value: "all", label: "All" }],
							date_type: { value: "all", label: "All" },
							flZone: { value: "", label: "Select..." },
							flState: { value: "", label: "Select..." },
							flCity: { value: "", label: "Select..." },
							flTransporter: { value: "", label: "All" }
						})

					})

				redirectURL.post("/consignments/usergridstates", { userId: localStorage.getItem("userid"), screenurl: window.location.pathname })
					.then(async (resp) => {
						//// console.log("resp ", resp)
						await this.setState({
							usergridstate: resp.data,
							startDate: this.state.defaultsdate,
							endDate: this.state.defaultedate,
							flDealer: { value: "", label: "Select..." },
							consigner: [{ value: "all", label: "All" }],
							date_type: { value: "all", label: "All" },
							flZone: { value: "", label: "Select..." },
							flState: { value: "", label: "Select..." },
							flCity: { value: "", label: "Select..." },
							flTransporter: { value: "", label: "All" }
						});
						await this.restoreGridStates();
					})
			}
		);

	}

	reloadPageDataGloabl = () => {
		// window.location.reload();
		document.getElementById("inputTruckConsignment").value = "";
		this.setState({
			selectedFilter: []
		}, () => {

			var urlpath = '/consignments/vendorreconcilliationdata';
			var dpt = 'SNDG';
			var consignData = {
				dept_code: encode(dpt),
				startDate: this.state.startDate,
				endDate: this.state.endDate,
				consigner: this.state.consigner
			}

			if (this.state.flTransporter.value != "" && this.state.flTransporter.value != undefined && this.state.flTransporter.value != null) {
				consignData.transporter_code = this.state.flTransporter.value;
			}

			if (localStorage.getItem("user_type") !== "" && localStorage.getItem("user_type") !== undefined) {
				if (localStorage.getItem("user_type") === "ZONE_MANAGER") {
					consignData.zone = localStorage.getItem("zone");
				}

				if (localStorage.getItem("user_type") === "REGIONAL_MANAGER") {
					if (localStorage.getItem("region") !== "" && localStorage.getItem("region") !== undefined) {
						var rr = localStorage.getItem("region").split(",");
						if (rr.length > 0) {
							consignData.region_states = rr;
						}
					}

				}

				if (localStorage.getItem("areas") !== "" && localStorage.getItem("areas") !== undefined) {
					var rr = localStorage.getItem("areas").split(",");
					if (rr.length > 0) {
						consignData.areas = rr;
					}
				}
			}
			this.onLoadGetConsignmentsList(urlpath, consignData);
		})
	}

	onClickShowMatirLogForm() {
		this.setState({
			viewMartixDocs: "slider-translate-30p",
			overly: "show-m",
		})
	}
	onClickShowMatirUploadForm() {
		this.setState({
			sliderTranslateMatrix: "slider-translate-30p",
			overly: "show-m",
			// loadshow:"show-m",
		})
	}
	onClickShowFormulaSB() {
		this.setState({
			formulaSB: "slider-translate-30p",
			overly: "show-m",
			// loadshow:"show-m",
		})
	}
	onClickHideAll() {
		this.setState({
			loadshow: 'show-n',
			overly: 'show-n',
			sliderTranslateMatrix: "",
			viewMartixDocs: "",
			sliderTranslatesidebar: "",
			sliderTranslateChangeMatrix: '',
			sliderTranslateViewMatrix: '',
			changeMatrixRow: [],
			viewMatrixRowData: [],
			overly: 'show-n',
			lsp_diesel_cost: '',
			lsp_driver_cost: '',
			lsp_road_cost: '',
			lsp_toll_cost: '',
			formulaSB: ''
		});

	}

	changeXLSXFileHandler(event) {
		this.setState({
			matrixFile: event.target.files[0]
		})
	}
	uploadMatrixFile(event) {
		event.preventDefault();
		this.setState({
			loadshow: "show-m"
		})
		var fdata = new FormData();
		fdata.append("excel_file", this.state.matrixFile);
		// redirectURL.post("http://dm-autometrics.in:8001/uploadVendorPaymentMatrixData",fdata,{
		redirectURL.post("uploadVendorPaymentMatrixData", fdata, {
			headers: {
				'cache-control': 'no-cache',
				'content-type': 'multipart/form-data'
			}
		})
			.then((response) => {
				// console.log("resposne.data ", response.data)
				if (response.data.result === "Success") {
					this.setState({
						sliderTranslateMatrix: "",
						changeMatrixRow: [],
						overly: 'show-n',
						loadshow: "show-n",
						show: true,
						basicTitle: "Matrix file has been uploaded successfully and data will be reflected in some time",
						basicType: "success"
					})
					this.onClickHideAll();
					var urlpath = '/consignments/vendorreconcilliationdata';

					var parameters = {
						dept_code: encode("SNDG"),
						startDate: this.state.startDate,
						endDate: this.state.endDate,
						consigner: this.state.consigner,

					}
					if (localStorage.getItem("user_type") === "TRANSPORTER") {
						parameters.transporter_code = localStorage.getItem("transportercode");
					}
					this.onLoadGetConsignmentsList(urlpath, parameters)
				}
				else {
					this.setState({
						loadshow: "show-n",
						show: true,
						basicTitle: "Failed to uploaded matrix file",
						basicType: "danger"
					})
				}
			})
	}
	onShowMatrixForm(rownode) {
		// console.log("rownode ", rownode)
		this.setState({
			sliderTranslateChangeMatrix: "slider-translate-30p",
			changeMatrixRow: [rownode.data],
			overly: 'show-m'
		})
	}
	calculateLatestDieselCost() {
		var params = {
			diesel_rate: this.state.currentDateDiesel
		}
		// console.log("params",params)
		redirectURL.post("dashboard/calculateDieselCostForVendorPayment", params)
			.then((response) => {
				console.log("resposne", response.data)
			})
	}

	onShowViewMatrixCost(rownode) {
		this.setState({
			sliderTranslateViewMatrix: "slider-translate-40p",
			viewMatrixRowData: [rownode.data],
			overly: 'show-m'
		})
	}
	changeHandler(e) {
		var name = e.target.name;
		var value = e.target.value;
		const floatRegex = /^[+-]?(\d+(\.\d*)?|\.\d+)$/;
		// console.log("floatRegex.test(value) ", floatRegex.test(value))
		if (value === "") {
			this.setState({
				[name]: value
			})
		}
		else {
			if (floatRegex.test(value) === true) {
				this.setState({
					[name]: value
				})
			}
		}
	}
	changeRequestMatrixCost(event) {
		event.preventDefault();
		// console.log("changeMatrixRow ", this.state.changeMatrixRow)
		if (this.state.changeMatrixRow.length > 0) {
			var rownode = JSON.stringify(this.state.changeMatrixRow);
		}
		else {
			var rownode = [];
		}
		var params = {
			lsp_diesel_cost: this.state.lsp_diesel_cost,
			lsp_driver_cost: this.state.lsp_driver_cost,
			lsp_road_cost: this.state.lsp_road_cost,
			lsp_toll_cost: this.state.lsp_toll_cost,
			rownode: rownode
		}
		// console.log("params ", params)
		redirectURL.post("/dashboard/addnewlspmatriccosts", params)
			.then((response) => {
				if (response.data.status === "success") {

					this.setState({
						show: true,
						basicTitle: "Successfully submitted change request",
						basicType: "success",
						sliderTranslateChangeMatrix: "",
						changeMatrixRow: [],
						overly: 'show-n',
						lsp_diesel_cost: '',
						lsp_driver_cost: '',
						lsp_road_cost: '',
						lsp_toll_cost: '',
					})
					this.onClickHideAll();
					var urlpath = '/consignments/vendorreconcilliationdata';

					var parameters = {
						dept_code: encode("SNDG"),
						startDate: this.state.startDate,
						endDate: this.state.endDate,
						consigner: this.state.consigner,

					}
					if (localStorage.getItem("user_type") === "TRANSPORTER") {
						parameters.transporter_code = localStorage.getItem("transportercode");
					}
					this.onLoadGetConsignmentsList(urlpath, parameters)
				}
				else {
					this.setState({
						show: true,
						basicTitle: "Failed to make changes",
						basicType: "info"
					})
				}
			})
	}
	approveLSPCosts(event) {
		event.preventDefault();
		if (this.state.viewMatrixRowData.length > 0) {
			var params = {
				rownode: JSON.stringify(this.state.viewMatrixRowData)
			}
			redirectURL.post("/dashboard/approveLSPCosts", params)
				.then((response) => {
					if (response.data.status === "success") {

						this.setState({
							show: true,
							basicTitle: "Successfully approved costs",
							basicType: "success",
							sliderTranslateViewMatrix: "",
							viewMatrixRowData: [],
							overly: 'show-n'
						})
						this.onClickHideAll();
						var urlpath = '/consignments/vendorreconcilliationdata';

						var parameters = {
							dept_code: encode("SNDG"),
							startDate: this.state.startDate,
							endDate: this.state.endDate,
							consigner: this.state.consigner,

						}
						if (localStorage.getItem("user_type") === "TRANSPORTER") {
							parameters.transporter_code = localStorage.getItem("transportercode");
						}
						this.onLoadGetConsignmentsList(urlpath, parameters)
					}
					else {
						this.setState({
							show: true,
							basicTitle: "Failed to approve changes",
							basicType: "info"
						})
					}
				})

		}

	}

	async rejectLSPCosts(event) {
		event.preventDefault();
		if (this.state.viewMatrixRowData.length > 0) {
			var params = {
				rownode: JSON.stringify(this.state.viewMatrixRowData)
			}
			console.log(params,"pppppppppppppp")
			await redirectURL.post("/dashboard/rejectLSPCosts", params)
				.then((response) => {
					if (response.data.status === "success") {

						this.setState({
							show: true,
							basicTitle: "Successfully rejected costs",
							basicType: "success",
							sliderTranslateViewMatrix: "",
							viewMatrixRowData: [],
							overly: 'show-n'
						})
						this.onClickHideAll();
						var urlpath = '/consignments/vendorreconcilliationdata';

						var parameters = {
							dept_code: encode("SNDG"),
							startDate: this.state.startDate,
							endDate: this.state.endDate,
							consigner: this.state.consigner,

						}
						if (localStorage.getItem("user_type") === "TRANSPORTER") {
							parameters.transporter_code = localStorage.getItem("transportercode");
						}
						this.onLoadGetConsignmentsList(urlpath, parameters)
					}
					else {
						this.setState({
							show: true,
							basicTitle: "Failed to reject changes",
							basicType: "info"
						})
					}
				})

		}

	}

	approveLSPCostsFinal(event) {
		event.preventDefault();
		if (this.state.viewMatrixRowData.length > 0) {
			var params = {
				rownode: JSON.stringify(this.state.viewMatrixRowData)
			}
			redirectURL.post("/dashboard/approveLSPCostsFinal", params)
				.then((response) => {
					if (response.data.status === "success") {

						this.setState({
							show: true,
							basicTitle: "Successfully approved costs",
							basicType: "success",
							sliderTranslateViewMatrix: "",
							viewMatrixRowData: [],
							overly: 'show-n'
						})
						this.onClickHideAll();
						var urlpath = '/consignments/vendorreconcilliationdata';

						var parameters = {
							dept_code: encode("SNDG"),
							startDate: this.state.startDate,
							endDate: this.state.endDate,
							consigner: this.state.consigner,

						}
						if (localStorage.getItem("user_type") === "TRANSPORTER") {
							parameters.transporter_code = localStorage.getItem("transportercode");
						}
						this.onLoadGetConsignmentsList(urlpath, parameters)
					}
					else {
						this.setState({
							show: true,
							basicTitle: "Failed to approve changes",
							basicType: "info"
						})
					}
				})

		}

	}

	rejectLSPCostsFinal(event) {
		event.preventDefault();
		if (this.state.viewMatrixRowData.length > 0) {
			var params = {
				rownode: JSON.stringify(this.state.viewMatrixRowData)
			}
			redirectURL.post("/dashboard/rejectLSPCostsFinal", params)
				.then((response) => {
					if (response.data.status === "success") {

						this.setState({
							show: true,
							basicTitle: "Successfully rejected costs",
							basicType: "success",
							sliderTranslateViewMatrix: "",
							viewMatrixRowData: [],
							overly: 'show-n'
						})
						this.onClickHideAll();
						var urlpath = '/consignments/vendorreconcilliationdata';

						var parameters = {
							dept_code: encode("SNDG"),
							startDate: this.state.startDate,
							endDate: this.state.endDate,
							consigner: this.state.consigner,

						}
						if (localStorage.getItem("user_type") === "TRANSPORTER") {
							parameters.transporter_code = localStorage.getItem("transportercode");
						}
						this.onLoadGetConsignmentsList(urlpath, parameters)
					}
					else {
						this.setState({
							show: true,
							basicTitle: "Failed to rejected changes",
							basicType: "info"
						})
					}
				})

		}

	}
	onClickSaveGridState() {
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();
		// // console.log("Columns ", window.colState)
		// // console.log("Group ", window.groupState)
		// // console.log("Sort ", window.sortState)
		// // console.log("Filter ", window.filterState)
		let screenpage = 'POD Dashboard';


		let reqparams = {
			gridcolumns: window.colState,
			gridgroup: window.groupState,
			gridcolsort: window.sortState,
			gridcolfilter: window.filterState,
			userId: localStorage.getItem("userid"),
			screenurl: window.location.pathname,
			screentitle: screenpage
		}
		console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
			.then((response) => {
				//// console.log("State response ",response.data)
				this.setState({
					show: true,
					basicTitle: "Successfully saved grid layout",
					basicType: "success",
					screenurl: window.location.pathname,
					screentitle: screenpage
				})
			})
			.catch(function (e) {
				// console.log("Error ", e)
			})
	}

	restoreGridStates() {

		if (this.state.usergridstate.length > 0) {
			var windowstates = this.state.usergridstate;
			this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
			this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
			this.gridApi.setSortModel(windowstates[0].gridcolsort);
			this.gridApi.setFilterModel(windowstates[0].gridcolfilter);

		}
	}

	resetState = () => {
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//// console.log('column state reset');
		var reqparams = {
			userId: localStorage.getItem("userid"),
			screenurl: window.location.pathname,
			screentitle: this.state.screenpage
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
			.then((response) => {
				this.setState({
					show: true,
					basicTitle: "Successfully reset default grid layout",
					basicType: "success"
				})
			})

	};

	onGridReady = params => {
		this.gridApi = params.api;

		//console.log("Grid is: ", params);
		this.gridColumnApi = params.columnApi;

		this.gridApi.addEventListener('paginationChanged', (e) => {
			//Reset rows selection based on current page
			// this.resetPaginationSelection(this);
		});

		this.gridApi.forEachLeafNode((node) => {
			// console.log("node here ",node)
			node.setSelected(true);

		});

	};


	onGridState = () => {
		console.log(this.gridApi);

		/*Get  Current Columns State and Store in this.colState */
		this.colState = this.gridColumnApi.getColumnState();

		/*Get Current RowGroup Columns State and Store in this.rowGroupState */
		this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		/*Get Current Pivot Columns State and Store in this.pivotColumns, 
		 * User should enable the Pivot mode.*/
		this.pivotColumns = this.gridColumnApi.getPivotColumns();

		/*Get Current Filter State and Store in window.filterState */
		window.filterState = this.gridApi.getFilterModel();
		this.gridApi.setFilterModel(window.filterState);
	}

	render() {
		const columnwithDefs = []

		if (localStorage.getItem("user_type") === "TRANSPORTER") {
			columnwithDefs.push(

				{
					headerName: "",
					field: "",
					pinned: "left",
					width: 170,
					filter: "agSetColumnFilter",
					cellRendererSelector: function (params) {
						if (params.data.is_transporter_change_cost !== "" && params.data.is_transporter_change_cost !== undefined) {
							if (params.data.is_transporter_change_cost === 1) {
								return { component: 'ViewMatrixCosts' }
							}
						}
						else {
							return { component: 'ChangeMatrixCosts' }
						}
					},
					cellClass: function (params) {
						try {
							if (params.data.no_matrix_data != "" && params.data.no_matrix_data != undefined) {
								if (params.data.no_matrix_data == 1) {
									return "lightred"
								}
							}
						} catch (error) {

						}
					}
				},
				{
					headerName: "Plant Code",
					field: "consigner_code",
					width: 120,
					filter: "agSetColumnFilter",
					cellRenderer: '', resizable: true
				},
				{
					headerName: "Type",
					field: "description_code",
					width: 120,
					filter: "agSetColumnFilter",
					cellRenderer: '', resizable: true
				},
				{
					headerName: "Description",
					field: "description",
					width: 120,
					filter: "agSetColumnFilter",
					cellRenderer: '', resizable: true
				},

				{
					headerName: "Vin No",
					field: "vin_number",
					width: 120,
					pinned: "left",
					filter: "agSetColumnFilter",
					resizable: true
				},

				{
					headerName: "Dealer Code",
					field: "parent_dealer_code",
					width: 150,
					// pinned: "left",
					filter: "agSetColumnFilter",
					resizable: true
				},
				{
					headerName: "Dealer Name",
					field: "parent_dealer_name",
					width: 150,
					// pinned: "left",
					filter: "agSetColumnFilter",
					resizable: true
				},

				{
					headerName: "Ship To Party Code",
					field: "consignee_code",
					width: 150,
					// pinned: "left",
					filter: "agSetColumnFilter",
					resizable: true
				},
				{
					headerName: "Ship To Party Name",
					field: "consignee_name",
					width: 200,
					// pinned: "left",
					filter: "agSetColumnFilter",
					resizable: true
				},
				{
					headerName: "Dealer City",
					field: "consignee_city",
					width: 150,
					// pinned: "left",
					filter: "agSetColumnFilter",
					resizable: true
				},
				{
					headerName: "Dealer State",
					field: "consignee_state",
					width: 150,
					// pinned: "left",
					filter: "agSetColumnFilter",
					resizable: true
				},
				{
					headerName: "Dealer Zone",
					field: "zone",
					width: 150,
					// pinned: "left",
					filter: "agSetColumnFilter",
					resizable: true
				},

				{
					headerName: "Invoice No",
					field: "invoice_no",
					// pinned: 'left',
					width: 150,
					resizable: true,
					valueGetter: function (params) {
						if (params.data.consignment_code != '' && params.data.consignment_code != undefined) {
							return params.data.consignment_code;
						}
						else {
							return "";
						}
					},
				},

				{
					headerName: 'Invoice Date',
					field: "invoice_time",
					width: 150,
					// pinned: 'left',
					//filter: "agDateColumnFilter",
					cellRenderer: '', resizable: true,
					comparator: dateComparator,

					valueGetter: function (params) {
						if (params.data.invoice_time != '' && params.data.invoice_time != undefined && params.data.invoice_time !== ' ') {
							return getHyphenDDMMMYYYYHHMM(params.data.invoice_time);
						}
						else {
							return "NA";
						}
					},
					// hide: (this.state.tnpReturnConsignmentsVisibilty) ? true : hideonlyPRT, // this logic was imported from msil so for hero we are commenting this as of 27-07-2022
				},

				{
					headerName: "Mode Of Dispatch",
					field: "vehicle_mode",
					width: 150,
					// pinned: "left",
					filter: "agSetColumnFilter",
					resizable: true
				},
				{
					headerName: 'Dispath Date',
					field: "gate_out_time",
					width: 150,
					// pinned: 'left',
					//filter: "agDateColumnFilter",
					cellRenderer: '', resizable: true,
					comparator: dateComparator,

					valueGetter: function (params) {
						if (params.data.gate_out_time != '' && params.data.gate_out_time != undefined && params.data.gate_out_time !== ' ') {
							return getHyphenDDMMMYYYYHHMM(params.data.gate_out_time);
						}
						else {
							return "NA";
						}
					},
					// hide: (this.state.tnpReturnConsignmentsVisibilty) ? true : hideonlyPRT, // this logic was imported from msil so for hero we are commenting this as of 27-07-2022
				},

				{
					headerName: "Transporter Name",
					field: "transporter_name",
					width: 150,
					filter: "agSetColumnFilter",
					cellRenderer: '',
					resizable: true,
					valueGetter: function (params) {
						if (params.data.truck_no != "" && params.data.truck_no != "") {
							return params.data.transporter_name
						}
					}
				},
				{
					headerName: "Transporter Code",
					field: "transporter_code",
					width: 110,
					filter: "agSetColumnFilter",
					cellRenderer: '', resizable: true,
					valueGetter: function (params) {
						if (params.data.truck_no != "" && params.data.truck_no != "") {
							return params.data.transporter_code
						}
					}
				},

				{
					headerName: "Total Distance (Matrix)",
					field: "distance_km_matrix",
					width: 170,
					filter: "agSetColumnFilter",
					valueGetter: params => {
						let data = params.data.distance_km_matrix
						if (data == 0) {
							return 0
						}
						return data
						// console.log("params",params.data.aprox_dist_travelled_from_start)
					}
				},
				{
					headerName: "Driver Cost (Matrix)",
					field: "driver_cost_matrix",
					width: 170,
					filter: "agSetColumnFilter"
				},
				{
					headerName: "Road Tax (Matrix)",
					field: "road_cost_matrix",
					width: 170,
					filter: "agSetColumnFilter"
				},
				{
					headerName: "Toll Tax (Matrix)",
					field: "toll_cost_matrix",
					width: 170,
					filter: "agSetColumnFilter"
				},
				{
					headerName: "Diesel Cost (Matrix)",
					field: "diesel_cost_matrix",
					width: 170,
					filter: "agSetColumnFilter"
				},
				{
					headerName: "Total Transportation Cost (Matrix)",
					field: "total_transportation_cost_matrix",
					width: 170,
					filter: "agSetColumnFilter"
				}
			);
		}
		else {
			columnwithDefs.push(
				// {
				// 	headerName: "",
				// 	field: "_id",
				// 	colId: "_id",
				// 	width: 50,
				// 	pinned: 'left',
				// 	cellRenderer: 'consignmentActions',
				// 	filter: false, resizable: true

				// },
				{
					headerName: "Plant Code",
					field: "consigner_code",
					width: 120,
					filter: "agSetColumnFilter",
					cellRenderer: '',
					resizable: true,
					cellClass: function (params) {
						try {
							if (params.data.is_transporter_change_cost !== "" && params.data.is_transporter_change_cost !== undefined) {
								if (params.data.is_transporter_change_cost === 1) {
									return ["cellstylegridO"]
								}
							}
							else {
								return "";
							}
						} catch (error) {

						}
					}
				},
				{
					headerName: "Type",
					field: "description_code",
					width: 120,
					filter: "agSetColumnFilter",
					cellRenderer: '', resizable: true,
					cellClass: function (params) {
						try {
							if (params.data.is_transporter_change_cost !== "" && params.data.is_transporter_change_cost !== undefined) {
								if (params.data.is_transporter_change_cost === 1) {
									return ["cellstylegridO"]
								}
							}
							else {
								return "";
							}
						} catch (error) {

						}
					}
				},
				{
					headerName: "Description",
					field: "description",
					width: 120,
					filter: "agSetColumnFilter",
					cellRenderer: '', resizable: true,
					cellClass: function (params) {
						try {
							if (params.data.is_transporter_change_cost !== "" && params.data.is_transporter_change_cost !== undefined) {
								if (params.data.is_transporter_change_cost === 1) {
									return ["cellstylegridO"]
								}
							}
							else {
								return "";
							}
						} catch (error) {

						}
					}
				},

				{
					headerName: "Vin No",
					field: "vin_number",
					width: 180,
					pinned: "left",
					filter: "agSetColumnFilter",
					resizable: true,
					cellClass: function (params) {
						try {
							if (params.data.is_transporter_change_cost !== "" && params.data.is_transporter_change_cost !== undefined) {
								if (params.data.is_transporter_change_cost === 1) {
									return ["cellstylegridO"]
								}
							}
							else {
								return "";
							}
						} catch (error) {

						}
					}
				},

				{
					headerName: "Status",
					field: "status",
					width: 180,
					pinned: 'left',
					// hide : hideStatus,
					filter: "agSetColumnFilter",
					resizable: true,
					cellClass: function (params) {
						try {
							if (params.data.is_transporter_change_cost !== "" && params.data.is_transporter_change_cost !== undefined) {
								if (params.data.is_transporter_change_cost === 1) {
									return ["cellstylegridO"]
								}
							}
							else {
								return "";
							}
						} catch (error) {

						}
					},
					valueGetter: (params) => {
						//console.log("GPS ", params.data.gps_data_available);
						if (params.data.status == 1) {
							return "Inside Plant"
						}
						else if (params.data.status == 2) {
							return "In Transit"
						}

						else if (params.data.status == 3) {
							return "Near Destination"
						}
						else if (params.data.status == 4) {
							return "Reached Destination"
						}
						else if (params.data.status == 5) {
							return "Manual Closure"
						}
						else {
							return ""
						}

					}
				},
				{
					headerName: 'Dispath Date',
					field: "gate_out_time",
					width: 150,
					// pinned: 'left',
					//filter: "agDateColumnFilter",
					cellRenderer: '', resizable: true,
					comparator: dateComparator,

					valueGetter: function (params) {
						if (params.data.gate_out_time != '' && params.data.gate_out_time != undefined && params.data.gate_out_time !== ' ') {
							return getHyphenDDMMMYYYYHHMM(params.data.gate_out_time);
						}
						else {
							return "NA";
						}
					},
					cellClass: function (params) {
						try {
							if (params.data.is_transporter_change_cost !== "" && params.data.is_transporter_change_cost !== undefined) {
								if (params.data.is_transporter_change_cost === 1) {
									return ["cellstylegridO"]
								}
							}
							else {
								return "";
							}
						} catch (error) {

						}
					}
					// hide: (this.state.tnpReturnConsignmentsVisibilty) ? true : hideonlyPRT, // this logic was imported from msil so for hero we are commenting this as of 27-07-2022
				},
				{
					headerName: 'Reached Destination',
					field: "recent_dealer_reported",
					width: 150,
					// pinned: 'left',
					//filter: "agDateColumnFilter",
					cellRenderer: '', resizable: true,
					comparator: dateComparator,

					valueGetter: function (params) {
						if (params.data.recent_dealer_reported != '' && params.data.recent_dealer_reported != undefined && params.data.recent_dealer_reported !== ' ') {
							return getHyphenDDMMMYYYYHHMM(params.data.recent_dealer_reported);
						}
						else {
							return "NA";
						}
					},
					cellClass: function (params) {
						try {
							if (params.data.is_transporter_change_cost !== "" && params.data.is_transporter_change_cost !== undefined) {
								if (params.data.is_transporter_change_cost === 1) {
									return ["cellstylegridO"]
								}
							}
							else {
								return "";
							}
						} catch (error) {

						}
					}
					// hide: (this.state.tnpReturnConsignmentsVisibilty) ? true : hideonlyPRT, // this logic was imported from msil so for hero we are commenting this as of 27-07-2022
				},

				{
					headerName: "Dealer Code",
					field: "parent_dealer_code",
					width: 150,
					// pinned: "left",
					filter: "agSetColumnFilter",
					resizable: true,
					cellClass: function (params) {
						try {
							if (params.data.is_transporter_change_cost !== "" && params.data.is_transporter_change_cost !== undefined) {
								if (params.data.is_transporter_change_cost === 1) {
									return ["cellstylegridO"]
								}
							}
							else {
								return "";
							}
						} catch (error) {

						}
					}
				},
				{
					headerName: "Dealer Name",
					field: "parent_dealer_name",
					width: 150,
					// pinned: "left",
					filter: "agSetColumnFilter",
					resizable: true,
					cellClass: function (params) {
						try {
							if (params.data.is_transporter_change_cost !== "" && params.data.is_transporter_change_cost !== undefined) {
								if (params.data.is_transporter_change_cost === 1) {
									return ["cellstylegridO"]
								}
							}
							else {
								return "";
							}
						} catch (error) {

						}
					}
				},

				{
					headerName: "Ship To Party Code",
					field: "consignee_code",
					width: 150,
					// pinned: "left",
					filter: "agSetColumnFilter",
					resizable: true,
					cellClass: function (params) {
						try {
							if (params.data.is_transporter_change_cost !== "" && params.data.is_transporter_change_cost !== undefined) {
								if (params.data.is_transporter_change_cost === 1) {
									return ["cellstylegridO"]
								}
							}
							else {
								return "";
							}
						} catch (error) {

						}
					}
				},
				{
					headerName: "Ship To Party Name",
					field: "consignee_name",
					width: 200,
					// pinned: "left",
					filter: "agSetColumnFilter",
					resizable: true,
					cellClass: function (params) {
						try {
							if (params.data.is_transporter_change_cost !== "" && params.data.is_transporter_change_cost !== undefined) {
								if (params.data.is_transporter_change_cost === 1) {
									return ["cellstylegridO"]
								}
							}
							else {
								return "";
							}
						} catch (error) {

						}
					}
				},
				{
					headerName: "Dealer City",
					field: "consignee_city",
					width: 150,
					// pinned: "left",
					filter: "agSetColumnFilter",
					resizable: true,
					cellClass: function (params) {
						try {
							if (params.data.is_transporter_change_cost !== "" && params.data.is_transporter_change_cost !== undefined) {
								if (params.data.is_transporter_change_cost === 1) {
									return ["cellstylegridO"]
								}
							}
							else {
								return "";
							}
						} catch (error) {

						}
					}
				},
				{
					headerName: "Dealer State",
					field: "consignee_state",
					width: 150,
					// pinned: "left",
					filter: "agSetColumnFilter",
					resizable: true,
					cellClass: function (params) {
						try {
							if (params.data.is_transporter_change_cost !== "" && params.data.is_transporter_change_cost !== undefined) {
								if (params.data.is_transporter_change_cost === 1) {
									return ["cellstylegridO"]
								}
							}
							else {
								return "";
							}
						} catch (error) {

						}
					}
				},
				{
					headerName: "Dealer Zone",
					field: "zone",
					width: 150,
					// pinned: "left",
					filter: "agSetColumnFilter",
					resizable: true,
					cellClass: function (params) {
						try {
							if (params.data.is_transporter_change_cost !== "" && params.data.is_transporter_change_cost !== undefined) {
								if (params.data.is_transporter_change_cost === 1) {
									return ["cellstylegridO"]
								}
							}
							else {
								return "";
							}
						} catch (error) {

						}
					}
				},

				{
					headerName: "Invoice No",
					field: "invoice_no",
					// pinned: 'left',
					width: 150,
					resizable: true,
					cellClass: function (params) {
						try {
							if (params.data.is_transporter_change_cost !== "" && params.data.is_transporter_change_cost !== undefined) {
								if (params.data.is_transporter_change_cost === 1) {
									return ["cellstylegridO"]
								}
							}
							else {
								return "";
							}
						} catch (error) {

						}
					},
					valueGetter: function (params) {
						if (params.data.consignment_code != '' && params.data.consignment_code != undefined) {
							return params.data.consignment_code;
						}
						else {
							return "";
						}
					},
				},

				{
					headerName: 'Invoice Date',
					field: "invoice_time",
					width: 150,
					// pinned: 'left',
					//filter: "agDateColumnFilter",
					cellRenderer: '', resizable: true,
					comparator: dateComparator,

					valueGetter: function (params) {
						if (params.data.invoice_time != '' && params.data.invoice_time != undefined && params.data.invoice_time !== ' ') {
							return getHyphenDDMMMYYYYHHMM(params.data.invoice_time);
						}
						else {
							return "NA";
						}
					},
					cellClass: function (params) {
						try {
							if (params.data.is_transporter_change_cost !== "" && params.data.is_transporter_change_cost !== undefined) {
								if (params.data.is_transporter_change_cost === 1) {
									return ["cellstylegridO"]
								}
							}
							else {
								return "";
							}
						} catch (error) {

						}
					}
					// hide: (this.state.tnpReturnConsignmentsVisibilty) ? true : hideonlyPRT, // this logic was imported from msil so for hero we are commenting this as of 27-07-2022
				},

				{
					headerName: "Mode Of Dispatch",
					field: "vehicle_mode",
					width: 150,
					// pinned: "left",
					filter: "agSetColumnFilter",
					resizable: true,
					cellClass: function (params) {
						try {
							if (params.data.is_transporter_change_cost !== "" && params.data.is_transporter_change_cost !== undefined) {
								if (params.data.is_transporter_change_cost === 1) {
									return ["cellstylegridO"]
								}
							}
							else {
								return "";
							}
						} catch (error) {

						}
					}
				},


				{
					headerName: "Transporter Name",
					field: "transporter_name",
					width: 150,
					filter: "agSetColumnFilter",
					cellRenderer: '',
					resizable: true,
					valueGetter: function (params) {
						if (params.data.truck_no != "" && params.data.truck_no != "") {
							return params.data.transporter_name
						}
					},
					cellClass: function (params) {
						try {
							if (params.data.is_transporter_change_cost !== "" && params.data.is_transporter_change_cost !== undefined) {
								if (params.data.is_transporter_change_cost === 1) {
									return ["cellstylegridO"]
								}
							}
							else {
								return "";
							}
						} catch (error) {

						}
					}
				},
				{
					headerName: "Transporter Code",
					field: "transporter_code",
					width: 110,
					filter: "agSetColumnFilter",
					cellRenderer: '', resizable: true,
					valueGetter: function (params) {
						if (params.data.truck_no != "" && params.data.truck_no != "") {
							return params.data.transporter_code
						}
					},
					cellClass: function (params) {
						try {
							if (params.data.is_transporter_change_cost !== "" && params.data.is_transporter_change_cost !== undefined) {
								if (params.data.is_transporter_change_cost === 1) {
									return ["cellstylegridO"]
								}
							}
							else {
								return "";
							}
						} catch (error) {

						}
					}
				},

				{
					headerName: "Travelled Distance (KM)",
					field: "aprox_dist_travelled_from_start",
					width: 170,
					filter: "agSetColumnFilter",
					valueGetter: params => {
						let data = params.data.aprox_dist_travelled_from_start
						if (data == 0) {
							return 0
						}
						return data
						// console.log("params",params.data.aprox_dist_travelled_from_start)
					},
					cellClass: function (params) {
						try {
							if (params.data.is_transporter_change_cost !== "" && params.data.is_transporter_change_cost !== undefined) {
								if (params.data.is_transporter_change_cost === 1) {
									return ["cellstylegridO"]
								}
							}
							else {
								return "";
							}
						} catch (error) {

						}
					}
				},

				{
					headerName: "Total Distance (Matrix)",
					field: "distance_km_matrix",
					width: 170,
					filter: "agSetColumnFilter",
					valueGetter: params => {
						let data = params.data.distance_km_matrix
						if (data == 0) {
							return 0
						}
						return data
						// console.log("params",params.data.aprox_dist_travelled_from_start)
					},
					cellClass: function (params) {
						try {
							if (params.data.is_transporter_change_cost !== "" && params.data.is_transporter_change_cost !== undefined) {
								if (params.data.is_transporter_change_cost === 1) {
									return ["cellstylegridO"]
								}
							}
							else {
								return "";
							}
						} catch (error) {

						}
					}
				},
				{
					headerName: "Driver Cost (Matrix)",
					field: "driver_cost_matrix",
					width: 170,
					filter: "agSetColumnFilter",
					cellClass: function (params) {
						try {
							if (params.data.is_transporter_change_cost !== "" && params.data.is_transporter_change_cost !== undefined) {
								if (params.data.is_transporter_change_cost === 1) {
									return ["cellstylegridO"]
								}
							}
							else {
								return "";
							}
						} catch (error) {

						}
					}
				},
				{
					headerName: "Driver Cost (Enmovil)",
					field: "driver_cost_enmovil",
					width: 170,
					filter: "agSetColumnFilter",
					cellClass: function (params) {
						try {
							if (params.data.is_transporter_change_cost !== "" && params.data.is_transporter_change_cost !== undefined) {
								if (params.data.is_transporter_change_cost === 1) {
									return ["cellstylegridO"]
								}
							}
							else {
								return "";
							}
						} catch (error) {

						}
					}
				},
				{
					headerName: "Road Tax (Matrix)",
					field: "road_cost_matrix",
					width: 170,
					filter: "agSetColumnFilter",
					cellClass: function (params) {
						try {
							if (params.data.is_transporter_change_cost !== "" && params.data.is_transporter_change_cost !== undefined) {
								if (params.data.is_transporter_change_cost === 1) {
									return ["cellstylegridO"]
								}
							}
							else {
								return "";
							}
						} catch (error) {

						}
					}
				},
				{
					headerName: "Road Tax (Enmovil)",
					field: "road_cost_enmovil",
					width: 170,
					filter: "agSetColumnFilter",
					cellClass: function (params) {
						try {
							if (params.data.is_transporter_change_cost !== "" && params.data.is_transporter_change_cost !== undefined) {
								if (params.data.is_transporter_change_cost === 1) {
									return ["cellstylegridO"]
								}
							}
							else {
								return "";
							}
						} catch (error) {

						}
					}
				},
				{
					headerName: "Toll Tax (Matrix)",
					field: "toll_cost_matrix",
					width: 170,
					filter: "agSetColumnFilter",
					cellClass: function (params) {
						try {
							if (params.data.is_transporter_change_cost !== "" && params.data.is_transporter_change_cost !== undefined) {
								if (params.data.is_transporter_change_cost === 1) {
									return ["cellstylegridO"]
								}
							}
							else {
								return "";
							}
						} catch (error) {

						}
					}
				},
				{
					headerName: "Toll Tax (Enmovil)",
					field: "toll_cost",
					width: 170,
					filter: "agSetColumnFilter",
					cellClass: function (params) {
						try {
							if (params.data.is_transporter_change_cost !== "" && params.data.is_transporter_change_cost !== undefined) {
								if (params.data.is_transporter_change_cost === 1) {
									return ["cellstylegridO"]
								}
							}
							else {
								return "";
							}
						} catch (error) {

						}
					}
				},
				{
					headerName: "Diesel Cost (Matrix)",
					field: "diesel_cost_matrix",
					width: 170,
					filter: "agSetColumnFilter",
					cellClass: function (params) {
						try {
							if (params.data.is_transporter_change_cost !== "" && params.data.is_transporter_change_cost !== undefined) {
								if (params.data.is_transporter_change_cost === 1) {
									return ["cellstylegridO"]
								}
							}
							else {
								return "";
							}
						} catch (error) {

						}
					}
				},
				{
					headerName: "Diesel Cost (Enmovil)",
					field: "diesel_cost_enmovil",
					width: 170,
					filter: "agSetColumnFilter",
					cellClass: function (params) {
						try {
							if (params.data.is_transporter_change_cost !== "" && params.data.is_transporter_change_cost !== undefined) {
								if (params.data.is_transporter_change_cost === 1) {
									return ["cellstylegridO"]
								}
							}
							else {
								return "";
							}
						} catch (error) {

						}
					}
				},
				{
					headerName: "Total Transportation Cost (Matrix)",
					field: "total_transportation_cost_matrix",
					width: 170,
					filter: "agSetColumnFilter",
					cellClass: function (params) {
						try {
							if (params.data.is_transporter_change_cost !== "" && params.data.is_transporter_change_cost !== undefined) {
								if (params.data.is_transporter_change_cost === 1) {
									return ["cellstylegridO"]
								}
							}
							else {
								return "";
							}
						} catch (error) {

						}
					}
				},
				{
					headerName: "Total Transportation Cost (Enmovil)",
					field: "total_transportation_cost_enmovil",
					width: 170,
					filter: "agSetColumnFilter",
					cellClass: function (params) {
						try {
							if (params.data.is_transporter_change_cost !== "" && params.data.is_transporter_change_cost !== undefined) {
								if (params.data.is_transporter_change_cost === 1) {
									return ["cellstylegridO"]
								}
							}
							else {
								return "";
							}
						} catch (error) {

						}
					}
				},
				{
					headerName: "LSP Requested Cost",
					field: "lsp_diesel_cost",
					width: 170,
					filter: "agSetColumnFilter",
					cellClass: function (params) {
						try {
							if (params.data.is_transporter_change_cost !== "" && params.data.is_transporter_change_cost !== undefined) {
								if (params.data.is_transporter_change_cost === 1) {
									return ["cellstylegridO"]
								}
							}
							else {
								return "";
							}
						} catch (error) {

						}
					},
					cellRendererSelector: function (params) {
						try {
							if (params.data.is_transporter_change_cost !== "" && params.data.is_transporter_change_cost !== undefined) {
								if (params.data.is_transporter_change_cost === 1) {
									return { component: 'ViewMatrixCosts' }
								}
								else {
									return "";
								}
							}
							else {
								return "";
							}
						} catch (error) {

						}
					},
				},
				{
					headerName: "LSP Yard Gate-in",
					field: "lsp_yard_in",
					width: 170,
					filter: "agSetColumnFilter",
					cellClass: function (params) {
						try {
							if (params.data.is_transporter_change_cost !== "" && params.data.is_transporter_change_cost !== undefined) {
								if (params.data.is_transporter_change_cost === 1) {
									return ["cellstylegridO"]
								}
							}
							else {
								return "";
							}
						} catch (error) {

						}
					},
					valueGetter: function (params) {
						try {
							if (params.data.lsp_yard_in !== "" && params.data.lsp_yard_in !== undefined) {
								return getDDMMMYYYYHHMMDefault(params.data.lsp_yard_in);
							}
							else {
								return "";
							}
						} catch (error) {

						}
					},
				},
				{
					headerName: "LSP Yard Gate-Out",
					field: "lsp_yard_out",
					width: 170,
					filter: "agSetColumnFilter",
					cellClass: function (params) {
						try {
							if (params.data.is_transporter_change_cost !== "" && params.data.is_transporter_change_cost !== undefined) {
								if (params.data.is_transporter_change_cost === 1) {
									return ["cellstylegridO"]
								}
							}
							else {
								return "";
							}
						} catch (error) {

						}
					},
					valueGetter: function (params) {
						try {
							if (params.data.lsp_yard_out !== "" && params.data.lsp_yard_out !== undefined) {
								return getDDMMMYYYYHHMMDefault(params.data.lsp_yard_out);
							}
							else {
								return "";
							}
						} catch (error) {

						}
					},
				},
				{
					headerName: "Total Spend Time At Yard (Days)",
					field: "yard_duration",
					width: 170,
					filter: "agSetColumnFilter",
					cellClass: function (params) {
						try {
							if (params.data.is_transporter_change_cost !== "" && params.data.is_transporter_change_cost !== undefined) {
								if (params.data.is_transporter_change_cost === 1) {
									return ["cellstylegridO"]
								}
							}
							else {
								return "";
							}
						} catch (error) {

						}
					}
				},
				{
					headerName: "Total Yard Cost",
					field: "yard_cost",
					width: 170,
					filter: "agSetColumnFilter",
					cellClass: function (params) {
						try {
							if (params.data.is_transporter_change_cost !== "" && params.data.is_transporter_change_cost !== undefined) {
								if (params.data.is_transporter_change_cost === 1) {
									return ["cellstylegridO"]
								}
							}
							else {
								return "";
							}
						} catch (error) {

						}
					}
				}
			);
		}

		// if(localStorage.getItem("user_type") === "SITE_ADMIN" || localStorage.getItem("user_type") === "ADMIN" || localStorage.getItem("user_type") === "APP_ADMIN" || localStorage.getItem("user_type") === "TECHUSER" || localStorage.getItem("user_type") === "ORGUSER"  || localStorage.getItem("user_type") === "RSO")
		// {
		var columnDefList = columnwithDefs
		// }
		// else{
		//     var columnDefList = columnwithDefsCustomer
		// }
		return (
			<div className="container-fluid" style={{ marginBottom: "60px" }}>
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					onConfirm={this.closeAlert}
				>
				</SweetAlert>
				<h5 className="mb-15p">
					<i className="icofont icofont-users cus-i"></i> <span>Vendor Payment Reconcilliation </span>

				</h5>
				<div className="card">
					<div className="card-body">
						<div className="row">
							<div className="col-xl-12 col-lg-12">
								<form id="filterForm" method="POST" className="row">
									<div className="row col-xl-12 col-lg-12" >
										<div className="form-group col-xl-3 col-lg-3">
											<label>Select Consigner </label>
											<Select
												placeholder={"All"}
												closeMenuOnSelect={false}
												value={this.state.consigner}
												isMulti="true"
												className="border-radius-0"
												onChange={this.changeConsignerArrayHandler.bind(this)}
												style={{ borderRadius: "09px" }}
												options={this.selectConsignerOptionsItems()}
												required
											/>

										</div>
										<div className="col-xl-2 col-lg-2 form-group">
											<label>From Date</label>
											<Datetime
												value={this.state.startDate}
												disableCloseOnClickOutside={false}
												closeOnSelect={true}
												inputProps={{ placeholder: 'From', name: 'startDate', autoComplete: 'off', readOnly: true }}
												dateFormat="YYYY-MM-DD"
												name="startDate"
												onChange={this.handlerStartDateTime.bind(this)} />
										</div>

										<div className="col-xl-2 col-lg-2 form-group">
											<label>To Date</label>
											<Datetime
												value={this.state.endDate}
												disableCloseOnClickOutside={false}
												closeOnSelect={true}
												inputProps={{ placeholder: 'To', name: 'endDate', autoComplete: 'off', readOnly: true }}
												dateFormat="YYYY-MM-DD"
												onChange={this.handlerEndDateTime.bind(this)} />
										</div>
										{(localStorage.getItem("user_type") !== "TRANSPORTER") ?
											<div className="form-group col-xl-2 col-lg-3">
												<label>Transporter </label>
												<Select
													className="border-radius-0"
													placeholder="All"
													isMulti={false}
													id="selectedTransporter"
													style={{ borderRadius: "0px" }}
													value={this.state.flTransporter}
													onChange={this.onChangeTransporter.bind(this)}
													options={this.state.filterTransporters}
												/>
											</div>
											: ""}
										<div className="form-group col-xl-2 col-lg-3">
											<label>Date Type </label>
											<Select
												className="border-radius-0"
												isMulti={false}
												style={{ borderRadius: "0px" }}
												value={this.state.date_type}
												onChange={this.onChangeDateType.bind(this)}
												options={[{ value: "all", label: "All" }, { value: "Delivered", label: "Delivered Date" }, { value: "Dispatch", label: "Dispatch Date" }]}
											/>
										</div>
									</div>

									<div className="row col-xl-12 col-lg-12" style={{ marginLeft: "0px", padding: "0px" }}>
										<div className="form-group col-xl-3 col-lg-3">
											<label>Dealer Zone</label>
											<Select
												className="border-radius-0"
												isMulti={false}
												id="selectedCluster"
												style={{ borderRadius: "0px" }}
												value={this.state.flZone}
												onChange={this.onChangeZone.bind(this)}
												options={this.state.filterZones}
											/>
										</div>
										<div className="form-group col-xl-3 col-lg-3">
											<label>Dealer State</label>
											<Select
												className="border-radius-0"
												isMulti={false}
												id="selectedState"
												style={{ borderRadius: "0px" }}
												value={this.state.flState}
												onChange={this.onChangeDealerState.bind(this)}
												options={this.state.filterStates}
											/>
										</div>
										<div className="form-group col-xl-3 col-lg-3">
											<label>Dealer City</label>
											<Select
												className="border-radius-0"
												isMulti={false}
												id="selectedCity"
												style={{ borderRadius: "0px" }}
												value={this.state.flCity}
												onChange={this.onChangeDealerCity.bind(this)}
												options={this.state.filterCities}
											/>
										</div>

										<div className="form-group col-xl-3 col-lg-3">
											<label>Dealer</label>
											<Select
												className="border-radius-0"
												isMulti={false}
												id="selectedDealer"
												style={{ borderRadius: "0px" }}
												value={this.state.flDealer}
												onChange={this.newFilter.bind(this, "flDealer")}
												options={this.state.filterDealers}
											/>
										</div>

									</div>

									<div className="row col-xl-12 col-lg-12" style={{ marginLeft: "0px" }}>
										<button type="button" className="btn btn-success" onClick={this.onGetConsigners.bind(this)}>Submit</button>
										<button type="button" onClick={this.reloadPageData.bind(this)} className="f12" style={{ color: "#000", textDecoration: "underline", border: "0px", background: "transparent", cursor: "pointer" }}>Reset Filters</button>

									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
				{(localStorage.getItem("user_type") === "APP_ADMIN") ?

					<div className="col-xl-12 col-lg-12 col-sm-12 beffect">
						<div className="card" style={{ marginLeft: "-1.15em", marginRight: "-1.15em" }}>
							<div className="card-body" id="counterCardBody">
								<div className="crm-numbers pb-0">
									<div className="row">

										<div className="col cirlce-d cursorPointer">
											<span className="f13"><i className="icofont icofont-vehicle-delivery-van f24 txt-danger"></i><br /> Total Trucks</span>
											<h4 className="txt-danger f40">
												<span className="counter">
													<CountUp end={(this.state.rowData.length > 0) ? this.state.rowData.length : 0} />
												</span>
											</h4>

										</div>
										<div className="col cirlce-d cursorPointer">
											<span className="f13"><i className="icofont icofont-ui-browser f24 txt-info"></i><br /> Driver Cost (INR)</span>
											<h4 className="txt-info f40">
												<span className="counter">
													{/* <CountUp end={(this.state.total_driver_cost != '') ? this.state.total_driver_cost : 0} /> */}
													{formatNumber(this.state.total_driver_cost)}
												</span>
											</h4>

										</div>
										<div className="col cirlce-d cursorPointer">
											<span className="f13"><i className="icofont icofont-ui-browser f24 txt-secondary"></i><br /> Road Cost (INR)</span>
											<h4 className="txt-secondary f40">
												<span className="counter">
													{/* <CountUp end={(this.state.total_road_cost != '') ? this.state.total_road_cost : 0} /> */}
													{formatNumber(this.state.total_road_cost)}
												</span>
											</h4>

										</div>
										<div className="col cirlce-d cursorPointer">
											<span className="f13"><i className="icofont icofont-ui-browser f24 txt-primary"></i><br /> Toll Cost (INR)</span>
											<h4 className="txt-primary f40">
												<span className="counter">
													{/* <CountUp end={(this.state.total_toll_cost != '') ? this.state.total_toll_cost : 0} /> */}
													{formatNumber(this.state.total_toll_cost)}
												</span>
											</h4>

										</div>
										<div className="col cirlce-d cursorPointer">
											<span className="f13"><i className="icofont icofont-ui-browser f24 txt-success"></i><br /> Diesel Cost (INR)</span>
											<h4 className="txt-success f40">
												<span className="counter">
													{/* <CountUp end={(this.state.total_diesel_cost != '') ? this.state.total_diesel_cost : 0} /> */}
													{formatNumber(this.state.total_diesel_cost)}
												</span>
											</h4>

										</div>
										<div className="col cirlce-d cursorPointer">
											<span className="f13"><i className="icofont icofont-ui-browser f24 txt-warning"></i><br /> Total Transportation Cost (INR)</span>
											<h4 className="txt-warning f40">
												<span className="counter">
													{/* <CountUp end={(this.state.total_transportation_cost != '') ? this.state.total_transportation_cost : 0} /> */}
													{formatNumber(this.state.total_transportation_cost)}
												</span>
											</h4>

										</div>

									</div>
								</div>
							</div>
						</div>
					</div>
					: ""}
				<div className="card">
					<div className="card-body">
						{(localStorage.getItem("user_type") !== "TRANSPORTER") ?
							<div className="row mb-20p f12">
								<div className='col-sm-2 fbold'>
									Current Day Diesel Cost: <span className="f16">INR {this.state.currentDateDiesel}</span>
									{/* <input type="text" size="5" value={this.state.currentDateDiesel} name="currentDateDiesel" /> */}
								</div>
								<div className='col-sm-3 fbold'>

								</div>
								<div className='col-sm-4 fbold'>
									Matrix Last Update On : <span className="f16">{this.state.last_update_matrix}</span>
								</div>
								<div className='col-sm-3 fbold' style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", paddingTop: "20px" }}>
									<button type="button" className="btn btn-warning" onClick={this.onClickShowMatirUploadForm.bind(this)}>Upload Matrix File</button>&nbsp;&nbsp;
									<button type="button" className="btn btn-warning" onClick={this.onClickShowMatirLogForm.bind(this)}>Matrix Logs</button>&nbsp;&nbsp;
									<button type="button" className="btn btn-success" onClick={this.onClickShowFormulaSB.bind(this)}>Logics</button>&nbsp;&nbsp;
									<button type="button" className="btn btn-success" onClick={this.calculateLatestDieselCost.bind(this)}>Calcualte</button>
								</div>
							</div>

							: ""}
						<div className="row" style={{ textAlign: 'right' }}>
							<div className="col-sm-12">
								<button className="float-right custom-btn white btn-danger" onClick={this.onClickSaveGridState.bind(this)}>
									<i className="icofont icofont-save"></i> Save Grid Layout
								</button>
								<button className="float-right custom-btn white btn-info" style={{ marginRight: "10px" }} onClick={this.resetState.bind(this)}>
									<i className="icofont icofont-refresh"></i> Reset Default Layout
								</button>
							</div>
						</div>
						<div className="row">
							<div className='col-sm-12'>
								<div id="myGrid" style={{ height: "580px", width: "100%" }} className="ag-theme-balham">
									<AgGridReact
										modules={this.state.modules}
										columnDefs={columnDefList}
										defaultColDef={this.state.defaultColDef}
										rowData={this.state.rowData}
										// onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
										enableCharts={true}
										enableRangeSelection={true}
										autoGroupColumnDef={this.state.autoGroupColumnDef}
										onGridReady={this.onGridReady}
										onGridState={this.onGridState}
										statusBar={this.state.statusBar}
										sideBar={this.state.sideBar}
										paginationPageSize={this.state.paginationPageSize}
										pagination={true}
										frameworkComponents={this.state.frameworkComponents}
										detailCellRendererParams={this.state.detailCellRendererParams}
										//editType={this.state.editType}
										stopEditingWhenGridLosesFocus={true}
										//floatingFilter={true}

										enableCellChangeFlash={false}
										//suppressCellFlash={true}
										rowClassRules={this.state.rowClassRules}
										// onCellDoubleClicked={this.onRowClicked.bind(this)}
										gridOptions={{
											context: { componentParent: this }
										}}
										masterDetail={true}
										rowHeight={this.state.rowHeight}
										// onCellEditingStopped={this.onCellUpdateData.bind(this)}

										rowSelection={this.state.rowSelection}
										// onRowSelected={this.onRowSelection.bind(this)}
										suppressRowClickSelection={true}
										// suppressCellSelection= {true}
										overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
									// excelStyles={this.state.excelStyles}
									// onCellClicked={this.onCellClicked.bind(this)}
									/>

									{/*
							<div className={"dataLoadpage " +(this.state.loadshow)}>
							</div>
							<div className={"dataLoadpageimg " +(this.state.loadshow)}>
								<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
							</div> */}

								</div>
							</div>
						</div>


					</div>
				</div>

				<div className={"dataLoadpage " + (this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " + (this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>


				<div className={"slide-r " + (this.state.sliderTranslateMatrix)} style={{ overflow: "hidden" }}>
					<h3 className="subH">Upload Matrix
						<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
					</h3>

					<div className="slide-r-body" style={{ position: "relative" }}>

						<div className="container-fluid">
							<form method="POST" id="upform" className="theme-form" onSubmit={this.uploadMatrixFile.bind(this)}>

								<div className="form-group mt-20p">
									<label className="">Upload File</label>
									<input type="file" name="uploadFile" id="bulkUploadPID" onChange={this.changeXLSXFileHandler} className="form-control" required />
								</div>
								<div className="form-group">
									<button type="submit" className="btn btn-success">Submit</button>
									<button type="button" onClick={this.onClickHideAll.bind(this)} className="btn btn-default">Cancel</button>
								</div>
							</form>

						</div>
					</div>
				</div>

				<div className={"slide-r " + (this.state.formulaSB)} style={{ overflow: "auto", width:"50%" }}>
					<h3 className="subH">Vendor Payment Reconciliation Logics
						<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
					</h3>
					<div className="slide-r-body" style={{ position: "relative" }}>
						<div className="container-fluid">
							<div className="form-group mt-10p">
								<label className="font-weight-bold">Road Cost Calculation</label>
								<div>
									<p style={{ paddingLeft: '14px', letterSpacing: "0px", fontSize: "16px" }}><b>&#10003;</b>&nbsp;&nbsp;&nbsp;The cost calculation is per the "Road Cost Matrix" shared.</p>
									<p style={{ paddingLeft: '14px', letterSpacing: "0px", fontSize: "16px" }}><b>&#10003;</b>&nbsp;&nbsp;&nbsp;The road cost is calculated as per the cost defined in the Road Cost Matrix based on the consignee code and description.</p>
								</div>
								<label className="font-weight-bold">Driver Cost Calculation</label>
								<div>
									<p style={{ paddingLeft: '14px', letterSpacing: "0px", fontSize: "16px" }}><b>&#10003;</b>&nbsp;&nbsp;&nbsp;The Driver Cost is calculated based on the total distance traveled and a distance-dependent slab rate considered from the Slab Rate Master shared by DICV.</p>
									<p style={{ paddingLeft: '14px', letterSpacing: "0px", fontSize: "16px" }}><b>&#10003;</b>&nbsp;&nbsp;&nbsp;This slab rate considers whether it's a single driver or double driver scenario.</p>
									<p style={{ paddingLeft: '14px', letterSpacing: "0px", fontSize: "16px" }}><b>&#10003;</b>&nbsp;&nbsp;&nbsp;<b>Formula:</b> Total Distance Travelled * LSP Slab Rate (Based on KM).</p>
								</div>
								<label className="font-weight-bold">Diesel Cost Calculation</label>
								<div>
									<p style={{ paddingLeft: '14px', letterSpacing: "0px", fontSize: "16px" }}><b>&#10003;</b>&nbsp;&nbsp;&nbsp;The system retrieves the relevant diesel rate defined by the admin user in the application. The cost will be effective from the date it is defined in the application.</p>
									<p style={{ paddingLeft: '14px', letterSpacing: "0px", fontSize: "16px" }}><b>&#10003;</b>&nbsp;&nbsp;&nbsp;Mileage information is considered from the Mileage Master shared by the DICV team with respect to description code.</p>
									<p style={{ paddingLeft: '14px', letterSpacing: "0px", fontSize: "16px" }}><b>&#10003;</b>&nbsp;&nbsp;&nbsp;<b>Formula:</b> (Total Distance Travelled / Mileage Rate) * Diesel Rate</p>
								</div>
								<label className="font-weight-bold">Toll Cost Calculation</label>
								<div>
									<p style={{ paddingLeft: '14px', letterSpacing: "0px", fontSize: "16px" }}><b>&#10003;</b>&nbsp;&nbsp;&nbsp;Only tolls that accept FASTag are considered for calculation.</p>
									<p style={{ paddingLeft: '14px', letterSpacing: "0px", fontSize: "16px" }}><b>&#10003;</b>&nbsp;&nbsp;&nbsp;The Enmovil system is integrated with the NHAI API to retrieve toll costs.</p>
									<p style={{ paddingLeft: '14px', letterSpacing: "0px", fontSize: "16px" }}><b>&#10003;</b>&nbsp;&nbsp;&nbsp;Since vehicle model is not provided, by default “Multi-Axile Single” category is considered.</p>
								</div>
								<label className="font-weight-bold">Total Transportation Cost Calculation</label>
								<div>
									<p style={{ paddingLeft: '14px', letterSpacing: "0px", fontSize: "16px" }}><b>&#10003;</b>&nbsp;&nbsp;&nbsp;The Total Transportation Cost is the sum of all the individual cost components: Driver cost, Diesel cost, Road cost, and Toll cost.</p>
									<p style={{ paddingLeft: '14px', letterSpacing: "0px", fontSize: "16px" }}><b>&#10003;</b>&nbsp;&nbsp;&nbsp;<b>Formula:</b> Transportation Cost = Driver Cost + Diesel Cost + Road Cost + Toll Cost</p>
								</div>
							</div>
						</div>
					</div>
				</div>


				<div className={"slide-r " + (this.state.viewMartixDocs)} style={{ overflow: "hidden" }}>
					<h3 className="subH">Previous Matrix Documents
						<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
					</h3>

					<div className="slide-r-body" style={{ position: "relative" }}>

						<div className="container-fluid">

							{
								(this.state.matrixData.length > 0) ?

									<table width={"100%"} style={{ border: "1px solid black" }}>
										<thead style={{ border: "1px solid black" }}>
											<tr style={{ borderSpacing: '1rem', background: "#eef3ff", border: "1px solid black" }}>
												<td style={{ padding: "1rem", border: "1px solid black" }}>
													File
												</td>
												<td style={{ padding: "0.5rem", border: "1px solid black" }}>
													Uploaded Date
												</td>
											</tr>
										</thead>
										<tbody>
											{
												this.state.matrixData.map(each => {
													return (
														<tr style={{ border: "1px solid black" }}>
															<td style={{ padding: "0.5rem", border: "1px solid black" }}>
																<a
																	href={(each.excel_file != "" && each.excel_file != undefined) ? each.excel_file : "javascript:;"}
																	target="_blank"
																	title="Download Document">
																	View Document</a>
															</td>
															<td style={{ padding: "0.5rem", border: "1px solid black" }}>
																{(each.created_on == undefined || each.created_on === "") ? "NA" : moment.parseZone(each.created_on, 'YYYYMMDDHHmmss').format('DD-MM-YYYY h:mm A')}
															</td>
														</tr>
													)
												})
											}
										</tbody>
									</table>
									:
									<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
										No Data Found
									</div>
							}

						</div>
					</div>
				</div>


				<div className={"slide-r " + (this.state.sliderTranslateChangeMatrix)} style={{ overflow: "hidden" }}>
					<h3 className="subH">Change LSP Requested Matrix Costs
						<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
					</h3>

					<div className="slide-r-body" style={{ position: "relative" }}>

						<div className="container-fluid">
							<form method="POST" id="upform" className="theme-form" onSubmit={this.changeRequestMatrixCost.bind(this)}>

								<div className="form-group mt-20p">
									<label className="">Driver Cost</label>
									<input type="value" name="lsp_driver_cost" id="lsp_driver_cost" value={this.state.lsp_driver_cost} onChange={this.changeHandler.bind(this)} className="form-control" placeholder='0.00' required />
								</div>
								<div className="form-group mt-20p">
									<label className="">Road Cost</label>
									<input type="value" name="lsp_road_cost" id="lsp_road_cost" value={this.state.lsp_road_cost} onChange={this.changeHandler.bind(this)} className="form-control" placeholder='0.00' required />
								</div>
								<div className="form-group mt-20p">
									<label className="">Toll Cost</label>
									<input type="value" name="lsp_toll_cost" id="lsp_toll_cost" value={this.state.lsp_toll_cost} onChange={this.changeHandler.bind(this)} className="form-control" placeholder='0.00' required />
								</div>
								<div className="form-group mt-20p">
									<label className="">Diesel Cost</label>
									<input type="value" name="lsp_diesel_cost" id="lsp_diesel_cost" value={this.state.lsp_diesel_cost} onChange={this.changeHandler.bind(this)} className="form-control" placeholder='0.00' required />
								</div>
								<div className="form-group">
									<button type="submit" className="btn btn-success">Submit</button>
									<button type="button" onClick={this.onClickHideAll.bind(this)} className="btn btn-default">Cancel</button>
								</div>
							</form>

						</div>
					</div>
				</div>
				<div className={"slide-r " + (this.state.sliderTranslateViewMatrix)} style={{ overflow: "hidden" }}>
					<h3 className="subH">View LSP Requested Matrix Costs
						<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
					</h3>

					<div className="slide-r-body" style={{ position: "relative" }}>

						<div className="container-fluid">
							<form method="POST" id="upform" className="theme-form">
								{
									(this.state.viewMatrixRowData.length > 0) ?
										<table className="table table-bordered">
											<tr>
												<th>Content</th>
												<th>Old Cost</th>
												<th>Modified Cost</th>
											</tr>

											<tr>
												<td>Driver Cost</td>
												<td>{this.state.viewMatrixRowData[0].driver_cost_matrix}</td>
												<td>{this.state.viewMatrixRowData[0].lsp_driver_cost}</td>
											</tr>
											<tr>
												<td>Road Cost</td>
												<td>{this.state.viewMatrixRowData[0].road_cost_matrix}</td>
												<td>{this.state.viewMatrixRowData[0].lsp_road_cost}</td>
											</tr>
											<tr>
												<td>Toll Cost</td>
												<td>{this.state.viewMatrixRowData[0].toll_cost_matrix}</td>
												<td>{this.state.viewMatrixRowData[0].lsp_toll_cost}</td>
											</tr>
											<tr>
												<td>Diesel Cost</td>
												<td>{this.state.viewMatrixRowData[0].diesel_cost_matrix}</td>
												<td>{this.state.viewMatrixRowData[0].lsp_diesel_cost}</td>
											</tr>
										</table>
										: ""
								}
								{
									(localStorage.getItem("user_type") !== "TRANSPORTER") ?
										(this.state.viewMatrixRowData.length > 0) ?
											(this.state.approveLevels.length > 0) ?
												((localStorage.getItem("email") == this.state.approveLevels[0].email_level_1 && this.state.approveLevels[0].email_level_1 != undefined)) ?
													(this.state.viewMatrixRowData[0].send_for_approval_level_1 == 0) ?
														<div className="form-group">
															<button type="button" onClick={this.approveLSPCosts.bind(this)} className="btn btn-success">Approve</button>
															<button type="button" onClick={this.rejectLSPCosts.bind(this)} className="btn btn-danger" style={{ marginLeft: '10px', marginRight: '10px' }}>Reject</button>
															<button type="button" onClick={this.onClickHideAll.bind(this)} className="btn btn-default">Cancel</button>
														</div>
														:
														(this.state.viewMatrixRowData[0].send_for_approval_level_1 == 1 && this.state.viewMatrixRowData[0].send_for_approval_level_2 == 0) ?
															<button type="button" className="btn btn-default mt-20p col-sm-12">Status: <b>Pending for level 2 approval</b></button>
															:

															(this.state.viewMatrixRowData[0].send_for_approval_level_1 == 2) ?
																<button type="button" className="btn btn-danger mt-20p col-sm-12">Status: <b>Rejected</b></button>
																: ""

													: ""
												: ""
											: ""
										: ""
								}

								{
									(localStorage.getItem("user_type") !== "TRANSPORTER") ?
										(this.state.approveLevels.length > 0) ?
											(this.state.viewMatrixRowData.length > 0) ?
												((localStorage.getItem("email") == this.state.approveLevels[0].email_level_2 && this.state.approveLevels[0].email_level_2 != undefined)) ?
													(this.state.viewMatrixRowData[0].send_for_approval_level_2 == 0) ?
														<div className="form-group">
															<button type="button" onClick={this.approveLSPCostsFinal.bind(this)} className="btn btn-success">Approve</button>
															<button type="button" onClick={this.rejectLSPCostsFinal.bind(this)} className="btn btn-danger" style={{ marginLeft: '10px', marginRight: '10px' }}>Reject</button>
															<button type="button" onClick={this.onClickHideAll.bind(this)} className="btn btn-default">Cancel</button>
														</div>
														:
														(this.state.viewMatrixRowData[0].send_for_approval_level_2 == 2) ?
															<button type="button" className="btn btn-danger mt-20p col-sm-12">Status: <b>Rejected</b></button>
															: ""

													: ""
												: ""
											: ""
										: ""
								}
								{
									(localStorage.getItem("user_type") === "TRANSPORTER") ?
										<button type="button" className="btn btn-default mt-20p col-sm-12">Status: <b>Pending for approval</b></button>
										: ""
								}
							</form>

						</div>
					</div>
				</div>

			</div>
		)
	}
}



// function loadScript(url){
//     var index  =window.document.getElementsByTagName("script")[0]
//     var script = window.document.createElement("script")
//     script.src=url
//     script.async=true
//     script.defer = true
//     index.parentNode.insertBefore(script,index)
// }
function isEmpty(obj) {
	for (var key in obj) {
		if (obj.hasOwnProperty(key))
			return false;
	}
	return true;
}
function timeConvert(n) {
	// var num = n/1000;
	// var hours = (num / (3600*24));
	// var rhours = Math.floor(hours);
	// var minutes = (n) / (60*60);
	// var rminutes = Math.round(minutes);

	var diffMs = n;
	var diffDays = Math.floor(diffMs / 86400000); // days
	var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
	var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes


	return diffHrs + " hour(s) and " + (diffMins / 60) + " minute(s).";
}
var currentinfowindow = null;
function getTimeInfo(marker, timedet, coords) {
	var timeinfo = new window.google.maps.InfoWindow({
		content: "Time at this location: " + timedet
	});

	marker.addListener('click', function () {

		if (currentinfowindow != null) {
			currentinfowindow.close();
			currentinfowindow = timeinfo;
			timeinfo.open(marker.get('map'), marker);
		}
		else {
			currentinfowindow = timeinfo;
			timeinfo.open(marker.get('map'), marker);
		}

	});
}



function secondsToString(seconds) {
	var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}

function secondsToDays(seconds) {
	var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numdays;
}
function CheckForHtmlTag() {
	var rs = document.getElementById("invalid_reason");
	var reg = /<(.|\n)*?>/g;
	if (reg.test(rs.value)) {
		var ErrorText = "Oops! HTML or Script is not allowed.";
		rs.value = ''
		//alert();
		return 1;
	}
}
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
		// console.log(date1,date2);
		var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
		date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
		var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
		date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
		// console.log(date1,date2);
		var date1Number = monthToComparableNumber(date1);
		var date2Number = monthToComparableNumber(date2);
		// console.log(date1Number,date2Number);
		if (date1Number === null && date2Number === null) {
			return 0;
		}
		if (date1Number === null) {
			return -1;
		}
		if (date2Number === null) {
			return 1;
		}
		return date1Number - date2Number;
	}
}
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	//console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName) {
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
	];
	var monthNum = months.indexOf(monthName) + 1;
	if (monthNum < 10) {
		monthNum = "0" + monthNum.toString();
	}
	return monthNum;
}

function loadDateTimeScript() {
	$('#reached_dealer_time').datetimepicker({
		mask: '39-19-9999 29:59',
		format: 'd-m-Y H:i',
		onShow: false
	});
	$('#left_dealer_time').datetimepicker({
		mask: '39-19-9999 29:59',
		format: 'd-m-Y H:i',
		onShow: false
	});
	$('.datetimepicker_date').datetimepicker({
		mask: '39-19-9999',
		format: 'd-m-Y',
		timepicker: false,
		onShow: false
	});
	$('#invoice_date').datetimepicker({
		mask: '39-19-9999 29:59',
		format: 'd-m-Y H:i'
	});
	$('#gate_out_time').datetimepicker({
		mask: '39-19-9999 29:59',
		format: 'd-m-Y H:i'
	});

	var index = window.document.getElementsByTagName("script")[1]
	var script = window.document.createElement("script")
	script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async = true
	script.defer = true
	index.parentNode.insertBefore(script, index)
}


function GetSortDescOrder(prop) {
	return function (a, b) {
		if (a[prop] < b[prop]) {
			return 1;
		} else if (a[prop] > b[prop]) {
			return -1;
		}
		return 0;
	}
}


function GetSortAscOrder(prop) {
	return function (a, b) {
		if (a[prop] > b[prop]) {
			return 1;
		} else if (a[prop] < b[prop]) {
			return -1;
		}
		return 0;
	}
}

function secondsToD(seconds) {
	seconds = Number(seconds);
	var d = Math.floor(seconds / (3600 * 24));
	var h = Math.floor((seconds % (3600 * 24)) / 3600);
	var m = Math.floor((seconds % 3600) / 60);
	var s = Math.floor(seconds % 60);

	var dDisplay = d >= 0 ? d : 0;
	return dDisplay;
}
function secondsToDhms(seconds) {
	seconds = Number(seconds);
	var d = Math.floor(seconds / (3600 * 24));
	var h = Math.floor((seconds % (3600 * 24)) / 3600);
	var m = Math.floor((seconds % 3600) / 60);
	var s = Math.floor(seconds % 60);

	var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
	var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
	var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
	var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
	return dDisplay + hDisplay + mDisplay;
}

function secondsToMinutesSeconds(seconds) {
	// var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numhours + " hours " + numminutes + " minutes " + numseconds + ' seconds';
}
function formatNumber(num) {
	if (num >= 10000000) {
		return (num / 10000000).toFixed(2) + ' Crore';
	} else if (num >= 100000) {
		return (num / 100000).toFixed(2) + ' Lakh';
	} else if (num >= 1000) {
		return (num / 1000).toFixed(2) + ' Thousand';
	} else {
		return num.toString();
	}
}