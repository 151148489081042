import React, { Component } from 'react';
import { render } from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _, { escape } from "lodash";
import Select from 'react-select';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
// //import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import { getDDMMYYYY, getDDMMYYYYHHMMSS,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY } from '../common/utils'; 
import 'react-vertical-timeline-component/style.min.css';
import DrawMap from '../common/drawmap';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import ViewPODFile from "./viewpodfileaction";
import MarkasCompletedBtn from "./btn.js";
import Logsbtn from "./logsbtn.js"
import CommentItem from "./commentItem.js"
import UpdateManualStatusBtn from "./updateManualStatusBtn.js"
import CountUp from 'react-countup';
import { func } from 'prop-types';
import {verifyColumns} from "../common/verifyCSVFileFields";
import CSVFileValidator from 'csv-file-validator'
// import { getElockTrucksList } from '../../../../carlsberg_node/routes/controller/consignmentController';
 
var redirectURL = require('../redirectURL');
var moment = require('moment');
var Constant = require('../common/Constant');
var googleAnalytics = require("../common/googleanalytics");

const cipher = salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);

    return text => text.split('')
        .map(textToChars)
        .map(applySaltToChar)
        .map(byteHex)
        .join('');
}
var encode = cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
export default class DeviceLogs extends Component{

	constructor(props){
		super(props);
		this.state={
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			loadshow:'show-n',
			showDiv:'show-n',
			overly:'show-n',
			alert:null,
            show: false,
            basicTitle:'',
			basicType:"default",
			modules: AllModules,
			sidebarSubHeader:'',
      	    defaultColDef: {
      	    	sortable: true,
				filter: true,
				resizable: true

      	    },
      	    rowData: [],
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
			  overlayNoRowsTemplate: "",
			  frameworkComponents: {
                ViewPODFile:ViewPODFile,
				MarkasCompletedBtn:MarkasCompletedBtn,
				Logsbtn:Logsbtn,
				UpdateManualStatusBtn:UpdateManualStatusBtn,
				CommentItem:CommentItem
			},
            animateRows: true,
      	    debug: true,
      	    showToolPanel: false,
			uppressAggFuncInHeader: true,
				
			childconfs: [],
			childrow:[],
			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams:{},
			rowClassRules: {
				
            },
            transporterslist:[],
            container_no:'',
            updatesearatebtn:'show-n',
            searates:'',
            pipavaContainers:[],
            mundraContainers:[],
            containerslist:[],
            errorMessage:'',
            successMessage:'',
			transporter_code:'',
			alltransporters:[],
			components: { datePicker: getDatePicker() },
			totalpod:[],
            pendingpod:[],
            completedpod:[],
			sliderAddTransporter:"",
			rowDataforHistory:[],
			atplant:[],
			returntoplant:[],
			intransittocustomer:[],
			proximitycustomerlocation:[],
			nearcustomervisinity:[],
			leftcustomervisinity:[],
			reachedcustomervisinity:[],
			proximitytripclosure:[],
			possibletripclosure:[],
			intransit:[],
			atdestination:[],
			unloadingForPickup:[],
			noprocess:[],
			returnInTransit:[],
			misplace:[],
			notAvailable:[],
			nearCustomer:[],
			check:0,
			sliderForpivottable: "",
			manual_status: "",
			device_status:'',
			plantList: [],
			waitingforunload: [],
			fixedgps: [],
			gpswithdriver: [],
			gpswithtransporter: [],
			gpsdepositedatdepot: [],
			drivernotresponding: [],
			returnintransit: [],
			misplace: [],
			returned: [],
			intranit:[],
			emptystatus: [],
			totaltrucks:[],
			rsodata:[],
			yardhold:[],
			tripdeveation:[],
			lspdata:[],
			gpsdrivelsp:[],
			gpsnotfound:[],
			gpscarriercustomer:[],
			missing:[],
			pendingpickup:[],
			blanksforreachedandnearstatus:[],
			epodPending:[],
			epodDone:[],
			lspPending:[],
			lspDone:[],
			cp1:[],
			cp2:[],
			cp3:[],
			cp4:[],
			pp1:[],
			pp2:[],
			pp3:[],
			pp4:[],
			rt1:[],
			rt2:[],
			rt3:[],
			rt4:[],
			sliderEmailAllDeviceHistory:'',
			email:localStorage.getItem('email') || '',
			sliderTranslateBulk:"",
			uploaddeviceLogsBulk:[],
		}
		
        this.onLoadGetShipments = this.onLoadGetShipments.bind(this);
        this.onClickUpdateItem = this.onClickUpdateItem.bind(this);
		this.onClickMarkAsPriorityBtn = this.onClickMarkAsPriorityBtn
		this.onClickUpdateManualStatusBtn = this.onClickUpdateManualStatusBtn
	}
	componentDidMount = async () => {
        var from_date = moment.parseZone().subtract(3,'days').format("YYYY-MM-DD")
        var to_date = moment.parseZone().format("YYYY-MM-DD")
        $("#osfromDate").val(from_date);
		$("#ostoDate").val(to_date);
        var parameters = {
            startdate : from_date,
			enddate : to_date
        }
        this.onLoadGetShipments(parameters);
        loadDateTimeScript();
		
    }
    onLoadGetShipments(parameters){
        this.setState({
            loadshow:"show-m"
        })
        redirectURL.post("/masters/getDevicesInfo").then(async (response) =>{
			try{
				var devicesInfo = JSON.parse(response.data.report_data) 
				var rowData = devicesInfo
				var atplant = rowData.filter(e=>e.status=="1"||e.status==1)
				var intransittocustomer = rowData.filter(e=>e.status=="2" || e.status==2)
				var proximitycustomerlocation = rowData.filter(e=>((e.status==2 || e.status==6 || e.status==5) && (e.first_25_km_entry!=null && e.first_25_km_entry!="" && e.first_25_km_entry!=undefined) && (e.proximity_trip_closure_time==null||e.proximity_trip_closure_time==""||e.proximity_trip_closure_time==undefined)))
				var nearcustomervisinity = rowData.filter(e=>e.status=="3" || e.status==3)
				var reachedcustomervisinity = rowData.filter(e=>e.status=="4"||e.status=="6"||e.status==6 || e.status==4)
				var leftcustomervisinity = rowData.filter(e=>e.status=="5" || e.status==5)
				var possibletripclosure = rowData.filter(e=>e.status=="6"||e.status==6)
				var proximitytripclosure = rowData.filter(e=>e.status=="14"||e.status==14)
				var tripcompleted = rowData.filter(e=>e.status=="15"||e.status==15)
				var waitingforunload = rowData.filter(e=>(e.device_status!=undefined) && (e.device_status.toLowerCase()=="Waiting for unloading".toLowerCase()))
				var fixedgps = rowData.filter(e=>(e.device_status!=undefined) && (e.device_status.toLowerCase()=="Fixed GPS".toLowerCase()))
				var gpswithdriver = rowData.filter(e=>(e.device_status!=undefined) && (e.device_status.toLowerCase()=="GPS With driver".toLowerCase()))
				var gpswithtransporter = rowData.filter(e=>(e.device_status!=undefined) && (e.device_status.toLowerCase()=="GPS With transporter".toLowerCase()))
				var gpsdepositedatdepot = rowData.filter(e=>(e.device_status!=undefined) && (e.device_status.toLowerCase()=="GPS deposited at depot".toLowerCase()))
				var drivernotresponding = rowData.filter(e=>(e.device_status!=undefined) && (e.device_status.toLowerCase()=="Driver not responding".toLowerCase()))
				var returnintransit = rowData.filter(e=>(e.device_status!=undefined) && (e.device_status.toLowerCase()=="Return In Transit".toLowerCase()))
				var misplace = rowData.filter(e=>(e.device_status!=undefined) && (e.device_status.toLowerCase()=="Misplace".toLowerCase()))
				var returned = rowData.filter(e=>(e.device_status!=undefined) && (e.device_status.toLowerCase()=="Returned".toLowerCase()))
				var intranit = rowData.filter(e=>(e.device_status==undefined || e.device_status=="")&& (e.status==2))
				var  returntoplant= rowData.filter(e=>((e.device_status!=undefined) && (e.device_status.toLowerCase()=="Returned to Plant".toLowerCase()))||((e.device_status==undefined || e.device_status=="") && (e.status=="" || e.status==undefined)))
				var emptystatus = rowData.filter(e=>e.device_status=="")
				var lspdata = rowData.filter(e=>e.deviation_marked=="1"||e.deviation_marked==1)
				var tripdeveation = rowData.filter(e=>e.deviation_lifetime=="1"||e.deviation_lifetime==1)


				
				var gpsdrivelsp = rowData.filter(e=>(e.device_status!=undefined) && (e.device_status.toLowerCase()=="GPS With Driver/LSP".toLowerCase()))
				var gpsnotfound = rowData.filter(e=>(e.device_status!=undefined) && (e.device_status.toLowerCase()=="GPS Not Found in Truck".toLowerCase()))
				var gpscarriercustomer = rowData.filter(e=>(e.device_status!=undefined) && (e.device_status.toLowerCase()=="GPS in Carrier/Customer".toLowerCase()))
				var missing = rowData.filter(e=>(e.device_status!=undefined) && (e.device_status.toLowerCase()=="Missing".toLowerCase()))
				var pendingpickup = rowData.filter(e=>(e.device_status!=undefined) && (e.device_status.toLowerCase()=="Pending For Pickup".toLowerCase()))
				var rsodata = rowData.filter(e=>(e.consignee_code=="40890022" && (e.status=="4"||e.status=="5"||e.status=="6"))||(e.consignee_code==40890022 && (e.status==4||e.status==5||e.status==6)))
				var yardhold = rowData.filter(e=>(e.status=="2" && e.trip_status=="2")||(e.status==2 && e.trip_status==2))
				var epodPending = rowData.filter(e=>(e.is_pod_submit!="1" && (e.status=="4"||e.status=="5"||e.status=="6"))||(e.is_pod_submit!="1" && (e.status==4||e.status==5||e.status==6)))
				var epodDone = rowData.filter(e=>e.is_pod_submit=="1" || e.is_pod_submit==1)
				var lspPending = rowData.filter(e=>((e.tpt_delivere_confirm==undefined || e.tpt_delivere_confirm=="" || e.tpt_delivere_confirm==null) && (e.status=="2"||e.status=="3"||e.status=="5"||e.status=="6"||e.status=="7"))||((e.tpt_delivere_confirm==undefined || e.tpt_delivere_confirm=="" || e.tpt_delivere_confirm==null ) && (e.status==2||e.status==3||e.status==5||e.status==6||e.status==7)))
				var lspDone = rowData.filter(e=>e.tpt_delivere_confirm=="1" || e.tpt_delivere_confirm==1)
				var blanksforreachedandnearstatus = rowData.filter(e=>(e.device_status==undefined || e.device_status=="")&& (e.status==3||e.status==4||e.status==5||e.status==6||e.status==7))

				var cp1=[]
				var cp2=[]
				var cp3=[]
				var cp4=[]

				var pp1=[]
				var pp2=[]
				var pp3=[]
				var pp4=[]

				var rt1=[]
				var rt2=[]
				var rt3=[]
				var rt4=[]


				rowData.filter(e=>(e.device_status!=undefined) && (e.device_status.toLowerCase()=="Confirmation Pending".toLowerCase())).forEach(element => {
					if ("modified_on" in element){
						const hours=moment().diff(element.modified_on,"hours")
						if(hours<=24){
							cp1.push(element)
						}
						else if(hours>24 && hours<=48){
							cp2.push(element)
						}
						else if(hours>48 && hours<=72){
							cp3.push(element)
						}
						else{
							cp4.push(element)
						}
					}
				});

				rowData.filter(e=>(e.device_status!=undefined) && (e.device_status.toLowerCase()=="Pending For Pickup".toLowerCase())).forEach(element => {
					if ("modified_on" in element){
						const hours=moment().diff(element.modified_on,"hours")
						if(hours<=24){
							pp1.push(element)
						}
						else if(hours>24 && hours<=48){
							pp2.push(element)
						}
						else if(hours>48 && hours<=72){
							pp3.push(element)
						}
						else{
							pp4.push(element)
						}
					}
				});

				rowData.filter(e=>(e.device_status!=undefined) && (e.device_status.toLowerCase()=="Return In Transit".toLowerCase())).forEach(element => {
					if ("modified_on" in element){
						const hours=moment().diff(element.modified_on,"hours")
						if(hours<=24){
							rt1.push(element)
						}
						else if(hours>24 && hours<=48){
							rt2.push(element)
						}
						else if(hours>48 && hours<=72){
							rt3.push(element)
						}
						else{
							rt4.push(element)
						}
					}
				});
				this.setState({
					rowData:rowData,
					loadshow:"show-n",
					atplant:atplant,
					yardhold,yardhold,
					rsodata:rsodata,
					lspdata:lspdata,
					tripdeveation:tripdeveation,
					pendingpickup:pendingpickup,
					missing:missing,
					gpscarriercustomer:gpscarriercustomer,
					gpsnotfound:gpsnotfound,
					gpsdrivelsp:gpsdrivelsp,
					epodPending:epodPending,
					epodDone:epodDone,
					returntoplant:returntoplant,
					lspPending:lspPending,
					lspDone:lspDone,
					intransittocustomer:intransittocustomer,
					proximitycustomerlocation:proximitycustomerlocation,
					nearcustomervisinity:nearcustomervisinity,
					reachedcustomervisinity:reachedcustomervisinity,
					leftcustomervisinity:leftcustomervisinity,
					possibletripclosure:possibletripclosure,
					proximitytripclosure:proximitytripclosure,
					tripcompleted:tripcompleted,
					waitingforunload:waitingforunload,
					fixedgps:fixedgps,
					gpswithdriver:gpswithdriver,
					gpswithtransporter:gpswithtransporter,
					gpsdepositedatdepot:gpsdepositedatdepot,
					drivernotresponding:drivernotresponding,
					returnintransit:returnintransit,
					misplace:misplace,
					returned:returned,
					emptystatus:emptystatus,
					totaltrucks:rowData,
					intranit:intranit,
					blanksforreachedandnearstatus:blanksforreachedandnearstatus,
					cp1:cp1,
					cp2:cp2,
					cp3:cp3,
					cp4:cp4,
					pp1:pp1,
					pp2:pp2,
					pp3:pp3,
					pp4:pp4,
					rt1:rt1,
					rt2:rt2,
					rt3:rt3,
					rt4:rt4,
	
				})
			}
			catch(e){

			}
        })
    }
	onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	};
	onClickSaveGridState() {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": "alltrips",
				"action": this.state.eventGridAction,
				"label": "Save Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		try {
			window.colState = this.gridColumnApi.getColumnState();
			window.groupState = this.gridColumnApi.getColumnGroupState();
			window.sortState = this.gridApi.getSortModel();
			window.filterState = this.gridApi.getFilterModel();

			let screenpage = 'devicelogs';


			let reqparams = {
				gridcolumns: window.colState,
				gridgroup: window.groupState,
				gridcolsort: window.sortState,
				gridcolfilter: window.filterState,
				userId: localStorage.getItem("userid"),
				screenurl: window.location.pathname,
				screentitle: "devicelogs"
			}
			//console.log("reqparams ", reqparams)
			redirectURL.post("/consignments/saveGridStates", reqparams)
				.then((response) => {
					//console.log("State response ",response.data)
					this.setState({
						show: true,
						basicTitle: "Successfully saved grid layout",
						basicType: "success",
						screenurl: window.location.pathname,
						screentitle: screenpage
					})
				})
				.catch(function (e) {
					console.log("Error ", e)
				})
		}
		catch (e) { }
	}
	resetState = () => {

		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Reset Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		try {

			this.gridColumnApi.resetColumnState();
			this.gridColumnApi.resetColumnGroupState();
			this.gridApi.setSortModel(null);
			this.gridApi.setFilterModel(null);
			//console.log('column state reset');
			var reqparams = {
				userId: localStorage.getItem("userid"),
				screenurl: window.location.pathname,
				screentitle: this.state.screenpage
			}

			redirectURL.post("/consignments/removeUserGridState", reqparams)
				.then((response) => {
					this.setState({
						show: true,
						basicTitle: "Successfully reset default grid layout",
						basicType: "success"
					})
				})
		}
		catch (e) { }

	};
	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
	}
	onClickHideAll(){
		var uploadfilebulk = $("#uploadBulk").val(null)
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderRouteTranslate:'',
			tabsliderTranslate:'',
			sliderAddTransporter:"",
			sliderEmailAllDeviceHistory:'',
			sliderTranslateBulk:"",
			uploaddeviceLogsBulk:[],
			invalid_devices:''
		});
	}
	onClickHide(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderAddTransporter:'',
			sliderEditTransporter:"",
			sliderEmailAllDeviceHistory:'',
			sliderTranslateBulk:"",
			uploaddeviceLogsBulk:[],
			invalid_devices:'',
		});
		
    }
	closeAlert = () => {
        this.setState({
            show: false
        });
    }
	onClickCounter(param){
        console.log("onClickCounter_parm",param)
        if(param == "0"){
			console.log("test")
            this.setState({
                rowData:this.state.yardhold,
                loadshow:"show-n"
            })
        }
		if(param == "1"){
            this.setState({
                rowData:this.state.intransittocustomer,
                loadshow:"show-n"
            })
        }
		if(param == "2"){
            this.setState({
                rowData:this.state.proximitycustomerlocation,
                loadshow:"show-n"
            })
        }
		if(param == "3"){
            this.setState({
                rowData:this.state.nearcustomervisinity,
                loadshow:"show-n"
            })
        }
		if(param == "4"){
            this.setState({
                rowData:this.state.reachedcustomervisinity,
                loadshow:"show-n"
            })
        }
		if(param == "5"){
            this.setState({
                rowData:this.state.rsodata,
                loadshow:"show-n"
            })
        }
		if(param == "6"){
            this.setState({
                rowData:this.state.possibletripclosure,
                loadshow:"show-n"
            })
        }
		if(param == "7"){
            this.setState({
                rowData:this.state.lspdata,
                loadshow:"show-n"
            })
        }
		if(param == "8"){
            this.setState({
                rowData:this.state.gpsnotfound,
                loadshow:"show-n"
            })
        }
		if(param == "9"){
            this.setState({
                rowData:this.state.gpscarriercustomer,
                loadshow:"show-n"
            })
        }
		if(param == "10"){
            this.setState({
                rowData:this.state.missing,
                loadshow:"show-n"
            })
        }
		if(param == "11"){
            this.setState({
                rowData:this.state.returntoplant,
                loadshow:"show-n"
            })
        }
		if(param == "12"){
            this.setState({
                rowData:this.state.returnintransit,
                loadshow:"show-n"
            })
        }
		if(param == "13"){
            this.setState({
                rowData:this.state.gpsdrivelsp,
                loadshow:"show-n"
            })
        } 
		if(param == "14"){
            this.setState({
                rowData:this.state.waitingforunload,
                loadshow:"show-n"
            })
        }
		if(param == "15"){
            this.setState({
                rowData:this.state.fixedgps,
                loadshow:"show-n"
            })
        }
		if(param == "16"){
            this.setState({
                rowData:this.state.drivernotresponding,
                loadshow:"show-n"
            })
        }
		if(param == "17"){
            this.setState({
                rowData:this.state.emptystatus,
                loadshow:"show-n"
            })
        }
		if(param == "18"){
            this.setState({
                rowData:this.state.totaltrucks,
                loadshow:"show-n"
            })
        }
		if(param == "19"){
            this.setState({
                rowData:this.state.tripdeveation,
                loadshow:"show-n"
            })
        }
		if(param == "20"){
            this.setState({
                rowData:this.state.epodPending,
                loadshow:"show-n"
            })
        }
		if(param == "21"){
            this.setState({
                rowData:this.state.epodDone,
                loadshow:"show-n"
            })
        }
		if(param == "22"){
            this.setState({
                rowData:this.state.lspPending,
                loadshow:"show-n"
            })
        }
		if(param == "23"){
            this.setState({
                rowData:this.state.lspDone,
                loadshow:"show-n"
            })
        }
		if(param == "24"){
            this.setState({
                rowData:this.state.intranit,
                loadshow:"show-n"
            })
        }
		
		if(param == "25"){
            this.setState({
                rowData:this.state.cp1,
                loadshow:"show-n"
            })
        }
		
		if(param == "26"){
            this.setState({
                rowData:this.state.cp2,
                loadshow:"show-n"
            })
        }
		
		if(param == "27"){
            this.setState({
                rowData:this.state.cp3,
                loadshow:"show-n"
            })
        }
		
		if(param == "28"){
            this.setState({
                rowData:this.state.cp4,
                loadshow:"show-n"
            })
        }
		
		if(param == "29"){
            this.setState({
                rowData:this.state.pp1,
                loadshow:"show-n"
            })
        }
		
		if(param == "30"){
            this.setState({
                rowData:this.state.pp2,
                loadshow:"show-n"
            })
        }
		
		if(param == "31"){
            this.setState({
                rowData:this.state.pp3,
                loadshow:"show-n"
            })
        }
		
		if(param == "32"){
            this.setState({
                rowData:this.state.pp4,
                loadshow:"show-n"
            })
        }
		
		if(param == "33"){
            this.setState({
                rowData:this.state.rt1,
                loadshow:"show-n"
            })
        }
		
		if(param == "34"){
            this.setState({
                rowData:this.state.rt2,
                loadshow:"show-n"
            })
        }
		
		if(param == "35"){
            this.setState({
                rowData:this.state.rt3,
                loadshow:"show-n"
            })
        }
		
		if(param == "36"){
            this.setState({
                rowData:this.state.rt4,
                loadshow:"show-n"
            })
        }
		if(param == "37"){
            this.setState({
                rowData:this.state.blanksforreachedandnearstatus,
                loadshow:"show-n"
            })
        }
		if(param == "38"){
            this.setState({
                rowData:this.state.pendingpickup,
                loadshow:"show-n"
            })
        }
    }
	onCellUpdateData = (params) => {
        
	}
	onCellValueChanged=(params)=>{
		var newValue = params.newValue 
		if(params.colDef.field=="manual_status")
			this.setState({manual_status:newValue})
		else if(params.colDef.field=="device_status")
			this.setState({device_status:newValue})
	}
	onClickUpdateManualStatusBtn(rowdata){
		this.setState({loadshow:'show-n'})
		var row = rowdata.data 
		var manualStatus = row.manual_status 
		var comment = row.comment
		var awb_no=row.awb_no
		var courier_by=row.courier_by
		var device_status = row.device_status
		// console.log(comment,"comment11")
		var deviceId = row.device_id 
		var vin_number = row.vin_number 
		var consignment_code = row.consignment_code 
		var params = {
			deviceId:deviceId,
			vin_number:vin_number,
			consignment_code:consignment_code,
			manualStatus:manualStatus,
			device_status:device_status,
			comment:comment,
			awb_no:awb_no,
			courier_by:courier_by
		}
		var email = localStorage.getItem("email")
		params.updatedBy = email 
		var updatedOn = moment.parseZone().format("YYYY-MM-DD HH:mm:ss")
		params.updatedOn = updatedOn 
		params.email = localStorage.getItem("email")
		redirectURL.post("/masters/updateManualStatus",params).then((resp)=>{
			if(resp.data.status == "success")
            {
                this.setState({
                    show:true,
                    basicTitle:"Successfully Completed",
                    basicType:"success",
                })
				var parameters = {}
				this.onLoadGetShipments(parameters);
            }
		})
	}
	onClickMarkAsPriorityBtn(rowdata){
		var row = rowdata.data 
		var deviceId = row.device_id 
		var shipmentId = row.shipment_id 
		var params= {
			deviceId:deviceId,
			shipmentId:shipmentId
		}
		var email = localStorage.getItem("email")
		params.updatedBy = email 
		var updatedOn = moment.parseZone().format("YYYY-MM-DD HH:mm:ss")
		params.updatedOn = updatedOn 
		console.log(params,"paramsfordevicess")
		redirectURL.post("/carlsberg/saveReverseLogisticsStatus",params).then((resp)=>{
			var devices = resp.data.devices 
			console.log(devices,"devices")
			if(resp.data.status == "success")
            {
                this.setState({
                    show:true,
                    basicTitle:"Successfully Completed",
                    basicType:"success"
                })
				var parameters = {}
				this.onLoadGetShipments(parameters);
            }
            else{
                this.setState({
                    show:true,
                    basicTitle:"Failed",
                    basicType:"danger"
                })
            }

		})
	}
	async onClickLogsBtn(rowdata){
		var row = rowdata.data 
		var params = {
			device_id:row.device_id,
			truck_no:row.truck_no
		}
		await redirectURL.post("/masters/getSingleDevicesInfo",params).then((response) => {
			try {
				// if(response.data.report_data!="" && response.data.report_data!=undefined){
					
				var data = JSON.parse(response.data.report_data) 
				// }
				console.log(data,"data1212")
				this.setState({
					rowDataforHistory:data
				})
				this.setState({
					sliderAddTransporter:'slider-translate',
					overly:"show-m"
				});
	
			} catch (error) {
				
			}
		})
		.catch((error)=>{
			this.setState({
				show:true,
				basicTitle:"No data available",
				basicType:"danger"
			})
		})
	}
    onClickUpdateItem(params){
        redirectURL.post("/carlsberg/updatecustomerpod",params.data)
        .then((response) => {
            if(response.data.status == "success")
            {
                this.setState({
                    show:true,
                    basicTitle:"Successfully update data",
                    basicType:"success"
                })
            }
            else{
                this.setState({
                    show:true,
                    basicTitle:"Failed to update data",
                    basicType:"danger"
                })
            }
        })
    }
	onClickAllDeviceHisotry = () => {
		this.setState({
			sliderEmailAllDeviceHistory: 'slider-translate',
			overly: "show-m"
		})
	}
	validateEmail = (email) => {
		// Define a regex pattern for email validation
		const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		// Check if the email matches the pattern
		if (!emailPattern.test(email)) {
			this.setState({
				show: true,
				basicTitle: "Please enter a valid email address.",
				basicType: "danger"
			});
			return false;
		}
		return true;
	};

	getAllDeviseHistory = async (event) => {
		event.preventDefault();
		try {
			const emailInput = this.state.email;
			const isEmailValid = this.validateEmail(emailInput);

			if (isEmailValid) {
				// console.log(isEmailValid, 'input email');
				// Perform the API call or other operations here
				var params = {
					email: this.state.email
				}
				await redirectURL.post("/masters/getAllDevicesInfo", params)
					.then((response) => {
						// console.log(response, "response all device history")
						this.setState({
							show: true,
							basicTitle: "Email sent successfully",
							basicType: "success"
						})
						this.onClickHide()

						})
					.catch(e => {
						console.log("Error Device all history ", e)
						this.setState({
							show: true,
							basicTitle: "Failed to Send Mail",
							basicType: "danger"
						})
					})
			}
		} catch (e) {
			console.log("Error all device history:", e);
		}
	};

	formBulkUpload = async (event) => {
		// Prevent the form from submitting
		event.preventDefault();
		// Get the list of devices from the state
		const devices = this.state.uploaddeviceLogsBulk;
		// Define the required and non-empty columns
		const requiredColumns = ["device_id", "vin_number", "device_status", "device_status_update_on", "manual_status", "manual_status_update_on", "awb_no", "courier_by", "comment"];
		const nonEmptyColumns = ["device_id", "vin_number"];
		// Define the list of non-numeric and non-date columns
		const nonNumericColumns = [];
		const nonDateColumns = [];
		// Check the validity of the columns
		const verificationResult = verifyColumns.checkValidations(
			devices,
			requiredColumns,
			nonEmptyColumns,
			nonNumericColumns,
			nonDateColumns
		);
		// Handle verification errors
		if (verificationResult !== "") {
			this.setState({
				show: true,
				basicTitle: verificationResult,
				basicType: "danger",
			});
			return;
		}
		// Show loading state
		this.setState({ loadshow: 'show-m' });

		try {
			// Post the list of devices to the backend
			const response = await redirectURL.post("/masters/updateBulkDeviceLogs", devices);
			if (response.data.status === "fail") {
				this.setState({
					show: true,
					basicTitle: "Failed",
					basicType: "danger",
					loadshow: 'show-n',
					overly: "show-n",
					sliderTranslate: '',
				});
			} else if (response.data.status === "success") {
				if(response.data.invalid_devices.length>0){
					this.state.invalid_devices = response.data.invalid_devices || []
					if(response.data.valid_devices_count){
						this.setState({valid_device_count:response.data.valid_devices_count})
					}
					var uploadfilebulk = $("#uploadBulk").val(null)
				}else{
				this.onClickHideAll();
				}
				this.setState({
					show: true,
					basicTitle: "Successful",
					basicType: "success",
					uploaddeviceLogsBulk: []
				});
			} else {
				this.setState({
					show: true,
					basicTitle: "Failed",
					basicType: "danger",
					loadshow: 'show-n',
					overly: "show-n",
					sliderTranslate: '',
					uploaddeviceLogsBulk: []
				});
			}
			// Load the devices again
			this.onLoadGetShipments();
		} catch (error) {
			console.error("Error:", error);
			this.setState({
				show: true,
				basicTitle: "Error occurred during upload",
				basicType: "danger",
				loadshow: 'show-n',
				overly: "show-n",
				sliderTranslate: '',
				uploaddeviceLogsBulk: []
			});
		}
		
	};


	changeDeviceCSVFileHandler = async (e) => {
		const file = e.target.files[0];
		if (!file) return;

		// Validate file type
		if (file.type !== 'text/csv' && file.type !== 'application/vnd.ms-excel') {
			this.resetFileInput(e);
			this.setState({
				uploaddeviceLogsBulk: '',
				show: true,
				basicType: 'danger',
				basicTitle: 'Please upload a file with a .csv extension only.',
			});
			return;
		}

		const config = {
			headers: [
				{ name: 'Device ID', inputName: 'device_id', required: true, requiredError: this.requiredError },
				{ name: 'Vin Number', inputName: 'vin_number', required: true, requiredError: this.requiredError },
				{ name: 'Device Status', inputName: 'device_status', required: true, requiredError: this.requiredError },
				{ name: 'Device Status Update On', inputName: 'device_status_update_on', required: true, requiredError: this.requiredError },
				{ name: 'Manual Status', inputName: 'manual_status', required: true, requiredError: this.requiredError },
				{ name: 'Manual Status Update On', inputName: 'manual_status_update_on', required: true, requiredError: this.requiredError },
				{ name: 'AWB No', inputName: 'awb_no', required: true, requiredError: this.requiredError },
				{ name: 'Courier by', inputName: 'courier_by', required: true, requiredError: this.requiredError },
				{ name: 'Comment', inputName: 'comment', required: true, requiredError: this.requiredError },
				
			]
		};

		try {
			const csvData = await CSVFileValidator(file, config);
			this.setState({ uploaddeviceLogsBulk: csvData.data });
		} catch (err) {
			console.error(err);
			this.resetFileInput(e);
			this.setState({
				uploaddeviceLogsBulk: '',
				show: true,
				basicType: 'danger',
				basicTitle: 'Error processing the CSV file.',
			});
		}

		try {
			const contents = await this.readFile(file);
			// console.log(contents); // Use contents as needed
		} catch (err) {
			console.error(err);
		}
	};

	// Function to reset the file input
	resetFileInput = (e) => {
		e.target.value = null;
	};

	// Function to generate the required error message
	requiredError = (headerName, rowNumber, columnNumber) => {
		return `${headerName} is required in row ${rowNumber} / column ${columnNumber}`;
	};

	// Function to read file content
	readFile = (file) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = (e) => resolve(e.target.result);
			reader.onerror = reject;
			reader.readAsText(file);
		});
	};



	onChangeEmailInput = (event) => {
		this.setState({ email: event.target.value })
	}
	onClickShowBulkForm() {
		this.setState({
			sliderTranslateBulk: "slider-translate-30p",
			loadshow: 'show-n',
			overly: 'show-m'
		});
	}
	render(){
		var columnwithDefs = [
			{
				headerName: "",
				field: "",				
				width: 70,
				filter: true,resizable: true,
				cellRendererSelector:function(params){	
					var rendComponent = {
						component: 'Logsbtn'
					};
					return rendComponent;
				},
			},
            {
                headerName:"Device ID",
                field:"device_id",
                width:130,
            },
			{
                headerName:"Vin Number",
                field:"vin_number",
                width:130,
            },
			{
                headerName:"Ship to",
                field:"consignee_name",
                width:130,
            },
			{
                headerName:"Ship to City",
                field:"consignee_city",
                width:130,
            },
			{
                headerName:"Ship to Code",
                field:"consignee_code",
                width:130,
            },
			{
                headerName:"Deviation",
                field:"deviation_lifetime",
                width:150,
				valueGetter:function(params){
					try {
						if (params.data.deviation_lifetime == 0) {
							return "False"
						}
						else if (params.data.deviation_lifetime == 1) {
							return "True"
						}
						else {
							return ""
						}
					}
                    catch(e){}
                }
            },
			{
                headerName:"Reached Destination Time",
                field:"recent_dealer_reported",
                width:130,
				valueGetter:function(params){
                    try{
                       
                        if(params.data.recent_dealer_reported != "" && params.data.recent_dealer_reported != "NA" && params.data.recent_dealer_reported != undefined)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.recent_dealer_reported);
                        }
                        else{
                            return "Not Available";
                        }
                    }
                    catch(e){return "Not Available"}
				}
            },
			{
                headerName:"Proximity Entry Time",
                field:"first_25_km_entry",
                width:130,
				valueGetter:function(params){
                    try{
                       
                        if(params.data.first_25_km_entry != "" && params.data.first_25_km_entry != "NA" && params.data.first_25_km_entry != undefined)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.first_25_km_entry);
                        }
                        else{
                            return "Not Available";
                        }
                    }
                    catch(e){return "Not Available"}
				}
            },
			{
                headerName:"Proximity Exit Time",
                field:"proximity_trip_closure_time",
                width:130,
				valueGetter:function(params){
                    try{
                       
                        if(params.data.proximity_trip_closure_time != "" && params.data.proximity_trip_closure_time != "NA" && params.data.proximity_trip_closure_time != undefined)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.proximity_trip_closure_time);
                        }
                        else{
                            return "Not Available";
                        }
                    }
                    catch(e){return "Not Available"}
				}
            },
			{
                headerName:"LSP Delivery Date",
                field:"tpt_confirm_delivered_on",
                width:130,
				valueGetter:function(params){
					try {
						if(params.data.tpt_confirm_delivered_on != "" && params.data.tpt_confirm_delivered_on != undefined)
						{
							var formattedTime = moment(params.data.tpt_confirm_delivered_on, "YYYYMMDDHHmmss").format("DD-MMM-YYYY HH:mm");
							return formattedTime;
						}
					} catch (error) {
						
					}
				},
            },
			{
                headerName:"Last Packet Time",
                field:"timestamp",
                width:130,
            },
			{
                headerName:"Dispatch Date",
                field:"gate_out_time",
                width:150,
				valueGetter:function(params){
                    try{
                       
                        if(params.data.gate_out_time != "" && params.data.gate_out_time != "NA" && params.data.gate_out_time != undefined)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.gate_out_time);
                        }
                        else{
                            return "Not Available";
                        }
                    }
                    catch(e){}
                },

            },
			{
                headerName:"City",
                field:"city",
                width:130,
            },
			{
                headerName:"Trip Status",
                field:"status",
                width:150,
				valueGetter:function(params){
					try {
						if (params.data.status == 1) {
							return "Inside Plant"
						}
						else if (params.data.status == 2) {
							if (params.data.trip_status == 1) {
								return "Intransit Transporter Yard";
							}
							else if (params.data.trip_status == 2) {
								return "At Transporter Yard";
							}
							else if (params.data.trip_status == 3) {
								return "Intransit";
							}
							else{
								return "Intransit"
							}
							
						}
						else if (params.data.status == 3) {
							return "Near Destination"
						}
						else if (params.data.status == 4) {
							return "Reached Destination"
						}

						else if (params.data.status == 5) {
							return "Manual Closure"
						}
						else if (params.data.status == 6) {
							return "Force Closed"
						}
						else if (params.data.status == 7) {
							return "Proximity Trip Closure"
						}
						else {
							return ""
						}
					}
                    catch(e){}
                }
            },
			{
                headerName:"Updated On",
                field:"modified_on",
                width:150,
				valueGetter:function(params){
                    try{
                       
                        if(params.data.modified_on != "" && params.data.modified_on != "NA" && params.data.modified_on != undefined)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.modified_on);
                        }
                        else{
                            return "Not Available";
                        }
                    }
                    catch(e){}
                },
            },			
			{
                headerName:"Device Status Update On",
                field:"device_status_update_on",
                width:150,
				valueGetter:function(params){
                    try{
                       
                        if(params.data.device_status_update_on != "" && params.data.device_status_update_on != "NA" && params.data.device_status_update_on != undefined)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.device_status_update_on);
                        }
                        else{
                            return "Not Available";
                        }
                    }
                    catch(e){}
                },
            },
			{
                headerName:"Manual Status Update On",
                field:"manual_status_update_on",
                width:150,
				valueGetter:function(params){
                    try{
                       
                        if(params.data.manual_status_update_on != "" && params.data.manual_status_update_on != "NA" && params.data.manual_status_update_on != undefined)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.manual_status_update_on);
                        }
                        else{
                            return "Not Available";
                        }
                    }
                    catch(e){}
                },
            },
			{
				headerName: "Device Status",
				field: "device_status",				
				resizable:true,
				width: 130,
				filter: true,
				editable:true,
				cellEditor: 'agSelectCellEditor',
				cellEditorParams: {
					values: [
						"Missing",
						"GPS With Driver/LSP",
						"GPS Not Found in Truck",
						"GPS in Carrier/Customer",
						"Return In Transit",
						"Pending For Pickup",
						"Returned to Plant",
						"Confirmation Pending"
					],
				},
			},
			{
				headerName: "Manual Status",
				field: "manual_status",
				resizable:true,
				width: 130,
				filter: true,  
				pinned: 'right',
				editable:true,
				cellEditor: 'agSelectCellEditor',
				cellEditorParams: {
					values: [
						"Reached at Deviated Location",
						"Yard Hold",
						"Deviation",
						"Device with Customer"
					],
				},
			},
			{
				headerName: "AWB No.",
				field: "awb_no",
				resizable:true,
				width: 100,
				filter: false,
				editable:true
			  },
			  {
				  headerName: "Courier by",
				  field: "courier_by",
				  resizable:true,
				  width: 100,
				  filter: false,
				  editable:true
				},
			{
				headerName: "Comment",
				field: "comment",
				resizable:true,
				pinned: 'right',
				width: 100,
				filter: false,
				editable:true
			  },
			{
				headerName: "Update",
				field: "",				
				width: 150,
				pinned: 'right',
				filter: true,resizable: true,
				cellRendererSelector:function(params){	
					var rendComponent = {
						component: 'UpdateManualStatusBtn'
					};
					return rendComponent;
				},
						
			},
			{
				headerName: "Bill To Dealer City",
				field: "dealer_city",
				width: 180

			},
			{
				headerName: "Bill To Dealer Code",
				field: "dealer_code",
				width: 180

			}
        ]
		var columnwithDefsHistory = [
			{
                headerName:"Device ID",
                field:"device_id",
                width:150,
            },
			{
                headerName:"Vin Number",
                field:"vin_number",
                width:150,
            },
			{
                headerName:"Trip Status",
                field:"status",
                width:150,
				valueGetter:function(params){
					try {
						if (params.data.status == 1) {
							return "Inside Plant"
						}
						else if (params.data.status == 2) {
							if (params.data.trip_status == 1) {
								return "Intransit Transporter Yard";
							}
							else if (params.data.trip_status == 2) {
								return "At Transporter Yard";
							}
							else if (params.data.trip_status == 3) {
								return "Intransit";
							}
							else{
								return "Intransit"
							}
							
						}
						else if (params.data.status == 3) {
							return "Near Destination"
						}
						else if (params.data.status == 4) {
							return "Reached Destination"
						}

						else if (params.data.status == 5) {
							return "Manual Closure"
						}
						else if (params.data.status == 6) {
							return "Force Closed"
						}
						else if (params.data.status == 7) {
							return "Proximity Trip Closure"
						}
						else {
							return ""
						}
					}
                    catch(e){}
                }
            },
			{
                headerName:"Mapped on",
                field:"mapped_on",
                width:150,
				valueGetter:function(params){
                    try{
                       
                        if(params.data.mapped_on != "" && params.data.mapped_on != "NA" && params.data.mapped_on != undefined)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.mapped_on);
                        }
                        else{
                            return "Not Available";
                        }
                    }
                    catch(e){}
                },
            },
			{
                headerName:"Unmapped on",
                field:"unmapped_on",
                width:150,
				valueGetter:function(params){
                    try{
                       
                        if(params.data.unmapped_on != "" && params.data.unmapped_on != "NA" && params.data.unmapped_on != undefined)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.unmapped_on);
                        }
                        else{
                            return "Not Available";
                        }
                    }
                    catch(e){}
                },
            },
			{
                headerName:"Returned On",
                field:"returned_on",
                width:150,
				valueGetter:function(params){
                    try{
                       
                        if(params.data.returned_on != "" && params.data.returned_on != "NA" && params.data.returned_on != undefined)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.returned_on);
                        }
                        else{
                            return "Not Available";
                        }
                    }
                    catch(e){}
                },
            },
			{
                headerName:"Picked Up On",
                field:"picked_up_on",
                width:150,
				valueGetter:function(params){
                    try{
                       
                        if(params.data.picked_up_on != "" && params.data.picked_up_on != "NA" && params.data.picked_up_on != undefined)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.picked_up_on);
                        }
                        else{
                            return "Not Available";
                        }
                    }
                    catch(e){}
                },
            },
			{
                headerName:"Confirmation on",
                field:"confirmation_on",
                width:150,
				valueGetter:function(params){
                    try{
                       
                        if(params.data.confirmation_on != "" && params.data.confirmation_on != "NA" && params.data.confirmation_on != undefined)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.confirmation_on);
                        }
                        else{
                            return "Not Available";
                        }
                    }
                    catch(e){}
                },
            },			
			{
                headerName:"Device Status Update On",
                field:"device_status_update_on",
                width:150,
				valueGetter:function(params){
                    try{
                       
                        if(params.data.device_status_update_on != "" && params.data.device_status_update_on != "NA" && params.data.device_status_update_on != undefined)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.device_status_update_on);
                        }
                        else{
                            return "Not Available";
                        }
                    }
                    catch(e){}
                },
            },
			{
                headerName:"Manual Status Update On",
                field:"manual_status_update_on",
                width:150,
				valueGetter:function(params){
                    try{
                       
                        if(params.data.manual_status_update_on != "" && params.data.manual_status_update_on != "NA" && params.data.manual_status_update_on != undefined)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.manual_status_update_on);
                        }
                        else{
                            return "Not Available";
                        }
                    }
                    catch(e){}
                },
            },
			{
                headerName:"Reached Destination Time",
                field:"recent_dealer_reported",
                width:130,
				valueGetter:function(params){
                    try{
                       
                        if(params.data.recent_dealer_reported != "" && params.data.recent_dealer_reported != "NA" && params.data.recent_dealer_reported != undefined)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.recent_dealer_reported);
                        }
                        else{
                            return "Not Available";
                        }
                    }
                    catch(e){return "Not Available"}
				}
            },
			{
                headerName:"Proximity Entry Time",
                field:"first_25_km_entry",
                width:130,
				valueGetter:function(params){
                    try{
                       
                        if(params.data.first_25_km_entry != "" && params.data.first_25_km_entry != "NA" && params.data.first_25_km_entry != undefined)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.first_25_km_entry);
                        }
                        else{
                            return "Not Available";
                        }
                    }
                    catch(e){return "Not Available"}
				}
            },
			{
                headerName:"Proximity Exit Time",
                field:"proximity_trip_closure_time",
                width:130,
				valueGetter:function(params){
                    try{
                       
                        if(params.data.proximity_trip_closure_time != "" && params.data.proximity_trip_closure_time != "NA" && params.data.proximity_trip_closure_time != undefined)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.proximity_trip_closure_time);
                        }
                        else{
                            return "Not Available";
                        }
                    }
                    catch(e){return "Not Available"}
				}
            },
			{
                headerName:"LSP Delivery Date",
                field:"tpt_confirm_delivered_on",
                width:130,
				valueGetter:function(params){
					try {
						if(params.data.tpt_confirm_delivered_on != "" && params.data.tpt_confirm_delivered_on != undefined)
						{
							var formattedTime = moment(params.data.tpt_confirm_delivered_on, "YYYYMMDDHHmmss").format("DD-MMM-YYYY HH:mm");
							return formattedTime;
						}
					} catch (error) {
						
					}
				},
            },
			{
                headerName:"AWB No.",
                field:"awb_no",
                width:150,
            },
			{
                headerName:"Dealer Name",
                field:"consignee_name",
                width:150,
            },
			{
                headerName:"Ship To Dealer City",
                field:"consignee_city",
                width:150,
            },
			{
                headerName:"Manual Status",
                field:"manual_status",
                width:150,
            },
			{
                headerName:"Comment",
                field:"comment",
                width:150,
            },
			{
                headerName:"Courier by",
                field:"courier_by",
                width:150,
            },
			{
				headerName: "Bill To Dealer City",
				field: "dealer_city",
				width: 180

			},
			{
				headerName: "Bill To Dealer Code",
				field: "dealer_code",
				width: 180

			}
		]	
		var columnwithDefsInvalidDevides = [
			{
                headerName:"Device ID",
                field:"device_id",
                flex:1,
            },
			{
                headerName:"Vin Number",
                field:"vin_number",
                flex:1,
            },
		]
		return (
			<div className="container-fluid">
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					onConfirm={this.closeAlert}
				>
					{this.state.invalid_devices && this.state.invalid_devices.length > 0 && (
						<div>
							Successful Devices: {this.state.valid_device_count ? this.state.valid_device_count:0 },
							Failed Device : {this.state.invalid_devices.length}
						</div>
					)}
				</SweetAlert>
				
				<div className="col-xl-12 col-lg-12 col-sm-12 beffect">
					<div className="card" style={{marginBottom:"10px"}}>
						<div className="card-body" id="counterCardBody" style={{padding:"5px"}}>
							<div className="crm-numbers pb-0">
								<div className="row">
								<div className="col cursorPointer pt-1px" onClick={this.onClickCounter.bind(this, "18")}>
										<span className="f13"><br />Total</span>
										<h4 className="txt-success f30"><span className="counter"><CountUp end={this.state.totaltrucks.length} /></span></h4>
									</div>
									<div className='col cursorPointer pt-1px' onClick={this.onClickCounter.bind(this,"1")}>
										<span className='f13'><br/>Intransit To Customer</span>
										<h4 className='txt-info f30'><span className='counter'><CountUp end={this.state.intransittocustomer.length}/></span></h4>
									</div>
									<div className='col cursorPointer pt-1px' onClick={this.onClickCounter.bind(this,"2")}>
										<span className='f13'><br/>Proximity Customer Location</span>
										<h4 className='txt-secondary f30'><span className='counter'><CountUp end={this.state.proximitycustomerlocation.length}/></span></h4>
									</div>
									<div className='col cursorPointer pt-1px' onClick={this.onClickCounter.bind(this,"3")}>
										<span className='f13'><br/>Near Customer Visinity</span>
										<h4 className='txt-danger f30'><span className='counter'><CountUp end={this.state.nearcustomervisinity.length}/></span></h4>
									</div>
									<div className='col cursorPointer pt-1px' onClick={this.onClickCounter.bind(this,"4")}>
										<span className='f13'><br/>Reached Customer Visinity</span>
										<h4 className='txt-warning f30'><span className='counter'><CountUp end={this.state.reachedcustomervisinity.length}/></span></h4>
									</div>


									<div className='col cursorPointer pt-1px'>
												
										<span className='f13'><br/>Deviation</span>
											
											<div className='row'>
												<div className='col' onClick={this.onClickCounter.bind(this,"7")}>
											<span className='f13'><br/>LSP</span>
										<h4 className='txt-secondary f30'><span className='counter'><CountUp end={this.state.lspdata.length}/></span></h4>
												</div>
												<div className='col' onClick={this.onClickCounter.bind(this,"19")}>
											<span className='f13'><br/>Proximity Exit</span>
										<h4 className='txt-secondary f30'><span className='counter'><CountUp end={this.state.tripdeveation.length}/></span></h4>
												</div>
											</div>
									</div>


									<div className="col cursorPointer pt-1px" onClick={this.onClickCounter.bind(this, "0")}>
										<span className="f13"><br />Yard Hold</span>
										<h4 className="txt-primary f30"><span className="counter"><CountUp end={this.state.yardhold.length} /></span></h4>
									</div>
									<div className='col cursorPointer pt-1px' onClick={this.onClickCounter.bind(this,"5")}>
										<span className='f13'><br/>RSO</span>
										<h4 className='txt-info f30'><span className='counter'><CountUp end={this.state.rsodata.length}/></span></h4>
									</div>
								</div>
							</div>
						</div>
		
					</div>
					<div className="card" style={{marginBottom:"10px"}}>
						<div className="card-body" id="counterCardBody" style={{padding:"5px"}}>
							<div className="crm-numbers pb-0">
								<div className="row">
								<div className='col cursorPointer pt-2px' onClick={this.onClickCounter.bind(this,"13")}>
										<span className='f13'><br/>GPS With Driver/LSP</span>
										<h4 className='txt-info f30'><span className='counter'><CountUp end={this.state.gpsdrivelsp.length}/></span></h4>
									</div>
									<div className='col cursorPointer pt-2px' onClick={this.onClickCounter.bind(this,"24")}>
										<span className='f13'><br/>In Transit</span>
										<h4 className='txt-danger f30'><span className='counter'><CountUp end={this.state.intranit.length}/></span></h4>
									</div>
									<div className="col cursorPointer pt-2px" onClick={this.onClickCounter.bind(this, "8")}>
										<span className="f13"><br />GPS Not Found in Truck</span>
										<h4 className="txt-secondary f30"><span className="counter"><CountUp end={this.state.gpsnotfound.length} /></span></h4>
									</div>
									<div className='col cursorPointer pt-2px' onClick={this.onClickCounter.bind(this,"9")}>
										<span className='f13'><br/>GPS in Carrier/Customer</span>
										<h4 className='txt-danger f30'><span className='counter'><CountUp end={this.state.gpscarriercustomer.length}/></span></h4>
									</div>
									<div className='col cursorPointer pt-2px' onClick={this.onClickCounter.bind(this,"10")}>
										<span className='f13'><br/>Missing</span>
										<h4 className='txt-warning f30'><span className='counter'><CountUp end={this.state.missing.length}/></span></h4>
									</div>
									<div className='col cursorPointer pt-2px' onClick={this.onClickCounter.bind(this,"12")}>
										<span className='f13'><br/>Return In transit</span>
										<h4 className='txt-warning f30'><span className='counter'><CountUp end={this.state.returnintransit.length}/></span></h4>
									</div>
									<div className='col cursorPointer pt-2px' onClick={this.onClickCounter.bind(this,"38")}>
										<span className='f13'><br/>Pending For Pickup</span>
										<h4 className='txt-info f30'><span className='counter'><CountUp end={this.state.pendingpickup.length}/></span></h4>
									</div>
									<div className='col cursorPointer pt-2px' onClick={this.onClickCounter.bind(this,"11")}>
										<span className='f13'><br/>In Plant</span>
										<h4 className='txt-info f30'><span className='counter'><CountUp end={this.state.returntoplant.length}/></span></h4>
									</div>

									<div className='col cursorPointer pt-1px'>
												
										<span className='f13'><br/>EPOD</span>
											
											<div className='row'>
												<div className='col' onClick={this.onClickCounter.bind(this,"20")}>
											<span className='f13'><br/>Pending</span>
										<h4 className='txt-secondary f30'><span className='counter'><CountUp end={this.state.epodPending.length}/></span></h4>
												</div>
												<div className='col' onClick={this.onClickCounter.bind(this,"21")}>
											<span className='f13'><br/>Done</span>
										<h4 className='txt-secondary f30'><span className='counter'><CountUp end={this.state.epodDone.length}/></span></h4>
												</div>
											</div>
									</div>


									
									<div className='col cursorPointer pt-1px'>
												
										<span className='f13'><br/>LSP Delivery</span>
											
											<div className='row'>
												<div className='col' onClick={this.onClickCounter.bind(this,"22")}>
											<span className='f13'><br/>Pending</span>
										<h4 className='txt-secondary f30'><span className='counter'><CountUp end={this.state.lspPending.length}/></span></h4>
												</div>
												<div className='col' onClick={this.onClickCounter.bind(this,"23")}>
											<span className='f13'><br/>Done</span>
										<h4 className='txt-secondary f30'><span className='counter'><CountUp end={this.state.lspDone.length}/></span></h4>
												</div>
											</div>
									</div>
									<div className='col cursorPointer pt-2px' onClick={this.onClickCounter.bind(this,"37")}>
										<span className='f13'><br/>Blanks for Reached and Near Status</span>
										<h4 className='txt-info f30'><span className='counter'><CountUp end={this.state.blanksforreachedandnearstatus.length}/></span></h4>
									</div>
	

									{/* <div className='col cursorPointer pt-2px' onClick={this.onClickCounter.bind(this,"14")}>
										<span className='f13'><br/>Waiting For Unloading</span>
										<h4 className='txt-primary f30'><span className='counter'><CountUp end={this.state.waitingforunload.length}/></span></h4>
									</div>
									<div className='col cursorPointer pt-2px' onClick={this.onClickCounter.bind(this,"16")}>
										<span className='f13'><br/>Driver Not Responding</span>
										<h4 className='txt-secondary f30'><span className='counter'><CountUp end={this.state.drivernotresponding.length}/></span></h4>
									</div>
									<div className='col cursorPointer pt-2px' onClick={this.onClickCounter.bind(this,"17")}>
										<span className='f13'><br/>NA</span>
										<h4 className='txt-primary f30'><span className='counter'><CountUp end={this.state.emptystatus.length}/></span></h4>
									</div> */}
									
								</div>
							</div>
						</div>
					</div>

					<div className="card" style={{marginBottom:"10px"}}>
						<div className="card-body" id="counterCardBody" style={{padding:"5px"}}>
							<div className="crm-numbers pb-0">
								<div className="row">
									<div className='col cursorPointer pt-1px'>
											<span className='f13'><br/>Confirmation Pending</span>
											<div className='row'>
												<div className='col' onClick={this.onClickCounter.bind(this,"25")}>
											<span className='f13'><br/>&lt;1</span>
										<h4 className='txt-secondary f30'><span className='counter'><CountUp end={this.state.cp1.length}/></span></h4>
												</div>
												<div className='col' onClick={this.onClickCounter.bind(this,"26")}>
											<span className='f13'><br/>&lt;2</span>
										<h4 className='txt-secondary f30'><span className='counter'><CountUp end={this.state.cp2.length}/></span></h4>
												</div>
												<div className='col' onClick={this.onClickCounter.bind(this,"27")}>
											<span className='f13'><br/>&lt;3</span>
										<h4 className='txt-secondary f30'><span className='counter'><CountUp end={this.state.cp3.length}/></span></h4>
												</div>
												<div className='col' onClick={this.onClickCounter.bind(this,"28")}>
											<span className='f13'><br/>&gt;5</span>
										<h4 className='txt-secondary f30'><span className='counter'><CountUp end={this.state.cp4.length}/></span></h4>
												</div>
											</div>
									</div>
									<div className='col cursorPointer pt-1px'>
										<span className='f13'><br/>Pending for Pickup</span>
											<div className='row'>
												<div className='col' onClick={this.onClickCounter.bind(this,"29")}>
											<span className='f13'><br/>&lt;1</span>
										<h4 className='txt-secondary f30'><span className='counter'><CountUp end={this.state.pp1.length}/></span></h4>
												</div>
												<div className='col' onClick={this.onClickCounter.bind(this,"30")}>
											<span className='f13'><br/>&lt;2</span>
										<h4 className='txt-secondary f30'><span className='counter'><CountUp end={this.state.pp2.length}/></span></h4>
												</div>
												<div className='col' onClick={this.onClickCounter.bind(this,"31")}>
											<span className='f13'><br/>&lt;3</span>
										<h4 className='txt-secondary f30'><span className='counter'><CountUp end={this.state.pp3.length}/></span></h4>
												</div>
												<div className='col' onClick={this.onClickCounter.bind(this,"32")}>
											<span className='f13'><br/>&gt;5</span>
										<h4 className='txt-secondary f30'><span className='counter'><CountUp end={this.state.pp4.length}/></span></h4>
												</div>
											</div>
									</div>

									
									<div className='col cursorPointer pt-1px'>
										<span className='f13'><br/>Return In Transit</span>
											<div className='row'>
												<div className='col' onClick={this.onClickCounter.bind(this,"33")}>
											<span className='f13'><br/>&lt;1</span>
										<h4 className='txt-secondary f30'><span className='counter'><CountUp end={this.state.rt1.length}/></span></h4>
												</div>
												<div className='col' onClick={this.onClickCounter.bind(this,"34")}>
											<span className='f13'><br/>&lt;2</span>
										<h4 className='txt-secondary f30'><span className='counter'><CountUp end={this.state.rt2.length}/></span></h4>
												</div>
												<div className='col' onClick={this.onClickCounter.bind(this,"35")}>
											<span className='f13'><br/>&lt;3</span>
										<h4 className='txt-secondary f30'><span className='counter'><CountUp end={this.state.rt3.length}/></span></h4>
												</div>
												<div className='col' onClick={this.onClickCounter.bind(this,"36")}>
											<span className='f13'><br/>&gt;5</span>
										<h4 className='txt-secondary f30'><span className='counter'><CountUp end={this.state.rt4.length}/></span></h4>
												</div>
											</div>
									</div>

								</div>
							</div>
						</div>
					</div>

				</div>
				{this.state.alert}
				<div className="col-xl-12 col-lg-12 col-sm-12 beffect">
				<div className="row">
					<div className="col-xl-12 col-lg-12">
						<div className="card">
						<div className="card-header">
				       			<h5>
									
									<span className="">
										<button className="float-right custom-btn white btn-danger" onClick={this.onClickSaveGridState.bind(this)}>
											<i className="icofont icofont-save"></i> Save Grid Layout
										</button>
										<button className="float-right custom-btn white btn-info" style={{ marginRight: "10px" }} onClick={this.resetState.bind(this)}>
											<i className="icofont icofont-refresh"></i> Reset Default Layout
										</button>
										<button className="float-right custom-btn white btn-warning" style={{ marginRight: "10px" }} onClick={this.onClickAllDeviceHisotry.bind(this)}>
											All Device History
										</button>
										<button className="float-right custom-btn white btn-success" style={{ marginRight: "10px" }} onClick={this.onClickShowBulkForm.bind(this)}>
											Bulk Update
										</button>
										
									</span>
								</h5>
				   			</div>		
							<div className="card-body">
								<div className="row col-xl-12 col-lg-12">
								</div>
								<div className="row col-xl-12 col-lg-12">
									<div className="col-xl-12 col-lg-12">
										<div id="myGrid" style={{ height: "500px", width: "100%" }} className="ag-theme-balham">
											<AgGridReact
												modules={this.state.modules}
												columnDefs={columnwithDefs}
												defaultColDef={this.state.defaultColDef}
												rowData={this.state.rowData}
												enableCharts={false}
												enableRangeSelection={true}
												autoGroupColumnDef={this.state.autoGroupColumnDef}
												onGridReady={this.onGridReady}
												onGridState={this.onGridState}
												statusBar={this.state.statusBar}
												sideBar={this.state.sideBar}
												paginationPageSize={this.state.paginationPageSize}
												pagination={true}
												components={this.state.components}
												frameworkComponents={this.state.frameworkComponents}

												//floatingFilter={true}
												onCellEditingStopped={this.onCellUpdateData.bind(this)}
												//onCellClicked={this.popmarker}
												gridOptions={{
													context: { componentParent: this }
												}}
												onCellClicked={this.handleCellClicked}
												onCellValueChanged={this.onCellValueChanged}
											/>
										</div>
										{/* <button type="button" onClick={this.formAssignHandler.bind(this)} className="btn btn-primary float-right">Update Now</button> */}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				</div>
				<div className={"dataLoadpage " + (this.state.loadshow)}></div>
				<div className={"dataLoadpageimg " + (this.state.loadshow)}>
					<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
				<div className={"slide-r " + (this.state.sliderAddTransporter)} style={{ overflow: "auto", width: "65%", padding: "2%" }}>
					<div className="slide-r-title">
						<h4>
							Device History
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHide.bind(this)} >X</span>
						</h4>
					</div>
					<div>
						<div id="myGrid" style={{ height: "500px", width: "100%" }} className="ag-theme-balham">
							<AgGridReact
								modules={this.state.modules}
								columnDefs={columnwithDefsHistory}
								defaultColDef={this.state.defaultColDef}
								rowData={this.state.rowDataforHistory}
								enableCharts={false}
								enableRangeSelection={true}
								autoGroupColumnDef={this.state.autoGroupColumnDef}
								onGridReady={this.onGridReady}
								onGridState={this.onGridState}
								statusBar={this.state.statusBar}
								sideBar={this.state.sideBar}
								paginationPageSize={this.state.paginationPageSize}
								pagination={true}
								components={this.state.components}
								frameworkComponents={this.state.frameworkComponents}

								//floatingFilter={true}
								onCellEditingStopped={this.onCellUpdateData.bind(this)}
								//onCellClicked={this.popmarker}
								gridOptions={{
									context: { componentParent: this }
								}}
							/>
						</div>
					</div>
				</div>
				<div className={"p-1 slide-r " + (this.state.sliderEmailAllDeviceHistory)} style={{ overflow: "auto", width: "25%", }}>

					<div className="slide-r-title">
						<h4>
							All Device History
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>

						<div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div>
						<div className={"row"} style={{ padding: '5px 0px', width: '100%', overflow: 'hidden', zIndex: '9999', height: "100em" }} >
							<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.getAllDeviseHistory}>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label htmlFor='email' className="col-xl-12 col-lg-12 ml-0 pl-0" style={{fontSize:'18px !important',fontWeight:'700'}}>Email Id</label>
									<input
										type="email"
										placeholder="Enter Email"
										value={this.state.email}
										id="email"
										name="email"
										className="form-control mb-2"
										autoComplete="off"
										onChange={this.onChangeEmailInput}
										required
									/>
									
								</div>
								<div className="col-xl-12 col-lg-12 form-group ml-0 pl-0">
									<button type="submit" className="btn btn-success pl-2 pr-2 pt-1 pb-1 " style={{fontSize:'16px',borderRadius:'4px'}}>Send Mail</button>
								</div>
							</form>
						</div>
					</div>

				</div>
				<div className={"slide-r " + (this.state.sliderTranslateBulk)} style={{ overflow: "auto" }}>
					<div className="slide-r-title">
						<h4>
							Bulk Update
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>

						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative", width: '100%', paddingLeft: '0px', paddingRight: '0px' }} >
						<div className="row col-xl-12 col-lg-12" style={{ width: '100%', paddingLeft: '0px', paddingRight: '0px' }}>
							<form method="POST" className="theme-form col-xl-12 col-lg-12" style={{ width: '100%' }} onSubmit={this.formBulkUpload.bind(this)}>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Upload CSV <span style={{ color: "#FF0000" }}>*</span></label>
									<input type="file" style={{ marginLeft: "14px" }} id="uploadBulk" name="uploadBulk" className="form-control" onChange={this.changeDeviceCSVFileHandler.bind(this)} required />
								</div>

								<div className="col-xl-12 col-lg-12 form-group">
									<button type="submit" className="btn btn-success">Submit</button>
								</div>

								<div className="col-xl-12 col-lg-12 form-group">
									<a href={require("../../assets/json/sampleBulkUploadDeviceLogs.csv")} target="_blank" className="btn btn-danger">Download Sample Template</a>
								</div>
							</form>
							{
								this.state.invalid_devices && 
								<div className="col-xl-12 col-lg-12 ">
									<div className="col-xl-12 col-lg-12 ">
										< h1 style={{ fontSize: '1.5rem', fontFamily: 'sans-serif', fontWeight: '600' }} >Failed Devices List </h1>
									</div>
									<div className="col-xl-12 col-lg-12 " style={{ width: '100%' }}>
										<div id="myGrid" style={{ height: "500px", width: "100%" }} className="ag-theme-balham">
											<AgGridReact
												modules={this.state.modules}
												columnDefs={columnwithDefsInvalidDevides}
												defaultColDef={this.state.defaultColDef}
												rowData={this.state.invalid_devices}
												enableCharts={false}
												enableRangeSelection={true}
												paginationPageSize={this.state.paginationPageSize}
												pagination={true}
												components={this.state.components}
												frameworkComponents={this.state.frameworkComponents}
											/>
										</div>
									</div>
								</div>

							}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}                 
function timeConvert(n) {
	var num = n;
	var hours = (num / (3600));
	var rhours = parseInt(hours);
	var minutes = (num-(rhours*3600)) / (60);
	var rminutes = Math.round(minutes);
	return rhours + " hour(s) and " + rminutes + " minute(s).";
}                              

function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}
function dateScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function distance(lat1, lon1, lat2, lon2, unit) {
	if ((lat1 == lat2) && (lon1 == lon2)) {
		return 0;
	}
	else {
		var radlat1 = Math.PI * lat1/180;
		var radlat2 = Math.PI * lat2/180;
		var theta = lon1-lon2;
		var radtheta = Math.PI * theta/180;
		var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
		if (dist > 1) {
			dist = 1;
		}
		dist = Math.acos(dist);
		dist = dist * 180/Math.PI;
		dist = dist * 60 * 1.1515;
		if (unit=="K") { dist = dist * 1.609344 }
		if (unit=="N") { dist = dist * 0.8684 }
		return dist;
	}
}
function getDatePicker() {
	function Datepicker() {}
	Datepicker.prototype.init = function(params) {
	  this.eInput = document.createElement('input');
	  this.eInput.value = params.value;
	  $(this.eInput).datepicker({ dateFormat: 'dd-mm-yy' });
	};
	Datepicker.prototype.getGui = function() {
	  return this.eInput;
	};
	Datepicker.prototype.afterGuiAttached = function() {
	  this.eInput.focus();
	  this.eInput.select();
	};
	Datepicker.prototype.getValue = function() {
	  return this.eInput.value;
	};
	Datepicker.prototype.destroy = function() {};
	Datepicker.prototype.isPopup = function() {
	  return false;
	};
	return Datepicker;
  }


  function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};
function dateComparator(date1, date2) {
	
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }

function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}
function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}
function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		format:'Y-m-d',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}