import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils'; 

import Highcharts from 'highcharts';
import drilldown from 'highcharts-drilldown';
import HighchartsReact from 'highcharts-react-official';
import $ from 'jquery';
drilldown(Highcharts);
window.jQuery = $;
window.$ = $;

var redirectURL = require('../redirectURL');

var criticaldata=[]
export default class ColumnGroupChart extends Component {

    constructor(props) {
		super(props);
		this.state = {
			showgrid:'display:none',
			showtransgrid:'display:none',
			rowdata:[],
			test:"TEstng"
		}

	}
	componentDidMount = async () =>{
		
	}
	
    render(){
    
        const dStyles={
				width:'100%',
				height:'100%'
		}
		const highchartoptions = {
            chart: {
                // type: 'areaspline',
                renderTo: 'container',
                // height:"500"
            },
            title: {
                text: this.props.xTitle,
                align: 'left'
            },
            tooltip: {
                // pointFormat: '<b>{series.name}:Total Cost: INR {point.y}</b>'
                shared:false
            },
            xAxis: {
                // categories: ['East', 'West', 'North','South', 'Central']
                type:"category"
            },
            yAxis: {
                title: "Total Cost (INR)",
                // type:"logarithmic"
            },
            accessibility: {
                // point: {
                //     valueSuffix: ''
                // }
            },
            plotOptions: {
                
                dataLabels:{
                    format:'{point.lblTxt}',
                    enabled:true
                }
            },
            series:this.props.series,
            // colors:[ '#00f0b5','#f35e82','#7f84e9','#f3d800',  '#fff493', '#f88fa2'],
            credits:false
        };
        
        return ( 
			<div>
               <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={highchartoptions} />
			</div>
			);
		}
}