
import React, { Component } from 'react';

 
const CheckboxOption = (props) => {
    // console.log("props ",props.selectedDealers)
    const handleClick = (e) => {
        e.stopPropagation();
        props.customFunction(props.data.value);
    };

    return (
        <div className="jallaDrp">
            <input type="checkbox" onClick={handleClick}  checked={(props.selectedDealers.includes(props.data.value))?true:""}  />
            &nbsp;{props.children}
        </div>
    );
};

export default CheckboxOption;
