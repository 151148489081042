/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import CountUp from 'react-countup';
import axios from 'axios';
var redirectURL = require('../redirectURL');

export default class ConsignmentCountersComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            records: [],
            active_consignments: 0,
            overspeeding: 0,
            night_driving: 0,
            transit_delay: 0,
            unloading_dealer: 0,
            left_dealer: 0,
            no_gps_data_available: 0,
            nogpslifetime: 0,
            is_critical: 0,
            is_priority: 0,
            deptcode: this.props.deptcode,
            triptype: this.props.triptype,
            active_trucks: 0,
            loading_delay: 0,
            unloading_delay: 0,
            enroute_stoppage: 0,
            probableAccident: 0,
            hidecol: true,
            probableAccidentconfirm: 0,
            cancelledCount: 0,
            pod_count: 0,
            trip_closed_by_pod_count: 0,
            returnConsignments: 0,
            counttype: "all",
            truckReportedDate: "",
            deviated_routes: [],
            withoutTruckGateOutTimeData: [],
            forceClosed : 0,
            total_accidents : 0,
            total_deviations : 0,
            active_tab:"",
            ontimedata:[],
            delaydata:[],
            deviation_trips:0,
            isyardcounterenabled:false,
        }

    }
    componentWillReceiveProps = async (newprops) => {

        if (newprops.deptcode != this.state.deptcode) {
            // console.log("Conters ",newprops.deptcode)
            if (newprops.triptype && newprops.triptype != 0) {
                var layoutdata = {
                    token: localStorage.getItem('token'),
                    dept_code: newprops.deptcode,
                    trip_type: newprops.triptype
                }
            }
            else {
                var layoutdata = {
                    token: localStorage.getItem('token'),
                    dept_code: newprops.deptcode
                }
            }

            /*Venkatesh Code*/
            var transitdelaysarr = [];
            var gpsdatanaarr = [];
            var overspeedtrucksarr = [];
            var nightdrivearr = [];
            var enroutearr = [];
            var loadingarr = [];
            var unloadingarr = [];
            var iscriticalarr = [];
            // console.log("newprops.gridData ", newprops.gridData)
            var isyardcounterenabled = false
                try {
                    var isyardcounterenabled = false
                    if (newprops.consignmentsfor == 'yarddata') {
                        var isyardcounterenabled = true
                    }
                    // console.log(newprops.consignmentsfor, 'isyardcounterenabled  ')
                } catch (e) {
                    console.log("Error ,",e)
                }      
            if (newprops.gridData.length > 0) {
                /*New Code for Counters*/
                var counter = newprops.countersjson;
                //  console.log("counter: => ",counter)
                let withoutTruckGateOutTimeData = 0
                var overspdcnt = 0;
                var nightdrvcnt = 0;
                var transdly = 0;
                var npgs = 0;
                var nogpslifetime = 0;
                var lodelay = 0;
                var unlodelay = 0;
                var enrote = 0;
                var critical = 0;
                var priority = 0;
                var probableAccident = 0;
                var probableAccidentConfirmCount = 0;
                var cancelledCount = 0;
                var accident_exception = 0;
                var newitem;
                var deviation_trips = 0;
                var pod_count = counter.pod_received_data;
                var trip_closed_by_pod_count = counter.trip_closed_by_pod;
                var forceClosed = 0;
                if (counter.cancelled_count != "Not Applicable") {
                    cancelledCount = counter.cancelled_count
                }
                if (counter.overspeeding.length > 0) {
                    var overspdcnt = counter.overspeeding[0].overspeeding_exception_count;
                }
                try {
                    if (counter.probableAccident.length > 0) {
                        var probableAccident = counter.probableAccident[0].probableAccidentCount;
                    }
                } catch (e) { }

                try {
                    if (counter.probableAccidentConfirm.length > 0) {
                        var probableAccidentConfirmCount = counter.probableAccidentConfirm[0].probableAccidentConfirmCount;
                    }
                } catch (e) { }
                try {
                    if (counter.criticalCount.length > 0) {
                        critical = counter.criticalCount[0].criticalCount;
                    }
                }
                catch (e) { }
                try {
                    if (counter.priorityCount.length > 0) {
                        priority = counter.priorityCount[0].priorityCount;
                    }
                }
                catch (e) { }
                if (counter.night_drive.length > 0) {
                    var nightdrvcnt = counter.night_drive[0].nightdriving_exception_count;
                }
                try{
                    if (counter.forceClosed.length > 0) {
                        var forceClosed = counter.forceClosed[0].forceClosedCount;
                    }
                }catch (e) { }
                if (counter.transit_delay.length > 0) {
                    var transdly = counter.transit_delay[0].transit_delay_count;
                }

                if (counter.no_gps.length > 0) {
                    var npgs = counter.no_gps[0].no_gps_data_count;
                }
                //console.log("newprops.hide ",newprops.hide)
                // if(newprops.hide == true)
                // {
                try {
                    if (counter.nogpslifetime.length > 0) {
                        var nogpslifetime = counter.nogpslifetime[0].no_gps_data_lifetime_count;
                    }
                } catch (e) { }
                // }
                if (counter.loading_delay.length > 0) {
                    var lodelay = counter.loading_delay[0].loading_delay_exception_count;
                }

                if (counter.unloading_delay.length > 0) {
                    var unlodelay = counter.unloading_delay[0].unloading_delay_exception_count;
                }

                if (counter.enroute_stoppage.length > 0) {
                    var enrote = counter.enroute_stoppage[0].enroute_exception_count;
                }
                try{

                    if (counter.tripDeviation.length > 0) {
                        var deviation_trips = counter.tripDeviation[0].tripDeviationCount;
                    }
                }
                catch(e){

                }
                 let grid_data = []
                if(this.props.path == "/prtdeliveredconsignments"){
                    grid_data = newprops.gridData.filter(item  => item.status !==6 )
                }else{
                    grid_data = newprops.gridData
                }
                // var total_accidents 
                // if(grid_data.length>0){
                //     console.log("counter171")
                //     if(newitem.accident_exception === 1)
                //     {
                //         console.log("175")
                //          total_accidents.push("newitem")
                        

                //     }
                // }
                // else{
                //     total_accidents = 0
                // }
                // console.log("168 ",newprops)
                await this.setState({
                    active_consignments: grid_data.length,
                    overspeeding: overspdcnt,
                    is_critical: critical,
                    is_priority: priority,
                    night_driving: nightdrvcnt,
                    transit_delay: transdly,
                    no_gps_data_available: npgs,
                    nogpslifetime: nogpslifetime,
                    loading_delay: lodelay,
                    unloading_delay: unlodelay,
                    enroute_stoppage: enrote,
                    probableAccident: probableAccident,
                    probableAccidentconfirm: probableAccidentConfirmCount,
                    cancelledCount: cancelledCount,
                    pod_count: pod_count,
                    trip_closed_by_pod_count: trip_closed_by_pod_count,
                    returnConsignments: newprops.returnConsignments,
                    counttype: newprops.counttype,
                    deviated_routes: counter.deviated_routes,
                    withoutTruckGateOutTimeData: newprops.withoutTruckGateOutTimeData.length,
                    forceClosed,
                    total_accidents:newprops.total_accidents,
                    total_deviations:newprops.total_deviations,
                    active_tab:newprops.active_tab,
                    ontimedata:newprops.ontimedata,
                    delaydata:newprops.delaydata,
                    deviation_trips:deviation_trips,
                    isyardcounterenabled:isyardcounterenabled,
                });
                // console.log("total_accidents",total_accidents)
            }
            else {
                await this.setState({
                    active_consignments: 0,
                    overspeeding: 0,
                    night_driving: 0,
                    transit_delay: 0,
                    is_critical: 0,
                    is_priority: 0,
                    //unloading_dealer:counterdata.unloading_dealer,
                    //left_dealer:counterdata.left_dealer,
                    no_gps_data_available: 0,
                    nogpslifetime: 0,
                    //active_trucks:counterdata.active_trucks,
                    loading_delay: 0,
                    unloading_delay: 0,
                    enroute_stoppage: 0,
                    probableAccident: 0,
                    probableAccidentconfirm: 0,
                    returnConsignments: newprops.returnConsignments,
                    deviated_routes: [],
                    deviation_trips:0,                    
                    isyardcounterenabled:isyardcounterenabled,
                });
            }
            this.setState({
                hidecol: newprops.hide,
                truckReportedDate: this.props.truckReportedDate
            });
        }


    }

    onClickCounter(data) {
        this.props.context.onClickCounterShowData(data)
    }
    render() {
        var activeshow = (this.state.counttype == 'all' || this.state.counttype == 'active') ? 'show-m' : 'show-n'
        // console.log("ths.state", this.state.deptcode, '\n this.prop', this.props)
        var istptactiveconsignments = this.state.isyardcounterenabled==true && ['/sndtransporterconsignments','/sndconsignments' , '/snddeliveredconsignments' , '/sndallconsignments'].includes(this.props.path) ? {'minWidth':'25%',"paddingBottom":'8px'} :{}
        if(this.state.isyardcounterenabled==true && this.props.path.includes('snddeliveredconsignments')){
            var istptactiveconsignments = {'minWidth':'33.3%',"paddingBottom":'8px'}
        }
    //    console.log(this.state.isyardcounterenabled,'istptactiveconsignments')
        return (
            <div className="row">

                <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                    {/* <div className="card">
                        <div className="card-body" id="counterCardBody"> */}
                            <div className="crm-numbers pb-0">
                                {(this.state.returnConsignments == false) ?
                                    <div className="row">
                                        <div className="col cirlce-d cursorPointer" onClick={this.onClickCounter.bind(this, "all")} style={istptactiveconsignments}>
                                            <div class="o-hidden card nmr">
                                                <div class="bg-info b-r-4 card-body">
                                                    <div class="media static-top-widget d-flex">
                                                        <div class="align-self-center text-center">
                                                            {/* <i class="icofont icofont-chart-histogram-alt"></i> */}
                                                            <i className="icofont icofont-ui-browser"></i>
                                                        </div>
                                                        <div class="media-body">
                                                            <h4 class="counter mb-0 f28"><span>
                                                                <CountUp end={(this.state.active_consignments != '') ? this.state.active_consignments : 0} />
                                                            </span></h4>
                                                            <span class="m-0">Consignments</span>
                                                            {/* <i class="icofont icofont-chart-histogram-alt icon-bg">

                                                            </i> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            {/* <span className="f13"><i className="icofont icofont-ui-browser f24 greenfont"></i><br /> Consignments</span>
                                            <h4 className="txt-info f40"><span className="counter"><CountUp end={(this.state.active_consignments != '') ? this.state.active_consignments : 0} /></span></h4> */}

                                        </div>
                                        
                                        {(this.state.active_tab == "delivered")?
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "ontimedata")} style={istptactiveconsignments}>

                                            <div class="o-hidden card nmr">
                                                <div class="bg-ontime b-r-4 card-body">
                                                    <div class="media static-top-widget d-flex">
                                                        <div class="align-self-center text-center">
                                                            {/* <i class="icofont icofont-chart-histogram-alt"></i> */}
                                                            <i className="icofont icofont-clock-time"></i>
                                                        </div>
                                                        <div class="media-body">
                                                            <h4 class="counter mb-0 f28"><span>
                                                                <CountUp end={(this.state.ontimedata.length > 0) ? this.state.ontimedata.length : 0} />
                                                            </span></h4>
                                                            <span class="m-0">Ontime</span>
                                                            {/* <i class="icofont icofont-chart-histogram-alt icon-bg">

                                                            </i> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        :""}
                                        {(this.state.active_tab != "delivered")?
                                            <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "transit_delay")} style={istptactiveconsignments}>

                                                <div class="o-hidden card nmr">
                                                    <div class="bg-danger b-r-4 card-body">
                                                        <div class="media static-top-widget d-flex">
                                                            <div class="align-self-center text-center">
                                                                {/* <i class="icofont icofont-chart-histogram-alt"></i> */}
                                                                <i className="icofont icofont-clock-time"></i>
                                                            </div>
                                                            <div class="media-body">
                                                                <h4 class="counter mb-0 f28"><span>
                                                                    <CountUp end={(this.state.transit_delay != '') ? this.state.transit_delay : 0} />
                                                                </span></h4>
                                                                <span class="m-0">Transit Delays</span>
                                                                {/* <i class="icofont icofont-chart-histogram-alt icon-bg">

                                                                </i> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        
                                        :
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "delaydata")} style={istptactiveconsignments}>

                                            <div class="o-hidden card nmr">
                                                <div class="bg-danger b-r-4 card-body">
                                                    <div class="media static-top-widget d-flex">
                                                        <div class="align-self-center text-center">
                                                            {/* <i class="icofont icofont-chart-histogram-alt"></i> */}
                                                            <i className="icofont icofont-clock-time"></i>
                                                        </div>
                                                        <div class="media-body">
                                                            <h4 class="counter mb-0 f28"><span>
                                                                <CountUp end={(this.state.delaydata.length > 0) ? this.state.delaydata.length : 0} />
                                                            </span></h4>
                                                            <span class="m-0">Delayed</span>
                                                            {/* <i class="icofont icofont-chart-histogram-alt icon-bg">

                                                            </i> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                     
                                        }
                                       
                                     
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "overspeeding_exception")} style={istptactiveconsignments}>

                                            <div class="o-hidden card nmr">
                                                <div class="bg-warning b-r-4 card-body">
                                                    <div class="media static-top-widget d-flex">
                                                        <div class="align-self-center text-center">
                                                            {/* <i class="icofont icofont-chart-histogram-alt"></i> */}
                                                            <i className="fa fa-tachometer"></i>
                                                        </div>
                                                        <div class="media-body">
                                                            <h4 class="counter mb-0 f28"><span>
                                                                <CountUp end={(this.state.overspeeding != '') ? this.state.overspeeding : 0} />
                                                            </span></h4>
                                                            <span class="m-0">Overspeeding</span>
                                                            {/* <i class="icofont icofont-chart-histogram-alt icon-bg">

                                                            </i> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* <span className="f13"><i className="fa fa-tachometer f22 txt-success"></i><br /> Overspeeding</span>
                                            <h4 className="txt-success f40"><span className="counter"><CountUp end={(this.state.overspeeding != '') ? this.state.overspeeding : 0} /></span></h4> */}

                                        </div>
                                        {(this.props.deptcode == 'LOG-PRT') ? "" :
                                            <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "nightdriving_exception")} style={istptactiveconsignments}>
                                                <div class="o-hidden card nmr">
                                                    <div class="bg-primary b-r-4 card-body">
                                                        <div class="media static-top-widget d-flex">
                                                            <div class="align-self-center text-center">
                                                                {/* <i class="icofont icofont-chart-histogram-alt"></i> */}
                                                                <i className="icofont icofont-full-night"></i>
                                                            </div>
                                                            <div class="media-body">
                                                                <h4 class="counter mb-0 f28"><span>
                                                                    <CountUp end={(this.state.night_driving != '') ? this.state.night_driving : 0} />
                                                                </span></h4>
                                                                <span class="m-0">Night Driving</span>
                                                                {/* <i class="icofont icofont-chart-histogram-alt icon-bg">

                                                                </i> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* <span className="f13"><i className="icofont icofont-full-night f24 txt-primary"></i><br /> Night Driving</span>
                                                <h4 className="txt-primary f40"><span className="counter"><CountUp end={(this.state.night_driving != '') ? this.state.night_driving : 0} /></span></h4> */}

                                            </div>
                                        }
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "enroute_exception")} style={istptactiveconsignments}>
                                            
                                            <div class="o-hidden card nmr">
                                                <div class="bg-secondary b-r-4 card-body">
                                                    <div class="media static-top-widget d-flex">
                                                        <div class="align-self-center text-center">
                                                            {/* <i class="icofont icofont-chart-histogram-alt"></i> */}
                                                            <i className="icofont icofont-pause"></i>
                                                        </div>
                                                        <div class="media-body">
                                                            <h4 class="counter mb-0 f28"><span>
                                                                <CountUp end={(this.state.enroute_stoppage != '') ? this.state.enroute_stoppage : 0} />
                                                            </span></h4>
                                                            <span class="m-0">Stoppages</span>
                                                            {/* <i class="icofont icofont-chart-histogram-alt icon-bg">

                                                            </i> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <span className="f13"><i className="icofont icofont-pause f22 maroon"></i><br /> Stoppages</span>
                                            <h4 className="maroon f40"><span className="counter"><CountUp end={(this.state.enroute_stoppage != '') ? this.state.enroute_stoppage : 0} /></span></h4> */}
                                        </div>
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"accident")} style={istptactiveconsignments}>
                                            
                                            <div class="o-hidden card nmr">
                                                <div class="bg-danger b-r-4 card-body">
                                                    <div class="media static-top-widget d-flex">
                                                        <div class="align-self-center text-center">
                                                            {/* <i class="icofont icofont-chart-histogram-alt"></i> */}
                                                            {/* <i className="icofont icofont-vehicle-delivery-van"></i>
                                                             */}
                                                             <img src={require("../../assets/icons/car-crash.png")} style={{width:"35px",marginTop:"-5px"}} />
                                        
                                                        </div>
                                                        <div class="media-body">
                                                            <h4 class="counter mb-0 f28"><span>
                                                                <CountUp end={(this.state.total_accidents != '') ? this.state.total_accidents : 0} />
                                                            </span></h4>
                                                            <span class="m-0">Accidents</span>
                                                            {/* <i class="icofont icofont-chart-histogram-alt icon-bg">

                                                            </i> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <span className="f13"><i className="icofont icofont-vehicle-delivery-van f24 mildgreen"></i><br /> Accidents</span>
                                            <h4 className="mildgreen f40"><span className="counter"><CountUp end={this.state.total_accidents}/></span></h4> */}
                                        </div>

                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"tripdeviations")} style={istptactiveconsignments}>
                                            
                                            <div class="o-hidden card nmr">
                                                <div class="bg-default3 b-r-4 card-body">
                                                    <div class="media static-top-widget d-flex">
                                                        <div class="align-self-center text-center">
                                                            {/* <i class="icofont icofont-chart-histogram-alt"></i> */}
                                                            {/* <i className="fa fa-random"></i> */}
                                                            <img src={require("../../assets/icons/trip_deviation.png")} className="imgwh" />
                                                        </div>
                                                        <div class="media-body">
                                                            <h4 class="counter mb-0 f28"><span>
                                                                <CountUp end={(this.state.deviation_trips != '') ? this.state.deviation_trips : 0} />
                                                            </span></h4>
                                                            <span class="m-0">Trip Deviations</span>
                                                            {/* <i class="icofont icofont-chart-histogram-alt icon-bg">

                                                            </i> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <span className="f13"><i className="icofont icofont-minus-circle f22 maroon"></i><br /> Deviations</span>
                                            <h4 className="maroon f40"><span className="counter"><CountUp end={this.state.total_deviations}/></span></h4> */}
                                        </div>

                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"deviations")} style={istptactiveconsignments}>
                                            
                                            <div class="o-hidden card nmr">
                                                <div class="bg-default2 b-r-4 card-body">
                                                    <div class="media static-top-widget d-flex">
                                                        <div class="align-self-center text-center">
                                                            {/* <i class="icofont icofont-chart-histogram-alt"></i> */}
                                                            <i className="fa fa-random"></i>
                                                        </div>
                                                        <div class="media-body">
                                                            <h4 class="counter mb-0 f28"><span>
                                                                <CountUp end={(this.state.total_deviations != '') ? this.state.total_deviations : 0} />
                                                            </span></h4>
                                                            <span class="m-0">LSP Deviations</span>
                                                            {/* <i class="icofont icofont-chart-histogram-alt icon-bg">

                                                            </i> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <span className="f13"><i className="icofont icofont-minus-circle f22 maroon"></i><br /> Deviations</span>
                                            <h4 className="maroon f40"><span className="counter"><CountUp end={this.state.total_deviations}/></span></h4> */}
                                        </div>
                                    
                                        {(this.props.deptcode.includes("PRT")) ?                                        
                                                <div className="col cursorPointer">
                                                    <span className="f13"><i className="icofont icofont-map-pins f22 txt-danger"></i><br />Deviated Routes</span>
                                                    <h4 className="txt-secondary f40">
                                                        <span className="counter" onClick={this.onClickCounter.bind(this, "deviation")}>
                                                            <CountUp className="txt-danger" end={(this.state.deviated_routes != '' && this.state.deviated_routes != undefined) ? this.state.deviated_routes.length : 0} />
                                                        </span>
                                                    </h4>
                                                </div>                                           
                                            : ""}

                                        {(this.props.priority_counter_display == 1) ?
                                            <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "is_priority")} style={istptactiveconsignments}>
                                                <span className="f13"><i className="icofont icofont-check f22 green"></i><br /> Priority</span>
                                                <h4 className="txt-success f40"><span className="counter"><CountUp end={(this.state.is_priority != '') ? this.state.is_priority : 0} /></span></h4>

                                            </div>
                                            : ""}

                                        {(this.props.path == "/prtallconsignments" || this.props.path == "/prtdeliveredconsignments") ?
                                            <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "cancelled")}>
                                                <span className="f13"><i className="fa fa-window-close-o f22 maroon"></i><br /> Cancelled</span>
                                                <h4 className="maroon f40"><span className="counter"><CountUp end={(this.state.cancelledCount != '') ? this.state.cancelledCount : 0} /></span></h4>
                                            </div>
                                            : ""}
                                            {(this.props.path == "/prtdeliveredconsignments") ?
                                            <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "force_closed")}>
                                                <span className="f13"><i className="fa fa-ban f22 maroon"></i><br /> Force closed</span>
                                                <h4 className="maroon f40"><span className="counter"><CountUp end={(this.state.forceClosed != '') ? this.state.forceClosed : 0} /></span></h4>
                                            </div>
                                            : ""}
                                        {
                                            (this.props.path == '/prtconsignments') ?
                                            <>
                                                <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "no_gate_out_time")}>
                                                    <span className="f13"><i className="fa fa-ban f22 maroon"></i><br /> No GateOut Time</span>
                                                    <h4 className="maroon f40"><span className="counter"><CountUp end={(this.state.withoutTruckGateOutTimeData != '') ? this.state.withoutTruckGateOutTimeData : 0} /></span></h4>
                                                </div>
                                                <div className="col cursorPointer">
                                                    <span className="f13"><i className="icofont icofont-social-google-map f24 txt-danger"></i><br /> GPS Not Available</span>
                                                    <h4 className="txt-secondary f40">

                                                        <span className="counter" onClick={this.onClickCounter.bind(this, "no_gps_data")}>

                                                            <CountUp className="txt-danger" end={(this.state.no_gps_data_available != '') ? this.state.no_gps_data_available : 0} />
                                                        </span>
                                                      
                                                    </h4>
                                                </div>
                                            </>
                                                 : ''
                                        }

                                    

                                    </div>
                                    :
                                    <div className="row">
                                        <div className="col cirlce-d cursorPointer" onClick={this.onClickCounter.bind(this, "all")}>
                                            <span className="f13"><i className="icofont icofont-ui-browser f24 greenfont"></i><br /> Consignments</span>
                                            <h4 className="txt-info f40"><span className="counter"><CountUp end={(this.state.active_consignments != '') ? this.state.active_consignments : 0} /></span></h4>

                                        </div>

                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "transit_delay")}>
                                            <span className="f13"><i className="icofont icofont-clock-time f22 redfont"></i><br /> Transit Delays</span>
                                            <h4 className="txt-danger f40"><span className="counter"><CountUp end={(this.state.transit_delay != '') ? this.state.transit_delay : 0} /></span></h4>

                                        </div>
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "enroute_exception")}>
                                            <span className="f13"><i className="icofont icofont-pause f22 maroon"></i><br /> Stoppages</span>
                                            <h4 className="maroon f40"><span className="counter"><CountUp end={(this.state.enroute_stoppage != '') ? this.state.enroute_stoppage : 0} /></span></h4>
                                        </div>

                                    <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"accident_exception")} >
                                        <span className="f13">
                                        <img src={require("../../assets/icons/car-crash.png")} style={{width:"25px",marginTop:"-5px"}} />
                                        <br /> Accidents</span>
                                        <h4 className="mildgreen f40"><span className="counter"><CountUp end={this.state.total_accidents}/></span></h4>
                                    </div>

                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"is_critical")}>
                                            <span className="f13"><i className="fa fa-random f22 maroon"></i><br /> Deviations</span>
                                            <h4 className="maroon f40"><span className="counter"><CountUp end={this.state.total_deviations}/></span></h4>
                                        </div>
                                    

                                    </div>
                                }

                            </div>

                        {/* </div>
                    </div> */}
                </div>

            </div>
        );
    }
}
