import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import UpdateSB from './UpdateSB'
var redirectURL = require('../redirectURL');

export default class SlabRateData extends Component {

    constructor(props) {
        super(props);
        this.state = {
            deptcode: "",
            eventGridAction: "gridAction",
            eventCounterAction: "counterAction",
            eventFormAction: "formAction",
            modules: AllModules,
            pivotmodeis: false,
            plants: [],
            flCity: { value: "", label: "All" },
            flState: { value: "", label: "All" },
            flCluster: { value: "", label: "All" },
            flZone: { value: "", label: "All" },
            flDealer: { value: "", label: "All" },
            consigner: [{ value: "all", label: "All" }],
            startDate: "",
            endDate: "",
            date_type: { value: "all", label: "All" },
            filterCities: [],
            filterStates: [],
            filterDealers: [],
            filterClusters: [],
            filterZones: [],
            filterTransporters: [],
            flTransporter: { value: "", label: "All" },

            pivotcols: [],
            defaultColDef: {
                sortable: true,
                filter: true,
                resizable: true,
                pivot: true,
                enableValue: true,
                enableRowGroup: true,
            },
            rowData: [],
            maprowData: null,
            rowSelection: 'multiple',
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            overlayNoRowsTemplate: "",

            animateRows: true,
            debug: true,
            showToolPanel: false,
            uppressAggFuncInHeader: true,

            childconfs: [],
            childrow: [],
            rowModelType: "serverSide",
            paginationPageSize: 100,
            cacheBlockSize: 10,
            maxBlocksInCache: 1,
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            detailCellRendererParams: {},
            rowClassRules: {
                //"highlitRow": "data.transit_delay == 1",
                //"green":"data.status >= 3"

            },
            show: false,
            basicTitle: "",
            basicType: "default",
            show1: false,
            basicTitle1: "",
            basicType1: "default",
            loadshow: "show-n",
            overly: "show-n",
            sliderAddTranslate: '',
            sliderWithoutDefectAddTranslate: "",
            sliderWithDefectAddTranslate: "",
            sliderFeedback: "",
            customer_pod_date: "",
            customer_lr_doc: "",
            customer_pod_remarks: "",
            item_code: { "value": "", "label": "Select Item" },
            item_description: "",
            defect_description: "",
            defect_code: { "value": "", "label": "Select Defect" },
            category: { "value": "", "label": "Select Category" },
            quantity: "",
            itemslist: [],
            itemcode: "",
            defectlist: [],
            defectcode: { "value": "", "label": "Select Deffect" },
            categorylist: [],
            categorycode: "",
            customer_code: "",
            grn_no: "",
            alldata: [],
            podsubmitdata: [],
            nonpoddata: [],
            withdefects: [],
            withoutdefects: [],
            withdefectspod: [],
            withoutdefectspod: [],
            sidebarrowData: [],
            frameworkComponents: {
                UpdateSB: UpdateSB
            },
            srownode: "",
            invoice_no: "",
            LSBSData: [],
            supply_plant_desc: "",
            feedrowid: "",
            feed_invoice_no: "",
            feed_rownode: "",
            rating1: { "value": "10", "label": "10" },
            rating2: { "value": "10", "label": "10" },
            rating3: { "value": "10", "label": "10" },
            comment1: "",
            comment2: "",
            comment3: "",
            material_description: "",
            material_no: "",
            customer_document_1: "",
            customer_document_2: "",
            admincode: "",
            allcustomers: [],
            scustomer: { "value": "", "label": "All Customers" },
            podsDocs: [],
            driver_mobile: "",
            driver_mobile_with_defects: "",
            driver_without_defect_otp: "",
            driver_with_defect_otp: "",
            is_driver_without_defect_verified: 0,
            is_driver_with_defect_verified: 0,
            is_driver_without_defect_otp_trigger: 0,
            is_driver_with_defect_otp_trigger: 0,
            mobile_number: "",
            gps_device_vehicle: { value: "", label: "Select" },
            device_no: "",
            updateSB: '',
            updatestart_km: '',
            updateend_km: '',
            updatesingle_driver_cost: '',
            updatedouble_driver_cost: '',
            update_id: '',

            addSB: '',
            addstart_km: '',
            addend_km: '',
            addsingle_driver_cost: '',
            adddouble_driver_cost: '',


        }
        this.changeHandler = this.changeHandler.bind(this)
    }
    componentDidMount() {

        this.loadVendorData()

    }
    async loadVendorData() {
        await redirectURL.get("/consignments/getLSBSlabRate")
            .then((response) => {

                if (response.data.status === "success") {
                    this.setState({
                        LSBSData: response.data.LSBSData,
                        rowData: response.data.LSBSData
                    })
                }
                else {
                    this.setState({
                        LSBSData: [],
                        rowData: []
                    })
                }
            })
    }

    onGridReady = params => {
        this.gridApi = params.api;

        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;

        this.gridApi.addEventListener('paginationChanged', (e) => {
            //Reset rows selection based on current page
            // this.resetPaginationSelection(this);
        });

        this.gridApi.forEachLeafNode((node) => {
            // console.log("node here ",node)
            node.setSelected(true);

        });

    };


    onGridState = () => {
        console.log(this.gridApi);

        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }

    onClickHideAll() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            slideuploadeway: '',
            sliderAddTranslate: '',
            sliderWithoutDefectAddTranslate: "",
            sliderWithDefectAddTranslate: "",
            sliderFeedback: "",
            material_no: '',
            material_description: "",
            supply_plant_desc: "",
            customer_pod_date: "",
            itemcode: "",
            defectcode: "",
            category: "",
            quantity: "",
            gps_device_vehicle: "",
            device_no: "",
            customer_document_1: "",
            customer_pod_remarks: "",
            // driver_mobile_with_defects:"",
            // driver_with_defect_otp:"",
            // driver_without_defect_otp:"",
            // is_driver_with_defect_otp_trigger:"",
            // is_driver_without_defect_verified:'',
            // is_driver_with_defect_verified:"",
            grn_no: "",
            customer_pod_date_without: "",
            gps_device_vehicle: "",
            device_no: '',
            // is_driver_without_defect_otp_trigger:"",
            driver_mobile: '',
            driver_mobile_no: "",
            updateSB: "",
            updatestart_km: '',
            updateend_km: '',
            updatesingle_driver_cost: '',
            updatedouble_driver_cost: '',
            update_id: '',
            addSB:'',
            addstart_km:'',
            addend_km:'',
            addsingle_driver_cost:'',
            adddouble_driver_cost:'',
        });
    }


    onCLickUpdateSB(rownode) {
        this.setState({
            updatestart_km: rownode["data"].start_km,
            updateend_km: rownode["data"].end_km,
            updatesingle_driver_cost: rownode["data"].single_driver_cost,
            updatedouble_driver_cost: rownode["data"].double_driver_cost,
            update_id: rownode["data"]._id,
            updateSB: "slider-translate-30p",
			overly:"show-m",
        })
    }


    changeHandler(event) {
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value
        })
    }
    onSubmitUpdate = async (event) => {
        event.preventDefault();
        await redirectURL.post("/consignments/updateSlabRate", { _id: this.state.update_id, single_driver_cost: this.state.updatesingle_driver_cost, double_driver_cost: this.state.updatedouble_driver_cost })
            .then((response) => {

                if (response.data.status == "success") {
                    this.setState({
                        show: true,
                        basicTitle: "Successfully updated data",
                        basicType: "success",
                        loadshow: "show-n",
                        updateSB: ''
                    })
                    this.loadVendorData()
                }
                else {
                    this.setState({
                        show: true,
                        basicTitle: "Failed to update data",
                        basicType: "danger",
                        loadshow: "show-n"
                    })
                }
            })
    }


    onSubmitAdd = async (event) => {
        event.preventDefault();
        await redirectURL.post("/consignments/addSlabRate", { start_km: this.state.addstart_km, end_km: this.state.addend_km, single_driver_cost: this.state.addsingle_driver_cost, double_driver_cost: this.state.adddouble_driver_cost })
            .then((response) => {
                if (response.data.status == "success") {
                    this.setState({
                        show: true,
                        basicTitle: "Successfully added Slab Rate",
                        basicType: "success",
                        loadshow: "show-n",
                        addSB: ''
                    })
                    this.loadVendorData()
                }
                else {
                    this.setState({
                        show: true,
                        basicTitle: response.data.message,
                        basicType: "danger",
                        loadshow: "show-n"
                    })
                }
            })
    }


    onCLickAddSB() {
        this.setState({
            addSB: 'slider-translate-30p',
			overly:"show-m",
            addstart_km: '',
            addend_km: '',
            addsingle_driver_cost: '',
            adddouble_driver_cost: '',
        })
    }

    closeAlert = async () => {
        await this.setState({
            show: false,
            loadshow: "show-n",
            overly: 'show-n',
        });
    }

    render() {

        var columnwithDefs = [
            {
                headerName: "Kilometer Range",
                field: "km_range",
                 resizable: true

            },
            {
                headerName: "Single Driver Cost",
                field: "single_driver_cost",
                 resizable: true

            },
            {
                headerName: "Double Driver Cost",
                field: "double_driver_cost",
                 resizable: true

            },
            {
                headerName: "",
                field: "_id",
                width: 150,
                cellRenderer: 'UpdateSB',
                filter: false,
                resizable: true

            }
        ]
        return (
            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}>
                </SweetAlert>
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="card">
                            <div className="card-header"  style={{  display: "flex", justifyContent: "space-between"}}>
                                <h5>
                                    <span>Driver Slab Rates</span>
                                </h5>
                                <button type="button" className="btn btn-warning" onClick={this.onCLickAddSB.bind(this)}>Add New</button>
                            </div>
                            <div className="card-body" style={{paddingTop:"5px"}}>


                                <div className="row">
                                    <div id="myGrid" style={{ marginTop: "20px", height: "480px", width: "100%" }} className="ag-theme-balham">
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={columnwithDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.rowData}
                                            enableCharts={true}
                                            enableRangeSelection={true}
                                            autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            detailCellRendererParams={this.state.detailCellRendererParams}
                                            frameworkComponents={this.state.frameworkComponents}
                                            stopEditingWhenGridLosesFocus={true}
                                            enableCellChangeFlash={false}
                                            rowClassRules={this.state.rowClassRules}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            masterDetail={true}
                                            rowSelection={this.state.rowSelection}
                                            suppressRowClickSelection={true}
                                            onPaginationChanged={this.resetPaginationSelection}

                                        />


                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>


                <div className={"slide-r " + (this.state.updateSB)} >

                    <div className="slide-r-title">
                        <h4>
                            Update Driver Slab Rates
                            <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{ position: "relative" }}>
                        <div className={"dataLoad " + (this.state.loadshow)}>
                            <div style={{ position: "absolute", left: "38%" }}>
                                <div class="loader-box">
                                    <div class="loader-box">
                                        <div class="rotate dashed colored"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-lg-12">
                            <form className="theme-form" onSubmit={this.onSubmitUpdate}>

                                <div className={"form-group mt-20p "}>
                                    <label className="">Start km</label>
                                    <span className="form-control"  style={{background:"#b5b5b5"}} >
                                        {
                                            (this.state.updatestart_km != "") ?
                                                this.state.updatestart_km
                                                : ""
                                        }
                                    </span>
                                </div>

                                <div className={"form-group mt-20p "}>
                                    <label className="">End km</label>

                                    <span className="form-control"  style={{background:"#b5b5b5"}} >
                                        {
                                            (this.state.updateend_km != "") ?
                                                this.state.updateend_km
                                                : ""
                                        }
                                    </span>
                                </div>
                                <div className="col-xl-12 col-lg-12 row form-group">
                                    <label className="col-xl-12 col-lg-12">Single Driver Cost <span style={{ color: "#ff0000" }}>*</span></label>
                                    <input type="number" step="0.01" name='updatesingle_driver_cost' value={this.state.updatesingle_driver_cost} id='updatesingle_driver_cost' onChange={this.changeHandler} required className="form-control" style={{border:"1px solid #b5b5b5"}}  />
                                </div>


                                <div className="col-xl-12 col-lg-12 row form-group">
                                    <label className="col-xl-12 col-lg-12">Double Driver Cost <span style={{ color: "#ff0000" }}>*</span></label>
                                    <input type="number" step="0.01" name='updatedouble_driver_cost' value={this.state.updatedouble_driver_cost} id='updatedouble_driver_cost' onChange={this.changeHandler} required className="form-control" style={{border:"1px solid #b5b5b5"}}  />
                                </div>

                                <div className="form-group">
                                    <button type="submit" className="btn btn-success">Update Item</button>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>


                <div className={"slide-r " + (this.state.addSB)} >

                    <div className="slide-r-title">
                        <h4>
                            Add Driver Slab Rates
                            <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{ position: "relative" }}>
                        <div className={"dataLoad " + (this.state.loadshow)}>
                            <div style={{ position: "absolute", left: "38%" }}>
                                <div class="loader-box">
                                    <div class="loader-box">
                                        <div class="rotate dashed colored"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-lg-12">
                            <form className="theme-form" onSubmit={this.onSubmitAdd}>

                            <div className="col-xl-12 col-lg-12 row form-group">
                                    <label className="col-xl-12 col-lg-12">Start km<span style={{ color: "#ff0000" }}>*</span></label>
                                        <input type="number" name='addstart_km' value={this.state.addstart_km} id='addstart_km' onChange={this.changeHandler} required  className="form-control" style={{border:"1px solid #b5b5b5"}}  />
                                </div>

                                <div className="col-xl-12 col-lg-12 row form-group">
                                    <label className="col-xl-12 col-lg-12">End km<span style={{ color: "#ff0000" }}>*</span></label>
                                        <input type="number" name='addend_km' value={this.state.addend_km} id='addend_km' onChange={this.changeHandler} required  className="form-control" style={{border:"1px solid #b5b5b5"}} />
                                </div>
                                <div className="col-xl-12 col-lg-12 row form-group">
                                    <label className="col-xl-12 col-lg-12">Single Driver Cost <span style={{ color: "#ff0000" }}>*</span></label>
                                    <input type="number" step="0.01" name='addsingle_driver_cost' value={this.state.addsingle_driver_cost} id='addsingle_driver_cost' onChange={this.changeHandler} required  className="form-control" style={{border:"1px solid #b5b5b5"}} />
                                </div>


                                <div className="col-xl-12 col-lg-12 row form-group">
                                    <label className="col-xl-12 col-lg-12">Double Driver Cost <span style={{ color: "#ff0000" }}>*</span></label>
                                    <input type="number" step="0.01" name='adddouble_driver_cost' value={this.state.adddouble_driver_cost} id='adddouble_driver_cost' onChange={this.changeHandler} required  className="form-control" style={{border:"1px solid #b5b5b5"}} />
                                </div>

                                <div className="form-group">
                                    <button type="submit" className="btn btn-success">Add Item</button>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}