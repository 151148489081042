
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const AccidentDocs = (props) => {
    const handleEventClick = (e) => {
        e.stopPropagation();
        //console.log("Overspeed ",props);
        props.context.componentParent.onShowAccidentDocs(props);
    };
    
    //}
    return (
        <div>
            {props.data.accident_exception === 1 ?
                <button onClick={handleEventClick} className="f12 btn btn-warning" style={{padding:"0 5px"}} title="View Documents" ><i className={"icofont icofont-file "}></i> View Documents</button>
            
            :""}
            
        </div>
    );
};

export default AccidentDocs;
