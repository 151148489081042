var verifyColumns = {
    checkValidations: function (dataList,reqParams,nonEmptyParams,nonNumericParams,nonDateParams)
    {
      var response = "";
      //first Check for all mandatory fields
      console.log("dataList ",dataList)
      console.log("reqParams ",reqParams)
      response = hasRequiredParameters(dataList,reqParams);
      console.log("response ", response)
      if (response != "")
      {
        return response;
      }
      //Check for empty field values
      response = rejectEmptyValues(dataList,nonEmptyParams);
      if (response != "")
      {
        return response;
      }
      //Check for Non Numeric values
      response = rejectNonNumericValues(dataList,nonNumericParams);
      if (response != "")
      {
        return response;
      }
      //Check for Invalid date values
      response = rejectInvalidDateValues(dataList,nonDateParams);
      if (response != "")
      {
        return response;
      }
      return response;
    },
   
  };


function hasRequiredParameters(dataList,paramsList)
{
    if(dataList.length > 0)
    {
            
        for (var i =0;i < dataList.length;i++)
        {
            // Check each element in the arrays
            for (let a = 0; a < dataList[i].length; a++) {
                var keys = Object.keys(dataList[i]);
                if (keys.length !== paramsList.length) {
                    return 'Invalid column(s)';
                }
                keys.map((ti) => {
                    console.log(paramsList[a].includes(ti))
                        if(paramsList[a].includes(ti))
                        {

                        }
                        else{
                            return 'Invalid column(s)';        
                        }
                })
            }
        }
        return "";
    }
    else{
        return "Empty";
    }
}

function rejectEmptyValues(dataList,paramsList)
{
  for (var i =0;i < dataList.length;i++)
  {
    for(var j = 0; j < paramsList.length;j++ )
    {
      if(dataList[i][paramsList[j]] === "")
      {
        return 'Value cannot be empty for "' + paramsList[j] + '" in row - ' + i;
      }
    }
  }
  return "";
}

function rejectNonNumericValues(dataList,paramsList)
{
  for (var i =0;i < dataList.length;i++)
  {
    for(var j = 0; j < paramsList.length;j++ )
    {
      if(isNaN(dataList[i][paramsList[j]]))
      {
        return 'Non numeric value found for "' + paramsList[j] + '" in row - ' + i;
      }
    }
  }
  return "";
}

function rejectInvalidDateValues(dataList,paramsList)
{
  for (var i =0;i < dataList.length;i++)
  {
    for(var j = 0; j < paramsList.length;j++ )
    {
      if (isNaN(Date.parse(dataList[i][paramsList[j]]))) {
          return 'Invalid date value found for "' + paramsList[j] + '" in row - ' + i;
      }
    }
  }
  return "";
}


exports.verifyColumns = verifyColumns;