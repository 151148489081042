import React, { Component } from "react";
import axios from 'axios';
import {Redirect} from 'react-router-dom';
import redirectURL from '../redirectURL'; // Assuming redirectURL is an axios instance
 
class EnmUserLogin extends Component {
    constructor(props) {
        super(props);
        this.state = {successLogin:0};
    }
 
    async componentDidMount() {
        const token = this.props.match.params.token;
        var ppwwdd = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
        var usrtoken = ppwwdd(token)
        const query = {
            token: token
        };
        try {
            const response = await redirectURL.post("/authenticateUserToken", query);
            console.log("After login ", response);
 
            if (response.status === 200) {
                if (response.data.auth) {
                    if (response.data.otpAuthentication === 1) {
                        this.setState({
                            otpAuthentication: response.data.otpAuthentication,
                            otpMessage: "OTP has been sent to your Email",
                            userData: response.data,
                            successLogin: 0,
                        });
                    } else {
                        this.setState({
                            "successLogin" : 1,
                        })
                        await redirectURL.post("/manage/saveuserlogged", {
                            token: response.data.token,
                            userId: response.data.userid
                        });
                       
                        localStorage.setItem('token', response.data.token);

                        localStorage.setItem('userid', response.data.userid);
                        let deptCode = JSON.stringify(response.data.user.dept_code);
                        //console.log("In  LoginForm, response.data = ", response.data);
                        localStorage.setItem('dept_code', deptCode);
                        localStorage.setItem('is_admin', response.data.user.is_admin);
                        localStorage.setItem('is_dept_admin', response.data.user.is_dept_admin);
                        localStorage.setItem('is_global', response.data.user.is_global);
                        localStorage.setItem('firstname', response.data.user.firstname);
                        localStorage.setItem('username', response.data.user.username);
                        localStorage.setItem('lastname', response.data.user.lastname);
                        console.log("response.data.user.email ", response.data.user.email)
                        var useremailat = response.data.user.email.replace("[at]", "@")
                        var useremail = useremailat.replace("[dot]", ".")

                        localStorage.setItem('email', useremail);
                        localStorage.setItem('user_type', response.data.user.user_type);							
                        // localStorage.setItem('prt_transporter_code', response.data.user.prt_transporter_code);
                        localStorage.setItem('prt_transporter_code', JSON.stringify(response.data.user.prt_transporter_code));

                        //console.log("response.data.menus ", response.data.menus);
                        localStorage.setItem('usermenus', JSON.stringify(response.data.menus));
                        //console.log("response.data.usermenucodes ", response.data.usermenucodes);
                        localStorage.setItem('usermenucodes', JSON.stringify(response.data.usermenucodes));
                        localStorage.setItem('transportercode', response.data.user.transporter_code);
                        localStorage.setItem('customer_code', response.data.user.customer_code);
                        localStorage.setItem('zone', response.data.user.zone);
                        localStorage.setItem('region', response.data.user.region);
                        localStorage.setItem('areas', response.data.user.areas);
                        localStorage.setItem('assign_dealers', response.data.user.assign_dealers);

                        localStorage.setItem('roles', response.data.user.roles);
                        localStorage.setItem('is_plant_code', (response.data.user.plant_code != undefined)?response.data.user.plant_code:"");
                        localStorage.setItem('is_enmovil_transporter', this.state.is_enmovil_transporter);
                        localStorage.setItem('is_sub_user', response.data.user.is_sub_user);
                        localStorage.setItem('cha_code', response.data.user.cha_code);
                        localStorage.setItem('system_password_change', response.data.user.system_password_change);
                        var md = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
                        var m = md(JSON.stringify(response.data.user))
                        localStorage.setItem('m', m);
                        localStorage.setItem('supplier_code', response.data.user.supplier_code);
                        try {
                            localStorage.setItem('zone', response.data.user.zone);
                            if (response.data.user.vehicle_group != undefined) {
                                localStorage.setItem('vehicle_group', response.data.user.vehicle_group);
                            }
                            else {
                                localStorage.setItem('vehicle_group', "");
                            }
                        }
                        catch (e) { }
 
                        this.setState({
                            username: '',
                            email: '',
                            password: '',
                            loginMessage: response.data.msg
                        });
                    }
                } else {
                    this.setState({
                        username: '',
                        email: '',
                        password: '',
                        loginMessage: response.data.msg
                    });
                }
            } else {
                this.setState({
                    username: '',
                    email: '',
                    password: '',
                    loginMessage: response.data.msg
                });
            }
        } catch (error) {
            console.error("Error authenticating user:", error);
        }
    }
 
    getUserDashboardUrl(pathToMatch = null) {
        var dashboardUrl = "sndconsignments";    
        return dashboardUrl;
    }
 
    cipher = salt => {
        const textToChars = text => text.split('').map(c => c.charCodeAt(0));
        const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
        const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);
   
        return text => text.split('')
            .map(textToChars)
            .map(applySaltToChar)
            .map(byteHex)
            .join('');
    }
    render() {
        if(localStorage.getItem('token') != '' && localStorage.getItem('token') != null)
        {
            var successLogin = this.state.successLogin;
            if(successLogin == 1)
            {
                let dashboardUrl = this.getUserDashboardUrl();
                console.log(dashboardUrl,"dashboardUrl1143");
                //let dashboardUrl = this.state.landingpage;
                // return <Redirect to="dashboard/snddefault" push={true} />
 
                return <Redirect to={"/"+dashboardUrl} push={true} />
            }
        }
        return (
            <div>
                {/* Loader starts */}
                <div className="loader-wrapper">
                    <div className="loader bg-white">
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <h4>Authenticating... <span>&#x263A;</span></h4>
                    </div>
                </div>
                {/* Loader ends */}
            </div>
        );
    }
}
 
export default EnmUserLogin;