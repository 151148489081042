/* eslint-disable eqeqeq */
/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import $ from "jquery";
import Select from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';

import axios from 'axios';
import Multiselect from 'multiselect-dropdown-react';
import Breadcrumb from '../common/breadcrumb.component';
import { Redirect } from 'react-router-dom';
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");

var drawingManager;
var selectedShape;
var colors = ['#1D9567', '#1692A5', '#F4D749', '#F73E3E', '#B7B3B3'];
var selectedColor;
var colorButtons = {};
var fencingLength = 0;
var mapZoom = 12;
var coordinates = [];
var map;

var marker;
export default class AddDeviation extends Component {
    constructor(props){
        super(props);
        this.state = {
            pageTitle: "Add Deviation",
            eventGridAction: "gridAction",
            eventCounterAction: "counterAction",
            eventFormAction: "formAction",
            currentDepartmentCode: null,
            currentDepartmentTitle: null,
            routemap: '',
            departments: [],
            coords: [],
            dept_code: [],
            erroMessage: '',
            redirectpage: '',
            typeListOptions: [],
            plantListOptoins: [],
            show: false,
            basicTitle: '',
            basicType: "default",
            show1: false,
            basicTitle1: '',
            basicType1: "default",
            isParking: false,
            plantDetails : null,
            deviation_address : "",
            deviation_city : "",
            deviation_lat : "",
            deviation_lng : "",
            deviation_name : "",
            deviation_state : "",
			deviation_postal_code:"",
			deviation_code:"",
            zone : "",
            zone_code:"",
            deviation_zone:{"value":"","label":""}
        };
        window.getLatlng = this.getLatlng.bind(this);
		this.closeAlert1 =this.closeAlert1.bind(this);
    }
	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
			} else {
				console.log("GA disabled...");
			}
		} catch (error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}
	componentDidMount() {
		if (googleAnalytics.page.enableGA) {
			this.logPageView();
		}
		//get dynamic list for type dropdown
		this.getDropDownList()
		// get department code to filter records by department
		let currentDepartmentCode = this.getCurrentDepartmentCode(this.props.match.path);
		if (!currentDepartmentCode) {
			setTimeout(
				function () {
					redirectURL.post('/overview/departments')
						.then((response) => {
							//console.log(response.data);
							this.setState({
								departments: response.data
							});

						})
						.catch(function (error) {
							console.log(error)
						});

				}
					.bind(this),
				2000
			);
		}
		this.showMap();
	}

	getDropDownList = () => {
		redirectURL.post('/consignments/getTypeDropDownForgeoFence').then(response => {
			// console.log("response118", response.data)
			let typeListOptions = response.data.data1
			let plantListOptoins = response.data.pD1
			// console.log('typeListOptions',typeListOptions)
			// console.log('plantListOptoins',plantListOptoins)
			this.setState({
				typeListOptions,
				plantListOptoins
			})

		})
	}

	showMap = () => {
		this.renderMap();
	}
	renderMap = () => {

		loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyD1AFWPoJqPaemwGO4A7P7Q6CsUJ1TLGJk&libraries=places,drawing&callback=initMap");
		window.initMap = this.initMap
	}
	getCurrentDepartmentCode(pathToMatch = null) {
		let departmentCode = null;
		let departmentName = null;
		let departmentTitle = null;
		switch (pathToMatch) {
			case "/addDeviation":
				departmentCode = 'SNDG';
				departmentName = "Sales and Dispatch";
				departmentTitle = " (Sales and Dispatch) ";
				break;
			case "/addDeviation":
				departmentCode = 'LOG-PRT';
				departmentName = "Spare Parts";
				departmentTitle = " (Spare Parts) ";
				break;
			default:
				console.log("IN getDepartmentFilter, default, params = ", pathToMatch);
		}
		this.setState({
			currentDepartmentTitle: departmentTitle,
			currentDepartmentCode: departmentCode,
			departments: [{ "dept_code": departmentCode, "dept_name": departmentName }],
			dept_code: departmentCode
		});
		return departmentCode;
	}

	departmentDropDown() {
		let items = [];
		//console.log("this.state.departments = ", this.state.departments);
		this.state.departments.map(item => {
			items.push({ value: item.dept_code, label: item.dept_name });
			//items.push(<option key={item.dept_code} value={item.dept_code}>{item.dept_name}</option>);
		});
		//console.log("IN departmentDropDown items= ", items);
		return items;
	}

	initMap = () => {
		try {
			var currentwindow;
			map = new window.google.maps.Map(document.getElementById('map_canvas'), {
				zoom: 8,
				center: new window.google.maps.LatLng(17.3580, 78.4869),
				mapTypeId: window.google.maps.MapTypeId.ROADMAP,
			});

			var input = document.getElementById('search');
			var searchBox = new window.google.maps.places.SearchBox(input);
			map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);

			// Bias the SearchBox results towards current map's viewport.
			map.addListener('bounds_changed', function () {

				searchBox.setBounds(map.getBounds());
			});

			searchBox.addListener('places_changed', function () {
				var places = searchBox.getPlaces();

				if (places.length == 0) {
					return;
				}

                
				// Clear out the old markers.
				// marker.forEach(function (marker) {
					// console.log("Search Marker ", marker)
					marker.setMap(null);
				// });
				// For each place, get the icon, name and location.
				var bounds = new window.google.maps.LatLngBounds();
				places.forEach(function (place) {
					if (!place.geometry) {
						console.log("Returned place contains no geometry");
						return;
					}
					var icon = {
						url: place.icon,
						size: new window.google.maps.Size(71, 71),
						origin: new window.google.maps.Point(0, 0),
						anchor: new window.google.maps.Point(17, 34),
						scaledSize: new window.google.maps.Size(25, 25)
					};

					// Create a marker for each place.
					//console.log("Plae Lat", place.geometry.location.lat());
					document.getElementById("latitude").value = place.geometry.location.lat();

					document.getElementById("longitude").value = place.geometry.location.lng();
                    window.getLatlng(place.geometry.location.lat(),place.geometry.location.lng());
					marker = new window.google.maps.Marker({
						map: map,
						icon: icon,
						title: place.name,
						position: place.geometry.location
					})

					if (place.geometry.viewport) {
						// Only geocodes have viewport.
						bounds.union(place.geometry.viewport);
					} else {
						bounds.extend(place.geometry.location);
					}
				});
				map.fitBounds(bounds);
			});

            marker = new window.google.maps.Marker({
				map: map,
				draggable: true,
				animation: window.google.maps.Animation.DROP,
				//position: {lat: 28.48, lng: 77.06}
			});

			marker.addListener('click', function () {
				marker.setAnimation(window.google.maps.Animation.BOUNCE)
			});

			window.google.maps.event.addListener(map, 'click', function (event) {

				document.getElementById("latitude").value = event.latLng.lat();
				document.getElementById("longitude").value = event.latLng.lng();
				marker.setPosition(event.latLng);

			});
			
		}
		catch (e) {
			console.log(e);
		}
	}

    getLatlng(lat,lng){
        this.setState({
            deviation_lat:lat,
            deviation_lng:lng
        })
    }
	fenceTypeHandler = (event) => {
		console.log(event.target)
	}


	changeHandler = (deviation_zone) => {
		this.setState({deviation_zone})
	}

	changePlantHandler = plantDetails =>{
		this.setState({plantDetails})
	}

	checkHandler = (event) => {

		let name = event.target.name;
		let value = event.target.value;
		this.setState({ [name]: value });
	}
	changeInputHandler = (event) => {
		let name = event.target.name;
		if(name == "deviation_postal_code")
		{
			var regex = /^[0-9]+$/;
			// if (!regex.test(e.target.value)) {
			event.target.value = event.target.value.replace(/[^0-9]/g, '');
			event.target.value = event.target.value.slice(0, 6);
		}
		let value = event.target.value;
		this.setState({ [name]: value });
	}

	changeArrayHandler = dept_code => {
		/*this.setState({
			dept_code:[...this.state.dept_code,event.target.value]
		},() => {
			console.log(this.state.dept_code)
		});
		*/

		this.setState(
			{ dept_code },
			() => console.log(`Option selected:`, this.state.dept_code)
		);


	}

	checkNotifyHandler = (event) => {
		let name = event.target.name;
		let value = event.target.value;
		this.setState({ [name]: value });
	}

	applyCoords = (e) => {
		console.log(e);
	}

	formHandler = (event) => {

		event.preventDefault();
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmitted,
			}
			googleAnalytics.logEvent(eventOptions);
		}
        var deviation_lat = $("#latitude").val();
        var deviation_lng = $("#longitude").val();
		var formdata = {
			deviation_address : this.state.deviation_address,
			deviation_city : this.state.deviation_city,
			deviation_lat : deviation_lat,
			deviation_lng : deviation_lng,
			deviation_name : this.state.deviation_name,
			// deviation_code : this.state.deviation_code,
			deviation_state : this.state.deviation_state,
			deviation_postal_code: this.state.deviation_postal_code,
			zone : this.state.deviation_zone.label,
			zone_code:this.state.deviation_zone.value
		}
		// console.log("formdata ", formdata)

		if(this.state.deviation_address != "" && this.state.deviation_city != ""  && deviation_lat != ""  && deviation_lng != ""  && this.state.deviation_name != ""  && this.state.deviation_state != "" && this.state.deviation_postal_code != "" && this.state.deviation_zone.value != "" )
		{
			
			if(this.state.plantDetails !== null && this.state.plantDetails !== undefined) formdata.plant_code = this.state.plantDetails.value
			console.log(formdata);
			// console.log("coordinates", coordinates);
			
			redirectURL.post("/masters/saveDeviation", formdata, {
				headers: {
					'Content-Type': 'application/json'
				}
			}).
        	then(
        		(response) => {
        			// console.log(response);
        			if (response.data.message == 'success') {
        				this.setState({
        					deviation_address : "",
                            deviation_city : "",
                            deviation_lat : "",
                            deviation_lng : "",
                            deviation_name : "",
                            deviation_state : "",
							deviation_postal_code:"",
                            zone : "",
                            zone_code:"",
                            deviation_zone:{"value":"","label":""},
        					show: true,
        					basicType: "success",
        					basicTitle: "Successfully added deviation"
        				});

        			}
					if (response.data.message == 'exists') {
        				this.setState({
        					show: true,
        					basicType: "danger",
        					basicTitle: "Deviation information already exists"
        				});

        			}

        			// window.location.href='/deviations';

        		}
        	)
        	.catch(function (error) {
        		console.log(error);
        	});
		}
		else{
			this.setState({
				show1: true,
				basicType1: "danger",
				basicTitle1: "All * fields are mandatory"
			})
		}
		
	}

	closeAlert = async () => {
		await this.setState({
			show: false
		});
		
		await setTimeout(()=>{
			window.location.href='/deviations';
		},300)
	}

	closeAlert1 = async () => {
		await this.setState({
			show1: false
		});
	}

	render() {
		var redurl = "/deviations";
		
		let order_cnt = [];

		const dStyles = {
			width: '100%',
			height: '500px'
		}
		const { dept_code } = this.state;
		const { geo_type } = this.state;

		return (
			<div className="container-fluid">
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					onConfirm={this.closeAlert}>
				</SweetAlert>
				<SweetAlert
					show={this.state.show1}
					type={this.state.basicType1}
					title={this.state.basicTitle1}
					onConfirm={this.closeAlert1}>
				</SweetAlert>
				<div className="row">
					<div className="col-xl-12 col-lg-12">
						<div className="card">

							<div className="card-header">
								<h5>
									<i className="icon-map cus-i"></i> <span>{this.state.pageTitle}</span>
								</h5>
							</div>
							<div className="row card-body">

								<form className=" theme-form col-xl-4 col-lg-4 row" onSubmit={this.formHandler}>
									
									<div className="col-xl-12 col-lg-12">
										<div className="form-group">
											<label className="c-lbl">Deviation Name <span style={{color:"#ff0000"}}>*</span></label>
											<input type="text" name="deviation_name" value={this.state.deviation_name} onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="Deviation Name" required />
										</div>
									</div>
									<div className="col-xl-12 col-lg-12">
										<div className="form-group">
											<label className="c-lbl">Deviation Address <span style={{color:"#ff0000"}}>*</span></label>
											<input type="text" name="deviation_address" value={this.state.deviation_address} onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="Deviation Address" required />
										</div>
									</div>
									<div className="col-xl-6 col-lg-12">
										<div className="form-group">
											<label className="c-lbl">Deviation City <span style={{color:"#ff0000"}}>*</span></label>
											<input type="text" name="deviation_city" value={this.state.deviation_city} onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="Deviation City" required />
										</div>
									</div>
                                    <div className="col-xl-6 col-lg-12">
										<div className="form-group">
											<label className="c-lbl">Deviation State <span style={{color:"#ff0000"}}>*</span></label>
											<input type="text" name="deviation_state" value={this.state.deviation_state} onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="Deviation State" required />
										</div>
									</div>
									
                                    <div className="col-xl-12 col-lg-12">
										<div className="form-group">
											<label className="c-lbl">Postal Code <span style={{color:"#ff0000"}}>*</span></label>
											<input type="text" name="deviation_postal_code" value={this.state.deviation_postal_code} onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="Postal Code" required />
										</div>
									</div>
                                    <div className="col-xl-6 col-lg-12">
										<div className="form-group">
											<label className="c-lbl">Deviation Latitude <span style={{color:"#ff0000"}}>*</span></label>
											<input type="text" name="deviation_lat" id="latitude"  className="form-control"
												readOnly={true}
												required />
										</div>
									</div>
									<div className="col-xl-6 col-lg-12">
										<div className="form-group">
											<label className="c-lbl">Deviation Longitude <span style={{color:"#ff0000"}}>*</span></label>
											<input type="text" name="deviation_lng" id="longitude" className="form-control"  
												readOnly={true} 
												required />
										</div>
									</div>
									<div className="col-xl-12 col-lg-12">
									
										<div className="form-group">
											<label className="c-lbl">Zone <span style={{color:"#ff0000"}}>*</span></label>
											<Select 
                                                value={this.state.deviation_zone} 
                                                onChange={this.changeHandler.bind(this)} 
                                                style={{ borderRadius: "0px" }}
                                                options={[
                                                    {
                                                        value:"DMCZ",
                                                        label:"Central"
                                                    },
                                                    {
                                                        value:"DMEZ",
                                                        label:"East"
                                                    },
                                                    {
                                                        value:"DMWZ",
                                                        label:"West"
                                                    },
                                                    {
                                                        value:"DMNZ",
                                                        label:"North"
                                                    },
                                                    {
                                                        value:"DMSZ",
                                                        label:"South"
                                                    }
                                                    
                                                ]}
                                                required
                                            />
										</div>
									</div>
									<div className="col-xl-12 col-lg-12">
										
										<div className="form-group">
											<button type="submit" className="btn btn-success cs-btn" id="saveGeo">Save</button>
											<a href={redurl} className="btn btn-info cs-btn">Cancel</a>

										</div>
									</div>

								</form>
								<div className="col-xl-8 col-lg-8">

									{this.state.erroMessage == '' ? '' :
										<div className="alert alert-danger"></div>
									}
									<input type="text" name="search" className="col-xl-6 col-lg-6 form-control mt-5p" id="search" placeholder="Enter Address to search" />


									<div id="map_canvas" className="col-xl-12 col-lg-12" style={dStyles}></div>

									<div className="col-xl-12 col-lg-12">
										<input type="hidden" name="coords" id="coords" value={this.state.coords} />
									</div>

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

		);
	}
}
function loadScript(url) {
	var index = window.document.getElementsByTagName("script")[0]
	var script = window.document.createElement("script")
	script.src = url
	script.async = true
	script.defer = true
	index.parentNode.insertBefore(script, index)
}

function timeConvert(n) {
	var num = n;
	var hours = (num / (3600));
	var rhours = Math.floor(hours);
	var minutes = (n) / (60);
	var rminutes = Math.round(minutes);
	return rhours + " hour(s) and " + rminutes + " minute(s).";
}
var currentinfowindow = null;
function getTimeInfo(marker, timedet, coords) {
	var timeinfo = new window.google.maps.InfoWindow({
		content: "Time at this location: " + timedet
	});

	marker.addListener('click', function () {

		if (currentinfowindow != null) {
			currentinfowindow.close();
			currentinfowindow = timeinfo;
			timeinfo.open(marker.get('map'), marker);
		}
		else {
			currentinfowindow = timeinfo;
			timeinfo.open(marker.get('map'), marker);
		}

	});
}


function clearSelection() {
	if (selectedShape) {
		selectedShape.setEditable(false);
		selectedShape = null;
	}
}

function setSelection(shape) {
	clearSelection();
	selectedShape = shape;
	//console.log("selectedShape",selectedShape);
	shape.setEditable(true);

	selectColor(shape.get('fillColor') || shape.get('strokeColor'));
}

function deleteSelectedShape() {
	fencingLength = 0;
	if (selectedShape) {
		selectedShape.setMap(null);
	}
	// To show:
	drawingManager.setOptions({
		drawingControl: true
	});
}


function selectColor(color) {
	selectedColor = color;
	for (var i = 0; i < colors.length; ++i) {
		var currColor = colors[i];
		//console.log(colorButtons[currColor]);
		//colorButtons[currColor].style.border = currColor == color ? '1px solid #789' : '1px solid #fff';
	}

	// Retrieves the current options from the drawing manager and replaces the
	// stroke or fill color as appropriate.
	var polylineOptions = drawingManager.get('polylineOptions');
	polylineOptions.strokeColor = color;
	drawingManager.set('polylineOptions', polylineOptions);

	var rectangleOptions = drawingManager.get('rectangleOptions');
	rectangleOptions.fillColor = color;
	drawingManager.set('rectangleOptions', rectangleOptions);

	var circleOptions = drawingManager.get('circleOptions');
	circleOptions.fillColor = color;
	drawingManager.set('circleOptions', circleOptions);

	var polygonOptions = drawingManager.get('polygonOptions');
	polygonOptions.fillColor = color;
	drawingManager.set('polygonOptions', polygonOptions);
}

function setSelectedShapeColor(color) {
	if (selectedShape) {
		if (selectedShape.type == window.google.maps.drawing.OverlayType.POLYLINE) {
			selectedShape.set('strokeColor', color);
		} else {
			selectedShape.set('fillColor', color);
		}
	}
}

function makeColorButton(color) {
	var button = document.createElement('span');
	button.className = 'color-button';
	button.style.backgroundColor = color;
	window.google.maps.event.addDomListener(button, 'click', function () {
		selectColor(color);
		setSelectedShapeColor(color);
	});

	return button;
}

function buildColorPalette() {
	var colorPalette = document.getElementById('color-palette');
	for (var i = 0; i < colors.length; ++i) {
		var currColor = colors[i];
		var colorButton = makeColorButton(currColor);
		colorPalette.appendChild(colorButton);
		colorButtons[currColor] = colorButton;
	}
	selectColor(colors[0]);
}

function getSelectedShape(coords) {
	var lt = []
	//console.log("coordsLatlngs ",coords);
	for (let value of Object.values(coords)) {
		//console.log("Val is ",value); // John, then 30
		lt.push(value)
	}
	//console.log("Combine ",lt);
	document.getElementById('coords').value = lt;
	//return lt;
}
