import React, { Component } from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
var redirectURL = require('../redirectURL');


export default class DieselMaster extends Component {

    
    constructor(props) {
        super(props);
        this.state = {
            eventGridAction: "gridAction",
            eventCounterAction: "counterAction",
            eventFormAction: "formAction",
            modules: AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                resizable: true,
                pivot: true,
                enableValue: true,
                enableRowGroup: true,
            },
            rowData: [],
            rowSelection: 'multiple',

            paginationPageSize: 100,
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            detailCellRendererParams: {},
            rowClassRules: {

            },
            loadshow: "show-n",
            overly: "show-n",
        }

    }


    async loadDieselData() {
        await redirectURL.get("/consignments/getDieselData")
            .then((response) => {
                if (response.data.status === "success") {
                    this.setState({
                        rowData: response.data.data,
                        loadshow:"show-n"
                    })
                }
                else {
                    this.setState({
                        rowData: [],
                        loadshow:"show-n"
                    })
                }
            })
            .catch(error=>console.log(error))
    }
    componentDidMount(){
        this.setState({
            loadshow:"show-m"
        })
        this.loadDieselData()
    }
    
    
    render() {

        var columnwithDefs = [
            {
                headerName: "State",
                field: "state",
                resizable: true
            },
            {
                headerName: "Diesel",
                field: "Diesel",
                resizable: true
            },
            {
                headerName: "Petrol",
                field: "Petrol",
                resizable: true
            },
            // {
            //     headerName: "CNG",
            //     field: "cng",
            //     resizable: true
            // },
            {
                headerName: "Date",
                field: "Date",
                resizable: true
            },
        ]
        return (
            <div className="container-fluid">
            <div className="row">
                <div className="col-xl-12 col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h5>
                                <span>Fuels Price Data</span>
                            </h5>
                        </div>
                        <div className="card-body" style={{paddingTop:"5px"}}>


                            <div className="row">
                                <div id="myGrid" style={{ marginTop: "20px", height: "480px", width: "100%" }} className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        enableCharts={true}
                                        enableRangeSelection={true}
                                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        detailCellRendererParams={this.state.detailCellRendererParams}
                                        stopEditingWhenGridLosesFocus={true}
                                        enableCellChangeFlash={false}
                                        rowClassRules={this.state.rowClassRules}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        masterDetail={true}
                                        rowSelection={this.state.rowSelection}
                                        suppressRowClickSelection={true}
                                        onPaginationChanged={this.resetPaginationSelection}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className={"dataLoadpage " +(this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div className="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                </div>
            </div>
        );
    }
}