/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import Autosuggest from 'react-autosuggest';

import Grid from '../layouts/gridActionComponent';
import Breadcrumb from '../common/breadcrumb.component';
import EditItem from '../layouts/editActionComponent';
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");

export default class Dealers extends Component {

    constructor(props) {
        super(props);

        this.state = {
			lists:[],
			departmentCode: null,
			currentDepartmentTitle: null,
			currentAddGeoFenceLink: null,
			usermanualmodal:false

        };
	}
	getCurrentDepartmentCode(pathToMatch = null) {
		let department = null;
		let departmentName = null;
		let currentAddGeoFenceLink = "/sndadddealer";
		switch(pathToMatch) {
			case "/sndadddealer":
				department='SNDG';
				departmentName = " (Sales and Dispatch) ";
				currentAddGeoFenceLink = "/sndadddealer";
				break;
			case "/addprtdealer":
				department='LOG-PRT';
				departmentName = " (Spare Parts) ";
				currentAddGeoFenceLink = "/addprtdealer";
				break;
			default:
				console.log("IN getDepartmentFilter, default, params = ", pathToMatch);
		}
		this.setState({
			currentDepartmentTitle:departmentName,
			currentAddGeoFenceLink:currentAddGeoFenceLink
		});
		return department;
	}
    componentDidMount(){
		if (googleAnalytics.page.enableGA) {
			googleAnalytics.logPageView();
		}
		// get department code to filter records by department
		let currentDepartmentCode = this.getCurrentDepartmentCode(this.props.match.path);
		this.setState({
			departmentCode:currentDepartmentCode
		});
        if(this.props.match.path == "/snddealers")
        {
            var upath = "/masters/snddealerslist";
        }
        else{
            var upath = "/masters/prtdealers";
        }
		var parameters = {};
    	redirectURL.post(upath,parameters)
		.then(
			(response)=>{
				let records = (response.data.records) ? response.data.records : [];
				this.setState({
					lists:records
				});
			}
		)
		.catch(function(error){
			console.log(error);
		});
    }

    onCloseUserManualModal = () => {
        this.setState({ usermanualmodal: false });
    };

    onClickShowUserManaul = () => {
        this.setState({ usermanualmodal: true });
    };
    render(){
		const columnwithDefs = [
	  	        {
	    	          headerName: "Dealer Name",
	    	          field: "consignee_name",
	    	          width: 200

    	        },
    	    
                {
                    headerName: "Dealer Code",
                    field: "consignee_code",
                    width: 100

                },
                {
                    headerName: "Ship To Party Code",
                    field: "ship_to_party_code",
                    width: 150
                 },
                 {
                     headerName: "Ship To Party Name",
                     field: "ship_to_party_name",
                     width: 150
                  },
          
                  {
                    headerName: "EPOD Code",
                    field: "epod_code",
                    width: 150
                 }
		        

	      ];
        return (

    		<div className="container-fluid">
            	<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">

			                <div className="card-header">
				       			<h5>
				       				<i className="icon-map cus-i"></i> <span>Dealers {this.state.currentDepartmentTitle} </span>
				       				<a href={this.state.currentAddGeoFenceLink} className="btn btn-success pull-right cs-btn"> <i className="icon-plus"></i> Add Dealer</a>
				       			</h5>
				   			</div>
				   			<div className="card-body">
				   				<Grid actionMethod={"deleteGeo"} screenurl={"/manage/geofences"} actionCall={"geofence"} columnwithDefs={columnwithDefs} rowData={this.state.lists} />
				   			</div>
				   		</div>
				   	</div>
				 </div>
				
            </div>

        );
    }
}
