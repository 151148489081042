
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import _ from "lodash";
import Modal from 'react-responsive-modal';
import SweetAlert from 'react-bootstrap-sweetalert';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import Select from 'react-select';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import axios from 'axios';
import Autosuggest from 'react-autosuggest';
import Grid from '../layouts/gridActionComponent';
import Breadcrumb from '../common/breadcrumb.component';
import EditItem from '../layouts/editActionComponent';
import { event } from 'react-ga';
import {
    getDDMMYYYY,
    getDDMMYYYYHHMMSS,
    getYYYYMMDD,
    getYYYYMMDDHHMMSS,
    getHyphenYYYYMMDDHHMMSS,
    getHyphenDDMMMYYYYHHMM,
    getHyphenYYYYMMDD,
    getHyphenDDMMYYYYHHMMSS,
    getHyphenDDMMYYYY,
    getDDMMMYYYYHHMMDefault
} from '../common/utils';
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
var moment = require('moment');
export default class UniqueHoldData extends Component {
    constructor(props) {
        super(props);
        const today = moment().utcOffset("+05:30").subtract(7, 'days').format("DD-MM-YYYY"); // Get today's date with +05:30 offset
        const endDate = moment().utcOffset("+05:30").format("DD-MM-YYYY"); // Get tomorrow's date with +05:30 offset
        this.state = {
            lists: [],
            departmentCode: null,
            currentDepartmentTitle: null,
            currentAddGeoFenceLink: null,
            usermanualmodal: false,
            departmentCode: null,
            currentDepartmentTitle: null,
            currentAddGeoFenceLink: null,
            pagetitle: "Hold Request",
            rowData: [],
            show1: false,
            basicTitle1: '',
            basicType1: "default",
            show: false,
            basicTitle: '',
            basicType: "default",
            loadshow: "show-n",
            from_date: "",
            to_date: "",
            noOfVehicles: 0,
            reasonForHold: '',
            approvalEmailId: "",
            requesterEmail: '',
            salesPlannerEmail: '',
            oblEmail: '',
            emailIds: {
                approvalEmailId: "",
                requesterEmail: '',
                salesPlannerEmail: '',
                oblEmail: '',
            },
            errors: {},
            startDate: today, // Set default to today
            endDate: endDate, // Set default to tomorrow
            rowData: [],
            sliderBulkUpdate: "",
            loadshow: 'show-n',
            overly: 'show-n',
            defaultColDef: {
                sortable: true,
                filter: true,
                resizable: true,
                pivot: true,
                enableValue: true,
                // enableRowGroup: true,
            },
            modules: AllModules,
            animateRows: true,
            debug: true,
            showToolPanel: false,
            uppressAggFuncInHeader: true,
            childconfs: [],
            childrow: [],
            rowModelType: "serverSide",
            paginationPageSize: 100,
            cacheBlockSize: 10,
            maxBlocksInCache: 1,
            rowData: [],
            maprowData: null,
            rowSelection: 'single',
            rowHeight: 32,
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            overlayNoRowsTemplate: "",
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            detailCellRendererParams: {},
            rowClassRules: {},
            frameworkComponents: {},
            errrorRowData: "",
            inplant: "btn-info",
            yard: "btn-default",
            matrixFile: null,
            showFilters: false,
            selected_hold_option: { value: "All", label: "All" },
            hold_options: [{ value: "All", label: "All" }],
            inputValue: '',
            hold_id: '',
            usergridstate: [],
            pagetitle: "",
        };
    }
    async componentDidMount() {
        try {
            this.setState({
                loadshow: "show-m",
                overly: "show-m"
            })
            let { hold_id } = this.props.match.params
            let params = {
                hold_id: hold_id
            }
            if (!hold_id) {
                return ''
            }
            let response = await redirectURL.post("/consignments/getuniqueholdiddata", params);
            let data = response.data || []
            let isYard = response.data[0].hold_type == 2 ? true : false
            this.setState({
                rowData: data,
                hold_id: hold_id,
                loadshow: "show-n",
                overly: "show-n",
                inplant: isYard ? 'btn-default' : 'btn-info',
            })
            redirectURL.post("/consignments/usergridstates", { userId: localStorage.getItem("userid"), screenurl: window.location.pathname })
                .then(async (resp) => {
                    await this.setState({
                        usergridstate: resp.data
                    });
                    await this.restoreGridStates();
                })
        } catch (e) {
            console.log(e, 'e')
            this.setState({
                loadshow: "show-n",
                overly: "show-n"
            })
        }
    }
    closeAlert = () => {
        this.setState({
            show: false
        });
    }
    onClickHideAll() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
        });
    }
    onBackToHoldReport = () => {
        window.location.href = "/holdreport"
    }
    onClickSaveGridState() {
        window.colState = this.gridColumnApi.getColumnState();
        window.groupState = this.gridColumnApi.getColumnGroupState();
        window.sortState = this.gridApi.getSortModel();
        window.filterState = this.gridApi.getFilterModel();
        let screenpage = 'Hold Report ';
        let reqparams = {
            gridcolumns: window.colState,
            gridgroup: window.groupState,
            gridcolsort: window.sortState,
            gridcolfilter: window.filterState,
            userId: localStorage.getItem("userid"),
            screenurl: window.location.pathname,
            screentitle: screenpage
        }
        redirectURL.post("/consignments/saveGridStates", reqparams)
            .then((response) => {
                this.setState({
                    show: true,
                    basicTitle: "Successfully saved grid layout",
                    basicType: "success",
                    screenurl: window.location.pathname,
                    screentitle: screenpage
                })
            })
            .catch(function (e) {
            })
    }
    restoreGridStates() {
        if (this.state.usergridstate.length > 0) {
            var windowstates = this.state.usergridstate;
            this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
            this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
            this.gridApi.setSortModel(windowstates[0].gridcolsort);
            this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
        }
    }
    resetState = () => {
        this.gridColumnApi.resetColumnState();
        this.gridColumnApi.resetColumnGroupState();
        this.gridApi.setSortModel(null);
        this.gridApi.setFilterModel(null);
        var reqparams = {
            userId: localStorage.getItem("userid"),
            screenurl: window.location.pathname,
            screentitle: this.state.screenpage
        }
        redirectURL.post("/consignments/removeUserGridState", reqparams)
            .then((response) => {
                this.setState({
                    show: true,
                    basicTitle: "Successfully reset default grid layout",
                    basicType: "success"
                })
            })
    };
    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    };
    render() {
        const {
            rowData,
            inplant
        } = this.state;
        let hideForYard = inplant == 'btn-info' ? false : true
        let columnDefs = [
            {
                headerName: 'Hold Id',
                validation: (value) => value && value.trim() !== '',
                field: 'hold_id',
                editable: false,
                width: 140,
            },
            {
                headerName: 'Hold Status',
                validation: (value) => value && value.trim() !== '',
                field: 'is_request_approved',
                editable: false,
                width: 120,
                cellClass: function (params) {
                    if (params.data && params.data.is_request_approved == 1) {
                        return "bgcolorsuccesgreen";
                    } else if (params.data && params.data.is_request_approved == 0) {
                        return "boxBgLighRed";
                    } else if (params.data && params.data.is_request_approved == 2) {
                        return "bgcolorsuccesgreenRelease";
                    } else {
                        return "boxbgblue5"
                    }
                },
                valueGetter: params => {
                    try {
                        let { is_request_approved } = params.data
                        let status = "Pending"
                        if (is_request_approved == 1) {
                            status = "Approved"
                        } else if (is_request_approved == 0) {
                            status = "Rejected"
                        } else if (is_request_approved == 2) {
                            status = "Released"
                        }
                        return status
                    } catch (e) {
                        console.log("Error ", e)
                    }
                }
            },
            {
                headerName: 'Vin Number',
                validation: (value) => value && value.trim() !== '',
                field: 'vin_number',
                editable: false,
                width: 180,
            },
            {
                headerName: 'Invoice Number',
                validation: (value) => value && value.trim() !== '',
                field: 'invoice_number',
                editable: false,
                width: 180,
            },
            {
                headerName: 'Approver Email',
                validation: (value) => value && value.trim() !== '',
                field: 'approver_email',
                editable: false,
                width: 180,
                suppressColumnsToolPanel: hideForYard,
                hide: hideForYard,
            },
            {
                headerName: 'Requester L4 Email',
                validation: (value) => value && value.trim() !== '',
                field: 'requester_l4_mail',
                editable: false,
                width: 180,
                hide: !hideForYard,
                suppressColumnsToolPanel: !hideForYard,
            },
            {
                headerName: 'Logistics Controller Email',
                validation: (value) => value && value.trim() !== '',
                field: 'controller_mail',
                editable: false,
                width: 180,
                hide: !hideForYard,
                suppressColumnsToolPanel: !hideForYard,
            },
            {
                headerName: 'Expected Yard Cost',
                validation: (value) => value && value.trim() !== '',
                field: 'yard_cost',
                editable: false,
                width: 180,
                hide: !hideForYard,
                suppressColumnsToolPanel: !hideForYard,
            },
            {
                headerName: 'Requester Email',
                validation: (value) => value && value.trim() !== '',
                field: 'requester_email',
                editable: false,
                width: 180,
            },
            {
                headerName: 'Sales Planner Email',
                validation: (value) => value && value.trim() !== '',
                field: 'sales_planner_email',
                editable: false,
                width: 180,
            },
            {
                headerName: 'Obl Email',
                validation: (value) => value && value.trim() !== '',
                field: 'obl_email',
                editable: false,
                width: 180,
            },
            {
                headerName: 'No of Vehicles',
                validation: (value) => value && value.trim() !== '',
                field: 'no_of_vehicles',
                editable: false,
                width: 120,
            },
            {
                headerName: 'Reason',
                validation: (value) => value && value.trim() !== '',
                field: 'reason',
                editable: false,
                width: 160,
            },
            {
                headerName: 'Requester L4 Status',
                validation: (value) => value && value.trim() !== '',
                field: 'is_l4_approved',
                editable: false,
                width: 180,
                hide: !hideForYard,
                suppressColumnsToolPanel: !hideForYard,
                cellClass: function (params) {
                    if (params.data && params.data.is_l4_approved == 1) {
                        return "bgcolorsuccesgreen";
                    } else if (params.data && params.data.is_l4_approved == 0) {
                        return "boxBgLighRed";
                    } else {
                        return "boxbgblue5"
                    }
                },
                valueGetter: params => {
                    try {
                        let { is_l4_approved } = params.data
                        let status = 'Pending'
                        if (is_l4_approved == 1) {
                            status = "Approved"
                        } else if (is_l4_approved == 0) {
                            status = "Rejected"
                        }
                        return status
                    } catch (e) {
                        console.log("Error :", e)
                    }
                }
            },
            {
                headerName: 'Logistics Controller Status',
                validation: (value) => value && value.trim() !== '',
                field: 'controller_satus',
                editable: false,
                width: 180,
                hide: !hideForYard,
                suppressColumnsToolPanel: !hideForYard,
                cellClass: function (params) {
                    if (params.data && params.data.controller_satus == 1) {
                        return "bgcolorsuccesgreen";
                    } else if (params.data && params.data.controller_satus == 0) {
                        return "boxBgLighRed";
                    } else if (params.data && params.data.controller_satus == 2) {
                        return "boxbgblue5"
                    }
                },
                valueGetter: params => {
                    try {
                        let { controller_satus } = params.data
                        let status = ''
                        if (controller_satus == 1) {
                            status = "Approved"
                        } else if (controller_satus == 0) {
                            status = "Rejected"
                        } else if (controller_satus == 2) {
                            status = 'Pending'
                        }
                        return status
                    } catch (e) {
                        console.log("Error :", e)
                    }
                }
            },
            {
                headerName: 'No of Times Extended',
                validation: (value) => value && value.trim() !== '',
                field: 'no_of_extensions',
                editable: false,
                width: 120,
                valueGetter: params => {
                    try {
                        let { no_of_extensions = 0 } = params.data
                        return no_of_extensions
                    } catch (e) {
                        console.log("Error at:", e)
                    }
                }
            },
            {
                headerName: 'Extension Status',
                validation: (value) => value && value.trim() !== '',
                field: 'extension_status',
                editable: false,
                width: 120,
                cellClass: function (params) {
                    if (params.data && params.data.extension_status == 1) {
                        return "bgcolorsuccesgreen";
                    } else if (params.data && params.data.extension_status == 0) {
                        return "boxBgLighRed";
                    } else if (params.data && params.data.extension_status == 2) {
                        return "boxbgblue5"
                    }
                },
                valueGetter: params => {
                    try {
                        let { extension_status } = params.data
                        let status = "N/A"
                        if (extension_status == 1) {
                            status = "Approved"
                        } else if (extension_status == 0) {
                            status = "Rejected"
                        } else if (extension_status == 2) {
                            status = "Pending"
                        }
                        return status
                    } catch (e) {
                        console.log("Error ", e)
                    }
                }
            },
            {
                headerName: 'Extension Remarks',
                validation: (value) => value && value.trim() !== '',
                field: 'extension_remarks',
                editable: false,
                width: 120,
                valueGetter: params => {
                    try {
                        let { extension_remarks = "" } = params.data
                        return extension_remarks
                    } catch (e) {
                        console.log("Error at:", e)
                    }
                }
            },
            {
                headerName: 'Unhold Remarks',
                validation: (value) => value && value.trim() !== '',
                field: 'unhold_remarks',
                editable: false,
                width: 120,
                valueGetter: params => {
                    try {
                        let { unhold_remarks = "" } = params.data
                        return unhold_remarks
                    } catch (e) {
                        console.log("Error at:", e)
                    }
                }
            },
            {
                headerName: 'Start Date',
                validation: (value) => value && value.trim() !== '',
                field: 'start_date',
                editable: false,
                width: 160,
                valueGetter: params => {
                    try {
                        let { start_date } = params.data
                        let result = getHyphenDDMMMYYYYHHMM(start_date)
                        return result || ""
                    } catch (e) {
                        console.log("Error ", e)
                    }
                }
            },
            {
                headerName: 'Intial Clearance Date',
                validation: (value) => value && value.trim() !== '',
                field: 'clearance_date',
                editable: false,
                width: 160,
                valueGetter: params => {
                    try {
                        let { clearance_date } = params.data
                        let result = getHyphenDDMMMYYYYHHMM(clearance_date)
                        return result || ""
                    } catch (e) {
                        console.log("Error ", e)
                    }
                }
            },
            {
                headerName: 'Current Clearance Date',
                validation: (value) => value && value.trim() !== '',
                field: 'cr_clearance_date',
                editable: false,
                width: 160,
                valueGetter: params => {
                    try {
                        let { clearance_date, cr_clearance_date } = params.data
                        let result
                        if (cr_clearance_date) {

                            result = getHyphenDDMMMYYYYHHMM(cr_clearance_date)
                        } else {
                            result = getHyphenDDMMMYYYYHHMM(clearance_date)
                        }
                        return result || ""
                    } catch (e) {
                        console.log("Error ", e)
                    }
                }
            },
            {
                headerName: 'Expected Clearance Date',
                validation: (value) => value && value.trim() !== '',
                field: 'exp_clarance_date',
                editable: false,
                width: 160,
                valueGetter: params => {
                    try {
                        let { clearance_date, cr_clearance_date, exp_clarance_date } = params.data
                        let result
                        if (exp_clarance_date) {
                            result = getHyphenDDMMMYYYYHHMM(exp_clarance_date)
                        } else if (cr_clearance_date) {
                            result = getHyphenDDMMMYYYYHHMM(cr_clearance_date)
                        } else {
                            result = getHyphenDDMMMYYYYHHMM(clearance_date)
                        }
                        return result || ""
                    } catch (e) {
                        console.log("Error ", e)
                    }
                }
            },
            {
                headerName: 'Actual Clearance Date',
                validation: (value) => value && value.trim() !== '',
                field: 'clearance_date',
                editable: false,
                width: 160,
                valueGetter: params => {
                    try {
                        let { clearance_date, cr_clearance_date, exp_clarance_date, ac_clearance_date, is_request_approved } = params.data
                        let result
                        if (ac_clearance_date) {
                            result = getHyphenDDMMMYYYYHHMM(ac_clearance_date)
                        } else if (cr_clearance_date) {
                            result = getHyphenDDMMMYYYYHHMM(cr_clearance_date)
                        } else {
                            result = getHyphenDDMMMYYYYHHMM(clearance_date)
                        }
                        return is_request_approved == 2 ? result || "N/A" : "N/A"
                        // return result || "N/A"
                    } catch (e) {
                        console.log("Error ", e)
                    }
                }
            },
        ]
        return (
            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                />
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="d-flex justify-content-between"
                            style={{ width: "100%" }}
                        >
                            <h5>
                                <i className="icofont icofont-vehicle-delivery-van cus-i"> </i>
                                <span> Hold Report</span>
                                <span style={{ lineHeight: "0px", letterSpacing: "0px", fontSize: "14px", fontWeight: "900" }}>
                                    <span> (Hold Id : {this.state.hold_id}) </span>
                                </span>
                            </h5>
                            <p
                                onClick={this.onBackToHoldReport}
                                style={{
                                    fontSize: "18px", marginRight: "4px", color: "#000",
                                    textDecoration: "underline",
                                    border: "0px",
                                    background: "transparent",
                                    cursor: "pointer",
                                    fontStyle: 'oblique'
                                }}
                                title="Back To Hold Report"
                            >
                                &#8592; Back
                            </p>
                        </div>
                        <div className="card">
                            <div className="card-body" style={{ padding: "20px", minHeight: "60vh" }}>
                                <div className="card-body" style={{ padding: '10px', paddingRight: '0px', paddingTop: '0px' }}>
                                    <div className="row" style={{ textAlign: 'right' }}>
                                        <div className="col-sm-12">
                                            <button className="float-right custom-btn white btn-danger" onClick={this.onClickSaveGridState.bind(this)}>
                                                <i className="icofont icofont-save"></i> Save Grid
                                            </button>
                                            <button className="float-right custom-btn white btn-info" style={{ marginRight: "10px" }} onClick={this.resetState.bind(this)}>
                                                <i className="icofont icofont-refresh"></i> Reset Grid
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div id="myGrid" style={{ height: "480px", width: "100%" }} className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnDefs} // Assuming this is a state variable for columns
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={rowData}
                                        sideBar={this.state.sideBar}
                                        enableCharts={true}
                                        enableRangeSelection={true}
                                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady}
                                        pagination={true}
                                        frameworkComponents={this.state.frameworkComponents}
                                        stopEditingWhenGridLosesFocus={true}
                                        enableCellChangeFlash={false}
                                        rowClassRules={this.state.rowClassRules}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        masterDetail={true}
                                        rowHeight={this.state.rowHeight}
                                        rowSelection={this.state.rowSelection}
                                        suppressRowClickSelection={true}
                                        overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                                        excelStyles={this.state.excelStyles}
                                        editable={false}  // Enable editing for all cells
                                        onSelectionChanged={this.onSelectionChanged}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                </div>
            </div>
        );
    }
}
