import React, { Component } from 'react';
import ReactDOM from 'react-dom';


import _, { parseInt } from "lodash";
import Modal from 'react-responsive-modal';
import SweetAlert from 'react-bootstrap-sweetalert';

import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import Select from 'react-select';
// import {
//   Line,
//   Doughnut,
//   Bar,
//   Scatter,
//   Pie,
//   Radar
// } from 'react-chartjs-2';

// import { Chart } from "react-google-charts";
import PieChart from "./pieChart";
import ColumnGroupChart from "./columnGroupChart";
import StackGroupChart from "./stackgroupChart";
import BubbleChart from "./bubbleChart";
import { getHyphenDDMMMYYYYHHMM } from '../common/utils';
import MarkerClusterer from "@google/markerclusterer";
import ConsolidateReport from './default/conslidatereportComponent';
var redirectURL = require('../redirectURL');
var moment = require('moment');

var infoBox = require('../common/google-infowindow');
var oms = require(`npm-overlapping-marker-spiderfier/lib/oms.min`);

const decipher = salt => {
	const textToChars = text => text.split('').map(c => c.charCodeAt(0));
	const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
	return encoded => encoded.match(/.{1,2}/g)
		.map(hex => parseInt(hex, 16))
		.map(applySaltToChar)
		.map(charCode => String.fromCharCode(charCode))
		.join('');
}
var decode = decipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

const cipher = (salt) => {
  const textToChars = text => text.split('').map(c => c.charCodeAt(0));
  const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
  const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
  try {
    return text => text.split('')
      .map(textToChars)
      .map(applySaltToChar)
      .map(byteHex)
      .join('');
  }
  catch (e) {

  }

}

var encode = cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");


var marker;
var markers = [];
var circle;
var selectlat;
var selectlng
var allcords=[];
var map;
var rad = function(x) {
	return x * Math.PI / 180;
  };
var rlatitude;
var rlongitude;

var fencingLength=0;
var geoFencingArea;


export default class Dashboard extends Component {
  	  constructor(props) {
	    super(props);
	    this.state = {
            rowData:[],
            show1: false,
            basicTitle1:'',
            basicType1:"default",
            show: false,
            basicTitle:'',
            basicType:"default",
            loadshow:"show-n",
            from_date:"",
            to_date:"",
            consigner:{value:"ALL", label:"All"},
            date_type:{value:"ALL", label:"All"},
            flTransporter:{value:"ALL", label:"All"},
            flZone:{value:"ALL", label:"All"},
            flState:{value:"ALL", label:"All"},
            flCity:{value:"ALL", label:"All"},
            flDealer:{value:"ALL", label:"All"},
            filterCities: [],
            filterStates: [],
            filterClusters: [],
            filterDealers:[],
            filterZones: [],
            pagetitle: 'Summary Dashboard',
            plants:[],
            flStateArray:[],
            flCityArray:[],
            defaultsdate:'',
            defaultedate:'',
            pieChartData:[],
            bubbleChartData:[],
            nightdriving:[],
            overspeed:[],
            ontime:[],
            delay:[],
            accident:[],
            pod_without:[],
            pod_with:[],
            map_details:[],
            zoneChart:[],
            zoneCats:[],
            stateChart:[],
            cityChart:[],
            dealerChart:[],
            lspChart:[],
            cState:{value:"",label:"All"},
            cCity:{value:"",label:"All"},
            cDealer:{value:"",label:"All"},
            cType:{value:"",label:"All"},
            cLsp:{value:"",label:"All"},
            cLspType:{value:"",label:"All"},
            chartCities: [],
            chartStates: [],
            chartClusters: [],
            chartDealers:[],
            chartLsps:[],
            statedrilldown:[],
            allstateChart:[],
            overall_trip_details:[],
            stateslineChart:[]
	    };
      this.loadDashboardData = this.loadDashboardData.bind(this);
	   
	  }

	
    componentDidMount(){
      var edate = moment.parseZone().format('YYYY-MM-DD');
      var sdate = moment.parseZone().format('YYYY-MM-DD');
			// var sdate = moment.parseZone().subtract(1, 'month').format('YYYY-MM-DD');
			// var sdate = "2023-08-01";

		  this.setState({
            loadshow:"show-n",
            from_date:sdate,
            to_date:edate,
            defaultsdate:sdate,
            defaultedate:edate
        },()=> {
          this.getData();
        });
        this.renderMap();
        
    }
  // 3007 bugzilla: made changes for reset filter functionality
  loadDashboardData(e)
    {
      // this.setState({
      //   loadshow:"show-m"
      // })
      var params;
      if(e){
        params = {

        }
      }else{
        params = {
          from_date:this.state.from_date,
          to_date:this.state.to_date,
          date_type:this.state.date_type.value,
          transporter_code:this.state.flTransporter.value,
          zone:this.state.flZone.value,
          state:this.state.flState.value,
          city:this.state.flCity.value,
          consignee_code:this.state.flDealer.value,
          consigner_code:this.state.consigner.value
        }
      }
      
      // console.log("params before loading data", params)
      redirectURL.post("/dashboard/dashboarddata",params)
      .then((response) => {
        //  console.log("resp after loading data",response.data);
        try {
          
          var total_transportation_cost = [];
          var total_driver_cost = [];
          var total_diesel_cost = [];
          var total_road_cost = [];
          var total_toll_cost = [];
          var overall_trips_counters_count = JSON.parse(response.data.overall_trips_counters_count);
          var overall_trip_details = JSON.parse(response.data.overall_trip_details);
          
          var map_details = JSON.parse(response.data.map_details);
          // console.log(map_details,"map_details")
          //  console.log("overall_trip_details ", overall_trip_details)
          
          var stateChart=[];
          var cityChart=[];
          var dealerChart=[];
          var lspChart=[];
          
          var modelTypeZones  = []
          var modelTypeStates = []
          var modelTypeCity = []
          var dealerChartData = []
          var lspChartData = []
          var pertripseries = [] 
          
          var statedrilldowncities = []
            
          var zoneCats = []
          var zoneCategory = [];
          if(overall_trip_details.length > 0)
          {
            try {
              var strucks = [];
              var dtrucks = [];
              var ltrucks = [];
              var ztrucks = [];
              var ztruckslis = [];
              // var modeltypes = groupBy(overall_trip_details, rdata => rdata.model);
              // modeltypes.forEach(async (mtypes,mKey) => {
                // if(mKey !== "" && mKey !== undefined && mKey !== null)
                // {
                  
                  // Zone Wise Start
                  var zoneValues = [];
                  var zoneslist = groupBy(overall_trip_details, rdata => rdata.zone);
                  //  console.log("zoneslist ",zoneslist)
                  zoneslist.forEach(async (allzones,zonekey) => {
                    if(zonekey !== "" && zonekey !== "Central")
                    {
                      zoneCategory.push({zone_name:zonekey});
                      var total_transportation_cost_matrix = 0;
                      var diesel_cost_matrix = 0;
                      var driver_cost_matrix = 0;
                      var road_cost_matrix = 0;
                      var toll_cost_matrix = 0;
                      var zondeata = []
                      if(allzones.length > 0)
                      {
                        allzones.map((ti) => {
                          total_transportation_cost_matrix = (![NaN , null , undefined , "undefined" , ""].includes(ti.total_transportation_cost_matrix) ? parseFloat(ti.total_transportation_cost_matrix) : 0)+total_transportation_cost_matrix
                          // console.log("251zone",total_transportation_cost_matrix )
                          if(ti.diesel_cost_matrix !== "" && ti.diesel_cost_matrix !== null && ti.diesel_cost_matrix !== undefined)
                          {
                            diesel_cost_matrix = parseFloat(ti.diesel_cost_matrix)+parseFloat(diesel_cost_matrix);
                          }
                          if(ti.driver_cost_matrix !== "" && ti.driver_cost_matrix !== null && ti.driver_cost_matrix !== undefined)
                          {
                            driver_cost_matrix = parseFloat(ti.driver_cost_matrix)+parseFloat(driver_cost_matrix);
                          }
                          if(ti.road_cost_matrix !== "" && ti.road_cost_matrix !== null && ti.road_cost_matrix !== undefined)
                          {
                            road_cost_matrix = parseFloat(ti.road_cost_matrix)+parseFloat(road_cost_matrix);
                          }
                          if(ti.toll_cost_matrix !== "" && ti.toll_cost_matrix !== null && ti.toll_cost_matrix !== undefined)
                          {
                            toll_cost_matrix = parseFloat(ti.toll_cost_matrix)+parseFloat(toll_cost_matrix);
                          }
                          // driver_cost_matrix = parseFloat(ti.driver_cost_matrix)+parseFloat(driver_cost_matrix);
                          // road_cost_matrix = parseFloat(ti.road_cost_matrix)+parseFloat(road_cost_matrix);
                          // toll_cost_matrix = parseFloat(ti.toll_cost_matrix)+parseFloat(toll_cost_matrix);
                        })
                      }
                      // await setTimeout(()=>{
                        var ord =0;
                        if(zonekey === "East" || zonekey === "east" || zonekey === "EAST")
                        {
                          ord = 1;
                        }
                        if(zonekey === "West" || zonekey === "west" || zonekey === "WEST")
                        {
                          ord = 2;
                        }
                        if(zonekey === "North" || zonekey === "north" || zonekey === "NORTH")
                        {
                          ord = 3;
                        }
                        if(zonekey === "South" || zonekey === "south" || zonekey === "SOUTH")
                        {
                          ord = 4;
                        }
                        if(zonekey === "Central" || zonekey === "central" || zonekey === "CENTRAL")
                        {
                          ord = 5;
                        }
                        var pertripcost =parseInt(parseFloat(total_transportation_cost_matrix)/parseInt(allzones.length));
                        pertripseries.push({
                          name:zonekey,
                          y:pertripcost,
                          lblTxt:"INR "+pertripcost,
                          orderpos:ord
                        })
                        //  console.log("pertripseries",pertripseries)
                        total_transportation_cost.push({
                          name:zonekey,
                          y:total_transportation_cost_matrix,
                          lblTxt:"INR "+total_transportation_cost_matrix,
                          orderpos:ord
                          
                        })
                        // console.log("total_transportation_cost",total_transportation_cost)
                        total_diesel_cost.push({
                          name:zonekey,
                          y:diesel_cost_matrix,
                          lblTxt:"INR "+diesel_cost_matrix,
                          orderpos:ord
                          
                        })
                        total_driver_cost.push({
                          name:zonekey,
                          y:driver_cost_matrix,
                          lblTxt:"INR "+driver_cost_matrix,
                          orderpos:ord
                        })
                        total_road_cost.push({
                          name:zonekey,
                          y:road_cost_matrix,
                          lblTxt:"INR "+road_cost_matrix,
                          orderpos:ord
                        })
                        total_toll_cost.push({
                          name:zonekey,
                          y:toll_cost_matrix,
                          lblTxt:"INR "+toll_cost_matrix,
                          orderpos:ord
                        })
                        ztruckslis.push({
                          name:zonekey,
                          trucks:allzones.length,
                          orderpos:ord,
                        })
                      // },300);
                      // modelTypeZones.push({
                      //   name:zonekey,
                      //   data:zondeata,
                      //   dataLabels:{format:'{point.lblTxt}',enabled:true},
                      //   type:"column",
                      //   tooltip: {
                      //     pointFormat: '<b>{series.name}:Total Cost: INR {point.y}</b>'
                      //   }
                      // })
                    }
                    
                  })
                  modelTypeZones.push({
                    name:"Toll Cost",
                    data:total_toll_cost,
                    dataLabels:{format:'{point.lblTxt}',enabled:true},
                    type:"column",
                    tooltip: {
                      pointFormat: '<b>{series.name}: INR {point.y}</b>'
                    },
                    yAxis:0,
                    color:{
                      linearGradient: {
                        x1: 1,
                        x2: 0,
                        y1: 1,
                        y2: 0
                      },
                      stops: [
                        [0, "#ff0000"],
                        [1, "#B60D05"]
                      ]
                      }
                  },{
                    name:"Road Cost",
                    data:total_road_cost,
                    dataLabels:{format:'{point.lblTxt}',enabled:true},
                    type:"column",
                    tooltip: {
                      pointFormat: '<b>{series.name}: INR {point.y}</b>'
                    },
                    yAxis:0,
                    color:{
                      linearGradient: {
                        x1: 1,
                        x2: 0,
                        y1: 1,
                        y2: 0
                      },
                      stops: [
                        [0, "#A2A704"],
                        [1, "#DDE308"]
                      ]
                    }
                  },{
                    name:"Diesel Cost",
                    data:total_diesel_cost,
                    dataLabels:{format:'{point.lblTxt}',enabled:true},
                    type:"column",
                    tooltip: {
                      pointFormat: '<b>{series.name}: INR {point.y}</b>'
                    },
                    yAxis:0,
                    color:{
                      linearGradient: {
                        x1: 1,
                        x2: 0,
                        y1: 1,
                        y2: 0
                      },
                      stops: [
                        [0, "#005C00"],
                        [1, "#0AAB0A"]
                      ]
                      }
                  },{
                    name:"Driver Cost",
                    data:total_driver_cost,
                    dataLabels:{format:'{point.lblTxt}',enabled:true},
                    type:"column",
                    tooltip: {
                      pointFormat: '<b>{series.name}: INR {point.y}</b>'
                    },
                    yAxis:0,
                    color:{
                      linearGradient: {
                        x1: 1,
                        x2: 0,
                        y1: 1,
                        y2: 0
                      },
                      stops: [
                        [0, "#022372"],
                        [1, "#0D4BDE"]
                      ]
                    }
                  })
                  // console.log("modelTypeZones ", modelTypeZones)
                  
                  // zoneValues = zoneValues.sort(GetSortOrder("orderpos"));
                  // ztrucks = ztruckslis.sort(GetSortOrder("orderpos"));
                  ztrucks = ztruckslis;
                  // modelTypeZones.push({
                  //   name:mKey,
                  //   data:zoneValues,
                  //   dataLabels:{format:'{point.lblTxt}',enabled:true},
                  //   type:"areaspline",
                  //   tooltip: {
                  //     pointFormat: '<b>{series.name}:Total Cost: INR {point.y}</b>'
                  //   }
                  // })

                    // State Wise Start
                    var stateValues = [];
                    var statelist = groupBy(overall_trip_details, rdata => rdata.consignee_state);
                    // console.log("statelist",statelist)
                    var stotal_transportation_cost=[];
                    var stotal_driver_cost = [];
                    var stotal_diesel_cost = [];
                    var stotal_road_cost = [];
                    var stotal_toll_cost = [];
                    var pertripstateseries = [];
                    
                    //  console.log("statelist ",statelist)
                    statelist.forEach(async (allstate,statekey) => {
                      if(statekey !== "")
                      {
                        var diesel_cost_matrix = 0;
                        var driver_cost_matrix = 0;
                        var road_cost_matrix = 0;
                        var toll_cost_matrix = 0;
                        // stateCategory.push({state_name:statekey});
                        var total_transportation_cost_matrix = 0;
                        if(allstate.length > 0)
                        {
                          allstate.map((ti) => {
                            total_transportation_cost_matrix = (![NaN , null , undefined , "undefined" , ""].includes(ti.total_transportation_cost_matrix) ? parseFloat(ti.total_transportation_cost_matrix) : 0)+total_transportation_cost_matrix
                            // console.log("total_transportation_cost_matrix",total_transportation_cost_matrix)
                            if(ti.diesel_cost_matrix !== "" && ti.diesel_cost_matrix !== null && ti.diesel_cost_matrix !== undefined)
                            {
                              diesel_cost_matrix = parseFloat(ti.diesel_cost_matrix)+parseFloat(diesel_cost_matrix);
                            }
                            if(ti.driver_cost_matrix !== "" && ti.driver_cost_matrix !== null && ti.driver_cost_matrix !== undefined)
                            {
                              driver_cost_matrix = parseFloat(ti.driver_cost_matrix)+parseFloat(driver_cost_matrix);
                            }
                            if(ti.road_cost_matrix !== "" && ti.road_cost_matrix !== null && ti.road_cost_matrix !== undefined)
                            {
                              road_cost_matrix = parseFloat(ti.road_cost_matrix)+parseFloat(road_cost_matrix);
                            }
                            if(ti.toll_cost_matrix !== "" && ti.toll_cost_matrix !== null && ti.toll_cost_matrix !== undefined)
                            {
                              toll_cost_matrix = parseFloat(ti.toll_cost_matrix)+parseFloat(toll_cost_matrix);
                            }
                          })
                        }
                        var pertripcoststate =parseInt(parseFloat(total_transportation_cost_matrix)/parseInt(allstate.length));
                        // console.log("pertripcoststate",parseInt(allstate.length))
                        pertripstateseries.push({
                          name:statekey,
                          y:pertripcoststate,
                          lblTxt:"INT "+pertripcoststate,
                          
                        })
                        //  console.log("pertripstateseries",pertripstateseries)
                        stotal_transportation_cost.push({
                          name:statekey,
                          y:total_transportation_cost_matrix,
                          lblTxt:"INR "+total_transportation_cost_matrix,
                        })
                        // console.log("stotal_transportation_cost",stotal_transportation_cost)
                        stotal_diesel_cost.push({
                          name:statekey,
                          y:diesel_cost_matrix,
                          lblTxt:"INR "+diesel_cost_matrix,
                          
                        })
                        stotal_driver_cost.push({
                          name:statekey,
                          y:driver_cost_matrix,
                          lblTxt:"INR "+driver_cost_matrix,
                        })
                        stotal_road_cost.push({
                          name:statekey,
                          y:road_cost_matrix,
                          lblTxt:"INR "+road_cost_matrix,
                        })
                        stotal_toll_cost.push({
                          name:statekey,
                          y:toll_cost_matrix,
                          lblTxt:"INR "+toll_cost_matrix,
                        })
                        stateValues.push({
                            name:statekey,
                            y:total_transportation_cost_matrix,
                            lblTxt:"INR "+total_transportation_cost_matrix,
                            drilldown:statekey
                          })
                          // console.log("stateValues",stateValues)
                          strucks.push({
                            name:statekey,
                            trucks:allstate.length
                          })
                        // },300);
                      }
                     
                    })
                    modelTypeStates.push({
                      name:"Toll Cost",
                      data:stotal_toll_cost,
                      dataLabels:{format:'{point.lblTxt}',enabled:true},
                      type:"column",
                      tooltip: {
                        pointFormat: '<b>{series.name}: INR {point.y}</b>'
                      },
                      yAxis:0,
                      color:{
                        linearGradient: {
                          x1: 1,
                          x2: 0,
                          y1: 1,
                          y2: 0
                        },
                        stops: [
                          [0, "#ff0000"],
                          [1, "#B60D05"]
                        ]
                        }
                    },{
                      name:"Road Cost",
                      data:stotal_road_cost,
                      dataLabels:{format:'{point.lblTxt}',enabled:true},
                      type:"column",
                      tooltip: {
                        pointFormat: '<b>{series.name}: INR {point.y}</b>'
                      },
                      yAxis:0,
                      color:{
                        linearGradient: {
                          x1: 1,
                          x2: 0,
                          y1: 1,
                          y2: 0
                        },
                        stops: [
                          [0, "#A2A704"],
                          [1, "#DDE308"]
                        ]
                      }
                    },{
                      name:"Diesel Cost",
                      data:stotal_diesel_cost,
                      dataLabels:{format:'{point.lblTxt}',enabled:true},
                      type:"column",
                      tooltip: {
                        pointFormat: '<b>{series.name}: INR {point.y}</b>'
                      },
                      yAxis:0,
                      color:{
                        linearGradient: {
                          x1: 1,
                          x2: 0,
                          y1: 1,
                          y2: 0
                        },
                        stops: [
                          [0, "#005C00"],
                          [1, "#0AAB0A"]
                        ]
                        }
                    },{
                      name:"Driver Cost",
                      data:stotal_driver_cost,
                      dataLabels:{format:'{point.lblTxt}',enabled:true},
                      type:"column",
                      tooltip: {
                        pointFormat: '<b>{series.name}: INR {point.y}</b>'
                      },
                      yAxis:0,
                      color:{
                        linearGradient: {
                          x1: 1,
                          x2: 0,
                          y1: 1,
                          y2: 0
                        },
                        stops: [
                          [0, "#022372"],
                          [1, "#0D4BDE"]
                        ]
                        }
                    })
                  //   // stateValues = stateValues.sort(GetSortOrder("orderpos"));
                  //   modelTypeStates.push({
                  //     name:mKey,
                  //     data:stateValues,
                  //     dataLabels:{format:'{point.lblTxt}',enabled:true},
                  //     type:"column",
                  //     tooltip: {
                  //       pointFormat: '<b>{series.name}:Total Cost: INR {point.y}</b>'
                  //     }
                  //   })

                  // //State wise End
                  // // City Wise Start
                  // var cityValues = [];
                  // var citylist = groupBy(mtypes, rdata => rdata.consignee_city);
                  // citylist.forEach(async (allcity,citykey) => {
                  //   if(citykey !== "")
                  //   {
                  //     var total_transportation_cost_matrix = 0;
                  //     if(allcity.length > 0)
                  //     {
                  //       allcity.map((ti) => {
                  //         statedrilldowncities.push({
                  //           name:citykey,
                  //           y:parseFloat(ti.total_transportation_cost_matrix),
                  //           id:ti.consignee_state,
                  //           drilldown:citykey
                  //         })
                  //         total_transportation_cost_matrix = parseFloat(ti.total_transportation_cost_matrix)+parseFloat(total_transportation_cost_matrix);
                  //       })
                  //     }
                      
                  //     cityValues.push({
                  //         name:citykey,
                  //         y:total_transportation_cost_matrix,
                  //         lblTxt:"INR "+total_transportation_cost_matrix,
                  //       })
                  //     // },300);
                  //   }
                  // })
                  
                  // // stateValues = stateValues.sort(GetSortOrder("orderpos"));
                  // modelTypeCity.push({
                  //   name:mKey,
                  //   data:cityValues,
                  //   dataLabels:{format:'{point.lblTxt}',enabled:true}
                  // })
                  // // Citywise End

                  
                  // // Dealer Wise Start
                  // var dealerValues = [];
                  // var dealerlist = groupBy(mtypes, rdata => rdata.consignee_code);
                  // dealerlist.forEach(async (alldealer,dealerkey) => {
                  //   if(dealerkey !== "")
                  //   {
                  //     var total_transportation_cost_matrix = 0;
                  //     if(alldealer.length > 0)
                  //     {
                  //       alldealer.map((ti) => {
                  //         // console.log("ti ", ti)
                  //         statedrilldowncities.push({
                  //           name:ti.consignee_name+ " "+ti.consignee_code,
                  //           y:parseFloat(ti.total_transportation_cost_matrix),
                  //           id:ti.consignee_city
                  //         })
                  //         total_transportation_cost_matrix = parseFloat(ti.total_transportation_cost_matrix)+parseFloat(total_transportation_cost_matrix);
                  //       })
                  //     }
                      
                  //     dealerValues.push({
                  //         name:alldealer[0].consignee_name+" - "+alldealer[0].consignee_code,
                  //         y:total_transportation_cost_matrix,
                  //         lblTxt:"INR "+total_transportation_cost_matrix,
                  //       })
                  //     dtrucks.push({
                  //       name:alldealer[0].consignee_name+" - "+alldealer[0].consignee_code,
                  //       trucks:alldealer.length
                  //     })
                  //     // },300);
                  //   }
                  // })
                  
                  // // stateValues = stateValues.sort(GetSortOrder("orderpos"));
                  // dealerChartData.push({
                  //   name:mKey,
                  //   data:dealerValues,
                  //   dataLabels:{format:'{point.lblTxt}',enabled:true},
                  //   type:"column",
                  //   tooltip: {
                  //     pointFormat: '<b>{series.name}:Total Cost: INR {point.y}</b>'
                  //   }
                  // })
                  // // Dealerwise End
              // var modeltypes = groupBy(overall_trip_details, rdata => rdata.model);
              // modeltypes.forEach(async (mtypes,mKey) => {
              //   if(mKey !== "" && mKey !== undefined && mKey !== null)
              //   {
                  // LSP Wise Start
                  var lspValues = [];
                  var ltotal_driver_cost = [];
                  var ltotal_diesel_cost = [];
                  var ltotal_road_cost = [];
                  var ltotal_toll_cost = [];
                  var ltotal_transportation_cost = [];
                  var pertriptransseries  = [];
                  var lsplist = groupBy(overall_trip_details, rdata => rdata.transporter_name);
                  lsplist.forEach(async (alllsp,lspkey) => {
                    if(lspkey !== "")
                    {
                      var ldiesel_cost_matrix = 0;
                        var ldriver_cost_matrix = 0;
                        var lroad_cost_matrix = 0;
                        var ltoll_cost_matrix = 0;
                        // stateCategory.push({state_name:statekey});
                        var total_transportation_cost_matrix = 0;
                        if(alllsp.length > 0)
                        {
                          alllsp.map((ti) => {
                            total_transportation_cost_matrix = (![NaN , null , undefined , "undefined" , ""].includes(ti.total_transportation_cost_matrix) ? parseFloat(ti.total_transportation_cost_matrix) : 0)+total_transportation_cost_matrix
                            if(ti.diesel_cost_matrix !== "" && ti.diesel_cost_matrix !== null && ti.diesel_cost_matrix !== undefined)
                            {
                              ldiesel_cost_matrix = parseFloat(ti.diesel_cost_matrix)+parseFloat(ldiesel_cost_matrix);
                            }
                            if(ti.driver_cost_matrix !== "" && ti.driver_cost_matrix !== null && ti.driver_cost_matrix !== undefined)
                            {
                              ldriver_cost_matrix = parseFloat(ti.driver_cost_matrix)+parseFloat(ldriver_cost_matrix);
                            }
                            if(ti.road_cost_matrix !== "" && ti.road_cost_matrix !== null && ti.road_cost_matrix !== undefined)
                            {
                              lroad_cost_matrix = parseFloat(ti.road_cost_matrix)+parseFloat(lroad_cost_matrix);
                            }
                            if(ti.toll_cost_matrix !== "" && ti.toll_cost_matrix !== null && ti.toll_cost_matrix !== undefined)
                            {
                              ltoll_cost_matrix = parseFloat(ti.toll_cost_matrix)+parseFloat(ltoll_cost_matrix);
                            }
                          })
                        }
                        var pertripcosttrans = parseInt(parseFloat(total_transportation_cost_matrix)/parseInt(alllsp.length));
                        // console.log("pertripcosttrans",alllsp.length)
                        pertriptransseries.push({
                          name:lspkey,
                          y:pertripcosttrans,
                          lblTxt:"INT "+pertripcosttrans,
                          
                        })
                        ltotal_transportation_cost.push({
                          name:lspkey,
                          y:total_transportation_cost_matrix,
                          lblTxt:"INR "+total_transportation_cost_matrix,
                        })
                        ltotal_diesel_cost.push({
                          name:lspkey,
                          y:ldiesel_cost_matrix,
                          lblTxt:"INR "+ldiesel_cost_matrix,
                        })
                        ltotal_driver_cost.push({
                          name:lspkey,
                          y:ldriver_cost_matrix,
                          lblTxt:"INR "+ldriver_cost_matrix,
                        })
                        ltotal_road_cost.push({
                          name:lspkey,
                          y:lroad_cost_matrix,
                          lblTxt:"INR "+lroad_cost_matrix,
                        })
                        ltotal_toll_cost.push({
                          name:lspkey,
                          y:ltoll_cost_matrix,
                          lblTxt:"INR "+ltoll_cost_matrix,
                        })
                      lspValues.push({
                          name:lspkey,
                          y:total_transportation_cost_matrix,
                          lblTxt:"INR "+total_transportation_cost_matrix,
                        })
                        // console.log("lspValues",lspValues)
                        ltrucks.push({
                          name:lspkey,
                          trucks:alllsp.length
                        })
                      // },300);
                    }
                  })
                  
                  // stateValues = stateValues.sort(GetSortOrder("orderpos"));
                  lspChartData.push({
                    name:"Toll Cost",
                    data:ltotal_toll_cost,
                    dataLabels:{format:'{point.lblTxt}',enabled:true},
                    type:"column",
                    tooltip: {
                      pointFormat: '<b>{series.name}: INR {point.y}</b>'
                    },
                    yAxis:0,
                    color:{
                      linearGradient: {
                        x1: 1,
                        x2: 0,
                        y1: 1,
                        y2: 0
                      },
                      stops: [
                        [0, "#ff0000"],
                        [1, "#B60D05"]
                      ]
                      }
                  },{
                    name:"Road Cost",
                    data:ltotal_road_cost,
                    dataLabels:{format:'{point.lblTxt}',enabled:true},
                    type:"column",
                    tooltip: {
                      pointFormat: '<b>{series.name}: INR {point.y}</b>'
                    },
                    yAxis:0,
                    color:{
                      linearGradient: {
                        x1: 1,
                        x2: 0,
                        y1: 1,
                        y2: 0
                      },
                      stops: [
                        [0, "#A2A704"],
                        [1, "#DDE308"]
                      ]
                    }
                  },{
                    name:"Diesel Cost",
                    data:ltotal_diesel_cost,
                    dataLabels:{format:'{point.lblTxt}',enabled:true},
                    type:"column",
                    tooltip: {
                      pointFormat: '<b>{series.name}: INR {point.y}</b>'
                    },
                    yAxis:0,
                    color:{
                      linearGradient: {
                        x1: 1,
                        x2: 0,
                        y1: 1,
                        y2: 0
                      },
                      stops: [
                        [0, "#005C00"],
                        [1, "#0AAB0A"]
                      ]
                      }
                  },{
                    name:"Driver Cost",
                    data:ltotal_driver_cost,
                    dataLabels:{format:'{point.lblTxt}',enabled:true},
                    type:"column",
                    tooltip: {
                      pointFormat: '<b>{series.name}: INR {point.y}</b>'
                    },
                    yAxis:0,
                    color:{
                      linearGradient: {
                        x1: 1,
                        x2: 0,
                        y1: 1,
                        y2: 0
                      },
                      stops: [
                        [0, "#022372"],
                        [1, "#0D4BDE"]
                      ]
                    }
                  
                  })
                  // LSPwise End

              //   }
              // })
              // console.log("modelTypeZones ",modelTypeZones)
           
            } catch (error) {
              
            }
            
          }
          var newArray  = [];
          let totuniqueObject = {};
          var totobjTitle;
          // Loop for the array elements
          for (let i in zoneCategory) {

            // Extract the title
            totobjTitle = zoneCategory[i]['zone_name'];

            // Use the title as the index
            totuniqueObject[totobjTitle] = zoneCategory[i];
          }

          // Loop to push unique object into array
          for (var a in totuniqueObject) {
            newArray.push(totuniqueObject[a]);
          }
          if(newArray.length > 0)
          {
            newArray.map((z) => {
              zoneCats.push(z.zone_name);
            })
          }
          
          var pieChartData = []
          var bubbleChartData = []

          var nightdriving = []
          var overspeed = []
          var ontime = []
          var delay = []
          var pod_without = []
          var accident = []
            

          if(overall_trips_counters_count.length > 0)
          {
              var pieChartDataSeries = [{
                                    name: 'Intransit',
                                    y: overall_trips_counters_count[0].Total_Intransit,
                                    lblTxt: overall_trips_counters_count[0].Total_Intransit,
                                    sliced: true,
                                    selected: true,
                                    // color:"#ff0000"
                                    color:{
                                      radialGradient: {
                                          cx: 0.5,
                                          cy: 0.3,
                                          r: 0.7
                                      },
                                      stops: [
                                          [0, "#B60D05"],
                                          [1, "#ff0000"]
                                      ]
                                  }
                                }, {
                                  name: 'Delivered',
                                  y: overall_trips_counters_count[0].Total_Delivered,
                                  lblTxt: overall_trips_counters_count[0].Total_Delivered,
                                  // color:"#039C23"
                                  color:{
                                    radialGradient: {
                                        cx: 0.5,
                                        cy: 0.3,
                                        r: 0.7
                                    },
                                    stops: [
                                        [0, "#1E9102"],
                                        [1, "#039C23"]
                                    ]
                                }
                                }]
            pieChartData = [{
              name: 'Transit Status',
              colorByPoint: true,
              data: pieChartDataSeries
            }]
            var bData= [];
            overall_trips_counters_count.map((itm) =>{
              var ba = itm["Bus_inrange (40kmph)"];
              var bb = itm["Bus_critical (40-65kmph)"];
              var bc = itm["Bus_not_acceptable (>65kmph)"];
              var aa = itm["Truck_inrange (65kmph)"];
              var ab = itm["Truck_critical (65-85kmph)"];
              var ac = itm["Truck_not_acceptable (>85kmph)"];
              itm.overall_normal = aa+ba;
              itm.overall_optimal = ab+bb;
              itm.overall_not_accept = ac+bc;

              itm.bus_normal = ba;
              itm.bus_optimal = bb;
              itm.bus_not_accept = bc;

              itm.truck_normal = aa;
              itm.truck_optimal = ab;
              itm.truck_not_accept = ac;
              bData.push(itm)
            })
            // console.log("bData ",bData)
            bubbleChartData = [{
                    name:"Normal",
                    y:bData[0].overall_normal,
                    data:[
                      {
                        name:"Truck",
                        value:bData[0].truck_normal,
                        dataLabels:{enable:true}
                      },
                      {
                        name:"Bus",
                        value:bData[0].bus_normal,
                        dataLabels:{enable:true}
                      }
                    ]
                },{
                  name:"Optimal",
                  y:bData[0].overall_optimal,
                  data:[
                    {
                      name:"Truck",
                      value:bData[0].truck_optimal,
                      dataLabels:{enable:true}
                    },
                    {
                      name:"Bus",
                      value:bData[0].bus_optimal,
                      dataLabels:{enable:true}
                    }
                  ]
              },{
                name:"Not Acceptable",
                y:bData[0].overall_not_accept,
                data:
                [
                  {
                    name:"Truck",
                    value:bData[0].truck_not_accept
                  },
                  {
                    name:"Bus",
                    value:bData[0].bus_not_accept
                  }
                ]
            }]
            var nightdriving = [{count:overall_trips_counters_count[0].Night_Driving,percentage:overall_trips_counters_count[0].Night_Driving_Percent}];
            var overspeed = [{count:overall_trips_counters_count[0].Overspeed_count,percentage:overall_trips_counters_count[0].Overspeed_percentage}];
            var ontime = [{count:overall_trips_counters_count[0].Delivered_ontime_count,percentage:overall_trips_counters_count[0].Delivered_ontime_percentage}];
            // console.log("ontime",ontime)
            var delay = [{count:overall_trips_counters_count[0].Delivered_delay_count,percentage:overall_trips_counters_count[0].Delivered_delay_percentage}];
            var pod_without = [{count:overall_trips_counters_count[0].Without_POD_Defect,percentage:overall_trips_counters_count[0].Without_POD_Defect_Percent}];
            var accident = [{count:overall_trips_counters_count[0].No_of_accidents,percentage:overall_trips_counters_count[0].Accident_percent}]
          }
          // console.log("accident",accident)
          // console.log("delay",delay)
          // modelTypeZones = modelTypeZones.sort(GetSortOrder("name"));
          // console.log("modelTypeStates ", modelTypeStates)
          // console.log("statedrilldowncities ", statedrilldowncities)

          var stateline=[]
          if(strucks.length > 0)
          {
              var trucksl = groupBy(strucks, rdata => rdata.name);
              try {
                trucksl.forEach((im, k) => {
                  var tot = 0;
                  if(im.length > 0)
                  {
                    im.map((i) =>{
                      tot = parseInt(tot)+parseInt(i.trucks)
                    })
                  }
                  stateline.push({
                    name:k,
                    y:tot
                  })
                })
              } catch (error) {
                
              }
              
          }
          

          
          modelTypeStates.push({
            name:"Total Trucks",
            data:stateline,
            type:"spline",
            dataLabels:{enabled:true},
            color:"#000",
            marker: {
              lineWidth: 2,
              fillColor: 'black'
            },
            center: [100, 80],
            yAxis:1,
            tooltip: {
              pointFormat: '<b>{series.name}: {point.y}</b>'
            }
          })


          var dealerline=[]
          if(dtrucks.length > 0)
          {
              var dtrucksl = groupBy(dtrucks, rdata => rdata.name);
              try {
                dtrucksl.forEach((im, k) => {
                  var tot = 0;
                  if(im.length > 0)
                  {
                    im.map((i) =>{
                      tot = parseInt(tot)+parseInt(i.trucks)
                    })
                  }
                  dealerline.push({
                    name:k,
                    y:tot
                  })
                })
              } catch (error) {
                
              }
              
          }
          dealerChartData.push({
            name:"Total Trucks",
            data:dealerline,
            type:"spline",
            color:"#000",
            marker: {
              lineWidth: 2,
              fillColor: 'black'
            },
            center: [100, 80],
            tooltip: {
              pointFormat: '<b>{series.name}: {point.y}</b>'
            }
          })


          var lspline=[]
          if(ltrucks.length > 0)
          {
              var ltrucksl = groupBy(ltrucks, rdata => rdata.name);
              try {
                ltrucksl.forEach((im, k) => {
                  var tot = 0;
                  if(im.length > 0)
                  {
                    im.map((i) =>{
                      tot = parseInt(tot)+parseInt(i.trucks)
                    })
                  }
                  lspline.push({
                    name:k,
                    y:tot
                  })
                })
              } catch (error) {
                
              }
              
          }          
          lspChartData.push({
            name:"Total Trucks",
            data:lspline,
            type:"spline",
            color:"#000",
            yAxis:1,
            marker: {
              lineWidth: 2,
              fillColor: 'black'
            },
            center: [100, 80],
            tooltip: {
              pointFormat: '<b>{series.name}: {point.y}</b>'
            }
          })

          var znline=[]
          if(ztrucks.length > 0)
          {
              var ztrucksl = groupBy(ztrucks, rdata => rdata.name);
              try {
                ztrucksl.forEach((im, k) => {
                  var tot = 0;
                  if(im.length > 0)
                  {
                    im.map((i) =>{
                      tot = parseInt(tot)+parseInt(i.trucks)
                    })
                  }
                  znline.push({
                    name:k,
                    y:tot
                  })
                })
              } catch (error) {
                
              }
              
          }          
          modelTypeZones.push({
            name:"Total Trucks",
            data:znline,
            type:"spline",
            color:"#000",
            marker: {
              lineWidth: 2,
              fillColor: 'black'
            },
            // center: [100, 80],
            tooltip: {
              pointFormat: '<b>{series.name}: {point.y}</b>'
            },
            yAxis:1
          })

          modelTypeZones.push({
            name:"Per Trip Truck Cost",
            data:pertripseries,
            dataLabels:{enabled:true},
            type:"spline",
            color:"#D62862",
            marker: {
              lineWidth: 2,
              fillColor: '#02690B'
            },
            // center: [100, 80],
            tooltip: {
              pointFormat: '<b>{series.name}: {point.y}</b>'
            },
            yAxis:0
          })
          
          modelTypeStates.push({
            name:"Per Trip Truck Cost",
            data:pertripstateseries,
            type:"spline",
            color:"#D62862",
            dataLabels:{enabled:true},
            marker: {
              lineWidth: 2,
              fillColor: '#02690B'
            },
            // center: [100, 80],
            tooltip: {
              pointFormat: '<b>{series.name}: {point.y}</b>'
            },
            yAxis:0
          })
          lspChartData.push({
            name:"Per Trip Truck Cost",
            data:pertriptransseries,
            dataLabels:{enabled:true},
            type:"spline",
            color:"#D62862",
            marker: {
              lineWidth: 2,
              fillColor: '#02690B'
            },
            // center: [100, 80],
            tooltip: {
              pointFormat: '<b>{series.name}: {point.y}</b>'
            },
            yAxis:0
          })
          this.setState({
            pieChartData:pieChartData,
            bubbleChartData:bubbleChartData,
            nightdriving:nightdriving,
            accident:accident,
            overspeed:overspeed,
            ontime:ontime,
            delay:delay,
            pod_without:pod_without,
            map_details:map_details,
            zoneChart:modelTypeZones,
            zoneCats:zoneCats,
            loadshow:"show-n",
            overall_trip_details:overall_trip_details,
            stateChart:modelTypeStates,
            stateslineChart:stateline,
            allstateChart:modelTypeStates,
            statedrilldown: statedrilldowncities,
            cityChart:modelTypeCity,
            dealerChart:dealerChartData,
            lspChart:lspChartData
          },()=>{
            this.renderMap();
          })
          
        } catch (error) {
          
        }
        setTimeout(()=>{

          this.setState({
            loadshow:"show-n",
          })
        },3000)
        
      })
      
  }
  renderMap = () => {

      loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyD1AFWPoJqPaemwGO4A7P7Q6CsUJ1TLGJk&libraries=places,drawing&callback=initMap");
      window.initMap = this.initMap
    }
  
     initMap = () => {
      allcords=[];
      // console.log(this.state.map_details,"map render")
      this.state.map_details.map((marker) => {
        allcords.push(marker);
      });
  
      var contentdata = this.state.contentString;
      var lt=21.144644112601775;
      var ln=79.08860126768066;
      try{
        map = new window.google.maps.Map(document.getElementById('map_canvas'), {
          zoom: 5,
          center: new window.google.maps.LatLng(lt,ln),
          mapTypeId: window.google.maps.MapTypeId.ROADMAP,
          /*mapTypeControl: true,
          gestureHandling: 'greedy',
          mapTypeControlOptions: {
            style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
            position: window.google.maps.ControlPosition.TOP_RIGHT
            },
          disableDefaultUI: true,
          zoomControl: true*/
        });
      }
      catch(e){
        map = new window.google.maps.Map(document.getElementById('map_canvas'), {
          zoom: 5,
          center: new window.google.maps.LatLng(parseFloat(21.144644112601775),parseFloat(79.08860126768066)),
          mapTypeId: window.google.maps.MapTypeId.ROADMAP,
          /*mapTypeControl: true,
          gestureHandling: 'greedy',
          mapTypeControlOptions: {
            style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
            position: window.google.maps.ControlPosition.TOP_RIGHT
            },
          disableDefaultUI: true,
          zoomControl: true*/
        });
      }
  
      circle = new window.google.maps.Circle({
              map: map
      });
  
      marker = new window.google.maps.Marker({
        map: map,
        draggable: true,
        animation: window.google.maps.Animation.DROP,
        //position: {lat: 28.48, lng: 77.06}
      });
  
      marker.addListener('click', function(){
        marker.setAnimation(window.google.maps.Animation.BOUNCE)
      });
  
        window.google.maps.event.addListener(map, 'click', function(event) {
  
        //document.getElementById("latitude").value = event.latLng.lat();
        //document.getElementById("longitude").value = event.latLng.lng();
        marker.setPosition(event.latLng);
  
      });
      circle.bindTo('center', marker, 'position');
      var markerLatlng1;
      //console.log("Radius ", this.state.radius)
      // console.log(allcords);
      var markers = this.state.map_details.map((marker) => {
        // console.log("marker ",marker);
       
        var image =  require('../../assets/icons/truck_na_24.png');
        var contentarr = []
        var headerTitle = marker.truck_no;
        // contentarr.push({"key":"Truck No", "value":marker.truck_no});
        // contentarr.push({"key":"Transporter Name", "value":marker.transporter_name});
        //contentarr.push({"key":"Distance from geofence", "value":marker.distance});
        contentarr.push({"key":"GPS Provider", "value":marker.actual_lspuser});
        contentarr.push({"key":"Last Packet Time", "value":getHyphenDDMMMYYYYHHMM(marker.timestamp)});
        contentarr.push({"key":"Speed (km/h)", "value":Math.ceil(marker.speed)});
        // contentarr.push({"key":"Address", "value":marker.area+", "+marker.city+", "+marker.state});
        contentarr.push({"key":"Address", "value":marker.last_known_address});
  
        var contentString = infoBox(image, headerTitle, contentarr, '')
        var infowindow = new window.google.maps.InfoWindow({
          content: contentString
        });
  
        var markerLatlng = new window.google.maps.LatLng(parseFloat(marker.latitude), parseFloat(marker.longitude));
        var image =  require('../../assets/icons/truck_na_24.png');
        var mark = new window.google.maps.Marker({
          position: markerLatlng,
          map: map,
          title:"Truck No - "+marker.truck_no,
          icon: image
        });
        mark.addListener('click', function() {
          infowindow.open(map, mark);
          });
        return mark
              mark.setMap(map);
      });
      var eventtrigger = document.getElementsByClassName('truck');
      var markerCluster = new MarkerClusterer(map, markers,
              {imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'});
        // var searchBox = new window.google.maps.places.SearchBox(eventtrigger);
        //map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(eventtrigger);
      // if(this.state.plantLocations.length > 0){
      //   this.state.plantLocations.map(function(params){
      //     var plant = {
      //     url: require("../../assets/icons/"+params.icon), // url
      //     scaledSize: new window.google.maps.Size(32,32), // scaled size
      //     origin: new window.google.maps.Point(0,0), // origin
      //     anchor: new window.google.maps.Point(0, 0) // anchor
      //     };
      //     var coords = JSON.parse(params.coordinates);
      //     var plantmarker = new window.google.maps.Marker({
      //       position: new window.google.maps.LatLng(coords.lat,coords.lng),
      //       map: map,
      //       icon: plant,
      //       title : params.plant_name
      //     });
      //   })
      // }
    }
    toggleBounce() {
      if (marker.getAnimation() !== null) {
        marker.setAnimation(null);
      } else {
        marker.setAnimation(window.google.maps.Animation.BOUNCE);
      }
  
    }

	ClearNewFilter = () => {
		this.setState({
			loadshow: "show-m",
			overly: "show-m"
		})
		this.setState({
			flCity: "",
			flState: "",
			flCluster: "",
			flZone: "",
			flTransporter: "",
			flDealer:""
		});
		this.setState({
			rowData: this.state.beforeNewFilterRowData,
			allRowData: this.state.beforeNewFilterRowData,
			countersjson: this.state.beforeNewFiltercountersjson,
			loadshow: "show-n",
			overly: "show-n"
		});
		this.gridApi.refreshCells();
	}

	newFilter = (filterType, selOption) => {
		// console.log(filterType, selOption)
		this.setState({ [filterType]: selOption });
	}
  // 3007 bugzilla: made changes for reset filter functionality
	reloadPageData = (e) => {
    var edate = moment.parseZone().format('YYYY-MM-DD');
    var sdate = moment.parseZone().format('YYYY-MM-DD');
    
			
		// window.location.reload();
		this.setState({
			from_date: sdate,
			to_date: edate,
			from_date: sdate,
			to_date: edate,
			flDealer:{value:"",label:"All"},
			consigner: {value: "all", label: "All"},
			date_type:{value:"all", label:"All"},
      flTransporter:{value:"all", label:"All"},
			flZone:{value:"",label:"All"},
			flState:{value:"",label:"All"},
			flCity:{value:"",label:"All"},
      pieChartData:[],
      bubbleChartData:[],
      nightdriving:[],
      overspeed:[],
      ontime:[],
      delay:[],
      accident:[],
      pod_without:[],
      pod_with:[],
      map_details:[],
      zoneChart:[],
      zoneCats:[],
      stateChart:[],
      cityChart:[],
      dealerChart:[],
      lspChart:[]
		},()=>{
			
			this.loadDashboardData(e);
      // 3007 bugzilla: made changes for reset filter functionality
      this.renderMap()
      this.getData();
		})
	}

	
	reloadPageDataGloabl = () => {
		// window.location.reload();
		document.getElementById("inputTruckConsignment").value = "";
		var encode = cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
		this.setState({
			selectedFilter:[]
		},()=>{
			if(this.state.allBtn === "btn-info")
			{
				var urlpath = '/consignments/allsndconsignments';
				var dpt = 'SNDG';
				var consignData = {
					dept_code: encode(dpt),
					startDate: this.state.startDate,
					endDate: this.state.endDate,
					consigner: this.state.consigner,
					screen: "all",
					
				}
				if (this.state.flTransporter.value != "" && this.state.flTransporter.value != undefined && this.state.flTransporter.value != null) {
					consignData.transporter_code = this.state.flTransporter.value;
				}
			}
			
			if(this.state.intransitBtn === "btn-info")
			{
				var urlpath = '/consignments/sndconsignments';
				var dpt = 'SNDG';
				
				var consignData = {
					dept_code: encode(dpt),
					startDate: this.state.startDate,
					endDate: this.state.endDate,
					consigner: this.state.consigner
				}
				
				if (this.state.flTransporter.value != "" && this.state.flTransporter.value != undefined && this.state.flTransporter.value != null) {
					consignData.transporter_code = this.state.flTransporter.value;
				}
			}
			
			if(this.state.deliveredBtn === "btn-info")
			{
				var urlpath = '/consignments/deliveredsndconsignments';
				var dpt = 'SNDG';
				var consignData = {
					dept_code: encode(dpt),
					startDate: this.state.startDate,
					endDate: this.state.endDate,
					consigner: this.state.consigner
				}
				
				if (this.state.flTransporter.value != "" && this.state.flTransporter.value != undefined && this.state.flTransporter.value != null) {
					consignData.transporter_code = this.state.flTransporter.value;
				}
	
			}
			if(localStorage.getItem("user_type") !== "" && localStorage.getItem("user_type") !== undefined)
			{
				if(localStorage.getItem("user_type") === "ZONE_MANAGER")
				{
					consignData.zone = localStorage.getItem("zone");
				}
			
				if(localStorage.getItem("user_type") === "REGIONAL_MANAGER")
				{
					if(localStorage.getItem("region") !== "" && localStorage.getItem("region") !== undefined)
					{
						var rr = localStorage.getItem("region").split(",");
						if(rr.length > 0)
						{
							consignData.region_states = rr;
						}
					}
					
				}
				
				if(localStorage.getItem("areas") !== "" && localStorage.getItem("areas") !== undefined)
				{
					var rr = localStorage.getItem("areas").split(",");
					if(rr.length > 0)
					{
						consignData.areas = rr;
					}
				}
			}
			this.onLoadGetConsignmentsList(urlpath, consignData);
		})
	}

    getData(){
      var dpt = "SNDG";
      redirectURL.post("/consignments/getConsignmentsNewFilterKeys", { dept_code: encode("SNDG") })
        .then((resp) => {
          var dealers = resp.data.consigneeName;
          
          var filterDealers = [{value:"",label:"All"}];
          
          if (dealers.length > 0) {
            dealers.map(function (c) {
              if(localStorage.getItem("user_type") === "DEALER")
              {
                if(c.consignee_code == localStorage.getItem("customer_code"))
                {
                  filterDealers.push({
                    label: c.consignee_name+"-"+c.consignee_code,
                    value: c.consignee_code,
                  });
                }

              }
              else if(localStorage.getItem("user_type") === "PARENT_DEALER") {
                if(c.parent_dealer_code == localStorage.getItem("customer_code"))
                {
                  filterDealers.push({
                    label: c.consignee_name+"-"+c.consignee_code,
                    value: c.consignee_code,
                  });
                }
              }
              else{
                filterDealers.push({
                  label: c.consignee_name+"-"+c.consignee_code,
                  value: c.consignee_code,
                });
              }
            });
          }

          var cities = resp.data.cities;
          var filterCities = [{value:"",label:"All"}];
          if (cities.length > 0) {
            cities.map(function (c) {
              if(localStorage.getItem("user_type") !== "" && localStorage.getItem("user_type") !== undefined)
              {
                if(localStorage.getItem("user_type") === "AREA_MANAGER")
                {
                  if(localStorage.getItem("areas") !== "" && localStorage.getItem("areas") !== undefined)
                  {
                    var rr = localStorage.getItem("areas").split(",");
                    if(rr.length > 0)
                    {
                      rr.map((i) => {
                        if(i == c)
                        {
                          filterCities.push({
                            label: c,
                            value: c,
                          });
                        }
                      })
                    }
                  }
                  else{
                    
                    filterCities.push({
                      label: c,
                      value: c,
                    });
                  }
                  
                }
                else{
                  filterCities.push({
                    label: c,
                    value: c,
                  });
                }
              }
              else{
                filterCities.push({
                  label: c,
                  value: c,
                });
              }
              
            });
          }
          var states = resp.data.states;
          var filterStates = [{value:"",label:"All"}];
          if (states.length > 0) {
            states.map(function (s) {
              if(localStorage.getItem("user_type") !== "" && localStorage.getItem("user_type") !== undefined)
              {
                if(localStorage.getItem("user_type") === "REGIONAL_MANAGER" || localStorage.getItem("user_type") === "AREA_MANAGER")
                {
                  if(localStorage.getItem("region") !== "" && localStorage.getItem("region") !== undefined)
                  {
                    var rr = localStorage.getItem("region").split(",");
                    if(rr.length > 0)
                    {
                      rr.map((i) => {
                        if(i == s)
                        {
                          filterStates.push({
                            label: s,
                            value: s,
                          });
                        }
                      })
                    }
                  }
                  else{
                    
                      filterStates.push({
                        label: s,
                        value: s,
                      });
                  }
                  
                }
                else{
                    filterStates.push({
                      label: s,
                      value: s,
                    });
                }
              }
              else{
                  filterStates.push({
                    label: s,
                    value: s,
                  });
              }
            });
            
          }
          var clusters = resp.data.clusters;
          var filterClusters = [{value:"",label:"All"}];
          if (clusters.length > 0) {
            clusters.map(function (cl) {
              filterClusters.push({
                label: cl,
                value: cl,
              });
            });
          }
          var zones = resp.data.zones;
          // console.log(zones,"zonessssssssss")
          var filterZones = [{value:"",label:"All"}];
          const uniqueZonesSet = new Set();
          const x = zones.filter((zone) => {
            const zoneLowerCase = zone.toLowerCase();
            if (uniqueZonesSet.has(zoneLowerCase)) {
              return false;
            }
            uniqueZonesSet.add(zoneLowerCase);
            return true;
          });
          const y = x.filter((e)=>{
            if(e!=="Nan"){
              return true
            }
          })
          // console.log(y,"yyyyyyyyyyyy")
          if (y.length > 0) {
              y.map(function (cl) {
                if(localStorage.getItem("user_type") !== "" && localStorage.getItem("user_type") !== undefined)
                {
                  if(localStorage.getItem("user_type") === "ZONE_MANAGER" || localStorage.getItem("user_type") === "REGIONAL_MANAGER" || localStorage.getItem("user_type") === "AREA_MANAGER")
                  {
                    // console.log("zone ", localStorage.getItem("zone"))
                    if(localStorage.getItem("zone").includes(cl) === true)
                    {
                      filterZones.push({
                        label: cl,
                        value: cl,
                      });
                    }
                  }
                  else{
                    filterZones.push({
                      label: cl,
                      value: cl,
                    });
                  }
                }
                else{
                  filterZones.push({
                    label: cl,
                    value: cl,
                  });
                }
              });
          }
          var transporters = resp.data.alltransporters;
          var filterTransporters = [{value:"",label:"All"}];
          if (transporters.length > 0) {
            transporters.map(function (t) {
              filterTransporters.push({
                label: t.transporter_name,
                value: t.transporter_code,
              });
            });
          }
          
          // if(localStorage.getItem("user_type") === "DEALER")
          // {
          // 	this.setState({
          // 		flDealer:filterDealers[0]
          // 	})
          // }
          this.setState({
            filterCities: filterCities,
            filterStates: filterStates,
            filterClusters: filterClusters,
            filterZones: filterZones,
            filterTransporters: filterTransporters,
            filterDealers:filterDealers,
            chartCities:filterCities,
            chartStates:filterStates,
            chartDealers:filterDealers,
            chartLsps:filterTransporters,
            loadshow:"show-m"
          },()=> {
            this.loadDashboardData();
          });
          
          
        })

      //Use following code after ACL enable


      //console.log("dept_code ", this.state.dept_code)
      /*urlpath = '/consignments/consignmentByDepartment';
        dpt=localStorage.getItem('dept_code');
      redirectURL.post(urlpath,consignData)    */
      if(localStorage.getItem("user_type") !== "" && localStorage.getItem("user_type") !== undefined)
      {
        if(localStorage.getItem("user_type") === "ZONE_MANAGER" || localStorage.getItem("user_type") === "REGIONAL_MANAGER")
        {
          this.setState({
            flZone:{value:localStorage.getItem("zone"),label:localStorage.getItem("zone")}
          },()=>{this.filterZoneParams();})
          
          
        }
        if(localStorage.getItem("user_type") === "REGIONAL_MANAGER" || localStorage.getItem("user_type") === "AREA_MANAGER")
        {
          var statlist = []
          if(localStorage.getItem("region") !== "" && localStorage.getItem("region") !== undefined)
          {
            var rr = localStorage.getItem("region").split(",");
            if(rr.length > 0)
            {
              rr.map((i) =>{
                statlist.push({value:i,label:i})
              })
            }
          }
          if(statlist.length > 0)
          {
            this.setState({
              flStateArray:statlist
            },()=>{this.filterZoneParams();})
            // this.filterZoneParams();
          }
        }
        
        if(localStorage.getItem("user_type") === "AREA_MANAGER")
        {
          var statlista = []
          // console.log("Areas ", localStorage.getItem("areas"))
          if(localStorage.getItem("areas") !== "" && localStorage.getItem("areas") !== undefined)
          {
            var rr = localStorage.getItem("areas").split(",");
            // console.log("RRRR ", rr)
            if(rr.length > 0)
            {
              rr.map((i) =>{
                statlista.push({value:i,label:i})
              })
            }
          }
          // console.log("statlista ", statlista)
          if(statlista.length > 0)
          {
            this.setState({
              flCityArray:statlista
            },()=>{this.filterZoneParams();})
            
          }
        }
      }

      redirectURL.post("/consignments/plants", { dept_code: encode(dpt) })
        .then((resp) => {
          this.setState({
            plants: resp.data
          });
        })
      redirectURL.post("/consignments/usergridstates", { userId: localStorage.getItem("userid"), screenurl: window.location.pathname })
        .then(async (resp) => {
          //console.log("resp ", resp)
          await this.setState({
            usergridstate: resp.data
          });
          await this.restoreGridStates();
        })
      // console.log("urlPath:", urlpath, "consignData:", consignData)

      var aurlpath = "";
      var aurlpathplant = "";
      var aconsignmenturlpath = "";
      if (this.props.match.path == "/sndconsignments") {
        //var	dept_code = dpt;
        aurlpath = "/dashboard/snddepttrucks";
        aurlpathplant = "/dashboard/snddeptplants";
        // aconsignmenturlpath = "/dashboard/prtdeptconsignments";
      }
      var parameters = {
        dept_code: encode(dpt)
      }
      if (aurlpath != "") {
        redirectURL.post(aurlpath, parameters)
          .then((response) => {
            var records = response.data.records;
            var trucks = []
            if (records.length > 0) {
              records.map((item) => {
                trucks.push({
                  value: item.truck_no,
                  label: item.truck_no
                })
              })
            }
            this.setState({
              trucklist: trucks
            })
          })
      }
      if (aurlpathplant != "") {
        redirectURL.post(aurlpathplant, parameters)
          .then((response) => {
            var records = response.data.records;
            var plants = []
            if (records.length > 0) {
              records.map((item) => {
                if (this.state.loginplant != "" && this.state.loginplant != undefined) {
                  if (item.plant_code == this.state.loginplant) {
                    plants.push({
                      value: item.plant_code,
                      label: item.plant_name
                    })
                  }
                }
                else {
                  plants.push({
                    value: item.plant_code,
                    label: item.plant_name
                  })
                }

              })
            }
            this.setState({
              plantlist: plants
            })
          })
      }
      var consignData = {
        dept_code: encode(dpt)
      }
      redirectURL.post("/dashboard/sndconsignees")
        .then((response) => {
          var records = response.data.records;
          var consignees = []
          if (records.length > 0) {
            records.map((item) => {

              consignees.push({
                value: item.consignee_code,
                label: item.consignee_name+"-"+item.consignee_code+"-"+item.consignee_city
              })
            })
          }
          this.setState({
            consignees: consignees
          })
        })

  }
    
	handlerStartDateTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);

		var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());
  //  console.log(startdate,"startdate")  
		if (this.state.to_date != undefined && this.state.to_date != "") {
			// console.log(startdate,"startdate")
			//  console.log(this.state.to_date,"to_date")
			var from_date = new Date(startdate);
      // console.log("from_date",from_date)
			var to_date = new Date(this.state.to_date);
      // console.log("to_date",to_date)
			var startTime = from_date.getTime();
			var endTime = to_date.getTime();
			// console.log(startTime,endTime);
			if (startTime > endTime) {
				this.setState({
					from_date: ""
				});
				alert("To Date should be greater than From Date");
			}
			else {
				this.setState({
					from_date: startdate
				});
			}
		}
		else {
			this.setState({
				from_date: startdate
			});
		}
		//let value = event.target.value;
		//this.setState({'startDate':value});
	}
	handlerEndDateTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);
		var edate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate())
    // console.log("edate",edate)
		if (this.state.from_date != undefined && this.state.from_date != "") {
			//  console.log(this.state.to_date,"to_date")
			// console.log(edate,"endDate")
			var  from_date= new Date(this.state.from_date);
      // console.log("from_date",from_date)
			var to_date = new Date(edate);
      // console.log("to_date",to_date)
			var startTime = from_date.getTime();
			var endTime = to_date.getTime();
			// console.log(startTime,endTime);
			if (startTime > endTime) {
				this.setState({
					to_date: ""
				});
				alert("To Date should be greater than From Date");
			}
			else {
				this.setState({
					to_date: edate
				});
			}
		}
		else {
			this.setState({
				to_date: edate
			});
		}
	}
  
	changeConsignerArrayHandler = consigner => {
		this.setState(
			{ consigner },
			// () => console.log(`Consigner Option selected:`, this.state.consigner)
		);

		this.setState({
			errorMessage: '',
			successMessage: ''
		})
	}
  selectConsignerOptionsItems() {
		let items = [];

		var plants = this.state.plants;

		items.push({ value: 'all', label: 'All' });
		if (plants.length > 0) {
			plants.forEach(item => {
				if (this.state.loginplant != "" && this.state.loginplant != undefined) {
					if (this.state.loginplant == item.plant_code) {
						items.push({ value: item.plant_code, label: item.plant_name });
					}

				}
				else {
					items.push({ value: item.plant_code, label: item.plant_name });
				}

			});
		}
		return items;
	}

  onChangeTransporter(flTransporter)
	{
		this.setState({flTransporter}, () => {
			// this.onGetConsigners();
		})
	}
	onChangeDateType(date_type){
		this.setState({date_type})
	}
	onChangeZone(flZone)
	{
		this.setState({flZone},()=>{
        this.setState({
          flState:{value:"",label:"All"},
          flCity:{value:"",label:"All"},
          flDealer:{value:"",label:"All"},
        },()=>{
          
				this.filterZoneParams();
        })
		})
	}
	onChangeDealerState(flState)
	{
		this.setState({flState},()=>{
        this.setState({
          flCity:{value:"",label:"All"},
          flDealer:{value:"",label:"All"},
        },()=>{
          
				this.filterZoneParams();
        })
		})
	}
	onChangeDealerCity(flCity)
	{
		this.setState({flCity},()=>{
        this.setState({
          flDealer:{value:"",label:"All"},
        },()=>{
          
          this.filterZoneParams();
        })
		})
	}
  
  onChangeChartType(cType)
  {
    this.setState({cType,
      cState:{value:"",label:"All"},
    },()=>{
      this.filterChartZoneParams();
      this.setState({
        cCity:{value:"",label:"All"},
        cState:{value:"",label:"All"},
        cDealer:{value:"",label:"All"},
      })
      if(this.state.cType.value !== "")
      {
        this.getModelTypeChart(this.state.cType.value);
      }
      else{
        this.getStateWiseChartView();
      }
    })
  }
  onChangeLspChartType(cLspType)
  {
    this.setState({cLspType},()=>{
      this.filterChartZoneParams();
      this.setState({
        cLsp:{value:"",label:"All"}
      })
      if(this.state.cLspType.value !== "")
      {
        this.getModelLspTypeChart(this.state.cLspType.value);
      }
      else{
        this.getAllLspWiseChartView();
      }
    })
  }
  onChangeLSPData(cLsp){
    this.setState({cLsp},()=>{
      this.filterChartZoneParams();
      if(this.state.cLsp.value !== "")
      {
        this.getLspWiseChartView(this.state.cLsp.label);
      }
      else{
        this.getAllLspWiseChartView();
      }
    })
  }
	onChangeChartDealerState(cState)
	{
		this.setState({cState},()=>{
				this.filterChartZoneParams();
        this.setState({
          cCity:{value:"",label:"All"}
        })
        if(this.state.cState.value !== "")
        {
          this.getStateWiseChartData(this.state.cState.value);
        }
        else{
          this.getStateWiseChartView();
        }
		})
	}
	onChangeChartDealerCity(cCity)
	{
		this.setState({cCity},()=>{
				this.filterChartZoneParams();
        this.setState({
          cDealer:{value:"",label:"All"}
        })
        if(this.state.cCity.value !== "")
        {
          this.getCityWiseChartData(this.state.cCity.value);
        }
        else{
          if(this.state.cState.value !== "")
          {
            this.getStateWiseChartData(this.state.cState.value);
          }
          else{
            this.getStateWiseChartView();
          }
          
        }
        
		})
	}
	onChangeChartDealer(cDealer)
	{
		this.setState({cDealer},()=>{
				this.filterChartZoneParams();
        
        if(this.state.cDealer.value !== "")
        {
          // console.log("this.state.cCity.value ", this.state.cCity.value)
          this.getDealerWiseChartData(this.state.cDealer.value);
        }
        else{
          
          // console.log("this.state.cCity.value ", this.state.cCity.value)
          if(this.state.cCity.value !== "")
          {
            this.getCityWiseChartData(this.state.cCity.value);
          }
          else{
            if(this.state.cState.value !== "")
            {
              // console.log("this.state.cState.value ", this.state.cState.value)
              this.getStateWiseChartData(this.state.cState.value);
            }
            else{
              // console.log("def ")
              this.getStateWiseChartView();
            }
          }
          
        }
        
		})
	}
	filterZoneParams(){
		var encode = cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
		var params = {
			dept_code: encode("SNDG")
		}
		if(this.state.flZone.value !== "" && this.state.flZone.value !== undefined)
		{
			params.zone = this.state.flZone.value;
		}
		if(this.state.flCity.value !== "" && this.state.flCity.value !== undefined)
		{
			params.dealer_city = this.state.flCity.value;
		}
		
		if(this.state.flState.value !== "" && this.state.flState.value !== undefined)
		{
			params.dealer_state = this.state.flState.value;
		}
		// console.log("flCityArray ", this.state.flCityArray)
		if(this.state.flStateArray.length > 0)
		{
			var stlist= []
			this.state.flStateArray.map((it) => {
				stlist.push(it.value)
			})
			if(stlist.length > 0)
			{
				params.region_states = stlist;
			}
			
		}
		
		if(this.state.flCityArray.length > 0)
		{
			var astlist= []
			this.state.flCityArray.map((it) => {
				astlist.push(it.value)
			})
			if(astlist.length > 0)
			{
				params.areas = astlist;
			}
			
		}
		// console.log("params ",params)
		redirectURL.post("/consignments/getConsignmentsNewFilterKeys", params)
		.then((resp) => {
			var dealers = resp.data.consigneeName;
			var filterDealers = [{value:"",label:"All"}];
			if (dealers.length > 0) {
				dealers.map(function (c) {
					filterDealers.push({
						label: c.consignee_name+"-"+c.consignee_code,
						value: c.consignee_code,
					});
				});
			}

			var cities = resp.data.cities;
			var filterCities = [{value:"",label:"All"}];
			if (cities.length > 0) {
				cities.map(function (c) {
					filterCities.push({
						label: c,
						value: c,
					});
				});
			}
			var states = resp.data.states;
			var filterStates = [{value:"",label:"All"}];
			if (states.length > 0) {
				states.map(function (s) {
					filterStates.push({
						label: s,
						value: s,
					});
				});
			}
			var clusters = resp.data.clusters;
			var filterClusters = [{value:"",label:"All"}];
			if (clusters.length > 0) {
				clusters.map(function (cl) {
					filterClusters.push({
						label: cl,
						value: cl,
					});
				});
			}
			
			var transporters = resp.data.alltransporters;
			var filterTransporters = [{value:"",label:"All"}];
			if (transporters.length > 0) {
				transporters.map(function (t) {
					filterTransporters.push({
						label: t.transporter_name,
						value: t.transporter_code,
					});
				});
			}
			this.setState({
				filterCities: filterCities,
				filterStates: filterStates,
				filterClusters: filterClusters,
				filterDealers:filterDealers,
        filterTransporters:filterTransporters
			});
		})
	}
  
  
	filterChartZoneParams(){
		var encode = cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
		var params = {
			dept_code: encode("SNDG")
		}
		if(this.state.cCity.value !== "" && this.state.cCity.value !== undefined)
		{
			params.dealer_city = this.state.cCity.value;
		}
		
		if(this.state.cState.value !== "" && this.state.cState.value !== undefined)
		{
			params.dealer_state = this.state.cState.value;
		}
		// console.log("flCityArray ", this.state.flCityArray)
		if(this.state.flStateArray.length > 0)
		{
			var stlist= []
			this.state.flStateArray.map((it) => {
				stlist.push(it.value)
			})
			if(stlist.length > 0)
			{
				params.region_states = stlist;
			}
			
		}
		
		if(this.state.flCityArray.length > 0)
		{
			var astlist= []
			this.state.flCityArray.map((it) => {
				astlist.push(it.value)
			})
			if(astlist.length > 0)
			{
				params.areas = astlist;
			}
			
		}
		redirectURL.post("/consignments/getConsignmentsNewFilterKeys", params)
		.then((resp) => {
			var dealers = resp.data.consigneeName;
			var filterDealers = [{value:"",label:"All"}];
			if (dealers.length > 0) {
				dealers.map(function (c) {
					filterDealers.push({
						label: c.consignee_name+"-"+c.consignee_code,
						value: c.consignee_code,
					});
				});
			}

			var cities = resp.data.cities;
			var filterCities = [{value:"",label:"All"}];
			if (cities.length > 0) {
				cities.map(function (c) {
					filterCities.push({
						label: c,
						value: c,
					});
				});
			}
			var states = resp.data.states;
			var filterStates = [{value:"",label:"All"}];
			if (states.length > 0) {
				states.map(function (s) {
					filterStates.push({
						label: s,
						value: s,
					});
				});
			}
			var clusters = resp.data.clusters;
			var filterClusters = [{value:"",label:"All"}];
			if (clusters.length > 0) {
				clusters.map(function (cl) {
					filterClusters.push({
						label: cl,
						value: cl,
					});
				});
			}
			
			var transporters = resp.data.alltransporters;
			var filterTransporters = [{value:"",label:"All"}];
			if (transporters.length > 0) {
				transporters.map(function (t) {
					filterTransporters.push({
						label: t.transporter_name,
						value: t.transporter_code,
					});
				});
			}
			this.setState({
        chartCities:filterCities,
        chartStates:filterStates,
        chartDealers:filterDealers,
        chartLsps:filterTransporters,
			});
		})
	}
  
	onClickSaveGridState() {
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();
		// console.log("Columns ", window.colState)
		// console.log("Group ", window.groupState)
		// console.log("Sort ", window.sortState)
		// console.log("Filter ", window.filterState)
		let screenpage = '';

		if (this.props.match.path == "/sndconsignments") {
			screenpage = 'snd active consignments';
		}
		else if (this.props.match.path == "/prtconsignments") {
			screenpage = 'prt active consignments';
		}
		else if (this.props.match.path == "/tnpconsignments") {
			screenpage = 'tnp active consignments';
		}
		else if (this.props.match.path == "/sndallconsignments") {
			screenpage = 'snd all consignments';

		}
		else if (this.props.match.path == "/prtallconsignments") {
			screenpage = 'prt all consignments';
		}
		else if (this.props.match.path == "/tnpallconsignments") {
			screenpage = 'tnp all consignments';
		}
		else if (this.props.match.path == "/snddeliveredconsignments") {
			screenpage = 'snd delivered consignments';
		}
		else if (this.props.match.path == "/prtdeliveredconsignments") {
			screenpage = 'prt delivered consignments';
		}
		else if (this.props.match.path == "/tnpdeliveredconsignments") {
			screenpage = 'tnp delivered consignments';
		}
		else if (this.props.match.path == "/railconsignments") {
			screenpage = 'railconsignments';
		}
		else if (this.props.match.path == "/ncrconsignments") {
			screenpage = 'ncr consignments';
		}
		else if (this.props.match.path == "/moveconsignments") {
			screenpage = 'move consignments';
		}

		let reqparams = {
			gridcolumns: window.colState,
			gridgroup: window.groupState,
			gridcolsort: window.sortState,
			gridcolfilter: window.filterState,
			userId: localStorage.getItem("userid"),
			screenurl: window.location.pathname,
			screentitle: screenpage
		}
		// console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
			.then((response) => {
				//console.log("State response ",response.data)
				this.setState({
					show: true,
					basicTitle: "Successfully saved grid layout",
					basicType: "success",
					screenurl: window.location.pathname,
					screentitle: screenpage
				})
			})
			.catch(function (e) {
				console.log("Error ", e)
			})
	}

	restoreGridStates() {

		if (this.state.usergridstate.length > 0) {
			var windowstates = this.state.usergridstate;
			this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
			this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
			this.gridApi.setSortModel(windowstates[0].gridcolsort);
			this.gridApi.setFilterModel(windowstates[0].gridcolfilter);

		}
	}

	resetState = () => {
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId: localStorage.getItem("userid"),
			screenurl: window.location.pathname,
			screentitle: this.state.screenpage
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
			.then((response) => {
				this.setState({
					show: true,
					basicTitle: "Successfully reset default grid layout",
					basicType: "success"
				})
			})

	};
  onSubmitGetData(event){
    event.preventDefault();
    this.setState({
      loadshow:"show-m",
    },()=>{
      this.loadDashboardData();
    })
    
  }

  onClickGetParamsChart(){
   var allstateChart = this.state.allstateChart;
   var statedrilldown = this.state.statedrilldown;
   var overall_trip_details = this.state.overall_trip_details;
   var state = this.state.cState.value;
  //  console.log("State", state)
    var city = this.state.cCity.value;
    var dealer = this.state.cDealer.value;
   var statewise = []
  

      // State Wise Start
      if(state !== "")
      {
        this.getStateWiseChartData(state);
      }
      if(city !== "")
      {
        this.getCityWiseChartData(city);
      }
      if(state === "" && city === "")
      {
        this.getStateWiseChartView();
      }
  }
  getStateWiseChartData(state)
  {
    
   var overall_trip_details = this.state.overall_trip_details;
    var modelTypeStates = []
    var stotal_driver_cost = [];
    var stotal_diesel_cost = [];
    var stotal_road_cost = [];
    var stotal_toll_cost = [];
    var stotal_transportation_cost=[];
    var stateValues = [];
    var stateseries = [];
    var stateseriesstate = [];
    // var modeltypes = groupBy(overall_trip_details, rdata => rdata.model);
    //   // console.log("modeltypes",modeltypes )
      var strucks=[];
    //   modeltypes.forEach(async (mtypes,mKey) => {
        // if(mKey !== "" && mKey !== undefined && mKey !== null)
        // {
          var statelist = groupBy(overall_trip_details, rdata => rdata.consignee_state);
          // console.log("zoneslist ",mKey+" == "+ zoneslist)
           var statecities = []
          statelist.forEach(async (allstate,statekey) => {  
            if(statekey !== "" && statekey === state)
            {
              // stateCategory.push({state_name:statekey});
             // var total_transportation_cost_matrix = 0;
              
              if(allstate.length > 0)
              {
                var clist = groupBy(allstate, rdata => rdata.consignee_city);
                clist.forEach((c,k) => {
                  if(k !== "")
                  {
                    var total_transportation_cost_matrix = 0;
                    var diesel_cost_matrix = 0;
                    var driver_cost_matrix = 0;
                    var road_cost_matrix = 0;
                    var toll_cost_matrix = 0;
                    c.map((ti) => {
                      total_transportation_cost_matrix = (![NaN , null , undefined , "undefined" , ""].includes(ti.total_transportation_cost_matrix) ? parseFloat(ti.total_transportation_cost_matrix) : 0)+total_transportation_cost_matrix
                        if(ti.diesel_cost_matrix !== "" && ti.diesel_cost_matrix !== null && ti.diesel_cost_matrix !== undefined)
                        {
                          diesel_cost_matrix = parseFloat(ti.diesel_cost_matrix)+parseFloat(diesel_cost_matrix);
                        }
                        if(ti.driver_cost_matrix !== "" && ti.driver_cost_matrix !== null && ti.driver_cost_matrix !== undefined)
                        {
                          driver_cost_matrix = parseFloat(ti.driver_cost_matrix)+parseFloat(driver_cost_matrix);
                        }
                        if(ti.road_cost_matrix !== "" && ti.road_cost_matrix !== null && ti.road_cost_matrix !== undefined)
                        {
                          road_cost_matrix = parseFloat(ti.road_cost_matrix)+parseFloat(road_cost_matrix);
                        }
                        if(ti.toll_cost_matrix !== "" && ti.toll_cost_matrix !== null && ti.toll_cost_matrix !== undefined)
                        {
                          toll_cost_matrix = parseFloat(ti.toll_cost_matrix)+parseFloat(toll_cost_matrix);
                        }
                      
                    })
                    var stateseriresperratecity =parseInt(parseFloat(total_transportation_cost_matrix)/parseInt(allstate.length));
                    stateseries.push({
                      name:k,
                      y:stateseriresperratecity,
                      lblTxt:"INR "+stateseriresperratecity,
                    })
                    stotal_transportation_cost.push({
                      name:k,
                      y:total_transportation_cost_matrix,
                      lblTxt:"INR "+total_transportation_cost_matrix,
                    })
                    stotal_diesel_cost.push({
                      name:k,
                      y:diesel_cost_matrix,
                      lblTxt:"INR "+diesel_cost_matrix,
                    })
                    stotal_driver_cost.push({
                      name:k,
                      y:driver_cost_matrix,
                      lblTxt:"INR "+driver_cost_matrix,
                    })
                    stotal_road_cost.push({
                      name:k,
                      y:road_cost_matrix,
                      lblTxt:"INR "+road_cost_matrix,
                    })
                    stotal_toll_cost.push({
                      name:k,
                      y:toll_cost_matrix,
                      lblTxt:"INR "+toll_cost_matrix,
                    })
                    statecities.push({
                      name: k,
                      y:total_transportation_cost_matrix,
                      lblTxt:"INR "+total_transportation_cost_matrix,
                    })
                    strucks.push({
                      name:k,
                      trucks:c.length
                    })
                  }
                })
               
                
              }
              
            }
     
         })
        // stateValues = stateValues.sort(GetSortOrder("orderpos"));
          modelTypeStates.push({
            name:"Toll Cost",
            data:stotal_toll_cost,
            dataLabels:{format:'{point.lblTxt}',enabled:true},
            type:"column",
            tooltip: {
              pointFormat: '<b>{series.name}: INR {point.y}</b>'
            },
            yAxis:0,
            color:{
              linearGradient: {
                x1: 1,
                x2: 0,
                y1: 1,
                y2: 0
              },
              stops: [
                [0, "#ff0000"],
                [1, "#B60D05"]
              ]
              }
          },{
            name:"Road Cost",
            data:stotal_road_cost,
            dataLabels:{format:'{point.lblTxt}',enabled:true},
            type:"column",
            tooltip: {
              pointFormat: '<b>{series.name}: INR {point.y}</b>'
            },
            yAxis:0,
            color:{
              linearGradient: {
                x1: 1,
                x2: 0,
                y1: 1,
                y2: 0
              },
              stops: [
                [0, "#A2A704"],
                [1, "#DDE308"]
              ]
            }
          },{
            name:"Diesel Cost",
            data:stotal_diesel_cost,
            dataLabels:{format:'{point.lblTxt}',enabled:true},
            type:"column",
            tooltip: {
              pointFormat: '<b>{series.name}: INR {point.y}</b>'
            },
            yAxis:0,
            color:{
              linearGradient: {
                x1: 1,
                x2: 0,
                y1: 1,
                y2: 0
              },
              stops: [
                [0, "#005C00"],
                [1, "#0AAB0A"]
              ]
              }
          },{
            name:"Driver Cost",
            data:stotal_driver_cost,
            dataLabels:{format:'{point.lblTxt}',enabled:true},
            type:"column",
            tooltip: {
              pointFormat: '<b>{series.name}: INR {point.y}</b>'
            },
            yAxis:0,
            color:{
              linearGradient: {
                x1: 1,
                x2: 0,
                y1: 1,
                y2: 0
              },
              stops: [
                [0, "#022372"],
                [1, "#0D4BDE"]
              ]
}
          })
        // }
      // })
    // console.log("modelTypeStates ", modelTypeStates)
    var stateline=[]
    if(strucks.length > 0)
    {
        var trucksl = groupBy(strucks, rdata => rdata.name);
        try {
          trucksl.forEach((im, k) => {
            var tot = 0;
            if(im.length > 0)
            {
              im.map((i) =>{
                tot = parseInt(tot)+parseInt(i.trucks)
              })
            }
            stateline.push({
              name:k,
              y:tot
            })
          })
        } catch (error) {
          
        }
        
    }
    modelTypeStates.push({
      name:"Total Trucks",
      data:stateline,
      dataLabels:{enabled:true},
      type:"spline",
      yAxis:1,
      color:"#000",
      marker: {
        lineWidth: 2,
        fillColor: 'black'
      },
      center: [100, 80],
    })
    
    this.setState({
      stateChart:modelTypeStates
    })
    modelTypeStates.push({
      name:"Per Trip Truck Cost",
      data:stateseries,
      type:"spline",
      dataLabels:{enabled:true},
      yAxis:0,
      color:"#D62862",
      marker: {
        lineWidth: 2,
        fillColor: 'black'
      },
      center: [100, 80],
    })
    
    this.setState({
      stateChart:modelTypeStates
    })
  }
  
  getCityWiseChartData(city)
  {
    
    var modelTypeCity = []
    // City Wise Start
    var cityValues = [];
    var stotal_driver_cost = [];
    var stotal_diesel_cost = [];
    var stotal_road_cost = [];
    var stotal_toll_cost = [];
    var stotal_transportation_cost = [];

    var overall_trip_details = this.state.overall_trip_details;
    var strucks = [];
    var stateseriescity = [];
    // var modeltypes = groupBy(overall_trip_details, rdata => rdata.model);
    //   // console.log("modeltypes",modeltypes )
    //   modeltypes.forEach(async (mtypes,mKey) => {
        var statecities = []
    //     if(mKey !== "" && mKey !== undefined && mKey !== null)
    //     {
          var citylist = groupBy(overall_trip_details, rdata => rdata.consignee_city);
          
          citylist.forEach(async (allcity,citykey) => {
            if(citykey !== "" && citykey === city)
            {
              var total_transportation_cost_matrix = 0;
              if(allcity.length > 0)
              {
                var clist = groupBy(allcity, rdata => rdata.consignee_code);
                clist.forEach((c,k) => {
                  if(k !== "")
                  {
                    var total_transportation_cost_matrix = 0;
                    var diesel_cost_matrix = 0;
                    var driver_cost_matrix = 0;
                    var road_cost_matrix = 0;
                    var toll_cost_matrix = 0;
                    c.map((ti) => {
                      total_transportation_cost_matrix = (![NaN , null , undefined , "undefined" , ""].includes(ti.total_transportation_cost_matrix) ? parseFloat(ti.total_transportation_cost_matrix) : 0)+total_transportation_cost_matrix
                        
                        if(ti.diesel_cost_matrix !== "" && ti.diesel_cost_matrix !== null && ti.diesel_cost_matrix !== undefined)
                        {
                          diesel_cost_matrix = parseFloat(ti.diesel_cost_matrix)+parseFloat(diesel_cost_matrix);
                        }
                        if(ti.driver_cost_matrix !== "" && ti.driver_cost_matrix !== null && ti.driver_cost_matrix !== undefined)
                        {
                          driver_cost_matrix = parseFloat(ti.driver_cost_matrix)+parseFloat(driver_cost_matrix);
                        }
                        if(ti.road_cost_matrix !== "" && ti.road_cost_matrix !== null && ti.road_cost_matrix !== undefined)
                        {
                          road_cost_matrix = parseFloat(ti.road_cost_matrix)+parseFloat(road_cost_matrix);
                        }
                        if(ti.toll_cost_matrix !== "" && ti.toll_cost_matrix !== null && ti.toll_cost_matrix !== undefined)
                        {
                          toll_cost_matrix = parseFloat(ti.toll_cost_matrix)+parseFloat(toll_cost_matrix);
                        }
                      
                    })
                    var stateseriresperratecity = parseInt(parseFloat(total_transportation_cost_matrix)/parseInt(allcity.length));
                    stateseriescity.push({
                      name:c[0].consignee_name+" - "+k,
                      y:stateseriresperratecity,
                      lblTxt:"INR "+stateseriresperratecity,
                    })
                    stotal_transportation_cost.push({
                      name:c[0].consignee_name+" - "+k,
                      y:total_transportation_cost_matrix,
                      lblTxt:"INR "+total_transportation_cost_matrix,
                    })
                    stotal_diesel_cost.push({
                      name:c[0].consignee_name+" - "+k,
                      y:diesel_cost_matrix,
                      lblTxt:"INR "+diesel_cost_matrix,
                    })
                    stotal_driver_cost.push({
                      name:c[0].consignee_name+" - "+k,
                      y:driver_cost_matrix,
                      lblTxt:"INR "+driver_cost_matrix,
                    })
                    stotal_road_cost.push({
                      name:c[0].consignee_name+" - "+k,
                      y:road_cost_matrix,
                      lblTxt:"INR "+road_cost_matrix,
                    })
                    stotal_toll_cost.push({
                      name:c[0].consignee_name+" - "+k,
                      y:toll_cost_matrix,
                      lblTxt:"INR "+toll_cost_matrix,
                    })
                    
                    statecities.push({
                      name: c[0].consignee_name+" - "+k,
                      y:total_transportation_cost_matrix,
                      lblTxt:"INR "+total_transportation_cost_matrix,
                    })
                    
                    strucks.push({
                      name:c[0].consignee_name+" - "+k,
                      trucks:c.length
                    })
                  }
                })

                // allcity.map((ti) => {
                //   total_transportation_cost_matrix = parseFloat(ti.total_transportation_cost_matrix)+parseFloat(total_transportation_cost_matrix);
                // })
              }
              
              // cityValues.push({
              //     name:citykey,
              //     y:total_transportation_cost_matrix,
              //     lblTxt:"INR "+total_transportation_cost_matrix,
              //   })
              // },300);
            }
          })
          modelTypeCity.push({
            name:"Toll Cost",
            data:stotal_toll_cost,
            dataLabels:{format:'{point.lblTxt}',enabled:true},
            type:"column",
            tooltip: {
              pointFormat: '<b>{series.name}: INR {point.y}</b>'
            },
            yAxis:0,
            color:{
              linearGradient: {
                x1: 1,
                x2: 0,
                y1: 1,
                y2: 0
              },
              stops: [
                [0, "#ff0000"],
                [1, "#B60D05"]
              ]
            }
          },{
            name:"Road Cost",
            data:stotal_road_cost,
            dataLabels:{format:'{point.lblTxt}',enabled:true},
            type:"column",
            tooltip: {
              pointFormat: '<b>{series.name}: INR {point.y}</b>'
            },
            yAxis:0,
            color:{
              linearGradient: {
                x1: 1,
                x2: 0,
                y1: 1,
                y2: 0
              },
              stops: [
                [0, "#A2A704"],
                [1, "#DDE308"]
              ]
            }
          },{
            name:"Diesel Cost",
            data:stotal_diesel_cost,
            dataLabels:{format:'{point.lblTxt}',enabled:true},
            type:"column",
            tooltip: {
              pointFormat: '<b>{series.name}: INR {point.y}</b>'
            },
            yAxis:0,
            color:{
              linearGradient: {
                x1: 1,
                x2: 0,
                y1: 1,
                y2: 0
              },
              stops: [
                [0, "#005C00"],
                [1, "#0AAB0A"]
              ]
              }
          },{
            name:"Driver Cost",
            data:stotal_driver_cost,
            dataLabels:{format:'{point.lblTxt}',enabled:true},
            type:"column",
            tooltip: {
              pointFormat: '<b>{series.name}: INR {point.y}</b>'
            },
            yAxis:0,
            color:{
              linearGradient: {
                x1: 1,
                x2: 0,
                y1: 1,
                y2: 0
              },
              stops: [
                [0, "#022372"],
                [1, "#0D4BDE"]
              ]
}
          })
      
      //   }
      // })
    
    // stateValues = stateValues.sort(GetSortOrder("orderpos"));
    var stateline=[]
    if(strucks.length > 0)
    {
        var trucksl = groupBy(strucks, rdata => rdata.name);
        try {
          trucksl.forEach((im, k) => {
            var tot = 0;
            if(im.length > 0)
            {
              im.map((i) =>{
                tot = parseInt(tot)+parseInt(i.trucks)
              })
            }
            stateline.push({
              name:k,
              y:tot
            })
          })
        } catch (error) {
          
        }
        
    }
    modelTypeCity.push({
      name:"Total Trucks",
      data:stateline,
      type:"spline",
      color:"#000",
      yAxis:1,
      marker: {
        lineWidth: 2,
        fillColor: 'black'
      },
      center: [100, 80],
    })
    
    this.setState({
      stateChart:modelTypeCity
    })
    modelTypeCity.push({
      name:"Per Trip Truck Cost",
      data:stateseriescity,
      dataLabels:{enabled:true},
      type:"spline",
      color:"#D62862",
      yAxis:0,
      marker: {
        lineWidth: 2,
        fillColor: 'black'
      },
      center: [100, 80],
    })
    
    this.setState({
      stateChart:modelTypeCity
    })
  }
  getModelTypeChart(mtype)
  {
    // console.log("mtype ", mtype)
     // State Wise Start
     var stateValues = [];
     var modelTypeStates = [];
     var stotal_driver_cost = [];
      var stotal_diesel_cost = [];
      var stotal_road_cost = [];
      var stotal_toll_cost = [];
      var stotal_transportation_cost = [];
      var pertripstateseries = [];
      var stateseriestype = [];

     var overall_trip_details = this.state.overall_trip_details;
    var strucks = [];
     
    var modeltypes = groupBy(overall_trip_details, rdata => rdata.model);
    // console.log("modeltypes",modeltypes )
    modeltypes.forEach(async (mtypes,mKey) => {
      var statecities = []
      if(mKey !== "" && mKey !== undefined && mKey !== null && mKey === mtype)
      {
          var statelist = groupBy(mtypes, rdata => rdata.consignee_state);
          
          
          // console.log("allstate ",mKey+" == ")
          // console.log("statelist ", statelist)
          statelist.forEach(async (allstate,statekey) => {
            if(statekey !== "")
            {
              var diesel_cost_matrix = 0;
              var driver_cost_matrix = 0;
              var road_cost_matrix = 0;
              var toll_cost_matrix = 0;
              // stateCategory.push({state_name:statekey});
              var total_transportation_cost_matrix = 0;
              if(allstate.length > 0)
              {
                allstate.map((ti) => {
                  total_transportation_cost_matrix = (![NaN , null , undefined , "undefined" , ""].includes(ti.total_transportation_cost_matrix) ? parseFloat(ti.total_transportation_cost_matrix) : 0)+total_transportation_cost_matrix
                  if(ti.diesel_cost_matrix !== "" && ti.diesel_cost_matrix !== null && ti.diesel_cost_matrix !== undefined)
                  {
                    diesel_cost_matrix = parseFloat(ti.diesel_cost_matrix)+parseFloat(diesel_cost_matrix);
                  }
                  if(ti.driver_cost_matrix !== "" && ti.driver_cost_matrix !== null && ti.driver_cost_matrix !== undefined)
                  {
                    driver_cost_matrix = parseFloat(ti.driver_cost_matrix)+parseFloat(driver_cost_matrix);
                  }
                  if(ti.road_cost_matrix !== "" && ti.road_cost_matrix !== null && ti.road_cost_matrix !== undefined)
                  {
                    road_cost_matrix = parseFloat(ti.road_cost_matrix)+parseFloat(road_cost_matrix);
                  }
                  if(ti.toll_cost_matrix !== "" && ti.toll_cost_matrix !== null && ti.toll_cost_matrix !== undefined)
                  {
                    toll_cost_matrix = parseFloat(ti.toll_cost_matrix)+parseFloat(toll_cost_matrix);
                  }
                })
              }
              var stateseriesperrate =parseInt(parseFloat(total_transportation_cost_matrix)/parseInt(allstate.length));
              stateseriestype.push({
                name:statekey,
                y:stateseriesperrate,
                lblTxt:"INT "+stateseriesperrate,
                
              })
              // console.log("diesel_cost_matrix ", diesel_cost_matrix)
              // console.log("statekey ", statekey)
              stotal_transportation_cost.push({
                name:statekey,
                y:total_transportation_cost_matrix,
                lblTxt:"INR "+total_transportation_cost_matrix,
              })
              stotal_diesel_cost.push({
                name:statekey,
                y:diesel_cost_matrix,
                lblTxt:"INR "+diesel_cost_matrix,
              })
              stotal_driver_cost.push({
                name:statekey,
                y:driver_cost_matrix,
                lblTxt:"INR "+driver_cost_matrix,
              })
              stotal_road_cost.push({
                name:statekey,
                y:road_cost_matrix,
                lblTxt:"INR "+road_cost_matrix,
              })
              stotal_toll_cost.push({
                name:statekey,
                y:toll_cost_matrix,
                lblTxt:"INR "+toll_cost_matrix,
              })
              stateValues.push({
                  name:statekey,
                  y:total_transportation_cost_matrix,
                  lblTxt:"INR "+total_transportation_cost_matrix,
                  drilldown:statekey
                })
                strucks.push({
                  name:statekey,
                  trucks:allstate.length
                })
              // },300);
            }
            
          })
        }
      })
      // console.log("stotal_diesel_cost ", stotal_diesel_cost)
     modelTypeStates.push({
        name:"Toll Cost",
        data:stotal_toll_cost,
        dataLabels:{format:'{point.lblTxt}',enabled:true},
        type:"column",
        tooltip: {
          pointFormat: '<b>{series.name}: INR {point.y}</b>'
        },
        yAxis:0,
        color:{
          linearGradient: {
            x1: 1,
            x2: 0,
            y1: 1,
            y2: 0
          },
          stops: [
            [0, "#ff0000"],
            [1, "#B60D05"]
          ]
          }
      },{
       name:"Road Cost",
       data:stotal_road_cost,
       dataLabels:{format:'{point.lblTxt}',enabled:true},
       type:"column",
       tooltip: {
         pointFormat: '<b>{series.name}: INR {point.y}</b>'
       },
       yAxis:0,
       color:{
         linearGradient: {
           x1: 1,
           x2: 0,
           y1: 1,
           y2: 0
         },
         stops: [
           [0, "#A2A704"],
           [1, "#DDE308"]
         ]
       }
     },{
      name:"Diesel Cost",
      data:stotal_diesel_cost,
      dataLabels:{format:'{point.lblTxt}',enabled:true},
      type:"column",
      tooltip: {
        pointFormat: '<b>{series.name}: INR {point.y}</b>'
      },
      yAxis:0,
      color:{
        linearGradient: {
          x1: 1,
          x2: 0,
          y1: 1,
          y2: 0
        },
        stops: [
          [0, "#005C00"],
          [1, "#0AAB0A"]
        ]
        }
    },{
       name:"Driver Cost",
       data:stotal_driver_cost,
       dataLabels:{format:'{point.lblTxt}',enabled:true},
       type:"column",
       tooltip: {
         pointFormat: '<b>{series.name}: INR {point.y}</b>'
       },
       yAxis:0,
       color:{
         linearGradient: {
           x1: 1,
           x2: 0,
           y1: 1,
           y2: 0
         },
         stops: [
           [0, "#022372"],
           [1, "#0D4BDE"]
         ]
}
     })
     var stateline=[]
    if(strucks.length > 0)
    {
        var trucksl = groupBy(strucks, rdata => rdata.name);
        try {
          trucksl.forEach((im, k) => {
            var tot = 0;
            if(im.length > 0)
            {
              im.map((i) =>{
                tot = parseInt(tot)+parseInt(i.trucks)
              })
            }
            stateline.push({
              name:k,
              y:tot
            })
          })
        } catch (error) {
          
        }
        
    }
    modelTypeStates.push({
      name:"Total Trucks",
      data:stateline,
      type:"spline",
      dataLabels:{enabled:true},
      color:"#000",
      yAxis:1,
      marker: {
        lineWidth: 2,
        fillColor: 'black'
      },
      center: [100, 80],
    })
    
    this.setState({
      stateChart:modelTypeStates
    })
    modelTypeStates.push({
      name:"Per Trip Truck Cost",
      data:stateseriestype,
      dataLabels:{enabled:true},
      type:"spline",
      color:"#D62862",
      yAxis:0,
      marker: {
        lineWidth: 2,
        fillColor: 'black'
      },
      center: [100, 80],
    })
    
    this.setState({
      stateChart:modelTypeStates
    })
  }

  
  getModelLspTypeChart(mtype)
  {
    // console.log("mtype ", mtype)
     // State Wise Start
     var stateValues = [];
     var modelTypeStates = [];
     var stotal_driver_cost = [];
      var stotal_diesel_cost = [];
      var stotal_road_cost = [];
      var stotal_toll_cost = [];
      var stotal_transportation_cost = [];
      var stateseries = [];

     var overall_trip_details = this.state.overall_trip_details;
    var strucks = [];
    var modeltypes = groupBy(overall_trip_details, rdata => rdata.model);
    // console.log("modeltypes",modeltypes )
    modeltypes.forEach(async (mtypes,mKey) => {
      var statecities = []
      if(mKey !== "" && mKey !== undefined && mKey !== null && mKey === mtype)
      {
          var statelist = groupBy(mtypes, rdata => rdata.transporter_name);
          
          
          // console.log("allstate ",mKey+" == ")
          // console.log("statelist ", statelist)
          statelist.forEach(async (allstate,statekey) => {
            if(statekey !== "")
            {
              var diesel_cost_matrix = 0;
              var driver_cost_matrix = 0;
              var road_cost_matrix = 0;
              var toll_cost_matrix = 0;
              // stateCategory.push({state_name:statekey});
              var total_transportation_cost_matrix = 0;
              if(allstate.length > 0)
              {
                allstate.map((ti) => {
                  total_transportation_cost_matrix = (![NaN , null , undefined , "undefined" , ""].includes(ti.total_transportation_cost_matrix) ? parseFloat(ti.total_transportation_cost_matrix) : 0)+total_transportation_cost_matrix
                  if(ti.diesel_cost_matrix !== "" && ti.diesel_cost_matrix !== null && ti.diesel_cost_matrix !== undefined)
                  {
                    diesel_cost_matrix = parseFloat(ti.diesel_cost_matrix)+parseFloat(diesel_cost_matrix);
                  }
                  if(ti.driver_cost_matrix !== "" && ti.driver_cost_matrix !== null && ti.driver_cost_matrix !== undefined)
                  {
                    driver_cost_matrix = parseFloat(ti.driver_cost_matrix)+parseFloat(driver_cost_matrix);
                  }
                  if(ti.road_cost_matrix !== "" && ti.road_cost_matrix !== null && ti.road_cost_matrix !== undefined)
                  {
                    road_cost_matrix = parseFloat(ti.road_cost_matrix)+parseFloat(road_cost_matrix);
                  }
                  if(ti.toll_cost_matrix !== "" && ti.toll_cost_matrix !== null && ti.toll_cost_matrix !== undefined)
                  {
                    toll_cost_matrix = parseFloat(ti.toll_cost_matrix)+parseFloat(toll_cost_matrix);
                  }
                })
              }
              // console.log("diesel_cost_matrix ", diesel_cost_matrix)
              // console.log("statekey ", statekey)
              var stateseriesperrate =parseInt(parseFloat(total_transportation_cost_matrix)/parseInt(allstate.length));
              stateseries.push({
                name:statekey,
                y:stateseriesperrate,
                lblTxt:"INR "+stateseriesperrate,
              })
              stotal_transportation_cost.push({
                name:statekey,
                y:total_transportation_cost_matrix,
                lblTxt:"INR "+total_transportation_cost_matrix,
              })
              stotal_diesel_cost.push({
                name:statekey,
                y:diesel_cost_matrix,
                lblTxt:"INR "+diesel_cost_matrix,
              })
              stotal_driver_cost.push({
                name:statekey,
                y:driver_cost_matrix,
                lblTxt:"INR "+driver_cost_matrix,
              })
              stotal_road_cost.push({
                name:statekey,
                y:road_cost_matrix,
                lblTxt:"INR "+road_cost_matrix,
              })
              stotal_toll_cost.push({
                name:statekey,
                y:toll_cost_matrix,
                lblTxt:"INR "+toll_cost_matrix,
              })
              stateValues.push({
                  name:statekey,
                  y:total_transportation_cost_matrix,
                  lblTxt:"INR "+total_transportation_cost_matrix,
                  drilldown:statekey
                })
                strucks.push({
                  name:statekey,
                  trucks:allstate.length
                })
              // },300);
            }
            
          })
          modelTypeStates.push({
            name:"Toll Cost",
            data:stotal_toll_cost,
            dataLabels:{format:'{point.lblTxt}',enabled:true},
            type:"column",
            tooltip: {
              pointFormat: '<b>{series.name}: INR {point.y}</b>'
            },
            yAxis:0,
            color:{
              linearGradient: {
                x1: 1,
                x2: 0,
                y1: 1,
                y2: 0
              },
              stops: [
                [0, "#ff0000"],
                [1, "#B60D05"]
              ]
              }
          },{
            name:"Road Cost",
            data:stotal_road_cost,
            dataLabels:{format:'{point.lblTxt}',enabled:true},
            type:"column",
            tooltip: {
              pointFormat: '<b>{series.name}: INR {point.y}</b>'
            },
            yAxis:0,
            color:{
              linearGradient: {
                x1: 1,
                x2: 0,
                y1: 1,
                y2: 0
              },
              stops: [
                [0, "#A2A704"],
                [1, "#DDE308"]
              ]
            }
          },{
            name:"Diesel Cost",
            data:stotal_diesel_cost,
            dataLabels:{format:'{point.lblTxt}',enabled:true},
            type:"column",
            tooltip: {
              pointFormat: '<b>{series.name}: INR {point.y}</b>'
            },
            yAxis:0,
            color:{
              linearGradient: {
                x1: 1,
                x2: 0,
                y1: 1,
                y2: 0
              },
              stops: [
                [0, "#005C00"],
                [1, "#0AAB0A"]
              ]
              }
          },{
            name:"Driver Cost",
            data:stotal_driver_cost,
            dataLabels:{format:'{point.lblTxt}',enabled:true},
            type:"column",
            tooltip: {
              pointFormat: '<b>{series.name}: INR {point.y}</b>'
            },
            yAxis:0,
            color:{
              linearGradient: {
                x1: 1,
                x2: 0,
                y1: 1,
                y2: 0
              },
              stops: [
                [0, "#022372"],
                [1, "#0D4BDE"]
              ]
}
          })
        }
      })
      // console.log("stotal_diesel_cost ", stotal_diesel_cost)
     
     var stateline=[]
    if(strucks.length > 0)
    {
        var trucksl = groupBy(strucks, rdata => rdata.name);
        try {
          trucksl.forEach((im, k) => {
            var tot = 0;
            if(im.length > 0)
            {
              im.map((i) =>{
                tot = parseInt(tot)+parseInt(i.trucks)
              })
            }
            stateline.push({
              name:k,
              y:tot
            })
          })
        } catch (error) {
          
        }
        
    }
    modelTypeStates.push({
      name:"Total Trucks",
      data:stateline,
      dataLabels:{enabled:true},
      type:"spline",
      color:"#000",
      yAxis:1,
      marker: {
        lineWidth: 2,
        fillColor: 'black'
      },
      center: [100, 80],
    })
    
    this.setState({
      lspChart:modelTypeStates
    })
    modelTypeStates.push({
      name:"Per Trip Truck Cost",
      data:stateseries,
      dataLabels:{enabled:true},
      type:"spline",
      color:"#D62862",
      yAxis:0,
      marker: {
        lineWidth: 2,
        fillColor: 'black'
      },
      center: [100, 80],
    })
    
    this.setState({
      lspChart:modelTypeStates
    })
  }
  getDealerWiseChartData(dealer)
  {
    // console.log("dealer ", dealer)
    var modelTypeCity = []
    // City Wise Start
    var cityValues = [];
    var stotal_driver_cost = [];
    var stotal_diesel_cost = [];
    var stotal_road_cost = [];
    var stotal_toll_cost = [];
    var stotal_transportation_cost=[];
    var overall_trip_details = this.state.overall_trip_details;
    var strucks = [];
    var stateseriesdealer = [];
    // var modeltypes = groupBy(overall_trip_details, rdata => rdata.model);
    //   // console.log("modeltypes",modeltypes )
    //   modeltypes.forEach(async (mtypes,mKey) => {
        var statecities = []
    //     if(mKey !== "" && mKey !== undefined && mKey !== null)
    //     {
          // var citylist = groupBy(overall_trip_details, rdata => rdata.consignee_city);
          
          // citylist.forEach(async (allcity,citykey) => {
            // if(citykey !== "")
            // {
            //  // var total_transportation_cost_matrix = 0;
            //   if(allcity.length > 0)
            //   {
                var clist = groupBy(overall_trip_details, rdata => rdata.consignee_code);
                clist.forEach((c,k) => {
                  if(k !== "" && k === dealer)
                  {
                    var total_transportation_cost_matrix = 0;var diesel_cost_matrix = 0;
                    var driver_cost_matrix = 0;
                    var road_cost_matrix = 0;
                    var toll_cost_matrix = 0;
                    c.map((ti) => {
                      total_transportation_cost_matrix = (![NaN , null , undefined , "undefined" , ""].includes(ti.total_transportation_cost_matrix) ? parseFloat(ti.total_transportation_cost_matrix) : 0)+total_transportation_cost_matrix
                        
                        if(ti.diesel_cost_matrix !== "" && ti.diesel_cost_matrix !== null && ti.diesel_cost_matrix !== undefined)
                        {
                          diesel_cost_matrix = parseFloat(ti.diesel_cost_matrix)+parseFloat(diesel_cost_matrix);
                        }
                        if(ti.driver_cost_matrix !== "" && ti.driver_cost_matrix !== null && ti.driver_cost_matrix !== undefined)
                        {
                          driver_cost_matrix = parseFloat(ti.driver_cost_matrix)+parseFloat(driver_cost_matrix);
                        }
                        if(ti.road_cost_matrix !== "" && ti.road_cost_matrix !== null && ti.road_cost_matrix !== undefined)
                        {
                          road_cost_matrix = parseFloat(ti.road_cost_matrix)+parseFloat(road_cost_matrix);
                        }
                        if(ti.toll_cost_matrix !== "" && ti.toll_cost_matrix !== null && ti.toll_cost_matrix !== undefined)
                        {
                          toll_cost_matrix = parseFloat(ti.toll_cost_matrix)+parseFloat(toll_cost_matrix);
                        }
                      
                    })
                    var stateseriresperratecity =parseInt(parseFloat(total_transportation_cost_matrix)/parseInt(c.length));
                    stateseriesdealer.push({
                      name:c[0].consignee_name+" - "+k,
                      y:stateseriresperratecity,
                      lblTxt:"INR "+stateseriresperratecity,
                    })
                    stotal_transportation_cost.push({
                      name:c[0].consignee_name+" - "+k,
                      y:total_transportation_cost_matrix,
                      lblTxt:"INR "+total_transportation_cost_matrix,
                    })
                    stotal_diesel_cost.push({
                      name:c[0].consignee_name+" - "+k,
                      y:diesel_cost_matrix,
                      lblTxt:"INR "+diesel_cost_matrix,
                    })
                    stotal_driver_cost.push({
                      name:c[0].consignee_name+" - "+k,
                      y:driver_cost_matrix,
                      lblTxt:"INR "+driver_cost_matrix,
                    })
                    stotal_road_cost.push({
                      name:c[0].consignee_name+" - "+k,
                      y:road_cost_matrix,
                      lblTxt:"INR "+road_cost_matrix,
                    })
                    stotal_toll_cost.push({
                      name:c[0].consignee_name+" - "+k,
                      y:toll_cost_matrix,
                      lblTxt:"INR "+toll_cost_matrix,
                    })
                    
                    statecities.push({
                      name: c[0].consignee_name+" - "+k,
                      y:total_transportation_cost_matrix,
                      lblTxt:"INR "+total_transportation_cost_matrix,
                    })
                    
                    strucks.push({
                      name:c[0].consignee_name+" - "+k,
                      trucks:c.length
                    })
                  }
                })
            //   }

            // }
          // })
          modelTypeCity.push({
            name:"Toll Cost",
            data:stotal_toll_cost,
            dataLabels:{format:'{point.lblTxt}',enabled:true},
            type:"column",
            tooltip: {
              pointFormat: '<b>{series.name}: INR {point.y}</b>'
            },
            yAxis:0,
            color:{
              linearGradient: {
                x1: 1,
                x2: 0,
                y1: 1,
                y2: 0
              },
              stops: [
                [0, "#ff0000"],
                [1, "#B60D05"]
              ]
              }
          },{
            name:"Road Cost",
            data:stotal_road_cost,
            dataLabels:{format:'{point.lblTxt}',enabled:true},
            type:"column",
            tooltip: {
              pointFormat: '<b>{series.name}: INR {point.y}</b>'
            },
            yAxis:0,
            color:{
              linearGradient: {
                x1: 1,
                x2: 0,
                y1: 1,
                y2: 0
              },
              stops: [
                [0, "#A2A704"],
                [1, "#DDE308"]
              ]
            }
          },{
            name:"Diesel Cost",
            data:stotal_diesel_cost,
            dataLabels:{format:'{point.lblTxt}',enabled:true},
            type:"column",
            tooltip: {
              pointFormat: '<b>{series.name}: INR {point.y}</b>'
            },
            yAxis:0,
            color:{
              linearGradient: {
                x1: 1,
                x2: 0,
                y1: 1,
                y2: 0
              },
              stops: [
                [0, "#005C00"],
                [1, "#0AAB0A"]
              ]
              }
          },{
            name:"Driver Cost",
            data:stotal_driver_cost,
            dataLabels:{format:'{point.lblTxt}',enabled:true},
            type:"column",
            tooltip: {
              pointFormat: '<b>{series.name}: INR {point.y}</b>'
            },
            yAxis:0,
            color:{
              linearGradient: {
                x1: 1,
                x2: 0,
                y1: 1,
                y2: 0
              },
              stops: [
                [0, "#022372"],
                [1, "#0D4BDE"]
              ]
}
          })
      
      //   }
      // })
    
    // stateValues = stateValues.sort(GetSortOrder("orderpos"));
    var stateline=[]
    if(strucks.length > 0)
    {
        var trucksl = groupBy(strucks, rdata => rdata.name);
        try {
          trucksl.forEach((im, k) => {
            var tot = 0;
            if(im.length > 0)
            {
              im.map((i) =>{
                tot = parseInt(tot)+parseInt(i.trucks)
              })
            }
            stateline.push({
              name:k,
              y:tot
            })
          })
        } catch (error) {
          
        }
        
    }
    modelTypeCity.push({
      name:"Total Trucks",
      data:stateline,
      type:"spline",
      color:"#000",
      yAxis:1,
      marker: {
        lineWidth: 2,
        fillColor: 'black'
      },
      center: [100, 80],
    })
    // console.log("modelTypeCity ", modelTypeCity)
    this.setState({
      stateChart:modelTypeCity
    })
    modelTypeCity.push({
      name:"Per Trip Truck Cost",
      data:stateseriesdealer,
      dataLabels:{enabled:true},
      type:"spline",
      color:"#D62862",
      yAxis:0,
      marker: {
        lineWidth: 2,
        fillColor: 'black'
      },
      center: [100, 80],
    })
    // console.log("modelTypeCity ", modelTypeCity)
    this.setState({
      stateChart:modelTypeCity
    })
  }

  getStateWiseChartView(){
    console.log("here ")
    var modelTypeZones  = []
    var modelTypeStates = []
    var modelTypeCity = []
    var dealerChartData = []
    var modelTypeStates = [];
    var stotal_driver_cost = [];
     var stotal_diesel_cost = [];
     var stotal_road_cost = [];
     var stotal_toll_cost = [];
     var stotal_transportation_cost=[];
    var overall_trip_details = this.state.overall_trip_details;
    var strucks = []
    var stateseries = [];
    // var modeltypes = groupBy(overall_trip_details, rdata => rdata.model);
    // modeltypes.forEach(async (mtypes,mKey) => {
    //   if(mKey !== "" && mKey !== undefined && mKey !== null)
    //   {
        // State Wise Start
        var stateValues = [];
        var statelist = groupBy(overall_trip_details, rdata => rdata.consignee_state);
        // console.log("zoneslist ",mKey+" == "+ zoneslist)
        statelist.forEach(async (allstate,statekey) => {
          if(statekey !== "")
          {
            // stateCategory.push({state_name:statekey});
            var total_transportation_cost_matrix = 0;
            var diesel_cost_matrix = 0;
            var driver_cost_matrix = 0;
            var road_cost_matrix = 0;
            var toll_cost_matrix = 0;
            if(allstate.length > 0)
            {
              allstate.map((ti) => {
                total_transportation_cost_matrix = (![NaN , null , undefined , "undefined" , ""].includes(ti.total_transportation_cost_matrix) ? parseFloat(ti.total_transportation_cost_matrix) : 0)+total_transportation_cost_matrix
                if(ti.diesel_cost_matrix !== "" && ti.diesel_cost_matrix !== null && ti.diesel_cost_matrix !== undefined)
                {
                  diesel_cost_matrix = parseFloat(ti.diesel_cost_matrix)+parseFloat(diesel_cost_matrix);
                }
                if(ti.driver_cost_matrix !== "" && ti.driver_cost_matrix !== null && ti.driver_cost_matrix !== undefined)
                {
                  driver_cost_matrix = parseFloat(ti.driver_cost_matrix)+parseFloat(driver_cost_matrix);
                }
                if(ti.road_cost_matrix !== "" && ti.road_cost_matrix !== null && ti.road_cost_matrix !== undefined)
                {
                  road_cost_matrix = parseFloat(ti.road_cost_matrix)+parseFloat(road_cost_matrix);
                }
                if(ti.toll_cost_matrix !== "" && ti.toll_cost_matrix !== null && ti.toll_cost_matrix !== undefined)
                {
                  toll_cost_matrix = parseFloat(ti.toll_cost_matrix)+parseFloat(toll_cost_matrix);
                }
              })
            }
            var stateseriesperrate = parseInt(parseFloat(total_transportation_cost_matrix)/parseInt(allstate.length));
            stateseries.push({
              name:statekey,
              y:stateseriesperrate,
              lblTxt:"INR "+stateseriesperrate,
            })
            // console.log("stateseriesperrate",stateseriesperrate)
           
            stotal_transportation_cost.push({
              name:statekey,
              y:total_transportation_cost_matrix,
              lblTxt:"INR "+total_transportation_cost_matrix,
            })
            stotal_diesel_cost.push({
              name:statekey,
              y:diesel_cost_matrix,
              lblTxt:"INR "+diesel_cost_matrix,
            })
            stotal_driver_cost.push({
              name:statekey,
              y:driver_cost_matrix,
              lblTxt:"INR "+driver_cost_matrix,
            })
            stotal_road_cost.push({
              name:statekey,
              y:road_cost_matrix,
              lblTxt:"INR "+road_cost_matrix,
            })
            stotal_toll_cost.push({
              name:statekey,
              y:toll_cost_matrix,
              lblTxt:"INR "+toll_cost_matrix,
            })
            stateValues.push({
                name:statekey,
                y:total_transportation_cost_matrix,
                lblTxt:"INR "+total_transportation_cost_matrix,
                drilldown:statekey
              })
              
              strucks.push({
                name:statekey,
                trucks:allstate.length
              })
            // },300);
          }
        })
        
        // stateValues = stateValues.sort(GetSortOrder("orderpos"));
        modelTypeStates.push({
          name:"Toll Cost",
          data:stotal_toll_cost,
          dataLabels:{format:'{point.lblTxt}',enabled:true},
          type:"column",
          tooltip: {
            pointFormat: '<b>{series.name}: INR {point.y}</b>'
          },
          yAxis:0,
          color:{
            linearGradient: {
              x1: 1,
              x2: 0,
              y1: 1,
              y2: 0
            },
            stops: [
              [0, "#ff0000"],
              [1, "#B60D05"]
            ]
            }
        },{
          name:"Road Cost",
          data:stotal_road_cost,
          dataLabels:{format:'{point.lblTxt}',enabled:true},
          type:"column",
          tooltip: {
            pointFormat: '<b>{series.name}: INR {point.y}</b>'
          },
          yAxis:0,
          color:{
            linearGradient: {
              x1: 1,
              x2: 0,
              y1: 1,
              y2: 0
            },
            stops: [
              [0, "#A2A704"],
              [1, "#DDE308"]
            ]
          }
        },{
          name:"Diesel Cost",
          data:stotal_diesel_cost,
          dataLabels:{format:'{point.lblTxt}',enabled:true},
          type:"column",
          tooltip: {
            pointFormat: '<b>{series.name}: INR {point.y}</b>'
          },
          yAxis:0,
          color:{
            linearGradient: {
              x1: 1,
              x2: 0,
              y1: 1,
              y2: 0
            },
            stops: [
              [0, "#005C00"],
              [1, "#0AAB0A"]
            ]
            }
        },{
          name:"Driver Cost",
          data:stotal_driver_cost,
          dataLabels:{format:'{point.lblTxt}',enabled:true},
          type:"column",
          tooltip: {
            pointFormat: '<b>{series.name}: INR {point.y}</b>'
          },
          yAxis:0,
          color:{
            linearGradient: {
              x1: 1,
              x2: 0,
              y1: 1,
              y2: 0
            },
            stops: [
              [0, "#022372"],
              [1, "#0D4BDE"]
            ]
}
        })
    //   }
    // });
    // console.log("modelTypeStates ", modelTypeStates)
    var stateline=[]
    if(strucks.length > 0)
    {
        var trucksl = groupBy(strucks, rdata => rdata.name);
        try {
          trucksl.forEach((im, k) => {
            var tot = 0;
            if(im.length > 0)
            {
              im.map((i) =>{
                tot = parseInt(tot)+parseInt(i.trucks)
              })
            }
            stateline.push({
              name:k,
              y:tot
            })
          })
        } catch (error) {
          
        }
        
    }
    modelTypeStates.push({
      name:"Total Trucks",
      data:stateline,
      dataLabels:{enabled:true},
      type:"spline",
      yAxis:1,
      marker: {
        lineWidth: 2,
        fillColor: 'black'
      },
      center: [100, 80],
    })
    this.setState({
      stateChart:modelTypeStates
    })
    modelTypeStates.push({
      name:"Per Trip Truck Cost",
      data:stateseries,
      dataLabels:{enabled:true},
      type:"spline",
      yAxis:0,
      marker: {
        lineWidth: 2,
        fillColor: 'black'
      },
      center: [100, 80],
    })
    this.setState({
      stateChart:modelTypeStates
    })
  }
  getCityWiseChartView(){
    
    var modelTypeZones  = []
    var modelTypeStates = []
    var modelTypeCity = []
    var dealerChartData = []
    var overall_trip_details = this.state.overall_trip_details;
    
    var modeltypes = groupBy(overall_trip_details, rdata => rdata.model);
    modeltypes.forEach(async (mtypes,mKey) => {
      if(mKey !== "" && mKey !== undefined && mKey !== null)
      {   
          //State wise End
          // City Wise Start
          var cityValues = [];
          var citylist = groupBy(mtypes, rdata => rdata.consignee_city);
          citylist.forEach(async (allcity,citykey) => {
            if(citykey !== "")
            {
              var total_transportation_cost_matrix = 0;
              if(allcity.length > 0)
              {
                allcity.map((ti) => {
                  total_transportation_cost_matrix = parseFloat(ti.total_transportation_cost_matrix)+parseFloat(total_transportation_cost_matrix);
                })
              }
              
              cityValues.push({
                  name:citykey,
                  y:total_transportation_cost_matrix,
                  lblTxt:"INR "+total_transportation_cost_matrix,
                })
              // },300);
            }
          })
          
          // stateValues = stateValues.sort(GetSortOrder("orderpos"));
          modelTypeCity.push({
            name:mKey,
            data:cityValues,
            dataLabels:{format:'{point.lblTxt}',enabled:true}
          })
          // Citywise End
        }
      });
    }
    getDealerWiseChartView(){
    
      var modelTypeZones  = []
      var modelTypeStates = []
      var modelTypeCity = []
      var dealerChartData = []
      var overall_trip_details = this.state.overall_trip_details;
      
      var modeltypes = groupBy(overall_trip_details, rdata => rdata.model);
      modeltypes.forEach(async (mtypes,mKey) => {
        if(mKey !== "" && mKey !== undefined && mKey !== null)
        {   
          
          // Dealer Wise Start
          var dealerValues = [];
          var dealerlist = groupBy(mtypes, rdata => rdata.consignee_code);
          dealerlist.forEach(async (alldealer,dealerkey) => {
            if(dealerkey !== "")
            {
              var total_transportation_cost_matrix = 0;
              if(alldealer.length > 0)
              {
                alldealer.map((ti) => {
                  total_transportation_cost_matrix = parseFloat(ti.total_transportation_cost_matrix)+parseFloat(total_transportation_cost_matrix);
                })
              }
              
              dealerValues.push({
                  name:alldealer[0].consignee_name+" - "+alldealer[0].consignee_code,
                  y:total_transportation_cost_matrix,
                  lblTxt:"INR "+total_transportation_cost_matrix,
                })
              // },300);
            }
          })
          
          // stateValues = stateValues.sort(GetSortOrder("orderpos"));
          dealerChartData.push({
            name:mKey,
            data:dealerValues,
            dataLabels:{format:'{point.lblTxt}',enabled:true}
          })
        }
      })

  }
  
  getAllLspWiseChartView(lsp){
    var modelTypeZones  = []
    var modelTypeStates = []
    var modelTypeCity = []
    var dealerChartData = []
    var modelTypeStates = [];
    var stotal_driver_cost = [];
     var stotal_diesel_cost = [];
     var stotal_road_cost = [];
     var stotal_toll_cost = [];
     var stotal_transportation_cost = [];
    var overall_trip_details = this.state.overall_trip_details;
    var strucks = [];
    var stateseries = [];
    // var modeltypes = groupBy(overall_trip_details, rdata => rdata.model);
    // modeltypes.forEach(async (mtypes,mKey) => {
    //   if(mKey !== "" && mKey !== undefined && mKey !== null)
    //   {
        // State Wise Start
        var stateValues = [];
        var statelist = groupBy(overall_trip_details, rdata => rdata.transporter_name);
        // console.log("zoneslist ",mKey+" == "+ zoneslist)
        statelist.forEach(async (allstate,statekey) => {
          if(statekey !== "")
          {
            // stateCategory.push({state_name:statekey});
            var total_transportation_cost_matrix = 0;
            var diesel_cost_matrix = 0;
            var driver_cost_matrix = 0;
            var road_cost_matrix = 0;
            var toll_cost_matrix = 0;
            if(allstate.length > 0)
            {
              allstate.map((ti) => {
                total_transportation_cost_matrix = (![NaN , null , undefined , "undefined" , ""].includes(ti.total_transportation_cost_matrix) ? parseFloat(ti.total_transportation_cost_matrix) : 0)+total_transportation_cost_matrix
                if(ti.diesel_cost_matrix !== "" && ti.diesel_cost_matrix !== null && ti.diesel_cost_matrix !== undefined)
                {
                  diesel_cost_matrix = parseFloat(ti.diesel_cost_matrix)+parseFloat(diesel_cost_matrix);
                }
                if(ti.driver_cost_matrix !== "" && ti.driver_cost_matrix !== null && ti.driver_cost_matrix !== undefined)
                {
                  driver_cost_matrix = parseFloat(ti.driver_cost_matrix)+parseFloat(driver_cost_matrix);
                }
                if(ti.road_cost_matrix !== "" && ti.road_cost_matrix !== null && ti.road_cost_matrix !== undefined)
                {
                  road_cost_matrix = parseFloat(ti.road_cost_matrix)+parseFloat(road_cost_matrix);
                }
                if(ti.toll_cost_matrix !== "" && ti.toll_cost_matrix !== null && ti.toll_cost_matrix !== undefined)
                {
                  toll_cost_matrix = parseFloat(ti.toll_cost_matrix)+parseFloat(toll_cost_matrix);
                }
              })
            }
            var stateseriesperrate = parseInt(parseFloat(total_transportation_cost_matrix)/parseInt(allstate.length));
            stateseries.push({
              name:statekey,
              y:stateseriesperrate,
              lblTxt:"INR "+stateseriesperrate,
            })
            stotal_transportation_cost.push({
              name:statekey,
              y:total_transportation_cost_matrix,
              lblTxt:"INR "+total_transportation_cost_matrix,
            })
            stotal_diesel_cost.push({
              name:statekey,
              y:diesel_cost_matrix,
              lblTxt:"INR "+diesel_cost_matrix,
            })
            stotal_driver_cost.push({
              name:statekey,
              y:driver_cost_matrix,
              lblTxt:"INR "+driver_cost_matrix,
            })
            stotal_road_cost.push({
              name:statekey,
              y:road_cost_matrix,
              lblTxt:"INR "+road_cost_matrix,
            })
            stotal_toll_cost.push({
              name:statekey,
              y:toll_cost_matrix,
              lblTxt:"INR "+toll_cost_matrix,
            })
            stateValues.push({
                name:statekey,
                y:total_transportation_cost_matrix,
                lblTxt:"INR "+total_transportation_cost_matrix,
                drilldown:statekey
              })
              
              strucks.push({
                name:statekey,
                trucks:allstate.length
              })
            // },300);
          }
          
        })
        
        // stateValues = stateValues.sort(GetSortOrder("orderpos"));
        modelTypeStates.push({
          name:"Toll Cost",
          data:stotal_toll_cost,
          dataLabels:{format:'{point.lblTxt}',enabled:true},
          type:"column",
          tooltip: {
            pointFormat: '<b>{series.name}: INR {point.y}</b>'
          },
          yAxis:0,
          color:{
            linearGradient: {
              x1: 1,
              x2: 0,
              y1: 1,
              y2: 0
            },
            stops: [
              [0, "#ff0000"],
              [1, "#B60D05"]
            ]
            }
        },{
          name:"Road Cost",
          data:stotal_road_cost,
          dataLabels:{format:'{point.lblTxt}',enabled:true},
          type:"column",
          tooltip: {
            pointFormat: '<b>{series.name}: INR {point.y}</b>'
          },
          yAxis:0,
          color:{
            linearGradient: {
              x1: 1,
              x2: 0,
              y1: 1,
              y2: 0
            },
            stops: [
              [0, "#A2A704"],
              [1, "#DDE308"]
            ]
          }
        },{
          name:"Diesel Cost",
          data:stotal_diesel_cost,
          dataLabels:{format:'{point.lblTxt}',enabled:true},
          type:"column",
          tooltip: {
            pointFormat: '<b>{series.name}:INR {point.y}</b>'
          },
          yAxis:0,
          color:{
            linearGradient: {
              x1: 1,
              x2: 0,
              y1: 1,
              y2: 0
            },
            stops: [
              [0, "#005C00"],
              [1, "#0AAB0A"]
            ]
            }
        },{
          name:"Driver Cost",
          data:stotal_driver_cost,
          dataLabels:{format:'{point.lblTxt}',enabled:true},
          type:"column",
          tooltip: {
            pointFormat: '<b>{series.name}:INR {point.y}</b>'
          },
          yAxis:0,
          color:{
            linearGradient: {
              x1: 1,
              x2: 0,
              y1: 1,
              y2: 0
            },
            stops: [
              [0, "#022372"],
              [1, "#0D4BDE"]
            ]
}
        })
    //   }
    // });
    // console.log("modelTypeStates ", modelTypeStates)
    var stateline=[]
    if(strucks.length > 0)
    {
        var trucksl = groupBy(strucks, rdata => rdata.name);
        try {
          trucksl.forEach((im, k) => {
            var tot = 0;
            if(im.length > 0)
            {
              im.map((i) =>{
                tot = parseInt(tot)+parseInt(i.trucks)
              })
            }
            stateline.push({
              name:k,
              y:tot
            })
          })
        } catch (error) {
          
        }
        
    }
    modelTypeStates.push({
      name:"Total Trucks",
      data:stateline,
      dataLabels:{enabled:true},
      type:"spline",
      yAxis:1,
      marker: {
        lineWidth: 2,
        fillColor: 'black'
      },
      center: [100, 80],
    })
    this.setState({
      lspChart:modelTypeStates
    })
    modelTypeStates.push({
      name:"Per Trip Truck Cost",
      data:stateseries,
      dataLabels:{enabled:true},
      type:"spline",
      yAxis:0,
      marker: {
        lineWidth: 2,
        fillColor: 'black'
      },
      center: [100, 80],
    })
    this.setState({
      lspChart:modelTypeStates
    })
  }
  
  getLspWiseChartView(lsp){
    // console.log("here ",lsp)
    var modelTypeZones  = []
    var modelTypeStates = []
    var modelTypeCity = []
    var dealerChartData = []
    var modelTypeStates = [];
    var stotal_driver_cost = [];
     var stotal_diesel_cost = [];
     var stotal_road_cost = [];
     var stotal_toll_cost = [];
     var stotal_transportation_cost= [];
    var overall_trip_details = this.state.overall_trip_details;
    var strucks = []
    var stateserieslsp = []
    // var modeltypes = groupBy(overall_trip_details, rdata => rdata.model);
    // modeltypes.forEach(async (mtypes,mKey) => {
    //   if(mKey !== "" && mKey !== undefined && mKey !== null)
    //   {
        // State Wise Start
        var stateValues = [];
        var statelist = groupBy(overall_trip_details, rdata => rdata.transporter_name);
        // console.log("lspstatelist   == ", statelist)
        statelist.forEach(async (allstate,statekey) => {
          // console.log(statekey +"==="+ lsp)
          if(statekey !== "" && statekey === lsp)
          {
            // stateCategory.push({state_name:statekey});
            var total_transportation_cost_matrix = 0;
            var diesel_cost_matrix = 0;
            var driver_cost_matrix = 0;
            var road_cost_matrix = 0;
            var toll_cost_matrix = 0;
            if(allstate.length > 0)
            {
              allstate.map((ti) => {
                total_transportation_cost_matrix = (![NaN , null , undefined , "undefined" , ""].includes(ti.total_transportation_cost_matrix) ? parseFloat(ti.total_transportation_cost_matrix) : 0)+total_transportation_cost_matrix
                if(ti.diesel_cost_matrix !== "" && ti.diesel_cost_matrix !== null && ti.diesel_cost_matrix !== undefined)
                {
                  diesel_cost_matrix = parseFloat(ti.diesel_cost_matrix)+parseFloat(diesel_cost_matrix);
                }
                if(ti.driver_cost_matrix !== "" && ti.driver_cost_matrix !== null && ti.driver_cost_matrix !== undefined)
                {
                  driver_cost_matrix = parseFloat(ti.driver_cost_matrix)+parseFloat(driver_cost_matrix);
                }
                if(ti.road_cost_matrix !== "" && ti.road_cost_matrix !== null && ti.road_cost_matrix !== undefined)
                {
                  road_cost_matrix = parseFloat(ti.road_cost_matrix)+parseFloat(road_cost_matrix);
                }
                if(ti.toll_cost_matrix !== "" && ti.toll_cost_matrix !== null && ti.toll_cost_matrix !== undefined)
                {
                  toll_cost_matrix = parseFloat(ti.toll_cost_matrix)+parseFloat(toll_cost_matrix);
                }
              })
            }
            var stateseriesperrate = parseInt(parseFloat(total_transportation_cost_matrix)/parseInt(allstate.length));
            stateserieslsp.push({
              name:statekey,
              y:stateseriesperrate,
              lblTxt:"INR "+stateseriesperrate,
            })

            stotal_transportation_cost.push({
              name:statekey,
              y:total_transportation_cost_matrix,
              lblTxt:"INR "+total_transportation_cost_matrix,
            })
            stotal_diesel_cost.push({
              name:statekey,
              y:diesel_cost_matrix,
              lblTxt:"INR "+diesel_cost_matrix,
            })
            stotal_driver_cost.push({
              name:statekey,
              y:driver_cost_matrix,
              lblTxt:"INR "+driver_cost_matrix,
            })
            stotal_road_cost.push({
              name:statekey,
              y:road_cost_matrix,
              lblTxt:"INR "+road_cost_matrix,
            })
            stotal_toll_cost.push({
              name:statekey,
              y:toll_cost_matrix,
              lblTxt:"INR "+toll_cost_matrix,
            })
            stateValues.push({
                name:statekey,
                y:total_transportation_cost_matrix,
                lblTxt:"INR "+total_transportation_cost_matrix,
                drilldown:statekey
              })
              
              strucks.push({
                name:statekey,
                trucks:allstate.length
              })
            // },300);
          }
          
        })
        
        // stateValues = stateValues.sort(GetSortOrder("orderpos"));
        modelTypeStates.push({
          name:"Toll Cost",
          data:stotal_toll_cost,
          dataLabels:{format:'{point.lblTxt}',enabled:true},
          type:"column",
          tooltip: {
            pointFormat: '<b>{series.name}: INR {point.y}</b>'
          },
          yAxis:0,
          color:{
            linearGradient: {
              x1: 1,
              x2: 0,
              y1: 1,
              y2: 0
            },
            stops: [
              [0, "#ff0000"],
              [1, "#B60D05"]
            ]
            }
        },{
          name:"Road Cost",
          data:stotal_road_cost,
          dataLabels:{format:'{point.lblTxt}',enabled:true},
          type:"column",
          tooltip: {
            pointFormat: '<b>{series.name}: INR {point.y}</b>'
          },
          yAxis:0,
          color:{
            linearGradient: {
              x1: 1,
              x2: 0,
              y1: 1,
              y2: 0
            },
            stops: [
              [0, "#A2A704"],
              [1, "#DDE308"]
            ]
          }
        },{
          name:"Diesel Cost",
          data:stotal_diesel_cost,
          dataLabels:{format:'{point.lblTxt}',enabled:true},
          type:"column",
          tooltip: {
            pointFormat: '<b>{series.name}: INR {point.y}</b>'
          },
          yAxis:0,
          color:{
            linearGradient: {
              x1: 1,
              x2: 0,
              y1: 1,
              y2: 0
            },
            stops: [
              [0, "#005C00"],
              [1, "#0AAB0A"]
            ]
            }
        },{
          name:"Driver Cost",
          data:stotal_driver_cost,
          dataLabels:{format:'{point.lblTxt}',enabled:true},
          type:"column",
          tooltip: {
            pointFormat: '<b>{series.name}: INR {point.y}</b>'
          },
          yAxis:0,
          color:{
            linearGradient: {
              x1: 1,
              x2: 0,
              y1: 1,
              y2: 0
            },
            stops: [
              [0, "#022372"],
              [1, "#0D4BDE"]
            ]
}
        })
    //   }
    // });
    // console.log("modelTypeStates ", modelTypeStates)
    var stateline=[]
    if(strucks.length > 0)
    {
        var trucksl = groupBy(strucks, rdata => rdata.name);
        try {
          trucksl.forEach((im, k) => {
            var tot = 0;
            if(im.length > 0)
            {
              im.map((i) =>{
                tot = parseInt(tot)+parseInt(i.trucks)
              })
            }
            stateline.push({
              name:k,
              y:tot
            })
          })
        } catch (error) {
          
        }
        
    }
    modelTypeStates.push({
      name:"Total Trucks",
      data:stateline,
      dataLabels:{enabled:true},
      type:"spline",
      yAxis:1,
      marker: {
        lineWidth: 2,
        fillColor: 'black'
      },
      center: [100, 80],
    })
    this.setState({
      lspChart:modelTypeStates
    })
    modelTypeStates.push({
      name:"Per Trip Truck Cost",
      data:stateserieslsp,
      dataLabels:{enabled:true},
      type:"spline",
      yAxis:0,
      marker: {
        lineWidth: 2,
        fillColor: 'black'
      },
      center: [100, 80],
    })
    this.setState({
      lspChart:modelTypeStates
    })
  }
  render() {
    // console.log("this.state.zoneChart",4670)
	  
	    return (
	        <div className="container-fluid">
            <SweetAlert
                  show={this.state.show}
                  type={this.state.basicType}
                  title={this.state.basicTitle}
                  onConfirm={this.closeAlert}
              >
              </SweetAlert>
              <div className="row">
                  
                     
                  <div className="col-xl-12 col-lg-12">
                      {/* <div className="card">                      
                          <div className="card-header"> */}
                              {/* <h5 className="fbold f20">
                                  <i className="icon-map cus-i"></i> <span>Summary Dashboard</span>
                              </h5> */}
                              <h5 className="mb-15p fbold f20">
					                      <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.pagetitle} </span>
 
				                       </h5>
                          {/* </div> */}
                        <div className="card">             
                          <div className=" card-body">
                            <div className="row">
                              <div className="col-sm-12">
                              <form id="filterForm" method="POST" className="row">
                                
                                  <div className="row col-xl-12 col-lg-12" >
                                    <div className="form-group col-xl-2 col-lg-2">
                                      <label>Select Consigner </label>
                                      <Select 
                                          placeholder={"All"} 
                                          closeMenuOnSelect={true} 
                                          value={this.state.consigner} 
                                          isMulti={false} 
                                          className="border-radius-0" 
                                          onChange={this.changeConsignerArrayHandler.bind(this)} 
                                          style={{ borderRadius: "09px" }} 
                                          options={this.selectConsignerOptionsItems()} 
                                          required 
                                        />
                                    </div>
                                    <div className="col-xl-2 col-lg-2 form-group">
                                      <label>From Date</label>
                                      <Datetime
                                        value={this.state.from_date}
                                        disableCloseOnClickOutside={false}
                                        closeOnSelect={true}
                                        inputProps={{ placeholder: 'From', name: 'from_date', autoComplete: 'off', readOnly: true }}
                                        dateFormat="YYYY-MM-DD"
                                        timeFormat = {false}
                                        name="from_date"
                                        onChange={this.handlerStartDateTime.bind(this)} />
                                    </div>

                                    <div className="col-xl-2 col-lg-2 form-group">
                                      <label>To Date</label>
                                      <Datetime
                                        value={this.state.to_date}
                                        disableCloseOnClickOutside={false}
                                        closeOnSelect={true}
                                        inputProps={{ placeholder: 'To', name: 'to_date', autoComplete: 'off', readOnly: true }}
                                        dateFormat="YYYY-MM-DD"
                                        timeFormat = {false}
                                        onChange={this.handlerEndDateTime.bind(this)} />
                                    </div>
                                    <div className="form-group col-xl-2 col-lg-3">
                                      <label>Date Type </label>
                                      <Select
                                        className="border-radius-0"
                                        isMulti={false}
                                        style={{ borderRadius: "0px" }}
                                        value={this.state.date_type}
                                        onChange={this.onChangeDateType.bind(this)}
                                        options={[{value:"ALL", label:"All"},{value:"delivered_date", label:"Delivered Date"},{value:"dispatch_date", label:"Dispatch Date"}]}
                                      />
                                    </div>

                                    <div className="form-group col-xl-3 col-lg-3">
                                      <label>Transporter</label>
                                      <Select
                                        className="border-radius-0"
                                        isMulti={false}
                                        id="selectedCluster"
                                        style={{ borderRadius: "0px" }}
                                        value={this.state.flTransporter}
                                        onChange={this.onChangeTransporter.bind(this)}
                                        options={this.state.filterTransporters}
                                      />
                                    </div>
                                    
                                  </div>

                                <div className="row col-xl-12 col-lg-12" style={{ marginLeft: "0px", padding: "0px" }}>
                                  {(localStorage.getItem("user_type") !== 'DEALER' && localStorage.getItem("user_type") !== 'PARENT_DEALER' && localStorage.getItem("user_type") !== 'ZONE_MANAGER' && localStorage.getItem("user_type") !== 'REGIONAL_MANAGER' && localStorage.getItem("user_type") !== 'AREA_MANAGER') ?
                                  <div className="form-group col-xl-3 col-lg-3">
                                    <label>Dealer Zone</label>
                                    <Select
                                      className="border-radius-0"
                                      isMulti={false}
                                      id="selectedCluster"
                                      style={{ borderRadius: "0px" }}
                                      value={this.state.flZone}
                                      onChange={this.onChangeZone.bind(this)}
                                      options={this.state.filterZones}
                                    />
                                  </div>
                                  : ""}
                                  {(localStorage.getItem("user_type") !== 'DEALER' && localStorage.getItem("user_type") !== 'PARENT_DEALER'  && localStorage.getItem("user_type") !== 'REGIONAL_MANAGER' && localStorage.getItem("user_type") !== 'AREA_MANAGER') ?
                                    <div className="form-group col-xl-3 col-lg-3">
                                      <label>Dealer State</label>
                                      <Select
                                        className="border-radius-0"
                                        isMulti={false}
                                        id="selectedState"
                                        style={{ borderRadius: "0px" }}
                                        value={this.state.flState}
                                        onChange={this.onChangeDealerState.bind(this)}
                                        options={this.state.filterStates}
                                      />
                                    </div>
                                    : ""}
                                  {(localStorage.getItem("user_type") !== 'DEALER' && localStorage.getItem("user_type") !== 'PARENT_DEALER'  && localStorage.getItem("user_type") !== 'AREA_MANAGER') ?
                                    <div className="form-group col-xl-3 col-lg-3">
                                      <label>Dealer City</label>
                                      <Select
                                        className="border-radius-0"
                                        isMulti={false}
                                        id="selectedCity"
                                        style={{ borderRadius: "0px" }}
                                        value={this.state.flCity}
                                        onChange={this.onChangeDealerCity.bind(this)}
                                        options={this.state.filterCities}
                                      />
                                    </div>
                                    : ""}
                                    {(localStorage.getItem("user_type") !== 'DEALER' && localStorage.getItem("user_type") !== 'PARENT_DEALER')?
                                    
                                    
                                    <div className="form-group col-xl-3 col-lg-3">
                                      <label>Dealer</label>
                                      <Select
                                        className="border-radius-0"
                                        isMulti={false}
                                        id="selectedDealer"
                                        style={{ borderRadius: "0px" }}
                                        value={this.state.flDealer}
                                        onChange={this.newFilter.bind(this, "flDealer")}
                                        options={this.state.filterDealers}
                                      />
                                    </div>
                                  :""}
                              
                                  <div className="form-group col-xl-12 col-lg-12 float-right" style={{ marginLeft: "0px", textAlign:"right" }}>
                                    <button type="button" onClick={this.reloadPageData.bind(this,"reset")} className="f12 float-right" style={{ color:"#000", textDecoration:"underline",marginTop:"5px", textAlign:"center", border:"0px",background:"transparent",cursor:"pointer"}}>Reset Filters</button>
                                    <button type="button" className="btn btn-success float-right" onClick={this.onSubmitGetData.bind(this)}>Submit</button>
                                
                                  </div>
                                  
                                </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>  
                      {/* </div> */}
                  </div>
                  <div className="col-sm-6">
                    <div className="card">
                      <div className="card-header">
                        <h5 className="fbold">Transit Status</h5>
                      </div>
                      <div className="card-body">
                          {/* <Pie data={this.state.pieChartData} /> */}
                          {/* <div id="pie-chart1" className="chart-overflow"></div> */}
                                <PieChart
                                    series={this.state.pieChartData}
                                    xTitle={""}
                                    
                                />
                        </div>
                    </div>
                  </div>
                  {/* <div className="col-sm-3">
                    <div className="card">
                      <div className="card-header">
                        <h5 className="fbold">Speed Band</h5>
                      </div>
                      <div className="card-body">
                          <BubbleChart
                              series={this.state.bubbleChartData}
                              xTitle={""}
                              
                          />
                        </div>
                    </div>
                  </div> */}
                  <div className="col-sm-6">
                    
                    <div>
                      <div className="row">
                        <div className="col-sm-4 dsh">
                          {/* <div className="card skybluebg">
                            <div className="row">
                              <div className="col-sm-12 u-container">
                                  <div className="u-text-3 mb-10p">
                                    <i className="fa fa-tachometer f35"></i>
                                  </div>
                                  <div className="u-text-3">
                                      Overspeed
                                  </div>
                                  <div className="u-text-4">
                                        {this.state.overspeed.length > 0?
                                          this.state.overspeed[0].count+" ("+this.state.overspeed[0].percentage+"%)"
                                      :0}
                                  </div>
                              </div>
                            </div>  
                          </div>   */}

                            <div class="o-hidden card">
                              <div class="bg-warning b-r-4 card-body">
                                  <div class="media static-top-widget d-flex">
                                      {/* <div class="align-self-center text-center">
                                          <i className="icofont icofont-vehicle-delivery-van"></i>
                                      </div> */}
                                      <div class="media-body">
                                          <h4 class="counter mb-0 f40"><span>
                                          {this.state.overspeed.length > 0?
                                              this.state.overspeed[0].count+" ("+this.state.overspeed[0].percentage+"%)"
                                          :0}
                                          </span></h4>
                                          <span class="m-0 f18">Overspeed</span>
                                          <i class="icofont icofont-dashboard icon-bg">

                                          </i>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          
                        </div>  
                          
                          <div className="col-sm-4 dsh">
                            
                            <div class="o-hidden card">
                              <div class="bg-primary b-r-4 card-body">
                                  <div class="media static-top-widget d-flex">
                                      {/* <div class="align-self-center text-center">
                                          <i className="icofont icofont-vehicle-delivery-van"></i>
                                      </div> */}
                                      <div class="media-body">
                                          <h4 class="counter mb-0 f40"><span>
                                          {this.state.nightdriving.length > 0?
                                              this.state.nightdriving[0].count+" ("+this.state.nightdriving[0].percentage+"%)"
                                          :0}
                                          </span></h4>
                                          <span class="m-0 f18">Night Driving</span>
                                          <i class="icofont icofont-full-night icon-bg">

                                          </i>
                                      </div>
                                  </div>
                              </div>
                          </div>
                            {/* <div className="card yellow">
                              <div className="row">
                                <div className="col-sm-12 u-container">
                                    <div className="u-text-3 mb-10p">
                                      <i className="icofont icofont-full-night f35"></i>
                                    </div>
                                    <div className="u-text-3">
                                        Night Driving
                                    </div>
                                    <div className="u-text-4">
                                        {this.state.nightdriving.length > 0?
                                          this.state.nightdriving[0].count+" ("+this.state.nightdriving[0].percentage+"%)"
                                      :0}
                                    </div>
                                </div>
                              </div>  
                            </div>   */}
                          </div>  
                          
                          <div className="col-sm-4 dsh">
                             
                            <div class="o-hidden card">
                              <div class="bg-danger b-r-4 card-body">
                                  <div class="media static-top-widget d-flex">
                                      {/* <div class="align-self-center text-center">
                                          <i className="icofont icofont-vehicle-delivery-van"></i>
                                      </div> */}
                                      <div class="media-body">
                                          <h4 class="counter mb-0 f40"><span>
                                          {this.state.delay.length > 0?
                                              this.state.delay[0].count+" ("+this.state.delay[0].percentage+"%)"
                                          :0}
                                          </span></h4>
                                          <span class="m-0 f18">Delay</span>
                                          <i class="icofont icofont-ui-clock icon-bg">

                                          </i>
                                      </div>
                                  </div>
                              </div>
                            </div>
                            {/* <div className="card colorForDangerMedium">
                              <div className="row">
                                <div className="col-sm-12 u-container">
                                  <div className="u-text-3 mb-10p">
                                    <i className="icofont icofont-clock-time f35"></i>
                                  </div>
                                    <div className="u-text-3">
                                        Delay
                                    </div>
                                    <div className="u-text-4">
                                      {this.state.delay.length > 0?
                                          this.state.delay[0].count+" ("+this.state.delay[0].percentage+"%)"
                                      :0}
                                    </div>
                                </div>
                              </div>  
                            </div>   */}
                          </div>  
                          
                          <div className="col-sm-4 dsh">
                            
                            <div class="o-hidden card">
                                <div class="bg-success b-r-4 card-body">
                                    <div class="media static-top-widget d-flex">
                                        {/* <div class="align-self-center text-center">
                                            <i className="icofont icofont-vehicle-delivery-van"></i>
                                        </div> */}
                                        <div class="media-body">
                                            <h4 class="counter mb-0 f40"><span>
                                            {this.state.ontime.length > 0?
                                                this.state.ontime[0].count+" ("+this.state.ontime[0].percentage+"%)"
                                            :0}
                                            </span></h4>
                                            <span class="m-0 f18">On-Time</span>
                                            <i class="icofont icofont-sand-clock icon-bg">

                                            </i>
                                        </div>
                                    </div>
                                </div>
                              </div>
                            {/* <div className="card greenbg">
                              <div className="row">
                                <div className="col-sm-12 u-container">
                                  <div className="u-text-3 mb-10p">
                                    <i className="fa fa-check f35"></i>
                                  </div>
                                    <div className="u-text-3">
                                        On-Time
                                    </div>
                                    <div className="u-text-4">
                                      
                                      {this.state.ontime.length > 0?
                                          this.state.ontime[0].count+" ("+this.state.ontime[0].percentage+"%)"
                                      :0}
                                    </div>
                                </div>
                              </div>  
                            </div>  */}

                          </div>  
                          
                          <div className="col-sm-4 dsh">

                          <div class="o-hidden card">
                              <div class="bg-secondary b-r-4 card-body">
                                  <div class="media static-top-widget d-flex">
                                      {/* <div class="align-self-center text-center">
                                          <i className="icofont icofont-vehicle-delivery-van"></i>
                                      </div> */}
                                      <div class="media-body">
                                          <h4 class="counter mb-0 f40"><span>
                                          {this.state.accident.length > 0?
                                              this.state.accident[0].count+" ("+this.state.accident[0].percentage+"%)"
                                          :0}
                                          </span></h4>
                                          <span class="m-0 f18">Accident</span>
                                          <i class="icofont icofont-vehicle-delivery-van icon-bg">

                                          </i>
                                      </div>
                                  </div>
                              </div>
                            </div>

                            {/* <div className="card pinkgbg">
                              <div className="row">
                                <div className="col-sm-12 u-container">
                                  <div className="u-text-3 mb-10p">
                                    <img src={require("../../assets/icons/car-crash.png")} style={{width:"38px"}} />
                                  </div>
                                    <div className="u-text-3">
                                        Accident
                                    </div>
                                    <div className="u-text-4">
                                      {this.state.accident.length > 0?
                                          this.state.accident[0].count+" ("+this.state.accident[0].percentage+"%)"
                                      :0}
                                    </div>
                                </div>
                              </div>  
                            </div>   */}
                          </div>  
                          
                          <div className="col-sm-4 dsh">
                            
                          <div class="o-hidden card">
                              <div class="bg-default2 b-r-4 card-body">
                                  <div class="media static-top-widget d-flex">
                                      {/* <div class="align-self-center text-center">
                                          <i className="icofont icofont-vehicle-delivery-van"></i>
                                      </div> */}
                                      <div class="media-body">
                                          <h4 class="counter mb-0 f40"><span>
                                          {this.state.pod_without.length > 0?
                                              this.state.pod_without[0].count+" ("+this.state.pod_without[0].percentage+"%)"
                                          :0}
                                          </span></h4>
                                          <span class="m-0 f18">POD <span className="f13">(No of clean)</span></span>
                                          <i class="icofont icofont-law-document icon-bg">

                                          </i>
                                      </div>
                                  </div>
                              </div>
                            </div>

                            {/* <div className="card purplebg">
                              <div className="row">
                                <div className="col-sm-12 u-container ">
                                  <div className="u-text-3 mb-10p">
                                    <i className="fa fa-file f35"></i>
                                  </div>
                                    <div className="u-text-3">
                                        POD <span>(No of clean)</span>
                                    </div>
                                    <div className="u-text-4">
                                      {this.state.pod_without.length > 0?
                                          this.state.pod_without[0].count+" ("+this.state.pod_without[0].percentage+"%)"
                                      :0}
                                    </div>
                                </div>
                              </div>  
                            </div>   */}
                          </div>  

                      </div>
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="card">
                      <div className="card-header">
                        <h5 className="fbold">Vehicles Location</h5>
                      </div>
                      <div className="card-body">
                          <div className="row" id="map_canvas" style={{width:"100%",height:"400px", marginLeft:"0px"}}></div>
                      </div>
                    </div>
                  </div>
                  {this.state.zoneChart.length > 0?
                  <div className="col-sm-6">
                    <div className="card">
                      <div className="card-header">
                        <h5 className="fbold">Zone wise - Transportation Cost</h5>
                      </div>
                      <div className="card-body">
                          <StackGroupChart
                            series={this.state.zoneChart}
                            xTitle={""}
                            categories={this.state.zoneCats}
                          />
                      </div>
                    </div>
                  </div>
                  :""}
                  {this.state.stateChart.length > 0?
                  <div className="col-sm-12">
                    <div className="card">
                      <div className="card-header">
                        <h5 className="fbold">State wise - Transportation Cost
                            <div style={{width:"50%",position:"absolute",top:"0", right:"40px"}}>
                              <div className="row mt-10p f12">
                                <div className="col-sm-3">
                                  Type:
                                  <Select
                                      className="border-radius-0 f12"
                                      isMulti={false}
                                      id="selectedType"
                                      style={{ borderRadius: "0px" }}
                                      value={this.state.cType}
                                      onChange={this.onChangeChartType.bind(this)}
                                      options={[{value:"",label:"All"},{value:"HDT",label:"HDT"},{value:"MDT",label:"MDT"}]}
                                    />
                                </div>
                                <div className="col-sm-3">
                                  State:
                                  <Select
                                    className="border-radius-0 f12"
                                    isMulti={false}
                                    id="selectedState"
                                    style={{ borderRadius: "0px" }}
                                    value={this.state.cState}
                                    onChange={this.onChangeChartDealerState.bind(this)}
                                    options={this.state.chartStates}
                                  />
                                </div>
                                <div className="col-sm-3">
                                  City:
                                  <Select
                                      className="border-radius-0 f12"
                                      isMulti={false}
                                      id="selectedCity"
                                      style={{ borderRadius: "0px" }}
                                      value={this.state.cCity}
                                      onChange={this.onChangeChartDealerCity.bind(this)}
                                      options={this.state.chartCities}
                                    />
                                </div>
                                
                                <div className="col-sm-3" >
                                Dealer:
                                  <Select
                                      className="border-radius-0 f12"
                                      isMulti={false}
                                      id="selectedDealer"
                                      style={{ borderRadius: "0px"}}
                                      value={this.state.cDealer}
                                      onChange={this.onChangeChartDealer.bind(this)}
                                      options={this.state.chartDealers}
                                    />
                                </div>
                               
                              </div>
                            </div>
                        </h5>
                      </div>
                      <div className="card-body" style={{marginTop:"20px"}}>
                          <StackGroupChart
                            series={this.state.stateChart}
                            lineseries = {this.state.stateslineChart}
                            xTitle={""}
                            drilldown={this.state.statedrilldown}
                            // categories={this.state.stateCats}
                          />
                      </div>
                    </div>
                  </div>
                  :""}

                  {/* <div className="col-sm-12">
                    <div className="card">
                      <div className="card-header">
                        <h5 className="fbold">City wise - Transportation Cost</h5>
                      </div>
                      <div className="card-body">
                          <StackGroupChart
                            series={this.state.cityChart}
                            xTitle={""}
                            // categories={this.state.stateCats}
                          />
                      </div>
                    </div>
                  </div> */}
                  
                  
                  {/* <div className="col-sm-12">
                    <div className="card">
                      <div className="card-header">
                        <h5 className="fbold">Dealer wise - Transportation Cost</h5>
                      </div>
                      <div className="card-body">
                          <StackGroupChart
                            series={this.state.dealerChart}
                            xTitle={""}
                            drilldown={[]}
                            // categories={this.state.stateCats}
                          />
                      </div>
                    </div>
                  </div> */}
                  
                  {this.state.lspChart.length > 0?
                  <div className="col-sm-12">
                    <div className="card">
                      <div className="card-header">
                        <h5 className="fbold">Transporter wise - Transportation Cost</h5>
                        <div style={{width:"50%",position:"absolute",top:"0", right:"40px"}}>
                              <div className="row mt-10p f12">
                                <div className="col-sm-3">
                                  Type:
                                  <Select
                                      className="border-radius-0 f12"
                                      isMulti={false}
                                      id="selectedType"
                                      style={{ borderRadius: "0px" }}
                                      value={this.state.cLspType}
                                      onChange={this.onChangeLspChartType.bind(this)}
                                      options={[{value:"",label:"All"},{value:"HDT",label:"HDT"},{value:"MDT",label:"MDT"}]}
                                    />
                                </div>
                                <div className="col-sm-3">
                                  Transporters:
                                  <Select
                                    className="border-radius-0 f12"
                                    isMulti={false}
                                    id="selectedLsp"
                                    style={{ borderRadius: "0px" }}
                                    value={this.state.cLsp}
                                    onChange={this.onChangeLSPData.bind(this)}
                                    options={this.state.chartLsps}
                                  />
                                </div>
                               
                              </div>
                            </div>
                      </div>
                      <div className="card-body">
                          <StackGroupChart
                            series={this.state.lspChart}
                            xTitle={""}
                            
                            drilldown={[]}
                            // categories={this.state.stateCats}
                          />
                      </div>
                    </div>
                  </div>
                  :""}
                  
              </div>
              <div className={"dataLoadpage " +(this.state.loadshow)}>
              </div>
              <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                  <div className="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
              </div>
          </div>
	    );
	  }
}

function getFromLS(key) {
    let ls = {};
    if (global.localStorage) {
      try {
        ls = JSON.parse(global.localStorage.getItem("rgl-8")) || {};
      } 
      catch (e) {
      /*Ignore*/
      }
    }
    return ls[key];
}

function saveToLS(key, value) {
  if (global.localStorage) {
    global.localStorage.setItem(
      "rgl-8",
      JSON.stringify({
        [key]: value
      })
    );
  }
}

function refActions()
{
	alert("helloo")
}

function loadScript(url){
  var index  =window.document.getElementsByTagName("script")[0]
  var script = window.document.createElement("script")
  script.src=url
  script.async=true
  script.defer = true
  index.parentNode.insertBefore(script,index)
}


function groupBy(list, keyGetter) {
  const map11 = new Map();
  list.forEach((item) => {
       const key = keyGetter(item);
       const collection = map11.get(key);
       if (!collection) {
           map11.set(key, [item]);
       } else {
           collection.push(item);
       }
  });
  return map11;
}

function GetSortOrder(prop) {    
	return function(a, b) {    
		if (a[prop] > b[prop]) {    
			return 1;    
		} else if (a[prop] < b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 

function GetSortDescOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 


                                
                                
                                
                                
                                
                                