/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import Grid from '../layouts/gridActionComponent';
import { getHyphenDDMMMYYYY, getHyphenYYYYMMDD } from '../common/utils';
var redirectURL = require('../redirectURL');

export default class DieselCostRange extends Component {

    constructor(props) {
        super(props);

        this.state = {
			sliderTranslate:"",
			overly:"show-n",
			loadshow:"show-n",
			rowData:[],
			sliderEditTranslate:'',
			editRowID:'',
        };
	}

    componentDidMount(){
		//loadDateTimeScript();
		$("#dieselfromDate").datepicker({
			dateFormat:"yy-mm-dd",
			onClose: function(selectedDate) {
				const date = new Date(selectedDate);
						date.setDate(date.getDate() + 6);
				$("#dieseltoDate").datepicker("option", "minDate", selectedDate);
				$("#dieseltoDate").datepicker("option", "maxDate", date);
			}
		});
	
		$("#dieseltoDate").datepicker({
			dateFormat:"yy-mm-dd",
			"minDate":1
		});
		$("#dieselEditfromDate").datepicker({
			dateFormat:"yy-mm-dd",
			onClose: function(selectedDate) {
				const date = new Date(selectedDate);
						date.setDate(date.getDate() + 6);
				$("#dieselEdittoDate").datepicker("option", "minDate", selectedDate);
				$("#dieselEdittoDate").datepicker("option", "maxDate", date);
			}
		});
	
		$("#dieselEdittoDate").datepicker({
			dateFormat:"yy-mm-dd",
			"minDate":1
		});
		this.loadDieselCosts();
    }
	loadDieselCosts()
	{
		redirectURL.post("/dashboard/dieselcostsdata")
		.then((response) => {
			var records = []
			records = response.data.records;
			this.setState({
				rowData:records
			})
		})
	}
	onClickShowAddForm(){
		this.setState({
			sliderTranslate:"slider-translate-30p",
			overly:"show-m"
		})
	}
	onClickHideAll(){
		this.setState({
			sliderTranslate:"",
			sliderEditTranslate:'',
			overly:"show-n",
			loadshow:"show-n"
		})
	}
	formAddDieselCost(event)
	{
		event.preventDefault();
		var from_date = $("#dieselfromDate").val();
		var to_date = $("#dieseltoDate").val();
		var params ={
			from_date:from_date,
			to_date:to_date,
			diesel_cost:this.state.diesel_cost
		}
		console.log("dparasn ", params)
		redirectURL.post("/dashboard/saveDieselRangeData",params)
		.then((response) => {
			this.setState({
				sliderTranslate:"",
				overly:"show-n"

			})
			$("#dieselfromDate").val('');
			$("#dieseltoDate").val('');
			this.loadDieselCosts()
		})
	}
	changeHandler(event){
		var name = event.target.name;
		var value = event.target.value;
		this.setState({
			[name]:value
		})
	}
	onClickShowEditDataForm(rownode){
		console.log("rownode ", rownode)
		var fdate = getHyphenYYYYMMDD(rownode.from_date)
		var tdate = getHyphenYYYYMMDD(rownode.to_date)
		$("#dieselEditfromDate").val(fdate);
		$("#dieselEdittoDate").val(tdate);
		this.setState({
			sliderEditTranslate:"slider-translate-30p",
			overly:"show-m",
			diesel_cost:rownode.diesel_cost,
			editRowID:rownode._id

		})
	}
	
	formUpdateDieselCost(event)
	{
		event.preventDefault();
		var from_date = $("#dieselEditfromDate").val();
		var to_date = $("#dieselEdittoDate").val();
		var params ={
			from_date:from_date,
			to_date:to_date,
			diesel_cost:this.state.diesel_cost,
			rowid:this.state.editRowID
		}
		console.log("dparasn ", params)
		redirectURL.post("/dashboard/updateDieselRangeData",params)
		.then((response) => {
			this.setState({
				sliderEditTranslate:"",
				overly:"show-n",
				diesel_cost:'',
				editRowID:''

			})
			$("#dieselEditfromDate").val('');
			$("#dieselEdittoDate").val('');
			this.loadDieselCosts()
		})
	}
    render(){
		const columnwithDefs = [
	  	        {
	    	          headerName: "From Date",
	    	          field: "from_date",
	    	          width: 180,
					  valueGetter:function(params){
						try {
							if(params.data.from_date !== "" && params.data.from_date !== undefined)
							{
								return getHyphenDDMMMYYYY(params.data.from_date);
							}
							else{
								return "";
							}
						} catch (error) {
							
						}
					  }

    	        },
    	    
                {
                    headerName: "To Date",
                    field: "to_date",
                    width: 180,
					valueGetter:function(params){
					  try {
						  if(params.data.to_date !== "" && params.data.to_date !== undefined)
						  {
							  return getHyphenDDMMMYYYY(params.data.to_date);
						  }
						  else{
							  return "";
						  }
					  } catch (error) {
						  
					  }
					}

                },
                {
                    headerName: "Diesel Cost (INR)",
                    field: "diesel_cost",
                    width: 150
				},
               
		        

	      ];
		  if(localStorage.getItem("user_type") === "APP_ADMIN")
		  {
			columnwithDefs.push({
				headerName: "",
				field: "diesel_cost",
				width: 150,
				cellRendererSelector: function (params) {
					var rendComponent = {
						component: 'EditDieselCost'
					};
					return rendComponent;
				},
			})
		  }
        return (

    		<div className="container-fluid">
					        <div className="mb-15p fbold f20">    
							   <h5>
				       				<i className="icon-map cus-i"></i> <span>Diesel Costs </span>
									{(localStorage.getItem("user_type") === "TRANSPORTER")?
									""	
									:
									<button type="button" onClick={this.onClickShowAddForm.bind(this)} className="btn btn-success pull-right cs-btn"> <i className="icon-plus"></i> Add Diesel Cost</button>
								}
				       			</h5>
							</div>	
            	<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
				   			<div className="card-body">
				   				<Grid 
									actionMethod={"dashboard"} 
									screenurl={"/diesel-costs"} 
									actionCall={"dashboard"} 
									columnwithDefs={columnwithDefs} 
									rowData={this.state.rowData} 
									context={this}	
								/>
				   			</div>
				   		</div>
				   	</div>
				 </div>
				

				<div className={"dataLoadpage " + (this.state.loadshow)}></div>
				<div className={"dataLoadpageimg " + (this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>



				<div className={"slide-r " + (this.state.sliderTranslate)} style={{ overflow: "auto" }}>
					<div className="slide-r-title">
						<h4>
							Add Diesel Cost
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>

						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>

						<div className={"row"} style={{ padding: '5px 0px', width: '100%', overflow: 'hidden', zIndex: '9999', height: "100em" }} >
							<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formAddDieselCost.bind(this)}>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">From Date</label>
									<input readonly type="text"  id="dieselfromDate" name="from_date" className="datepicker form-control" />

								</div>

								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">To Date</label>
									<input readonly type="text"  id="dieseltoDate" name="to_date" className="datepicker form-control" />

								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Diesel Cost</label>
									<input type="text" value={this.state.diesel_cost} id="diesel_cost" name="diesel_cost" onChange={this.changeHandler.bind(this)} className="form-control" />
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<button type="submit" className="btn btn-success">Submit</button>
								</div>
								
							</form>
						</div>
					</div>
				</div>


				<div className={"slide-r " + (this.state.sliderEditTranslate)} style={{ overflow: "auto" }}>
					<div className="slide-r-title">
						<h4>
							Edit Diesel Cost
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>

						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>

						<div className={"row"} style={{ padding: '5px 0px', width: '100%', overflow: 'hidden', zIndex: '9999', height: "100em" }} >
							<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formUpdateDieselCost.bind(this)}>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">From Date</label>
									<input readonly type="text"  id="dieselEditfromDate" name="from_date" className="datepicker form-control" />

								</div>

								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">To Date</label>
									<input readonly type="text"  id="dieselEdittoDate" name="to_date" className="datepicker form-control" />

								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Diesel Cost</label>
									<input type="text" value={this.state.diesel_cost} id="diesel_cost" name="diesel_cost" onChange={this.changeHandler.bind(this)} className="form-control" />
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<button type="submit" className="btn btn-success">Submit</button>
								</div>
								
							</form>
						</div>
					</div>
				</div>

            </div>

        );
    }
}
loadDateTimeScript();
function loadDateTimeScript() {
	$("#dieselfromDate").datepicker({
		onClose: function(selectedDate) {
			const date = new Date(selectedDate);
					date.setDate(date.getDate() + 7);
			$("#dieseltoDate").datepicker("option", "maxDate", date);
		}
	});

	$("#dieseltoDate").datepicker({
		"minDate":1
	});

}
