
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const TripDeviationIcon = (props) => {
    const handleEventClick = (e) => {
        e.stopPropagation();
        //console.log("Overspeed ",props);
        // props.context.componentParent.onShowDeviationForm(props);
    };
    
   
    return (
        <div>
            <button onClick={handleEventClick} className="custom-btn f16 label label-success" 
            title="Trip Deviation" >
                {
                (props.data.deviation_lifetime !== "" && props.data.deviation_lifetime !== undefined)?
                props.data.deviation_lifetime === 1?
                    <img src={require("../../assets/icons/trip_deviation_red.png")} className="imgwh-ico" />
                :
                
                <img src={require("../../assets/icons/trip_deviation.png")} className="imgwh-ico imgfop" />
                :
                <img src={require("../../assets/icons/trip_deviation.png")} className="imgwh-ico imgfop" />
            }
            </button>
            
        </div>
    );
};

export default TripDeviationIcon;
