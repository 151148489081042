import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import $ from "jquery";
import _ from "lodash";
import MarkerClusterer from "@google/markerclusterer";
import { getDDMMYYYY, 
	getDDMMYYYYHHMMSS, 
	getYYYYMMDD, 
	getYYYYMMDDHHMMSS, 
	getHyphenYYYYMMDDHHMMSS,
	getHyphenDDMMMYYYYHHMM,
	getHyphenYYYYMMDD, 
	getHyphenDDMMYYYYHHMMSS, 
	getHyphenDDMMYYYY,
	getDDMMMYYYYHHMMDefault } from '../common/utils'; 
var redirectURL = require('../redirectURL');

var infoBox = require('../common/google-infowindow');
var oms = require(`npm-overlapping-marker-spiderfier/lib/oms.min`);
var googleAnalytics = require("../common/googleanalytics");

var marker;
var markers = [];
var circle;
var selectlat;
var selectlng
var allcords=[];
var map;
var rad = function(x) {
	return x * Math.PI / 180;
  };
var rlatitude;
var rlongitude;

var fencingLength=0;
var geoFencingArea;
export default class LiveTruckMapComponent extends Component {
    constructor(props) {
		super(props);
		this.displayData = [];
        this.state = {
			pageTitle: "",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			showform:'show-m',
			loadshow:'show-n',
			showrefresh:'show-n',
			sliderTranslate:'',
			showdata:this.displayData,
			radius:'5000',
			rlatitude:'',
			rlongitude:'',
			latitude:'',
			longitude:'',
			defaultradius:0,
			coordinates:[],
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
			contentString:"testing",
			mwidth:"col-xl-12 col-lg-12",
			alldepts:[],
			transportervalue:'ALL',
			alltransporters:[],
			truckstatus:'0',
			kpicode:'0',
			deptcode:'',
			truckNoList:[],
			alltrucks:[],
			truckno:'',
			showTrucksList : "show-n",
			filteredData : [],
			triptype :{"value":1,"label":"ALL"},
			plantLocations : [],
			totalTrucksCount : 0,
			onTripCounter : 0,
			typeCount:0,
			typeText:'',
			total_trucks:0,
            screenType: ""
			
		};
		this.popmarker=this.popmarker.bind(this);
		this.cipher = this.cipher.bind(this);
	}
	cipher = (salt) => {
		const textToChars = text => text.split('').map(c => c.charCodeAt(0));
		const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
		const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);
		try{
			return text => text.split('')
				.map(textToChars)
				.map(applySaltToChar)
				.map(byteHex)
				.join('');
		}
		catch(e){
	
		}
		
	}
	
	componentDidMount(){
	   // console.log("Props ",this.props)
	   var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
        var loc = this.props.location.search;
        var splitloc = loc.split("&")
        //console.log("splitloc ", splitloc)
        var stype = splitloc[0].split("=")
        var type = stype[1]

        var sdept = splitloc[1].split("=")
		var dept = sdept[1]
		
        var sevt = splitloc[2].split("=")
		var slide = sevt[1]
		

        var scevt = splitloc[3].split("=")
		var cslide = scevt[1]
		
		var screen = "laptop";
		if(splitloc.length > 4)
		{
			screen = splitloc[4].split("=")
			screen = screen[1]
			console.log(screen,"screen")
		}
		this.setState({
			screenType: screen
		})
		
       // console.log("Type ", type)
       // console.log("Dept ", dept)

        if(dept == 'sndg')
        {
            var deptcode = "SNDG"
        }
        if(dept == 'prt')
        {
            var deptcode = "LOG-PRT"
        }
        if(dept == 'tnp')
        {
            var deptcode = "LOG-TNP"
        }
        if(dept == 'sc')
        {
            var deptcode = "LOG-SC"
		}
		if(type == 1)
		{
			var typetext = "All";
		}

		if(type == 2)
		{
			var typetext = "On Trip";
		}

		if(type == 5)
		{
			var typetext = "At dealer";
		}

		if(type == 3)
		{
			var typetext = "Empty/Non-MSIL trip";
		}
		this.setState({
			typeText:typetext
		})
        var formdata = {
			radius:10000,
			latitude:"20.73568784876339",
			longitude:"79.38999444931353",
			dept_code:deptcode,
			status:0,
			trip_type : type
		}
		//console.log(formdata);
		//console.log("slide ", slide)
		//console.log("cslide ", cslide)
		rlatitude="20.73568784876339";
		rlongitude="79.38999444931353";
		if(parseInt(slide)-1 == cslide)
		{
			redirectURL.post('/vicinity/getRadiusCoordinates',formdata,{
				headers:{
					'Content-Type':'application/json',
					'Access-Control-Allow-Origin': '*',
					'Access-Control-Allow-Methods': 'PUT, GET, POST, DELETE, OPTIONS',
					'Access-Control-Allow-Headers': "Origin, X-Requested-With, Content-Type, Accept, Authorization"
	
				}
			})
			.then(
				(response) =>{
				//	console.log("type ", type)
				//console.log("response", JSON.parse(response.data.apiData.body));
			
				var markers = JSON.parse(JSON.parse(response.data.apiData.body).trucks_data);
				var total_trucks = JSON.parse(JSON.parse(response.data.apiData.body).total_trucks);
				//console.log(JSON.parse(JSON.parse(response.data.body).trucks_data))
	
				if(markers.length > 0)
				{
					this.setState({
						defaultradius:formdata.radius,
						coordinates:markers,
						onTripCounter : JSON.parse(response.data.apiData.body).gps_trucks,
						mwidth:"col-xl-12 col-lg-12",
						rowData:markers,
						radius:formdata.radius,
						loadshow:'show-n',
						showform:'show-n',
						showrefresh:'show-m',
						rlatitude:formdata.latitude,
						rlongitude:formdata.longitude,
						typeCount:markers.length,
						total_trucks:total_trucks
					});
					redirectURL.post("/weather/getPlants").then((rsp)=>{
						this.setState({
							plantLocations : rsp.data,
						});
						//console.log(rsp.data);
						this.renderMap();
						})
					
					
				}
					// else{
					// 	this.setState({
					// 		show: true, basicType:'warning', basicTitle:"No Data found.",
					// 		loadshow:'show-n',
					// 		showform:'show-m',
					// 	});
						
					// }
			})
			.catch(function(error){
				console.log(error);
			});
			
			this.renderMap();
		}
		


    }

    // componentWillReceiveProps(newprops){
	// 	this.setState({
    //         coordinates:newprops.coordinates,
    //         rlongitude:newprops.rlongitude,
    //         rlatitude:newprops.rlatitude,
    //         plantLocations:newprops.plantLocations
    //     })
	// 	this.renderMap();
		
	// }

	renderMap = () => {
    	
		loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyD1AFWPoJqPaemwGO4A7P7Q6CsUJ1TLGJk&libraries=places,drawing&callback=initMap");
		window.initMap = this.initMap
 	}
	
	 initMap = () => {
		var allcords=[];
		this.state.coordinates.map((marker) => {
			allcords.push(marker);
		});
		
		var contentdata = this.state.contentString;
		if(this.state.rlatitude == '' && this.state.rlongitude == '')
		{
			var lt=21.144644112601775;
			var ln=79.08860126768066;
		}
		else{
			var lt=this.state.rlatitude;
			var ln=this.state.rlongitude;
		}
		try{
			map = new window.google.maps.Map(document.getElementById('map_canvas'), {
				zoom: 4,
				center: new window.google.maps.LatLng(lt,ln),
				mapTypeId: window.google.maps.MapTypeId.ROADMAP,
				/*mapTypeControl: true,
				gestureHandling: 'greedy',
				mapTypeControlOptions: {
					style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
					position: window.google.maps.ControlPosition.TOP_RIGHT
				  },            
				disableDefaultUI: true,
				zoomControl: true*/
			});
		}
		catch(e){
			map = new window.google.maps.Map(document.getElementById('map_canvas'), {
				zoom: 4,
				center: new window.google.maps.LatLng(parseFloat(21.144644112601775),parseFloat(79.08860126768066)),
				mapTypeId: window.google.maps.MapTypeId.ROADMAP,
				/*mapTypeControl: true,
				gestureHandling: 'greedy',
				mapTypeControlOptions: {
					style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
					position: window.google.maps.ControlPosition.TOP_RIGHT
				  },            
				disableDefaultUI: true,
				zoomControl: true*/
			});
		}
    	
		circle = new window.google.maps.Circle({
            map: map
		});
		
		marker = new window.google.maps.Marker({
			map: map,
			draggable: true,
			animation: window.google.maps.Animation.DROP,
			//position: {lat: 28.48, lng: 77.06}
		});
		
		marker.addListener('click', function(){
			marker.setAnimation(window.google.maps.Animation.BOUNCE)			
		});
		
  		window.google.maps.event.addListener(map, 'click', function(event) {
			
			//document.getElementById("latitude").value = event.latLng.lat();
			//document.getElementById("longitude").value = event.latLng.lng();
			marker.setPosition(event.latLng);
			
		});
		circle.bindTo('center', marker, 'position');
		var markerLatlng1;
		//console.log("Radius ", this.state.radius)
		//console.log(allcords);
		var markers = allcords.map((marker) => {
			// console.log("marker ",marker);
			if(marker.status == 1)
			{
				var truckText =  "Inside Plant";
				var color =  "#333333";
				var image =  require('../../assets/icons/truck_inside_24.png');
			}
			else if(marker.status == 2)
			{
				var truckText =  "Going to Dealer";
				var color =  "#333333";
				var image =  require('../../assets/icons/truck_going_24.png');
			}
			else if(marker.status == 3 || marker.status == 4) 
			{
				var truckText =  "At Dealer Location";
				var color =  "#333333";
				var image =  require('../../assets/icons/truck_atdealer_24.png');
			}
			else if(marker.status == 5)
			{
				var truckText =  "Left Destination Location";
				var color =  "#333333";
				var image =  require('../../assets/icons/truck_leftdealer_24.png');
			}
			else if(marker.status == 6)
			{
				var truckText =  "Return to Plant";
				var color =  "#333333";
				var image =  require('../../assets/icons/truck_return_24.png');
			}
			else if(marker.status == 7)
			{
				var truckText =  "Empty Truck Outside Plant";
				var color =  "#333333";
				var image =  require('../../assets/icons/truck_empty_24.png');
			}
			else{
				var truckText =  marker.truck_no;
				var color =  "#333333";
				var image =  require('../../assets/icons/truck_na_24.png');
			}
			var image =  require('../../assets/icons/truck_na_24.png');
			var contentarr = []
			var headerTitle = truckText
			//contentarr.push({"key":"Truck No", "value":marker.truck_no});
			contentarr.push({"key":"Transporter Name", "value":marker.transporter_name});
			//contentarr.push({"key":"Distance from geofence", "value":marker.distance});
			contentarr.push({"key":"GPS Provider", "value":marker.actual_lspuser});
			contentarr.push({"key":"Last Packet Time", "value":getHyphenDDMMMYYYYHHMM(marker.timestamp)});
			contentarr.push({"key":"Speed (km/h)", "value":Math.ceil(marker.speed)});
			contentarr.push({"key":"Address", "value":marker.area+", "+marker.state});
			
			var contentString = infoBox(image, headerTitle, contentarr, '')

			
			// var contentString = "<table><tr><th>Status: </th><td style='color:"+color+";font-weight:600;text-align: right'>"+truckText+"</td></tr><tr><th>Distance from geofence: </th><td style='text-align: right'>";
			// contentString = contentString + marker.distance+"</td></tr><th>Truck No: </th><td style='text-align: right'>"+marker.truck_no+"</td></tr><tr><th>Name: </th><td style='text-align: right'>";
			// contentString = contentString + marker.transporter_name+"</td></tr><tr><th> LSP Name:</th><td style='text-align: right'>"+marker.lsp_name+"</td></tr><tr><th>Address:</th><td style='text-align: right'>";
			// contentString = contentString  +marker.address+"</td></tr></table>";
			
			//var contentString = "Here "+marker.truck_no;

			//var contentString = infoBox(require('../../assets/icons/truck_inside_24.png'), header, contentarr)

			var infowindow = new window.google.maps.InfoWindow({
				content: contentString
			});
			
			var markerLatlng = new window.google.maps.LatLng(parseFloat(marker.latitude), parseFloat(marker.longitude));
			
			if(marker.status == 1)
			{
				var image =  require('../../assets/icons/truck_inside_24.png');
			}
			else if(marker.status == 2)
			{
				var image =  require('../../assets/icons/truck_going_24.png');
			}
			else if(marker.status == 3 || marker.status == 4) 
			{
				var image =  require('../../assets/icons/truck_atdealer_24.png');
			}
			else if(marker.status == 5)
			{
				var image =  require('../../assets/icons/truck_leftdealer_24.png');
			}
			else if(marker.status == 6)
			{
				var image =  require('../../assets/icons/truck_return_24.png');
			}
			else if(marker.status == 7)
			{
				var image =  require('../../assets/icons/truck_empty_24.png');
			}
			else{
				var image =  require('../../assets/icons/truck_na_24.png');
			}
			var image =  require('../../assets/icons/truck_na_24.png');
			/*
			if(marker.overspeeding_exception == 1)
			{
				var image =  require('../../assets/icons/truck_overspeed.png');
			}
			if(marker.nightdriving_exception == 1)
			{
				var image =  require('../../assets/icons/truck-end.png');
			}
			*/
			
			
			var mark = new window.google.maps.Marker({
                position: markerLatlng,
				map: map,
				title:"Status: "+truckText+" #"+marker.truck_no,
				icon: image
            });
			mark.addListener('click', function() {
				infowindow.open(map, mark);
			  });
			return mark
            mark.setMap(map);
		});
		var eventtrigger = document.getElementsByClassName('truck');
		var markerCluster = new MarkerClusterer(map, markers,
            {imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'});
		  // var searchBox = new window.google.maps.places.SearchBox(eventtrigger);
		  //map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(eventtrigger);
		if(this.state.plantLocations.length > 0){
			this.state.plantLocations.map(function(params){
				var plant = {
				url: require("../../assets/icons/"+params.icon), // url
				scaledSize: new window.google.maps.Size(32,32), // scaled size
				origin: new window.google.maps.Point(0,0), // origin
				anchor: new window.google.maps.Point(0, 0) // anchor
				};
				var coords = JSON.parse(params.coordinates);
				var plantmarker = new window.google.maps.Marker({
					position: new window.google.maps.LatLng(coords.lat,coords.lng),
					map: map,
					icon: plant,
					title : params.plant_name
				});
				// var cityCircle = new window.google.maps.Circle({
				// strokeColor: '#9fc9b9',
				// strokeOpacity: 0.3,
				// strokeWeight: 2,
				// fillColor: '#9fc9b9',
				// fillOpacity: 0.6,
				// map: map,
				// center:coords,
				// radius: 50 * 1000
				// });
			})
		}
		 
	}
	toggleBounce() {
		if (marker.getAnimation() !== null) {
		  marker.setAnimation(null);
		} else {
		  marker.setAnimation(window.google.maps.Animation.BOUNCE);
		}
		
	  }
	
	getDistance = (p1, p2) => {
		//console.log("p1",p1);
		//console.log("p2",p2);
		var R = 6378137; // Earth’s mean radius in meter
		var dLat = rad(p2.lat - p1.lat);
		var dLong = rad(p2.lng - p1.lng);
		var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(rad(p1.lat)) * Math.cos(rad(p2.lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
		var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		var d = R * c;
		return d/1000; // returns the distance in meter
	  };
	onShowInfo = (e) =>{
		console.log(JSON.parse(e.target.id));
		this.setState({
			contentString:"Here Success"
		});
		var ourMarker = e.target.id;
 			window.google.maps.event.trigger(ourMarker, 'click');
		var infowindow = new window.google.maps.InfoWindow({
			content: "Here Success"
		  });
		infowindow.open(map, marker);

	}
	popmarker(e)
	{
		//console.log("OnClick Table ",e.data)
		var lat = parseFloat(e.data.latitude);
		var lng =parseFloat(e.data.longitude);
		var data =e.data;
		map.setCenter(new window.google.maps.LatLng(lat,lng));
		map.setZoom(22);
		var markerLatlng = new window.google.maps.LatLng(lat, lng);
		var image =  require('../../assets/icons/truckblue.png');
		var marker = new window.google.maps.Marker({
			position: markerLatlng,
			map: map,
			title:data.truck_no,
			icon:image
		});
		// var contentString = "Truck No: "+data.truck_no+"<br />Name: "+data.transporter_name+" LSP Name:"+data.lsp_name+"<br />Address:"+data.address;
		// var contentString = "<table><tr><th>Status: </th><td style='text-align: right'>"+data.status;
		// contentString = contentString +"</td></tr><th>Truck No: </th><td style='text-align: right'>"+data.truck_no;
		// contentString = contentString + "</td></tr><tr><th>Name: </th><td style='text-align: right'>"+data.transporter_name+"</td></tr><tr><th> LSP Name:</th><td style='text-align: right'>"+data.lsp_name;
		// contentString = contentString + "</td></tr><tr><th>Address:</th><td style='text-align: right'>"+data.address+"</td></tr></table>";
		
		var contentarr = []
		var headerTitle = data.truck_no
		//contentarr.push({"key":"Truck No", "value":marker.truck_no});
		contentarr.push({"key":"Transporter Name", "value":data.transporter_name});
		//contentarr.push({"key":"Distance from geofence", "value":marker.distance});
		contentarr.push({"key":"GPS Provider", "value":data.actual_lspuser});
		contentarr.push({"key":"Last Packet Time", "value":getHyphenDDMMMYYYYHHMM(data.timestamp)});
		contentarr.push({"key":"Speed (km/h)", "value":Math.ceil(data.speed)});
		contentarr.push({"key":"Address", "value":data.area+", "+data.state});
		
		var contentString = infoBox(image, headerTitle, contentarr, '')
		var infowindow = new window.google.maps.InfoWindow({
			content: contentString
		});
		
		marker.setAnimation(window.google.maps.Animation.DROP)
		marker.addListener('click', function() {
			infowindow.open(map, marker);
		});
		
		marker.setMap(map);
		
		
	}

    render(){
		var screenType = this.state.screenType;
		console.log(screenType,"screenType")
		var screenHeight = (screenType != "laptop")?window.screen.availHeight.toString()+"px":"340px";
			
    	const dStyles={
			width:'100%',
			marginLeft:'0px',
			paddingLeft:'0px',
			height: screenHeight
		}
		const btstye={
			marginBottom:"10px"
		}
		
        return (
            
    		<div className="container-fluid">
				<div className="col-xl-12 n-p-0">
					<h3 className="f16">
						{this.state.typeText} - Total Trucks: <span className="redfont f24">{this.state.total_trucks}</span> / With GPS (Past 24Hrs): <span className="redfont f24">{this.state.typeCount}</span>
						{/* {this.state.typeText} - {this.state.typeCount} */}
				
					</h3>
				</div>
                <div id="map_canvas" style={dStyles}>Loading....</div>
                                   
            </div>
              
        );
    }
}


function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function getPolygonCoords()
{
	
	 var len = geoFencingArea.getPath().getLength();
	 console.log(len);
	 var htmlStr = "";
	 var coordinates=[];
	 for (var i = 0; i < len; i++) {
		// var latlngd = {"lat":parseFloat(each_lat_lng[0]),"lng":parseFloat(each_lat_lng[1])};
		// coordinates.push()
		 var strlatlng = geoFencingArea.getPath().getAt(i).toUrlValue(5);
		 var spliting = strlatlng.split(",");
		 var latlngd = {"lat":parseFloat(spliting[0]),"lng":parseFloat(spliting[1])};
		 coordinates.push(latlngd);
	  // htmlStr += "new google.maps.LatLng(" + geoFencingArea.getPath().getAt(i).toUrlValue(5) + "), ";
	   //Use this one instead if you want to get rid of the wrap > new google.maps.LatLng(),
	   //htmlStr += "" + geoFencingArea.getPath().getAt(i).toUrlValue(5);
	 }
	// console.log("htmlStr", coordinates);
	 
}

