import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import CountUp from 'react-countup';


import _ from "lodash";

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
//import Grid from '../layouts/gridnosidebarComponent';

import CustomLoadingCellRenderer from "../layouts/customLoadingCellRenderer";
import KpiTransitDelayActions from './kpiTransitDelayActions';
import Notransit from './notransitComponent.js';
import ConsignmentActions from './routemapComponent';
import ConsignmentNoActions from './routemapNoComponent';
import ConsignmentImportsEventsAction from './consignmentImportsEventsAction';
import 'react-vertical-timeline-component/style.min.css';
 
var redirectURL = require('../redirectURL');

const fs = require('fs');



 
var drawingManager;
var selectedShape;
var colors = ['#1D9567', '#1692A5', '#F4D749','#F73E3E', '#B7B3B3' ];
var selectedColor;
var colorButtons = {};
var fencingLength=0;
var mapZoom=12;
var coordinates=[];
var geoFencingArea;

export default class ManageImportsConsignmentsFreightForward extends Component {

	constructor(props){
		super(props);
		this.state={
			uploadDivWidth:'0%',
			sliderTranslate:'',
			sliderRouteTranslate:'',
			tabsliderTranslate:'',
			timelineinfo:'',
			consignment_code:'',
			mapinfo:'',
			loadshow:'show-n',
			showDiv:'show-n',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			alerterrshow:'fade',
			alert:null,
            show: false,
            basicTitle:'',
			basicType:"default",
			modules: AllModules,
			filterstatess:{},
      	    columnsdatas:{},
      	    rowgroupdcols:[],
      	    valuecolumns:[],
      	    pivotmodeis:false,
			pivotcols:[],
			defTransitCoords:'',
      	      
      	    defaultColDef: {
      	    	sortable: true,
				filter: true,
				resizable: true,
				

      	    },
      	    rowData: null,
			headerHeight: 40,
			groupHeaderHeight:60,
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
			  frameworkComponents: {
                customLoadingCellRenderer: CustomLoadingCellRenderer,
                kpiTransitDelayActions:KpiTransitDelayActions,
				notransit:Notransit,
				consignmentActions:ConsignmentActions,
				consignmentNoActions:ConsignmentNoActions,
                ConsignmentImportsEventsAction: ConsignmentImportsEventsAction,
			},

      	    animateRows: true,
      	    debug: true,
      	    showToolPanel: false,
			uppressAggFuncInHeader: true,
				
			childconfs: [],
			childrow:[],
			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			rowClassRules: {
				"highlitRow": "data.transit_delay == 1",
				"cellStyleGrid":"columnApi.columnController.columnDefs[0].headerName == 'High Sea Details'"
			}
				
			
		}
	
		this.onShowRouteMap = this.onShowRouteMap.bind(this);
		
	}

	componentDidMount(){
		 /*Consignments List*/	
	    redirectURL.post('/imports/oneconsignments')
		    .then((response) => {
				var records = response.data;
				//console.log("IN componentDidMount records = ", records);
				// find container_no from all records
				const containerNumbersFromImportsConsignments = records.map(obj => obj.container_no);
				//console.log("containerNumbersFromImportsConsignments = ", containerNumbersFromImportsConsignments);
				// prepare query to fetch records for matching container numbers
				let queryByContainerNumbers = {
					"container_no": { $in: containerNumbersFromImportsConsignments}
				};
				//console.log("queryByContainerNumbers ", queryByContainerNumbers)
				//fetch data from inlandcontainerimports
				redirectURL.post('/consignments/getinlandcontainerimportstrackingdetails', {"queryByContainerNumbers":queryByContainerNumbers})
				.then((oneresponse) => {
					var inlandRecords = oneresponse.data;
					
					//console.log("IN componentDidMount oneresponse = ", inlandRecords);
					const inlandRecordsRewritten = inlandRecords.map(obj => {
						// prefix keys of inlandRecords to avoid any overwrite to consignment records
						Object.entries(obj).forEach(
							([key, value]) => {
								let newKey = 'inland_'+key;
								 	obj[newKey] = value;
									//console.log("IN componentDidMount MAPPING obj INNER = ", key, newKey, obj);
									delete(obj[key]) ; // remove original key after renaming
							}
						);

						//console.log("IN componentDidMount MAPPING obj = ", obj);
						return obj;

					});

					// Need a blank object with new keys to merge assign null values to grid columns
					// for container numbers found in importsconsignments but not found a match in inlandcontainerimports
					const inlandRecordsRewrittenBlank = Object.entries(inlandRecordsRewritten[0]).forEach(
						([key, value]) => {
							let blankObj = {};
							let newKey = 'inland_'+key;
							blankObj[newKey] = null; // create a blank object with same new keys
						}
					);
					//console.log("IN componentDidMount MAPPED inlandRecords = ", inlandRecords);
					//console.log("IN componentDidMount MAPPED inlandRecordsRewrite = ", inlandRecordsRewrite);
					// merge both results to prepare grid columns
					for (let i = 0; i < records.length; i++) {
						for (let j = 0; j < inlandRecordsRewritten.length; j++) {
							if (records[i].container_no === inlandRecordsRewritten[j].inland_container_no) {
								records[i] = Object.assign(records[i], inlandRecordsRewritten[j]);
							} else {
								// assign null values for no match
								records[i] = Object.assign(records[i], inlandRecordsRewrittenBlank);
							}
						}
					}
					//console.log("IN componentDidMount MOD records = ", records);
					
					this.setState({rowData:records});
					
				})
				.catch(function(error) {
					console.log(error);
				});
  
		    })
		    .catch(function(error) {
		    	console.log(error);
            });
	};
	
	


	
	onGridReady = params => {
	    this.gridApi = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
	    this.gridColumnApi = params.columnApi;
	
	   //    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
	//    };
	   
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
	}

	renderMap = () => {    	
		loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyD1AFWPoJqPaemwGO4A7P7Q6CsUJ1TLGJk&libraries=places,drawing&callback=initMap");
		window.initMap = this.initMap
	}
	
	initMap = () => {
    	//console.log(this.state.allmarkers);
    	var currentwindow;
    	var jsondata = this.state.mapinfo;
    	var locations = jsondata.points;
        var arr = jsondata.route;
		//console.log("Here",arr);
		if(arr.length > 0)
		{
			var lt=arr[0].lat;
			var ln=arr[0].lng;
		}
		else{
			//console.log("defTransitCoords ", JSON.parse(this.state.defTransitCoords));
			var lt=JSON.parse(this.state.defTransitCoords).lat;
			var ln=JSON.parse(this.state.defTransitCoords).lng;
		}
    	 var mapOptions = {
    			zoom: 7,
				zoomControl: true,
				mapTypeControl: true,
				scaleControl: true,
				streetViewControl: true,
				rotateControl: true,
				fullscreenControl: true,
    	          labels:true,
    	          mapTypeControlOptions: {
    	            mapTypeIds: ['hybrid', 'roadmap'],
    	          },
    	          center: new window.google.maps.LatLng(lt,ln),
    	          mapTypeId: window.google.maps.MapTypeId.ROADMAP
    	        };
    	        
    	        var map = new window.google.maps.Map(document.getElementById('map'),
    	            mapOptions);

				var bounds = new window.google.maps.LatLngBounds();
				bounds.extend(new window.google.maps.LatLng(lt,ln));
    			 // Create our info window content
    			var currentinfowindow = null;
    	       var line=new window.google.maps.Polyline(
    	              {
    	                map:map,
    									strokeColor: '#157254',
    									strokeOpacity: 1.0,
    									strokeWeight: 2.5,
    	                		icons: [{
    	                          icon: {
    	                                  path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
    	                                  strokeColor:'#ff8c52',
    	                                  fillColor:'#ff8c52',
    	                                  fillOpacity:1,
    									  strokeWeight: 2
    	                                },
    	                          repeat:'100px',
    	                          path:[]
    	                       }]
    	                 });

			if(arr.length > 0)
			{	
    		  for (let i = 0; i < arr.length; i++) {
    				var path=line.getPath().getArray();
    					
    						let latLng=new window.google.maps.LatLng(arr[i].lat,arr[i].lng);
    						path.push(latLng);
    	          			line.setPath(path);
    					

    				  
    		  
    	          //Change line color based on map type
    	        window.google.maps.event.addListener( map, 'maptypeid_changed', function() {
    	            if (map.getMapTypeId() =='hybrid' || map.getMapTypeId() =='satellite')
    	            {
    	                var line=new window.google.maps.Polyline(
    	                  {
    	                    map:map,
    	                    strokeColor: '#FFFFFF',
    	                    strokeOpacity: 1.0,
    	                    strokeWeight: 2.5,
    	                    icons: [{
    	                              icon: {
    	                                      path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
    	                                      strokeColor:'#ff8c52',
    	                                      fillColor:'#ff8c52',
    	                                      fillOpacity:1,
    	                                      strokeWeight: 2
    	                                    },
    	                              repeat:'100px',
    	                              path:[]
    	                           }]
    	                     });                
    	            }
    	            else
    	            {
    	                var line=new window.google.maps.Polyline(
    	                  {
    	                    map:map,
    	                    strokeColor: '#157254',
    	                    strokeOpacity: 1.0,
    	                    strokeWeight: 2.5,
    	                    icons: [{
    	                              icon: {
    	                                      path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
    	                                      strokeColor:'#ff8c52',
    	                                      fillColor:'#ff8c52',
    	                                      fillOpacity:1,
    	                                      strokeWeight: 2
    	                                    },
    	                              repeat:'100px',
    	                              path:[]
    	                           }]
    	                     });

    	            }

    	              /*for (i = 0; i < arr.length; i++) {
    	                    var path=line.getPath().getArray();
    	                    let latLng=new window.google.maps.LatLng(arr[i].lat,arr[i].lng);
    						path.push(latLng);
    	          			line.setPath(path);
    	                            //map.setCenter(latLng);
						  }
						*/
    	                            
    	             var x = map.getZoom();
    	             var c = map.getCenter();
    	             window.google.maps.event.trigger(map, 'resize');
    	             map.setZoom(x);
					 map.setCenter(c);
					 
					 var path=line.getPath().getArray();
					let latLng=new window.google.maps.LatLng(arr[i].lat,arr[i].lng);
					path.push(latLng);
					line.setPath(path);
				} );
				
				bounds.extend(new window.google.maps.LatLng(arr[i].lat,arr[i].lng));
    		  }

		  var infowindow = new window.google.maps.InfoWindow();
			var marker, l;
			  //console.log("locations.length ", locations.length)
			  var lastorigin='';
			if(locations.length > 0)
			{
				for (l = 0; l < locations.length; l++) {
					if(arr[0].lat != locations[l].coordinates.lat && arr[0].lng != locations[l].coordinates.lmg)
					{
						marker = new window.google.maps.Marker({
						position: new window.google.maps.LatLng(locations[l].coordinates.lat, locations[l].coordinates.lng),
						icon:require('../../assets/icons/boat.png'),
						map: map,
						});

						window.google.maps.event.addListener(marker, 'click', (function(marker, l) {
							return function() {
								var contentString = "<p class='maptitle'>"+"<B> "+locations[l].name+", "+locations[l].country+"</B></p>";
								
								if(locations[l].events.length > 0)
								{
									contentString = contentString+"<table class='mt-8p table table-border' style='background:#f5f5f5;border:1px solid #dee2e6'>";
									contentString = contentString + "<thead><tr><th>Date</th><th>Event</th><th>Transport Mode</th></tr></thead><tbody>";
									for(var e=0;e<locations[l].events.length;e++)
									{
										var typemode;
										if(locations[l].events[e].type == 1){
											typemode='Rail/Truck';
										}
										if(locations[l].events[e].type == 2){
											typemode='Air';
										}
										if(locations[l].events[e].type == 3){
											typemode='Sea';
										}
										if(locations[l].events[e].type == 4){
											typemode='Rail/Truck';
										}
										var d = locations[l].events[e].event_datetime_ist;
										var dat = d.split(" ");				
										
										contentString = contentString + "<tr><td class='custb'><span class='tbmap'><i class='icofont icofont-disc'></i> <span class='tdmapsp'>"+ dat[0];
										contentString = contentString + "</span></td><td>" + locations[l].events[e].description + "</span></td><td style='text-align: right'>" + typemode + "</span></td></tr>";
									}
									contentString = contentString+"</tbody></table>";
									
								}

							lastorigin=contentString;
							infowindow.setContent(contentString);
							currentwindow = infowindow;
							infowindow.open(map, marker);
							//console.log(marker.position.toJSON());
							//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
							}
						})(marker, l));
					}
					else{
						marker = new window.google.maps.Marker({
							position: new window.google.maps.LatLng(locations[l].coordinates.lat, locations[l].coordinates.lng),
							icon:require('../../assets/icons/start_port.png'),
							map: map,
							});
	
							window.google.maps.event.addListener(marker, 'click', (function(marker, l) {
								return function() {
									var contentString = "<p class='maptitle'>"+"<B> "+locations[l].name+", "+locations[l].country+"</B></p>";
									
									if(locations[l].events.length > 0)
									{
										contentString = contentString+"<table class='mt-8p table table-border' style='background:#f5f5f5;border:1px solid #dee2e6'>";
										contentString = contentString + "<thead><tr><th>Date</th><th>Event</th><th>Transport Mode</th></tr></thead><tbody>";
										for(var e=0;e<locations[l].events.length;e++)
										{
											var typemode;
											if(locations[l].events[e].type == 1){
												typemode='Rail/Truck';
											}
											if(locations[l].events[e].type == 2){
												typemode='Air';
											}
											if(locations[l].events[e].type == 3){
												typemode='Sea';
											}
											if(locations[l].events[e].type == 4){
												typemode='Rail/Truck';
											}
											var d = locations[l].events[e].event_datetime_ist;
											var dat = d.split(" ");											
											
											contentString = contentString + "<tr><td class='custb'><span class='tbmap'><i class='icofont icofont-disc'></i> <span class='tdmapsp'>"+ dat[0];
											contentString = contentString + "</span></td><td>" + locations[l].events[e].description + "</span></td><td style='text-align: right'>" + typemode + "</span></td></tr>";
	
										}
										contentString = contentString+"</tbody></table>";
										
									}
											
								infowindow.setContent(contentString);
								currentwindow = infowindow;
								infowindow.open(map, marker);
								//console.log(marker.position.toJSON());
								//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
								}
							})(marker, l));
					}
				}
			}
			
	   	  marker = new window.google.maps.Marker({
	        position: new window.google.maps.LatLng(jsondata.last_known_location.lat, jsondata.last_known_location.lng),
	  		icon:require('../../assets/icons/crane.png'),
	        map: map,	
	      });
		   window.google.maps.event.addListener(marker, 'click', (function(marker) {
		        return function() {
		        	console.log("jsondata", jsondata);
					
					var typemode;
					if(jsondata.last_event.type == 1){
						typemode='Rail/Truck';
					}
					if(jsondata.last_event.type == 2){
						typemode='Air';
					}
					if(jsondata.last_event.type == 3){
						typemode='Sea';
					}
					if(jsondata.last_event.type == 4){
						typemode='Rail/Truck';
					}
					var d = jsondata.last_event.event_datetime_ist;
					var dat = d.split(" ");
					
					var contentString = contentString+"<table class='mt-8p table table-border' style='background:#f5f5f5;border:1px solid #dee2e6'>";
					contentString = contentString + "<thead><tr><th>Last Updated Date</th><th>Transport Mode</th></tr></thead><tbody>";
					
					contentString = contentString + "<tr><td class='custb'><span class='tbmap'><i class='icofont icofont-disc'></i> <span class='tdmapsp'>"+ jsondata.last_known_time;
					contentString = contentString + "</span></td><td style='text-align: right'>" + typemode + "</td></tr>";
					contentString = contentString+"</tbody></table>";					
				  infowindow.setContent(contentString);
				  currentwindow = infowindow;
				  infowindow.open(map, marker);
				 // console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
				}
			})(marker));
		}
		map.fitBounds(bounds);

    }
	
	onShowRouteMap(rownode){
		console.log("rownode", rownode);
		if(rownode != 0)
		{
			var params = { 
				//container_no:"FSCU9842333"
				container_no:rownode.container_no
			}
			this.setState({
				loadshow:'show-m'
			})
			 redirectURL.post('/imports/containersearates',params)
			  .then((response) => {
			  
			   var records = JSON.parse(response.data.body);
				//console.log(records.status);
				if(records.status == 'Failure')
				{
					this.setState({
						show: true, 
						basicType:'danger', 
						basicTitle:"No data found",
							
					});
				}
				else{
					//console.log(records);
					this.setState({
						mapinfo:records,
						sliderRouteTranslate:"slider-translate-75p",
						consignment_code:rownode.container_no,
						show: false
					});
					this.renderMap();
					
				}
				this.setState({
					loadshow:'show-n',
				})
			  /* this.setState({
					mapinfo:response.data,
					sliderRouteTranslate:"slider-translate-50p",
					consignment_code:rownode.truck_no
			   });
				   this.renderMap();
			
				*/
			  })
			  .catch(function (error) {
				console.log(error);
			  });
			  
		}
		
	}
	onCloseRouteDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderRouteTranslate:"",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
		});
	}
	closeAlert = () => {
        this.setState({
            show: false
        });
    }
	render(){
		const columnwithDefs = [
			
			{
				headerName: "<i class='icofont icofont-ship-alt'></i> Origin Sea Port to Indian Sea Port",
				headerClass:["cellstylegrid","cellTopHeader"],			
				children: [
					{
						headerName: "Actions",
						headerClass:"cellstylegrid",
						field: "map_available",
						width: 100,
						
						cellRendererSelector:function(params){
							//if(params.data.map_available == 1)
							//{
								return { component: 'consignmentActions'}
							/*}
							else{
								return { component: 'consignmentNoActions'}
							}*/
							
						},
						filter: false,resizable: true,
						cellStyle:function(params){
							return {background:"rgb(241, 251, 255)",color:"#333"}
						}
						//cellClass: function(params) { return 'cellStyleGrid'; }
					},
					{
						headerName: "Events",
						headerClass:"cellstylegrid",
						field: "_id",
						width: 100,
						cellRendererSelector:function(params){
							return { component: 'ConsignmentImportsEventsAction'};
						},
						filter: false,resizable: true,
						cellStyle:function(params){
							return {background:"rgb(241, 251, 255)",color:"#333"}
						}
					},				
					
					{
						headerName: "Container No",
						headerClass:"cellstylegrid",
						field: "container_no",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						cellClass:'cellstylegrid'
					},
					{
						headerName: "Bill of Lading",
						headerClass:"cellstylegrid",
						field: "bill_of_lading",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						cellClass:'cellstylegrid'
					},
					
					{
						headerName: "Booking No",
						headerClass:"cellstylegrid",
						field: "booking_no",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						cellClass:'cellstylegrid'
					},
					
					{
						headerName: "Container Size",
						headerClass:"cellstylegrid",
						field: "container_size",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						cellClass:'cellstylegrid'
					},
					
					
					{
						headerName: "Vessel Name",
						headerClass:"cellstylegrid",
						field: "vessel_name",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						cellClass:'cellstylegrid'
					},
					
					
					{
						headerName: "Last One Location",
						headerClass:"cellstylegrid",
						field: "last_one_location",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						cellClass:'cellstylegrid'
					},
					{
						headerName: "Last One Update",
						headerClass:"cellstylegrid",
						field: "last_one_update",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						cellClass:'cellstylegrid'
					},
					
					
					{
						headerName: "Last One Status",
						headerClass:"cellstylegrid",
						field: "last_one_status",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						cellClass:'cellstylegrid'
					}
					
				],
				
			},
			{
				headerName: "<i class='icofont icofont-map-pins'></i> Indian Sea Port to MSIL",
				headerClass:["cellstylegridB","cellTopHeader"],
				children: [
					
					{
						headerName: "Process Code",
						//cellRenderer: "agGroupCellRenderer",
						field: "inland_process_code",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						headerClass:"cellstylegridB",
						cellClass:'cellstylegridB'
					},
					{
						headerName: "Container No",
						field: "inland_container_no",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						headerClass:"cellstylegridB",
						cellClass:'cellstylegridB'
					},					
					{
						headerName: "Bill of Lading",
						field: "inland_bill_of_lading",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						headerClass:"cellstylegridB",
						cellClass:'cellstylegridB'
					},					
					{
						headerName: "GRFL Arrival Date",
						field: "inland_grfl_arrival_date",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						headerClass:"cellstylegridB",
						cellClass:'cellstylegridB'
					},
					{
						headerName: "COC Date",
						field: "inland_coc_date",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						headerClass:"cellstylegridB",
						cellClass:'cellstylegridB'
					},
					{
						headerName: "Handover Date",
						field: "inland_handover_date",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						headerClass:"cellstylegridB",
						cellClass:'cellstylegridB'
					},
					{
						headerName: "Dispatch Date",
						field: "inland_dispatch_date",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						headerClass:"cellstylegridB",
						cellClass:'cellstylegridB'
					},
					{
						headerName: "Empty Return Date",
						field: "inland_empty_return_date",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						headerClass:"cellstylegridB",
						cellClass:'cellstylegridB'
					},
					{
						headerName: "Trailer No",
						field: "inland_trailer_no",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						headerClass:"cellstylegridB",
						cellClass:'cellstylegridB'
					},
					{
						headerName: "Port of Discharge",
						field: "inland_port_of_discharge",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						headerClass:"cellstylegridB",
						cellClass:'cellstylegridB'
					},
					{
						headerName: "Vessel Arrived On",
						field: "inland_vessel_arrived_on",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						headerClass:"cellstylegridB",
						cellClass:'cellstylegridB'
					},
					{
						headerName: "Inland Container Size",
						field: "inland_container_size",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						headerClass:"cellstylegridB",
						cellClass:'cellstylegridB'
					},
					{
						headerName: "Cargo Description",
						field: "inland_cargo_description",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						headerClass:"cellstylegridB",
						cellClass:'cellstylegridB'
					},
					{
						headerName: "Shipping Line",
						field: "inland_shipping_line",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						headerClass:"cellstylegridB",
						cellClass:'cellstylegridB'
					},
					{
						headerName: "BOE No",
						field: "inland_boe_no",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						headerClass:"cellstylegridB",
						cellClass:'cellstylegridB'
					},
					{
						headerName: "BOE Date",
						field: "inland_boe_date",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						headerClass:"cellstylegridB",
						cellClass:'cellstylegridB'
					},
					{
						headerName: "Plant Name",
						field: "inland_plant_name",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						headerClass:"cellstylegridB",
						cellClass:'cellstylegridB'
					},
					{
						headerName: "SMTP Handover Date",
						field: "inland_smtp_handover_date",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						headerClass:"cellstylegridB",
						cellClass:'cellstylegridB'
					},
					{
						headerName: "Departure to GRFL Date",
						field: "inland_departure_to_grfl_date",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						headerClass:"cellstylegridB",
						cellClass:'cellstylegridB'
					},
					{
						headerName: "Line Voyage No",
						field: "inland_line_voyage_no",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						headerClass:"cellstylegridB",
						cellClass:'cellstylegridB'
					},
					{
						headerName: "Transit Mode",
						field: "inland_transit_mode",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						headerClass:"cellstylegridB",
						cellClass:'cellstylegridB'
					},
					
				]
			},	
				
				
		];
		const dStyles={
			width:this.props.width,
			height:'500px',
			marginLeft:this.props.marginLeft,

	}

		return(
			<div className="container-fluid">
			<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
	            {this.state.alert}
				
            	<div className="row">					
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Container Imports (Freight Forwards) </span>
								</h5>
				   			</div>				   			
		            		<div className="card-body">
							
								
								 <div id="myGrid" style={{ height: "580px",width: "100%"}}  className="ag-theme-balham">
		          		          <AgGridReact
		          		            modules={this.state.modules}
		          		            columnDefs={columnwithDefs}
		          		            defaultColDef={this.state.defaultColDef}
		          		            rowData={this.state.rowData}
		          		            enableCharts={true}
		          		            enableRangeSelection={true}
		          		            autoGroupColumnDef={this.state.autoGroupColumnDef}
		          		            onGridReady={this.onGridReady}
		          		            onGridState={this.onGridState}
		          		            statusBar={this.state.statusBar}
		          	                sideBar={this.state.sideBar}
									frameworkComponents={this.state.frameworkComponents}
									detailCellRendererParams={this.state.detailCellRendererParams}
									//editType={this.state.editType}
									stopEditingWhenGridLosesFocus={true}
									floatingFilter={false}
									enableCellChangeFlash={true}
									suppressCellFlash={true}
									rowClassRules={this.state.rowClassRules}
									//onCellClicked={this.onRowClicked.bind(this)}
									gridOptions={{
										context: { componentParent: this }
									}}
									masterDetail={true}
									headerHeight={this.state.headerHeight}
									groupHeaderHeight={this.state.groupHeaderHeight}
		          		          />

									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
									</div>

		          		        </div>
				   			</div>
				   		</div>	
				   	</div>
				 </div>
				
				
				 {this.state.sliderRouteTranslate != ''?
					 <div className={"slide-r "+(this.state.sliderRouteTranslate)} style={{overflow:"auto"}}>
					 	<div className="slide-r-title">
							<h4>
								Container Routes #{this.state.consignment_code}
								<span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseRouteDiv.bind(this)} >X</span>
										
							</h4>
						</div>
						<div className="slide-r-body" style={{position:"relative"}}>
							
							<div className="row col-xl-12 col-lg-12">
								<img src={require('../../assets/icons/start_port.png')} className="mapico2" /> - Starting Point &nbsp;
								<img src={require('../../assets/icons/boat.png')} className="mapico1" /> - Port/Change Over location &nbsp; 
								<img src={require('../../assets/icons/crane.png')} className="mapico" /> - Last Known location &nbsp;
								<div id="map" className="" style={{width:'100%',height:"90vh",marginTop:"15px"}}></div>
							</div>
						</div>
					</div>
				:""	
				}
				
				
            </div>
              	
		);
    }


    
    onShowConsignmentImportsEventsClick = async (params) => {
		//console.log(e);
		console.log("IN onShowConsignmentImportsEventsClick params ",params);
	//http://localhost:3001/trackBlDeepSea/track?sealine=HPG&container=FSCU9842333&apiKey=O1TX-DPHE-WYN6-53MI
		if(params != 0)
		{
			var eventsData=[];
			
			await this.setState({
				detailCellRendererParams:{
					detailGridOptions: {
						columnDefs: [
                            { headerName: "Vessel Name",field: "vessel_name", filter:true },
							{ headerName: "Booking No",field: "booking_no", filter:true },							
							{ headerName: "Container No", field: "container_no", filter:true },
							{ headerName: "Container Size",field: "container_size", filter:true },
							{ headerName: "Date",field: "date", filter:true },
							{ headerName: "Current Status",field: "current_status", filter:true },
							{ headerName: "Location",field: "location", filter:true },
							{ headerName: "Yard Name",field: "yard_name", filter:true },
						]							
					},
					getDetailRowData: async function(params) {
						//console.log("Step 5 params = ",params);
						redirectURL.post('imports/onecontainersevents', {
							bl_no: params.data.bill_of_lading
						 })
						.then(async (response) =>{
							//console.log("Step 1 ",response.data)
							params.successCallback(response.data);
								
							//console.log("Step 2 ",response.data)
							
						});
					},
					masterDetail: true
				}
			});
			
			if(params.column.colId == '_id_1' || params.column.colId == '_id_2' || params.column.colId == '_id' )
			{
				//console.log("detailCellRendererParams 1111 = ", this.state.detailCellRendererParams, params.column.colId);
				params.node.setExpanded(!params.node.expanded);
			}
			else{
				//console.log("detailCellRendererParams 2222 = ", this.state.detailCellRendererParams, params.column.colId);
				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);
			
		} else {
			this.setState({
			
				detailCellRendererParams:{
					detailGridOptions: {
					  columnDefs: [
						{ headerName: "Truck No",field: "truck_no" },
						{ headerName: "Speed",field: "speed" },
						{ headerName: "Reported At",field: "first_instance" }
					]
					  
					},
					getDetailRowData: function(params) {
						//console.log("Child ", params);
					  params.successCallback([]);
					}
				  }
			});
		}
		
	}
}
function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}
                   
function timeConvert(n) {
	var num = n;
	var hours = (num / (3600));
	var rhours = Math.floor(hours);
	var minutes = (n) / (60);
	var rminutes = Math.round(minutes);
	return rhours + " hour(s) and " + rminutes + " minute(s).";
}                              
var currentinfowindow = null;
function getTimeInfo(marker,timedet,coords)
{
	var timeinfo = new window.google.maps.InfoWindow({
          content: "Time at this location: " + timedet
        });

	marker.addListener('click', function() {
		
				if (currentinfowindow != null) {
					currentinfowindow.close();
					currentinfowindow = timeinfo;
					timeinfo.open(marker.get('map'), marker);
				}
				else
				{
					currentinfowindow = timeinfo;
					timeinfo.open(marker.get('map'), marker);
				}
				
        });
}

function parseEventsData2 (jsonData) {
    console.info('parsing data IN parseEventsData');
    console.info('parsing data IN parseConsignemntDetailsSafexpress', jsonData);
   
    let points = jsonData.points;
   // console.log('points = ', points);
    let eventList = [];
    let eventInfo = {};
    points.forEach(function(point) {
        eventInfo.name = point.name;
        eventInfo.country = point.country;
      // traversing events
      if (point.events) {
        console.log('coming in events');
        point.events.forEach(function(eventObj) { 
            eventInfo.event_datetime_ist = eventObj.event_datetime_ist;
            eventInfo.description = eventObj.description;
        });
        eventList.push(eventInfo);
      } else {
        console.log('coming in ELSE point.events ');

      }
      
    });
    console.info('parsing points');

    return eventList;
  }
  
async function parseEventsData (jsonData) {
console.info('parsing data IN parseEventsData');
console.info('parsing data IN parseConsignemntDetailsSafexpress', jsonData);

let points = jsonData.points;
// console.log('points = ', points);
let eventList = [];
let eventInfo = [];

points.forEach(function(point) {
	let pointName = point.name;
	let pointCountry = point.country;
	let pointTransportTypeName = point.transport_type_name;
	let pointTransportType = point.type;
	if (pointTransportType === 1 || pointTransportType === 4) {
		pointTransportTypeName = "Rail/Truck";
	}
	if (!pointTransportTypeName) {
		pointTransportTypeName = "Data unavailable"
	}
	// traversing events
	if (point.events) {
		console.log('coming in events');
		point.events.forEach(function(eventObj) { 
			let eventDatetime = eventObj.event_datetime_ist;
			let eventDescription = eventObj.description;
			eventInfo = {
				"name": pointName,
				"country": pointCountry,
				"event_datetime_ist": eventDatetime,
				"description": eventDescription,
				"transport_type_name": pointTransportTypeName
			}
			eventList.push(eventInfo);
		});
	} else {
		console.log('coming in ELSE point.events '); // do nothing
	}
});
console.log('eventList', eventList);
return eventList;
}
