import React, {Component} from 'react';
import {Map, InfoWindow, Marker, GoogleApiWrapper, Polygon, Polyline} from 'google-maps-react';

import { getDDMMYYYY, getDDMMYYYYHHMMSS, getDDMMYYYYHHMM } from '../common/utils'; 

import Modal from 'react-responsive-modal';

import CustomLoadingCellRenderer from "../layouts/customLoadingCellRenderer";
import MarkerClusterer from "@google/markerclusterer";
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
// //import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

var infoBox = require('../common/google-infowindow');
var moment = require('moment');

var drawingManager;
var selectedShape;
var colors = ['#1D9567', '#1692A5', '#F4D749','#F73E3E', '#B7B3B3' ];
var selectedColor;
var colorButtons = {};
var fencingLength=0;
var mapZoom=12;
var coordinates=[];
var viewMarkersArr=[];
var coords_copy = [];
var geoFencingArea;
var markersArray;
var map;
var route_deviation_polyline_dict = {1: "route_deviation_polyline_1",2: "route_deviation_polyline_2",3: "route_deviation_polyline_3"}
var pathcolor = ["#157254","blue","red"]
class GeofenceMap extends Component {
	constructor(props){
		super(props);
		this.state = {
			mapinfo:'',
			tolls : [],
			proptruckno:'',
			defTransitCoords:'',
			activeMarker: {},
			selectedPlace: {},
			dealerCode:'',
			timelinesmarkers:[],
			viewtimelinemarkers:false,
			viewgoogleroutes:true,
			googleroutepath:[],
			loadshow:'show-n',
			open: false,
			modules: AllModules,
			defaultColDef: {
				sortable: true,
			  filter: true,
			  resizable: true

			},
			rowData: null,
			  
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			overlayNoRowsTemplate: "",
			frameworkComponents: {
			  customLoadingCellRenderer: CustomLoadingCellRenderer
		  },

			animateRows: true,
			debug: true,
			showToolPanel: false,
		  uppressAggFuncInHeader: true,
			  
		  childconfs: [],
		  childrow:[],
		  rowModelType: "serverSide",
		  paginationPageSize:50,
		  cacheBlockSize: 10,
		  maxBlocksInCache: 1,
		  showMarkers:0,
		  statusBar: {
			  statusPanels: [
				  {
				  statusPanel: "agTotalAndFilteredRowCountComponent",
				  align: "left"
				  },
				  {
				  statusPanel: "agTotalRowCountComponent",
				  align: "center"
				  },
				  { statusPanel: "agFilteredRowCountComponent" },
				  { statusPanel: "agSelectedRowCountComponent" },
				  { statusPanel: "agAggregationComponent" }
			  ]
		  },
		  sideBar: {
			  toolPanels: [
				  {
				  id: "columns",
				  labelDefault: "Columns",
				  labelKey: "columns",
				  iconKey: "columns",
				  toolPanel: "agColumnsToolPanel"
				  },
				  {
				  id: "filters",
				  labelDefault: "Filters",
				  labelKey: "filters",
				  iconKey: "filter",
				  toolPanel: "agFiltersToolPanel"
				  }
			  ]
		  },
		  detailCellRendererParams:{},
		  rowClassRules: {
			  "highlitRow": "data.transit_delay == 1",
			  "green":"data.status >= 3"

		  },
		  data_type : "",
		  selectedorigin:"",
		  selecteddestination:"",
		  returnTripsDashboardRowData: []
		};
		this.plotMultiColorPolyline = this.plotMultiColorPolyline.bind(this)
	}
	componentDidMount(){
		
		this.renderMap()
	}
	componentWillReceiveProps(newprops){
		if(newprops.truckno != this.state.proptruckno)
		{
			this.renderMap()
			this.setState({
				proptruckno:newprops.truckno
			})
		}
		if(newprops.selectedorigin != this.state.selectedorigin && newprops.selecteddestination != this.state.selecteddestination)
		{
			this.renderMap()
		}
		
	}
    renderMap = () => {   
		
		loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyD1AFWPoJqPaemwGO4A7P7Q6CsUJ1TLGJk&libraries=places,drawing&callback=initMap");
		window.initMap = this.initMap
	}
	
	onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	
	    if(this.state.deptcode == 'LOG-PRT')
	    {
			this.gridColumnApi.setColumnsVisible(["nightdriving_exception"], false);
			this.gridColumnApi.setColumnsVisible(["loading_delay_exception"], false);
		}
		if(this.state.deptcode == 'SNDG')
		{
			this.gridColumnApi.setColumnsVisible(["nightdriving_exception"], true);
			this.gridColumnApi.setColumnsVisible(["loading_delay_exception"], true);
		}

		
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
	}


plotMultiColorPolyline(arr2,map,bounds)
{
	if(arr2.length)
	{
		var data_type_list = [...new Set(arr2.map(item => item.type))];
		if(data_type_list.includes("transshipment"))
		{
			var data_type = "transshipment"
		}
		if(data_type_list.includes("rake"))
		{
			var data_type = "rake"
		}
		this.setState({
			data_type : data_type
		})
	}
	
	if(this.props.stepcoords == undefined)
	{
		if(arr2.length <= 1000)
		{
			var icon_cnt = 60;
			var icon_divisible = 60;
		}
		else
		{
			var icon_cnt = 500;
			var icon_divisible = 500;
		}
	}
	else
	{
		var icon_cnt = 1;
		var icon_divisible = 1;
	}
	//console.log(arr2, "coords")
	
	for(var i=0;i< arr2.length-1;i++)
	{
		var locationLatLng = [];
		locationLatLng.push(new window.google.maps.LatLng(arr2[i].lat,arr2[i].lng));
		locationLatLng.push(new window.google.maps.LatLng(arr2[i+1].lat,arr2[i+1].lng));
		if(icon_cnt % icon_divisible == 0)
		{
			var lineicon = [{
						icon: {
								path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
								strokeColor:'#FF0000',
								fillColor:'#FF0000',
								fillOpacity:1,
								strokeWeight: 2
						},
						repeat:'100px',
						path:[]
					}];
		}else{lineicon=[]}
		icon_cnt = icon_cnt + 1
		var consignments_missing_route_line = new window.google.maps.Polyline({
			map:map,
			path : locationLatLng,
			strokeColor: '#157254',
			strokeOpacity: 1.0,
			strokeWeight: 2.5,
			icons: lineicon
		});
		if(arr2[i].msgtype == "G")
		{
			consignments_missing_route_line.setOptions({
				strokeColor : "#452a68",
				strokeWeight: 5.5,
			})
		}
		
		if(arr2[i].set == undefined && arr2[i].msgtype != "G")
		{
			consignments_missing_route_line.setOptions({
				strokeColor : "#157254"
			})
		}
		else if(arr2[i].set == 1 && arr2[i].msgtype != "G")
		{
			consignments_missing_route_line.setOptions({
				strokeColor : "#157254"
			})
		}
		else if(arr2[i].set == 2 && arr2[i].msgtype != "G")
		{
			consignments_missing_route_line.setOptions({
				strokeColor : "#0000ff",
				strokeWeight: 5.5,
			})
		}
		else if(arr2[i].set == 3 && arr2[i].msgtype != "G")
		{
			consignments_missing_route_line.setOptions({
				strokeColor : "#980000",
				strokeWeight: 5.5,
			})
		}
		bounds.extend(new window.google.maps.LatLng(arr2[i].lat,arr2[i].lng));
	}
}
plotBoundaryLines(boundaryarr){
	//console.log("boundaries ",boundaryarr)
	if(boundaryarr.length > 0)
	{
		
		boundaryarr.map((item) => {
			if(item.length>0)
			{
				var line=new window.google.maps.Polyline(
					{
						map:map,
						strokeColor: '#0ABB50',
						strokeOpacity: 1.0,
						strokeWeight: 2.5
					});
				for(var i =0; i<item.length;i++)
				{
					var locationLatLng = [];
					locationLatLng.push(new window.google.maps.LatLng(item[i].lat,item[i].lng));
					var consignments_missing_route_line = new window.google.maps.Polyline({
						map:map,
						path : locationLatLng,
						strokeColor: '#0ABB50',
						strokeOpacity: 1.0,
						strokeWeight: 2.5,
						fillColor: '#1D9567',
						fillOpacity: 0.8
						//icons: lineicon
					});
					
					var path=line.getPath().getArray();								
					let latLng=new window.google.maps.LatLng(item[i].lat,item[i].lng);
					path.push(latLng);
					line.setPath(path);
				}

			}
		})
	}
}

initMap = () => 
{
	var currentwindow;
	var dealer='';
	var tolls = this.props.tolls;
	var chokepoints = this.props.chokepoints;
	this.setState({
		dealerCode:this.props.dealer
	});
    var arr=[];
    var locations= [];
    var coords_list=[];
	console.log("dealerCode ",this.props.mapinfo)
	if(this.props.mapFor == 'geofence')
	{
        var jsondata = this.props.mapinfo;
        console.log("jsondata ", jsondata)
		if(jsondata != undefined)
        {
            var arr = jsondata.plants;
            console.log("arr ", arr)
		}
        coords_copy = arr;
	}
	
	// if( arr == undefined)
	// {
		var lt=28.4519751;
		var ln=77.0310713;
	// }
	// else
	// {
	// 	if(arr.length > 0 && typeof arr.length != undefined)
	// 	{
	// 		if(this.props.mapFor == 'ticket' && this.props.consignercords != undefined)
	// 		{
	// 			var lt=this.props.consignercords.lat;
	// 			var ln=this.props.consignercords.lng;
	// 		}
	// 		else{
	// 			var lt=arr[0].lat;
	// 			var ln=arr[0].lng;
	// 		}
	// 	}
	// 	else{
	// 		if(this.props.defTransitCoords == '' || typeof this.props.defTransitCoords == undefined)
	// 		{
	// 			var lt=28.4519751;
	// 			var ln=77.0310713;
	// 		}
	// 		else{
	// 			try{
	// 				var lt=JSON.parse(this.props.defTransitCoords).lat;
	// 				var ln=JSON.parse(this.props.defTransitCoords).lng;
	// 			}
	// 			catch(e){
	// 				var lt=28.4519751;
	// 				var ln=77.0310713;
	// 			}
				
	// 		}
			
	// 	}
	// }

	
	try{
		var mapOptions = {
			zoom: 8,
			zoomControl: true,
			mapTypeControl: true,
			scaleControl: true,
			streetViewControl: true,
			rotateControl: true,
			fullscreenControl: true,
			labels:true,
			mapTypeControlOptions: {
				mapTypeIds: ['satellite', 'roadmap'],
			},
			center: new window.google.maps.LatLng(lt,ln),
			mapTypeId: window.google.maps.MapTypeId.ROADMAP
		};
				
	}
	catch(e){
		var mapOptions = {
			zoom: 13,
			zoomControl: true,
			mapTypeControl: true,
			scaleControl: true,
			streetViewControl: true,
			rotateControl: true,
			fullscreenControl: true,
			labels:true,
			mapTypeControlOptions: {
				mapTypeIds: ['satellite', 'roadmap'],
			},
			center: new window.google.maps.LatLng(28.4519751,77.0310713),
			mapTypeId: window.google.maps.MapTypeId.ROADMAP
		};
				
	}
	try{
		map = new window.google.maps.Map(document.getElementById('map'), mapOptions);
	}catch(e){}
	
	var bounds = new window.google.maps.LatLngBounds();
	bounds.extend(new window.google.maps.LatLng(lt,ln));
		// Create our info window content
	var currentinfowindow = null;
	var line=new window.google.maps.Polyline(
	{
		map:map,
		strokeColor: '#157254',
		strokeOpacity: 1.0,
		strokeWeight: 2.5,
		icons: [{
				icon: {
						path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
						strokeColor:'#ff8c52',
						fillColor:'#ff8c52',
						fillOpacity:1,
						strokeWeight: 2
				},
				repeat:'100px',
				path:[]
			}]
	});
	if(arr == undefined){

	}
	else{
		try{
			
				var allpoints = [];
				var arr1=[];
				//console.log("Total ",ratio);
				if(this.state.timelinesmarkers.length > 0)
				{
					arr1 = this.state.timelinesmarkers.filter(function (value, index, arr) {
						return (index % ratio == 0);
					} );
				}
				
				var arr2=[];
				var darr=[];
				if(arr.length > 0)
				{
					darr = arr.filter(function (value, index, arr) {
						//console.log("Vale ",value)
						return (value.dist_from_prev_point > 0);
					} );
					if(darr.length < 2000)
					{
						var ratio = 1;
					}
					else{
						var ratio = 20;
					}
					//console.log("darr.length ", darr.length)
					arr2 = darr.filter(function (value, index, darr) {
						return (index % ratio == 0);
					} );
					

					//var arr1 = this.state.timelinesmarkers;
				}
				
				/*GEOFENCE MAP*/
				if(this.props.mapFor == 'geofence')
				{
					
					// this.plotMultiColorPolyline(arr2,map,bounds)
					

					var infowindow = new window.google.maps.InfoWindow();
                    var marker, l;
                    

                    var geofences = jsondata.geofences
					for (var g = 0; g < geofences.length; g++) {
                        var latlng = JSON.parse(geofences[g].geofence_centriod_coordinates);
                        var lat = parseFloat(latlng.lat);
                        var lng = parseFloat(latlng.lng);
                        var gname = geofences[g].geofence_name;
                        console.log("gname ", gname)
                        // console.log("lng ", lng)
                        bounds.extend(new window.google.maps.LatLng(lat,lng));
                        marker = new window.google.maps.Marker({
                            position: new window.google.maps.LatLng(lat, lng),
                            icon:require('../../assets/icons/traindeliver.png'),
                            map: map,
                        });

                        window.google.maps.event.addListener(marker, 'click', (function(marker, l) {
                            return function() {
                                //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Break - "+(l+1)+"</B></p><p>Break time "+locations[l].break_start+" - "+locations[l].break_end+" ("+timeConvert(locations[l].break_time)+")</p>";
// console.log("marker ", marker)
                            var contentarr = []
                            var header = "Rail Yard";
                            
                                // contentarr.push({"key":" Name", "value":gname})
                            
                            var contentString = infoBox(marker.icon, header, contentarr,'')
                            
                            infowindow.setContent(contentString);
                            currentwindow = infowindow;
                            infowindow.open(map, marker);
                            //console.log(marker.position.toJSON());
                            console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
                            }
                        })(marker, l));
                    }
                    

                    var locations = jsondata.plants
                    // console.log("locations ",locations)
                    if(locations.length > 0)
                    {
                        for (l = 0; l < locations.length; l++) {
                            var latlng = JSON.parse(locations[l].plant_coordinates);
                            bounds.extend(new window.google.maps.LatLng(latlng.lat,latlng.lng));
                            // console.log("latlng ", latlng)
                            marker = new window.google.maps.Marker({
                            position: new window.google.maps.LatLng(latlng.lat, latlng.lng),
                            icon:require('../../assets/icons/business.png'),
                            map: map,
                            });
    
                            window.google.maps.event.addListener(marker, 'click', (function(marker, l) {
                                return function() {
                                    //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Break - "+(l+1)+"</B></p><p>Break time "+locations[l].break_start+" - "+locations[l].break_end+" ("+timeConvert(locations[l].break_time)+")</p>";
                                
                                var contentarr = []
                                var header = "Plant";
                                
                                contentarr.push({"key":"Plant Name", "value":locations[l].plant_name})
                                // contentarr.push({"key":"City", "value":locations[l].city})
                                // contentarr.push({"key":"Province", "value":locations[l].province})
                                // contentarr.push({"key":"Zone", "value":locations[l].zone})
                                // contentarr.push({"key":"Address", "value":locations[l].location_address})
                                
                                var contentString = infoBox(marker.icon, header, contentarr,'')
                                
                                infowindow.setContent(contentString);
                                currentwindow = infowindow;
                                infowindow.open(map, marker);
                                //console.log(marker.position.toJSON());
                                // console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
                                }
                            })(marker, l));
                        }
                    }
					
                    var consignees = jsondata.consignees;
                    if(consignees.length > 0)
                    {
                        for (var c = 0; c < consignees.length; c++) {
                            console.log("dddd ", consignees[c].consignee_name)
                            var latlng = JSON.parse(consignees[c].coordinates);
                            var lat = parseFloat(latlng.lat);
                            var lng = parseFloat(latlng.lng);
                            // console.log("lat ", lat)
                            // console.log("lng ", lng)
                            bounds.extend(new window.google.maps.LatLng(lat,lng));
                            marker = new window.google.maps.Marker({
                            position: new window.google.maps.LatLng(lat, lng),
                            icon:require('../../assets/icons/user-map.png'),
                            map: map,
                            });
    
                            window.google.maps.event.addListener(marker, 'click', (function(marker, l) {
                                return function() {
                                    //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Break - "+(l+1)+"</B></p><p>Break time "+locations[l].break_start+" - "+locations[l].break_end+" ("+timeConvert(locations[l].break_time)+")</p>";
                                
                                var contentarr = []
                                var header = "Consignee";
                                // if(consignees[c].consignee_name != undefined)
                                // {
                                //     contentarr.push({"key":" Name", "value":consignees[c].consignee_name})
                                // }
                                // contentarr.push({"key":"City", "value":consignees[c].consignee_city})
                                // contentarr.push({"key":"Province", "value":consignees[c].consignee_state})
                                // contentarr.push({"key":"Zone", "value":consignees[c].consignee_zone})
                                // console.log("contentarr ", contentarr)
                                var contentString = infoBox(marker.icon, header, contentarr,'')
                                
                                infowindow.setContent(contentString);
                                currentwindow = infowindow;
                                infowindow.open(map, marker);
                                //console.log(marker.position.toJSON());
                                // console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
                                }
                            })(marker, l));
                        }
                    }
					

				}


		}
		catch(e){

        }
        
		map.fitBounds(bounds)
	}

}


	onOpenModal = () => {
		this.setState({ open: true });
	 };
	
	 onCloseModal = () => {
	   this.setState({ open: false });
	 };

	onShowTimelines(){
		this.setState({
			viewtimelinemarkers:!this.state.viewtimelinemarkers
		})
		if(this.state.viewtimelinemarkers === true)
		{			
			var arr=[];
			if(this.props.mapFor == 'consignment' || this.props.mapFor == 'ticket')
			{
				var jsondata = this.props.mapinfo;
				var locations = jsondata.breaks;
				var arr = jsondata.coords;
			}
			if(this.props.mapFor == 'importsconsignment')
			{
				var jsondata = this.props.mapinfo;
				var locations = jsondata.points;
				var arr = jsondata.route;
			}
			if(this.props.mapFor == 'truckgps')
			{
				var jsondata = this.props.mapinfo;
				var locations = jsondata.breaks;
				var arr = jsondata.coords;
			}
			if(arr.length > 0)
			{

				var allpoints = [];
				

				// if(arr.length < 50)
				// {
				// 	var ratio = (arr.length/5);
				// }
				// else if(arr.length > 50 && arr.length <200)
				// {
				// 	var ratio = 5;
				// }
				// else if(arr.length > 200 && arr.length <400)
				// {
				// 	var ratio = 20;
				// }
				// else{
				// 	var ratio = 100;
				// }
				
				// //console.log("Total ",arr.length);
				// var arr1 = arr.filter(function (value, index, arr) {
				// 	//return (index % ratio == 0);
				// 	return index;
				// } );
				var darr = [];
				darr = arr.filter(function (value, index, arr) {
					//console.log("Vale ",value)
					return (value.dist_from_prev_point > 0);
				} );
				if(darr.length < 50)
				{
					var ratio = (arr.length/5);
				}
				else if(darr.length > 50 && arr.length <200)
				{
					var ratio = 5;
				}
				else if(darr.length > 200 && darr.length <400)
				{
					var ratio = 20;
				}
				else{
					var ratio = 100;
				}
				var arr2=[]
				//console.log("darr.length ", darr.length)
				arr2 = darr.filter(function (value, index, darr) {
					return (index % ratio == 0);
				} );

				this.setState({
					timelinesmarkers:arr2
				})
			}
		}
		else{
			this.setState({
				timelinesmarkers:[]
			})
		}
			this.renderMap()
		
	}
	onViewTimelines(e){
		this.setState({
			viewtimelinemarkers:!this.state.viewtimelinemarkers
		})
		//console.log("viewtimelinemarkers ", this.state.viewtimelinemarkers)
	}
	onShowGridPop(e){
		if(this.props.mapFor == 'consignment' || this.props.mapFor == 'ticket')
		{
			this.setState({
				loadshow:'show-m'
			})
			var jsondata = this.props.mapinfo;
			var locations = jsondata.breaks;
			var coordinates = jsondata.coords;
			//console.log("truckno ", this.props.truckno)
			//console.log("coordinates ", coordinates)
			var dataarr=[]
			//console.log("coordinates ", coordinates)
			coordinates.map((item) => {
				dataarr.push({"truck_no":this.props.truckno, "lat":item.lat, "lng":item.lng, "speed":item.speed, "stime":item.stime,"dist_from_prev_point":item.dist_from_prev_point, "received_on":item.received_on, "time_from_prev_point":item.time_from_prev_point})
			});
			this.setState({
				rowData:dataarr,
				open:true,
				loadshow:'show-n'
			})
		}
	}
 

	onShowGoogleRoutes(){
		this.setState({
			viewgoogleroutes:!this.state.viewgoogleroutes
		})
		if(this.state.viewgoogleroutes == true)
		{			
			if(this.props.mapFor == 'consignment')
			{
				try{
					var googleroutes = JSON.parse(this.props.googleroutes);
					//console.log("googleroutes ", googleroutes)
					if(googleroutes == 0)
					{
						this.props.context.showGoogleRouteAlert();
						this.setState({
							viewgoogleroutes:!this.state.viewgoogleroutes
						});
					}
					else{
						this.setState({
							googleroutepath:googleroutes
						})
					}
				}
				catch(e){
					console.log(e)
				}
			
			}
		}
		else{
			this.setState({
				googleroutepath:[],
				viewgoogleroutes:!this.state.viewgoogleroutes
			})
		}
			
		this.renderMap()
	}

	showMarkers(event){
		if(event.target.value == 0)
		{
			this.setState({
				showMarkers : 1
			});
			// viewMarkersArr.map(function(e){
			// 	e.setVisible(true);
			// })
			var infowindow = new window.google.maps.InfoWindow();
			if(markersArray.length > 0)
			{
				var stepby = Math.round(markersArray.length * 0.007)
				// var counter = ( stepby > 0)?stepby:1;
				// var incrementby = counter
				var counter = 1;
				var incrementby = 1;
				console.log(markersArray.length,counter);
				while(counter < markersArray.length)
				{
					var marker = new window.google.maps.Marker({
						position: new window.google.maps.LatLng(markersArray[counter].lat, markersArray[counter].lng),
						icon: {
							path: window.google.maps.SymbolPath.CIRCLE,
							strokeColor:'#157254',
							fillColor:'#157254',
							fillOpacity:1,
								strokeWeight: 5,
								scale:1,
							},
						map: map,
						content:markersArray[counter]
					});
					// marker.setVisible(false);
					window.google.maps.event.addListener(marker, 'mouseover', (function(marker) {
						return function() {
							//console.log("Arr ",marker)
							var contentarr = []
						var header = "Current Status"
						contentarr.push({"key":"Time ", "value":getDDMMYYYYHHMMSS(marker.content.stime)})
						contentarr.push({"key":"Speed ", "value":marker.content.speed+" KMPH"})
						
						var contentString = infoBox("", header, contentarr,'')
						
						
						infowindow.setContent(contentString);
						infowindow.open(map, marker);
						//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
						}
					})(marker));
					window.google.maps.event.addListener(marker, 'mouseout', function() {
						infowindow.close();
					});
	
					viewMarkersArr.push(marker);
					counter = counter + incrementby;
					console.log("counter",counter)
				}
				// for(var a=0;a<markersArray.length;a++)
				// {
					
				// }
			}
			
		}
		else
		{
			this.setState({
				showMarkers : 0
			});
			viewMarkersArr.map(function(e){
				e.setVisible(false);
			});
		}
	}

setRouteDeviationPathVisibility(routeno){
	routeno = routeno+1
	console.log(route_deviation_polyline_dict[routeno])
	if(route_deviation_polyline_dict[routeno].getVisible())
	{
		route_deviation_polyline_dict[routeno].setVisible(false)
	}
	else{
		route_deviation_polyline_dict[routeno].setVisible(true)
	}
}
onRTDashboardRowClicked(row){
	var breaklocations = [];
	var bounds = new window.google.maps.LatLngBounds();
	row.data.break_clusters.map(function(e){
		bounds.extend(new window.google.maps.LatLng(e.lat,e.lng));
	})
	map.fitBounds(bounds)
}
render () {
 const modalStyles  = {
              width:'500px !important',
      		}
	  const { open } = this.state;
	  const columnwithDefs=[
			{
				headerName: "Truck no",
				field: "truck_no",
				width: 100,
				filter: false,
				resizable: true
				
			},
			{
				headerName: "Latitude",
				field: "lat",
				width: 120,
				filter: false,
				resizable: true,
				valueGetter:function(params){
					return params.data.lat.toFixed(6);
				}
				
			},
			{
				headerName: "Longitude",
				field: "lng",
				width: 120,
				filter: false,
				resizable: true,
				valueGetter:function(params){
					return params.data.lng.toFixed(6);
				}
				
			},
			{
				headerName: "Speed (Kms)",
				field: "speed",
				width: 80,
				filter: false,
				resizable: true
				
			},
			{
				headerName: "Packet Time",
				field: "stime",
				width: 140,
				filter: false,
				resizable: true,
				valueGetter:function(params){
					//console.log(params);
					return getDDMMYYYYHHMMSS(params.data.stime);
			   }
				// valueGetter:function(params){
				// 	console.log(params);
				// 	return params.data.stime;
				// }
				
			},
			{
				headerName: "Received On",
				field: "received_on",
				width: 140,
				filter: false,
				resizable: true,
				 valueGetter:function(params){
				 	//console.log(params);
				 	return getDDMMYYYYHHMMSS(params.data.received_on);
				}
				
			},
	  ]
	
    return (
           
			<div className="col-xl-12 col-md-12">
				

				{/* map div  */}
				
				<div id="map" className="" style={{width:'100%',height:"70vh"}}></div>

				<Modal open={open} onClose={this.onCloseModal} styles={modalStyles} >
	                <div className={"row"} style={{ height: '100vh',padding:'5px 0px', width: '1185px', overflow: 'hidden',zIndex:'9999',}} >
	                	<div className={"col-sm-8 col-lg-8 col-md-8"} style={{padding:'20px', background:'#fff'}}>
	                		{/*<div style={{position:'absolute',top:'5px',right:'10px',padding:'0px'}}>
	                			<a href={"#"} title="Close" style={{fontSize:'12px', color:'#333', textDecoration:'none'}} onClick={this.onCloseModal}>
	                				<img src={require("../../assets/icons/close.png")} style={{width:'8px',marginTop:'-2px'}} title="Close" />
	                				 &nbsp;Close
	                			</a>
	                		</div>
							*/}
	                		<div id="myGrid" style={{ height: "580px",width: "100%",marginTop:'10px'}}  className="ag-theme-balham">
		          		          <AgGridReact
		          		            modules={this.state.modules}
		          		            columnDefs={columnwithDefs}
		          		            defaultColDef={this.state.defaultColDef}
		          		            rowData={this.state.rowData}
		          		            enableCharts={true}
		          		            enableRangeSelection={true}
		          		            autoGroupColumnDef={this.state.autoGroupColumnDef}
		          		            onGridReady={this.onGridReady}
		          		            onGridState={this.onGridState}
		          		            statusBar={this.state.statusBar}
									sideBar={this.state.sideBar}
									paginationPageSize={this.state.paginationPageSize}
									pagination={true}
									frameworkComponents={this.state.frameworkComponents}
									detailCellRendererParams={this.state.detailCellRendererParams}
									//editType={this.state.editType}
									stopEditingWhenGridLosesFocus={true}
									floatingFilter={false}
									enableCellChangeFlash={true}
									suppressCellFlash={true}
									rowClassRules={this.state.rowClassRules}
									//onCellClicked={this.onRowClicked.bind(this)}
									gridOptions={{
										context: { componentParent: this }
									}}
									masterDetail={true}
									overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
		          		          />

									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
									</div>

		          		        </div>
	                	</div>
	                </div>
	           </Modal>			
			</div>
        )
    }
}

function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function timeConvert(n) {
	var num = n;
	var hours = (num / (3600));
	var rhours = parseInt(hours);
	var minutes = (num-(rhours*3600)) / (60);
	var rminutes = Math.round(minutes);
	return rhours + " hour(s) and " + rminutes + " minute(s).";
}   

function intervals(startString, endString) {
    var start = moment(startString, 'DD-MM-YYYY HH:mm:ss');
    var end = moment(endString, 'DD-MM-YYYY HH:mm:ss');

    start.minutes(Math.ceil(start.minutes() / 15) * 15);

    var result = [];

    var current = moment(start);

    while (current <= end) {
        result.push(current.format('DD-MM-YYYY HH:mm'));
        current.add(15, 'minutes');
    }

    return result;
}


function secondsToString(seconds)
{
	var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}


function getPolygonCoords()
{
	
	 var len = geoFencingArea.getPath().getLength();
	 console.log(len);
	 var htmlStr = "";
	 coordinates=[];
	 for (var i = 0; i < len; i++) {
		// var latlngd = {"lat":parseFloat(each_lat_lng[0]),"lng":parseFloat(each_lat_lng[1])};
		// coordinates.push()
		 var strlatlng = geoFencingArea.getPath().getAt(i).toUrlValue(5);
		 var spliting = strlatlng.split(",");
		 var latlngd = {"lat":parseFloat(spliting[0]),"lng":parseFloat(spliting[1])};
		 coordinates.push(latlngd);
	  // htmlStr += "new google.maps.LatLng(" + geoFencingArea.getPath().getAt(i).toUrlValue(5) + "), ";
	   //Use this one instead if you want to get rid of the wrap > new google.maps.LatLng(),
	   //htmlStr += "" + geoFencingArea.getPath().getAt(i).toUrlValue(5);
	 }
	// console.log("htmlStr", coordinates);
	 
}


function groupBy(list, keyGetter) {
    const map11 = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map11.get(key);
         if (!collection) {
             map11.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}
function setBg(){
	const randomColor = Math.floor(Math.random()*16777215).toString(16);
	return "#"+randomColor
  }
export default GeofenceMap;