
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import _ from "lodash";
import Modal from 'react-responsive-modal';
import SweetAlert from 'react-bootstrap-sweetalert';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import Select from 'react-select';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import axios from 'axios';
import Autosuggest from 'react-autosuggest';
import Grid from '../layouts/gridActionComponent';
import Breadcrumb from '../common/breadcrumb.component';
import EditItem from '../layouts/editActionComponent';
import { event } from 'react-ga';
import FILTER from "../../assets/icons/FILTER.png"
import GridButton from '../layouts/gridBtn';
import {
	getDDMMYYYY,
	getDDMMYYYYHHMMSS,
	getYYYYMMDD,
	getYYYYMMDDHHMMSS,
	getHyphenYYYYMMDDHHMMSS,
	getHyphenDDMMMYYYYHHMM,
	getHyphenYYYYMMDD,
	getHyphenDDMMYYYYHHMMSS,
	getHyphenDDMMYYYY,
	getDDMMMYYYYHHMMDefault
} from '../common/utils';
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
var moment = require('moment');
export default class HoldReports extends Component {
	constructor(props) {
		super(props);
		const today = moment().utcOffset("+05:30").subtract(7, 'days').format("DD-MM-YYYY"); // Get today's date with +05:30 offset
		const endDate = moment().utcOffset("+05:30").format("DD-MM-YYYY"); // Get tomorrow's date with +05:30 offset
		this.state = {
			lists: [],
			departmentCode: null,
			currentDepartmentTitle: null,
			currentAddGeoFenceLink: null,
			usermanualmodal: false,
			departmentCode: null,
			currentDepartmentTitle: null,
			currentAddGeoFenceLink: null,
			pagetitle: "Hold Request",
			rowData: [],
			show1: false,
			basicTitle1: '',
			basicType1: "default",
			show: false,
			basicTitle: '',
			basicType: "default",
			loadshow: "show-n",
			from_date: "",
			to_date: "",
			noOfVehicles: 0,
			reasonForHold: '',
			approvalEmailId: "",
			requesterEmail: '',
			salesPlannerEmail: '',
			oblEmail: '',
			emailIds: {
				approvalEmailId: "",
				requesterEmail: '',
				salesPlannerEmail: '',
				oblEmail: '',
			},
			errors: {},
			startDate: today, // Set default to today
			endDate: endDate, // Set default to tomorrow
			rowData: [],
			sliderBulkUpdate: "",
			loadshow: 'show-n',
			overly: 'show-n',
			defaultColDef: {
				sortable: true,
				filter: true,
				resizable: true,
				pivot: true,
				enableValue: true,
				// enableRowGroup: true,
			},
			modules: AllModules,
			animateRows: true,
			debug: true,
			showToolPanel: false,
			uppressAggFuncInHeader: true,
			childconfs: [],
			childrow: [],
			rowModelType: "serverSide",
			paginationPageSize: 100,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			rowData: [],
			maprowData: null,
			rowSelection: 'single',
			rowHeight: 32,
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			overlayNoRowsTemplate: "",
			statusBar: {
				statusPanels: [
					{
						statusPanel: "agTotalAndFilteredRowCountComponent",
						align: "left"
					},
					{
						statusPanel: "agTotalRowCountComponent",
						align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
						id: "columns",
						labelDefault: "Columns",
						labelKey: "columns",
						iconKey: "columns",
						toolPanel: "agColumnsToolPanel"
					},
					{
						id: "filters",
						labelDefault: "Filters",
						labelKey: "filters",
						iconKey: "filter",
						toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams: {},
			rowClassRules: {},
			frameworkComponents: {
				GridButton: GridButton,
			},
			errrorRowData: "",
			inplant: "btn-info",
			yard: "btn-default",
			matrixFile: null,
			showFilters: false,
			inplant: "btn-info",
			yard: "btn-default",
			selected_hold_option: { value: "All", label: "All" },
			hold_options: [{ value: "All", label: "All" }],
			inputValue: '',
			sliderExtendClearance: "",
			activeRow: "",
			selectedClearanceDate: "",
			usergridstate: [],
			pagetitle: "",
			activeRowRemarks: "",
			extension_yard_cost: 0,
		};
	}
	async componentDidMount() {
		try {
			let params = {}
			let response = await redirectURL.post("/consignments/getholdfiltersdata", params);
			let { hold_ids } = response.data
			let hold_options = [{ value: "All", label: "All" }]
			if (response.data) {
				hold_options = [...hold_options, ...hold_ids.map(item => ({ value: item, label: item }))]
			}
			// console.log(hold_options, 'hold_options')
			this.setState({
				hold_options: hold_options
			})
			this.getHoldReport()
			redirectURL.post("/consignments/usergridstates", { userId: localStorage.getItem("userid"), screenurl: window.location.pathname })
				.then(async (resp) => {
					await this.setState({
						usergridstate: resp.data
					});
					await this.restoreGridStates();
				})
		} catch (e) {
			console.log(e, 'e')
		}
	}
	handlerStartDateTime = (event, currentDate, selectedDate) => {
		var startDate = moment(event._d);  // Moment.js object for the selected start date		
		// Format the start date in 'DD-MM-YYYY' format using Moment.js
		var formattedStartDate = startDate.format('DD-MM-YYYY');
		if (this.state.endDate) {
			var endDate = moment(this.state.endDate, 'DD-MM-YYYY').endOf('day');
			// Compare start date and end date
			if (startDate.isAfter(endDate)) {
				this.setState({
					startDate: "",
					show: true,
					basicTitle: "From Date should be greater than From Date",
					basicType: 'danger'
				});
			} else {
				this.setState({
					startDate: formattedStartDate
				});
			}
		} else {
			this.setState({
				startDate: formattedStartDate
			});
		}
	};
	handlerEndDateTime = (event, currentDate, selectedDate) => {
		var endDate = moment(event._d).endOf('day');  // Moment.js object for the selected end date	
		// Format the end date in 'DD-MM-YYYY' format using Moment.js		
		var formattedEndDate = endDate.format('DD-MM-YYYY');
		if (this.state.startDate) {
			var startDate = moment(this.state.startDate, "DD-MM-YYYY").startOf('day');
			// Compare start date and end date
			if (startDate.isAfter(endDate)) {
				this.setState({
					endDate: "",
					show: true,
					basicTitle: "To Date should be greater than From Date",
					basicType: 'danger'
				});
			} else {
				this.setState({
					endDate: formattedEndDate
				});
			}
		} else {
			this.setState({
				endDate: formattedEndDate
			});
		}
	};
	getHoldReport = async (e) => {
		try {
			if (e) { e.preventDefault() }
			this.setState({
				loadshow: "show-m",
				overly: "show-m"
			})
			let { startDate, endDate } = this.state
			let hold_type = this.state.yard == "btn-info" ? 2 : 1
			// if (this.state.yard == "btn-info") {
			// 	this.setState({
			// 		rowData: [],
			// 		loadshow: "show-n",
			// 		overly: "show-n"
			// 	})
			// 	return null // Curently there is no data for the yard thats why be defulat returning null here 
			// }
			if (startDate && endDate) {
				let params = {
					from_date: startDate,
					to_date: endDate,
					hold_type: hold_type,
				}
				let { selected_hold_option } = this.state
				if (!['', 'all', "All", "ALL", undefined, 'undefined', null].includes(selected_hold_option.value)) {
					params.hold_id = selected_hold_option.value
				}
				let response = await redirectURL.post("/consignments/getholdreportdata", params);
				let data = response.data || []
				this.setState({
					rowData: data,
					loadshow: "show-n",
					overly: "show-n"
				})
			} else {
				this.setState({
					show: true,
					basicTitle: "Dates cannot be empty",
					basicType: 'danger',
					loadshow: "show-n",
					overly: "show-n"
				})
			}
		} catch (e) {
			console.log("Error ", e)
			this.setState({
				loadshow: "show-n",
				overly: "show-n"
			})
		}
	}
	closeAlert = () => {
		this.setState({
			show: false
		});
	}
	toggleFilters = () => {
		this.setState(prevState => {
			return {
				showFilters: !prevState.showFilters
			};
		});
	}
	onClickHideAll() {
		this.setState({
			loadshow: 'show-n',
			overly: 'show-n',
			sliderExtendClearance: "",
			activeRow: "",
			selectedClearanceDate: "",
			activeRowRemarks: "",
			extension_yard_cost: 0,
		});
	}
	onClickTab = (value) => {
		if (value == "inplant") {
			this.setState({
				inplant: "btn-info",
				yard: "btn-default",
			}, () => {
				this.getHoldReport()
			})
		} else if (value == "yard") {
			this.setState({
				inplant: "btn-default",
				yard: "btn-info",
			}, () => {
				this.getHoldReport()
			})
		}
	}
	onSelectionChanged = (event) => {
		const selectedRows = event.api.getSelectedRows();
		if (selectedRows.length === 1) {
			// Redirect to a new page based on the hold_id
			const hold_id = selectedRows[0].hold_id;
			window.open(`/hold-data/${hold_id}`, '_blank');
		}
	};
	viewOrderDetails = async (event) => {
		try {
			var data = event.data;
			if (data) {
				// Redirect to a new page based on the hold_id
				const hold_id = data.hold_id;
				window.open(`/hold-data/${hold_id}`, '_blank');
			}
		} catch (error) {
			console.log("Error")
		}
	}
	// Function to filter and limit options based on search input
	filterOptions = (inputValue) => {
		const filteredOptions = this.state.hold_options.filter(option =>
			option.label.toLowerCase().includes(inputValue.toLowerCase())
		);
		// Limit to 100 options
		return filteredOptions.slice(0, 100);
	};
	onCellClicked = async (params) => {
		try {
			// console.log(params, 'params')
			let { hold_id, clearance_date, cr_clearance_date, is_request_approved, extension_status } = params.data
			let selectedClrance = cr_clearance_date ? cr_clearance_date : clearance_date
			let colField = params.colDef.field
			// Added Validations for the Extension and the Unhold options when clicked on the cell
			let validateUnHold = is_request_approved != 0 && is_request_approved != 2 || !is_request_approved
			let validateExtension = is_request_approved != 0 & extension_status != 2 && is_request_approved != 2 && is_request_approved
			if (colField == "un_hold" && validateUnHold) {
				let cnf = window.confirm("Are you sure you want to request for Unhold?");
				if (cnf == true) {
					// Prompt the user to enter the reason
					let reason = prompt("Please enter the reason for requesting the unhold:");
					// Check if the reason is entered
					if (reason && reason.trim() !== "") {
						let params = {
							hold_id: hold_id,
							performed_by: localStorage.getItem("email") || "",
							action: 'Unholding the Hold Records',
							reason: reason.trim() // Add the reason to the params
						};
						// Show loading indicator
						this.setState({
							loadshow: 'show-m',
							overly: 'show-m'
						});
						try {
							// Send the unhold request with reason
							let sendUnholdMail = await redirectURL.post("/consignments/sendunholdemail", params);
							// Show success message
							this.setState({
								show: true,
								basicTitle: 'Unhold Successfully',
								basicType: "success",
								loadshow: 'show-n',
								overly: 'show-n'
							}, () => {
								this.getHoldReport();
							});
						} catch (error) {
							// Handle error if the request fails
							console.error('Error sending unhold request:', error);
							this.setState({
								loadshow: 'show-n',
								overly: 'show-n',
								basicTitle: 'Failed to  Unhold ',
								basicType: 'error'
							});
						}
					} else {
						// If no reason is entered
						this.setState({
							show: true,
							basicTitle: 'Reason for the unhold request is required!',
							basicType: "danger",
						})
					}
				}
			} else if (colField == "extend_clearance_date" && validateExtension) {
				this.setState({
					sliderExtendClearance: "slider-translate",
					overly: 'show-m',
					activeRow: params.data,
					selectedClearanceDate: moment(selectedClrance).format('DD-MM-YYYY HH:mm')
				})
			}
		} catch (e) {
			console.log("Error : ", e)
			this.setState({
				loadshow: 'show-n',
				overly: 'show-n'
			})
		}
	}
	handlerClaranceDate = (event) => {
		try {
			var endDate = moment(event._d);  // Moment.js object for the selected end date	
			// Format the end date in 'DD-MM-YYYY' format using Moment.js	
			var formattedEndDate = endDate.format('DD-MM-YYYY HH:mm');
			let { clearance_date, cr_clearance_date, activeRow } = this.state
			let crClearanceDate = cr_clearance_date ? cr_clearance_date : clearance_date
			let validteDate = moment(moment(crClearanceDate)).isBefore(endDate)
			let diffDays = endDate.diff(activeRow.start_date, 'hours');
			let roundedDiffDays = Math.ceil(diffDays / 24);  // Divide by 24 and round up
			let expectedCost = roundedDiffDays * parseInt(activeRow.no_of_vehicles) * 450 - activeRow.yard_cost || 0
			// console.log(crClearanceDate , '\n ', endDate)
			if (!validteDate) {
				this.setState({
					show: true,
					basicTitle: 'The Extended Clearce Date Should be Greater than Current clearance Date',
					basicType: 'danger'
				})
			} else {
				this.setState({ selectedClearanceDate: formattedEndDate, extension_yard_cost: expectedCost })
			}
		} catch (e) {
			console.log("Error : ", e)
		}
	};
	updateClearanceDate = async (e) => {
		try {
			e.preventDefault()
			let { activeRow, selectedClearanceDate, activeRowRemarks, extension_yard_cost } = this.state
			let { clearance_date, cr_clearance_date } = activeRow
			let crClearanceDate = cr_clearance_date ? cr_clearance_date : clearance_date
			let validteDate = moment(crClearanceDate).isBefore(moment(selectedClearanceDate, 'DD-MM-YYYY HH:mm'))
			// console.log(crClearanceDate , '\n ',selectedClearanceDate )
			if (!validteDate) {
				this.setState({
					show: true,
					basicTitle: 'The Extended Clearce Date Should be Greater than Current clearance Date',
					basicType: 'danger'
				})
			} else if (!activeRowRemarks) { // Validation for Extension Remarks
				this.setState({
					show: true,
					basicTitle: 'Please enter the remarks for the extension',
					basicType: 'danger'
				})
			} else {
				// console.log(activeRow, selectedClearanceDate)
				let params = { hold_id: activeRow.hold_id, expected_clearance_date: selectedClearanceDate, performed_by: localStorage.getItem("email") || "", extension_remarks: activeRowRemarks }
				this.setState({
					loadshow: 'show-m',
					overly: 'show-m',
				})
				if (this.state.yard == 'btn-info') {
					params.extension_yard_cost = extension_yard_cost
				}
				let updateDate = await redirectURL.post("/consignments/sendMailtoExtendClearanceDate", params)
				this.setState({
					show: true,
					basicTitle: "Mail sent Succussfully",
					basicType: 'success',
					activeRow: "",
					selectedClearanceDate: "",
					loadshow: 'show-n',
					overly: 'show-n',
					sliderExtendClearance: "",
					activeRowRemarks: "",
					extension_yard_cost: 0,
				}, () => {
					this.getHoldReport();
				})
			}
		} catch (e) {
			console.log("Error :")
			this.setState({
				loadshow: 'show-n',
				overly: 'show-n',
			})
		}
	}
	onClickSaveGridState() {
		try {
			window.colState = this.gridColumnApi.getColumnState();
			window.groupState = this.gridColumnApi.getColumnGroupState();
			window.sortState = this.gridApi.getSortModel();
			window.filterState = this.gridApi.getFilterModel();
			let screenpage = 'Hold Data ';
			let reqparams = {
				gridcolumns: window.colState,
				gridgroup: window.groupState,
				gridcolsort: window.sortState,
				gridcolfilter: window.filterState,
				userId: localStorage.getItem("userid"),
				screenurl: window.location.pathname,
				screentitle: screenpage
			}
			redirectURL.post("/consignments/saveGridStates", reqparams)
				.then((response) => {
					this.setState({
						show: true,
						basicTitle: "Successfully saved grid layout",
						basicType: "success",
						screenurl: window.location.pathname,
						screentitle: screenpage
					})
				})
				.catch(function (e) {
				})
		} catch (e) {
			console.log("Error :", e)
		}
	}
	restoreGridStates() {
		try {
			if (this.state.usergridstate.length > 0) {
				var windowstates = this.state.usergridstate;
				this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
				this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
				this.gridApi.setSortModel(windowstates[0].gridcolsort);
				this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
			}
		} catch (e) {
			console.log("Error :", e)
		}
	}
	resetState = () => {
		try {
			this.gridColumnApi.resetColumnState();
			this.gridColumnApi.resetColumnGroupState();
			this.gridApi.setSortModel(null);
			this.gridApi.setFilterModel(null);
			var reqparams = {
				userId: localStorage.getItem("userid"),
				screenurl: window.location.pathname,
				screentitle: this.state.screenpage
			}
			redirectURL.post("/consignments/removeUserGridState", reqparams)
				.then((response) => {
					this.setState({
						show: true,
						basicTitle: "Successfully reset default grid layout",
						basicType: "success"
					})
				})
		} catch (e) {
			console.log("Error :", e)
		}
	};
	onGridReady = params => {
		try {
			this.gridApi = params.api;
			this.gridColumnApi = params.columnApi;
		} catch (e) {
			console.log("Error :", e)
		}
	};
	resetFilters = () => {
		this.setState({
			selected_hold_option: { value: "All", label: "All" },
			startDate: moment().utcOffset("+05:30").subtract(7, 'days').format("DD-MM-YYYY"),
			endDate: moment().utcOffset("+05:30").format("DD-MM-YYYY"),
			showFilters: false,
		}, () => {
			this.getHoldReport()
		})
	}
	render() {
		const {
			rowData,
			inplant
		} = this.state;
		let hideForYard = inplant == 'btn-info' ? false : true
		let columnDefs = [
			{
				pinned: "left",
				headerName: "",
				field: "hold_id",
				width: 55,
				resizable: true,
				editable: false,
				params: {
					buttonName: '', iconName: 'fa fa-eye', buttonCls: 'btn btn-info',
					onClickFunction: this.viewOrderDetails
				},
				cellRendererSelector: function (params) {
					var rendComponent = {
						component: "GridButton"
					};
					return rendComponent
				},
			},
			{
				headerName: 'Hold Id',
				validation: (value) => value && value.trim() !== '',
				field: 'hold_id',
				editable: false,
				width: 140,
			},
			{
				headerName: 'Hold Status',
				validation: (value) => value && value.trim() !== '',
				field: 'is_request_approved',
				editable: false,
				width: 120,
				cellClass: function (params) {
					if (params.data && params.data.is_request_approved == 1) {
						return "bgcolorsuccesgreen";
					} else if (params.data && params.data.is_request_approved == 0) {
						return "boxBgLighRed";
					} else if (params.data && params.data.is_request_approved == 2) {
						return "bgcolorsuccesgreenRelease";
					} else {
						return "boxbgblue5"
					}
				},
				valueGetter: params => {
					try {
						let { is_request_approved } = params.data
						let status = "Pending"
						if (is_request_approved == 1) {
							status = "Approved"
						} else if (is_request_approved == 0) {
							status = "Rejected"
						} else if (is_request_approved == 2) {
							status = "Released"
						}
						return status
					} catch (e) {
						console.log("Error ", e)
					}
				}
			},
			{
				headerName: 'Approver Email',
				validation: (value) => value && value.trim() !== '',
				field: 'approver_email',
				editable: false,
				width: 180,
				hide: hideForYard,
				suppressColumnsToolPanel: hideForYard,
			},
			{
				headerName: 'Requester L4 Email',
				validation: (value) => value && value.trim() !== '',
				field: 'requester_l4_mail',
				editable: false,
				width: 180,
				hide: !hideForYard,
				suppressColumnsToolPanel: !hideForYard,
			},
			{
				headerName: 'Logistics Controller Email',
				validation: (value) => value && value.trim() !== '',
				field: 'controller_mail',
				editable: false,
				width: 180,
				hide: !hideForYard,
				suppressColumnsToolPanel: !hideForYard,
			},
			{
				headerName: 'Expected Yard Cost',
				validation: (value) => value && value.trim() !== '',
				field: 'yard_cost',
				editable: false,
				width: 180,
				hide: !hideForYard,
				suppressColumnsToolPanel: !hideForYard,
			},
			{
				headerName: 'Requester Email',
				validation: (value) => value && value.trim() !== '',
				field: 'requester_email',
				editable: false,
				width: 180,
			},
			{
				headerName: 'Sales Planner Email',
				validation: (value) => value && value.trim() !== '',
				field: 'sales_planner_email',
				editable: false,
				width: 180,
			},
			{
				headerName: 'Obl Email',
				validation: (value) => value && value.trim() !== '',
				field: 'obl_email',
				editable: false,
				width: 180,
			},
			{
				headerName: 'No of Vehicles',
				validation: (value) => value && value.trim() !== '',
				field: 'no_of_vehicles',
				editable: false,
				width: 120,
			},
			{
				headerName: 'Reason',
				validation: (value) => value && value.trim() !== '',
				field: 'reason',
				editable: false,
				width: 160,
			},
			{
				headerName: "Unhold",
				field: "un_hold",
				width: 100,
				resizable: true,
				editable: false,
				cellRenderer: (params) => {
					try {
						let { clearance_date, cr_clearance_date, is_request_approved } = params.data;
						let cnce_date = clearance_date
						if (cr_clearance_date) {
							cnce_date = cr_clearance_date
						}
						let isValidDate = moment(cnce_date).isAfter(moment().utcOffset("+05:30"));
						if (isValidDate && is_request_approved != 0 && is_request_approved != 2 || !is_request_approved) {
							return '<button class="btn btn-primary unhold-btn" title="Unhold" style="border-radius: 6px; width:70px; padding:5px;">Unhold</button>';
						} else {
							return '<button disabled class="btn btn-primary unhold-btn" title="Unhold" style="cursor: not-allowed; border-radius: 6px; width:70px; padding:5px;">Unhold</button>';
						}
					} catch (e) {
						console.error("Error in cellRenderer:", e);
						return ''; // Return empty string in case of error
					}
				}
			},
			{
				headerName: "Extend Clearance Date",
				field: "extend_clearance_date",
				width: 140,
				resizable: true,
				editable: false,
				cellRenderer: (params) => {
					try {
						let { clearance_date, is_request_approved, extension_status, cr_clearance_date } = params.data;
						let cnce_date = clearance_date
						if (cr_clearance_date) {
							cnce_date = cr_clearance_date
						}
						let isValidDate = moment(cnce_date).isAfter(moment().utcOffset("+05:30"));
						if (isValidDate && is_request_approved != 0 && extension_status != 2 && is_request_approved != 2 && is_request_approved) {
							return '<button title="Extend Clearance Date" class="btn btn-warning unhold-btn" style="border-radius: 6px; width:70px; padding:5px;">Extend</button>';
						} else {
							return '<button title="Extend Clearance Date" disabled class="btn btn-warning unhold-btn" style="cursor: not-allowed; border-radius: 6px; width:70px; padding:5px;">Extend</button>';
						}
					} catch (e) {
						console.error("Error in cellRenderer:", e);
						return ''; // Return empty string in case of error
					}
				}
			},
			{
				headerName: 'Requester L4 Status',
				validation: (value) => value && value.trim() !== '',
				field: 'is_l4_approved',
				editable: false,
				width: 180,
				hide: !hideForYard,
				suppressColumnsToolPanel: !hideForYard,
				cellClass: function (params) {
					if (params.data && params.data.is_l4_approved == 1) {
						return "bgcolorsuccesgreen";
					} else if (params.data && params.data.is_l4_approved == 0) {
						return "boxBgLighRed";
					} else {
						return "boxbgblue5"
					}
				},
				valueGetter: params => {
					try {
						let { is_l4_approved } = params.data
						let status = 'Pending'
						if (is_l4_approved == 1) {
							status = "Approved"
						} else if (is_l4_approved == 0) {
							status = "Rejected"
						}
						return status
					} catch (e) {
						console.log("Error :", e)
					}
				}
			},
			{
				headerName: 'Logistics Controller Status',
				validation: (value) => value && value.trim() !== '',
				field: 'controller_satus',
				editable: false,
				width: 180,
				hide: !hideForYard,
				suppressColumnsToolPanel: !hideForYard,
				cellClass: function (params) {
					if (params.data && params.data.controller_satus == 1) {
						return "bgcolorsuccesgreen";
					} else if (params.data && params.data.controller_satus == 0) {
						return "boxBgLighRed";
					} else if (params.data && params.data.controller_satus == 2) {
						return "boxbgblue5"
					}
				},
				valueGetter: params => {
					try {
						let { controller_satus } = params.data
						let status = ''
						if (controller_satus == 1) {
							status = "Approved"
						} else if (controller_satus == 0) {
							status = "Rejected"
						} else if (controller_satus == 2) {
							status = 'Pending'
						}
						return status
					} catch (e) {
						console.log("Error :", e)
					}
				}
			},
			{
				headerName: 'No of Times Extended',
				validation: (value) => value && value.trim() !== '',
				field: 'no_of_extensions',
				editable: false,
				width: 120,
				valueGetter: params => {
					try {
						let { no_of_extensions = 0 } = params.data
						return no_of_extensions
					} catch (e) {
						console.log("Error at:", e)
					}
				}
			},
			{
				headerName: 'Extension Status',
				validation: (value) => value && value.trim() !== '',
				field: 'extension_status',
				editable: false,
				width: 120,
				cellClass: function (params) {
					if (params.data && params.data.extension_status == 1) {
						return "bgcolorsuccesgreen";
					} else if (params.data && params.data.extension_status == 0) {
						return "boxBgLighRed";
					} else if (params.data && params.data.extension_status == 2) {
						return "boxbgblue5"
					}
				},
				valueGetter: params => {
					try {
						let { extension_status } = params.data
						let status = "N/A"
						if (extension_status == 1) {
							status = "Approved"
						} else if (extension_status == 0) {
							status = "Rejected"
						} else if (extension_status == 2) {
							status = "Pending"
						}
						return status
					} catch (e) {
						console.log("Error ", e)
					}
				}
			},
			{
				headerName: 'Extension Remarks',
				validation: (value) => value && value.trim() !== '',
				field: 'extension_remarks',
				editable: false,
				width: 120,
				valueGetter: params => {
					try {
						let { extension_remarks = "" } = params.data
						return extension_remarks
					} catch (e) {
						console.log("Error at:", e)
					}
				}
			},
			{
				headerName: 'Unhold Remarks',
				validation: (value) => value && value.trim() !== '',
				field: 'unhold_remarks',
				editable: false,
				width: 120,
				valueGetter: params => {
					try {
						let { unhold_remarks = "" } = params.data
						return unhold_remarks
					} catch (e) {
						console.log("Error at:", e)
					}
				}
			},
			{
				headerName: 'Start Date',
				validation: (value) => value && value.trim() !== '',
				field: 'start_date',
				editable: false,
				width: 160,
				valueGetter: params => {
					try {
						let { start_date } = params.data
						let result = getHyphenDDMMMYYYYHHMM(start_date)
						return result || ""
					} catch (e) {
						console.log("Error ", e)
					}
				}
			},
			{
				headerName: 'Intial Clearance Date',
				validation: (value) => value && value.trim() !== '',
				field: 'clearance_date',
				editable: false,
				width: 160,
				valueGetter: params => {
					try {
						let { clearance_date } = params.data
						let result = getHyphenDDMMMYYYYHHMM(clearance_date)
						return result || ""
					} catch (e) {
						console.log("Error ", e)
					}
				}
			},
			{
				headerName: 'Current Clearance Date',
				validation: (value) => value && value.trim() !== '',
				field: 'cr_clearance_date',
				editable: false,
				width: 160,
				valueGetter: params => {
					try {
						let { clearance_date, cr_clearance_date } = params.data
						let result
						if (cr_clearance_date) {

							result = getHyphenDDMMMYYYYHHMM(cr_clearance_date)
						} else {
							result = getHyphenDDMMMYYYYHHMM(clearance_date)
						}
						return result || ""
					} catch (e) {
						console.log("Error ", e)
					}
				}
			},
			{
				headerName: 'Expected Clearance Date',
				validation: (value) => value && value.trim() !== '',
				field: 'exp_clarance_date',
				editable: false,
				width: 160,
				valueGetter: params => {
					try {
						let { clearance_date, cr_clearance_date, exp_clarance_date } = params.data
						let result
						if (exp_clarance_date) {
							result = getHyphenDDMMMYYYYHHMM(exp_clarance_date)
						} else if (cr_clearance_date) {
							result = getHyphenDDMMMYYYYHHMM(cr_clearance_date)
						} else {
							result = getHyphenDDMMMYYYYHHMM(clearance_date)
						}
						return result || ""
					} catch (e) {
						console.log("Error ", e)
					}
				}
			},
			{
				headerName: 'Actual Clearance Date',
				validation: (value) => value && value.trim() !== '',
				field: 'clearance_date',
				editable: false,
				width: 160,
				valueGetter: params => {
					try {
						let { clearance_date, cr_clearance_date, ac_clearance_date, is_request_approved } = params.data
						let result
						if (ac_clearance_date) {
							result = getHyphenDDMMMYYYYHHMM(ac_clearance_date)
						} else if (cr_clearance_date) {
							result = getHyphenDDMMMYYYYHHMM(cr_clearance_date)
						} else {
							result = getHyphenDDMMMYYYYHHMM(clearance_date)
						}
						return is_request_approved == 2 ? result || "N/A" : "N/A"
						// return result || "N/A"
					} catch (e) {
						console.log("Error ", e)
					}
				}
			},
		]
		return (
			<div className="container-fluid">
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					onConfirm={this.closeAlert}
				/>
				<div className="row">
					<div className="col-xl-12 col-lg-12 pb-0 mb-0">
						<div className="pb-0 mb-0">
							<h5>
								<i className="icofont icofont-vehicle-delivery-van cus-i"></i>  <span>Hold Report</span>
							</h5>
							<div className="pb-0 mb-0" style={{ width: "100%", display: "flex", justifyContent: "space-between" }} >
								<div className="pb-0 mb-0">
									<button className={"btn " + this.state.inplant} onClick={this.onClickTab.bind(this, 'inplant')} style={{ borderRight: "1px solid #ccc", borderLeft: "1px solid #ccc" }}>In Plant</button>
									<button className={"btn " + this.state.yard} onClick={this.onClickTab.bind(this, 'yard')}>Yard</button>
								</div>
								<div className="row">
									<button
										type="button"
										onClick={this.resetFilters}
										className=""
										style={{
											color: "#000",
											border: "0px",
											background: "transparent",
											cursor: "pointer",
											fontSize: "15px",
											backgroundColor: 'white',
											marginRight: '1px',
											width: '34px',
											color: '',
										}}
										title='Reset Filters'
									>
										<i className="icofont icofont-refresh" style={{ color: '#2060c7 ', }}></i>
									</button>
									<button
										type="button"
										onClick={this.toggleFilters}
										className=""
										style={{
											color: "#000",
											// textDecoration: "underline",
											border: "0px",
											background: "transparent",
											cursor: "pointer",
											fontSize: "15px",
											backgroundColor: 'white',
											marginRight: '16px',
											color: '#4099ff ',
										}}
										title={this.state.showFilters ? "Hide Filters" : "Show Filters"}
									>
										<img src={FILTER} height={'24px'} width={'24px'} />
										{/* {this.state.showFilters ? "Hide Filters" : "Show Filters"} */}
									</button>
								</div>
							</div>
						</div>
						<div className="card">
							<div className="card-body" style={{ padding: "0px 20px 0px 20px", minHeight: "70vh" }}>
								<div className="card-body p-0 m-0" >
									<form id="filterForm" method="POST" className="row p-0 m-0" onSubmit={this.getHoldReport}>
										<div className="row" style={{ width: '100%', paddingRight: '2px', paddingBottom: '0px' }} >
											{/* Show filters if showFilters is true */}
											{this.state.showFilters && (
												<div style={{ display: 'flex', width: "80%", marginTop: '16px' }}>
													<div className="col-xl-3 col-lg-3 form-group">
														<label>From Date</label>
														<Datetime
															closeOnSelect={true}
															inputProps={{ placeholder: 'From', name: 'startDate', autoComplete: 'off', readOnly: true, value: this.state.startDate }}
															dateFormat="DD-MM-YYYY"
															timeFormat={false}
															onChange={this.handlerStartDateTime}
														/>
													</div>
													<div className="col-xl-3 col-lg-3 form-group">
														<label>To Date</label>
														<Datetime
															closeOnSelect={true}
															inputProps={{ placeholder: 'To', name: 'endDate', autoComplete: 'off', readOnly: true, value: this.state.endDate }}
															dateFormat="DD-MM-YYYY"
															timeFormat={false}
															onChange={this.handlerEndDateTime}
														/>
													</div>
													<div className="form-group col-xl-3 col-lg-3">
														<label style={{ marginBottom: '5px' }}>Hold Id</label>
														<Select
															className="border-radius-0"
															isMulti={false} // Set to `true` if you want multi-select
															id="holdIds"
															value={this.state.selected_hold_option} // Selected option
															onChange={(e) => this.setState({ selected_hold_option: e })} // Set the selected value to state
															options={this.filterOptions(this.state.inputValue)} // Pass the filtered options
															inputValue={this.state.inputValue} // Bind the input value for filtering
															onInputChange={(newInputValue) => this.setState({ inputValue: newInputValue })} // Update input value on search input change
															isSearchable={true} // Enable search functionality
														/>
													</div>
													<div className="form-group col-xl-1 col-lg-1" style={{ textAlign: "left", top: "30px" }}>
														<button type="submit" className="btn btn-success">Apply</button>
													</div>
												</div>
											)}
											<div className="float-right" style={{ width: this.state.showFilters ? "20%" : "100%", display: 'fkex', flexDirection: 'column', }}>
												<div
													className="float-right"
													style={{
														width: "100%",
														height: '100%',
														display: "flex", // Corrected typo
														flexDirection: "column",
														justifyContent: 'flex-end',
														alignItems: "flex-end", // Align items to the right
														marginRight: "-12px",
														paddingBottom: '4px',

													}}
												>
													<div className="row" style={{ marginTop: "10px" }}> {/* Add margin for spacing */}
														<button
															className="custom-btn white btn-danger"
															onClick={this.onClickSaveGridState.bind(this)}
															style={{ height: "30px", marginRight: "10px" }}
														>
															<i className="icofont icofont-save"></i> Save Grid
														</button>
														<button
															className="custom-btn white btn-info"
															style={{ marginRight: "10px", height: "30px" }}
															onClick={this.resetState.bind(this)}
														>
															<i className="icofont icofont-refresh"></i> Reset Grid
														</button>
													</div>
												</div>
											</div>
										</div>
									</form>
								</div>
								{/* { Enabling the Grid for Yard Data also 
									this.state.inplant == "btn-info" ? */}
								<div className="card-body" style={{ padding: '0px' }}>
									<div className="row" style={{ textAlign: 'right' }}>
										{/* <div className="col-sm-12">
											<button className="float-right custom-btn white btn-danger" onClick={this.onClickSaveGridState.bind(this)}>
												<i className="icofont icofont-save"></i> Save Grid
											</button>
											<button className="float-right custom-btn white btn-info" style={{ marginRight: "10px" }} onClick={this.resetState.bind(this)}>
												<i className="icofont icofont-refresh"></i> Reset Grid
											</button>
										</div> */}
									</div>
								</div>
								<div id="myGrid" style={{ height: "580px", width: "100%" }} className="ag-theme-balham">
									<AgGridReact
										modules={this.state.modules}
										columnDefs={columnDefs} // Assuming this is a state variable for columns
										defaultColDef={this.state.defaultColDef}
										rowData={rowData}
										sideBar={this.state.sideBar}
										enableCharts={true}
										enableRangeSelection={true}
										autoGroupColumnDef={this.state.autoGroupColumnDef}
										onGridReady={this.onGridReady}
										pagination={true}
										frameworkComponents={this.state.frameworkComponents}
										stopEditingWhenGridLosesFocus={true}
										enableCellChangeFlash={false}
										rowClassRules={this.state.rowClassRules}
										gridOptions={{
											context: { componentParent: this }
										}}
										masterDetail={true}
										rowHeight={this.state.rowHeight}
										rowSelection={this.state.rowSelection}
										suppressRowClickSelection={true}
										overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
										excelStyles={this.state.excelStyles}
										editable={false}  // Enable editing for all cells
										onSelectionChanged={this.onSelectionChanged}
										onCellClicked={this.onCellClicked}
									/>
								</div>
								{/* : ""
								} */}
							</div>
						</div>
					</div>
				</div>
				<div className={"slide-r " + (this.state.sliderExtendClearance)} style={{ overflow: "auto", }}>
					<div className="slide-r-title">
						<h4>
							Extend Clearance Date
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>
						<div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', overflow: 'hidden', zIndex: '9999', height: "100em" }} >
							<form method="POST" onSubmit={this.updateClearanceDate} className="theme-form col-xl-12 col-lg-12">
								<div className="col-xl-10 col-lg-10 form-group">
									<label>Clearance Date</label>
									<Datetime
										value={this.state.selectedClearanceDate}
										closeOnSelect={true}
										inputProps={{ placeholder: 'To', name: 'endDate', autoComplete: 'off', readOnly: true }}
										dateFormat="DD-MM-YYYY"
										timeFormat="HH:mm"
										onChange={this.handlerClaranceDate}
									/>
								</div>
								{this.state.yard == 'btn-info' &&
									<>
										<div className="col-xl-10 col-lg-10 form-group" style={{ cursor: 'not-allowed' }}>
											<label>Current Yard Cost</label>
											<p style={{ fontSize: '20px', color: '#DC143C', backgroundColor: '#ffffff', border: '1px solid #c1c1c1', padding: '0px 8px 0px 8px ', borderRadius: '6px' }}>
												{this.state.activeRow.yard_cost || 0}
											</p>
										</div>
										<div className="col-xl-10 col-lg-10 form-group" style={{ cursor: 'not-allowed' }}>
											<label>Extension Yard Cost</label>
											<p style={{ fontSize: '20px', color: '#DC143C', backgroundColor: '#ffffff', border: '1px solid #c1c1c1', padding: '0px 8px 0px 8px ', borderRadius: '6px' }}>
												{this.state.extension_yard_cost || 0}
											</p>
										</div>
									</>
								}
								<div className="col-xl-10 col-lg-10 form-group">
									<label>Remarks <span style={{ color: "red" }}>*</span></label>
									<textarea style={{ width: '100%' }} placeholder="Enter Reamarks" onChange={(e) => {
										this.setState({ activeRowRemarks: e.target.value })
									}} value={this.state.activeRowRemarks}></textarea>
								</div>
								<div className="form-group col-xl-1 col-lg-1" style={{ textAlign: "left", top: "30px" }}>
									<button type="submit" className="btn btn-success">Submit</button>
								</div>
							</form>
						</div>
					</div>
				</div>
				<div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
				<div className={"dataLoadpage " + (this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " + (this.state.loadshow)}>
					<div className="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
				</div>
			</div>
		);
	}
}	
