
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');
var moment = require('moment');

 
const ViewPODFileAction = (props) => {
    const handleOverspeedClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onClickUpdateItem(props);
    };
    return (
        <div>
            {(props.data.customer_lr_doc != "")?
                <span>
                    {(props.data.customer_lr_doc != "" && props.data.customer_lr_doc != undefined && props.data.customer_lr_doc_path != "" && props.data.customer_lr_doc_path != undefined)?
                        <span>
                            <a href={props.data.customer_lr_doc_path} target="_blank" style={{color:"green",fontSize:"11px"}}>View Documnet</a>
                        </span>
                    :""}
                    
                </span>
            :""
            }
        </div>
    );
};

export default ViewPODFileAction;