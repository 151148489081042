
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const AccidentIcon = (props) => {
    const handleEventClick = (e) => {
        e.stopPropagation();
        //console.log("Overspeed ",props);
        props.context.componentParent.onShowAccidentForm(props);
    };
    
//    console.log(props.data.accident_exception,"props.data.accident_exception")
    return (
        <div>
            <button onClick={handleEventClick} className="custom-btn f22 label label-success" 
            title="Accident" >
                {
                (props.data.accident_exception !== "" && props.data.accident_exception !== undefined)?
                (props.data.accident_exception_text === "Yes" || props.data.accident_exception === 1)?
                    <img src={require("../../assets/icons/car-crash.png")} style={{width:"35px",marginTop:"-5px"}} />
                :
                    <img src={require("../../assets/icons/car-crash.png")} style={{filter:"opacity(0.4)",width:"35px",marginTop:"-5px"}} />
                :    <img src={require("../../assets/icons/car-crash.png")} style={{filter:"opacity(0.4)",width:"35px",marginTop:"-5px"}} />
            }
            </button>
            
        </div>
    );
};

export default AccidentIcon;
