
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const AccidentDocs = (props) => {
    const handleEventClick = (e) => {
        e.stopPropagation();
        //console.log("Overspeed ",props);
        props.context.componentParent.onShowConfirmDevlier(props);
    };
    //}
    return (
        <div>
            {(props.data.tpt_delivere_confirm == 1)?
             
             (props.data.tpt_confirm_deliver_remarks)
             :   <button onClick={handleEventClick} className="f12 fbold btn btn-warning" style={{padding:"0 5px"}} title="Confirm Deliver" ><i className={"icofont icofont-tick-mark"}></i> Confirm Deliver</button>
            
            }
            
        </div>
    );
};

export default AccidentDocs;
