
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');
var moment = require('moment');

 
const ViewDoc2Btn = (props) => {
   
    // console.log("props.data.customer_lr_doc ", props.data)
    return (
        <span>
        {   (props.data.invoice_document_2 != "" && props.data.invoice_document_2 != undefined)?
            <span>
                <a href={props.data.invoice_document_2} target="_blank" className="f12"><i className="fa fa-eye"></i> View Document</a>
            </span>
            :""
        }
        </span>
    );
};

export default ViewDoc2Btn;
