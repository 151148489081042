
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');
const ViewAttachments = (props) => {
    const handleRouteClick = (e) => {
        try{
            e.stopPropagation();
            props.colDef.params.onClickViewAttachment(props);
        }catch(e){
            console.log("Error ",e)
        }
       
    };
    var downloadBtn = "#1976d2";
    var cursorPoint = "pointer";
    return (
        <div className="map-icon-component">
            <span onClick={handleRouteClick} className="custom-btn label label-success" title="Download Attachment"><i style={{ color: downloadBtn, cursor: cursorPoint, marginTop: "10%" }} className="fa fa-download f20"></i> </span>
        </div>
    );
};
export default ViewAttachments;
