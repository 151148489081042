/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import Autosuggest from 'react-autosuggest';

import Grid from '../layouts/gridActionComponent';
import Breadcrumb from '../common/breadcrumb.component';
import EditItem from '../layouts/editActionComponent';
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");

export default class Deviations extends Component {

    constructor(props) {
        super(props);

        this.state = {
			lists:[],
			departmentCode: null,
			currentDepartmentTitle: null,
			currentAddGeoFenceLink: null,
			usermanualmodal:false

        };
	}
	getCurrentDepartmentCode(pathToMatch = null) {
		let department = null;
		let departmentName = null;
		let currentAddGeoFenceLink = "/addDeviation";
		switch(pathToMatch) {
			case "/deviations":
				department='SNDG';
				departmentName = " (Sales and Dispatch) ";
				currentAddGeoFenceLink = "/addDeviation";
				break;
			default:
				console.log("IN getDepartmentFilter, default, params = ", pathToMatch);
		}
		this.setState({
			currentDepartmentTitle:departmentName,
			currentAddGeoFenceLink:currentAddGeoFenceLink
		});
		return department;
	}
    componentDidMount(){
		if (googleAnalytics.page.enableGA) {
			googleAnalytics.logPageView();
		}
		// get department code to filter records by department
		let currentDepartmentCode = this.getCurrentDepartmentCode(this.props.match.path);
		this.setState({
			departmentCode:currentDepartmentCode
		});
		var upath = "/masters/deviations";
        
		var parameters = {};
    	redirectURL.post(upath,parameters)
		.then(
			(response)=>{
				let records = (response.data.records) ? response.data.records : [];
				this.setState({
					lists:records
				});
			}
		)
		.catch(function(error){
			console.log(error);
		});
    }

    onCloseUserManualModal = () => {
        this.setState({ usermanualmodal: false });
    };

    onClickShowUserManaul = () => {
        this.setState({ usermanualmodal: true });
    };
    render(){
		const columnwithDefs = [
	  	        {
	    	          headerName: "Deviation Name",
	    	          field: "deviation_name",
	    	          width: 200

    	        },
                {
                    headerName: "Deviation Address",
                    field: "deviation_address",
                    width: 150
                 },
                 {
                     headerName: "Deviation City",
                     field: "deviation_city",
                     width: 150
                  },
          
                  {
                    headerName: "Deviation Zone",
                    field: "zone",
                    width: 150
                 },
                 {
                     headerName: "Deviation State",
                     field: "deviation_state",
                     width: 150
                  },
				  {
					  headerName: "Postal Code",
					  field: "deviation_postal_code",
					  width: 150
				   },
          
		        {
	  	        	field:'_id',
					headerName:'',
					width: 72,
					cellRenderer:function(params){
						var geoname = params.data._id;
						var htmloption = '<a href="/editDeviation/'+geoname+'" class="custom-btn label label-success"><i class="icofont icofont-edit"></i> Edit</a>';
						return htmloption;
					}
    	        },
		        

	      ];
        return (

    		<div className="container-fluid">
            	<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">

			                <div className="card-header">
				       			<h5>
				       				<i className="icon-map cus-i"></i> <span>Deviations</span>
				       				<a href={this.state.currentAddGeoFenceLink} className="btn btn-success pull-right cs-btn"> <i className="icon-plus"></i> Add Deviation</a>
				       			</h5>
				   			</div>
				   			<div className="card-body">
				   				<Grid actionMethod={"deleteGeo"} screenurl={"/manage/geofences"} actionCall={"geofence"} columnwithDefs={columnwithDefs} rowData={this.state.lists} />
				   			</div>
				   		</div>
				   	</div>
				 </div>
				
            </div>

        );
    }
}
