
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import _ from "lodash";
import Modal from 'react-responsive-modal';
import SweetAlert from 'react-bootstrap-sweetalert';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import Select from 'react-select';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import axios from 'axios';
import Autosuggest from 'react-autosuggest';
import Grid from '../layouts/gridActionComponent';
import Breadcrumb from '../common/breadcrumb.component';
import EditItem from '../layouts/editActionComponent';
import { event } from 'react-ga';
import FILTER from "../../assets/icons/FILTER.png"
import GridButton from '../layouts/gridBtn';
import ViewAttachments from "../layouts/viewattachments";
import {
    getDDMMYYYY,
    getDDMMYYYYHHMMSS,
    getYYYYMMDD,
    getYYYYMMDDHHMMSS,
    getHyphenYYYYMMDDHHMMSS,
    getHyphenDDMMMYYYYHHMM,
    getHyphenYYYYMMDD,
    getHyphenDDMMYYYYHHMMSS,
    getHyphenDDMMYYYY,
    getDDMMMYYYYHHMMDefault
} from '../common/utils';
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
var moment = require('moment');
export default class DeviationReport extends Component {
    constructor(props) {
        super(props);
        const today = moment().utcOffset("+05:30").subtract(7, 'days').format("DD-MM-YYYY"); // Get today's date with +05:30 offset
        const endDate = moment().utcOffset("+05:30").format("DD-MM-YYYY"); // Get tomorrow's date with +05:30 offset
        this.state = {
            lists: [],
            departmentCode: null,
            currentDepartmentTitle: null,
            currentAddGeoFenceLink: null,
            usermanualmodal: false,
            departmentCode: null,
            currentDepartmentTitle: null,
            currentAddGeoFenceLink: null,
            pagetitle: "Deviation Request",
            rowData: [],
            show1: false,
            basicTitle1: '',
            basicType1: "default",
            show: false,
            basicTitle: '',
            basicType: "default",
            loadshow: "show-n",
            from_date: "",
            to_date: "",
            noOfVehicles: 0,
            deviation_reason: '',
            approvalEmailId: "",
            requesterEmail: '',
            salesPlannerEmail: '',
            oblEmail: '',
            emailIds: {
                approvalEmailId: "",
                requesterEmail: '',
                salesPlannerEmail: '',
                oblEmail: '',
            },
            errors: {},
            startDate: today, // Set default to today
            endDate: endDate, // Set default to tomorrow
            rowData: [],
            sliderViewFiles: "",
            loadshow: 'show-n',
            overly: 'show-n',
            defaultColDef: {
                sortable: true,
                filter: true,
                resizable: true,
                pivot: true,
                enableValue: true,
                // enableRowGroup: true,
            },
            modules: AllModules,
            animateRows: true,
            debug: true,
            showToolPanel: false,
            uppressAggFuncInHeader: true,
            childconfs: [],
            childrow: [],
            rowModelType: "serverSide",
            paginationPageSize: 100,
            cacheBlockSize: 10,
            maxBlocksInCache: 1,
            rowData: [],
            maprowData: null,
            rowSelection: 'single',
            rowHeight: 32,
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            overlayNoRowsTemplate: "",
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            detailCellRendererParams: {},
            rowClassRules: {},
            frameworkComponents: {
                GridButton: GridButton,
                ViewAttachments: ViewAttachments,
            },
            errrorRowData: "",
            offroute: "btn-default",
            matrixFile: null,
            showFilters: false,
            enroute: "btn-info",
            offroute: "btn-default",
            selected_deviation_option: { value: "All", label: "All" },
            deviation_options: [{ value: "All", label: "All" }],
            inputValue: '',
            sliderExtendClearance: "",
            activeRow: "",
            selectedClearanceDate: "",
            usergridstate: [],
            pagetitle: "",
            activeRowRemarks: "",
            extension_yard_cost: 0,
            row_files: {},

        };
    }
    async componentDidMount() {
        try {
            let params = {}
            let response = await redirectURL.post("/consignments/getdeviationfilterkeys", params);
            let { deviation_ids } = response.data
            let deviation_options = [{ value: "All", label: "All" }]
            if (response.data) {
                deviation_options = [...deviation_options, ...deviation_ids.map(item => ({ value: item, label: item }))]
            }
            // console.log(deviation_options, 'deviation_options')
            this.setState({
                deviation_options: deviation_options
            })
            this.getDeviationReport()
            redirectURL.post("/consignments/usergridstates", { userId: localStorage.getItem("userid"), screenurl: window.location.pathname })
                .then(async (resp) => {
                    await this.setState({
                        usergridstate: resp.data
                    });
                    await this.restoreGridStates();
                })
        } catch (e) {
            console.log(e, 'e')
        }
    }
    handlerStartDateTime = (event, currentDate, selectedDate) => {
        var startDate = moment(event._d);  // Moment.js object for the selected start date		
        // Format the start date in 'DD-MM-YYYY' format using Moment.js
        var formattedStartDate = startDate.format('DD-MM-YYYY');
        if (this.state.endDate) {
            var endDate = moment(this.state.endDate, 'DD-MM-YYYY').endOf('day');
            // Compare start date and end date
            if (startDate.isAfter(endDate)) {
                this.setState({
                    startDate: "",
                    show: true,
                    basicTitle: "From Date should be greater than From Date",
                    basicType: 'danger'
                });
            } else {
                this.setState({
                    startDate: formattedStartDate
                });
            }
        } else {
            this.setState({
                startDate: formattedStartDate
            });
        }
    };
    handlerEndDateTime = (event, currentDate, selectedDate) => {
        var endDate = moment(event._d).endOf('day');  // Moment.js object for the selected end date	
        // Format the end date in 'DD-MM-YYYY' format using Moment.js		
        var formattedEndDate = endDate.format('DD-MM-YYYY');
        if (this.state.startDate) {
            var startDate = moment(this.state.startDate, "DD-MM-YYYY").startOf('day');
            // Compare start date and end date
            if (startDate.isAfter(endDate)) {
                this.setState({
                    endDate: "",
                    show: true,
                    basicTitle: "To Date should be greater than From Date",
                    basicType: 'danger'
                });
            } else {
                this.setState({
                    endDate: formattedEndDate
                });
            }
        } else {
            this.setState({
                endDate: formattedEndDate
            });
        }
    };
    getDeviationReport = async (e) => {
        try {
            if (e) { e.preventDefault() }
            this.setState({
                loadshow: "show-m",
                overly: "show-m"
            })
            let { startDate, endDate } = this.state
            let deviation_type = this.state.offroute == "btn-info" ? 2 : 1
            if (startDate && endDate) {
                let params = {
                    from_date: startDate,
                    to_date: endDate,
                    deviation_type: deviation_type,
                }
                let { selected_deviation_option } = this.state
                if (!['', 'all', "All", "ALL", undefined, 'undefined', null].includes(selected_deviation_option.value)) {
                    params.deviation_id = selected_deviation_option.value
                }
                let response = await redirectURL.post("/consignments/getdeviationreportdata", params);
                let data = response.data || []
                this.setState({
                    rowData: data,
                    loadshow: "show-n",
                    overly: "show-n"
                })
            } else {
                this.setState({
                    show: true,
                    basicTitle: "Dates cannot be empty",
                    basicType: 'danger',
                    loadshow: "show-n",
                    overly: "show-n"
                })
            }
        } catch (e) {
            console.log("Error ", e)
            this.setState({
                loadshow: "show-n",
                overly: "show-n"
            })
        }
    }
    closeAlert = () => {
        this.setState({
            show: false
        });
    }
    toggleFilters = () => {
        this.setState(prevState => {
            return {
                showFilters: !prevState.showFilters
            };
        });
    }
    onClickHideAll() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            sliderExtendClearance: "",
            activeRow: "",
            selectedClearanceDate: "",
            activeRowRemarks: "",
            extension_yard_cost: 0,
            sliderViewFiles: "",
        });
    }
    onClickTab = (value) => {
        if (value == "enroute") {
            this.setState({
                enroute: "btn-info",
                offroute: "btn-default",
            }, () => {
                this.getDeviationReport()
            })
        } else if (value == "offroute") {
            this.setState({
                enroute: "btn-default",
                offroute: "btn-info",
            }, () => {
                this.getDeviationReport()
            })
        }
    }
    onSelectionChanged = (event) => {
        const selectedRows = event.api.getSelectedRows();
        if (selectedRows.length === 1) {
            // Redirect to a new page based on the deviation_id
            const deviation_id = selectedRows[0].deviation_id;
            window.open(`/deviation-data/${deviation_id}`, '_blank');
        }
    };
    viewOrderDetails = async (event) => {
        try {
            var data = event.data;
            if (data) {
                // Redirect to a new page based on the deviation_id
                const deviation_id = data.deviation_id;
                window.open(`/deviation-data/${deviation_id}`, '_blank');
            }
        } catch (error) {
            console.log("Error")
        }
    }
    // Function to filter and limit options based on search input
    filterOptions = (inputValue) => {
        const filteredOptions = this.state.deviation_options.filter(option =>
            option.label.toLowerCase().includes(inputValue.toLowerCase())
        );
        // Limit to 100 options
        return filteredOptions.slice(0, 100);
    };
    onCellClicked = async (params) => {
        try {
            // console.log(params, 'params')
            let { deviation_id, clearance_date, cr_clearance_date, deviation_status, extension_status } = params.data
        } catch (e) {
            console.log("Error : ", e)
            this.setState({
                loadshow: 'show-n',
                overly: 'show-n'
            })
        }
    }
    handlerClaranceDate = (event) => {
        try {
            var endDate = moment(event._d);  // Moment.js object for the selected end date	
            // Format the end date in 'DD-MM-YYYY' format using Moment.js	
            var formattedEndDate = endDate.format('DD-MM-YYYY HH:mm');
            let { clearance_date, cr_clearance_date, activeRow } = this.state
            let crClearanceDate = cr_clearance_date ? cr_clearance_date : clearance_date
            let validteDate = moment(moment(crClearanceDate)).isBefore(endDate)
            let diffDays = endDate.diff(activeRow.start_date, 'hours');
            let roundedDiffDays = Math.ceil(diffDays / 24);  // Divide by 24 and round up
            let expectedCost = roundedDiffDays * parseInt(activeRow.no_of_vehicles) * 450 - activeRow.yard_cost || 0
            // console.log(crClearanceDate , '\n ', endDate)
            if (!validteDate) {
                this.setState({
                    show: true,
                    basicTitle: 'The Extended Clearce Date Should be Greater than Current clearance Date',
                    basicType: 'danger'
                })
            } else {
                this.setState({ selectedClearanceDate: formattedEndDate, extension_yard_cost: expectedCost })
            }
        } catch (e) {
            console.log("Error : ", e)
        }
    };
    onClickSaveGridState() {
        try {
            window.colState = this.gridColumnApi.getColumnState();
            window.groupState = this.gridColumnApi.getColumnGroupState();
            window.sortState = this.gridApi.getSortModel();
            window.filterState = this.gridApi.getFilterModel();
            let screenpage = 'Deviation Data ';
            let reqparams = {
                gridcolumns: window.colState,
                gridgroup: window.groupState,
                gridcolsort: window.sortState,
                gridcolfilter: window.filterState,
                userId: localStorage.getItem("userid"),
                screenurl: window.location.pathname,
                screentitle: screenpage
            }
            redirectURL.post("/consignments/saveGridStates", reqparams)
                .then((response) => {
                    this.setState({
                        show: true,
                        basicTitle: "Successfully saved grid layout",
                        basicType: "success",
                        screenurl: window.location.pathname,
                        screentitle: screenpage
                    })
                })
                .catch(function (e) {
                })
        } catch (e) {
            console.log("Error :", e)
        }
    }
    restoreGridStates() {
        try {
            if (this.state.usergridstate.length > 0) {
                var windowstates = this.state.usergridstate;
                this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
                this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
                this.gridApi.setSortModel(windowstates[0].gridcolsort);
                this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
            }
        } catch (e) {
            console.log("Error :", e)
        }
    }
    resetState = () => {
        try {
            this.gridColumnApi.resetColumnState();
            this.gridColumnApi.resetColumnGroupState();
            this.gridApi.setSortModel(null);
            this.gridApi.setFilterModel(null);
            var reqparams = {
                userId: localStorage.getItem("userid"),
                screenurl: window.location.pathname,
                screentitle: this.state.screenpage
            }
            redirectURL.post("/consignments/removeUserGridState", reqparams)
                .then((response) => {
                    this.setState({
                        show: true,
                        basicTitle: "Successfully reset default grid layout",
                        basicType: "success"
                    })
                })
        } catch (e) {
            console.log("Error :", e)
        }
    };
    onGridReady = params => {
        try {
            this.gridApi = params.api;
            this.gridColumnApi = params.columnApi;
        } catch (e) {
            console.log("Error :", e)
        }
    };
    resetFilters = () => {
        this.setState({
            selected_deviation_option: { value: "All", label: "All" },
            startDate: moment().utcOffset("+05:30").subtract(7, 'days').format("DD-MM-YYYY"),
            endDate: moment().utcOffset("+05:30").format("DD-MM-YYYY"),
            showFilters: false,
        }, () => {
            this.getDeviationReport()
        })
    }
    onClickViewAttachment = (params) => {
        try {
            // console.log(params, 'params')
            this.setState({
                sliderViewFiles: 'slider-translate-30p',
                overly: 'show-m',
                row_files: params.data.uploaded_files
            })
        } catch (e) {
            console.log("Error ", e)
        }
    }
    downloadB2 = (fileName = "") => {
        try {
            if (fileName === "") {
                return;
            }
            redirectURL
                .post("/consignments/downloadFile", {
                    fileName: fileName
                }
                )
                .then((res) => {
                    // console.log(res);
                    if (res.status === 200) {
                        const downloadUrl = res.data.url;
                        const link = document.createElement("a");
                        link.href = downloadUrl;
                        link.setAttribute("download", fileName); // Optional: specify download filename
                        link.setAttribute("target", "_blank");
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        // console.log("clicked");
                    }
                })
                .catch((err) => {
                    console.log("err - ", err);
                });
        } catch (e) {
            console.log("Error ", e)
        }
    };
    render() {
        const {
            rowData,
            enroute
        } = this.state;
        let hideForEnroute = enroute == 'btn-info' ? true : false
        let attachMentHeader = {
            "dealer_declaration": "Dealer Declaration",
            "delivery_challan": "Delivery Challan",
            "retail_invoice": "Retail Invoice",
            "trade_certificate": "Trade Certificate",
            "tc_insurance": "TC Insurance or Transit Insurance",
            "eway_bill": "Eway Bill for Delivery Address",
            "body_builder_letter": "Body Builder Letter"
        }
        let columnDefs = [
            {
                pinned: "left",
                headerName: "",
                field: "deviation_id",
                width: 55,
                resizable: true,
                editable: false,
                params: {
                    buttonName: '', iconName: 'fa fa-eye', buttonCls: 'btn btn-info',
                    onClickFunction: this.viewOrderDetails
                },
                cellRendererSelector: function (params) {
                    var rendComponent = {
                        component: "GridButton"
                    };
                    return rendComponent
                },
            },
            {
                headerName: 'Deviation Id',
                validation: (value) => value && value.trim() !== '',
                field: 'deviation_id',
                editable: false,
                width: 140,
            },
            {
                headerName: 'Deviation Status',
                validation: (value) => value && value.trim() !== '',
                field: 'deviation_status',
                editable: false,
                width: 120,
                cellClass: function (params) {
                    if (params.data && params.data.deviation_status == 0) {
                        return "boxbgblue5"
                    }
                    if (params.data && params.data.deviation_status == 1) {
                        return "bgcolorsuccesgreen";
                    } else if (params.data && params.data.deviation_status == 2) {
                        return "boxBgLighRed";
                    } else if (params.data && params.data.deviation_status == 3) {
                        return "bgcolorsuccesgreenRelease";
                    }
                },
                valueGetter: params => {
                    try {
                        let { deviation_status } = params.data
                        let status = ""
                        if (deviation_status == 0) {
                            status = "Pending"
                        }
                        if (deviation_status == 1) {
                            status = "Approved"
                        } else if (deviation_status == 2) {
                            status = "Rejected"
                        }
                        // else if (deviation_status == 2) {
                        //     status = "Released"
                        // }
                        return status
                    } catch (e) {
                        console.log("Error ", e)
                    }
                }
            },
            {
                headerName: 'No of Vehicles',
                validation: (value) => value && value.trim() !== '',
                field: 'no_of_vehicles',
                editable: false,
                width: 120,
            },
            {
                headerName: 'Reason',
                validation: (value) => value && value.trim() !== '',
                field: 'deviation_reason',
                editable: false,
                width: 160,
            },
            {
                headerName: 'OBL Status',
                validation: (value) => value && value.trim() !== '',
                field: 'obl_status',
                editable: false,
                width: 180,
                hide: hideForEnroute,
                suppressColumnsToolPanel: hideForEnroute,
                cellClass: function (params) {
                    if (params.data && params.data.obl_status == 0) {
                        return "boxbgblue5"
                    } else if (params.data && params.data.obl_status == 1) {
                        return "bgcolorsuccesgreen";
                    } else if (params.data && params.data.obl_status == 2) {
                        return "boxBgLighRed";
                    }
                },
                valueGetter: params => {
                    try {
                        let { obl_status } = params.data
                        let status = ''
                        if (obl_status == 0) {
                            status = 'Pending'
                        } else if (obl_status == 1) {
                            status = "Approved"
                        } else if (obl_status == 2) {
                            status = "Rejected"
                        }
                        return status
                    } catch (e) {
                        console.log("Error :", e)
                    }
                }
            },
            {
                headerName: 'Requester L4 Status',
                validation: (value) => value && value.trim() !== '',
                field: 'req_l4_status',
                editable: false,
                width: 180,
                cellClass: function (params) {
                    if (params.data && params.data.req_l4_status == 0) {
                        return "boxbgblue5"
                    } else if (params.data && params.data.req_l4_status == 1) {
                        return "bgcolorsuccesgreen";
                    } else if (params.data && params.data.req_l4_status == 2) {
                        return "boxBgLighRed";
                    }
                },
                valueGetter: params => {
                    try {
                        let { req_l4_status } = params.data
                        let status = ''
                        if (req_l4_status == 0) {
                            status = 'Pending'
                        } else if (req_l4_status == 1) {
                            status = "Approved"
                        } else if (req_l4_status == 2) {
                            status = "Rejected"
                        }
                        return status
                    } catch (e) {
                        console.log("Error :", e)
                    }
                }
            },
            {
                headerName: 'Requester Business Head Status',
                validation: (value) => value && value.trim() !== '',
                field: 'req_bs_head_status',
                editable: false,
                width: 180,
                cellClass: function (params) {
                    if (params.data && params.data.req_bs_head_status == 0) {
                        return "boxbgblue5"
                    } else if (params.data && params.data.req_bs_head_status == 1) {
                        return "bgcolorsuccesgreen";
                    } else if (params.data && params.data.req_bs_head_status == 2) {
                        return "boxBgLighRed";
                    }
                },
                valueGetter: params => {
                    try {
                        let { req_bs_head_status } = params.data
                        let status = ''
                        if (req_bs_head_status == 0) {
                            status = 'Pending'
                        } else if (req_bs_head_status == 1) {
                            status = "Approved"
                        } else if (req_bs_head_status == 2) {
                            status = "Rejected"
                        }
                        return status
                    } catch (e) {
                        console.log("Error :", e)
                    }
                }
            },
            {
                headerName: 'F&C Approver Status',
                validation: (value) => value && value.trim() !== '',
                field: 'fc_status',
                editable: false,
                width: 180,
                hide: hideForEnroute,
                suppressColumnsToolPanel: hideForEnroute,
                cellClass: function (params) {
                    if (params.data && params.data.fc_status == 0) {
                        return "boxbgblue5"
                    } else if (params.data && params.data.fc_status == 1) {
                        return "bgcolorsuccesgreen";
                    } else if (params.data && params.data.fc_status == 2) {
                        return "boxBgLighRed";
                    }
                },
                valueGetter: params => {
                    try {
                        let { fc_status } = params.data
                        let status = ''
                        if (fc_status == 0) {
                            status = 'Pending'
                        } else if (fc_status == 1) {
                            status = "Approved"
                        } else if (fc_status == 2) {
                            status = "Rejected"
                        }
                        return status
                    } catch (e) {
                        console.log("Error :", e)
                    }
                }
            },
            {
                headerName: 'Requester Email',
                validation: (value) => value && value.trim() !== '',
                field: 'requester_mail',
                editable: false,
                width: 180,
            },
            {
                headerName: 'Requester L4 Email',
                validation: (value) => value && value.trim() !== '',
                field: 'req_l4_mail',
                editable: false,
                width: 180,
            },
            {
                headerName: 'Requester Business Head Email',
                validation: (value) => value && value.trim() !== '',
                field: 'req_bs_head_mail',
                editable: false,
                width: 180,
            },
            {
                headerName: 'M&S Email',
                validation: (value) => value && value.trim() !== '',
                field: 'ms_mail',
                editable: false,
                width: 180,
            },
            {
                headerName: 'F&C Email',
                validation: (value) => value && value.trim() !== '',
                field: 'fc_mail',
                editable: false,
                width: 180,
                hide: hideForEnroute,
                suppressColumnsToolPanel: hideForEnroute,
            },
            {
                headerName: 'Obl Email',
                validation: (value) => value && value.trim() !== '',
                field: 'obl_mail',
                editable: false,
                width: 180,
            },

            {
                headerName: "Attachments",
                field: "",
                width: 100,
                resizable: true,
                params: {
                    onClickViewAttachment: this.onClickViewAttachment
                },
                cellRendererSelector: function (params) {
                    var rendComponent = {
                        component: "ViewAttachments"
                    };
                    return rendComponent
                },
            },
        ]
        return (
            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                />
                <div className="row">
                    <div className="col-xl-12 col-lg-12 pb-0 mb-0">
                        <div className="pb-0 mb-0">
                            <h5>
                                <i className="icofont icofont-vehicle-delivery-van cus-i"></i>  <span>Deviation Report</span>
                            </h5>
                            <div className="pb-0 mb-0" style={{ width: "100%", display: "flex", justifyContent: "space-between" }} >
                                <div className="pb-0 mb-0">
                                    <button className={"btn " + this.state.enroute} onClick={this.onClickTab.bind(this, 'enroute')} style={{ borderRight: "1px solid #ccc", borderLeft: "1px solid #ccc" }}>Enroute</button>
                                    <button className={"btn " + this.state.offroute} onClick={this.onClickTab.bind(this, 'offroute')}>Offroute</button>
                                </div>
                                <div className="row">
                                    <button
                                        type="button"
                                        onClick={this.resetFilters}
                                        className=""
                                        style={{
                                            color: "#000",
                                            border: "0px",
                                            background: "transparent",
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            backgroundColor: 'white',
                                            marginRight: '1px',
                                            width: '34px',
                                            color: '',
                                        }}
                                        title='Reset Filters'
                                    >
                                        <i className="icofont icofont-refresh" style={{ color: '#2060c7 ', }}></i>
                                    </button>
                                    <button
                                        type="button"
                                        onClick={this.toggleFilters}
                                        className=""
                                        style={{
                                            color: "#000",
                                            // textDecoration: "underline",
                                            border: "0px",
                                            background: "transparent",
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            backgroundColor: 'white',
                                            marginRight: '16px',
                                            color: '#4099ff ',
                                        }}
                                        title={this.state.showFilters ? "Hide Filters" : "Show Filters"}
                                    >
                                        <img src={FILTER} height={'24px'} width={'24px'} />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body" style={{ padding: "0px 20px 0px 20px", minHeight: "70vh" }}>
                                <div className="card-body p-0 m-0" >
                                    <form id="filterForm" method="POST" className="row p-0 m-0" onSubmit={this.getDeviationReport}>
                                        <div className="row" style={{ width: '100%', paddingRight: '2px', paddingBottom: '0px' }} >
                                            {/* Show filters if showFilters is true */}
                                            {this.state.showFilters && (
                                                <div style={{ display: 'flex', width: "80%", marginTop: '16px' }}>
                                                    <div className="col-xl-3 col-lg-3 form-group">
                                                        <label>From Date</label>
                                                        <Datetime
                                                            closeOnSelect={true}
                                                            inputProps={{ placeholder: 'From', name: 'startDate', autoComplete: 'off', readOnly: true, value: this.state.startDate }}
                                                            dateFormat="DD-MM-YYYY"
                                                            timeFormat={false}
                                                            onChange={this.handlerStartDateTime}
                                                        />
                                                    </div>
                                                    <div className="col-xl-3 col-lg-3 form-group">
                                                        <label>To Date</label>
                                                        <Datetime
                                                            closeOnSelect={true}
                                                            inputProps={{ placeholder: 'To', name: 'endDate', autoComplete: 'off', readOnly: true, value: this.state.endDate }}
                                                            dateFormat="DD-MM-YYYY"
                                                            timeFormat={false}
                                                            onChange={this.handlerEndDateTime}
                                                        />
                                                    </div>
                                                    <div className="form-group col-xl-3 col-lg-3">
                                                        <label style={{ marginBottom: '5px' }}>Deviation Id</label>
                                                        <Select
                                                            className="border-radius-0"
                                                            isMulti={false} // Set to `true` if you want multi-select
                                                            id="holdIds"
                                                            value={this.state.selected_deviation_option} // Selected option
                                                            onChange={(e) => this.setState({ selected_deviation_option: e })} // Set the selected value to state
                                                            options={this.filterOptions(this.state.inputValue)} // Pass the filtered options
                                                            inputValue={this.state.inputValue} // Bind the input value for filtering
                                                            onInputChange={(newInputValue) => this.setState({ inputValue: newInputValue })} // Update input value on search input change
                                                            isSearchable={true} // Enable search functionality
                                                        />
                                                    </div>
                                                    <div className="form-group col-xl-1 col-lg-1" style={{ textAlign: "left", top: "30px" }}>
                                                        <button type="submit" className="btn btn-success">Apply</button>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="float-right" style={{ width: this.state.showFilters ? "20%" : "100%", display: 'fkex', flexDirection: 'column', }}>
                                                <div
                                                    className="float-right"
                                                    style={{
                                                        width: "100%",
                                                        height: '100%',
                                                        display: "flex", // Corrected typo
                                                        flexDirection: "column",
                                                        justifyContent: 'flex-end',
                                                        alignItems: "flex-end", // Align items to the right
                                                        marginRight: "-12px",
                                                        paddingBottom: '4px',

                                                    }}
                                                >
                                                    <div className="row" style={{ marginTop: "10px" }}> {/* Add margin for spacing */}
                                                        <button
                                                            className="custom-btn white btn-danger"
                                                            onClick={this.onClickSaveGridState.bind(this)}
                                                            style={{ height: "30px", marginRight: "10px" }}
                                                        >
                                                            <i className="icofont icofont-save"></i> Save Grid
                                                        </button>
                                                        <button
                                                            className="custom-btn white btn-info"
                                                            style={{ marginRight: "10px", height: "30px" }}
                                                            onClick={this.resetState.bind(this)}
                                                        >
                                                            <i className="icofont icofont-refresh"></i> Reset Grid
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="card-body" style={{ padding: '0px' }}>
                                    <div className="row" style={{ textAlign: 'right' }}>
                                    </div>
                                </div>
                                <div id="myGrid" style={{ height: "580px", width: "100%" }} className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnDefs} // Assuming this is a state variable for columns
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={rowData}
                                        sideBar={this.state.sideBar}
                                        enableCharts={true}
                                        enableRangeSelection={true}
                                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady}
                                        pagination={true}
                                        frameworkComponents={this.state.frameworkComponents}
                                        stopEditingWhenGridLosesFocus={true}
                                        enableCellChangeFlash={false}
                                        rowClassRules={this.state.rowClassRules}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        masterDetail={true}
                                        rowHeight={this.state.rowHeight}
                                        rowSelection={this.state.rowSelection}
                                        suppressRowClickSelection={true}
                                        overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                                        excelStyles={this.state.excelStyles}
                                        editable={false}  // Enable editing for all cells
                                        onSelectionChanged={this.onSelectionChanged}
                                        onCellClicked={this.onCellClicked}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"slide-r " + (this.state.sliderViewFiles)} style={{ overflow: "auto", }}>
                    <div className="slide-r-title">
                        <h4>
                            Attachments
                            <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 text-center">
                            <div className="col-xl-12 col-lg-12 form-group">
                                <div>
                                    {
                                        Object.keys(this.state.row_files).map((key, index) => {
                                            const file = this.state.row_files[key];
                                            return (
                                                <div className="mb-15p mt-15p" key={index}>
                                                    <p className='p-0 m-0'>{attachMentHeader[key]}</p>
                                                    <button onClick={() => this.downloadB2(file)} className="btn btn-warning1">
                                                        {(file !== undefined && file !== "undefined")
                                                            ? (file.includes('_')
                                                                ? file.split('/').pop().split('_').slice(0, -1).join('_')
                                                                : file)
                                                            : `Download File ${index + 2}`
                                                        }
                                                        <i
                                                            style={{ color: 'rgb(255 255 255)', cursor: 'pointer', marginLeft: '10px', marginTop: '5px', marginBottom: '5px' }}
                                                            className="fa fa-download f20"
                                                        ></i>
                                                    </button>
                                                    <br />
                                                </div>
                                            );
                                        }) || ""
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                </div>
            </div>
        );
    }
}	
