/* eslint-disable array-callback-return */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */

import React, { Component, useDebugValue } from 'react';
// import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import SweetAlert from 'react-bootstrap-sweetalert';
// import CSVFileValidator from 'csv-file-validator';
// import UpdateButton from "../layouts/updatelmstates";
// import EditButton from "../layouts/editbutton";
//import DeleteButton from "./deleteshipmentDSR";
import Select from 'react-select';
// import CreatableSelect from 'react-select/creatable';
// import { ActionMeta, OnChangeValue } from 'react-select';
import $ from 'jquery';
import 'react-datetime/css/react-datetime.css';
import { getHyphenYYYYMMDD, getHyphenYYYYMMDDHHMMSS, getDDMMYYYYHHMMSS } from '../common/utils';

// import { verifyext, verifycsvdata } from "../common/verifyext";
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
var colDefs2 = [
    // {
    //     headerName: "",
    //     field: "checkbox_selection",
    //     checkboxSelection:true,
    //     width:60
    // },
    // {
    //     headerName: "",
    //     field: "map_routes",
    //     cellRenderer: (params)=>{
    //         return "<i class='icofont icofont-map-pins f20'></i>"
    //     },
    //     width:60
    // },
    {
        headerName: "Truck No",
        field: "truck_no",
    },
    {
        headerName: "Invoice No",
        field: "invoice_no",
    },
    {
        headerName: "Consignment Code",
        field: "consignment_code",
    },
    // {
    //     "headerName": "Base Route",
    //     field: "base_route_no",
    // },
    {
        headerName: "Dealer Code",
        field: "consignee_code",
    },
    // {
    //     headerName: "Dealer Name",
    //     field: "consignee_name",
    // },
    {
        headerName: "Source",
        field: "consigner_code",
    },
    {
        headerName: "Destination",
        field: "consignee_city",
    },
    {
        headerName: "Zone",
        field: "zone"
    },
    {
        headerName: "Invoice Time",
        field: "invoice_date",
        valueGetter: (params)=>{
            return getHyphenYYYYMMDDHHMMSS(params.data.invoice_date)
        }
    },
    {
        headerName: "Trip End Time",
        field: "trip_end_time",
        valueGetter: (params)=>{
            return getHyphenYYYYMMDDHHMMSS(params.data.trip_end_time)
        }
    },
    {
        headerName: "Transporter Code",
        field: "transporter_code"
    },
    {
        headerName: "Transporter Name",
        field: "transporter_name",
    },
    {
        headerName: "GPS Provider",
        field: "gps_provider",
        valueGetter: (params)=>{
            var label = params.data.gps_provider
            if(params.data.gps_provider == "AXESTRACK_SND"){label = "AXESTRACK"}
            return label
        }
    },
    {
        headerName: "Truck Type",
        field: "vehicle_mode"
    },
    // {
    //     headerName: "Total GPS Distance (Km)",
    //     field: "total_travelled_trip_dist_km",
    // },
    // {
    //     headerName: "Distance Travelled (KM)",
    //     field: "total_trip_dist_with_google_km",
    //     valueGetter: (params)=>{
    //         var total = Math.round(parseFloat(params.data.total_trip_dist_with_google_km));
    //         // if(params.data.missing_distance_km != undefined)
    //         // {
    //         //     total += Math.round(parseFloat(params.data.missing_distance_km))
    //         // }
    //         return total;
    //     }
    // },
    // {
    //     headerName: "Route Ignored",
    //     field: "route_ignored",
    //     valueGetter: (params)=>{
    //         if(params.data.route_ignored != undefined)
    //         {
    //             return "Yes";
    //         }
    //         else
    //         {
    //             return "No";
    //         }
    //     }
    // },
    {
        headerName: "Reason",
        field: "reason",
        // valueGetter: (params)=>{
        //     if(params.data.club_load == 1 && params.data.route_ignored == 1)
        //     {
        //         return  "Club Load"
        //     }
        //     if(params.data.straight_lines == 1 && params.data.route_ignored == 1)
        //     {
        //         return "Due to straight lines"
        //     }
        //     else if(params.data.route_ignored != undefined)
        //     {
        //         if(params.data.away_from_plant == 1)
        //         {
        //             return "Away from Plant";
        //         }
        //         else if(params.data.expected_distance*0.8 > params.data.total_travelled_trip_dist_km)
        //         {
        //             return "Trip Distance < 80% of Hero SAP Distance";
        //         }
        //         else{
        //             return "Trip Distance > 120% of Hero SAP Distance";
        //         }
        //     }
        //     else
        //     {
        //         return "";
        //     }
        // }
    },  
]

const decipher = salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
    return encoded => encoded.match(/.{1,2}/g)
        .map(hex => parseInt(hex, 16))
        .map(applySaltToChar)
        .map(charCode => String.fromCharCode(charCode))
        .join('');
}
var decode = decipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");


export default class IgnoredRoutes extends Component {
    state = {
        modules: AllModules,
        defaultColDef: {
            sortable: true,
            filter: true,
            editable: true,
        },
        eventAction: null,
        show: false,
        frameworkComponents: {
            // UpdateButton: UpdateButton,
            // EditButton: EditButton,
            // DeleteButton:DeleteButton,
        },
        modified_by: "",
        basicTitle: '',
        reasonforceclose: "",
        bulkslide: "",
        file: "",
        uploadFile: "",
        basicType: "default",
        loadshow: 'show-n',
        forceCloseRowNode: "",
        forceclosedata: "",
        csvcontent: "",
        sliderForceCloseTranslate: "",
        overly: '',
        rowData: [],
        showDiv: "",
        uploadDivWidth: "",
        mapinfo: "",
        dealer: "",
        sliderCommentTranslate: "",
        commentsRowData: "",
        consignment_code: "",
        sliderRouteTranslate: "",
        maptruckno: "",
        routeTruck: "",
        rownode: "",
        googelRoutes: "",
        rowSelection: '',
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
        statusBar: {
            statusPanels: [
                {
                    statusPanel: "agTotalAndFilteredRowCountComponent",
                    align: "left"
                },
                {
                    statusPanel: "agTotalRowCountComponent",
                    align: "center"
                },
                { statusPanel: "agFilteredRowCountComponent" },
                { statusPanel: "agSelectedRowCountComponent" },
                { statusPanel: "agAggregationComponent" }
            ]
        },
        paginationPageSize: 200,
        geofencelist: [],
        geoFenceData: "",
        sideBar: {
            toolPanels: [
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel"
                },
                {
                    id: "filters",
                    labelDefault: "Filters",
                    labelKey: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel"
                }
            ]
        },
        animateRows: true,
        dept_code: "",
        pagetitle: "",
        markDeliverRowNode: "",
        markDeliverData: "",
        sliderMarkDeliverTranslate: "",
        csvData: 0,
        holidayEvents: [],
        showCalendar: "show-m",
        showGridData: "show-m",
        vehicleCap: [],
        statesList: [{ label: "ALL", value: "ALL" }],
        gateway_id: [{ label: "ALL", value: "ALL" }],
        rowIds: [],
        freight_type: 1,
        comp_code: "",
        lpNames: [],
        loading_port_code: "",
        source_name: [],
        base_station: [],
        createEventDepts: "",
        rowId: "",
        truckList: [],
        originoptions:[],
        destinationOptions: [],
        originoption:{"value":"","label":"Select Origin"},
        selected_provider: {"label": "ALL", "value": "ALL"},
        selected_truck_type: {"label": "ALL", "value": "ALL"},
        selected_period_type: {"label": "ALL", "value": "ALL"},
        //components : {datepicker:getDatePicker()},
    }

    componentDidMount() {
        // loadDateTimeScript();
        this.getDropDownListValues()
        // var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
    }

    getDropDownListValues = () =>{
        let consigner_code = ''
        var map_plants = {"HM4N": "Neemrana", "HHHU": "Haridwar", "HHHD": "Dharuhera", "HHHG": "Gurgaon", "HM5V": "Halol", "HM6C": "Chittoor"}
        redirectURL.post("/dashboard/getSourceFromBaseRoutes").then((response) => {
            //console.log("Ress ", response.data)
            var plantdata = response.data;
            var originoptions = [];
            if(plantdata.length > 0)
            {
                plantdata.map((item) => {
                    originoptions.push({
                        value:item,
                        label: map_plants[item]
                    })
                })
            }
            redirectURL.post("dashboard/getDestinationFromBaseRoutes", {"consigner_code": consigner_code}).then((response1)=>{
                var items=[];
                var destinationarr = response1.data;
                var check = destinationarr.map((item) =>{
                    items.push({
                        value:item,label:item 
                    })
                })
                redirectURL.post("dashboard/getInitialDataForBaseRouteSummary", {}).then((response2)=>{
                    // console.log(response2.data)
                    var lspusers_options = [];
                    var truck_types = [];
                    var period_types_options = [];
                    // var period_types = sortPeriodTypes(response2.data.period_types);
                    var period_types = response2.data.period_types;
                    // console.log(period_types, "period_types")
                    response2.data.lsp_users.map((e)=>{
                        if(e != "NA")
                        {
                            var label = e
                            if(e == "AXESTRACK_SND"){label = "AXESTRACK"}
                            lspusers_options.push({"label": label, "value": e})
                        }
                    })
                    response2.data.truck_types.map((e)=>{
                        truck_types.push({"label": e, "value": e})
                    })
                    period_types.map((e)=>{
                        period_types_options.push({"label": e, "value": e})
                    })
                    // console.log(period_types, "period_types options")
                    this.setState({
                        originoptions: originoptions,
                        destinationOptions: items,
                        lspusers_options: lspusers_options,
                        truck_types: truck_types,
                        period_types: period_types_options,
                        overly: "show-n",
                        loadshow: "show-n"
                    })
                })
            })
        })
    }

    formHandler = () => {
        // dept_code: encode('SNDG')
        if(this.state.originoption.value != "" && this.state.destinationoption.value != "")
        {

            var formdata = {
                consigner_code:this.state.originoption.value,
                consignee_city:this.state.destinationoption.value,
                gps_provider: this.state.selected_provider.value,
                invoice_month: this.state.selected_period_type.value,
                vehicle_mode: this.state.selected_truck_type.value,
            }

            // let tCode = localStorage.getItem("transportercode");
            // if (tCode !== undefined && tCode !== "") params.transporter_code = tCode;

            redirectURL.post("/dashboard/getIgnoredRoutes",formdata).then((response) => {
                // console.log("response",response.data);
                // let responseData = response.data;
                // let truckList = []
                // responseData.trucksList.map(e => truckList.push({ value: e, label: e }))
                this.setState({
                    rowData: response.data,
                    overly: "show-n",
                })
            }).catch((e) => {
                console.log("error", e);
            })

        }
        else
        {
            this.setState({
                basicTitle: "Please select Consigner and Consignee City",
                basicType: "warning",
                show: true,
                overly: "show-n",
                loadshow: "show-n"
            })
        }
        let params = {

        }
        
    }

    onGridReady = params => {
        this.gridApi = params.api;
        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
    };
    onGridState = () => {
        //console.log(this.gridApi);

        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
        * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }

    closeAlert = () => {
        this.setState({
            show: false
        });
    }

    onCellClicked = (e) => {
        if (e.colDef.field == "delete") {
            var rowId = e.data._id;
            // console.log("delete",e.data);
            var qry = {
                rowId: rowId,
                truck_no: e.data.truck_no
            }
            //var confirm = confirm("Are You Sure! Do you want really delete?");
            if (window.confirm("Are You Sure! Do you want really delete?")) {
                redirectURL.post("/dashboard/deleteIgnoredTruck", qry).then((response) => {
                    // console.log("response",response);
                    if (response.data.status == "success") {
                        this.setState({
                            basicTitle: "Deleted Successfully.",
                            basicType: "success",
                            show: true
                        })
                        //window.location.reload();
                        this.onLoadPageData();
                    }
                    else {
                        this.setState({
                            basicTitle: "Failed to Delete.",
                            basicType: "warning",
                            show: true
                        })
                    }
                }).catch((e) => {
                    console.log(e);
                })
            }
        }
    }

    onClickHideAll = () => {
        $('#transName').val('')
        $('#transCode').val('')
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            sliderRouteTranslate: '',
            bulkslide: "",
            sliderbulkupload: "",
            truck_no: null
        });

    }
    
    // addNewRow()
    // {
    //     // console.log(this.gridApi);
    //     this.gridApi.insertItemsAtIndex(0,[{zone_id:"",zone_name:"",gateway_id:""}])
    //     // this.gridApi.updateRowData({add:[{holiday_date:"",holiday_name:""}]});
    // }
    onClickAddButton = () => {
        this.setState({
            sliderbulkupload: "slider-translate-40p",
            overly: "show-n"
        })
    }
   
    showGrid = () => {
        this.setState({
            showCalendar: "show-n",
            showGridData: "show-m"
        })
    }

    onClickDataExport = () => {
        var params = {
            columnKeys: ['zone_id_1', 'zone_name_1', 'gateway_id_1']
        };
        this.gridApi.exportDataAsCsv(params);
    }


    onChangeTruckNo = (item) => {
        // console.log(item, "item");
        let params = {
            truck_no: item[0].value
        }
        this.setState({
            truck_no: item
        })
    }

  
    showBulkUpload() {
        this.setState({
            overly: "show-m",
            bulkslide: "slider-translate"
        })
    }
    resetUpload = () => {
        this.setState({
            bulkslide: '',
            overly: 'show-n',
            file: ''
        });
        document.getElementById("upform").reset();
    }


    // checkFileData = () => {
    //     // var vdata = verifycsvdata(JSON.stringify(this.state.csvData));
    //     //console.log("verifycsvdata ", vdata)
    //     if (vdata == 0) {
    //         this.setState({
    //             csvData: '',
    //             show: true,
    //             basicType: 'danger',
    //             basicTitle: 'File contains invalid data',
    //             uploadFile: '',
    //             file: ""
    //         });
    //     }
    // }

    onRowSelection(event) {
        var rowIds = [];
        var rwCount = event.api.getSelectedNodes();
        rwCount.map((item) => {
            rowIds.push(item.data._id)
        });

        this.setState({
            rowIds: rowIds
        })
    }

    changeOriginHandler = originoption => {
        this.setState(
            { originoption }
        );
    }

    changeDestinationHandler = destinationoption => {        
        this.setState(
            { destinationoption }
        );
    }

    changeGpsProviderHandler = selected_provider => {    
        this.setState({
            selected_provider: selected_provider,
        })
    }

    changePeriodTypeHandler = selected_period_type => {       
        this.setState({
            selected_period_type: selected_period_type,
        })
    }

    changeTruckTypeHandler = selected_truck_type => {
        this.setState({
            selected_truck_type: selected_truck_type,
        })
    }

    render() {
        var columnwithDefs = [
            {
                headerName: "Truck No",
                field: "truck_no",
                width: 150,
                filter: true,
                resizable: true,
                editable: false,
                //     cellEditor: 'agSelectCellEditor',
                //     cellEditorParams: {
                //         values: this.state.asset_id ,
                // },
            },
            {
                headerName: "Invoice No",
                field: "invoice_no",
                width: 250,
                filter: true,
                resizable: true,
                editable: false,
                // cellEditor: 'agSelectCellEditor',
                // cellEditorParams: {
                //     values: this.state.statesList,
                // },
            },
            {
                headerName: "Invoice Date",
                field: "invoice_date",
                width: 150,
                filter: true,
                resizable: true,
                editable: false,
                valueGetter: (params)=>{
                    return getHyphenYYYYMMDDHHMMSS(params.data.invoice_date)
                }   
            },
            {
                headerName: "Trip End Time",
                field: "trip_end_time",
                width: 150,
                filter: true,
                resizable: true,
                editable: false,
                valueGetter: (params)=>{
                    return getHyphenYYYYMMDDHHMMSS(params.data.trip_end_time)
                }   
            },
            {
                headerName: "GPS Provider",
                field: "gps_provider",
                width: 150,
                filter: true,
                resizable: true,
                editable: false,
                //     cellEditor: 'agSelectCellEditor',
                //     cellEditorParams: {
                //         values: this.state.asset_id ,
                // },
            },  
            // {
            //     headerName: "Reason",
            //     field: "reason",
            //     width: 150,
            //     filter: true,
            //     resizable: true,
            //     editable: false,
            //     //     cellEditor: 'agSelectCellEditor',
            //     //     cellEditorParams: {
            //     //         values: this.state.asset_id ,
            //     // },
            // },                   
            // {
            //     pinned: "left",
            //     headerName: "",
            //     field: "delete",
            //     width: 50,
            //     resizable: true,
            //     editable: false,
            //     cellRenderer: params => {
            //         return '<i class="icofont icofont-ui-delete"></i>';
            //     },
            //     filter: false,
            // }
        ]
        const { show, basicType, basicTitle, modules, defaultColDef, rowData, frameworkComponents, statusBar, sideBar, paginationPageSize, rowHeight, truck_no, loadshow, overly, truckList, sliderbulkupload, bulkslide, showGridData } = this.state

        return (
            <div class="container-fluid">
                <SweetAlert
                    show={show}
                    type={basicType}
                    title={basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row">
                        <div className="row col-xl-12 col-lg-12">
                            <div className="form-group col-xl-2 col-lg-2">
                                <label>Origin: </label>
                                <Select
                                    name="originoption"
                                    closeMenuOnSelect={true}
                                    value={this.state.originoption}
                                    className="border-radius-0"
                                    onChange={this.changeOriginHandler}
                                    style={{ borderRadius: "0px" }}
                                    options={this.state.originoptions} required
                                />
                            </div>
                            <div className="form-group col-xl-2 col-lg-2">
                                <label>Destination: </label>
                                <Select
                                    name="destinationoption"
                                    closeMenuOnSelect={true}
                                    value={this.state.destinationoption}
                                    className="border-radius-0"
                                    onChange={this.changeDestinationHandler}
                                    style={{ borderRadius: "0px" }}
                                    options={this.state.destinationOptions} required
                                />
                            </div>
                            <div className="form-group col-xl-2 col-lg-2">
                                <label>GPS Provider: </label>
                                <Select
                                    name="GPS Provider"
                                    closeMenuOnSelect={true}
                                    value={this.state.selected_provider}
                                    className="border-radius-0"
                                    onChange={this.changeGpsProviderHandler}
                                    style={{ borderRadius: "0px" }}
                                    options={this.state.lspusers_options} required
                                />
                            </div>
                            <div className="form-group col-xl-2 col-lg-2">
                                <label>Period: </label>
                                <Select
                                    name="Period"
                                    closeMenuOnSelect={true}
                                    value={this.state.selected_period_type}
                                    className="border-radius-0"
                                    onChange={this.changePeriodTypeHandler}
                                    style={{ borderRadius: "0px" }}
                                    options={this.state.period_types} required
                                />
                            </div>
                            <div className="form-group col-xl-2 col-lg-2">
                                <label>Truck Type: </label>
                                <Select
                                    name="Truck Type"
                                    closeMenuOnSelect={true}
                                    value={this.state.selected_truck_type}
                                    className="border-radius-0"
                                    onChange={this.changeTruckTypeHandler}
                                    style={{ borderRadius: "0px" }}
                                    options={this.state.truck_types} required
                                />
                            </div>

                            <div className="form-group col-xl-2 col-lg-2">
                                <button style={{ marginTop: "30px" }} onClick={this.formHandler} type="button" className="btn btn-success">Submit</button>
                            </div>
                        </div>
                    <div className="card col-xl-12">
                        <div className="card-header">
                            <h5>
                                <i className="icofont icofont-truck-loaded cus-i"></i> <span>Ignored Routes</span>
                                <a style={{float: "right"}} href={'/deviationanalysisdata'} class="btn btn-warning" rel="noopener" >Base Route Summary</a>

                                {/* <button className="btn btn-success" style={{ float: "right" }} onClick={this.onClickAddButton}>Add Truck</button> */}
                            </h5>
                        </div>
                        <div className="card-body col-xl-12 col-lg-12">
                            <div id="myGrid" style={{ width: "100%", height: "478px" }} className={"ag-theme-balham " + showGridData}>
                                <AgGridReact
                                    modules={modules}
                                    columnDefs={colDefs2}
                                    defaultColDef={defaultColDef}
                                    rowData={rowData}
                                    enableCharts={false}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    frameworkComponents={frameworkComponents}
                                    statusBar={statusBar}
                                    sideBar={sideBar}
                                    stopEditingWhenGridLosesFocus={true}
                                    paginationPageSize={paginationPageSize}
                                    pagination={true}
                                    gridOptions={{
                                        context: { componentParent: this }
                                    }}
                                    enableRangeSelection={true}
                                    onCellClicked={this.onCellClicked}
                                // autoGroupColumnDef={autoGroupColumnDef}
                                // components={components}
                                //rowSelection={false}
                                //onRowSelected={this.onRowSelection.bind(this)}
                                // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                                />
                            </div>
                        </div>
                    </div>
                </div>




                <div className={"dataLoadpage " + (loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (overly)} onClick={this.onClickHideAll}></div>
            </div>

        );
    }
}

function dateComparator1(date1, date2) {
    //console.log(date1,date2);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split("-")[1]);
        date1 = date1.replace(date1.split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split("-")[1]);
        date2 = date2.replace(date2.split("-")[1], date_2);
        var date1Number = monthToComparableNumber1(date1);
        var date2Number = monthToComparableNumber1(date2);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}

function monthToComparableNumber1(date) {
    // console.log(date.length);
    // console.log(date);
    if (date === undefined || date === null || date.length !== 10) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}
function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        // console.log(date1,date2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        // console.log(date1Number,date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    // console.log(yearNumber, monthNumber, dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName) {
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}


function loadDateTimeScript() {
    // alert("timeDate");
    $('.datetimepicker_mask').datetimepicker({
        //mask:'39-19-9999 29:59',
        format: 'd-m-Y'
    });
    $('.datetimepicker_date').datetimepicker({
        mask: '39-19-9999',
        format: 'd-m-Y',
        timepicker: false
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}

function DateEditor() { }

// gets called once before the renderer is used
DateEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
        timepicker: false,
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function () {
    // console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};

function NumericCellEditor() {
}

// gets called once before the renderer is used
NumericCellEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');

    if (isCharNumeric(params.charPress)) {
        this.eInput.value = params.charPress;
    } else {
        if (params.value !== undefined && params.value !== null) {
            this.eInput.value = params.value;
        }
    }

    var that = this;
    this.eInput.addEventListener('keypress', function (event) {
        if (!isKeyPressedNumeric(event)) {
            that.eInput.focus();
            if (event.preventDefault) event.preventDefault();
        } else if (that.isKeyPressedNavigation(event)) {
            event.stopPropagation();
        }
    });

    // only start edit if key pressed is a number, not a letter
    var charPressIsNotANumber = params.charPress && ('1234567890'.indexOf(params.charPress) < 0);
    this.cancelBeforeStart = charPressIsNotANumber;
};

function getCharCodeFromEvent(event) {
    event = event || window.event;
    return (typeof event.which == "undefined") ? event.keyCode : event.which;
}

function isCharNumeric(charStr) {
    return !!/\d/.test(charStr);
}

function isCharDecimal(charStr) {
    return !!/\./.test(charStr);
}

function isKeyPressedNumeric(event) {
    const charCode = getCharCodeFromEvent(event);
    const charStr = String.fromCharCode(charCode);
    return isCharNumeric(charStr) || isCharDecimal(charStr);
}

NumericCellEditor.prototype.isKeyPressedNavigation = function (event) {
    return event.keyCode === 39
        || event.keyCode === 37;
};


// gets called once when grid ready to insert the element
NumericCellEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
NumericCellEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
};

// returns the new value after editing
NumericCellEditor.prototype.isCancelBeforeStart = function () {
    return this.cancelBeforeStart;
};

// example - will reject the number if it contains the value 007
// - not very practical, but demonstrates the method.
NumericCellEditor.prototype.isCancelAfterEnd = function () {
    var value = this.getValue();
    return value.indexOf('007') >= 0;
};

// returns the new value after editing
NumericCellEditor.prototype.getValue = function () {
    return this.eInput.value;
};

// any cleanup we need to be done here
NumericCellEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could  even leave this method out as it's optional
};

// if true, then this editor will appear in a popup 
NumericCellEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};