module.exports = {
    COL_POD_RECEIVED: "POD Received",
    COL_POD_DATE: "POD Date",
    COL_POD_DATE_TIME:"POD Date Time",
    COL_POD_REMARKS:"Remarks",
    COL_POD_DONE_BY:"POD Done By",
    COL_POD_DAMAGES_ANY: "Damages (If any)",
    COL_POD_DAMAGE_DESCRIPTION:"Damage Description",
    COL_TRAVEL_STATUS:"Travel Status",
    PAGE_EPOD:"EPOD",
    PAGE_EPOD_DASHBOARD:"POD Dashboard",
    COL_DEALER_CODE:"Customer Code",
    COL_DEALER_NAME:"Customer Name",
    COL_POD_CREATED_DATE:"POD Created Date",
    COL_POD_INVOICE_DOC_1:"Document 1",
    COL_POD_INVOICE_DOC_2:"Document 2",
    COL_POD_INVOICE_DOCS:"Documents",
    COUNTER_TOTAL_POD: "Total POD",
    COUNTER_PENDING_POD: "POD Pending",
    COUNTER_COMPLETED_POD: "POD Completed",
    COL_UPDATE_LRNO: "Update Invoice",
    COL_VIN_NO: "Vin No",
    COL_PLANT: "Supplying Plant",
    COL_SUBMIT_INVOICE: "Submit POD",
    COL_INVOICE_NO: "Invoice No",
    COL_REACHED_DESTINATION_AT: "Reached Destination At",
    COL_CONSIGNEMNT_CODE: "Consignment Code",
    COL_REMAINING_TOTAL_LRNO: "Remaining Invoice Nos / Total Invoice Nos",
    COL_POD_SUBMIT_STATUS:"POD Status",
    COL_POD_EPOD_SUBMIT_DATE:"POD Submit Date"


    

    




    

    




}