import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Select from "react-select";
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
$.datetimepicker.setLocale('en');
var infoBox = require('../common/google-infowindow');

var Constant = require('../common/Constant');
var redirectURL = require('../redirectURL');
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");
const decipher = salt => {
	const textToChars = text => text.split('').map(c => c.charCodeAt(0));
	const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
	return encoded => encoded.match(/.{1,2}/g)
		.map(hex => parseInt(hex, 16))
		.map(applySaltToChar)
		.map(charCode => String.fromCharCode(charCode))
		.join('');
}
var decode = decipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

export default class VendorPayments extends Component {

	constructor(props) {
		super(props);
		this.state = {
			deptcode: "",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
            modules: AllModules,
			pivotmodeis: false,
			pivotcols: [],
			defaultColDef: {
				sortable: true,
				filter: true,
				resizable: true,
				pivot: true,
				enableValue: true,
				enableRowGroup: true,
			},
			rowData: [],
			maprowData: null,
			rowSelection: 'multiple',
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			overlayNoRowsTemplate: "",
			frameworkComponents: {
            },

			animateRows: true,
			debug: true,
			showToolPanel: false,
			uppressAggFuncInHeader: true,

			childconfs: [],
			childrow: [],
			rowModelType: "serverSide",
			paginationPageSize: 100,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
						statusPanel: "agTotalAndFilteredRowCountComponent",
						align: "left"
					},
					{
						statusPanel: "agTotalRowCountComponent",
						align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
						id: "columns",
						labelDefault: "Columns",
						labelKey: "columns",
						iconKey: "columns",
						toolPanel: "agColumnsToolPanel"
					},
					{
						id: "filters",
						labelDefault: "Filters",
						labelKey: "filters",
						iconKey: "filter",
						toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams: {},
			rowClassRules: {
				//"highlitRow": "data.transit_delay == 1",
				//"green":"data.status >= 3"

			},
            show:false,
            basicTitle:"",
            basicType:"default",
            loadshow:"show-n",
            overly:"show-n",            
			sliderAddTranslate:'',
        }
        
        this.onLoadGetConsignment = this.onLoadGetConsignment.bind(this);
    }
    componentDidMount(){
        
        if(localStorage.getItem("customer_code") != "" && localStorage.getItem("customer_code") != undefined && localStorage.getItem("customer_code") != "undefined")
        {
            if(localStorage.getItem("customer_code") != "adminpod")
            {
                var customer_code = localStorage.getItem("customer_code");
            }
            else{
                this.setState({
                    admincode:"adminpod"
                })
                var customer_code = "";
            }
            
        }
        else{
            var customer_code = "";
        }
        this.setState({
            customer_code:customer_code
        })
        this.onLoadGetConsignment();

    }
    onLoadGetConsignment(){
        this.setState({
            loadshow:"show-m"
        })
        var parameters  = {
            invoice_no:this.props.match.params.invoice_no,
            description_code:this.props.match.params.model_code
        }
        redirectURL.post("/dashboard/consignmentdata",parameters)
        .then((response) => {
            if(response.data.status == "success")
            {
                var records = response.data.records;
                // var recordsarr = [];
                // if(records.length > 0)
                // {
                //     records.map((item) => {
                //         recordsarr.push(item)
                //     })
                // }
                // console.log("recordsarr ",records)
                this.setState({
                    loadshow:"show-n",
                    rowData:records
                })
            }
            
        });

    }
    closeAlert = async () => {
        await this.setState({
            show: false
        });
        await window.location.reload();
    }
    onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
        
        this.gridApi.addEventListener('paginationChanged', (e) =>
        {
            //Reset rows selection based on current page
           // this.resetPaginationSelection(this);
        });

        this.gridApi.forEachLeafNode( (node) => {
            // console.log("node here ",node)
            node.setSelected(true);
         
        });
        
	};
	

	onGridState = () =>{
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
           this.gridApi.setFilterModel(window.filterState);
          
    }
   
    onCloseRouteDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderRouteTranslate:"",
			sliderCommentTranslate : "",
			sliderSegmentTranslate : "",
            sliderFeedback:"",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
			overly:'show-n'
		});
	}
    onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			slideuploadeway:'',
			sliderAddTranslate:'',
            sliderWithoutDefectAddTranslate:"",
            sliderWithDefectAddTranslate:"",
            sliderFeedback:""
		});
		
	}
    
    render(){
       
        var gropucode = (this.props.match.params.model_code != undefined && this.props.match.params.model_code != "")?this.props.match.params.model_code:"";
        var columnwithDefs = [
            
            {
                headerName: "State",
                field: "dealer_state",
                width: 150,
                resizable: true
            },
            {
                headerName: "Region",
                field: "dealer_zone",
                width: 150,
                resizable: true
            },
            // {
            //     headerName: "Dealer Name",
            //     field: "dealer_name",
            //     width: 200,
            //     resizable: true
            // },
            // {
            //     headerName: "Location",
            //     field: "dealer_city",
            //     width: 180,
            //     resizable: true
            // },
            {
                headerName: "Distance (KM)",
                field: "total_distance_travelled_km",
                width: 150,
                resizable: true,
                valueGetter:function(params){
                    try{
                        if(params.data.total_distance_travelled_km != undefined)
                        {
                            var dist = convertMeters(params.data.total_distance_travelled_km);
                            return dist;
                        }
                        else{
                            return 0;
                        }
                    }
                    catch(e){

                    }
                }
            },
            {
                headerName: "Via",
                field: "dealer_via_location",
                width: 150,
                resizable: true
            },
            {
                headerName: gropucode,
                width: 150,
                resizable: true,
                children:[{
                    headerName:"Per KM Cost Driver Cost",
                    field:"dealer_driver_cost_per_km_fbt",
                    width:150,
                    resizable:true
                },
                {
                    headerName:"Driver Cost",
                    field:"dealer_driver_cost_per_km_fbt",
                    width:150,
                    resizable:true,
                    valueGetter:function(params){
                        try{
                            if(params.data.dealer_driver_cost_per_km_fbt != undefined)
                            {
                                var dist = convertMeters(params.data.total_distance_travelled_km);
                                var drivercost = Math.floor(parseFloat(dist)*parseFloat(params.data.dealer_driver_cost_per_km_fbt));
                                return drivercost;
                            }
                            else{
                                return 0;
                            }
                        }
                        catch(e){

                        }
                    }
                },{
                    headerName:"Road",
                    field:"dealer_road_cost_fbt",
                    width:150,
                    resizable:true
                },{
                    headerName:"Toll",
                    field:"dealer_toll_cost_fbt",
                    width:150,
                    resizable:true
                },{
                    headerName:"Diesel Cost",
                    field:"dealer_diesel_cost_fbt",
                    width:150,
                    resizable:true
                },{
                    headerName:"Mileage",
                    field:"dealer_mileage_fbt",
                    width:150,
                    resizable:true
                },{
                    headerName:"Total Cost",
                    field:"dealer_mileage_fbt",
                    width:150,
                    resizable:true,
                    valueGetter:function(params){
                        try{
                            var a=0;
                            var b=0;
                            var c=0;
                            var ds= 0;
                            var dist = convertMeters(params.data.total_distance_travelled_km);
                            
                            ds = ((parseFloat(dist)/parseFloat(params.data.dealer_mileage_fbt))* parseFloat(params.data.dealer_diesel_cost_fbt)).toFixed(2);
                            // console.log("ds ",ds)
                            if(params.data.dealer_driver_cost_per_km_fbt != undefined)
                            {
                                var drivercost = Math.floor(parseFloat(dist)*parseFloat(params.data.dealer_driver_cost_per_km_fbt));
                                a = parseFloat(drivercost);
                            }
                            
                            if(params.data.dealer_road_cost_fbt != undefined)
                            {
                                b = parseInt(params.data.dealer_road_cost_fbt);
                            }
                            if(params.data.dealer_toll_cost_fbt != undefined)
                            {
                                c = parseInt(params.data.dealer_toll_cost_fbt);
                            }
                            return parseFloat(a)+parseInt(b)+parseInt(c)+parseFloat(ds)
                        }
                        catch(e){}
                    }
                }]
            },
            // {
            //     headerName: "1617R - CHS",
            //     width: 150,
            //     resizable: true,
            //     children:[{
            //         headerName:"Per KM Cost Driver cost",
            //         field:"dealer_driver_cost_per_km_chs",
            //         width:150,
            //         resizable:true
            //     },
            //     {
            //         headerName:"Driver Cost",
            //         field:"dealer_driver_cost_per_km_chs",
            //         width:150,
            //         resizable:true,
            //         valueGetter:function(params){
            //             try{
            //                 if(params.data.dealer_driver_cost_per_km_chs != undefined)
            //                 {
            //                     var dist = convertMeters(params.data.total_distance_travelled_km);
            //                     var drivercost = Math.floor(parseFloat(dist)*parseFloat(params.data.dealer_driver_cost_per_km_chs));
            //                     return drivercost;
            //                 }
            //                 else{
            //                     return 0;
            //                 }
            //             }
            //             catch(e){

            //             }
            //         }
            //     },{
            //         headerName:"Road",
            //         field:"dealer_road_cost_chs",
            //         width:150,
            //         resizable:true
            //     },{
            //         headerName:"Toll",
            //         field:"dealer_toll_cost_chs",
            //         width:150,
            //         resizable:true
            //     },{
            //         headerName:"Diesel Cost",
            //         field:"dealer_diesel_cost_chs",
            //         width:150,
            //         resizable:true
            //     },{
            //         headerName:"Mileage",
            //         field:"dealer_mileage_chs",
            //         width:150,
            //         resizable:true
            //     }]
            // }           
            
        ]
        return (
            <div className="container-fluid">          
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
               
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>
                                    <span>Vendor Payments - {(this.state.rowData.length > 0)?this.state.rowData[0].dealer_name+" - "+this.state.rowData[0].dealer_city:""}</span>                                    
                                </h5>
                            </div>
                            <div className="card-body pt-15px">
                                
                                <div className="row">
                                    <div id="myGrid" style={{marginTop:"20px", height: "480px",width: "100%"}}  className="ag-theme-balham">
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={columnwithDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.rowData}
                                            enableCharts={true}
                                            enableRangeSelection={true}
                                            autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            frameworkComponents={this.state.frameworkComponents}
                                            detailCellRendererParams={this.state.detailCellRendererParams}
                                            stopEditingWhenGridLosesFocus={true}
                                            enableCellChangeFlash={false}
                                            rowClassRules={this.state.rowClassRules}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            masterDetail={true}
                                            rowSelection={this.state.rowSelection}
                                            suppressRowClickSelection={true}
                                            onPaginationChanged={this.resetPaginationSelection}
                                            
                                        />
                                            
                                        
                                        </div> 
                                        

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"dataLoadpage " +(this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div className="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>

            </div>
        );
    }
}


function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}       
function GetSortDescOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
}       
function GetSortASCOrder(prop) {    
	return function(a, b) {    
		if (a[prop] > b[prop]) {    
			return 1;    
		} else if (a[prop] < b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 

function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600*24));
    var h = Math.floor(seconds % (3600*24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);
    
    var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay;
}
function convertMeters(metersValue)
{
    return (metersValue/1000).toFixed(2);
}