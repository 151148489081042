import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import SweetAlert from 'react-bootstrap-sweetalert';
import ComponentIndex from '../common/appendComponents';
import MapComponent from './mapLocationComponent';
import { getDDMMYYYY, 
	getDDMMYYYYHHMMSS, 
	getYYYYMMDD, 
	getYYYYMMDDHHMMSS, 
	getHyphenYYYYMMDDHHMMSS,
	getHyphenDDMMMYYYYHHMM,
	getHyphenYYYYMMDD, 
	getHyphenDDMMYYYYHHMMSS, 
	getHyphenDDMMYYYY,
	getDDMMMYYYYHHMMDefault } from '../common/utils'; 
var redirectURL = require('../redirectURL');
var infoBox = require('../common/google-infowindow');
var oms = require(`npm-overlapping-marker-spiderfier/lib/oms.min`)
var moment = require('moment');

var googleAnalytics = require("../common/googleanalytics");
var marker;
var markers = [];
var circle;
var selectlat;
var selectlng
var allcords=[];
var map;
var doOnce = true;
var rad = function(x) {
	return x * Math.PI / 180;
  };
var rlatitude;
var rlongitude;

var fencingLength=0;
var geoFencingArea;

export default class RakesConsignmentComponent extends Component {

    constructor(props) {
        super(props);
		this.displayData = [];
        this.state = {
			showform:'show-m',
			loadshow:'show-n',
			showrefresh:'show-n',
			sliderTranslate:'',
			showdata:this.displayData,
			modules: AllModules,
        	radius:'15',
			rlatitude:'',
			rlongitude:'',
			latitude:'',
			longitude:'',
			defaultradius:0,
			coordinates:[],
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
			contentString:"testing",
			mwidth:"col-xl-12 col-lg-12",
			dept_code:'',
			defaultColDef: {
				sortable: true,
				filter: true,
				//editable: true,
				resizable: true
			},
			rowData: [],

			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
			showToolPanel: false,
			rowSelection: "single" ,
			context: { componentParent: this },
			
			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 50,
			maxBlocksInCache: 2,
			pagetitle:"Rake Positions",
			columnDefs: [
				{
				  headerName: "Rake No",
				  field: "truck_no",
				  width: 200,
				  
				  
				}, 	        
			  {
				headerName: "Transporter Name",
				field: "transporter_name",
				width: 200,
					
			  },
				{
				headerName: "Actual GPS Provider",
				field: "actual_lspuser",
				width: 200,
					
			  },
			  {
				headerName: "Last Packet Time",
				field: "timestamp",
				width: 200,
				valueGetter:function(params){
					return moment.parseZone(params.data.timestamp).format("DD-MMM-YYYY HH:mm")
				},
				//filter: "agDateColumnFilter",
				comparator: dateComparator,
				// filterParams: {
				// 	browserDatePicker: true,
				// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
				// 	// provide comparator function
				// 	comparator: function(filteredDate,cellValue,secondDate) {
				// 		cellValue = cellValue.replace(/\//g,"-")
				// 		if(cellValue != "" && cellValue != " "){
				// 			cellValue = cellValue.split(" ")[0].split("-");
				// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
				// 			var cellDate = new Date(cellValue);
				// 			if(filteredDate.getMonth() < 10){
				// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
				// 			}
				// 			else{
				// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
				// 			}
				// 			if(filterValue.split("-")[2].length < 2)
				// 			{
				// 				var temp = filterValue
				// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
				// 			}
				// 			var filteredDate = new Date(filterValue);
				// 			cellDate = cellDate.getTime();
				// 			filteredDate = filteredDate.getTime();
				// 			// console.log(cellDate,filteredDate);
				// 			if(cellDate === filteredDate)
				// 			{
				// 				return 0;
				// 			}
				// 			if(cellDate < filteredDate){
				// 				return -1;
				// 			}
				// 			if(cellDate > filteredDate)
				// 			{
				// 				return 1;
				// 			}
				// 		}
				// 	}
				// },
            },
				{
				headerName: "Last city / Town ",
				field: "area",
				width: 200,
					
			  },
			  {
				headerName: "Last State",
				field: "state",
				width: 200
			  }
				 
				 
			  ],
		};
		this.popmarker=this.popmarker.bind(this);
		this.goBack = this.goBack.bind(this);
		this.cipher = this.cipher.bind(this);
	}
	cipher = (salt) => {
		const textToChars = text => text.split('').map(c => c.charCodeAt(0));
		const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
		const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);
		try{
			return text => text.split('')
				.map(textToChars)
				.map(applySaltToChar)
				.map(byteHex)
				.join('');
		}
		catch(e){
	
		}
		
	}
		
	/*Alert Popups*/
	closeAlert = () => {
        this.setState({
            show: false
        });
    }

    onRecieveInput = (value) => {
        this.setState({
            alert: (
                <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
                    You wrote: {value}
                </SweetAlert>
            )
        });
    }
	hideAlert = () => {
        this.setState({
            alert: null
        });
	}
	
    componentDidMount(){
		// console.log("path ", this.props.match.path)
		this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		this.setState({eventAction: eventAction});

		var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
        this.renderMapDefault();
        var urlpath;
        var dpt;
        if(this.props.match.path == "/sndrakes")
		{
			urlpath = '/consignments/sndrakes';
			dpt='SNDG';
        }
        if(this.props.match.path == "/tnprakes")
		{
			urlpath = '/consignments/sndrakes';
			dpt='LOG-TNP';
        }
        this.setState({
			dept_code:dpt,
        });
        redirectURL.post(urlpath,{dept_code:encode(dpt)})
		.then((response) => {
            var rakesData = response.data.message;
            // console.log(rakesData);
			rakesData = JSON.parse(rakesData);
             console.log("truckinfo,",rakesData);
             this.setState({
                rowData:rakesData,
            });
            // console.log(this.state.rowData);
            var dCode = this.state.dept_code;
			if(rakesData.length > 0)
			{
				//console.log("UPpcase Dept ", dCode);
				var markers = [];
				rakesData.map((item) => {
						markers.push(item);
				});
				console.log("Markers ", markers);
				this.setState({
					// defaultradius:formdata.radius,
					coordinates:markers,
					mwidth:"col-xl-7 col-lg-7",
					rowData:markers,
					// radius:formdata.radius,
					loadshow:'show-n',
					showform:'show-n',
					showrefresh:'show-m',
					rlatitude:rakesData[0].latitude,
					rlongitude:rakesData[0].longitude,
				});
				
				
				var coords = markers;
				/*oms = new oms.OverlappingMarkerSpiderfier(map, {
					markersWontMove: true,
					markersWontHide: true,
					keepSpiderfied: true,
					basicFormatEvents: true
				});*/
				var p1 = {lat:parseFloat(rakesData[0].latitude), lng:parseFloat(rakesData[0].longitude)};
				coords.map((marker) => {
					//console.log("marker", marker);
					var p2 = {lat:marker.latitude, lng:marker.longitude};
					//console.log("Distace is ", this.getDistance(p1,p2));
					//console.log(marker.distance +"<= "+ response.data.radius)
					var distance =  this.getDistance(p1,p2);
					//console.log(window.google.maps.geometry.spherical.computeDistanceBetween (p1, p2))
					// if (distance <= formdata.radius)
					// {
						allcords.push(marker);
					// }
					
				});
				window.initMap = this.initMap
				this.renderMap();
			}
			else{
				this.setState({
					show: true, basicType:'success', basicTitle:"No Data found.",
					loadshow:'show-n',
					showform:'show-m',
				});
				
			}
        });
		
	}
	
	logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
	}
	
	renderMapDefault = () => {
    	
		loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyD1AFWPoJqPaemwGO4A7P7Q6CsUJ1TLGJk&libraries=places,drawing&callback=initMapDefault");
		window.initMapDefault = this.initMapDefault
 	}
	renderMap = () => {		
		loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyD1AFWPoJqPaemwGO4A7P7Q6CsUJ1TLGJk&libraries=places,drawing&callback=initMap");
		window.initMap = this.initMap
	}
	initMapDefault = () => {
		if(this.state.rowData.length <= 0 ){
            var lt=28.4519751;
            var ln=77.0310713;
        }
        else{
            var lt=this.state.rowData[0].latitude;
            var ln=this.state.rowData[0].longitude; 
        }
	
    	map = new window.google.maps.Map(document.getElementById('map_canvas'), {
            zoom: 13,
            center: new window.google.maps.LatLng(lt,ln),
            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
			/*mapTypeControl: true,
			gestureHandling: 'greedy',
            mapTypeControlOptions: {
                style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: window.google.maps.ControlPosition.TOP_RIGHT
              },            
            disableDefaultUI: true,
            zoomControl: true*/
		});
		var markers = [];
	

		circle = new window.google.maps.Circle({
            map: map
		});
		
		marker = new window.google.maps.Marker({
			map: map,
			draggable: true,
			animation: window.google.maps.Animation.DROP,
			//position: {lat: 28.48, lng: 77.06}
		});
		
		marker.addListener('click', function(){
			marker.setAnimation(window.google.maps.Animation.BOUNCE)			
		});
		
  		window.google.maps.event.addListener(map, 'click', function(event) {
			
			document.getElementById("latitude").value = event.latLng.lat();
			document.getElementById("longitude").value = event.latLng.lng();
			marker.setPosition(event.latLng);
			
		});
		circle.bindTo('center', marker, 'position');
		
		
		
		var eventtrigger = document.getElementsByClassName('truck');

		  // var searchBox = new window.google.maps.places.SearchBox(eventtrigger);
		  //map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(eventtrigger);
		 
	}
	 initMap = () => {
        var contentdata = this.state.contentString;
        console.log("initmap funciton");
        console.log(this.state.rowData);
        if(this.state.rowData.length == 0){
            var lt=28.4519751;
            var ln=77.0310713;
        }
		else{
			var lt=this.state.rowData[0].latitude;
			var ln=this.state.rowData[0].longitude;
		}
    	map = new window.google.maps.Map(document.getElementById('map_canvas'), {
            zoom: 11,
            center: new window.google.maps.LatLng(lt,ln),
            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
		
		});

		
		var markers = [];
		
		var bounds = new window.google.maps.LatLngBounds();

		circle = new window.google.maps.Circle({
            map: map
		});
		
		circle.bindTo('center', marker, 'position');
		var markerLatlng1;
		//console.log("Radius ", this.state.radius)
		//console.log("state Lat ",this.state.rlatitude)
		if(this.state.rlatitude != '' && this.state.rlongitude != '')
		{
			//var geoFencingObj = allcords;
			//console.log(parseFloat(this.state.rlatitude),parseFloat(this.state.rlongitude));
			markerLatlng1 = new window.google.maps.LatLng(this.state.rlatitude,this.state.rlongitude);
			//console.log("markerLatlng", markerLatlng1)
			var circle = new window.google.maps.Circle({
				strokeColor: '#71caab',
				strokeOpacity: 0.7,
				strokeWeight: 1,
				fillColor: '#71caab',
				fillOpacity: 0.25,
				map: map,
				center: markerLatlng1,
				//radius: Math.sqrt(this.state.radius) * 10000
			});

		}
	
		//console.log("allcords initmap ", allcords)
		if(doOnce){
        	initOMS();
          	doOnce = false;
        }
		allcords.map((marker) => {
		//console.log("marker ", marker)
			var image =  require('../../assets/icons/traindeliver.png');
			var contentarr = []
			var headerTitle = marker.truck_no
			contentarr.push({"key":"Transporter Name", "value":marker.transporter_name});
			contentarr.push({"key":"GPS Provider", "value":marker.actual_lspuser});
			contentarr.push({"key":"Last Packet Time", "value":getHyphenDDMMMYYYYHHMM(marker.timestamp)});
			contentarr.push({"key":"Speed (km/h)", "value":marker.speed});
			contentarr.push({"key":"Address", "value":marker.area+","+marker.state});
			
			var contentString = infoBox(image, headerTitle, contentarr, '')


			//var contentString = "Here "+marker.truck_no;
			var infowindow = new window.google.maps.InfoWindow({
				content: contentString
			});
			
			var markerLatlng = new window.google.maps.LatLng(parseFloat(marker.latitude), parseFloat(marker.longitude));
		

			var image =  require('../../assets/icons/traindeliver.png');
			var mark = new window.google.maps.Marker({
                position: markerLatlng,
				map: map,
				title:marker.truck_no,
				icon: image
            });
			mark.addListener('click', function() {
				infowindow.open(map, mark);
			  });
			mark.setMap(map);
			bounds.extend(new window.google.maps.LatLng(parseFloat(marker.latitude), parseFloat(marker.longitude)));
			
			map.fitBounds(bounds);
		});
		var eventtrigger = document.getElementsByClassName('truck');

		  // var searchBox = new window.google.maps.places.SearchBox(eventtrigger);
		  //map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(eventtrigger);
		 
	}
	toggleBounce() {
		if (marker.getAnimation() !== null) {
		  marker.setAnimation(null);
		} else {
		  marker.setAnimation(window.google.maps.Animation.BOUNCE);
		}
		
	  }
	
	changeInputHandler = (event) => {
    	let name = event.target.name;
		let value = event.target.value;
    	this.setState({[name]:value});
    }
    
	changeRadiusHandler = (event) => {
    	let name = event.target.name;
		let value = event.target.value;
		this.setState({[name]:value});
		circle.setRadius(value);
    }
    
	formHandler = (event) =>{
		event.preventDefault();
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.formAction,
			}
			googleAnalytics.logEvent(eventOptions);
		}

		this.setState({
			loadshow:'show-m',
        });
		var formdata = {
			radius:this.state.radius,
			latitude:document.getElementById("latitude").value,
			longitude:document.getElementById("longitude").value
		}
		rlatitude=document.getElementById("latitude").value;
		rlongitude=document.getElementById("longitude").value;
		//http://autometrics.in:5000/getNearByTrucks
		redirectURL.post('/getRadiusCoordinates',formdata,{
			headers:{
				'Content-Type':'application/json',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods': 'PUT, GET, POST, DELETE, OPTIONS',
				'Access-Control-Allow-Headers': "Origin, X-Requested-With, Content-Type, Accept, Authorization"

			}
		})
		.then(
			(response) =>{
			//console.log("response", JSON.parse(response.data.body));
			allcords=[];
			var allmarkers=JSON.parse(response.data.body);
			//console.log("Dept ", this.state.dept_code);
			var dCode = this.state.dept_code;
			if(allmarkers.length > 0)
			{
				//console.log("UPpcase Dept ", dCode);
				var markers = [];
				allmarkers.map((item) => {
					if(item.dept_code == dCode.toUpperCase())
					{
						markers.push(item);
					}
				});
				console.log("Markers ", markers);
				this.setState({
					defaultradius:formdata.radius,
					coordinates:markers,
					mwidth:"col-xl-7 col-lg-7",
					rowData:markers,
					radius:formdata.radius,
					loadshow:'show-n',
					showform:'show-n',
					showrefresh:'show-m',
					rlatitude:formdata.latitude,
					rlongitude:formdata.longitude,
				});
				
				
				var coords = markers;
				/*oms = new oms.OverlappingMarkerSpiderfier(map, {
					markersWontMove: true,
					markersWontHide: true,
					keepSpiderfied: true,
					basicFormatEvents: true
				});*/
				var p1 = {lat:parseFloat(formdata.latitude), lng:parseFloat(formdata.longitude)};
				coords.map((marker) => {
					//console.log("marker", marker);
					var p2 = {lat:marker.latitude, lng:marker.longitude};
					//console.log("Distace is ", this.getDistance(p1,p2));
					//console.log(marker.distance +"<= "+ response.data.radius)
					var distance =  this.getDistance(p1,p2);
					//console.log(window.google.maps.geometry.spherical.computeDistanceBetween (p1, p2))
					if (distance <= formdata.radius)
					{
						allcords.push(marker);
					}
					
				});
				window.initMap = this.initMap
				this.renderMap();
			}
			else{
				this.setState({
					show: true, basicType:'success', basicTitle:"No Data found.",
					loadshow:'show-n',
					showform:'show-m',
				});
				
			}
		})
		.catch(function(error){
			console.log(error);
		});
	}
	goBack(){
		window.location.reload();
	}
	getDistance = (p1, p2) => {
		//console.log("p1",p1);
		//console.log("p2",p2);
		var R = 6378137; // Earth’s mean radius in meter
		var dLat = rad(p2.lat - p1.lat);
		var dLong = rad(p2.lng - p1.lng);
		var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(rad(p1.lat)) * Math.cos(rad(p2.lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
		var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		var d = R * c;
		return d/1000; // returns the distance in meter
	  };
	onShowInfo = (e) =>{
		console.log(JSON.parse(e.target.id));
		this.setState({
			contentString:"Here Success"
		});
		var ourMarker = e.target.id;
 			window.google.maps.event.trigger(ourMarker, 'click');
		var infowindow = new window.google.maps.InfoWindow({
			content: "Here Success"
		  });
		infowindow.open(map, marker);

	}
	popmarker(e)
	{
		console.log("OnClick Table ",e.data)
		var lat = parseFloat(e.data.latitude);
		var lng =parseFloat(e.data.longitude);
		var data =e.data;
		map.setCenter(new window.google.maps.LatLng(lat,lng));
		map.setZoom(17);
		var markerLatlng = new window.google.maps.LatLng(lat, lng);
		//var image =  require('../../assets/icons/truckblue.png');
		var image =  require('../../assets/icons/traindeliver.png');
		/*if(data.overspeeding_exception == 1)
		{
			var image =  require('../../assets/icons/truck_overspeed.png');
		}
		
		if(data.nightdriving_exception == 1)
		{
			var image =  require('../../assets/icons/truck-end.png');
		}*/

		
		var image =  require('../../assets/icons/traindeliver.png');
		var marker = new window.google.maps.Marker({
			position: markerLatlng,
			map: map,
			title:data.truck_no,
			icon:image
		});


		var contentarr = []
		var headerTitle = e.data.truck_no
		contentarr.push({"key":"Transporter Name", "value":e.data.transporter_name});
		contentarr.push({"key":"GPS Provider", "value":e.data.actual_lspuser});
		contentarr.push({"key":"Last Packet Time", "value":getHyphenDDMMMYYYYHHMM(e.data.timestamp)});
		contentarr.push({"key":"Speed (km/h)", "value":e.data.speed});
		contentarr.push({"key":"Address", "value":e.data.area+","+e.data.state});
		
		var contentString = infoBox(image, headerTitle, contentarr, '')
		/*
		var contentString = "Truck No: "+data.truck_no+"<br />Name: "+data.transporter_name+" LSP Name:"+data.lsp_name+"<br />Department:" + data.dept_code+"<br />Address:"+data.address;
		var contentString = "<table><tr><th>Status: </th><td style='text-align: right'>"+truckText+"</td></tr><tr><th>Distance from Geofence: </th><td style='text-align: right'>"+data.distancel
		contentString = contentString + " (KM)</td></tr><th>Truck No: </th><td style='text-align: right'>"+data.truck_no+"</td></tr><tr><th>Name: </th><td style='text-align: right'>";
		contentString = contentString + data.transporter_name + "</td></tr><tr><th> LSP Name:</th><td style='text-align: right'>"+data.lsp_name+"</td></tr><tr><th>Department:</th>";
		contentString = contentString + "<td style='text-align: right'>"+data.dept_code+"</td></tr><tr><th>Address:</th><td style='text-align: right'>"+data.address+"</td></tr></table>";
			*/	
		var infowindow = new window.google.maps.InfoWindow({
			content: contentString
		});
		
		marker.setAnimation(window.google.maps.Animation.DROP)
		marker.addListener('click', function() {
			infowindow.open(map, marker);
		});
		
		marker.setMap(map);
		
		
	}
	onShowUploadDiv = () => {
		//console.log("Click Upload Button")
		this.setState({
			uploadDivWidth:'30%',
			sliderTranslate:"slider-translate",
			showDiv:'show-m',
			csvmessage:'',
			csverrmessage:'',
		});
	}
	onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderTranslate:"",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
		});
	}
	 /*Truck Sidebar Component Starts*/
	 async onShowSidebarAquireComponent(e){
	   this.setState({
		sliderTranslate:'slider-translate',
		loadshow:'show-m'
	   });
	   //console.log(e);
	   if(e.colDef.field == 'truck_no')
	   {
		   let ComponentInfo = ComponentIndex['truck'];
		   //console.log("ComponentInfo", ComponentInfo);
		   var layoutdata1 = {
			   truck_no:e.data[e.colDef.field]
		   };
		   //console.log("layoutdata",layoutdata);
		   await redirectURL.post('/masters/truckByNo', layoutdata1)
			   .then((response) => {
				   //console.log("trucks response", response);
				   var truckrecords = JSON.parse(JSON.stringify(response)).data;
				   
				   this.setState({
					   truckInfo:truckrecords
				   });
			   })
			   .catch(function (error) {
				   console.log(error);
			   });

			   /*Trucks details by Truck No*/
		   await redirectURL.post('/masters/latestConsignmentsByTruckNo', layoutdata1)
			   .then((response) => {
				   //console.log(response);
				   var consignrecords = JSON.parse(JSON.stringify(response)).data;
				   
				   this.setState({
					   consignments:consignrecords
				   });
			   })
			   .catch(function (error) {
				   console.log(error);
			   });
		   
		   /*Trucks details by Truck No*/
		   await redirectURL.post('/masters/latestTruckLocation', layoutdata1)
			   .then((response) => {
				   //console.log("HR55W4880",response);
				   var currenttrucklocation = JSON.parse(JSON.stringify(response)).data;
				   
				   this.setState({
					   currenttrucklocation:currenttrucklocation
				   });
			   })
			   .catch(function (error) {
				   console.log(error);
			   });
		   //console.log("currenttrucklocation",this.state.currenttrucklocation);
		   //setTimeout(function(){})
		   
		   this.displayData=await (<ComponentInfo context={this} truckNo={e.data[e.colDef.field]} truckInfo={this.state.truckInfo}  consignments={this.state.consignments} currenttrucklocation={this.state.currenttrucklocation}></ComponentInfo>);
		   /*		
		   this.setState({
			   showdata : this.displayData,
			   sliderTranslate:"slider-translate"
		   });
		   */

		  this.setState({
			showdata:this.displayData,
			loadshow:'show-n'
		  });
	   }

	   /*Transporters Sidebar*/
	   
	   if(e.colDef.field == 'transporter_name')
	   {
		   let ComponentInfo = ComponentIndex['transporter'];
		   //console.log("ComponentInfo", ComponentInfo);
		   var layoutdata1 = {
			   transporter_name:e.data[e.colDef.field]
		   };
		   //console.log("layoutdata",layoutdata);
		   await redirectURL.post('/masters/transporterByName', layoutdata1)
			   .then((response) => {
				   console.log("transporterByName response", response);
				   var transporterrecords = JSON.parse(JSON.stringify(response)).data;
				   var deptcodes=[];
				   var transportName='';
				   var transportCode='';
				   transporterrecords.map(item=>{
					   transportName=item.transporter_name;
					   transportCode=item.transporter_code;
					   deptcodes.push(item.dept_code+", ");
				   });
				   //console.log(transportName+","+transportCode+","+deptcodes)
				   var transportData = [{
					   transporter_name:transportName,
					   transporter_code:transportCode,
					   dept_code:deptcodes
				   }]
				   this.setState({
					   transporterInfo:transportData
				   });
			   })
			   .catch(function (error) {
				   console.log(error);
			   });

		   /*Total Trucks Operating*/
		   await redirectURL.post('/masters/totalTrucksOperating', layoutdata1)
		   .then((response) => {
			   //console.log("Total Trucks Operating response", response);
			   this.setState({
				   truckoperates:response
			   });
		   })
		   .catch(function (error) {
			   console.log(error);
		   });

		   /*Total Trucks With GPS Integration Lifetime*/
		   await redirectURL.post('/masters/totalTrucksGPSLifetime', layoutdata1)
		   .then((response) => {
			   console.log("Total Trucks Operating response", response);
			   this.setState({
				   truckgpslifetime:response
			   });
		   })
		   .catch(function (error) {
			   console.log(error);
		   });
			   
		   /*Total Trucks With GPS Integration Today*/
		   await redirectURL.post('/masters/totalTrucksGPSToday', layoutdata1)
		   .then((response) => {
			   //console.log("Total Trucks Operating response", response);
			   this.setState({
				   gpstoday:response
			   });
		   })
		   .catch(function (error) {
			   console.log(error);
		   });

			   /*Transporters Consignment details*/
		   await redirectURL.post('/masters/latestConsignmentsByTransporterName', layoutdata1)
			   .then((response) => {
				   //console.log(response);
				   var consignrecords = JSON.parse(JSON.stringify(response)).data;
				   var consignrecordslist=[];
				   consignrecords.map(item=>{
					   if(item.consigns.length > 0)
					   {
						   item.consigns.map(cons => {
							   consignrecordslist.push(cons)
						   })
						   
					   }
				   });
				   
				   console.log("consignrecords list", consignrecordslist);
				   this.setState({
					   consignments:consignrecordslist
				   });
			   })
			   .catch(function (error) {
				   console.log(error);
			   });
		   
		   
		   this.displayData=await (<ComponentInfo context={this} gpstoday={this.state.gpstoday} gpsLifetime={this.state.truckgpslifetime} transportName={e.data[e.colDef.field]} transporterInfo={this.state.transporterInfo} totalTruckOperating={this.state.truckoperates} consignments={this.state.consignments} ></ComponentInfo>);
		   
		   this.setState({
			showdata:this.displayData,
			loadshow:'show-n'
		  });
	   }
	   /*End*/
	   
	   /*LSP Sidebar*/
	   
	   if(e.colDef.field == 'actual_lspuser')
	   {
		   let ComponentInfo = ComponentIndex['lsp'];
		   //console.log("ComponentInfo", ComponentInfo);
		   var layoutdata2 = {
			   actual_lspuser:e.data[e.colDef.field]
		   };
		   console.log("layoutdata2", layoutdata2);
		   //Total Trucks Integrate;
		   await redirectURL.post('/masters/totalTruckIntegrate', layoutdata2)
			   .then((response) => {
				   var lsprecords = JSON.parse(JSON.stringify(response)).data.count;
				   //console.log("LSP response serveeeee", lsprecords);
				   
				   var result = lsprecords.reduce(function(sum, item){
					   return sum = sum+item.count;
				   },0);
					   console.log("result", result);
				   this.setState({
					   lspInfo:result
				   });
			   })
			   .catch(function (error) {
				   console.log(error);
			   });

		   /*Department Serving*/
		   await redirectURL.post('/masters/totalDeptServe', layoutdata2)
		   .then((response) => {
			   var deprecords = JSON.parse(JSON.stringify(response)).data;
			   //console.log("LSP DeptServe response", deprecords);
			   
			   var deptcnt=[];
			   deprecords.map(item => {
				   deptcnt.push(item._id)
			   });
			   this.setState({
				   deptServes:deptcnt.length
			   });
		   })
		   .catch(function (error) {
			   console.log(error);
		   });

		   /*No Of Transporters Serving*/
		   await redirectURL.post('/masters/totalTransporterServe', layoutdata2)
		   .then((response) => {
			   var transrecords = JSON.parse(JSON.stringify(response)).data;
			   //console.log("Transporter serve response", transrecords);
			   var trasportcnt=[];
			   transrecords.map(item => {
				   trasportcnt.push(item._id)
			   });
			   this.setState({
				   transrecordserves:trasportcnt.length
			   });
		   })
		   .catch(function (error) {
			   console.log(error);
		   });

		   /*No Of GPS DATA Send Today*/
		   await redirectURL.post('/masters/totalGPSSendToday', layoutdata2)
		   .then((response) => {
			   var gpssendrecords = JSON.parse(JSON.stringify(response)).data;
			   //console.log("GPS Send Data response", gpssendrecords);
			   
			   this.setState({
				   gpssendtoday:gpssendrecords.length
			   });
		   })
		   .catch(function (error) {
			   console.log(error);
		   });

		   /*Total Trucks with no transporter data*/
		   await redirectURL.post('/masters/trucksWithNoTransporter', layoutdata2)
		   .then((response) => {
			   var notransrecords = JSON.parse(JSON.stringify(response)).data;
			   //console.log("No Trasnporter Data response", notransrecords);
			   
			   this.setState({
				   nopetransporters:notransrecords.length
			   });
		   })
		   .catch(function (error) {
			   console.log(error);
		   });
		   
		   this.displayData=await (<ComponentInfo context={this} nopetransporters={this.state.nopetransporters} gpssendtoday={this.state.gpssendtoday} transportersServes={this.state.transrecordserves} deptServes={this.state.deptServes} lspName={e.data[e.colDef.field]} lspInfo={this.state.lspInfo} ></ComponentInfo>);
		   /*		
		   this.setState({
			   showdata : this.displayData,
			   sliderTranslate:"slider-translate"
		   });
		   */

		  this.setState({
			showdata:this.displayData,
			loadshow:'show-n'
		  });

	   }
	   /*End*/
   }

    render(){
		var hText = 'All';
		if(this.state.dept_code == 'sndg')
		{
			hText = 'Sales and Dispatch'
		}
		if(this.state.dept_code == 'log-tnp')
		{
			hText = 'Train and Production'
		}
	
		if(this.state.dept_code == 'log-prt')
		{
			hText = 'Spare Parts'
		}
    	const dStyles={
			width:'90%',
			marginLeft:'0px',
			paddingLeft:'0px',
			height:'500px'
		}
		const btstye={
			marginBottom:"10px"
		}
        return (
            
    		<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
	            {this.state.alert}
            	<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
		            	
			                <div className="card-header">
				       			<h5>
				       				<i className="icon-map cus-i"></i> <span>Rake Positions  </span>
				       				{/*<span className="float-right">
										<img className="mapico2" src={require('../../assets/icons/truck_overspeed.png')} /> - Overspeed
										&nbsp;&nbsp;<img className="mapico" src={require('../../assets/icons/truck-end.png')} /> - Night Driving
									
									</span> */}
									<span className="col-xl-12 col-lg-12 pull-right" style={{fontSize:"12px",marginBottom:"20px"}}>
										{/*<img className="mapico1" src={require('../../assets/icons/truck_na_32.png')} /> - N/A
										&nbsp;&nbsp;<img className="mapico1" src={require('../../assets/icons/truck_inside_32.png')} /> - Inside Plant
										&nbsp;&nbsp;<img className="mapico1" src={require('../../assets/icons/truck_going_32.png')} /> - Going to Dealer
										&nbsp;&nbsp;<img className="mapico1" src={require('../../assets/icons/truck_atdealer_32.png')} /> - At Dealer Location
										&nbsp;&nbsp;<img className="mapico1" src={require('../../assets/icons/truck_leftdealer_32.png')} /> - Left Dealer Location
										&nbsp;&nbsp;<img className="mapico1" src={require('../../assets/icons/truck_return_32.png')} /> - Return to Plant
										&nbsp;&nbsp;<img className="mapico" src={require('../../assets/icons/truck_empty_32.png')} /> - Empty Truck Outside Plant
										*/}
									</span>									
								</h5>
				   			</div>
				   			<div className="card-body row">
							   {/* <form className={" theme-form col-xl-12 col-lg-12 "+ (this.state.showform)} onSubmit={this.formHandler}>
	                    			<div className="row">
					               		<div className="form-group  col-xl-3 col-lg-3">
					               			<label className="c-lbl">Radius *:</label>
					               			<input type="text" name="radius" id="radius" value={this.state.radius} onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="Radius" required />
					               		</div>
										<div className="form-group col-xl-3 col-lg-3">
					               			<label className="c-lbl">Latitude *:</label>
					               			<input type="text" name="latitude" id="latitude" onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="Latitude" required />
					               		</div>
										<div className="form-group col-xl-3 col-lg-3">
					               			<label className="c-lbl">Longitude *:</label>
					               			<input type="text" name="longitude" id="longitude" onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="Longitude" required />
					               		</div>
										<div className="form-group col-xl-3 col-lg-3">
					               			<label className="c-lbl"></label>
											<button type="submit" style={{marginTop:"35px"}} className="btn btn-success">Submit</button>
										</div>
									</div>
									
				
								</form>
								<div className={"col-xl-12 col-lg-12 "+(this.state.showrefresh)}>
										<button type="button" style={{marginRight:"30px"}} className={"btn btn-danger float-right"} onClick={this.goBack} >Back</button>
									</div> */}
								<div className="row col-xl-12 col-lg-12">
								    
									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
									</div>
									
									<div id="map_canvas" className={this.state.mwidth} style={dStyles}></div>
									{
									(this.state.coordinates.length > 0)?
										<div className="col-xl-5 col-lg-5" style={{height:"500px"}}>
											

											<div id="myGrid" style={{ height: "532px",width: "100%"}}  className="ag-theme-balham">
												<AgGridReact
													modules={this.state.modules}
													columnDefs={this.state.columnDefs}
													defaultColDef={this.state.defaultColDef}
													rowData={this.state.rowData}
													enableCharts={false}
													enableRangeSelection={false}
													autoGroupColumnDef={this.state.autoGroupColumnDef}
													onGridReady={this.onGridReady}
													onGridState={this.onGridState}
													statusBar={this.state.statusBar}
													sideBar={this.state.sideBar}
													paginationPageSize={this.state.paginationPageSize}
													pagination={true}
													enableRangeSelection={true}
													//onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
												    onCellClicked={this.popmarker}
												/>
											</div>
										</div>
										: ""
									}
								</div>
							   		
                            </div>
				   		</div>	
				   	</div>
				 </div>

				 
				 <div className={"slide-r "+(this.state.sliderTranslate)} >
				 	
					
				 	<div className="slide-r-body" style={{position:"relative"}}>
					 <div className={"dataLoadpage " +(this.state.loadshow)}>
					</div>
					<div className={"dataLoadpageimg " +(this.state.loadshow)}>
						<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
					</div>
				 		<div className="col-xl-12 col-lg-12">
				 			 {this.displayData}
						</div>
					</div>
				 </div>


            </div>
              
        );
    }
}


function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function getPolygonCoords()
{
	
	 var len = geoFencingArea.getPath().getLength();
	 console.log(len);
	 var htmlStr = "";
	 var coordinates=[];
	 for (var i = 0; i < len; i++) {
		// var latlngd = {"lat":parseFloat(each_lat_lng[0]),"lng":parseFloat(each_lat_lng[1])};
		// coordinates.push()
		 var strlatlng = geoFencingArea.getPath().getAt(i).toUrlValue(5);
		 var spliting = strlatlng.split(",");
		 var latlngd = {"lat":parseFloat(spliting[0]),"lng":parseFloat(spliting[1])};
		 coordinates.push(latlngd);
	  // htmlStr += "new google.maps.LatLng(" + geoFencingArea.getPath().getAt(i).toUrlValue(5) + "), ";
	   //Use this one instead if you want to get rid of the wrap > new google.maps.LatLng(),
	   //htmlStr += "" + geoFencingArea.getPath().getAt(i).toUrlValue(5);
	 }
	// console.log("htmlStr", coordinates);
	 
}
function initOMS(){
    // Initializing OMS
    oms = new oms.OverlappingMarkerSpiderfier(map, {
      //markersWontMove: true,
      //keepSpiderfied: true,
      //nearbyDistance: 10,
      //circleFootSeparation: 60,
	  //legWeight: 1.5
	  markersWontMove: true,
	markersWontHide: true,
	keepSpiderfied: true,
	basicFormatEvents: true
    });
  }

  function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}