import React from "react";
const GridButton = (props) => {
    const handleOnClick = () => {
        props.colDef.params.onClickFunction(props, true);
    };
    return (
        <div>
            <button
                className={"btn btn-info grid-btn"}
                onClick={handleOnClick}
                style={{ border: "0px", position: "relative", paddingLeft: "10px", paddingRight: "10px", borderRadius: "4px" }}
                title="View Details"
            >
                <i className={'fa fa-eye'}></i> {props.colDef.params.buttonName}
            </button>
        </div>
    );
};
export default GridButton;
