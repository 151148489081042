/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import "@grapecity/wijmo.styles/wijmo.css";
import * as pdf from "@grapecity/wijmo.pdf";
import * as wijmo from "@grapecity/wijmo";
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenYYYYMMDDHHMMSS } from '../common/utils';


var redirectURL = require('../redirectURL');

var columnDefs = [
							
    {
        header:"Unique Code",
        binding:"item_uniquecode"
    },
    {
        header:"Invoice No",
        binding:"invoice_no"
    },
    {
        header:"Material No",
        binding:"material_no"
    },
    {
        header:"Material Description",
        binding:"material_desc"
    },
    
    {
        header:"Supplying Plant Description",
        binding:"supply_plant_desc"
    },
    {
        header:"Invoice Date",
        binding:"invoice_date_frt"
    },
    
    {
        header:"Vin No",
        binding:"truck_no"
    },
    
    {
        header:"Transporter Name",
        binding:"transporter_name"
    },
    {
        header:"POD Date",
        binding:"customer_pod_date_frt"
    },
   
    {
        header:"Item Code",
        binding:"item_code"
    },
   
    {
        header:"Item Description",
        binding:"item_description"
    },
   
    {
        header:"Defect Code",
        binding:"defect_code"
    },
   
    {
        header:"Defect Description",
        binding:"defect_description"
    },
   
    {
        header:"Category",
        binding:"category"
    },
   
    {
        header:"Quantity",
        binding:"quantity"
    },
    {
        header:"Remarks",
        binding:"customer_pod_remarks"
    },
    {
        header:"Document 1",
        binding:"invoice_document_1"
    },
    {
        header:"Document 2",
        binding:"invoice_document_2"
    }  
]

export default class ExportPDF extends Component {

    constructor(props) {
        super(props);
        // this.colWidth = 130;
        this.rowHeight = 20;
        this.state =
        {
            rowData: [],
            pdfColumns: [],
            adaniCheck: 0,
            addressColumn: "",
            colWidth: null,
            invoice_no :"",
            invoice_date :"",
            reached_destination :"",
            vin_no:"",
            supplying_plant :"",
            transporter_name:"",
            rowData:[]
        };
    }

    componentDidMount() {
        // console.log(this.props,"cnsole")
        var pdata = this.props.data;
        var invoice_no = pdata.invoice_no;
        var invoice_date = pdata.inv_date;
        var reached_destination = pdata.rec_dealer_reported;
        var vin_no = pdata.truck_no;
        var supplying_plant = pdata.consigner_name;
        var transporter_name = pdata.transporter_name;

       

        //   var rr = {
        //         dealer_code:this.props.data.consignee_code,
        //         invoice_no:this.props.data.invoice_no
        //     }
        //     redirectURL.post("/dashboard/customerconsignments",rr)
        //     .then((resp) => {
        //         var records = resp.data.records;
        //         var recordsarr = []
        //         if(records.length > 0)
        //         { 
        //             try{
        //                 var pods = records[0].pods;
        //                 pods.map((item) => {
        //                     item.truck_no = this.props.data.truck_no;
        //                     item.invoice_date = getHyphenDDMMMYYYYHHMM(item.invoice_date);
        //                     item.customer_pod_date = getHyphenDDMMMYYYYHHMM(item.customer_pod_date);
        //                     recordsarr.push(item);
        //                 })
        //             }
        //             catch(e){}
                    
        //         }
        //         this.setState({
        //             rowData:recordsarr
        //         });
        //     })
            
        this.setState({
            
            invoice_no :invoice_no,
            invoice_date :invoice_date,
            reached_destination :reached_destination,
            vin_no:vin_no,
            supplying_plant :supplying_plant,
            transporter_name:transporter_name
        })
        if(this.props.data.pods != undefined)
        {
            var rowData = this.props.data.pods;
        }
        else{
            var rowData = [];
        }
        // var pdfColumns = this.props.pdfColumns;
        var addressColumnWidth = this.props.addressColumn;
        // console.log("pdfrowData", rowData)
        if (addressColumnWidth == "undefined" || addressColumnWidth == "" || addressColumnWidth == null) {
            this.setState({
                colWidth: 130
            })
        } else {
            this.setState({
                colWidth: addressColumnWidth
            })
        }
        this.setState({
            rowData: rowData,
            pdfColumns: columnDefs
        })
        // console.log("rowDataTest: => ", rowData)
    }

    componentWillReceiveProps(newprops) {
        // console.log("receivedProps",newprops.rowData)
        if(newprops.data.pods != undefined)
        {
            var rowData = newprops.data.pods;
        }
        else{
            var rowData = [];
        }
        this.setState({
            rowData: rowData,
            pdfColumns: columnDefs
        })
    }

    exportPDF() {
        var invoice_no = this.state.invoice_no;
        var invoice_date = this.state.invoice_date;
        var reached_destination = this.state.reached_destination;
        var vin_no = this.state.vin_no;
        var supplying_plant = this.state.supplying_plant;
        var transporter_name = this.state.transporter_name;
        
        let doc = new pdf.PdfDocument({
            header: {
                declarative: {
                    text: this.props.pagetitle,
                    font: new pdf.PdfFont('times', 12),
                    brush: '#bfc1c2'
                }
            },
            footer: { declarative: { text: '\t&[Page] of &[Pages]' } },
            lineGap: 2,
            pageSettings: {
                margins: {
                    left: 10,
                    right: 10,
                    top: 10,
                    bottom: 10
                },
                size: "Legal"
            },
            pageAdded: function (s, e) {
                let image = require('../../assets/icons/logo.png');
                let enmovilImage = require('../../assets/icons/enmovil.png');
                //
                s.drawImage(image, null, null, {
                    width: 150,
                    height: 30,
                    stretchProportionally: true,
                    align: pdf.PdfImageHorizontalAlign.Center
                });
                s.drawImage(enmovilImage, 1000, 0, {
                    width: 100,
                    height: 30,
                    stretchProportionally: true,
                    align: pdf.PdfImageHorizontalAlign.Right
                });
                
                // s.drawText("Series No: "+invoice_no ,null,null, { align: pdf.PdfImageHorizontalAlign.Left, font: new pdf.PdfFont('times', 12, 'normal', 'bold'), margins:{top:20} });
                // s.drawText("Inovice Date: "+invoice_date ,null,null, { align: pdf.PdfImageHorizontalAlign.Right, font: new pdf.PdfFont('times', 12, 'normal', 'bold'), margins:{top:20} });
                // s.drawText("Vin No: "+vin_no ,null,null, { align: pdf.PdfImageHorizontalAlign.Left, font: new pdf.PdfFont('times', 12, 'normal', 'bold'), margins:{top:20} });
                // s.drawText("Reached Destination: "+reached_destination ,null,null, { align: pdf.PdfImageHorizontalAlign.Right, font: new pdf.PdfFont('times', 12, 'normal', 'bold'), margins:{top:20} });
                // s.drawText("Supplying Plant: "+supplying_plant ,null,null, { align: pdf.PdfImageHorizontalAlign.Left, font: new pdf.PdfFont('times', 12, 'normal', 'bold'), margins:{top:20} });
                // s.drawText("Transporter Name: "+transporter_name ,null,null, { align: pdf.PdfImageHorizontalAlign.Right, font: new pdf.PdfFont('times', 12, 'normal', 'bold'), margins:{top:20} });
            },
            ended: (sender, args) => pdf.saveBlob(args.blob, 'Document.pdf')
        });

        doc.footer.drawText("Enmovil Solutions", null, null, {
            align: pdf.PdfTextHorizontalAlign.Right
        });
        var consignees = this.state.rowData;
        // var consignees = [];
        // console.log("cCheck=:",this.state.rowData)
        // console.log(consignees,"consignees")

        consignees.map(t => {
            
              return ({item_uniquecode : t.item_uniquecode,
				invoice_no: t.invoice_no,
				material_no : t.material_no,
				material_desc: t.material_desc,
				supply_plant_desc: t.supply_plant_desc,
				invoice_date_frt: t.invoice_date_frt,
				truck_no: t.truck_no,
				transporter_name:t.tpt_name,
				customer_pod_date_frt: t.customer_pod_date_frt,
				item_code: t.item_code,
				item_description: t.item_description,
				defect_code: t.defect_code,
				defect_description: t.defect_description,
				category: t.category,
				quantity: t.quantity,
				customer_pod_remarks: t.customer_pod_remarks,
				invoice_document_1: t.invoice_document_1,
				invoice_document_2: t.invoice_document_2
			})
        });
        // console.log("consignees ", consignees)
        if (consignees.length > 50) {
            var totalPages = (consignees.length / 50).toString();
            var lastRemainder = parseInt(totalPages.split(".")[1])
            totalPages = parseInt(totalPages.split(".")[0])
            //console.log(totalPages,"totalPages")
            //console.log(lastRemainder,"lastRemainder")
            var endPage = 50
            var startPage = 0
            while (consignees.length !== 0) {
                let sen = consignees.splice(0, 50)
                this.$_drawEmployee(doc, sen);
            }
            // for (var i = 0; i < totalPages; i++) {
            //     //console.log(startPage, endPage);
            //     var sen = consignees.slice(startPage, endPage);
            //     this.$_drawEmployee(doc, sen);

            //     if ((i + 1) == totalPages) {
            //         var sen1 = consignees.slice(endPage + 1, lastRemainder);
            //         this.$_drawEmployee(doc, sen1);
            //     }
            //     else {

            //         startPage = endPage + 1;
            //         endPage = endPage + 75;
            //     }

            // }
        }
        else {
            this.$_drawEmployee(doc, consignees);
        }

        // consignees.forEach((employee, i, arr) => {
        //     this.$_drawEmployee(doc, employee);
        //     if (i < arr.length - 1) {
        //         doc.addPage();
        //     }
        // });

        doc.end();
    }
    //
    $_drawEmployee(doc, consignees) {

        var invoice_no = this.state.invoice_no;
        var invoice_date = this.state.invoice_date;
        var reached_destination = this.state.reached_destination;
        var vin_no = this.state.vin_no;
        var supplying_plant = this.state.supplying_plant;
        var transporter_name = this.state.transporter_name;

        doc.drawText("Series No: "+invoice_no ,null,null, { align: pdf.PdfImageHorizontalAlign.Left, font: new pdf.PdfFont('times', 10, 'normal', 'bold'), margins:{top:20} });
        doc.drawText("Inovice Date: "+invoice_date ,null,null, { align: pdf.PdfImageHorizontalAlign.Right, font: new pdf.PdfFont('times', 10, 'normal', 'bold'), margins:{top:20} });
        doc.drawText("Vin No: "+vin_no ,null,null, { align: pdf.PdfImageHorizontalAlign.Left, font: new pdf.PdfFont('times', 10, 'normal', 'bold'), margins:{top:20} });
        doc.drawText("Reached Destination: "+reached_destination ,null,null, { align: pdf.PdfImageHorizontalAlign.Right, font: new pdf.PdfFont('times', 10, 'normal', 'bold'), margins:{top:20} });
        doc.drawText("Supplying Plant: "+supplying_plant ,null,null, { align: pdf.PdfImageHorizontalAlign.Left, font: new pdf.PdfFont('times', 10, 'normal', 'bold'), margins:{top:20} });
        doc.drawText("Transporter Name: "+transporter_name ,null,null, { align: pdf.PdfImageHorizontalAlign.Right, font: new pdf.PdfFont('times', 10, 'normal', 'bold'), margins:{top:20} });
    

        //let tot = employee.expenses.totals;
        let expenses = consignees;
        // console.log(expenses, "expenses")

        if (expenses.length > 0) {
            let columns = this.state.pdfColumns, bold = new pdf.PdfFont('times', 10, 'normal', 'bold'),
                colWidth = this.state.colWidth, rowHeight = this.rowHeight;

            let y = doc.y;

            doc.moveDown(2);

            // * draw table *
            doc.saveState();

            y = 0;
            let scale = doc.width / (columns.length * colWidth), docY = doc.y;

            if (scale > 1) {
                scale = 1;
            }

            doc.scale(scale, scale, new wijmo.Point(0, docY));
            doc.translate(0, docY);

            // header
            this.$_renderRow(doc, y, columns, column => column.header, null, bold, '#fad9cd');

            y += rowHeight;

            // body

            expenses.map((item, index) => {
                //this.$_checkLineAvailable(doc);  
                // console.log("item ", item);
                this.$_renderRow(doc, y, columns, column => item[column.binding], column => column.format);
                y += rowHeight;
            });
            // this.$_renderRow(doc, y, columns, column => item[column.binding], column => column.format);

            doc.addPage(doc.currentPageSettings);
            doc.footer.drawText("Report generated on enmovil.io by Enmovil Solutions", null, null, {
                align: pdf.PdfTextHorizontalAlign.Right
            });

        }
    }
    //
    $_checkLineAvailable(doc) {
        if (doc.height - doc.y < doc.lineHeight() + doc.lineGap) {
            doc.addPage();
        }
    }
    //
    $_renderRow(doc, y, values, valueGetter, formatGetter, font, brush) {
        let colWidth = this.state.colWidth, rowHeight = this.rowHeight;
        //
        values.forEach((v, idx) => {
            let x = idx * colWidth;
            //
            doc.paths
                .rect(x, y, colWidth, rowHeight)
                //.fill(brush || '#eaeaea')
                .stroke();
            //
            let value = valueGetter != null ? valueGetter(v) : v || '';
            let format = formatGetter != null ? formatGetter(v) : '';
            //
            if (value !== 'Total') {
                value = wijmo.changeType(value, wijmo.DataType.String, format);
            }
            //
            doc.drawText(value, x + 2, y + 2, {
                font: font,
                height: rowHeight,
                width: colWidth
            });
        });
        doc.moveDown(5);
    }

    render() {
        return (
            <button className="label label-danger" style={{border:"1px solid #FF5370"}} onClick={this.exportPDF.bind(this)}>Export To PDF</button>

        )
    }
}

function timeConvert(n) {
    var num = n;
    var hours = (num / (3600));
    var rhours = parseInt(hours);
    var minutes = (num - (rhours * 3600)) / (60);
    var rminutes = Math.round(minutes);
    return rhours + " hour(s) and " + rminutes + " minute(s).";
}

function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor(seconds % (3600 * 24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);

    var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay;
}

function ConvertMinutes(num) {
    var d = Math.floor(num / 1440); // 60*24
    var h = Math.floor((num - (d * 1440)) / 60);
    var m = Math.round(num % 60);
    var dayText = d > 1 ? " Days " : " Day ";
    return d + dayText + h + " Hrs " + m + " Mins"
}

function ConvertSeconds(totalSeconds) {
    // var days = Math.floor(num/86400);
    // var hours = Math.floor(num/3600)
    // var minutes = Math.floor(num/60);
    var minutes = Math.round((totalSeconds % 3600) / 60);
    var hours = Math.round((totalSeconds % 86400) / 3600);
    var days = Math.round((totalSeconds % (86400 * 30)) / 86400);
    // let d = (new Date(t0)) - (new Date(t1));
    // let weekdays     = Math.floor(d/1000/60/60/24/7);
    // let days         = Math.floor(d/1000/60/60/24 - weekdays*7);
    // let hours        = Math.floor(d/1000/60/60    - weekdays*7*24            - days*24);
    // let minutes      = Math.ceil(d/1000/60       - weekdays*7*24*60         - days*24*60         - hours*60);
    var dayText = days > 1 ? " Days " : " Day ";
    return days + dayText + hours + " Hrs " + minutes + " Mins"
}

function ConvertHHMMSeconds(n) {
    var days = Math.round(parseInt(n / (24 * 3600)));

    n = n % (24 * 3600);
    var hours = Math.round(parseInt(n / 3600));

    n %= 3600;
    var minutes = Math.round(n / 60);

    n %= 60;
    var seconds = Math.round(n);

    return days + " Day(s) " + hours + " Hrs " + minutes + " mins " + seconds + " secs"
}
function convertMeters(metersValue) {
    return (metersValue / 1000).toFixed(2);
}