/* eslint-disable no-unreachable */
/* eslint-disable no-loop-func */
/* eslint-disable array-callback-return */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from "lodash";
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import $ from "jquery";
import Modal from 'react-responsive-modal';
import Select from 'react-select';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import DateFormater from './dateComponent';
import CustomLoadingCellRenderer from "./customLoadingCellRenderer";
import {
    getDDMMYYYY,
    getDDMMYYYYHHMMSS,
    getYYYYMMDD,
    getYYYYMMDDHHMMSS,
    getHyphenYYYYMMDDHHMMSS,
    getHyphenYYYYMMDD,
    getHyphenDDMMYYYYHHMMSS,
    getHyphenDDMMMYYYYHHMM,
    getHyphenDDMMYYYY
} from '../common/utils';
import 'react-vertical-timeline-component/style.min.css';
import DrawMap from '../common/drawmap';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");

const decipher = salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
    return encoded => encoded.match(/.{1,2}/g)
        .map(hex => parseInt(hex, 16))
        .map(applySaltToChar)
        .map(charCode => String.fromCharCode(charCode))
        .join('');
}
var decode = decipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

const cipher = (salt) => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
    try {
        return text => text.split('')
            .map(textToChars)
            .map(applySaltToChar)
            .map(byteHex)
            .join('');
    }
    catch (e) { }

}
var encode = cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

export default class TollsInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageTitle: "",
            eventGridAction: "gridAction",
            eventCounterAction: "counterAction",
            eventFormAction: "formAction",
            eventAction: null,
            allRowData: [],
            sliderTranslate: '',
            loadshow: 'show-n',
            showDiv: 'show-n',
            alertshow: 'fade',
            alertmg: 'show-n',
            alerterrmg: 'show-n',
            overly: 'show-n',
            alerterrshow: 'fade',
            alert: null,
            show: false,
            basicTitle: '',
            basicType: "default",
            modules: AllModules,
            filterstatess: {},
            columnsdatas: {},
            rowgroupdcols: [],
            pivotmodeis: false,
            defaultColDef: {
                sortable: true,
                filter: true,
                resizable: true,
                editable: true
            },
            rownode: [],
            rowData: [],
            rowSelection: 'multiple',
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            overlayNoRowsTemplate: "",
            frameworkComponents: {
                customLoadingCellRenderer: CustomLoadingCellRenderer
            },
            animateRows: true,
            debug: true,
            showToolPanel: false,
            uppressAggFuncInHeader: true,
            childconfs: [],
            childrow: [],
            rowModelType: "serverSide",
            paginationPageSize: 50,
            cacheBlockSize: 10,
            maxBlocksInCache: 1,
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            detailCellRendererParams: {},
            rowClassRules: {
                //"highlitRow": "data.transit_delay == 1",
                //"green":"data.status >= 3"

            },
            selectedRows: '',
            transporterCodes: [],
            transporterNames: [],
            geoNames: [],

        }
    }

    componentDidMount() {
        let itemM = localStorage.getItem("m")
        if (itemM !== "" && itemM !== undefined) var loguser = decode(itemM);
        var loginplant = "";
        try {
            var luser = JSON.parse(loguser);
            loginplant = luser.is_plant_code;
            // console.log("logplant", loginplant)
            this.setState({
                loginplant: loginplant,
                // consigner:[{"value":luser.is_plant_code,"label":luser.is_plant_name}]

            })
        }
        catch (e) {

        }
        let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
        let pagetitle = 'Toll Details';
        if (this.props.match.path == "/tollDetails") {
            var dept_code = "SNDG"
        }
        this.setState({
            loadshow: 'show-m',
            overly: 'show-m',
            // eventAction:eventAction,
            dept_code: dept_code,
            pagetitle: pagetitle
        });

        this.loadPageData()

        redirectURL.post("/consignments/usergridstates", { userId: localStorage.getItem("userid"), screenurl: window.location.pathname })
        .then(async (resp) => {
            //// console.log("resp ", resp)
            await this.setState({
                usergridstate: resp.data
            });
            await this.restoreGridStates();
        })

    }

    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
            } else {
                console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }

    loadPageData = () => {

        var params = {
            // dept_code: encode(dept_code),
        }

        params.transporter_code = localStorage.getItem("transportercode")
        let urlPath = "/consignments/getDataForTolls"
        redirectURL.post(urlPath, params).then(response => {
            let respData = response.data

            let data = []
            respData.map(e =>{
                // console.log("e",e)
                let location = e.location
                let plainText = location.replace(/<[^>]*>/g, '');
                data.push({
                    "_id":e._id,
                    "area":e.area,
                    "lat":e.lat,
                    "lon":e.lon,
                    "location":plainText,
                    "name":e.name,
                    "state":e.state,
                    "rate_to_be_considered":e.rate_to_be_considered,
                    "car_single":e.rates.car_single,
                    "car_multi":e.rates.car_multi,
                    "car_monthly":e.rates.car_monthly,
                    "lcv_single":e.rates.lcv_single,
                    "lcv_multi":e.rates.lcv_multi,
                    "lcv_monthly":e.rates.lcv_monthly,
                    "bus_multi":e.rates.bus_multi,
                    "bus_monthly":e.rates.bus_monthly,
                    "multiaxle_single":e.rates.multiaxle_single,
                    "multiaxle_multi":e.rates.multiaxle_multi,
                    "multiaxle_monthly":e.rates.multiaxle_monthly,
                    "hcm_single":e.rates.hcm_single,
                    "hcm_multi":e.rates.hcm_multi,
                    "hcm_monthly":e.rates.hcm_monthly,
                    "four_six_axle_single":e.rates.four_six_axle_single,
                    "four_six_axle_multi":e.rates.four_six_axle_multi,
                    "four_six_axle_monthly":e.rates.four_six_axle_monthly,
                    "seven_plus_axle_single":e.rates.seven_plus_axle_single,
                    "seven_plus_axle_multi":e.rates.seven_plus_axle_multi,
                    "seven_plus_axle_monthly":e.rates.seven_plus_axle_monthly
                })
            })
            // console.log("data",data)
            this.setState({
                rowData: data,
                loadshow: 'show-n',
                overly: 'show-n',
            })

        })
    }

    onGridReady = params => {
        this.gridApi = params.api;
        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
    };


    onGridState = () => {
        //console.log(this.gridApi);

        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns,
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }

    onRowSelection(event) {
        // var containerslist=[];
        // //console.log("rowselect", event)
        // var rwCount = event.api.getSelectedNodes();
        // console.log("rwCount ", rwCount)
        // 	//var containerlist=[];
        // 	console.log("rowselect", event.data)
        // 	var rowCount =  event.data
        // 	//console.log("rowCount ", rowCount)

        // 	rwCount.map((item) => {
        // 		containerslist.push(item.data.truck_no)
        // 	});

        // 	this.setState({
        // 		containerslist:containerslist
        // 	})
        // 	 console.log("checkedlist ", containerslist)

    }

    onCellRowClicked = e => {
        // console.log("e",e)
        if (e.colDef.field == "update") {
            let dat = e.data
            // console.log("dat",dat)
            // console.log("typeOf",(/[a-zA-Z]/).test(dat.lat))
            let latCheck = (/[a-zA-Z]/).test(dat.lat);
            let lonCheck = (/[a-zA-Z]/).test(dat.lon);
            let params = {}
            let rate_to_be_consideredCheck = (/[0-9]/).test(dat.rate_to_be_considered);
            // console.log("reateCheck",rate_to_be_consideredCheck)
            if((latCheck && lonCheck) == false  && rate_to_be_consideredCheck == true){
                let params = {
                    "name":dat.name,
                    "lat":dat.lat,
                    "lon":dat.lon,
                    "rates": {
                        "car_single" : dat.car_single,
                        "car_multi" : dat.car_multi,
                        "car_monthly" : dat.car_monthly,
                        "lcv_single" : dat.lcv_single,
                        "lcv_multi" : dat.lcv_multi,
                        "lcv_monthly" : dat.lcv_monthly,
                        "bus_multi" : dat.bus_multi,
                        "bus_monthly" : dat.bus_monthly,
                        "multiaxle_single" : dat.multiaxle_single,
                        "multiaxle_multi" : dat.multiaxle_multi,
                        "multiaxle_monthly" : dat.multiaxle_monthly,
                        "hcm_single" : dat.hcm_single,
                        "hcm_multi" : dat.hcm_multi,
                        "hcm_monthly" : dat.hcm_monthly,
                        "four_six_axle_single" : dat.four_six_axle_single,
                        "four_six_axle_multi" : dat.four_six_axle_multi,
                        "four_six_axle_monthly" : dat.four_six_axle_monthly,
                        "seven_plus_axle_single" : dat.seven_plus_axle_single,
                        "seven_plus_axle_multi" : dat.seven_plus_axle_multi,
                        "seven_plus_axle_monthly" : dat.seven_plus_axle_monthly
                    },
                    "location":dat.location,
                    "area":dat.area,
                    "state":dat.state,
                    "rate_to_be_considered":dat.rate_to_be_considered,
                } 
                console.log("params",params)      
                if (params.name !== "" && params.lat !== "" && params.lon !== '' && params.location !== "" && params.state !== "" && params.rate_to_be_considered !== "") {
                    redirectURL.post("/consignments/updateTollsDetails", params).then((response) => {
                            if (response.data.message == "Success") {
                                this.loadPageData()
                                this.setState({
                                    basicTitle: "Success",
                                    basicType: "success",
                                    show: true,
                                    overly: "show-n",
                                    loadshow: "show-n",
                                })
                                //window.location.reload();
                                this.loadPageData()
                            }
                            else if(response.data.message == "Details Already Exists"){
                                this.setState({
                                    basicTitle: "Details Already Exists",
                                    basicType: "warning",
                                    show: true
                                })
                            }
                            else {
                                this.setState({
                                    basicTitle: "Failed to update.",
                                    basicType: "warning",
                                    show: true
                                })
                            }
                        })
                }
                else {
                    this.setState({
                        basicTitle: "Please Enter Values to update.",
                        basicType: "warning",
                        show: true
                    })
                }
            }
            else{
                this.setState({
                    basicTitle: "Please Enter Numerals for lat,lon and rates to be considered.",
                    basicType: "warning",
                    show: true
                })
            }
        }
        // else if(e.colDef.field == "edit"){
        //     let dat = e.data
        //     let params = {
        //         "name":dat.name,
        //         "lat":dat.lat,
        //         "lon":dat.lon,
        //         "rates": {
        //             "car_single" : dat.car_single,
        //             "car_multi" : dat.car_multi,
        //             "car_monthly" : dat.car_monthly,
        //             "lcv_single" : dat.lcv_single,
        //             "lcv_multi" : dat.lcv_multi,
        //             "lcv_monthly" : dat.lcv_monthly,
        //             "bus_multi" : dat.bus_multi,
        //             "bus_monthly" : dat.bus_monthly,
        //             "multiaxle_single" : dat.multiaxle_single,
        //             "multiaxle_multi" : dat.multiaxle_multi,
        //             "multiaxle_monthly" : dat.multiaxle_monthly,
        //             "hcm_single" : dat.hcm_single,
        //             "hcm_multi" : dat.hcm_multi,
        //             "hcm_monthly" : dat.hcm_monthly,
        //             "four_six_axle_single" : dat.four_six_axle_single,
        //             "four_six_axle_multi" : dat.four_six_axle_multi,
        //             "four_six_axle_monthly" : dat.four_six_axle_monthly,
        //             "seven_plus_axle_single" : dat.seven_plus_axle_single,
        //             "seven_plus_axle_multi" : dat.seven_plus_axle_multi,
        //             "seven_plus_axle_monthly" : dat.seven_plus_axle_monthly
        //         },
        //         "location":dat.location,
        //         "area":dat.area,
        //         "state":dat.state,
        //         "rate_to_be_considered":dat.rate_to_be_considered,
        //     }
        //     let fParam = {
        //         "isNew": true,
        //         params
        //     }
        //     if (params.name !== "" && params.lat !== "" && params.lon !== '' && params.location !== "" && params.area !== "" && params.state !== "" && params.rate_to_be_considered !== "") {
        //         redirectURL.post("/consignments/updateTollsDetails", fParam).then((response) => {
        //                 if (response.data.message == "Success") {
        //                     this.loadPageData()
        //                     this.setState({
        //                         basicTitle: "Success",
        //                         basicType: "success",
        //                         show: true,
        //                         overly: "show-n",
        //                         loadshow: "show-n",
        //                     })
        //                     //window.location.reload();
        //                     this.loadPageData()
        //                 }
        //                 else {
        //                     this.setState({
        //                         basicTitle: "Failed to Upload.",
        //                         basicType: "warning",
        //                         show: true
        //                     })
        //                 }
        //             })
        //     }
        //     else {
        //         this.setState({
        //             basicTitle: "Please Enter Values to update.",
        //             basicType: "warning",
        //             show: true
        //         })
        //     }
        // }
    }

    onClickHideAll = () => {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            sliderRouteTranslate: '',
            tabsliderTranslate: '',
            sliderCommentTranslate: "",
            slideBar: ''
        });

    }

    closeAlert = () => {
        this.setState({
            show: false
        });
    }

    addNewRow = () => {
        // console.log(this.gridApi);
        this.gridApi.insertItemsAtIndex(0, [{ name: "", lat: "", lon: "", location: "", area: "", state: "", rate_to_be_considered: "", car_single: "", car_multi: "", car_monthly: "", lcv_single: "", lcv_multi: "", lcv_monthly: "", bus_multi: "", bus_monthly: "", multiaxle_single: "", multiaxle_multi: "", multiaxle_monthly: "", hcm_single: "", hcm_multi: "", hcm_monthly: "", four_six_axle_single: "", four_six_axle_multi: "", four_six_axle_monthly: "", seven_plus_axle_single: "", seven_plus_axle_multi: "", seven_plus_axle_monthly: "" }])
        // this.gridApi.updateRowData({add:[{holiday_date:"",holiday_name:""}]});
    }
	onClickSaveGridState() {
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();
		// // console.log("Columns ", window.colState)
		// // console.log("Group ", window.groupState)
		// // console.log("Sort ", window.sortState)
		// // console.log("Filter ", window.filterState)
		let screenpage = 'Tolls';

		
		let reqparams = {
			gridcolumns: window.colState,
			gridgroup: window.groupState,
			gridcolsort: window.sortState,
			gridcolfilter: window.filterState,
			userId: localStorage.getItem("userid"),
			screenurl: window.location.pathname,
			screentitle: screenpage
		}
		// // console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
			.then((response) => {
				//// console.log("State response ",response.data)
				this.setState({
					show: true,
					basicTitle: "Successfully saved grid layout",
					basicType: "success",
					screenurl: window.location.pathname,
					screentitle: screenpage
				})
			})
			.catch(function (e) {
				// console.log("Error ", e)
			})
	}

	restoreGridStates() {

		if (this.state.usergridstate.length > 0) {
			var windowstates = this.state.usergridstate;
			this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
			this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
			this.gridApi.setSortModel(windowstates[0].gridcolsort);
			this.gridApi.setFilterModel(windowstates[0].gridcolfilter);

		}
	}

	resetState = () => {
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//// console.log('column state reset');
		var reqparams = {
			userId: localStorage.getItem("userid"),
			screenurl: window.location.pathname,
			screentitle: this.state.screenpage
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
			.then((response) => {
				this.setState({
					show: true,
					basicTitle: "Successfully reset default grid layout",
					basicType: "success"
				})
			})

	};

    render() {
        const columnwithDefs = [
            // {
            //     field:'edit',
            //     headerName:'',
            //     colId:"_id",
            //     //pinned:"left",
            //     width: 72,
            //     cellRenderer:function(params){
            //         let basestring = params.data._id;
            //         let htmlOpt = '<button class="custom-btn label label-success"><i class="icofont icofont-edit"></i>Edit</button>'
            //         return htmlOpt
            //         // let encryptedstring = window.btoa(basestring);
            //         //     var htmloption = '<a href="/editffnewaircargoform/'+encryptedstring+'" class="custom-btn label label-success"><i class="icofont icofont-edit"></i> Edit</a>';
                   
            //         // return htmloption;
            //     }            
            // },
            {
                headerName: "Name",
                field: "name",
                // pinned: 'left',
                width: 140,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Latitude",
                field: "lat",
                width: 100,
                // pinned: 'left',
                filter: true,
                resizable: true,
                // valueGetter: param =>{
                //     let datCheck = param.data.lat;
                //     // try{
                //     //     if(datCheck !== "" && datCheck !== undefined && datCheck !== null && datCheck !== "undefined"){
                //     //         return datCheck.toFixed(2)
                //     //     }
                //     //     else{
                //     //         return ""
                //     //     }
                //     // }
                //     // catch(e){console.log("error",e)}
                //     return datCheck;
                // }
            },
            {
                headerName: "Longitude",
                field: "lon",
                width: 100,
                // pinned: 'left',
                filter: true,
                resizable: true,
                // valueGetter: param =>{
                //     let datCheck = param.data.lon
                //     // try{
                //     // if(param.data.lon !== "" && param.data.lon !== undefined && datCheck !== null && datCheck !== "undefined"){
                //     //     return param.data.lon.toFixed(2)
                //     // }
                //     // else{
                //     //     return ""
                //     // }
                //     // }
                //     // catch(e){console.log("error",e)}
                //     return datCheck
                // }
            },
            {
                headerName: "Location",
                width: 140,
                field: "location",
                // pinned: 'left',
                filter: true,
                resizable: true,
                // valueGetter: (params) => {
                //     let data = params.data;
                //     console.log("data",data)
                //     if(data !== "" && data !== undefined){
                //         let plainText = data.replace(/<[^>]*>/g, '');
                //         return plainText
                //     }
                //     else{
                //         return ""
                //     }
                // }
            },
            {
                headerName: "Area",
                field: "area",
                width: 100,
                // pinned: 'left',
                filter: true,
                resizable: true,
            },
            {
                headerName: "State",
                field: "state",
                width: 100,
                // pinned: 'left',
                filter: true,
                resizable: true,
            },
            {
                headerName: "Rate To Be Considered",
                field: "rate_to_be_considered",
                width: 100,
                pinned: 'right',
                filter: true,
                resizable: true,
            },
            {
                headerName: "Car Single",
                field: "car_single",
                width: 100,
                // pinned: 'left',
                filter: true,
                resizable: true,
                // valueGetter: params => {
                //     // console.log("params",params.data)
                //     // console.log("params",params.data.rates)
                //     let data = params.data.car_single
                //     if (data !== undefined && data !== "" && data !== null) {
                //         return data
                //     }
                //     else {
                //         return ''
                //     }

                // }
            },
            {
                headerName: "Car Multiple",
                field: "car_multi",
                width: 100,
                // pinned: 'left',
                filter: true,
                resizable: true,
                // valueGetter: params => {
                //     let data = params.data.rates.car_multi
                //     if (data !== undefined && data !== "" && data !== null) { return data }
                //     else {
                //         return ''
                //     }
                // }
            },
            {
                headerName: "Car Monthly",
                field: "car_monthly",
                width: 100,
                // pinned: 'left',
                filter: true,
                resizable: true,
            },
            {
                headerName: "Lcv Single",
                field: "lcv_single",
                width: 100,
                // pinned: 'left',
                filter: true,
                resizable: true,  
            },
            {
                headerName: "Lcv Multiple",
                field: "lcv_multi",
                width: 100,
                // pinned: 'left',
                filter: true,
                resizable: true,
            },
            {
                headerName: "Lcv Monthly",
                field: "lcv_monthly",
                width: 100,
                // pinned: 'left',
                filter: true,
                resizable: true,
            },
            {
                headerName: "Bus Multiple",
                field: "bus_multi",
                width: 100,
                // pinned: 'left',
                filter: true,
                resizable: true,
            },
            {
                headerName: "Bus Monthly",
                field: "bus_monthly",
                width: 100,
                // pinned: 'left',
                filter: true,
                resizable: true,
            },
            {
                headerName: "Multi Axle Single",
                field: "multiaxle_single",
                width: 100,
                // pinned: 'left',
                filter: true,
                resizable: true,
            },
            {
                headerName: "Multi Axle Multiple",
                field: "multiaxle_multi",
                width: 100,
                // pinned: 'left',
                filter: true,
                resizable: true,               
            },
            {
                headerName: "Multi Axle Monthly",
                field: "multiaxle_monthly",
                width: 100,
                // pinned: 'left',
                filter: true,
                resizable: true,
            },
            {
                headerName: "Hcm Single",
                field: "hcm_single",
                width: 100,
                // pinned: 'left',
                filter: true,
                resizable: true,
            },
            {
                headerName: "Hcm Multi",
                field: "hcm_multi",
                width: 100,
                // pinned: 'left',
                filter: true,
                resizable: true,
            },
            {
                headerName: "Hcm Monthly",
                field: "hcm_monthly",
                width: 100,
                // pinned: 'left',
                filter: true,
                resizable: true,
            },
            {
                headerName: "Four Six Axle Single",
                field: "four_six_axle_single",
                width: 100,
                // pinned: 'left',
                filter: true,
                resizable: true,
            },
            {
                headerName: "Four Six Axle Multi",
                field: "four_six_axle_multi",
                width: 100,
                // pinned: 'left',
                filter: true,
                resizable: true,
            },
            {
                headerName: "Four Six Axle Monthly",
                field: "four_six_axle_monthly",
                width: 100,
                // pinned: 'left',
                filter: true,
                resizable: true, 
            },
            {
                headerName: "Seven Plus Axle Single",
                field: "seven_plus_axle_single",
                width: 100,
                // pinned: 'left',
                filter: true,
                resizable: true,
            },
            {
                headerName: "Seven Plus Axle Multi",
                field: "seven_plus_axle_multi",
                width: 100,
                // pinned: 'left',
                filter: true,
                resizable: true,               
            },
            {
                headerName: "Seven Plus Axle Monthly",
                field: "seven_plus_axle_monthly",
                width: 100,
                // pinned: 'left',
                filter: true,
                resizable: true,                
            },
            {
                headerName: "Update",
                field: "update",
                width: 120,
                filter: false,
                pinned: 'left',
                resizable: true,
                cellRenderer: function (params) {
                    var htmloption = '<button class="btn btn-success" style="padding-top:0px">Update</button>';
                    return htmloption;
                },
            },
        ]
        const { defaultColDef, modules, statusBar, sideBar, paginationPageSize, rowSelection, rowData, pagetitle, show, basicType, basicTitle, overly, loadshow } = this.state
        return (
            <div className="container-fluid">
                <SweetAlert
                    show={show}
                    type={basicType}
                    title={basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="mb-15p fbold f20">
                    <h5>
                        <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{pagetitle} </span>
                        <button className="btn btn-success" style={{ float: "right", fontFamily: "Roboto", fontSize: "14px" }} onClick={this.addNewRow}>Add Toll Details</button>
                    </h5>
                </div>
                <div className='row'>
                    <div className='col-xl-12 col-lg-12'>
                        <div className='card'>
                            {/* <div className="card-header">
                                
                            </div> */}
                            <div className="card-body pt-10px" >
                                <div className="row" style={{textAlign:'right'}}>
                                    <div className="col-sm-12">
                                        <button className="float-right custom-btn white btn-danger" onClick={this.onClickSaveGridState.bind(this)}>
                                            <i className="icofont icofont-save"></i> Save Grid Layout
                                        </button>
                                        <button className="float-right custom-btn white btn-info" style={{marginRight:"10px"}} onClick={this.resetState.bind(this)}>
                                            <i className="icofont icofont-refresh"></i> Reset Default Layout
                                        </button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div id="myGrid" style={{ height: "580px", width: "100%" }} className="ag-theme-balham">
                                        <AgGridReact
                                            modules={modules}
                                            columnDefs={columnwithDefs}
                                            defaultColDef={defaultColDef}
                                            rowData={rowData}
                                            enableCharts={true}
                                            enableRangeSelection={true}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            statusBar={statusBar}
                                            sideBar={sideBar}
                                            paginationPageSize={paginationPageSize}
                                            pagination={true}
                                            stopEditingWhenGridLosesFocus={true}
                                            enableCellChangeFlash={true}
                                            suppressCellFlash={true}
                                            gridOptions={{
                                                headerHeight: 60,
                                                context: { componentParent: this }
                                            }}
                                            suppressRowClickSelection={true}
                                            onCellClicked={this.onCellRowClicked.bind(this)}
                                        // onRowSelected={this.onRowSelection}
                                        // rowClassRules={this.state.rowClassRules}
                                        //onCellClicked={this.onRowClicked.bind(this)}
                                        //floatingFilter={true}
                                        // frameworkComponents={this.state.frameworkComponents}
                                        // detailCellRendererParams={this.state.detailCellRendererParams}
                                        //editType={this.state.editType}
                                        // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                <div className={"dataLoadpageimg " + (loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (overly)} onClick={this.onClickHideAll}></div>

            </div>

        )
    }



}